import WhatsAppChannelApi from './whatsAppChannelApi';
import { createSlice } from '@reduxjs/toolkit';
import { showMessageError } from '@Utils/RenderUtils';
import toastr from 'toastr';

const initialState = {
  page: {},
  loadingList: false,
  loadingSearchList: false,
  pageRequest: {},
  errors: [],

  currentWhatsAppChannel: {},

  errorsUpdate: [],
  loadingUpdate: false,

  errorsUpdateSubmit: [],
  loadingUpdateSubmit: false,

  errorsDelete: [],
  loadingDelete: false,

  errorsCreate: [],
  loadingCreate: false,

  whatsAppTemplatePage: {},
  whatsAppTemplatePageRequest: {},
  whatsAppTemplateLoading: false,
  whatsAppTemplateActionLoading: false,
  whatsAppTemplateErrors: [],
  currentWhatsAppTemplate: {},
  currentWhatsAppTemplateLoading: false,
  templateWhatsAppChannelList: [],
};

export const whatsAppChannelSlice = createSlice({
  name: 'whatsAppChannel',
  initialState,
  reducers: {
    //WhatsAppChannelList & WhatsAppChannelSearch
    setLoadingList(state, { payload }) {
      state.loadingList = payload;
    },
    setWhatsAppChannelList(state, { payload }) {
      state.page = payload;
    },
    setWhatsAppChannelListErrors(state, { payload }) {
      state.errors = payload;
    },
    setLoadingSearchList(state, { payload }) {
      state.loadingSearchList = payload;
    },
    //WhatsAppChannelUpdate
    setLoadingUpdate(state, { payload }) {
      state.loadingUpdate = payload;
    },
    setErrorsUpdate(state, { payload }) {
      state.errorsUpdate = payload;
    },
    //WhatsAppChannelUpdateSubmit
    setLoadingUpdateSubmit(state, { payload }) {
      state.loadingUpdateSubmit = payload;
    },
    setErrorsUpdateSubmit(state, { payload }) {
      state.errorsUpdateSubmit = payload;
    },
    //WhatsAppChannelCreate
    setLoadingCreate(state, { payload }) {
      state.loadingCreate = payload;
    },
    setErrorsCreate(state, { payload }) {
      state.errorsCreate = payload;
    },
    //WhatsAppChannelDelete
    setLoadingDelete(state, { payload }) {
      state.loadingDelete = payload;
    },
    setErrorsDelete(state, { payload }) {
      state.errorsDelete = payload;
    },
    //
    setCurrentWhatsAppChannel(state, { payload }) {
      state.currentWhatsAppChannel = payload;
    },
    setPageRequest(state, { payload }) {
      state.pageRequest = payload;
    },

    ///WhatsApp channel templates///
    setWhatsAppChannelTemplateList(state, { payload }) {
      state.whatsAppTemplatePage = payload;
    },
    setWhatsAppTemplatePageRequest(state, { payload }) {
      state.whatsAppTemplatePageRequest = payload;
    },
    setWhatsAppChannelTemplateLoading(state, { payload }) {
      state.whatsAppTemplateLoading = payload;
    },
    setWhatsAppChannelTemplateActionLoading(state, { payload }) {
      state.whatsAppTemplateActionLoading = payload;
    },
    setWhatsAppChannelTemplateErrors(state, { payload }) {
      state.whatsAppTemplateErrors = payload;
    },
    setCurrentWhatsAppChannelTemplate(state, { payload }) {
      state.currentWhatsAppTemplate = payload;
    },
    setCurrentWhatsAppChannelTemplateLoading(state, { payload }) {
      state.currentWhatsAppTemplateLoading = payload;
    },
    setTemplateWhatsAppChannelList(state, { payload }) {
      state.templateWhatsAppChannelList = payload;
    },
  },
});

export const {
  setLoadingList,
  setWhatsAppChannelList,
  setWhatsAppChannelListErrors,
  setLoadingSearchList,
  setLoadingUpdate,
  setErrorsUpdate,
  setCurrentWhatsAppChannel,
  setLoadingUpdateSubmit,
  setErrorsUpdateSubmit,
  setLoadingCreate,
  setErrorsCreate,
  setLoadingDelete,
  setErrorsDelete,
  setPageRequest,
  setWhatsAppChannelTemplateList,
  setWhatsAppTemplatePageRequest,
  setWhatsAppChannelTemplateLoading,
  setWhatsAppChannelTemplateActionLoading,
  setWhatsAppChannelTemplateErrors,
  setCurrentWhatsAppChannelTemplate,
  setCurrentWhatsAppChannelTemplateLoading,
  setTemplateWhatsAppChannelList,
} = whatsAppChannelSlice.actions;

//WhatsApp Provider//
export const fetchWhatsAppChannelList = (pageRequest) => async (dispatch) => {
  dispatch(setLoadingList(true));
  WhatsAppChannelApi.list(pageRequest)
    .then((res) => {
      dispatch(setWhatsAppChannelList(res));
      dispatch(setPageRequest(pageRequest));
    })
    .catch((error) => {
      dispatch(setWhatsAppChannelListErrors(error));
      showMessageError(error);
    })
    .finally(() => {
      dispatch(setLoadingList(false));
    });
};

export const fetchWhatsAppChannelSearchList =
  (pageRequest) => async (dispatch) => {
    dispatch(setLoadingSearchList(true));
    WhatsAppChannelApi.list(pageRequest)
      .then((res) => {
        dispatch(setWhatsAppChannelList(res));
      })
      .catch((error) => {
        dispatch(setWhatsAppChannelListErrors(error));
        showMessageError(error);
      })
      .finally(() => {
        dispatch(setLoadingSearchList(false));
      });
  };

export const fetchWhatsAppChannelProvider =
  (id, isEdit = true) =>
  async (dispatch) => {
    dispatch(setLoadingUpdate(true));
    const method = isEdit ? WhatsAppChannelApi.update : WhatsAppChannelApi.view;

    method(id)
      .then((res) => {
        dispatch(setCurrentWhatsAppChannel(res));
      })
      .catch((error) => {
        dispatch(setErrorsUpdate(error));
        showMessageError(error);
      })
      .finally(() => {
        dispatch(setLoadingUpdate(false));
      });
  };

export const updateWhatsAppChannelProvider =
  (id, whatAppChannel, closeModal) => async (dispatch, getState) => {
    const state = getState();
    dispatch(setLoadingUpdateSubmit(true));
    WhatsAppChannelApi.updateSubmit(id, whatAppChannel)
      .then(() => {
        toastr.success('Has been updated successfully');
        dispatch(
          fetchWhatsAppChannelList(state.channel.whatsAppChannel.pageRequest)
        );
      })
      .then(() => closeModal())
      .catch((error) => {
        dispatch(setErrorsUpdateSubmit(error));
        showMessageError(error);
      })
      .finally(() => {
        dispatch(setLoadingUpdateSubmit(false));
      });
  };

export const createWhatsAppChannelProvider =
  (whatAppChannel, closeModal) => async (dispatch, getState) => {
    const state = getState();
    dispatch(setLoadingCreate(true));
    WhatsAppChannelApi.create({ ...whatAppChannel })
      .then(() => {
        toastr.success('Has been created successfully');
        dispatch(
          fetchWhatsAppChannelList(state.channel.whatsAppChannel.pageRequest)
        );
      })
      .then(() => closeModal())
      .catch((error) => {
        dispatch(setErrorsCreate(error));
        showMessageError(error);
      })
      .finally(() => {
        dispatch(setLoadingCreate(false));
      });
  };

export const deleteWhatsAppChannelProvider =
  (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(setLoadingDelete(true));
    WhatsAppChannelApi.delete(id)
      .then(() => {
        toastr.success('Has been deleted successfully');
        dispatch(
          fetchWhatsAppChannelList(state.channel.whatsAppChannel.pageRequest)
        );
      })
      .catch((error) => {
        dispatch(setErrorsDelete(error));
        showMessageError(error);
      })
      .finally(() => {
        dispatch(setLoadingDelete(false));
      });
  };

//WhatsApp Template//
export const fetchWhatsAppChannelTemplateList =
  (pageRequest) => async (dispatch) => {
    dispatch(setWhatsAppChannelTemplateLoading(true));
    WhatsAppChannelApi.listTemplate(pageRequest)
      .then((res) => {
        dispatch(setWhatsAppChannelTemplateList(res));
        dispatch(setWhatsAppTemplatePageRequest(pageRequest));
      })
      .catch((error) => {
        dispatch(setWhatsAppChannelTemplateErrors(error));
        showMessageError(error);
      })
      .finally(() => {
        dispatch(setWhatsAppChannelTemplateLoading(false));
      });
  };

export const fetchWhatsAppChannelTemplate =
  (id, isEdit = true) =>
  async (dispatch) => {
    dispatch(setCurrentWhatsAppChannelTemplateLoading(true));
    const method = isEdit
      ? WhatsAppChannelApi.updateTemplate
      : WhatsAppChannelApi.viewTemplate;

    method(id)
      .then((res) => {
        dispatch(setCurrentWhatsAppChannelTemplate(res));
      })
      .catch((error) => {
        dispatch(setWhatsAppChannelTemplateErrors(error));
        showMessageError(error);
      })
      .finally(() => {
        dispatch(setCurrentWhatsAppChannelTemplateLoading(false));
      });
  };

export const updateWhatsAppChannelTemplate =
  (id, whatAppChannelTemplate, closeModal) => async (dispatch, getState) => {
    const state = getState();
    const temp = { ...whatAppChannelTemplate };
    temp.whatsAppChannel = whatAppChannelTemplate.whatsAppChannel?.id;
    dispatch(setWhatsAppChannelTemplateActionLoading(true));

    WhatsAppChannelApi.updateSubmitTemplate(id, temp)
      .then(() => {
        toastr.success('Has been updated successfully');
        dispatch(
          fetchWhatsAppChannelTemplateList(
            state.channel.whatsAppChannel.whatsAppTemplatePageRequest
          )
        );
      })
      .then(() => closeModal())
      .catch((error) => {
        dispatch(setWhatsAppChannelTemplateErrors(error));
        showMessageError(error);
      })
      .finally(() => {
        dispatch(setWhatsAppChannelTemplateActionLoading(false));
      });
  };

export const createWhatsAppChannelTemplate =
  (whatAppChannelTemplate, closeModal) => async (dispatch, getState) => {
    const state = getState();
    const temp = { ...whatAppChannelTemplate };
    temp.whatsAppChannel = whatAppChannelTemplate.whatsAppChannel?.id;
    dispatch(setWhatsAppChannelTemplateActionLoading(true));

    WhatsAppChannelApi.createTemplate(temp)
      .then(() => {
        toastr.success('Has been created successfully');
        dispatch(
          fetchWhatsAppChannelTemplateList(
            state.channel.whatsAppChannel.whatsAppTemplatePageRequest
          )
        );
      })
      .then(() => closeModal())
      .catch((error) => {
        dispatch(setWhatsAppChannelTemplateErrors(error));
        showMessageError(error);
      })
      .finally(() => {
        dispatch(setWhatsAppChannelTemplateActionLoading(false));
      });
  };

export const deleteWhatsAppChannelTemplate =
  (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(setWhatsAppChannelTemplateLoading(true));
    WhatsAppChannelApi.deleteTemplate(id)
      .then(() => {
        toastr.success('Has been deleted successfully');
        dispatch(
          fetchWhatsAppChannelTemplateList(
            state.channel.whatsAppChannel.whatsAppTemplatePageRequest
          )
        );
      })
      .catch((error) => {
        dispatch(setWhatsAppChannelTemplateErrors(error));
        showMessageError(error);
      })
      .finally(() => {
        dispatch(setWhatsAppChannelTemplateLoading(false));
      });
  };

export const fetchTemplateWhatsAppChannelList =
  (pageRequest, cb) => async (dispatch) => {
    WhatsAppChannelApi.list(pageRequest)
      .then((res) => {
        const temp = [];
        res.content?.map((channel) => {
          temp.push({
            value: channel.name,
            label: channel.name,
            key: channel.id,
          });
        });
        dispatch(setTemplateWhatsAppChannelList(temp));
      })
      .then(() => cb & cb())
      .catch((error) => {
        showMessageError(error);
      });
  };

setTemplateWhatsAppChannelList;

export default whatsAppChannelSlice.reducer;

export const whatsAppChannelData = (state) => ({
  page: state.channel.whatsAppChannel.page,
  whatsAppChannelList: state.channel.whatsAppChannel?.page?.content,
  loadingList: state.channel.whatsAppChannel.loadingList,
  loadingSearchList: state.channel.whatsAppChannel.loadingSearchList,
  pageRequest: state.channel.whatsAppChannel.pageRequest,
  errors: state.channel.whatsAppChannel.errors,
  currentWhatsAppChannel: state.channel.whatsAppChannel.currentWhatsAppChannel,
  errorsUpdate: state.channel.whatsAppChannel.errorsUpdate,
  loadingUpdate: state.channel.whatsAppChannel.loadingUpdate,
  errorsUpdateSubmit: state.channel.whatsAppChannel.errorsUpdateSubmit,
  loadingUpdateSubmit: state.channel.whatsAppChannel.loadingUpdateSubmit,
  errorsDelete: state.channel.whatsAppChannel.errorsDelete,
  loadingDelete: state.channel.whatsAppChannel.loadingDelete,
  errorsCreate: state.channel.whatsAppChannel.errorsCreate,
  loadingCreate: state.channel.whatsAppChannel.loadingCreate,
  whatsAppTemplatePage: state.channel.whatsAppChannel.whatsAppTemplatePage,
  whatsAppTemplatePageRequest:
    state.channel.whatsAppChannel.whatsAppTemplatePageRequest,
  whatsAppTemplateLoading:
    state.channel.whatsAppChannel.whatsAppTemplateLoading,
  whatsAppTemplateActionLoading:
    state.channel.whatsAppChannel.whatsAppTemplateActionLoading,
  whatsAppTemplateErrors: state.channel.whatsAppChannel.whatsAppTemplateErrors,
  currentWhatsAppTemplate:
    state.channel.whatsAppChannel.currentWhatsAppTemplate,
  currentWhatsAppTemplateLoading:
    state.channel.whatsAppChannel.currentWhatsAppTemplateLoading,
  templateWhatsAppChannelList:
    state.channel.whatsAppChannel.templateWhatsAppChannelList,
});
