import React from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import '@Component/journey/pages/root/index.less';
import { Column } from '@ant-design/charts';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import {
  costumEventMissMatchReport,
  customEventMisMatchChangeCurrent,
} from '@Redux/slices/data/customEventSlice';
import { numberWithCommas } from '@Utils/RenderUtils';
import './customEventMismatch.module.less';

const loadingSpin = (
  <LoadingOutlined style={{ color: '#3b91fb', fontSize: 35 }} spin />
);
const ModalMismatch = () => {
  const loadingMissMatch = useSelector(
    (state) => state.dataModule.customEvent.loadingMissMatch
  );
  const customEventMissMatch = useSelector(
    (state) => state.dataModule.customEvent.customEventMissMatch
  );

  const customEventMissMatchFilter = useSelector(
    (state) => state.dataModule.customEvent.customEventMissMatchFilter
  );
  const currentCustomEvent = useSelector(
    (state) => state.dataModule.customEvent.currentCustomEvent
  );

  const x = 'Type Mismatch Report';
  const y = currentCustomEvent.name;
  const w = customEventMissMatchFilter.eventAttributeName;

  const dispatch = useDispatch();
  useEffect(() => {
    if (customEventMissMatchFilter?.eventAttributeId) {
      dispatch(costumEventMissMatchReport(customEventMissMatchFilter));
    }
  }, [customEventMissMatchFilter?.eventAttributeId]);

  const [openModalClass] = useState(false);

  const data = [];
  const config = {
    data,
    xField: 'label',
    yField: 'count',
    isStack: true,
    connectedArea: {
      style: (oldStyle) => {
        return {
          fill: 'rgba(0,0,0,0.25)',
          stroke: oldStyle.fill,
          lineWidth: 0.5,
        };
      },
    },
    tooltip: {
      shared: false,
      formatter: function formatter(v) {
        return { name: v.category, value: numberWithCommas(v.count) };
      },
    },
    point: {
      size: 5,
      shape: 'diamond',
    },
    xAxis: { label: { autoRotate: true, autoEllipsis: true } },
    seriesField: 'category',
    legend: {
      position: 'right',
    },
    interactions: [{ type: 'element-highlight-by-color' }],
  };

  if (customEventMissMatch.list && Array.isArray(customEventMissMatch.list)) {
    customEventMissMatch.list.forEach((item) => {
      if (Object.keys(item.split).length === 0) {
        data.push({ label: item.date, count: item.count });
      } else {
        for (var i = 0; i <= Object.values(item.split).length - 1; i++) {
          data.push({
            label: item.date,
            count: Object.values(item.split)[i],
            category: Object.keys(item.split)[i],
          });
        }
      }
    });
  }

  return (
    <Modal
      className={`dnd-modal ${openModalClass}`}
      ariaHideApp={false}
      isOpen={!!customEventMissMatchFilter?.eventAttributeId}
      onRequestClose={() => {
        dispatch(
          customEventMisMatchChangeCurrent({ ...customEventMissMatchFilter }),
          !customEventMissMatchFilter?.eventAttributeId
        );
      }}
    >
      <div>
        {loadingMissMatch ? (
          <Spin indicator={loadingSpin} />
        ) : (
          <div>
            <h1
              style={{ padding: '20px', fontWeight: 'bold', fontSize: '20px' }}
            >
              {x}
            </h1>

            <div
              style={{
                border: '1px solid lightGray',
                // padding: '15px',
                margin: '20px',
              }}
            >
              <div
                style={{
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                  borderBottom: '1px solid lightGray',
                  display: 'flex',
                }}
              >
                <span style={{ flex: '50%' }}>
                  Event: {y}
                  <br />
                  Attribute: {w}
                </span>
                <span style={{ marginLeft: 'auto', marginTop: 'auto' }}>
                  Past week
                </span>
              </div>
              <Column
                {...config}
                style={{
                  backgroundColor: 'white',
                  padding: '30px',
                }}
              ></Column>
            </div>
          </div>
        )}
      </div>
      <button
        className="modal-close__button"
        onClick={() => {
          dispatch(
            customEventMisMatchChangeCurrent({
              ...customEventMissMatchFilter,
              eventAttributeId: null,
            })
          );
        }}
      >
        <CloseOutlined style={{ fontSize: '22px' }} />
      </button>
    </Modal>
  );
};
export default ModalMismatch;
