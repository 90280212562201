import {
  USER_ROLE_LIST_FETCH_SUCCESS,
  USER_LIST_FETCH,
  USER_LIST_FETCH_SUCCESS,
  USER_CHANGE_CURRENT,
  USER_UPDATE_FETCH_SUCCESS,
  USER_CREATE_FETCH_FAIL,
  USER_UPDATE_SUBMIT_FETCH_FAIL,
  USER_PAGE_CHANGE_CURRENT,
  USER_VIEW_FETCH_SUCCESS,
  USER_UPLOAD_PICTURE_FETCH_SUCCESS,
  USER_CHANGE_PASSWORD_SUBMIT_FETCH_FAIL,
  USER_CHANGE_PASSWORD_FETCH_SUCCESS,
  USER_CP_SERVICE_LIST_FETCH_SUCCESS,
  USER_PRODUCT_LIST_FETCH_SUCCESS,
  USER_SEARCH_LIST_FETCH_SUCCESS,
  USER_SEARCH_LIST_FETCH,
  ADMIN_USER_CLEAR_ERROR,
  USER_UPDATE_FETCH_FAIL,
  USER_UPDATE_FETCH,
  USER_UPDATE_SUBMIT_FETCH_SUCCESS,
  USER_UPDATE_SUBMIT_FETCH,
  USER_CREATE_FETCH,
  USER_CREATE_FETCH_SUCCESS,
  USER_CHANGE_PASSWORD_SUBMIT_FETCH_SUCCESS,
  USER_CHANGE_PASSWORD_SUBMIT_FETCH,
} from './UserConstants';

const initialState = {
  page: {},
  pageRequest: {},
  roles: [],
  products: [],
  services: [],
  errors: [],
  adminUserErrors: [],
  currentUser: {},
  loadingUserList: false,
  loadingSearchUserList: false,
  loadingAdminUsers: false,
  userUpdateLoading: false,
};

export default function UserStateReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LIST_FETCH:
      return { ...state, pageRequest: action.payload, loadingUserList: true };
    case ADMIN_USER_CLEAR_ERROR:
      return {
        ...state,
        adminUserErrors: { ...state.adminUserErrors, errors: action.payload },
      };
    case USER_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingUserList: false };
    case USER_SEARCH_LIST_FETCH:
      return {
        ...state,
        pageRequest: action.payload,
        loadingSearchUserList: true,
      };
    case USER_SEARCH_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingSearchUserList: false };
    case USER_ROLE_LIST_FETCH_SUCCESS:
      return { ...state, roles: action.payload };
    case USER_PRODUCT_LIST_FETCH_SUCCESS:
      return { ...state, products: action.payload };
    case USER_CP_SERVICE_LIST_FETCH_SUCCESS:
      return { ...state, services: action.payload };
    case USER_CREATE_FETCH:
      return { ...state, userUpdateLoading: true };
    case USER_CREATE_FETCH_SUCCESS:
      return { ...state, userUpdateLoading: false };
    case USER_CREATE_FETCH_FAIL:
      return {
        ...state,
        adminUserErrors: action.payload,
        userUpdateLoading: false,
      };
    case USER_UPDATE_SUBMIT_FETCH:
      return { ...state, userUpdateLoading: true };
    case USER_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, userUpdateLoading: false };
    case USER_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        adminUserErrors: action.payload,
        userUpdateLoading: false,
      };
    case USER_CHANGE_PASSWORD_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingChangePassSubmit: false };
    case USER_CHANGE_PASSWORD_SUBMIT_FETCH:
      return { ...state, loadingChangePassSubmit: true };
    case USER_CHANGE_PASSWORD_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loadingChangePassSubmit: false,
      };
    case USER_CHANGE_CURRENT:
      return { ...state, currentUser: action.payload, errors: [] };
    case USER_UPDATE_FETCH_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        loadingAdminUsers: false,
      };
    case USER_UPDATE_FETCH:
      return { ...state, currentUser: action.payload, loadingAdminUsers: true };
    case USER_UPDATE_FETCH_FAIL:
      return {
        ...state,
        currentUser: action.payload,
        loadingAdminUsers: false,
      };
    case USER_CHANGE_PASSWORD_FETCH_SUCCESS:
    case USER_VIEW_FETCH_SUCCESS:
      return { ...state, currentUser: action.payload };
    case USER_PAGE_CHANGE_CURRENT:
      return { ...state, pageRequest: action.payload };
    case USER_UPLOAD_PICTURE_FETCH_SUCCESS:
      return {
        ...state,
        currentUser: { ...state.currentUser, picture: action.payload },
      };
    default:
      return state;
  }
}
