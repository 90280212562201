export const FUNNEL_LIST_EVENTS_FETCH = 'FUNNEL_LIST_EVENTS_FETCH';
export const FUNNEL_LIST_EVENTS_FETCH_SUCCESS =
  'FUNNEL_LIST_EVENTS_FETCH_SUCCESS';
export const FUNNEL_LIST_EVENTS_FETCH_FAIL = 'FUNNEL_LIST_EVENTS_FETCH_FAIL';

export const FUNNEL_VIEW_FETCH = 'FUNNEL_VIEW_FETCH';
export const FUNNEL_VIEW_FETCH_SUCCESS = 'FUNNEL_VIEW_FETCH_SUCCESS';
export const FUNNEL_VIEW_FETCH_FAIL = 'FUNNEL_VIEW_FETCH_FAIL';

export const FUNNEL_CREATE_UPDATE_FETCH = 'FUNNEL_VIEW_UPDATE_FETCH';
export const FUNNEL_CREATE_UPDATE_FETCH_SUCCESS =
  'FUNNEL_VIEW_UPDATE_FETCH_SUCCESS';
export const FUNNEL_CREATE_UPDATE_FETCH_FAIL = 'FUNNEL_VIEW_UPDATE_FETCH_FAIL';

export const FUNNEL_EXECUTE = 'FUNNEL_EXECUTE';
export const FUNNEL_EXECUTE_SUCCESS = 'FUNNEL_EXECUTE_SUCCESS';
export const FUNNEL_EXECUTE_FAIL = 'FUNNEL_EXECUTE_FAIL';
export const FUNNEL_EXECUTE_PARAMS_CHANGED = 'FUNNEL_EXECUTE_PARAMS_CHANGED';

export const FUNNEL_VIEW_EXECUTION = 'FUNNEL_VIEW_EXECUTION';
export const FUNNEL_VIEW_EXECUTION_SUCCESS = 'FUNNEL_VIEW_EXECUTION_SUCCESS';
export const FUNNEL_VIEW_EXECUTION_FAIL = 'FUNNEL_VIEW_EXECUTION_FAIL';

export const FUNNEL_STOP_EXECUTION = 'FUNNEL_STOP_EXECUTION';
export const FUNNEL_STOP_EXECUTION_SUCCESS = 'FUNNEL_STOP_EXECUTION_SUCCESS';
export const FUNNEL_STOP_EXECUTION_FAIL = 'FUNNEL_STOP_EXECUTION_FAIL';

export const FUNNEL_LIST_FETCH = 'FUNNEL_LIST_FETCH';
export const FUNNEL_LIST_PARAMS_CHANGED = 'FUNNEL_LIST_PARAMS_CHANGED';
export const FUNNEL_LIST_FETCHED = 'FUNNEL_LIST_FETCHED';
export const FUNNEL_LIST_FETCH_FAIL = 'FUNNEL_LIST_FETCH_FAIL';
