export const OPERATORS = [
  {
    name: 'EQUAL_TO',
    label: 'Equal to',
    negateLabel: 'Does Not Equal to',
    dataTypes: ['DATE', 'BOOLEAN', 'NUMERIC', 'STRING'],
  },
  {
    name: 'IS_NOT_EMPTY',
    label: 'Not Epmty',
    negateLabel: 'Is Empty',
    dataTypes: ['DATE', 'BOOLEAN', 'NUMERIC', 'STRING'],
  },
  {
    name: 'STARTS_WITH',
    label: 'Starts with',
    negateLabel: 'Does Not starts with',
    dataTypes: ['STRING'],
  },
  {
    name: 'ENDS_WITH',
    label: 'Ends with',
    negateLabel: 'Does Not Ends with',
    dataTypes: ['STRING'],
  },
  {
    name: 'CONTAINS',
    label: 'Contains',
    negateLabel: 'Does Not Contains',
    dataTypes: ['STRING'],
  },
  {
    name: 'ONE_OF',
    label: 'One of',
    negateLabel: 'None of',
    dataTypes: ['STRING', 'NUMERIC'],
  },
  {
    name: 'BETWEEN',
    label: 'Between',
    negateLabel: 'Not Between',
    dataTypes: ['DATE', 'NUMERIC'],
  },
  // {
  //   name: 'LESS_THAN',
  //   label: 'Less than or equal',
  //   negateLabel: 'Greater than',
  //   dataTypes: ['DATE', 'NUMERIC'],
  // },
  // {
  //   name: 'GREATER_THAN',
  //   label: 'Greater than or equal',
  //   negateLabel: 'Less than',
  //   dataTypes: ['DATE', 'NUMERIC'],
  // },
];

export const THEME_OPTIONS = [
  {
    key: 'border_radius',
    title: 'Border Radius',
    validFor: ['IMAGE', 'TEXT', 'COUPON_ON_SITE', 'COUPON_IN_APP'],
    type: 'input',
    default: '8',
  },
  {
    key: 'label_color',
    title: 'Label Color',
    validFor: ['TEXT'],
    type: 'color_picker',
    default: '#000000',
  },
  {
    key: 'title_color',
    title: 'Tilte Color',
    validFor: [
      'CARD',
      'MODAL',
      'BANNER',
      'TEXT',
      'COUPON_ON_SITE',
      'COUPON_IN_APP',
    ],
    type: 'color_picker',
    default: '#1B1B1D',
  },
  {
    key: 'description_color',
    title: 'Description Color',
    validFor: [
      'CARD',
      'MODAL',
      'BANNER',
      'TEXT',
      'COUPON_ON_SITE',
      'COUPON_IN_APP',
    ],
    type: 'color_picker',
    default: '#38373B',
  },
  {
    key: 'bg_color',
    title: 'Background',
    validFor: [
      'CARD',
      'MODAL',
      'BANNER',
      'TEXT',
      'COUPON_ON_SITE',
      'COUPON_IN_APP',
    ],
    type: 'color_picker',
    default: '#fefefe',
  },
  {
    key: 'action_label_color',
    title: 'Action Label',
    validFor: ['CARD', 'MODAL', 'COUPON_IN_APP'],
    type: 'color_picker',
    default: '#000000',
  },
  {
    key: 'action_bg_color',
    title: 'Action Background',
    validFor: ['MODAL', 'COUPON_IN_APP'],
    type: 'color_picker',
    default: '#ffffff',
  },

  {
    key: 'cta_background',
    title: 'CTA Background',
    validFor: ['TEXT'],
    type: 'color_picker',
    default: '#1D88F6',
  },
  {
    key: 'cta_font_color',
    title: 'CTA Font Color',
    validFor: ['TEXT'],
    type: 'color_picker',
    default: '#fff',
  },
  {
    key: 'cta_background_coupon',
    title: 'CTA Background',
    validFor: ['COUPON_ON_SITE'],
    type: 'color_picker',
    default: '#FAFAFA',
  },
  {
    key: 'cta_font_color_coupon',
    title: 'CTA Font Color',
    validFor: ['COUPON_ON_SITE'],
    type: 'color_picker',
    default: '#00587A',
  },
];

export const CUSTOM_FONT_OPTIONS = [
  {
    title: 'Yekan',
    value: 'byekan',
  },
  {
    title: 'Iran Yekan',
    value: 'iranyekanregular',
  },
  {
    title: 'Vazir',
    value: 'vazirregular',
  },
  {
    title: 'Iran Sans',
    value: 'iransans',
  },
  // {
  //   title: 'Titr',
  //   value: 'TITR',
  // },
  // {
  //   title: 'Shabnam',
  //   value: 'SHABNAM',
  // },
];
