import FunnelCreate from '../component/FunnelCreate';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  createFunnel,
  listEvents,
  triggerCreateUpdateFetchSuccess,
  updateFunnel,
  resetFunnelCreateUpdateContent,
  viewUpdateFunnel,
} from '../FunnelAction';
import {
  productSegmentReportViewFetch,
  productSegmentRecommendEventAttributesFetch,
} from '../../../insight/productSegment/ProductSegmentActions';

const mapStateToProps = (state) => ({
  loadingCreate: state.analytics.funnel.loadingCreate,
  loadingSegmentReportView: state.insight.productSegment.loadingReportView,
  segmentReportView: state.insight.productSegment.currentReport,
  events: state.analytics.funnel.events,
  createUpdate: state.analytics.funnel.createUpdate,
  errorSegmentReportView: state.insight.productSegment.errorReportView,
  loadingRecommendEventAttributes:
    state.insight.productSegment.loadingRecommendEventAttributes,
  recommendEventAttributes:
    state.insight.productSegment.recommendEventAttributes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createFunnel: createFunnel,
      viewUpdateFunnel: viewUpdateFunnel,
      updateFunnel: updateFunnel,
      listEvents: listEvents,
      triggerFunnelFetchSuccess: triggerCreateUpdateFetchSuccess,
      resetFunnelCreateUpdateContent: resetFunnelCreateUpdateContent,
      productSegmentReportViewFetch,
      productSegmentRecommendEventAttributesFetch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FunnelCreate);

FunnelCreate.propTypes = {};
