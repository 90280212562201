import MembersViewComponent from '../component/MembersViewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  teamMembersFetchList,
  teamMembersViewFetch,
  teamMemberDeleteFetch,
  newTeaMemberChangeCurrent,
  resendInvitation,
} from '../TeamMembersActions';
// import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  teamMembersPageRequest: state.integration.teamMembers.teamMembersPageRequest,
  teamMembers: state.integration.teamMembers.teamMembers,
  teamMembersLoading: state.integration.teamMembers.teamMembersLoading,
  currentActiveMembers: state.integration.teamMembers.currentActiveMembers,
  currentTeamMember: state.integration.teamMembers.currentTeamMember,
  permissions: state.integration.teamMembers.permissions,
  resendInvitationLoading:
    state.integration.teamMembers.resendInvitationLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      teamMembersFetchList,
      newTeaMemberChangeCurrent,
      teamMembersViewFetch,
      teamMemberDeleteFetch,
      resendInvitation,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersViewComponent);

// MembersViewComponent.propTypes = {};
