import React from 'react';
import { Modal, Button } from 'antd';
import { useSelector } from 'react-redux';
import ProductSegmentPredicateComponent from '../../../../insight/productSegment/component/ProductSegmentPredicateComponent';
import {
  productSegmentRecommendUserAttributesFetch,
  productSegmentRecommendDeviceAttributesFetch,
  productSegmentRecommendEventAttributesFetch,
} from '../../../../insight/productSegment/ProductSegmentActions';

const ConversionEventFilterSelector = ({
  selectorModalVisible,
  closeSelectorModal,
  onResetEventFilter,
  onOkEventFilter,
  currentPredicate,
  handleCurrentPredicateChange,
  currentWhen,
}) => {
  const eventList = useSelector(
    (state) => state.engage.communication.eventList
  );
  const recommendEventAttributes = useSelector(
    (state) => state.insight.productSegment.recommendEventAttributes
  );
  const recommendDeviceAttributes = useSelector(
    (state) => state.insight.productSegment.recommendDeviceAttributes
  );
  const recommendUserAttributes = useSelector(
    (state) => state.insight.productSegment.recommendUserAttributes
  );
  const isDynamic = currentWhen?.type === 'TRIGGERED';
  return (
    <Modal
      width={1080}
      title={''}
      centered
      visible={selectorModalVisible}
      onOk={closeSelectorModal}
      onCancel={closeSelectorModal}
      footer={[
        <Button shape="round" key="back" onClick={onResetEventFilter}>
          Reset
        </Button>,
        <Button
          key="submit"
          type="primary"
          shape="round"
          onClick={onOkEventFilter}
        >
          Apply
        </Button>,
      ]}
    >
      <div>
        <ProductSegmentPredicateComponent
          type="HAS_DONE_EVENT"
          predicate={currentPredicate}
          events={eventList}
          isInSegment={false}
          handleChangePredicate={(currentPredicate) =>
            handleCurrentPredicateChange(currentPredicate)
          }
          clearSegmentError={() => {
            //TODO Must Handle
          }}
          fetchRecommendEventAttributes={
            productSegmentRecommendEventAttributesFetch
          }
          recommendEventAttributes={recommendEventAttributes}
          fetchRecommendDeviceAttributes={
            productSegmentRecommendDeviceAttributesFetch
          }
          recommendDeviceAttributes={recommendDeviceAttributes}
          fetchRecommendUserAttributes={
            productSegmentRecommendUserAttributesFetch
          }
          recommendUserAttributes={recommendUserAttributes}
          dynamicEvent={currentWhen}
          dynamicConversion={isDynamic}
        />
      </div>
    </Modal>
  );
};

export default ConversionEventFilterSelector;
