import RoleViewComponent from '../component/RoleViewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { roleViewFetch, roleChangeCurrent } from '../RoleActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentRole: state.admin.role.currentRole,
  loadingViewRole: state.admin.role.loadingViewRole,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      roleViewFetch,
      roleChangeCurrent,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RoleViewComponent);

RoleViewComponent.propTypes = {
  currentRole: PropTypes.object.isRequired,
  roleViewFetch: PropTypes.func.isRequired,
  roleChangeCurrent: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
