import _, { isNil, isEmpty, find as lodashFind } from 'lodash';
import React from 'react';
import moment from 'moment';
// import toastr from 'toastr';
import { notification } from 'antd';
import vars from '@Theme/styles/vars';
export function getPushLayout(pushType) {
  let result = '';
  const pushTypes = {
    TEXT: 'Text',
    BANNER: 'Banner',
    CAROUSEL: 'Carousel',
    RATING: 'Rating',
  };
  result = !isNil(pushTypes[pushType]) ? pushTypes[pushType] : '';
  return result;
}

export function getInAppLayout(pushType) {
  let result = '';
  const pushTypes = {
    CARD: 'Card',
    BANNER: 'Banner',
    MODAL: 'Modal',
    IMAGE_ONLY: 'Image only',
    COUPON: 'Coupon',
  };
  result = !isNil(pushTypes[pushType]) ? pushTypes[pushType] : '';
  return result;
}
export function getOnSiteLayout(pushType) {
  let result = '';
  const pushTypes = {
    TEXT: 'Text & Link',
    COUPON: 'Coupon',
    IMAGE: 'Image only',
  };
  result = !isNil(pushTypes[pushType]) ? pushTypes[pushType] : '';
  return result;
}

export function numberWithCommas(x, supportNegative = false) {
  if (x) {
    let str = persianNumberToEnglish(x).toString();
    let negative = false;
    if (supportNegative && str.startsWith('-')) {
      str = str.substring(1);
      negative = true;
    }
    str = str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (negative) {
      str = `-${str}`;
    }
    return str;
  }
  return x;
}
export function numberWithoutCommas(x) {
  let str = x.toString();
  let numberWithoutCommas = parseFloat(str.replace(/,/g, ''));
  return parseInt(numberWithoutCommas);
}

export function renderLowerFont(title, fontSize = 13) {
  return <div style={{ fontSize }}>{title}</div>;
}

export function numberWithDecimal(x) {
  if (x) {
    const value = Math.round(x * 100) / 100;
    if (value === parseInt(value, 10)) {
      return value;
    }
    return value.toFixed(2);
  }
  return x;
}

export function percentage(from, total, removeDecimalFromInt = false) {
  if (
    Number(total) === 0 ||
    isNil(total) ||
    Number(from) === 0 ||
    isNil(from)
  ) {
    return removeDecimalFromInt ? 0 : '0.00';
  }

  const result = (Number(from) * 100) / Number(total);
  if (removeDecimalFromInt) {
    return Number.isInteger(result) ? result : numberWithDecimal(result);
  } else {
    return numberWithDecimal(result);
  }
}

const persianToEnglishMap = {
  '۰': '0',
  '۱': '1',
  '۲': '2',
  '۳': '3',
  '۴': '4',
  '۵': '5',
  '۶': '6',
  '۷': '7',
  '۸': '8',
  '۹': '9',
};

export function persianNumberToEnglish(x) {
  if (x) {
    const str = x.toString();
    let res = '';
    for (let i = 0; i < str.length; i++) {
      if (persianToEnglishMap[str[i]]) {
        res += persianToEnglishMap[str[i]];
      } else {
        res += str[i];
      }
    }
    return res;
  }
  return x;
}

export function renderDateTime(date) {
  if (date && moment(date).isValid()) {
    return moment(date).format('DD MMM YYYY, HH:mm A');
  }
  return null;
}

export function renderFullName(first, last) {
  if (!isEmpty(first) && !isEmpty(last)) {
    return first + ' ' + last;
  }
  if (isEmpty(first) && !isEmpty(last)) {
    return last;
  }
  if (isEmpty(last) && !isEmpty(first)) {
    return first;
  }
  return '-';
}

export function showMessageError(error) {
  if (_.get(error, 'errors[0].message')) {
    const key = _.get(error, 'errors[0].key');
    const message = _.get(error, 'errors[0].message');
    const msg = key ? message + ': ' + key : message;

    const key_ = msg ? msg.replaceAll(' ', '_').toLowerCase() : 'Error';

    notification.error({
      key: key_,
      message: (key || '').replace(/\[\d]\s?/g, '').replaceAll('.', ' '),
      description: (msg || '').replace(/\[\d]\s?/g, '').replaceAll('.', ' '),
      placement: 'bottomRight',
    });
  } else {
    notification.error({
      key: error?.status || 'Error',
      description: error?.message || error?.errorMessage || 'Unexpected error',
      placement: 'bottomRight',
    });
  }
}
export function showMessageErrorAudience(error) {
  if (_.get(error, 'errors[0].message')) {
    // const key = _.get(error, 'errors[0].key');
    const message = _.get(error, 'errors[0].message');
    // const msg = key ? message + ': ' + key : message;

    // const key_ = msg ? msg.replaceAll(' ', '_').toLowerCase() : '';
    return message;
  }
  return 'error';
}
export function showMessage(message, type, title) {
  const key = message ? message.replaceAll(' ', '_').toLowerCase() : '';
  notification[type]({
    key,
    message: title,
    description: message,
    placement: 'bottomRight',
  });
}

export function renderCampaignStatus(status) {
  const styles = {
    statusBadge: {
      fontSize: 11,
      fontWeight: '600',
      padding: '5px 10px',
      textTransform: 'uppercase',
      borderRadius: '50px',
      color: vars.light100,
    },
  };

  if (status === 'DRAFT') {
    return (
      <span
        style={{
          ...styles.statusBadge,
          backgroundColor: '#e7e8e9',
          color: '#94969e',
        }}
      >
        DRAFT
      </span>
    );
  }
  if (status === 'ARCHIVED') {
    return (
      <span
        style={{
          ...styles.statusBadge,
          backgroundColor: '#94969e',
          color: '#fff',
        }}
      >
        ARCHIVED
      </span>
    );
  } else if (status === 'UPCOMING') {
    return (
      <span
        style={{
          ...styles.statusBadge,
          backgroundColor: '#ffc107',
          color: '#FFFFFF',
        }}
      >
        UPCOMING
      </span>
    );
  } else if (status === 'W_UPCOMING') {
    return (
      <span
        style={{
          ...styles.statusBadge,
          backgroundColor: '#ffc107',
          color: '#FFFFFF',
        }}
      >
        W-UPCOMING
      </span>
    );
  } else if (status === 'RUNNING') {
    return (
      <span
        style={{
          ...styles.statusBadge,
          backgroundColor: '#008bfa',
          color: '#FFFFFF',
        }}
      >
        RUNNING
      </span>
    );
  } else if (status === 'W_RUNNING') {
    return (
      <span
        style={{
          ...styles.statusBadge,
          backgroundColor: '#008bfa',
          color: '#FFFFFF',
        }}
      >
        W-RUNNING
      </span>
    );
  } else if (status === 'ENDED') {
    return (
      <span
        style={{
          ...styles.statusBadge,
          backgroundColor: '#09d88c',
          color: '#FFFFFF',
        }}
      >
        ENDED
      </span>
    );
  } else if (status === 'PAUSED') {
    return (
      <span
        style={{
          ...styles.statusBadge,
          backgroundColor: vars.warningColor,
          color: '#FFFFFF',
        }}
      >
        PAUSED
      </span>
    );
  } else if (status === 'STOPPED') {
    return (
      <span
        style={{
          ...styles.statusBadge,
          backgroundColor: '#f76464',
          color: '#FFFFFF',
        }}
      >
        STOPPED
      </span>
    );
  }
  return null;
}

export function renderTag(text, listTags) {
  let color = 'gray';
  if (listTags && Array.isArray(listTags)) {
    const foundItem = lodashFind(listTags, (o) => o.name === text);
    if (foundItem) {
      color = foundItem.color;
    }
  }
  const styles = {
    tagPill: {
      borderRadius: 5,
      borderTop: '1px solid rgba(61,64,78,.2)',
      borderBottom: '1px solid rgba(61,64,78,.2)',
      borderRight: '1px solid rgba(61,64,78,.2)',
      borderLeft: '5px solid',
      borderLeftColor: color,
      padding: '6px 10px 6px 13px',
      position: 'relative',
    },
    item: {
      display: 'block',
      lineHeight: '1',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };

  return (
    <div style={{ ...styles.tagPill, maxWidth: 90 }}>
      <span title={text} style={styles.item}>
        {text}
      </span>
    </div>
  );
}

export function percentageWithoutDecimalPart(from, total) {
  if (
    Number(total) === 0 ||
    isNil(total) ||
    Number(from) === 0 ||
    isNil(from)
  ) {
    return 0;
  }

  const result = (Number(from) * 100) / Number(total);
  return result.toFixed();
}
