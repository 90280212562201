import { createSlice } from '@reduxjs/toolkit';
import { showMessageError } from '@Utils/RenderUtils';
import managementDashboardApi from './managementDashboardApi';

const initialState = {
  loading: false,
  chartsLoading: false,
  totalData: {},
  dailyData: {},
  customizationData: null,
};

export const contracts = createSlice({
  name: 'managementDashboard',
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setTotalData(state, { payload }) {
      state.totalData = payload;
    },
    setDailyData(state, { payload }) {
      state.dailyData = payload;
    },
    setChartsLoading(state, { payload }) {
      state.chartsLoading = payload;
    },
    setCustomizationData(state, { payload }) {
      state.customizationData = payload;
    },
  },
});

export default contracts.reducer;

export const {
  setLoading,
  setChartsLoading,
  setTotalData,
  setDailyData,
  setCustomizationData,
} = contracts.actions;

export const managementDashboardReportFetchCharts =
  (body) => async (dispatch) => {
    dispatch(setChartsLoading(true));

    await managementDashboardApi
      .get(body)
      .then((response) => {
        if (body.granularity === 'TOTAL') {
          dispatch(setTotalData(response));
        } else {
          dispatch(setDailyData(response));
        }
      })
      .catch((error) => showMessageError(error))
      .finally(() => dispatch(setChartsLoading(false)));
  };

export const managementDashboardReportFetch =
  (body, cb) => async (dispatch) => {
    dispatch(setLoading(true));

    await managementDashboardApi
      .get(body)
      .then((response) => cb && cb(response))
      .catch((error) => showMessageError(error))
      .finally(() => dispatch(setLoading(false)));
  };

export const managementDashboardStoreCustomization = (body) => async () => {
  await managementDashboardApi
    .store(body)
    .then(() => {})
    .catch((error) => showMessageError(error));
};

export const managementDashboardDownloadReport =
  ({ body, cb }) =>
  async () => {
    await managementDashboardApi
      .downloadReport(body)
      .then((res) => {
        cb && cb(res.path);
      })
      .catch((error) => showMessageError(error));
  };
