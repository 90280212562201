import { combineReducers } from 'redux';
import overview from './overview/OverviewState';
import communication from './communication/CommunicationState';
import journey from './journey/JourneyState';

const engageReducer = combineReducers({
  overview,
  communication,
  journey,
});

export default engageReducer;
