import {
  INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH,
  INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_ANALYZE_FETCH,
  INSIGHT_PRODUCT_USER_ANALYZE_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_ANALYZE_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_DEVICES_FETCH,
  INSIGHT_PRODUCT_USER_DEVICES_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_DEVICES_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_EVENTS_FETCH,
  INSIGHT_PRODUCT_USER_EVENTS_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_EVENTS_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_LIST_FETCH,
  INSIGHT_PRODUCT_USER_LIST_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_LIST_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH,
  INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH,
  INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH,
  INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_VIEW_FETCH,
  INSIGHT_PRODUCT_USER_VIEW_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_VIEW_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_PAGE_CHANGE_CURRENT,
  INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH,
  USER_CHANGE_CURRENT,
  USER_ATTRIBUTE_LIST_FETCH,
  USER_ATTRIBUTE_LIST_FETCH_SUCCESS,
  USER_ATTRIBUTE_LIST_FETCH_FAIL,
  USER_ANALYTICS_FETCH_SUCCESS,
  USER_ANALYTICS_FETCH_FAIL,
  USER_ANALYTICS_FETCH,
  INSIGHT_PRODUCT_USER_LIST_EVENT_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_JOURNEY_FETCH,
  INSIGHT_PRODUCT_USER_JOURNEY_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_JOURNEY_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_RELAY_FETCH,
  INSIGHT_PRODUCT_USER_RELAY_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_RELAY_FETCH_FAIL,
} from './UsersConstants';
import UsersApi from './UsersApi';

export function insightProductUserActivityReportFetch(body) {
  return (dispatch) => {
    dispatch({
      type: INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH,
      payload: body,
    });
    UsersApi.activityReport(body)
      .then((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function insightProductUserAnalyzeFetch(body) {
  return (dispatch) => {
    dispatch({
      type: INSIGHT_PRODUCT_USER_ANALYZE_FETCH,
      payload: body,
    });
    UsersApi.analyze(body)
      .then((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_ANALYZE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_ANALYZE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function insightProductUserDeviceFetch(id) {
  return (dispatch) => {
    dispatch({
      type: INSIGHT_PRODUCT_USER_DEVICES_FETCH,
    });
    UsersApi.devices(id)
      .then((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_DEVICES_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_DEVICES_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function insightProductUserEventFetch(id, body) {
  return (dispatch) => {
    dispatch({
      type: INSIGHT_PRODUCT_USER_EVENTS_FETCH,
    });
    UsersApi.events(id, body)
      .then((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_EVENTS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_EVENTS_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function insightProductUserCommunicationsFetch(id, body) {
  return (dispatch) => {
    dispatch({
      type: INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH,
    });
    UsersApi.communications(id, body)
      .then((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function insightProductUserJourneyFetch(id, body) {
  return (dispatch) => {
    dispatch({ type: INSIGHT_PRODUCT_USER_JOURNEY_FETCH });
    UsersApi.journeys(id, body)
      .then((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_JOURNEY_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_JOURNEY_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function insightProductUserRelayFetch(id, body) {
  return (dispatch) => {
    dispatch({ type: INSIGHT_PRODUCT_USER_RELAY_FETCH });
    UsersApi.journeys(id, body)
      .then((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_RELAY_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_RELAY_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

const loadInsightProductUserList = (dispatch, pageRequest) => {
  dispatch({
    type: INSIGHT_PRODUCT_USER_LIST_FETCH,
    payload: pageRequest,
  });
  UsersApi.list(pageRequest)
    .then((response) => {
      dispatch({
        type: INSIGHT_PRODUCT_USER_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: INSIGHT_PRODUCT_USER_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function insightProductUserListFetch(pageRequest) {
  return (dispatch) => loadInsightProductUserList(dispatch, pageRequest);
}

//////
const loadInsightProductUserSearchList = (dispatch, pageRequest) => {
  dispatch({
    type: INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH,
    payload: pageRequest,
  });
  UsersApi.list(pageRequest)
    .then((response) => {
      dispatch({
        type: INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function insightProductUserSearchListFetch(pageRequest) {
  return (dispatch) => loadInsightProductUserSearchList(dispatch, pageRequest);
}
/////
export function insightProductUserPageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: INSIGHT_PRODUCT_USER_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function insightProductUserListAttributeFetch() {
  return (dispatch) => {
    dispatch({
      type: INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH,
    });
    UsersApi.listAttributes()
      .then((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function insightProductUserListEventsFetch() {
  return (dispatch) => {
    // dispatch({
    //   type: INSIGHT_PRODUCT_USER_LIST_EVENT_FETCH,
    // });
    UsersApi.listEvents()
      .then((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_LIST_EVENT_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        // dispatch({
        //   type: INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH_FAIL,
        //   payload: response,
        // });
      });
  };
}

export function insightProductUserReachabilityReportFetch() {
  return (dispatch) => {
    dispatch({
      type: INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH,
    });
    UsersApi.reachabilityReport()
      .then((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function insightProductUserViewFetch(id) {
  return (dispatch) => {
    dispatch({
      type: INSIGHT_PRODUCT_USER_VIEW_FETCH,
    });
    UsersApi.view(id)
      .then((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: INSIGHT_PRODUCT_USER_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function userAnalyzeFetch(body) {
  return (dispatch) => {
    dispatch({
      type: USER_ANALYTICS_FETCH,
      payload: body,
    });
    UsersApi.analyze(body)
      .then((response) => {
        dispatch({
          type: USER_ANALYTICS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: USER_ANALYTICS_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function userAttributeListFetch() {
  return (dispatch) => {
    dispatch({
      type: USER_ATTRIBUTE_LIST_FETCH,
    });
    UsersApi.listAttributes()
      .then((response) => {
        dispatch({
          type: USER_ATTRIBUTE_LIST_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: USER_ATTRIBUTE_LIST_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function userChangeCurrent(user) {
  return (dispatch) => {
    dispatch({
      type: USER_CHANGE_CURRENT,
      payload: user,
    });
  };
}
