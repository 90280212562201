import {
  CUSTOM_CHANNEL_DELETE_FETCH,
  CUSTOM_CHANNEL_DELETE_FETCH_FAIL,
  CUSTOM_CHANNEL_DELETE_FETCH_SUCCESS,
  CUSTOM_CHANNEL_CREATE_FETCH,
  CUSTOM_CHANNEL_CREATE_FETCH_FAIL,
  CUSTOM_CHANNEL_CREATE_FETCH_SUCCESS,
  CUSTOM_CHANNEL_LIST_FETCH,
  CUSTOM_CHANNEL_LIST_FETCH_FAIL,
  CUSTOM_CHANNEL_LIST_FETCH_SUCCESS,
  CUSTOM_CHANNEL_SEARCH_LIST_FETCH,
  CUSTOM_CHANNEL_SEARCH_LIST_FETCH_FAIL,
  CUSTOM_CHANNEL_SEARCH_LIST_FETCH_SUCCESS,
  CUSTOM_CHANNEL_UPDATE_FETCH,
  CUSTOM_CHANNEL_UPDATE_FETCH_FAIL,
  CUSTOM_CHANNEL_UPDATE_FETCH_SUCCESS,
  CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH,
  CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
  CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
  CUSTOM_CHANNEL_CHANGE_CURRENT,
  CUSTOM_CHANNEL_PAGE_CHANGE_CURRENT,
  CUSTOM_CHANNEL_TOGGLE_ADD_MODAL,
} from './CustomChannelConstants';
import CustomChannelApi from './CustomChannelApi';
import toastr from 'toastr';
import { showMessageError } from '../../../utils/RenderUtils';

const loadCustomChannelList = (dispatch, pageRequest) => {
  dispatch({
    type: CUSTOM_CHANNEL_LIST_FETCH,
    payload: pageRequest,
  });
  CustomChannelApi.list(pageRequest)
    .then((response) => {
      dispatch({
        type: CUSTOM_CHANNEL_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: CUSTOM_CHANNEL_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function customChannelListFetch(pageRequest) {
  return (dispatch) => loadCustomChannelList(dispatch, pageRequest);
}
const loadCustomSearchChannelList = (dispatch, pageRequest) => {
  dispatch({
    type: CUSTOM_CHANNEL_SEARCH_LIST_FETCH,
    payload: pageRequest,
  });
  CustomChannelApi.list(pageRequest)
    .then((response) => {
      dispatch({
        type: CUSTOM_CHANNEL_SEARCH_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: CUSTOM_CHANNEL_SEARCH_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function customChannelSearchListFetch(pageRequest) {
  return (dispatch) => loadCustomSearchChannelList(dispatch, pageRequest);
}

export function customChannelChangeCurrent(emailChannel) {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_CHANNEL_CHANGE_CURRENT,
      payload: emailChannel,
    });
  };
}

export function customChannelPageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_CHANNEL_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function customChannelCreateFetch(customChannel, pageRequest) {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_CHANNEL_CREATE_FETCH,
      payload: customChannel,
    });
    CustomChannelApi.create(customChannel)
      .then((response) => {
        dispatch({
          type: CUSTOM_CHANNEL_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been created successfully');
        loadCustomChannelList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: CUSTOM_CHANNEL_CREATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function customChannelUpdateFetch(id, isEdit = true) {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_CHANNEL_UPDATE_FETCH,
    });
    const method = isEdit ? CustomChannelApi.update : CustomChannelApi.view;
    method(id)
      .then((response) => {
        dispatch({
          type: CUSTOM_CHANNEL_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: CUSTOM_CHANNEL_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function customChannelUpdateSubmitFetch(id, customChannel, pageRequest) {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH,
      payload: customChannel,
    });
    CustomChannelApi.updateSubmit(id, customChannel)
      .then((response) => {
        dispatch({
          type: CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been updated successfully');
        loadCustomChannelList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function customChannelDeleteFetch(id, pageRequest) {
  return (dispatch) => {
    dispatch({ type: CUSTOM_CHANNEL_DELETE_FETCH });
    CustomChannelApi.delete(id)
      .then((response) => {
        dispatch({
          type: CUSTOM_CHANNEL_DELETE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been deleted successfully');
        loadCustomChannelList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: CUSTOM_CHANNEL_DELETE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function customChannelToggleAddModal(isOpen) {
  return (dispatch) => {
    dispatch({
      type: CUSTOM_CHANNEL_TOGGLE_ADD_MODAL,
      payload: isOpen,
    });
  };
}
