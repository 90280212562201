import {
  managementDashboardDownloadReport,
  managementDashboardStoreCustomization,
  setCustomizationData,
} from '@Redux/slices/admin/managementDashboardSlice';
import ReportCardsSection from './reportCardsSection';
import { DownOutlined, DownloadOutlined } from '@ant-design/icons';
import PagesWrapper from '@Component/layout/PagesWrapper';
import ReportChartsSection from './reportChartsSection';
import PagesHeader from '@Component/layout/PagesHeader';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { exportElementToPdf } from '@Utils/ExportUtils';
import './style.css';

const AdminDashboard = () => {
  const [isCurrentUserUpdated, setIsCurrentUserUpdated] = useState(false);
  const currentUser = useSelector((state) => state.account.auth.currentUser);
  const currentFilters = useSelector(
    (state) => state.admin.managementDashboard.customizationData
  );

  const printContentId = 'reportPdf';
  const dispatch = useDispatch();

  const handleDownloadCSV = () => {
    const temp = {
      productId: currentFilters?.cardReportProductId,
      fromDate: currentFilters?.cardReportStartDate,
      toDate: currentFilters?.cardReportEndDate,
    };

    dispatch(
      managementDashboardDownloadReport({ body: temp, cb: getCSVExport })
    );
  };

  const getCSVExport = (path) => {
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.click();
  };

  const items = [
    {
      label: (
        <div onClick={() => exportElementToPdf(printContentId)}>
          Download PDF
        </div>
      ),
      key: '1',
    },
    {
      label: <div onClick={handleDownloadCSV}>Download CSV</div>,
      key: '2',
    },
  ];

  const menuProps = {
    items,
  };

  useEffect(() => {
    dispatch(
      setCustomizationData(currentUser?.managementDashboardCustomization ?? {})
    );
  }, [currentUser]);
  useEffect(() => {
    if (currentFilters && Object.keys(currentFilters).length) {
      dispatch(
        managementDashboardStoreCustomization({
          data: {
            ...currentFilters,
          },
        })
      );
      setIsCurrentUserUpdated(true);
    }
  }, [currentFilters]);

  return (
    <div className="cardWrapper" id={printContentId}>
      <PagesHeader
        title="Admin Dashboard"
        actions={
          <Dropdown menu={menuProps}>
            <Button
              className="printButton"
              icon={<DownloadOutlined className="buttonIcon" />}
            >
              <Space>
                Download PDF/CSV
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        }
      />
      <PagesWrapper>
        <ReportCardsSection isCurrentUserUpdated={isCurrentUserUpdated} />
        <hr />
        <ReportChartsSection isCurrentUserUpdated={isCurrentUserUpdated} />
      </PagesWrapper>
    </div>
  );
};

export default AdminDashboard;
