export const viewDataTime = [
  {
    label: 'DAYS',
    value: 'DAY',
    title: 'Day',
  },
  {
    label: 'WEEKS',
    value: 'WEEK',
    title: 'Week',
  },
  {
    label: 'MONTHS',
    value: 'MONTH',
    title: 'Month',
  },
];

export const viewDataMetric = [
  {
    label: '#',
    value: 'number',
  },
  {
    label: '%',
    value: 'percent',
  },
];
