//TODO: lazy loading
import inTrackVars from './intrack.vars.less';
import UsermostVars from './Usermost.vars.less';

function camelize(str) {
  return str.replace(/\W+(.)/g, (match, chr) => {
    return chr.toUpperCase();
  });
}

const baseVars = process.env.THEME === 'Usermost' ? UsermostVars : inTrackVars;
const vars = {};
Object.keys(baseVars).forEach((key) => {
  const camelKey = camelize(key);
  if (!(camelKey in vars)) {
    vars[camelKey] = baseVars[key];
  } else {
    vars[key] = baseVars[key];
  }
});

export default vars;
