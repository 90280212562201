import UserUpdateComponent from '../component/UserUpdateComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  userChangeCurrent,
  userUpdateFetch,
  userUpdateSubmitFetch,
  userRoleListFetch,
  userProductListFetch,
  userCreateFetch,
  userUploadPictureFetch,
  clearAdminUserErrors,
} from '../UserActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentUser: state.admin.user.currentUser,
  roles: state.admin.user.roles,
  products: state.admin.user.products,
  errors: state.admin.user.errors,
  adminUserErrors: state.admin.user.adminUserErrors,
  loadingAdminUsers: state.admin.user.loadingAdminUsers,
  userUpdateLoading: state.admin.user.userUpdateLoading,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userChangeCurrent,
      userUpdateFetch,
      userUpdateSubmitFetch,
      userCreateFetch,
      userRoleListFetch,
      userProductListFetch,
      userUploadPictureFetch,
      clearAdminUserErrors,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserUpdateComponent);

UserUpdateComponent.propTypes = {
  currentUser: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  userChangeCurrent: PropTypes.func.isRequired,
  userUpdateFetch: PropTypes.func.isRequired,
  userUpdateSubmitFetch: PropTypes.func.isRequired,
  userCreateFetch: PropTypes.func.isRequired,
  userRoleListFetch: PropTypes.func.isRequired,
  userProductListFetch: PropTypes.func.isRequired,
  userUploadPictureFetch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
