import BaseApi from '../../../api/BaseApi';

const basePath = '/sdk/restApi';
class ProductSettingApi {
  static view() {
    return BaseApi.post(basePath + '/view', {});
  }
  static revoke() {
    return BaseApi.post(basePath + '/revoke', {});
  }
  static generateToken(body) {
    return BaseApi.post(basePath + '/token', body);
  }
}

export default ProductSettingApi;
