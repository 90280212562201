import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  uninstallUsersChangeCurrent,
  uninstallUsersFetch,
} from '../UninstallActions';
import styles from './uninstallUsers.module.less';
import { Spin, Input } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { renderLowerFont } from '@Utils/RenderUtils';
import moment from 'moment';
import ListBase from '@Component/form/ListBase';
import { readableDateTime } from '../../../../utils/DateUtils';
import { Link } from 'react-router-dom';
import vars from '@Theme/styles/vars';
import { isEmpty } from 'lodash';
import { HashBase64 } from '@Component/utils/HashBase64';
import Card from '@Utils/Card';
const loadingSpin = <LoadingOutlined className={styles.spinColor} spin />;
const UninstallUsersComponent = () => {
  const loadingOverview = useSelector(
    (state) => state.analytics.uninstalls.loadingOverview
  );

  const uninstallUsersFilter = useSelector(
    (state) => state.analytics.uninstalls.uninstallUsersFilter
  );

  const uninstallUsers = useSelector(
    (state) => state.analytics.uninstalls.uninstallUsers
  );

  const pageRequest = useSelector(
    (state) => state.analytics.uninstalls.uninstallUsersFilter
  );

  const page = useSelector(
    (state) => state.analytics.uninstalls.uninstallUsers
  );

  const [name, setName] = useState('');

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(uninstallUsersFetch(uninstallUsersFilter));
  }, [uninstallUsersFilter]);

  useEffect(() => {
    const lastWeek = moment().subtract(7, 'days');
    return () => {
      dispatch(
        uninstallUsersChangeCurrent({
          fromDate: lastWeek,
          toDate: moment(),
          page: 0,
          pageSize: 10,
          query: '',
          ...pageRequest,
        })
      );
    };
  }, []);
  const handleChangeUninstallUsers = (e) => {
    if (e.type === 'click' && !e.target.value) {
      dispatch(
        uninstallUsersChangeCurrent({
          ...pageRequest,
          page: 0,
          query: null,
        })
      );
    }
    setName(e.target.value);
  };

  const renderTable = () => {
    const dataSource = uninstallUsers?.content;
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    const getItemLink = (object) => {
      const hashedUserId = HashBase64().encode(object.id);
      return `../users/view/${hashedUserId}/basic`;
    };

    const columns = [
      {
        title: 'USER ID',
        dataIndex: 'id',
        key: 'id',
        render: (text, object) => (
          <Link to={getItemLink(object)}>
            <div>
              <span
                style={{
                  color: vars.primaryColor,
                  fontSize: 14,
                  cursor: 'pointer',
                }}
              >
                {text.substring(1)}
              </span>
            </div>
          </Link>
        ),
      },

      {
        title: 'DEVICE',
        dataIndex: 'device',
        key: 'device',
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'FIRST INSTALL DATE',
        dataIndex: 'firstInstallDate',
        key: 'firstInstallDate',
        render: (text) =>
          isEmpty(text) ? (
            <span>Not in last 3 months</span>
          ) : (
            <span className={styles.tableValue}>
              {renderLowerFont(readableDateTime(text))}
            </span>
          ),
      },
      {
        title: 'LAST UNINSTALL DATE',
        dataIndex: 'lastUninstallDate',
        key: 'lastUninstallDate',
        render: (text) => (
          <span className={styles.tableValue}>
            {renderLowerFont(readableDateTime(text))}
          </span>
        ),
      },
      {
        title: 'COUNTRY',
        dataIndex: 'country',
        key: 'country',
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'CITY',
        dataIndex: 'city',
        key: 'city',
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'OS NAME',
        dataIndex: 'os',
        key: 'os',
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'OS VERSION',
        dataIndex: 'osVersion',
        key: 'osVersion',
        render: (text) => <span>{text}</span>,
      },
    ];
    return (
      <Card>
        <div style={{ borderBottom: 'solid 2px #f4f9ff' }}>
          <Input
            loading={true}
            placeholder={'Search Users by ID'}
            allowClear={loadingOverview ? false : true}
            style={{
              width: 300,
              marginLeft: 10,
              borderRadius: 4,
              marginBottom: 12,
              marginTop: 12,
            }}
            value={name}
            onPressEnter={() =>
              dispatch(
                uninstallUsersChangeCurrent({
                  ...pageRequest,
                  page: 0,
                  query: name,
                })
              )
            }
            onChange={handleChangeUninstallUsers}
            suffix={
              isEmpty(name) ? (
                <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              ) : (
                loadingOverview && (
                  <div>
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                )
              )
            }
          />
        </div>
        {loadingOverview ? (
          <div className={styles.spin}>
            {' '}
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <ListBase
            bordered
            pagination={true}
            dataSource={dataSource}
            columns={columns}
            page={page}
            pageRequest={pageRequest}
            onChange={(p) => {
              dispatch(
                uninstallUsersChangeCurrent({
                  ...p,
                })
              );
            }}
          ></ListBase>
        )}
      </Card>
    );
  };

  return <div className={styles.layout}>{renderTable()}</div>;
};
export default UninstallUsersComponent;
