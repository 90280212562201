import React from 'react';
import { Input, Select, Spin } from 'antd';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import ListBase, { defaultActions } from '@Component/form/ListBase';
import AttributeType from '../model/AttributeType';
import { LoadingOutlined } from '@ant-design/icons';
import Card from '@Utils/Card';
const spinStyles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};
class SystemAttributeListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      piiModalId: null,
      type: null,
      name: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingUpdateSubmit && !this.props.loadingUpdateSubmit) {
      this.setState({ piiModalId: null });
    }
  }

  componentDidMount() {
    this.props.systemAttributeListFetch(this.props.pageRequest);
  }

  componentWillUnmount() {
    this.props.systemAttributePageChangeCurrent({});
  }

  renderPII = (x) => {
    if (x === undefined) {
      return '--';
    }
    return x ? 'Is PII' : 'Not PII';
  };

  handleChangeSearch = (name) => {
    this.setState({ name });
    if (name === '' || name === null) {
      this.props.systemAttributeListFetch({
        ...this.props.pageRequest,
        page: 0,
        name: null,
      });
    }
  };

  handleChangeType = (type) => {
    this.setState({ type });
    this.props.systemAttributeListFetch({
      ...this.props.pageRequest,
      page: 0,
      type,
    });
  };

  openPiiModal = (x) => {
    if (!x.pii) {
      this.props.systemAttributeUpdateFetch(x.id);
      this.setState({ piiModalId: x.id });
    }
  };

  render() {
    const columnList = [
      {
        sorter: false,
        title: 'title',
        dataIndex: 'title',
        key: 'title',
        // ...getColumnTextSearchProps('title'),
      },
      {
        sorter: false,
        title: 'name',
        dataIndex: 'name',
        key: 'name',
        // ...getColumnTextSearchProps('name'),
      },
      {
        sorter: false,
        title: 'Is PII?',
        dataIndex: 'pii',
        key: 'pii',
        render: (x) => this.renderPII(x),
      },
      {
        sorter: false,
        title: 'data type',
        dataIndex: 'type',
        key: 'type',
        render: (x) => AttributeType[x],
        // ...getColumnEnumSearchProps('type', AttributeType),
      },
      {
        title: 'action',
        dataIndex: '',
        key: '-action-',
        width: '5%',
        render: (x) =>
          defaultActions(x, {
            action: () => this.openPiiModal(x),
            title: x.pii ? 'Mark as Not PII' : 'Mark as PII',
            enabled: true,
            disablePopConfirm: true,
          }),
      },
    ];

    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    return (
      <Card>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '12px',
            paddingBottom: 0,
            paddingLeft: '20px',
          }}
        >
          <div style={{ color: '#000000', fontSize: 16, fontWeight: '600' }}>
            System Attributes
            <span style={{ paddingLeft: 10 }}>
              {false && (
                <InfoCircleOutlined
                  style={{ color: '#8C8C8C', fontSize: 12 }}
                />
              )}
            </span>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '12px',
            borderBottom: 'solid 2px #f4f9ff',
          }}
        >
          <Input
            loading={true}
            allowClear={this.props.loadingSearchList ? false : true}
            placeholder={'Search by name'}
            // eslint-disable-next-line max-len
            onPressEnter={() =>
              this.props.systemAttributeSearchListFetch({
                ...this.props.pageRequest,
                page: 0,
                name: this.state.name,
              })
            }
            onChange={(e) => this.handleChangeSearch(e.target.value)}
            value={this.state.name}
            style={{ width: 300, marginRight: 30, borderRadius: 5 }}
            suffix={
              isEmpty(this.state.name) ? (
                <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              ) : (
                this.props.loadingSearchList && (
                  <div style={spinStyles.inputLoadingSpinStyle}>
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                )
              )
            }
          />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Select
              onChange={(searchStatus) => this.handleChangeType(searchStatus)}
              value={this.state.type}
              defaultValue="all"
              style={{ width: 120 }}
              className={'chart-selector'}
              bordered={false}
            >
              <Select.Option value={null}>All Types</Select.Option>
              <Select.Option value="BOOLEAN">Boolean</Select.Option>
              <Select.Option value="NUMERIC">Numeric</Select.Option>
              <Select.Option value="STRING">String</Select.Option>
              <Select.Option value="DATE">Date</Select.Option>
              <Select.Option value="ARRAY">Array</Select.Option>
              <Select.Option value="OBJECT">Object</Select.Option>
            </Select>
          </div>
        </div>
        {this.props.loadingList ? (
          <div style={spinStyles.loadingSpinStyle}>
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <ListBase
            onChange={this.props.systemAttributeListFetch}
            page={this.props.page}
            pageRequest={this.props.pageRequest}
            columns={columnList}
            // onClick={(row) => this.viewItem(row.id)}
            createLink={null}
          />
        )}
      </Card>
    );
  }
}

export default SystemAttributeListComponent;
