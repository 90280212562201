/* eslint-disable no-nested-ternary */
import React from 'react';
import NumberRowItem from './NumberRowItem';
import '../../style/ProductSdkAndroidStyle.less';
import texts from '../../../../../utils/texts';
const InitializeFragment = (props) => {
  const { managedByCustomer, webPushKeyType } = props;
  const initializeItemsAndroid = [
    {
      number: 1,
      title: '  Your Android SDK Credentials:',
      highlightContent:
        '       app_key:  ' +
        props.appKey +
        '\n' +
        '       auth_key: ' +
        props.authKey,
    },
    {
      number: 1,
      title: 'Adding the SDK to the project:',
      url: `${texts.SDK}`,
    },
    {
      number: 2,
      title: 'Initialization:',
      url: `${texts.Initialization}`,
      highlightContent: `\n ${texts.BrandName}.init(new InTrackConfig().setApplication(this).setAppKey("${props.appKey}").setAuthKey("${props.authKey}")); \n
`,
    },
    {
      number: 3,
      title: 'Proguard Rule​: ',
      highlightContent: '      -keep class com.google.android.gms.ads.**{*;}',
    },
  ];
  const initializeItemsIOS = [
    {
      number: 1,
      title: '  Your IOS SDK Credentials:',
      highlightContent:
        '       app_key:  ' +
        props.appKey +
        '\n' +
        '       auth_key: ' +
        props.authKey,
    },
    {
      number: 2,
      title: 'Adding the SDK to the project:',
      urlDescription: `you can add ${texts.BrandName} SDK to your project in two way.`,
      url: `${texts.iosAdding}`,
    },
    {
      number: 3,
      title: 'SDK Integration:',
      url: `${texts.iosInitialization}`,
      urlDescription: `To integrate the ${texts.BrandName} iOS SDK into your application, please follow these steps:`,
      highlightContent:
        '//Swift \n' +
        '   func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplicationLaunchOptionsKey: Any]?) -> Bool \n' +
        '     { \n' +
        '         let config: InTrackConfig = InTrackConfig() \n' +
        '         config.appKey =  ' +
        props.appKey +
        '\n' +
        '         config.authKey = ' +
        props.authKey +
        '\n' +
        '         InTrack.initWith(config); \n ' +
        '         // your code \n' +
        '         return true \n' +
        '     } \n' +
        ' \n' +
        'or \n' +
        ' \n' +
        '//Objective-C \n' +
        '     - (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions \n' +
        '       { \n' +
        '           InTrackConfig* config = InTrackConfig.new; \n' +
        '           config.appKey =  ' +
        props.appKey +
        '\n' +
        '           config.authKey = ' +
        props.authKey +
        '\n' +
        '           [InTrack startWithConfig:config]; \n ' +
        '           // your code \n' +
        '           return YES \n' +
        '       } \n',
    },
  ];
  const initializeItemsFlutter = [
    {
      number: 1,
      title: 'Adding Dependency:',
      url: `${texts.addingFlutterDependency}`,
      urlDescription: 'Use the following command',
    },
    {
      number: 2,
      title: ' Add Proguard Role:',
      highlightContent:
        '       -keep class com.google.android.gms.ads.**{*;}\n',
    },
    {
      number: 3,
      title: ' Import Library:',
      highlightContent: `import 'package:intrack_flutter/intrack_flutter.dart'; 
import 'package:intrack_flutter/intrack_config.dart';`,
    },
    {
      number: 4,
      title:
        '  Initialize in Main App Component ComponentDidMount With These Credential:',
      highlightContent:
        '       APP_KEY:  ' +
        props.appKey +
        '\n' +
        '       ANDROID_AUTH_KEY: ' +
        props.authKey +
        '\n' +
        '       IOS_AUTH_KEY: ' +
        props.iosAuthKey,
    },
    {
      number: 5,
      title: 'For Example: ',
      highlightContent: `InTrack.isInitialized().then((bool isInitialized) {
    if (!isInitialized) {
      InTrackConfig config =
          InTrackConfig("${props.appKey}")
            ..setAndroidAuthKey("${props.authKey}")
            ..setIOSAuthKey("${props.iosAuthKey}")
      InTrack.initWithConfig(config).then((value) {
        print('inTrack $value');
        //you can enable push notification here
      });
    }
}); 
`,
    },
  ];
  const initializeItemsReactNative = [
    {
      number: 1,
      title: 'Adding Dependency:',
      url: `${texts.addingDependency}`,
      urlDescription: 'Use the following command',
    },
    {
      number: 2,
      title: ' Add Proguard Role:',
      highlightContent:
        '       -keep class com.google.android.gms.ads.**{*;}\n',
    },
    {
      number: 3,
      title: ' Import Library:',
      highlightContent: `import ${texts.BrandName} from \`${texts.brandname}-react-native-bridge\`;`,
    },
    {
      number: 4,
      title:
        '  Initialize in Main App Component ComponentDidMount With These Credential:',
      highlightContent:
        '       APP_KEY:  ' +
        props.appKey +
        '\n' +
        '       AUTH_KEY: ' +
        props.authKey,
    },
    {
      number: 5,
      title: 'For Example: ',
      highlightContent:
        `if(!(await ${texts.BrandName}.isInitialized())) { \n` +
        ' const options = { \n' +
        '   appKey:"' +
        props.appKey +
        '", \n' +
        '   iosAuthKey: "' +
        props.iosAuthKey +
        '", \n' +
        '   androidAuthKey: "' +
        props.authKey +
        '", \n' +
        ' }; \n' +
        `await ${texts.BrandName}.init(options);\n` +
        `${texts.BrandName}.start(); \n` +
        '}\n',
    },
  ];
  const initializeItemsWeb = [
    {
      number: 1,
      title: '  Your Web SDK Credentials:',
      highlightContent:
        '       app_key:  ' +
        props.appKey +
        '\n' +
        '       auth_key: ' +
        props.authKey +
        '\n' +
        `       ${
          managedByCustomer && props.webPushKeyType === 'FIREBASE'
            ? ''
            : `public_key: ${props.publicKey}`
        }`,
    },
    {
      number: 2,
      title:
        'Initialize Web SDK with Provided Credentials and Putting the Following Code in the Bottom of <head> Tag:',
      url: `${texts.webSdk}`,
    },

    ...(managedByCustomer && webPushKeyType === 'BROWSER'
      ? [
          {
            number: 3,
            title:
              ' Add Service Worker and Manifest By Downloading These Files and Putting Them in the Root of of the HTTPS Domain (public_html or var/www/html or a Similar Folder). You feel free to have your own Service Worker, If its name is the exact name as sw.js (our Service Worker) you must import its codes on top of your codes.',
            highlightContent: `${texts.sw}\n` + `${texts.manifest}\n`,
          },
        ]
      : managedByCustomer && webPushKeyType === 'FIREBASE'
      ? [
          {
            number: 3,
            title:
              ' Add Manifest By Downloading This File and Putting it in the Root of of the HTTPS Domain (public_html or var/www/html or a Similar Folder)',
            highlightContent: `${texts.manifest}\n`,
          },
        ]
      : [
          {
            number: 3,
            title:
              ' Add Service Worker and Manifest By Downloading These Files and Putting Them in the Root of of the HTTPS Domain (public_html or var/www/html or a Similar Folder)',
            highlightContent: `${texts.sw}\n` + `${texts.manifest}\n`,
          },
        ]),
  ];
  let items =
    props.sdkType === 'flutter'
      ? initializeItemsFlutter
      : props.sdkType === 'react-native'
      ? initializeItemsReactNative
      : props.sdkType === 'android'
      ? initializeItemsAndroid
      : props.sdkType === 'ios'
      ? initializeItemsIOS
      : initializeItemsWeb;
  return items.map((item, index) => {
    return (
      <NumberRowItem
        key={index + props.sdkType}
        number={item.number}
        title={item.title}
        highlightContent={item.highlightContent}
        url={item.url}
        urlDescription={item.urlDescription}
      />
    );
  });
};
export default InitializeFragment;
