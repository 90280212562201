import BaseApi from '../../../api/BaseApi';

const basePath = '/alert';

export default class AlertApi {
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static create(body) {
    return BaseApi.post(basePath + '/create', body);
  }
  static view(id) {
    return BaseApi.post(basePath + `/get/${id}`);
  }
  static update(id, body) {
    return BaseApi.post(basePath + `/update/${id}`, body);
  }
  static delete(id) {
    return BaseApi.post(basePath + `/delete/${id}`);
  }
}
