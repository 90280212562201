/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import JourneyTools from '../../../../component/journey';
import SendSmsComponent from './virtualSteps/SendSmsStep';
import SendEmailComponent from './virtualSteps/SendEmailStep';
import SendPushComponent from './virtualSteps/SendPushStep';
import SendWebPushComponent from './virtualSteps/SendWebPushStep';
import SendCustomComponent from './virtualSteps/SendCustomStep';
import SendInAppComponent from './virtualSteps/SendInAppStep';
import SendOnSiteComponent from './virtualSteps/SendOnSiteStep';
import { getJourneyId } from '@Utils/AuthorityProvider';
import SendWhatsAppStep from './virtualSteps/SendWhatsAppStep';

const JourneyModalContent = (type) => () => {
  return {
    SEND_SMS: (
      node,
      updateJourney,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendSmsComponent
        dataNode={node.dataNode}
        updateJourney={updateJourney}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
    SEND_EMAIL: (
      node,
      updateJourney,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendEmailComponent
        dataNode={node.dataNode}
        updateJourney={updateJourney}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
    SEND_PUSH: (
      node,
      updateJourney,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendPushComponent
        dataNode={node.dataNode}
        updateJourney={updateJourney}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
    SEND_WEB_PUSH: (
      node,
      syncCache,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendWebPushComponent
        dataNode={node.dataNode}
        updateJourney={syncCache}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
    SEND_WHATSAPP: (
      node,
      syncCache,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendWhatsAppStep
        dataNode={node.dataNode}
        updateJourney={syncCache}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
    SEND_CUSTOM: (
      node,
      syncCache,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendCustomComponent
        dataNode={node.dataNode}
        updateJourney={syncCache}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
    SEND_IN_APP: (
      node,
      syncCache,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendInAppComponent
        dataNode={node.dataNode}
        updateJourney={syncCache}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
    SEND_ON_SITE: (
      node,
      syncCache,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendOnSiteComponent
        dataNode={node.dataNode}
        updateJourney={syncCache}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
  };
};

const JourneyReportComponent = (props) => {
  const {
    journeyUpdate,
    journeyUpdateSubmit,
    journeyLaunch,
    journeyPause,
    journeyStop,
    journeyClearLaunchData,
    clearModalData,
  } = props;

  const journeyId = getJourneyId(props.location.pathname);
  // const mode = 'report' ;
  const mode = props.match.params.type || 'report';
  const productId = props.match.params.productId;

  useEffect(() => {
    props.journeyListSegmentsFetch({
      productId,
    });
    props.journeyListEventsFetch({
      productId,
    });
    props.journeyListBusinessEventsFetch({
      productId,
    });
    props.journeyListAttribtesFetch({
      productId,
    });
    props.journeyGetReportOverview(journeyId, {
      productId,
    });
    props.journeyBusinessEventsReportFetch(journeyId, { stepId: 1 });
  }, []);

  useEffect(() => {
    if (props.launchData || props.stopData || props.pauseData) {
      journeyUpdate(journeyId, mode);
      // this.props.journeyClearLaunchData();
      journeyClearLaunchData();
    }
  }, [props.launchData, props.stopData, props.pauseData]);

  const loading =
    props.loadingUpdateJourney ||
    props.loadingStop ||
    props.loadingPause ||
    props.loadingLaunch ||
    false;

  return (
    <div className="report-mode">
      <JourneyTools
        type={props.type}
        data={{
          journeyId,
          productId,
          journey: props.successUpdateJourney,
          newJourney: props.successCreateJourney,
          segments: props.successGetSegments,
          events: props.successGetEvents,
          businessEvents: props.successGetBusinessEvents,
          attributes: props.attributes,
          businessEventsReport: props.successGetBusinessEventsReport,
          journeyBusinessEventsReportFetch:
            props.journeyBusinessEventsReportFetch,
          loadingBusinessEventsReport: props.loadingBusinessEventsReport,
          reportOverview: props.overviewData,
          segmentReport: {
            data: props.segmentReportView || {},
            loading: props.loadingSegmentReportView || false,
            error: props.errorSegmentReportView || null,
          },
          mode,
          fetchRecommendEventAttributes:
            props.productSegmentRecommendEventAttributesFetch,
          recommendEventAttributes: props.recommendEventAttributes,
          fetchRecommendDeviceAttributes:
            props.productSegmentRecommendDeviceAttributesFetch,
          recommendDeviceAttributes: props.recommendDeviceAttributes,
          fetchRecommendUserAttributes:
            props.productSegmentRecommendUserAttributesFetch,
          recommendUserAttributes: props.recommendUserAttributes,
        }}
        modals={JourneyModalContent(props.type)}
        clearModalData={clearModalData}
        update={journeyUpdate}
        launch={journeyLaunch}
        pause={journeyPause}
        stop={journeyStop}
        updateSubmit={journeyUpdateSubmit}
        getSegment={props.productSegmentReportViewFetch}
        history={props.history}
        loading={loading}
      />
    </div>
  );
};

export default JourneyReportComponent;
