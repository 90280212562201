import React, { useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { rejectInvoice } from '@Redux/slices/setting/invoiceSlice';
import { useDispatch } from 'react-redux';
import style from '@Modules/setting/billing/components/payment/UploadReceiptModalContent.module.less';

const UploadReceiptModal = ({ isOpen, onClose, selectedInvoiceId }) => {
  const dispatch = useDispatch();

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [rejectReason, setRejectReason] = useState('');

  const handleCloseModal = (success) => {
    onClose(success);
    setRejectReason('');
  };

  const handleRejectInvoice = () => {
    dispatch(
      rejectInvoice(selectedInvoiceId, { rejectReason: rejectReason }, () => {
        handleCloseModal(true);
      })
    );
  };

  return (
    <Modal
      width={650}
      title={'Reject Invoice'}
      centered
      open={isOpen}
      onOk={() => {
        onClose(false);
      }}
      onCancel={() => {
        onClose(false);
      }}
      footer={[
        <Button
          key="cancel"
          shape="round"
          onClick={() => {
            handleCloseModal(false);
          }}
        >
          Discard
        </Button>,
        <Button
          key="submit"
          shape="round"
          type="primary"
          onClick={handleRejectInvoice}
          disabled={!submitEnabled}
        >
          Submit
        </Button>,
      ]}
    >
      <Form>
        <Form.Item name="reason">
          <div className={style.inputLabel}>Reject Reason</div>
          <Input.TextArea
            name="nameSpace"
            value={rejectReason}
            onChange={(e) => {
              let value = e.target.value;
              setRejectReason(value);
              setSubmitEnabled(value.trim().length > 0);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UploadReceiptModal;
