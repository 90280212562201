import * as React from 'react';

function SvgCode(props) {
  return (
    <svg viewBox="0 0 18 25" width="1em" height="1em" {...props}>
      <path
        d="M1.552 24.314c-1.311-1.312-.187-3.565-.187-3.565l8.442-7.692L1.741 4.99s-1.312-1.875.375-3.56C3.805-.26 5.68 1.238 5.68 1.238l8.066 8.068 3.754 3.752-3.94 3.753-8.444 7.69c0 .188-2.25 1.125-3.564-.186"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgCode;
