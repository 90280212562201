import React, { useState, useEffect } from 'react';
import Card from '@Utils/Card';
import { Input, Spin } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import { WhatsAppProvider } from '../model/WhatsAppConst';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
  whatsAppChannelData,
  fetchWhatsAppChannelList,
  deleteWhatsAppChannelProvider,
} from '@Redux/slices/channel/whatsAppChannelSlice';
import { checkPermissions } from '../model/WhatsAppConst';
import WhatsAppProviderModal from './WhatsAppProviderModal';

const WhatsAppProvidersList = () => {
  const dispatch = useDispatch();
  const permissions = checkPermissions();

  const columnList = [
    {
      sorter: false,
      title: 'service provider',
      dataIndex: 'vendor',
      key: 'vendor',
      width: 300,
      render: (x) => WhatsAppProvider[x],
    },
    {
      sorter: false,
      title: 'configuration name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
    },
    {
      title: 'action',
      dataIndex: '',
      key: '-action-',
      width: '5%',
      render: (x) =>
        defaultActions(
          x,
          {
            action: () => openEditModal(x.id),
            title: permissions.createOrUpdate ? 'Edit' : 'View',
            enabled: permissions.view,
            disablePopConfirm: true,
          },
          {
            action: () => handleDeleteProvider(x.id),
            title: 'Delete',
            enabled: permissions.delete,
          }
        ),
    },
  ];

  const [searchInput, setSearchInput] = useState('');
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { loadingList, loadingSearchList, page, pageRequest } = useSelector(
    whatsAppChannelData,
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchWhatsAppChannelList({}));
  }, []);

  const handleSearchFieldChange = (event) => {
    setSearchInput(event.target.value);
    if (event.target.value === '' || event.target.value === null) {
      dispatch(
        fetchWhatsAppChannelList({
          ...pageRequest,
          page: 0,
          name: null,
        })
      );
    }
  };

  const handleSearchProvider = () => {
    dispatch(
      fetchWhatsAppChannelList({
        ...pageRequest,
        page: 0,
        name: searchInput,
      })
    );
  };

  const openEditModal = (id) => {
    setIsModalOpen(true);
    setSelectedProviderId(id);
  };

  const handleListChange = (e) => {
    dispatch(
      fetchWhatsAppChannelList({
        ...pageRequest,
        page: e.page,
      })
    );
  };

  const handleDeleteProvider = (id) => {
    dispatch(deleteWhatsAppChannelProvider(id));
  };

  return (
    <>
      <Card title="Your WhatsApp Service Provider (WSP) List">
        <div style={styles.headerContainer}>
          <Input
            loading={true}
            allowClear={!loadingSearchList}
            style={styles.searchInput}
            placeholder={'Search by name'}
            onPressEnter={handleSearchProvider}
            onChange={handleSearchFieldChange}
            value={searchInput}
            suffix={
              isEmpty(searchInput) ? (
                <SearchOutlined style={styles.searchIconStyle} />
              ) : (
                loadingSearchList && (
                  <div style={styles.inputLoadingSpinStyle}>
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                )
              )
            }
          />
        </div>
        {loadingList ? (
          <div style={styles.loadingSpinStyle}>
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <ListBase
            onChange={handleListChange}
            page={page}
            pageRequest={pageRequest}
            columns={columnList}
            // onClick={() => {}}
            createLink={null}
          />
        )}
      </Card>
      {isModalOpen && (
        <WhatsAppProviderModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          providerId={selectedProviderId}
        />
      )}
    </>
  );
};

export default WhatsAppProvidersList;

const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const styles = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px',
  },
  searchInput: {
    width: 300,
    borderRadius: 5,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  searchIconStyle: {
    color: 'rgba(0,0,0,.45)',
  },
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
};
