import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { checkTemplatePermissions } from '../model/WhatsAppConst';
import WhatsAppTemplateModal from './WhatsAppTemplateModal';
import React, { useState, useEffect } from 'react';
import vars from '@Theme/styles/vars';
import { Input, Spin } from 'antd';
import { isEmpty } from 'lodash';
import Card from '@Utils/Card';
import {
  whatsAppChannelData,
  fetchWhatsAppChannelTemplateList,
  deleteWhatsAppChannelTemplate,
} from '@Redux/slices/channel/whatsAppChannelSlice';

const WhatsAppTemplatesList = () => {
  const dispatch = useDispatch();

  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const {
    whatsAppTemplateLoading,
    whatsAppTemplatePage,
    whatsAppTemplatePageRequest,
  } = useSelector(whatsAppChannelData, shallowEqual);

  const templatePermissions = checkTemplatePermissions();

  useEffect(() => {
    dispatch(fetchWhatsAppChannelTemplateList({}));
  }, []);

  const cardHeader = (
    <div style={styles.cardTitleContainer}>
      <div>WhatsApp Templates</div>
      <i
        onClick={() => openEditModal()}
        className="fl-add-circle"
        style={styles.addIcon}
      />
    </div>
  );

  const columnList = [
    {
      sorter: false,
      title: 'TEMPLATE NAME',
      dataIndex: 'name',
      key: 'name',
      width: 300,
    },
    {
      sorter: false,
      title: 'TEMPLATE TEXT',
      dataIndex: 'bodyText',
      key: 'bodyText',
      width: 500,
    },
    {
      sorter: false,
      title: 'TEMPLATE TYPE',
      dataIndex: 'headerType',
      key: 'headerType',
      width: 300,
    },
    {
      sorter: false,
      title: 'WSP',
      key: 'whatsAppChannel',
      dataIndex: ['whatsAppChannel', 'name'],
      width: 200,
    },

    {
      title: 'ACTION',
      dataIndex: '',
      key: '-action-',
      width: '5%',
      render: (x) =>
        defaultActions(
          x,
          {
            action: () => openEditModal(x.id),
            //if it was necessary to update later //
            // title: templatePermissions.createOrUpdate ? 'Edit' : 'View',
            title: 'View',
            enabled: templatePermissions.view,
            disablePopConfirm: true,
          },
          {
            action: () => handleDeleteTemplate(x.id),
            title: 'Delete',
            enabled: templatePermissions.delete,
          }
        ),
    },
  ];

  const handleSearchFieldChange = (event) => {
    setSearchInput(event.target.value);
    if (event.target.value === '' || event.target.value === null) {
      dispatch(
        fetchWhatsAppChannelTemplateList({
          ...whatsAppTemplatePageRequest,
          page: 0,
          name: null,
        })
      );
    }
  };

  const openEditModal = (id) => {
    setIsModalOpen(true);
    setSelectedTemplateId(id);
  };

  const handleListChange = (e) => {
    dispatch(
      fetchWhatsAppChannelTemplateList({
        ...whatsAppTemplatePageRequest,
        page: e.page,
      })
    );
  };

  const handleSearchTemplate = () => {
    dispatch(
      fetchWhatsAppChannelTemplateList({
        ...whatsAppTemplatePageRequest,
        page: 0,
        name: searchInput,
      })
    );
  };

  const handleDeleteTemplate = (id) => {
    dispatch(deleteWhatsAppChannelTemplate(id));
  };

  return (
    <>
      <Card title={cardHeader}>
        <div style={styles.headerContainer}>
          <Input
            loading={true}
            allowClear={!whatsAppTemplateLoading}
            style={styles.searchInput}
            placeholder={'Search by name'}
            onPressEnter={handleSearchTemplate}
            onChange={handleSearchFieldChange}
            value={searchInput}
            suffix={
              isEmpty(searchInput) ? (
                <SearchOutlined style={styles.searchIconStyle} />
              ) : (
                whatsAppTemplateLoading && (
                  <div style={styles.inputLoadingSpinStyle}>
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                )
              )
            }
          />
        </div>
        {whatsAppTemplateLoading ? (
          <div style={styles.loadingSpinStyle}>
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <ListBase
            onChange={handleListChange}
            page={whatsAppTemplatePage}
            pageRequest={whatsAppTemplatePageRequest}
            columns={columnList}
            // onClick={() => {}}
            createLink={null}
          />
        )}
      </Card>
      {isModalOpen && (
        <WhatsAppTemplateModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          templateId={selectedTemplateId}
        />
      )}
    </>
  );
};

export default WhatsAppTemplatesList;

const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const styles = {
  cardTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addIcon: {
    fontSize: '32px',
    color: vars.primaryColor,
    marginLeft: 10,
    cursor: 'pointer',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '16px',
  },
  searchInput: {
    width: 300,
    borderRadius: 5,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  searchIconStyle: {
    color: 'rgba(0,0,0,.45)',
  },
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
};
