import {
  managementDashboardReportFetch,
  setCustomizationData,
} from '@Redux/slices/admin/managementDashboardSlice';
import ReportCardsSectionHeader from './ReportCardsSectionHeader';
import { cardReportEnums } from './dashboardConstants';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import ReportCard from './ReportCard';
import { Col, Row } from 'antd';
import moment from 'moment';

const ReportCardsSection = ({ isCurrentUserUpdated }) => {
  const dispatch = useDispatch();

  /////////////////////////////Global States/////////////////////////////////
  const products = useSelector(
    (state) => state.account.auth.currentUser.products
  );
  const currentFilters = useSelector(
    (state) => state.admin.managementDashboard.customizationData
  );
  const currentUser = useSelector((state) => state.account.auth.currentUser);

  /////////////////////////////FilterBox States//////////////////////////////
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months'));
  const [productName, setProductName] = useState('ALL');
  const [productList, setProductList] = useState([]);
  const [endDate, setEndDate] = useState(moment());

  const [channelReport, setChannelReport] = useState(null);
  const [typeReport, setTypeReport] = useState(null);
  const [selectedCards, setSelectedCards] = useState({});
  const [totalReport, setTotalReport] = useState(null);
  const [reportsEnum, setReportsEnum] = useState([]);

  useEffect(() => {
    if (products?.length) {
      setProductList([{ id: 'ALL', label: 'ALL' }, ...products]);
    }
  }, [products]);

  useEffect(() => {
    if (
      productName &&
      startDate &&
      endDate &&
      selectedCards &&
      reportsEnum.length &&
      currentFilters
    ) {
      const tempStartDate = startDate.utc().format('YYYY-MM-DD') + 'T00:00:00Z';
      const tempEndDate = endDate.utc().format('YYYY-MM-DD') + 'T23:59:59Z';

      dispatch(
        managementDashboardReportFetch(
          {
            fromDate: tempStartDate,
            granularity: 'TOTAL',
            productId: productName !== 'ALL' ? productName : null,
            reports: reportsEnum,
            toDate: tempEndDate,
          },
          setTotalReport
        )
      );
      dispatch(
        managementDashboardReportFetch(
          {
            fromDate: tempStartDate,
            granularity: 'TOTAL',
            productId: productName !== 'ALL' ? productName : null,
            reports: reportsEnum,
            splitBy: 'CHANNEL',
            toDate: tempEndDate,
          },
          setChannelReport
        )
      );

      dispatch(
        managementDashboardReportFetch(
          {
            fromDate: tempStartDate,
            granularity: 'TOTAL',
            productId: productName !== 'ALL' ? productName : null,
            reports: reportsEnum,
            splitBy: 'TYPE',
            toDate: tempEndDate,
          },
          setTypeReport
        )
      );

      dispatch(
        setCustomizationData({
          ...currentFilters,
          cardReportStartDate: tempStartDate,
          cardReportEndDate: tempEndDate,
          cardReportProductId: productName !== 'ALL' ? productName : null,
          cardReportSelectedMetrics: reportsEnum.length ? reportsEnum : null,
          cardReportSelectedItemsInCards: selectedCards,
        })
      );
    }
  }, [productName, startDate, endDate, selectedCards]);

  useEffect(() => {
    if (currentFilters && isCurrentUserUpdated) {
      setProductName(
        currentUser?.managementDashboardCustomization?.cardReportProductId ??
          'ALL'
      );
      setStartDate(
        currentUser?.managementDashboardCustomization?.cardReportStartDate
          ? moment(
              currentUser?.managementDashboardCustomization?.cardReportStartDate
            )
          : moment().subtract(1, 'months')
      );
      setEndDate(
        currentUser?.managementDashboardCustomization?.cardReportEndDate
          ? moment(
              currentUser?.managementDashboardCustomization?.cardReportEndDate
            )
          : moment()
      );
      setReportsEnum(
        currentUser?.managementDashboardCustomization
          ?.cardReportSelectedMetrics ?? [
          ...cardReportEnums['EVENTS_AND_PROFILES'],
          ...cardReportEnums['LUNCHED_CAMPAIGN'],
        ]
      );
      setSelectedCards(
        currentUser?.managementDashboardCustomization
          ?.cardReportSelectedItemsInCards ?? {
          EVENTS_AND_PROFILES: [
            {
              label: 'System Events',
              key: 'SYSTEM_EVENTS',
              type: '',
            },
            {
              label: 'Custom Events',
              key: 'CUSTOM_EVENTS',
              type: '',
            },
          ],
          LUNCHED_CAMPAIGN: [
            {
              label: 'Launched Campaigns',
              key: 'ENDED_CAMPAIGNS',
              type: 'TOTAL',
            },
            {
              label: 'Launched SMS Campaigns',
              key: 'ENDED_CAMPAIGNS_SMS',
              type: 'SMS',
            },
          ],
        }
      );
    }
  }, [currentUser, isCurrentUserUpdated]);

  return (
    <>
      <ReportCardsSectionHeader
        productList={productList}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        productName={productName}
        setProductName={setProductName}
        selectedCards={selectedCards}
        setSelectedCards={setSelectedCards}
        reportsEnum={reportsEnum}
        setReportsEnum={setReportsEnum}
      />
      <Row gutter={[16, 16]}>
        {Object.keys(selectedCards)?.map((card, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={8}>
            <ReportCard
              cardTitle={card}
              metrics={selectedCards[card]}
              selectedCards={selectedCards}
              setSelectedCards={setSelectedCards}
              totalReport={totalReport}
              channelReport={channelReport}
              typeReport={typeReport}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ReportCardsSection;
