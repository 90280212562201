import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';
import { Button } from 'antd';
import { AuthorityProvider, hasAnyAuthority } from '@Utils/AuthorityProvider';
import React from 'react';
import PagesHeader from '@Component/layout/PagesHeader';
import PagesWrapper from '@Component/layout/PagesWrapper';
import ThirdPartyList from '@Modules/admin/thirdParty/components/ThirdPartyList';
import {
  PAGE_LOCATION,
  thirdPartyEnums,
} from '@Modules/admin/thirdParty/components/ThirdPartyTypes';
import ThirdPartyView from '@Modules/admin/thirdParty/components/ThirdPartyView';

const ThirdParty = () => {
  let match = useRouteMatch();
  let location = useLocation();
  const history = useHistory();
  const pageLocation = () => {
    if (location.pathname.includes(PAGE_LOCATION.VIEW))
      return PAGE_LOCATION.VIEW;
    if (location.pathname.includes(PAGE_LOCATION.LIST))
      return PAGE_LOCATION.LIST;
  };

  const button =
    pageLocation() === PAGE_LOCATION.LIST ? (
      <Button
        disabled={!hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_PRODUCT_CREATE)}
        type="primary"
        onClick={() =>
          history.push({
            pathname: '../../create-product',
            state: { thirdPartyOption: thirdPartyEnums.CHILD },
          })
        }
      >
        New Child
      </Button>
    ) : (
      ''
    );

  let title =
    pageLocation() === PAGE_LOCATION.LIST ? 'Vendors List' : 'Vendor View';

  return (
    <>
      <PagesHeader
        title={title}
        hasAuthNew={hasAnyAuthority(
          AuthorityProvider.ROLE_THIRD_PARTY_PRODUCT_LIST
        )}
        button={button}
        hasBackButton={pageLocation() === PAGE_LOCATION.VIEW}
        historyGoBack={true}
      />
      <PagesWrapper>
        <Switch>
          <Route exact path={`${match.path}:productId/list`}>
            <ThirdPartyList />
          </Route>
          <Route exact path={`${match.path}:thirdPartyId/view`}>
            <ThirdPartyView />
          </Route>
        </Switch>
      </PagesWrapper>
    </>
  );
};

export default ThirdParty;
