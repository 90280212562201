import { viewDataTime } from '@Modules/analytics/cohort/cohortReport/cohortReportConstants';
import ProductUserApi from '@Modules/track/users/UsersApi';
import { showMessageError } from '@Utils/RenderUtils';
import { createSlice } from '@reduxjs/toolkit';
import CohortApi from './cohortApi';
import moment from 'moment';

const initialState = {
  cohortList: [],
  loading: false,
  loadingEvent: false,
  listEvents: [],
  listAttributes: [],
  loadingExecution: false,
  loadingStopExecution: false,
  executing: false,
  executingId: null,
  cohortExecutionReport: null,
  cohortExecutionConfig: {},
  loadingTable: false,
};

export const contracts = createSlice({
  name: 'cohort',
  initialState,
  reducers: {
    setCohortList(state, { payload }) {
      state.cohortList = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setLoadingEvent(state, { payload }) {
      state.loadingEvent = payload;
    },
    setListEvents(state, { payload }) {
      state.listEvents = payload;
    },
    setListAttributes(state, { payload }) {
      state.listAttributes = payload;
    },
    setCohortExecutionReport(state, { payload }) {
      state.cohortExecutionReport = payload;
    },
    setLoadingExecution(state, { payload }) {
      state.loadingExecution = payload;
    },
    setLoadingStopExecution(state, { payload }) {
      state.loadingStopExecution = payload;
    },
    setExecuting(state, { payload }) {
      state.executing = payload;
    },
    setExecutingId(state, { payload }) {
      state.executingId = payload;
    },
    setCohortExecutionConfig(state, { payload }) {
      state.cohortExecutionConfig = payload;
    },
    setLoadingTable(state, { payload }) {
      state.loadingTable = payload;
    },
  },
});

export default contracts.reducer;

export const {
  setCohortList,
  setLoading,
  setLoadingEvent,
  setListEvents,
  setListAttributes,
  setCohortExecutionReport,
  setLoadingExecution,
  setLoadingStopExecution,
  setExecuting,
  setExecutingId,
  setCohortExecutionConfig,
  setLoadingTable,
} = contracts.actions;

export const cohortListFetch = (body) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await CohortApi.list(body);
    dispatch(setCohortList(response));
  } catch (e) {
    showMessageError(e);
  } finally {
    dispatch(setLoading(false));
  }
};

export const cohortCreate =
  ({ body, cb }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await CohortApi.create(body);
      cb & cb();
    } catch (e) {
      showMessageError(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const cohortView =
  ({ id, cb }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await CohortApi.view(id);
      cb && cb(response);
    } catch (e) {
      showMessageError(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const cohortEdit =
  ({ id, body, cb }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await CohortApi.update(id, body);
      cb & cb();
    } catch (e) {
      showMessageError(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const cohortDelete =
  ({ id, cb }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await CohortApi.delete(id);
      cb & cb();
    } catch (e) {
      showMessageError(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const cohortExecute = (cohortId) => async (dispatch, getState) => {
  const state = getState();
  const cohortExecutionConfig = state.analytics.cohort.cohortExecutionConfig;

  dispatch(setLoadingExecution(true));
  dispatch(setExecuting(true));
  try {
    const response = await CohortApi.execute({
      cohort: {
        id: +cohortId,
      },
      endDate: cohortExecutionConfig.toDate.format('YYYY-MM-DD'),
      startDate: cohortExecutionConfig.fromDate.format('YYYY-MM-DD'),
      timeFrame: cohortExecutionConfig.cohortDataTimeFrame,
    });
    dispatch(cohortViewExecution(response));
    dispatch(setExecutingId(response));
  } catch (e) {
    showMessageError(e);
    dispatch(setExecuting(false));
  } finally {
    dispatch(setLoadingExecution(false));
  }
};

export const cohortForceExecute = (cohortId) => async (dispatch, getState) => {
  const state = getState();
  const cohortExecutionConfig = state.analytics.cohort.cohortExecutionConfig;
  dispatch(setLoadingExecution(true));
  dispatch(setExecuting(true));
  try {
    const response = await CohortApi.forceExecute({
      cohort: {
        id: +cohortId,
      },
      endDate: cohortExecutionConfig.toDate.format('YYYY-MM-DD'),
      startDate: cohortExecutionConfig.fromDate.format('YYYY-MM-DD'),
      timeFrame: cohortExecutionConfig.cohortDataTimeFrame,
    });
    dispatch(cohortViewExecution(response));
    dispatch(setExecutingId(response));
  } catch (e) {
    showMessageError(e);
    dispatch(setExecuting(false));
  } finally {
    dispatch(setLoadingExecution(false));
  }
};

export const cohortViewExecution = (id) => async (dispatch) => {
  const checkExecutionStatus = async () => {
    try {
      const response = await CohortApi.viewExecution(id);
      dispatch(
        setCohortExecutionConfig({
          toDate: moment(response?.endDate),
          fromDate: moment(response?.startDate),
          cohortDataTimeFrame: response?.timeFrame,
        })
      );
      dispatch(setCohortExecutionReport(response));
      if (
        response?.status === 'RUNNING' &&
        window.location.pathname.includes('cohorts/report')
      ) {
        setTimeout(checkExecutionStatus, 1000);
      } else {
        dispatch(setExecuting(false));
        dispatch(setLoadingTable(false));
      }
    } catch (e) {
      showMessageError(e);
    }
  };

  await checkExecutionStatus();
};

export const cohortStopExecution = (id) => async (dispatch) => {
  dispatch(setLoadingStopExecution(true));
  try {
    await CohortApi.stopExecute(id);
    dispatch(setExecuting(false));
    dispatch(setLoadingExecution(false));
  } catch (e) {
    showMessageError(e);
  } finally {
    dispatch(setLoadingStopExecution(false));
  }
};

export const getCohortLastExecution = (cohortId) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setLoadingTable(true));

  try {
    const response = await CohortApi.lastExecution(cohortId);
    dispatch(cohortViewExecution(response));
    dispatch(setExecutingId(response));
  } catch (error) {
    await dispatch(
      setCohortExecutionConfig({
        toDate: moment(),
        // * the days amount should be 6. dont change it to 7
        fromDate: moment().subtract(6, 'days'),
        cohortDataTimeFrame: viewDataTime[0].value,
      })
    );
    // dispatch(cohortExecute(cohortId));
    dispatch(setLoading(false));
    dispatch(setLoadingTable(false));
  } finally {
    dispatch(setLoading(false));
  }
};

export const cohortListEventsFetch = () => async (dispatch) => {
  dispatch(setLoadingEvent(true));
  try {
    const response = await ProductUserApi.listEvents();
    dispatch(setListEvents(response));
  } catch (e) {
    showMessageError(e);
  } finally {
    dispatch(setLoadingEvent(false));
  }
};

export const cohortListAttributesFetch = () => async (dispatch) => {
  try {
    const response = await ProductUserApi.listAttributes();
    dispatch(setListAttributes(response));
  } catch (e) {
    showMessageError(e);
  }
};
