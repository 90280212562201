import { createSlice } from '@reduxjs/toolkit';
import auditLogApis from './auditLogApis';
import moment from 'moment';
import { showMessageError } from '@Utils/RenderUtils';

const initialState = {
  list: {},
  logTypes: [],
  pageRequest: {
    direction: 'ASC',
    entity: null,
    entityType: null,
    intervalEndDate: moment().format('YYYY-MM-DD'),
    intervalStartDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    page: 0,
  },
  users: [],
  loading: false,
};

export const auditLogSlice = createSlice({
  name: 'auditLog',
  initialState,
  reducers: {
    listLogFetchStart(state, { payload }) {
      state.pageRequest = payload;
    },
    listLogFetchSuccess(state, { payload }) {
      state.list = payload;
      state.loading = false;
    },
    listFetchTypes(state, { payload }) {
      state.logTypes = payload;
    },
    listFetchUsers(state, { payload }) {
      state.users = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    modifyPageRequest(state, { payload }) {
      state.pageRequest = payload;
    },
  },
});

export default auditLogSlice.reducer;

export const {
  listLogFetchStart,
  listLogFetchSuccess,
  listFetchTypes,
  listFetchUsers,
  setLoading,
  modifyPageRequest,
} = auditLogSlice.actions;

export const fetchAuditLoglist = (pageRequest) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(listLogFetchStart(pageRequest));
  try {
    const response = await auditLogApis.auditList(pageRequest);
    dispatch(listLogFetchSuccess(response));
  } catch (e) {
    showMessageError(e);
    dispatch(setLoading(false));
  }
};
export const fetchAuditTypes = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await auditLogApis.types();
    dispatch(listFetchTypes(response));
  } catch (e) {
    showMessageError(e);
    dispatch(setLoading(false));
  }
};
export const fetchAuditUsers = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await auditLogApis.users();
    dispatch(listFetchUsers(response));
  } catch (e) {
    showMessageError(e);
    dispatch(setLoading(false));
  }
};
