import React from 'react';
import UnreadIcon from './../assets/IconUnread';
import StarIcon from './../assets/IconStar';
import TupeIcon from './../assets/IconTupe';
import UserIcon from './../assets/IconUser';
import CsvIcon from './../assets/IconCsv';
import GeoIcon from './../assets/IconGeo';
import TextIcon from './../assets/IconText';
import WebIcon from './../assets/IconWeb';
import WebPushIcon from './../assets/IconWebPush';
import PushIcon from './../assets/IconPush';
import InAppIcon from './../assets/IconInApp';
import WhatsAppIcon from './../assets/IconWhatsapp';
import CodeIcon from './../assets/IconCode';
import WaitIcon from './../assets/IconWait';
import WaitRangeIcon from './../assets/IconWaitRange';
import WaitForDateIcon from './../assets/IconWaitForDate';
import StopIcon from './../assets/IconStop';
import TrafficIcon from './../assets/IconTraffic';
import CustomChannelIcon from './../assets/IconCustomChannel';

export const NodeIcon = ({ name, type, nodeId }) => {
  return name ? (
    <div
      nodeId={nodeId}
      className={`box-node__type__icon back_icon--${type.toLocaleLowerCase()}`}
    >
      {name === 'unread' && <UnreadIcon />}
      {name === 'star' && <StarIcon />}
      {name === 'tupe' && <TupeIcon />}
      {name === 'user' && <UserIcon />}
      {name === 'csv' && <CsvIcon />}
      {name === 'geo' && <GeoIcon />}
      {name === 'text' && <TextIcon />}
      {name === 'web' && <WebIcon />}
      {name === 'web-push' && <WebPushIcon />}
      {name === 'push' && <PushIcon />}
      {name === 'in-app' && <InAppIcon />}
      {name === 'whatsapp' && <WhatsAppIcon />}
      {name === 'code' && <CodeIcon />}
      {name === 'wait' && <WaitIcon />}
      {name === 'wait-range' && <WaitRangeIcon />}
      {name === 'wait-for-date' && <WaitForDateIcon />}
      {name === 'stop' && <StopIcon />}
      {name === 'traffic_splitter' && <TrafficIcon />}
      {name === 'custom_channel' && <CustomChannelIcon />}
    </div>
  ) : null;
};
