import React, { useState, useEffect, useContext } from 'react';
import ConversionEventFilterSelector from './ConversionEventFilterSelector';
import { cloneDeep, isObject, isEqual, isArray } from 'lodash';
import TrackingStep from './TrackingStep';
import TrackingFooter from './TrackingFooter';
import { history } from '../../../../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import {
  getChannel,
  getCommunicationId,
} from '../../../../../utils/AuthorityProvider';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import TrackingPage from './TrackingPage';
import {
  communicationUpdateConversionFetch,
  communicationListEventsFetch,
  communicationUpdateSubmitConversionFetch,
  communicationUpdateMessageFetch,
  clearCommunicationTrackingError,
  communicationUpdateWhenFetch,
} from '../../CommunicationActions';
import { convertMinute, getDelayMinutes } from '../../../../../utils/DateUtils';
import { urlRegex, currentConversionType } from './CommunicationConst';
import { AppContext } from '@Component/journey/app';
import { useIsParentProduct } from '@Utils/isParentProduct';

const CommunicationConversion = ({
  journey,
  communicationId: comId,
  journeySteps,
  readOnly,
  goToStepOnJourney,
  dataNode,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const pathname = useSelector((state) => state.router.location.pathname);
  const loadingUpdateConversion = useSelector(
    (state) => state.engage.communication.loadingUpdateConversion
  );
  const communicationData = useSelector(
    (state) => state.engage.communication.communicationData
  );
  const currentConversion = useSelector(
    (state) => state.engage.communication.currentConversion
  );

  const currentMessage = useSelector(
    (state) => state.engage.communication.currentMessage
  );

  const errorUpdateConversionSubmit = useSelector(
    (state) => state.engage.communication.errorUpdateConversionSubmit
  );
  const currentWhen = useSelector(
    (state) => state.engage.communication.currentWhen
  );

  const currentJourney = useSelector(
    (state) => state.engage.journey?.currentJourney
  );

  const channel = getChannel(pathname);

  const [selectorModalVisible, setSelectorModalVisible] = useState(false);

  const [currentPredicate, setCurrentPredicate] = useState({
    event: 0,
    eventConjunction: 'AND',
    eventPredicates: [],
    type: 'BEHAVIOUR',
  });

  const [conversionTracking, setConversionTracking] = useState({
    conversionValue: null,
    conversionDuration: null,
    conversionEnable: false,
    conversionEventFilterPredicates: [],
    conversionEventFilterConjunction: 'AND',
    conversionEvent: null,
    controlGroupEnable: false,
    winnerMechanismEnabled: false,
  });

  const [variationDistribution, setVariationDistribution] = useState({
    nonControlGroupPercentage: 95,
    variations: [],
  });

  const [winnerMechanism, setWinnerMechanism] = useState({
    winnerMechanismTestAudienceSize: 0,
    winnerMechanismWinVariationCriteria: '',
    winnerMechanismTimeToTestValue: null,
    winnerMechanismTimeToTestDuration: null,
  });

  const [winnerCriteriaFilter, setWinnerCriteriaFilter] = useState([]);

  let communicationId;
  if (journey) {
    communicationId = comId || communicationData?.id;
  } else {
    communicationId = getCommunicationId(pathname);
  }

  const currentUser = useSelector((state) => state.account.auth.currentUser);

  const isParent = useIsParentProduct(currentUser);

  const { syncCache } = useContext(AppContext);

  useEffect(() => {
    dispatch(communicationUpdateConversionFetch(communicationId));
    dispatch(communicationListEventsFetch());
    dispatch(communicationUpdateMessageFetch(communicationId));
    if (!journey) {
      dispatch(communicationUpdateWhenFetch(communicationId));
    }
  }, []);

  useEffect(() => {
    const nonControlGroupPercentage =
      100 - Number(currentConversion?.controlGroupPercentage || 0);
    const variations = [];
    if (Array.isArray(currentConversion?.variations)) {
      currentConversion.variations.forEach((item) => {
        variations.push(item.percentage);
      });
    }

    const resultConversion = convertMinute(
      currentConversion?.conversionDeadlineMinutes
    );
    const conversionDeadline = {
      conversionValue: resultConversion?.value,
      conversionDuration: resultConversion?.type,
    };
    const conversionEnable = !!currentConversion?.conversion;
    const currentPredicate = fillCurrentPredicate(
      currentConversion?.conversionEvent,
      currentConversion?.conversionEventFilterConjunction,
      currentConversion?.conversionEventFilterPredicates
    );

    const conversionEvent = currentConversion?.conversionEvent;
    const controlGroupEnable = currentConversion?.controlGroup;
    const winnerMechanismEnabled = currentConversion?.winnerMechanismEnabled;

    //set Winner default
    const winnerMechanismTestAudienceSize =
      currentConversion?.winnerMechanismTestAudienceSize;
    const winnerMechanismWinVariationCriteria =
      currentConversion?.winnerMechanismWinVariationCriteria;
    const tempTimeToTest = convertMinute(
      currentConversion?.winnerVariationTimeToTestMinutes
    );
    const timeToTest = {
      winnerMechanismTimeToTestValue: tempTimeToTest?.value,
      winnerMechanismTimeToTestDuration: tempTimeToTest?.type,
    };

    setConversionTracking((prevState) => ({
      ...prevState,
      conversionEventFilterPredicates: cloneDeep(
        currentConversion?.conversionEventFilterPredicates
      ),
      conversionEventFilterConjunction:
        currentConversion?.conversionEventFilterConjunction ?? 'AND',
      conversionEvent,
      conversionEnable,
      controlGroupEnable,
      winnerMechanismEnabled,
      ...conversionDeadline,
    }));

    setVariationDistribution((prevState) => ({
      ...prevState,
      nonControlGroupPercentage,
      variations,
    }));

    setCurrentPredicate(currentPredicate);

    setWinnerMechanism((prevState) => ({
      ...prevState,
      winnerMechanismTestAudienceSize,
      winnerMechanismWinVariationCriteria,
      ...timeToTest,
    }));
  }, [currentConversion]);

  let tempWinnerCriteria = [];
  let foundedLinks = [];

  useEffect(() => {
    if (channel === 'WHATSAPP') {
      currentMessage?.variations?.forEach((variation) => {
        foundedLinks.push(variation?.whatsAppTemplateHasButtonWithUrl);
      });
    } else {
      currentMessage?.variations?.forEach((variation) => {
        const reg = new RegExp(urlRegex, 'ig');
        const links = extractLinks(variation?.message);
        if (links?.length) {
          links.forEach((link) => {
            if (reg.test(link)) {
              foundedLinks.push(true);
            }
          });
        } else {
          foundedLinks.push(false);
        }
      });
    }
    if (foundedLinks.length && foundedLinks.every((value) => value === true)) {
      tempWinnerCriteria.push('CLICKS');
    }

    if (
      conversionTracking.conversionEnable ||
      (journey && currentJourney.conversion)
    ) {
      tempWinnerCriteria.push('CONVERSION');
    } else {
      tempWinnerCriteria = tempWinnerCriteria.filter(
        (criteria) => criteria !== 'CONVERSION'
      );
    }
    setWinnerCriteriaFilter(tempWinnerCriteria);

    setWinnerMechanism((prevState) => ({
      ...prevState,
      winnerMechanismWinVariationCriteria: tempWinnerCriteria?.[0],
    }));
  }, [conversionTracking.conversionEnable, currentMessage?.variations]);

  useEffect(() => {
    if (!winnerCriteriaFilter.length) {
      setConversionTracking((prevState) => ({
        ...prevState,
        winnerMechanismEnabled: false,
      }));

      setWinnerMechanism({
        winnerMechanismTestAudienceSize: 0,
        winnerMechanismWinVariationCriteria: '',
        winnerMechanismTimeToTestValue: null,
        winnerMechanismTimeToTestDuration: null,
      });
    }
  }, [winnerCriteriaFilter]);

  const extractLinks = (text) => {
    const regex = /\b(https?:\/\/\S+|www\.\S+|https?:\/\/\S+)\b/g;
    const links = text?.match(regex);
    return links || [];
  };

  const fillCurrentPredicate = (event, eventConjunction, eventPredicates) => {
    const result = {
      event: cloneDeep(event),
      eventConjunction: eventConjunction ? cloneDeep(eventConjunction) : 'AND',
      eventPredicates: cloneDeep(eventPredicates),
      type: 'BEHAVIOUR',
    };
    return result;
  };

  const isEdited = () => {
    const trackingState = getCurrentConversion();
    let sourceKeys = [
      //{ key:'type',defaultValue:'ON_TIME'},
      { key: 'conversion', defaultValue: false },
      { key: 'conversionEvent', defaultValue: null },
      { key: 'conversionDeadlineMinutes', defaultValue: 0 },
      { key: 'controlGroup', defaultValue: false },
      { key: 'winningVariation', defaultValue: false },
      { key: 'winningVariationSizeOfTest', defaultValue: 0 },
      { key: 'winningVariationTimeToTestMinutes', defaultValue: 0 },
      { key: 'winningVariationWinCriteria', defaultValue: 'CONVERSION' },
      { key: 'controlGroupPercentage', defaultValue: 0 },
      { key: 'conversionEventFilterConjunction', defaultValue: 'AND' },
      { key: 'conversionEventFilterPredicates', defaultValue: [] },
      { key: 'variations', defaultValue: [] },
    ];
    const res = sourceKeys.some((key) => {
      const src = currentConversion[key.key];
      const dest = trackingState[key.key];
      var isChanged = false;
      ((src === null || src === undefined) &&
        (dest === null || dest === undefined)) ||
      ((src === null || src === undefined) && dest === key.defaultValue) ||
      src == dest ||
      ((isArray(dest) || isObject(dest)) &&
        (dest == null ||
          dest == undefined ||
          dest.length == undefined ||
          dest.length == 0) &&
        isEqual(src, dest))
        ? (isChanged = false)
        : (isChanged = true);
      return isChanged;
    });
    return res;
  };

  const isEditDisable = () => {
    return !journey && currentConversion?.status === 'ENDED';
  };

  const communicationHasJourneyType = () => {
    if (!journey && ['JOURNEY', 'RELAY'].includes(currentConversion?.type)) {
      return true;
    }
    return false;
  };

  const handleSelectorModalVisibleChange = (value) => {
    setSelectorModalVisible(value);
  };

  const closeSelectorModal = () => {
    setSelectorModalVisible(false);
  };

  const onResetEventFilter = () => {
    setCurrentPredicate((prevState) => ({
      ...prevState,
      event: cloneDeep(conversionTracking.conversionEvent),
      eventPredicates: cloneDeep(
        conversionTracking.conversionEventFilterPredicates
      ),
      eventConjunction: cloneDeep(
        conversionTracking.conversionEventFilterConjunction
      ),
    }));
  };

  const onOkEventFilter = () => {
    setSelectorModalVisible(false);
    setConversionTracking((prevState) => ({
      ...prevState,
      conversionEvent: cloneDeep(currentPredicate.event),
      conversionEventFilterPredicates: cloneDeep(
        currentPredicate.eventPredicates
      ),
      conversionEventFilterConjunction: cloneDeep(
        currentPredicate.eventConjunction
      ),
    }));
  };

  const handleCurrentPredicateChange = (value) => {
    setCurrentPredicate(value);
  };

  const handleVariationDistributionChange = (value) => {
    setVariationDistribution(value);
  };

  const handleConversionTrackingChange = (value, errorId) => {
    setConversionTracking(value);
    dispatch(
      clearCommunicationTrackingError(
        errorUpdateConversionSubmit?.errors,
        errorId
      )
    );
  };

  const handleWinnerMechanismChange = (value, errorId) => {
    setWinnerMechanism(value);
    dispatch(
      clearCommunicationTrackingError(
        errorUpdateConversionSubmit?.errors,
        errorId
      )
    );
  };

  const getVariations = () => {
    let result = [];
    currentConversion?.variations?.forEach((item, index) => {
      result.push({
        id: item.id,
        percentage: variationDistribution.variations[index],
      });
    });

    return result;
  };

  const handleClickNext = () => {
    const result = {
      ...currentConversion,
      conversion: conversionTracking.conversionEnable,
      controlGroup: conversionTracking.controlGroupEnable,
      conversionEvent: conversionTracking.conversionEvent,
      controlGroupPercentage:
        100 - variationDistribution.nonControlGroupPercentage,
      conversionDeadlineMinutes: getDelayMinutes(
        conversionTracking.conversionValue,
        conversionTracking.conversionDuration
      ),
      conversionEventFilterConjunction:
        conversionTracking.conversionEventFilterConjunction,
      conversionEventFilterPredicates:
        conversionTracking.conversionEventFilterPredicates,
      variations: getVariations(),
      winnerMechanismEnabled: conversionTracking.winnerMechanismEnabled,
      winnerMechanismWinVariationCriteria:
        winnerMechanism.winnerMechanismWinVariationCriteria,
      winnerMechanismTestAudienceSize:
        currentConversion?.type === currentConversionType.RECURRING
          ? 0
          : winnerMechanism.winnerMechanismTestAudienceSize,
      winnerVariationTimeToTestMinutes:
        currentConversion?.type === currentConversionType.ONE_TIME
          ? getDelayMinutes(
              winnerMechanism.winnerMechanismTimeToTestValue,
              winnerMechanism.winnerMechanismTimeToTestDuration
            )
          : 0,
    };

    dispatch(
      communicationUpdateSubmitConversionFetch(
        communicationId,
        result,
        journey,
        handleClickNextJourney
      )
    );
  };

  const handleClickBack = () => {
    if (!journey) {
      history.push('message');
    } else {
      goToStepOnJourney('message');
    }
  };

  const handleClickNextJourney = () => {
    if (isParent) {
      if (!journey) {
        history.push('preview');
      } else {
        // this.props.goToStepOnJourney('preview');
        delete dataNode.ui.error;
        syncCache(
          'update-step',
          Object.assign({}, dataNode, {
            error: null,
            communication: communicationId,
          }),
          true
        );
        // this.props.goToStepOnJourney('test');
        setTimeout(() => closeModal(false, true), 1000);
      }
    } else {
      goToStepOnJourney('test');
    }
  };

  const getCurrentConversion = () => {
    return {
      currentConversion,
      winningVariationSizeOfTest: 0,
      winningVariationTimeToTestMinutes: 0,
      winningVariationWinCriteria: 'CONVERSION',

      winnerMechanismEnabled: conversionTracking.winnerMechanismEnabled,
      conversion: conversionTracking.conversionEnable,
      controlGroup: conversionTracking.controlGroupEnable,
      conversionEvent: conversionTracking.conversionEvent,
      controlGroupPercentage:
        100 - variationDistribution.nonControlGroupPercentage,
      conversionDeadlineMinutes: getDelayMinutes(
        conversionTracking.conversionValue,
        conversionTracking.conversionDuration
      ),
      conversionEventFilterConjunction:
        conversionTracking.conversionEventFilterConjunction,
      conversionEventFilterPredicates:
        conversionTracking.conversionEventFilterPredicates,
      variations: getVariations(),
      winnerMechanismTestAudienceSize:
        winnerMechanism.winnerMechanismTestAudienceSize,
      winnerMechanismWinVariationCriteria:
        winnerMechanism.winnerMechanismWinVariationCriteria,
      winnerVariationTimeToTestMinutes: getDelayMinutes(
        winnerMechanism.winnerMechanismTimeToTestValue,
        winnerMechanism.winnerMechanismTimeToTestDuration
      ),
    };
  };

  return (
    <div style={{ padding: 30 }}>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 5,
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
        }}
      ></div>
      <TrackingStep
        isEdited={isEdited}
        journey={journey}
        journeySteps={journeySteps}
        communicationHasJourneyType={communicationHasJourneyType}
        goToStepOnJourney={goToStepOnJourney}
        channel={channel}
      />
      {loadingUpdateConversion ? (
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            paddingTop: 50,
            paddingBottom: 50,
          }}
        >
          <Spin indicator={loadingSpin} />
        </div>
      ) : (
        <>
          <TrackingPage
            journey={journey}
            communicationHasJourneyType={communicationHasJourneyType}
            isEditDisable={isEditDisable}
            conversionTracking={conversionTracking}
            handleConversionTrackingChange={handleConversionTrackingChange}
            handleSelectorModalVisibleChange={handleSelectorModalVisibleChange}
            handleCurrentPredicateChange={handleCurrentPredicateChange}
            currentPredicate={currentPredicate}
            variationDistribution={variationDistribution}
            handleVariationDistributionChange={
              handleVariationDistributionChange
            }
            winnerMechanism={winnerMechanism}
            handleWinnerMechanismChange={handleWinnerMechanismChange}
            winnerCriteriaFilter={winnerCriteriaFilter}
          />
          <ConversionEventFilterSelector
            selectorModalVisible={selectorModalVisible}
            closeSelectorModal={closeSelectorModal}
            onResetEventFilter={onResetEventFilter}
            onOkEventFilter={onOkEventFilter}
            currentPredicate={currentPredicate}
            handleCurrentPredicateChange={handleCurrentPredicateChange}
            currentWhen={currentWhen}
          />
          <TrackingFooter
            readOnly={readOnly}
            journey={journey}
            journeySteps={journeySteps}
            communicationHasJourneyType={communicationHasJourneyType}
            handleClickNext={handleClickNext}
            handleClickBack={handleClickBack}
            isEdited={isEdited}
            isEditDisable={isEditDisable}
          />
        </>
      )}
    </div>
  );
};

const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;

export default CommunicationConversion;
