import {
  PRODUCT_SDK_ANDROID_VIEW_FETCH,
  PRODUCT_SDK_ANDROID_VIEW_FETCH_FAIL,
  PRODUCT_SDK_ANDROID_VIEW_FETCH_SUCCESS,
  PRODUCT_SDK_WEB_VIEW_FETCH,
  PRODUCT_SDK_WEB_VIEW_FETCH_FAIL,
  PRODUCT_SDK_WEB_VIEW_FETCH_SUCCESS,
  PRODUCT_SDK_IOS_VIEW_FETCH,
  PRODUCT_SDK_IOS_VIEW_FETCH_FAIL,
  PRODUCT_SDK_IOS_VIEW_FETCH_SUCCESS,
} from './ProductSdkConstants';

const initialState = {
  currentProductSdkAndroid: {},
  loadingProductSdkAndroidView: false,
  errorsProductSdkAndroid: [],

  currentProductSdkWeb: {},
  loadingProductSdkWebView: false,
  errorsProductSdkWeb: [],

  currentProductSdkIos: {},
  loadingProductSdkIosView: false,
  errorsProductSdkIos: [],
};

export default function ProductSdkState(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_SDK_ANDROID_VIEW_FETCH_SUCCESS:
      return {
        ...state,
        currentProductSdkAndroid: action.payload,
        loadingProductSdkAndroidView: false,
      };
    case PRODUCT_SDK_ANDROID_VIEW_FETCH:
      return { ...state, loadingProductSdkAndroidView: true };
    case PRODUCT_SDK_ANDROID_VIEW_FETCH_FAIL:
      return {
        ...state,
        loadingProductSdkAndroidView: false,
        errorsProductSdkAndroid: action.payload.errors,
      };
    case PRODUCT_SDK_WEB_VIEW_FETCH_SUCCESS:
      return {
        ...state,
        currentProductSdkWeb: action.payload,
        loadingProductSdkWebView: false,
      };
    case PRODUCT_SDK_WEB_VIEW_FETCH:
      return { ...state, loadingProductSdkWebView: true };
    case PRODUCT_SDK_WEB_VIEW_FETCH_FAIL:
      return {
        ...state,
        loadingProductSdkWebView: false,
        errorsProductSdkWeb: action.payload.errors,
      };
    case PRODUCT_SDK_IOS_VIEW_FETCH_SUCCESS:
      return {
        ...state,
        currentProductSdkIos: action.payload,
        loadingProductSdkIosView: false,
      };
    case PRODUCT_SDK_IOS_VIEW_FETCH:
      return { ...state, loadingProductSdkIosView: true };
    case PRODUCT_SDK_IOS_VIEW_FETCH_FAIL:
      return {
        ...state,
        loadingProductSdkIosView: false,
        errorsProductSdkIos: action.payload.errors,
      };
    default:
      return state;
  }
}
