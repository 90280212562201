import React, { useContext, useState, useEffect } from 'react';

import { AppContext } from '../../app';
import { Form, Button, Modal } from 'antd';
import ProductSegmentPredicateComponent from '@Modules/insight/productSegment/component/ProductSegmentPredicateComponent';
import _ from 'lodash';
import { isUsedOnWaitForDate } from '../../utils/hooks';
import { notification } from 'antd';
const { confirm } = Modal;

const predicateDefault = {
  event: null,
  eventConjunction: 'OR',
  type: 'EVENT_OCCURRENCE',
};

const TrOccurrenceEventContent = (props) => {
  const [form] = Form.useForm();
  const { journey, syncCache } = useContext(AppContext);
  const {
    events,
    attributes,
    fetchRecommendDeviceAttributes,
    fetchRecommendEventAttributes,
    fetchRecommendUserAttributes,
    recommendDeviceAttributes,
    recommendEventAttributes,
    recommendUserAttributes,
  } = props.data;
  const [predicate, setPredicate] = useState(predicateDefault);

  useEffect(() => {
    const currentStep = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );
    setPredicate(Object.assign({}, predicateDefault, currentStep));
  }, []);

  const resetConfirm = (stepsThatUsed) => {
    const ids = stepsThatUsed.map((m) => {
      return 'State-' + m.id + ',';
    });

    confirm({
      width: '50vw',
      title: `Warning to change this node`,
      content: `Making a change has an effect on Wait For Date block(s),
      Blocks with following states have been reset:${ids}.
      Please reconfigure them before publishing the journey.`,
      onOk() {
        let stepsMustUpdate = [];
        stepsThatUsed.forEach((m) => {
          const step2 = journey.steps.find(
            (step) => step.id + '' === m.id + ''
          );
          let step_ = Object.assign({}, step2, {
            stepId: null,
            waitForAttributeType: 'USER_ATTRIBUTE',
            eventAttribute: null,
            delayMinutes: null,
            bufferMinutes: null,
            userAttribute: null,
          });
          stepsMustUpdate.push(step_);
          // syncCache("update-step", step_, false);
        });

        const currentStep = journey.steps.find(
          (step) => step.id + '' === props.node.id + ''
        );
        const currentStepUpdated = Object.assign({}, currentStep, predicate);
        _.unset(currentStepUpdated, 'ui.error');

        if (stepsMustUpdate.length) {
          // batch update
          syncCache(
            'update-step',
            [currentStepUpdated, ...stepsMustUpdate],
            true
          );
        } else {
          syncCache('update-step', currentStepUpdated, true);
        }
        setTimeout(() => props.closeModal(false, true), 100);
      },
      onCancel() {},
    });
  };

  const onFinish = () => {
    const currentStep = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );
    // check is used on WaitForDate or no
    if (currentStep?.event && predicate?.event !== currentStep?.event) {
      const stepsThatUsed = isUsedOnWaitForDate(journey, props.node.id);
      if (stepsThatUsed.length) {
        // Show reset confirm
        resetConfirm(stepsThatUsed);
        return;
      }
    }

    const currentPredicates = Object.assign({}, predicate);
    currentPredicates.conjunction = currentPredicates.eventConjunction || 'AND';

    const isValid = currentPredicates.eventPredicates.every((item) => {
      switch (item.operator) {
        case 'IS_NOT_EMPTY':
          return true;
        case 'EQUAL_TO':
        case 'ONE_OF':
        case 'STARTS_WITH':
        case 'ENDS_WITH':
        case 'CONTAINS':
        case 'MATCHES_REGEX':
          return (
            'stringValue' in item &&
            !_.isNull(item.stringValue) &&
            item.stringValue !== ''
          );
        case 'BETWEEN':
          switch (item.relativeDateType) {
            case 'DATE':
              return (
                (!_.isNull(item.greaterThan) && item.greaterThan !== '') ||
                (!_.isNull(item.lessThan) && item.lessThan !== '')
              );
            case 'LAST-LAST':
            case 'NEXT-NEXT':
            case 'LAST-NEXT':
              if (
                !item.relativeDateDurationFrom &&
                !item.relativeDateDurationTo
              ) {
                return false;
              }

              if (
                item.relativeDateDurationFrom &&
                (!item.relativeDateValueFrom ||
                  item.relativeDateValueFrom === '' ||
                  _.isNull(item.relativeDateValueFrom))
              ) {
                return false;
              }
              if (
                item.relativeDateDurationTo &&
                (!item.relativeDateValueTo ||
                  item.relativeDateValueTo === '' ||
                  _.isNull(item.relativeDateValueTo))
              ) {
                return false;
              }

              if (
                !item.relativeDateDurationFrom &&
                item.relativeDateValueFrom
              ) {
                return false;
              }

              if (!item.relativeDateDurationTo && item.relativeDateValueTo) {
                return false;
              }

              return true;
            default:
              return (
                (!_.isNull(item.greaterThan) && item.greaterThan !== '') ||
                (!_.isNull(item.lessThan) && item.lessThan !== '')
              );
          }
        default:
          break;
      }
    });

    if (!isValid) {
      notification.error({
        key: 'Error',
        description: 'Please fill all fields or remove unused filters',
        placement: 'bottomRight',
      });
    } else {
      const currentStepUpdated = Object.assign({}, currentStep, predicate);
      // clean if has error
      _.unset(currentStepUpdated, 'ui.error');
      syncCache('update-step', currentStepUpdated, true);
      setTimeout(() => props.closeModal(false, true), 100);
    }
  };

  const handleChangePredicate = (changedPredicate) => {
    setPredicate(changedPredicate);
  };

  return (
    <>
      <Form form={form} onFinish={onFinish} className="modal_wrap">
        <div className={'modal-body'}>
          <ProductSegmentPredicateComponent
            journey={true}
            readOnly={props.readOnly}
            type="EVENT_OCCURRENCE"
            predicate={predicate}
            attributes={attributes}
            events={events}
            isInSegment={false}
            handleChangePredicate={(changedPredicate) =>
              handleChangePredicate(changedPredicate)
            }
            clearSegmentError={() => {
              //TODO Must Handle
            }}
            fetchRecommendEventAttributes={fetchRecommendEventAttributes}
            recommendEventAttributes={recommendEventAttributes}
            fetchRecommendDeviceAttributes={fetchRecommendDeviceAttributes}
            recommendDeviceAttributes={recommendDeviceAttributes}
            fetchRecommendUserAttributes={fetchRecommendUserAttributes}
            recommendUserAttributes={recommendUserAttributes}
          />
        </div>
        <div className={'modal-footer'}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              disabled={props.readOnly}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
export default TrOccurrenceEventContent;
