import ListBase, { defaultActions } from '@Component/form/ListBase';
import {
  confirmInvoice,
  fetchInvoiceList,
} from '@Redux/slices/setting/invoiceSlice';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UploadReceiptModal from './payment/UploadReceiptModal';
import GatewayModal from './payment/GatewayModal';
import { numberWithCommas } from '@Utils/RenderUtils';
import StatusContainer from '@Component/app/StatusContainer';
import { INVOICE_STATUS } from './BillingConstant';
import RejectInvoiceModal from '@Modules/setting/billing/components/payment/RejectInvoiceModal';

function BillingInvoice() {
  const dispatch = useDispatch();
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [isGatewayModalOpen, setIsGatewayModalOpen] = useState(false);
  const [gatewayModalInfo, setGatewayModalInfo] = useState();
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState(null);
  const [processedInvoicePage, setProcessedInvoicePage] = useState({});

  const { pageRequest, page: invoicePage } = useSelector(
    (state) => state.setting.invoice
  );
  const loadingList = useSelector((state) => state.setting.invoice.loadingList);

  useEffect(() => {
    dispatch(fetchInvoiceList(pageRequest));
  }, []);

  useEffect(() => {
    setProcessedInvoicePage(
      invoicePage.content
        ? {
            ...invoicePage,
            content: invoicePage.content.map((invoice) => {
              return {
                ...invoice,
                finalBasePrice: invoice.basePriceDetails.finalPrice,
                finalEventsPrice: invoice.eventsPriceDetails.finalPrice,
              };
            }),
          }
        : {}
    );
  }, [invoicePage]);

  const gatewayInfoRow = (x) => {
    setGatewayModalInfo(x);
    setIsGatewayModalOpen(true);
  };
  const receiptsDisabledStatus = [
    INVOICE_STATUS.CREATED.name,
    INVOICE_STATUS.ISSUED.name,
    INVOICE_STATUS.REJECTED.name,
  ];
  const gatewayEnabledStatus = [
    INVOICE_STATUS.CONFIRMED.name,
    INVOICE_STATUS.PAYMENT_FAILED.name,
  ];
  const confirmRejectEnabledStatus = [INVOICE_STATUS.ISSUED.name];

  const columnList = [
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
    },
    {
      title: 'Invoice Number',
      dataIndex: 'internalNumber',
      key: 'internalNumber',
    },
    {
      title: 'Subscription Fee',
      dataIndex: 'finalBasePrice',
      key: 'finalBasePrice',
      render: (x) => {
        return <span>{numberWithCommas(x)}</span>;
      },
    },

    {
      title: 'Total Events Fee',
      dataIndex: 'finalEventsPrice',
      key: 'finalEventsPrice',
      render: (x) => {
        return <span>{numberWithCommas(x)}</span>;
      },
    },
    {
      title: 'Final Price',
      dataIndex: 'finalPrice',
      key: 'finalPrice',
      render: (x) => {
        return <span>{numberWithCommas(x)}</span>;
      },
    },
    {
      title: 'Payment Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) =>
        text ? (
          <StatusContainer
            color={INVOICE_STATUS[text]?.color}
            bgColor={INVOICE_STATUS[text]?.bgColor}
          >
            {text}
          </StatusContainer>
        ) : (
          '-'
        ),
    },
    {
      title: 'Invoice',
      dataIndex: '',
      key: '-action-',
      width: '5%',
      render: (x) => {
        return (
          <Button
            type="link"
            shape="rounded"
            onClick={() => {
              const url = `invoice-preview/${x.id}`;
              window.open(url, '_blank');
            }}
          >
            <div>
              <EyeOutlined style={{ fontSize: '18px' }} />
            </div>
          </Button>
        );
      },
    },
    {
      title: 'Payment',
      dataIndex: '',
      key: '-action-',
      width: '10%',
      render: (x) =>
        defaultActions(
          x,
          {
            action: () => gatewayInfoRow(x),
            title: 'Gateway',
            enabled: gatewayEnabledStatus.includes(x.status),
            disablePopConfirm: true,
          },
          {
            action: () => handleOpeningPaymentModal(x.id, x.status),
            title: 'Receipt',
            enabled: !receiptsDisabledStatus.includes(x.status),
            disablePopConfirm: true,
          },
          {
            action: () => handleConfirmingInvoice(x.id, x.status),
            title: 'Confirm',
            enabled: confirmRejectEnabledStatus.includes(x.status),
            disablePopConfirm: false,
          },
          {
            action: () => handleOpeningRejectModel(x.id),
            title: 'Reject',
            enabled: confirmRejectEnabledStatus.includes(x.status),
            disablePopConfirm: true,
          }
        ),
    },
  ];

  const handleOpeningPaymentModal = (id, status) => {
    setIsReceiptModalOpen(true);
    setSelectedInvoiceId(id);
    setSelectedInvoiceStatus(status);
  };

  const handleClosingPaymentModal = () => {
    setIsReceiptModalOpen(false);
    setSelectedInvoiceId(null);
    setSelectedInvoiceStatus(null);
  };

  const handleConfirmingInvoice = (id) => {
    dispatch(
      confirmInvoice(id, () => {
        message.success(`Invoice confirmed successfully.`);
        dispatch(fetchInvoiceList(pageRequest));
      })
    );
  };

  const handleOpeningRejectModel = (id) => {
    setIsRejectModalOpen(true);
    setSelectedInvoiceId(id);
  };

  const handleClosingRejectModel = (success) => {
    setIsRejectModalOpen(false);
    if (success) {
      message.success(`Invoice rejected successfully.`);
      dispatch(fetchInvoiceList(pageRequest));
    }
  };

  const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;

  return (
    <>
      {loadingList ? (
        <Spin
          indicator={loadingSpin}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      ) : (
        <ListBase
          onChange={(p) =>
            dispatch(
              fetchInvoiceList({
                ...pageRequest,
                page: p.page,
              })
            )
          }
          page={processedInvoicePage}
          pageRequest={pageRequest}
          columns={columnList}
          createLink={null}
        />
      )}
      <UploadReceiptModal
        isOpen={isReceiptModalOpen}
        onClose={handleClosingPaymentModal}
        selectedInvoiceId={selectedInvoiceId}
        selectedInvoiceStatus={selectedInvoiceStatus}
      />
      <GatewayModal
        isOpen={isGatewayModalOpen}
        onClose={() => setIsGatewayModalOpen(false)}
        gatewayModalInfo={gatewayModalInfo}
        gateway="parsian"
      />
      <RejectInvoiceModal
        isOpen={isRejectModalOpen}
        onClose={(success) => handleClosingRejectModel(success)}
        selectedInvoiceId={selectedInvoiceId}
      />
    </>
  );
}

export default BillingInvoice;
