import React from 'react';
import AttributeType from '../../systemAttribute/model/AttributeType';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Select, Spin } from 'antd';
import { find as isEmpty, isNil } from 'lodash';

import { LoadingOutlined } from '@ant-design/icons';
import ListBase, { defaultActions } from '@Component/form/ListBase';
import Card from '@Utils/Card';
const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'right',
  },
  editField: {
    marginLeft: 20,
    width: 350,
  },
};

class CustomAttributeListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalId: null,
      type: null,
      name: '',
    };
  }

  componentDidMount() {
    this.props.customAttributeListFetch(this.props.pageRequest);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingUpdateSubmit && !this.props.loadingUpdateSubmit) {
      this.setState({ modalId: null });
    }
    if (prevProps.loading && this.props.loading) {
      this.props.customAttributeListFetch(this.props.pageRequest);
    }
  }

  handleChangeSearch = (name) => {
    this.setState({ name });
    if (name === '' || name === null) {
      this.props.customAttributeListFetch({
        ...this.props.pageRequest,
        page: 0,
        name: null,
      });
    }
  };

  handleChangeType = (type) => {
    this.setState({ type });
    this.props.customAttributeListFetch({
      ...this.props.pageRequest,
      page: 0,
      type,
    });
  };

  openModal = (x) => {
    this.props.customAttributeUpdateFetch(x.id);
    this.setState({ modalId: x.id });
  };

  onOkModal = () => {
    this.props.customAttributeUpdateSubmitFetch(
      this.state.modalId,
      this.props.currentCustomAttribute,
      this.props.pageRequest
    );
  };

  renderContent = () => {
    return this.props.loadingUpdate ||
      this.props.loadingDelete ||
      this.props.loadingList ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin style={{ display: 'flex', alignSelf: 'center' }} />
      </div>
    ) : (
      <div>
        <div style={styles.editContainer}>
          <div style={{ ...styles.editLabel, flex: 1.5 }}>Name</div>
          <div style={{ flex: 4 }}>
            <div style={{ ...styles.editField, width: 308 }}>
              {this.props.currentCustomAttribute &&
                this.props.currentCustomAttribute.name}
            </div>
          </div>
        </div>
        <div style={styles.editContainer}>
          <div style={{ ...styles.editLabel, flex: 1.5 }}>Title</div>
          <div style={{ flex: 4 }}>
            <Input
              value={
                this.props.currentCustomAttribute &&
                this.props.currentCustomAttribute.title
              }
              onChange={(e) =>
                this.props.customAttributeChangeCurrent({
                  ...this.props.currentCustomAttribute,
                  title: e.target.value,
                })
              }
              size="large"
              style={{ ...styles.editField, width: 308 }}
            />
          </div>
        </div>
        <div style={styles.editContainer}>
          {/* <div style={{ ...styles.editLabel, flex: 1.5 }}>Is Pii</div>
                <div style={{ flex: 4 }}>
                    <div style={{ marginLeft: 20 }}>
                        <Switch
                            checked={(this.props.currentCustomAttribute && this.props.currentCustomAttribute.pii)}
                            onChange={(pii) => this.props.customAttributeChangeCurrent({ ...this.props.currentCustomAttribute, pii })}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                        />
                    </div>
                </div> */}
        </div>
      </div>
    );
  };

  renderModal = () => {
    return (
      <Modal
        width={600}
        title={'Edit User Attribute'}
        centered
        visible={!isNil(this.state.modalId)}
        onOk={() => this.setState({ modalId: null })}
        onCancel={() => this.setState({ modalId: null })}
        footer={[
          <Button
            key="cancel"
            shape="round"
            onClick={() => this.setState({ modalId: null })}
          >
            CANCEL
          </Button>,
          <Button
            loading={this.props.loadingUpdateSubmit}
            key="submit"
            shape="round"
            type="primary"
            onClick={() => this.onOkModal()}
          >
            Update
          </Button>,
        ]}
      >
        <div>{this.renderContent()}</div>
      </Modal>
    );
  };

  render() {
    const columnList = [
      {
        sorter: false,
        title: 'TITLE',
        dataIndex: 'title',
        key: 'title',
        // ...getColumnTextSearchProps('title'),
      },
      {
        sorter: false,
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        // ...getColumnTextSearchProps('name'),
      },
      {
        sorter: false,
        title: 'DATA TYPE',
        dataIndex: 'type',
        key: 'type',
        render: (x) => AttributeType[x],
        // ...getColumnEnumSearchProps('type', AttributeType),
      },
      {
        title: 'ACTION',
        dataIndex: '',
        key: '-action-',
        width: '5%',
        render: (x) =>
          defaultActions(
            x,
            {
              action: () => this.openModal(x),
              title: 'Edit',
              enabled: true,
              disablePopConfirm: true,
            },
            {
              action: (id) => {
                this.props.customAttributeDeleteFetch(
                  id,
                  this.props.pageRequest
                );
              },
              title: 'Delete',
              enabled: true,
            }
          ),
      },
    ];

    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    return (
      <>
        <Card>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              paddingBottom: 0,
              paddingLeft: '20px',
            }}
          >
            <div style={{ color: '#000000', fontSize: 16, fontWeight: '600' }}>
              User Attributes
              <span style={{ paddingLeft: 10 }}>
                {false && (
                  <InfoCircleOutlined
                    style={{ color: '#8C8C8C', fontSize: 12 }}
                  />
                )}
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              borderBottom: 'solid 2px #f4f9ff',
            }}
          >
            <Input
              loading={true}
              allowClear={this.props.loadingSearchList ? false : true}
              placeholder={'Search by name'}
              onPressEnter={() =>
                this.props.customAttributeSearchListFetch({
                  ...this.props.pageRequest,
                  page: 0,
                  name: this.state.name,
                })
              }
              onChange={(e) => this.handleChangeSearch(e.target.value)}
              value={this.state.name}
              style={{ width: 300, marginRight: 30, borderRadius: 5 }}
              suffix={
                isEmpty(this.state.name) ? (
                  <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                ) : (
                  this.props.loadingSearchList && (
                    <div style={styles.inputLoadingSpinStyle}>
                      <Spin indicator={inputLoadingSpin} />
                    </div>
                  )
                )
              }
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Select
                onChange={(searchStatus) => this.handleChangeType(searchStatus)}
                value={this.state.type}
                defaultValue="all"
                style={{ width: 120 }}
                className={'chart-selector'}
                bordered={false}
              >
                <Select.Option value={null}>All Types</Select.Option>
                <Select.Option value="BOOLEAN">Boolean</Select.Option>
                <Select.Option value="NUMERIC">Numeric</Select.Option>
                <Select.Option value="STRING">String</Select.Option>
                <Select.Option value="DATE">Date</Select.Option>
                <Select.Option value="ARRAY">Array</Select.Option>
                <Select.Option value="OBJECT">Object</Select.Option>
              </Select>
            </div>
          </div>
          {this.props.loading ? (
            <div style={styles.loadingSpinStyle}>
              <Spin indicator={loadingSpin} />
            </div>
          ) : (
            <ListBase
              onChange={this.props.customAttributeListFetch}
              page={this.props.page}
              pageRequest={this.props.pageRequest}
              columns={columnList}
              // onClick={(row) => this.viewItem(row.id)}
              createLink={null}
            />
          )}
        </Card>
        {this.renderModal()}
      </>
    );
  }
}

export default CustomAttributeListComponent;
