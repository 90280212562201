import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
  name: 'dataSlice',
  initialState: {
    loading: false,
  },
  reducers: {
    appLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

export const { appLoading } = dataSlice.actions;
export default dataSlice.reducer;
