import {
  FUNNEL_LIST_EVENTS_FETCH,
  FUNNEL_LIST_EVENTS_FETCH_FAIL,
  FUNNEL_LIST_EVENTS_FETCH_SUCCESS,
  FUNNEL_EXECUTE,
  FUNNEL_EXECUTE_SUCCESS,
  FUNNEL_EXECUTE_FAIL,
  FUNNEL_EXECUTE_PARAMS_CHANGED,
  FUNNEL_VIEW_EXECUTION,
  FUNNEL_VIEW_EXECUTION_SUCCESS,
  FUNNEL_VIEW_EXECUTION_FAIL,
  FUNNEL_VIEW_FETCH,
  FUNNEL_VIEW_FETCH_SUCCESS,
  FUNNEL_VIEW_FETCH_FAIL,
  FUNNEL_LIST_FETCH_FAIL,
  FUNNEL_LIST_FETCHED,
  FUNNEL_LIST_FETCH,
  FUNNEL_LIST_PARAMS_CHANGED,
  FUNNEL_STOP_EXECUTION,
  FUNNEL_STOP_EXECUTION_SUCCESS,
  FUNNEL_STOP_EXECUTION_FAIL,
  FUNNEL_CREATE_UPDATE_FETCH,
  FUNNEL_CREATE_UPDATE_FETCH_SUCCESS,
  FUNNEL_CREATE_UPDATE_FETCH_FAIL,
} from './FunnelConstants';
import FunnelApi from './FunnelApi';
import { showMessageError } from '@Utils/RenderUtils';
import { isFunction } from 'lodash';
import moment from 'moment';

export function triggerCreateUpdateFetchSuccess(data) {
  return {
    type: FUNNEL_CREATE_UPDATE_FETCH_SUCCESS,
    payload: data,
  };
}

export function createFunnel(productFunnel, callback) {
  return (dispatch) => {
    dispatch({
      type: FUNNEL_CREATE_UPDATE_FETCH,
      payload: productFunnel,
    });
    FunnelApi.create(productFunnel)
      .then((response) => {
        if (isFunction(callback)) {
          callback(response);
        }
        dispatch(
          triggerCreateUpdateFetchSuccess({
            name: null,
            startDate: moment().subtract(30, 'd').format('YYYY-MM-DD'),
            steps: [
              {
                conjunction: 'AND',
                event: null,
                type: 'BEHAVIOUR',
                predicates: [],
              },
            ],
          })
        );
      })
      .catch((response) => {
        dispatch({
          type: FUNNEL_CREATE_UPDATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function viewUpdateFunnel(id) {
  return (dispatch) => {
    dispatch({
      type: FUNNEL_CREATE_UPDATE_FETCH,
    });
    FunnelApi.view(id)
      .then((response) => {
        dispatch(triggerCreateUpdateFetchSuccess(response));
      })
      .catch((response) => {
        dispatch({
          type: FUNNEL_CREATE_UPDATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function updateFunnel(id, body, callback) {
  return (dispatch) => {
    dispatch({
      type: FUNNEL_CREATE_UPDATE_FETCH,
    });
    FunnelApi.update(id, body)
      .then((response) => {
        if (isFunction(callback)) {
          callback(response);
        }
        dispatch(
          triggerCreateUpdateFetchSuccess({
            name: null,
            steps: [
              {
                conjunction: 'AND',
                event: null,
                type: 'BEHAVIOUR',
                predicates: [],
              },
            ],
          })
        );
      })
      .catch((response) => {
        dispatch({
          type: FUNNEL_CREATE_UPDATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function resetFunnelCreateUpdateContent() {
  return (dispatch) =>
    dispatch(
      triggerCreateUpdateFetchSuccess({
        name: null,
        startDate: moment().subtract(30, 'd').format('YYYY-MM-DD'),
        steps: [
          {
            conjunction: 'AND',
            event: null,
            type: 'BEHAVIOUR',
            predicates: [],
          },
        ],
      })
    );
}

export function listEvents(productId) {
  return (dispatch) => {
    dispatch({
      type: FUNNEL_LIST_EVENTS_FETCH,
      payload: productId,
    });
    FunnelApi.getEvents()
      .then((response) => {
        dispatch({
          type: FUNNEL_LIST_EVENTS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: FUNNEL_LIST_EVENTS_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function listFunnels(params) {
  return (dispatch) => {
    dispatch({
      type: FUNNEL_LIST_FETCH,
    });
    FunnelApi.list(params)
      .then((response) => {
        dispatch({
          type: FUNNEL_LIST_FETCHED,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: FUNNEL_LIST_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function updateFunnelListParameters(params) {
  return {
    type: FUNNEL_LIST_PARAMS_CHANGED,
    payload: params,
  };
}

export function executeFunnel(params) {
  return (dispatch) => {
    dispatch({
      type: FUNNEL_EXECUTE,
    });
    FunnelApi.execute(params)
      .then((response) => {
        dispatch({
          type: FUNNEL_EXECUTE_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: FUNNEL_EXECUTE_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function forceExecuteFunnel(params) {
  return (dispatch) => {
    dispatch({
      type: FUNNEL_EXECUTE,
    });
    FunnelApi.forceExecute(params)
      .then((response) => {
        dispatch({
          type: FUNNEL_EXECUTE_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: FUNNEL_EXECUTE_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function updateFunnelExecutionParameters(params) {
  return {
    type: FUNNEL_EXECUTE_PARAMS_CHANGED,
    payload: params,
  };
}

export function viewFunnelExecution(id) {
  return (dispatch) => {
    dispatch({
      type: FUNNEL_VIEW_EXECUTION,
    });
    FunnelApi.viewExecution(id)
      .then((response) => {
        dispatch({
          type: FUNNEL_VIEW_EXECUTION_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: FUNNEL_VIEW_EXECUTION_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function stopFunnelExecution(id) {
  return (dispatch) => {
    dispatch({
      type: FUNNEL_STOP_EXECUTION,
    });
    FunnelApi.stopExecution(id)
      .then((response) => {
        dispatch({
          type: FUNNEL_STOP_EXECUTION_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: FUNNEL_STOP_EXECUTION_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function viewFunnel(id) {
  return (dispatch) => {
    dispatch({
      type: FUNNEL_VIEW_FETCH,
    });
    FunnelApi.view(id)
      .then((response) => {
        dispatch({
          type: FUNNEL_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: FUNNEL_VIEW_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function deleteFunnel(id, callBack) {
  return () => {
    FunnelApi.delete(id)
      .then(() => {
        if (isFunction(callBack)) {
          callBack();
        }
      })
      .catch((response) => {
        showMessageError(response);
      });
  };
}
