/* eslint-disable complexity */
import {
  PRODUCT_CHANGE_CURRENT,
  PRODUCT_UPDATE_FETCH,
  PRODUCT_UPDATE_FETCH_SUCCESS,
  PRODUCT_UPDATE_FETCH_FAIL,
  PRODUCT_UPDATE_SUBMIT_FETCH,
  PRODUCT_UPDATE_SUBMIT_FETCH_FAIL,
  PRODUCT_UPDATE_SUBMIT_FETCH_SUCCESS,
  PRODUCT_LIST_TIMEZONE_FETCH,
  PRODUCT_LIST_TIMEZONE_FETCH_FAIL,
  PRODUCT_LIST_TIMEZONE_FETCH_SUCCESS,
  FC_UPDATE_FETCH,
  FC_UPDATE_FETCH_FAIL,
  FC_UPDATE_FETCH_SUCCESS,
  FC_UPDATE_SUBMIT_FETCH,
  FC_UPDATE_SUBMIT_FETCH_FAIL,
  FC_UPDATE_SUBMIT_FETCH_SUCCESS,
  FC_CHANGE_CURRENT,
  THROTTLING_UPDATE_FETCH,
  THROTTLING_UPDATE_FETCH_FAIL,
  THROTTLING_UPDATE_FETCH_SUCCESS,
  THROTTLING_UPDATE_SUBMIT_FETCH,
  THROTTLING_UPDATE_SUBMIT_FETCH_FAIL,
  THROTTLING_UPDATE_SUBMIT_FETCH_SUCCESS,
  THROTTLING_CHANGE_CURRENT,
  DND_UPDATE_FETCH,
  DND_UPDATE_FETCH_FAIL,
  DND_UPDATE_FETCH_SUCCESS,
  DND_CHANGE_CURRENT,
  DND_UPDATE_SUBMIT_FETCH,
  DND_UPDATE_SUBMIT_FETCH_FAIL,
  DND_UPDATE_SUBMIT_FETCH_SUCCESS,
  CUSTOM_DOMAIN_UPDATE_FETCH,
  CUSTOM_DOMAIN_UPDATE_FETCH_FAIL,
  CUSTOM_DOMAIN_UPDATE_FETCH_SUCCESS,
  CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH_FAIL,
  CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH_SUCCESS,
  CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH,
  CUSTOM_DOMAIN_CHANGE_CURRENT,
  UPLOADED_FONTS_SUBMIT_FETCH,
  UPLOADED_FONTS_SUBMIT_FETCH_SUCCESS,
  UPLOADED_FONTS_SUBMIT_FETCH_FAIL,
} from './ProductSettingConstants';

const initialState = {
  zones: {},
  loadingZones: false,
  errorsZones: [],

  loadingUpdate: false,
  loadingUpdateSubmit: false,
  errors: [],
  currentProduct: {},
  currentURL: {},

  loadingDndUpdate: false,
  loadingDndUpdateSubmit: false,
  dnd: {},
  errorsDnd: [],

  loadingFcUpdate: false,
  loadingFcUpdateSubmit: false,
  fc: {},
  errorsFc: [],

  loadingThrottlingUpdate: false,
  loadingThrottlingUpdateSubmit: false,
  throttling: {},
  errorsThrottling: [],

  loadingCustomDomainUpdate: false,
  loadingCustomDomainSubmit: false,

  uploadedFonts: [],
  loadingUploadedFonts: false,
};

export default function ProductSettingStateReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PRODUCT_UPDATE_FETCH_SUCCESS:
      return { ...state, loadingUpdate: false, currentProduct: action.payload };
    case PRODUCT_UPDATE_FETCH:
      return { ...state, loadingUpdate: true };
    case PRODUCT_UPDATE_FETCH_FAIL:
      return { ...state, loadingUpdate: false, errors: action.payload.errors };

    case CUSTOM_DOMAIN_UPDATE_FETCH_SUCCESS:
      return {
        ...state,
        loadingCustomDomainUpdate: false,
        currentURL: action.payload,
      };
    case CUSTOM_DOMAIN_UPDATE_FETCH:
      return { ...state, loadingCustomDomainUpdate: true };
    case CUSTOM_DOMAIN_UPDATE_FETCH_FAIL:
      return {
        ...state,
        loadingCustomDomainUpdate: false,
        errors: action.payload.errors,
      };

    case CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingCustomDomainSubmit: false };
    case CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH:
      return { ...state, loadingCustomDomainSubmit: true };
    case CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        loadingCustomDomainSubmit: false,
        errors: action.payload.errors,
      };

    case CUSTOM_DOMAIN_CHANGE_CURRENT:
      return { ...state, currentURL: action.payload };

    case PRODUCT_LIST_TIMEZONE_FETCH_SUCCESS:
      return { ...state, loadingZones: false, zones: action.payload };
    case PRODUCT_LIST_TIMEZONE_FETCH:
      return { ...state, loadingZones: true };
    case PRODUCT_LIST_TIMEZONE_FETCH_FAIL:
      return {
        ...state,
        loadingZones: false,
        errorsZones: action.payload.errors,
      };

    case PRODUCT_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingUpdateSubmit: false };
    case PRODUCT_UPDATE_SUBMIT_FETCH:
      return { ...state, loadingUpdateSubmit: true };
    case PRODUCT_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        loadingUpdateSubmit: false,
        errors: action.payload.errors,
      };

    case FC_UPDATE_FETCH_SUCCESS:
      return { ...state, loadingFcUpdate: false, fc: action.payload };
    case FC_UPDATE_FETCH:
      return { ...state, loadingFcUpdate: true };
    case FC_UPDATE_FETCH_FAIL:
      return {
        ...state,
        loadingFcUpdate: false,
        errorsFc: action.payload.errors,
      };

    case FC_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingFcUpdateSubmit: false };
    case FC_UPDATE_SUBMIT_FETCH:
      return { ...state, loadingFcUpdateSubmit: true };
    case FC_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        loadingFcUpdateSubmit: false,
        errorsFc: action.payload.errors,
      };

    case FC_CHANGE_CURRENT:
      return { ...state, fc: action.payload };
    case THROTTLING_UPDATE_FETCH:
      return { ...state, loadingThrottlingUpdate: true };
    case THROTTLING_UPDATE_FETCH_SUCCESS:
      return {
        ...state,
        loadingThrottlingUpdate: false,
        throttling: action.payload,
      };
    case THROTTLING_UPDATE_FETCH_FAIL:
      return {
        ...state,
        loadingThrottlingUpdate: false,
        errorsThrottling: action.payload.errors,
      };

    case THROTTLING_UPDATE_SUBMIT_FETCH:
      return { ...state, loadingThrottlingUpdateSubmit: true };
    case THROTTLING_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingThrottlingUpdateSubmit: false };
    case THROTTLING_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        loadingThrottlingUpdateSubmit: false,
        errorsThrottling: action.payload.errors,
      };

    case THROTTLING_CHANGE_CURRENT:
      return { ...state, throttling: action.payload };

    case DND_UPDATE_FETCH:
      return { ...state, loadingDndUpdate: true };
    case DND_UPDATE_FETCH_SUCCESS:
      return { ...state, loadingDndUpdate: false, dnd: action.payload };
    case DND_UPDATE_FETCH_FAIL:
      return {
        ...state,
        loadingDndUpdate: false,
        errorsDnd: action.payload.errors,
      };

    case DND_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingDndUpdateSubmit: false };
    case DND_UPDATE_SUBMIT_FETCH:
      return { ...state, loadingDndUpdateSubmit: true };
    case DND_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        loadingDndUpdateSubmit: false,
        errorsDnd: action.payload.errors,
      };

    case DND_CHANGE_CURRENT:
      return { ...state, dnd: action.payload };

    case PRODUCT_CHANGE_CURRENT:
      return { ...state, currentProduct: action.payload };

    case UPLOADED_FONTS_SUBMIT_FETCH_SUCCESS:
      return {
        ...state,
        loadingUploadedFonts: false,
        uploadedFonts: action.payload,
      };
    case UPLOADED_FONTS_SUBMIT_FETCH:
      return {
        ...state,
        loadingUploadedFonts: true,
      };
    case UPLOADED_FONTS_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        loadingUploadedFonts: false,
      };

    default:
      return state;
  }
}
