import InputSpinner from '@Modules/analytics/cohort/components/InputSpinner';
import ListBase, { defaultActions } from '@Component/form/ListBase';
import Loading from '@Modules/analytics/cohort/components/Loading';
import { AuthorityProvider } from '@Utils/AuthorityProvider';
import { hasAnyAuthority } from '@Utils/AuthorityProvider';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { history } from '@Redux/store';
import { Card, Input } from 'antd';
import { isEmpty } from 'lodash';
import {
  alertListFetch,
  alertDelete,
  alertPauseAndResume,
} from '@Redux/slices/data/alertSlice';

const AlertList = () => {
  const alertList = useSelector((state) => state.dataModule.alerts.alertList);
  const loading = useSelector((state) => state.dataModule.alerts.loading);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const alertListFilter = {
    page: 0,
    order: 'id',
    name: null,
    direction: 'DESC',
  };

  useEffect(() => {
    getList();
  }, []);

  const getList = (body = alertListFilter) => {
    dispatch(alertListFetch(body));
  };

  const handlePressEnterInput = () => {
    getList({
      name: search,
      order: 'id',
      page: 0,
      direction: 'DESC',
    });
  };

  const handleOnChangeInput = (e) => {
    setSearch(e.target.value);
    if (isEmpty(e.target.value)) {
      getList({
        name: null,
        order: 'id',
        page: 0,
        direction: 'DESC',
      });
    }
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const formattedDate = `${month} ${day}, ${year}, ${hours}:${formattedMinutes} ${ampm}`;

    return formattedDate;
  };

  const handlePauseAndResume = async ({ id, enabled }) => {
    dispatch(alertPauseAndResume({ id, enabled, cb: getList }));
  };

  const columnList = [
    {
      sorter: false,
      title: 'ALERT NAME',
      dataIndex: 'name',
      key: 'name',
      width: 300,
    },
    {
      sorter: false,
      title: 'FREQUENCY',
      dataIndex: 'checkFrequency',
      key: 'checkFrequency',
      width: 300,
    },
    {
      sorter: false,
      title: 'LAST CHECK ',
      dataIndex: 'lastCheckDate',
      key: 'lastCheckDate',
      width: 300,
      render: (x) => (x ? formatDate(x) : '-'),
    },
    {
      title: 'ACTION',
      dataIndex: '',
      key: '-action-',
      width: '5%',
      render: (x) =>
        defaultActions(
          x,
          {
            title: x.enabled ? 'View' : 'Edit',
            action: () => history.push(`edit/${x.id}`),
            enabled: hasAnyAuthority(AuthorityProvider.ROLE_ALERT_UPDATE),
            disablePopConfirm: true,
          },
          {
            title: 'Delete',
            action: () => {
              dispatch(alertDelete({ id: x.id, cb: getList }));
            },
            enabled: hasAnyAuthority(AuthorityProvider.ROLE_ALERT_DELETE),
          },

          {
            title: x.enabled ? 'Pause' : 'Resume',
            action: () => {
              x.enabled
                ? handlePauseAndResume({ id: x.id, enabled: false })
                : handlePauseAndResume({ id: x.id, enabled: true });
            },
            enabled: hasAnyAuthority(AuthorityProvider.ROLE_ALERT_UPDATE),
          }
        ),
    },
  ];

  return (
    <Card>
      <div className="card-wrapper">
        <Input
          loading={true}
          allowClear={!loading}
          placeholder={'Search by alert name'}
          onPressEnter={handlePressEnterInput}
          onChange={(e) => {
            handleOnChangeInput(e);
          }}
          value={search}
          className="search-input"
          suffix={
            isEmpty(search) ? (
              <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            ) : (
              loading && <InputSpinner />
            )
          }
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <ListBase
          onChange={getList}
          page={alertList}
          pageRequest={alertListFilter}
          columns={columnList}
        />
      )}
    </Card>
  );
};

export default AlertList;
