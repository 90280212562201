export const journeySteps = [
  {
    step: 'audience',
    nextButtonTitle: 'SAVE & CONTINUE',
    title: 'Basic Info',
  },
  { step: 'message', nextButtonTitle: 'SAVE & CONTINUE', title: 'Message' },
  { step: 'conversion', nextButtonTitle: 'SAVE & CONTINUE', title: 'Tracking' },
  { step: 'test', nextButtonTitle: 'SAVE CAMPAIGN', title: 'Test Campaign' },
];

export const allSteps = [
  { step: 'audience', nextButtonTitle: 'SAVE & CONTINUE', title: 'Audience' },
  { step: 'when', nextButtonTitle: 'SAVE & CONTINUE', title: 'When' },
  { step: 'message', nextButtonTitle: 'SAVE & CONTINUE', title: 'Message' },
  { step: 'conversion', nextButtonTitle: 'SAVE & CONTINUE', title: 'Tracking' },
  { step: 'test', nextButtonTitle: 'PREVIEW & LAUNCH', title: 'Test Campaign' },
  {
    step: 'preview',
    nextButtonTitle: 'LAUNCH CAMPAIGN',
    title: 'Preview & Launch',
  },
];
export const transactionalSteps = [
  { step: 'audience', nextButtonTitle: 'SAVE & CONTINUE', title: 'Audience' },
  { step: 'message', nextButtonTitle: 'SAVE & CONTINUE', title: 'Message' },
  { step: 'conversion', nextButtonTitle: 'SAVE & CONTINUE', title: 'Tracking' },
  { step: 'test', nextButtonTitle: 'PREVIEW & LAUNCH', title: 'Test Campaign' },
  {
    step: 'preview',
    nextButtonTitle: 'LAUNCH CAMPAIGN',
    title: 'Preview & Launch',
  },
];
