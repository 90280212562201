export const UNINSTALL_OVERVIEW_FETCH = 'UNINSTALL_OVERVIEW_FETCH';
export const UNINSTALL_OVERVIEW_FETCH_SUCCESS =
  'UNINSTALL_OVERVIEW_FETCH_SUCCESS';
export const UNINSTALL_OVERVIEW_FETCH_FAIL = 'UNINSTALL_OVERVIEW_FETCH_FAIL';
export const UNINSTALL_OVERVIEW_CHANGE_VALUE =
  'UNINSTALL_OVERVIEW_CHANGE_VALUE';
export const UNINSTALL_USERS_FETCH = 'UNINSTALL_USERS_FETCH';
export const UNINSTALL_USERS_FETCH_SUCCESS = 'UNINSTALL_USERS_FETCH_SUCCESS';
export const UNINSTALL_USERS_FETCH_FAIL = 'UNINSTALL_USERS_FETCH_FAIL';
export const UNINSTALL_USERS_CHANGE_VALUE = 'UNINSTALL_USERS_CHANGE_VALUE';
export const UNINSTALL_ANALYZE_FETCH_LIST = 'UNINSTALL_ANALYZE_FETCH_LIST';
export const UNINSTALL_ANALYZE_FETCH_LIST_SUCCESS =
  'UNINSTALL_ANALYZE_FETCH_LIST_SUCCESS';
export const UNINSTALL_ANALYZE_FETCH_LIST_FAIL =
  'UNINSTALL_ANALYZE_FETCH_LIST_FAIL';
export const UNINSTALL_ANALYZE_CHANGE_VALUE = 'UNINSTALL_ANALYZE_CHANGE_VALUE';
export const UNINSTALL_ANALYZE_FETCH = 'UNINSTALL_ANALYZE_FETCH';
export const UNINSTALL_ANALYZE_FETCH_SUCCESS =
  'UNINSTALL_ANALYZE_FETCH_SUCCESS';
export const UNINSTALL_ANALYZE_FETCH_FAIL = 'UNINSTALL_ANALYZE_FETCH_FAIL';
