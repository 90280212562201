import { defaultActions } from '@Component/form/ListBase';
import {
  contractTemplateListFetch,
  contractTemplateDeleteFetch,
  contractTemplateDisableFetch,
  contractTemplatePublishFetch,
  contractTemplateUnpublishFetch,
  contractTemplateUpdateClear,
} from '@Redux/slices/admin/contractTemplateSlice';
import { AuthorityProvider, hasAnyAuthority } from '@Utils/AuthorityProvider';
import Card from '@Utils/Card';
import { Table } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StatusContainer from '@Component/app/StatusContainer';
import { CONTRACT_STATUS, CONTRACT_ACTIONS } from './ContractConstant';

function ContractList() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.admin.contractTemplate.loading);
  const contractList = useSelector(
    (state) => state.admin.contractTemplate.contractList
  );

  useEffect(() => {
    dispatch(contractTemplateListFetch({}));
    return () => {
      dispatch(contractTemplateUpdateClear());
    };
  }, []);

  const columns = [
    {
      title: 'title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'totalPenalty',
      dataIndex: 'totalPenalty',
      key: 'totalPenalty',
    },
    {
      title: 'status',
      key: 'status',
      dataIndex: 'status',

      render: (text) =>
        text ? (
          <StatusContainer
            bgColor={CONTRACT_STATUS[text]?.bgColor}
            color={CONTRACT_STATUS[text]?.color}
          >
            {text}
          </StatusContainer>
        ) : (
          '-'
        ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: '-action-',
      width: '5%',
      render: (x) =>
        defaultActions(
          x,

          CONTRACT_ACTIONS.VIEW.includes(x.status) && {
            link: x.id + '/view',
            title: 'View',
            enabled: hasAnyAuthority(
              AuthorityProvider.ROLE_CONTRACT_TEMPLATE_VIEW
            ),
          },
          CONTRACT_ACTIONS.EDIT.includes(x.status) && {
            link: x.id + '/edit',
            title: 'Edit',
            enabled: hasAnyAuthority(
              AuthorityProvider.ROLE_CONTRACT_TEMPLATE_UPDATE
            ),
          },
          CONTRACT_ACTIONS.DELETE.includes(x.status) && {
            action: (id) => {
              dispatch(contractTemplateDeleteFetch(id));
            },
            title: 'Delete',
            enabled: hasAnyAuthority(AuthorityProvider.ROLE_CONTRACT_DELETE),
          },
          CONTRACT_ACTIONS.PUBLISH.includes(x.status) && {
            action: (id) => {
              dispatch(contractTemplatePublishFetch(id));
            },
            title: 'Publish',
            enabled: hasAnyAuthority(
              AuthorityProvider.ROLE_CONTRACT_TEMPLATE_PUBLISH
            ),
          },
          CONTRACT_ACTIONS.UNPUBLISH.includes(x.status) && {
            action: (id) => {
              dispatch(contractTemplateUnpublishFetch(id));
            },
            title: 'UnPublish',
            enabled: hasAnyAuthority(
              AuthorityProvider.ROLE_CONTRACT_TEMPLATE_UPDATE
            ),
          },
          CONTRACT_ACTIONS.DISABLE.includes(x.status) && {
            action: (id) => {
              dispatch(contractTemplateDisableFetch(id));
            },
            title: 'Disable',
            enabled: hasAnyAuthority(
              AuthorityProvider.ROLE_CONTRACT_TEMPLATE_UPDATE
            ),
          }
        ),
    },
  ];
  return (
    <Card loading={loading}>
      <Table
        className={'custom-table'}
        columns={columns}
        dataSource={contractList}
        pagination={false}
      />
    </Card>
  );
}

export default ContractList;
