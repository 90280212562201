import UsersOverviewComponent from '../component/UsersOverviewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  insightProductUserActivityReportFetch,
  insightProductUserReachabilityReportFetch,
} from '../UsersActions';

const mapStateToProps = (state) => ({
  reachabilityLoading: state.track.users.reachabilityLoading,
  reachabilityErrors: state.track.users.reachabilityErrors,
  reachability: state.track.users.reachability,

  activityPageRequest: state.track.users.activityPageRequest,
  activityLoading: state.track.users.activityLoading,
  activityErrors: state.track.users.activityErrors,
  activity: state.track.users.activity,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      insightProductUserActivityReportFetch,
      insightProductUserReachabilityReportFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersOverviewComponent);

UsersOverviewComponent.propTypes = {};
