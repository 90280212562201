export const PRODUCT_REST_API_VIEW_FETCH = 'PRODUCT_REST_API_VIEW_FETCH';
export const PRODUCT_REST_API_VIEW_FETCH_FAIL =
  'PRODUCT_REST_API_VIEW_FETCH_FAIL';
export const PRODUCT_REST_API_VIEW_FETCH_SUCCESS =
  'PRODUCT_REST_API_VIEW_FETCH_SUCCESS';

export const GENERATE_TOKEN_FETCH = 'GENERATE_TOKEN_FETCH';
export const GENERATE_TOKEN_FETCH_FAIL = 'GENERATE_TOKEN_FETCH_FAIL';
export const GENERATE_TOKEN_FETCH_SUCCESS = 'GENERATE_TOKEN_FETCH_SUCCESS';

export const REVOKE_LOADING = 'REVOKE_LOADING';
