/* eslint-disable max-len */
import ProductSettingCustomDomainComponent from '../component/ProductSettingCustomDomainComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  customDomainUpdateFetch,
  customDomainUpdateSubmitFetch,
  customDomainChangeCurrent,
} from '../ProductSettingActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  loadingCustomDomainUpdate:
    state.setting.productSetting.loadingCustomDomainUpdate,
  loadingCustomDomainSubmit:
    state.setting.productSetting.loadingCustomDomainSubmit,
  currentURL: state.setting.productSetting.currentURL,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      customDomainUpdateFetch,
      customDomainUpdateSubmitFetch,
      customDomainChangeCurrent,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSettingCustomDomainComponent);

ProductSettingCustomDomainComponent.propTypes = {
  currentURL: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
};
