import CommunicationViewComponent from '../component/CommunicationViewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  communicationDeleteFetch,
  communicationCommunicationReportFetch,
  communicationVariationReportFetch,
  communicationUpdateMessageFetch,
  communicationUpdateAudienceFetch,
  communicationViewFetch,
  communicationListSmsChannelsFetch,
  communicationFailuresReportFetch,
} from '../CommunicationActions';
import PropTypes from 'prop-types';
import { revenueMappingUpdateFetch } from '@Redux/slices/data/revenueMappingSlice';

const mapStateToProps = (state) => ({
  loadingCommunicationReport:
    state.engage.communication.loadingCommunicationReport,
  errorsCommunicationReport:
    state.engage.communication.errorsCommunicationReport,
  communicationReport: state.engage.communication.communicationReport,

  variationReportPageRequest:
    state.engage.communication.variationReportPageRequest,
  loadingVariationReport: state.engage.communication.loadingVariationReport,
  errorsVariationReport: state.engage.communication.errorsVariationReport,
  currentVariationReport: state.engage.communication.currentVariationReport,

  loadingSmsChannels: state.engage.communication.loadingSmsChannels,
  smsChannels: state.engage.communication.smsChannels,

  loadingUpdateMessage: state.engage.communication.loadingUpdateMessage,
  currentMessage: state.engage.communication.currentMessage,
  errorUpdateMessage: state.engage.communication.currentMessage,

  currentCommunication: state.engage.communication.currentCommunication,
  loadingView: state.engage.communication.loadingView,

  pathname: state.router.location.pathname,
  currentRevenueMapping: state.dataModule.revenueMapping.currentRevenueMapping,
  communicationReportPageRequest:
    state.engage.communication.communicationReportPageRequest,
  failures: state.engage.communication.failures,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      communicationDeleteFetch,
      communicationCommunicationReportFetch,
      communicationVariationReportFetch,
      communicationUpdateMessageFetch,
      communicationUpdateAudienceFetch,
      communicationViewFetch,
      communicationListSmsChannelsFetch,
      revenueMappingUpdateFetch,
      communicationFailuresReportFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationViewComponent);

CommunicationViewComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  communicationListFetch: PropTypes.func.isRequired,
  communicationDeleteFetch: PropTypes.func.isRequired,
  communicationPageChangeCurrent: PropTypes.func.isRequired,
};
