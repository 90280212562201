import React, { useEffect } from 'react';
import { Switch, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Card from '@Utils/Card';
import { thirdPartyViewFetch } from '@Redux/slices/admin/thirdPartySlice';
import './thirdPartyView.less';
function ThirdPartyView() {
  const dispatch = useDispatch();
  const currentThirdPartyProductInfo = useSelector(
    (state) => state.admin.thirdParty.currentThirdPartyProductInfo
  );
  const currentThirdPartyProductJourneys = useSelector(
    (state) => state.admin.thirdParty.currentThirdPartyProductJourneys
  );
  const loading = useSelector((state) => state.admin.thirdParty.loading);
  const { thirdPartyId } = useParams();

  useEffect(() => {
    console.log('thirdPartyId', thirdPartyId);
    if (thirdPartyId) {
      dispatch(thirdPartyViewFetch(thirdPartyId, {}));
    }
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <Card loading={loading} styles={{ padding: 16 }}>
      <h2 className="sectionHeading">Product Info</h2>
      <div className="infoRow">
        <div className="title">NAME</div>
        <div className="description">
          {currentThirdPartyProductInfo.name
            ? currentThirdPartyProductInfo.name
            : 'No data'}
        </div>
        <div className="title">ENABLED</div>
        <Switch
          style={{ margin: 5 }}
          title="Enabled"
          disabled={true}
          defaultChecked={currentThirdPartyProductInfo.enabled}
        />
      </div>
      <hr />
      <h2 className="sectionHeading">Journeys</h2>
      <Table
        className={'custom-table'}
        columns={columns}
        dataSource={currentThirdPartyProductJourneys}
        pagination={false}
        // pagination={{
        //   current: pagination.current + 1,
        //   pageSize: pagination.pageSize,
        //   totalElements: pagination.totalElements,
        //   position: ['none', 'bottomCenter'],
        //   onChange: handleTableChange,
        //   showSizeChanger: false,
        // }}
      />
    </Card>
  );
}

export default ThirdPartyView;
