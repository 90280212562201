import BaseApi from '../../../api/BaseApi';

const basePath = '/data/customAttribute';
class CustomAttributeApi {
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static create(body) {
    return BaseApi.post(basePath + '/create', body);
  }
  static copy(id) {
    return BaseApi.post(basePath + '/copy/' + id, {});
  }
  static update(id) {
    return BaseApi.post(basePath + '/update/' + id, {});
  }
  static updateSubmit(id, body) {
    return BaseApi.post(basePath + '/updateSubmit/' + id, body);
  }
  static view(id) {
    return BaseApi.post(basePath + '/view/' + id, {});
  }
  static delete(id) {
    return BaseApi.post(basePath + '/delete/' + id, {});
  }
}

export default CustomAttributeApi;
