import { useState, useRef, useEffect } from 'react';

const useTimer = ({
  initialSeconds,
  initialTimer,
  handleResendOtpBtn,
  isTimerEnabled = () => {},
}) => {
  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref = useRef(null);
  const [timer, setTimer] = useState(initialTimer);
  const [showTimer, setShowTimer] = useState(true);

  useEffect(() => {
    // Check if there is a stored timer duration in sessionStorage
    const storedTimerDuration = sessionStorage.getItem('timerDuration');
    if (storedTimerDuration) {
      const storedDeadline = new Date(storedTimerDuration);
      const { minutes, seconds } = getTimeRemaining(storedDeadline);
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      );

      clearTimer(storedDeadline);
    } else {
      clearTimer(getDeadTime());
      setTimer(initialTimer);
    }

    isTimerEnabled();
  }, []);

  // useEffect(() => {
  // return () => {
  //     sessionStorage.removeItem('timerDuration');
  //  };
  // }, []);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      );

      if (minutes === 0 && seconds === 0) {
        setShowTimer(false);
        sessionStorage.removeItem('timerDuration');
      }
    }
  };

  const clearTimer = (e) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
    // Store the timer duration in sessionStorage
    sessionStorage.setItem('timerDuration', e.toISOString());
  };

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + initialSeconds);
    return deadline;
  };

  const handleResendButton = () => {
    clearTimer(getDeadTime());
    setTimer(initialTimer);
    setShowTimer(true);
    handleResendOtpBtn();
  };

  return { timer, showTimer, handleResendButton };
};

export default useTimer;
