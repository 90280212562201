import CustomChannelComponent from '../component/CustomChannelComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  customChannelSearchListFetch,
  customChannelToggleAddModal,
  customChannelListFetch,
  customChannelPageChangeCurrent,
  customChannelCreateFetch,
  customChannelUpdateFetch,
  customChannelUpdateSubmitFetch,
  customChannelDeleteFetch,
  customChannelChangeCurrent,
} from '../CustomChannelActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  page: state.channel.customChannel.page,
  pageRequest: state.channel.customChannel.pageRequest,
  loadingUpdateSubmit: state.channel.customChannel.loadingUpdateSubmit,
  loadingUpdate: state.channel.customChannel.loadingUpdate,
  currentCustomChannel: state.channel.customChannel.currentCustomChannel,
  loadingDelete: state.channel.customChannel.loadingDelete,
  loadingCreate: state.channel.customChannel.loadingCreate,
  loadingList: state.channel.customChannel.loadingList,
  loadingSearchList: state.channel.customChannel.loadingSearchList,
  addModalOpen: state.channel.customChannel.addModalOpen,
  currentUser: state.account.auth.currentUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      customChannelListFetch,
      customChannelSearchListFetch,
      customChannelPageChangeCurrent,
      customChannelCreateFetch,
      customChannelUpdateFetch,
      customChannelUpdateSubmitFetch,
      customChannelDeleteFetch,
      customChannelChangeCurrent,
      customChannelToggleAddModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomChannelComponent);

CustomChannelComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
};
