import React from 'react';
import { history } from '../../../../redux/store';
import UserStatus from '../model/UserStatus';
import {
  AuthorityProvider,
  hasAnyAuthority,
} from '../../../../utils/AuthorityProvider';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import { viewDateTime } from '../../../../utils/DateUtils';
import { Input, Spin, Select } from 'antd';
import { isEmpty } from 'lodash';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import Card from '@Utils/Card';

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};
class UserListComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    email: '',
    presentation: '',
  };
  componentDidMount() {
    this.props.userRoleListFetch();
    this.props.userListFetch({
      ...this.props.pageRequest,
      page: 0,
      email: null,
      presentation: null,
    });
  }

  viewItem(id) {
    if (hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_USER_VIEW)) {
      history.push(`view/${id}`);
    }
  }

  getNavigation() {
    return [
      { name: 'Home', path: '/' },
      { name: 'User List', path: '' },
    ];
  }
  handleChangeEmailSearch = (email) => {
    this.setState({ email });
    if (email === '' || email == null) {
      this.props.userListFetch({
        ...this.props.pageRequest,
        email: null,
        page: 0,
      });
    }
  };
  handleChangeNameSearch = (presentation) => {
    this.setState({ presentation });
    if (presentation === '' || presentation == null) {
      this.props.userListFetch({
        ...this.props.pageRequest,
        presentation: null,
        page: 0,
      });
    }
  };
  render() {
    const columnList = [
      {
        sorter: false,
        title: 'Name',
        dataIndex: 'presentation',
        key: 'presentation',
        render: (text, record) => (
          <a onClick={() => this.viewItem(record.id)}>{text}</a>
        ),
        // ...getColumnTextSearchProps('presentation'),
      },
      {
        sorter: false,
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        // ...getColumnTextSearchProps('phone'),
      },
      {
        sorter: false,
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        // ...getColumnTextSearchProps('email'),
      },
      {
        sorter: false,
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (x) => UserStatus[x],
        // ...getColumnEnumSearchProps('status', UserStatus),
      },
      {
        sorter: false,
        title: 'Last Seen',
        dataIndex: 'lastSeen',
        key: 'lastSeen',
        render: (x) => viewDateTime(x),
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '-action-',
        width: '5%',
        render: (x) =>
          defaultActions(
            x,
            {
              link: 'view/' + x.id,
              title: 'View',
              enabled: hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_USER_VIEW),
            },
            {
              link: 'update/' + x.id,
              title: 'Edit',
              enabled: hasAnyAuthority(
                AuthorityProvider.ROLE_ADMIN_USER_UPDATE
              ),
            }
          ),
      },
    ];

    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    return (
      <div>
        <Card>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              borderBottom: 'solid 2px #f4f9ff',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'start' }}>
              <Input
                loading={true}
                allowClear={this.props.loadingSearchUserList ? false : true}
                placeholder={'Search by user name'}
                onPressEnter={() =>
                  this.props.userSearchListFetch({
                    ...this.props.pageRequest,
                    page: 0,
                    presentation: this.state.presentation,
                  })
                }
                onChange={(e) => this.handleChangeNameSearch(e.target.value)}
                value={this.state.presentation}
                style={{ width: 300, marginRight: 30, borderRadius: 5 }}
                suffix={
                  isEmpty(this.state.presentation) ? (
                    <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  ) : (
                    this.props.loadingSearchUserList && (
                      <div style={styles.inputLoadingSpinStyle}>
                        <Spin indicator={inputLoadingSpin} />
                      </div>
                    )
                  )
                }
              />
              <Input
                loading={true}
                allowClear={this.props.loadingSearchUserList ? false : true}
                placeholder={'Search by user email'}
                onPressEnter={() =>
                  this.props.userSearchListFetch({
                    ...this.props.pageRequest,
                    page: 0,
                    email: this.state.email,
                  })
                }
                onChange={(e) => this.handleChangeEmailSearch(e.target.value)}
                value={this.state.email}
                style={{ width: 300, marginRight: 30, borderRadius: 5 }}
                suffix={
                  isEmpty(this.state.email) ? (
                    <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  ) : (
                    this.props.loadingSearchUserList && (
                      <div style={styles.inputLoadingSpinStyle}>
                        <Spin indicator={inputLoadingSpin} />
                      </div>
                    )
                  )
                }
              />
            </div>
            <Select
              onChange={(searchStatus) =>
                this.props.userListFetch({
                  ...this.props.pageRequest,
                  page: 0,
                  status: searchStatus,
                })
              }
              value={
                this.props.pageRequest && this.props.pageRequest.status
                  ? this.props.pageRequest.status
                  : null
              }
              style={{ width: 150 }}
              className={'chart-selector'}
              bordered={false}
            >
              <Select.Option value={null}>All Statuses</Select.Option>
              <Select.Option value="PENDING_VERIFICATION">
                Pending Verification
              </Select.Option>
              <Select.Option value="ENABLED">Enabled</Select.Option>
              <Select.Option value="ACCOUNT_EXPIRED">
                Account Expired
              </Select.Option>
              <Select.Option value="LOCKED">Locked</Select.Option>
              <Select.Option value="PASSWORD_EXPIRED">
                Password Expired
              </Select.Option>
            </Select>
          </div>
          {this.props.loadingUserList ? (
            <div style={styles.loadingSpinStyle}>
              <Spin indicator={loadingSpin} />
            </div>
          ) : (
            <ListBase
              onChange={this.props.userListFetch}
              page={this.props.page}
              pageRequest={this.props.pageRequest}
              columns={columnList}
              // onClick={(row) => this.viewItem(row.id)}
              createLink={
                hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_USER_CREATE)
                  ? 'create'
                  : null
              }
            />
          )}
        </Card>
      </div>
    );
  }
}

export default UserListComponent;
