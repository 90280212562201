import * as React from 'react';

function SvgWaitRange(props) {
  return (
    <svg viewBox="0 0 25 27" width="1em" height="1em" {...props}>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <circle stroke="#FFF" strokeWidth={2} cx={11.5} cy={13.5} r={11} />
        <path
          d="M8.467 20.634l4.092-4.406c1.055-.205 1.67-.731 1.842-1.58.173-.849-.07-1.538-.73-2.07v-6.52a7.75 7.75 0 11-5.204 14.576z"
          fill="#FFF"
        />
        <path
          d="M5.5 23L4 25m13.5-2l1.5 2"
          stroke="#FFF"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path d="M12 1v1" stroke="#FFF" strokeWidth={3} strokeLinecap="round" />
        <path
          stroke="#FFF"
          strokeWidth={1.5}
          strokeLinecap="round"
          d="M11.574 6.618v7.604l-3.69 4.017"
        />
        <circle stroke="#FFF" fill="#EF5350" cx={11.5} cy={14} r={1} />
      </g>
    </svg>
  );
}

export default SvgWaitRange;
