import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  uninstallAnalyzeChangeCurrent,
  uninstallAnalyzeFetch,
  uninstallAnalyzeFetchAttribute,
} from '../UninstallActions';
import styles from './uninstallAnalyze.module.less';
import { Spin, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Column } from '@ant-design/charts';
import { numberWithCommas } from '@Utils/RenderUtils';
import { isEmpty } from 'lodash';
import Card from '@Utils/Card';

const { OptGroup } = Select;

const loadingSpin = <LoadingOutlined className={styles.spinColor} spin />;

const UninstallAnalyzeComponent = () => {
  const loadingOverview = useSelector(
    (state) => state.analytics.uninstalls.loadingOverview
  );
  const uninstallAnalyzeFilter = useSelector(
    (state) => state.analytics.uninstalls.uninstallAnalyzeFilter
  );
  const uninstallAnalyze = useSelector(
    (state) => state.analytics.uninstalls.uninstallAnalyze
  );
  const attributeGroups = useSelector((state) => {
    const attributes = state.analytics.uninstalls.attributes;
    const grouped = { users: [], events: [] };
    attributes.forEach((attr) => {
      if (attr.name.indexOf('event.') === 0) {
        grouped.events.push(attr);
      } else {
        grouped.users.push(attr);
      }
    });
    return grouped;
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(uninstallAnalyzeFetchAttribute());
  }, []);

  useEffect(() => {
    if (uninstallAnalyzeFilter?.over) {
      dispatch(uninstallAnalyzeFetch(uninstallAnalyzeFilter));
    }
  }, [uninstallAnalyzeFilter]);

  const renderAttributes = (type) => {
    const overValue = uninstallAnalyzeFilter?.over;
    const splitValue = uninstallAnalyzeFilter?.split;
    return (
      <>
        {Object.keys(attributeGroups).map((group) => (
          <OptGroup
            key={group}
            label={`${group.toLocaleUpperCase()} attributes `}
          >
            {attributeGroups[group]
              .filter(
                (attr) =>
                  (type === 'split' && attr?.name !== overValue) ||
                  (type === 'over' && attr?.name !== splitValue)
              )
              .map((attr) => (
                <Select.Option
                  // defaultValue={type === 'split' ? 'Gender' : 'City'}
                  key={attr.name}
                  value={attr.name}
                  disabled={
                    (type === 'split' &&
                      group === 'events' &&
                      overValue?.indexOf('user.') === 0) ||
                    (type === 'over' &&
                      group === 'users' &&
                      splitValue?.indexOf('event.') === 0)
                  }
                >
                  {attr.title}
                </Select.Option>
              ))}
          </OptGroup>
        ))}
      </>
    );
  };

  const renderFilter = () => {
    return (
      <div
        className={styles.layoutRow}
        style={{
          backgroundColor: 'white',
          paddingLeft: '30px',
          paddingTop: '30px',
        }}
      >
        <React.Fragment>
          <div style={styles.titleText}>
            Analyze Uninstalls Over
            <Select
              showSearch
              filterOption={(input, option) => {
                if (option.children) {
                  return (
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }
              }}
              style={{ marginLeft: 10, width: 150 }}
              value={uninstallAnalyzeFilter.over}
              onChange={(value) => {
                dispatch(
                  uninstallAnalyzeChangeCurrent({
                    ...uninstallAnalyzeFilter,
                    over: value,
                  })
                );
              }}
            >
              {renderAttributes('over')}
            </Select>
          </div>{' '}
          <div style={{ paddingLeft: '50px' }}>
            Split By
            <Select
              allowClear={true}
              showSearch
              style={{ marginLeft: 10, width: 150 }}
              value={uninstallAnalyzeFilter.split}
              onChange={(value) =>
                dispatch(
                  uninstallAnalyzeChangeCurrent({
                    ...uninstallAnalyzeFilter,
                    split: value,
                  })
                )
              }
            >
              {renderAttributes('split')}
            </Select>
          </div>
        </React.Fragment>
      </div>
    );
  };

  const renderBarChart = () => {
    const data = [];
    const config = {
      data,
      xField: 'label',
      yField: 'count',
      isStack: true,
      connectedArea: {
        style: (oldStyle) => {
          return {
            fill: 'rgba(0,0,0,0.25)',
            stroke: oldStyle.fill,
            lineWidth: 0.5,
          };
        },
      },
      xAxis: { label: { autoRotate: true, autoEllipsis: true } },
      point: {
        size: 5,
        shape: 'diamond',
      },
      seriesField: 'category',
      legend: {
        position: 'right',
      },
      tooltip: {
        shared: false,
        formatter: function formatter(v) {
          return { name: v.category, value: numberWithCommas(v.count) };
        },
      },
      interactions: [{ type: 'element-highlight-by-color' }],
    };

    if (uninstallAnalyze.over && Array.isArray(uninstallAnalyze.over)) {
      if (!uninstallAnalyzeFilter?.split) {
        uninstallAnalyze.over.forEach((item) => {
          data.push({
            label: item.label,
            count: item.labelCount,
            category: '',
          });
        });
      } else {
        uninstallAnalyze.over.forEach((item) => {
          if (Object.keys(item.split).length === 0) {
            data.push({ label: item.label, count: item.labelCount });
          } else {
            for (var i = 0; i <= Object.values(item.split).length - 1; i++) {
              data.push({
                label: item.label,
                count: Object.values(item.split)[i],
                category: Object.keys(item.split)[i],
              });
            }
          }
        });
      }
    }

    return (
      <>
        {loadingOverview ? (
          <div className={styles.spin}>
            {' '}
            <Spin indicator={loadingSpin} />
          </div>
        ) : isEmpty(data) || data.length === 0 ? (
          <div
            style={{
              color: '#3d404e',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: 18,
              fontWeight: '500',
              paddingTop: 200,
              paddingBottom: 200,
              backgroundColor: 'white',
            }}
          >
            No Data Available
          </div>
        ) : (
          <Column
            {...config}
            style={{
              backgroundColor: 'white',
              paddingLeft: '30px',
              paddingBottom: '30px',
            }}
          ></Column>
        )}
      </>
    );
  };

  return (
    <Card>
      {renderFilter()}
      {renderBarChart()}
    </Card>
  );
};

export default UninstallAnalyzeComponent;
