import ProductSettingFcComponent from '../component/ProductSettingFcComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  fcUpdateFetch,
  fcUpdateSubmitFetch,
  fcChangeCurrent,
} from '../ProductSettingActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  loadingFcUpdate: state.setting.productSetting.loadingFcUpdate,
  loadingFcUpdateSubmit: state.setting.productSetting.loadingFcUpdateSubmit,
  fc: state.setting.productSetting.fc,
  errorsFc: state.setting.productSetting.errorsFc,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fcUpdateFetch,
      fcUpdateSubmitFetch,
      fcChangeCurrent,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSettingFcComponent);

ProductSettingFcComponent.propTypes = {
  currentProduct: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  productChangeCurrent: PropTypes.func.isRequired,
  productUpdateFetch: PropTypes.func.isRequired,
  productUpdateSubmitFetch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
