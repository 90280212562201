export const RELEASE_NOTE_LIST_FETCH = 'RELEASE_NOTE_LIST_FETCH';
export const RELEASE_NOTE_LIST_FETCH_SUCCESS =
  'RELEASE_NOTE_LIST_FETCH_SUCCESS';
export const RELEASE_NOTE_LIST_FETCH_FAIL = 'RELEASE_NOTE_LIST_FETCH_FAIL';
export const RELEASE_NOTE_CREATE_FETCH = 'RELEASE_NOTE_CREATE_FETCH';
export const RELEASE_NOTE_CREATE_FETCH_SUCCESS =
  'RELEASE_NOTE_CREATE_FETCH_SUCCESS';
export const RELEASE_NOTE_CREATE_FETCH_FAIL = 'RELEASE_NOTE_CREATE_FETCH_FAIL';
export const RELEASE_NOTE_UPDATE_FETCH = 'RELEASE_NOTE_UPDATE_FETCH';
export const RELEASE_NOTE_UPDATE_FETCH_SUCCESS =
  'RELEASE_NOTE_UPDATE_FETCH_SUCCESS';
export const RELEASE_NOTE_UPDATE_FETCH_FAIL = 'RELEASE_NOTE_UPDATE_FETCH_FAIL';
export const RELEASE_NOTE_UPDATE_SUBMIT_FETCH =
  'RELEASE_NOTE_UPDATE_SUBMIT_FETCH';
export const RELEASE_NOTE_UPDATE_SUBMIT_FETCH_SUCCESS =
  'RELEASE_NOTE_UPDATE_SUBMIT_FETCH_SUCCESS';
export const RELEASE_NOTE_UPDATE_SUBMIT_FETCH_FAIL =
  'RELEASE_NOTE_UPDATE_SUBMIT_FETCH_FAIL';
export const RELEASE_NOTE_DELETE_FETCH = 'RELEASE_NOTE_DELETE_FETCH';
export const RELEASE_NOTE_DELETE_FETCH_SUCCESS =
  'RELEASE_NOTE_DELETE_FETCH_SUCCESS';
export const RELEASE_NOTE_DELETE_FETCH_FAIL = 'RELEASE_NOTE_DELETE_FETCH_FAIL';
export const RELEASE_NOTE_CHANGE_CURRENT = 'RELEASE_NOTE_CHANGE_CURRENT';
