import React from 'react';
import { Link } from 'react-router-dom';
import ListBase, { defaultActions } from '@Component/form/ListBase';
import { isEmpty, isNil, cloneDeep } from 'lodash';
import {
  Button,
  Input,
  Modal,
  Spin,
  Switch,
  Empty,
  Popconfirm,
  Tooltip,
} from 'antd';
import AttributeType from '../../systemAttribute/model/AttributeType';
import { viewBoolean } from '@Utils/BooleanUtils';
import {
  InfoCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
// import { CloseOutlined } from '@ant-design/icons';
import './customEventMismatch.module.less';
import '@Component/journey/pages/root/index.less';
import ModalMismatch from './ModalMismatch';
import Card from '@Utils/Card';

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'right',
  },
  editField: {
    marginLeft: 20,
    width: 350,
  },
};
class CustomEventListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalId: null,
      name: '',
      pop: false,
      modalTest: false,
      openModalReact: false,
      nodeSelectedModal: '',
      handleShow: false,
    };
  }
  componentDidMount() {
    this.props.customEventListFetch(this.props.pageRequest);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingUpdateSubmit && !this.props.loadingUpdateSubmit) {
      this.setState({ modalId: null });
    }
    if (prevProps.loadingDelete && !this.props.loadingDelete) {
      this.props.customEventUpdateFetch(this.state.modalId);
    }
  }

  renderSystem = (x) => {
    if (isNil(x)) {
      return '--';
    }
    return x ? 'System' : 'Custom';
  };

  handleChangeSearch = (name) => {
    this.setState({ name });
    if (name === '' || name === null) {
      this.props.customEventListFetch({
        ...this.props.pageRequest,
        page: 0,
        name: null,
      });
    }
  };

  openModal = (x) => {
    this.props.customEventUpdateFetch(x.id);
    this.setState({ modalId: x.id });
  };

  onOkModal = () => {
    this.props.customEventUpdateSubmitFetch(
      this.state.modalId,
      this.props.currentCustomEvent,
      this.props.pageRequest
    );
  };

  setAttribute = (index, key, value) => {
    const clonedAttributes = cloneDeep(
      this.props.currentCustomEvent.attributes
    );
    clonedAttributes[index][key] = value;
    this.props.customEventChangeCurrent({
      ...this.props.currentCustomEvent,
      attributes: clonedAttributes,
    });
  };
  renderAttributes = () => {
    return (
      <div>
        <div style={{ fontSize: 16, fontWeight: '600', padding: 5 }}>
          {(this.props.currentCustomEvent && this.props.currentCustomEvent.name
            ? this.props.currentCustomEvent.name
            : 'Custom Events') + "'s Attributes:"}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 10,
            backgroundColor: '#EFEFEF',
          }}
        >
          <div style={{ display: 'flex', flex: 2 }}>NAME</div>
          <div style={{ display: 'flex', flex: 4 }}>TITLE</div>
          <div style={{ display: 'flex', flex: 1 }}>Type</div>
          <div style={{ display: 'flex', flex: 1 }}>DATA TYPE</div>
          <div style={{ display: 'flex', flex: 1 }}>MISMATCH</div>{' '}
          <div style={{ display: 'flex', flex: 1 }}>ACTION</div>
        </div>
        {this.props.currentCustomEvent &&
          Array.isArray(this.props.currentCustomEvent.attributes) && (
            <React.Fragment>
              <ModalMismatch />
              {this.props.currentCustomEvent.attributes.length > 0 ? (
                <React.Fragment>
                  {this.props.currentCustomEvent.attributes.map(
                    (item, index) => (
                      <div
                        key={index}
                        style={{
                          marginTop: 3,
                          marginBottom: 3,
                          display: 'flex',
                          flexDirection: 'row',
                          flex: 1,
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: 10,
                          backgroundColor: '#FCFCFC',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flex: 2,
                          }}
                        >
                          {item.name}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flex: 4,
                          }}
                        >
                          <Input
                            value={
                              this.props.currentCustomEvent &&
                              this.props.currentCustomEvent.attributes &&
                              this.props.currentCustomEvent.attributes[index]
                                .title
                            }
                            onChange={(e) =>
                              this.setAttribute(index, 'title', e.target.value)
                            }
                            style={{ width: 308 }}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                          }}
                        >
                          {this.renderSystem(item.system)}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                          }}
                        >
                          {AttributeType[item.type]}
                        </div>
                        <div style={{ display: 'flex', flex: 1 }}>
                          {item.attributeMismatchCount}
                          &nbsp;
                          {item.attributeMismatchCount ? (
                            <a
                              onClick={() => {
                                this.props.customEventMisMatchChangeCurrent({
                                  ...this.props.customEventMissMatchFilter,
                                  eventAttributeId: item.id,
                                  eventAttributeName: item.name,
                                });
                              }}
                              style={{
                                color: '#1890FF',
                                textDecoration: 'underline',
                                textDecorationColor: '#1890FF',
                              }}
                            >
                              View
                            </a>
                          ) : (
                            '0'
                          )}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                          }}
                        >
                          <Popconfirm
                            title={
                              <React.Fragment>
                                Are you sure to delete this? <br />
                                This action can not be undone.
                              </React.Fragment>
                            }
                            onConfirm={() => {
                              this.props.customEventDeleteFetch(item.id);
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                          >
                            <div className="int-list-ellipsis">
                              <Link>
                                <DeleteOutlined
                                  style={{
                                    color: 'black',
                                  }}
                                />
                              </Link>
                            </div>
                          </Popconfirm>
                        </div>
                      </div>
                    )
                  )}
                </React.Fragment>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </React.Fragment>
          )}
      </div>
    );
  };

  renderContent = () => {
    return this.props.loadingUpdate || this.props.loadingDelete ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin style={{ display: 'flex', alignSelf: 'center' }} />
      </div>
    ) : (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 20,
          }}
        >
          <div style={{ ...styles.editContainer }}>
            <div style={{ ...styles.editLabel, flex: 1.5 }}>Title</div>
            <div style={{ flex: 4 }}>
              <Input
                value={
                  this.props.currentCustomEvent &&
                  this.props.currentCustomEvent.title
                }
                onChange={(e) =>
                  this.props.customEventChangeCurrent({
                    ...this.props.currentCustomEvent,
                    title: e.target.value,
                  })
                }
                size="large"
                style={{ ...styles.editField, width: 308 }}
              />
            </div>
          </div>
          <div style={{ ...styles.editContainer }}>
            <div style={{ ...styles.editLabel, flex: 1.5 }}>Enabled</div>
            <div style={{ flex: 1.5 }}>
              <div style={{ marginLeft: 20 }}>
                <Switch
                  checked={
                    this.props.currentCustomEvent &&
                    this.props.currentCustomEvent.enabled
                  }
                  onChange={(enabled) =>
                    this.props.customEventChangeCurrent({
                      ...this.props.currentCustomEvent,
                      enabled,
                    })
                  }
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                />
              </div>
            </div>
          </div>
        </div>
        {this.renderAttributes()}
      </div>
    );
  };

  renderModalTitle = () => {
    const name =
      this.props.currentCustomEvent && this.props.currentCustomEvent.name
        ? this.props.currentCustomEvent.name
        : null;
    if (name) {
      return 'Edit Custom Event: ' + this.props.currentCustomEvent.name;
    }
    return 'Edit Custom Event';
  };

  renderModal = () => {
    return (
      <Modal
        width={900}
        title={this.renderModalTitle()}
        centered
        visible={!isNil(this.state.modalId)}
        onOk={() => this.setState({ modalId: null })}
        onCancel={() => this.setState({ modalId: null })}
        footer={[
          <Button
            key="cancel"
            shape="round"
            onClick={() => this.setState({ modalId: null })}
          >
            CANCEL
          </Button>,
          <Button
            loading={this.props.loadingUpdateSubmit}
            key="submit"
            shape="round"
            type="primary"
            onClick={() => this.onOkModal()}
          >
            Update
          </Button>,
        ]}
      >
        <div>{this.renderContent()}</div>
      </Modal>
    );
  };

  render() {
    const columnList = [
      {
        sorter: false,
        title: 'TITLE',
        dataIndex: 'title',
        key: 'title',
        // ...getColumnTextSearchProps('title'),
      },
      {
        sorter: false,
        title: 'NAME',
        dataIndex: 'name',
        key: 'name',
        // ...getColumnTextSearchProps('name'),
      },
      {
        sorter: false,
        title: 'ENABLED',
        dataIndex: 'enabled',
        key: 'enabled',
        render: (x) => viewBoolean(x),
        // ...getColumnBooleanSearchProps('enabled'),
      },
      {
        sorter: false,
        // title: 'MISMATCH',
        title: () => (
          <div style={{ display: 'flex' }}>
            {' '}
            <p>MISMATCH</p>
            <Tooltip
              placement={'top'}
              title={
                'Total number of mismatches occurred during the last 7 days'
              }
            >
              <i
                className="fl-info"
                style={{
                  color: '#63c1fb',
                  fontSize: 21,
                  fontWeight: 400,
                }}
              ></i>
            </Tooltip>
          </div>
        ),
        dataIndex: 'eventTypeMismatchCount',
        key: 'eventTypeMismatchCount',
        // render: (x) => <span>{x}</span>,
      },
      {
        title: 'ACTION',
        dataIndex: '',
        key: '-action-',
        width: '5%',
        render: (x) =>
          defaultActions(
            x,
            {
              action: () => this.openModal(x),
              title: 'Edit',
              enabled: true,
              disablePopConfirm: true,
            }
            // { link: 'copy/' + x.id, title: 'Duplicate', enabled: hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_EVENT_CREATE) },
            //  { action: (id) => this.props.customEventDeleteFetch(id, this.props.pageRequest), title: 'Delete', enabled: hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_EVENT_DELETE) },
          ),
      },
    ];
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    return (
      <>
        <Card>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              paddingBottom: 0,
              paddingLeft: '20px',
            }}
          >
            <div
              style={{
                color: '#000000',
                fontSize: 16,
                fontWeight: '600',
              }}
            >
              Custom Events
              <span style={{ paddingLeft: 10 }}>
                {false && (
                  <InfoCircleOutlined
                    style={{
                      color: '#8C8C8C',
                      fontSize: 12,
                    }}
                  />
                )}
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              borderBottom: 'solid 2px #f4f9ff',
            }}
          >
            <Input
              loading={true}
              allowClear={this.props.loadingSearchList ? false : true}
              placeholder={'Search by name'}
              onPressEnter={() =>
                this.props.customEventSearchListFetch({
                  ...this.props.pageRequest,
                  page: 0,
                  name: this.state.name,
                })
              }
              onChange={(e) => this.handleChangeSearch(e.target.value)}
              value={this.state.name}
              style={{
                width: 300,
                marginRight: 30,
                borderRadius: 5,
              }}
              suffix={
                isEmpty(this.state.name) ? (
                  <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                ) : (
                  this.props.loadingSearchList && (
                    <div style={styles.inputLoadingSpinStyle}>
                      <Spin indicator={inputLoadingSpin} />
                    </div>
                  )
                )
              }
            />
          </div>
          {this.props.loadingList ? (
            <div style={styles.loadingSpinStyle}>
              <Spin indicator={loadingSpin} />
            </div>
          ) : (
            <ListBase
              onChange={this.props.customEventListFetch}
              page={this.props.page}
              pageRequest={this.props.pageRequest}
              columns={columnList}
              // onClick={(row) => this.viewItem(row.id)}
              createLink={null}
            />
          )}
        </Card>
        {this.renderModal()}
      </>
    );
  }
}

export default CustomEventListComponent;
