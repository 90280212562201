import React, { useState, useEffect } from 'react';
import styles from './HomeComponent.module.less';
import { Input, Button } from 'antd';
import { ArrowLeftOutlined, RedoOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getResetPasswordVerificationCode } from '@Modules/account/profile/ProfileActions';
import { getCaptcha } from '@Modules/account/auth/AuthActions';

const ResetPassFirstStep = ({
  goToLoginFirstStep,
  goToResetPassSecondStep,
}) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [captchaEnabled, setCaptchaEnabled] = useState(null);
  const [email, setEmail] = useState(state?.email ?? '');
  const [captchaCode, setCaptchaCode] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [token, setToken] = useState(null);

  const { resetPasswordLoading } = useSelector(
    (state) => state.account.profile
  );

  const handleGetCaptcha = () => {
    dispatch(getCaptcha(setToken, setCaptchaEnabled));
    setCaptchaCode(null);
  };

  useEffect(() => {
    handleGetCaptcha();
  }, []);

  useEffect(() => {
    handleIsButtonDisabled();
  }, [email]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleIsButtonDisabled = () => {
    if (email !== '' && validateEmail(email)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handleGetVerificationCode = () => {
    dispatch(
      getResetPasswordVerificationCode({
        body: {
          userEmail: email,
          captchaCode: captchaEnabled ? captchaCode?.trim() : null,
          captchaToken: captchaEnabled ? token : null,
        },
        cb: goToResetPassSecondStep,
        resendCaptcha: handleGetCaptcha,
      })
    );
  };

  const handlePressEnter = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      handleGetVerificationCode();
    }
  };

  return (
    <>
      <div className={styles.form}>
        <div
          className={styles.form__back_button}
          onClick={() => goToLoginFirstStep()}
        >
          <ArrowLeftOutlined />
          <span style={{ marginLeft: '8px' }}>Back</span>
        </div>
        <div className={styles.form__header}>Reset Password</div>
        <div className={styles.form__caption}>Enter your email please:</div>
        <div className={styles['form__item--last']}>
          <div className={styles.form__title}>Email</div>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Type your Email"
            className={styles.form__input}
          />
        </div>

        <div
          className={styles['form__captcha-container']}
          style={{ display: captchaEnabled ? '' : 'none' }}
        >
          <div className={` ${styles.captchaContainer}`}>
            <img
              id="captcha-image"
              alt="captcha"
              style={{ borderRadius: '3px 0px 0px 3px' }}
            />
            <div onClick={handleGetCaptcha} className={styles.reCaptcha}>
              <RedoOutlined />
            </div>
          </div>

          <div>
            <Input
              id="int-login-captcha"
              onKeyPress={(ev) => handlePressEnter(ev)}
              value={captchaCode}
              onChange={(e) => setCaptchaCode(e.target.value)}
              placeholder="Type your captcha code"
              className={styles.form__input}
            />
          </div>
        </div>

        <div className={styles['form__button-container']}>
          <Button
            loading={resetPasswordLoading}
            onClick={handleGetVerificationCode}
            type="primary"
            className={styles.form__login}
            disabled={isDisabled}
            shape="round"
            lassName={styles.form__login}
          >
            SEND VERIFICATION CODE
          </Button>
        </div>
      </div>
    </>
  );
};

export default ResetPassFirstStep;
