import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import React from 'react';
import './Card.less';

function Card({
  children,
  loading,
  colSpan,
  styles,
  dataCy,
  id,
  title,
  linkText,
  linkHref,
}) {
  const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
  return (
    <Row>
      <Col
        id={id}
        data-cy={dataCy}
        className="mainCard"
        span={colSpan || 24}
        style={{ ...styles }}
      >
        <>
          {(title || linkText) && (
            <div className="mainCard__header">
              {title && <div className="mainCard__title">{title}</div>}
              {linkText && (
                <div>
                  <a href={linkHref} target="_blank" rel="noreferrer">
                    <span className="right-header">{linkText}</span>
                  </a>
                </div>
              )}
            </div>
          )}
          {loading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                paddingTop: 50,
                paddingBottom: 50,
              }}
            >
              <Spin className="mainCard__loading" indicator={loadingSpin} />
            </div>
          ) : (
            <main>{children}</main>
          )}
        </>
      </Col>
    </Row>
  );
}

export default Card;
