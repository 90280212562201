import BaseApi from '../../../api/BaseApi';

const basePath = '/admin/third-party';
export default class ThirdPartyApi {
  static list(productId, body) {
    return BaseApi.post(basePath + '/' + productId + `/list`, body);
  }

  static view(thirdPartyId, body) {
    return BaseApi.post(basePath + '/' + thirdPartyId + `/view`, body);
  }

  // `productId` is the `parentProductId`
  static updateEnabled(productId, body) {
    return BaseApi.post(basePath + '/' + productId + `/updateEnabled`, body);
  }
}
