import {
  WEBHOOK_CREATE_FETCH,
  WEBHOOK_CREATE_FETCH_FAIL,
  WEBHOOK_CREATE_FETCH_SUCCESS,
  WEBHOOK_CHANGE_CURRENT,
  WEBHOOK_DELETE_FETCH,
  WEBHOOK_DELETE_FETCH_FAIL,
  WEBHOOK_DELETE_FETCH_SUCCESS,
  WEBHOOK_LIST_FETCH,
  WEBHOOK_LIST_FETCH_FAIL,
  WEBHOOK_LIST_FETCH_SUCCESS,
  WEBHOOK_UPDATE_FETCH,
  WEBHOOK_UPDATE_FETCH_FAIL,
  WEBHOOK_UPDATE_FETCH_SUCCESS,
  WEBHOOK_UPDATE_SUBMIT_FETCH,
  WEBHOOK_UPDATE_SUBMIT_FETCH_FAIL,
  WEBHOOK_UPDATE_SUBMIT_FETCH_SUCCESS,
  WEBHOOK_WEBHOOK_VIEW_FETCH,
  WEBHOOK_WEBHOOK_VIEW_FETCH_FAIL,
  WEBHOOK_WEBHOOK_VIEW_FETCH_SUCCESS,
  WEBHOOK_CREATE_TOGGLE,
  WEBHOOK_PAGE_CHANGE_CURRENT,
  WEBHOOK_LIST_EVENTS,
} from './WebhookConstants';

const initialState = {
  listWebhook: {},
  loadingListWebhook: false,
  pageRequest: {},
  errorListWebhook: [],

  loadingCreate: false,
  errorCreate: [],

  loadingUpdate: false,
  currentWebhook: {},
  errorUpdate: [],

  webhookCreateOpen: false,

  errorUpdateSubmit: [],
  loadingUpdateSubmit: false,

  loadingDelete: false,
  errorDelete: [],

  loadingView: false,
  errorView: [],
  view: {},

  listEvents: [],
};

export default function ProductRestApiSettingStateReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case WEBHOOK_LIST_FETCH_SUCCESS:
      return {
        ...state,
        listWebhook: action.payload,
        loadingListWebhook: false,
      };
    case WEBHOOK_LIST_FETCH:
      return {
        ...state,
        errorListWebhook: [],
        pageRequest: action.payload,
        loadingListWebhook: true,
      };
    case WEBHOOK_LIST_FETCH_FAIL:
      return {
        ...state,
        loadingListWebhook: false,
        errorListWebhook: action.payload,
      };

    case WEBHOOK_PAGE_CHANGE_CURRENT:
      return { ...state, pageRequest: action.payload };

    case WEBHOOK_CHANGE_CURRENT:
      return { ...state, currentWebhook: action.payload };

    case WEBHOOK_CREATE_TOGGLE:
      return { ...state, webhookCreateOpen: action.payload };

    case WEBHOOK_CREATE_FETCH:
      return { ...state, loadingCreate: true };
    case WEBHOOK_CREATE_FETCH_SUCCESS:
      return { ...state, loadingCreate: false, errorCreate: [] };
    case WEBHOOK_CREATE_FETCH_FAIL:
      return { ...state, loadingCreate: false, errorCreate: action.payload };

    case WEBHOOK_UPDATE_SUBMIT_FETCH:
      return { ...state, loadingUpdateSubmit: true };
    case WEBHOOK_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingUpdateSubmit: false };
    case WEBHOOK_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        loadingUpdateSubmit: false,
        errorUpdateSubmit: action.payload,
      };

    case WEBHOOK_UPDATE_FETCH:
      return { ...state, loadingUpdate: true };
    case WEBHOOK_UPDATE_FETCH_SUCCESS:
      return { ...state, loadingUpdate: false, currentWebhook: action.payload };
    case WEBHOOK_UPDATE_FETCH_FAIL:
      return { ...state, loadingUpdate: false, errorUpdate: action.payload };

    case WEBHOOK_DELETE_FETCH:
      return { ...state, loadingDelete: true };
    case WEBHOOK_DELETE_FETCH_SUCCESS:
      return { ...state, loadingDelete: false };
    case WEBHOOK_DELETE_FETCH_FAIL:
      return { ...state, loadingDelete: false, errorDelete: action.payload };

    case WEBHOOK_WEBHOOK_VIEW_FETCH:
      return { ...state, loadingView: true };
    case WEBHOOK_WEBHOOK_VIEW_FETCH_SUCCESS:
      return { ...state, loadingView: false, view: action.payload };
    case WEBHOOK_WEBHOOK_VIEW_FETCH_FAIL:
      return { ...state, loadingView: false, errorView: action.payload };
    case WEBHOOK_LIST_EVENTS:
      return { ...state, listEvents: action.payload };
    default:
      return state;
  }
}
