exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ---blue---*/\n/* ---light theme---*/\n/* ---status---*/\n.TrOccurrenceBusinessEventConfiguration-module__toContent--UMfxv {\n  background-color: #e5e5e5;\n  border-radius: 2px;\n  padding: 4px 8px;\n  margin-right: 10px;\n  margin-left: 10px;\n  color: #1c1c1c;\n  font-size: 14px;\n  float: left;\n  height: 32px;\n}\n.TrOccurrenceBusinessEventConfiguration-module__flexRow--2o8BU {\n  display: flex;\n  flex-direction: row;\n}\n", ""]);

// exports
exports.locals = {
	"toContent": "TrOccurrenceBusinessEventConfiguration-module__toContent--UMfxv",
	"flexRow": "TrOccurrenceBusinessEventConfiguration-module__flexRow--2o8BU"
};