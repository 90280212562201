import EmailChannelComponent from '../component/EmailChannelComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  emailChannelSearchListFetch,
  emailChannelListFetch,
  emailChannelPageChangeCurrent,
  emailChannelCreateFetch,
  emailChannelUpdateFetch,
  emailChannelUpdateSubmitFetch,
  emailChannelDeleteFetch,
  emailChannelChangeCurrent,
} from '../EmailChannelActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  page: state.channel.emailChannel.page,
  pageRequest: state.channel.emailChannel.pageRequest,
  loadingUpdateSubmit: state.channel.emailChannel.loadingUpdateSubmit,
  loadingUpdate: state.channel.emailChannel.loadingUpdate,
  currentEmailChannel: state.channel.emailChannel.currentEmailChannel,
  loadingDelete: state.channel.emailChannel.loadingDelete,
  loadingCreate: state.channel.emailChannel.loadingCreate,
  loadingList: state.channel.emailChannel.loadingList,
  loadingSearchList: state.channel.emailChannel.loadingSearchList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      emailChannelListFetch,
      emailChannelSearchListFetch,
      emailChannelPageChangeCurrent,
      emailChannelCreateFetch,
      emailChannelUpdateFetch,
      emailChannelUpdateSubmitFetch,
      emailChannelDeleteFetch,
      emailChannelChangeCurrent,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailChannelComponent);

EmailChannelComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  customAttributeListFetch: PropTypes.func.isRequired,
  customAttributeDeleteFetch: PropTypes.func.isRequired,
  customAttributePageChangeCurrent: PropTypes.func.isRequired,
};
