import React from 'react';
import ListBase from '../../../../component/form/ListBase';
import { Link } from 'react-router-dom';
import { readableDateTime } from '../../../../utils/DateUtils';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { Tooltip, Spin, Input } from 'antd';
import { isEmpty, isNil } from 'lodash';
import { getJourneyId } from '../../../../utils/AuthorityProvider';
import { viewBoolean } from '@Utils/BooleanUtils';
import { HashBase64 } from '@Component/utils/HashBase64';
import vars from '@Theme/styles/vars.js';
import CsvButton from '@Component/utils/CsvExport/CsvButton';
import Card from '@Utils/Card';
import { featureAccessCheckCSVExport } from '@Utils/AccessManagement';
class JourneyEngagedUserComponent extends React.Component {
  constructor(props) {
    super(props);
    this.journeyId = getJourneyId(this.props.pathname);
    this.state = {
      name: '',
      selector: null,
      csvModalVisible: false,
    };
    console.log('journeyid', this.journeyId);
  }

  componentDidMount() {
    this.props.journeyEngagedUserReportFetch({
      ...this.props.journeyEngagedUserPageRequest,
      page: 0,
      journeyId: this.journeyId,
      query: '',
    });
  }
  handleChangeSearch = (name) => {
    this.setState({ name });
    if (name === '' || name == null) {
      this.props.journeyEngagedUserReportFetch({
        ...this.props.journeyEngagedUserPageRequest,
        page: 0,
        journeyId: this.journeyId,
        query: '',
      });
    }
  };
  renderUserId = (object) => {
    if (!isNil(object.userId)) {
      return object.userId;
    }
    return (
      <Tooltip placement={'top'} title={'Anonymous'}>
        {object.anonymousId}
        <i
          className="fl-info"
          style={{
            color: '#bbbcc1',
            fontSize: 24,
            fontWeight: 400,
            position: 'relative',
            top: 6,
          }}
        ></i>
      </Tooltip>
    );
  };

  getItemLink = (row) => {
    const hashedUserId = HashBase64().encode(row.id);
    return `../../users/view/${hashedUserId}/basic`;
  };

  render() {
    let columns;
    columns = [
      {
        title: 'ID',
        dataIndex: 'userId',
        key: 'userId',
        fixed: 'left',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 9 };
          }
        },
        render: (x, object) => (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Link
              to={this.getItemLink(object)}
              style={{ width: object.deleted ? 1000 : null }}
            >
              <span
                style={{
                  color: vars.primaryColor,
                  fontSize: 14,
                  width: object.deleted ? 1000 : null,
                }}
              >
                {this.renderUserId(object)}
              </span>
            </Link>

            {object.deleted && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'left',
                  fontSize: 15,
                }}
              >
                Data of this record is outdated
              </div>
            )}
          </div>
        ),
      },
      {
        title: 'START DATE',
        dataIndex: 'startDate',
        key: 'startDate',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 0 };
          }
        },
        render: (x) => !isNil(x) && readableDateTime(x),
      },
      {
        title: 'END DATE',
        dataIndex: 'endDate',
        key: 'endDate',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 0 };
          }
        },
        render: (x) => !isNil(x) && readableDateTime(x),
      },
      {
        title: 'END REASON',
        dataIndex: 'endReason',
        key: 'endReason',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 0 };
          }
        },
      },
      {
        title: 'CONVERTED',
        dataIndex: 'converted',
        key: 'converted',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 0 };
          }
        },
        render: (x) => (isNil(x) ? '-' : viewBoolean(x)),
      },
      {
        title: 'CONTROL GROUP',
        dataIndex: 'controlGroup',
        key: 'controlGroup',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 0 };
          }
        },
        render: (x) => (isNil(x) ? '-' : viewBoolean(x)),
      },
      {
        title: 'CURRENT STEPS',
        dataIndex: 'currentSteps',
        key: 'currentSteps',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 0 };
          }
        },
        render: (x) => !isNil(x) && x.join('-'),
      },
      {
        title: 'VISITED STEPS',
        dataIndex: 'visitedSteps',
        key: 'visitedSteps',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 0 };
          }
        },
        render: (x) => !isNil(x) && x.join('-'),
      },
    ];
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    return (
      <div>
        <Card>
          {/* {this.state.csvModalVisible && (
            <ExportCsvModal
              currentUserEmail={
                this.props.currentUser && this.props.currentUser.email
              }
              setCsvModalVisibilityFalse={() =>
                this.setState({ csvModalVisible: false })
              }
              csvModalVisible={this.state.csvModalVisible}
            ></ExportCsvModal>
          )} */}
          <div
            style={{
              paddingLeft: 20,
              paddingTop: 20,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Input
              loading={true}
              allowClear={this.props.loadingJourneyEngagedUser ? false : true}
              placeholder={'Search Users by ID, Phone or Email'}
              onPressEnter={() =>
                this.props.journeyEngagedUserReportFetch({
                  ...this.props.journeyEngagedUserPageRequest,
                  page: 0,
                  journeyId: this.journeyId,
                  query: this.state.name,
                })
              }
              onChange={(e) => this.handleChangeSearch(e.target.value)}
              value={this.state.name}
              style={{ width: 300, borderRadius: 5 }}
              suffix={
                isEmpty(this.state.name) ? (
                  <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                ) : (
                  this.props.loadingJourneyEngagedUser && (
                    <div>
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 18 }} spin />
                        }
                      />
                    </div>
                  )
                )
              }
            />
            {featureAccessCheckCSVExport() && (
              <CsvButton
                label="List Of Users"
                callApi={() => this.props.callExportJourneyFile(this.journeyId)}
                type="communication"
              ></CsvButton>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              borderBottom: 'solid 2px #f4f9ff',
            }}
          ></div>
          {this.props.loadingJourneyEngagedUser ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                paddingTop: 50,
                paddingBottom: 50,
              }}
            >
              <Spin indicator={loadingSpin} />
            </div>
          ) : (
            <ListBase
              onChange={this.props.journeyEngagedUserReportFetch}
              page={this.props.journeyEngagedUser}
              pageRequest={this.props.journeyEngagedUserPageRequest}
              columns={columns}
              //   createLink={
              //     hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_USER_CREATE)
              //       ? 'create'
              //       : null
              //   }
            />
          )}
        </Card>
      </div>
    );
  }
}

export default JourneyEngagedUserComponent;
