import ApiTokenViewComponent from '../component/ApiTokenViewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  apiTokenCreateFetch,
  apiTokenRevokeFetch,
  apiTokenRevokeAllFetch,
  apiTokenListFetch,
  apiTokenPageChangeCurrent,
  apiTokenModalView,
  apiTokenRevokeAllModalToggle,
} from '../ApiTokenActions';
import PropTypes from 'prop-types';
import { productRestApiViewFetch } from '@Modules/integration/productRestApi/ProductRestApiActions';

const mapStateToProps = (state) => ({
  tokensList: state.integration.productRestApiTokens.tokensList,
  loadingTokensList: state.integration.productRestApiTokens.loadingTokensList,
  pageRequest: state.integration.productRestApiTokens.pageRequest,
  errorListTokens: state.integration.productRestApiTokens.errorListTokens,
  loadingCreate: state.integration.productRestApiTokens.loadingCreate,
  errorCreate: state.integration.productRestApiTokens.errorCreate,
  createdTokenDetails:
    state.integration.productRestApiTokens.createdTokenDetails,
  tokenDetailsModalView:
    state.integration.productRestApiTokens.tokenDetailsModalView,
  loadingRevoke: state.integration.productRestApiTokens.loadingRevoke,
  errorRevoke: state.integration.productRestApiTokens.errorRevoke,
  loadingRevokeAll: state.integration.productRestApiTokens.loadingRevokeAll,
  tokenRevokeAllModalView:
    state.integration.productRestApiTokens.tokenRevokeAllModalView,
  errorRevokeAll: state.integration.productRestApiTokens.errorRevokeAll,
  currentProductRestApi:
    state.integration.productRestApiTokens.currentProductRestApi,
  loadingProjectCredentials:
    state.integration.productRestApiTokens.loadingProjectCredentials,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      apiTokenCreateFetch,
      apiTokenRevokeFetch,
      apiTokenRevokeAllFetch,
      apiTokenListFetch,
      apiTokenPageChangeCurrent,
      apiTokenModalView,
      apiTokenRevokeAllModalToggle,
      productRestApiViewFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiTokenViewComponent);

ApiTokenViewComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
};
