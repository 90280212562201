import BaseApi from '../../../api/BaseApi';

const basePath = '/setting/teamMembers';
class ProductUserApi {
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static view(id) {
    return BaseApi.post(basePath + '/view/' + id, {});
  }
  static delete(id) {
    return BaseApi.post(basePath + '/delete/' + id, {});
  }
  static listProductRoles() {
    return BaseApi.post(basePath + '/listProductRoles', {});
  }
  static createTeamMember(body) {
    return BaseApi.post(basePath + '/create', body);
  }
  static updateSubmitTeamMember(id, body) {
    return BaseApi.post(basePath + '/updateSubmit/' + id, body);
  }
  static resendInvitation(id) {
    return BaseApi.post(basePath + '/sendInvite/' + id, {});
  }
  static updateTeamMember(id) {
    return BaseApi.post(basePath + '/update/' + id, {});
  }
  static register(body, token) {
    return BaseApi.post('/auth/authentication/register?token=' + token, body);
  }
  static allTeam(body) {
    return BaseApi.post(basePath + '/all', body);
  }
}

export default ProductUserApi;
