import JourneyListComponent from '../component/JourneyListComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  journeySearchListFetch,
  journeyPageChangeCurrent,
  journeyListFetch,
  journeyDeleteFetch,
  journeyCopyFetch,
  journeyStop,
  journeyPause,
  journeyUpdateTags,
  journeyUpdateSubmitTags,
  journeyChangeCurrent,
  journeyArchive,
  journeyActivate,
} from '../JourneyActions';
import {
  communicationDraftSaveDiscard,
  communicationListTagsFetch,
} from '../../communication/CommunicationActions';
import { changeMenuLayout } from '../../../account/auth/AuthActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  loadingUpdateJourney: state.engage.journey.loadingUpdateJourney,
  loadingUpdateSubmitJourney: state.engage.journey.loadingUpdateSubmitJourney,
  errorUpdateSubmitJourney: state.engage.journey.errorUpdateSubmitJourney,
  currentJourney: state.engage.journey.currentJourney,
  loadingListTags: state.engage.communication.loadingListTags,
  listTags: state.engage.communication.listTags,
  page: state.engage.journey.page,
  pageRequest: state.engage.journey.pageRequest,
  pathname: state.router.location.pathname,
  loadingList: state.engage.journey.loadingList,
  loadingSearchList: state.engage.journey.loadingSearchList,
  archiveSuccess: state.engage.journey.archiveSuccess,
  activeSuccess: state.engage.journey.activeSuccess,
  currentUser: state.account.auth.currentUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      journeyPageChangeCurrent,
      journeyListFetch,
      journeySearchListFetch,
      journeyDeleteFetch,
      changeMenuLayout,
      journeyCopyFetch,
      journeyStop,
      journeyPause,
      journeyUpdateTags,
      journeyUpdateSubmitTags,
      journeyChangeCurrent,
      communicationListTagsFetch,
      communicationDraftSaveDiscard,
      journeyArchive,
      journeyActivate,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JourneyListComponent);

JourneyListComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  communicationListFetch: PropTypes.func.isRequired,
  communicationDeleteFetch: PropTypes.func.isRequired,
  communicationPageChangeCurrent: PropTypes.func.isRequired,
};
