import {
  OVERVIEW_LIST_TAG_FETCH,
  OVERVIEW_LIST_TAG_FETCH_FAIL,
  OVERVIEW_LIST_TAG_FETCH_SUCCESS,
  OVERVIEW_FETCH,
  OVERVIEW_FETCH_FAIL,
  OVERVIEW_FETCH_SUCCESS,
  OVERVIEW_SPLIT_OVERVIEW_FETCH,
  OVERVIEW_SPLIT_OVERVIEW_FETCH_FAIL,
  OVERVIEW_SPLIT_OVERVIEW_FETCH_SUCCESS,
} from './OverviewConstants';
const initialState = {
  loadingListTags: false,
  loadingOverview: false,
  loadingSplitOverview: false,

  errorsListTags: [],
  errorsOverview: [],
  errorsSplitOverview: [],

  listTags: [],
  overview: null,
  splitOverview: null,

  pageRequestOverview: {},
  pageRequestSplitOverview: {},
};

export default function OverviewStateReducer(state = initialState, action) {
  switch (action.type) {
    case OVERVIEW_LIST_TAG_FETCH:
      return { ...state, loadingListTags: true };
    case OVERVIEW_LIST_TAG_FETCH_FAIL:
      return {
        ...state,
        loadingListTags: false,
        errorsListTags: action.payload,
      };
    case OVERVIEW_LIST_TAG_FETCH_SUCCESS:
      return { ...state, loadingListTags: false, listTags: action.payload };

    case OVERVIEW_FETCH:
      return {
        ...state,
        loadingOverview: true,
        pageRequestOverview: action.payload,
      };
    case OVERVIEW_FETCH_FAIL:
      return {
        ...state,
        loadingOverview: false,
        errorsOverview: action.payload,
      };
    case OVERVIEW_FETCH_SUCCESS:
      return { ...state, loadingOverview: false, overview: action.payload };

    case OVERVIEW_SPLIT_OVERVIEW_FETCH:
      return {
        ...state,
        loadingSplitOverview: true,
        pageRequestSplitOverview: action.payload,
      };
    case OVERVIEW_SPLIT_OVERVIEW_FETCH_FAIL:
      return {
        ...state,
        loadingSplitOverview: false,
        errorsSplitOverview: action.payload,
      };
    case OVERVIEW_SPLIT_OVERVIEW_FETCH_SUCCESS:
      return {
        ...state,
        loadingSplitOverview: false,
        splitOverview: action.payload,
      };

    default:
      return state;
  }
}
