// import CommunicationConversionComponent from '../component/CommunicationConversionComponent';
import CommunicationConversion from '../component/communicationConversion/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  communicationUpdateConversionFetch,
  communicationUpdateSubmitConversionFetch,
  communicationListEventsFetch,
  communicationLaunchFetch,
  clearCommunicationTrackingError,
} from '../CommunicationActions';
import {
  productSegmentRecommendUserAttributesFetch,
  productSegmentRecommendDeviceAttributesFetch,
  productSegmentRecommendEventAttributesFetch,
} from '../../../insight/productSegment/ProductSegmentActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  page: state.engage.communication.page,
  pageRequest: state.engage.communication.pageRequest,
  pathname: state.router.location.pathname,
  loadingEvents: state.engage.communication.loadingEvents,
  loadingUpdateConversion: state.engage.communication.loadingUpdateConversion,
  loadingUpdateConversionSubmit:
    state.engage.communication.loadingUpdateConversionSubmit,
  currentConversion: state.engage.communication.currentConversion,
  currentCommunication: state.engage.communication.currentCommunication,
  eventList: state.engage.communication.eventList,
  communicationData: state.engage.communication.communicationData,
  errorUpdateConversionSubmit:
    state.engage.communication.errorUpdateConversionSubmit,
  loadingRecommendUserAttributes:
    state.insight.productSegment.loadingRecommendUserAttributes,
  recommendUserAttributes: state.insight.productSegment.recommendUserAttributes,
  loadingRecommendEventAttributes:
    state.insight.productSegment.loadingRecommendEventAttributes,
  recommendEventAttributes:
    state.insight.productSegment.recommendEventAttributes,
  loadingRecommendDeviceAttributes:
    state.insight.productSegment.loadingRecommendDeviceAttributes,
  recommendDeviceAttributes:
    state.insight.productSegment.recommendDeviceAttributes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      communicationListEventsFetch,
      communicationUpdateSubmitConversionFetch,
      communicationUpdateConversionFetch,
      communicationLaunchFetch,
      clearCommunicationTrackingError,
      productSegmentRecommendUserAttributesFetch,
      productSegmentRecommendDeviceAttributesFetch,
      productSegmentRecommendEventAttributesFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationConversion);

CommunicationConversion.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  communicationListEventsFetch: PropTypes.func.isRequired,
  communicationUpdateSubmitConversionFetch: PropTypes.func.isRequired,
  communicationUpdateConversionFetch: PropTypes.func.isRequired,
};
