import { Alert, Card, Col, Input, Radio, Row, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app';
import './styles.less';

const deadlineTypes = [
  {
    name: 'hours',
    displayName: 'Hours',
  },
  {
    name: 'days',
    displayName: 'Days',
  },
];

const FreqDndStep = (props) => {
  const { journey, syncCache } = useContext(AppContext);
  const [fc, setFc] = useState(true);
  const [dnd, setDnd] = useState(true);
  const [queue, setQueue] = useState(false);
  const [deadline, setDeadline] = useState(2);
  const [deadLineType, setDeadLineType] = useState('hours');

  useEffect(() => {
    //note: Just on response of update props.data.journey filled
    if (journey) {
      setFc(journey?.followFrequencyCapping);
      setDnd(journey?.followDnd);
      setQueue(journey?.queueMinutes ? true : false);
      const deadLineTmp = getDefaultDeadlineType();
      setDeadline(deadLineTmp.time);
      setDeadLineType(deadLineTmp.type);
    }
  }, [journey]);

  const onFcChange = (e) => {
    const val = e.target.value;
    setFc(val);
    syncCache('followFrequencyCapping', val);
  };

  const onDndChange = (e) => {
    const val = e.target.value;
    setDnd(val);
    syncCache('followDnd', val);
  };

  const onQuChange = (e) => {
    const val = e.target.value;
    setQueue(val);
    if (!val) {
      syncCache('queueMinutes', 0);
    } else {
      onInputDeadlineChange(2);
    }
  };

  const onInputDeadlineChange = (e) => {
    const value = e?.target?.value || e;
    setDeadline(value);
    let mValue = 0;
    if (deadLineType === 'hours') {
      mValue = 60 * value;
    } else {
      mValue = 60 * 24 * value;
    }
    syncCache('queueMinutes', mValue);
  };

  const onChangeSelectType = (type) => {
    setDeadLineType(type);
    const value = deadline;
    let mValue = 0;
    if (type === 'hours') {
      mValue = 60 * value;
    } else {
      mValue = 60 * 24 * value;
    }
    syncCache('queueMinutes', mValue);
  };

  const getDefaultDeadlineType = () => {
    let time_ = 7;
    let type_ = 'hours';
    if (journey?.queueMinutes) {
      if (Number(journey?.queueMinutes) / 2880 < 1) {
        type_ = 'hours';
        time_ = (journey?.queueMinutes / 60).toFixed();
      } else {
        type_ = 'days';
        time_ = (journey?.queueMinutes / 1440).toFixed();
      }
    }
    return {
      time: time_,
      type: type_,
    };
  };

  return (
    props.currentStep === 'frequencyCappingandDND' && (
      <div>
        <Row>
          {!props?.readOnly && (
            <Col span={20} offset={1}>
              <Card
                // title="Exit Trigger Type"
                bordered={false}
                style={{ minHeight: '300px', marginBottom: 30 }}
              >
                <Alert
                  message={`Frequency Capping, DND & Queuing settings apply to all Push, SMS and Email communications that are a part of this ${props.type.toLowerCase()}`}
                  type="info"
                  showIcon
                />
                <br />
                <div>
                  <div className={'freq_row'}>
                    <div className={'freq_row__label'}>FC</div>
                    <div className={'freq_row__body'}>
                      <Radio.Group onChange={onFcChange} value={fc}>
                        <div>
                          <Radio value={true}>
                            Follow Frequency Capping settings. Do not send
                            messages to a user if limit for that day/week/month
                            is reached for that user
                          </Radio>
                        </div>
                        <div>
                          <Radio value={false}>
                            Ignore Frequency Capping settings. Send messages to
                            a user even if limit for that day/week/month is
                            reached for that user
                          </Radio>
                        </div>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className={'freq_row'}>
                    <div className={'freq_row__label'}>DND</div>
                    <div className={'freq_row__body'}>
                      <Radio.Group onChange={onDndChange} value={dnd}>
                        <div>
                          <Radio value={true}>
                            Follow DND settings. Do not send messages during DND
                            hours
                          </Radio>
                        </div>
                        <div>
                          <Radio value={false}>
                            Ignore DND settings. Send messages during DND hours
                          </Radio>
                        </div>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className={'freq_row'}>
                    <div className={'freq_row__label'}>QUEUEING</div>
                    <div className={'freq_row__body'}>
                      <Radio.Group onChange={onQuChange} value={queue}>
                        <div>
                          <Radio value={true}>Queue message for up to</Radio>
                        </div>
                        <div className={'covtrac_deadline'}>
                          <Input
                            disabled={!queue}
                            style={{ width: 100 }}
                            defaultValue={deadline}
                            onChange={onInputDeadlineChange}
                          />
                          <Select
                            disabled={!queue}
                            style={{ width: 200, margin: '0 20px' }}
                            placeholder="Select a type"
                            onChange={onChangeSelectType}
                            defaultValue={deadLineType}
                          >
                            {deadlineTypes.map((item) => (
                              <Select.Option key={item.key} value={item.name}>
                                {item.displayName}
                              </Select.Option>
                            ))}
                          </Select>
                          <p> from delivery of message to user</p>
                        </div>
                        <div>
                          <Radio value={false}>
                            Do not queue messages. Drop the message and do not
                            deliver later if delivery does not happen
                            immediately
                          </Radio>
                        </div>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          )}
          {props.readOnly && (
            <div>
              <div className={'freq_row'}>
                <div className={'freq_row__label'}>FC</div>
                <div className={'freq_row__body'}>
                  {journey?.followFrequencyCapping ? (
                    <div>
                      Follow Frequency Capping settings. Do not send messages to
                      a user if limit for that day/week/month is reached for
                      that user
                    </div>
                  ) : (
                    <div>
                      Ignore Frequency Capping settings. Send messages to a user
                      even if limit for that day/week/month is reached for that
                      user
                    </div>
                  )}
                </div>
              </div>
              <div className={'freq_row'}>
                <div className={'freq_row__label'}>DND</div>
                <div className={'freq_row__body'}>
                  {journey?.followDnd ? (
                    <div>
                      Follow DND settings. Do not send messages during DND hours
                    </div>
                  ) : (
                    <div>
                      Ignore DND settings. Send messages during DND hours
                    </div>
                  )}
                </div>
              </div>
              <div className={'freq_row'}>
                <div className={'freq_row__label'}>QUEUEING</div>
                <div className={'freq_row__body freq_row__body__read_only'}>
                  {journey?.queueMinutes ? (
                    <div>
                      <span>Queue message for up to</span>
                      <span>{deadline}</span>
                      <span>{deadLineType}</span>
                      <span>from delivery of message to user</span>
                    </div>
                  ) : (
                    <div>
                      Do not queue messages. Drop the message and do not deliver
                      later if delivery does not happen immediately
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </Row>
      </div>
    )
  );
};

export default FreqDndStep;
