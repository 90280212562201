import BaseApi from '../../../api/BaseApi';

const basePath = '/engagement/communication';

class OverviewApi {
  static listTags() {
    return BaseApi.post(basePath + '/listTags', {});
  }
  static overview(body) {
    return BaseApi.post(basePath + '/overview', body);
  }
  static splitOverview(body) {
    return BaseApi.post(basePath + '/splitOverview', body);
  }
}

export default OverviewApi;
