import React from 'react';
import PagesWrapper from '@Component/layout/PagesWrapper';
import PagesHeader from '@Component/layout/PagesHeader';
import {
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';
import { history } from '../../../redux/store';
import AlertList from './alertList';
import { Button } from 'antd';
import CreateAlert from './createAlert';

const Alerts = () => {
  const { productId } = useParams();
  let match = useRouteMatch();

  const handleCreateAlert = () => {
    history.push(`/product/${productId}/alerts/create`);
  };

  const renderHeaderAction = () => {
    return (
      <>
        {location.pathname.includes('alerts/list') ? (
          <Button type="primary" onClick={handleCreateAlert}>
            New
          </Button>
        ) : null}
      </>
    );
  };

  return (
    <>
      <PagesHeader
        title="Alerts"
        hasBackButton={
          location.pathname.includes('alerts/create') ||
          location.pathname.includes('edit')
            ? true
            : false
        }
        backUrl={`/product/${productId}/alerts/list`}
        actions={renderHeaderAction()}
      />
      <PagesWrapper>
        <Switch>
          <Route exact path={`${match.path}list`}>
            <AlertList />
          </Route>
          <Route exact path={`${match.path}create`}>
            <CreateAlert />
          </Route>
          <Route exact path={`${match.path}edit/:alertId`}>
            <CreateAlert />
          </Route>
        </Switch>
      </PagesWrapper>
    </>
  );
};

export default Alerts;
