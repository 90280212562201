import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router';
import ProfileUpdateContainer from './container/ProfileUpdateContainer';
import ProfileCsvExportLog from '@Component/utils/CsvExport/CsvExportLog';
import ProfileChangePasswordContainer from './container/ProfileChangePasswordContainer';

function Profile() {
  let match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route exact path={`${match.path}update`}>
          <ProfileUpdateContainer />
        </Route>
        <Route path={`${match.path}csv-export-log`}>
          <ProfileCsvExportLog type="PROFILE" />
        </Route>
        <Route path={`${match.path}changePassword`}>
          <ProfileChangePasswordContainer />
        </Route>
      </Switch>
    </div>
  );
}

export default Profile;
