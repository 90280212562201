import React from 'react';
import { Input, InputNumber, Select, Spin } from 'antd';
import { WhatsAppProvider } from '../model/WhatsAppConst';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  whatsAppChannelData,
  setCurrentWhatsAppChannel,
} from '@Redux/slices/channel/whatsAppChannelSlice';
import { checkPermissions } from '../model/WhatsAppConst';

const WhatsAppProviderModalContent = () => {
  const dispatch = useDispatch();
  const permissions = checkPermissions();

  const { loadingUpdate, currentWhatsAppChannel } = useSelector(
    whatsAppChannelData,
    shallowEqual
  );

  const handleInputChange = (e) => {
    dispatch(
      setCurrentWhatsAppChannel({
        ...currentWhatsAppChannel,
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleSelectChange = (value, name) => {
    dispatch(
      setCurrentWhatsAppChannel({
        ...currentWhatsAppChannel,
        [name]: value,
      })
    );
  };

  return loadingUpdate ? (
    <div style={styles.spinnerContainer}>
      <Spin style={styles.spinner} />
    </div>
  ) : (
    <>
      <div style={styles.formContainer}>
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>
            <span style={styles.requiredMark}>*</span>WHATSAPP SERVICE PROVIDER
          </div>
          <div style={styles.editFieldContainer}>
            <Select
              value={currentWhatsAppChannel?.vendor ?? null}
              style={styles.editField}
              size="large"
              disabled={!permissions?.createOrUpdate}
              onChange={(vendor) => handleSelectChange(vendor, 'vendor')}
            >
              {Object.keys(WhatsAppProvider).map((item) => (
                <Select.Option value={item} key={item}>
                  {WhatsAppProvider[item]}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>
            <span style={styles.requiredMark}>*</span>Name
          </div>
          <div style={styles.editFieldContainer}>
            <Input
              name="name"
              disabled={!permissions?.createOrUpdate}
              onChange={handleInputChange}
              value={currentWhatsAppChannel?.name ?? ''}
              size="large"
              style={styles.editField}
            />
          </div>
        </div>
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>
            <span style={styles.requiredMark}>*</span>API KEY
          </div>
          <div style={styles.editFieldContainer}>
            <Input.Password
              name="apiKey"
              autoComplete="new-password"
              disabled={!permissions?.createOrUpdate}
              onChange={handleInputChange}
              value={
                permissions?.createOrUpdate
                  ? currentWhatsAppChannel?.apiKey ||
                    (currentWhatsAppChannel?.createdDate && '***')
                  : ''
              }
              size="large"
              style={styles.editField}
            />
          </div>
        </div>
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>
            <span style={styles.requiredMark}>*</span>Sender No.
          </div>
          <div style={styles.editFieldContainer}>
            <Input
              name="sender"
              disabled={!permissions?.createOrUpdate}
              onChange={handleInputChange}
              value={currentWhatsAppChannel?.sender ?? ''}
              size="large"
              style={styles.editField}
            />
          </div>
        </div>
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>
            <span style={styles.requiredMark}>*</span>
            Base URL
          </div>
          <div style={styles.editFieldContainer}>
            <Input
              name="baseUrl"
              disabled={!permissions?.createOrUpdate}
              onChange={handleInputChange}
              value={currentWhatsAppChannel?.baseUrl ?? ''}
              size="large"
              style={styles.editField}
            />
          </div>
        </div>
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>Batch Size</div>
          <div style={styles.editFieldContainer}>
            <InputNumber
              name="batchSize"
              disabled={!permissions?.createOrUpdate}
              onChange={(e) => {
                dispatch(
                  setCurrentWhatsAppChannel({
                    ...currentWhatsAppChannel,
                    batchSize: e,
                  })
                );
              }}
              value={currentWhatsAppChannel?.batchSize ?? ''}
              size="large"
              style={styles.editField}
              min={2}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatsAppProviderModalContent;

const styles = {
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    display: 'flex',
    alignSelf: 'center',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    fontWeight: '600',
    flex: 1,
  },
  editFieldContainer: {
    flex: 1,
  },
  editField: {
    marginLeft: 20,
    width: 350,
  },
  requiredMark: {
    color: '#ff4d4f',
    fontSize: '14px',
    marginRight: '4px',
  },
};
