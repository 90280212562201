import SearchUserListBase from '../../../../component/form/searchUserListBase/SearchUserListBase';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  insightProductUserSearchListFetch,
  insightProductUserListFetch,
  insightProductUserPageChangeCurrent,
  insightProductUserListAttributeFetch,
} from '../UsersActions';
import { callExportSegmentFile } from '@Component/utils/CsvExport/CsvExportActions';

const mapStateToProps = (state) => ({
  usersListPageRequest: state.track.users.usersListPageRequest,
  usersListLoading: state.track.users.usersListLoading,
  usersSerachListLoading: state.track.users.usersSerachListLoading,
  usersListErrors: state.track.users.usersListErrors,
  usersList: state.track.users.usersList,
  attributesListLoading: state.track.users.attributesListLoading,
  attributesListErrors: state.track.users.attributesListErrors,
  attributesList: state.track.users.attributesList,
  pathname: state.router.location.pathname,
  csvExport: false,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      insightProductUserListFetch,
      insightProductUserSearchListFetch,
      insightProductUserPageChangeCurrent,
      insightProductUserListAttributeFetch,
      callExportSegmentFile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchUserListBase);

SearchUserListBase.propTypes = {};
