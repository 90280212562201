exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".GatewayResponse-module__response--1sQvz {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n.GatewayResponse-module__responseCard--bq42_ {\n  flex-flow: column;\n  align-items: center;\n  padding: 40px;\n  background: #fff;\n  box-shadow: 0px 3px 6px 0px #0000000D;\n  text-align: center;\n  width: 500px;\n}\n.GatewayResponse-module__responseIcon--2Px8N {\n  margin: 0 auto;\n  max-width: 150px;\n  padding-bottom: 20px;\n}\n.GatewayResponse-module__responseData--3e5C2 {\n  margin-bottom: 20px;\n}\n.GatewayResponse-module__responseButton--1xDyj {\n  height: 48px !important;\n  width: 400px;\n}\n", ""]);

// exports
exports.locals = {
	"response": "GatewayResponse-module__response--1sQvz",
	"responseCard": "GatewayResponse-module__responseCard--bq42_",
	"responseIcon": "GatewayResponse-module__responseIcon--2Px8N",
	"responseData": "GatewayResponse-module__responseData--3e5C2",
	"responseButton": "GatewayResponse-module__responseButton--1xDyj"
};