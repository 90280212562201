import React from 'react';
import { Line, Column } from '@ant-design/charts';
import { Select, Button, Badge, Modal, Spin } from 'antd';
import moment from 'moment';
import { isNil } from '@antv/util';
import { isEmpty } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import ProductSegmentPredicateComponent from '../../modules/insight/productSegment/component/ProductSegmentPredicateComponent';
import { getSegmentId } from '../../utils/AuthorityProvider';
import { numberWithCommas } from '@Utils/RenderUtils';
import vars from '@Theme/styles/vars';
import Card from '@Utils/Card';
const styles = {
  button: {
    textTransform: 'uppercase',
    textDecoration: 'none',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    position: 'relative',
    paddingBottom: '20px',
    borderBottom: '1px solid #eee',
    marginBottom: '15px',
  },
  titleText: { color: '#9a9ca3', fontSize: 12 },
};
class AnalyticsComponent extends React.Component {
  componentDidMount() {
    if (this.isEventAnalytics) {
      this.props.productEventListFetch();
      this.props.productEventAnalyzeFetch(this.props.eventAnalyticsValue);
    } else {
      this.props.userAttributeListFetch();
    }
  }
  componentWillUnmount() {
    if (this.isEventAnalytics) {
      const lastWeek = moment().subtract(7, 'days');
      this.props.eventChangeCurrent({
        eventAnalyzeType: 'ALL_SYSTEM_EVENTS',
        uniqueness: false,
        fromDate: lastWeek,
        toDate: moment(),
        eventPredicates: [],
        eventPredicateConjunction: 'AND',
        event: null,
        overTime: null,
        over: null,
        splitTime: null,
        split: null,
      });
    } else if (this.isUserAnalytics || this.isSegmentAnalytics) {
      this.props.userChangeCurrent({
        ...this.props.userAnalyticsValue,
        known: null,
        segment: null,
        over: null,
        split: null,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (
      !isNil(this.props.userAttributeList) &&
      Array.isArray(this.props.userAttributeList) &&
      this.props.userAttributeList.length > 0 &&
      this.props.userAttributeLoading === false &&
      prevProps.userAttributeLoading === true
    ) {
      if (this.isUserAnalytics) {
        this.props.userAnalyzeFetch({
          ...this.props.userAnalyticsValue,
          over: this.props.userAttributeList[0].id,
        });
      } else if (this.isSegmentAnalytics) {
        this.props.userAnalyzeFetch({
          ...this.props.userAnalyticsValue,
          over: this.props.userAttributeList[0].id,
          segment: getSegmentId(this.props.pathname),
        });
      }
    }
  }
  constructor(props) {
    super(props);
    this.isEventAnalytics =
      this.props.pathname.split('/').pop() === 'event-analytics' ? true : false;
    this.isSegmentAnalytics =
      this.props.pathname.split('/').pop() === 'segment-analytics'
        ? true
        : false;
    this.isUserAnalytics =
      this.props.pathname.split('/').pop() === 'user-analytics' ? true : false;
  }

  renderEventChart = (config) => {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    if (this.props.loadingEventAnalytics) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 200,
            paddingTop: 200,
          }}
        >
          <Spin indicator={loadingSpin} />{' '}
        </div>
      );
    } else if (
      this.props.userAttributeLoading ||
      this.props.userAnalyticsFetchLoading
    ) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 200,
            paddingTop: 200,
          }}
        >
          <Spin indicator={loadingSpin} />{' '}
        </div>
      );
    } else if (this.props.isRefreshed) {
      return (
        <div
          style={{
            color: '#3d404e',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: 18,
            fontWeight: '500',
            paddingTop: 200,
            paddingBottom: 200,
          }}
        >
          Please press on the refresh button
        </div>
      );
    } else if (
      (!this.props.isRefreshed &&
        config &&
        config.data &&
        Array.isArray(config.data) &&
        config.data.length === 0) ||
      !isEmpty(this.props.eventAnalyticsErrors)
    ) {
      return (
        <div
          style={{
            color: '#3d404e',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: 18,
            fontWeight: '500',
            paddingTop: 200,
            paddingBottom: 200,
          }}
        >
          No Data Available
        </div>
      );
    } else if (
      this.props.eventAnalyticsValue &&
      this.props.eventAnalyticsValue.overTime === 'DAYS'
    ) {
      var configLine = { ...config, isStack: false };
      return <Line {...configLine} style={{ padding: 40 }} />;
    } else {
      return <Column {...config} style={{ padding: 40 }} />;
    }
  };
  renderEventOptions = (eventType) => {
    let systemItems = [];
    let customItems = [];
    if (this.props.eventList && Array.isArray(this.props.eventList)) {
      this.props.eventList.forEach((item) => {
        if (item.system) {
          systemItems.push(
            <Select.Option key={item.id} value={item.id}>
              {item.title}
            </Select.Option>
          );
        } else {
          customItems.push(
            <Select.Option key={item.id} value={item.id}>
              {item.title}
            </Select.Option>
          );
        }
      });
      if (eventType === 'system') {
        return systemItems;
      } else if (eventType === 'custom') {
        return customItems;
      }
    }
  };
  renderOverTimeOptions = (type) => {
    let eventSystemAttributes = [];
    let eventCustomAttributes = [];
    let userAttributes = [];
    if (this.isUserAnalytics || this.isSegmentAnalytics) {
      if (
        this.props.userAttributeList &&
        Array.isArray(this.props.userAttributeList)
      ) {
        this.props.userAttributeList.forEach((item) => {
          userAttributes.push(
            <Select.Option key={item.id} value={item.id}>
              {item.title}
            </Select.Option>
          );
        });
      }
      return userAttributes;
    } else {
      if (this.props.eventList && Array.isArray(this.props.eventList)) {
        this.props.eventList.forEach((item) => {
          if (item.id === this.props.eventAnalyticsValue.event) {
            item.attributes.forEach((element) => {
              if (element.system) {
                eventSystemAttributes.push(
                  <Select.Option key={element.title} value={element.id}>
                    {element.title}
                  </Select.Option>
                );
              } else {
                eventCustomAttributes.push(
                  <Select.Option key={element.title} value={element.id}>
                    {element.title}
                  </Select.Option>
                );
              }
            });
          }
        });
      }
      if (type === 'system') {
        return eventSystemAttributes;
      } else {
        return eventCustomAttributes;
      }
    }
  };
  state = {
    selectorModalVisible: false,
    currentPredicate: {
      event: 0,
      eventConjunction: 'AND',
      eventPredicates: [],
      type: 'BEHAVIOUR',
    },
  };
  onOkEventFilter = () => {
    this.props.eventChangeCurrent({
      ...this.props.eventAnalyticsValue,
      eventAnalyzeType: 'EVENT',
      event: this.state.currentPredicate.event,
      eventPredicates: this.state.currentPredicate.eventPredicates,
      eventPredicateConjunction: this.state.currentPredicate.eventConjunction,
    });
    this.setState(
      {
        selectorModalVisible: false,
      },
      () => {}
    );
  };

  onResetEventFilter = () => {
    this.setState({
      currentPredicate: {
        event: this.props.eventAnalyticsValue.event,
        eventConjunction:
          this.props.eventAnalyticsValue.eventPredicateConjunction,
        eventPredicates: this.props.eventAnalyticsValue.eventPredicates,
        type: 'BEHAVIOUR',
      },
    });
  };
  seriesFieldValue = () => {
    if (this.isEventAnalytics) {
      if (
        isNil(this.props.eventAnalyticsValue.split) &&
        isNil(this.props.eventAnalyticsValue.splitTime)
      ) {
        return null;
      } else {
        return 'category';
      }
    } else if (this.isUserAnalytics || this.isSegmentAnalytics) {
      if (isNil(this.props.userAnalyticsValue.split)) {
        return null;
      } else {
        return 'category';
      }
    }
  };
  chartColor = () => {
    if (this.isEventAnalytics) {
      if (
        isNil(this.props.eventAnalyticsValue.split) &&
        isNil(this.props.eventAnalyticsValue.splitTime)
      ) {
        return '#2f8bfa';
      } else {
        return null;
      }
    } else if (this.isUserAnalytics || this.isSegmentAnalytics) {
      if (isNil(this.props.userAnalyticsValue.split)) {
        return '#2f8bfa';
      } else {
        return null;
      }
    }
  };
  renderEventFilterSelector = () => {
    if (this.isEventAnalytics) {
      return (
        <Modal
          width={1080}
          title={''}
          centered
          visible={this.state.selectorModalVisible}
          onOk={() => this.setState({ selectorModalVisible: false }, () => {})}
          onCancel={() => {
            this.setState({ selectorModalVisible: false });
          }}
          footer={[
            <Button key="back" onClick={() => this.onResetEventFilter()}>
              Reset
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => this.onOkEventFilter()}
            >
              Apply
            </Button>,
          ]}
        >
          <div>
            <ProductSegmentPredicateComponent
              selectEventDisabled={true}
              type="HAS_DONE_EVENT"
              predicate={{
                ...this.state.currentPredicate,
                event: this.props.eventAnalyticsValue.event,
              }}
              events={this.props.eventList}
              isInSegment={false}
              handleChangePredicate={(currentPredicate) =>
                this.setState({ currentPredicate })
              }
              fetchRecommendEventAttributes={
                this.props.productSegmentRecommendEventAttributesFetch
              }
              recommendEventAttributes={this.props.recommendEventAttributes}
              fetchRecommendDeviceAttributes={
                this.props.productSegmentRecommendDeviceAttributesFetch
              }
              recommendDeviceAttributes={this.props.recommendDeviceAttributes}
              fetchRecommendUserAttributes={
                this.props.productSegmentRecommendUserAttributesFetch
              }
              recommendUserAttributes={this.props.recommendUserAttributes}
            />
          </div>
        </Modal>
      );
    } else {
      return null;
    }
  };
  renderHeaderOptions = () => {
    const { Option, OptGroup } = Select;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            ...styles.header,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: 30,
            paddingTop: 30,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {this.isEventAnalytics ? (
              <div style={styles.titleText}>
                SHOW
                <Select
                  value={this.props.eventAnalyticsValue.uniqueness}
                  onChange={(val) => {
                    this.props.eventChangeCurrent({
                      ...this.props.eventAnalyticsValue,
                      uniqueness: val,
                    });
                  }}
                  style={{ marginLeft: 10, width: 150 }}
                >
                  <Select.Option value={false} key={'Occurrences'}>
                    Occurrences
                  </Select.Option>
                  {/* <Select.Option value={true} key={'Uniqueness'}>
                    Uniques
                  </Select.Option> */}
                </Select>
              </div>
            ) : (
              <div style={styles.titleText}>
                ANALYZE
                <Select
                  value={this.props.userAnalyticsValue.known}
                  onChange={(val) => {
                    this.props.userChangeCurrent({
                      ...this.props.userAnalyticsValue,
                      known: val,
                    });
                  }}
                  style={{ marginLeft: 10, width: 150 }}
                >
                  <Select.Option value={true} key={'known'}>
                    Known Users
                  </Select.Option>
                  <Select.Option value={false} key={'unknown'}>
                    Unknown Users
                  </Select.Option>
                  <Select.Option value={null} key={'all'}>
                    All Users
                  </Select.Option>
                </Select>
              </div>
            )}
            {this.isUserAnalytics || this.isSegmentAnalytics ? null : (
              <div style={{ ...styles.titleText, marginLeft: 45 }}>
                OF
                <Select
                  showSearch
                  filterOption={(input, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }
                  }}
                  bordered={true}
                  value={
                    this.props.eventAnalyticsValue.eventAnalyzeType !==
                      'ALL_CUSTOM_EVENTS' &&
                    this.props.eventAnalyticsValue.eventAnalyzeType !==
                      'ALL_SYSTEM_EVENTS'
                      ? this.props.eventAnalyticsValue.event
                      : this.props.eventAnalyticsValue.eventAnalyzeType
                  }
                  onChange={(val) => {
                    if (
                      val !== 'ALL_CUSTOM_EVENTS' &&
                      val !== 'ALL_SYSTEM_EVENTS'
                    ) {
                      this.props.productEventListFetch();
                      this.setState(
                        {
                          currentPredicate: {
                            event: val,
                            eventConjunction: 'AND',
                            eventPredicates: [],
                            type: 'BEHAVIOUR',
                          },
                        },
                        () => {
                          this.props.eventChangeCurrent({
                            ...this.props.eventAnalyticsValue,
                            eventPredicates: [],
                            eventPredicateConjunction: 'AND',
                            eventAnalyzeType: 'EVENT',
                            event: val,
                            overTime: 'DAYS',
                            over: null,
                            splitTime: null,
                            split: null,
                          });
                        }
                      );
                    } else {
                      this.setState(
                        {
                          currentPredicate: {
                            event: val,
                            eventConjunction: 'AND',
                            eventPredicates: [],
                            type: 'BEHAVIOUR',
                          },
                        },
                        () => {
                          this.props.eventChangeCurrent({
                            ...this.props.eventAnalyticsValue,
                            eventPredicates: [],
                            eventPredicateConjunction: 'AND',
                            event: null,
                            eventAnalyzeType: val,
                            over: null,
                            overTime: null,
                            splitTime: null,
                            split: null,
                          });
                        }
                      );
                    }
                  }}
                  style={{ marginLeft: 10, width: 170 }}
                >
                  <OptGroup label="ALL">
                    <Select.Option
                      value={'ALL_CUSTOM_EVENTS'}
                      key={'ALL_CUSTOM_EVENTS'}
                    >
                      All Custom Events
                    </Select.Option>
                    <Select.Option
                      value={'ALL_SYSTEM_EVENTS'}
                      key={'ALL_SYSTEM_EVENTS'}
                    >
                      All System Events
                    </Select.Option>
                  </OptGroup>
                  <OptGroup label="SYSTEM">
                    {this.renderEventOptions('system')}
                  </OptGroup>
                  <OptGroup label="CUSTOM">
                    {this.renderEventOptions('custom')}
                  </OptGroup>
                </Select>
              </div>
            )}
            {this.isUserAnalytics || this.isSegmentAnalytics ? null : (
              <Badge
                onClick={() =>
                  this.setState(
                    {
                      selectorModalVisible: !isNil(
                        this.props.eventAnalyticsValue.event
                      ),
                    },
                    () => {}
                  )
                }
                count={
                  this.state.currentPredicate.eventPredicates &&
                  Array.isArray(this.state.currentPredicate.eventPredicates)
                    ? this.state.currentPredicate.eventPredicates.length
                    : null
                }
                showZero={false}
              >
                <div
                  className="fl-filter fl-dark"
                  style={{ fontSize: 28 }}
                ></div>
              </Badge>
            )}
            <div style={{ ...styles.titleText, marginLeft: 48 }}>
              OVER
              {this.isUserAnalytics || this.isSegmentAnalytics ? (
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={
                    isNil(this.props.userAnalyticsValue.over) &&
                    this.props.userAttributeList &&
                    Array.isArray(this.props.userAttributeList)
                      ? this.props.userAttributeList[0].title
                      : this.props.userAnalyticsValue.over
                  }
                  onChange={(val) => {
                    this.props.userChangeCurrent({
                      ...this.props.userAnalyticsValue,
                      over: val,
                    });
                  }}
                  style={{ marginLeft: 10, width: 200 }}
                >
                  {this.renderOverTimeOptions()}
                </Select>
              ) : (
                <Select
                  disabled={
                    this.props.eventAnalyticsValue.eventAnalyzeType ===
                      'ALL_CUSTOM_EVENTS' ||
                    this.props.eventAnalyticsValue.eventAnalyzeType ===
                      'ALL_SYSTEM_EVENTS'
                      ? true
                      : false
                  }
                  showSearch
                  filterOption={(input, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }
                  }}
                  value={
                    !isNil(this.props.eventAnalyticsValue.over)
                      ? this.props.eventAnalyticsValue.over
                      : this.props.eventAnalyticsValue.overTime
                  }
                  onChange={(val) => {
                    if (
                      val !== 'DAYS' &&
                      val !== 'WEEKS' &&
                      val !== 'MONTHS' &&
                      val !== 'HOURS_OF_DAY' &&
                      val !== 'DAYS_OF_WEEK' &&
                      val !== 'MONTHS_OF_YEAR'
                    ) {
                      this.props.eventChangeCurrent({
                        ...this.props.eventAnalyticsValue,
                        over: val,
                        overTime: null,
                        splitTime: null,
                        split: null,
                      });
                    } else {
                      this.props.eventChangeCurrent({
                        ...this.props.eventAnalyticsValue,
                        overTime: val,
                        over: null,
                        splitTime: null,
                        split: null,
                      });
                    }
                  }}
                  style={{ marginLeft: 10, width: 150 }}
                >
                  <OptGroup label="TIME">
                    <Option value={'DAYS'} key={'DAYS'}>
                      Days
                    </Option>
                    <Option value={'WEEKS'} key={'WEEKS'}>
                      Weeks
                    </Option>
                    <Option value={'MONTHS'} key={'MONTHS'}>
                      Months
                    </Option>
                  </OptGroup>
                  <OptGroup label="TIME BLOCK">
                    <Option value={'HOURS_OF_DAY'} key={'HOURS_OF_DAY'}>
                      Hours of day
                    </Option>
                    <Option value={'DAYS_OF_WEEK'} key={'DAYS_OF_WEEK'}>
                      Days of week
                    </Option>
                    <Option value={'MONTHS_OF_YEAR'} key={'MONTHS_OF_YEAR'}>
                      Months of year
                    </Option>
                  </OptGroup>
                  <OptGroup label="SYSTEM">
                    {this.renderOverTimeOptions('system')}
                  </OptGroup>
                  <OptGroup label="CUSTOM">
                    {this.renderOverTimeOptions('custom')}
                  </OptGroup>
                </Select>
              )}
            </div>
            <div
              style={{ ...styles.titleText, marginLeft: 48, marginRight: 48 }}
            >
              SPLIT BY
              {this.isUserAnalytics || this.isSegmentAnalytics ? (
                <Select
                  showSearch
                  allowClear={true}
                  filterOption={(input, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }
                  }}
                  value={this.props.userAnalyticsValue.split}
                  onChange={(val) => {
                    this.props.userChangeCurrent({
                      ...this.props.userAnalyticsValue,
                      split: val,
                    });
                  }}
                  style={{ marginLeft: 10, width: 200 }}
                >
                  {this.renderOverTimeOptions()}
                </Select>
              ) : (
                <Select
                  disabled={
                    this.props.eventAnalyticsValue.eventAnalyzeType ===
                      'ALL_CUSTOM_EVENTS' ||
                    this.props.eventAnalyticsValue.eventAnalyzeType ===
                      'ALL_SYSTEM_EVENTS'
                      ? true
                      : false
                  }
                  showSearch
                  allowClear={true}
                  filterOption={(input, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }
                  }}
                  value={
                    !isNil(this.props.eventAnalyticsValue.split)
                      ? this.props.eventAnalyticsValue.split
                      : this.props.eventAnalyticsValue.splitTime
                  }
                  onChange={(val) => {
                    if (
                      val !== 'DAYS' &&
                      val !== 'WEEKS' &&
                      val !== 'MONTHS' &&
                      val !== 'HOURS_OF_DAY' &&
                      val !== 'DAYS_OF_WEEK' &&
                      val !== 'MONTHS_OF_YEAR'
                    ) {
                      this.props.eventChangeCurrent({
                        ...this.props.eventAnalyticsValue,
                        split: val,
                        splitTime: null,
                      });
                    } else {
                      this.props.eventChangeCurrent({
                        ...this.props.eventAnalyticsValue,
                        splitTime: val,
                        split: null,
                      });
                    }
                  }}
                  style={{ marginLeft: 10, width: 150 }}
                >
                  <OptGroup label="TIME">
                    <Option value={'DAYS'} key={'DAYS'}>
                      Days
                    </Option>
                    <Option value={'WEEKS'} key={'WEEKS'}>
                      Weeks
                    </Option>
                    <Option value={'MONTHS'} key={'MONTHS'}>
                      Months
                    </Option>
                  </OptGroup>
                  <OptGroup label="TIME BLOCK">
                    <Option value={'HOURS_OF_DAY'} key={'HOURS_OF_DAY'}>
                      Hours of day
                    </Option>
                    <Option value={'DAYS_OF_WEEK'} key={'DAYS_OF_WEEK'}>
                      Days of week
                    </Option>
                    <Option value={'MONTHS_OF_YEAR'} key={'MONTHS_OF_YEAR'}>
                      Months of year
                    </Option>
                  </OptGroup>
                  <OptGroup label="SYSTEM">
                    {this.renderOverTimeOptions('system')}
                  </OptGroup>
                  <OptGroup label="CUSTOM">
                    {this.renderOverTimeOptions('custom')}
                  </OptGroup>
                </Select>
              )}
            </div>
          </div>
          <Button
            type="primary"
            shape="circle"
            size={'middle'}
            style={{
              ...styles.button,
              marginRight: '30px',
              marginLeft: this.isUserAnalytics ? 150 : null,
              backgroundColor: this.props.isRefreshed
                ? vars.primaryColor
                : '#C5C5C5',
              border: this.props.isRefreshed ? '1px solid ' : '1px solid',
              borderColor: this.props.isRefreshed
                ? vars.primaryColor
                : ' #C5C5C5',
            }}
            onClick={() => {
              if (this.isUserAnalytics) {
                this.props.userAnalyzeFetch(this.props.userAnalyticsValue);
              } else if (this.isSegmentAnalytics) {
                this.props.userAnalyzeFetch({
                  ...this.props.userAnalyticsValue,
                  segment: getSegmentId(this.props.pathname),
                });
              } else {
                this.props.productEventAnalyzeFetch(
                  this.props.eventAnalyticsValue
                );
              }
            }}
          >
            {' '}
            <i
              className="fl-sync fl-light"
              style={{ color: 'white', fontSize: 25, padding: 0, margin: 0 }}
            />
          </Button>
        </div>
        {this.isEventAnalytics ? (
          <div
            style={{
              color: '#9a9ca3',
              fontSize: 13,
              paddingLeft: 30,
              paddingBottom: 20,
              marginBottom: 30,
              borderBottom: '1px solid #eee',
            }}
          >
            TOTAL{' '}
            {this.props.eventAnalyticsValue &&
            this.props.eventAnalyticsValue.uniqueness
              ? 'UNIQUES'
              : 'OCCURRENCES'}{' '}
            :{' '}
            {this.props.isRefreshed || this.props.loadingEventAnalytics
              ? ''
              : this.props.eventAnalytics.over &&
                Array.isArray(this.props.eventAnalytics.over) &&
                numberWithCommas(this.props.eventAnalytics.totalHits)}
          </div>
        ) : null}
      </div>
    );
  };
  render() {
    var data = [];
    if (this.isEventAnalytics) {
      if (
        this.props.eventAnalytics.over &&
        Array.isArray(this.props.eventAnalytics.over)
      ) {
        if (
          isNil(this.props.eventAnalyticsValue.splitTime) &&
          isNil(this.props.eventAnalyticsValue.split)
        ) {
          this.props.eventAnalytics.over.forEach((item) => {
            data.push({ label: item.label, count: item.labelCount });
          });
        } else {
          this.props.eventAnalytics.over.forEach((item) => {
            for (var i = 0; i <= Object.values(item.split).length - 1; i++) {
              data.push({
                label: item.label,
                count: Object.values(item.split)[i],
                category: Object.keys(item.split)[i],
              });
            }
          });
        }
      }
    } else {
      if (
        this.props.userAttributesResult.over &&
        Array.isArray(this.props.userAttributesResult.over)
      ) {
        if (isNil(this.props.userAnalyticsValue.split)) {
          this.props.userAttributesResult.over.forEach((item) => {
            data.push({ label: item.label, count: item.labelCount });
          });
        } else {
          this.props.userAttributesResult.over.forEach((item) => {
            for (var i = 0; i <= Object.values(item.split).length - 1; i++) {
              data.push({
                label: item.label,
                count: Object.values(item.split)[i],
                category: Object.keys(item.split)[i],
              });
            }
          });
        }
      }
    }
    var config = {
      isStack: true,
      tooltip: {
        shared: false,
        formatter: function formatter(v) {
          return { name: 'count', value: numberWithCommas(v.count) };
        },
      },
      data,
      xField: 'label',
      yField: 'count',
      seriesField: this.seriesFieldValue(),
      color: this.chartColor(),
      legend: {
        itemName: {
          formatter: (text) => {
            return numberWithCommas(text);
          },
        },
        position: 'right',
        verticalAlign: 'middle',
      },
      point: {
        size: 5,
        shape: 'circle',
      },
      xAxis: {
        tickCount: data.length,
        nice: true,
        label: {
          formatter: function formatter(v) {
            return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
              return ''.concat(s, ',');
            });
          },
          position: 'right',
          autoRotate: true,
          //   rotate: -Math.PI / 4,
          // offsetX: -60,
          // offsetY: 10,
          autoEllipsis: true,
          style: {
            cursor: 'pointer',
            fontSize: 12,
          },
        },
      },
      yAxis: {
        label: {
          formatter: function formatter(value) {
            if (value >= 1000 && value <= 1000000 && value % 1000 === 0) {
              return value / 1000 + 'K';
            } else if (value >= 1000000 && value % 1000000 === 0) {
              return value / 1000000 + 'M';
            } else {
              return value;
            }
          },
        },
      },
    };
    return (
      <div>
        <Card>
          {this.renderHeaderOptions()}
          {this.renderEventFilterSelector()}
          {this.renderEventChart(config)}
        </Card>
      </div>
    );
  }
}
export default AnalyticsComponent;
