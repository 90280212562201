import React from 'react';
import { history } from '../../../../redux/store';
import { isNil } from 'lodash';
import { allSteps } from '../../../../utils/CommunicationSteps';
import { CheckCircleTwoTone, RightOutlined } from '@ant-design/icons';
import vars from '@Theme/styles/vars';
import { Modal } from 'antd';
// import CommunicationDiscardModalComponent from './CommunicationDiscardModalComponent'
const styles = {
  nextIcon: {
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '40px',
    color: '#bbbcc1',
  },
  selectedLevel: {
    fontSize: 13,
    lineHeight: '54px',
    borderBottom: '3px solid',
    borderColor: vars.primaryColor,
    fontWeight: '500',
    color: '#3d404e',
    cursor: 'pointer',
    marginBottom: -5,
  },
  normalLevel: {
    fontSize: 13,
    cursor: 'pointer',
    lineHeight: '60px',
    color: '#94969e',
  },
  levelContainer: {
    justifyContent: 'space-around',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  levelsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    backgroundColor: '#FFFFFF',
    flexDirection: 'row',
    padding: '0 15px',
    borderBottom: '1px solid rgba(61,64,78,.12)',
  },
};

class CommunicationStepsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.steps = isNil(this.props.steps) ? allSteps : this.props.steps;
    this.step = this.props.step;
    this.state = {
      isOpenModal: false,
    };
  }
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.steps !== this.props.steps) {
      this.steps = isNil(this.props.steps) ? allSteps : this.props.steps;
    }
  }

  isStepCompleted = (step) => {
    let result = [];
    if (this.props.creationStep === 'WHEN') {
      result = ['audience'];
    }
    if (this.props.creationStep === 'MESSAGE') {
      result = ['audience', 'when'];
    }
    if (this.props.creationStep === 'DONE') {
      result = ['audience', 'when', 'message', 'conversion', 'test', 'preview'];
    }
    if (this.props.step !== step && result.includes(step)) {
      return true;
    }
    return false;
  };

  goToStep = (step) => {
    if (this.isStepCompleted(step)) {
      if (this.props.journey) {
        this.props.goToStepOnJourney(step);
      } else {
        if (!isNil(this.props.communicationDraftSaveDiscard)) {
          this.props.communicationDraftSaveDiscard();
        }
        history.push(step);
      }
    }
  };
  clickBackButton = (step) => {
    if (this.props.isEdited()) {
      this.setState({ isOpenModal: step });
    } else {
      this.goToStep(step);
    }
  };

  renderSteps = () => {
    return (
      <div style={styles.levelsContainer}>
        {this.steps.map((item, index) => (
          <div key={index} style={styles.levelContainer}>
            <div
              onClick={() => {
                this.clickBackButton(item.step);
              }}
              style={
                this.step === item.step
                  ? styles.selectedLevel
                  : styles.normalLevel
              }
            >
              {item.title}
              {this.isStepCompleted(item.step) && (
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ marginLeft: 5 }}
                />
              )}
            </div>
            {index < this.steps.length - 1 && (
              <RightOutlined style={styles.nextIcon} />
            )}
          </div>
        ))}
      </div>
    );
  };

  render() {
    return (
      <>
        {this.renderSteps()}
        {/* <CommunicationDiscardModalComponent
        show={this.state.isOpenModal}
        //cancelModal={this.hideModal()}
        onDone={() => this.goToStep(item.step)
        }
      /> */}
        {this.isStepCompleted(this.state.isOpenModal) && (
          <Modal
            width={600}
            title={<span style={{ fontWeight: 'bold' }}>Are You Sure?</span>}
            visible={!!this.state.isOpenModal}
            onOk={() => {
              this.goToStep(this.state.isOpenModal);
            }}
            okText="Yes, Continue"
            onCancel={() => this.setState({ isOpenModal: false })}
            cancelText="No"
          >
            <p>
              {' '}
              Any information entered in this step will be lost if you do not
              save this step. Would you like to continue?
            </p>
          </Modal>
        )}
      </>
    );
  }
}

export default CommunicationStepsComponent;
