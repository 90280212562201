import {
  INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH,
  INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_ANALYZE_FETCH,
  INSIGHT_PRODUCT_USER_ANALYZE_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_ANALYZE_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_DEVICES_FETCH,
  INSIGHT_PRODUCT_USER_DEVICES_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_DEVICES_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_EVENTS_FETCH,
  INSIGHT_PRODUCT_USER_EVENTS_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_EVENTS_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_LIST_FETCH,
  INSIGHT_PRODUCT_USER_LIST_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_LIST_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH,
  INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH,
  INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH,
  INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_VIEW_FETCH,
  INSIGHT_PRODUCT_USER_VIEW_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_VIEW_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_PAGE_CHANGE_CURRENT,
  INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH,
  INSIGHT_PRODUCT_USER_LIST_EVENT_FETCH_SUCCESS,
  INSIGHT_PRODUCT_USER_JOURNEY_FETCH,
  INSIGHT_PRODUCT_USER_JOURNEY_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_JOURNEY_FETCH_SUCCESS,
  ////
  USER_ATTRIBUTE_LIST_FETCH,
  USER_CHANGE_CURRENT,
  USER_ATTRIBUTE_LIST_FETCH_FAIL,
  USER_ATTRIBUTE_LIST_FETCH_SUCCESS,
  USER_ANALYTICS_FETCH,
  USER_ANALYTICS_FETCH_SUCCESS,
  USER_ANALYTICS_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_RELAY_FETCH,
  INSIGHT_PRODUCT_USER_RELAY_FETCH_FAIL,
  INSIGHT_PRODUCT_USER_RELAY_FETCH_SUCCESS,
} from './UsersConstants';

const initialState = {
  activityPageRequest: {},
  activityLoading: false,
  activityErrors: [],
  activity: [],

  analyzePageRequest: {},
  analyzeLoading: false,
  analyzeErrors: [],
  analyze: null,

  eventPageRequest: { page: 0, query: null, segment: null },
  eventLoading: false,
  eventErrors: [],
  event: null,

  usersListPageRequest: { known: true, page: 0, query: null, segment: null },
  usersListLoading: false,
  usersSerachListLoading: false,
  usersListErrors: [],
  usersList: null,

  attributesListLoading: false,
  attributesListErrors: [],
  attributesList: [],

  eventsList: [],

  deviceList: [],
  deviceListLoading: [],
  deviceListErrors: [],

  reachabilityLoading: false,
  reachabilityErrors: [],
  reachability: null,

  userLoading: false,
  userErrors: [],
  user: null,

  communicationsPageRequest: { page: 0 },
  communicationsLoading: false,
  communicationsErrors: [],
  communications: null,

  userAttributeLoading: false,
  userAnalyticsFetchLoading: false,
  userAttributeListErrors: [],
  userAttributeList: {},
  userAttributesResult: {},
  isRefreshed: false,
  userAnalyticsValue: {
    known: null,
    segment: null,
    over: null,
    split: null,
  },

  journeyPageRequest: { page: 0 },
  journeysLoading: false,
  journeysErrors: [],
  journeys: null,
  relays: null,
  relayPageRequest: { page: 0 },
  relaysLoading: false,
};

export default function usersStateReducer(state = initialState, action) {
  switch (action.type) {
    case INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH:
      return {
        ...state,
        activityLoading: true,
        activityErrors: [],
        activityPageRequest: action.payload,
      };
    case INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH_FAIL:
      return {
        ...state,
        activityLoading: false,
        activityErrors: action.payload.errors,
      };
    case INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH_SUCCESS:
      return { ...state, activityLoading: false, activity: action.payload };

    case INSIGHT_PRODUCT_USER_ANALYZE_FETCH:
      return {
        ...state,
        analyzeLoading: true,
        analyzeErrors: [],
        analyzePageRequest: action.payload,
      };
    case INSIGHT_PRODUCT_USER_ANALYZE_FETCH_FAIL:
      return {
        ...state,
        analyzeLoading: false,
        analyzeErrors: action.payload.errors,
      };
    case INSIGHT_PRODUCT_USER_ANALYZE_FETCH_SUCCESS:
      return { ...state, analyzeLoading: false, analyze: action.payload };

    case INSIGHT_PRODUCT_USER_DEVICES_FETCH:
      return { ...state, deviceListLoading: true, deviceListErrors: [] };
    case INSIGHT_PRODUCT_USER_DEVICES_FETCH_FAIL:
      return {
        ...state,
        deviceListLoading: false,
        deviceListErrors: action.payload.errors,
      };
    case INSIGHT_PRODUCT_USER_DEVICES_FETCH_SUCCESS:
      return { ...state, deviceListLoading: false, deviceList: action.payload };

    case INSIGHT_PRODUCT_USER_LIST_FETCH:
      return {
        ...state,
        usersListLoading: true,
        usersListErrors: [],
        usersListPageRequest: action.payload,
      };
    case INSIGHT_PRODUCT_USER_LIST_FETCH_FAIL:
      return {
        ...state,
        usersListLoading: false,
        usersListErrors: action.payload.errors,
      };
    case INSIGHT_PRODUCT_USER_LIST_FETCH_SUCCESS:
      return { ...state, usersListLoading: false, usersList: action.payload };
    case INSIGHT_PRODUCT_USER_PAGE_CHANGE_CURRENT:
      return { ...state, usersListPageRequest: action.payload };

    case INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH:
      return {
        ...state,
        usersSerachListLoading: true,
        usersListErrors: [],
        usersListPageRequest: action.payload,
      };
    case INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH_FAIL:
      return {
        ...state,
        usersSerachListLoading: false,
        usersListErrors: action.payload.errors,
      };
    case INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH_SUCCESS:
      return {
        ...state,
        usersSerachListLoading: false,
        usersList: action.payload,
      };

    case INSIGHT_PRODUCT_USER_EVENTS_FETCH:
      return {
        ...state,
        eventLoading: true,
        eventErrors: [],
        eventPageRequest: action.payload,
      };
    case INSIGHT_PRODUCT_USER_EVENTS_FETCH_FAIL:
      return {
        ...state,
        eventLoading: false,
        eventErrors: action.payload.errors,
      };
    case INSIGHT_PRODUCT_USER_EVENTS_FETCH_SUCCESS:
      return { ...state, eventLoading: false, event: action.payload };

    case INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH:
      return {
        ...state,
        communicationsLoading: true,
        communicationsErrors: [],
        communicationsPageRequest: action.payload,
      };
    case INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH_FAIL:
      return {
        ...state,
        communicationsLoading: false,
        communicationsErrors: action.payload.errors,
      };
    case INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH_SUCCESS:
      return {
        ...state,
        communicationsLoading: false,
        communications: action.payload,
      };
    case INSIGHT_PRODUCT_USER_JOURNEY_FETCH:
      return {
        ...state,
        journeysLoading: true,
        journeyPageRequest: action.payload,
        journeysErrors: [],
      };
    case INSIGHT_PRODUCT_USER_JOURNEY_FETCH_FAIL:
      return {
        ...state,
        journeysLoading: false,
        journeysErrors: action.payload.errors,
      };
    case INSIGHT_PRODUCT_USER_JOURNEY_FETCH_SUCCESS:
      return {
        ...state,
        journeysLoading: false,
        journeys: action.payload,
      };
    case INSIGHT_PRODUCT_USER_RELAY_FETCH:
      return {
        ...state,
        relaysLoading: true,
        relayPageRequest: action.payload,
      };
    case INSIGHT_PRODUCT_USER_RELAY_FETCH_FAIL:
      return {
        ...state,
        relaysLoading: false,
      };
    case INSIGHT_PRODUCT_USER_RELAY_FETCH_SUCCESS:
      return {
        ...state,
        relaysLoading: false,
        relays: action.payload,
      };
    case INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH:
      return {
        ...state,
        attributesListLoading: true,
        attributesListErrors: [],
      };
    case INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH_FAIL:
      return {
        ...state,
        attributesListLoading: false,
        attributesListErrors: action.payload.errors,
      };
    case INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH_SUCCESS:
      return {
        ...state,
        attributesListLoading: false,
        attributesList: action.payload,
      };

    case INSIGHT_PRODUCT_USER_LIST_EVENT_FETCH_SUCCESS:
      return {
        ...state,
        eventsList: action.payload,
      };

    case INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH:
      return { ...state, reachabilityLoading: true, reachabilityErrors: [] };
    case INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH_FAIL:
      return {
        ...state,
        reachabilityLoading: false,
        reachabilityErrors: action.payload.errors,
      };
    case INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH_SUCCESS:
      return {
        ...state,
        reachabilityLoading: false,
        reachability: action.payload,
      };

    case INSIGHT_PRODUCT_USER_VIEW_FETCH:
      return { ...state, userLoading: true, userErrors: [] };
    case INSIGHT_PRODUCT_USER_VIEW_FETCH_FAIL:
      return {
        ...state,
        userLoading: false,
        userErrors: action.payload.errors,
      };
    case INSIGHT_PRODUCT_USER_VIEW_FETCH_SUCCESS:
      return { ...state, userLoading: false, user: action.payload };
    case USER_ATTRIBUTE_LIST_FETCH:
      return {
        ...state,
        userAttributeLoading: true,
        userAttributeListErrors: [],
      };
    case USER_ATTRIBUTE_LIST_FETCH_FAIL:
      return {
        ...state,
        userAttributeLoading: false,
        userAttributeListErrors: action.payload.errors,
      };
    case USER_ATTRIBUTE_LIST_FETCH_SUCCESS:
      return {
        ...state,
        userAttributeLoading: false,
        userAttributeList: action.payload,
      };
    case USER_ANALYTICS_FETCH:
      return {
        ...state,
        userAnalyticsFetchLoading: true,
        userAttributeListErrors: [],
        isRefreshed: false,
        userAnalyticsValue: action.payload,
      };
    case USER_ANALYTICS_FETCH_FAIL:
      return {
        ...state,
        userAnalyticsFetchLoading: false,
        userAttributeListErrors: action.payload.errors,
      };
    case USER_ANALYTICS_FETCH_SUCCESS:
      return {
        ...state,
        userAnalyticsFetchLoading: false,
        userAttributesResult: action.payload,
      };
    case USER_CHANGE_CURRENT:
      return {
        ...state,
        userAnalyticsValue: action.payload,
        errors: [],
        isRefreshed: true,
      };
    default:
      return state;
  }
}
