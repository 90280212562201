import FileStorageUpdateComponent from '../component/FileStorageUpdateComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  FileStorageCreateFetch,
  fileStorageChangeCurrent,
  fileStorageUpdateFetch,
  fileStorageUpdateSubmitFetch,
  FileStorageViewFetch,
} from '../FileStorageAction';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentFileStorage: state.admin.fileStorage.currentFileStorage,
  updateLoading: state.admin.fileStorage.updateLoading,
  submitLoading: state.admin.fileStorage.submitLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      FileStorageCreateFetch,
      fileStorageChangeCurrent,
      fileStorageUpdateFetch,
      fileStorageUpdateSubmitFetch,
      FileStorageViewFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileStorageUpdateComponent);

FileStorageUpdateComponent.propTypes = {
  fileStorageChangeCurrent: PropTypes.func.isRequired,
  fileStorageUpdateFetch: PropTypes.func.isRequired,
  currentFileStorage: PropTypes.object.isRequired,
};
