import BaseApi from '../../../api/BaseApi';

const basePath = '/insight/productSegment';
const basePathFile = '/base/file';

class ProductSegmentApi {
  static listAttributes(body = {}) {
    return BaseApi.post(basePath + '/listAttributes', body);
  }
  static listEvents(body = {}) {
    return BaseApi.post(basePath + '/listEvents', body);
  }
  static listDeviceAttributes(body = {}) {
    return BaseApi.post(basePath + '/listDeviceAttributes', body);
  }
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static create(body) {
    return BaseApi.post(basePath + '/create', body);
  }
  static copy(id) {
    return BaseApi.post(basePath + '/copy/' + id, {});
  }
  static report(body) {
    return BaseApi.post(basePath + '/report', body);
  }
  static reportView(id) {
    return BaseApi.post(basePath + '/reportView/' + id, {});
  }
  static segmentSizeReport(body) {
    return BaseApi.post(basePath + '/segmentSizeReport', body);
  }
  static archive(id) {
    return BaseApi.post(basePath + '/archive/' + id, {});
  }
  static activate(id) {
    return BaseApi.post(basePath + '/activate/' + id, {});
  }
  static update(id) {
    return BaseApi.post(basePath + '/update/' + id, {});
  }
  static updateSubmit(id, body) {
    return BaseApi.post(basePath + '/updateSubmit/' + id, body);
  }
  static view(id) {
    return BaseApi.post(basePath + '/view/' + id, {});
  }
  static delete(id) {
    return BaseApi.post(basePath + '/delete/' + id, {});
  }
  static uploadFile(file) {
    return BaseApi.uploadFile(basePathFile + '/upload', file);
  }
  static listSourceProducts() {
    return BaseApi.post(basePath + '/listSourceProducts');
  }
  static recommendUserAttributes(userAttributeId) {
    return BaseApi.post(
      basePath + '/recommendUserAttributes/' + userAttributeId,
      {}
    );
  }
  static recommendEventAttributes(eventAttributeId) {
    return BaseApi.post(
      basePath + '/recommendEventAttributes/' + eventAttributeId,
      {}
    );
  }
  static recommendDeviceAttributes(deviceAttributeId) {
    return BaseApi.post(
      basePath + '/recommendDeviceAttributes/' + deviceAttributeId,
      {}
    );
  }
}

export default ProductSegmentApi;
