import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import FileStorageListContainer from './container/FileStorageListContainer';
import FileStorageUpdateContainer from './container/FileStorageUpdateContainer';

function FileStorage(props) {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}list`}>
        <FileStorageListContainer />
      </Route>
      <Route path={`${match.path}create`}>
        <FileStorageUpdateContainer {...props} />
      </Route>
      <Route path={`${match.path}update/:id`}>
        <FileStorageUpdateContainer {...props} />
      </Route>
      <Route path={`${match.path}view/:id`}>
        <FileStorageUpdateContainer {...props} />
      </Route>
    </Switch>
  );
}

export default FileStorage;
