export const WEBHOOK_CREATE_FETCH = 'WEBHOOK_CREATE_FETCH';
export const WEBHOOK_CREATE_FETCH_FAIL = 'WEBHOOK_CREATE_FETCH_FAIL';
export const WEBHOOK_CREATE_FETCH_SUCCESS = 'WEBHOOK_CREATE_FETCH_SUCCESS';

export const WEBHOOK_DELETE_FETCH = 'WEBHOOK_DELETE_FETCH';
export const WEBHOOK_DELETE_FETCH_FAIL = 'WEBHOOK_DELETE_FETCH_FAIL';
export const WEBHOOK_DELETE_FETCH_SUCCESS = 'WEBHOOK_DELETE_FETCH_SUCCESS';

export const WEBHOOK_LIST_FETCH = 'WEBHOOK_LIST_FETCH';
export const WEBHOOK_LIST_FETCH_FAIL = 'WEBHOOK_LIST_FETCH_FAIL';
export const WEBHOOK_LIST_FETCH_SUCCESS = 'WEBHOOK_LIST_FETCH_SUCCESS';

export const WEBHOOK_UPDATE_FETCH = 'WEBHOOK_UPDATE_FETCH';
export const WEBHOOK_UPDATE_FETCH_FAIL = 'WEBHOOK_UPDATE_FETCH_FAIL';
export const WEBHOOK_UPDATE_FETCH_SUCCESS = 'WEBHOOK_UPDATE_FETCH_SUCCESS';

export const WEBHOOK_UPDATE_SUBMIT_FETCH = 'WEBHOOK_UPDATE_SUBMIT_FETCH';
export const WEBHOOK_UPDATE_SUBMIT_FETCH_FAIL =
  'WEBHOOK_UPDATE_SUBMIT_FETCH_FAIL';
export const WEBHOOK_UPDATE_SUBMIT_FETCH_SUCCESS =
  'WEBHOOK_UPDATE_SUBMIT_FETCH_SUCCESS';

export const WEBHOOK_WEBHOOK_VIEW_FETCH = 'WEBHOOK_WEBHOOK_VIEW_FETCH';
export const WEBHOOK_WEBHOOK_VIEW_FETCH_FAIL =
  'WEBHOOK_WEBHOOK_VIEW_FETCH_FAIL';
export const WEBHOOK_WEBHOOK_VIEW_FETCH_SUCCESS =
  'WEBHOOK_WEBHOOK_VIEW_FETCH_SUCCESS';

export const WEBHOOK_CHANGE_CURRENT = 'WEBHOOK_CHANGE_CURRENT';

export const WEBHOOK_CREATE_TOGGLE = 'WEBHOOK_CREATE_TOGGLE';

export const WEBHOOK_PAGE_CHANGE_CURRENT = 'WEBHOOK_PAGE_CHANGE_CURRENT';
export const WEBHOOK_LIST_EVENTS = 'WEBHOOK_LIST_EVENTS';
