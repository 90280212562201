import {
  UI_CONTROLLER_ADD_LOG,
  UI_CONTROLLER_ADD_LOG_FAIL,
  UI_CONTROLLER_ADD_LOG_SUCCESS,
} from './UiControllerConstants';
import UiControllerApi from './UiControllerApi';

export function uiControllerSubmitLog(data) {
  return (dispatch) => {
    dispatch({
      type: UI_CONTROLLER_ADD_LOG,
      payload: data,
    });
    UiControllerApi.create(data)
      .then((response) => {
        dispatch({
          type: UI_CONTROLLER_ADD_LOG_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: UI_CONTROLLER_ADD_LOG_FAIL,
          payload: response,
        });
      });
  };
}
