/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import JourneyTools from '../../../../component/journey';
import { showMessage } from '../../../../utils/RenderUtils';
import SendSmsComponent from './virtualSteps/SendSmsStep';
import SendEmailComponent from './virtualSteps/SendEmailStep';
import SendPushComponent from './virtualSteps/SendPushStep';
import SendWebPushComponent from './virtualSteps/SendWebPushStep';
import SendCustomComponent from './virtualSteps/SendCustomStep';
import SendInAppComponent from './virtualSteps/SendInAppStep';
import SendOnSiteComponent from './virtualSteps/SendOnSiteStep';
import SendWhatsAppStep from './virtualSteps/SendWhatsAppStep';
import { getJourneyId } from '@Utils/AuthorityProvider';
import { thirdPartyEnums } from '@Modules/admin/thirdParty/components/ThirdPartyTypes';
import { isNil } from 'lodash';

const JourneyModalContent = (type) => () => {
  return {
    SEND_SMS: (
      node,
      syncCache,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendSmsComponent
        dataNode={node.dataNode}
        updateJourney={syncCache}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
    SEND_EMAIL: (
      node,
      syncCache,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendEmailComponent
        dataNode={node.dataNode}
        updateJourney={syncCache}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
    SEND_PUSH: (
      node,
      syncCache,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendPushComponent
        dataNode={node.dataNode}
        updateJourney={syncCache}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
    SEND_WEB_PUSH: (
      node,
      syncCache,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendWebPushComponent
        dataNode={node.dataNode}
        updateJourney={syncCache}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
    SEND_WHATSAPP: (
      node,
      syncCache,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendWhatsAppStep
        dataNode={node.dataNode}
        updateJourney={syncCache}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
    SEND_CUSTOM: (
      node,
      syncCache,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendCustomComponent
        dataNode={node.dataNode}
        updateJourney={syncCache}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
    SEND_IN_APP: (
      node,
      syncCache,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => {
      return (
        <SendInAppComponent
          dataNode={node.dataNode}
          updateJourney={syncCache}
          closeModal={closeModal}
          readOnly={readOnly}
          journeyStepsForVariable={journeyStepsForVariable}
          type={type}
        />
      );
    },
    SEND_ON_SITE: (
      node,
      syncCache,
      closeModal,
      readOnly,
      journeyStepsForVariable
    ) => (
      <SendOnSiteComponent
        dataNode={node.dataNode}
        updateJourney={syncCache}
        closeModal={closeModal}
        readOnly={readOnly}
        journeyStepsForVariable={journeyStepsForVariable}
        type={type}
      />
    ),
  };
};

const JourneyAddComponent = (props) => {
  const {
    journeyCreate,
    journeyUpdate,
    journeyLaunch,
    journeyPause,
    journeyStop,
    journeyUpdateSubmit,
    journeyLocalUpdateSteps,
    clearModalData,
    redirect,
    publishAfterCreate,
    journeyTestApi,
    // errorTestApi,
    uiControllerSubmitLog,
  } = props;

  const journeyId = getJourneyId(props.location.pathname);
  const mode = props.match.params.type || 'view';
  const productId = props.match.params.productId;
  const [currentProduct, setCurrentProduct] = useState();
  const templateNames = props.templateNames;
  useEffect(() => {
    if (isNil(currentProduct)) return;
    if (currentProduct?.thirdPartyOption !== thirdPartyEnums.PARENT) {
      props.journeyListSegmentsFetch({
        productId,
      });
      if (journeyId) {
        props.journeyBusinessEventsReportFetch(journeyId, { stepId: 1 });
      }
    } else {
      props.journeyListTemplateNames(productId);
    }
    props.journeyListEventsFetch({
      productId,
    });
    props.journeyListBusinessEventsFetch({
      productId,
    });
    props.journeyListAttribtesFetch({
      productId,
    });
  }, [currentProduct]);

  useEffect(() => {
    if (props.currentUser)
      setCurrentProduct(
        props.currentUser?.products?.find((x) => x.id.toString() === productId)
      );
  }, [props.currentUser]);

  useEffect(() => {
    if (props.successCreateJourney) {
      showMessage(`${props.type} created successfully!`, 'success');
      if (redirect) {
        redirectToList();
      }
      // else if (redirect && publishAfterCreate) { }
    }
  }, [props.successCreateJourney]);

  useEffect(() => {
    if (props.successUpdateSubmitJourney) {
      if (props.showNotificationMessage) {
        showMessage(`${props.type} updated successfully!`, 'success');
      }
      if (!redirect) {
        return;
      }
      if (!props.successCreateJourney) {
        showMessage(`${props.type} updated successfully!`, 'success');
      }
      redirectToList();
    }
  }, [props.successUpdateSubmitJourney]);

  useEffect(() => {
    const isRelay = props.type === 'RELAY';
    if (props.launchData) {
      showMessage(
        `${isRelay ? 'Relay' : 'Journey'} published successfully!`,
        'success'
      );
      const url = props.match.url;
      const newUrl = isRelay
        ? url.split('/relay')[0] + '/relays/list'
        : url.split('/journey')[0] + '/journeys/list';

      setTimeout(() => {
        props.history.push(newUrl);
      }, 1000);
    } else if (props.errorLaunch) {
      // showMessage('Journey error on publish!', 'error');
    }
  }, [props.loadingLaunch]);

  const redirectToList = () => {
    const url = props.match.url;
    const isRelay = props.type === 'RELAY';
    const newUrl = isRelay
      ? url.split('/relay')[0] + '/relays/list'
      : url.split('/journey')[0] + '/journeys/list';
    setTimeout(() => {
      props.history.push(newUrl);
    }, 1000);
  };

  const loading =
    props.loadingCreateJourney ||
    props.loadingUpdateJourney ||
    props.loadingUpdateSubmitJourney ||
    props.loadingTestApi ||
    false;

  const JourneyModalContents = JourneyModalContent(props.type);
  return (
    <div style={{ padding: 0 }}>
      {currentProduct && (
        <JourneyTools
          type={
            currentProduct.thirdPartyOption === thirdPartyEnums.PARENT
              ? 'JOURNEY_TEMPLATE'
              : props.type
          }
          data={{
            journeyId,
            productId,
            journey: props.successUpdateJourney,
            newJourney: props.successCreateJourney,
            segments: props.successGetSegments,
            events: props.successGetEvents,
            businessEvents: props.successGetBusinessEvents,
            businessEventsReport: props.successGetBusinessEventsReport,
            journeyBusinessEventsReportFetch:
              props.journeyBusinessEventsReportFetch,
            loadingBusinessEventsReport: props.loadingBusinessEventsReport,
            attributes: props.attributes,
            testApiData: props.testApiData || props.errorTestApi,
            publishAfterCreate,
            segmentReport: {
              data: props.segmentReportView || {},
              loading: props.loadingSegmentReportView || false,
              error: props.errorSegmentReportView || null,
            },
            mode,
            currentProduct,
            templateNames,
            fetchRecommendEventAttributes:
              props.productSegmentRecommendEventAttributesFetch,
            recommendEventAttributes: props.recommendEventAttributes,
            fetchRecommendDeviceAttributes:
              props.productSegmentRecommendDeviceAttributesFetch,
            recommendDeviceAttributes: props.recommendDeviceAttributes,
            fetchRecommendUserAttributes:
              props.productSegmentRecommendUserAttributesFetch,
            recommendUserAttributes: props.recommendUserAttributes,
          }}
          modals={JourneyModalContents}
          clearModalData={clearModalData}
          create={journeyCreate}
          update={journeyUpdate}
          localUpdateSteps={journeyLocalUpdateSteps}
          launch={journeyLaunch}
          pause={journeyPause}
          stop={journeyStop}
          updateSubmit={journeyUpdateSubmit}
          testApi={journeyTestApi}
          getSegment={props.productSegmentReportViewFetch}
          history={props.history}
          loading={loading}
          errors={{
            launch: props.errorLaunch,
          }}
          submitLog={uiControllerSubmitLog}
        />
      )}
    </div>
  );
};

export default JourneyAddComponent;
