import {
  PROFILE_CHANGE_CURRENT,
  PASSWORD_CHANGE_CURRENT,
  PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH_FAIL,
  PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH,
  PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH_SUCCESS,
  PROFILE_CHANGE_PASSWORD_FETCH,
  PROFILE_CHANGE_PASSWORD_FETCH_FAIL,
  PROFILE_CHANGE_PASSWORD_FETCH_SUCCESS,
  PROFILE_UPDATE_FETCH_SUCCESS,
  PROFILE_UPDATE_FETCH,
  PROFILE_UPDATE_FETCH_FAIL,
  PROFILE_UPDATE_SUBMIT_FETCH,
  PROFILE_UPDATE_SUBMIT_FETCH_SUCCESS,
  PROFILE_UPDATE_SUBMIT_FETCH_FAIL,
  PROFILE_UPLOAD_PICTURE_FETCH_SUCCESS,
  PROFILE_VIEW_FETCH_SUCCESS,
  PROFILE_VERIFICATION_CODE,
  PROFILE_VERIFICATION_EMAIL,
  PROFILE_RESET_PASSWORD,
} from './ProfileConstants';

const initialState = {
  errors: [], // list of (key,message)
  currentProfile: {},
  loadingUpdateSubmit: false,
  loadingUpdate: false,
  loadingUpdatePassword: false,
  loadingUpdatePasswordSubmit: false,
  currentPassword: {},
  emailVerificationOTPCode: {
    loading: false,
    error: true,
  },
  emailVerification: {},
  resetPasswordLoading: false,
};

export default function ProfileStateReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loadingUpdatePasswordSubmit: false,
      };
    case PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH:
      return { ...state, loadingUpdatePasswordSubmit: true };
    case PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingUpdatePasswordSubmit: false };
    case PROFILE_CHANGE_CURRENT:
      return { ...state, currentProfile: action.payload, errors: [] };
    case PASSWORD_CHANGE_CURRENT:
      return { ...state, currentPassword: action.payload, errors: [] };
    case PROFILE_UPDATE_FETCH_SUCCESS:
      return { ...state, currentProfile: action.payload, loadingUpdate: false };
    case PROFILE_UPDATE_FETCH:
      return { ...state, loadingUpdate: true };
    case PROFILE_UPDATE_FETCH_FAIL:
      return { ...state, loadingUpdate: false };
    case PROFILE_UPDATE_SUBMIT_FETCH:
      return { ...state, loadingUpdateSubmit: true };
    case PROFILE_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loadingUpdateSubmit: false,
      };
    case PROFILE_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingUpdateSubmit: false };
    case PROFILE_CHANGE_PASSWORD_FETCH_SUCCESS:
      return {
        ...state,
        currentPassword: action.payload,
        loadingUpdatePassword: false,
      };
    case PROFILE_CHANGE_PASSWORD_FETCH:
      return { ...state, loadingUpdatePassword: true };
    case PROFILE_CHANGE_PASSWORD_FETCH_FAIL:
      return {
        ...state,
        loadingUpdatePassword: false,
        errors: action.payload.errors,
      };
    case PROFILE_VIEW_FETCH_SUCCESS:
      return { ...state, currentProfile: action.payload };
    case PROFILE_UPLOAD_PICTURE_FETCH_SUCCESS:
      return {
        ...state,
        currentProfile: { ...state.currentProfile, picture: action.payload },
      };
    case PROFILE_VERIFICATION_CODE:
      return { ...state, emailVerificationOTPCode: action.payload };
    case PROFILE_VERIFICATION_EMAIL:
      return { ...state, emailVerification: action.payload };
    case PROFILE_RESET_PASSWORD:
      return { ...state, resetPasswordLoading: action.payload };
    default:
      return state;
  }
}
