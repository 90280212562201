/* eslint-disable no-nested-ternary */
import React from 'react';
import NumberRowItem from './NumberRowItem';
import '../../style/ProductSdkAndroidStyle.less';
import texts from '../../../../../utils/texts';
const TrackUserFragment = (props) => {
  const TrackUserFragmentReactNative = [
    {
      number: 1,
      title: ' Login and Logout: ',
      highlightContent:
        '// Method login accepts a parameter with the following model: \n' +
        '       UserDetails: {\n' +
        '           firstName: String, \n' +
        '           lastName: String, \n' +
        '           email: String, \n' +
        '           phone: String, \n' +
        '           country: String, \n' +
        '           state: String, \n' +
        '           city: String, \n' +
        '           gender: "male" | "female" | "other", \n' +
        '           birthday: String, //ISO-8601 format \n' +
        '           company: String, \n' +
        '           hashedPhone: String, \n' +
        '           hashedEmail: String, \n' +
        '           smsOptIn: Boolean, \n' +
        '           emailOptIn: Boolean, \n' +
        '           userId: String, \n' +
        '           attributes: Object, \n' +
        '       } \n',
      url: `${texts.mobileSdkLogin}`,
      urlDescription:
        'To register login and logout of a user, the following methods can be called are',
      note: (
        <React.Fragment>
          {'✹ userId field is mandatory and you must always provide it. \n'}{' '}
          <br />
          {'✹ birthday is a string in ISO-8601 format. \n'} <br />
          {
            '✹ phone is a string with country code, for example: +989121234567 \n'
          }
        </React.Fragment>
      ),
    },
    {
      number: 2,
      title: 'Profile Updates​',
      url: `${texts.profile}`,
      urlDescription: `In case of having any changes in user's details, these changes can be sent to ${texts.brandName} server by calling the InTrack.updateProfile method`,
    },
  ];
  const TrackUserFragmentFlutter = [
    {
      number: 1,
      title: ' Login and Logout: ',
      highlightContent:
        '// Method login accepts a parameter with the following model: \n' +
        '       UserDetails: {\n' +
        '           firstName: String, \n' +
        '           lastName: String, \n' +
        '           email: String, \n' +
        '           phone: String, \n' +
        '           country: String, \n' +
        '           state: String, \n' +
        '           city: String, \n' +
        '           gender: "male" | "female" | "other", \n' +
        '           birthday: String, //ISO-8601 format \n' +
        '           company: String, \n' +
        '           hashedPhone: String, \n' +
        '           hashedEmail: String, \n' +
        '           smsOptIn: Boolean, \n' +
        '           emailOptIn: Boolean, \n' +
        '           userId: String, \n' +
        '           attributes: Object, \n' +
        '       } \n',
      url: `${texts.flutterSdkLogin}`,
      urlDescription:
        'To register login and logout of a user, the following methods can be called are',
      note: (
        <React.Fragment>
          {'✹ userId field is mandatory and you must always provide it. \n'}{' '}
          <br />
          {'✹ birthday is a string in ISO-8601 format. \n'} <br />
          {
            '✹ phone is a string with country code, for example: +989121234567 \n'
          }
        </React.Fragment>
      ),
    },
    {
      number: 2,
      title: 'Profile Updates​',
      url: `${texts.flutterProfile}`,
      urlDescription: `In case of having any changes in user's details, these changes can be sent to ${texts.brandName} server by calling the InTrack.updateProfile method`,
    },
  ];
  const TrackUserFragmentAndroid = [
    {
      number: 1,
      title: ' Login and Logout: ',
      highlightContent:
        `// Method recordLogin accepts a ir.${texts.brandname}.android.sdk.UserDetails: \n  \n ` +
        `   public String firstName;
    public String lastName;
    public String email;
    public String phone;

    // Location
    public String country;
    public String state;
    public String city;

    // System Attributes
    public ApiUserGender gender;
    public Date birthday;
    public String company;

    // Hashed Contact Info
    public String hashedPhone;
    public String hashedEmail;

    // Opt In
    public Boolean smsOptIn;
    public Boolean emailOptIn;

    // Identifiers
    public String userId;

    public Map<String, Object> userAttributes = new HashMap<>();`,
      url: `${texts.androidSdkLogin}`,
      urlDescription:
        'To register login and logout of a user, the following methods can be called are',
      note: (
        <React.Fragment>
          {'✹userId field is mandatory and you must always provide it. \n'}{' '}
          <br />
          {'✹birthday is a string in ISO-8601 format. \n'} <br />
          {
            '✹phone is a string with country code, for example: +989121234567 \n'
          }
        </React.Fragment>
      ),
    },
    {
      number: 2,
      title: 'Profile Updates​',
      url: `${texts.androidProfile}`,
      urlDescription: `In case of having any changes in user's details, these changes can be sent to ${texts.brandName} server by calling the following method`,
    },
  ];
  const TrackUserFragmentIos = [
    {
      number: 1,
      title: ' Login and Logout IOS: ',
      highlightContent:
        '// Method login accepts  an instance of InTrackUserDetails class: \n' +
        '//InTrackUserDetails.h \n' +
        '       #import "ApiUserGender.h" \n' +
        '       **@interface**  InTrackUserDetails : NSObject \n' +
        '           **@property** (**nonatomic**, **copy**) NSString * firstName; \n' +
        '           **@property** (**nonatomic**, **copy**) NSString * lastName; \n' +
        '           **@property** (**nonatomic**, **copy**) NSString * email; \n' +
        '           **@property** (**nonatomic**, **copy**) NSString * phone; \n' +
        '           **@property** (**nonatomic**, **copy**) NSString * country; \n' +
        '           **@property** (**nonatomic**, **copy**) NSString * state; \n' +
        '           **@property** (**nonatomic**, **copy**) NSString * city; \n' +
        '           **@property** (**nonatomic**) **enum**  ApiUserGender  gender; \n' +
        '           **@property** (**nonatomic**, **copy**) NSDate * birthday; \n' +
        '           **@property** (**nonatomic**, **copy**) NSString * company; \n' +
        '           **@property** (**nonatomic**, **copy**) NSString * hashedPhone; \n' +
        '           **@property** (**nonatomic**, **copy**) NSString * hashedEmail; \n' +
        '           **@property** (**nonatomic**, **copy**) NSString * smsOptIn; \n' +
        '           **@property** (**nonatomic**, **copy**) NSString * emailOptIn; \n' +
        '           **@property** (**nonatomic**, **copy**) NSString * userId; \n' +
        '           **@property** (**nonatomic**, **copy**) NSDictionary * userAttributes; \n' +
        '           - (NSDictionary *)toDictionary; \n' +
        '       **@end** \n',
      url: `${texts.iosSdkLogin}`,
      urlDescription:
        'To register login and logout of a user, the following methods can be called are',
      note: (
        <React.Fragment>
          {
            '✹note that userId field is mandatory and you must always provide it \n'
          }
        </React.Fragment>
      ),
    },
    {
      number: 2,
      title: 'Profile Updates​',
      highlightContent:
        '  InTrack.updateProfile(userDetails: InTrackUserDetails!); \n',
      url: `${texts.iosProfile}`,
      urlDescription: `In case of having any changes in user's details, these changes can be sent to ${texts.brandName} server by calling the following method`,
    },
  ];
  const TrackUserFragmentWeb = [
    {
      number: 1,
      title: ' Login and Logout: ',
      highlightContent:
        '// Method login accepts a parameter with the following model: \n' +
        '       UserDetails: {\n' +
        '           firstName: String, \n' +
        '           lastName: String, \n' +
        '           email: String, \n' +
        '           phone: String, \n' +
        '           country: String, \n' +
        '           state: String, \n' +
        '           city: String, \n' +
        '           gender: "male" | "female" | "other", \n' +
        '           birthday: String, //ISO-8601 format \n' +
        '           company: String, \n' +
        '           hashedPhone: String, \n' +
        '           hashedEmail: String, \n' +
        '           smsOptIn: Boolean, \n' +
        '           emailOptIn: Boolean, \n' +
        '           userId: String, \n' +
        '           attributes: Object, \n' +
        '       } \n',
      url: `${texts.webSdkLogin}`,
      urlDescription:
        'To register login and logout of a user, the following methods can be called are',
      note: (
        <React.Fragment>
          {'✹userId field is mandatory and you must always provide it. \n'}{' '}
          <br />
          {'✹birthday is a string in ISO-8601 format. \n'} <br />
          {
            '✹phone is a string with country code, for example: +989121234567 \n'
          }
        </React.Fragment>
      ),
    },
    {
      number: 2,
      title: 'Profile Updates​',
      url: `${texts.webSdkProfile}`,
      urlDescription: `In case of having any changes in user's details, these changes can be sent to ${texts.brandName} server by calling the following method`,
    },
  ];
  let items =
    props.sdkType === 'flutter'
      ? TrackUserFragmentFlutter
      : props.sdkType === 'react-native'
      ? TrackUserFragmentReactNative
      : props.sdkType === 'android'
      ? TrackUserFragmentAndroid
      : props.sdkType === 'ios'
      ? TrackUserFragmentIos
      : TrackUserFragmentWeb;
  return (
    <div>
      {items.map((item, index) => {
        return (
          <NumberRowItem
            key={index}
            number={item.number}
            title={item.title}
            highlightContent={item.highlightContent}
            url={item.url}
            urlDescription={item.urlDescription}
            note={item.note}
          />
        );
      })}
    </div>
  );
};

export default TrackUserFragment;
