const AttributeType = {
  BOOLEAN: 'Boolean',
  NUMERIC: 'Numeric',
  STRING: 'String',
  DATE: 'Date',
  ARRAY: 'Array',
  OBJECT: 'Object',
};

export default AttributeType;
