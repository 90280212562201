import React from 'react';
import { Input, Select, Modal, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { history } from '../../../../redux/store';
import { isEmpty } from 'lodash';
const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    direction: 'ltr',
    fontWeight: '700',
    color: '#3d404e',
    fontSize: 17,
    textAlign: 'left',
  },
  rowItem: {
    display: 'flex',
    flexDirection: 'row',
    color: '#707070',
    marginTop: 10,
    marginBottom: 10,
  },
  buttons: {
    margin: '0px 5px',
  },
};

class MembersUpdateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.teamId = this.props.pathname.split('update/').pop();
    (this.updateMode = this.props.pathname.includes('update')),
      (this.state = {
        editingId: null,
        url: '',
        openModal: true,
        roleArray: [],
      });
  }
  componentDidMount() {
    this.props.listProductRolesFetch();
    if (this.updateMode) {
      this.props.teamUpdateFetch(parseInt(this.teamId));
    }
  }
  componentWillUnmount() {
    this.props.newTeaMemberChangeCurrent({});
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.updateTeamLoading === true &&
      this.props.updateTeamLoading === false
    ) {
      let roleTitle = [];
      if (this.props.roleList && Array.isArray(this.props.roleList)) {
        this.props.roleList.forEach((itemOne) => {
          if (
            this.props.currentTeamMember &&
            Array.isArray(this.props.currentTeamMember.roles)
          ) {
            this.props.currentTeamMember.roles.forEach((itemTwo) => {
              if (itemOne.id.toString() === itemTwo.toString()) {
                roleTitle.push(itemOne.name);
              }
            });
          }
        });
      }
    }
  }
  renderRoleList = () => {
    let roleListItems = [];
    if (this.props.roleList && Array.isArray(this.props.roleList)) {
      this.props.roleList.forEach((item) => {
        roleListItems.push(
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        );
      });
    }
    return roleListItems;
  };
  roleTitle = () => {
    let roleTitle = [];
    if (this.props.roleList && Array.isArray(this.props.roleList)) {
      this.props.roleList.forEach((itemOne) => {
        if (
          this.props.currentTeamMember &&
          Array.isArray(this.props.currentTeamMember.roles)
        ) {
          this.props.currentTeamMember.roles.forEach((itemTwo) => {
            if (itemOne.id.toString() === itemTwo.toString()) {
              roleTitle.push(itemOne.name);
              this.props.newTeaMemberChangeCurrent({
                ...this.props.currentTeamMember,
                userProducts: [{ roles: roleTitle }],
              });
            }
          });
        }
      });
    }
    return roleTitle;
  };
  render() {
    const loadingSpin = (
      <LoadingOutlined
        style={{ justifyContent: 'center', fontSize: 30, alignItems: 'center' }}
        spin
      />
    );
    return (
      <Modal
        width={'85%'}
        title={
          <div style={styles.header}>
            {this.updateMode ? 'Edit Team Member' : 'Invite Team Member'}
          </div>
        }
        visible={this.state.openModal}
        onCancel={() => {
          if (this.props.pathname.includes('create')) {
            history.push('../members');
          } else {
            history.push('../../members');
          }
        }}
        footer={
          <Button
            type="primary"
            shape="round"
            size={'large'}
            loading={
              this.updateMode
                ? this.props.updateSubmitLoading
                : this.props.createTeamLoading
            }
            style={styles.buttons}
            onClick={() => {
              if (this.updateMode) {
                this.props.teamUpdateSubmitFetch(
                  this.teamId,
                  this.props.currentTeamMember
                );
              } else {
                this.props.teamCreateFetch(this.props.currentTeamMember);
              }
              this.setState({ permissionsModalOpen: false });
            }}
          >
            {this.updateMode ? 'SUBMIT' : 'SEND INVITE'}
          </Button>
        }
      >
        {this.props.updateTeamLoading ? (
          <Spin
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: 50,
              marginBottom: 50,
            }}
            indicator={loadingSpin}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 50,
            }}
          >
            <div style={styles.rowItem}>
              NAME
              <Input
                disabled={this.updateMode}
                value={
                  this.props.currentTeamMember &&
                  this.props.currentTeamMember.user &&
                  this.props.currentTeamMember.user.firstName
                }
                style={{ width: 500, marginLeft: 105, color: '#707070' }}
                onChange={(e) => {
                  this.props.newTeaMemberChangeCurrent({
                    ...this.props.currentTeamMember,
                    firstName: e.target.value,
                  });
                }}
              ></Input>
            </div>
            <div style={styles.rowItem}>
              LAST NAME
              <Input
                disabled={this.updateMode}
                value={
                  this.props.currentTeamMember &&
                  this.props.currentTeamMember.user &&
                  this.props.currentTeamMember.user.lastName
                }
                style={{ width: 500, marginLeft: 66, color: '#707070' }}
                onChange={(e) => {
                  this.props.newTeaMemberChangeCurrent({
                    ...this.props.currentTeamMember,
                    presentation: e.target.value,
                    lastName: e.target.value,
                  });
                }}
              ></Input>
            </div>
            <div style={styles.rowItem}>
              EMAIL ADDRESS
              <Input
                disabled={this.updateMode}
                value={
                  this.props.currentTeamMember &&
                  this.props.currentTeamMember.user &&
                  this.props.currentTeamMember.user.email
                }
                style={{ width: 500, marginLeft: 35, color: '#707070' }}
                onChange={(e) => {
                  this.props.newTeaMemberChangeCurrent({
                    ...this.props.currentTeamMember,
                    email: e.target.value,
                  });
                }}
              ></Input>
            </div>
            <div style={styles.rowItem}>
              ROLE
              <Select
                loading={this.props.updateTeamLoading}
                value={
                  this.props.currentTeamMember &&
                  this.props.currentTeamMember.roles
                }
                style={{ width: 500, marginLeft: 110, color: '#707070' }}
                mode="multiple"
                onChange={(val) => {
                  let roleArray = [];
                  val.forEach((item) => {
                    roleArray.push(parseInt(item));
                  });
                  if (!isEmpty(roleArray)) {
                    if (this.updateMode) {
                      this.props.newTeaMemberChangeCurrent({
                        ...this.props.currentTeamMember,
                        roles: roleArray,
                      });
                    } else {
                      this.props.newTeaMemberChangeCurrent({
                        ...this.props.currentTeamMember,
                        userProducts: [{ roles: roleArray }],
                      });
                    }
                  }
                }}
              >
                {this.renderRoleList()}
              </Select>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
export default MembersUpdateComponent;
