export const INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH =
  'INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH';
export const INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH_FAIL =
  'INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH_FAIL';
export const INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH_SUCCESS =
  'INSIGHT_PRODUCT_USER_ACTIVITY_REPORT_FETCH_SUCCESS';

export const INSIGHT_PRODUCT_USER_ANALYZE_FETCH =
  'INSIGHT_PRODUCT_USER_ANALYZE_FETCH';
export const INSIGHT_PRODUCT_USER_ANALYZE_FETCH_FAIL =
  'INSIGHT_PRODUCT_USER_ANALYZE_FETCH_FAIL';
export const INSIGHT_PRODUCT_USER_ANALYZE_FETCH_SUCCESS =
  'INSIGHT_PRODUCT_USER_ANALYZE_FETCH_SUCCESS';

export const INSIGHT_PRODUCT_USER_DEVICES_FETCH =
  'INSIGHT_PRODUCT_USER_DEVICES_FETCH';
export const INSIGHT_PRODUCT_USER_DEVICES_FETCH_FAIL =
  'INSIGHT_PRODUCT_USER_DEVICES_FETCH_FAIL';
export const INSIGHT_PRODUCT_USER_DEVICES_FETCH_SUCCESS =
  'INSIGHT_PRODUCT_USER_DEVICES_FETCH_SUCCESS';

export const INSIGHT_PRODUCT_USER_EVENTS_FETCH =
  'INSIGHT_PRODUCT_USER_EVENTS_FETCH';
export const INSIGHT_PRODUCT_USER_EVENTS_FETCH_FAIL =
  'INSIGHT_PRODUCT_USER_EVENTS_FETCH_FAIL';
export const INSIGHT_PRODUCT_USER_EVENTS_FETCH_SUCCESS =
  'INSIGHT_PRODUCT_USER_EVENTS_FETCH_SUCCESS';

export const INSIGHT_PRODUCT_USER_LIST_FETCH =
  'INSIGHT_PRODUCT_USER_LIST_FETCH';
export const INSIGHT_PRODUCT_USER_LIST_FETCH_FAIL =
  'INSIGHT_PRODUCT_USER_LIST_FETCH_FAIL';
export const INSIGHT_PRODUCT_USER_LIST_FETCH_SUCCESS =
  'INSIGHT_PRODUCT_USER_LIST_FETCH_SUCCESS';

export const INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH =
  'INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH';
export const INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH_FAIL =
  'INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH_FAIL';
export const INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH_SUCCESS =
  'INSIGHT_PRODUCT_USER_LIST_ATTRIBUTE_FETCH_SUCCESS';

export const INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH =
  'INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH';
export const INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH_FAIL =
  'INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH_FAIL';
export const INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH_SUCCESS =
  'INSIGHT_PRODUCT_USER_REACHABILITY_REPORT_FETCH_SUCCESS';

export const INSIGHT_PRODUCT_USER_VIEW_FETCH =
  'INSIGHT_PRODUCT_USER_VIEW_FETCH';
export const INSIGHT_PRODUCT_USER_VIEW_FETCH_FAIL =
  'INSIGHT_PRODUCT_USER_VIEW_FETCH_FAIL';
export const INSIGHT_PRODUCT_USER_VIEW_FETCH_SUCCESS =
  'INSIGHT_PRODUCT_USER_VIEW_FETCH_SUCCESS';

export const INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH =
  'INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH';
export const INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH_FAIL =
  'INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH_FAIL';
export const INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH_SUCCESS =
  'INSIGHT_PRODUCT_USER_COMMUNICATION_FETCH_SUCCESS';

export const INSIGHT_PRODUCT_USER_PAGE_CHANGE_CURRENT =
  'INSIGHT_PRODUCT_USER_PAGE_CHANGE_CURRENT';

export const INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH =
  'INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH';
export const INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH_SUCCESS =
  'INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH_SUCCESS';
export const INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH_FAIL =
  'INSIGHT_PRODUCT_USER_SEARCH_LIST_FETCH_FAIL';

export const USER_ATTRIBUTE_LIST_FETCH = 'USER_ATTRIBUTE_LIST_FETCH';
export const USER_ATTRIBUTE_LIST_FETCH_SUCCESS =
  'USER_ATTRIBUTE_LIST_FETCH_SUCCESS';
export const USER_ATTRIBUTE_LIST_FETCH_FAIL = 'USER_ATTRIBUTE_LIST_FETCH_FAIL';

export const USER_ANALYTICS_FETCH = 'USER_ANALYTICS_FETCH';
export const USER_ANALYTICS_FETCH_SUCCESS = 'USER_ANALYTICS_FETCH_SUCCESS';
export const USER_ANALYTICS_FETCH_FAIL = 'USER_ANALYTICS_FETCH_FAIL';

export const USER_CHANGE_CURRENT = 'USER_CHANGE_CURRENT';

export const INSIGHT_PRODUCT_USER_LIST_EVENT_FETCH_SUCCESS =
  'INSIGHT_PRODUCT_USER_LIST_EVENT_FETCH_SUCCESS';

export const INSIGHT_PRODUCT_USER_JOURNEY_FETCH =
  'INSIGHT_PRODUCT_USER_JOURNEY_FETCH';
export const INSIGHT_PRODUCT_USER_JOURNEY_FETCH_FAIL =
  'INSIGHT_PRODUCT_USER_JOURNEY_FETCH_FAIL';
export const INSIGHT_PRODUCT_USER_JOURNEY_FETCH_SUCCESS =
  'INSIGHT_PRODUCT_USER_JOURNEY_FETCH_SUCCESS';

export const INSIGHT_PRODUCT_USER_RELAY_FETCH =
  'INSIGHT_PRODUCT_USER_RELAY_FETCH';
export const INSIGHT_PRODUCT_USER_RELAY_FETCH_SUCCESS =
  'INSIGHT_PRODUCT_USER_RELAY_FETCH_SUCCESS';
export const INSIGHT_PRODUCT_USER_RELAY_FETCH_FAIL =
  'INSIGHT_PRODUCT_USER_RELAY_FETCH_FAIL';
