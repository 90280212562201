import React from 'react';
import { isNil, find as lodashFind } from 'lodash';
import moment from 'moment';
import { renderCampaignStatus, renderTag } from '../../../../utils/RenderUtils';
import {
  getChannel,
  getCommunicationId,
} from '../../../../utils/AuthorityProvider';
import { renderDelay } from '../../../../utils/ReducerUtils';
import {
  convertMinute,
  readableDateTime,
  viewTime,
} from '../../../../utils/DateUtils';
import { isEmpty } from 'lodash';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { variationLetter } from '../../../../utils/FormUtils';
import { currentConversionType } from './communicationConversion/CommunicationConst';
const styles = {
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'right',
  },
  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    display: 'flex',
    flexDirection: 'row',
  },
  infoBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 30px',
    borderBottom: '1px solid #e7e8e9',
    backgroundColor: '#fdfdfd',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dataRowContainer: {
    padding: 30,
  },
  dataRowFirst: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dataRow: {
    paddingTop: 10,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dataValue: {
    direction: 'ltr',
    fontSize: 13,
    color: '#3d404e',
  },
  dataContainer: {
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  dataLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '20px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
  },
  methodContainer: {
    border: '1px solid #e7e8e9',
    borderRadius: '5px',
    padding: '20px',
    height: 460,
    width: 247,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
};

class CommunicationDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.channel = getChannel(this.props.pathname);
    this.communicationId = getCommunicationId(this.props.pathname);
    this.state = {
      segment: 0,
      activeVariationIndex: 0,
    };
  }

  componentDidMount() {
    this.props.communicationViewFetch(this.communicationId);
    this.props.communicationListPushChannelsFetch();
    this.props.communicationListSmsChannelsFetch();
    this.props.communicationListEventsFetch();
    this.props.communicationListSegmentsFetch();
    this.props.communicationListTagsFetch();
  }

  getStartDate = (preview = false) => {
    let startDate = moment().toDate();
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.startDate
    ) {
      startDate = this.props.currentCommunication.startDate;
    }
    if (preview) {
      return !startDate ? '-' : readableDateTime(startDate);
    }
    return startDate;
  };

  getEndDate = (preview = false) => {
    let endDate = null;
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.endDate &&
      this.props.currentCommunication.type !== 'ONE_TIME'
    ) {
      endDate = this.props.currentCommunication.endDate;
    }
    if (preview) {
      return !endDate ? '-' : readableDateTime(endDate);
    }
    return endDate;
  };

  renderAudienceType = () => {
    let result = null;
    if (
      (this.props.currentCommunication &&
        (isNil(this.props.currentCommunication.segments) ||
          isEmpty(this.props.currentCommunication.segments)) &&
        (isNil(this.props.currentCommunication.ignoreSegments) ||
          isEmpty(this.props.currentCommunication.ignoreSegments))) ||
      (this.props.currentCommunication &&
        this.props.currentCommunication.segments &&
        this.props.currentCommunication.segments.length === 1 &&
        (!this.props.currentCommunication.ignoreSegments ||
          (this.props.currentCommunication.ignoreSegments &&
            this.props.currentCommunication.ignoreSegments.length === 0)))
    ) {
      result = 'Single Segment';
    } else {
      result = 'Multiple Segment';
    }
    return result;
  };

  renderPushPackageNames = () => {
    const result = [];
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.pushChannels &&
      this.props.pushChannels
    ) {
      this.props.currentCommunication.pushChannels.forEach((item) => {
        const foundItem = lodashFind(
          this.props.pushChannels,
          (o) => o.id === item
        );
        if (foundItem && foundItem.packageName) {
          result.push(foundItem.packageName);
        }
      });
    }
    if (result.length > 0) {
      return result.join(', ');
    } else {
      return '-';
    }
  };

  renderWeekDay = (days) => {
    let result = '';
    const daysName = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    if (Array.isArray(days) && days.length > 0) {
      days.forEach((day, index) => {
        result += daysName[day];
        if (index !== days.length - 1) {
          result += ', ';
        }
      });
    }
    return result;
  };

  renderMonthDay = (days) => {
    let result = '';
    if (Array.isArray(days) && days.length > 0) {
      days.forEach((day, index) => {
        if (day == -1) {
          result = 'Last day of month';
          if (index !== days.length - 1) {
            result += ', ';
          }
        } else {
          const dayNumber = Number(day) + 1;
          result += dayNumber + '';
          if (index !== days.length - 1) {
            result += ', ';
          }
        }
      });
    }
    return result;
  };

  renderRecuringTime = () => {
    let result = '-';
    if (this.props.currentCommunication) {
      if (this.props.currentCommunication.repeatType === 'DAY') {
        result =
          'Send Every ' +
          this.props.currentCommunication.repeatEvery +
          ' ' +
          'Day' +
          (this.props.currentCommunication.repeatEvery == 1 ? ' ' : 's ') +
          ' at ' +
          viewTime(this.props.currentCommunication.repeatTime);
      } else if (this.props.currentCommunication.repeatType === 'WEEK') {
        result =
          'Send Every ' +
          this.props.currentCommunication.repeatEvery +
          ' ' +
          'Week' +
          (this.props.currentCommunication.repeatEvery == 1 ? ' ' : 's ') +
          this.renderWeekDay() +
          ' at ' +
          viewTime(this.props.currentCommunication.repeatTime);
      } else if (this.props.currentCommunication.repeatType === 'MONTH') {
        result =
          'Send Every ' +
          this.props.currentCommunication.repeatEvery +
          ' ' +
          'Month' +
          (this.props.currentCommunication.repeatEvery == 1 ? ' ' : 's ') +
          this.renderMonthDay() +
          ' at ' +
          viewTime(this.props.currentCommunication.repeatTime);
      }
    }
    return result;
  };

  renderDeliveryTime = () => {
    let result = '';
    if (
      this.props.currentCommunication &&
      (isNil(this.props.currentCommunication.delayMinutes) ||
        this.props.currentCommunication.delayMinutes == 0)
    ) {
      result =
        this.props?.currentCommunication?.channel === 'IN_APP'
          ? "On User's Device Request"
          : 'On Event Occurrence';
    } else {
      const delayMinutes = this.props.currentCommunication.delayMinutes;
      result = renderDelay(delayMinutes) + ', After Event Occurrencesss';
    }
    return result;
  };

  renderConversionTime = () => {
    let result = '-';
    if (
      this.props.currentCommunication &&
      (this.props.currentCommunication.conversionDeadlineMinutes === null ||
        this.props.currentCommunication.conversionDeadlineMinutes == 0)
    ) {
      result = 'On Event Occurrence';
    } else {
      const delayMinutes =
        this.props.currentCommunication.conversionDeadlineMinutes;
      result = renderDelay(delayMinutes) + ', After Event Occurrence';
    }
    return result;
  };

  renderWinnerTestTime = () => {
    let result = '-';
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.winnerVariationTimeToTestMinutes
    ) {
      const delayMinutes =
        this.props.currentCommunication.winnerVariationTimeToTestMinutes;
      result = renderDelay(delayMinutes);
    }
    return result;
  };

  renderWinner = () => {
    let result = '-';
    let index = this.props.currentCommunication?.variations?.findIndex(
      (variation) =>
        variation.id ===
        this.props.currentCommunication?.winnerMechanismWinnerVariationId
    );

    result = index > -1 ? `Variation ${variationLetter[index]}` : '-';

    return result;
  };

  isJourneyType = () => {
    return (
      this.props.currentCommunication &&
      this.props.currentCommunication.type === 'JOURNEY'
    );
  };

  renderCampaignType = () => {
    let result = '';
    if (this.props.currentCommunication) {
      if (this.props.currentCommunication.type === 'TRIGGERED') {
        result = 'Triggered';
      } else if (this.props.currentCommunication.type === 'ONE_TIME') {
        result = 'One Time';
      } else if (this.props.currentCommunication.type === 'RECURRING') {
        result = 'Recurring';
      } else if (this.props.currentCommunication.type === 'TRANSACTIONAL') {
        result = 'Transactional';
      } else if (this.props.currentCommunication.type === 'JOURNEY') {
        result = 'Journey';
      }
    }
    return result;
  };

  renderTriggeredEvent = () => {
    let result = '';
    if (
      this.props.currentCommunication &&
      !isNil(this.props.currentCommunication.event)
    ) {
      const foundItem = lodashFind(
        this.props.eventList,
        (o) => o.id === this.props.currentCommunication.event
      );
      if (foundItem && foundItem.title) {
        result = foundItem.title;
      }
    }
    return result ? result : '-';
  };

  renderEventName = (id) => {
    let result = '-';
    const foundItem = lodashFind(this.props.eventList, (o) => o.id === id);
    if (foundItem && foundItem.title) {
      result = foundItem.title;
    }
    return result;
  };

  renderTags = () => {
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.tags &&
      Array.isArray(this.props.currentCommunication.tags) &&
      this.props.currentCommunication.tags.length > 0
    ) {
      let result = [];
      this.props.currentCommunication.tags.forEach((item) => {
        result.push(
          <span style={{ marginRight: 10 }}>
            {renderTag(item, this.props.listTags)}
          </span>
        );
      });
      return result;
    }
    return '-';
  };

  renderPostfixQueue = (value, text) => {
    if (!isNil(value) && !isNil(text)) {
      if (value && value == 1) {
        return 'Up to ' + value + ' ' + text.toLowerCase();
      } else {
        return 'Up to ' + value + ' ' + text.toLowerCase() + 's';
      }
    }
    return '-';
  };

  renderQueueMinutes = () => {
    if (this.props.currentCommunication.queueMinutes) {
      const queue = convertMinute(this.props.currentCommunication.queueMinutes);
      return this.renderPostfixQueue(queue.value, queue.type);
    }
    return '';
  };

  renderPreview = () => {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    return (
      <div className="raman-table">
        {this.props.loadingView ? (
          <div style={{ background: 'none', backgroundColor: 'white' }}>
            <div style={{ border: '1px solid #F1F1F3' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  paddingTop: 50,
                  paddingBottom: 50,
                }}
              >
                <Spin indicator={loadingSpin} />
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <div style={{ background: 'none', backgroundColor: 'white' }}>
              <div style={{ border: '1px solid #F1F1F3' }}>
                <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                  <div style={styles.headTitleContainer}>
                    <span>Communication Metadata</span>
                  </div>
                </div>
                <div style={styles.dataRowContainer}>
                  <div style={styles.dataRowFirst}>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>Communication Status</span>
                      <span style={styles.dataValue}>
                        {this.props.currentCommunication &&
                        this.props.currentCommunication.status
                          ? renderCampaignStatus(
                              this.props.currentCommunication.status
                            )
                          : '-'}
                      </span>
                    </div>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>Tags</span>
                      <span style={styles.dataValue}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {this.renderTags()}
                        </div>
                      </span>
                    </div>
                    <div style={styles.dataContainer}></div>
                  </div>
                </div>
              </div>
            </div>
            {this.props.currentCommunication.type === 'TRANSACTIONAL' && (
              <div
                className="raman-paper-profile"
                style={{
                  background: 'none',
                  marginTop: 40,
                  backgroundColor: 'white',
                }}
              >
                <div style={{ border: '1px solid #F1F1F3' }}>
                  <div style={styles.infoBoxHeader}>
                    <div style={styles.headTitleContainer}>
                      <span>Basic Info</span>
                      {false && (
                        <div style={{ cursor: 'pointer' }}>
                          <i
                            className="fl-edit"
                            style={{
                              border: '1px solid',
                              borderRadius: '50%',
                              fontSize: '28px',
                              color: '#94969e',
                              marginLeft: 10,
                            }}
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    ...styles.dataRowFirst,
                    marginLeft: 40,
                    marginTop: 30,
                    paddingBottom: 40,
                  }}
                >
                  <div style={styles.dataContainer}>
                    <span style={styles.dataLabel}>COMMUNICATION NAME</span>
                    <span style={styles.dataValue}>
                      {this.props.currentCommunication &&
                      this.props.currentCommunication.name
                        ? this.props.currentCommunication.name
                        : '-'}
                    </span>
                  </div>
                  <div style={styles.dataContainer}>
                    <span style={styles.dataLabel}>CAMPAIGN TYPE</span>
                    <span style={styles.dataValue}>
                      {this.renderCampaignType()}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {this.props.currentCommunication.type !== 'TRANSACTIONAL' && (
              <div
                className="raman-paper-profile"
                style={{
                  background: 'none',
                  marginTop: 40,
                  backgroundColor: 'white',
                }}
              >
                <div style={{ border: '1px solid #F1F1F3' }}>
                  <div
                    style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}
                  >
                    <div style={styles.headTitleContainer}>
                      <span>Audience</span>
                    </div>
                  </div>
                  <div style={styles.dataRowContainer}>
                    <div style={styles.dataRowFirst}>
                      <div style={styles.dataContainer}>
                        <span style={styles.dataLabel}>COMMUNICATION NAME</span>
                        <span style={styles.dataValue}>
                          {this.props.currentCommunication &&
                          this.props.currentCommunication.name
                            ? this.props.currentCommunication.name
                            : '-'}
                        </span>
                      </div>
                      <div style={styles.dataContainer}>
                        <span style={styles.dataLabel}>CONTENT TYPE</span>
                        <span style={styles.dataValue}>
                          {this.props.currentCommunication &&
                          this.props.currentCommunication.transactional ===
                            false
                            ? 'Promotional'
                            : 'Transactional'}
                        </span>
                      </div>
                      <div style={styles.dataContainer}>
                        <span style={styles.dataLabel}>AUDIENCE TYPE</span>
                        <span style={styles.dataValue}>
                          {this.renderAudienceType()}
                        </span>
                      </div>
                    </div>
                    <div style={styles.dataRow}>
                      <div style={styles.dataContainer}>
                        <span style={styles.dataLabel}>Send to</span>
                        <span style={styles.dataValue}>
                          {this.renderSendTo()}
                        </span>
                      </div>
                      <div style={styles.dataContainer}>
                        {this.channel === 'PUSH' && (
                          <React.Fragment>
                            <span style={styles.dataLabel}>Target Device</span>
                            <span style={styles.dataValue}>
                              {this.props.currentCommunication &&
                              isNil(
                                this.props.currentCommunication.mobileDevice
                              )
                                ? 'Both Android & iOS'
                                : this.props.currentCommunication
                                    .mobileDevice === 'ANDROID'
                                ? 'Android Only'
                                : 'iOS Only'}
                            </span>
                          </React.Fragment>
                        )}
                      </div>
                      <div style={styles.dataContainer}>
                        {this.channel === 'PUSH' && (
                          <React.Fragment>
                            <span style={styles.dataLabel}>Target Apps:</span>
                            <span style={styles.dataValue}>
                              {this.renderPushPackageNames()}
                            </span>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.props.currentCommunication.type !== 'TRANSACTIONAL' && (
              <div
                className="raman-paper-profile"
                style={{
                  background: 'none',
                  marginTop: 40,
                  backgroundColor: 'white',
                }}
              >
                <div style={{ border: '1px solid #F1F1F3' }}>
                  <div style={styles.infoBoxHeader}>
                    <div style={styles.headTitleContainer}>
                      <span>When</span>
                    </div>
                  </div>
                  <div style={styles.dataRowContainer}>
                    <div style={styles.dataRowFirst}>
                      <div style={styles.dataContainer}>
                        <span style={styles.dataLabel}>CAMPAIGN TYPE</span>
                        <span style={styles.dataValue}>
                          {this.renderCampaignType()}
                        </span>
                      </div>
                      {this.props.currentCommunication &&
                        this.props.currentCommunication.type ===
                          'TRIGGERED' && (
                          <div style={styles.dataContainer}>
                            <span style={styles.dataLabel}>TRIGGER EVENT</span>
                            <span style={styles.dataValue}>
                              {this.renderTriggeredEvent()}
                            </span>
                          </div>
                        )}
                      {!this.isJourneyType() && (
                        <div style={styles.dataContainer}>
                          <span style={styles.dataLabel}>DELIVERY TIME</span>
                          <span style={styles.dataValue}>
                            {this.props.currentCommunication &&
                            this.props.currentCommunication.type === 'ONE_TIME'
                              ? this.getStartDate(true)
                              : this.props.currentCommunication &&
                                this.props.currentCommunication.type ===
                                  'RECURRING'
                              ? this.renderRecuringTime()
                              : this.renderDeliveryTime()}
                          </span>
                        </div>
                      )}
                      {!this.isJourneyType() &&
                        this.props.currentCommunication &&
                        this.props.currentCommunication.type !==
                          'TRIGGERED' && (
                          <div style={styles.dataContainer}>
                            <span style={styles.dataLabel}></span>
                          </div>
                        )}
                    </div>
                    {!this.isJourneyType() &&
                      this.props.currentCommunication &&
                      this.props.currentCommunication.type !== 'ONE_TIME' && (
                        <div style={styles.dataRow}>
                          <div style={styles.dataContainer}>
                            <span style={styles.dataLabel}>START DATE:</span>
                            <span style={styles.dataValue}>
                              {this.getStartDate(true)}
                            </span>
                          </div>
                          <div style={styles.dataContainer}>
                            <span style={styles.dataLabel}>END DATE</span>
                            <span style={styles.dataValue}>
                              {this.getEndDate(true)}
                            </span>
                          </div>
                          <div style={styles.dataContainer}>
                            <span style={styles.dataLabel}>DND</span>
                            <span style={styles.dataValue}>
                              {this.props?.currentCommunication?.channel ===
                              'IN_APP'
                                ? '-'
                                : this.props.currentCommunication &&
                                  this.props.currentCommunication.followDnd ===
                                    true
                                ? 'Follow'
                                : 'Ignore'}
                            </span>
                          </div>
                        </div>
                      )}
                    {!this.isJourneyType() &&
                      this.props.currentCommunication &&
                      this.props.currentCommunication.type !== 'ONE_TIME' && (
                        <div style={styles.dataRow}>
                          <div style={styles.dataContainer}>
                            <span style={styles.dataLabel}>FC</span>
                            <span style={styles.dataValue}>
                              {this.props?.currentCommunication?.channel ===
                              'IN_APP'
                                ? '-'
                                : this.props.currentCommunication &&
                                  this.props.currentCommunication
                                    .followFrequencyCapping === true
                                ? 'Follow'
                                : 'Ignore'}
                            </span>
                          </div>
                          <div style={styles.dataContainer}>
                            <span style={styles.dataLabel}>QUEUEING</span>
                            <span style={styles.dataValue}>
                              {this.props.currentCommunication &&
                              this.props.currentCommunication.queueMinutes
                                ? this.renderQueueMinutes()
                                : this.props?.currentCommunication?.channel ===
                                  'IN_APP'
                                ? '-'
                                : 'Do Not Queue'}
                            </span>
                          </div>
                          <div style={styles.dataContainer}></div>
                        </div>
                      )}
                    {!this.isJourneyType() &&
                      !(
                        this.props.currentCommunication &&
                        this.props.currentCommunication.type !== 'ONE_TIME'
                      ) && (
                        <div style={styles.dataRow}>
                          <div style={styles.dataContainer}>
                            <span style={styles.dataLabel}>FC</span>
                            <span style={styles.dataValue}>
                              {this.props.currentCommunication &&
                              this.props.currentCommunication
                                .followFrequencyCapping === true
                                ? 'Follow'
                                : 'Ignore'}
                            </span>
                          </div>
                          <div style={styles.dataContainer}>
                            <span style={styles.dataLabel}>DND</span>
                            <span style={styles.dataValue}>
                              {this.props.currentCommunication &&
                              this.props.currentCommunication.followDnd === true
                                ? 'Follow'
                                : 'Ignore'}
                            </span>
                          </div>
                          <div style={styles.dataContainer}>
                            <span style={styles.dataLabel}>QUEUEING</span>
                            <span style={styles.dataValue}>
                              {this.props.currentCommunication &&
                              this.props.currentCommunication.queueMinutes
                                ? this.renderQueueMinutes()
                                : 'Do Not Queue'}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                background: 'none',
                marginTop: 40,
                backgroundColor: 'white',
                marginBottom: 40,
              }}
            >
              <div style={{ border: '1px solid #F1F1F3' }}>
                <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                  <div style={styles.headTitleContainer}>
                    <span>Conversion Tracking</span>
                  </div>
                </div>
                <div style={styles.dataRowContainer}>
                  <div style={styles.dataRowFirst}>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>CONVERSION TRACKING</span>
                      <span style={styles.dataValue}>
                        {this.props.currentCommunication &&
                        this.props.currentCommunication.conversion
                          ? 'on'
                          : 'off'}
                      </span>
                    </div>
                    <div style={styles.dataContainer}>
                      {this.props.currentCommunication &&
                        this.props.currentCommunication.conversion && (
                          <span style={styles.dataLabel}>CONVERSION EVENT</span>
                        )}
                      {this.props.currentCommunication &&
                        this.props.currentCommunication.conversion &&
                        this.props.currentCommunication.conversionEvent && (
                          <span style={styles.dataValue}>
                            {this.renderEventName(
                              this.props.currentCommunication.conversionEvent
                            )}
                          </span>
                        )}
                    </div>
                    <div style={styles.dataContainer}>
                      {this.props.currentCommunication &&
                        this.props.currentCommunication.conversion && (
                          <span style={styles.dataLabel}>
                            CONVERSION DEADLINE
                          </span>
                        )}
                      {this.props.currentCommunication &&
                        this.props.currentCommunication.conversion &&
                        this.props.currentCommunication.conversionEvent && (
                          <span style={styles.dataValue}>
                            {this.renderConversionTime()}
                          </span>
                        )}
                    </div>
                  </div>
                  <div style={styles.dataRow}>
                    <div style={styles.dataContainer}>
                      {this.props.currentCommunication &&
                        this.props.currentCommunication.conversion && (
                          <span style={styles.dataLabel}>CONTROL GROUP</span>
                        )}
                      {this.props.currentCommunication &&
                        this.props.currentCommunication.conversion && (
                          <span style={styles.dataValue}>
                            {this.props.currentCommunication.controlGroup
                              ? 'Enabled ' +
                                '(' +
                                this.props.currentCommunication
                                  .controlGroupPercentage +
                                '%)'
                              : 'Disabled'}
                          </span>
                        )}
                    </div>
                    <div style={styles.dataContainer}></div>
                    <div style={styles.dataContainer}></div>
                  </div>
                </div>
              </div>
            </div>
            {this.props?.currentCommunication?.winnerMechanismEnabled ? (
              <div
                style={{
                  background: 'none',
                  marginTop: 40,
                  backgroundColor: 'white',
                  marginBottom: 40,
                }}
              >
                <div style={{ border: '1px solid #F1F1F3' }}>
                  <div
                    style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}
                  >
                    <div style={styles.headTitleContainer}>
                      <span>Winner Mechanism</span>
                    </div>
                  </div>
                  <div style={styles.dataRowContainer}>
                    <div style={styles.dataRowFirst}>
                      <div style={styles.dataContainer}>
                        <span style={styles.dataLabel}>WINNER MECHANISM</span>
                        <span style={styles.dataValue}>
                          {this.props.currentCommunication
                            .winnerMechanismEnabled
                            ? 'on'
                            : 'off'}
                        </span>
                      </div>
                      <div style={styles.dataContainer}>
                        <span style={styles.dataLabel}>
                          SIZE OF TEST AUDIENCE
                        </span>
                        <span style={styles.dataValue}>
                          {this.props.currentCommunication.type ===
                          currentConversionType.TRIGGERED ? (
                            <>
                              {
                                this.props.currentCommunication
                                  .winnerMechanismTestAudienceSize
                              }{' '}
                              message
                            </>
                          ) : this.props.currentCommunication.type ===
                            currentConversionType.ONE_TIME ? (
                            <>
                              {
                                this.props.currentCommunication
                                  .winnerMechanismTestAudienceSize
                              }{' '}
                              of audience
                            </>
                          ) : (
                            '-'
                          )}
                        </span>
                      </div>
                      <div style={styles.dataContainer}>
                        <span style={styles.dataLabel}>TIME TO TEST</span>
                        <span style={styles.dataValue}>
                          {this.props.currentCommunication.type ===
                          currentConversionType.ONE_TIME
                            ? this.renderWinnerTestTime()
                            : '-'}
                        </span>
                      </div>
                    </div>
                    <div style={styles.dataRow}>
                      <div style={styles.dataContainer}>
                        <span style={styles.dataLabel}>WIN CRITERIA</span>
                        <span style={styles.dataValue}>
                          {this.props?.currentCommunication
                            ?.winnerMechanismWinVariationCriteria ?? '-'}
                        </span>
                      </div>
                      <div style={styles.dataContainer}>
                        <span style={styles.dataLabel}>WINNER VARIATION</span>
                        <span style={styles.dataValue}>
                          {['W_UPCOMING', 'W_RUNNING'].includes(
                            this.props.currentCommunication?.status
                          )
                            ? '-'
                            : this.props.currentCommunication
                                ?.winnerMechanismWinnerVariationId
                            ? this.renderWinner()
                            : 'Control Group'}
                        </span>
                      </div>
                      <div style={styles.dataContainer}></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div
              style={{
                background: 'none',
                marginTop: 40,
                backgroundColor: 'white',
                marginBottom: 40,
              }}
            >
              <div style={{ border: '1px solid #F1F1F3' }}>
                <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                  <div style={styles.headTitleContainer}>
                    <span>Activity Metadata</span>
                  </div>
                </div>
                <div style={styles.dataRowContainer}>
                  <div style={styles.dataRowFirst}>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>Created By</span>
                      <span style={styles.dataValue}>
                        {this.props.currentCommunication &&
                        this.props.currentCommunication.createdBy
                          ? this.props.currentCommunication.createdBy
                              .presentation
                          : '-'}
                      </span>
                    </div>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>Created Date</span>
                      <span style={styles.dataValue}>
                        {this.props.currentCommunication &&
                        this.props.currentCommunication.createdDate
                          ? readableDateTime(
                              this.props.currentCommunication.createdDate
                            )
                          : '-'}
                      </span>
                    </div>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>Launch Date</span>
                      <span style={styles.dataValue}>
                        {this.props.currentCommunication &&
                        this.props.currentCommunication.launchDate
                          ? readableDateTime(
                              this.props.currentCommunication.launchDate
                            )
                          : '-'}
                      </span>
                    </div>
                  </div>
                  <div style={styles.dataRowFirst}>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>Modified By</span>
                      <span style={styles.dataValue}>
                        {this.props.currentCommunication &&
                        this.props.currentCommunication.modifiedBy
                          ? this.props.currentCommunication.modifiedBy
                              .presentation
                          : '-'}
                      </span>
                    </div>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>Modified Date</span>
                      <span style={styles.dataValue}>
                        {this.props.currentCommunication &&
                        this.props.currentCommunication.modifiedDate
                          ? readableDateTime(
                              this.props.currentCommunication.modifiedDate
                            )
                          : '-'}
                      </span>
                    </div>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>Stop Date</span>
                      <span style={styles.dataValue}>
                        {this.props.currentCommunication &&
                        this.props.currentCommunication.stopDate
                          ? readableDateTime(
                              this.props.currentCommunication.stopDate
                            )
                          : '-'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  renderSendTo = () => {
    let str = '';
    let result = '';
    let notResult = '';
    if (
      this.props.segments &&
      this.props.currentCommunication &&
      this.props.currentCommunication.segments &&
      this.props.currentCommunication.segments.length > 0
    ) {
      this.props.currentCommunication.segments.forEach((item, index) => {
        const foundItem = lodashFind(this.props.segments, (o) => o.id === item);
        if (
          foundItem &&
          foundItem.name &&
          index < this.props.currentCommunication.segments.length - 1
        ) {
          result += foundItem.name + ', ';
        } else if (foundItem && foundItem.name) {
          result += foundItem.name;
        }
      });
    }
    if (
      this.props.segments &&
      this.props.currentCommunication &&
      this.props.currentCommunication.ignoreSegments &&
      this.props.currentCommunication.ignoreSegments.length > 0
    ) {
      this.props.currentCommunication.ignoreSegments.forEach((item, index) => {
        const foundItem = lodashFind(this.props.segments, (o) => o.id === item);
        if (
          foundItem &&
          foundItem.name &&
          index < this.props.currentCommunication.ignoreSegments.length - 1
        ) {
          notResult += foundItem.name + ', ';
        } else if (foundItem && foundItem.name) {
          notResult += foundItem.name;
        }
      });
    }
    if (result) {
      str +=
        'Users in' +
        (this.props.currentCommunication.segments.length > 1
          ? ' (' + this.props.currentCommunication.segmentsConjunction + ')'
          : '') +
        ': ' +
        result;
    }
    if (notResult) {
      str +=
        (isEmpty(str) ? '' : ', and ') +
        'Users Not in' +
        (this.props.currentCommunication.ignoreSegments.length > 1
          ? ' (' +
            this.props.currentCommunication.ignoreSegmentsConjunction +
            ')'
          : '') +
        ': ' +
        notResult;
    }
    if (!result && !notResult) {
      str = 'All Users (No Specific Segment)';
    }
    return str;
  };

  render() {
    return <div style={{ padding: 30 }}>{this.renderPreview()}</div>;
  }
}

export default CommunicationDetailsComponent;
