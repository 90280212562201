import BaseApi from '../../../api/BaseApi';
const basePath = '/admin/billing/invoice';
export default class BackOfficeApi {
  static listAll(body) {
    return BaseApi.post(basePath + '/listAll', body);
  }
  static list(id, body) {
    return BaseApi.post(basePath + '/list/' + id, body);
  }
  static getContractProduct(id) {
    return BaseApi.post(basePath + '/getContractProduct/' + id);
  }
}
