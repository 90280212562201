import React from 'react';
import '../../style/ProductSdkAndroidStyle.less';
import SetUpItem from './SetupItem';
import texts from '../../../../../utils/texts';
const SetupFragment = (props) => {
  const findInitializeUrl = () => {
    if (props.currentSdkType === 'react-native') {
      return `${texts.mobileSdk}`;
    } else if (props.currentSdkType === 'web') {
      return `${texts.webSdk}`;
    } else if (props.currentSdkType === 'android') {
      return `${texts.androidSdk}`;
    } else if (props.currentSdkType === 'ios') {
      return `${texts.iosSdk}`;
    } else {
      return `${texts.docs}`;
    }
  };
  const findPushUrl = () => {
    if (
      props.currentSdkType === 'react-native' ||
      props.currentSdkType === 'android'
    ) {
      return `${texts.mobileSdkNoti}`;
    } else if (props.currentSdkType === 'web') {
      return `${texts.webSdkNoti}`;
    } else if (props.currentSdkType === 'ios') {
      return `${texts.iosSdkNoti}`;
    } else {
      return `${texts.docs}`;
    }
  };
  return (
    <div>
      <div className="setup">
        <SetUpItem
          number={1}
          title={' SDK Setup: '}
          description={
            'You can continue this wizard or follow the steps listed '
          }
          url={findInitializeUrl()}
        ></SetUpItem>
      </div>
      <div className="setup-item-space">
        <SetUpItem
          title={' Push Setup (Optional): '}
          number={2}
          url={findPushUrl()}
          description={`If you would like to send push notifications through ${texts.brandName}, please follow the steps listed `}
        ></SetUpItem>
      </div>
    </div>
  );
};

export default SetupFragment;
