import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { viewDateTime } from '../../../../utils/DateUtils';
import {
  AuthorityProvider,
  hasAnyAuthority,
} from '../../../../utils/AuthorityProvider';
import { viewBoolean } from '../../../../utils/BooleanUtils';
import Card from '@Utils/Card';

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    display: 'flex',
    color: '#9a9ca3',
    fontSize: 14,
    width: 200,
    marginBottom: 10,
  },
  description: {
    borderRadius: 10,
    backgroundColor: '#efefef',
    padding: '10px 40px',
    color: '#9a9ca3',
    marginBottom: 10,
    marginRight: 30,
    width: 300,
  },
};
class AdminProductViewComponent extends React.Component {
  componentDidMount() {
    const productId = this.props.pathname.split('view/').pop();
    this.props.adminProductViewFetch(productId);
  }

  componentWillUnmount() {
    this.props.adminProductChangeCurrent({});
  }

  getNavigation() {
    return [
      { name: 'Home', path: '/' },
      { name: 'Admin Product List', path: '../list' },
      { name: 'View Admin Product', path: '' },
    ];
  }
  renderEventLimit = () => {
    let eventLimitArray = [];
    if (
      this.props.currentAdminProduct &&
      this.props.currentAdminProduct.eventRateLimits &&
      Array.isArray(this.props.currentAdminProduct.eventRateLimits)
    ) {
      this.props.currentAdminProduct.eventRateLimits.forEach((item) => {
        eventLimitArray.push(
          <span style={styles.description}>
            {item.threshold}
            <span style={{ marginLeft: 5, marginRight: 5 }}>
              {parseInt(item.threshold) > 1 ? 'events per' : 'event per'}
            </span>
            {item.interval.toLowerCase()}
          </span>
        );
      });
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {eventLimitArray.length === 0 ? (
          <span style={styles.description}>No data</span>
        ) : (
          eventLimitArray
        )}
      </div>
    );
  };
  renderDedicatedResource = () => {
    const { dedicatedTopics } = this.props.currentAdminProduct || [];

    if (!dedicatedTopics || dedicatedTopics.length === 0) {
      return <div style={styles.description}>No data</div>;
    }

    return (
      <div
        style={{
          ...styles.description,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {dedicatedTopics.map((dedicatedTopic, index) => (
          <div
            key={index}
            style={{
              width: 'auto',
              justifyContent: 'start',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 8,
            }}
          >
            <div style={{ fontSize: 13, marginRight: 8 }}>
              <div style={{ fontWeight: 'bold' }}>
                {dedicatedTopic.topicName}:
              </div>
              <div>
                {'Partition Count: ' +
                  dedicatedTopic.partitionCount +
                  ', Replica Count: ' +
                  dedicatedTopic.replicaCount}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  renderAudienceGrowth = () => {
    let AudienceArray = [];
    if (
      this.props.currentAdminProduct &&
      this.props.currentAdminProduct.audienceGrowths &&
      Array.isArray(this.props.currentAdminProduct.audienceGrowths)
    ) {
      if (this.props.currentAdminProduct.audienceGrowths.length === 0) {
        AudienceArray = 'No data';
      } else {
        AudienceArray = this.props.currentAdminProduct.audienceGrowths.map(
          (item) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div
                style={{
                  width: 'auto',
                  height: 40,
                  justifyContent: 'start',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div style={{ width: 80 }}>
                  {' '}
                  {item?.hasOwnProperty('channelType')
                    ? item.channelType.toLowerCase()
                    : 'All Channels'}
                </div>
                {' : ' + item.growthValue}
              </div>
            );
          }
        );
      }
    }
    return (
      <div
        style={{
          ...styles.description,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {AudienceArray}
      </div>
    );
  };
  render() {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 40 }} spin />;
    const productId = this.props.pathname.split('view/').pop();
    return (
      <div>
        <Card
          styles={{
            padding: 30,
          }}
          loading={this.props.loadingProducts}
        >
          {this.props.loadingProducts ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 40,
                marginBottom: 40,
              }}
            >
              <Spin indicator={loadingSpin} />{' '}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <div style={styles.title}>CREATED DATE</div>
                <div style={styles.description}>
                  {viewDateTime(this.props.currentAdminProduct.createdDate)
                    ? viewDateTime(this.props.currentAdminProduct.createdDate)
                    : 'No data'}
                </div>
                <div style={styles.title}>CREATED BY</div>
                <div style={{ ...styles.description, width: 300 }}>
                  {this.props.currentAdminProduct.createdBy
                    ? this.props.currentAdminProduct.createdBy.presentation
                    : 'No data'}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={styles.title}>MODIFIED DATE</div>
                <div style={styles.description}>
                  {viewDateTime(this.props.currentAdminProduct.modifiedDate)
                    ? viewDateTime(this.props.currentAdminProduct.modifiedDate)
                    : 'No data'}
                </div>
                <div style={styles.title}>MODIFIED BY</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.modifiedBy
                    ? this.props.currentAdminProduct.modifiedBy.presentation
                    : 'No data'}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={styles.title}>NAME</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.name
                    ? this.props.currentAdminProduct.name
                    : 'No data'}
                </div>
                <div style={styles.title}>DESCRIPTION</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.description
                    ? this.props.currentAdminProduct.description
                    : 'No data'}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={styles.title}>ENABLED</div>
                <div style={styles.description}>
                  {viewBoolean(this.props.currentAdminProduct.enabled)
                    ? viewBoolean(this.props.currentAdminProduct.enabled)
                    : 'No data'}
                </div>
                <div style={styles.title}>SHARDS</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.shards
                    ? this.props.currentAdminProduct.shards
                    : 'No data'}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={styles.title}>THIRD PARTY OPTION</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.thirdPartyOption
                    ? this.props.currentAdminProduct.thirdPartyOption
                    : 'No data'}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={styles.title}>LICENCE CODE</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.licenceCode
                    ? this.props.currentAdminProduct.licenceCode
                    : 'No data'}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={styles.title}>LEGACY PUSH ENABLED</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.legacyPushEnabled
                    ? viewBoolean(
                        this.props.currentAdminProduct.legacyPushEnabled
                      )
                    : 'No data'}
                </div>

                <div style={styles.title}>DEDICATED RESOURCE</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.dedicatedResource
                    ? viewBoolean(
                        this.props.currentAdminProduct.dedicatedResource
                      )
                    : 'No data'}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={styles.title}>DEMO</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.demo
                    ? viewBoolean(this.props.currentAdminProduct.demo)
                    : 'No data'}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={styles.title}>REPLICAS</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.replicas
                    ? this.props.currentAdminProduct.replicas
                    : 'No data'}
                </div>
                <div style={styles.title}>ENABLE THROTTLING</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.throttlingEnabled
                    ? viewBoolean(
                        this.props.currentAdminProduct.throttlingEnabled
                      )
                    : 'No'}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'start',
                  justifyContent: 'center',
                }}
              >
                <div style={{ ...styles.title, marginTop: 10 }}>
                  EVENT LIMITATION
                </div>
                {this.renderEventLimit()}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'start',
                  justifyContent: 'start',
                }}
              >
                <div style={{ ...styles.title, marginTop: 10 }}>
                  Average Audience Growth (%)
                </div>
                {this.renderAudienceGrowth()}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'start',
                  justifyContent: 'start',
                }}
              >
                <div style={{ ...styles.title, marginTop: 10 }}>
                  dedicated Resource
                </div>
                {this.renderDedicatedResource()}
              </div>
              {this.props.currentAdminProduct?.webPushManagedByCustomer && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div style={styles.title}>WEB PUSH TYPE</div>
                  <div style={styles.description}>
                    {this.props.currentAdminProduct.webPushKeyType
                      ? this.props.currentAdminProduct.webPushKeyType
                      : 'No data'}
                  </div>
                </div>
              )}
              <hr />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={styles.title}>Maximum Events for known users</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.knownMaximumEventCount
                    ? this.props.currentAdminProduct.knownMaximumEventCount
                    : 'No data'}
                </div>
                <div style={styles.title}>Maximum Events for unknown users</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.unknownMaximumEventCount
                    ? this.props.currentAdminProduct.unknownMaximumEventCount
                    : 'No data'}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={styles.title}>
                  Event Retention Days for known users
                </div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.knownEventRetentionDays
                    ? this.props.currentAdminProduct.knownEventRetentionDays
                    : 'No data'}
                </div>
                <div style={styles.title}>
                  Event Retention Days for unknown users
                </div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.unknownEventRetentionDays
                    ? this.props.currentAdminProduct.unknownEventRetentionDays
                    : 'No data'}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={styles.title}>Maximum Device</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.maximumDeviceCount
                    ? this.props.currentAdminProduct.maximumDeviceCount
                    : 'No data'}
                </div>
              </div>

              <hr />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={styles.title}>Limited Segment Prefix</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.limitedSegmentPrefix
                    ? this.props.currentAdminProduct.limitedSegmentPrefix
                    : 'No data'}
                </div>
                <div style={styles.title}>Watched Event Api Partitions</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.watchedEventApiPartitions
                    ? this.props.currentAdminProduct.watchedEventApiPartitions
                    : 'No data'}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div style={styles.title}>Limited User Ids</div>
                <div style={styles.description}>
                  {this.props.currentAdminProduct.limitedUserIds
                    ? this.props.currentAdminProduct.limitedUserIds
                    : 'No data'}
                </div>
              </div>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 40,
            }}
          >
            <Link to="../list">
              <Button
                type="primary"
                shape="round"
                size={'large'}
                loading={this.props.loading}
                className="w-200"
              >
                Back
              </Button>
            </Link>
            <div>
              {hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_PRODUCT_CREATE) && (
                <Button
                  type="primary"
                  shape="round"
                  size={'large'}
                  loading={this.props.loading}
                  style={{ marginRight: 15 }}
                  className="w-200"
                  onClick={() => this.props.adminProductIndexFetch(productId)}
                >
                  Index
                </Button>
              )}
              {hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_PRODUCT_UPDATE) && (
                <Link to={`../update/${productId}/product`}>
                  <Button
                    type="primary"
                    shape="round"
                    size={'large'}
                    loading={this.props.loading}
                    className="w-200"
                  >
                    Update
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default AdminProductViewComponent;
