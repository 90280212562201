import { Button, Result } from 'antd';
import React, { useEffect } from 'react';
import {
  // useDispatch,
  useSelector,
} from 'react-redux';
import { Link } from 'react-router-dom';

import './Download.module.less';
// import { callExportCsvDownload } from '@Component/utils/CsvExport/CsvExportActions';
// import { useParams } from 'react-router';
export default function Download() {
  // const dispatch = useDispatch();
  // const success = useSelector((state) => state.csv.success);
  // const loginUpdate = useSelector((state) => state.csv.loadingUpdate);
  const currentUser = useSelector((state) => state?.account?.auth?.currentUser);
  // const { id } = useParams();

  useEffect(() => {
    if (currentUser?.login) {
      //workaround (because of routers problem)
      // dispatch(callExportCsvDownload(id));
    }
  }, [currentUser]);
  return (
    <div id={'fullScreen'}>
      <Result title="this page is no longer valid" />
      <p style={{ textAlign: 'center' }}>
        you can download exported files in your profile page
      </p>
      <div>
        <Button type="primary" ghost shape="round">
          <Link exact to="/account/profile/csv-export-log" title="Dashboard">
            Back to Dashboard
          </Link>
        </Button>
      </div>
      {/* <div>
        {!success && (
          <Button
            type="danger"
            ghost
            shape="round"
            onClick={() => dispatch(callExportCsvDownload(id))}
          >
            RETRY
          </Button>
        )}
      </div> */}
    </div>
  );
}
