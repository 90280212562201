import * as React from 'react';

function SvgStar(props) {
  return (
    <svg viewBox="0 0 26 24" width="1em" height="1em" {...props}>
      <path
        d="M13 19.5l-7.641 4.017 1.46-8.508L.635 8.983 9.18 7.74 13 0l3.82 7.741 8.544 1.242-6.182 6.026 1.46 8.508z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgStar;
