import ProductSettingDndComponent from '../component/ProductSettingDndComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  dndUpdateFetch,
  dndUpdateSubmitFetch,
  dndChangeCurrent,
} from '../ProductSettingActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  loadingDndUpdate: state.setting.productSetting.loadingDndUpdate,
  loadingDndUpdateSubmit: state.setting.productSetting.loadingDndUpdateSubmit,
  dnd: state.setting.productSetting.dnd,
  errorsDnd: state.setting.productSetting.errorsDnd,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dndUpdateSubmitFetch,
      dndUpdateFetch,
      dndChangeCurrent,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSettingDndComponent);

ProductSettingDndComponent.propTypes = {
  currentProduct: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  productChangeCurrent: PropTypes.func.isRequired,
  productUpdateFetch: PropTypes.func.isRequired,
  productUpdateSubmitFetch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
