/* eslint-disable no-undef */
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import rootReducer from './reducer';
import { appInternalError } from './appState';
//import createHistory from 'history/createBrowserHistory';
const createHistory = require('history').createBrowserHistory;

export const history = createHistory({
  basename: '',
  hashType: 'slash',
});

/**
 * Logs all actions and states after they are dispatched.
 */
// const logger = () => (next) => (action) => {
//   // console.groupCollapsed(action.type);
//   // console.log('Previous state', store.getState());
//   // console.info('Dispatch', action);
//   let result = next(action);
//   // console.log('Next state', store.getState());
//   // console.groupEnd(action.type);
//   return result;
// };

const apiErrors = (store) => (next) => (action) => {
  if (action?.payload instanceof Error) {
    if ((action.payload?.message || '').includes('403:')) {
      store.dispatch(appInternalError(403));
    }
    if ((action.payload?.message || '').includes('404:')) {
      store.dispatch(appInternalError(404));
    }
  }

  try {
    return next(action);
  } catch (err) {
    store.dispatch(appInternalError(500));
  }
};

const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history), apiErrors];

if (process.env.NODE_ENV === 'development') {
  // middleware.push(logger);
  const devToolsExtension =
    window.devToolsExtension || window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(
  rootReducer(history),
  initialState,
  composedEnhancers
);

export default store;
