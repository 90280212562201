import BaseApi from '../../../api/BaseApi';
const basePath = '/data/revenueMapping';

class RevenueMappingApi {
  static listCurrencies() {
    return BaseApi.post(basePath + '/listCurrencies', {});
  }
  static update() {
    return BaseApi.post(basePath + '/update', {});
  }
  static updateSubmit(body) {
    return BaseApi.post(basePath + '/updateSubmit', body);
  }
}

export default RevenueMappingApi;
