export const currentConversionType = {
  ONE_TIME: 'ONE_TIME',
  TRIGGERED: 'TRIGGERED',
  RECURRING: 'RECURRING',
};

export const timeDuration = {
  MINUTE: 'Minutes',
  HOUR: 'Hours',
  DAY: 'Days',
  WEEK: 'Weeks',
  YEAR: 'Years',
};

export const winCriteria = {
  CONVERSION: 'Conversion',
  CLICKS: 'Clicks',
};

export const urlRegex =
  '(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[\\[\\]-\\w@\\+\\.~#\\?&/=%]*)?$';
