import React from 'react';
import { Form } from 'antd';

import InputSelector from '@Component/form/InputSelector';
import { InlineThemePicker } from '../inlineThemePicker';
import { InputColorPicker } from '../inlineThemePicker/inputColorpicker';

const styles = {
  editContainer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  editFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 6,
    fontSize: 13,
    lineHeight: 1.43,
    color: '#3d404e',
  },

  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'left',
    marginBottom: '24px',
    width: '37px',
  },

  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    display: 'flex',
    flexDirection: 'row',
  },
  infoBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 30px',
    borderBottom: '1px solid #e7e8e9',
    backgroundColor: '#fdfdfd',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dataRowContainer: {
    padding: 30,
  },
  dataRowFirst: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dataRow: {
    paddingTop: 10,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

const BtnWrapper = ({
  title,
  label,
  handleLabelChange,
  action,
  variation,
  handleActionChange,
  handleThemeChange,
  disabled,
  readOnly,
  customFonts,
  actionIndex,
  ...props
}) => {
  const isNotValid = (label && !action) || (action && !label);
  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      <div
        style={{
          boxShadow: 'inset 0 0 6px 0 #e7e8e9',
          borderRadius: 5,
          border: `1px solid ${props.isValid ? '#e2e2e2' : 'red'}`,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <div
          style={{
            fontWeight: 600,
            opacity: '0.75',
            marginLeft: 20,
            marginRight: 20,
            borderBottom: '1px solid #e7e8e9',
            paddingTop: 15,
            paddingBottom: 15,
          }}
        >
          {title}
        </div>
        <div style={{ margin: 20 }}>
          <div style={styles.dataRowFirst}>
            <div style={styles.editContainer}>
              <div
                style={{
                  ...styles.editLabel,
                  position: 'relative',
                  top: -10,
                }}
              >
                LABEL
              </div>
              <div
                style={{
                  ...styles.editFieldContainer,
                  marginLeft: 20,
                  flex: 3,
                }}
              >
                <InlineThemePicker
                  name="actions"
                  actionIndex={actionIndex}
                  variation={variation}
                  customFonts={customFonts}
                  onChange={handleThemeChange}
                />

                <Form.Item
                  validateStatus={isNotValid && 'error'}
                  help={isNotValid && 'you should input both label and action'}
                >
                  <InputSelector
                    disabled={disabled}
                    readOnly={readOnly}
                    // type="richInline"
                    index={props.index}
                    attributeStyle={{
                      position: 'relative',
                      top: '-10px',
                      right: '-380px',
                    }}
                    attributes={props.attributes}
                    events={props.events}
                    businessEvents={props.businessEvents}
                    journeySteps={props.journeySteps}
                    placeholder="OK"
                    value={label}
                    onChange={(value) => {
                      handleLabelChange(value);
                    }}
                    size="large"
                  />
                </Form.Item>
                <span
                  style={{
                    color: '#94969e',
                    fontSize: '10px',
                    paddingLeft: '5px',
                    paddingTop: '5px',
                    display: 'block',
                  }}
                ></span>
              </div>
            </div>
          </div>
          <div style={styles.dataRow}>
            <div style={styles.editContainer}>
              <div
                style={{
                  ...styles.editLabel,
                  position: 'relative',
                  top: -10,
                }}
              >
                ON-CLICK ACTION
              </div>
              <div
                style={{
                  ...styles.editFieldContainer,
                  marginLeft: 20,
                  flex: 3,
                }}
              >
                <Form.Item
                  validateStatus={isNotValid && 'error'}
                  help={isNotValid && 'you should input both label and action'}
                >
                  <InputSelector
                    disabled={disabled}
                    readOnly={readOnly}
                    // type="richInline"
                    index={props.index}
                    attributeStyle={{
                      position: 'relative',
                      top: '-10px',
                      right: '-380px',
                    }}
                    attributes={props.attributes}
                    events={props.events}
                    businessEvents={props.businessEvents}
                    journeySteps={props.journeySteps}
                    hideEmojiPicker
                    placeholder="Deeplink or URL"
                    value={action}
                    onChange={(value) => {
                      handleActionChange(value);
                    }}
                    size="large"
                  />
                </Form.Item>
                <span
                  style={{
                    color: '#94969e',
                    fontSize: '10px',
                    paddingLeft: '5px',
                    paddingTop: '5px',
                    display: 'block',
                  }}
                >
                  {
                    'Deeplink can be any URI (eg. myapp://productdetails/itemabc). URL can be HTTP or HTTPS (eg. https://www.abc.com)'
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!props.isValid && (
        <div style={{ color: 'red' }}>Please specify an action!</div>
      )}
    </div>
  );
};

export default function InAppActions({
  handleChange,
  variation,
  disabled,
  readOnly = false,
  index,
  customFonts,
  ...props
}) {
  return (
    <div
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        border: '1px solid #F1F1F3',
        marginTop: 40,
        backgroundColor: 'white',
      }}
    >
      <div style={styles.infoBoxHeader}>
        <div style={styles.headTitleContainer}>
          <span>Actions</span>
        </div>
      </div>
      <div style={styles.dataRowContainer}>
        {['BANNER', 'BANNER_RTL', 'IMAGE_ONLY'].includes(
          variation?.inAppLayoutType
        ) && (
          <div style={{ display: 'flex', gap: 18, flexDirection: 'column' }}>
            <div style={styles.dataRowFirst}>
              <div style={styles.editContainer}>
                <div
                  style={{
                    ...styles.editLabel,
                  }}
                >
                  ON-CLICK ACTION
                </div>
                <div
                  style={{
                    ...styles.editFieldContainer,
                    marginLeft: 20,
                    flex: 3,
                  }}
                >
                  <InputSelector
                    dataCy="onclick-action-url"
                    disabled={disabled}
                    // type="richInline"
                    index={index}
                    attributeStyle={{
                      position: 'relative',
                      top: '-10px',
                      right: '-380px',
                    }}
                    id="deep-link-url"
                    journeySteps={props.journeySteps}
                    attributes={props.attributes}
                    events={props.events}
                    businessEvents={props.businessEvents}
                    hideEmojiPicker
                    placeholder="Deeplink or URL"
                    value={variation?.androidButton1OnClickAction || ''}
                    onChange={(value) =>
                      // handleChange('androidOnClickAction', value)
                      handleChange('androidButton1OnClickAction', value)
                    }
                    size="large"
                  />
                  <span
                    style={{
                      color: '#94969e',
                      fontSize: '10px',
                      paddingLeft: '5px',
                      paddingTop: '5px',
                      display: 'block',
                    }}
                  >
                    {
                      'Deeplink can be any URI (eg. myapp://productdetails/item/abc). URL can be HTTP or HTTPS (eg. https://www.abc.com)'
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {['CARD', 'MODAL'].includes(variation?.inAppLayoutType) && (
          <>
            <BtnWrapper
              title={`Primary Button ${
                variation?.inAppLayoutType === 'CARD' ? '*' : ''
              }`}
              actionIndex={0}
              customFonts={customFonts}
              label={variation?.androidButton1Label}
              variation={variation}
              handleLabelChange={(value) =>
                handleChange('androidButton1Label', value)
              }
              action={variation?.androidButton1OnClickAction}
              handleActionChange={(value) =>
                handleChange('androidButton1OnClickAction', value)
              }
              handleThemeChange={(value) => handleChange('theme', value)}
              disabled={disabled}
              readOnly={readOnly}
              isValid={
                variation?.inAppLayoutType === 'CARD'
                  ? variation?.androidButton1Label &&
                    variation?.androidButton1OnClickAction
                  : true
              }
              index={index}
              {...props}
            />
            <div
              style={{
                display: 'flex',
                gap: 24,
                marginTop: 18,
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  ...styles.editLabel,
                  maxWidth: 'fit-content',
                  marginBottom: 0,
                }}
              >
                ACTION BACKGROUND
              </span>
              <InputColorPicker
                name="actions"
                actionIndex={0}
                variation={variation}
                onChange={(theme) => handleChange('theme', theme)}
              />
            </div>
          </>
        )}

        {['CARD'].includes(variation?.inAppLayoutType) && (
          <>
            <BtnWrapper
              title="Secondary Button"
              label={variation?.androidButton2Label}
              actionIndex={1}
              customFonts={customFonts}
              variation={variation}
              handleLabelChange={(value) =>
                handleChange('androidButton2Label', value)
              }
              action={variation?.androidButton2OnClickAction}
              handleActionChange={(value) =>
                handleChange('androidButton2OnClickAction', value)
              }
              handleThemeChange={(value) => {
                handleChange('theme', value);
              }}
              disabled={disabled}
              readOnly={readOnly}
              isValid={true}
              index={index}
              {...props}
            />
            <div
              style={{
                display: 'flex',
                gap: 24,
                marginTop: 18,
              }}
            >
              <span
                style={{
                  ...styles.editLabel,
                  maxWidth: 'fit-content',
                  marginBottom: 0,
                }}
              >
                ACTION BACKGROUND
              </span>
              <InputColorPicker
                name="actions"
                actionIndex={1}
                variation={variation}
                onChange={(theme) => handleChange('theme', theme)}
              />
            </div>
          </>
        )}

        {['COUPON'].includes(variation?.inAppLayoutType) && (
          <div style={{ display: 'flex', gap: 18, flexDirection: 'column' }}>
            <div style={styles.dataRowFirst}>
              <div style={styles.editContainer}>
                <div
                  style={{
                    ...styles.editLabel,
                  }}
                >
                  Coupon Code
                </div>
                <div
                  style={{
                    ...styles.editFieldContainer,
                    marginLeft: 20,
                    flex: 3,
                  }}
                >
                  <InlineThemePicker
                    name="coupon"
                    variation={variation}
                    onChange={(value) => handleChange('theme', value)}
                    customFonts={customFonts}
                  />
                  <Form.Item
                    validateStatus={!variation?.couponCode && 'error'}
                    help={!variation?.couponCode && 'required'}
                  >
                    <InputSelector
                      disabled={disabled}
                      // type="richInline"
                      index={index}
                      attributeStyle={{
                        position: 'relative',
                        top: '-10px',
                        right: '-380px',
                      }}
                      journeySteps={props.journeySteps}
                      attributes={props.attributes}
                      events={props.events}
                      businessEvents={props.businessEvents}
                      hideEmojiPicker
                      value={variation?.couponCode || ''}
                      onChange={(value) => handleChange('couponCode', value)}
                      size="large"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div style={styles.dataRowFirst}>
              <div
                style={{
                  ...styles.editContainer,
                }}
              >
                <span style={{ ...styles.editLabel }}>action background</span>
                <InputColorPicker
                  name="cta_background_coupon"
                  variation={variation}
                  onChange={(theme) => handleChange('theme', theme)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
