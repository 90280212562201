import { combineReducers } from 'redux';
import businessEvent from './businessEventSlice';
import dataApp from './dataSlice';
import customAttribute from './customAttributeSlice';
import customEvent from './customEventSlice';
import dataCsv from './dataPlatformSlice';
import revenueMapping from './revenueMappingSlice';
import systemAttribute from './systemAttributeslice';
import alerts from './alertSlice';

const reducer = combineReducers({
  dataApp,
  businessEvent,
  customAttribute,
  customEvent,
  dataCsv,
  revenueMapping,
  systemAttribute,
  alerts,
});

export default reducer;
