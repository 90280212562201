import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router';
import UsersOverviewContainer from './container/UsersOverviewContainer';
import UsersSearchContainer from './container/UsersSearchContainer';
import UsersAnalyticsContainer from './container/UsersAnalyticsContainer';
import UserAttrContainer from './container/UserAttrContainer';
import UserBasicInfoContainer from './container/UserBasicInfoContainer';
import UserDevicesContainer from './container/UserDevicesContainer';
import UserChannelsContainer from './container/UserChannelsContainer';
import UserInteractionsContainer from './container/UserInteractionsContainer';

function User(props) {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}overview`}>
        <UsersOverviewContainer />
      </Route>
      <Route path={`${match.path}search`}>
        <UsersSearchContainer />
      </Route>
      <Route path={`${match.path}user-analytics`}>
        <UsersAnalyticsContainer />
      </Route>
      <Route path={`${match.path}view/:id/attributes`}>
        <UserAttrContainer />
      </Route>
      <Route path={`${match.path}view/:id/basic`}>
        <UserBasicInfoContainer />
      </Route>
      <Route path={`${match.path}view/:id/devices`}>
        <UserDevicesContainer />
      </Route>
      <Route path={`${match.path}view/:id/channels`}>
        <UserChannelsContainer />
      </Route>
      <Route path={`${match.path}view/:id/interactions`}>
        <UserInteractionsContainer {...props} />
      </Route>
    </Switch>
  );
}

export default User;
