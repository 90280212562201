import React, { useCallback, useEffect } from 'react';
import { Tree } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFeatures,
  fetchAdminFeatures,
} from '@Redux/slices/setting/billingSlice';

const Features = ({
  setFeatures,
  currentFeatures,
  isAdmin,
  disabledTree = null,
}) => {
  const dispatch = useDispatch();

  const featuresList = useSelector((state) => state.setting.billing.features);
  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchAdminFeatures());
    } else {
      dispatch(fetchFeatures());
    }
  }, []);

  const renameKeys = useCallback(
    (obj, newKeys) => {
      const cObj = { ...obj };
      if (featuresList.length > 0) {
        const keyValues = Object.keys(cObj).map((key) => {
          let newKey = null;
          if (key === 'type') {
            newKey = newKeys.type;
          } else {
            newKey = key;
          }
          if (key === 'children') {
            cObj[key] = cObj[key].map((obj) => renameKeys(obj, newKeys));
          }
          return {
            [newKey]: cObj[key],
          };
        });
        return Object.assign({}, ...keyValues);
      }
    },
    [featuresList]
  );

  const renamedObj = [
    renameKeys(featuresList[0], {
      type: 'key',
    }),
  ];

  const onCheck = (_, info) => {
    const check = info.checkedNodes
      .map((obj) => obj.key)
      .filter((notUndefined) => notUndefined !== undefined);
    setFeatures(check);
    console.log('onCheck', check, info);
  };

  return (
    <div>
      {featuresList.length > 0 && renamedObj.length > 0 && (
        <>
          <Tree
            disabled={disabledTree}
            checkable
            defaultExpandAll
            onCheck={onCheck}
            treeData={renamedObj}
            defaultCheckedKeys={currentFeatures}
          />
        </>
      )}
    </div>
  );
};
export default Features;
