import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import styles from './CsvButton.module.less';
import {
  AuthorityProvider,
  hasAnyAuthority,
  // getProductId,
} from '../../../utils/AuthorityProvider';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { clearErrors } from './CsvExportActions.js';

export default function CsvButton(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [csvModalVisible, setCsvModalVisible] = useState(false);
  const errors = useSelector((state) => state.csv.errors);
  const success = useSelector((state) => state.csv.success);
  const loadingUpdate = useSelector((state) => state.csv.loadingUpdate);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (!clicked) return;
    if (!loadingUpdate && isEmpty(errors) && success) {
      setCsvModalVisible(true);
    }
    return () => {
      dispatch(clearErrors());
    };
  }, [loadingUpdate]);

  const Auth =
    props.type === 'communication'
      ? AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_EXPORT
      : AuthorityProvider.ROLE_PRODUCT_SEGMENT_EXPORT;

  if (hasAnyAuthority(Auth)) {
    return (
      <Fragment>
        <div
          onClick={() => {
            setClicked(true);
            props.callApi();
          }}
        >
          {props.display === 'menu' ? (
            <span>{props.label}</span>
          ) : (
            <span className={`${styles.headerCsvBtn}`}>
              <DownloadOutlined />
              <span>{props.label}</span>
            </span>
          )}
        </div>

        {csvModalVisible && (
          <Modal
            width={1080}
            title={<span style={{ fontWeight: 'bold' }}>Download Report</span>}
            visible={csvModalVisible}
            onOk={() => {
              setCsvModalVisible(false);
              dispatch(clearErrors());
            }}
            onCancel={() => {
              setCsvModalVisible(false);
              dispatch(clearErrors());
            }}
            footer={[
              <div key="ok-button" style={{ height: 40 }}>
                <Button
                  type="primary"
                  shape="default"
                  ghost
                  onClick={() => {
                    setCsvModalVisible(false);
                    // const pid = getProductId(history.location?.pathname);
                    // history.push(`/product/${pid}/users/overview`);

                    history.push('/account/profile/csv-export-log');
                  }}
                >
                  Go to CSV Export Log
                </Button>
              </div>,
            ]}
          >
            Your file is being prepared for download. Please find the link for
            download in your profile page -&gt; CSV Export Log.
          </Modal>
        )}
      </Fragment>
    );
  } else {
    return null;
  }
}
