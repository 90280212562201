import React from 'react';
import { Link } from 'react-router-dom';
import {
  DownOutlined,
  InfoCircleFilled,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import {
  Select,
  Spin,
  Badge,
  Tooltip,
  Progress,
  Dropdown,
  Radio,
  Empty,
} from 'antd';
import { isNil, find as lodashFind, isEmpty, filter } from 'lodash';

import {
  numberWithCommas,
  renderTag,
  renderCampaignStatus,
  percentage,
} from '../../../../utils/RenderUtils';
import { getChannel, getJourneyId } from '../../../../utils/AuthorityProvider';
import { readableDate } from '../../../../utils/DateUtils';
import moment from 'moment';
import { Line } from '@ant-design/charts';
import TagSelector from '../../../../component/form/TagSelector';
import CommunicationChannels from '../../overview/model/CommunicationChannels';
import chartLineSelected from '../../../../theme/icons/chart-line-selected.svg';
import chartLine from '../../../../theme/icons/chart-line.svg';
import table from '../../../../theme/icons/table.svg';
import tableSelected from '../../../../theme/icons/table-selected.svg';
import Card from '@Utils/Card';
const styles = {
  summaryText: {
    color: 'rgba(61,64,78,.55)',
    fontWeight: '600',
    fontSize: 12,
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  summaryCard: {
    backgroundColor: 'white',
    padding: '55px 20px 20px',
    marginBottom: '30px',
    border: '1px solid #F1F1F3',
    height: 'calc(100% - 30px)',
  },
  summaryNumber: {
    fontSize: '18.2px',
    fontWeight: '500',
    lineHeight: '30px',
    textAlign: 'left',
    display: 'flex',
  },
  summaryCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableTitle: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '13px 20px',
    fontSize: '10px',
    fontWeight: '600',
    color: '#94969e',
    width: 80,
  },
  tableData: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '13px 20px',
    fontSize: '14px',
    color: 'rgba(61,64,78,.85)',
    width: 80,
  },
  headerInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    direction: 'ltr',
    justifyContent: 'space-between',
  },
  headerInfoPlaceholder: {
    display: 'flex',
    flexDirection: 'row',
    direction: 'ltr',
    flex: 2,
  },
  headerInfo: {
    display: 'flex',
    flexDirection: 'row',
    direction: 'ltr',
    flex: 3,
    textTransform: 'uppercase',
    color: 'rgba(61,64,78,.55)',
    fontSize: 12,
    letterSpacing: '.5px',
    fontWeight: '600',
    textAlign: 'left',
  },
  headerOverall: {
    display: 'flex',
    flexDirection: 'row',
    direction: 'ltr',
    fontWeight: '700',
    lineHeight: '1.43',
    color: '#3d404e',
    fontSize: 13,
    textAlign: 'left',
  },
  headerData: {
    display: 'flex',
    flexDirection: 'row',
    direction: 'ltr',
    lineHeight: '1.43',
    color: '#3d404e',
    fontSize: 13,
    textAlign: 'left',
  },
};

class JourneyOverviewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReport: 'entries',
      showEnterOrExitChart: true,
      showPercentage: true,
      selectedTags: [],
      showVariationChart: true,
      selectedCommunicationValue: 'delivered',
      selectedCommunicationChannel: null,
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      type: '',
    };
  }

  componentDidMount() {
    this.props.clearJourneyData();

    this.journeyId = getJourneyId(this.props.pathname);
    this.channel = getChannel(this.props.pathname);
    this.props.journeyViewFetch(this.journeyId);

    this.props.journeyListTagsFetch();
    this.props.revenueMappingUpdateFetch();
    this.props.journeyClearLaunchData();
  }

  componentDidUpdate(prevProps) {
    if (
      isEmpty(prevProps.journeyDetails) &&
      !isEmpty(this.props?.journeyDetails)
    ) {
      const fromDate = this.props?.journeyDetails?.createdDate
        ? moment(this.props.journeyDetails.createdDate).format('YYYY-MM-DD')
        : moment().subtract(7, 'days').format('YYYY-MM-DD');
      this.setState({ fromDate });
      this.props.journeyGetReportOverview(this.journeyId, {
        fromDate: fromDate,
        toDate: this.state.toDate, //TODO: calculate this based on end/pused date (if backend provide these data)
      });
      this.fetchData(fromDate, this.state.toDate);
    }
  }

  renderNumber = (number) => {
    if (isNil(number)) {
      return 0;
    }
    return numberWithCommas(number);
  };
  fetchData = (fromDate, toDate) => {
    this.props.journeyReportCommunicationReportFetch({
      toDate,
      fromDate,
      journeyId: this.journeyId,
    });
    this.props.journeyReportEnterOrExitReportFetch({
      toDate,
      fromDate,
      journeyId: this.journeyId,
    });
  };

  handleApplyTags = (selectedTags) => {
    document.getElementById('tag-selector').click();
    this.setState({ selectedTags });
  };

  campaignsCount = () => {
    if (
      this.props.journeyCommunicationReportData &&
      this.props.journeyCommunicationReportData.communicationAggregations &&
      Array.isArray(
        this.props.journeyCommunicationReportData.communicationAggregations
      )
    ) {
      return this.props.journeyCommunicationReportData.communicationAggregations
        .length;
    }
    return 0;
  };

  renderChart = (config) => {
    return (
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Line {...config} style={{ flex: 1, padding: 20 }} />
      </div>
    );
  };

  journeyAggregationTypes = () => {
    if (
      this.props.journeyCommunicationReportData &&
      this.props.journeyCommunicationReportData.communicationAggregations &&
      Array.isArray(
        this.props.journeyCommunicationReportData.communicationAggregations
      )
    ) {
      const result = [];
      const types = [];
      this.props.journeyCommunicationReportData.communicationAggregations.forEach(
        (item) => {
          if (!types.includes(item.communicationChannelType)) {
            types.push(item.communicationChannelType);
          }
        }
      );
      types.forEach((type) => {
        const foundItems = filter(
          this.props.journeyCommunicationReportData.communicationAggregations,
          (o) => o.communicationChannelType === type
        );
        let value = {
          type,
          campaigns: 0,
          delivered: 0,
          converted: 0,
          revenue: 0,
        };
        foundItems.forEach((item) => {
          value = {
            type: value.type,
            delivered: value.delivered + item.delivered,
            converted: value.converted + item.converted,
            campaigns: value.campaigns + 1,
            revenue: value.revenue + item.revenue,
          };
        });
        result.push(value);
      });
      return result;
    }
    return [];
  };

  renderChannelsInfo = () => {
    return (
      <div style={styles.summaryCard}>
        {this.props.loadingJourneyCommunicationReport ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: 30,
            }}
          >
            <Spin />
          </div>
        ) : (
          <React.Fragment>
            <div style={styles.headerInfoContainer}>
              <div style={styles.headerInfoPlaceholder}></div>
              <div style={styles.headerInfo}>
                CAMPAIGNS<span style={{ paddingLeft: 10 }}></span>
              </div>
              <div style={styles.headerInfo}>
                DELIVERED<span style={{ paddingLeft: 10 }}></span>
              </div>
              <div style={styles.headerInfo}>
                UNIQUE CONVERSIONS<span style={{ paddingLeft: 10 }}></span>
              </div>
              <div style={styles.headerInfo}>
                REVENUE
                {this.props.currentRevenueMapping &&
                  ' (' + this.props.currentRevenueMapping.revenueCurrency + ')'}
                <span style={{ paddingLeft: 10 }}></span>
              </div>
            </div>
            <div style={{ ...styles.headerInfoContainer, margin: '20px 0' }}>
              <div style={{ ...styles.headerOverall, flex: 2 }}>OVERALL</div>
              <div style={{ ...styles.headerOverall, flex: 3 }}>
                {numberWithCommas(this.campaignsCount())}
              </div>
              <div style={{ ...styles.headerOverall, flex: 3 }}>
                {numberWithCommas(this.getOverall('delivered'))}
              </div>
              <div style={{ ...styles.headerOverall, flex: 3 }}>
                {numberWithCommas(this.getOverall('converted'))}
              </div>
              <div style={{ ...styles.headerOverall, flex: 3 }}>
                {numberWithCommas(this.getOverall('revenue'))}
              </div>
            </div>
            {this.journeyAggregationTypes() &&
              Array.isArray(this.journeyAggregationTypes()) &&
              this.journeyAggregationTypes().map((report, index) => {
                if (!isNil(report.type)) {
                  return (
                    <div
                      key={index}
                      style={{
                        ...styles.headerInfoContainer,
                        margin: '20px 0',
                      }}
                    >
                      <div style={{ ...styles.headerData, flex: 2 }}>
                        {CommunicationChannels[report.type]}
                      </div>
                      <div style={{ ...styles.headerData, flex: 3 }}>
                        {this.renderBarChart(report, 'campaigns')}
                      </div>
                      <div style={{ ...styles.headerData, flex: 3 }}>
                        {this.renderBarChart(report, 'delivered')}
                      </div>
                      <div style={{ ...styles.headerData, flex: 3 }}>
                        {this.renderBarChart(report, 'converted')}
                      </div>
                      <div style={{ ...styles.headerData, flex: 3 }}>
                        {this.renderBarChart(report, 'revenue')}
                      </div>
                    </div>
                  );
                }
              })}
          </React.Fragment>
        )}
      </div>
    );
  };

  renderSummaryNumber = (selector) => {
    if (this.props.overviewData && !isNil(this.props.overviewData[selector])) {
      return numberWithCommas(this.props.overviewData[selector]);
    }
    return '-';
  };

  upLiftCalculation = () => {
    const controlGroupConversion = percentage(
      this.props?.overviewData?.controlGroupUniqueConversions,
      this.props?.overviewData?.controlGroupUniqueDelivered
    );
    if (parseFloat(controlGroupConversion) == 0) {
      return (0).toFixed(2);
    }

    const totalConversion = percentage(
      this.props?.overviewData?.uniqueConversions,
      this.props?.overviewData?.uniqueDelivered
    );

    const uplift = parseFloat(
      percentage(totalConversion, controlGroupConversion) - 100
    ).toFixed(2);

    return uplift;
  };

  controlGroupCalculation = () => {
    return percentage(
      this.props?.overviewData?.controlGroupUniqueConversions,
      this.props?.overviewData?.controlGroupUniqueDelivered
    );
  };

  renderSummary = () => {
    const increaseTooltipTitle =
      'Journey performed better than control group. Journey conversion was ' +
      this.upLiftCalculation() +
      ' higher than control group conversion. Calculations based on unique conversions.';
    const decreaseTooltipTitle =
      'Control group performed better than Journey. Journey conversion was ' +
      this.upLiftCalculation() +
      ' lower than control group conversion. Calculations based on unique conversions.';
    const zeroTooltipTitle =
      'Journey performed better than Control Group. Control Group conversion was lower than conversion of journey. Calculation based on unique conversion.';
    const uniqueConversionTooltip =
      this.props?.overviewData?.uniqueConversions +
      ' unique conversions out of ' +
      this.props?.overviewData?.uniqueDelivered +
      ' users engaged through campaigns where message was successfully delivered (for Push, SMS, WebPush & Email) or viewed.';
    const controlGroupToolTip =
      this.props?.overviewData?.controlGroupUniqueConversions +
      ' unique conversions out of ' +
      this.props?.overviewData?.controlGroupUniqueDelivered +
      ' users in control group';
    return (
      <div style={{ background: 'none', padding: 0 }}>
        <div style={styles.summaryCardContainer}>
          <div style={{ flex: 1, alignSelf: 'stretch' }}>
            <div style={styles.summaryCard}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      overflow: 'hidden',
                      backgroundColor: '#fe5196',
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      marginRight: 5,
                    }}
                  ></div>
                  <div style={styles.summaryText}>ENTRIES</div>
                </div>
                {this.props.loadingOverview ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: 20,
                    }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <React.Fragment>
                    <div style={styles.summaryNumber}>
                      {this.renderSummaryNumber('entries')}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: 15, alignSelf: 'stretch' }}>
            <div style={styles.summaryCard}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      overflow: 'hidden',
                      backgroundColor: '#008bfa',
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      marginRight: 5,
                    }}
                  ></div>
                  <div style={styles.summaryText}>EXITS</div>
                </div>
                {this.props.loadingOverview ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: 20,
                    }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <React.Fragment>
                    <div style={styles.summaryNumber}>
                      {this.renderSummaryNumber('exits')}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: 15, alignSelf: 'stretch' }}>
            <div style={styles.summaryCard}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      overflow: 'hidden',
                      backgroundColor: '#ffc107',
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      marginRight: 5,
                    }}
                  ></div>
                  <div style={styles.summaryText}>IN {this.props.type}</div>
                </div>
                {this.props.loadingOverview ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: 20,
                    }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <React.Fragment>
                    <div style={styles.summaryNumber}>
                      {this.renderSummaryNumber('inJourney')}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              flex: 1,
              marginLeft: 15,
              alignSelf: 'stretch',
            }}
          >
            <div style={styles.summaryCard}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      overflow: 'hidden',
                      backgroundColor: '#09d88c',
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      marginRight: 5,
                    }}
                  ></div>
                  <div style={styles.summaryText}>UNIQUE CONVERSIONS</div>
                </div>
                {this.props.loadingOverview ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: 20,
                    }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <React.Fragment>
                    <div style={{ ...styles.summaryNumber, marginTop: 10 }}>
                      {this.renderPercentage(
                        this.props.overviewData,
                        'uniqueConversions',
                        'uniqueDelivered'
                      )}
                      <Tooltip placement="top" title={uniqueConversionTooltip}>
                        <InfoCircleFilled
                          style={{
                            color: 'rgba(61,64,78,.55)',
                            marginTop: 5,
                            fontSize: 15,
                            paddingLeft: 5,
                          }}
                        />
                      </Tooltip>
                      {this.props?.journeyDetails?.controlGroup ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop:
                              this.upLiftCalculation() <= (0).toFixed(2)
                                ? 5
                                : -5,
                            alignItems: 'flex-end',
                          }}
                        >
                          {this.upLiftCalculation() > (0).toFixed(2) && (
                            <CaretUpOutlined
                              style={{ color: '#09d88c', marginRight: 15 }}
                            />
                          )}
                          <Tooltip
                            placement="top"
                            title={
                              this.upLiftCalculation() > (0).toFixed(2)
                                ? increaseTooltipTitle
                                : this.upLiftCalculation() === (0).toFixed(2)
                                ? this.props.overviewData?.uniqueConversions ===
                                  0
                                  ? ''
                                  : zeroTooltipTitle
                                : decreaseTooltipTitle
                            }
                          >
                            {' '}
                            <div
                              style={{
                                ...styles.summeryTitleText,
                                lineHeight: '0.8em',
                                fontSize: 13,
                                fontWeight: 550,
                                marginLeft: 50,
                                color:
                                  this.upLiftCalculation() < (0).toFixed(2)
                                    ? '#f76464'
                                    : this.upLiftCalculation() ===
                                      (0).toFixed(2)
                                    ? 'rgba(61,64,78,.55)'
                                    : '#09d88c',
                              }}
                            >
                              {this.upLiftCalculation() + '%'}
                            </div>
                          </Tooltip>
                          {this.upLiftCalculation() < (0).toFixed(2) && (
                            <CaretDownOutlined
                              style={{ color: '#f76464', marginRight: 15 }}
                            />
                          )}
                        </div>
                      ) : null}
                    </div>
                    {this.props?.journeyDetails?.conversion &&
                    this.props?.journeyDetails?.controlGroup ? (
                      <div
                        style={{
                          borderTop: '1px solid rgba(61,64,78,.2)',
                          borderRadius: 10,
                          padding: 10,
                          borderBottom: '1px solid rgba(61,64,78,.2)',
                          borderRight: '1px solid rgba(61,64,78,.2)',
                          borderLeft: '1px solid rgba(61,64,78,.2)',
                          marginTop:
                            this.upLiftCalculation() < (0).toFixed(2) ? 15 : 25,
                          marginBottom: 5,
                        }}
                      >
                        <div
                          style={{
                            color: 'rgba(61,64,78,.55)',
                            fontSize: 12,
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          Control Group:
                          <div style={{ fontWeight: 'bold' }}>
                            {' '}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {this.controlGroupCalculation() + '%'}
                            <Tooltip
                              placement="top"
                              title={controlGroupToolTip}
                            >
                              <InfoCircleFilled
                                style={{
                                  color: 'rgba(61,64,78,.55)',
                                  fontSize: 15,
                                  paddingLeft: 5,
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: 15, alignSelf: 'stretch' }}>
            <div style={styles.summaryCard}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      overflow: 'hidden',
                      backgroundColor: '#00b875',
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      marginRight: 5,
                    }}
                  ></div>
                  <div style={styles.summaryText}>
                    REVENUE
                    {this.props.currentRevenueMapping &&
                      ' (' +
                        this.props.currentRevenueMapping.revenueCurrency +
                        ')'}
                  </div>
                </div>
                {this.props.loadingOverview ? (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: 20,
                    }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <React.Fragment>
                    <div style={styles.summaryNumber}>
                      {this.renderSummaryNumber('revenue')}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTagItem = (tag) => {
    if (tag) {
      return renderTag(tag, this.props.listTags);
    }
    return <span>(No Tag)</span>;
  };

  getOverall = (selector) => {
    if (
      this.props.journeyCommunicationReportData &&
      this.props.journeyCommunicationReportData.communicationAggregations &&
      Array.isArray(
        this.props.journeyCommunicationReportData.communicationAggregations
      )
    ) {
      let result = 0;
      this.props.journeyCommunicationReportData.communicationAggregations.forEach(
        (item) => {
          result += item[selector];
        }
      );
      return result;
    }
    return 0;
  };

  renderPercentage = (item, selectorFrom, selectorTotal) => {
    if (!isNil(item)) {
      return percentage(item[selectorFrom], item[selectorTotal]) + '%';
    }
    return '-';
  };

  getCommunicationLink = (item) => {
    if (!isNil(item.communicationChannelType) && !isNil(item.communicationId)) {
      if (item.communicationChannelType === 'PUSH') {
        return (
          '../../push-notifications/campaigns/' +
          item.communicationId +
          '/overview'
        );
      } else if (item.communicationChannelType === 'SMS') {
        return (
          '../../sms-messages/campaigns/' + item.communicationId + '/overview'
        );
      } else if (item.communicationChannelType === 'EMAIL') {
        return '../../emails/campaigns/' + item.communicationId + '/overview';
      } else if (item.communicationChannelType === 'WEB_PUSH') {
        return (
          '../../web-notifications/campaigns/' +
          item.communicationId +
          '/overview'
        );
      } else if (item.communicationChannelType === 'CUSTOM') {
        return (
          '../../custom-channel/campaigns/' + item.communicationId + '/overview'
        );
      } else if (item.communicationChannelType === 'IN_APP') {
        return '../../in-app/campaigns/' + item.communicationId + '/overview';
      } else if (item.communicationChannelType === 'ON_SITE') {
        return (
          '../../onSiteChannel/campaigns/' + item.communicationId + '/overview'
        );
      }
    }
  };

  renderBarChart = (item, selector) => {
    const colors = {
      delivered: 'rgb(0, 139, 250)',
      campaigns: 'rgb(253, 87, 141)',
      converted: 'rgb(14, 216, 139)',
      revenue: 'rgb(0, 184, 117)',
    };
    let percentage = 0;
    if (item && item[selector]) {
      let overall = this.getOverall(selector);
      if (
        selector === 'campaigns' &&
        this.props.journeyCommunicationReportData &&
        this.props.journeyCommunicationReportData.communicationAggregations &&
        Array.isArray(
          this.props.journeyCommunicationReportData.communicationAggregations
        )
      ) {
        overall =
          this.props.journeyCommunicationReportData.communicationAggregations
            .length;
      }
      if (overall) {
        percentage = ((item[selector] * 100) / overall).toFixed(0);
      }
    }
    return (
      <div style={{ width: 180 }}>
        <Progress
          type="line"
          strokeLinecap="square"
          strokeWidth="10px"
          percent={percentage}
          size="small"
          status="active"
          strokeColor={colors[selector]}
        />
      </div>
    );
  };

  renderSelectedTagsCount = () => {
    if (
      this.state.selectedTags &&
      Array.isArray(this.state.selectedTags) &&
      this.state.selectedTags.length > 0
    ) {
      return this.state.selectedTags.length;
    }
    return null;
  };

  renderTagSelector = () => {
    // const usedTags = new Set();
    // if (this.props.journeyCommunicationReportData && this.props.journeyCommunicationReportData.communicationAggregations && Array.isArray(this.props.journeyCommunicationReportData.communicationAggregations)) {
    //     this.props.journeyCommunicationReportData.communicationAggregations.forEach(item => {
    //        if (item.tags && Array.isArray(item.tags)) {
    //            item.tags.forEach(tag => {
    //               const foundItem = lodashFind(this.props.listTags, object => object.name === tag);
    //               if (foundItem) {
    //                   usedTags.add(foundItem);
    //               }
    //            });
    //        }
    //     });
    // }
    return (
      <Dropdown
        overlay={
          <TagSelector
            listTags={this.props.listTags}
            selectedTags={
              this.state.selectedTags && Array.isArray(this.state.selectedTags)
                ? this.state.selectedTags
                : null
            }
            onApply={(tags) => this.handleApplyTags(tags)}
          />
        }
        trigger={['click']}
      >
        <div
          id="tag-selector"
          style={{
            width: 80,
            marginRight: 20,
            display: 'flex',
            flexDirection: 'row',
            color: '#1C1C1C',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F3F3F3',
            cursor: 'pointer',
          }}
        >
          <Badge
            offset={[10, 0]}
            count={this.renderSelectedTagsCount()}
            size="small"
            showZero={false}
          >
            Tags
          </Badge>{' '}
          <DownOutlined
            style={{ marginLeft: 20, color: '#b6b6b6', fontSize: 12 }}
          />
        </div>
      </Dropdown>
    );
  };

  renderSplitOverviewPercentage = (delivered, number) => {
    if (delivered === 0 || isNil(number) || number === 0) {
      return 0;
    }
    return ((number * 100) / delivered).toFixed(0);
  };

  getCategoryByCommunicationName = () => {
    const values = {};
    if (
      this.props.journeyCommunicationReportData &&
      this.props.journeyCommunicationReportData.communicationAggregations &&
      Array.isArray(
        this.props.journeyCommunicationReportData.communicationAggregations
      )
    ) {
      this.props.journeyCommunicationReportData.communicationAggregations.forEach(
        (item) => {
          values[item.communicationId] = item.communicationName;
        }
      );
    }
    return values;
  };

  renderVariationChart = (config) => {
    return (
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            writingMode: 'vertical-rl',
            textOrientation: 'mixed',
            textAlign: 'center',
            paddingLeft: 14,
          }}
        >
          {'COUNT'}
        </div>
        <Line {...config} style={{ flex: 1, padding: 20 }} />
      </div>
    );
  };

  renderCommunicationName = (id, data) => {
    let result = null;
    const foundItem = lodashFind(data, (o) => o.communicationId === id);
    if (foundItem) {
      result = foundItem.communicationName;
    }
    return result;
  };

  renderCommunicationReportHeader = (ids, data) => {
    let result = [];
    ids.forEach((id) => {
      result.push(
        <div
          style={{
            ...styles.tableTitle,
            borderRight: '1px solid #e7e8e9',
            flex: 1,
          }}
        >
          {/* <Tooltip
            placement="top"
            title={this.renderCommunicationName(id, data)}
          >
            {id}
          </Tooltip> */}
          {this.renderCommunicationName(id, data)}
        </div>
      );
    });
    return result;
  };

  renderCommunicationReportBody = (ids, item) => {
    let result = [];
    ids.forEach((id) => {
      result.push(
        <div
          style={{
            ...styles.tableTitle,
            borderRight: '1px solid #e7e8e9',
            flex: 1,
          }}
        >
          {isNil(item[id]) ? '-' : item[id]}
        </div>
      );
    });
    return result;
  };

  renderCommunicationReportRows = (ids, rows) => {
    let result = [];
    rows.forEach((item) => {
      result.push(
        <div
          style={{
            borderTop: '1px solid #e7e8e9',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              ...styles.tableData,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            {item.date}
          </div>
          {this.renderCommunicationReportBody(ids, item)}
        </div>
      );
    });
    return result;
  };

  renderVariationTable = (data) => {
    if (!isNil(this.state.selectedCommunicationChannel)) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            borderRadius: 5,
            paddingBottom: 5,
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                ...styles.tableTitle,
                borderRight: '1px solid #e7e8e9',
                flex: 1,
              }}
            >
              DATE
            </div>
            <div
              style={{
                ...styles.tableTitle,
                borderRight: '1px solid #e7e8e9',
                flex: 1,
              }}
            >
              DELIVERIES
            </div>
            <div
              style={{
                ...styles.tableTitle,
                borderRight: '1px solid #e7e8e9',
                flex: 1,
              }}
            >
              CLICKS
            </div>
            <div
              style={{
                ...styles.tableTitle,
                borderRight: '1px solid #e7e8e9',
                flex: 1,
              }}
            >
              CONVERSIONS
            </div>
            <div
              style={{
                ...styles.tableTitle,
                borderRight: '1px solid #e7e8e9',
                flex: 1,
              }}
            >
              REVENUE
              {this.props.currentRevenueMapping &&
                ' (' + this.props.currentRevenueMapping.revenueCurrency + ')'}
            </div>
          </div>
          {data.map((item, i) => {
            return (
              <div
                key={i}
                style={{
                  borderTop: '1px solid #e7e8e9',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    ...styles.tableData,
                    borderRight: '1px solid #e7e8e9',
                    flex: 1,
                  }}
                >
                  {readableDate(item.date)}
                </div>
                <div
                  style={{
                    ...styles.tableData,
                    borderRight: '1px solid #e7e8e9',
                    flex: 1,
                  }}
                >
                  {this.renderNumbers(item, 'delivered')}
                </div>
                <div
                  style={{
                    ...styles.tableData,
                    borderRight: '1px solid #e7e8e9',
                    flex: 1,
                  }}
                >
                  {this.renderNumbers(item, 'clicked')}
                </div>
                <div
                  style={{
                    ...styles.tableData,
                    borderRight: '1px solid #e7e8e9',
                    flex: 1,
                  }}
                >
                  {this.renderNumbers(item, 'converted')}
                </div>
                <div
                  style={{
                    ...styles.tableData,
                    borderRight: '1px solid #e7e8e9',
                    flex: 1,
                  }}
                >
                  {this.renderNumbers(item, 'revenue')}
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      const ids = new Set();
      const dates = new Set();
      data.forEach((item) => {
        ids.add(item.communicationId);
        dates.add(item.date);
      });
      const rows = [];
      dates.forEach((date) => {
        const value = {};
        ids.forEach((id) => {
          const foundItem = lodashFind(
            data,
            (o) => o.date === date && o.communicationId === id
          );
          if (foundItem) {
            value[id] = foundItem[this.state.selectedCommunicationValue];
          }
        });
        rows.push({ date: readableDate(date), ...value });
      });
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            borderRadius: 5,
            paddingBottom: 5,
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                ...styles.tableTitle,
                borderRight: '1px solid #e7e8e9',
                flex: 1,
              }}
            >
              DATE
            </div>
            {this.renderCommunicationReportHeader(ids, data)}
          </div>
          {this.renderCommunicationReportRows(ids, rows)}
        </div>
      );
    }
  };

  renderNumbers = (item, selector) => {
    return numberWithCommas(item[selector]);
  };

  renderCommunicationReport = () => {
    let temp = [];
    const data = [];
    const communicationNames = this.getCategoryByCommunicationName();
    const valueTypes = {
      delivered: 'Delivered',
      sent: 'Sent',
      clicked: 'Clicks',
      converted: 'Conversions',
      revenue: 'Revenue',
    };
    if (
      this.props.journeyCommunicationReportData &&
      this.props.journeyCommunicationReportData.communicationCharts &&
      Array.isArray(
        this.props.journeyCommunicationReportData.communicationCharts
      )
    ) {
      const dateSet = new Set();
      this.props.journeyCommunicationReportData.communicationCharts.forEach(
        (item) => {
          if (!isNil(item.communicationId)) {
            dateSet.add(item.date);
          }
        }
      );
      temp = [];
      dateSet.forEach((date) => {
        const itemsInSameDate = filter(
          this.props.journeyCommunicationReportData.communicationCharts,
          (o) => {
            return !isNil(o.communicationId) && o.date === date;
          }
        );
        if (isNil(this.state.selectedCommunicationChannel)) {
          let result = {
            id: null,
            date,
            delivered: 0,
            clicked: 0,
            converted: 0,
            revenue: 0,
            sent: 0,
          };
          itemsInSameDate.forEach((item) => {
            result = {
              communicationName: item.communicationName,
              communicationId: item.communicationId,
              date,
              delivered: Number(item.delivered),
              sent: Number(item.sent),
              clicked: Number(item.clicked),
              converted: Number(item.converted),
              revenue: Number(item.revenue),
            };
            temp.push(result);
          });
        } else {
          let result = {
            channel: null,
            date,
            delivered: 0,
            clicked: 0,
            converted: 0,
            revenue: 0,
            sent: 0,
          };
          const foundItems = filter(
            itemsInSameDate,
            (o) =>
              o.communicationChannelType ===
              this.state.selectedCommunicationChannel
          );
          foundItems.forEach((item) => {
            result = {
              channel: this.state.selectedCommunicationChannel,
              date,
              delivered: Number(item.delivered) + result.delivered,
              sent: Number(item.sent) + result.sent,
              clicked: Number(item.clicked) + result.clicked,
              converted: Number(item.converted) + result.converted,
              revenue: Number(item.revenue) + result.revenue,
            };
          });
          temp.push(result);
        }
      });
    }
    temp.forEach((item) => {
      const date = moment(item.date, 'YYYY-MM-DD').format('DD MMM');
      if (isNil(this.state.selectedCommunicationChannel)) {
        data.push({
          date,
          value: item[this.state.selectedCommunicationValue],
          category: communicationNames[item.communicationId],
        });
      } else {
        Object.keys(valueTypes).forEach((category) => {
          data.push({
            date,
            value: item[category],
            category: valueTypes[category],
          });
        });
      }
    });
    const config = {
      data,
      height: 400,
      xField: 'date',
      yField: 'value',
      padding: 'auto',
      seriesField: 'category',
      color: ['#fc587d', '#008BFA', '#FFC009', '#58FEA1', '#02eb7f', '#773a80'],
      lineStyle: function lineStyle() {
        return {
          lineDash: [4, 4],
          opacity: 1,
        };
      },
      yAxis: {
        label: {
          formatter: function formatter(v) {
            return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
              return ''.concat(s, ',');
            });
          },
        },
      },
      point: {
        size: 5,
        shape: 'circle',
      },
      legend: {
        position: 'right',
        verticalAlign: 'middle',
      },
      meta: {
        value: {
          formatter: (v) => {
            return this.renderNumber(v);
          },
        },
      },
    };
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: 5,
          paddingBottom: 5,
          marginTop: 24,
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '12px',
            paddingLeft: '20px',
            borderBottom: 'solid 2px #f4f9ff',
          }}
        >
          <div style={{ color: '#1c1c1c', fontSize: 16, fontWeight: '600' }}>
            <Select
              value={this.state.selectedCommunicationChannel}
              onChange={(selectedCommunicationChannel) =>
                this.setState({ selectedCommunicationChannel })
              }
              style={{ width: 150, marginLeft: 20, marginRight: 10 }}
              className={'chart-selector'}
              bordered={false}
            >
              <Select.Option value={null}>{'All Channels'}</Select.Option>
              <Select.Option value={'PUSH'}>{'Push'}</Select.Option>
              <Select.Option value={'SMS'}>{'SMS'}</Select.Option>
              <Select.Option value={'EMAIL'}>{'Email'}</Select.Option>
              <Select.Option value={'WEB_PUSH'}>{'Web Push'}</Select.Option>
            </Select>
            {isNil(this.state.selectedCommunicationChannel) && (
              <Select
                value={this.state.selectedCommunicationValue}
                onChange={(selectedCommunicationValue) =>
                  this.setState({ selectedCommunicationValue })
                }
                style={{ width: 200, marginLeft: 20, marginRight: 10 }}
                className={'chart-selector'}
                bordered={false}
              >
                <Select.Option value={'delivered'}>{'Delivered'}</Select.Option>
                <Select.Option value={'sent'}>{'Sent'}</Select.Option>
                <Select.Option value={'clicked'}>
                  {'Unique Clicks'}
                </Select.Option>
                <Select.Option value={'converted'}>
                  {'Unique Conversions'}
                </Select.Option>
                <Select.Option value={'revenue'}>
                  {'Revenue'}
                  {this.props.currentRevenueMapping &&
                    ' (' +
                      this.props.currentRevenueMapping.revenueCurrency +
                      ')'}
                </Select.Option>
              </Select>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: 10,
                  marginBottom: 5,
                }}
              >
                <div
                  onClick={() => this.setState({ showVariationChart: true })}
                  style={{ cursor: 'pointer', marginLeft: 25, marginRight: 15 }}
                >
                  <img
                    src={
                      this.state.showVariationChart
                        ? chartLineSelected
                        : chartLine
                    }
                    style={{ width: 16, height: 'auto' }}
                    alt=""
                  />
                </div>
                <div
                  onClick={() => this.setState({ showVariationChart: false })}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={this.state.showVariationChart ? table : tableSelected}
                    style={{ width: 16, height: 'auto' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showVariationChart
          ? this.renderVariationChart(config)
          : this.renderVariationTable(temp)}
      </div>
    );
  };

  getRowsByTags = () => {
    const result = [];
    if (
      this.props.journeyCommunicationReportData &&
      this.props.journeyCommunicationReportData.communicationAggregations &&
      Array.isArray(
        this.props.journeyCommunicationReportData.communicationAggregations
      )
    ) {
      this.props.journeyCommunicationReportData.communicationAggregations.forEach(
        (communication) => {
          if (
            communication &&
            communication.tags &&
            Array.isArray(communication.tags) &&
            communication.tags.length > 0
          ) {
            for (let i = 0; i < this.state.selectedTags.length; i++) {
              if (communication.tags.includes(this.state.selectedTags[i])) {
                result.push(communication);
                break;
              }
            }
          }
        }
      );
    }
    return result;
  };

  disabledDate = (current) => {
    return current && current > moment().startOf('day');
  };

  handleChangeDateRange = (dates) => {
    const fromDate = dates[0].format('YYYY-MM-DD');
    const toDate = dates[1].format('YYYY-MM-DD');
    this.setState({ toDate, fromDate }, () => {
      this.fetchData(fromDate, toDate);
    });
  };

  splitOverviewChart = () => {
    if (isEmpty(this.state.selectedTags)) {
      return (
        !this.props.journeyCommunicationReportData ||
        !this.props.journeyCommunicationReportData.communicationAggregations ||
        !Array.isArray(
          this.props.journeyCommunicationReportData.communicationAggregations
        ) ||
        !this.props.journeyCommunicationReportData.communicationAggregations
          .length === 0
      );
    } else {
      const result = this.getRowsByTags();
      return result.length === 0;
    }
  };

  getCommunications = () => {
    if (isEmpty(this.state.selectedTags)) {
      return this.props.journeyCommunicationReportData
        .communicationAggregations;
    }
    return this.getRowsByTags();
  };

  renderSplitOverviewTable = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: 5,
          paddingBottom: 5,
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 2,
            }}
          >
            CAMPAIGN NAME
          </div>
          <div style={{ ...styles.tableTitle, flex: 1 }}>CHANNEL</div>
          <div style={{ ...styles.tableTitle, flex: 1 }}>STATUS</div>
          <div style={{ ...styles.tableTitle, flex: 1 }}>DELIVERED</div>
          <div style={{ ...styles.tableTitle, flex: 1 }}>UNIQUE DELIVERED</div>
          <div style={{ ...styles.tableTitle, flex: 1 }}>UNIQUE CLICKS</div>
          <div style={{ ...styles.tableTitle, flex: 1 }}>
            UNIQUE CONVERSIONS
          </div>
          <div style={{ ...styles.tableTitle, flex: 1 }}>TOTAL CONVERSIONS</div>
          <div style={{ ...styles.tableTitle, flex: 1 }}>
            REVENUE
            {this.props.currentRevenueMapping &&
              ' (' + this.props.currentRevenueMapping.revenueCurrency + ')'}
          </div>
          <div style={{ ...styles.tableTitle, flex: 1 }}>
            INCREMENTAL CONVERSIONS
          </div>
        </div>
        {this.splitOverviewChart() ? (
          <div
            style={{
              borderTop: '1px solid #e7e8e9',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        ) : (
          this.getCommunications().map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  borderTop: '1px solid #e7e8e9',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    ...styles.tableData,
                    borderRight: '1px solid #e7e8e9',
                    flex: 2,
                  }}
                >
                  <Link to={this.getCommunicationLink(item)}>
                    {item.communicationName}
                  </Link>
                </div>
                <div style={{ ...styles.tableData, flex: 1 }}>
                  {CommunicationChannels[item.communicationChannelType]}
                </div>
                <div style={{ ...styles.tableData, flex: 1 }}>
                  {renderCampaignStatus(item.communicationStatus)}
                </div>
                <div style={{ ...styles.tableData, flex: 1 }}>
                  {numberWithCommas(item.delivered)}
                </div>
                <div style={{ ...styles.tableData, flex: 1 }}>
                  {numberWithCommas(item.uniqueDelivered)}
                </div>
                <div style={{ ...styles.tableData, flex: 1 }}>
                  {this.state.showPercentage
                    ? this.renderPercentage(item, 'clicked', 'uniqueDelivered')
                    : numberWithCommas(item.clicked)}
                </div>
                <div style={{ ...styles.tableData, flex: 1 }}>
                  {this.state.showPercentage
                    ? this.renderPercentage(
                        item,
                        'converted',
                        'uniqueDelivered'
                      )
                    : numberWithCommas(item.converted)}
                </div>
                <div style={{ ...styles.tableData, flex: 1 }}>
                  {this.state.showPercentage
                    ? this.renderPercentage(item, 'totalConverted', 'delivered')
                    : numberWithCommas(item.totalConverted)}
                </div>
                <div style={{ ...styles.tableData, flex: 1 }}>
                  {numberWithCommas(item.revenue)}
                </div>
                <div style={{ ...styles.tableData, flex: 1 }}>
                  {numberWithCommas(item.incrementalConversion)}
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  };

  renderCommunicationOverview = () => {
    return (
      <Card
        styles={{
          marginBottom: 30,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '12px',
            borderBottom: 'solid 2px #f4f9ff',
          }}
        >
          {this.renderTagSelector()}
          <Radio.Group
            value={this.state.showPercentage}
            onChange={(e) => this.setState({ showPercentage: e.target.value })}
          >
            <Radio.Button value={true}>%</Radio.Button>
            <Radio.Button value={false}>#</Radio.Button>
          </Radio.Group>
        </div>
        {this.renderSplitOverviewTable()}
      </Card>
    );
  };

  renderEnterExitChart = (config) => {
    return (
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Line {...config} style={{ flex: 1, padding: 20 }} />
      </div>
    );
  };

  renderEnterExitTable = (data) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: 5,
          paddingBottom: 5,
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            DAYS
          </div>
          <div
            style={{
              ...styles.tableTitle,
              borderRight: '1px solid #e7e8e9',
              flex: 1,
            }}
          >
            OCCURRENCES
          </div>
        </div>
        {data.map((item, i) => {
          return (
            <div
              key={i}
              style={{
                borderTop: '1px solid #e7e8e9',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {readableDate(item.date)}
              </div>
              <div
                style={{
                  ...styles.tableData,
                  borderRight: '1px solid #e7e8e9',
                  flex: 1,
                }}
              >
                {numberWithCommas(item[this.state.selectedReport])}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderDateSelector = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flex: 1,
          paddingBottom: 5,
          marginBottom: 20,
          borderBottom: '1px solid #e7e8e9',
        }}
      >
        <div
          style={{
            color: '#3d404e',
            fontSize: 18,
            fontWeight: 500,
            paddingLeft: 5,
          }}
        >
          Detailed Stats
        </div>
      </div>
    );
  };

  renderEnterExitReport = () => {
    const data = [];
    if (
      this.props.journeyCommunicationEnterOrExit &&
      Array.isArray(this.props.journeyCommunicationEnterOrExit)
    ) {
      this.props.journeyCommunicationEnterOrExit.forEach((item) => {
        const date = moment(item.date, 'YYYY-MM-DD').format('DD MMM');
        data.push({
          date,
          [this.state.selectedReport]: item[this.state.selectedReport],
        });
      });
    }
    const config = {
      data,
      height: 400,
      xField: 'date',
      yField: this.state.selectedReport,
      padding: 'auto',
      color: ['#fc587d', '#008BFA', '#FFC009', '#58FEA1', '#02eb7f', '#773a80'],
      lineStyle: function lineStyle() {
        return {
          lineDash: [4, 4],
          opacity: 1,
        };
      },
      yAxis: {
        label: {
          formatter: function formatter(v) {
            return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
              return ''.concat(s, ',');
            });
          },
        },
      },
      point: {
        size: 5,
        shape: 'circle',
      },
      legend: {
        position: 'right',
        verticalAlign: 'middle',
      },
      meta: {
        value: {
          formatter: (v) => {
            return this.renderNumber(v);
          },
        },
      },
    };
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderRadius: 5,
          marginTop: 20,
          paddingBottom: 5,
          borderTop: '1px solid #e7e8e9',
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '20px',
            borderBottom: '1px solid #e7e8e9',
          }}
        >
          <Radio.Group
            onChange={(e) => this.setState({ selectedReport: e.target.value })}
            value={this.state.selectedReport}
          >
            <Radio value={'entries'}>Entries</Radio>
            <Radio value={'exits'}>Exits</Radio>
          </Radio.Group>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginRight: 10,
                marginBottom: 5,
              }}
            >
              <div
                onClick={() => this.setState({ showEnterOrExitChart: true })}
                style={{ cursor: 'pointer', marginLeft: 25, marginRight: 15 }}
              >
                <img
                  src={
                    this.state.showEnterOrExitChart
                      ? chartLineSelected
                      : chartLine
                  }
                  style={{ width: 16, height: 'auto' }}
                  alt=""
                />
              </div>
              <div
                onClick={() => this.setState({ showEnterOrExitChart: false })}
                style={{ cursor: 'pointer' }}
              >
                <img
                  src={this.state.showEnterOrExitChart ? table : tableSelected}
                  style={{ width: 16, height: 'auto' }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.showEnterOrExitChart
          ? this.renderEnterExitChart(config)
          : this.renderEnterExitTable(data)}
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderSummary()}
        {this.renderDateSelector()}
        {this.renderChannelsInfo()}
        {this.renderCommunicationOverview()}
        {this.renderCommunicationReport()}
        {this.renderEnterExitReport()}
      </div>
    );
  }
}

export default JourneyOverviewComponent;
