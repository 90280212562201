import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import CommunicationAudienceContainer from './container/CommunicationAudienceContainer';
import CommunicationConversionContainer from './container/CommunicationConversionContainer';
import CommunicationDetailsContainer from './container/CommunicationDetailsContainer';
import CommunicationEngagedUserContainer from './container/CommunicationEngagedUserContainer';
import CommunicationMessageContainer from './container/CommunicationMessageContainer';
import CommunicationPreviewContainer from './container/CommunicationPreviewContainer';
import CommunicationTestContainer from './container/CommunicationTestContainer';
import CommunicationViewContainer from './container/CommunicationViewContainer';
import CommunicationWhenContainer from './container/CommunicationWhenContainer';

function Communication(props) {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}:id/audience`}>
        <CommunicationAudienceContainer />
      </Route>
      <Route path={`${match.path}audience`}>
        <CommunicationAudienceContainer />
      </Route>
      <Route path={`${match.path}:id/conversion/`}>
        <CommunicationConversionContainer />
      </Route>
      <Route path={`${match.path}:id/message/`}>
        <CommunicationMessageContainer />
      </Route>
      <Route path={`${match.path}:id/preview/`}>
        <CommunicationPreviewContainer />
      </Route>
      <Route path={`${match.path}:id/test/`}>
        <CommunicationTestContainer />
      </Route>
      <Route path={`${match.path}:id/overview/`}>
        <CommunicationViewContainer {...props} />
      </Route>
      <Route path={`${match.path}:id/details/`}>
        <CommunicationDetailsContainer />
      </Route>
      <Route path={`${match.path}:id/when/`}>
        <CommunicationWhenContainer />
      </Route>
      <Route path={`${match.path}:id/users/`}>
        <CommunicationEngagedUserContainer {...props} />
      </Route>
    </Switch>
  );
}

export default Communication;
