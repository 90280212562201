import JourneyAddComponent from '../component/JourneyAddComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';

import {
  journeyCreate,
  journeyUpdate,
  journeyLaunch,
  journeyStop,
  journeyPause,
  journeyUpdateSubmit,
  journeyLocalUpdateSteps,
  journeyListAttribtesFetch,
  journeyListEventsFetch,
  journeyListBusinessEventsFetch,
  journeyBusinessEventsReportFetch,
  journeyListSegmentsFetch,
  journeyListTemplateNames,
  journeyTestApi,
} from '../JourneyActions';

import { uiControllerSubmitLog } from '../../../admin/uiController/UiControllerActions';

import {
  productSegmentReportViewFetch,
  productSegmentRecommendUserAttributesFetch,
  productSegmentRecommendDeviceAttributesFetch,
  productSegmentRecommendEventAttributesFetch,
} from '../../../insight/productSegment/ProductSegmentActions';

import { clearModalData } from '../../communication/CommunicationActions';

const mapStateToProps = (state) => ({
  currentUser: state.account.auth.currentUser,

  loadingCreateJourney: state.engage.journey.loadingCreateJourney,
  successCreateJourney: state.engage.journey.successCreateJourney,
  errorCreateJourney: state.engage.journey.errorCreateJourney,

  loadingUpdateJourney: state.engage.journey.loadingUpdateJourney,
  successUpdateJourney: state.engage.journey.successUpdateJourney,
  errorUpdateJourney: state.engage.journey.errorUpdateJourney,

  attributes: state.engage.journey.attributes,

  loadingUpdateSubmitJourney: state.engage.journey.loadingUpdateSubmitJourney,
  successUpdateSubmitJourney: state.engage.journey.successUpdateSubmitJourney,
  errorUpdateSubmitJourney: state.engage.journey.errorUpdateSubmitJourney,
  redirect: state.engage.journey.redirect,
  publishAfterCreate: state.engage.journey.publishAfterCreate,
  showNotificationMessage: state.engage.journey.showNotificationMessage,

  loadingGetSegments: state.engage.journey.loadingSegmentsList,
  successGetSegments: state.engage.journey.segments,
  errorGetSegments: state.engage.journey.errorSegments,

  loadingGetTemplateNames: state.engage.journey.loadingTemplateNamesList,
  templateNames: state.engage.journey.templateNames,

  loadingGetEvents: state.engage.journey.loadingEventsList,
  successGetEvents: state.engage.journey.events,
  errorGetEvents: state.engage.journey.errorEvents,

  loadingGetBusinessEvents: state.engage.journey.loadingBusinessEventsList,
  successGetBusinessEvents: state.engage.journey.businessEvents,
  errorGetBusinessEvents: state.engage.journey.errorBusinessEvents,

  loadingBusinessEventsReport: state.engage.journey.loadingBusinessEventsReport,
  successGetBusinessEventsReport: state.engage.journey.businessEventsReport,
  errorGetBusinessEventsReport: state.engage.journey.errorBusinessEventsReport,

  loadingLaunch: state.engage.journey.loadingLaunch,
  launchData: state.engage.journey.launchData,
  errorLaunch: state.engage.journey.errorLaunch,

  loadingStop: state.engage.journey.loadingStop,
  stopData: state.engage.journey.stopData,
  errorStop: state.engage.journey.errorStop,

  loadingPause: state.engage.journey.loadingPause,
  pauseData: state.engage.journey.pauseData,
  errorPause: state.engage.journey.errorPause,

  loadingTestApi: state.engage.journey.loadingTestApi,
  testApiData: state.engage.journey.testApiData,
  errorTestApi: state.engage.journey.errorTestApi,

  loadingSegmentReportView: state.insight.productSegment.loadingReportView,
  segmentReportView: state.insight.productSegment.currentReport,
  errorSegmentReportView: state.insight.productSegment.errorReportView,

  loadingRecommendUserAttributes:
    state.insight.productSegment.loadingRecommendUserAttributes,
  recommendUserAttributes: state.insight.productSegment.recommendUserAttributes,
  loadingRecommendEventAttributes:
    state.insight.productSegment.loadingRecommendEventAttributes,
  recommendEventAttributes:
    state.insight.productSegment.recommendEventAttributes,
  loadingRecommendDeviceAttributes:
    state.insight.productSegment.loadingRecommendDeviceAttributes,
  recommendDeviceAttributes:
    state.insight.productSegment.recommendDeviceAttributes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      journeyCreate,
      journeyUpdate,
      journeyStop,
      journeyPause,
      journeyLaunch,
      clearModalData,
      journeyUpdateSubmit,
      journeyLocalUpdateSteps,
      journeyListEventsFetch,
      journeyListBusinessEventsFetch,
      journeyBusinessEventsReportFetch,
      journeyListSegmentsFetch,
      journeyListTemplateNames,
      journeyListAttribtesFetch,
      journeyTestApi,
      productSegmentReportViewFetch,
      uiControllerSubmitLog,
      productSegmentRecommendUserAttributesFetch,
      productSegmentRecommendDeviceAttributesFetch,
      productSegmentRecommendEventAttributesFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JourneyAddComponent);

JourneyAddComponent.propTypes = {};
