import AdminProductViewComponent from '../component/AdminProductViewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  adminProductViewFetch,
  adminProductIndexFetch,
  adminProductChangeCurrent,
} from '../AdminProductActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentAdminProduct: state.admin.adminProduct.currentAdminProduct,
  loadingProducts: state.admin.adminProduct.loadingProducts,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      adminProductViewFetch,
      adminProductIndexFetch,
      adminProductChangeCurrent,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProductViewComponent);

AdminProductViewComponent.propTypes = {
  currentAdminProduct: PropTypes.object.isRequired,
  adminProductViewFetch: PropTypes.func.isRequired,
  adminProductIndexFetch: PropTypes.func.isRequired,
  adminProductChangeCurrent: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
