import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const ControlGroupSection = ({
  variationDistribution,
  handleVariationDistributionChange,
  isEditDisable,
}) => {
  const [controlGroupPercentage, setControlGroupPercentage] = useState(0);

  useEffect(() => {
    setControlGroupPercentage(
      100 - Number(variationDistribution.nonControlGroupPercentage)
    );
  }, [variationDistribution.nonControlGroupPercentage]);

  return (
    <>
      <Slider
        disabled={isEditDisable()}
        defaultValue={variationDistribution.nonControlGroupPercentage}
        railStyle={{ backgroundColor: '#96dbfa' }}
        onChange={(value) =>
          handleVariationDistributionChange({
            ...variationDistribution,
            nonControlGroupPercentage: value,
          })
        }
      />
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: variationDistribution.nonControlGroupPercentage,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>Variations</div>
          <div>{variationDistribution.nonControlGroupPercentage + '%'}</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: controlGroupPercentage,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>CG</div>
          <div>{controlGroupPercentage + '%'}</div>
        </div>
      </div>
    </>
  );
};

export default ControlGroupSection;
