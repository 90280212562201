/* eslint-disable no-case-declarations */
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Select,
  Col,
  Row,
  Card,
  Input,
  Form,
  Button,
  Spin,
  message,
  Empty,
} from 'antd';
import {
  CopyTwoTone,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import InputSelector from '../../../../form/InputSelector';
import { AppContext } from '../../../app';
import { filterJourneyVariableSteps } from '../../../utils/hooks';
import { getActiveProductDetails } from '@Utils/AuthorityProvider';
import _ from 'lodash';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select;

const Methods = ['GET', 'POST', 'PUT', 'DELETE'];
const initHeaders = [
  {
    key: '',
    value: '',
  },
];
const initTab = [
  {
    key: 'setHeaders',
    tab: 'Set Headers',
  },
];

const BuildStep = (props) => {
  const [form] = Form.useForm();
  let { location } = useHistory();
  const { journey, loading: loadingApiCall } = useContext(AppContext);
  const currentStepId = props?.dataCall?.id;
  const journeySteps = filterJourneyVariableSteps(journey.steps, currentStepId); // Remove self step

  // PROPS
  const {
    currentTab,
    tab,
    setCurrentTab,
    currentStep,
    tabsData,
    syncCache,
    setDataCall,
    reviewResponse,
  } = props;

  const { events, attributes } = props.data;
  const dataCall = props.dataCall;
  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState(dataCall.method);
  const [headers, setHeaders] = useState(
    dataCall.headers || initHeaders.slice()
  );

  const isTestStep = props.isTestStep;

  const [activeTab, setActiveTab] = useState();
  const [tabs, setTabs] = useState([]);
  const [urlFieldValue, setUrlFieldValue] = useState(currentStep.url);
  const [bodyFieldValue, setBodyFieldValue] = useState(currentStep.body);
  const apiCallEndpoints = useMemo(() => {
    return (
      (getActiveProductDetails(location.pathname) || {})?.apiCallEndpoints || ''
    ).split(',');
  }, []);

  useEffect(() => {
    let init_ = initTab.slice();
    let defaultHeader = 'setHeaders';
    if (dataCall.method === 'POST' || dataCall.method === 'PUT') {
      init_ = [
        ...initTab,
        {
          key: 'setBody',
          tab: 'Set Body',
        },
      ];
    }

    setTabs(init_);
    setActiveTab(defaultHeader);

    if (headers !== dataCall.headers) {
      setHeaders(
        dataCall.headers?.length ? dataCall.headers : initHeaders.slice()
      );
    }

    const keys = dataCall.headers?.map((item) => item.key);
    const values = dataCall.headers?.map((item) => item.value);

    form.setFieldsValue({
      method: dataCall.method,
      url: dataCall.url,
      body: dataCall.body,
      headers: dataCall.headers,
      ['key_']: keys,
      ['value_']: values,
    });
    setMethod(dataCall.method);
  }, [dataCall]);

  useEffect(() => {
    if (!loadingApiCall) {
      setLoading(false);
    }
  }, [reviewResponse, loadingApiCall]);
  function generateCurlCommand() {
    if (!urlFieldValue || !headers[0].key) {
      return '';
    }
    const curlHeaders = headers
      .map((header) => `-H "${header.key}: ${header.value}"`)
      .join(' ');

    let curlCommand = `curl -X ${method} ${urlFieldValue} ${curlHeaders}`;

    if (bodyFieldValue) {
      const escapedBody = bodyFieldValue;
      curlCommand += ` -d '${escapedBody}'`;
    }

    return curlCommand;
  }

  const handleCopyClick = () => {
    navigator.clipboard.writeText(generateCurlCommand()).then(
      () => {
        message.success('cURL command copied to clipboard');
      },
      (err) => {
        console.error('Unable to copy to clipboard', err);
        message.error('Failed to copy cURL command');
      }
    );
  };
  const handleChangeMethod = (e) => {
    setMethod(e);
    if (e === 'POST' || e === 'PUT') {
      setTabs([
        ...initTab,
        {
          key: 'setBody',
          tab: 'Set Body',
        },
      ]);
      form.setFieldsValue({
        body: '',
      });
    } else {
      setTabs(initTab);
    }
  };

  const createRow = () => {
    setHeaders((old) => {
      const data_ = [
        ...old,
        {
          key: '',
          value: '',
        },
      ];
      return data_;
    });
  };

  const deleteRow = (index) => {
    if (index > 0) {
      let headers_ = headers.slice();
      headers_ = headers_.filter((h, i) => i !== index);
      setHeaders(headers_);
    } else {
      setHeaders([...initHeaders]);
    }
  };

  const handleChangeKey = (e, index) => {
    let headers_ = headers.length ? headers.slice() : initHeaders.slice();
    headers_[index].key = e.target.value;
    setHeaders(headers_);
  };

  const handleChangeValue = (value, index) => {
    let headers_ = headers.length ? headers.slice() : initHeaders.slice();
    headers_[index].value = value;
    setHeaders(headers_);
  };

  const checkRule = useCallback(
    (item) => {
      if (item.key) return true;
      if (headers.length > 1) return true;
      return false;
    },
    [headers]
  );

  const renderRowHeader = (header, index) => {
    return (
      <Row key={index}>
        <Col span={10} className="acl-call__header_left-col">
          <Form.Item
            className="apiCallFormItem"
            name={['key_', index]}
            rules={[
              {
                required: checkRule(header),
              },
            ]}
            help={false}
          >
            <Input
              size="large"
              placeholder="Enter key"
              className="normalInput"
              style={{ padding: '12px' }}
              value={header.key}
              readOnly={props.readOnly}
              onChange={(e) => handleChangeKey(e, index)}
            />
          </Form.Item>
          <span className="input_spacer">:</span>
        </Col>
        <Col span={11} className="acl-call__header_right-col">
          {/* <Input size="large" placeholder="Enter value" /> */}
          <Form.Item
            // name={'value_' + index}
            className="apiCallFormItem"
            name={['value_', index]}
            rules={[
              {
                required: checkRule(header),
              },
            ]}
            help={false}
          >
            <InputSelector
              // disabled={props.readOnly}
              readOnly={props.readOnly}
              id={`ac-call-header-${index}`}
              journeySteps={journeySteps}
              attributeStyle={{
                position: 'absolute',
                top: '49px',
                right: '195px',
                zIndex: 20,
              }}
              attributes={attributes}
              events={events}
              value={header.value}
              hideEmojiPicker
              placeholder={'Enter value'}
              onChange={(value) => handleChangeValue(value, index)}
            />
          </Form.Item>
        </Col>
        {!props.readOnly ? (
          <Col span={2} className="acl-call__header_plus">
            <PlusCircleOutlined
              onClick={createRow}
              style={{ fontSize: '20px' }}
            />
            {index ? (
              <MinusCircleOutlined
                onClick={() => deleteRow(index)}
                style={{ fontSize: '20px' }}
              />
            ) : (
              ''
            )}
          </Col>
        ) : (
          ''
        )}
      </Row>
    );
  };

  const renderBody = () => {
    return (
      <Row className="acl-call__body">
        <Form.Item name="body">
          <InputSelector
            disabled={props.readOnly}
            id="ac-call-body"
            journeySteps={journeySteps}
            attributeStyle={{
              position: 'absolute',
              top: '49px',
              right: '195px',
              zIndex: 20,
            }}
            attributes={attributes}
            events={events}
            // value={body}
            hideEmojiPicker
            placeholder={''}
            textArea={true}
            // onChange={(value) => {
            //   setBody(value);
            //   // syncContext('body', value);
            // }}
            onChange={(value) => setBodyFieldValue(value)}
          />
        </Form.Item>
        {/* <TextArea rows={5} value={body} onChange={(e) => setBody(e.target.value)}/> */}
      </Row>
    );
  };

  const convertToObject = (key_, value_) => {
    return key_
      ?.filter((k) => k)
      .map((k, i) => ({
        key: k,
        value: value_[i],
      }));
  };

  const onContinue = async (index, remote = false) => {
    setLoading(false);
    try {
      await form.validateFields();
      const {
        url: url1,
        body: body1,
        key_,
        value_,
        method,
      } = form.getFieldsValue();
      setCurrentTab({ index, key: tabsData[index].key });
      // save step
      const hd = convertToObject(key_, value_);

      const currentStepUpdated = Object.assign({}, currentStep, {
        url: url1,
        body: body1,
        headers: hd,
        method,
      });
      delete currentStepUpdated.ui?.error;
      if (remote) {
        setDataCall(currentStepUpdated);
        syncCache('update-step', currentStepUpdated, false);
      }
    } catch (e) {
      console.log('error----', e);
    }
  };

  const onCallApi = () => {
    setLoading(true);
    const { url: url1, body: body1, key_, value_ } = form.getFieldsValue();
    const hd = convertToObject(key_, value_);

    let body_ = body1;
    if (_.isString(body_)) {
      try {
        body_ = JSON.parse(body_.replaceAll('\n', ''));
      } catch (e) {
        console.log('error', e);
      }
    }
    props.testApi({
      url: url1,
      method: dataCall.method,
      headers: hd,
      body: JSON.stringify(body_),
    });
  };

  if (currentTab.index !== tab) {
    return '';
  }

  return (
    <div>
      <Form
        form={form}
        layout={'horizontal'}
        className="acl-call__form modal_wrap"
      >
        <Row>
          <Col span={6}>
            <Form.Item
              name="method"
              rules={[
                {
                  required: true,
                },
              ]}
              help={false}
              initialValue="POST"
            >
              <Select
                // defaultValue="POST"
                disabled={isTestStep || props.readOnly}
                value={method}
                className="arc-call-selector"
                style={{ width: '90%' }}
                onChange={handleChangeMethod}
              >
                {Methods.map((protocol) => (
                  <Option
                    // disabled={protocol === 'GET' || protocol === 'DELETE'}
                    key={protocol}
                    value={protocol}
                  >
                    {protocol}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item
              name="url"
              className="apiCallFormItem"
              rules={[
                {
                  required: true,
                },
                () => ({
                  validator(_, value) {
                    try {
                      const u = new URL(value);
                      if (
                        !apiCallEndpoints.length ||
                        !apiCallEndpoints[0].length ||
                        apiCallEndpoints.includes(`${u.protocol}//${u.host}`)
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error('This host is not in your white list!')
                        );
                      }
                      // eslint-disable-next-line no-empty
                    } catch (error) {}

                    return Promise.reject(
                      new Error('please enter a valid url')
                    );
                  },
                }),
              ]}
            >
              <InputSelector
                disabled={props.readOnly}
                id="ac-call-url"
                journeySteps={journeySteps}
                attributeStyle={{
                  position: 'absolute',
                  top: '49px',
                  right: '195px',
                  zIndex: 20,
                }}
                attributes={attributes}
                events={events}
                hideEmojiPicker
                placeholder={'Enter URL starting with http:// or https://'}
                onChange={(value) => setUrlFieldValue(value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            {tabs.length ? (
              <Card
                tabList={tabs}
                headStyle={{ background: '#f9f9f9' }}
                activeTabKey={activeTab}
                onTabChange={(key) => setActiveTab(key)}
              >
                <div
                  style={{
                    display: activeTab === 'setHeaders' ? 'block' : 'none',
                  }}
                >
                  {headers.map((header, index) =>
                    renderRowHeader(header, index)
                  )}
                </div>
                <div
                  style={{
                    display: activeTab === 'setBody' ? 'block' : 'none',
                  }}
                >
                  {renderBody()}
                </div>
              </Card>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <Card
              title="cURL"
              extra={
                <CopyTwoTone
                  onClick={handleCopyClick}
                  style={{
                    fontSize: 18,
                  }}
                />
              }
            >
              {!generateCurlCommand().length ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <div
                  id="curlCommand"
                  style={{ minHeight: 134, wordBreak: 'break-word' }}
                >
                  {generateCurlCommand()}
                </div>
              )}
            </Card>
          </Col>
        </Row>
        {!isTestStep ? (
          <div className={'modal-footer'}>
            <Form.Item shouldUpdate>
              {() => {
                return (
                  <Button
                    type="primary"
                    shape="round"
                    onClick={() => onContinue(1, true)}
                    disabled={!form.getFieldValue('url') || props.readOnly}
                  >
                    CONTINUE
                  </Button>
                );
              }}
            </Form.Item>
          </div>
        ) : (
          ''
        )}
        {isTestStep ? (
          <div className={'modal-footer'}>
            <Row style={{ flexDirection: 'row-reverse' }}>
              <Form.Item style={{ marginLeft: 10 }}>
                <Button
                  className="ac-button-skip"
                  type="primary"
                  shape="round"
                  onClick={() => onContinue(2)}
                  disabled={props.readOnly}
                >
                  SKIP THIS STEP
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  onClick={onCallApi}
                  disabled={props.readOnly || loading || props.loading}
                >
                  {props.loading || loading ? <Spin indicator={antIcon} /> : ''}
                  {' CALL API NOW'}
                </Button>
              </Form.Item>
            </Row>
          </div>
        ) : (
          ''
        )}
      </Form>
    </div>
  );
};

export default BuildStep;
