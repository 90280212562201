import React from 'react';
import { Input, Button, Form } from 'antd';
import { history } from '../../../../redux/store';
import lessStyles from './teamInvitation.module.less';
class TeamMemberInvitationComponent extends React.Component {
  componentDidMount() {
    this.props.logoutFetch();
  }

  render() {
    let token = history.location.search.split('?token=').pop();
    return (
      <div className={lessStyles['register-wrapper']}>
        <div className={lessStyles['register-container']}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div className={lessStyles.layout}>
                <span className={lessStyles.layoutItem}>First Name</span>
                <span className={lessStyles.layoutItem}>Last Name</span>
                <span className={lessStyles.layoutItem}>Phone</span>
                <span className={lessStyles.layoutItem}>Password</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Form.Item className={lessStyles.input}>
                  <Input
                    autoComplete="name"
                    onChange={(e) => {
                      this.props.invitedUserChangeCurrent({
                        ...this.props.currentInvitedUser,
                        firstName: e.target.value,
                      });
                    }}
                  ></Input>
                </Form.Item>
                <Form.Item className={lessStyles.input}>
                  {' '}
                  <Input
                    autoComplete="new-lastname"
                    onChange={(e) =>
                      this.props.invitedUserChangeCurrent({
                        ...this.props.currentInvitedUser,
                        lastName: e.target.value,
                      })
                    }
                  ></Input>
                </Form.Item>
                <Form.Item className={lessStyles.input}>
                  <Input
                    autoComplete="new-phone"
                    className={lessStyles.input}
                    onChange={(e) =>
                      this.props.invitedUserChangeCurrent({
                        ...this.props.currentInvitedUser,
                        phone: e.target.value,
                      })
                    }
                    onKeyUp={() => {
                      console.log('uppppp');
                    }}
                  ></Input>
                  <span style={{ color: 'grey', fontSize: 10, marginTop: -5 }}>
                    +98.....
                  </span>
                </Form.Item>

                <Form.Item autoComplete="off">
                  <Input
                    autoComplete="new-password"
                    type="password"
                    onChange={(e) =>
                      this.props.invitedUserChangeCurrent({
                        ...this.props.currentInvitedUser,
                        inputPassword: e.target.value,
                      })
                    }
                  ></Input>
                </Form.Item>
              </div>
            </div>
            <Button
              style={{ marginTop: 20 }}
              //   loading={props.loginLoading}
              onClick={() =>
                this.props.teamMemberRegisterFetch(
                  this.props.currentInvitedUser,
                  token
                )
              }
              type="primary"
              shape="round"
            >
              Sign Up
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default TeamMemberInvitationComponent;
