import {
  ROLE_AUTHORITY_LIST_FETCH_SUCCESS,
  ROLE_LIST_FETCH,
  ROLE_LIST_FETCH_SUCCESS,
  ROLE_CHANGE_CURRENT,
  ROLE_UPDATE_FETCH_SUCCESS,
  ROLE_CREATE_FETCH_FAIL,
  ROLE_UPDATE_SUBMIT_FETCH_FAIL,
  ROLE_PAGE_CHANGE_CURRENT,
  ROLE_VIEW_FETCH_SUCCESS,
  ROLE_COPY_FETCH_SUCCESS,
  ROLE_SEARCH_LIST_FETCH_SUCCESS,
  ROLE_SEARCH_LIST_FETCH,
  ROLE_VIEW_FETCH_FAIL,
  ROLE_VIEW_FETCH,
  ROLE_UPDATE_FETCH_FAIL,
  ROLE_UPDATE_FETCH,
  ROLE_UPDATE_SUBMIT_FETCH_SUCCESS,
  ROLE_UPDATE_SUBMIT_FETCH,
  ROLE_CREATE_FETCH,
  ROLE_CREATE_FETCH_SUCCESS,
  ADMIN_ROLE_CLEAR_ERROR,
} from './RoleConstants';

const initialState = {
  page: {},
  pageRequest: {},
  authorities: [],
  errors: [],
  currentRole: {},
  loadingRoleList: false,
  loadingRoleSearchList: false,
  loadingViewRole: false,
  loadingUpdateRole: false,
  roleSubmitLoading: false,
};

export default function RoleStateReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_ROLE_CLEAR_ERROR:
      return { ...state, errors: { ...state.errors, errors: action.payload } };
    case ROLE_LIST_FETCH:
      return { ...state, pageRequest: action.payload, loadingRoleList: true };
    case ROLE_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingRoleList: false };
    case ROLE_SEARCH_LIST_FETCH:
      return {
        ...state,
        pageRequest: action.payload,
        loadingRoleSearchList: true,
      };
    case ROLE_SEARCH_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingRoleSearchList: false };
    case ROLE_AUTHORITY_LIST_FETCH_SUCCESS:
      return { ...state, authorities: action.payload };
    case ROLE_CREATE_FETCH:
      return { ...state, roleSubmitLoading: true };
    case ROLE_CREATE_FETCH_SUCCESS:
      return { ...state, roleSubmitLoading: false };
    case ROLE_CREATE_FETCH_FAIL:
      return {
        ...state,
        roleSubmitLoading: false,
        errors: action.payload.errors,
      };
    case ROLE_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, roleSubmitLoading: false };
    case ROLE_UPDATE_SUBMIT_FETCH:
      return { ...state, roleSubmitLoading: true };
    case ROLE_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        roleSubmitLoading: false,
      };
    case ROLE_CHANGE_CURRENT:
      return { ...state, currentRole: action.payload, errors: [] };
    case ROLE_UPDATE_FETCH:
      return { ...state, currentRole: action.payload, loadingUpdateRole: true };
    case ROLE_UPDATE_FETCH_FAIL:
      return {
        ...state,
        currentRole: action.payload,
        loadingUpdateRole: false,
      };
    case ROLE_UPDATE_FETCH_SUCCESS:
      return {
        ...state,
        currentRole: action.payload,
        loadingUpdateRole: false,
      };
    case ROLE_COPY_FETCH_SUCCESS:
    case ROLE_VIEW_FETCH:
      return { ...state, currentRole: action.payload, loadingViewRole: true };
    case ROLE_VIEW_FETCH_FAIL:
      return { ...state, currentRole: action.payload, loadingViewRole: false };
    case ROLE_VIEW_FETCH_SUCCESS:
      return { ...state, currentRole: action.payload, loadingViewRole: false };
    case ROLE_PAGE_CHANGE_CURRENT:
      return { ...state, pageRequest: action.payload };
    default:
      return state;
  }
}
