import { createSlice } from '@reduxjs/toolkit';
import { showMessageError } from '@Utils/RenderUtils';
import BackOfficeApi from './backOfficeInvoiceApi';

const initialState = {
  backOfficeInvoiceListAll: [],
  pageRequest: {
    direction: 'DESC',
    enabled: true,
    order: 'modifiedDate',
    name: '',
    page: 0,
  },
  loadingList: false,
  loading: false,
  backOfficeInvoiceList: {},
  errors: [],
  contractDetail: {},
};

export const contracts = createSlice({
  name: 'contractTemplate',
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
      state.loadingList = payload;
    },
    backOfficeInvoiceListAllFetchSuccess(state, { payload }) {
      state.backOfficeInvoiceListAll = payload;
    },
    invoiceListFetched(state, { payload }) {
      state.backOfficeInvoiceList = payload;
    },
    invoiceListFetchedErrors(state, { payload }) {
      state.errors = payload;
    },
    setPageRequest(state, { payload }) {
      state.pageRequest = payload;
    },
    setContractDetail(state, { payload }) {
      state.contractDetail = payload;
    },
  },
});

export default contracts.reducer;

export const {
  backOfficeInvoiceListAllFetchSuccess,
  setLoading,
  invoiceListFetched,
  invoiceListFetchedErrors,
  setPageRequest,
  setContractDetail,
} = contracts.actions;

export const backOfficeInvoiceListAllFetch =
  (pageRequest) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await BackOfficeApi.listAll(pageRequest);
      dispatch(backOfficeInvoiceListAllFetchSuccess(response));
      dispatch(setPageRequest(pageRequest));

      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
      showMessageError(e);
    }
  };

export const backOfficeInvoiceListFetch =
  (id, pageRequest) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await BackOfficeApi.list(id, pageRequest);
      dispatch(invoiceListFetched(response));
      dispatch(setPageRequest(pageRequest));

      dispatch(setLoading(false));
    } catch (err) {
      dispatch(invoiceListFetchedErrors(err));
      dispatch(setLoading(false));
      showMessageError(err);
    }
  };

export const backOfficeContractProductDetailFetch =
  (id) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await BackOfficeApi.getContractProduct(id);
      dispatch(setContractDetail(response));
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setLoading(false));
      showMessageError(err);
    }
  };
