import infobipLogo from '../../../../theme/icons/infobip.png';
import WhatsAppProviderModal from './WhatsAppProviderModal';
import style from '../../channel.module.less';
import React, { useState } from 'react';
import Card from '@Utils/Card';

const AvailableWhatsAppProviders = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Card
        style={styles.serviceProvidersWrapper}
        title="Available WhatsApp Service Providers (WSP)"
      >
        <div style={styles.providerSection}>
          <p style={styles.subHeader}>
            Select a WSP below to configure and add to your project
          </p>
          <div className={style.serviceProviderContainer}>
            {providersList.map((provider) => {
              return (
                <div
                  key={provider.name}
                  className={style.serviceProviderBox}
                  onClick={() => setIsModalOpen(true)}
                >
                  <img src={provider.image} />
                  <span>{provider.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      </Card>
      {isModalOpen && (
        <WhatsAppProviderModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default AvailableWhatsAppProviders;

const providersList = [
  {
    name: 'Infobip',
    image: infobipLogo,
  },
];

const styles = {
  serviceProvidersWrapper: {
    marginTop: 20,
  },
  providerSection: {
    padding: 30,
  },
  subHeader: {
    color: '#767577',
  },
};
