import React from 'react';
import { useSelector } from 'react-redux';
import CommunicationStepsComponent from '../CommunicationStepsComponent';
import {
  journeySteps,
  transactionalSteps,
} from '../../../../../utils/CommunicationSteps';

const TrackingStep = ({
  isEdited,
  journey,
  goToStepOnJourney,
  journeySteps: journeyStp,
  communicationHasJourneyType,
  channel,
}) => {
  const currentConversion = useSelector(
    (state) => state.engage.communication.currentConversion
  );

  let steps;
  if (journey) {
    steps = journeyStp;
  } else if (communicationHasJourneyType()) {
    steps = journeySteps;
  } else if (currentConversion?.type === 'TRANSACTIONAL') {
    steps = transactionalSteps;
  }

  return (
    <CommunicationStepsComponent
      step={'conversion'}
      isEdited={isEdited}
      campaignType={currentConversion?.type}
      creationStep={currentConversion?.creationStep ?? null}
      journey={journey}
      steps={steps}
      goToStepOnJourney={goToStepOnJourney}
      channel={channel}
    />
  );
};

export default TrackingStep;
