import React, { useState, useEffect } from 'react';
import { Button, Modal, Spin } from 'antd';
import UploadReceiptModalContent from './UploadReceiptModalContent';
import {
  fetchInvoicePaymentReceipt,
  setSelectedInvoiceInfo,
  uploadPaymentReceipt,
} from '@Redux/slices/setting/invoiceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { INVOICE_STATUS } from '@Modules/setting/billing/components/BillingConstant';

const UploadReceiptModal = ({
  isOpen,
  onClose,
  selectedInvoiceId,
  selectedInvoiceStatus,
}) => {
  const dispatch = useDispatch();
  const { loadingUploadReceipts, loadingFetchInvoice, selectedInvoiceInfo } =
    useSelector((state) => state.setting.invoice);

  const [receipts, setReceipts] = useState([]);
  const [description, setDescription] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);

  const disabledStatus = [
    INVOICE_STATUS.PAYMENT_PENDING.name,
    INVOICE_STATUS.PAID.name,
  ];

  useEffect(() => {
    if (selectedInvoiceId) {
      dispatch(fetchInvoicePaymentReceipt(selectedInvoiceId));
    }
    setIsViewMode(disabledStatus.includes(selectedInvoiceStatus));
  }, [selectedInvoiceId]);

  useEffect(() => {
    if (selectedInvoiceInfo.length) {
      let temp = cloneDeep(selectedInvoiceInfo);
      setReceipts(temp);
      selectedInvoiceInfo.forEach((element) => {
        if (element?.description) {
          return setDescription(element?.description);
        }
      });
    } else {
      setReceipts([
        {
          id: Math.round(Math.random() * 100000),
          referenceNo: '',
          loading: false,
          filePath: '',
          fileName: '',
          amount: 0,
        },
      ]);
    }
  }, [selectedInvoiceInfo]);

  useEffect(() => {
    const checkIsDisable = [];
    receipts.forEach((receipt) => {
      if (
        receipt.amount === '' ||
        receipt.referenceNo === '' ||
        receipt.fileName === ''
      ) {
        checkIsDisable.push(true);
      }
    });
    setIsDisabled(checkIsDisable.some((value) => value === true));
  }, [receipts]);

  const handleCloseModal = () => {
    onClose();
    dispatch(setSelectedInvoiceInfo([]));
    setDescription('');
    setReceipts([
      {
        id: Math.round(Math.random() * 100000),
        referenceNo: '',
        loading: false,
        filePath: '',
        amount: 0,
        fileName: '',
      },
    ]);
  };

  const handleUploadReceipt = () => {
    let tempBody = receipts.map((receipt) => ({
      id: selectedInvoiceInfo.length ? receipt?.id : null,
      referenceNo: receipt.referenceNo,
      amount: receipt.amount,
      fileName: receipt.fileName,
      filePath: receipt?.filePath,
      description,
      crc: selectedInvoiceInfo.length ? receipt?.crc : null,
    }));
    dispatch(
      uploadPaymentReceipt(selectedInvoiceId, tempBody, handleCloseModal)
    );
  };

  return (
    <Modal
      width={650}
      title={'Upload Payment Receipt'}
      centered
      visible={isOpen}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      footer={
        !isViewMode
          ? [
              <Button key="cancel" shape="round" onClick={handleCloseModal}>
                Discard
              </Button>,
              <Button
                key="submit"
                shape="round"
                type="primary"
                loading={loadingUploadReceipts}
                disabled={isDisabled}
                onClick={handleUploadReceipt}
              >
                Submit
              </Button>,
            ]
          : []
      }
    >
      {loadingFetchInvoice ? (
        <Spin
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      ) : (
        <UploadReceiptModalContent
          receipts={receipts}
          setReceipts={setReceipts}
          description={description}
          setDescription={setDescription}
          selectedInvoiceId={selectedInvoiceId}
          isViewMode={isViewMode}
        />
      )}
    </Modal>
  );
};

export default UploadReceiptModal;
