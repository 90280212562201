import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import JourneyAddContainer from './container/JourneyAddContainer';
import JourneyReportContainer from './container/JourneyReportContainer';
import JourneyOverviewContainer from './container/JourneyOverviewContainer';
import JourneyDetailsContainer from './container/JourneyDetailsContainer';
import JourneyEngagedUserContainer from './container/JourneyEngagedUserContainer';

function Journey(props) {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}:id/view`}>
        <JourneyAddContainer type="RELAY" {...props} />
      </Route>
      <Route path={`${match.path}:id/report`}>
        <JourneyReportContainer type="RELAY" {...props} />
      </Route>
      <Route path={`${match.path}:id/overview`}>
        <JourneyOverviewContainer type="RELAY" {...props} />
      </Route>
      <Route path={`${match.path}:id/details`}>
        <JourneyDetailsContainer type="RELAY" {...props} />
      </Route>
      <Route path={`${match.path}:id/users`}>
        <JourneyEngagedUserContainer type="RELAY" {...props} />
      </Route>
    </Switch>
  );
}

export default Journey;
