exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BusinessEventListComponent-module__root--3yvIJ {\n  background-color: white;\n  border-radius: 5px;\n  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);\n}\n.BusinessEventListComponent-module__header--3C4CI {\n  padding: 12px;\n  border-bottom: solid 2px #f4f9ff;\n}\n.BusinessEventListComponent-module__headerTitle--EQJko {\n  color: black;\n  font-size: 16px;\n  font-weight: 600;\n  padding-left: 8px;\n  padding-bottom: 12px;\n}\n.BusinessEventListComponent-module__headerTitleAdd--2wqIS {\n  font-size: 16px;\n  color: #1d88f6 !important;\n  cursor: pointer;\n  margin-left: 16px;\n}\n.BusinessEventListComponent-module__headerSearch--1m-WW {\n  width: 300px !important;\n  margin-right: 30px;\n  border-radius: 5px !important;\n  padding-right: 12px;\n}\n.BusinessEventListComponent-module__inputLoadingSpin--24go6 {\n  align-items: center;\n  justify-content: center;\n  display: flex;\n}\n.BusinessEventListComponent-module__loadingSpin--3OPt3 {\n  height: 180px;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  padding-top: 10;\n  padding-bottom: 10;\n}\n#business_event_list .ant-table-row.ant-table-row-level-1 .ant-table-cell-fix-left-last > div {\n  visibility: hidden !important;\n}\n", ""]);

// exports
exports.locals = {
	"root": "BusinessEventListComponent-module__root--3yvIJ",
	"header": "BusinessEventListComponent-module__header--3C4CI",
	"headerTitle": "BusinessEventListComponent-module__headerTitle--EQJko",
	"headerTitleAdd": "BusinessEventListComponent-module__headerTitleAdd--2wqIS",
	"headerSearch": "BusinessEventListComponent-module__headerSearch--1m-WW",
	"inputLoadingSpin": "BusinessEventListComponent-module__inputLoadingSpin--24go6",
	"loadingSpin": "BusinessEventListComponent-module__loadingSpin--3OPt3"
};