import React from 'react';
import CommunicationFooterComponent from '../CommunicationFooterComponent';
import { journeySteps } from '../../../../../utils/CommunicationSteps';
import { useSelector } from 'react-redux';

const TrackingFooter = ({
  readOnly,
  journey,
  journeySteps: journeyStp,
  communicationHasJourneyType,
  handleClickNext,
  handleClickBack,
  isEdited,
  isEditDisable,
}) => {
  let steps;
  if (journey) {
    steps = journeyStp;
  } else if (communicationHasJourneyType()) {
    steps = journeySteps;
  }

  const loadingUpdateConversionSubmit = useSelector(
    (state) => state.engage.communication.loadingUpdateConversionSubmit
  );

  return (
    <CommunicationFooterComponent
      step={'conversion'}
      isEdited={isEdited}
      steps={steps}
      loadingNext={loadingUpdateConversionSubmit}
      journey={journey}
      readOnly={readOnly || isEditDisable()}
      handleClickNext={handleClickNext}
      handleClickBack={handleClickBack}
    />
  );
};

export default TrackingFooter;
