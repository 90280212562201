import {
  // Checkbox, Dropdown, Menu, message,
  Radio,
} from 'antd';
import styles from './ReportChartSection.module.less';
import {
  // MoreOutlined,
  LineChartOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import { Line, Column } from '@ant-design/plots';

import React, { useState } from 'react';

const ReportChart = ({ cardTitle, config }) => {
  const [chartType, setChartType] = useState('BAR');
  // const menu = (
  //   <Menu>
  //     <div className={styles.checkListMenuWrapper}>
  //       <Menu selectable={false} className={styles.checkListMenu}>
  //         {/* {cardMetrics[cardTitle].map((metric, index) => ( */}
  //         <Menu.Item>
  //           <Checkbox
  //             style={{ width: 200 }}
  //             // onChange={(e) => handleChangeMetrics(e, metric)}
  //             // checked={isMetricChecked(metric)}
  //           >
  //             HEHE
  //           </Checkbox>
  //         </Menu.Item>
  //         {/* ))} */}
  //       </Menu>
  //     </div>
  //   </Menu>
  // );

  const options = [
    { label: <LineChartOutlined />, value: 'LINE' },
    { label: <BarChartOutlined />, value: 'BAR' },
  ];

  return (
    <>
      <div className={styles.reportCardWrapper}>
        <div className={styles.reportCardHeader}>
          <div className={styles.reportCardTitle}>{cardTitle}</div>
          {/* <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            <MoreOutlined style={{ fontSize: '16px', color: '#767577' }} />
          </Dropdown> */}
          <Radio.Group
            options={options}
            onChange={(e) => {
              setChartType(e.target.value);
            }}
            value={chartType}
            optionType="button"
          />
        </div>
        <div className={styles.reportCardContent}>
          {chartType === 'LINE' ? <Line {...config} /> : <Column {...config} />}
          {/* <DualAxes {...config} />; */}
        </div>
      </div>
    </>
  );
};

export default ReportChart;
