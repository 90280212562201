import BaseApi from '../../../api/BaseApi';

const basePath = '/admin/releaseNotes';
class ReleaseNoteApi {
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static create(body) {
    return BaseApi.post(basePath + '/create', body);
  }

  static update(id) {
    return BaseApi.post(basePath + '/update/' + id, {});
  }
  static updateSubmit(id, body) {
    return BaseApi.post(basePath + '/updateSubmit/' + id, body);
  }

  static delete(id) {
    return BaseApi.post(basePath + '/delete/' + id, {});
  }
}

export default ReleaseNoteApi;
