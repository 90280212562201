import { createSlice } from '@reduxjs/toolkit';
import { showMessageError } from '@Utils/RenderUtils';
import ContractApi from './adminProductContractApi';
import toastr from 'toastr';

const initialState = {
  archivedContractList: [],
  loaingList: false,
  loading: false,
  createLoading: false,
  activeContract: {},
  errorsContract: {},
  currentContract: {},
  loadingContract: false,
};

export const contracts = createSlice({
  name: 'contractTemplate',
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
      state.loaingList = payload;
    },
    archivedContractListFetchSuccess(state, { payload }) {
      state.archivedContractList = payload;
    },
    contractCreateFetchStart(state) {
      state.createLoading = true;
    },
    contractCreateFetchSuccess(state) {
      state.createLoading = false;

      toastr.success('Has been created successfully');
    },
    contractCreateFetchFail(state, { payload }) {
      state.errorsContract = payload;
      state.createLoading = false;

      showMessageError(payload);
    },
    contractViewActiveFetchSuccess(state, { payload }) {
      state.activeContract = payload;
      state.loading = false;
    },
    contractViewFetchStart(state) {
      state.loadingContract = true;
    },
    contractViewFetchSuccess(state, { payload }) {
      state.loadingContract = false;
      state.currentContract = payload;
    },
    contractViewFetchFail(state) {
      state.loadingContract = false;
    },
    contractUpdateSubmitFetchSuccess() {
      toastr.success('Has been edited successfully');
    },
    contractCleareActiveContract(state) {
      state.activeContract = {};
    },
    cleareCurrentContract(state) {
      state.currentContract = {};
    },
    contractUpdateSubmitFetchFail(state, { payload }) {
      state.errorsContract = payload;
      showMessageError(payload);
    },
    clearErrorsContract(state, { payload = null }) {
      if (payload === null) {
        state.errorsContract = {};
      } else {
        state.errorsContract.errors = state.errorsContract.errors.filter(
          (arrow) => arrow.key !== payload
        );
      }
    },
    contractDeleteStart(state) {
      state.loaingList = true;
    },
    contractDeleteSuccess(state) {
      state.loaingList = false;
      toastr.success('Has Deleted successfully');
    },
    contractDeleteFail(state, { payload }) {
      state.loaingList = false;

      showMessageError(payload);
    },
  },
});

export default contracts.reducer;

export const {
  archivedContractListFetchSuccess,
  setLoading,
  contractCreateFetchStart,
  contractCreateFetchSuccess,
  contractCreateFetchFail,
  contractViewActiveFetchSuccess,
  contractUpdateSubmitFetchSuccess,
  contractUpdateSubmitFetchFail,
  contractCleareActiveContract,
  clearErrorsContract,
  contractViewFetchStart,
  contractViewFetchSuccess,
  contractViewFetchFail,
  contractDeleteStart,
  contractDeleteSuccess,
  contractDeleteFail,
  cleareCurrentContract,
} = contracts.actions;

export const archivedContractListFetch = (body) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await ContractApi.list(body);
    dispatch(archivedContractListFetchSuccess(response));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(setLoading(false));
    showMessageError(e);
  }
};

export const contractCreateFetch = (contract) => async (dispatch) => {
  dispatch(contractCreateFetchStart());
  try {
    const response = await ContractApi.create(contract);
    dispatch(contractCreateFetchSuccess(response));
    const list = await ContractApi.list({});
    dispatch(archivedContractListFetchSuccess(list));
  } catch (e) {
    dispatch(contractCreateFetchFail(e));
    showMessageError(e);
  }
};

export const contractViewActiveFetch = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await ContractApi.viewActive();
    dispatch(contractViewActiveFetchSuccess(response));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(setLoading(false));
    showMessageError(e);
  }
};
export const contractViewFetch = (id) => async (dispatch) => {
  dispatch(contractViewFetchStart(true));
  try {
    const response = await ContractApi.view(id);
    dispatch(contractViewFetchSuccess(response));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(contractViewFetchFail(false));
    showMessageError(e);
  }
};

export const contractUpdateSubmitFetch = (id, contract) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await ContractApi.updateSubmit(id, contract);
    dispatch(contractUpdateSubmitFetchSuccess(response));
    const active = await ContractApi.viewActive();
    dispatch(contractViewActiveFetchSuccess(active));
    const list = await ContractApi.list({});
    dispatch(archivedContractListFetchSuccess(list));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(contractUpdateSubmitFetchFail(e));
    dispatch(setLoading(false));
    showMessageError(e);
  }
};

export const contractDeleteFetch = (id) => async (dispatch) => {
  dispatch(contractDeleteStart());
  try {
    const response = await ContractApi.delete(id);
    dispatch(contractDeleteSuccess(response));
    const list = await ContractApi.list({});
    dispatch(archivedContractListFetchSuccess(list));
  } catch (e) {
    dispatch(contractDeleteFail(e));
    showMessageError(e);
  }
};
