const EmailProvider = {
  MANDRILL: 'Mandrill',
  SENDMOST: 'SendMost',
  SMTP: 'SMTP',
  PRIVATE: 'Private',
};

export default EmailProvider;

export const InheritableEmailProviders = {
  NO_DELIVERY: 'No Delivery',
  MANDRILL: 'Mandrill',
  SENDMOST: 'SendMost',
};
