import React from 'react';
import PropTypes from 'prop-types';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashCloneDeep from 'lodash/cloneDeep';
import lodashFind from 'lodash/find';
import lodashIsNil from 'lodash/isNil';
import { Divider, Input, Menu, Empty } from 'antd';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import _ from 'lodash';
import RichEditor from '@Component/form/RichEditor';
import { getActiveProductDetails } from '@Utils/AuthorityProvider';
class InputSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 'first',
      attributes: [],
      events: [],
      showEmojiPicker: false,
      showAttributePicker: false,
      attributesType: 'default',
    };
  }

  componentDidMount() {
    if (this.props.journeySteps) {
      this.setState({ journeySteps: lodashCloneDeep(this.props.journeySteps) });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.attributes ||
        (prevProps.attributes && lodashIsEmpty(prevProps.attributes))) &&
      this.props.attributes &&
      Array.isArray(this.props.attributes) &&
      this.props.attributes.length > 0
    ) {
      this.setState({ attributes: lodashCloneDeep(this.props.attributes) });
    }
    if (
      (!prevProps.events ||
        (prevProps.events && lodashIsEmpty(prevProps.events))) &&
      this.props.events &&
      Array.isArray(this.props.events) &&
      this.props.events.length > 0
    ) {
      this.setState({ events: lodashCloneDeep(this.props.events) });
    }

    if (
      this.props.journeySteps &&
      this.props.journeySteps?.length !== prevProps.journeySteps?.length
    ) {
      this.setState({ journeySteps: lodashCloneDeep(this.props.journeySteps) });
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.state.hideEmojiPicker) {
      document.addEventListener(
        'mousedown',
        this.handleClickEmojiPicker,
        false
      );
    }
    document.addEventListener(
      'mousedown',
      this.handleClickAttributePicker,
      false
    );
  }

  componentWillUnmount() {
    if (!this.state.hideEmojiPicker) {
      document.removeEventListener(
        'mousedown',
        this.handleClickEmojiPicker,
        false
      );
    }
    document.removeEventListener(
      'mousedown',
      this.handleClickAttributePicker,
      false
    );
  }

  handleClickEmojiPicker = (e) => {
    if (this.emojiNode && this.emojiNode.contains(e.target)) {
      return;
    }
    this.setState({ showEmojiPicker: false });
  };

  handleClickAttributePicker = (e) => {
    if (this.attributeNode && this.attributeNode.contains(e.target)) {
      return;
    }
    this.setState({ showAttributePicker: false });
  };

  handleChangeAttributeSearch = (value) => {
    let attributes = this.props.attributes;
    if (value && value.trim()) {
      const condition = new RegExp(value, 'i');
      attributes = this.props.attributes.filter((name) => {
        return condition.test(name.title) || condition.test(name.name);
      });
    }
    this.setState({ attributes });
  };

  handleChangeJourneySearch = (value) => {
    let journeySteps = this.props.journeySteps;
    if (value && value.trim()) {
      const condition = new RegExp(value, 'i');
      journeySteps = this.props.journeySteps.filter((step) => {
        return condition.test(step.ui?.label) || condition.test(step.type);
      });
    }
    this.setState({ journeySteps });
  };

  handleChangeEventSearch = (value) => {
    let events = this.props.events;
    if (value && value.trim()) {
      const condition = new RegExp(value, 'i');
      events = this.props.events.filter((name) => {
        return condition.test(name.title) || condition.test(name.name);
      });
    }
    this.setState({ events });
  };

  handleChangeVariablesSearch = (value) => {
    let variables = this.state.variablesList;
    if (value && value.trim()) {
      const condition = new RegExp(value, 'i');
      variables = this.state.variablesList.filter((name) => {
        return condition.test(name.title) || condition.test(name.name);
      });
    }
    this.setState({ variables });
  };

  handleChangeVariablesApiSearch = () => {};

  handleToggleAttributePicker = () => {
    if (this.state.showAttributePicker) {
      this.setState({ showAttributePicker: false });
    } else {
      this.setState({
        showAttributePicker: true,
        attributes: this.props.attributes,
      });
    }
  };

  goBack = () => {
    this.setState({
      activePage: 'first',
      events: this.props.events,
      attributes: this.props.attributes,
      attributesType: 'defult',
    });
  };

  goBackToEvent = () => {
    this.setState({
      activePage: 'events',
      selectedEvent: null,
      events: this.props.events,
    });
  };

  goBackToJourneySteps = () => {
    this.setState({
      activePage: 'journey',
      selectedJourneyStep: null,
      journeySteps: this.props.journeySteps,
    });
  };

  selectAttribute = (str, overrideChange) => {
    let type = 'emoji';
    const foundItem = lodashFind(
      this.props.attributes,
      (item) => item.name === str
    );
    if (foundItem && foundItem.system) {
      type = 'system';
    } else if (foundItem && !foundItem.system) {
      type = 'custom';
    }
    if (type === 'system') {
      this.appendMessage(
        '{{user["system"]["' + str + '"]}}',
        false,
        overrideChange
      );
    } else if (type === 'emoji') {
      this.appendMessage(str, false, overrideChange);
    } else if (type === 'custom') {
      this.appendMessage(
        '{{user["custom"]["' + str + '"]}}',
        false,
        overrideChange
      );
    }
  };

  // selectEvents = (event) => {
  //   this.setState({ activePage: 'eventAttributes', selectedEvent: event });
  // };

  selectJourney = (journeyStepId) => {
    const journeyStepSelected = lodashFind(
      this.props.journeySteps,
      (o) => o.id === Number(journeyStepId)
    );
    if (journeyStepSelected.type === 'CALL_API') {
      let resp = journeyStepSelected.ui.response;
      try {
        if (!_.isObject(resp)) {
          resp = JSON.parse(resp);
        }
      } catch (e) {
        console.log('error-parse-----', e);
      }
      if (_.isObject(resp)) {
        const variableResponse = Object.keys(resp).map((key) => {
          return {
            key,
            value: key, //journeyStepSelected.ui.response[key],
            over: JSON.stringify(resp[key]),
          };
        });
        this.setState({
          activePage: 'journeyVariablesCallApi',
          selectedJourneyStep: journeyStepId,
          variablesCallApi: variableResponse,
        });
      } else {
        this.setState({
          activePage: 'journeyVariablesCallApi',
          selectedJourneyStep: journeyStepId,
          variablesCallApi: [],
        });
      }
    } else if (journeyStepSelected.type === 'BUSINESS_EVENT_OCCURRENCE') {
      this.setState({
        activePage: 'business-attributes',
      });
    } else {
      const foundItem = lodashFind(
        this.props.events,
        (o) => o.id === Number(journeyStepSelected.event)
      );
      const list =
        foundItem && foundItem.attributes && Array.isArray(foundItem.attributes)
          ? foundItem.attributes
          : [];
      this.setState({
        activePage: 'journeyVariables',
        selectedJourneyStep: journeyStepId,
        variables: list,
        variablesList: list,
      });
    }
  };

  selectJourneyVariableCallApi = (variable, overrideChange) => {
    const foundItem = lodashFind(
      this.props.journeySteps,
      (item) => item.id === Number(this.state.selectedJourneyStep)
    );
    const variable_ = lodashFind(
      this.state.variablesCallApi,
      (item) => item.key === variable
    );
    this.appendMessage(
      `{{journey["state-${foundItem.id}"].api_data["${variable_.key}"]}}`,
      false,
      overrideChange
    );
  };

  selectJourneyVariable = (variable, overrideChange) => {
    const foundItem = lodashFind(
      this.props.journeySteps,
      (item) => item.id === Number(this.state.selectedJourneyStep)
    );
    const variable_ = lodashFind(
      this.state.variables,
      (item) => item.id === Number(variable)
    );
    if (variable_?.system) {
      this.appendMessage(
        `{{journey["state-${foundItem.id}"].system["${variable_.name}"]}}`,
        false,
        overrideChange
      );
    } else {
      this.appendMessage(
        `{{journey["state-${foundItem.id}"].custom["${variable_.name}"]}}`,
        false,
        overrideChange
      );
    }
  };
  selectBusinessEventVariable = (key, overrideChange) => {
    this.appendMessage(
      `{{journey["state-1"].businessEventAttribute["${key}"]}}`,
      false,
      overrideChange
    );
  };

  selectADSAttr = (key, overrideChange) => {
    this.appendMessage(`{{ads["${key}"]}}`, false, overrideChange);
  };
  selectFUNCAttr = (key, overrideChange) => {
    this.setState({ attributesType: 'function' });
    if (key === 'orElse' || key === 'addMinutes') {
      this.appendMessage(`{{${key}(,)}}`, false, overrideChange);
    } else {
      this.appendMessage(`{{${key}()}}`, false, overrideChange);
    }
  };

  selectEventAttributes = (str, overrideChange) => {
    const foundItem = lodashFind(
      this.props.events,
      (item) => item.id === this.props.currentEvent
    );
    if (foundItem && foundItem.attributes) {
      const selectedAttribute = lodashFind(
        foundItem.attributes,
        (o) => o.name === str
      );
      if (selectedAttribute && selectedAttribute.system) {
        this.appendMessage(
          '{{event["custom"]["' +
            foundItem.title +
            '"]["system"]["' +
            str +
            '"]}}',

          false,
          overrideChange
        );
      } else if (selectedAttribute && !selectedAttribute.system) {
        this.appendMessage(
          '{{event["custom"]["' +
            foundItem.title +
            '"]["custom"]["' +
            str +
            '"]}}',
          false,
          overrideChange
        );
      }
    }
  };

  appendMessage = (str, saveCursorPosition, overrideChange) => {
    if (_.isFunction(overrideChange)) {
      overrideChange(str);
    } else {
      const element = document.getElementById(
        this.props.id ? this.props.id : 'description-text-area'
      );
      const cursorStart = element.selectionStart;
      const cursorEnd = element.selectionEnd;
      if (lodashIsNil(this.props.value) || lodashIsEmpty(this.props.value)) {
        this.props.onChange(str);
      } else {
        this.props.onChange(
          this.props.value.slice(0, cursorStart) +
            str +
            this.props.value.slice(cursorEnd)
        );
      }
      this.setState({ showAttributePicker: false, showEmojiPicker: false });
      if (!this.props.funcPopulate) {
        element.focus();
      }
      if (saveCursorPosition) {
        setTimeout(() => {
          element.setSelectionRange(cursorStart, cursorStart + 1);
        }, 500);
      }
    }
  };

  renderAttributesSelector = (style, overrideChange) => {
    if (this.props.readOnly) return;
    const attributesNodes = this.state.attributes.map((name) => {
      return <Menu.Item key={name.name}>{name.title}</Menu.Item>;
    });
    const eventsNodes = this.state.events.map((name) => {
      return <Menu.Item key={name.title}>{name.title}</Menu.Item>;
    });

    const journeySteps =
      this.state.journeySteps &&
      this.state.journeySteps.map((step) => {
        return (
          <Menu.Item key={step.id} title={step.over}>
            <span className="input-selector-step">[STATE-{step.id}]</span>{' '}
            {step.ui.label}
          </Menu.Item>
        );
      });
    const variables =
      this.state.variables &&
      this.state.variables.map((step) => {
        return <Menu.Item key={step.id}>{step.title}</Menu.Item>;
      });

    const variablesCallApi =
      this.state.variablesCallApi &&
      this.state.variablesCallApi.map((step) => {
        return (
          <Menu.Item key={step.key} title={step.over}>
            {step.value}
          </Menu.Item>
        );
      });

    let eventAttributesNodes = [];
    let eventAttributes = [];
    let businessEventAttributes = [];
    let businessEventAttributesNodes = [];
    // if (this.state.selectedEvent) {
    //   const foundItem = lodashFind(
    //     this.props.events,
    //     (o) => o.title === this.state.selectedEvent
    //   );
    //   if (foundItem && foundItem.attributes) {
    //     eventAttributes = lodashCloneDeep(foundItem.attributes);
    //     eventAttributesNodes = foundItem.attributes.map((name) => {
    //       return <Menu.Item key={name.name}>{name.title}</Menu.Item>;
    //     });
    //   }
    //   // if (foundItem && (!foundItem.attributes || (foundItem.attributes && lodashIsEmpty(foundItem.attributes)))) {

    //   // }
    // }
    if (this.props.currentEvent) {
      const foundItem = lodashFind(
        this.props.events,
        (o) => o.id === this.props.currentEvent
      );

      if (foundItem && foundItem.attributes) {
        eventAttributes = lodashCloneDeep(foundItem.attributes);
        eventAttributesNodes = foundItem.attributes.map((name) => {
          return <Menu.Item key={name.name}>{name.title}</Menu.Item>;
        });
      }
      // if (foundItem && (!foundItem.attributes || (foundItem.attributes && lodashIsEmpty(foundItem.attributes)))) {

      // }
    }

    if (
      Array.isArray(this.state.journeySteps) &&
      Array.isArray(this.props.businessEvents)
    ) {
      let businessEventId;
      this.state.journeySteps.forEach((item) => {
        if (item.type === 'BUSINESS_EVENT_OCCURRENCE') {
          businessEventId = item.businessEvent;
        }
      });
      this.props.businessEvents.forEach((item) => {
        if (item.id === businessEventId) {
          businessEventAttributes = item.attributes;
          if (Array.isArray(businessEventAttributes)) {
            businessEventAttributesNodes = businessEventAttributes.map(
              (name) => {
                return <Menu.Item key={name.name}>{name.title}</Menu.Item>;
              }
            );
          }
        }
      });
    }

    const isRelay =
      this.props.journeySteps?.length &&
      this.props.journeySteps.find(
        (item) => item.type === 'BUSINESS_EVENT_OCCURRENCE'
      );

    const adsEnabled = (getActiveProductDetails(window.location.pathname) || {})
      ?.adsEnabled;

    return (
      <div
        ref={(node) => {
          this.attributeNode = node;
        }}
        style={style}
      >
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            width: 200,
            backgroundColor: 'white',
            borderRadius: 5,
          }}
        >
          {this.state.activePage === 'first' && (
            <div>
              <Menu
                onClick={({ key }) => this.setState({ activePage: key })}
                style={{
                  height: this.props.journeySteps?.length ? 160 : 105,
                  overflow: 'scroll',
                }}
              >
                <Menu.Item key="attributes">
                  User Attributes
                  <i
                    style={{
                      fontSize: 25,
                      position: 'absolute',
                      top: 6,
                      right: 5,
                    }}
                    className="fl-dark fl-chevron-right"
                  ></i>
                </Menu.Item>
                {this.props.currentType === 'TRIGGERED' && (
                  <Menu.Item key="eventAttributes">
                    Event Attribiutes
                    <i
                      style={{
                        fontSize: 25,
                        position: 'absolute',
                        top: 6,
                        right: 5,
                      }}
                      className="fl-dark fl-chevron-right"
                    ></i>
                  </Menu.Item>
                )}

                {adsEnabled ? (
                  <Menu.Item key="ads">
                    ADS
                    <i
                      style={{
                        fontSize: 25,
                        position: 'absolute',
                        top: 6,
                        right: 5,
                      }}
                      className="fl-dark fl-chevron-right"
                    ></i>
                  </Menu.Item>
                ) : null}
                {!this.props.hideFunctionPopulate && (
                  <Menu.Item key="functions">
                    Functions
                    <i
                      style={{
                        fontSize: 25,
                        position: 'absolute',
                        top: 6,
                        right: 5,
                      }}
                      className="fl-dark fl-chevron-right"
                    ></i>
                  </Menu.Item>
                )}

                {this.props.journeySteps?.length ? (
                  <Menu.Item key="journey">
                    {isRelay ? 'Relay' : 'Journey'}
                    <i
                      style={{
                        fontSize: 25,
                        position: 'absolute',
                        top: 6,
                        right: 5,
                      }}
                      className="fl-dark fl-chevron-right"
                    ></i>
                  </Menu.Item>
                ) : (
                  ''
                )}
              </Menu>
            </div>
          )}
          {this.state.activePage === 'attributes' && (
            <div>
              <Menu
                onClick={() => this.goBack()}
                style={{ height: 45, backgroundColor: '#f5f5f6' }}
              >
                <Menu.Item key="back">
                  <i
                    style={{
                      fontSize: 25,
                      position: 'relative',
                      top: 6,
                      right: 7,
                    }}
                    className="fl-dark fl-chevron-left"
                  ></i>
                  Back
                </Menu.Item>
              </Menu>
              <div style={{ padding: 10 }}>
                <Input
                  placeholder="Search..."
                  allowClear
                  onChange={(e) => {
                    this.handleChangeAttributeSearch(e.target.value);
                  }}
                />
              </div>
              <Divider style={{ margin: '5px 0 0 0' }} />
              <Menu
                onClick={({ key }) => {
                  this.selectAttribute(key, overrideChange);
                }}
                style={{ height: 200, overflow: 'scroll' }}
              >
                {this.state.attributes.length > 0 ? (
                  attributesNodes
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Menu>
            </div>
          )}
          {false && this.state.activePage === 'events' && (
            <div>
              <Menu
                onClick={() => this.goBack()}
                style={{ height: 45, backgroundColor: '#f5f5f6' }}
              >
                <Menu.Item key="back">
                  <i
                    style={{
                      fontSize: 25,
                      position: 'relative',
                      top: 6,
                      right: 7,
                    }}
                    className="fl-dark fl-chevron-left"
                  ></i>
                  Back
                </Menu.Item>
              </Menu>
              <div style={{ padding: 10 }}>
                <Input
                  placeholder="Search..."
                  allowClear
                  onChange={(e) => this.handleChangeEventSearch(e.target.value)}
                />
              </div>
              <Divider style={{ margin: '5px 0 0 0' }} />
              <Menu
                onClick={({ key }) => this.selectEvents(key)}
                style={{ height: 200, overflow: 'scroll' }}
              >
                {this.state.events.length > 0 ? (
                  eventsNodes
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Menu>
            </div>
          )}
          {this.state.activePage === 'eventAttributes' && (
            <div>
              <Menu
                onClick={() => this.goBack()}
                style={{ height: 45, backgroundColor: '#f5f5f6' }}
              >
                <Menu.Item key="back">
                  <i
                    style={{
                      fontSize: 25,
                      position: 'relative',
                      top: 6,
                      right: 7,
                    }}
                    className="fl-dark fl-chevron-left"
                  ></i>
                  Back
                </Menu.Item>
              </Menu>
              {false && (
                <div style={{ padding: 10 }}>
                  <Input
                    placeholder="Search..."
                    allowClear
                    onChange={(e) =>
                      this.handleChangeEventSearch(e.target.value)
                    }
                  />
                </div>
              )}
              <Divider style={{ margin: '5px 0 0 0' }} />
              <Menu
                onClick={({ key }) => {
                  this.selectEventAttributes(key, overrideChange);
                }}
                style={{ height: 200, overflow: 'scroll' }}
              >
                {eventAttributes.length > 0 ? (
                  eventAttributesNodes
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Menu>
            </div>
          )}
          {this.state.activePage === 'business-attributes' && (
            <div>
              <Menu
                onClick={() => this.goBack()}
                style={{ height: 45, backgroundColor: '#f5f5f6' }}
              >
                <Menu.Item key="back">
                  <i
                    style={{
                      fontSize: 25,
                      position: 'relative',
                      top: 6,
                      right: 7,
                    }}
                    className="fl-dark fl-chevron-left"
                  ></i>
                  Back
                </Menu.Item>
              </Menu>
              {false && (
                <div style={{ padding: 10 }}>
                  <Input
                    placeholder="Search..."
                    allowClear
                    // onChange={(e) =>
                    //   this.handleChangeEventSearch(e.target.value)
                    // }
                  />
                </div>
              )}
              <Divider style={{ margin: '5px 0 0 0' }} />
              <Menu
                onClick={({ key }) => {
                  this.selectBusinessEventVariable(key, overrideChange);
                }}
                style={{ height: 200, overflow: 'scroll' }}
              >
                {businessEventAttributes.length > 0 ? (
                  businessEventAttributesNodes
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Menu>
            </div>
          )}
          {this.state.activePage === 'journey' ? (
            <div>
              <Menu
                onClick={() => this.goBack()}
                style={{ height: 45, backgroundColor: '#f5f5f6' }}
              >
                <Menu.Item key="back">
                  <i
                    style={{
                      fontSize: 25,
                      position: 'relative',
                      top: 6,
                      right: 7,
                    }}
                    className="fl-dark fl-chevron-left"
                  ></i>
                  Back
                </Menu.Item>
              </Menu>
              <div style={{ padding: 10 }}>
                <Input
                  placeholder="Search..."
                  allowClear
                  onChange={(e) =>
                    this.handleChangeJourneySearch(e.target.value)
                  }
                />
              </div>
              <Divider style={{ margin: '5px 0 0 0' }} />
              <Menu
                onClick={({ key }) => this.selectJourney(key)}
                style={{ height: 200, overflow: 'scroll' }}
              >
                {this.state.journeySteps &&
                this.state.journeySteps.length > 0 ? (
                  journeySteps
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Menu>
            </div>
          ) : (
            ''
          )}
          {this.state.activePage === 'journeyVariables' && (
            <div>
              <Menu
                onClick={() => this.goBackToJourneySteps()}
                style={{ height: 45, backgroundColor: '#f5f5f6' }}
              >
                <Menu.Item key="back">
                  <i
                    style={{
                      fontSize: 25,
                      position: 'relative',
                      top: 6,
                      right: 7,
                    }}
                    className="fl-dark fl-chevron-left"
                  ></i>
                  Back to events
                </Menu.Item>
              </Menu>
              {
                <div style={{ padding: 10 }}>
                  <Input
                    placeholder="Search..."
                    allowClear
                    onChange={(e) =>
                      this.handleChangeVariablesSearch(e.target.value)
                    }
                  />
                </div>
              }
              <Divider style={{ margin: '5px 0 0 0' }} />
              <Menu
                onClick={({ key }) => {
                  this.selectJourneyVariable(key, overrideChange);
                }}
                style={{ height: 200, overflow: 'scroll' }}
              >
                {this.state.variables.length > 0 ? (
                  variables
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Menu>
            </div>
          )}
          {this.state.activePage === 'journeyVariablesCallApi' && (
            <div>
              <Menu
                onClick={() => this.goBackToJourneySteps()}
                style={{ height: 45, backgroundColor: '#f5f5f6' }}
              >
                <Menu.Item key="back">
                  <i
                    style={{
                      fontSize: 25,
                      position: 'relative',
                      top: 6,
                      right: 7,
                    }}
                    className="fl-dark fl-chevron-left"
                  ></i>
                  Back to events
                </Menu.Item>
              </Menu>
              {false && (
                <div style={{ padding: 10 }}>
                  <Input
                    placeholder="Search..."
                    allowClear
                    onChange={(e) =>
                      this.handleChangeVariablesApiSearch(e.target.value)
                    }
                  />
                </div>
              )}
              <Divider style={{ margin: '5px 0 0 0' }} />
              <Menu
                onClick={({ key }) => {
                  this.selectJourneyVariableCallApi(key, overrideChange);
                }}
                style={{ height: 200, overflow: 'scroll' }}
              >
                {this.state.variablesCallApi.length > 0 ? (
                  variablesCallApi
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Menu>
            </div>
          )}

          {this.state.activePage === 'ads' && (
            <div>
              <Menu
                onClick={() => this.goBack()}
                style={{ height: 45, backgroundColor: '#f5f5f6' }}
              >
                <Menu.Item key="back">
                  <i
                    style={{
                      fontSize: 25,
                      position: 'relative',
                      top: 6,
                      right: 7,
                    }}
                    className="fl-dark fl-chevron-left"
                  ></i>
                  Back
                </Menu.Item>
              </Menu>
              <Menu
                onClick={({ key }) => {
                  this.selectADSAttr(key, overrideChange);
                }}
                style={{ height: 200, overflow: 'scroll' }}
              >
                {[
                  'primaryYektanetId',
                  'secondaryYektanetId',
                  'primaryMediaadId',
                  'secondaryMediaadId',
                  'advertisingId',
                ].map((name) => {
                  return <Menu.Item key={name}>{name}</Menu.Item>;
                })}
              </Menu>
            </div>
          )}
          {this.state.activePage === 'functions' && (
            <div>
              <Menu
                onClick={() => this.goBack()}
                style={{ height: 45, backgroundColor: '#f5f5f6' }}
              >
                <Menu.Item key="back">
                  <i
                    style={{
                      fontSize: 25,
                      position: 'relative',
                      top: 6,
                      right: 7,
                    }}
                    className="fl-dark fl-chevron-left"
                  ></i>
                  Back
                </Menu.Item>
              </Menu>
              <Menu
                onClick={({ key }) => {
                  this.selectFUNCAttr(key, overrideChange);
                }}
                style={{ height: 200, overflow: 'scroll' }}
              >
                {[
                  'urlEncode',
                  'ceil',
                  'floor',
                  'jalaliDate',
                  'jalaliDateTime',
                  'gregorianDate',
                  'gregorianDateTime',
                  'orElse',
                  'addMinutes',
                  'now',
                ].map((name) => {
                  return <Menu.Item key={name}>{name}</Menu.Item>;
                })}
              </Menu>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    if (this.props.isRich) {
      return (
        <RichEditor
          readOnly={this.props.disabled}
          value={this.props.value}
          placeholder={this.props.placeholder ? this.props.placeholder : ''}
          renderAttributesSelector={
            !this.props.hideAttributePicker && this.renderAttributesSelector
          }
          onChange={(value) => {
            if (this.props.readOnly) return;
            this.props.onChange(value.trim());
          }}
          type={this.props.type}
          className={this.props.className}
          style={this.props.style}
          index={this.props.index}
        />
      );
    }
    if (this.props.type === 'richInline') {
      return (
        <RichEditor
          readOnly={this.props.disabled}
          value={this.props.value}
          placeholder={this.props.placeholder ? this.props.placeholder : ''}
          renderAttributesSelector={
            !this.props.hideAttributePicker && this.renderAttributesSelector
          }
          onChange={(value) => {
            if (this.props.readOnly) return;
            this.props.onChange(value.trim());
          }}
          type={this.props.type}
          hideEmojiPicker={this.props.hideEmojiPicker}
          index={this.props.index}
          onChangeButton={this.props.onChangeButton}
          className={this.props.className}
          style={this.props.style}
        />
      );
    }
    if (this.props.textArea) {
      return (
        <div style={{ position: 'relative', display: 'flex' }}>
          {this.props.type === 'richTextArea' ? (
            <RichEditor
              readOnly={this.props.disabled}
              value={this.props.value}
              placeholder={this.props.placeholder ? this.props.placeholder : ''}
              renderAttributesSelector={
                !this.props.hideAttributePicker && this.renderAttributesSelector
              }
              onChange={(value) => {
                if (this.props.readOnly) return;
                this.props.onChange(value.trim());
              }}
              type={this.props.type}
              hideEmojiPicker={this.props.hideEmojiPicker}
              index={this.props.index}
              className={this.props.className}
              style={this.props.style}
            />
          ) : (
            <Input.TextArea
              disabled={this.props.disabled}
              readOnly={this.props.readOnly}
              id={this.props.id ? this.props.id : 'description-text-area'}
              className={'not-resizable'}
              dir={'auto'}
              style={this.props.style ? this.props.style : {}}
              autoSize={
                this.props.autoSize
                  ? this.props.autoSize
                  : { maxRows: 3, minRows: 3 }
              }
              rows={this.props.row ? this.props.row : 3}
              value={this.props.value}
              placeholder={this.props.placeholder ? this.props.placeholder : ''}
              onChange={(e) => {
                if (this.props.readOnly) return;
                this.props.onChange(e.target.value);
              }}
              size={this.props.size ? this.props.size : 'large'}
            />
          )}
          {this.props.type !== 'richTextArea' && (
            <span
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                paddingRight: 10,
              }}
            >
              {!this.props.hideEmojiPicker && (
                <i
                  onClick={() => {
                    if (!this.props.disabled) {
                      this.setState({
                        showEmojiPicker: !this.state.showEmojiPicker,
                      });
                    }
                  }}
                  className="fl-emoji fl-dark input-suffix-icon"
                ></i>
              )}
              <i
                onClick={() => {
                  if (!this.props.disabled) {
                    this.handleToggleAttributePicker();
                  }
                }}
                className="fl-dark fl-user input-suffix-icon"
              ></i>
              {!this.props.hideEmojiPicker && this.state.showEmojiPicker && (
                <div
                  ref={(node) => (this.emojiNode = node)}
                  style={
                    this.props.emojiStyle
                      ? this.props.emojiStyle
                      : { position: 'relative', top: '-7px', right: '320px' }
                  }
                >
                  <Picker
                    set="apple"
                    style={{
                      position: 'absolute',
                      zIndex: 1,
                      border: '1px solid #FFFFFF',
                      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                    }}
                    color="#fe5196"
                    showPreview={false}
                    showSkinTones={false}
                    onSelect={(emoji) => this.selectAttribute(emoji.native)}
                  />
                </div>
              )}
              {this.state.showAttributePicker &&
                this.renderAttributesSelector(this.props.attributeStyle)}
            </span>
          )}
        </div>
      );
    }
    return (
      <React.Fragment>
        <Input
          data-cy={this.props.dataCy}
          disabled={this.props.disabled}
          readOnly={this.props.readOnly}
          id={this.props.id ? this.props.id : 'description-text-area'}
          value={this.props.value}
          dir={'auto'}
          style={this.props.style ? this.props.style : {}}
          onChange={(e) => {
            if (this.props.readOnly) return;
            this.props.onChange(e.target.value);
          }}
          size={this.props.size ? this.props.size : 'large'}
          className={this.props.className ? this.props.className : ''}
          placeholder={this.props.placeholder ? this.props.placeholder : ''}
          suffix={
            <span>
              {!this.props.hideEmojiPicker && (
                <i
                  onClick={() => {
                    if (!this.props.disabled) {
                      this.setState({
                        showEmojiPicker: !this.state.showEmojiPicker,
                      });
                    }
                  }}
                  className="fl-emoji fl-dark input-suffix-icon"
                ></i>
              )}
              <i
                onClick={() => {
                  if (!this.props.disabled) {
                    this.handleToggleAttributePicker();
                  }
                }}
                className="fl-dark fl-user input-suffix-icon"
              ></i>
            </span>
          }
        />

        {!this.props.hideEmojiPicker && this.state.showEmojiPicker && (
          <div
            ref={(node) => (this.emojiNode = node)}
            style={
              this.props.emojiStyle
                ? this.props.emojiStyle
                : { position: 'relative', top: '-10px', left: '30px' }
            }
          >
            <Picker
              set="apple"
              style={{
                position: 'absolute',
                zIndex: 1,
                border: '1px solid #FFFFFF',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
              }}
              color="#fe5196"
              showPreview={false}
              showSkinTones={false}
              onSelect={(emoji) => this.selectAttribute(emoji.native)}
            />
          </div>
        )}
        {this.state.showAttributePicker &&
          this.renderAttributesSelector(this.props.attributeStyle)}
      </React.Fragment>
    );
  }
}

export default InputSelector;

InputSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  size: PropTypes.string,
  hideEmojiPicker: PropTypes.bool,
  autoSize: PropTypes.object,
  rows: PropTypes.number,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.object,
  attributeStyle: PropTypes.object,
  emojiStyle: PropTypes.object,
  isRich: PropTypes.bool,
};
