import React, { useEffect } from 'react';
import {
  Select,
  Form,
  DatePicker,
  Timeline,
  Spin,
  Pagination,
  Empty,
} from 'antd';
import moment from 'moment';
import Card from '@Utils/Card';
import './auditLogList.less';
import {
  fetchAuditLoglist,
  fetchAuditTypes,
  fetchAuditUsers,
  modifyPageRequest,
} from '@Redux/slices/setting/auditLogSlice';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { readableDateTime } from '../../../../utils/DateUtils';
import { LoadingOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;

const Loading = () => (
  <div className="audit-log-list__loading-spin">
    <Spin indicator={loadingSpin} />
  </div>
);

const createLink = (id, entity, entityType, productId) => {
  const channelSettingsMap = {
    EMAIL_SETTING: 'email',
    SMS_SETTING: 'sms',
    PUSH_SETTING: 'push',
    EMAIL_TEMPLATE_SETTING: 'email',
    CUSTOM_CHANNEL_SETTING: 'custom',
    FC: 'fc',
    DND: 'dnd',
    REVENUE_MAPPING: 'revenue',
  };

  const lowerEntityType = entityType ? entityType.toLowerCase() : '';
  const channelSetting = channelSettingsMap[entityType] || '';
  const entityChannelSetting = channelSettingsMap[entity] || '';

  let path = `/product/${productId}/users/overview`;

  switch (entity) {
    case 'SEGMENT':
      path = `/product/${productId}/segments/${
        lowerEntityType === 'live' ? lowerEntityType : 'static'
      }/${id}/overview`;
      break;
    case 'CAMPAIGN':
      path = `/product/${productId}/${lowerEntityType}-messages/campaigns/${id}/overview`;
      break;
    case 'JOURNEY':
      path = `/product/${productId}/${lowerEntityType}/${id}/overview`;
      break;
    case 'CHANNEL_SETTING':
      path = `/product/${productId}/channels/${channelSetting}`;
      break;
    case 'FC':
    case 'DND':
      path = `/product/${productId}/settings/${entityChannelSetting}`;
      break;
    case 'REVENUE_MAPPING':
      path = `/product/${productId}/data-management/events/${entityChannelSetting}`;
      break;
  }

  return path;
};

const transformDataModel = (dataModel) => {
  const options = [{ label: 'All Features', value: null }];

  Object.keys(dataModel).forEach((key) => {
    if (!dataModel[key].length) {
      options.push({
        value: `${key}`,
        label: `${key}`,
      });
    } else {
      dataModel[key].forEach((item) => {
        options.push({
          value: `${key}/${item}`,
          label: `${key} (${item})`,
        });
      });
    }
  });

  return options;
};

const AuditLogList = () => {
  const dispatch = useDispatch();
  const { logTypes, pageRequest, list, loading, users } = useSelector(
    (state) => state.setting.audit
  );
  const { productId } = useParams();

  useEffect(() => {
    dispatch(fetchAuditTypes());
    dispatch(fetchAuditUsers());
  }, []);
  useEffect(() => {
    dispatch(fetchAuditLoglist(pageRequest));

    return () => {
      dispatch(
        modifyPageRequest({
          direction: 'ASC',
          entity: null,
          entityType: null,
          intervalEndDate: moment().format('YYYY-MM-DD'),
          intervalStartDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
          page: 0,
        })
      );
    };
  }, [pageRequest]);

  const options = transformDataModel(logTypes);
  const allUsersOption = { label: 'All users', value: null };

  const userOptions = [
    allUsersOption,
    ...users.map((user) => ({
      label: `${user.presentation} (${user.email})`,
      value: user.id,
    })),
  ];

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input?.toLowerCase());

  return (
    <Card>
      <div className="audit-log-list__header">
        <div className="audit-log-list__filter">
          Show Audit log of
          <Select
            showSearch
            filterOption={filterOption}
            defaultValue="All features"
            style={{ width: 200, paddingLeft: 8 }}
            options={options}
            onChange={(value) => {
              const [entity, entityType] = value?.split('/') ?? [null, null];

              dispatch(
                modifyPageRequest({
                  ...pageRequest,
                  entity,
                  entityType,
                  page: 0,
                })
              );
            }}
          />
        </div>
        <div className="audit-log-list__filter">
          For Changes Made by
          <Select
            showSearch
            filterOption={filterOption}
            defaultValue="All users"
            style={{ width: 240, paddingLeft: 8 }}
            options={userOptions}
            onChange={(value) => {
              dispatch(
                modifyPageRequest({ ...pageRequest, userId: value, page: 0 })
              );
            }}
          />
        </div>
        <Form
          name="audit_log_date"
          layout="inline"
          className="audit-log-list__form"
        >
          <Form.Item
            name="date"
            label="Date Range"
            initialValue={[
              moment(pageRequest.intervalStartDate, 'YYYY-MM-DD'),
              moment(pageRequest.intervalEndDate, 'YYYY-MM-DD'),
            ]}
          >
            <DatePicker.RangePicker
              allowClear={false}
              disabledDate={(date) => date && date > moment().startOf('day')}
              value={[
                moment(pageRequest.intervalStartDate, 'YYYY-MM-DD'),
                moment(pageRequest.intervalEndDate, 'YYYY-MM-DD'),
              ]}
              onChange={(val) => {
                dispatch(
                  modifyPageRequest({
                    ...pageRequest,
                    intervalStartDate: val[0].format('YYYY-MM-DD'),
                    intervalEndDate: val[1].format('YYYY-MM-DD'),
                    page: 0,
                  })
                );
              }}
            />
          </Form.Item>
        </Form>
      </div>
      {loading ? (
        <Loading />
      ) : isEmpty(list?.content) ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          <Timeline className="audit-log-list__timeline">
            {list?.content?.map((item, index) => (
              <Timeline.Item key={index}>
                <div className="audit-log-list__timeline-item">
                  <div className="audit-log-list__timeline-time">
                    {readableDateTime(item.modifiedDate)}
                  </div>
                  <div className="audit-log-list__timeline-details">
                    <p>
                      <strong>{item.modifiedByEmail}</strong>
                      {` ${item.action?.toLowerCase()} `}{' '}
                      {item.entityType === 'RELAY'
                        ? 'relay'
                        : item.entity?.toLowerCase()}
                      :{' '}
                      {item.action === 'DELETED' &&
                      item.entity === 'CAMPAIGN' ? (
                        <span>
                          {item.entityTitle || item.entity.toLowerCase()}
                        </span>
                      ) : (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={createLink(
                            item.entityId,
                            item.entity,
                            item.entityType,
                            productId
                          )}
                        >
                          {item.entityTitle || item.entity.toLowerCase()}
                        </a>
                      )}
                    </p>
                  </div>
                </div>
              </Timeline.Item>
            ))}
          </Timeline>
          {!isEmpty(list) && (
            <div className="audit-log-list__pagination">
              <Pagination
                showSizeChanger={false}
                current={list?.number + 1}
                pageSize={list?.size}
                total={list?.totalElements}
                onChange={(val) => {
                  dispatch(
                    modifyPageRequest({ ...pageRequest, page: val - 1 })
                  );
                }}
              />
            </div>
          )}
        </>
      )}
    </Card>
  );
};

export default AuditLogList;
