import JourneyOverviewComponent from '../component/JourneyOverviewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  journeyGetReportOverview,
  journeyReportEnterOrExitReportFetch,
  journeyListTagsFetch,
  journeyReportCommunicationReportFetch,
  journeyViewFetch,
  journeyClearLaunchData,
} from '../JourneyActions';

import { JOURNEY_VIEW_FETCH_SUCCESS } from '../JourneyConstants';
import { revenueMappingUpdateFetch } from '@Redux/slices/data/revenueMappingSlice';

const mapStateToProps = (state) => ({
  loadingOverview: state.engage.journey.loadingOverview,
  overviewData: state.engage.journey.overviewData,
  loadingJourneyCommunicationReport:
    state.engage.journey.loadingJourneyCommunicationReport,
  loadingJourneyEnterOrExit: state.engage.journey.loadingJourneyEnterOrExit,
  journeyCommunicationEnterOrExit:
    state.engage.journey.journeyCommunicationEnterOrExit,
  journeyCommunicationReportData:
    state.engage.journey.journeyCommunicationReportData,
  listTags: state.engage.journey.listTags,
  loadingJourneyDetails: state.engage.journey.loadingJourneyDetails,
  journeyDetails: state.engage.journey.journeyDetails,
  errorJourneyDetails: state.engage.journey.errorJourneyDetails,
  // loadingSplitOverview: state.engage.communication.loadingSplitOverview,
  // loadingListTags: state.engage.communication.loadingListTags,
  // pageRequestOverview: state.engage.communication.pageRequestOverview,
  // pageRequestSplitOverview: state.engage.communication.pageRequestSplitOverview,
  // errorsListTags: state.engage.communication.errorsListTags,
  // errorsOverview: state.engage.communication.errorsOverview,
  // errorsSplitOverview: state.engage.communication.errorsSplitOverview,
  // overview: state.engage.communication.overview,
  // splitOverview: state.engage.communication.splitOverview,
  pathname: state.router.location.pathname,
  currentRevenueMapping: state.dataModule.revenueMapping.currentRevenueMapping,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      journeyReportEnterOrExitReportFetch,
      journeyGetReportOverview,
      journeyListTagsFetch,
      journeyViewFetch,
      journeyReportCommunicationReportFetch,
      revenueMappingUpdateFetch,
      journeyClearLaunchData,
    },
    dispatch
  ),
  clearJourneyData: () => {
    dispatch({
      type: JOURNEY_VIEW_FETCH_SUCCESS,
      payload: {},
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JourneyOverviewComponent);

JourneyOverviewComponent.propTypes = {
  communicationSplitOverviewFetch: PropTypes.func.isRequired,
  communicationOverviewFetch: PropTypes.func.isRequired,
  communicationListTagsFetch: PropTypes.func.isRequired,
  communicationSplitOverviewPageChangeCurrent: PropTypes.func.isRequired,
};
