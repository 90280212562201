import {
  PRODUCT_REST_API_VIEW_FETCH_SUCCESS,
  PRODUCT_REST_API_VIEW_FETCH_FAIL,
  PRODUCT_REST_API_VIEW_FETCH,
  GENERATE_TOKEN_FETCH,
  GENERATE_TOKEN_FETCH_FAIL,
  GENERATE_TOKEN_FETCH_SUCCESS,
  REVOKE_LOADING,
} from './ProductRestApiConstants';

const initialState = {
  currentProductRestApi: {},
  loadingProjectCredentials: false,
  generatedToken: {},
  loadingGeneratedToken: false,
  loadingRevoke: false,
};

export default function ProductRestApiSettingStateReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PRODUCT_REST_API_VIEW_FETCH_SUCCESS:
      return {
        ...state,
        currentProductRestApi: action.payload,
        loadingProjectCredentials: false,
      };
    case PRODUCT_REST_API_VIEW_FETCH:
      return { ...state, loadingProjectCredentials: true };
    case PRODUCT_REST_API_VIEW_FETCH_FAIL:
      return {
        ...state,
        currentProductRestApi: action.payload,
        loadingProjectCredentials: false,
      };

    case GENERATE_TOKEN_FETCH_SUCCESS:
      return {
        ...state,
        generatedToken: action.payload,
        loadingGeneratedToken: false,
      };
    case GENERATE_TOKEN_FETCH:
      return { ...state, loadingGeneratedToken: true };
    case GENERATE_TOKEN_FETCH_FAIL:
      return {
        ...state,
        generatedToken: action.payload,
        loadingGeneratedToken: false,
      };
    case REVOKE_LOADING:
      return {
        ...state,
        loadingRevoke: action.payload,
      };
    default:
      return state;
  }
}
