import React from 'react';
import isNil from 'lodash/isNil';
import findIndex from 'lodash/findIndex';
import clone from 'lodash/clone';
import { Divider, Input, Menu, Empty, Checkbox, Button } from 'antd';
import { renderTag } from '../../utils/RenderUtils';

class TagSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTags: this.props.selectedTags,
      filteredTags: this.props.listTags,
    };
  }

  isChecked = (name) => {
    let isChecked = false;
    if (this.state.selectedTags) {
      isChecked = findIndex(this.state.selectedTags, (o) => o === name) !== -1;
    }
    return isChecked;
  };

  handleCheck = (name) => {
    if (!isNil(this.state.selectedTags)) {
      const foundIndex = findIndex(this.state.selectedTags, (o) => o === name);
      if (foundIndex !== -1) {
        const cloned = clone(this.state.selectedTags);
        cloned.splice(foundIndex, 1);
        this.setState({ selectedTags: cloned });
      } else {
        this.setState({ selectedTags: [...this.state.selectedTags, name] });
      }
    } else {
      this.setState({ selectedTags: [name] });
    }
  };

  handleChangeTagSearch = (value) => {
    let filteredTags = clone(this.props.listTags);
    if (value && value.trim()) {
      const condition = new RegExp(value, 'i');
      filteredTags = this.props.listTags.filter((item) => {
        return condition.test(item.name);
      });
    }
    this.setState({ filteredTags });
  };

  render() {
    return (
      <Menu>
        <div
          style={{
            width: 220,
            backgroundColor: 'white',
            boxShadow:
              '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
            borderRadius: 2,
          }}
        >
          <div style={{ padding: 10 }}>
            <Input
              placeholder="Search..."
              allowClear
              onChange={(e) => this.handleChangeTagSearch(e.target.value)}
            />
          </div>
          <Divider style={{ margin: '5px 0 0 0' }} />
          {this.state.filteredTags &&
          Array.isArray(this.state.filteredTags) &&
          this.state.filteredTags.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <Menu
              selectable={false}
              style={{ maxHeight: 200, overflow: 'scroll' }}
            >
              {this.state.filteredTags.map((item) => (
                <Menu.Item key={item.name}>
                  <Checkbox
                    style={{ width: 200 }}
                    onChange={() => this.handleCheck(item.name)}
                    checked={this.isChecked(item.name)}
                  >
                    {renderTag(item.name, this.state.filteredTags)}
                  </Checkbox>
                </Menu.Item>
              ))}
            </Menu>
          )}
          <Divider style={{ margin: '5px 0 0 0' }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              padding: 10,
            }}
          >
            {false && (
              <Button
                shape="round"
                type="link"
                style={{ marginRight: 20 }}
                size={'small'}
              >
                RESET
              </Button>
            )}
            <Button
              type="primary"
              shape="round"
              size={'small'}
              onClick={() => this.props.onApply(this.state.selectedTags)}
            >
              APPLY
            </Button>
          </div>
        </div>
      </Menu>
    );
  }
}

export default TagSelector;

TagSelector.propTypes = {};
