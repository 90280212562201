export const PRODUCT_CHANGE_CURRENT = 'PRODUCT_CHANGE_CURRENT';

export const PRODUCT_UPDATE_FETCH = 'PRODUCT_UPDATE_FETCH';
export const PRODUCT_UPDATE_FETCH_FAIL = 'PRODUCT_UPDATE_FETCH_FAIL';
export const PRODUCT_UPDATE_FETCH_SUCCESS = 'PRODUCT_UPDATE_FETCH_SUCCESS';

export const PRODUCT_UPDATE_SUBMIT_FETCH = 'PRODUCT_UPDATE_SUBMIT_FETCH';
export const PRODUCT_UPDATE_SUBMIT_FETCH_FAIL =
  'PRODUCT_UPDATE_SUBMIT_FETCH_FAIL';
export const PRODUCT_UPDATE_SUBMIT_FETCH_SUCCESS =
  'PRODUCT_UPDATE_SUBMIT_FETCH_SUCCESS';

export const PRODUCT_LIST_TIMEZONE_FETCH = 'PRODUCT_LIST_TIMEZONE_FETCH';
export const PRODUCT_LIST_TIMEZONE_FETCH_FAIL =
  'PRODUCT_LIST_TIMEZONE_FETCH_FAIL';
export const PRODUCT_LIST_TIMEZONE_FETCH_SUCCESS =
  'PRODUCT_LIST_TIMEZONE_FETCH_SUCCESS';

export const PRODUCT_VIEW_FETCH = 'PRODUCT_VIEW_FETCH';
export const PRODUCT_VIEW_FETCH_FAIL = 'PRODUCT_VIEW_FETCH_FAIL';
export const PRODUCT_VIEW_FETCH_SUCCESS = 'PRODUCT_VIEW_FETCH_SUCCESS';

export const FC_UPDATE_FETCH = 'FC_UPDATE_FETCH';
export const FC_UPDATE_FETCH_FAIL = 'FC_UPDATE_FETCH_FAIL';
export const FC_UPDATE_FETCH_SUCCESS = 'FC_UPDATE_FETCH_SUCCESS';

export const FC_UPDATE_SUBMIT_FETCH = 'FC_UPDATE_SUBMIT_FETCH';
export const FC_UPDATE_SUBMIT_FETCH_FAIL = 'FC_UPDATE_SUBMIT_FETCH_FAIL';
export const FC_UPDATE_SUBMIT_FETCH_SUCCESS = 'FC_UPDATE_SUBMIT_FETCH_SUCCESS';

export const FC_CHANGE_CURRENT = 'FC_CHANGE_CURRENT';

export const THROTTLING_UPDATE_FETCH = 'THROTTLING_UPDATE_FETCH';
export const THROTTLING_UPDATE_FETCH_FAIL = 'THROTTLING_UPDATE_FETCH_FAIL';
export const THROTTLING_UPDATE_FETCH_SUCCESS =
  'THROTTLING_UPDATE_FETCH_SUCCESS';

export const THROTTLING_UPDATE_SUBMIT_FETCH = 'THROTTLING_UPDATE_SUBMIT_FETCH';
export const THROTTLING_UPDATE_SUBMIT_FETCH_FAIL =
  'THROTTLING_UPDATE_SUBMIT_FETCH_FAIL';
export const THROTTLING_UPDATE_SUBMIT_FETCH_SUCCESS =
  'THROTTLING_UPDATE_SUBMIT_FETCH_SUCCESS';

export const THROTTLING_CHANGE_CURRENT = 'THROTTLING_CHANGE_CURRENT';

export const DND_UPDATE_FETCH = 'DND_UPDATE_FETCH';
export const DND_UPDATE_FETCH_FAIL = 'DND_UPDATE_FETCH_FAIL';
export const DND_UPDATE_FETCH_SUCCESS = 'DND_UPDATE_FETCH_SUCCESS';

export const DND_CHANGE_CURRENT = 'DND_CHANGE_CURRENT';

export const DND_UPDATE_SUBMIT_FETCH = 'DND_UPDATE_SUBMIT_FETCH';
export const DND_UPDATE_SUBMIT_FETCH_FAIL = 'DND_UPDATE_SUBMIT_FETCH_FAIL';
export const DND_UPDATE_SUBMIT_FETCH_SUCCESS =
  'DND_UPDATE_SUBMIT_FETCH_SUCCESS';

export const CUSTOM_DOMAIN_UPDATE_FETCH = 'CUSTOM_DOMAIN_UPDATE_FETCH';
export const CUSTOM_DOMAIN_UPDATE_FETCH_SUCCESS =
  'CUSTOM_DOMAIN_UPDATE_FETCH_SUCCESS';
export const CUSTOM_DOMAIN_UPDATE_FETCH_FAIL =
  'CUSTOM_DOMAIN_UPDATE_FETCH_FAIL';

export const CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH =
  'CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH';
export const CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH_SUCCESS =
  'CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH_SUCCESS';
export const CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH_FAIL =
  'CUSTOM_DOMAIN_UPDATE_SUBMIT_FETCH_FAIL';

export const CUSTOM_DOMAIN_CHANGE_CURRENT = 'CUSTOM_DOMAIN_CHANGE_CURRENT';

export const UPLOADED_FONTS_SUBMIT_FETCH = 'UPLOADED_FONTS_SUBMIT_FETCH';
export const UPLOADED_FONTS_SUBMIT_FETCH_SUCCESS =
  'UPLOADED_FONTS_SUBMIT_FETCH_SUCCESS';
export const UPLOADED_FONTS_SUBMIT_FETCH_FAIL =
  'UPLOADED_FONTS_SUBMIT_FETCH_FAIL';
