import TeamMemberInvitationComponent from '../component/teamMemberInvitationComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  teamMemberRegisterFetch,
  invitedUserChangeCurrent,
} from '../TeamMembersActions';
import { logoutFetch } from '../TeamMembersActions';
const mapStateToProps = (state) => ({
  currentInvitedUser: state.integration.teamMembers.currentInvitedUser,
  registerResponse: state.integration.teamMembers.registerResponse,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      teamMemberRegisterFetch,
      invitedUserChangeCurrent,
      logoutFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMemberInvitationComponent);
TeamMemberInvitationComponent.propTypes = {};
