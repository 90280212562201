import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router';
import AdminProductListContainer from './container/AdminProductListContainer';
import AdminProductUpdateContainer from './container/AdminProductUpdateContainer';
import AdminProductViewContainer from './container/AdminProductViewContainer';
import AdminProductContract from './component/AdminProductContract';

function adminProduct() {
  let match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route exact path={`${match.path}list`}>
          <AdminProductListContainer />
        </Route>
        <Route
          path={`${match.path}create`}
          component={(props) => {
            return <AdminProductUpdateContainer {...props} />;
          }}
        />
        <Route
          path={`${match.path}update/:id/product`}
          component={(props) => {
            return <AdminProductUpdateContainer {...props} />;
          }}
        />

        <Route
          path={`${match.path}update/:id/contract`}
          component={(props) => {
            return <AdminProductContract {...props} />;
          }}
        />
        <Route path={`${match.path}view/:id`}>
          <AdminProductViewContainer />
        </Route>
      </Switch>
    </div>
  );
}

export default adminProduct;
