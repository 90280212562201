import BaseApi from '../../../api/BaseApi';

const basePath = '/engagement/emailTemplate';
class EmailTemplateApi {
  static create(body) {
    return BaseApi.post(basePath + '/create', body);
  }
  static delete(id) {
    return BaseApi.post(basePath + '/delete/' + id, {});
  }
  static list(data) {
    return BaseApi.post(basePath + '/list', data);
  }
  static listAll() {
    return BaseApi.post(basePath + '/listAll', {
      direction: 'DESC',
      order: 'id',
    });
  }
  static update(id) {
    return BaseApi.post(basePath + '/update/' + id, {});
  }
  static updateSubmit(id, body) {
    return BaseApi.post(basePath + '/updateSubmit/' + id, body);
  }
  static view(id) {
    return BaseApi.post(basePath + '/view/' + id, {});
  }
  static duplicate(id) {
    return BaseApi.post(basePath + '/copy/' + id, {});
  }
}

export default EmailTemplateApi;
