import BaseApi from '../../../api/BaseApi';
const basePath = '/insight/appInstallation';
class UninstallsApi {
  static uninstallOverview(pageRequest) {
    return BaseApi.post(basePath + '/installUninstallOverview', pageRequest);
  }
  static uninstallUsers(pageRequest) {
    return BaseApi.post(basePath + '/installUninstallUsers', pageRequest);
  }

  static uninstallList() {
    return BaseApi.post(basePath + '/installUninstallAnalyze/attributes', {});
  }
  static uninstallAnalyze(body) {
    return BaseApi.post(basePath + '/installUninstallAnalyze', body);
  }
}

export default UninstallsApi;
