import SiderComponent from '../component/SiderComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  currentUserFetch,
  logoutFetch,
  changeMenuLayout,
  changeActiveProduct,
} from '../../../account/auth/AuthActions';
import { updateLastProductSubmit } from '../../../account/profile/ProfileActions';
const mapStateToProps = (state) => ({
  currentUser: state.account.auth.currentUser,
  pathname: state.router.location.pathname,
  isMenuOpen: state.account.auth.isMenuOpen,
  activeProduct: state.account.auth.activeProduct,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      currentUserFetch,
      logoutFetch,
      changeMenuLayout,
      changeActiveProduct,
      updateLastProductSubmit,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SiderComponent);

SiderComponent.propTypes = {
  currentUser: PropTypes.object.isRequired,
  currentUserFetch: PropTypes.func.isRequired,
  logoutFetch: PropTypes.func.isRequired,
};
