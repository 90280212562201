import BaseApi from '../../../api/BaseApi';
import toastr from 'toastr';
import { showMessageError } from '../../../utils/RenderUtils';
export const CSV_EXPORT_FETCH = 'CSV_EXPORT_FETCH';
export const CSV_EXPORT_SUCCESS = 'CSV_EXPORT_SUCCESS';
export const CSV_EXPORT_FAIL = 'CSV_EXPORT_FAIL';
export const CSV_LIST_SEARCH_FETCH = 'CSV_LIST_SEARCH_FETCH';
export const CSV_LIST_SEARCH_FETCH_SUCCESS = 'SV_LIST_SEARCH_FETCH_SUCCESS';
export const CSV_LIST_SEARCH_FETCH_FAIL = 'CSV_LIST_SEARCH_FETCH_FAIL';
export const CSV_LIST_FETCH = 'CSV_LIST_FETCH';
export const CSV_LIST_FETCH_SUCCESS = 'CSV_LIST_FETCH_SUCCESS';
export const CSV_LIST_FETCH_FAIL = 'CSV_LIST_FETCH_FAIL';
export const CSV_UPDATE_SUBMIT_FETCH = 'CSV_UPDATE_SUBMIT_FETCH';
export const CSV_UPDATE_SUBMIT_FETCH_SUCCESS =
  'CSV_UPDATE_SUBMIT_FETCH_SUCCESS';
export const CSV_UPDATE_SUBMIT_FETCH_FAIL = 'CSV_UPDATE_SUBMIT_FETCH_FAIL';
export const CSV_UPDATE_FETCH = 'CSV_UPDATE_FETCH';
export const CSV_UPDATE_FETCH_SUCCESS = ' CSV_UPDATE_FETCH_SUCCESS';
export const CSV_UPDATE_FETCH_FAIL = 'CSV_UPDATE_FETCH_FAIL';
export const CSV_EXPORT_CLEAR_ERROR = 'CSV_EXPORT_CLEAR_ERROR';
const initialState = {
  loadingUpdate: false,
  errors: [],
  csvModalVisible: false,
  success: true,
  loadingList: false,
  loadingSearchList: false,
  loadingUpdateSubmit: false,
  pageRequest: {},
  page: {},
};
export function CsvExportStateReducer(state = initialState, action) {
  switch (action.type) {
    case CSV_EXPORT_CLEAR_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    case CSV_EXPORT_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        csvModalVisible: false,
        currentUser: action.payload,
        success: true,
      };
    case CSV_EXPORT_FETCH:
      return {
        ...state,
        csvModalVisible: true,
        loadingUpdate: true,
        success: true,
      };
    case CSV_EXPORT_FAIL:
      return {
        ...state,
        loadingUpdate: false,
        csvModalVisible: false,
        errors: action.payload.errors,
        success: false,
      };
    case CSV_LIST_FETCH:
      return { ...state, pageRequest: action.payload, loadingList: true };
    case CSV_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingList: false };
    case CSV_LIST_FETCH_FAIL:
      return { ...state, errors: action.payload.errors, loadingList: false };
    case CSV_LIST_SEARCH_FETCH:
      return { ...state, pageRequest: action.payload, loadingSearchList: true };
    case CSV_LIST_SEARCH_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingSearchList: false };
    case CSV_LIST_SEARCH_FETCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loadingSearchList: false,
      };
    default:
      return state;
  }
}
class CsvExportApi {
  static exportCommunicationFile(id) {
    return BaseApi.post(
      `/engagement/communication/exportCommunicationFile/${id}`
    );
  }
  static exportJourneyFile(journeyId) {
    return BaseApi.post(`/engagement/journey/exportJourneyFile/${journeyId}`);
  }
  static exportSegmentFile(id, body) {
    return BaseApi.post(
      `/insight/productSegment/exportSegmentFile/${id}`,
      body
    );
  }
  static possibleSegmentHeaders() {
    return BaseApi.get('/insight/productSegment/possibleHeaders');
  }
  static exportCsvDownload(campaignId, productId) {
    return BaseApi.downLoadFile(
      `/report/download/${campaignId}?productId=${productId}`
    );
  }
  static logList(body) {
    return BaseApi.post('/data/csvExportLog/getLogs', body);
  }
  static profileLogList(body) {
    return BaseApi.post('/auth/profile/csvExportLog', body);
  }
}
export default CsvExportApi;

export function callExportCommunicationFile(id) {
  return (dispatch) => {
    dispatch({
      type: CSV_EXPORT_FETCH,
    });
    CsvExportApi.exportCommunicationFile(id)
      .then((response) => {
        dispatch({
          type: CSV_EXPORT_SUCCESS,
          payload: response,
        });
        toastr.success('Has been saved successfully');
      })
      .catch((response) => {
        dispatch({
          type: CSV_EXPORT_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function callExportJourneyFile(id) {
  return (dispatch) => {
    dispatch({
      type: CSV_EXPORT_FETCH,
    });
    CsvExportApi.exportJourneyFile(id)
      .then((response) => {
        dispatch({
          type: CSV_EXPORT_SUCCESS,
          payload: response,
        });
        toastr.success('Has been saved successfully');
      })
      .catch((response) => {
        dispatch({
          type: CSV_EXPORT_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function callExportSegmentFile({ id, body, cb }) {
  return (dispatch) => {
    dispatch({
      type: CSV_EXPORT_FETCH,
    });
    CsvExportApi.exportSegmentFile(id, body)
      .then((response) => {
        dispatch({
          type: CSV_EXPORT_SUCCESS,
          payload: response,
        });
        toastr.success('Has been saved successfully');
        cb && cb();
      })
      .catch((response) => {
        dispatch({
          type: CSV_EXPORT_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function getPossibleSegmentHeaders({ cb }) {
  return () => {
    CsvExportApi.possibleSegmentHeaders()
      .then((response) => {
        cb && cb(response);
      })
      .catch((response) => {
        showMessageError(response);
      });
  };
}
export function callExportCsvDownload(campaignId, productId) {
  return (dispatch) => {
    dispatch({
      type: CSV_EXPORT_FETCH,
    });
    CsvExportApi.exportCsvDownload(campaignId, productId)
      .then((response) => {
        dispatch({
          type: CSV_EXPORT_SUCCESS,
          payload: response,
        });
        toastr.success('Has been saved successfully');
      })
      .catch((response) => {
        dispatch({
          type: CSV_EXPORT_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function clearErrors() {
  return (dispatch) => {
    dispatch({
      type: CSV_EXPORT_CLEAR_ERROR,
      payload: [],
    });
  };
}

const loadCsvSearchList = (dispatch, csvPageRequest) => {
  dispatch({
    type: CSV_LIST_SEARCH_FETCH,
    payload: csvPageRequest,
  });
  CsvExportApi.logList(csvPageRequest)
    .then((response) => {
      dispatch({
        type: CSV_LIST_SEARCH_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: CSV_LIST_SEARCH_FETCH_FAIL,
        payload: response,
      });
    });
};
const profileLoadCsvSearchList = (dispatch, csvPageRequest) => {
  dispatch({
    type: CSV_LIST_SEARCH_FETCH,
    payload: csvPageRequest,
  });
  CsvExportApi.profileLogList(csvPageRequest)
    .then((response) => {
      dispatch({
        type: CSV_LIST_SEARCH_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: CSV_LIST_SEARCH_FETCH_FAIL,
        payload: response,
      });
    });
};
const loadCsvList = (dispatch, csvPageRequest) => {
  dispatch({
    type: CSV_LIST_FETCH,
    payload: csvPageRequest,
  });
  CsvExportApi.logList(csvPageRequest)
    .then((response) => {
      dispatch({
        type: CSV_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: CSV_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};
const profileLoadCsvList = (dispatch, csvPageRequest) => {
  dispatch({
    type: CSV_LIST_FETCH,
    payload: csvPageRequest,
  });
  CsvExportApi.profileLogList(csvPageRequest)
    .then((response) => {
      dispatch({
        type: CSV_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: CSV_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};
export function csvUpdateFetch(id) {
  return (dispatch) => {
    dispatch({
      type: CSV_UPDATE_FETCH,
    });
    CsvExportApi.update(id)
      .then((response) => {
        dispatch({
          type: CSV_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: CSV_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function csvExportHistorySearchListFetch(csvPageRequest) {
  return (dispatch) => loadCsvSearchList(dispatch, csvPageRequest);
}
export function profileCsvExportHistorySearchListFetch(csvPageRequest) {
  return (dispatch) => profileLoadCsvSearchList(dispatch, csvPageRequest);
}
export function csvListFetch(csvPageRequest) {
  return (dispatch) => loadCsvList(dispatch, csvPageRequest);
}
export function profileCsvListFetch(csvPageRequest) {
  return (dispatch) => profileLoadCsvList(dispatch, csvPageRequest);
}
