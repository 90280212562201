import React, { useEffect } from 'react';
import { Table, Spin, Divider } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSla } from '@Redux/slices/setting/billingSlice';

import {
  contractCleareActiveContract,
  contractViewActiveFetch,
} from '@Redux/slices/admin/adminProductContractSlice';

const columns = [
  {
    title: 'Service',
    dataIndex: 'service',
    key: 'service',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Monthly SLA',
    dataIndex: 'sla',
    key: 'sla',
  },
  {
    title: 'Penalty',
    key: 'penalty',
    dataIndex: 'penalty',
  },
  {
    title: 'Assessment Measure',
    key: 'asses',
    dataIndex: 'asses',
  },
  {
    title: 'Service Metrics',
    key: 'metrics',
    dataIndex: 'metrics',
  },
];

export default function ProductBillingSla() {
  const dispatch = useDispatch();
  const sla = useSelector((state) => state.setting.billing.sla);
  const duration = useSelector((state) => state.setting.billing.slaDuration);
  const isLoading = useSelector((state) => state.setting.billing.loading);
  const activeContract = useSelector(
    (state) => state.admin.contract.activeContract
  );
  useEffect(() => {
    dispatch(contractViewActiveFetch());
    return () => {
      dispatch(contractCleareActiveContract());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchSla(duration));
  }, [duration]);

  const res = [
    {
      key: '1',
      service: 'Event Tracking',
      description:
        activeContract?.contractTemplate?.slaMetrics?.EVENT_RECEIVE_SLA
          ?.description || '-',
      sla: activeContract?.contractTemplate?.slaMetrics?.EVENT_RECEIVE_SLA
        ?.threshold
        ? `${activeContract?.contractTemplate?.slaMetrics?.EVENT_RECEIVE_SLA?.threshold}%`
        : '-',
      penalty:
        activeContract?.contractTemplate?.slaMetrics?.EVENT_RECEIVE_SLA
          ?.penalty || '-',
      asses:
        activeContract?.contractTemplate?.slaMetrics?.EVENT_RECEIVE_SLA
          ?.evaluationCriteria || '-',
      metrics: sla.hasOwnProperty('eventReceiveSla')
        ? `${sla?.eventReceiveSla}%`
        : '-',
    },
    {
      key: '2',
      service: 'Event Processing',
      description:
        activeContract?.contractTemplate?.slaMetrics?.EVENT_PROCESS_SLA
          ?.description || '-',
      sla: activeContract?.contractTemplate?.slaMetrics?.EVENT_PROCESS_SLA
        ?.threshold
        ? `${activeContract?.contractTemplate?.slaMetrics?.EVENT_PROCESS_SLA?.threshold}%`
        : '-',
      penalty:
        activeContract?.contractTemplate?.slaMetrics?.EVENT_PROCESS_SLA
          ?.penalty || '-',
      asses:
        activeContract?.contractTemplate?.slaMetrics?.EVENT_PROCESS_SLA
          ?.evaluationCriteria || '-',
      metrics: sla.hasOwnProperty('eventProcessSla')
        ? `${sla.eventProcessSla}%`
        : '-',
    },
    {
      key: '3',
      service: 'Event Processing delay',
      description:
        activeContract?.contractTemplate?.slaMetrics?.EVENT_QUEUE_TIME_SLA
          ?.description || '-',
      sla: activeContract?.contractTemplate?.slaMetrics?.EVENT_QUEUE_TIME_SLA
        ?.threshold
        ? `${activeContract?.contractTemplate?.slaMetrics?.EVENT_QUEUE_TIME_SLA?.threshold}%`
        : '-',
      penalty:
        activeContract?.contractTemplate?.slaMetrics?.EVENT_QUEUE_TIME_SLA
          ?.penalty || '-',
      asses:
        activeContract?.contractTemplate?.slaMetrics?.EVENT_QUEUE_TIME_SLA
          ?.evaluationCriteria || '-',
      metrics: sla.hasOwnProperty('eventQueueTimeSla')
        ? `${sla.eventQueueTimeSla}%`
        : '-',
    },
    {
      key: '4',
      service: 'Communications',
      description:
        activeContract?.contractTemplate?.slaMetrics?.CAMPAIGN_SUCCESS_SLA
          ?.description || '-',
      sla: activeContract?.contractTemplate?.slaMetrics?.CAMPAIGN_SUCCESS_SLA
        ?.threshold
        ? `${activeContract?.contractTemplate?.slaMetrics?.CAMPAIGN_SUCCESS_SLA?.threshold}%`
        : '-',
      penalty:
        activeContract?.contractTemplate?.slaMetrics?.CAMPAIGN_SUCCESS_SLA
          ?.penalty || '-',
      asses:
        activeContract?.contractTemplate?.slaMetrics?.CAMPAIGN_SUCCESS_SLA
          ?.evaluationCriteria || '-',
      metrics: sla.hasOwnProperty('campaignSuccessSla')
        ? `${sla?.campaignSuccessSla}%`
        : '-',
    },
    {
      key: '5',
      service: 'Dashboard Availability',
      description:
        activeContract?.contractTemplate?.slaMetrics?.UPTIME_SLA?.description ||
        '-',
      sla: activeContract?.contractTemplate?.slaMetrics?.UPTIME_SLA?.threshold
        ? `${activeContract?.contractTemplate?.slaMetrics?.UPTIME_SLA?.threshold}%`
        : '-',
      penalty:
        activeContract?.contractTemplate?.slaMetrics?.UPTIME_SLA?.penalty ||
        '-',
      asses:
        activeContract?.contractTemplate?.slaMetrics?.UPTIME_SLA
          ?.evaluationCriteria || '-',
      metrics: sla.hasOwnProperty('uptimeSla') ? `${sla?.uptimeSla}%` : '-',
    },
  ];
  const dataSourceAggregated = [
    {
      key: '1',
      totalCustomEvents: sla.hasOwnProperty('totalCustomEvents')
        ? `${sla.totalCustomEvents}`
        : '-',
      totalSystemEvents: sla.hasOwnProperty('totalSystemEvents')
        ? `${sla.totalSystemEvents}`
        : '-',
      totalEvents: sla.hasOwnProperty('totalEvents')
        ? `${sla.totalEvents}`
        : '-',
    },
  ];

  const columnsAggregated = [
    {
      title: 'Aggregated Custom Events',
      dataIndex: 'totalCustomEvents',
      key: 'totalCustomEvents',
    },
    {
      title: 'Aggregated System Events',
      dataIndex: 'totalSystemEvents',
      key: 'totalSystemEvents',
    },
    {
      title: 'Aggregated Total Events',
      dataIndex: 'totalEvents',
      key: 'totalEvents',
    },
  ];
  return (
    <>
      <strong>
        According to your contract, your project will benefit from the following
        SLA
      </strong>
      <br />
      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 35 }} spin />} />
        </div>
      ) : (
        <>
          <Table columns={columns} dataSource={res} pagination={false} />
        </>
      )}
      <Divider />
      <Table dataSource={dataSourceAggregated} columns={columnsAggregated} />
    </>
  );
}
