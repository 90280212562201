import {
  EMAIL_CHANNEL_DELETE_FETCH,
  EMAIL_CHANNEL_DELETE_FETCH_FAIL,
  EMAIL_CHANNEL_DELETE_FETCH_SUCCESS,
  EMAIL_CHANNEL_CREATE_FETCH,
  EMAIL_CHANNEL_CREATE_FETCH_FAIL,
  EMAIL_CHANNEL_CREATE_FETCH_SUCCESS,
  EMAIL_CHANNEL_LIST_FETCH,
  EMAIL_CHANNEL_LIST_FETCH_FAIL,
  EMAIL_CHANNEL_LIST_FETCH_SUCCESS,
  EMAIL_CHANNEL_UPDATE_FETCH,
  EMAIL_CHANNEL_UPDATE_FETCH_FAIL,
  EMAIL_CHANNEL_UPDATE_FETCH_SUCCESS,
  EMAIL_CHANNEL_UPDATE_SUBMIT_FETCH,
  EMAIL_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
  EMAIL_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
  EMAIL_CHANNEL_CHANGE_CURRENT,
  EMAIL_CHANNEL_PAGE_CHANGE_CURRENT,
  EMAIL_CHANNEL_SEARCH_LIST_FETCH,
  EMAIL_CHANNEL_SEARCH_LIST_FETCH_FAIL,
  EMAIL_CHANNEL_SEARCH_LIST_FETCH_SUCCESS,
} from './EmailChannelConstants';
import EmailChannelApi from './EmailChannelApi';
import toastr from 'toastr';
import { showMessageError } from '../../../utils/RenderUtils';

const loadEmailChannelList = (dispatch, pageRequest) => {
  dispatch({
    type: EMAIL_CHANNEL_LIST_FETCH,
    payload: pageRequest,
  });
  EmailChannelApi.list(pageRequest)
    .then((response) => {
      dispatch({
        type: EMAIL_CHANNEL_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: EMAIL_CHANNEL_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function emailChannelListFetch(pageRequest) {
  return (dispatch) => loadEmailChannelList(dispatch, pageRequest);
}
const loadEmailSearchChannelList = (dispatch, pageRequest) => {
  dispatch({
    type: EMAIL_CHANNEL_SEARCH_LIST_FETCH,
    payload: pageRequest,
  });
  EmailChannelApi.list(pageRequest)
    .then((response) => {
      dispatch({
        type: EMAIL_CHANNEL_SEARCH_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: EMAIL_CHANNEL_SEARCH_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function emailChannelSearchListFetch(pageRequest) {
  return (dispatch) => loadEmailSearchChannelList(dispatch, pageRequest);
}

export function emailChannelChangeCurrent(emailChannel) {
  return (dispatch) => {
    dispatch({
      type: EMAIL_CHANNEL_CHANGE_CURRENT,
      payload: emailChannel,
    });
  };
}

export function emailChannelPageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: EMAIL_CHANNEL_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function emailChannelCreateFetch(emailChannel, pageRequest) {
  return (dispatch) => {
    dispatch({
      type: EMAIL_CHANNEL_CREATE_FETCH,
      payload: emailChannel,
    });
    EmailChannelApi.create(emailChannel)
      .then((response) => {
        dispatch({
          type: EMAIL_CHANNEL_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been created successfully');
        loadEmailChannelList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: EMAIL_CHANNEL_CREATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function emailChannelUpdateFetch(id, isEdit = true) {
  return (dispatch) => {
    dispatch({
      type: EMAIL_CHANNEL_UPDATE_FETCH,
    });
    const method = isEdit ? EmailChannelApi.update : EmailChannelApi.view;
    method(id)
      .then((response) => {
        dispatch({
          type: EMAIL_CHANNEL_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: EMAIL_CHANNEL_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function emailChannelUpdateSubmitFetch(id, emailChannel, pageRequest) {
  return (dispatch) => {
    dispatch({
      type: EMAIL_CHANNEL_UPDATE_SUBMIT_FETCH,
      payload: emailChannel,
    });
    EmailChannelApi.updateSubmit(id, emailChannel)
      .then((response) => {
        dispatch({
          type: EMAIL_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been updated successfully');
        loadEmailChannelList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: EMAIL_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function emailChannelDeleteFetch(id, pageRequest) {
  return (dispatch) => {
    dispatch({ type: EMAIL_CHANNEL_DELETE_FETCH });
    EmailChannelApi.delete(id)
      .then((response) => {
        dispatch({
          type: EMAIL_CHANNEL_DELETE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been deleted successfully');
        loadEmailChannelList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: EMAIL_CHANNEL_DELETE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
