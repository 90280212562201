import {
  PRODUCT_SDK_ANDROID_VIEW_FETCH,
  PRODUCT_SDK_ANDROID_VIEW_FETCH_FAIL,
  PRODUCT_SDK_ANDROID_VIEW_FETCH_SUCCESS,
  PRODUCT_SDK_WEB_VIEW_FETCH,
  PRODUCT_SDK_WEB_VIEW_FETCH_FAIL,
  PRODUCT_SDK_WEB_VIEW_FETCH_SUCCESS,
  PRODUCT_SDK_IOS_VIEW_FETCH,
  PRODUCT_SDK_IOS_VIEW_FETCH_FAIL,
  PRODUCT_SDK_IOS_VIEW_FETCH_SUCCESS,
} from './ProductSdkConstants';
import ProductSdkApi from './ProductSdkApi';

export function productSdkAndroidViewFetch() {
  return (dispatch) => {
    dispatch({ type: PRODUCT_SDK_ANDROID_VIEW_FETCH });
    ProductSdkApi.viewAndroid()
      .then((response) => {
        dispatch({
          type: PRODUCT_SDK_ANDROID_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SDK_ANDROID_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function productSdkWebViewFetch() {
  return (dispatch) => {
    dispatch({ type: PRODUCT_SDK_WEB_VIEW_FETCH });
    ProductSdkApi.viewWeb()
      .then((response) => {
        dispatch({
          type: PRODUCT_SDK_WEB_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SDK_WEB_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function productSdkIosViewFetch() {
  return (dispatch) => {
    dispatch({ type: PRODUCT_SDK_IOS_VIEW_FETCH });
    ProductSdkApi.viewIOS()
      .then((response) => {
        dispatch({
          type: PRODUCT_SDK_IOS_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SDK_IOS_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
