import React from 'react';
import { isNil, find as lodashFind } from 'lodash';
import moment from 'moment';
import HtmlToReact from 'html-to-react';
import pushPreviewContainer from '../../../../theme/icons/gpixel-push-min.png';
import iosPush from '../../../../theme/icons/iphone6-push-min.png';
import appleIcon from '../../../../theme/icons/apple-icon-min.png';
import androidIcon from '../../../../theme/icons/android-os-min.png';
import androidTrangleIcon from '../../../../theme/icons/android-triangle.png';
import { Segmented, Select } from 'antd';
import lodashIsEmpty from 'lodash/isEmpty';
import InAppPreview from './inApp/InAppPreview';
import OnSitePreview from './inApp/OnSitePreview';
import WhatsAppPreview from './whatsApp/WhatsAppPreview';
import { AndroidFilled, AppleFilled } from '@ant-design/icons';

class CommunicationPreviewWidgetComponent extends React.Component {
  constructor(props) {
    super(props);
    this.props.currentCommunication.variations;

    const activeVariationData =
      this.props?.currentCommunication?.variations?.find(
        (item) => item.id === this.props?.activeVariationIndex
      );

    this.state = {
      isPreviewAndroid: true,
      webPushPreviewType: 'chrome_windows_10',
      activeVariationData,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeVariationIndex != this.props.activeVariationIndex) {
      const activeVariationData =
        this.props?.currentCommunication?.variations?.find(
          (item) => item.id === this.props?.activeVariationIndex
        );
      this.setState({ activeVariationData });
    }
  }

  renderMobileNumber = (variation) => {
    let result = '8758316578';
    if (this.props.currentCommunication.type === 'SMS' && variation) {
      const id = !isNil(variation.smsChannel) ? variation.smsChannel : null;
      const foundItem = lodashFind(this.props.smsChannels, (o) => o.id === id);
      if (foundItem && foundItem.name) {
        result = foundItem.name;
      }
    }
    return result;
  };

  showAndroidIosPreview = (platform = 'ANDROID') => {
    let result = false;
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.channel === 'PUSH'
    ) {
      if (
        this.props.currentCommunication.mobileDevice === platform ||
        this.props.currentCommunication.mobileDevice === undefined ||
        this.props.currentCommunication.mobileDevice === null
      ) {
        result = true;
      }
    } else {
      result = true;
    }
    return result;
  };

  renderActiveVariationContent = (section) => {
    let result = '';
    if (this.state.activeVariationData) {
      result = this.state.activeVariationData[section];
    }
    return result;
  };

  renderEmailPreview = () => {
    const parser = new HtmlToReact.Parser();
    const htmlInput = this.state.activeVariationData?.message;
    return parser.parse(htmlInput);
  };

  getPreviewType = () => {
    let result = null;
    if (
      this.props.currentCommunication &&
      isNil(this.props.currentCommunication.mobileDevice)
    ) {
      if (this.state.isPreviewAndroid) {
        result = 'ANDROID';
      } else {
        result = 'IOS';
      }
    }
    if (
      this.props.currentCommunication &&
      !isNil(this.props.currentCommunication.mobileDevice)
    ) {
      result = this.props.currentCommunication.mobileDevice;
    }
    return result;
  };

  renderWebPushPreviewTypeSelector = () => {
    return (
      <Select
        onChange={(webPushPreviewType) => this.setState({ webPushPreviewType })}
        value={this.state.webPushPreviewType}
        style={{ width: 180 }}
        className={'chart-selector'}
        bordered={false}
      >
        <Select.Option value={'chrome_windows_10'}>
          Chrome Windows 10
        </Select.Option>
        <Select.Option value={'chrome_windows'}>Chrome Windows</Select.Option>
        <Select.Option value={'chrome_mac'}>Chrome Mac</Select.Option>
        <Select.Option value={'chrome_android'}>Chrome Android</Select.Option>
        <Select.Option value={'firefox_windows'}>Firefox Windows</Select.Option>
        <Select.Option value={'firefox_mac'}>Firefox Mac</Select.Option>
      </Select>
    );
  };

  renderWebPushSectionPreview = () => {
    if (this.props.currentCommunication.channel === 'WEB_PUSH') {
      if (this.state.webPushPreviewType === 'chrome_windows_10') {
        return (
          <div
            style={{ minHeight: 360, maxWidth: 405 }}
            className="pos-relative w-100 h-100 overflow-scroll"
          >
            <div
              id="webPush-preview"
              className="preview-inner web-push-preview web-push-preview--crud"
            >
              <div className="grid-12 col-12 web-push-preview__body we__win10">
                {this.state?.activeVariationData?.pushType === 'BANNER' && (
                  <div className="row">
                    <div className="we__win10__big-image">
                      <div
                        className="bad--win10__preview"
                        style={{
                          backgroundImage: this.state?.activeVariationData
                            ?.image
                            ? 'url(' +
                              this.state?.activeVariationData?.image +
                              ')'
                            : 'url(//d107ygk0jso0v4.cloudfront.net/frontline/images/placeholderImgHorizontal-f6a7793f4c3000d2467c99c87913ea93.svg)',
                        }}
                      ></div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col col-3 web-push-preview__logo no-padding web-push-preview-flex-center we__win10 we__win10__thumb justify-content-center">
                    <img
                      src={
                        this.state?.activeVariationData?.icon
                          ? this.state?.activeVariationData?.icon
                          : '//d107ygk0jso0v4.cloudfront.net/frontline/images/web-push/placeholder-small-d282371abebcc5dce91979af42768214.png'
                      }
                    />
                  </div>
                  <div className="col col-9 padding-top-x web-push-preview__content we__win10">
                    <div className="web-push-preview__title">
                      {lodashIsEmpty(this.state?.activeVariationData?.subject)
                        ? 'Title'
                        : this.state?.activeVariationData?.subject}
                    </div>
                    <div className="web-push-preview__description we__win10__description">
                      {lodashIsEmpty(this.state?.activeVariationData?.message)
                        ? 'Description'
                        : this.state?.activeVariationData?.message}
                    </div>
                    <div className="web-push-preview__footer domain__block--windows we__win10__footer ">
                      Google Chrome • localhost
                    </div>
                  </div>
                </div>
                {!lodashIsEmpty(
                  this.state?.activeVariationData?.androidButton1Label
                ) &&
                  !lodashIsEmpty(
                    this.state?.activeVariationData?.androidButton2Label
                  ) && (
                    <div className="row text-center">
                      <div className="col-6 no-padding-right margin-bottom-xs">
                        <div className="we__win10__button">
                          {lodashIsEmpty(
                            this.state?.activeVariationData?.androidButton1Label
                          )
                            ? 'Button 1'
                            : this.state?.activeVariationData
                                ?.androidButton1Label}
                        </div>
                      </div>
                      <div className="col-6 no-padding-left margin-bottom-xs">
                        <div className="col-12 we__win10__button">
                          {lodashIsEmpty(
                            this.state?.activeVariationData?.androidButton2Label
                          )
                            ? 'Button 2'
                            : this.state?.activeVariationData
                                ?.androidButton2Label}
                        </div>
                      </div>
                    </div>
                  )}
                {!lodashIsEmpty(
                  this.state?.activeVariationData?.androidButton1Label
                ) &&
                  lodashIsEmpty(
                    this.state?.activeVariationData?.androidButton2Label
                  ) && (
                    <div className="row text-center">
                      <div
                        style={{ visibility: 'hidden' }}
                        className="col-6 no-padding-right margin-bottom-xs"
                      >
                        <div className="we__win10__button">{'Button 1'}</div>
                      </div>
                      <div className="col-6 no-padding-left margin-bottom-xs">
                        <div className="col-12 we__win10__button">
                          {lodashIsEmpty(
                            this.state?.activeVariationData?.androidButton1Label
                          )
                            ? 'Button 1'
                            : this.state?.activeVariationData
                                ?.androidButton1Label}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        );
      } else if (this.state.webPushPreviewType === 'chrome_windows') {
        return (
          <div
            style={{ minHeight: 360 }}
            className="pos-relative w-100 h-100 overflow-scroll"
          >
            <div
              id="webPush-preview"
              className="preview-inner web-push-preview web-push-preview--crud"
            >
              <div className="grid-12 web-push-preview__body ">
                <div className="row">
                  <div className="col col-3 web-push-preview__logo no-padding web-push-preview-flex-center  we__win10__thumb justify-content-center">
                    <img
                      src={
                        this.state?.activeVariationData?.icon
                          ? this.state?.activeVariationData?.icon
                          : '//d107ygk0jso0v4.cloudfront.net/frontline/images/web-push/placeholder-small-d282371abebcc5dce91979af42768214.png'
                      }
                    />
                  </div>
                  <div className="col col-9 padding-top-x web-push-preview__content ">
                    <div>
                      <i
                        className="web-push-preview__close fl-close fl-dark"
                        style={{ fontSize: 24 }}
                      ></i>
                      <i
                        className="web-push-preview__close web-push-preview__settings fl-cog fl-dark"
                        style={{ fontSize: 24 }}
                      ></i>
                    </div>
                    <div className="web-push-preview__title">
                      {lodashIsEmpty(this.state?.activeVariationData?.subject)
                        ? 'Title'
                        : this.state?.activeVariationData?.subject}
                    </div>
                    {this.state?.activeVariationData?.pushType === 'TEXT' && (
                      <div className="web-push-preview__description">
                        {lodashIsEmpty(this.state?.activeVariationData?.message)
                          ? 'Description'
                          : this.state?.activeVariationData?.message}
                      </div>
                    )}
                    {this.state?.activeVariationData?.pushType === 'BANNER' && (
                      <div className="web-push-preview__description win-mi-max-hack">
                        {lodashIsEmpty(this.state?.activeVariationData?.message)
                          ? 'Description'
                          : this.state?.activeVariationData?.message}
                      </div>
                    )}
                    <div className="web-push-preview__footer domain__block--windows">
                      localhost
                    </div>
                  </div>
                </div>
                {this.state?.activeVariationData?.pushType === 'BANNER' && (
                  <div className="row">
                    <div className="web-push-preview__big-image">
                      <img
                        src={
                          this.state?.activeVariationData?.image
                            ? this.state?.activeVariationData?.image
                            : '//d107ygk0jso0v4.cloudfront.net/frontline/images/placeholderImgHorizontal-f6a7793f4c3000d2467c99c87913ea93.svg'
                        }
                      />
                    </div>
                  </div>
                )}
                {!lodashIsEmpty(
                  this.state?.activeVariationData?.androidButton1Label
                ) && (
                  <div
                    className="row border-top"
                    style={{ backgroundColor: 'white' }}
                  >
                    <div className="col-9" style={{ padding: 12 }}>
                      {lodashIsEmpty(
                        this.state?.activeVariationData?.androidButton1Label
                      )
                        ? 'Button 1'
                        : this.state?.activeVariationData?.androidButton1Label}
                    </div>
                  </div>
                )}
                {!lodashIsEmpty(
                  this.state?.activeVariationData?.androidButton2Label
                ) && (
                  <div
                    className="row border-top"
                    style={{ backgroundColor: 'white' }}
                  >
                    <div className="col-9" style={{ padding: 12 }}>
                      {lodashIsEmpty(
                        this.state?.activeVariationData?.androidButton2Label
                      )
                        ? 'Button 2'
                        : this.state?.activeVariationData?.androidButton2Label}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      } else if (this.state.webPushPreviewType === 'chrome_mac') {
        return (
          <div
            style={{ minHeight: 360 }}
            className="pos-relative w-100 h-100 overflow-scroll"
          >
            <div
              id="webPush-preview"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className="preview-inner web-push-preview web-push-preview--crud"
            >
              <div className="outer-cover text-left n-preview">
                <div className="simple-block notification-redirect-url">
                  <div className="placeholder-circle chrome-browser"></div>
                  <div className="text-block">
                    <div className="title-block">
                      {lodashIsEmpty(this.state?.activeVariationData?.subject)
                        ? 'Title'
                        : this.state?.activeVariationData?.subject}
                    </div>
                    <div className="domain-block">localhost</div>
                    <div className="subtitle-block">
                      {lodashIsEmpty(this.state?.activeVariationData?.message)
                        ? 'Description'
                        : this.state?.activeVariationData?.message}
                    </div>
                  </div>
                  <div className="logo-block">
                    <img
                      src={
                        this.state?.activeVariationData?.icon
                          ? this.state?.activeVariationData?.icon
                          : '//d107ygk0jso0v4.cloudfront.net/frontline/images/web-push/placeholder-small-d282371abebcc5dce91979af42768214.png'
                      }
                      className="js-campaign-preview-img"
                    />
                  </div>
                  <div className="cta-block">
                    <div className="cta-button">Close</div>
                    <div className="cta-button cursor-pointer">More</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.webPushPreviewType === 'firefox_windows') {
        return (
          <div
            style={{ minHeight: 360 }}
            id="webPush-preview"
            className="preview-inner web-push-preview web-push-preview--crud"
          >
            <div
              className="grid-12 col-12 web-push-preview__body "
              style={{ minWidth: 410 }}
            >
              <div
                className="firefox-font firefox-fweight web-push-preview__title padding-top-x"
                style={{ maxWidth: 360 }}
              >
                {lodashIsEmpty(this.state?.activeVariationData?.subject)
                  ? 'Title'
                  : this.state?.activeVariationData?.subject}
                <i
                  className="firefox-preview-cog margin-right-x fl-close fl-dark"
                  style={{ top: 3, fontSize: 24 }}
                ></i>
              </div>
              <div className="row">
                <div className="col col-3 web-push-preview__logo no-padding web-push-preview-flex-center  we__win10__thumb justify-content-center">
                  <img
                    src={
                      this.state?.activeVariationData?.icon
                        ? this.state?.activeVariationData?.icon
                        : '//d107ygk0jso0v4.cloudfront.net/frontline/images/web-push/placeholder-small-d282371abebcc5dce91979af42768214.png'
                    }
                  />
                </div>
                <div className="col col-9 padding-top-x web-push-preview__content ">
                  <div></div>
                  <div className="web-push-preview__description">
                    {lodashIsEmpty(this.state?.activeVariationData?.message)
                      ? 'Description'
                      : this.state?.activeVariationData?.message}
                  </div>
                  <div className="web-push-preview__footer domain__block--windows firefox-font">
                    localhost
                    <i
                      style={{ top: 2, fontSize: 24 }}
                      className="firefox-preview-cog fl-cog fl-dark"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.webPushPreviewType === 'chrome_android') {
        return (
          <div
            style={{ minHeight: 360 }}
            className="pos-relative w-100 h-100 overflow-scroll"
          >
            <div
              id="webPush-preview"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className="preview-inner web-push-preview web-push-preview--crud"
            >
              <div className="outer-cover1 n-preview">
                <div className="simple-block notification-redirect-url android-preview">
                  <div className="app-block">
                    <span className="browser-icon browser-icon-chrome margin-right-xs">
                      <span className="margin-left-x">
                        <img
                          style={{ maxWidth: '100%', borderStyle: 'none' }}
                          src="https://d107ygk0jso0v4.cloudfront.net/frontline/images/placeholderImgHorizontal-f6a7793f4c3000d2467c99c87913ea93.svg"
                        />
                      </span>
                    </span>
                    <span className="margin-left-x domain__block">
                      <span>Chrome • </span>
                      <span className="domain__block--width">
                        localhost
                      </span>{' '}
                      <span> • 1m</span>
                    </span>
                  </div>
                  <div className="top-block">
                    <div className="content-block">
                      <div className="text-block">
                        <div className="title-block">
                          <span className="notification-title">
                            {lodashIsEmpty(
                              this.state?.activeVariationData?.subject
                            )
                              ? 'Title'
                              : this.state?.activeVariationData?.subject}
                          </span>
                        </div>
                        <div
                          className={
                            this.state?.activeVariationData?.pushType ===
                            'BANNER'
                              ? 'subtitle-block'
                              : 'subtitle-block subtitle__override subtitle__override--height'
                          }
                        >
                          <span className="notification-message">
                            {lodashIsEmpty(
                              this.state?.activeVariationData?.message
                            )
                              ? 'Description'
                              : this.state?.activeVariationData?.message}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="logo-block">
                      <img
                        src={
                          this.state?.activeVariationData?.icon
                            ? this.state?.activeVariationData?.icon
                            : '//d107ygk0jso0v4.cloudfront.net/frontline/images/web-push/placeholder-small-d282371abebcc5dce91979af42768214.png'
                        }
                        className="js-campaign-preview-img"
                      />
                    </div>
                  </div>
                  <div className="bottom-block">
                    {this.state?.activeVariationData?.pushType === 'BANNER' && (
                      <div className="hero-image-block">
                        <div
                          className="hero-image"
                          style={{
                            backgroundImage: this.state?.activeVariationData
                              ?.image
                              ? 'url(' +
                                this.state?.activeVariationData?.image +
                                ')'
                              : 'url(//d107ygk0jso0v4.cloudfront.net/frontline/images/placeholderImgHorizontal-f6a7793f4c3000d2467c99c87913ea93.svg)',
                          }}
                        />
                      </div>
                    )}
                    {!lodashIsEmpty(
                      this.state?.activeVariationData?.androidButton1Label
                    ) ||
                    !lodashIsEmpty(
                      this.state?.activeVariationData?.androidButton2Label
                    ) ? (
                      <div className="cta-block">
                        {(!lodashIsEmpty(
                          this.state?.activeVariationData?.androidButton1Label
                        ) ||
                          !lodashIsEmpty(
                            this.state?.activeVariationData?.androidButton2Label
                          )) && (
                          <div className="cta-button">
                            <div className="cta-button-content">
                              <span href="#">
                                <span className="cta-text ">
                                  {lodashIsEmpty(
                                    this.state?.activeVariationData
                                      ?.androidButton1Label
                                  )
                                    ? 'BUTTON 1'
                                    : this.state?.activeVariationData
                                        ?.androidButton1Label}
                                </span>
                              </span>
                            </div>
                          </div>
                        )}
                        {!lodashIsEmpty(
                          this.state?.activeVariationData?.androidButton1Label
                        ) &&
                          !lodashIsEmpty(
                            this.state?.activeVariationData?.androidButton2Label
                          ) && (
                            <div className="cta-button">
                              <div className="cta-button-content">
                                <span href="#">
                                  <span className="cta-text ">
                                    {lodashIsEmpty(
                                      this.state?.activeVariationData
                                        ?.androidButton2Label
                                    )
                                      ? 'BUTTON 2'
                                      : this.state?.activeVariationData
                                          ?.androidButton2Label}
                                  </span>
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    ) : (
                      <div className="cta-block">
                        <div className="cta-button">
                          <div className="cta-button-content">
                            <span href="#">
                              <span className="cta-text ">
                                {'SITE SETTINGS'}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (this.state.webPushPreviewType === 'firefox_mac') {
        return (
          <div
            style={{ minHeight: 360 }}
            className="pos-relative w-100 h-100 overflow-scroll"
          >
            <div
              id="webPush-preview"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className="preview-inner web-push-preview web-push-preview--crud"
            >
              <div className="outer-cover text-left n-preview">
                <div
                  className="simple-block notification-redirect-url"
                  style={{}}
                >
                  <div className="placeholder-circle firefox-browser"></div>
                  <div className="text-block">
                    <div className="title-block">
                      {lodashIsEmpty(this.state?.activeVariationData?.subject)
                        ? 'Title'
                        : this.state?.activeVariationData?.subject}
                    </div>
                    <div className="domain-block">localhost</div>
                    <div className="subtitle-block">
                      {lodashIsEmpty(this.state?.activeVariationData?.message)
                        ? 'Description'
                        : this.state?.activeVariationData?.message}
                    </div>
                  </div>
                  <div className="logo-block">
                    <img
                      src={
                        this.state?.activeVariationData?.icon
                          ? this.state?.activeVariationData?.icon
                          : '//d107ygk0jso0v4.cloudfront.net/frontline/images/web-push/placeholder-small-d282371abebcc5dce91979af42768214.png'
                      }
                      className="js-campaign-preview-img"
                    />
                  </div>
                  <div className="cta-block">
                    <div className="cta-button">Close</div>
                    <div className="cta-button cursor-pointer">More</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  };
  implementEllipsis = (buttonLabel, n) => {
    let buttonText = '';
    if (buttonLabel.length > n) {
      buttonText = buttonLabel.substring(0, n) + '...';
    } else {
      buttonText = buttonLabel;
    }
    return buttonText;
  };

  renderPreview = () => {
    const mobilePlatformIconStyle = { fontSize: 16, color: '#777' };
    let mobilePlatformOptions = [
      {
        value: 'android',
        icon: <AndroidFilled style={mobilePlatformIconStyle} />,
      },
      {
        value: 'ios',
        icon: <AppleFilled style={mobilePlatformIconStyle} />,
      },
    ];
    return (
      <div
        style={{
          overflow: 'hidden',
          direction: 'ltr',
        }}
      >
        {(this.props.currentCommunication.channel === 'PUSH' ||
          this.props.currentCommunication.channel === 'SMS') && (
          <div className="margin-left-auto message-container__options">
            <Segmented
              style={{ borderRadius: 8 }}
              onChange={(val) => {
                if (val === 'android') {
                  this.setState({ isPreviewAndroid: true });
                } else {
                  this.setState({ isPreviewAndroid: false });
                }
              }}
              options={mobilePlatformOptions}
            />
          </div>
        )}
        {this.props.currentCommunication.channel === 'WEB_PUSH' && (
          <div className="margin-left-auto message-container__options">
            {this.renderWebPushPreviewTypeSelector()}
          </div>
        )}
        {this.props.currentCommunication.channel === 'EMAIL' && (
          <div id="emailPreview" style={{ height: 450, overflow: 'scroll' }}>
            {this.renderEmailPreview()}
          </div>
        )}
        {this.props.currentCommunication.channel === 'SMS' && (
          <div className="pos-relative w-100 h-100 overflow-scroll">
            <div className="preview">
              {this.state.isPreviewAndroid ? (
                <div
                  className="preview_sms preview_mobile preview_android"
                  style={{ height: '670px' }}
                >
                  <div id="sms-android" className="preview-inner">
                    <div className="preview-device empty"></div>
                    <div className="preview-device mobile mobile_android"></div>
                    <div className="preview-content">
                      <div className="sms-preview-date">
                        {moment().format('H:mm')}
                      </div>
                      <div className="sms-preview-header">
                        <div>{this.renderMobileNumber()}</div>
                      </div>
                      <div className="sms-preview-body">
                        <div className="clearfix">
                          <div className="sms-preview-from">8</div>
                          <div
                            className="sms-preview-message"
                            style={{ textAlign: 'left' }}
                          >
                            {this.renderActiveVariationContent('message')}
                          </div>
                        </div>
                        <br />
                        <div className="sms-preview-footer">
                          {moment().format('H:mm') + ' via SMS'}
                        </div>
                      </div>
                      <div className="sms-preview-msg-textfield">
                        Send an SMS
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="preview_sms preview_mobile preview_ios"
                  style={{ height: '670px' }}
                >
                  <div id="sms-android" className="preview-inner">
                    <div className="preview-device empty"></div>
                    <div className="preview-device mobile mobile_ios"></div>
                    <div className="preview-content">
                      <div className="sms-preview-header row">
                        <div className="col-4">Messages</div>
                        <div className="col-5 text-ellipsis">
                          {this.renderMobileNumber()}
                        </div>
                        <div className="col-3">Details</div>
                      </div>
                      <div className="sms-preview-sub-header sms-preview-sub-header_1">
                        Text Message
                      </div>
                      <div className="sms-preview-sub-header sms-preview-sub-header_2">
                        {'Today ' + moment().format('H:mm')}
                      </div>
                      <div className="sms-preview-body">
                        <div className="clearfix">
                          <div className="sms-preview-message">
                            {this.renderActiveVariationContent('message')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {this.props.currentCommunication.channel === 'WEB_PUSH' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {this.renderWebPushSectionPreview()}
          </div>
        )}
        {this.props.currentCommunication.channel === 'WEB_PUSH' && false && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{ minHeight: 710, maxWidth: 405 }}
              className="pos-relative w-100 h-100 overflow-scroll"
            >
              <div
                id="webPush-preview"
                className="preview-inner web-push-preview web-push-preview--crud"
              >
                <div className="grid-12 col-12 web-push-preview__body we__win10">
                  {this.state?.activeVariationData?.pushType === 'BANNER' && (
                    <div className="row">
                      <div className="we__win10__big-image">
                        <div
                          className="bad--win10__preview"
                          style={{
                            backgroundImage: this.state?.activeVariationData
                              ?.image
                              ? 'url(' +
                                this.state?.activeVariationData?.image +
                                ')'
                              : 'url(//d107ygk0jso0v4.cloudfront.net/frontline/images/placeholderImgHorizontal-f6a7793f4c3000d2467c99c87913ea93.svg)',
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col col-3 web-push-preview__logo no-padding web-push-preview-flex-center we__win10 we__win10__thumb justify-content-center">
                      <img
                        src={
                          this.state?.activeVariationData?.icon
                            ? this.state?.activeVariationData?.icon
                            : '//d107ygk0jso0v4.cloudfront.net/frontline/images/web-push/placeholder-small-d282371abebcc5dce91979af42768214.png'
                        }
                      />
                    </div>
                    <div className="col col-9 padding-top-x web-push-preview__content we__win10">
                      <div className="web-push-preview__title">
                        {this.renderActiveVariationContent('subject')}
                      </div>
                      <div className="web-push-preview__description we__win10__description">
                        {this.renderActiveVariationContent('message')}
                      </div>
                      <div className="web-push-preview__footer domain__block--windows we__win10__footer ">
                        Google Chrome • localhost
                      </div>
                    </div>
                  </div>
                  {this.state?.activeVariationData &&
                    !lodashIsEmpty(
                      this.state?.activeVariationData?.androidButton1Label
                    ) &&
                    !lodashIsEmpty(
                      this.state?.activeVariationData?.androidButton2Label
                    ) && (
                      <div className="row text-center">
                        <div className="col-6 no-padding-right margin-bottom-xs">
                          <div className="we__win10__button">
                            {this.state?.activeVariationData
                              ?.androidButton1Label
                              ? 'Button 1'
                              : this.state?.activeVariationData
                                  ?.androidButton1Label}
                          </div>
                        </div>
                        <div className="col-6 no-padding-left margin-bottom-xs">
                          <div className="col-12 we__win10__button">
                            {this.state?.activeVariationData
                              ?.androidButton2Label
                              ? 'Button 2'
                              : this.state?.activeVariationData
                                  ?.androidButton2Label}
                          </div>
                        </div>
                      </div>
                    )}
                  {this.state?.activeVariationData &&
                    this.state?.activeVariationData?.androidButton1Label &&
                    (lodashIsEmpty(
                      this.state?.activeVariationData?.androidButton2Label
                    ) ||
                      isNil(
                        this.state?.activeVariationData?.androidButton2Label
                      )) && (
                      <div className="row text-center">
                        <div
                          style={{ visibility: 'hidden' }}
                          className="col-6 no-padding-right margin-bottom-xs"
                        >
                          <div className="we__win10__button">{'Button 1'}</div>
                        </div>
                        <div className="col-6 no-padding-left margin-bottom-xs">
                          <div className="col-12 we__win10__button">
                            {this.state?.activeVariationData
                              ?.androidButton1Label
                              ? 'Button 1'
                              : this.state?.activeVariationData
                                  ?.androidButton1Label}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}
        {this.props.currentCommunication.channel === 'PUSH' && (
          <React.Fragment>
            {this.state.isPreviewAndroid ? (
              <div
                className="preview-and-launch__message-content"
                style={{ height: '600px' }}
              >
                <div className="message-container__content">
                  <div className="push-notification push-notification--android">
                    <img src={pushPreviewContainer} />
                    <div className="push-notification__block">
                      <div
                        className="push-notification__message"
                        style={{ padding: 0 }}
                      >
                        <div style={{ padding: 16 }}>
                          <div className="push-notification__upper-container">
                            <div className="push-notification__section-left">
                              <div className="push-notification__system-info">
                                <img
                                  src={androidTrangleIcon}
                                  className="push-notification__system-icon"
                                />
                                <span className="push-notification__system-title">
                                  {this.props.productName}
                                </span>
                                <span>&nbsp;&nbsp;</span>
                                <span className="push-notification__system-time">
                                  <i className="fl-dark fl-chevron-bottom line-height-0"></i>
                                </span>
                              </div>
                              <div className="push-notification__title">
                                {this.renderActiveVariationContent('subject')}
                              </div>
                              <div
                                className={
                                  this.state?.activeVariationData?.pushType ===
                                  'TEXT'
                                    ? 'push-notification__description'
                                    : 'push-notification__description text-ellipsis'
                                }
                              >
                                {this.renderActiveVariationContent('message')}
                              </div>
                            </div>
                            <div className="push-notification__section-right">
                              <img
                                className="push-notification__icon"
                                style={{ maxWidth: '100%' }}
                                src={androidIcon}
                              />
                            </div>
                          </div>
                          {this.state?.activeVariationData?.pushType ===
                            'BANNER' && (
                            <div
                              className="push-notification__banner"
                              style={{
                                backgroundImage:
                                  'url("' +
                                  this.state?.activeVariationData?.image +
                                  '")',
                                height: 149,
                                backgroundSize: 'cover',
                              }}
                            ></div>
                          )}
                        </div>
                        {this.props.currentCommunication.variations &&
                          Array.isArray(
                            this.props.currentCommunication.variations
                          ) &&
                          (!isNil(
                            this.state?.activeVariationData?.androidButton1Label
                          ) ||
                            !isNil(
                              this.state?.activeVariationData
                                ?.androidButton2Label
                            )) && (
                            <div
                              className="push-notification__buttons-container"
                              style={{
                                marginTop: 10,
                                display: 'flex',
                                maxHeight: 80,
                              }}
                            >
                              <div
                                className="push-notification__buttons-container-el text-uppercase font-700"
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: 'flex',
                                  justifyContent: 'start',
                                }}
                              >
                                {!isNil(
                                  this.state?.activeVariationData
                                    ?.androidButton1Label
                                ) &&
                                  this.implementEllipsis(
                                    this.state?.activeVariationData
                                      ?.androidButton1Label,
                                    20
                                  )}
                              </div>
                              <div
                                className="push-notification__buttons-container-el text-uppercase font-700"
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: 'flex',
                                  justifyContent: 'start',
                                }}
                              >
                                {!isNil(
                                  this.state?.activeVariationData
                                    ?.androidButton2Label
                                ) &&
                                  this.implementEllipsis(
                                    this.state?.activeVariationData
                                      ?.androidButton2Label,
                                    20
                                  )}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="preview-and-launch__message-content"
                style={{ height: '600px' }}
              >
                <div className="message-container__content">
                  <div className="push-notification push-notification--ios">
                    <img src={iosPush} />
                    <div className="push-notification__screen">
                      <div className="push-notification__notification-area">
                        <div
                          className="push-notification__notification-block"
                          style={{ marginTop: -420, height: 150 }}
                        >
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <div
                              className="push-notification__notification-content"
                              style={{ maxHeight: 300 }}
                            >
                              <div className="push-notification__system-info align-items-center">
                                <img
                                  src={appleIcon}
                                  className="push-notification__system-icon"
                                />
                                <span className="push-notification__system-title">
                                  {this.props.productName}
                                </span>
                                <span className="push-notification__system-cross fl-close"></span>
                              </div>
                              {this.state?.activeVariationData?.image && (
                                <div
                                  className="push-notification__banner"
                                  style={{
                                    backgroundImage:
                                      'url("' +
                                      this.state?.activeVariationData?.image +
                                      '")',
                                    height: 149,
                                    backgroundSize: 'cover',
                                  }}
                                ></div>
                              )}
                              <div className="push-notification__text">
                                <p className="push-notification__text--title font-700">
                                  {this.renderActiveVariationContent('subject')}
                                </p>
                                <div className="push-notification__text--description">
                                  {this.renderActiveVariationContent('message')}
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.props.currentCommunication.variations &&
                            Array.isArray(
                              this.props.currentCommunication.variations
                            ) &&
                            (!isNil(
                              this.state?.activeVariationData
                                ?.androidButton1Label
                            ) ||
                              !isNil(
                                this.state?.activeVariationData
                                  ?.androidButton2Label
                              )) && (
                              <div
                                style={{
                                  backgroundColor: '#b9bdc7',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  alignItems: 'center',
                                  flexDirection: 'column',
                                  marginTop: 10,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  paddingTop: '20px',
                                  paddingBottom: '20px',
                                  borderRadius: 15,
                                }}
                              >
                                {isNil(
                                  this.state?.activeVariationData
                                    ?.androidButton1Label
                                ) !==
                                  isNil(
                                    this.state?.activeVariationData
                                      ?.androidButton2Label
                                  ) && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      maxHeight: 50,
                                    }}
                                  >
                                    {' '}
                                    {!isNil(
                                      this.state?.activeVariationData
                                        ?.androidButton1Label
                                    )
                                      ? this.implementEllipsis(
                                          this.state?.activeVariationData
                                            ?.androidButton1Label,
                                          35
                                        )
                                      : this.implementEllipsis(
                                          this.state?.activeVariationData
                                            ?.androidButton2Label,
                                          35
                                        )}
                                  </div>
                                )}
                                {!isNil(
                                  this.state?.activeVariationData
                                    ?.androidButton1Label
                                ) &&
                                  !isNil(
                                    this.state?.activeVariationData
                                      ?.androidButton2Label
                                  ) && (
                                    <div
                                      style={{
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          borderBottom: 'solid 1px #91949c',
                                          width: '100%',
                                          paddingBottom: 20,
                                        }}
                                      >
                                        {this.implementEllipsis(
                                          this.state?.activeVariationData
                                            ?.androidButton1Label,
                                          35
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          paddingTop: 20,
                                        }}
                                      >
                                        {this.implementEllipsis(
                                          this.state?.activeVariationData
                                            ?.androidButton2Label,
                                          35
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        )}

        {this.props.currentCommunication.channel === 'IN_APP' && (
          <InAppPreview
            previewPage={true}
            variation={this.state?.activeVariationData || {}}
            mobileDevice={this.props.currentCommunication.mobileDevice}
          />
        )}

        {this.props.currentCommunication.channel === 'ON_SITE' && (
          <OnSitePreview
            previewPage={true}
            variation={this.state?.activeVariationData || {}}
            mobileDevice={this.props.currentCommunication.mobileDevice}
          />
        )}
        {this.props.currentCommunication.channel === 'WHATSAPP' && (
          <WhatsAppPreview
            previewPage={true}
            variation={
              this.props?.currentCommunication?.variations[this.props.index] ||
              {}
            }
            whatsapp={this.props.whatsAppTemplateObject}
            index={this.props.index}
            mobileDevice={this.props.currentCommunication.mobileDevice}
          />
        )}
      </div>
    );
  };

  render() {
    return <React.Fragment>{this.renderPreview()}</React.Fragment>;
  }
}

export default CommunicationPreviewWidgetComponent;
