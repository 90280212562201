import React, { useEffect, useState } from 'react';
import { Modal, Spin } from 'antd';
import BackOfficeConfirmationReceiptModalContent from './BackOfficeConfirmationReceiptModalContent';
import {
  // fetchInvoicePaymentReceipt,
  setSelectedInvoiceInfo,
} from '@Redux/slices/setting/invoiceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

const BackOfficeConfirmationReceiptModal = ({
  isOpen,
  onClose,
  selectedInvoiceId,
}) => {
  const dispatch = useDispatch();
  const { loadingFetchInvoice, selectedInvoiceInfo } = useSelector(
    (state) => state.setting.invoice
  );
  const [receipts, setReceipts] = useState([]);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (selectedInvoiceId) {
      //Check Endpoint
      // dispatch(fetchInvoicePaymentReceipt(selectedInvoiceId));
    }
  }, [selectedInvoiceId]);

  useEffect(() => {
    let temp = cloneDeep(selectedInvoiceInfo);
    setReceipts(temp);
    selectedInvoiceInfo.forEach((element) => {
      if (element?.description) {
        return setDescription(element?.description);
      }
    });
  }, [selectedInvoiceInfo]);

  const handleCloseModal = () => {
    onClose();
    dispatch(setSelectedInvoiceInfo([]));
    setDescription('');
    setReceipts([]);
  };

  return (
    <Modal
      width={650}
      title={'Receipt Uploaded Confirmation '}
      centered
      visible={isOpen}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      footer={[]}
    >
      {loadingFetchInvoice ? (
        <Spin
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      ) : (
        <BackOfficeConfirmationReceiptModalContent
          receipts={receipts}
          description={description}
          selectedInvoiceId={selectedInvoiceId}
        />
      )}
    </Modal>
  );
};

export default BackOfficeConfirmationReceiptModal;
