import RoleUpdateComponent from '../component/RoleUpdateComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  roleChangeCurrent,
  roleCopyFetch,
  roleUpdateFetch,
  roleUpdateSubmitFetch,
  roleAuthorityListFetch,
  roleCreateFetch,
} from '../RoleActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentRole: state.admin.role.currentRole,
  authorities: state.admin.role.authorities,
  errors: state.admin.role.errors,
  loadingUpdateRole: state.admin.role.loadingUpdateRole,
  roleSubmitLoading: state.admin.role.roleSubmitLoading,
  clearRolesErrors: state.admin.role.clearRolesErrors,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      roleChangeCurrent,
      roleCopyFetch,
      roleUpdateFetch,
      roleUpdateSubmitFetch,
      roleAuthorityListFetch,
      roleCreateFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleUpdateComponent);

RoleUpdateComponent.propTypes = {
  currentRole: PropTypes.object.isRequired,
  authorities: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  roleChangeCurrent: PropTypes.func.isRequired,
  roleCopyFetch: PropTypes.func.isRequired,
  roleUpdateFetch: PropTypes.func.isRequired,
  roleUpdateSubmitFetch: PropTypes.func.isRequired,
  roleAuthorityListFetch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
