import BaseApi from '../../../api/BaseApi';

const basePath = '/sdk/restApi';
class ApiTokenApi {
  static create() {
    return BaseApi.post(basePath + '/token');
  }
  static revokeToken(id) {
    return BaseApi.post(basePath + '/revokeToken/' + id, {});
  }
  static list(body) {
    return BaseApi.post(basePath + '/listToken', body);
  }
  static revokeAll() {
    return BaseApi.post(basePath + '/revoke', {});
  }
}

export default ApiTokenApi;
