import BaseApi from '../../../api/BaseApi';

const basePath = '/insight/productEvent';
class EventAnalyticsApi {
  static eventList() {
    return BaseApi.post(basePath + '/listEvents', {});
  }
  static getEventAnalytics(body) {
    return BaseApi.post(basePath + '/analyze', body);
  }
}

export default EventAnalyticsApi;
