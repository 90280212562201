import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import './inlineThemePicker.less';

const InputColorPicker = ({ name, variation, onChange, actionIndex }) => {
  const [showSketchPicker, setShowSketchPicker] = useState(false);

  const color =
    name === 'actions'
      ? (variation?.theme?.[name] ?? [])?.[actionIndex]?.bg_color
      : variation?.theme?.[name];

  const onColorChange = (hex) => {
    if (name === 'actions') {
      const actionsList = variation?.theme?.[name] || [];

      actionsList[actionIndex] = {
        ...(variation?.theme?.[name]?.[actionIndex] ?? {}),
        bg_color: hex,
      };

      onChange({
        ...(variation?.theme ?? {}),
        [name]: actionsList,
      });
    } else {
      onChange({
        ...(variation?.theme ?? {}),
        [name]: hex,
      });
    }
  };

  useEffect(() => {
    if (!color) onColorChange('#1b1b1d');
  }, [color]);

  return (
    <div
      style={{
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        flex: 3,
      }}
    >
      <input
        className="action-input"
        pattern="^#(?:[0-9a-fA-F]{3}){1,2}$"
        value={color ?? '#1b1b1d'}
        onChange={(event) => {
          onColorChange(event.target.value);
        }}
      />

      <div
        onClick={() => setShowSketchPicker(true)}
        className="color-picker-action"
        style={{ background: color }}
      >
        {showSketchPicker && (
          <div className="color-picker">
            <div
              className="cover"
              onClick={(e) => {
                e.stopPropagation();
                setShowSketchPicker(false);
              }}
            />
            <SketchPicker
              disableAlpha={true}
              color={color}
              onChange={(value) => onColorChange(value.hex)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { InputColorPicker };
