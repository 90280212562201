import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import ProductSegmentListContainer from './container/ProductSegmentListContainer';
import ProductSegmentArchiveListContainer from './container/ProductSegmentArchiveListContainer';
import ProductSegmentUpdateContainer from './container/ProductSegmentUpdateContainer';
import ProductSegmentSearchContainer from './container/ProductSegmentSearchContainer';
import ProductSegmentAnalyticsContainer from './container/ProductSegmentAnalyticsContainer';
import ProductSegmentOverviewContainer from './container/ProductSegmentOverviewContainer';

function ProductSegment(praps) {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}list`}>
        <ProductSegmentListContainer />
      </Route>
      <Route path={`${match.path}archive`}>
        <ProductSegmentArchiveListContainer {...praps} />
      </Route>
      <Route
        path={`${match.path}create/:source?`}
        component={(praps) => {
          return <ProductSegmentUpdateContainer {...praps} />;
        }}
      />
      <Route path={`${match.path}copy/:copyId`}>
        <ProductSegmentUpdateContainer {...praps} />
      </Route>
      <Route
        path={`${match.path}update/:id/:source?`}
        component={(praps) => {
          return <ProductSegmentUpdateContainer {...praps} />;
        }}
      />

      <Route path={`${match.path}:id/overview`}>
        <ProductSegmentOverviewContainer {...praps} />
      </Route>
      <Route path={`${match.path}:id/search`}>
        <ProductSegmentSearchContainer {...praps} />
      </Route>
      <Route path={`${match.path}:id/segment-analytics`}>
        <ProductSegmentAnalyticsContainer {...praps} />
      </Route>
    </Switch>
  );
}

export default ProductSegment;
