import React from 'react';
import '../../style/ProductSdkAndroidStyle.less';
const ProductSdkTabItem = (props) => {
  const styles = {
    nextIcon: {
      fontSize: '32px',
      fontWeight: '400',
      lineHeight: '40px',
      color: '#bbbcc1',
    },
  };

  return (
    <div className="level-container">
      <div onClick={props.onClick} className={props.className}>
        {props.children}
      </div>

      {props.arrayFinished && (
        <i className="fl-chevron-right" style={styles.nextIcon}></i>
      )}
    </div>
  );
};
export default ProductSdkTabItem;
