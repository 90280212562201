import BaseApi from '../../../api/BaseApi';

const basePath = '/channel/sms';
const enumBasePath = '/info/enum';
class SmsChannelApi {
  static create(body) {
    return BaseApi.post(basePath + '/create', body);
  }
  static delete(id) {
    return BaseApi.post(basePath + '/delete/' + id, {});
  }
  static refreshToken(id) {
    return BaseApi.post(basePath + '/refreshToken/' + id, {});
  }
  static list(data) {
    return BaseApi.post(basePath + '/list', data);
  }
  static update(id) {
    return BaseApi.post(basePath + '/update/' + id, {});
  }
  static view(id) {
    return BaseApi.post(basePath + '/view/' + id, {});
  }
  static updateSubmit(id, body) {
    return BaseApi.post(basePath + '/updateSubmit/' + id, body);
  }
  static enumList() {
    return BaseApi.post(enumBasePath + '/list', {});
  }
}

export default SmsChannelApi;
