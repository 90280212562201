import {
  PRODUCT_REST_API_VIEW_FETCH,
  PRODUCT_REST_API_VIEW_FETCH_FAIL,
  PRODUCT_REST_API_VIEW_FETCH_SUCCESS,
  GENERATE_TOKEN_FETCH,
  GENERATE_TOKEN_FETCH_FAIL,
  GENERATE_TOKEN_FETCH_SUCCESS,
  REVOKE_LOADING,
} from './ProductRestApiConstants';
import ProductRestApiApi from './ProductRestApiApi';

export function productRestApiViewFetch() {
  return (dispatch) => {
    dispatch({ type: PRODUCT_REST_API_VIEW_FETCH });
    ProductRestApiApi.view()
      .then((response) => {
        dispatch({
          type: PRODUCT_REST_API_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_REST_API_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function generateTokenFetch(body) {
  return (dispatch) => {
    dispatch({ type: GENERATE_TOKEN_FETCH });
    ProductRestApiApi.generateToken(body)
      .then((response) => {
        dispatch({
          type: GENERATE_TOKEN_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: GENERATE_TOKEN_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function revokeFetch() {
  return (dispatch) => {
    dispatch({ type: REVOKE_LOADING, payload: true });
    ProductRestApiApi.revoke()
      .then(() => {
        dispatch(productRestApiViewFetch());
      })
      .catch(() => {})
      .finally(() => {
        dispatch({
          type: REVOKE_LOADING,
          payload: false,
        });
      });
  };
}
