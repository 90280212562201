import {
  LoadingOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import ListBase, { defaultActions } from '@Component/form/ListBase';
import { Input, Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import styles from './BusinessEventListComponent.module.less';
import {
  businessEventListFetch,
  businessEventTriggerClearError,
} from '@Redux/slices/data/businessEventSlice';
import { useDispatch, useSelector } from 'react-redux';
import BusinessEventTriggerComponent from './BusinessEventTriggerComponent';
import { useHistory, useRouteMatch } from 'react-router';
import Card from '@Utils/Card';

export default function BusinessEventListComponent() {
  const dispatch = useDispatch();
  const loadingList = useSelector((state) => state.dataModule.dataApp.loading);
  const page = useSelector((state) => state.dataModule.businessEvent.list.page);
  const pageRequest = useSelector(
    (state) => state.dataModule.businessEvent.list.pageRequest
  );
  const [name, setName] = useState('');
  const [visible, setVisible] = useState(false);
  let match = useRouteMatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(
      businessEventListFetch({
        direction: 'DESC',
        enabled: true,
        order: 'modifiedDate',
        name: '',
        page: 0,
      })
    );
  }, []);

  const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
  const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  const productId = match.params.productId;
  const [currentBussinesEventAttr, setcurrentBussinesEventAttr] =
    useState(null);

  const showModal = (id) => {
    if (id) {
      setVisible(true);
      const current = page.content.find((cont) => cont.id === id);
      setcurrentBussinesEventAttr(current);
    }
  };

  const handleCancel = (form) => {
    form.resetFields();
    setVisible(false);
    dispatch(businessEventTriggerClearError());
  };
  const gotoCreate = () => {
    console.log('hello', match.params.productId);
    history.push(`business-events-new`);
  };
  const handleChangeSearch = (name) => {
    setName(name);
    if (name === '' || name == null) {
      dispatch(
        businessEventListFetch({
          ...pageRequest,
          page: 0,
          name: null,
        })
      );
    }
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'DATA TYPE',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'LAST RECEIVED	',
      dataIndex: 'lastreceived',
      key: 'lastreceived',
    },
    {
      title: 'RELAYS ATTACHED	',
      dataIndex: 'relayattached',
      key: 'relayattached',
    },
    {
      title: 'ACTION',
      key: 'action',
      fixed: 'left',
      width: '5%',
      render: (x) =>
        defaultActions(
          x,
          {
            action: (id) => showModal(id),
            title: 'Trigger',
            enabled: true,
            disablePopConfirm: true,
          }
          // ,
          // {
          //   action: (id) => {
          //     console.log('id:', id);
          //   },
          //   title: 'Add to relay',
          //   enabled: true,
          // }
        ),
    },
  ];

  var content = page?.content?.reduce((content, thing) => {
    if (!isEmpty(thing.attributes)) {
      content.push({
        ...thing,
        children: thing?.attributes.map((attr) => ({
          //id: thing.id,
          name: attr.name,
          type: attr.type,
        })),
      });
    } else {
      content.push({
        ...thing,
      });
    }
    return content;
  }, []);
  const data = {
    ...page,
    content,
  };
  return (
    <Card id={'business_event_list'}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          Business Event
          <PlusCircleOutlined
            className={styles.headerTitleAdd}
            id="int-add"
            onClick={() => gotoCreate()}
            style={styles.addButton}
          />
        </div>
        <Input
          loading={true}
          allowClear={loadingList ? false : true}
          placeholder={'Search by name'}
          className={styles.headerSearch}
          onPressEnter={() =>
            dispatch(
              businessEventListFetch({
                ...pageRequest,
                page: 0,
                name,
              })
            )
          }
          onChange={(e) => handleChangeSearch(e.target.value)}
          value={name}
          suffix={
            isEmpty(name) ? (
              <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            ) : (
              loadingList && (
                <div className={styles.inputLoadingSpin}>
                  <Spin indicator={inputLoadingSpin} />
                </div>
              )
            )
          }
        />
      </div>
      {loadingList ? (
        <div className={styles.loadingSpin}>
          <Spin indicator={loadingSpin} />
        </div>
      ) : (
        <>
          <ListBase
            onChange={(p) => {
              dispatch(
                businessEventListFetch({
                  ...pageRequest,
                  page: p.page,
                })
              );
            }}
            page={data}
            pageRequest={pageRequest}
            columns={columns}
            //expandable={{ childrenColumnName: 'attributes' }}
          />

          <BusinessEventTriggerComponent
            visible={visible}
            handleCancel={handleCancel}
            currentBussinesEventAttr={currentBussinesEventAttr}
            productId={productId}
            setVisible={setVisible}
          />
        </>
      )}
    </Card>
  );
}
