/* eslint-disable no-loop-func */
import { history } from '../../../redux/store';
import { showMessageError } from '../../../utils/RenderUtils';
import { filter, cloneDeep, isNil } from 'lodash';

import {
  PRODUCT_SEGMENT_SAVE_CLICKED,
  PRODUCT_SEGMENT_RESET_CLICKED,
  PRODUCT_SEGMENT_LIST_FETCH,
  PRODUCT_SEGMENT_LIST_FETCH_FAIL,
  PRODUCT_SEGMENT_LIST_FETCH_SUCCESS,
  PRODUCT_SEGMENT_LIST_RESET,
  PRODUCT_SEGMENT_CREATE_FETCH,
  PRODUCT_SEGMENT_CREATE_FETCH_FAIL,
  PRODUCT_SEGMENT_CREATE_FETCH_SUCCESS,
  PRODUCT_SEGMENT_CHANGE_CURRENT,
  PRODUCT_SEGMENT_UPDATE_FETCH,
  PRODUCT_SEGMENT_UPDATE_FETCH_FAIL,
  PRODUCT_SEGMENT_UPDATE_FETCH_SUCCESS,
  PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH,
  PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH_FAIL,
  PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH_SUCCESS,
  PRODUCT_SEGMENT_DELETE_FETCH,
  PRODUCT_SEGMENT_DELETE_FETCH_FAIL,
  PRODUCT_SEGMENT_DELETE_FETCH_SUCCESS,
  PRODUCT_SEGMENT_VIEW_FETCH,
  PRODUCT_SEGMENT_VIEW_FETCH_FAIL,
  PRODUCT_SEGMENT_VIEW_FETCH_SUCCESS,
  PRODUCT_SEGMENT_PAGE_CHANGE_CURRENT,
  PRODUCT_SEGMENT_COPY_FETCH_SUCCESS,
  PRODUCT_SEGMENT_COPY_FETCH,
  PRODUCT_SEGMENT_COPY_FETCH_FAIL,
  PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH,
  PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH_SUCCESS,
  PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH_FAIL,
  PRODUCT_SEGMENT_LIST_EVENTS_FETCH,
  PRODUCT_SEGMENT_LIST_EVENTS_FETCH_SUCCESS,
  PRODUCT_SEGMENT_LIST_EVENTS_FETCH_FAIL,
  PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH,
  PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH_SUCCESS,
  PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH_FAIL,
  PRODUCT_SEGMENT_REPORT_FETCH,
  PRODUCT_SEGMENT_REPORT_FETCH_SUCCESS,
  PRODUCT_SEGMENT_REPORT_FETCH_FAIL,
  PRODUCT_SEGMENT_REPORT_VIEW_FETCH,
  PRODUCT_SEGMENT_REPORT_VIEW_FETCH_SUCCESS,
  PRODUCT_SEGMENT_REPORT_VIEW_FETCH_FAIL,
  PRODUCT_SEGMENT_SIZE_REPORT_FETCH,
  PRODUCT_SEGMENT_SIZE_REPORT_FETCH_SUCCESS,
  PRODUCT_SEGMENT_SIZE_REPORT_FETCH_FAIL,
  PRODUCT_SEGMENT_ARCHIVE_FETCH,
  PRODUCT_SEGMENT_ARCHIVE_FETCH_FAIL,
  PRODUCT_SEGMENT_ARCHIVE_FETCH_SUCCESS,
  PRODUCT_SEGMENT_ACTIVATE_FETCH,
  PRODUCT_SEGMENT_ACTIVATE_FETCH_FAIL,
  PRODUCT_SEGMENT_ACTIVATE_FETCH_SUCCESS,
  PRODUCT_SEGMENT_RESET_COMPLETED,
  PRODUCT_SEGMENT_STATIC_CREATE_TOGGLE,
  PRODUCT_SEGMENT_FILE_UPLOAD_FETCH,
  PRODUCT_SEGMENT_FILE_UPLOAD_FETCH_SUCCESS,
  PRODUCT_SEGMENT_FILE_UPLOAD_FETCH_FAIL,
  PRODUCT_SEGMENT_FILE_UPLOAD_RESET,
  PRODUCT_SEGMENT_SEARCH_LIST_FETCH,
  PRODUCT_SEGMENT_SEARCH_LIST_FETCH_SUCCESS,
  PRODUCT_SEGMENT_SEARCH_LIST_FETCH_FAIL,
  CLEAR_SEGMENT_ERROR,
  PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH,
  PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH_FAIL,
  PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH_SUCCESS,
  PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH,
  PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH_FAIL,
  PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH_SUCCESS,
  PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH,
  PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH_FAIL,
  PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH_SUCCESS,
  PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH_FAIL,
  PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH_SUCCESS,
  PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH,
} from './ProductSegmentConstants';
import ProductSegmentApi from './ProductSegmentApi';
import toastr from 'toastr';

export function productSegmentListAttributesFetch(body) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH,
    });
    ProductSegmentApi.listAttributes(body)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function productSegmentListEventsFetch(body) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_LIST_EVENTS_FETCH,
    });
    ProductSegmentApi.listEvents(body)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_LIST_EVENTS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_LIST_EVENTS_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function productSegmentListDeviceAttributesFetch(body) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH,
    });
    ProductSegmentApi.listDeviceAttributes(body)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

const loadProductSegmentList = (dispatch, productSegmentPageRequest) => {
  dispatch({
    type: PRODUCT_SEGMENT_LIST_FETCH,
    payload: productSegmentPageRequest,
  });
  ProductSegmentApi.list(productSegmentPageRequest)
    .then((response) => {
      dispatch({
        type: PRODUCT_SEGMENT_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: PRODUCT_SEGMENT_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function productSegmentListFetch(productSegmentPageRequest) {
  return (dispatch) =>
    loadProductSegmentList(dispatch, productSegmentPageRequest);
}
////////////////
const loadProductSearchSegmentList = (dispatch, productSegmentPageRequest) => {
  dispatch({
    type: PRODUCT_SEGMENT_SEARCH_LIST_FETCH,
    payload: productSegmentPageRequest,
  });
  ProductSegmentApi.list(productSegmentPageRequest)
    .then((response) => {
      dispatch({
        type: PRODUCT_SEGMENT_SEARCH_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: PRODUCT_SEGMENT_SEARCH_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function productSearchSegmentListFetch(productSegmentPageRequest) {
  return (dispatch) =>
    loadProductSearchSegmentList(dispatch, productSegmentPageRequest);
}
//////////////////

export function productSegmentListReset() {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_LIST_RESET,
    });
  };
}

export function productSegmentChangeCurrent(productSegment) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_CHANGE_CURRENT,
      payload: productSegment,
    });
  };
}

export function productSegmentPageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function productSegmentCreateFetch(
  productSegment,
  copyId,
  goBack = false
) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_CREATE_FETCH,
      payload: productSegment,
    });
    ProductSegmentApi.create(productSegment)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been created successfully');
        if (goBack) {
          history.goBack();
        } else if (copyId) {
          history.push('../list');
        } else {
          history.push('list');
        }
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_CREATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function productSegmentCsvCreateFetch(productSegment, pageRequest) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_CREATE_FETCH,
      payload: productSegment,
    });
    ProductSegmentApi.create(productSegment)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been created successfully');
        loadProductSegmentList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_CREATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function productSegmentCopyFetch(
  id,
  productSegmentPageRequest,
  fromDetails = false
) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_COPY_FETCH,
    });
    ProductSegmentApi.copy(id)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_COPY_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been duplicated successfully');
        if (fromDetails) {
          history.push('../list');
        } else {
          loadProductSegmentList(dispatch, productSegmentPageRequest);
        }
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_COPY_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function productSegmentReportFetch(productSegment) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_REPORT_FETCH,
      payload: productSegment,
    });
    ProductSegmentApi.report(productSegment)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_REPORT_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_REPORT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function productSegmentReportViewFetch(id) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_REPORT_VIEW_FETCH,
      payload: id,
    });
    ProductSegmentApi.reportView(id)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_REPORT_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_REPORT_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function productSegmentSizeReport(body) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_SIZE_REPORT_FETCH,
      payload: body,
    });
    ProductSegmentApi.segmentSizeReport(body)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_SIZE_REPORT_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_SIZE_REPORT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function productSegmentArchive(id) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_ARCHIVE_FETCH,
    });
    ProductSegmentApi.archive(id)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_ARCHIVE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been archived successfully');
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_ARCHIVE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function productSegmentActivate(id) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_ACTIVATE_FETCH,
    });
    ProductSegmentApi.activate(id)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_ACTIVATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been activated successfully');
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_ACTIVATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function productSegmentUpdateFetch(id) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_UPDATE_FETCH,
    });
    ProductSegmentApi.update(id)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function productSegmentSaveClicked() {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_SAVE_CLICKED,
    });
  };
}

export function productSegmentStaticCreateToggle(open) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_STATIC_CREATE_TOGGLE,
      payload: open,
    });
  };
}

export function productSegmentFileUploadFetch(file) {
  return (dispatch) => {
    dispatch({ type: PRODUCT_SEGMENT_FILE_UPLOAD_FETCH });
    ProductSegmentApi.uploadFile(file)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_FILE_UPLOAD_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_FILE_UPLOAD_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function productSegmentFileUploadReset() {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_FILE_UPLOAD_RESET,
    });
  };
}

export function productSegmentResetClicked() {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_RESET_CLICKED,
    });
  };
}

export function productSegmentResetCompleted() {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_RESET_COMPLETED,
    });
  };
}

export function productSegmentUpdateSubmitFetch(
  id,
  productSegment,
  goBack = false
) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH,
      payload: productSegment,
    });
    ProductSegmentApi.updateSubmit(id, productSegment)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been updated successfully');
        if (goBack) {
          history.goBack();
        } else {
          history.push('../list');
        }
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function productSegmentDeleteFetch(
  id,
  productSegmentPageRequest,
  fromDetails = false
) {
  return (dispatch) => {
    dispatch({ type: PRODUCT_SEGMENT_DELETE_FETCH });
    ProductSegmentApi.delete(id)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_DELETE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been deleted successfully');
        if (fromDetails) {
          history.push('../list');
        } else {
          loadProductSegmentList(dispatch, productSegmentPageRequest);
        }
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_DELETE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function productSegmentViewFetch(id) {
  return (dispatch) => {
    dispatch({ type: PRODUCT_SEGMENT_VIEW_FETCH });
    ProductSegmentApi.view(id)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function clearSegmentError(errors, errorKey = null) {
  return (dispatch) => {
    if (errorKey === null) {
      dispatch({
        type: CLEAR_SEGMENT_ERROR,
        payload: [],
      });
    } else {
      if (Array.isArray(errorKey) && !isNil(errorKey)) {
        let filteredErrors = cloneDeep(errors);
        for (var i = 0; i <= errorKey.length; i++) {
          filteredErrors = filter(filteredErrors, (o) => o.key !== errorKey[i]);
        }
        dispatch({
          type: CLEAR_SEGMENT_ERROR,
          payload: filteredErrors,
        });
      } else {
        const filteredErrors = filter(errors, (o) => o.key !== errorKey);
        dispatch({
          type: CLEAR_SEGMENT_ERROR,
          payload: filteredErrors,
        });
      }
    }
  };
}

export function productSegmentRecommendUserAttributesFetch(userAttributeId) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH,
    });
    ProductSegmentApi.recommendUserAttributes(userAttributeId)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH_SUCCESS,
          payload: { response, userAttributeId },
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function productSegmentRecommendEventAttributesFetch(eventAttributeId) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH,
    });
    ProductSegmentApi.recommendEventAttributes(eventAttributeId)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH_SUCCESS,
          payload: { response, eventAttributeId },
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function productSegmentRecommendDeviceAttributesFetch(
  deviceAttributeId
) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH,
    });
    ProductSegmentApi.recommendDeviceAttributes(deviceAttributeId)
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH_SUCCESS,
          payload: { response, deviceAttributeId },
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function productSegmentListProductSourseFetch() {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH,
    });
    ProductSegmentApi.listSourceProducts()
      .then((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
