import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form, Input, Button, Select, Badge, Tooltip } from 'antd';

import './funnelCreate.less';
import ModalEventFilterSelector from './ModalEventFilterSelector';
import Card from '@Utils/Card';
import { useParams } from 'react-router-dom';

const FunnelCreate = (props) => {
  const { Option, OptGroup } = Select;
  const { id } = useParams();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const productId = props.match.params.productId;

  useEffect(() => {
    props.listEvents(productId);
    if (id) {
      props.viewUpdateFunnel(id);
    } else {
      props.resetFunnelCreateUpdateContent();
    }
  }, []);

  useEffect(() => {
    setLoading(props.events.fetching || props.createUpdate.fetching);
  }, [props.events.fetching, props.createUpdate.fetching]);

  const onFinish = () => {
    if (!id) {
      props.createFunnel(props.createUpdate.content, () => {
        props.history.push('./list');
      });
    } else {
      props.updateFunnel(id, props.createUpdate.content, () => {
        props.history.push('./overview');
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const renderEventOptions = (eventType) => {
    let systemItems = [];
    let customItems = [];
    if (props.events.content && Array.isArray(props.events.content)) {
      props.events.content.forEach((item) => {
        if (item.system) {
          systemItems.push(
            <Option key={item.id} value={item.id}>
              {item.title}
            </Option>
          );
        } else {
          customItems.push(
            <Option key={item.id} value={item.id}>
              {item.title}
            </Option>
          );
        }
      });
      if (eventType === 'system') {
        return systemItems;
      } else if (eventType === 'custom') {
        return customItems;
      }
    }
  };

  const renderEventSelector = (idx, onChange) => {
    const currentState = Object.assign({}, props.createUpdate.content);
    const currentStateStep = currentState.steps[idx];
    return (
      <div className="funnel_events">
        <Select
          showSearch
          style={{ width: '100%' }}
          value={props.createUpdate.content.steps[idx].event?.id}
          placeholder={'Select an Event'}
          onChange={(value) => {
            currentStateStep.event = { id: value };
            currentStateStep.predicates = [];
            onChange(currentState);
          }}
          required
          optionFilterProp="children"
        >
          <OptGroup label="SYSTEM">{renderEventOptions('system')}</OptGroup>
          <OptGroup label="CUSTOM">{renderEventOptions('custom')}</OptGroup>
        </Select>
        <Badge
          count={
            currentStateStep.predicates &&
            Array.isArray(currentStateStep.predicates) &&
            currentStateStep.predicates.length
          }
          showZero={false}
        >
          <div
            onClick={() => {
              if (currentStateStep.event?.id) {
                setIsModalVisible(currentStateStep.event?.id);
              }
            }}
            className="fl-filter fl-dark pointer"
            style={{ fontSize: 28 }}
          ></div>
        </Badge>
        <div
          onClick={() => {
            currentState.steps.splice(idx, 1);
            onChange(currentState);
          }}
          className="fl-delete fl-dark pointer"
          style={{ fontSize: 28, marginLeft: 5 }}
        ></div>
        <ModalEventFilterSelector
          currentPredicate={{
            ...currentStateStep,
            event: currentStateStep.event?.id,
          }}
          isModalVisible={currentStateStep.event?.id === isModalVisible}
          events={props.events.content}
          onCancelHandler={() => setIsModalVisible(false)}
          onOkHandler={(changedPredicate) => {
            currentState.steps[idx] = {
              ...changedPredicate,
              event: {
                id: changedPredicate.event,
              },
            };
            console.log(currentState.steps[idx]);
            props.triggerFunnelFetchSuccess(currentState);
            setIsModalVisible(false);
          }}
          handleChangePredicate={() => {}}
          fetchRecommendEventAttributes={
            props.productSegmentRecommendEventAttributesFetch
          }
          recommendEventAttributes={props.recommendEventAttributes}
        />
      </div>
    );
  };

  const formIsValid =
    props.createUpdate.content.name &&
    props.createUpdate.content.steps.length &&
    props.createUpdate.content.steps.every((step) => step.event?.id);

  return (
    <div className="funnel">
      <Card styles={{ padding: 20 }} loading={loading}>
        <Form
          name="funnel"
          initialValues={{
            funnelName: props.createUpdate.content?.name,
            funnelStartDate:
              (!!props.createUpdate.content?.startDate &&
                moment(props?.createUpdate.content?.startDate)) ||
              moment().subtract(30, 'd'),
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="funnel-name">
            <div className="funnel-name_title">FUNNEL NAME</div>
            <div className="funnel-name_input">
              <Form.Item
                name="funnelName"
                rules={[
                  {
                    required: true,
                    message: 'this field is required',
                  },
                ]}
              >
                <Input
                  value={props.createUpdate.content?.name}
                  onChange={(e) => {
                    props.triggerFunnelFetchSuccess({
                      ...props.createUpdate.content,
                      name: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </div>
          </div>
          {props.createUpdate.content.steps.map((step, idx) => (
            <div key={idx} className="funnel-step">
              <div className="funnel-step_title">STEP {idx + 1}</div>
              <div className="funnel-step_input">
                {renderEventSelector(idx, props.triggerFunnelFetchSuccess)}
              </div>
            </div>
          ))}
          <div className="funnel_add-step">
            <Button
              type="primary"
              ghost
              shape="round"
              onClick={() => {
                const currentState = Object.assign(
                  {},
                  props.createUpdate.content
                );
                currentState.steps.push({
                  event: null,
                  type: 'BEHAVIOUR',
                  conjunction: 'AND',
                });
                props.triggerFunnelFetchSuccess(currentState);
              }}
            >
              ADD STEP
            </Button>
          </div>
          <div className="dnd-footer">
            <Tooltip title={formIsValid ? '' : 'please field all fields'}>
              <Button
                loading={props.createUpdate.fetching}
                type="primary"
                shape="round"
                size={'large'}
                htmlType="submit"
                disabled={!formIsValid}
              >
                SAVE
              </Button>
            </Tooltip>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default FunnelCreate;
