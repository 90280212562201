/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import '../../style/ProductSdkAndroidStyle.less';
import texts from '../../../../../utils/texts';
import TrackEventFragmentReactNative from './trackEvents/TrackEventFragmentReactNative';
import TrackEventFragmentFlutter from './trackEvents/TrackEventFragmentFlutter';
import TrackEventFragmentAndroid from './trackEvents/TrackEventFragmentAndroid';
import TrackEventFragmentIos from './trackEvents/TrackEventFragmentIos';
import TrackEventFragmentWeb from './trackEvents/TrackEventFragmentWeb';

const TrackEventFragment = (props) => {
  const [highlight, setHighlight] = useState(null);
  useEffect(() => {
    switch (props.sdkType) {
      case 'react-native':
        setHighlight(null);
        setTimeout(() => {
          setHighlight(
            '       EventData: {\n' +
              '           eventName: String, \n' +
              '           eventData: JsonObject, \n' +
              '       } \n'
          );
        }, 100);
        break;
      case 'android':
        setHighlight(null);
        setTimeout(() => {
          setHighlight(
            '       HashMap<String, Object> eventDetails = new HashMap<String, Object>();\n' +
              '       eventDetails.put("country", "Iran");, \n' +
              '       ventDetails.put("app_version", "1.0"); \n' +
              '\n' +
              `       ${texts.BrandName}.recordEvent("custom_event_name", eventDetails); \n`
          );
        }, 100);
        break;
      case 'ios':
        setHighlight(null);
        setTimeout(() => {
          setHighlight(
            ` ${texts.BrandName}.recordEvent(eventName: String!, eventData: [String : String]!> \n`
          );
        }, 100);
        break;
      default:
        setHighlight(null);
        setTimeout(() => {
          setHighlight(
            '       EventData: {\n' +
              '           eventName: String, \n' +
              '           eventData: JsonObject, \n' +
              '       } \n'
          );
        }, 100);
    }
  }, [props]);

  let content =
    props.sdkType === 'flutter' ? (
      <TrackEventFragmentFlutter highlight={highlight} />
    ) : props.sdkType === 'react-native' ? (
      <TrackEventFragmentReactNative highlight={highlight} />
    ) : props.sdkType === 'android' ? (
      <TrackEventFragmentAndroid highlight={highlight} />
    ) : props.sdkType === 'ios' ? (
      <TrackEventFragmentIos highlight={highlight} />
    ) : (
      <TrackEventFragmentWeb highlight={highlight} />
    );
  return <div>{content}</div>;
};
export default TrackEventFragment;
