export const profileType = {
  vendor: 'فروشنده',
  customer: 'خریدار',
};
export const profileVendor = {
  name: 'شرکت اطلس ارتباط رامان',
  registrationCode: '521875',
  ecomomicCode: '14007365912',
  nationalCode: '14007365912',
  address: ' شهرک قدس (غرب)، خیابان مهستان، کوچه یازدهم، پلاک ۱۵ ، طبقه ۱',
  zipCode: '1465754461',
  phone: '012345',
};

export const invoiceLable = {
  invoiceTitle: 'پیش فاکتور فروش کالا و خدمات',
  invoiceCode: 'شماره',
  invoiceDate: 'تاریخ',
  seller: 'فروشنده',
  buyer: 'خریدار',
  name: 'نام شخص حقیقی/حقوقی',
  registrationCode: 'شماره ثبت',
  economicCode: 'کد اقتصادی',
  nationalID: 'شناسه ملی',
  nationalCode: 'کد ملی',
  address: 'آدرس',
  zipCode: 'کد پستی',
  phone: 'تلفن/نمابر',
  productDetails: 'مشخصات کالا و خدمات',
  number: 'ردیف',
  productServices: 'شرح کالا / خدمات',
  quantity: 'تعداد',
  unit: 'واحد',
  unitPrice: 'مبلغ واحد (ریال)',
  totalPrice: 'مبلغ کل (ریال)',
  discountAmount: 'مبلغ تخفیف (ریال)',
  totalDiscountedPrice: 'مبلغ کل پس از تخفیف (ریال)',
  taxCharges: 'مالیات و عوارض ارزش افزوده (ریال)',
  totalPriceAfterTax: 'مبلغ کل بعلاوه مالیات و عوارض (ریال)',
  intrack: 'حق استفاده از داشبورد اینترک',
  events: 'پردازش داده مشتریان',
  grandTotal: 'جمع کل به حروف',
  salesConditions: 'شرایط و نحوه فروش',
  month: 'ماه',
  eventNumber: 'ایونت',
  total: 'جمع کل',
  totalInWords: 'جمع کل به حروف',
  description: 'توضیحات',
  sellerSignature: 'امضای فروشنده',
  buyerSignature: 'امضای خریدار',
  cash: 'نقدی  ☑',
  notCash: 'غیر نقدی ☐',
  rial: 'ریال',
};
