import BaseApi from '../../../api/BaseApi';

const basePath = '/funnel';
const executionBasePath = '/funnel-execution';
class FunnelApi {
  static create(body, deepInIntegration = false) {
    const path = deepInIntegration
      ? `${basePath}/create/`
      : `${basePath}/create/`;
    return BaseApi.post(path, body);
  }

  static view(id) {
    return BaseApi.post(basePath + '/view/' + id, {});
  }

  static update(id, body) {
    return BaseApi.post(basePath + '/update/' + id, body);
  }

  static getEvents() {
    return BaseApi.post('/insight/productSegment/listEvents', {});
  }

  static execute(params) {
    return BaseApi.post(basePath + '/execute/', params);
  }

  static forceExecute(params) {
    return BaseApi.post(basePath + '/force-execute/', params);
  }

  static list(params) {
    return BaseApi.post(basePath + '/list/', params);
  }

  static delete(id) {
    return BaseApi.post(basePath + '/delete/' + id, {});
  }

  static viewExecution(id) {
    return BaseApi.post(executionBasePath + '/view/' + id, {});
  }

  static stopExecution(id) {
    return BaseApi.post(executionBasePath + '/stop/' + id, {});
  }
}

export default FunnelApi;
