import ContractTemplateApi from '@Redux/slices/admin/contractTemplateApi';
import { showMessageError } from '@Utils/RenderUtils';
import BaseApi from '../../../api/BaseApi';
import toastr from 'toastr';

const basePath = '/admin/product';

class AdminCreateProductApi {
  static create(body) {
    return BaseApi.post(basePath + '/create-with-contract', body);
  }

  static listParents(body) {
    return BaseApi.post(basePath + '/listParents', body);
  }
}

export async function adminProductCreateWithContract({ body, setLoading, cb }) {
  setLoading(true);
  await AdminCreateProductApi.create(body)
    .then(() => {
      cb && cb();
      toastr.success('Has been created successfully');
    })
    .catch((err) => {
      showMessageError(err);
    })
    .finally(() => {
      setLoading(false);
    });
}

export async function getContractTemplates({ cb }) {
  await ContractTemplateApi.list({})
    .then((res) => {
      cb && cb(res);
    })
    .catch((err) => {
      showMessageError(err);
    });
}

export async function getParentProducts({ cb }) {
  await AdminCreateProductApi.listParents({})
    .then((res) => {
      cb && cb(res);
    })
    .catch((err) => {
      showMessageError(err);
    });
}
