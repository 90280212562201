import React from 'react';
import { Form, Input } from 'antd';
import FormBase from '../../../../component/form/FormBase';

class ProductSettingScheduledReportComponent extends React.Component {
  componentDidMount() {
    this.props.productUpdateFetch();
  }

  componentWillUnmount() {
    this.props.productChangeCurrent({});
  }

  onFinish(values) {
    this.props.productUpdateSubmitFetch(values);
  }

  getNavigation() {
    return [
      { name: 'Product Settings', path: 'view' },
      { name: 'Update Product Settings', path: '' },
    ];
  }

  render() {
    return (
      <FormBase
        errors={this.props.errors}
        value={this.props.currentProduct}
        onFinish={(values) => this.onFinish(values)}
        onChange={(values) => this.props.productChangeCurrent(values)}
        back={'view'}
      >
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
      </FormBase>
    );
  }
}

export default ProductSettingScheduledReportComponent;
