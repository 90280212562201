exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ReportChartSection-module__headerWrapper--1N8IG {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 16px;\n}\n.ReportChartSection-module__headerDatePicker--2nBZI {\n  width: 260px;\n  margin-right: 4px !important;\n  background-color: white !important;\n}\n.ReportChartSection-module__headerProductSelect--2taRc {\n  width: 150px !important;\n  margin-right: 4px !important;\n}\n.ReportChartSection-module__checkListMenuWrapper--2wVzl {\n  width: 220;\n  background-color: white;\n  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;\n  border-radius: 2;\n}\n.ReportChartSection-module__checkListMenu--3OITK {\n  max-height: 300px !important;\n  overflow-y: scroll !important;\n}\n.ReportChartSection-module__reportCardWrapper--3hJBy {\n  background-color: white;\n  border-radius: 5px;\n  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);\n  padding: 20px;\n}\n.ReportChartSection-module__reportCardHeader--3_-hz {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 16px;\n}\n.ReportChartSection-module__reportCardTitle--2_ZMq {\n  font-size: 24px;\n  font-weight: 600;\n  color: #1B1B1D;\n}\n.ReportChartSection-module__reportCardContentTitle--iFdWq {\n  font-size: 18px;\n  font-weight: 600;\n  color: #38373B;\n}\n.ReportChartSection-module__reportCardContentDesc--2y6OB {\n  font-size: 12px;\n  font-weight: 500;\n  color: #767577;\n}\n", ""]);

// exports
exports.locals = {
	"headerWrapper": "ReportChartSection-module__headerWrapper--1N8IG",
	"headerDatePicker": "ReportChartSection-module__headerDatePicker--2nBZI",
	"headerProductSelect": "ReportChartSection-module__headerProductSelect--2taRc",
	"checkListMenuWrapper": "ReportChartSection-module__checkListMenuWrapper--2wVzl",
	"checkListMenu": "ReportChartSection-module__checkListMenu--3OITK",
	"reportCardWrapper": "ReportChartSection-module__reportCardWrapper--3hJBy",
	"reportCardHeader": "ReportChartSection-module__reportCardHeader--3_-hz",
	"reportCardTitle": "ReportChartSection-module__reportCardTitle--2_ZMq",
	"reportCardContentTitle": "ReportChartSection-module__reportCardContentTitle--iFdWq",
	"reportCardContentDesc": "ReportChartSection-module__reportCardContentDesc--2y6OB"
};