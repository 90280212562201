import AnalyticsComponent from '../../../../component/analyticsChart/AnalyticsComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  userAttributeListFetch,
  userAnalyzeFetch,
  userChangeCurrent,
} from '../../../track/users/UsersActions';
import { productSegmentSizeReport } from '../../../insight/productSegment/ProductSegmentActions';
const mapStateToProps = (state) => ({
  isRefreshed: state.track.users.isRefreshed,
  pathname: state.router.location.pathname,
  userAnalyticsValue: state.track.users.userAnalyticsValue,
  userAttributeList: state.track.users.userAttributeList,
  userAttributesResult: state.track.users.userAttributesResult,
  userAttributeLoading: state.track.users.userAttributeLoading,
  userAnalyticsFetchLoading: state.track.users.userAnalyticsFetchLoading,
  segmentSizeReport: state.insight.productSegment.segmentSizeReport,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userAttributeListFetch,
      userAnalyzeFetch,
      userChangeCurrent,
      productSegmentSizeReport,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsComponent);

AnalyticsComponent.propTypes = {};
