import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Spin } from 'antd';
import style from './GatewayResponse.module.less';
import failed from '../../../../../theme/icons/failed.svg';
import success from '../../../../../theme/icons/success.svg';
import { useDispatch, useSelector } from 'react-redux';
import { readableDateTime } from '@Utils/DateUtils';
import { LoadingOutlined } from '@ant-design/icons';
import StripedBox from '@Component/app/StripedBox';
import { fetchTransactionResponse } from '@Redux/slices/setting/billingSlice';
import { numberWithCommas } from '@Utils/RenderUtils';
function GatewayResponse() {
  const history = useHistory();
  const { transactionInfo, loading } = useSelector(
    (state) => state.setting.billing
  );

  const type = transactionInfo?.status || 'CANCELED';
  const dispatch = useDispatch();
  const query = new URLSearchParams(history.location.search);
  const token = query.get('token');
  useEffect(() => {
    dispatch(fetchTransactionResponse(token));
  }, []);
  const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;

  const errorMessage =
    'Parsian payment failed. There was an error communicating with Parsian. Please try again later';
  const readableDate = useMemo(
    () => readableDateTime(transactionInfo?.createdDate),
    [transactionInfo?.createdDate]
  );
  const formattedAmount = useMemo(
    () => numberWithCommas(transactionInfo?.amount),
    [transactionInfo?.amount]
  );

  const rows = [
    {
      columns: [
        <span key="0">Reference number</span>,
        <span key="1"> {transactionInfo?.referenceNo}</span>,
      ],
    },
    {
      columns: [
        <span key="0">Payment Date</span>,
        <span key="1"> {readableDate}</span>,
      ],
    },
    {
      columns: [
        <span key="0">Receipt Date</span>,
        <span key="1">
          <span style={{ color: 'rgba(56, 55, 59, 0.65)', paddingRight: 2 }}>
            From
          </span>
          {transactionInfo?.invoice?.startDate}
          <span style={{ color: 'rgba(56, 55, 59, 0.65)', padding: '0 2px' }}>
            to
          </span>
          {transactionInfo?.invoice?.endDate}
        </span>,
      ],
    },
    {
      columns: [
        <span key="0">Amount</span>,
        <span key="1"> {formattedAmount} Rial</span>,
      ],
    },
    {
      columns: [
        <span key="0">Payment Method</span>,
        <span key="1"> {transactionInfo?.gateway}</span>,
      ],
    },
  ];
  const cols = [
    {
      width: '50%',
      textAlign: 'left',
      color: 'rgba(56, 55, 59, 0.8)',
      fontWeight: '400',
    },
    {
      width: '50%',
      overflowWrap: 'anywhere',
      textAlign: 'left',
      color: '#38373BCC',
      fontWeight: '500',
    },
  ];
  return (
    <div className={style.response}>
      {loading ? (
        <Spin indicator={loadingSpin} />
      ) : (
        <div className={style.responseCard}>
          <div className="response_icon">
            <img src={`${type === 'COMPLETED' ? success : failed}`} />
          </div>
          <h2>
            {type === 'COMPLETED' ? 'Payment Successful' : 'Payment Failed'}
          </h2>

          <div className={style.responseData}>
            {type === 'COMPLETED' ? (
              <StripedBox cols={cols} rows={rows} bg={'#fff'} />
            ) : (
              <span>{errorMessage}</span>
            )}
          </div>

          <Button
            className={style.responseButton}
            onClick={() => {
              history.push('/');
            }}
            type="primary"
            shape="round"
          >
            Back to Home
          </Button>
        </div>
      )}
    </div>
  );
}

export default GatewayResponse;
