import PagesWrapper from '@Component/layout/PagesWrapper';
import PagesHeader from '@Component/layout/PagesHeader';
import CreateProductForm from './CreateProductForm';
import React from 'react';

const AdminCreateProduct = () => {
  return (
    <>
      <PagesHeader title="Create Product" />
      <PagesWrapper>
        <CreateProductForm />
      </PagesWrapper>
    </>
  );
};

export default AdminCreateProduct;
