const NotRestrictionOperator = {
  //GENERAL
  EQUAL_TO: 'Not Equal to',
  IS_NOT_EMPTY: 'Is Empty',
  //STRING
  ONE_OF: 'Not One of',
  STARTS_WITH: 'Does Not Start with',
  ENDS_WITH: 'Does Not End with',
  MATCHES_REGEX: 'Does Not Match regex',
  CONTAINS: 'Does Not Contain',
  //NUMERIC_DATE
  BETWEEN: 'Not Between',
  // Date
  DATE_EQUALS: 'Date Not Equal to',
};

export default NotRestrictionOperator;
