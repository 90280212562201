import MembersUpdateComponent from '../component/MembersUpdateComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  listProductRolesFetch,
  newTeaMemberChangeCurrent,
  teamCreateFetch,
  teamUpdateFetch,
  teamUpdateSubmitFetch,
} from '../TeamMembersActions';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  roleList: state.integration.teamMembers.roleList,
  loadingRoleList: state.integration.teamMembers.loadingRoleList,
  currentTeamMember: state.integration.teamMembers.currentTeamMember,
  createTeamLoading: state.integration.teamMembers.createTeamLoading,
  updateTeamLoading: state.integration.teamMembers.updateTeamLoading,
  updateSubmitLoading: state.integration.teamMembers.updateSubmitLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      listProductRolesFetch,
      newTeaMemberChangeCurrent,
      teamCreateFetch,
      teamUpdateFetch,
      teamUpdateSubmitFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersUpdateComponent);

MembersUpdateComponent.propTypes = {};
