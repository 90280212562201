import React from 'react';
import { Button } from 'antd';
import '../../style/ProductSdkAndroidStyle.less';
const ProductSdkButton = (props) => {
  return (
    <Button
      type="primary"
      shape="round"
      size="large"
      ghost
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};
export default ProductSdkButton;
