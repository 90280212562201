import React from 'react';
import isNil from 'lodash/isNil';
import findIndex from 'lodash/findIndex';
import clone from 'lodash/clone';
import { Divider, Menu, Empty, Checkbox, Button } from 'antd';
class TypeSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTypes: this.props.selectedTypes,
      filteredTypes: this.props.listTypes,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedTypes !== prevProps.selectedTypes) {
      this.setState({ selectedTypes: this.props.selectedTypes });
    }
    if (this.props.filteredTypes !== prevProps.filteredTypes) {
      this.setState({ filteredTypes: this.props.filteredTypes });
    }
  }

  isChecked = (name) => {
    let isChecked = false;
    if (this.state.selectedTypes) {
      isChecked = findIndex(this.state.selectedTypes, (o) => o === name) !== -1;
    }
    return isChecked;
  };

  handleCheck = (name) => {
    if (!isNil(this.state.selectedTypes)) {
      const foundIndex = findIndex(this.state.selectedTypes, (o) => o === name);
      if (foundIndex !== -1) {
        const cloned = clone(this.state.selectedTypes);
        cloned.splice(foundIndex, 1);
        this.setState({ selectedTypes: cloned });
      } else {
        this.setState({ selectedTypes: [...this.state.selectedTypes, name] });
      }
    } else {
      this.setState({ selectedTypes: [name] });
    }
  };

  handleChangeTypeSearch = (value) => {
    let filteredTypes = clone(this.props.listTypes);
    if (value && value.trim()) {
      const condition = new RegExp(value, 'i');
      filteredTypes = this.props.listTypes.filter((item) => {
        return condition.test(item.name);
      });
    }
    this.setState({ filteredTypes });
  };

  render() {
    return (
      <Menu>
        <div
          style={{
            width: 220,
            backgroundColor: 'white',
            boxShadow:
              '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
            borderRadius: 2,
          }}
        >
          {this.state.filteredTypes &&
          Array.isArray(this.state.filteredTypes) &&
          this.state.filteredTypes.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <Menu
              selectable={false}
              style={{ maxHeight: 200, overflow: 'scroll' }}
            >
              {this.state.filteredTypes.map((item) => (
                <Menu.Item key={item.name}>
                  <Checkbox
                    style={{ width: 200 }}
                    onChange={() => this.handleCheck(item.name)}
                    checked={this.isChecked(item.name)}
                  >
                    {item.title}
                  </Checkbox>
                </Menu.Item>
              ))}
            </Menu>
          )}
          <Divider style={{ margin: '5px 0 0 0' }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              padding: 10,
            }}
          >
            {false && (
              <Button
                shape="round"
                type="link"
                style={{ marginRight: 20 }}
                size={'small'}
              >
                RESET
              </Button>
            )}
            <Button
              type="primary"
              shape="round"
              size={'small'}
              onClick={() => this.props.onApply(this.state.selectedTypes)}
            >
              APPLY
            </Button>
          </div>
        </div>
      </Menu>
    );
  }
}

export default TypeSelector;

TypeSelector.propTypes = {};
