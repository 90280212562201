export const PUSH_CHANNEL_DELETE_FETCH = 'PUSH_CHANNEL_DELETE_FETCH';
export const PUSH_CHANNEL_DELETE_FETCH_FAIL = 'PUSH_CHANNEL_DELETE_FETCH_FAIL';
export const PUSH_CHANNEL_DELETE_FETCH_SUCCESS =
  'PUSH_CHANNEL_DELETE_FETCH_SUCCESS';

export const PUSH_CHANNEL_ANDROID_CREATE_FETCH =
  'PUSH_CHANNEL_ANDROID_CREATE_FETCH';
export const PUSH_CHANNEL_ANDROID_CREATE_FETCH_FAIL =
  'PUSH_CHANNEL_ANDROID_CREATE_FETCH_FAIL';
export const PUSH_CHANNEL_ANDROID_CREATE_FETCH_SUCCESS =
  'PUSH_CHANNEL_ANDROID_CREATE_FETCH_SUCCESS';

export const PUSH_CHANNEL_IOS_CREATE_FETCH = 'PUSH_CHANNEL_IOS_CREATE_FETCH';
export const PUSH_CHANNEL_IOS_CREATE_FETCH_FAIL =
  'PUSH_CHANNEL_IOS_CREATE_FETCH_FAIL';
export const PUSH_CHANNEL_IOS_CREATE_FETCH_SUCCESS =
  'PUSH_CHANNEL_IOS_CREATE_FETCH_SUCCESS';

export const PUSH_CHANNEL_ANDROID_LIST_FETCH =
  'PUSH_CHANNEL_ANDROID_LIST_FETCH';
export const PUSH_CHANNEL_ANDROID_LIST_FETCH_FAIL =
  'PUSH_CHANNEL_ANDROID_LIST_FETCH_FAIL';
export const PUSH_CHANNEL_ANDROID_LIST_FETCH_SUCCESS =
  'PUSH_CHANNEL_ANDROID_LIST_FETCH_SUCCESS';
export const PUSH_CHANNEL_ANDROID_LIST_CHANGE_CURRENT =
  'PUSH_CHANNEL_ANDROID_LIST_CHANGE_CURRENT';

export const PUSH_CHANNEL_IOS_LIST_FETCH = 'PUSH_CHANNEL_IOS_LIST_FETCH';
export const PUSH_CHANNEL_IOS_LIST_FETCH_FAIL =
  'PUSH_CHANNEL_IOS_LIST_FETCH_FAIL';
export const PUSH_CHANNEL_IOS_LIST_FETCH_SUCCESS =
  'PUSH_CHANNEL_IOS_LIST_FETCH_SUCCESS';
export const PUSH_CHANNEL_IOS_LIST_CHANGE_CURRENT =
  'PUSH_CHANNEL_IOS_LIST_CHANGE_CURRENT';

export const PUSH_CHANNEL_UPDATE_SUBMIT_FETCH =
  'PUSH_CHANNEL_UPDATE_SUBMIT_FETCH';
export const PUSH_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL =
  'PUSH_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL';
export const PUSH_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS =
  'PUSH_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS';

export const PUSH_CHANNEL_ANDROID_CHANGE_CURRENT =
  'PUSH_CHANNEL_ANDROID_CHANGE_CURRENT';
export const PUSH_CHANNEL_IOS_CHANGE_CURRENT =
  'PUSH_CHANNEL_IOS_CHANGE_CURRENT';

export const PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH =
  'PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH';
export const PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS =
  'PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS';
export const PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL =
  'PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL';
export const PUSH_CHANNEL_FIREBASE_CHANGE_CURRENT =
  'PUSH_CHANNEL_FIREBASE_CHANGE_CURRENT';

export const PUSH_CHANNEL_FIREBASE_CREATE_FETCH =
  'PUSH_CHANNEL_FIREBASE_CREATE_FETCH';
export const PUSH_CHANNEL_FIREBASE_CREATE_FETCH_FAIL =
  'PUSH_CHANNEL_FIREBASE_CREATE_FETCH_FAIL';
export const PUSH_CHANNEL_FIREBASE_CREATE_FETCH_SUCCESS =
  'PUSH_CHANNEL_FIREBASE_CREATE_FETCH_SUCCESS';

export const PUSH_CHANNEL_BROWSER_CHANGE_CURRENT =
  'PUSH_CHANNEL_BROWSER_CHANGE_CURRENT';

export const PUSH_CHANNEL_BROWSER_CREATE_FETCH =
  'PUSH_CHANNEL_BROWSER_CREATE_FETCH';
export const PUSH_CHANNEL_BROWSER_CREATE_FETCH_FAIL =
  'PUSH_CHANNEL_BROWSER_CREATE_FETCH_FAIL';
export const PUSH_CHANNEL_BROWSER_CREATE_FETCH_SUCCESS =
  'PUSH_CHANNEL_BROWSER_CREATE_FETCH_SUCCESS';

export const PUSH_CHANNEL_FIREBASE_LIST_FETCH =
  'PUSH_CHANNEL_FIREBASE_LIST_FETCH';
export const PUSH_CHANNEL_FIREBASE_LIST_FETCH_FAIL =
  'PUSH_CHANNEL_FIREBASE_LIST_FETCH_FAIL';
export const PUSH_CHANNEL_FIREBASE_LIST_FETCH_SUCCESS =
  'PUSH_CHANNEL_FIREBASE_LIST_FETCH_SUCCESS';
export const PUSH_CHANNEL_FIREBASE_LIST_CHANGE_CURRENT =
  'PUSH_CHANNEL_FIREBASE_LIST_CHANGE_CURRENT';

export const PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH =
  'PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH';
export const PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS =
  'PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS';
export const PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL =
  'PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL';

export const PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH =
  'PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH';
export const PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS =
  'PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS';
export const PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL =
  'PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL';

export const PUSH_CHANNEL_BROWSER_LIST_FETCH =
  'PUSH_CHANNEL_BROWSER_LIST_FETCH';
export const PUSH_CHANNEL_BROWSER_LIST_FETCH_FAIL =
  'PUSH_CHANNEL_BROWSER_LIST_FETCH_FAIL';
export const PUSH_CHANNEL_BROWSER_LIST_FETCH_SUCCESS =
  'PUSH_CHANNEL_BROWSER_LIST_FETCH_SUCCESS';
export const PUSH_CHANNEL_BROWSER_LIST_CHANGE_CURRENT =
  'PUSH_CHANNEL_BROWSER_LIST_CHANGE_CURRENT';

export const PUSH_CHANNEL_FETCH_ITEM_FAILED = 'PUSH_CHANNEL_FETCH_ITEM_FAILED';
