import { hasAnyAuthority, AuthorityProvider } from '@Utils/AuthorityProvider';

export const WhatsAppProvider = {
  INFOBIP: 'Infobip',
};

export const WhatsAppTemplateTypes = {
  TEXT: 'TEXT',
  // IMAGE: 'IMAGE',
  // VIDEO: 'VIDEO',
  // DOCUMENT: 'DOCUMENT',
  // LOCATION: 'LOCATION',
};

export const WhatsAppTemplateLanguages = {
  ENGLISH: {
    label: 'English',
    key: 'en',
  },
  PERSIAN: {
    label: 'Persian',
    key: 'fa',
  },
};

export const WhatsAppTemplateGeneralActionButtonTypes = {
  CALL_TO_ACTION: 'Call To Action',
  QUICK_REPLY: 'Quick Reply',
};

export const WhatsAppTemplateActionButtonTypes = {
  QUICK_REPLY: 'QUICK_REPLY',
  URL: 'URL',
  PHONE_NUMBER: 'PHONE_NUMBER',
};

export const WhatsAppTemplateCallToActionButtons = {
  URL: 'Visit Website',
  PHONE_NUMBER: 'Call Phone Number',
};

export const WhatsAppTemplateActionButtonURLTypes = {
  STATIC: 'Static',
  DYNAMIC: 'Dynamic',
};

export const actionButtonProperties = {
  [WhatsAppTemplateActionButtonTypes.PHONE_NUMBER]: {
    typeOfAction: WhatsAppTemplateActionButtonTypes.PHONE_NUMBER,
    text: '',
    phoneNumber: '',
  },
  [WhatsAppTemplateActionButtonTypes.URL]: {
    typeOfAction: WhatsAppTemplateActionButtonTypes.URL,
    text: '',
    url: '',
  },
  [WhatsAppTemplateActionButtonTypes.QUICK_REPLY]: {
    typeOfAction: WhatsAppTemplateActionButtonTypes.QUICK_REPLY,
    text: '',
  },
};

export const checkPermissions = () => {
  return {
    view: hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CHANNEL_WHATSAPP_VIEW),
    createOrUpdate: hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_CHANNEL_WHATSAPP_UPDATE,
      AuthorityProvider.ROLE_PRODUCT_CHANNEL_WHATSAPP_CREATE
    ),
    delete: hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_CHANNEL_WHATSAPP_DELETE
    ),
  };
};

export const checkTemplatePermissions = () => {
  return {
    view: hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_WHATSAPP_TEMPLATE_VIEW
    ),
    createOrUpdate: hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_WHATSAPP_TEMPLATE_UPDATE,
      AuthorityProvider.ROLE_PRODUCT_WHATSAPP_TEMPLATE_CREATE
    ),
    delete: hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_WHATSAPP_TEMPLATE_DELETE
    ),
  };
};
