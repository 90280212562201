import {
  WEBHOOK_CREATE_FETCH,
  WEBHOOK_CREATE_FETCH_FAIL,
  WEBHOOK_CREATE_FETCH_SUCCESS,
  WEBHOOK_CHANGE_CURRENT,
  WEBHOOK_DELETE_FETCH,
  WEBHOOK_DELETE_FETCH_FAIL,
  WEBHOOK_DELETE_FETCH_SUCCESS,
  WEBHOOK_LIST_FETCH,
  WEBHOOK_LIST_FETCH_FAIL,
  WEBHOOK_LIST_FETCH_SUCCESS,
  WEBHOOK_UPDATE_FETCH,
  WEBHOOK_UPDATE_FETCH_FAIL,
  WEBHOOK_UPDATE_FETCH_SUCCESS,
  WEBHOOK_UPDATE_SUBMIT_FETCH,
  WEBHOOK_UPDATE_SUBMIT_FETCH_FAIL,
  WEBHOOK_UPDATE_SUBMIT_FETCH_SUCCESS,
  WEBHOOK_WEBHOOK_VIEW_FETCH,
  WEBHOOK_WEBHOOK_VIEW_FETCH_FAIL,
  WEBHOOK_WEBHOOK_VIEW_FETCH_SUCCESS,
  WEBHOOK_CREATE_TOGGLE,
  WEBHOOK_PAGE_CHANGE_CURRENT,
  WEBHOOK_LIST_EVENTS,
} from './WebhookConstants';
import WebhookApi from './WebhookApi';
import toastr from 'toastr';
import ProductUserApi from '@Modules/track/users/UsersApi';
import { showMessageError } from '@Utils/RenderUtils';

export function webhookCreateFetch(body, pageRequest) {
  return (dispatch) => {
    dispatch({
      type: WEBHOOK_CREATE_FETCH,
    });
    WebhookApi.create(body)
      .then((response) => {
        dispatch({
          type: WEBHOOK_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been created successfully');
        dispatch(webhookListFetch(pageRequest));
        dispatch(webhookChangeCurrent({}));
      })
      .catch((response) => {
        dispatch({
          type: WEBHOOK_CREATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function webhookChangeCurrent(currentWebhook) {
  return (dispatch) => {
    dispatch({
      type: WEBHOOK_CHANGE_CURRENT,
      payload: currentWebhook,
    });
  };
}

export function webhookModalToggle(open) {
  return (dispatch) => {
    dispatch({
      type: WEBHOOK_CREATE_TOGGLE,
      payload: open,
    });
  };
}

export function webhookPageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: WEBHOOK_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function webhookDeleteFetch(id, pageRequest) {
  return (dispatch) => {
    dispatch({
      type: WEBHOOK_DELETE_FETCH,
    });
    WebhookApi.delete(id)
      .then((response) => {
        dispatch({
          type: WEBHOOK_DELETE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been deleted successfully');
        dispatch(webhookListFetch(pageRequest));
      })
      .catch((response) => {
        dispatch({
          type: WEBHOOK_DELETE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function webhookListFetch(body) {
  return (dispatch) => {
    dispatch({
      type: WEBHOOK_LIST_FETCH,
      payload: body,
    });
    WebhookApi.list(body)
      .then((response) => {
        dispatch({
          type: WEBHOOK_LIST_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: WEBHOOK_LIST_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function webhookUpdateFetch(id, cb) {
  return (dispatch) => {
    dispatch({
      type: WEBHOOK_UPDATE_FETCH,
    });
    WebhookApi.update(id)
      .then((response) => {
        dispatch({
          type: WEBHOOK_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
        cb && cb(response?.events);
      })
      .catch((response) => {
        dispatch({
          type: WEBHOOK_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function webhookUpdateSubmitFetch(id, body, pageRequest) {
  return (dispatch) => {
    dispatch({
      type: WEBHOOK_UPDATE_SUBMIT_FETCH,
    });
    WebhookApi.updateSubmit(id, body)
      .then((response) => {
        dispatch({
          type: WEBHOOK_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        dispatch(webhookListFetch(pageRequest));
        toastr.success('Has been updated successfully');
      })
      .then(() => {
        dispatch(webhookChangeCurrent({}));
      })
      .catch((response) => {
        dispatch({
          type: WEBHOOK_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function webhookViewFetch() {
  return (dispatch) => {
    dispatch({
      type: WEBHOOK_WEBHOOK_VIEW_FETCH,
    });
    WebhookApi.view()
      .then((response) => {
        dispatch({
          type: WEBHOOK_WEBHOOK_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: WEBHOOK_WEBHOOK_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function listEventsFetch() {
  return (dispatch) => {
    ProductUserApi.listEvents()
      .then((response) => {
        dispatch({
          type: WEBHOOK_LIST_EVENTS,
          payload: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: WEBHOOK_LIST_EVENTS,
          payload: [],
        });
        showMessageError(err);
      });
  };
}
