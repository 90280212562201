import BaseApi from '../../../api/BaseApi';

const basePath = '/integration/webhook';
class ProductSettingApi {
  static create(body) {
    return BaseApi.post(basePath + '/create', body);
  }
  static delete(id) {
    return BaseApi.post(basePath + '/delete/' + id, {});
  }
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static update(id) {
    return BaseApi.post(basePath + '/update/' + id, {});
  }
  static updateSubmit(id, body) {
    return BaseApi.post(basePath + '/updateSubmit/' + id, body);
  }
  static view() {
    return BaseApi.post(basePath + '/webhookView', {});
  }
}

export default ProductSettingApi;
