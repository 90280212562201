import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { Link } from 'react-router-dom';
import {
  Table,
  Space,
  Button,
  Input,
  Select,
  Popconfirm,
  Menu,
  Dropdown,
} from 'antd';
import { EllipsisOutlined, SearchOutlined } from '@ant-design/icons';
import { viewBoolean } from '../../utils/BooleanUtils';

class ListBase extends React.Component {
  handleTableChange = (pagination, filters) => {
    this.props.onChange({
      ...this.props.pageRequest,
      // order: sorter.column ? sorter.field : null,
      // direction: sorter.order === 'descend' ? 'DESC' : 'ASC',
      page: pagination.current - 1,
      ...filters,
    });
  };

  clearAll = () => {
    this.props.onChange({ page: 0 });
  };

  render() {
    const { columns } = this.props;
    columns.forEach((x) => {
      if (x.key === this.props.pageRequest?.order) {
        x.sortOrder =
          this.props.pageRequest?.direction === 'DESC' ? 'descend' : 'ascend';
      } else {
        x.sortOrder = null;
      }
      if (this.props.pageRequest[x.key]) {
        x.filteredValue = this.props.pageRequest[x.key];
      } else {
        x.filteredValue = null;
      }
    });
    /*
    const data = [
      {
        id: 0,
        name: 'string 1',
        startDate: '2021-02-13T15:51:00.559Z',
        status: 'DRAFT',
        type: 'ONE_TIME',
      },
      {
        id: 1,
        name: 'string 2',
        startDate: '2021-02-13T15:51:00.559Z',
        status: 'DRAFT',
        type: 'ONE_TIME',
      },
      {
        id: 2,
        name: 'string 3',
        startDate: '2021-02-13T15:51:00.559Z',
        status: 'DRAFT',
        type: 'ONE_TIME',
      },
    ];
    */
    let dataSource = this.props.page.content;
    if (isNil(this.props.page.content) && !isNil(!this.props.page.contents)) {
      dataSource = this.props.page.contents;
    }
    return (
      <div>
        {false && (
          <Space style={{ marginBottom: 16 }}>
            {this.props.createLink && (
              <Link to={this.props.createLink}>
                <Button shape="round">Create</Button>
              </Link>
            )}
            <Button shape="round" onClick={this.clearAll}>
              Reset search
            </Button>
          </Space>
        )}
        <Table
          className={'custom-table'}
          columns={columns}
          // dataSource={data}
          dataSource={dataSource}
          rowKey={(record) => record.id}
          pagination={{
            position: ['none', 'bottomCenter'],
            current: this.props.page.number + 1,
            showSizeChanger: false,
            pageSize: this.props.page.size,
            total: this.props.page.totalElements,
          }}
          onChange={this.handleTableChange}
          onRow={(record) => {
            return {
              onClick: (event) => {
                if (this.props.url && this.props.history) {
                  const url = record.id + this.props.url;
                  if (event.ctrlKey) {
                    const win = window.open(url, '_blank');
                    win.focus();
                  } else {
                    this.props.history.push(url);
                  }
                } else if (this.props.onClick) {
                  this.props.onClick(record);
                }
              },
            };
          }}
        ></Table>
      </div>
    );
  }
}

ListBase.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  actions: PropTypes.array,
  createLink: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
};

export default ListBase;

export const defaultActions = (row, ...actions) => {
  const list = [];
  actions.forEach((x) => {
    if (x.link) {
      list.push(
        <Menu.Item
          disabled={!x.enabled}
          key={x.title}
          style={{ width: 'auto' }}
        >
          <Link to={x.link} onClick={isNil(x.onClick) ? null : x.onClick}>
            {x.title}
          </Link>
        </Menu.Item>
      );
    } else if (x.component) {
      list.push(
        <Menu.Item key={x.title} disabled={!x.enabled} style={{ width: 150 }}>
          {x.component}
        </Menu.Item>
      );
    } else if (x.action && !x.disablePopConfirm) {
      list.push(
        <Menu.Item
          key={x.title}
          disabled={!x.enabled}
          style={{ width: '100%' }}
          className="menuItemPopconfirm"
        >
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => x.action(row.id)}
            disabled={!x.enabled}
            placement="topLeft"
            style={{ backgroundColor: 'blue' }}
          >
            {x.title}
          </Popconfirm>
        </Menu.Item>
      );
    } else if (x.action && x.disablePopConfirm) {
      list.push(
        <Menu.Item
          key={x.title}
          disabled={!x.enabled}
          onClick={() => x.action(row.id)}
          style={{ width: 'auto' }}
        >
          {x.title}
        </Menu.Item>
      );
    } else {
      // list.push(
      //     <Button disabled={!x.enabled}>
      //         {x.title}
      //     </Button>
      // );
    }
  });
  const menu = <Menu>{list}</Menu>;

  return (
    <Space onClick={(e) => e.stopPropagation()}>
      <Dropdown overlay={menu} trigger={['click']}>
        <a className="int-list-ellipsis" onClick={(e) => e.preventDefault()}>
          <EllipsisOutlined
            style={{
              transform: 'rotateZ(90deg)',
              fontSize: 20,
              fontWeight: 'bold',
              color: 'gray',
            }}
          />
        </a>
      </Dropdown>
    </Space>
  );
};

export const getColumnTextSearchProps = (dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys}
        onChange={(e) => setSelectedKeys(e.target.value)}
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          shape="round"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters()}
          size="small"
          shape="round"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
});

export const getColumnEnumSearchProps = (dataIndex, enumeration) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Select
        style={{ width: 180, margin: 5 }}
        value={selectedKeys}
        onChange={(val) => setSelectedKeys(val)}
        options={Object.keys(enumeration).map((x) => ({
          value: x,
          label: enumeration[x],
        }))}
      />
      <br />
      <Space>
        <Button
          type="primary"
          shape="round"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters()}
          size="small"
          shape="round"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
});

export const getColumnBooleanSearchProps = () => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Select
        style={{ width: 180, margin: 5 }}
        value={selectedKeys}
        onChange={(val) => setSelectedKeys(val)}
        options={[true, false].map((x) => ({
          value: x.toString(),
          label: viewBoolean(x),
        }))}
      />
      <br />
      <Space>
        <Button
          type="primary"
          shape="round"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters()}
          size="small"
          shape="round"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
});
