export const FILE_STORAGE_LIST_FETCH = 'FILE_STORAGE_LIST_FETCH';
export const FILE_STORAGE_LIST_FETCH_FAIL = 'FILE_STORAGE_LIST_FETCH_FAIL';
export const FILE_STORAGE_LIST_FETCH_SUCCESS =
  'FILE_STORAGE_LIST_FETCH_SUCCESS';

export const FILE_STORAGE_CREATE_FETCH = 'FILE_STORAGE_CREATE_FETCH';
export const FILE_STORAGE_CREATE_FETCH_SUCCESS =
  'FILE_STORAGE_CREATE_FETCH_SUCCESS';
export const FILE_STORAGE_CREATE_FETCH_FAIL = 'FILE_STORAGE_CREATE_FETCH_FAIL';
export const FILE_STORAGE_CHANGE_CURRENT = 'FILE_STORAGE_CHANGE_CURRENT';

export const FILE_STORAGE_UPDATE_FETCH = 'FILE_STORAGE_UPDATE_FETCH';
export const FILE_STORAGE_UPDATE_FETCH_SUCCESS =
  'FILE_STORAGE_UPDATE_FETCH_SUCCESS';

export const FILE_STORAGE_UPDATE_FETCH_FAIL = 'FILE_STORAGE_UPDATE_FETCH_FAIL';

export const FILE_STORAGE_UPDATE_SUBMIT_FETCH =
  'FILE_STORAGE_UPDATE_SUBMIT_FETCH';
export const FILE_STORAGE_UPDATE_SUBMIT_FETCH_SUCCESS =
  'FILE_STORAGE_UPDATE_SUBMIT_FETCH_SUCCESS';
export const FILE_STORAGE_UPDATE_SUBMIT_FETCH_FAIL =
  'FILE_STORAGE_UPDATE_SUBMIT_FETCH_FAIL';

export const FILE_STORAGE_VIEW_FETCH = 'FILE_STORAGE_VIEW_FETCH';
export const FILE_STORAGE_VIEW_FETCH_SUCCESS =
  'FILE_STORAGE_VIEW_FETCH_SUCCESS';
export const FILE_STORAGE_VIEW_FETCH_FAIL = 'FILE_STORAGE_VIEW_FETCH_FAIL';

export const FILE_STORAGE_DELETE_FETCH = 'FILE_STORAGE_DELETE_FETCH';
export const FILE_STORAGE_DELETE_FETCH_SUCCESS =
  'FILE_STORAGE_DELETE_FETCH_SUCCESS';
export const FILE_STORAGE_DELETE_FETCH_FAIL = 'FILE_STORAGE_DELETE_FETCH_FAIL';
