import {
  thirdPartyListFetch,
  thirdPartyUpdateClear,
  thirdPartyUpdateEnabledFetch,
} from '@Redux/slices/admin/thirdPartySlice';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Card } from 'antd';
import ListBase, { defaultActions } from '@Component/form/ListBase';
import { AuthorityProvider, hasAnyAuthority } from '@Utils/AuthorityProvider';
import { useParams } from 'react-router-dom';
import { viewBoolean } from '@Utils/BooleanUtils';

function ThirdPartyList() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.admin.thirdParty.loading);
  const thirdPartyProducts = useSelector(
    (state) => state.admin.thirdParty.thirdPartyProducts
  );
  const pageRequest = useSelector(
    (state) => state.admin.thirdParty.pageRequest
  );
  const page = useSelector(
    (state) => state.admin.thirdParty.page
  );
  const pagination = useSelector((state) => ({
    current: state.admin.thirdParty.number,
    pageSize: state.admin.thirdParty.size,
    totalElements: state.admin.thirdParty.totalElements,
  }));
  const { productId } = useParams();

  useEffect(() => {
    thirdPartyList(pageRequest);
    return () => {
      dispatch(thirdPartyUpdateClear());
    };
  }, []);

  const thirdPartyList = (pageRequest) => {
    dispatch(thirdPartyListFetch(productId, pageRequest));
  };

  const handleTableChange = (pageRequest) => {
    thirdPartyList(pageRequest);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      key: 'enabled',
      render: (x) => viewBoolean(x),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: '-action-',
      width: '5%',
      render: (row) =>
        defaultActions(
          row,
          {
            link: '../' + row.id + '/view',
            title: 'View',
            enabled: hasAnyAuthority(
              AuthorityProvider.ROLE_THIRD_PARTY_PRODUCT_UPDATE
            ),
          },
          {
            action: () => {
              dispatch(
                thirdPartyUpdateEnabledFetch(productId, row.id, row.enabled, {
                  page: pagination.current,
                  pageSize: pagination.pageSize,
                })
              );
            },
            title: row.enabled && row.enabled === true ? 'Disable' : 'Enable',
            enabled: hasAnyAuthority(
              AuthorityProvider.ROLE_THIRD_PARTY_PRODUCT_UPDATE_ENABLED
            ),
          }
        ),
    },
  ];

  return (
    <Card loading={loading}>
      <ListBase
        className={'custom-table'}
        columns={columns}
        dataSource={thirdPartyProducts}
        onChange={handleTableChange}
        pageRequest={pageRequest}
        page={page}
      />
    </Card>
  );
}

export default ThirdPartyList;
