import React from 'react';
import { InputNumber, Select, Table, Spin } from 'antd';
import { findIndex, isNil, cloneDeep, isEmpty } from 'lodash';
import { getDelayMinutes, convertMinute } from '../../../../utils/DateUtils';
import ProductSettingFooterComponent from './ProductSettingFooterComponent';
import { LoadingOutlined } from '@ant-design/icons';
import Card from '@Utils/Card';
const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  settingBoxContainer: {
    padding: '40px 30px',
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
};

class ProductSettingFcComponent extends React.Component {
  componentDidMount() {
    this.props.fcUpdateFetch();
  }

  state = {
    AllChannelPerDay: 0,
    AllChannelPerWeek: 0,
    AllChannelPerMonth: 0,

    PushPerDay: 0,
    PushPerWeek: 0,
    PushPerMonth: 0,

    SMSPerDay: 0,
    SMSPerWeek: 0,
    SMSPerMonth: 0,

    WebPushPerDay: 0,
    WebPushPerWeek: 0,
    WebPushPerMonth: 0,

    EmailPerDay: 0,
    EmailPerWeek: 0,
    EmailPerMonth: 0,
  };

  handleClickSave = () => {
    this.props.fcUpdateSubmitFetch(this.props.fc);
  };

  handleChangeValue = (value, channel, title) => {
    // eslint-disable-next-line consistent-return
    const foundIndex = findIndex(this.props.fc.frequencyCappings, (o) => {
      if (!isNil(o.channelType)) {
        return o.channelType === channel;
      } else if (isNil(channel)) {
        return isNil(o.channelType);
      }
    });

    const frequencyCappingsCopy = cloneDeep(this.props.fc.frequencyCappings);
    frequencyCappingsCopy[foundIndex] = {
      ...frequencyCappingsCopy[foundIndex],
      [title]: value,
    };
    this.props.fcChangeCurrent({
      ...this.props.fc,
      frequencyCappings: frequencyCappingsCopy,
    });
  };

  handleChangeTimeGapValue = (value, type, channel) => {
    // eslint-disable-next-line consistent-return
    const foundIndex = findIndex(this.props.fc.frequencyCappings, (o) => {
      if (!isNil(o.channelType)) {
        return o.channelType === channel;
      } else if (isNil(channel)) {
        return isNil(o.channelType);
      }
    });
    const frequencyCappingsCopy = cloneDeep(this.props.fc.frequencyCappings);
    frequencyCappingsCopy[foundIndex] = {
      ...frequencyCappingsCopy[foundIndex],
      timeGap: { value, type },
      timeGapMinutes: getDelayMinutes(value, type),
    };
    this.props.fcChangeCurrent({
      ...this.props.fc,
      frequencyCappings: frequencyCappingsCopy,
    });
  };

  renderChannels = () => {
    const data = [
      {
        channelType: null,
        key: 'all',
        channels: 'All Channels Combined',
        messagePerDay: null,
        messagePerMonth: null,
        messagePerWeek: null,
        timeGap: { value: 0, type: 'MINUTE' },
      },
      {
        channelType: 'PUSH',
        key: 'push',
        channels: 'Push',
        messagePerDay: null,
        messagePerMonth: null,
        messagePerWeek: null,
        timeGap: { value: 0, type: 'MINUTE' },
      },
      {
        channelType: 'SMS',
        key: 'sms',
        channels: 'SMS',
        messagePerDay: null,
        messagePerMonth: null,
        messagePerWeek: null,
        timeGap: { value: 0, type: 'MINUTE' },
      },
      {
        channelType: 'EMAIL',
        key: 'email',
        channels: 'Email',
        messagePerDay: null,
        messagePerMonth: null,
        messagePerWeek: null,
        timeGap: { value: 0, type: 'MINUTE' },
      },
      {
        channelType: 'WEB_PUSH',
        key: 'web-notifications',
        channels: 'Web Push',
        messagePerDay: null,
        messagePerMonth: null,
        messagePerWeek: null,
        timeGap: { value: 0, type: 'MINUTE' },
      },
    ];
    if (
      this.props.fc &&
      this.props.fc.frequencyCappings &&
      Array.isArray(this.props.fc.frequencyCappings)
    ) {
      this.props.fc.frequencyCappings.forEach((item) => {
        let foundIndex = 0;
        if (!isNil(item.channelType)) {
          foundIndex = findIndex(
            data,
            (o) => o.channelType === item.channelType
          );
        }
        if (foundIndex > -1) {
          data[foundIndex].messagePerDay = item.messagePerDay;
          data[foundIndex].messagePerMonth = item.messagePerMonth;
          data[foundIndex].messagePerWeek = item.messagePerWeek;
          if (!isNil(item.timeGap) && !isEmpty(item.timeGap)) {
            data[foundIndex].timeGap = cloneDeep(item.timeGap);
          } else {
            const obj = convertMinute(item.timeGapMinutes);
            data[foundIndex].timeGap = cloneDeep(obj);
          }
        }
      });
    }
    const columns = [
      {
        title: 'channels',
        dataIndex: 'channels',
        key: 'channels',
        render: (text) => {
          return (
            <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
          );
        },
      },
      {
        title: 'message per day',
        dataIndex: 'messagePerDay',
        key: 'messagePerDay',
        render: (text, record) => {
          return (
            <InputNumber
              min={0}
              style={{ borderRadius: 5 }}
              onChange={(value) =>
                this.handleChangeValue(
                  value,
                  record.channelType,
                  'messagePerDay'
                )
              }
              value={text}
            />
          );
        },
      },
      {
        title: 'messages per week',
        dataIndex: 'messagePerWeek',
        key: 'messagePerWeek',
        render: (text, record) => {
          return (
            <InputNumber
              min={0}
              style={{ borderRadius: 5 }}
              onChange={(value) =>
                this.handleChangeValue(
                  value,
                  record.channelType,
                  'messagePerWeek'
                )
              }
              value={text}
            />
          );
        },
      },
      {
        title: 'message per month',
        dataIndex: 'messagePerMonth',
        key: 'messagePerMonth',
        render: (text, record) => {
          return (
            <InputNumber
              min={0}
              style={{ borderRadius: 5 }}
              onChange={(value) =>
                this.handleChangeValue(
                  value,
                  record.channelType,
                  'messagePerMonth'
                )
              }
              value={text}
            />
          );
        },
      },
      {
        title: 'time gap between message',
        dataIndex: 'timeGap',
        key: 'timeGap',
        render: (text, record) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputNumber
                min={0}
                style={{ borderRadius: 5, width: 50, marginRight: 10 }}
                onChange={(value) =>
                  this.handleChangeTimeGapValue(
                    value,
                    text.type,
                    record.channelType
                  )
                }
                value={text.value}
              />
              <Select
                onChange={(type) =>
                  this.handleChangeTimeGapValue(
                    text.value,
                    type,
                    record.channelType
                  )
                }
                value={text.type ? text.type : 'MINUTE'}
                style={{ width: 100 }}
                className={'chart-selector'}
                bordered={false}
              >
                <Select.Option value="MINUTE">minutes</Select.Option>
                <Select.Option value="HOUR">hours</Select.Option>
                <Select.Option value="DAY">days</Select.Option>
                <Select.Option value="WEEK">Weeks</Select.Option>
                <Select.Option value="YEAR">Years</Select.Option>
              </Select>
            </div>
          );
        },
      },
    ];

    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    return (
      <Card>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '12px',
            paddingBottom: 0,
            paddingLeft: '20px',
          }}
        >
          <div style={{ fontSize: 13, color: '#3d404e' }}>
            {
              'Avoid spamming your users by setting the maximum number of messages a user can receive per day, per week and per month through various channels. You can also specify the minimum time gap between the delivery of consecutive messages to a user. Please note that Frequency Capping does not apply to In-app and On-site campaigns.'
            }
          </div>
        </div>
        {this.props.loadingFcUpdate ? (
          <div style={styles.loadingSpinStyle}>
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <Table
            className={'custom-table'}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        )}
      </Card>
    );
  };

  render() {
    return (
      <div style={{ padding: '30px 30px 85px 30px' }}>
        {this.renderChannels()}
        <ProductSettingFooterComponent
          loading={this.props.loadingUpdateSubmit}
          handleClickSave={() => this.handleClickSave()}
        />
      </div>
    );
  }
}

export default ProductSettingFcComponent;
