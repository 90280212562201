import CommunicationAudienceComponent from '../component/CommunicationAudienceComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  communicationAudienceReset,
  communicationAudienceCreateFetch,
  communicationListPushChannelsFetch,
  communicationListSegmentsFetch,
  communicationListTagsFetch,
  communicationUpdateAudienceFetch,
  communicationUpdateSubmitAudienceFetch,
  communicationDraftSaveDiscard,
  communicationDraftSave,
  communicationCreateFetch,
  CommunicationAudienceClearCreateError,
  CommunicationAudienceClearUpdateError,
  communicationChangeCurrent,
  communicationAudienceSegmentReportFetch,
  segmentReportCurrent,
  communicationUpdateWhenThrottlingFetch,
} from '../CommunicationActions';
import PropTypes from 'prop-types';
import { insightProductUserReachabilityReportFetch } from '../../../track/users/UsersActions';
import { productSegmentReportViewFetch } from '../../../insight/productSegment/ProductSegmentActions';

const mapStateToProps = (state) => ({
  currentUser: state.account.auth.currentUser,
  page: state.engage.communication.page,
  pageRequest: state.engage.communication.pageRequest,
  loadingCreate: state.engage.communication.loadingCreate,
  successCreated: state.engage.communication.successCreated,
  communicationData: state.engage.communication.communicationData,
  loadingSegment: state.engage.communication.loadingSegment,
  loadingUpdateAudience: state.engage.communication.loadingUpdateAudience,
  loadingUpdateAudienceSubmit:
    state.engage.communication.loadingUpdateAudienceSubmit,
  loadingPushChannels: state.engage.communication.loadingPushChannels,
  segments: state.engage.communication.segments,
  pushChannels: state.engage.communication.pushChannels,
  currentAudience: state.engage.communication.currentAudience,
  currentWhen: state.engage.communication.currentWhen,
  loadingListTags: state.engage.communication.loadingListTags,
  listTags: state.engage.communication.listTags,
  communicationDraft: state.engage.communication.communicationDraft,
  pathname: state.router.location.pathname,
  errorCreate: state.engage.communication.errorCreate,
  errorCreateCommunication: state.engage.communication.errorCreateCommunication,
  errorUpdateAudienceSubmit:
    state.engage.communication.errorUpdateAudienceSubmit,
  reachability: state.track.users.reachability,
  currentReport: state.insight.productSegment.currentReport,
  currentSegmentId: state.insight.productSegment.currentSegmentId,
  loadingReportView: state.insight.productSegment.loadingReportView,
  segmentReport: state.engage.communication.segmentReport,
  // segmentReportError: state.engage.communication.segmentReportError,
  loadingSegmentReport: state.engage.communication.loadingSegmentReport,
  defaultThrottling: state.engage.communication.defaultThrottling,
  loadingUpdateThrottlingWhen:
    state.engage.communication.loadingUpdateThrottlingWhen,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      segmentReportCurrent,
      communicationDraftSaveDiscard,
      communicationDraftSave,
      communicationCreateFetch,
      communicationAudienceCreateFetch,
      communicationAudienceReset,
      communicationListSegmentsFetch,
      communicationListTagsFetch,
      communicationUpdateAudienceFetch,
      communicationUpdateSubmitAudienceFetch,
      communicationListPushChannelsFetch,
      CommunicationAudienceClearCreateError,
      CommunicationAudienceClearUpdateError,
      communicationChangeCurrent,
      insightProductUserReachabilityReportFetch,
      productSegmentReportViewFetch,
      communicationAudienceSegmentReportFetch,
      communicationUpdateWhenThrottlingFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationAudienceComponent);

CommunicationAudienceComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  communicationDeleteFetch: PropTypes.func.isRequired,
  communicationPageChangeCurrent: PropTypes.func.isRequired,
  communicationCreateFetch: PropTypes.func.isRequired,
};
