import JourneyDetailsComponent from '../component/JourneyDetailsComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { journeyListTagsFetch, journeyViewFetch } from '../JourneyActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  listTags: state.engage.journey.listTags,
  loadingJourneyDetails: state.engage.journey.loadingJourneyDetails,
  journeyDetails: state.engage.journey.journeyDetails,
  errorJourneyDetails: state.engage.journey.errorJourneyDetails,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      journeyListTagsFetch,
      journeyViewFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JourneyDetailsComponent);

JourneyDetailsComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  communicationViewFetch: PropTypes.func.isRequired,
};
