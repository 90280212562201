import texts from '@Utils/texts';
import React from 'react';
import Highlight from 'react-highlight.js';

const TrackEventFragmentIos = ({ highlight }) => {
  return (
    <>
      <div style={{ padding: 30 }}>
        <div className="fragment-content">
          <span>
            IOS Users can create their own custom messages and send them to{' '}
            {texts.brandName}&nbsp; server. This can be done by calling{' '}
            <a href={texts.iosEvent} target={'_blank'} rel="noreferrer">
              recordEvent
            </a>{' '}
            , For example:
          </span>
        </div>
      </div>
      <div className="rows-space">
        {highlight && (
          <div className="code-grey-card">
            <Highlight language={'javascript'} className="highlight">
              <div>{highlight}</div>
            </Highlight>
          </div>
        )}
      </div>
    </>
  );
};
export default TrackEventFragmentIos;
