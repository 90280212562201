import { history } from '../../../redux/store';
import toastr from 'toastr';
import {
  ADMIN_PRODUCT_CHANGE_CURRENT,
  ADMIN_PRODUCT_CREATE_FETCH,
  ADMIN_PRODUCT_CREATE_FETCH_FAIL,
  ADMIN_PRODUCT_CREATE_FETCH_SUCCESS,
  ADMIN_PRODUCT_INDEX_ALL_FETCH,
  ADMIN_PRODUCT_INDEX_ALL_FETCH_FAIL,
  ADMIN_PRODUCT_INDEX_ALL_FETCH_SUCCESS,
  ADMIN_PRODUCT_INDEX_FETCH,
  ADMIN_PRODUCT_INDEX_FETCH_FAIL,
  ADMIN_PRODUCT_INDEX_FETCH_SUCCESS,
  ADMIN_PRODUCT_LIST_FETCH,
  ADMIN_PRODUCT_LIST_FETCH_FAIL,
  ADMIN_PRODUCT_LIST_FETCH_SUCCESS,
  ADMIN_PRODUCT_PAGE_CHANGE_CURRENT,
  ADMIN_PRODUCT_UPDATE_FETCH,
  ADMIN_PRODUCT_UPDATE_FETCH_FAIL,
  ADMIN_PRODUCT_UPDATE_FETCH_SUCCESS,
  ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH,
  ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH_FAIL,
  ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH_SUCCESS,
  ADMIN_PRODUCT_VIEW_FETCH,
  ADMIN_PRODUCT_VIEW_FETCH_FAIL,
  ADMIN_PRODUCT_VIEW_FETCH_SUCCESS,
  EVENT_RATE_CHANGE_CURRENT,
  PARENT_PRODUCT_LIST_FETCH,
  PARENT_PRODUCT_LIST_FETCH_SUCCESS,
} from './AdminProductConstants';
import AdminProductApi from './AdminProductApi';
import { loadCurrentUserFetch } from '../../account/auth/AuthActions';
import { isArray, isFunction } from 'lodash';

const loadAdminProductList = (dispatch, adminProductPageRequest) => {
  dispatch({
    type: ADMIN_PRODUCT_LIST_FETCH,
    payload: adminProductPageRequest,
  });
  AdminProductApi.list(adminProductPageRequest)
    .then((response) => {
      dispatch({
        type: ADMIN_PRODUCT_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: ADMIN_PRODUCT_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};
export function adminProductListFetch(adminProductPageRequest) {
  return (dispatch) => loadAdminProductList(dispatch, adminProductPageRequest);
}

export function adminProductChangeCurrent(adminProduct) {
  return (dispatch) => {
    dispatch({
      type: ADMIN_PRODUCT_CHANGE_CURRENT,
      payload: adminProduct,
    });
  };
}

export function eventRateChangeCurrent(currentEventRate) {
  return (dispatch) => {
    dispatch({
      type: EVENT_RATE_CHANGE_CURRENT,
      payload: currentEventRate,
    });
  };
}
export function adminProductPageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: ADMIN_PRODUCT_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function adminProductCreateFetch(adminProduct) {
  if (isArray(adminProduct?.watchedEventApiPartitions)) {
    adminProduct.watchedEventApiPartitions =
      adminProduct?.watchedEventApiPartitions.join(',');
  }
  if (isArray(adminProduct?.limitedUserIds)) {
    adminProduct.limitedUserIds = adminProduct?.limitedUserIds.join(',');
  }

  return (dispatch) => {
    dispatch({
      type: ADMIN_PRODUCT_CREATE_FETCH,
      payload: adminProduct,
    });
    AdminProductApi.create(adminProduct)
      .then((response) => {
        dispatch({
          type: ADMIN_PRODUCT_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        loadCurrentUserFetch(dispatch);
        history.push('list');
      })
      .catch((response) => {
        dispatch({
          type: ADMIN_PRODUCT_CREATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function getParentProducts() {
  return (dispatch) => {
    dispatch({
      type: PARENT_PRODUCT_LIST_FETCH,
    });
    AdminProductApi.listParents({}).then((response) => {
      dispatch({
        type: PARENT_PRODUCT_LIST_FETCH_SUCCESS,
        payload: response,
      });
    });
  };
}

export function adminProductIndexFetch(id) {
  return (dispatch) => {
    dispatch({
      type: ADMIN_PRODUCT_INDEX_FETCH,
    });
    AdminProductApi.index(id)
      .then((response) => {
        dispatch({
          type: ADMIN_PRODUCT_INDEX_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Indexed successfully');
      })
      .catch((response) => {
        dispatch({
          type: ADMIN_PRODUCT_INDEX_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function adminProductIndexAllFetch() {
  return (dispatch) => {
    dispatch({
      type: ADMIN_PRODUCT_INDEX_ALL_FETCH,
    });
    AdminProductApi.indexAll()
      .then((response) => {
        dispatch({
          type: ADMIN_PRODUCT_INDEX_ALL_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Indexed successfully');
      })
      .catch((response) => {
        dispatch({
          type: ADMIN_PRODUCT_INDEX_ALL_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function adminProductUpdateFetch(id) {
  return (dispatch) => {
    if (!id) return;
    dispatch({
      type: ADMIN_PRODUCT_UPDATE_FETCH,
    });
    AdminProductApi.update(id)
      .then((response) => {
        if (response?.watchedEventApiPartitions) {
          response.watchedEventApiPartitions =
            response?.watchedEventApiPartitions.split(',');
        }
        if (response?.limitedUserIds) {
          response.limitedUserIds = response?.limitedUserIds.split(',');
        }
        dispatch({
          type: ADMIN_PRODUCT_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: ADMIN_PRODUCT_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function adminProductUpdateSubmitFetch(id, adminProduct) {
  if (isArray(adminProduct?.watchedEventApiPartitions)) {
    adminProduct.watchedEventApiPartitions =
      adminProduct?.watchedEventApiPartitions.join(',');
  }
  if (isArray(adminProduct?.limitedUserIds)) {
    adminProduct.limitedUserIds = adminProduct?.limitedUserIds.join(',');
  }

  return (dispatch) => {
    dispatch({
      type: ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH,
      payload: adminProduct,
    });
    AdminProductApi.updateSubmit(id, adminProduct)
      .then((response) => {
        dispatch({
          type: ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        loadCurrentUserFetch(dispatch);
        history.push('../../list');
      })
      .catch((response) => {
        dispatch({
          type: ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function adminProductViewFetch(id) {
  return (dispatch) => {
    dispatch({ type: ADMIN_PRODUCT_VIEW_FETCH });
    AdminProductApi.view(id)
      .then((response) => {
        if (response?.watchedEventApiPartitions) {
          response.watchedEventApiPartitions =
            response?.watchedEventApiPartitions.split(',');
        }
        if (response?.limitedUserIds) {
          response.limitedUserIds = response?.limitedUserIds.split(',');
        }
        dispatch({
          type: ADMIN_PRODUCT_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: ADMIN_PRODUCT_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function adminProductDelete(id, cb) {
  return () => {
    AdminProductApi.delete(id)
      .then(() => {
        if (cb && isFunction(cb)) {
          cb(true);
        }
      })
      .catch(() => {
        if (cb && isFunction(cb)) {
          cb(false);
        }
      });
  };
}
