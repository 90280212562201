export const getInlineThemeStyle = (variation, name, actionIndex) => {
  if (name === 'actions') {
    return {
      color: variation?.theme?.[name]?.[actionIndex]?.style?.color,
      fontFamily: variation?.theme?.[name]?.[actionIndex]?.style?.font_family,
      fontSize: variation?.theme?.[name]?.[actionIndex]?.style?.font_size,
    };
  }

  return {
    color: variation?.theme?.[name]?.color,
    fontFamily: variation?.theme?.[name]?.font_family,
    fontSize: variation?.theme?.[name]?.font_size,
  };
};
