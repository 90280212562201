import PagesWrapper from '@Component/layout/PagesWrapper';
import React from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';
import PagesHeader from '@Component/layout/PagesHeader';
import ContractList from './components/ContractList';
import ContractCreate from './components/ContractCreate';
import { AuthorityProvider, hasAnyAuthority } from '@Utils/AuthorityProvider';
import { Button } from 'antd';

function ContractTemplate() {
  let match = useRouteMatch();
  let location = useLocation();
  const history = useHistory();
  const isList = !!location.pathname.includes('/list');
  const button = isList ? (
    <Button
      disabled={
        !hasAnyAuthority(AuthorityProvider.ROLE_CONTRACT_TEMPLATE_CREATE)
      }
      type="primary"
      onClick={() => history.push('create')}
    >
      New
    </Button>
  ) : (
    ''
  );

  return (
    <>
      <PagesHeader
        title="Contracts"
        newButton
        hasAuthNew={hasAnyAuthority(
          AuthorityProvider.ROLE_CONTRACT_TEMPLATE_CREATE
        )}
        button={button}
        hasBackButton={!!location.pathname.includes('/view')}
        backUrl={'/admin/contract-template/list'}
      />
      <PagesWrapper>
        <Switch>
          <Route exact path={`${match.path}list`}>
            <ContractList />
          </Route>
          <Route exact path={`${match.path}create`}>
            <ContractCreate />
          </Route>
          <Route path={`${match.path}:id/edit`}>
            <ContractCreate />
          </Route>
          <Route path={`${match.path}:id/view`}>
            <ContractCreate />
          </Route>
        </Switch>
      </PagesWrapper>
    </>
  );
}

export default ContractTemplate;
