import HomeComponent from '../component/HomeComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loginFetch,
  logoutFetch,
  registerFetch,
  forgotFetch,
  forgotSubmitFetch,
  changeActiveProduct,
  loginSecondStepFetch,
} from '../../account/auth/AuthActions';
import { increment, decrement } from '../HomeState';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentUser: state.account.auth.currentUser,
  loginLoading: state.account.auth.loginLoading,
  loginErrors: state.account.auth.loginErrors,
  registerResponse: state.integration.teamMembers.registerResponse,
  count: state.home.count,
  recaptchaLoading: state.account.auth.recaptchaLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      increment,
      decrement,
      loginFetch,
      logoutFetch,
      registerFetch,
      forgotFetch,
      forgotSubmitFetch,
      changeActiveProduct,
      loginSecondStepFetch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);

HomeComponent.propTypes = {
  count: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  increment: PropTypes.func.isRequired,
  decrement: PropTypes.func.isRequired,
};
