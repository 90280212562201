import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import account from './slices/account';
import home from '../modules/home/HomeState';
import integration from '../modules/integration';
import dataModule from './slices/data';
import channel from './slices/channel';
import insight from '../modules/insight';
import track from '../modules/track';
import setting from './slices/setting';
import engage from '../modules/engage';
import app from './appState';
import analytics from './slices/analytics';
import admin from './slices/admin';
import { CsvExportStateReducer as csv } from '../component/utils/CsvExport/CsvExportActions';

const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    account,
    admin,
    home,
    integration,
    dataModule,
    channel,
    insight,
    setting,
    engage,
    track,
    app,
    analytics,
    csv,
  });

export default reducer;
