import { createSlice } from '@reduxjs/toolkit';
import { showMessageError } from '@Utils/RenderUtils';
import { history } from '@Redux/store';
import toastr from 'toastr';
import BusinessEventApi from './businessEventApi';
import { appLoading } from './dataSlice';

const initialState = {
  errorsCreate: [],
  loadingCreate: false,
  item: {
    name: null,
    attributes: [{ name: null, type: 'STRING' }],
  },
  list: {
    loadingList: true,
    page: {},
    pageRequest: {
      direction: 'DESC',
      enabled: true,
      order: 'modifiedDate',
      name: '',
      page: 0,
    },
  },
  loadingTrigger: false,
  errorsTrigger: [],
  eventAttributes: {},
};

export const businessEvent = createSlice({
  name: 'businessEvent',
  initialState,
  reducers: {
    businessEventListFetchSuccess(state, { payload }) {
      state.list.page = payload;
    },
    businessEventCreateFetchSuccess(state, { payload }) {
      state.item = payload;
      toastr.success('Has been updated successfully');
    },
    businessEventCreateFetchFail(state, { payload }) {
      state.errorsCreate = payload?.errors;
      showMessageError(payload);
    },
    businessEventTriggerFetchSuccess(state) {
      state.loadingTrigger = true;
    },
    businessEventTriggerFetchFail(state, { payload }) {
      state.loadingTrigger = false;
      state.errorsTrigger = payload.errors;
      showMessageError(payload);
    },
    businessEventTriggerClearError(state) {
      state.errorsTrigger = [];
    },
    businessEventCreateClearError(state, { errors, errorKey = null }) {
      if (errorKey === null) {
        state.errorsCreate = [];
      } else {
        const filteredErrors = errors.filter((o) => o.key !== errorKey);
        state.errorsCreate = filteredErrors;
      }
    },
  },
});

export default businessEvent.reducer;

export const {
  businessEventListFetchSuccess,
  businessEventCreateFetchSuccess,
  businessEventCreateFetchFail,
  businessEventTriggerFetchSuccess,
  businessEventTriggerFetchFail,
  businessEventTriggerClearError,
  businessEventCreateClearError,
} = businessEvent.actions;

export const businessEventCreateFetch = (businessEvent) => async (dispatch) => {
  dispatch(appLoading(true));
  try {
    const response = await BusinessEventApi.create(businessEvent);
    dispatch(businessEventCreateFetchSuccess(response));
    dispatch(appLoading(false));
    history.push('business');
  } catch (e) {
    dispatch(businessEventCreateFetchFail(e));
    dispatch(appLoading(false));
    showMessageError(e);
  }
};
export const businessEventListFetch = (pageRequest) => async (dispatch) => {
  dispatch(appLoading(true));

  try {
    const response = await BusinessEventApi.list(pageRequest);
    dispatch(businessEventListFetchSuccess(response));
    dispatch(appLoading(false));
  } catch (e) {
    dispatch(appLoading(false));
    showMessageError(e);
  }
};
export const businessEventTriggerFetch =
  (businessEvent) => async (dispatch) => {
    dispatch(appLoading(true));

    try {
      BusinessEventApi.trigger(businessEvent)
        .then(() => {
          dispatch(businessEventTriggerFetchSuccess());
          dispatch(appLoading(false));
          toastr.success('Has been updated successfully');
        })
        .catch((error) => {
          dispatch(businessEventTriggerFetchFail(error));
          dispatch(appLoading(false));
        });
    } catch (e) {
      showMessageError(e);
    }
  };
