import React from 'react';
import isNil from 'lodash/isNil';
import { Link } from 'react-router-dom';
import { Space, Popconfirm, Menu, Dropdown, Button } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import style from './ActionMenu.module.less';

export default function ActionMenu({ actions }) {
  const list = [];
  actions
    .filter((item) => !item.isPrimary)
    .forEach((x) => {
      if (x.link) {
        list.push(
          <Menu.Item disabled={!x.enabled} key={x.title} style={{ width: 140 }}>
            <Link to={x.link} onClick={isNil(x.onClick) ? null : x.onClick}>
              {x.title}
            </Link>
          </Menu.Item>
        );
      } else if (x.action && !x.disablePopConfirm) {
        list.push(
          <Menu.Item key={x.title} disabled={!x.enabled} style={{ width: 140 }}>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => x.action()}
              disabled={!x.enabled}
              placement="leftTop"
            >
              {x.title}
            </Popconfirm>
          </Menu.Item>
        );
      } else if (x.action && x.disablePopConfirm) {
        list.push(
          <Menu.Item
            key={x.title}
            disabled={!x.enabled}
            onClick={() => x.action()}
            style={{ width: 140 }}
          >
            {x.title}
          </Menu.Item>
        );
      } else {
        list.push(
          <Menu.Item
            key={x.title}
            onClick={x.onClick}
            disabled={!x.enabled}
            style={{ width: 140 }}
          >
            {x.title}
          </Menu.Item>
        );
      }
    });
  const primary = actions.find((item) => item.isPrimary);
  const menu = <Menu className={style.dropDownActionMenu}>{list}</Menu>;
  return (
    <div className={style.dropDownAction}>
      {primary && (
        <Button
          className="btn_status"
          onClick={primary.onClick}
          disabled={!primary.enabled}
        >
          {' '}
          {primary.title}
        </Button>
      )}

      {!!list.length && (
        <Space onClick={(e) => e.stopPropagation()}>
          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            <a
              className="int-list-ellipsis"
              onClick={(e) => e.preventDefault()}
            >
              <EllipsisOutlined className={style.ellipsisMenu} />
            </a>
          </Dropdown>
        </Space>
      )}
    </div>
  );
}
