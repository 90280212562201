import BaseApi from '../../../api/BaseApi';

const basePath = '/admin/user';
class UserApi {
  static listRoles() {
    return BaseApi.post(basePath + '/listRoles', {});
  }
  static listProducts() {
    return BaseApi.post(basePath + '/listProducts', {});
  }
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static create(body) {
    return BaseApi.post(basePath + '/create', body);
  }
  static update(id) {
    return BaseApi.post(basePath + '/update/' + id, {});
  }
  static updateSubmit(id, body) {
    return BaseApi.post(basePath + '/updateSubmit/' + id, body);
  }
  static view(id) {
    return BaseApi.post(basePath + '/view/' + id, {});
  }
  static changePassword(id) {
    return BaseApi.post(basePath + '/changePassword/' + id, {});
  }
  static changePasswordSubmit(id, body) {
    return BaseApi.post(basePath + '/changePasswordSubmit/' + id, body);
  }
  static uploadPicture(file) {
    return BaseApi.uploadFile(basePath + '/uploadPicture', file);
  }
}

export default UserApi;
