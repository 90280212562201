import BaseApi from '../../../api/BaseApi';

const basePath = '/channel/custom';
class CustomChannelApi {
  static create(body) {
    return BaseApi.post(basePath + '/create', body);
  }
  static delete(id) {
    return BaseApi.post(basePath + '/delete/' + id, {});
  }
  static list(data) {
    return BaseApi.post(basePath + '/list', data);
  }
  static update(id) {
    return BaseApi.post(basePath + '/update/' + id, {});
  }
  static view(id) {
    return BaseApi.post(basePath + '/view/' + id, {});
  }
  static updateSubmit(id, body) {
    return BaseApi.post(basePath + '/updateSubmit/' + id, body);
  }
}

export default CustomChannelApi;
