import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  managementDashboardReportFetchCharts,
  setCustomizationData,
} from '@Redux/slices/admin/managementDashboardSlice';
import ReportCardsSectionHeader from './ReportChartsSectionHeader';
import ReportChart from './ReportChart';
import { numberWithCommas } from '@Utils/RenderUtils';

export default function ReportChartsSection({ isCurrentUserUpdated }) {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.account.auth.currentUser);
  const currentFilters = useSelector(
    (state) => state.admin.managementDashboard.customizationData
  );

  const [startDate, setStartDate] = useState(moment().subtract(1, 'months'));
  const [endDate, setEndDate] = useState(moment());
  const [splitBy, setSplitBy] = useState('CHANNEL');
  const [productName, setProductName] = useState('ALL');

  const chartsData = useSelector((state) => {
    return state?.admin?.managementDashboard?.dailyData;
  });

  const loading = useSelector((state) => {
    return state?.admin?.managementDashboard?.chartsLoading;
  });

  const products = useSelector((state) => {
    const list =
      state?.account?.auth?.currentUser?.products?.map((product) => ({
        value: product.id,
        label: product.name,
      })) || [];
    list.unshift({ value: 'ALL', label: 'All' });
    return list;
  });

  const [selectedCards, setSelectedCards] = useState([
    'SYSTEM_EVENTS',
    'CUSTOM_EVENTS',
    'ACTIVE_USERS',
    // 'ENDED_CAMPAIGNS',
    // 'RUNNING_CAMPAIGNS',
    // 'RUNNING_JOURNEYS',
    // 'RUNNING_RELAYS',
    // 'SENT_MESSAGES',
    // 'DELIVERY_RATE',
    // 'REVENUE',
    // 'INCREMENTAL_REVENUE',
    // 'UPLIFT',
    // 'TOTAL_NUMBER_OF_ISSUE_INVOICES',
    // 'TOTAL_AMOUNT_OF_PAID_INVOICES',
    // 'TOTAL_NUMBER_OF_ACTIVE_PROJECTS',
    // 'UTILIZATION_INDEX',
  ]);

  const cardsList = [
    'SYSTEM_EVENTS',
    'CUSTOM_EVENTS',
    'ACTIVE_USERS',
    'ENDED_CAMPAIGNS',
    // 'RUNNING_CAMPAIGNS',  //TODO: whenever we have split by channel data for running campaigns
    'RUNNING_JOURNEYS',
    'RUNNING_RELAYS',
    'SENT_MESSAGES',
    'DELIVERY_RATE',
    'REVENUE',
    'INCREMENTAL_REVENUE',
    'UPLIFT',
    'TOTAL_USERS',
    'KNOWN_USERS',
    'UNKNOWN_USERS',
    'REACHABLE_USERS',
    // 'TOTAL_NUMBER_OF_ISSUE_INVOICES',
    // 'TOTAL_AMOUNT_OF_PAID_INVOICES',
    // 'TOTAL_NUMBER_OF_ACTIVE_PROJECTS',
    // 'UTILIZATION_INDEX',
  ];

  useEffect(() => {
    if (
      startDate &&
      endDate &&
      splitBy &&
      selectedCards &&
      productName &&
      currentFilters
    ) {
      const tempStartDate = startDate.utc().format('YYYY-MM-DD') + 'T00:00:00Z';
      const tempEndDate = endDate.utc().format('YYYY-MM-DD') + 'T23:59:59Z';

      dispatch(
        managementDashboardReportFetchCharts({
          fromDate: tempStartDate,
          granularity: 'DAILY',
          productId: productName === 'ALL' ? null : productName,
          reports: selectedCards,
          splitBy: splitBy,
          toDate: tempEndDate,
        })
      );

      dispatch(
        setCustomizationData({
          ...currentFilters,
          chartReportStartDate: tempStartDate,
          chartReportEndDate: tempEndDate,
          chartReportProductId: productName === 'ALL' ? null : productName,
          chartReportSplitBy: splitBy,
          chartReportSelectedMetrics: selectedCards,
        })
      );
    }
  }, [startDate, endDate, splitBy, selectedCards, productName]);

  useEffect(() => {
    if (currentFilters && isCurrentUserUpdated) {
      setProductName(
        currentUser?.managementDashboardCustomization?.chartReportProductId ??
          'ALL'
      );
      setStartDate(
        currentUser?.managementDashboardCustomization?.chartReportStartDate
          ? moment(
              currentUser?.managementDashboardCustomization
                ?.chartReportStartDate
            )
          : moment().subtract(1, 'months')
      );
      setEndDate(
        currentUser?.managementDashboardCustomization?.chartReportEndDate
          ? moment(
              currentUser?.managementDashboardCustomization?.chartReportEndDate
            )
          : moment()
      );
      setSplitBy(
        currentUser?.managementDashboardCustomization?.chartReportSplitBy ??
          'CHANNEL'
      );
      setSelectedCards(
        currentUser?.managementDashboardCustomization
          ?.chartReportSelectedMetrics ?? [
          'SYSTEM_EVENTS',
          'CUSTOM_EVENTS',
          'ACTIVE_USERS',
          // 'ENDED_CAMPAIGNS',
          // 'RUNNING_CAMPAIGNS',
          // 'RUNNING_JOURNEYS',
          // 'RUNNING_RELAYS',
          // 'SENT_MESSAGES',
          // 'DELIVERY_RATE',
          // 'REVENUE',
          // 'INCREMENTAL_REVENUE',
          // 'UPLIFT',
          // 'TOTAL_NUMBER_OF_ISSUE_INVOICES',
          // 'TOTAL_AMOUNT_OF_PAID_INVOICES',
          // 'TOTAL_NUMBER_OF_ACTIVE_PROJECTS',
          // 'UTILIZATION_INDEX',
        ]
      );
    }
  }, [currentUser, isCurrentUserUpdated]);

  const getChartConfig = (obj) => {
    const data = [];
    Object.keys(obj).forEach((key) => {
      if (!isNaN(new Date(key))) {
        const val = obj[key];

        if (typeof val === 'object' && !Array.isArray(val) && val !== null) {
          Object.keys(val).forEach((internalKey) => {
            data.push({
              date: key,
              value: val[internalKey],
              category: internalKey,
            });
          });
        } else {
          data.push({ date: key, value: val, category: 'value' });
        }
      } else {
        //it is not general !
        const categoryObj = obj[key];
        Object.keys(categoryObj).forEach((date) => {
          data.push({
            date,
            value: categoryObj[date],
            category: key,
          });
        });
      }
    });

    const config = {
      data,
      xField: 'date',
      yField: 'value',
      seriesField: 'category',
      isGroup: true,
      //   xAxis: {
      //     type: 'time',
      //   },
      yAxis: {
        label: {
          formatter: (v) =>
            `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
        },
      },
      smooth: true,
      connectNulls: true,
      legend: {
        layout: 'vertical',
        position: 'right',
      },
      tooltip: {
        formatter: (datum) => ({
          name: datum.category,
          value: numberWithCommas(datum.value), // Format tooltip value with commas
        }),
      },
    };

    return config;
  };

  return (
    <>
      <ReportCardsSectionHeader
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        productList={products}
        productName={productName}
        setProductName={setProductName}
        cardsList={cardsList}
        selectedCards={selectedCards}
        setSelectedCards={setSelectedCards}
        splitBy={splitBy}
        setSplitBy={setSplitBy}
      />
      {loading ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', margin: '50px' }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
                spin
              />
            }
          />
        </div>
      ) : (
        <Row gutter={[16, 16]}>
          {Object.keys(chartsData)?.map((card, index) => (
            <Col key={index} xs={24} sm={24} md={24} lg={24}>
              <ReportChart
                card={card}
                cardTitle={
                  card.charAt(0).toUpperCase() +
                  card.replace('_', ' ').toLowerCase().slice(1)
                }
                config={getChartConfig(chartsData[card])}
              />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}
