import { LoadingOutlined } from '@ant-design/icons';
import BusinessEventTriggerComponent from '@Modules/data/DataManagement/components/businessEvent/component/BusinessEventTriggerComponent';
import { viewDateTime } from '@Utils/DateUtils';
import { Spin, Table } from 'antd';
import React, { useState } from 'react';
import styles from './TrOccurrenceBusinessEventReport.module.less';
const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;

const TrOccurrenceBusinessEventReport = (props) => {
  const [visible, setVisible] = useState(false);
  const [currentBussinesEventAttr, setcurrentBussinesEventAttr] =
    useState(null);

  const handleCancel = () => {
    setVisible(false);
  };
  const showModal = (businessEventInstanceId) => {
    if (businessEventInstanceId) {
      setVisible(true);
      const current = props.data.businessEventsReport.find(
        (cont) => cont.businessEventInstanceId === businessEventInstanceId
      );
      setcurrentBussinesEventAttr(current);
    }
  };
  const columns = [
    {
      title: 'Date of Trigger',
      dataIndex: 'eventTime',
      key: 'eventTime',
      render: (x) => viewDateTime(x),
    },
    {
      title: 'Business Event Attribute values',
      dataIndex: 'businessEventName',
      key: 'businessEventName',
      render: (text, record) => (
        <a onClick={() => showModal(record.businessEventInstanceId)}>{text}</a>
      ),
    },
    {
      title: 'Total Users triggered for',
      dataIndex: 'enters',
      key: 'enters',
    },
  ];
  const data = props?.data?.businessEventsReport;
  const journeyId = props?.data?.journeyId;
  const productId = props?.data?.productId;
  const loading = props.data.loadingBusinessEventsReport;
  return (
    <>
      <div className={styles.listsHeader}>
        <div className={styles.listsHeaderTitle}>
          <span>History </span>

          <i
            className={`fl-sync fl-light ${styles.listsHeaderIcon}`}
            onClick={() => {
              props?.data?.journeyBusinessEventsReportFetch(journeyId, {
                stepId: 1,
              });
            }}
          />
        </div>
      </div>

      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 200,
            paddingTop: 200,
          }}
        >
          <Spin indicator={loadingSpin} />
        </div>
      ) : (
        <>
          <Table columns={columns} dataSource={data} pagination={false} />
          <BusinessEventTriggerComponent
            visible={visible}
            handleCancel={handleCancel}
            currentBussinesEventAttr={currentBussinesEventAttr}
            productId={productId}
            setVisible={setVisible}
            viewMode
          />
        </>
      )}
    </>
  );
};

export default TrOccurrenceBusinessEventReport;
