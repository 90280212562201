import { Button, DatePicker, Form, Input } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  releaseNoteChangeCurrent,
  releaseNoteCreateFetch,
  releaseNoteUpdateFetch,
  releaseNoteUpdateSubmitFetch,
} from '../ReleaseNoteAction';
import RichEditor from '@Component/form/RichEditor';
import { Link } from 'react-router-dom';
import { removeSpaceEnter } from '@Utils/RemoveSpaceEnter';
import moment from 'moment';
import Card from '@Utils/Card';
const styles = {
  box: {
    paddingTop: 50,
    paddingBottom: 10,
    paddingLeft: 50,
  },
  spin: {
    width: '100%',
    height: 100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
};
export default function ReleaseNoteUpdate() {
  const dispatch = useDispatch();
  const pathname = useSelector((state) => state.router.location.pathname);
  const currentNote = useSelector(
    (state) => state.admin.releaseNote.currentNote
  );
  const noteUpdateLoading = useSelector(
    (state) => state.admin.releaseNote.noteUpdateLoading
  );
  const updateMode = pathname.includes('update');
  const dateFormat = 'YYYY/MM/DD';
  useEffect(() => {
    if (updateMode) {
      const productId = pathname.split('update/').pop();
      dispatch(releaseNoteUpdateFetch(productId));
    }
  }, []);
  useEffect(() => {
    return () => {
      dispatch(releaseNoteChangeCurrent({}));
    };
  }, []);

  const onFinish = (values) => {
    let newVal = {
      ...values,
      releaseDate: moment(values.releaseDate).format('YYYY-MM-DD'),
    };
    if (updateMode) {
      const productId = pathname.split('update/').pop();
      dispatch(releaseNoteUpdateSubmitFetch(productId, newVal));
    } else {
      dispatch(releaseNoteCreateFetch(newVal));
    }
  };

  return (
    <Card loading={noteUpdateLoading} styles={styles.box}>
      <Form
        layout="vertical"
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          style={{ width: '50%' }}
          label="Release Note Number"
          name="releaseNoteNumber"
          initialValue={currentNote && currentNote.releaseNoteNumber}
          rules={[
            {
              required: true,
              message: 'Release Note Number is required!',
            },
          ]}
        >
          <Input placeholder="Release Note Number" />
        </Form.Item>
        <Form.Item
          style={{ width: '50%' }}
          label="Release Note Date"
          name="releaseDate"
          initialValue={
            currentNote &&
            currentNote.releaseDate &&
            moment(currentNote.releaseDate, dateFormat)
          }
          rules={[
            {
              required: true,
              message: 'Release Note Date is required!',
            },
          ]}
        >
          <DatePicker style={{ width: '100%' }} format={dateFormat} />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          initialValue={currentNote && currentNote.description}
          rules={[
            {
              validator: removeSpaceEnter,
            },
          ]}
        >
          <RichEditor isRich={true} type="rich" />
        </Form.Item>
        <div style={styles.row}>
          <Link to={updateMode ? '../list' : 'list'}>
            <Button
              type="primary"
              shape="round"
              size={'large'}
              className="w-200"
            >
              {'Back'}
            </Button>
          </Link>
          <Form.Item>
            <Button
              className="w-200"
              style={{ marginRight: 40 }}
              loading={noteUpdateLoading}
              shape="round"
              size={'large'}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>{' '}
    </Card>
  );
}
