import {
  ADMIN_PRODUCT_LIST_FETCH,
  ADMIN_PRODUCT_LIST_FETCH_SUCCESS,
  ADMIN_PRODUCT_CHANGE_CURRENT,
  ADMIN_PRODUCT_UPDATE_FETCH_SUCCESS,
  ADMIN_PRODUCT_CREATE_FETCH_FAIL,
  ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH_FAIL,
  ADMIN_PRODUCT_PAGE_CHANGE_CURRENT,
  ADMIN_PRODUCT_VIEW_FETCH_SUCCESS,
  ADMIN_PRODUCT_VIEW_FETCH,
  ADMIN_PRODUCT_VIEW_FETCH_FAIL,
  ADMIN_PRODUCT_UPDATE_FETCH,
  ADMIN_PRODUCT_UPDATE_FETCH_FAIL,
  ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH_SUCCESS,
  ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH,
  ADMIN_PRODUCT_CREATE_FETCH,
  ADMIN_PRODUCT_CREATE_FETCH_SUCCESS,
  EVENT_RATE_CHANGE_CURRENT,
  PARENT_PRODUCT_LIST_FETCH_SUCCESS,
} from './AdminProductConstants';

const initialState = {
  page: {},
  pageRequest: {},
  errors: [],
  currentAdminProduct: {
    allowProfileUpdateFromWebSdk: false,
    sendify: false,
    throttlingEnabled: false,
    adsEnabled: false,
    demo: false,
  },
  loadingProducts: false,
  loadingAdminProduct: false,
  loadingSearchProductNameList: false,
  loadingProductNameList: false,
  submitLoading: false,
  parentProducts: [],
};

export default function AdminProductStateReducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_PRODUCT_LIST_FETCH:
      return {
        ...state,
        pageRequest: action.payload,
        loadingSearchProductNameList: true,
        loadingProductNameList: true,
      };
    case ADMIN_PRODUCT_LIST_FETCH_SUCCESS:
      return {
        ...state,
        page: action.payload,
        loadingSearchProductNameList: false,
        loadingProductNameList: false,
      };
    case ADMIN_PRODUCT_CREATE_FETCH_FAIL:
      return {
        ...state,
        submitLoading: false,
      };
    case ADMIN_PRODUCT_CREATE_FETCH:
      return {
        ...state,
        currentAdminProduct: action.payload,
        submitLoading: true,
      };
    case ADMIN_PRODUCT_CREATE_FETCH_SUCCESS:
      return {
        ...state,
        currentAdminProduct: action.payload,
        submitLoading: false,
      };
    case ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH:
      return {
        ...state,
        currentAdminProduct: action.payload,
        submitLoading: true,
      };
    case ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH_SUCCESS:
      return {
        ...state,
        currentAdminProduct: action.payload,
        submitLoading: false,
      };
    case ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH_FAIL:
      return { ...state, errors: action.payload.errors, submitLoading: false };
    case ADMIN_PRODUCT_CHANGE_CURRENT:
      return { ...state, currentAdminProduct: action.payload, errors: [] };
    case ADMIN_PRODUCT_UPDATE_FETCH:
      return {
        ...state,
        currentAdminProduct: action.payload,
        loadingAdminProduct: true,
      };
    case ADMIN_PRODUCT_UPDATE_FETCH_FAIL:
      return {
        ...state,
        currentAdminProduct: action.payload,
        loadingAdminProduct: false,
      };
    case ADMIN_PRODUCT_UPDATE_FETCH_SUCCESS:
      return {
        ...state,
        currentAdminProduct: action.payload,
        loadingAdminProduct: false,
      };
    case ADMIN_PRODUCT_VIEW_FETCH:
      return {
        ...state,
        currentAdminProduct: action.payload,
        loadingProducts: true,
      };
    case ADMIN_PRODUCT_VIEW_FETCH_FAIL:
      return {
        ...state,
        currentAdminProduct: action.payload,
        loadingProducts: false,
      };
    case ADMIN_PRODUCT_VIEW_FETCH_SUCCESS:
      return {
        ...state,
        currentAdminProduct: action.payload,
        loadingProducts: false,
      };
    case ADMIN_PRODUCT_PAGE_CHANGE_CURRENT:
      return { ...state, pageRequest: action.payload };
    case EVENT_RATE_CHANGE_CURRENT:
      return { ...state, currentAdminProduct: action.payload };
    case PARENT_PRODUCT_LIST_FETCH_SUCCESS:
      return { ...state, parentProducts: action.payload };
    default:
      return state;
  }
}
