import Card from '@Utils/Card';
import React from 'react';
import {
  Switch,
  Form,
  Spin,
  Select,
  Badge,
  Input,
  Checkbox,
  Tooltip,
  Divider,
} from 'antd';
import { useSelector } from 'react-redux';
import { errorHandling } from '../../../../../utils/FormUtils';
import { isNil } from 'lodash';
import { clearCommunicationTrackingError } from '../../CommunicationActions';
import ControlGroupSection from './ControlGroupSection';
import VariationSection from './VariationSection';
import WinnerMechanismSection from './WinnerMechanismSection';
import { timeDuration } from './CommunicationConst';

const TrackingPage = ({
  journey,
  communicationHasJourneyType,
  isEditDisable,
  conversionTracking,
  handleConversionTrackingChange,
  handleSelectorModalVisibleChange,
  handleCurrentPredicateChange,
  currentPredicate,
  variationDistribution,
  handleVariationDistributionChange,
  winnerMechanism,
  handleWinnerMechanismChange,
  winnerCriteriaFilter,
}) => {
  const loadingUpdateConversion = useSelector(
    (state) => state.engage.communication.loadingUpdateConversion
  );
  const loadingEvents = useSelector(
    (state) => state.engage.communication.loadingEvents
  );
  const errorUpdateConversionSubmit = useSelector(
    (state) => state.engage.communication.errorUpdateConversionSubmit
  );
  const eventList = useSelector(
    (state) => state.engage.communication.eventList
  );
  const currentConversion = useSelector(
    (state) => state.engage.communication.currentConversion
  );

  let badgeCount =
    conversionTracking?.conversionEventFilterPredicates?.length ?? 0;

  const changeConversionDeadline = (value, errorId) => {
    handleConversionTrackingChange(
      {
        ...conversionTracking,
        conversionValue: value,
      },
      errorId
    );
    clearCommunicationTrackingError(
      errorUpdateConversionSubmit?.errors,
      'conversionDeadlineMinutes'
    );
  };

  const selectEventOnChange = (value, errorId) => {
    handleConversionTrackingChange(
      {
        ...conversionTracking,
        conversionEvent: value,
      },
      errorId
    );

    handleCurrentPredicateChange({
      ...currentPredicate,
      event: value,
      eventConjunction: 'AND',
      eventPredicates: [],
    });

    clearCommunicationTrackingError(
      errorUpdateConversionSubmit.errors,
      'conversionEvent'
    );
  };

  return (
    <div className="raman-table">
      {!journey && !communicationHasJourneyType() && (
        <div
          style={{
            background: 'none',
            marginTop: 40,
            backgroundColor: 'white',
          }}
        >
          <Card>
            <div style={styles.infoBoxHeader}>
              <div style={styles.headTitleContainer}>
                <span>Conversion Tracking</span>
                <Switch
                  id={'int-communication-tracking-switch'}
                  data-cy="communication-tracking-switch"
                  disabled={isEditDisable()}
                  checked={conversionTracking.conversionEnable}
                  onChange={(conversionEnable) =>
                    handleConversionTrackingChange({
                      ...conversionTracking,
                      conversionEnable,
                    })
                  }
                  style={{ position: 'relative', top: 5, left: 10 }}
                />
              </div>
            </div>
            {!!conversionTracking.conversionEnable && (
              <Form layout="vertical">
                <>
                  {loadingUpdateConversion || loadingEvents ? (
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: 40,
                        paddingBottom: 30,
                      }}
                    >
                      <Spin />
                    </div>
                  ) : (
                    <div style={{ padding: 30 }}>
                      <div style={styles.editContainer}>
                        <div style={{ ...styles.editLabel }}>
                          CONVERSION EVENT
                        </div>
                        <div
                          style={{
                            ...styles.editFieldContainer,
                            marginLeft: 20,
                            flexDirection: 'row',
                          }}
                        >
                          <Form.Item
                            validateStatus={
                              errorHandling(
                                errorUpdateConversionSubmit,
                                'conversionEvent'
                              )
                                ? 'error'
                                : null
                            }
                            help={errorHandling(
                              errorUpdateConversionSubmit,
                              'conversionEvent'
                            )}
                            id={
                              errorHandling(
                                errorUpdateConversionSubmit,
                                'conversionEvent'
                              )
                                ? 'error'
                                : null
                            }
                          >
                            <Select
                              id={'int-communication-tracking-event'}
                              data-cy={'communication-tracking-event'}
                              loading={loadingEvents}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toString()
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              disabled={
                                !conversionTracking.conversionEnable ||
                                isEditDisable()
                              }
                              style={
                                !conversionTracking.conversionEnable
                                  ? { ...styles.editField, fontSize: 13 }
                                  : {
                                      ...styles.editField,
                                      fontSize: 13,
                                      color: '#333',
                                    }
                              }
                              value={
                                !isNil(conversionTracking.conversionEvent)
                                  ? conversionTracking.conversionEvent
                                  : null
                              }
                              dropdownMenuStyle={{ maxHeight: 150 }}
                              size="large"
                              onChange={(conversionEvent) =>
                                selectEventOnChange(
                                  conversionEvent,
                                  'conversionEvent'
                                )
                              }
                            >
                              {loadingEvents
                                ? null
                                : eventList &&
                                  eventList.map((ua) => {
                                    return (
                                      <Select.Option value={ua.id} key={ua.id}>
                                        {ua.title}
                                      </Select.Option>
                                    );
                                  })}
                            </Select>
                          </Form.Item>
                          <span
                            id={'int-communication-tracking-event-attribute'}
                            onClick={() =>
                              handleSelectorModalVisibleChange(
                                !isNil(conversionTracking.conversionEvent)
                              )
                            }
                            style={{
                              position: 'relative',
                              top: 8,
                              left: 10,
                              cursor: 'pointer',
                            }}
                          >
                            {loadingEvents ? null : (
                              <Badge count={badgeCount} showZero={false}>
                                <i
                                  className="fl-filter fl-dark"
                                  style={{ fontSize: 28 }}
                                ></i>
                              </Badge>
                            )}
                          </span>
                        </div>
                      </div>
                      <div style={{ ...styles.editContainer, marginTop: 20 }}>
                        <div style={{ ...styles.editLabel }}>
                          CONVERSION DEADLINE
                        </div>
                        <div
                          style={{
                            ...styles.editFieldContainer,
                            marginLeft: 20,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Form.Item
                              validateStatus={
                                errorHandling(
                                  errorUpdateConversionSubmit,
                                  'conversionDeadlineMinutes'
                                )
                                  ? 'error'
                                  : null
                              }
                              help={errorHandling(
                                errorUpdateConversionSubmit,
                                'conversionDeadlineMinutes'
                              )}
                              id={
                                errorHandling(
                                  errorUpdateConversionSubmit,
                                  'conversionDeadlineMinutes'
                                )
                                  ? 'error'
                                  : null
                              }
                            >
                              <Input
                                id={
                                  'int-communication-tracking-conversion-value'
                                }
                                data-cy={
                                  'communication-tracking-conversion-value'
                                }
                                disabled={
                                  !conversionTracking.conversionEnable ||
                                  isEditDisable()
                                }
                                value={conversionTracking.conversionValue}
                                onChange={(e) =>
                                  changeConversionDeadline(
                                    e.target.value,
                                    'conversionDeadlineMinutes'
                                  )
                                }
                                style={{ width: 60, marginRight: 20 }}
                              />
                              <Select
                                id={
                                  'int-communication-tracking-conversion-duration'
                                }
                                data-cy="communication-tracking-conversion-duration"
                                disabled={
                                  !conversionTracking.conversionEnable ||
                                  isEditDisable()
                                }
                                style={
                                  !conversionTracking.conversionEnable
                                    ? {
                                        width: 130,
                                        marginRight: 20,
                                        fontSize: 13,
                                      }
                                    : {
                                        width: 130,
                                        marginRight: 20,
                                        fontSize: 13,
                                        color: '#333',
                                      }
                                }
                                value={conversionTracking.conversionDuration}
                                onChange={(conversionDuration) =>
                                  handleConversionTrackingChange(
                                    {
                                      ...conversionTracking,
                                      conversionDuration,
                                    },
                                    'conversionDeadlineMinutes'
                                  )
                                }
                              >
                                {Object.keys(timeDuration).map((time) => {
                                  return (
                                    <Select.Option value={time} key={time}>
                                      {timeDuration[time]}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            <span style={{ marginBottom: '32px' }}>
                              from delivery of message to user
                            </span>
                          </div>
                        </div>
                      </div>
                      <div style={{ ...styles.editContainer, marginTop: 20 }}>
                        <div style={{ ...styles.editLabel }}>
                          CONTROL GROUP (CG)
                        </div>
                        <div
                          style={{
                            ...styles.editFieldContainer,
                            marginLeft: 20,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Checkbox
                              id={'int-communication-tracking-control-group'}
                              disabled={isEditDisable()}
                              checked={conversionTracking.controlGroupEnable}
                              onChange={(e) =>
                                handleConversionTrackingChange({
                                  ...conversionTracking,
                                  controlGroupEnable: e.target.checked,
                                })
                              }
                            >
                              {
                                'Add a control group to measure effectiveness of campaign'
                              }
                            </Checkbox>
                          </div>
                          {['IN_APP', 'ON_SITE'].includes(
                            currentConversion?.channel
                          ) &&
                            conversionTracking.controlGroupEnable && (
                              <div
                                style={{
                                  marginTop: '8px',
                                  color: 'red',
                                  fontSize: '12px',
                                }}
                              >
                                Users with a session started within 30 minutes
                                of launch.
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              </Form>
            )}
          </Card>
        </div>
      )}

      <div
        style={{
          background: 'none',
          marginBottom: 40,
          marginTop: 40,
          backgroundColor: 'white',
        }}
      >
        <Card>
          <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
            <div style={styles.headTitleContainer}>Variation Distribution</div>
          </div>
          <div style={{ padding: 30 }}>
            {variationDistribution.variations.length > 1 && (
              <div style={{ ...styles.editContainer }}>
                <div
                  style={{
                    ...styles.editLabel,
                    lineHeight: '-10',
                  }}
                >
                  Enable Winner Mechanism
                  <span>
                    <Tooltip
                      placement={'top'}
                      title="Once you have created multiple variations of a campaign's message, you can choose to automate the testing process by enabling Winner Mechanism Automatically. Doing so will enable you to test all the variations (and Control Group if enabled) with a smaller test audience before determining a winning variant and sending it to the entire target audience."
                    >
                      <i
                        className="fl-info"
                        style={{
                          color: '#63C1FB',
                          fontSize: 28,
                          padding: 0,
                          margin: 0,
                          position: 'relative',
                          top: 4,
                        }}
                      ></i>
                    </Tooltip>
                  </span>
                </div>

                <div style={{ ...styles.editFieldContainer }}>
                  <Switch
                    id={'int-communication-tracking-switch'}
                    data-cy="communication-tracking-switch"
                    disabled={isEditDisable() || !winnerCriteriaFilter.length}
                    checked={conversionTracking.winnerMechanismEnabled}
                    onChange={(winnerMechanismEnabled) =>
                      handleConversionTrackingChange({
                        ...conversionTracking,
                        winnerMechanismEnabled,
                      })
                    }
                    style={{
                      position: 'relative',
                      left: 10,
                      width: '48px',
                    }}
                  />
                </div>
              </div>
            )}
            {conversionTracking.winnerMechanismEnabled && (
              <>
                <WinnerMechanismSection
                  journey={journey}
                  winnerMechanism={winnerMechanism}
                  handleWinnerMechanismChange={handleWinnerMechanismChange}
                  isEditDisable={isEditDisable}
                  winnerCriteriaFilter={winnerCriteriaFilter}
                />
                <Divider style={{ margin: '20px 0px' }} />
              </>
            )}
            {conversionTracking.controlGroupEnable &&
              conversionTracking.conversionEnable && (
                <div style={{ ...styles.editContainer, marginTop: '32px' }}>
                  <div
                    style={{
                      ...styles.editLabel,
                      position: 'relative',
                      top: -10,
                    }}
                  >
                    VARIATIONS VS. CG
                  </div>
                  <div style={{ ...styles.editFieldContainer, marginLeft: 20 }}>
                    <ControlGroupSection
                      variationDistribution={variationDistribution}
                      handleVariationDistributionChange={
                        handleVariationDistributionChange
                      }
                      isEditDisable={isEditDisable}
                    />
                  </div>
                </div>
              )}
            <div style={{ ...styles.editContainer, marginTop: 20 }}>
              <div style={styles.editLabel}>VARIATIONS DISTRIBUTION</div>
              <div
                style={{
                  ...styles.editFieldContainer,
                  marginLeft: 20,
                  marginTop: 9,
                }}
              >
                <VariationSection
                  variationDistribution={variationDistribution}
                  conversionTracking={conversionTracking}
                  isEditDisable={isEditDisable}
                  handleVariationDistributionChange={
                    handleVariationDistributionChange
                  }
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default TrackingPage;

const styles = {
  editContainer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  editFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 5,
    fontSize: 13,
    lineHeight: 1.43,
    color: '#3d404e',
  },
  editField: {
    width: 350,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'right',
  },
  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    display: 'flex',
    flexDirection: 'row',
  },
  infoBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 30px',
    borderBottom: '1px solid #e7e8e9',
    backgroundColor: '#fdfdfd',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
