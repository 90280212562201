import React, { useContext, useEffect, useState } from 'react';
import MasterModalContent from './MasterModalContent';
import TrOccurrenceEvent from './TrOccurrenceEvent';
import TrOccurrenceBusinessEvent from './TrOccurrenceBusinessEvent';
import TrSpecificUsers from './TrSpecificUsers';
import TrEnterExits from './TrEnterExits';
import TrChangeInUserAttr from './TrChangeInUserAttr';
// import TrForSpecificUser from "./TrForSpecificUser";
// import TrEnterGeoFence from "./TrEnterGeoFence";
// import AcSendEmail from "./AcSendEmail";
import './modalContent.less';
import CondIsInSegment from './CondIsInSegment';
import CondHasDoneEvent from './CondHasDoneEvent';
import CondCheckUserAttr from './CondCheckUserAttr';
import CondIsUserReachable from './CondIsUserReachable';
import FlowWaitForTime from './FlowWaitForTime';
import FlowWaitForEvent from './FlowWaitForEvent';
import { AppContext } from '../../app';
import AcCallApi from './AcCallApi';
import { filterJourneyVariableSteps } from '../../utils/hooks';
import { CloseOutlined } from '@ant-design/icons';
import FlowWaitForTimeSlot from './FlowWaitForTimeSlot';
import FlowWaitForDate from './FlowWaitForDate';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AcSetUserAttribute from './AcSetUserAttribute';
import TrafficSplitter from './TrafficSplitter';
import { useSelector } from 'react-redux';
import { useIsParentProduct } from '@Utils/isParentProduct';
import { useBannerStackSize } from '@Utils/bannerStackSize';

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

const EditNode = (props) => {
  const { node, closeModal, content: contentModal, els, getSegment } = props;
  const [content, setContent] = useState();
  const [loading, setLoading] = useState(true);
  const { journey, syncCache } = useContext(AppContext);

  const currentUser = useSelector((state) => state.account.auth.currentUser);

  const isParent = useIsParentProduct(currentUser);

  const listUneditableModalForParentProducts = [
    'EVENT_OCCURRENCE',
    'IS_IN_SEGMENT',
    'HAS_DONE_EVENT',
    'SPECIFIC_USERS',
    'ENTER_EXIT_SEGMENT',
    'EVENT_OCCURRENCE',
    'WAIT_FOR_EVENT',
    'CHANGE_IN_USER_ATTRIBUTE',
    'CHECK_USER_ATTRIBUTE',
    'WAIT_FOR_DATE',
    // 'Wait For Date' is handled inside its component
  ];
  const disableModals = () => {
    if (
      isParent &&
      listUneditableModalForParentProducts.includes(node.data?.key)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (els?.data?.events) {
      setLoading(false);
    } else {
      return;
    }

    // Call server
    const key = node.data?.key;
    if (key === 'TRAFFIC_SPLITTER') {
      setContent(<TrafficSplitter {...els} closeModal={closeModal} />);
    } else if (key === 'BUSINESS_EVENT_OCCURRENCE') {
      setContent(
        <TrOccurrenceBusinessEvent {...els} closeModal={closeModal} />
      );
    } else if (key === 'EVENT_OCCURRENCE') {
      setContent(<TrOccurrenceEvent {...els} closeModal={closeModal} />);
    } else if (key === 'IS_IN_SEGMENT') {
      setContent(<CondIsInSegment {...els} closeModal={closeModal} />);
    } else if (key === 'HAS_DONE_EVENT') {
      setContent(<CondHasDoneEvent {...els} closeModal={closeModal} />);
    } else if (key === 'CHECK_USER_ATTRIBUTE') {
      setContent(<CondCheckUserAttr {...els} closeModal={closeModal} />);
    } else if (key === 'IS_USER_REACHABLE') {
      setContent(<CondIsUserReachable {...els} closeModal={closeModal} />);
    } else if (key === 'WAIT_FOR_TIME') {
      setContent(<FlowWaitForTime {...els} closeModal={closeModal} />);
    } else if (key === 'WAIT_FOR_DATE') {
      setContent(<FlowWaitForDate {...els} closeModal={closeModal} />);
    } else if (key === 'WAIT_FOR_EVENT') {
      setContent(<FlowWaitForEvent {...els} closeModal={closeModal} />);
    } else if (key === 'WAIT_FOR_TIME_SLOT') {
      setContent(<FlowWaitForTimeSlot {...els} closeModal={closeModal} />);
    } else if (key === 'SPECIFIC_USERS') {
      setContent(<TrSpecificUsers {...els} closeModal={closeModal} />);
    } else if (key === 'CHANGE_IN_USER_ATTRIBUTE') {
      setContent(<TrChangeInUserAttr {...els} closeModal={closeModal} />);
    } else if (key === 'ENTER_EXIT_SEGMENT') {
      setContent(
        <TrEnterExits
          {...els}
          closeModal={closeModal}
          getSegment={getSegment}
        />
      );
    } else if (key === 'CALL_API') {
      setContent(<AcCallApi {...els} closeModal={closeModal} />);
    } else if (key === 'SET_USER_ATTRIBUTE') {
      setContent(<AcSetUserAttribute {...els} closeModal={closeModal} />);
    } else if (
      key === 'SEND_SMS' ||
      key === 'SEND_EMAIL' ||
      key == 'SEND_PUSH' ||
      key == 'SEND_WEB_PUSH' ||
      key == 'SEND_CUSTOM' ||
      key == 'SEND_IN_APP' ||
      key == 'SEND_ON_SITE' ||
      key == 'SEND_WHATSAPP'
    ) {
      const journeyStepsForVariable = filterJourneyVariableSteps(
        journey.steps,
        props.node.id
      ); // Remove self step
      const currentStep = journey.steps.find(
        (step) => step.id + '' === props.node.id + ''
      );
      const contents = contentModal();
      setContent(
        contents[key](
          {
            dataNode: currentStep,
            communication: currentStep?.communication,
          },
          syncCache,
          closeModal,
          els.readOnly,
          journeyStepsForVariable
        )
      );
    }
  }, [els.data.events, els.data.loadingBusinessEventsReport]);

  const bannerStackSize = useBannerStackSize();

  const nType =
    node.data.nodeType === 'FLOWCONTROL' ? 'FLOW CONTROL' : node.data.nodeType;

  return (
    <div className="modal">
      <div className={'modal-header'}>
        <h2>
          {nType}: {node.data?.label}
        </h2>
        {loading && (
          <Spin className={'modal-loading__icon'} indicator={antIcon} />
        )}
      </div>
      <button
        onClick={() => closeModal(false, false)}
        className={`modal-close__button modal-close-banner-${bannerStackSize}`}
      >
        <CloseOutlined style={{ fontSize: '22px' }} />
      </button>
      <div
        style={disableModals() ? { pointerEvents: 'none', opacity: '0.4' } : {}}
      >
        {content && (
          <MasterModalContent node={node}>{content}</MasterModalContent>
        )}
      </div>
    </div>
  );
};

export default EditNode;
