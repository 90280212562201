import * as React from 'react';

function SvgWebPush(props) {
  return (
    <svg viewBox="0 0 26 23" width="1em" height="1em" {...props}>
      <path
        d="M20.646 2H2.256C1.012 2.003.003 3.095 0 4.443v15.685c.002 1.348 1.011 2.44 2.257 2.443h19.487c1.245-.002 2.255-1.095 2.256-2.443V5.354A3.214 3.214 0 0120.646 2zM2.426 3.714h3.72c.393 0 .711.384.711.857 0 .474-.318.858-.711.858h-3.72c-.394 0-.712-.384-.712-.858 0-.473.318-.857.712-.857zm19.86 16.143a1 1 0 01-.98 1H2.694c-.535 0-.978-.453-.98-1V7.143h20.572v12.714zm1.571-15.571a2.143 2.143 0 100-4.286 2.143 2.143 0 000 4.286z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgWebPush;
