import React, { useState, useEffect } from 'react';
import {
  Input,
  Form,
  Upload,
  Button,
  message,
  Collapse,
  Col,
  Row,
  List,
  Popconfirm,
} from 'antd';
import style from './UploadReceiptModalContent.module.less';
import StripedBox from '@Component/app/StripedBox';
import UploadCloud from '../../../../../theme/icons/UploadCloud';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { numberWithCommas } from '@Utils/RenderUtils';

const { Dragger } = Upload;
const Panel = Collapse.Panel;

const UploadReceiptModalContent = ({
  receipts,
  setReceipts,
  description,
  setDescription,
  selectedInvoiceId,
  isViewMode,
}) => {
  const { page: invoiceList } = useSelector((state) => state.setting.invoice);

  const [selectedInvoice, setSelectedInvoice] = useState();

  useEffect(() => {
    invoiceList?.content?.forEach((invoice) => {
      if (invoice.id === selectedInvoiceId) {
        setSelectedInvoice(invoice);
      }
    });
  }, [selectedInvoiceId]);

  const props = {
    name: 'file',
    maxCount: 1,
    listType: 'picture',
    showUploadList: false,
    multiple: false,
    accept: '.png, .jpg, .jpeg',
    // TODO: Refactor to handle two simultaneous upload requests.
    action: '/api/web/base/file/upload',
  };

  const rows = [
    {
      columns: [
        <span key="0">Total</span>,
        <span key="1">
          {numberWithCommas(selectedInvoice?.finalPrice)} Rial
        </span>,
      ],
    },
    {
      columns: [
        <span key="0">Date</span>,
        <span key="1">
          <span style={{ color: 'rgba(56, 55, 59, 0.65)' }}>From</span>{' '}
          {selectedInvoice?.startDate}{' '}
          <span style={{ color: 'rgba(56, 55, 59, 0.65)' }}>to</span>{' '}
          {selectedInvoice?.endDate}
        </span>,
      ],
    },
    {
      columns: [
        <span key="0">Account Number</span>,
        <span key="1">826-810-5218750-1 </span>,
      ],
    },
    {
      columns: [
        <span key="0">Sheba</span>,
        <span key="1">IR690560082681005218750001</span>,
      ],
    },
  ];

  const cols = [
    {
      width: '50%',
      textAlign: 'left',
      color: 'rgba(56, 55, 59, 0.8)',
      fontWeight: '400',
    },
    {
      width: '50%',
      overflowWrap: 'anywhere',
      textAlign: 'left',
      color: '#38373BCC',
      fontWeight: '500',
    },
  ];

  const handleRemoveUploadedReceipt = (id) => {
    const tempReceipts = [...receipts];
    tempReceipts.forEach((receipt) => {
      if (receipt.id === id) {
        receipt.loading = false;
        receipt.filePath = '';
        receipt.fileName = '';
      }
    });
    setReceipts(tempReceipts);
  };

  const handleLoading = (loading, id) => {
    const tempReceipts = [...receipts];
    tempReceipts.forEach((receipt) => {
      if (receipt.id === id) {
        receipt.loading = loading;
      }
    });
    return tempReceipts;
  };

  const handleUploadChange = (info, id) => {
    const { status, type } = info.file;
    let tempReceipts = [...receipts];
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

    if (!allowedTypes.includes(type) && status !== 'uploading') {
      message.error(
        'Invalid file type. Please upload a PNG, JPEG, or JPG file.'
      );
      return;
    }

    if (status === 'uploading') {
      if (allowedTypes.includes(type)) {
        tempReceipts = handleLoading(true, id);
        setReceipts(tempReceipts);
      }
    } else if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
      tempReceipts.forEach((receipt) => {
        if (receipt.id === id) {
          receipt.fileName = info.file.name;
          receipt.loading = false;
          receipt.filePath = info.file.response?.result?.path;
        }
      });
      setReceipts(tempReceipts);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
      tempReceipts.forEach((receipt) => {
        if (receipt.id === id) {
          receipt.loading = false;
          receipt.filePath = '';
        }
      });
      setReceipts(tempReceipts);
    } else {
      tempReceipts = handleLoading(false, id);
      setReceipts(tempReceipts);
    }
  };

  const handleReceiptInfoChange = (e, id) => {
    let tempReceipts = [...receipts];

    tempReceipts.forEach((receipt) => {
      if (receipt.id === id) {
        receipt[e.target.name] = e.target.value;
      }
    });
    setReceipts(tempReceipts);
  };

  const handleRemoveReceiptCard = (id) => {
    const tempReceipts = receipts.filter((receipt) => receipt.id !== id);
    setReceipts(tempReceipts);
  };

  const handleAddReceiptCard = () => {
    if (receipts.length < 4) {
      setReceipts([
        ...receipts,
        {
          id: Math.round(Math.random() * 100000) + receipts.length,
          referenceNo: '',
          loading: false,
          filePath: '',
          amount: 0,
        },
      ]);
    }
  };

  return (
    <div id="uploadReceiptModal">
      <p className={style.header}>
        <div>
          To make your invoice payment, you can use the following financial
          information.
        </div>
        <div>
          Please upload all payment receipts so that our team can process them
          ASAP.
        </div>
      </p>
      <div className={style.title}>Receipt Info</div>
      <StripedBox cols={cols} rows={rows} />
      <Form
        style={{ marginTop: '16px' }}
        labelCol={{
          span: 5,
        }}
      >
        <div className={style.receiptHeader} style={{ marginBottom: '8px' }}>
          <div className={style.title}>Upload Receipt(s)</div>
          {receipts.length < 4 && !isViewMode ? (
            <Button
              type="text"
              className={style.addReceiptTitle}
              onClick={handleAddReceiptCard}
            >
              + Add receipt
            </Button>
          ) : null}
        </div>

        <Form.Item name="Receipts">
          {receipts.map((receipt, index) => {
            return (
              <Collapse bordered={true} key={receipt?.id}>
                <Panel
                  header={
                    <ReceiptCardHeader
                      receipts={receipts}
                      receiptId={receipt?.id}
                      receiptIndex={index}
                      handleRemoveReceiptCard={handleRemoveReceiptCard}
                      isViewMode={isViewMode}
                    />
                  }
                  className={style.customPanel}
                >
                  <>
                    <Row gutter={[8, 16]} align="middle">
                      <Col span={12}>Upload Receipt</Col>
                      <Col span={12}>
                        {!receipt?.fileName ? (
                          <Dragger
                            {...props}
                            onChange={(info) => {
                              handleUploadChange(info, receipt?.id);
                            }}
                            disabled={receipt?.loading || isViewMode}
                          >
                            <div className={style.uploadBox}>
                              <div className={style.uploadBox}>
                                <UploadCloud />
                                <div>
                                  <p className="ant-upload-hint">
                                    Select a file or drag and drop here
                                  </p>
                                </div>
                              </div>

                              <Button
                                className="btn_status"
                                shape="round"
                                loading={receipt?.loading}
                                disabled={isViewMode}
                              >
                                Select file
                              </Button>
                            </div>
                          </Dragger>
                        ) : (
                          <List>
                            <List.Item
                              style={{
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                padding: '8px',
                              }}
                              actions={
                                !isViewMode
                                  ? [
                                      <Popconfirm
                                        key={receipt?.fileName}
                                        title="Are you sure you want to delete this file?"
                                        onConfirm={() =>
                                          handleRemoveUploadedReceipt(
                                            receipt?.id
                                          )
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Button
                                          icon={<CloseOutlined />}
                                          type="text"
                                        />
                                      </Popconfirm>,
                                    ]
                                  : null
                              }
                            >
                              <List.Item.Meta
                                style={{
                                  fontWeight: '500 !important',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                avatar={
                                  <a
                                    href={receipt?.filePath}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    <img
                                      src={receipt?.filePath}
                                      alt=""
                                      style={{
                                        width: '30px',
                                        height: '30px',
                                        objectFit: 'cover',
                                      }}
                                    />
                                  </a>
                                }
                                title={
                                  <a
                                    href={receipt?.filePath}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={style.receiptInfo}
                                  >
                                    <span>{receipt?.fileName || '--'}</span>
                                  </a>
                                }
                              />
                            </List.Item>
                          </List>
                        )}
                      </Col>
                      <Col span={12}>Amount (IRR)</Col>
                      <Col span={12}>
                        <Input
                          name="amount"
                          type="number"
                          value={receipt.amount}
                          onChange={(e) =>
                            handleReceiptInfoChange(e, receipt.id)
                          }
                          disabled={isViewMode}
                        />
                      </Col>
                      <Col span={12}>Reference Number</Col>
                      <Col span={12}>
                        <Input
                          name="referenceNo"
                          value={receipt.referenceNo}
                          onChange={(e) =>
                            handleReceiptInfoChange(e, receipt.id)
                          }
                          disabled={isViewMode}
                        />
                      </Col>
                    </Row>
                  </>
                </Panel>
              </Collapse>
            );
          })}
        </Form.Item>

        <Form.Item name="comment">
          <div className={style.inputLabel}>Description</div>
          <Input.TextArea
            name="nameSpace"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            disabled={isViewMode}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default UploadReceiptModalContent;

const ReceiptCardHeader = ({
  receipts,
  receiptId,
  receiptIndex,
  handleRemoveReceiptCard,
  isViewMode,
}) => {
  return (
    <div className={style.receiptHeader}>
      <div className={style.title}>{`Receipt ${receiptIndex + 1}`}</div>
      {receipts.length > 1 && !isViewMode ? (
        <Button
          type="text"
          className={style.removeReceiptTitle}
          onClick={() => handleRemoveReceiptCard(receiptId)}
        >
          Remove
        </Button>
      ) : null}
    </div>
  );
};
