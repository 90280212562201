export const COMMUNICATION_CHANGE_CURRENT = 'COMMUNICATION_CHANGE_CURRENT';
export const COMMUNICATION_PAGE_CHANGE_CURRENT =
  'COMMUNICATION_PAGE_CHANGE_CURRENT';

export const COMMUNICATION_CREATE_CLEAR = 'COMMUNICATION_CREATE_CLEAR';
export const COMMUNICATION_CREATE_FETCH = 'COMMUNICATION_CREATE_FETCH';
export const COMMUNICATION_CREATE_FETCH_FAIL =
  'COMMUNICATION_CREATE_FETCH_FAIL';
export const COMMUNICATION_CREATE_FETCH_SUCCESS =
  'COMMUNICATION_CREATE_FETCH_SUCCESS';

export const COMMUNICATION_DRAFT_SAVE = 'COMMUNICATION_DRAFT_SAVE';
export const COMMUNICATION_DRAFT_SAVE_DISCARD =
  'COMMUNICATION_DRAFT_SAVE_DISCARD';

export const COMMUNICATION_AUDIENCE_CREATE_FETCH =
  'COMMUNICATION_AUDIENCE_CREATE_FETCH';
export const COMMUNICATION_AUDIENCE_CREATE_FETCH_SUCCESS =
  'COMMUNICATION_AUDIENCE_CREATE_FETCH_SUCCESS';
export const COMMUNICATION_AUDIENCE_CREATE_FETCH_FAIL =
  'COMMUNICATION_AUDIENCE_CREATE_FETCH_FAIL';

export const COMMUNICATION_COPY_FETCH = 'COMMUNICATION_COPY_FETCH';
export const COMMUNICATION_COPY_FETCH_FAIL = 'COMMUNICATION_COPY_FETCH_FAIL';
export const COMMUNICATION_COPY_FETCH_SUCCESS =
  'COMMUNICATION_COPY_FETCH_SUCCESS';

export const COMMUNICATION_DELETE_FETCH = 'COMMUNICATION_DELETE_FETCH';
export const COMMUNICATION_DELETE_FETCH_FAIL =
  'COMMUNICATION_DELETE_FETCH_FAIL';
export const COMMUNICATION_DELETE_FETCH_SUCCESS =
  'COMMUNICATION_DELETE_FETCH_SUCCESS';

export const COMMUNICATION_LAUNCH_FETCH = 'COMMUNICATION_LAUNCH_FETCH';
export const COMMUNICATION_LAUNCH_FETCH_FAIL =
  'COMMUNICATION_LAUNCH_FETCH_FAIL';
export const COMMUNICATION_LAUNCH_FETCH_SUCCESS =
  'COMMUNICATION_LAUNCH_FETCH_SUCCESS';

export const COMMUNICATION_LIST_FETCH_SUCCESS =
  'COMMUNICATION_LIST_FETCH_SUCCESS';
export const COMMUNICATION_LIST_FETCH_FAIL = 'COMMUNICATION_LIST_FETCH_FAIL';
export const COMMUNICATION_LIST_FETCH = 'COMMUNICATION_LIST_FETCH';

export const COMMUNICATION_SEARCH_LIST_FETCH_SUCCESS =
  'COMMUNICATION_SEARCH_LIST_FETCH_SUCCESS';
export const COMMUNICATION_SEARCH_LIST_FETCH_FAIL =
  'COMMUNICATION_SEARCH_LIST_FETCH_FAIL';
export const COMMUNICATION_SEARCH_LIST_FETCH =
  'COMMUNICATION_SEARCH_LIST_FETCH';

export const COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH_SUCCESS =
  'COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH_SUCCESS';
export const COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH_FAIL =
  'COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH_FAIL';
export const COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH =
  'COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH';

export const COMMUNICATION_LIST_EVENTS_FETCH_SUCCESS =
  'COMMUNICATION_LIST_EVENTS_FETCH_SUCCESS';
export const COMMUNICATION_LIST_EVENTS_FETCH_FAIL =
  'COMMUNICATION_LIST_EVENTS_FETCH_FAIL';
export const COMMUNICATION_LIST_EVENTS_FETCH =
  'COMMUNICATION_LIST_EVENTS_FETCH';

export const COMMUNICATION_LIST_PUSH_CHANNELS_FETCH_SUCCESS =
  'COMMUNICATION_LIST_PUSH_CHANNELS_FETCH_SUCCESS';
export const COMMUNICATION_LIST_PUSH_CHANNELS_FETCH_FAIL =
  'COMMUNICATION_LIST_PUSH_CHANNELS_FETCH_FAIL';
export const COMMUNICATION_LIST_PUSH_CHANNELS_FETCH =
  'COMMUNICATION_LIST_PUSH_CHANNELS_FETCH';

export const COMMUNICATION_LIST_SEGMENTS_FETCH_SUCCESS =
  'COMMUNICATION_LIST_SEGMENTS_FETCH_SUCCESS';
export const COMMUNICATION_LIST_SEGMENTS_FETCH_FAIL =
  'COMMUNICATION_LIST_SEGMENTS_FETCH_FAIL';
export const COMMUNICATION_LIST_SEGMENTS_FETCH =
  'COMMUNICATION_LIST_SEGMENTS_FETCH';

export const COMMUNICATION_LIST_SMS_CHANNELS_FETCH_SUCCESS =
  'COMMUNICATION_LIST_SMS_CHANNELS_FETCH_SUCCESS';
export const COMMUNICATION_LIST_SMS_CHANNELS_FETCH_FAIL =
  'COMMUNICATION_LIST_SMS_CHANNELS_FETCH_FAIL';
export const COMMUNICATION_LIST_SMS_CHANNELS_FETCH =
  'COMMUNICATION_LIST_SMS_CHANNELS_FETCH';

export const COMMUNICATION_PAUSE_FETCH = 'COMMUNICATION_PAUSE_FETCH';
export const COMMUNICATION_PAUSE_FETCH_FAIL = 'COMMUNICATION_PAUSE_FETCH_FAIL';
export const COMMUNICATION_PAUSE_FETCH_SUCCESS =
  'COMMUNICATION_PAUSE_FETCH_SUCCESS';

export const COMMUNICATION_STOP_FETCH = 'COMMUNICATION_STOP_FETCH';
export const COMMUNICATION_STOP_FETCH_FAIL = 'COMMUNICATION_STOP_FETCH_FAIL';
export const COMMUNICATION_STOP_FETCH_SUCCESS =
  'COMMUNICATION_STOP_FETCH_SUCCESS';

export const COMMUNICATION_TEST_FETCH = 'COMMUNICATION_TEST_FETCH';
export const COMMUNICATION_TEST_FETCH_FAIL = 'COMMUNICATION_TEST_FETCH_FAIL';
export const COMMUNICATION_TEST_FETCH_SUCCESS =
  'COMMUNICATION_TEST_FETCH_SUCCESS';

export const COMMUNICATION_UPDATE_AUDIENCE_FETCH =
  'COMMUNICATION_UPDATE_AUDIENCE_FETCH';
export const COMMUNICATION_UPDATE_AUDIENCE_FETCH_FAIL =
  'COMMUNICATION_UPDATE_AUDIENCE_FETCH_FAIL';
export const COMMUNICATION_UPDATE_AUDIENCE_FETCH_SUCCESS =
  'COMMUNICATION_UPDATE_AUDIENCE_FETCH_SUCCESS';
export const COMMUNICATION_CHANGE_CURRENT_AUDIENCE =
  'COMMUNICATION_CHANGE_CURRENT_AUDIENCE';

export const COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH =
  'COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH';
export const COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH_FAIL =
  'COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH_FAIL';
export const COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH_SUCCESS =
  'COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH_SUCCESS';

export const COMMUNICATION_AUDIENCE_RESET = 'COMMUNICATION_AUDIENCE_RESET';

export const COMMUNICATION_UPDATE_CONVERSION_FETCH =
  'COMMUNICATION_UPDATE_CONVERSION_FETCH';
export const COMMUNICATION_UPDATE_CONVERSION_FETCH_FAIL =
  'COMMUNICATION_UPDATE_CONVERSION_FETCH_FAIL';
export const COMMUNICATION_UPDATE_CONVERSION_FETCH_SUCCESS =
  'COMMUNICATION_UPDATE_CONVERSION_FETCH_SUCCESS';

export const COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH =
  'COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH';
export const COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH_FAIL =
  'COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH_FAIL';
export const COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH_SUCCESS =
  'COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH_SUCCESS';

export const COMMUNICATION_UPDATE_MESSAGE_FETCH =
  'COMMUNICATION_UPDATE_MESSAGE_FETCH';
export const COMMUNICATION_UPDATE_MESSAGE_FETCH_FAIL =
  'COMMUNICATION_UPDATE_MESSAGE_FETCH_FAIL';
export const COMMUNICATION_UPDATE_MESSAGE_FETCH_SUCCESS =
  'COMMUNICATION_UPDATE_MESSAGE_FETCH_SUCCESS';

export const COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH =
  'COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH';
export const COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH_FAIL =
  'COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH_FAIL';
export const COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH_SUCCESS =
  'COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH_SUCCESS';

export const COMMUNICATION_UPDATE_WHEN_FETCH =
  'COMMUNICATION_UPDATE_WHEN_FETCH';
export const COMMUNICATION_UPDATE_WHEN_FETCH_FAIL =
  'COMMUNICATION_UPDATE_WHEN_FETCH_FAIL';
export const COMMUNICATION_UPDATE_WHEN_FETCH_SUCCESS =
  'COMMUNICATION_UPDATE_WHEN_FETCH_SUCCESS';

export const COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH =
  'COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH';
export const COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH_FAIL =
  'COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH_FAIL';
export const COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH_SUCCESS =
  'COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH_SUCCESS';

export const COMMUNICATION_VIEW_FETCH = 'COMMUNICATION_VIEW_FETCH';
export const COMMUNICATION_VIEW_FETCH_FAIL = 'COMMUNICATION_VIEW_FETCH_FAIL';
export const COMMUNICATION_VIEW_FETCH_SUCCESS =
  'COMMUNICATION_VIEW_FETCH_SUCCESS';

export const COMMUNICATION_COMMUNICATION_REPORT_FETCH =
  'COMMUNICATION_COMMUNICATION_REPORT_FETCH';
export const COMMUNICATION_COMMUNICATION_REPORT_FETCH_FAIL =
  'COMMUNICATION_COMMUNICATION_REPORT_FETCH_FAIL';
export const COMMUNICATION_COMMUNICATION_REPORT_FETCH_SUCCESS =
  'COMMUNICATION_COMMUNICATION_REPORT_FETCH_SUCCESS';

export const COMMUNICATION_VARIATION_REPORT_FETCH =
  'COMMUNICATION_VARIATION_REPORT_FETCH';
export const COMMUNICATION_VARIATION_REPORT_FETCH_FAIL =
  'COMMUNICATION_VARIATION_REPORT_FETCH_FAIL';
export const COMMUNICATION_VARIATION_REPORT_FETCH_SUCCESS =
  'COMMUNICATION_VARIATION_REPORT_FETCH_SUCCESS';

export const COMMUNICATION_LIST_TAG_FETCH = 'COMMUNICATION_LIST_TAG_FETCH';
export const COMMUNICATION_LIST_TAG_FETCH_FAIL =
  'COMMUNICATION_LIST_TAG_FETCH_FAIL';
export const COMMUNICATION_LIST_TAG_FETCH_SUCCESS =
  'COMMUNICATION_LIST_TAG_FETCH_SUCCESS';

export const COMMUNICATION_OVERVIEW_FETCH = 'COMMUNICATION_OVERVIEW_FETCH';
export const COMMUNICATION_OVERVIEW_FETCH_FAIL =
  'COMMUNICATION_OVERVIEW_FETCH_FAIL';
export const COMMUNICATION_OVERVIEW_FETCH_SUCCESS =
  'COMMUNICATION_OVERVIEW_FETCH_SUCCESS';

export const COMMUNICATION_SPLIT_OVERVIEW_FETCH =
  'COMMUNICATION_SPLIT_OVERVIEW_FETCH';
export const COMMUNICATION_SPLIT_OVERVIEW_FETCH_FAIL =
  'COMMUNICATION_SPLIT_OVERVIEW_FETCH_FAIL';
export const COMMUNICATION_SPLIT_OVERVIEW_FETCH_SUCCESS =
  'COMMUNICATION_SPLIT_OVERVIEW_FETCH_SUCCESS';
export const COMMUNICATION_SPLIT_OVERVIEW_PAGE_CHANGE_CURRENT =
  'COMMUNICATION_SPLIT_OVERVIEW_PAGE_CHANGE_CURRENT';

export const COMMUNICATION_UPLOAD_FILE_FETCH =
  'COMMUNICATION_UPLOAD_FILE_FETCH';
export const COMMUNICATION_UPLOAD_FILE_FETCH_FAIL =
  'COMMUNICATION_UPLOAD_FILE_FETCH_FAIL';
export const COMMUNICATION_UPLOAD_FILE_FETCH_SUCCESS =
  'COMMUNICATION_UPLOAD_FILE_FETCH_SUCCESS';
export const COMMUNICATION_UPLOAD_FILE_RESET =
  'COMMUNICATION_UPLOAD_FILE_RESET';

export const COMMUNICATION_ENGAGED_USER_FETCH =
  'COMMUNICATION_ENGAGED_USER_FETCH';
export const COMMUNICATION_ENGAGED_USER_FETCH_FAIL =
  'COMMUNICATION_ENGAGED_USER_FETCH_FAIL';
export const COMMUNICATION_ENGAGED_USER_FETCH_SUCCESS =
  'COMMUNICATION_ENGAGED_USER_FETCH_SUCCESS';

export const COMMUNICATION_SEARCH_ENGAGED_USER_FETCH =
  'COMMUNICATION_SEARCH_ENGAGED_USER_FETCH';
export const COMMUNICATION_SEARCH_ENGAGED_USER_FETCH_SUCCESS =
  'COMMUNICATION_SEARCH_ENGAGED_USER_FETCH_SUCCESS';
export const COMMUNICATION_SEARCH_ENGAGED_USER_FETCH_FAIL =
  'COMMUNICATION_SEARCH_ENGAGED_USER_FETCH_FAIL';

export const COMMUNICATION_AUDIENCE_CLEAR_CREATE_ERROR =
  'COMMUNICATION_AUDIENCE_CLEAR_CREATE_ERROR';
export const COMMUNICATION_AUDIENCE_CLEAR_UPDATE_ERROR =
  'COMMUNICATION_AUDIENCE_CLEAR_UPDATE_ERROR';

export const COMMUNICATION_ENGAGED_USER_PAGE_CHANGE_CURRENT =
  'COMMUNICATION_ENGAGED_USER_PAGE_CHANGE_CURRENT';

export const COMMUNICATION_WHEN_CLEAR_ERROR = 'COMMUNICATION_WHEN_CLEAR_ERROR';
export const COMMUNICATION_MESSAGE_CLEAR_ERROR =
  'COMMUNICATION_MESSAGE_CLEAR_ERROR';

export const COMMUNICATION_TRACKING_CLEAR_ERROR =
  'COMMUNICATION_TRACKING_CLEAR_ERROR';

export const COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH =
  'COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH';
export const COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH_SUCCESS =
  'COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH_SUCCESS';
export const COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH_FAIL =
  'COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH_FAIL';

export const COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH_SUCCESS =
  'COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH_SUCCESS';
export const COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH_FAIL =
  'COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH_FAIL';
export const COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH =
  'COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH';

export const COMMUNICATION_CUSTOM_CHANNEL_FETCH_SUCCESS =
  'COMMUNICATION_CUSTOM_CHANNEL_FETCH_SUCCESS';
export const COMMUNICATION_CUSTOM_CHANNEL_FETCH_FAIL =
  'COMMUNICATION_CUSTOM_CHANNEL_FETCH_FAIL';
export const COMMUNICATION_CUSTOM_CHANNEL_FETCH_FETCH =
  'COMMUNICATION_CUSTOM_CHANNEL_FETCH_FETCH';

export const SEGMENT_REPORT_CHANGE_CURRENT = 'SEGMENT_REPORT_CHANGE_CURRENT';

export const COMMUNICATION_ARCHIVE_FETCH = 'COMMUNICATION_ARCHIVE_FETCH';
export const COMMUNICATION_ARCHIVE_FETCH_FAIL =
  'COMMUNICATION_ARCHIVE_FETCH_FAIL';
export const COMMUNICATION_ARCHIVE_FETCH_SUCCESS =
  'COMMUNICATION_ARCHIVE_FETCH_SUCCESS';

export const COMMUNICATION_ACTIVE_FETCH = 'COMMUNICATION_ACTIVE_FETCH';
export const COMMUNICATION_ACTIVE_FETCH_FAIL =
  'COMMUNICATION_ACTIVE_FETCH_FAIL';
export const COMMUNICATION_ACTIVE_FETCH_SUCCESS =
  'COMMUNICATION_ACTIVE_FETCH_SUCCESS';

export const COMMUNICATION_DELIVERY_CHECK = 'COMMUNICATION_DELIVERY_CHECK';
export const COMMUNICATION_DELIVERY_CHECK_FAIL =
  'COMMUNICATION_DELIVERY_CHECK_FAIL';
export const COMMUNICATION_DELIVERY_CHECK_SUCCESS =
  'COMMUNICATION_DELIVERY_CHECK_SUCCESS';

export const COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH =
  'COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH';
export const COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH_SUCCESS =
  'COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH_SUCCESS';
export const COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH_FAIL =
  'COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH_FAIL';

export const COMMUNICATION_REPORT_CSV_FETCH = 'COMMUNICATION_REPORT_CSV_FETCH';
export const COMMUNICATION_REPORT_CSV_SUCCESS =
  'COMMUNICATION_REPORT_CSV_SUCCESS';
export const COMMUNICATION_REPORT_CSV_FAIL = 'COMMUNICATION_REPORT_CSV_FAIL';

export const WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH =
  'WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH';
export const WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH_SUCCESS =
  'WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH_SUCCESS';
export const WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH_FAIL =
  'WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH_FAIL';

export const WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH =
  '  WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH,';
export const WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH_SUCCESS =
  '  WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH_SUCCESS,';
export const WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH_FAIL =
  '  WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH_FAIL,';

export const WHATSAPP_CHANNEL_TEMPLAE_VIEW_CHANGE =
  'WHATSAPP_CHANNEL_TEMPLAE_VIEW_CHANGE';

export const COMMUNICATION_FAILURES_REPORT_FETCH =
  'COMMUNICATION_FAILURES_REPORT_FETCH';
export const COMMUNICATION_FAILURES_REPORT_SUCCESS =
  'COMMUNICATION_FAILURES_REPORT_SUCCESS';
export const COMMUNICATION_FAILURES_REPORT_FAIL =
  'COMMUNICATION_FAILURES_REPORT_FAIL';
