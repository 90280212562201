export const APP_INTERNAL_ERROR_RAISED = 'APP_INTERNAL_ERROR_RAISED';
export const APP_INTERNAL_ERROR_CLEANED = 'APP_INTERNAL_ERROR_CLEANED';

export function appInternalError(status) {
  return {
    type: 'APP_INTERNAL_ERROR_RAISED',
    payload: status,
  };
}

export function cleanAppInternalError() {
  return {
    type: 'APP_INTERNAL_ERROR_CLEANED',
  };
}

const initialState = {
  errorStatus: null,
};

export default function AppStateReducer(state = initialState, action) {
  switch (action.type) {
    case APP_INTERNAL_ERROR_RAISED:
      return { ...state, errorStatus: action.payload };
    case APP_INTERNAL_ERROR_CLEANED:
      return { ...state, errorStatus: null };
    default:
      return state;
  }
}
