import { getProductId } from './AuthorityProvider';
import store from '../redux/store';
import { isNil } from 'lodash';
import { thirdPartyEnums } from '@Modules/admin/thirdParty/components/ThirdPartyTypes';
import { getProduct } from './getProduct';

export const FeatureList = {
  // *** Users ***
  USERS_OVERVIEW: 'USERS_OVERVIEW',
  USERS_LIST_PROFILE: 'USERS_LIST_PROFILE',
  USERS_ANALYZE: 'USERS_ANALYZE',

  // *** Events ***
  EVENTS_ANALYZE: 'EVENTS_ANALYZE',

  // *** Analytics ***
  ANALYTICS_FUNNEL: 'ANALYTICS_FUNNEL',
  ANALYTICS_COHORT: 'ANALYTICS_COHORT',
  ANALYTICS_INSTALL_UNINSTALL: 'ANALYTICS_INSTALL_UNINSTALL',

  // *** Engagement ***
  ENGAGE_CAMPAIGN_OVERVIEW_REPORT: 'ENGAGE_CAMPAIGN_OVERVIEW_REPORT',
  ENGAGE_JOURNEY: 'ENGAGE_JOURNEY',
  ENGAGE_RELAY: 'ENGAGE_RELAY',
  ENGAGE_PUSH: 'ENGAGE_PUSH',
  ENGAGE_SMS: 'ENGAGE_SMS',
  ENGAGE_WEB_PUSH: 'ENGAGE_WEB_PUSH',
  ENGAGE_EMAIL: 'ENGAGE_EMAIL',
  ENGAGE_CUSTOM_CHANNEL: 'ENGAGE_CUSTOM_CHANNEL',
  ENGAGE_IN_APP: 'ENGAGE_IN_APP',
  ENGAGE_ON_SITE: 'ENGAGE_ON_SITE',
  ENGAGE_WHATSAPP: 'ENGAGE_WHATSAPP',

  // *** Segment ***
  SEGMENT_LIVE: 'SEGMENT_LIVE',
  SEGMENT_STATIC: 'SEGMENT_STATIC',

  // *** Settings ***
  SETTINGS_TIMEZONE: 'SETTINGS_TIMEZONE',
  SETTINGS_FREQ_CAP: 'SETTINGS_FREQ_CAP',
  SETTINGS_DND: 'SETTINGS_DND',
  SETTINGS_SHORTENER_CUSTOM_DOMAIN: 'SETTINGS_SHORTENER_CUSTOM_DOMAIN',

  // *** SDK ***
  SDK_WEB: 'SDK_WEB',
  SDK_ANDROID: 'SDK_ANDROID',
  SDK_IOS: 'SDK_IOS',
  SDK_REACT_NATIVE: 'SDK_REACT_NATIVE',
  SDK_FLUTTER: 'SDK_FLUTTER',

  // ** Others **
  WEBHOOK: 'WEBHOOK',
  REST_API: 'REST_API',
  TEAM_MGMT: 'TEAM_MGMT',
  DATA_MANAGEMENT: 'DATA_MANAGEMENT',
  DATA_UPLOAD: 'DATA_UPLOAD',
  CSV_EXPORT: 'CSV_EXPORT',
};

export const hasAccessToFeature = (feature) => {
  const currentUser = store.getState().account.auth.currentUser;
  const pathname = store.getState().router.location.pathname;
  const productId = getProductId(pathname);
  if (!currentUser?.products) {
    return false;
  } else {
    let hasAccess = false;
    if (productId) {
      hasAccess = checkProductAccessFeature(currentUser, productId, feature);
    } else if (currentUser?.lastProduct) {
      hasAccess = checkProductAccessFeature(
        currentUser,
        currentUser.lastProduct,
        feature
      );
    }
    return hasAccess;
  }
};

const isNotParentProduct = () => {
  const currentUser = store.getState().account.auth.currentUser;
  const pathname = store.getState().router.location.pathname;
  let pid =
    currentUser &&
    currentUser.login &&
    (getProductId(pathname) || currentUser.lastProduct);

  if (pid === null) {
    return false;
  }
  const product = getProduct(currentUser, pid);

  if (isNil(product)) {
    return false;
  }

  return product?.thirdPartyOption !== thirdPartyEnums.PARENT;
};

const currentProductAuthType = () => {
  const currentUser = store.getState().account.auth.currentUser;
  const pathname = store.getState().router.location.pathname;
  const productId = getProductId(pathname);
  if (!currentUser?.products) {
    return undefined;
  } else {
    let authType;
    if (productId) {
      authType = productAuthType(currentUser, productId);
    } else if (currentUser?.lastProduct) {
      authType = productAuthType(currentUser, currentUser.lastProduct);
    }
    return authType;
  }
};

const productAuthType = (currentUser, productId) => {
  const products = [...currentUser?.products];

  for (const product of products) {
    if (product.id === productId) {
      return product.restAuthType || 'API_KEY';
    }
  }

  return undefined;
};

const checkProductAccessFeature = (currentUser, productId, feature) => {
  const products = [...currentUser?.products];

  for (const product of products) {
    if (product.id === productId) {
      return product.features?.includes(feature);
    }
  }

  return false;
};

// *** Users ***
export const featureAccessCheckSideMenuUsersOption = () => {
  return (
    featureAccessCheckUsersOverviewTab() ||
    featureAccessCheckUsersAnalyzeTab() ||
    featureAccessCheckUsersListTab()
  );
};

const featureAccessCheckUsersOverviewTab = () => {
  return hasAccessToFeature(FeatureList.USERS_OVERVIEW) && isNotParentProduct();
};

export const featureAccessCheckUsersAnalyzeTab = () => {
  return hasAccessToFeature(FeatureList.USERS_ANALYZE) && isNotParentProduct();
};

export const featureAccessCheckUsersListTab = () => {
  return (
    hasAccessToFeature(FeatureList.USERS_LIST_PROFILE) && isNotParentProduct()
  );
};

// *** Events ***
export const featureAccessCheckSideMenuEventsOption = () => {
  return hasAccessToFeature(FeatureList.EVENTS_ANALYZE) && isNotParentProduct();
};

// *** Analytics ***
export const featureAccessCheckSideMenuFunnels = () => {
  return (
    hasAccessToFeature(FeatureList.ANALYTICS_FUNNEL) && isNotParentProduct()
  );
};

export const featureAccessCheckSideMenuCohorts = () => {
  return hasAccessToFeature(FeatureList.ANALYTICS_COHORT);
};

export const featureAccessCheckSideMenuUninstalls = () => {
  return (
    hasAccessToFeature(FeatureList.ANALYTICS_INSTALL_UNINSTALL) &&
    isNotParentProduct()
  );
};

// *** Engagement ***
export const featureAccessCheckSideMenuEngageOverview = () => {
  return (
    hasAccessToFeature(FeatureList.ENGAGE_CAMPAIGN_OVERVIEW_REPORT) &&
    isNotParentProduct()
  );
};

export const featureAccessCheckSideMenuEngageJourneys = () => {
  return hasAccessToFeature(FeatureList.ENGAGE_JOURNEY);
};

export const featureAccessCheckSideMenuEngageRelays = () => {
  return hasAccessToFeature(FeatureList.ENGAGE_RELAY) && isNotParentProduct();
};

export const featureAccessCheckSideMenuEngagePush = () => {
  return hasAccessToFeature(FeatureList.ENGAGE_PUSH) && isNotParentProduct();
};

export const featureAccessCheckSideMenuEngageSms = () => {
  return hasAccessToFeature(FeatureList.ENGAGE_SMS) && isNotParentProduct();
};

export const featureAccessCheckSideMenuEngageWebPush = () => {
  return (
    hasAccessToFeature(FeatureList.ENGAGE_WEB_PUSH) && isNotParentProduct()
  );
};

export const featureAccessCheckSideMenuEngageEmail = () => {
  return hasAccessToFeature(FeatureList.ENGAGE_EMAIL) && isNotParentProduct();
};

export const featureAccessCheckSideMenuEngageCustomChannel = () => {
  return (
    hasAccessToFeature(FeatureList.ENGAGE_CUSTOM_CHANNEL) &&
    isNotParentProduct()
  );
};

export const featureAccessCheckSideMenuEngageInApp = () => {
  return hasAccessToFeature(FeatureList.ENGAGE_IN_APP) && isNotParentProduct();
};

export const featureAccessCheckSideMenuEngageOnSite = () => {
  return hasAccessToFeature(FeatureList.ENGAGE_ON_SITE) && isNotParentProduct();
};

//WhatsApp
export const featureAccessCheckSideMenuEngageWhatsapp = () => {
  return (
    hasAccessToFeature(FeatureList.ENGAGE_WHATSAPP) && isNotParentProduct()
  );
};

// *** Segment ***
export const featureAccessCheckSideMenuSegmentLive = () => {
  return hasAccessToFeature(FeatureList.SEGMENT_LIVE) && isNotParentProduct();
};

export const featureAccessCheckSideMenuSegmentStatic = () => {
  return hasAccessToFeature(FeatureList.SEGMENT_STATIC) && isNotParentProduct();
};

// *** Settings ***
export const featureAccessCheckSideMenuSetting = () => {
  return (
    featureAccessCheckSettingGeneralTab() ||
    featureAccessCheckSettingFCTab() ||
    featureAccessCheckSettingDNDTab() ||
    featureAccessCheckSettingCustomDomainTab()
  );
};

export const featureAccessCheckSettingGeneralTab = () => {
  return (
    hasAccessToFeature(FeatureList.SETTINGS_TIMEZONE) && isNotParentProduct()
  );
};

export const featureAccessCheckSettingFCTab = () => {
  return (
    hasAccessToFeature(FeatureList.SETTINGS_FREQ_CAP) && isNotParentProduct()
  );
};

export const featureAccessCheckSettingDNDTab = () => {
  return hasAccessToFeature(FeatureList.SETTINGS_DND) && isNotParentProduct();
};

export const featureAccessCheckSettingCustomDomainTab = () => {
  return (
    hasAccessToFeature(FeatureList.SETTINGS_SHORTENER_CUSTOM_DOMAIN) &&
    isNotParentProduct()
  );
};

// *** SDK ***
export const featureAccessCheckSideMenuSDK = () => {
  return (
    featureAccessCheckSDKWeb() ||
    featureAccessCheckSDKAndroid() ||
    featureAccessCheckSDKIOS() ||
    featureAccessCheckSDKNative() ||
    featureAccessCheckSDKFlutter()
  );
};

export const featureAccessCheckSDKWeb = () => {
  return hasAccessToFeature(FeatureList.SDK_WEB) && isNotParentProduct();
};

export const featureAccessCheckSDKAndroid = () => {
  return hasAccessToFeature(FeatureList.SDK_ANDROID) && isNotParentProduct();
};

export const featureAccessCheckSDKIOS = () => {
  return hasAccessToFeature(FeatureList.SDK_IOS) && isNotParentProduct();
};

export const featureAccessCheckSDKNative = () => {
  return (
    hasAccessToFeature(FeatureList.SDK_REACT_NATIVE) && isNotParentProduct()
  );
};

export const featureAccessCheckSDKFlutter = () => {
  return hasAccessToFeature(FeatureList.SDK_FLUTTER) && isNotParentProduct();
};

// ** Others-Setting Section **
export const featureAccessCheckSideMenuWebhook = () => {
  return hasAccessToFeature(FeatureList.WEBHOOK) && isNotParentProduct();
};

export const featureAccessCheckSideMenuAuditLog = () => {
  return isNotParentProduct();
};
export const featureAccessCheckSideMenuRestApiKey = () => {
  return (
    hasAccessToFeature(FeatureList.REST_API) &&
    currentProductAuthType() === 'API_KEY' &&
    isNotParentProduct()
  );
};

export const featureAccessCheckSideMenuRestApiToken = () => {
  return (
    hasAccessToFeature(FeatureList.REST_API) &&
    currentProductAuthType() === 'JWT' &&
    isNotParentProduct()
  );
};

export const featureAccessCheckSideMenuTeam = () => {
  return hasAccessToFeature(FeatureList.TEAM_MGMT) && isNotParentProduct();
};

// **DataPlatform**
export const featureAccessCheckSideMenuDataManagement = () => {
  return (
    hasAccessToFeature(FeatureList.DATA_MANAGEMENT) && isNotParentProduct()
  );
};

export const featureAccessCheckSideMenuUploadData = () => {
  return hasAccessToFeature(FeatureList.DATA_UPLOAD) && isNotParentProduct();
};

export const featureAccessCheckCSVExport = () => {
  return hasAccessToFeature(FeatureList.CSV_EXPORT) && isNotParentProduct();
};

export const featureAccessCheckScheduleDeliveryCheck = () => {
  return hasAccessToFeature(FeatureList.ENGAGE_);
};

// **Sections**

export const featureAccessCheckSideMenuTrackSection = () => {
  return (
    featureAccessCheckSideMenuUsersOption() ||
    featureAccessCheckSideMenuEventsOption()
  );
};

export const featureAccessCheckSideMenuAnalyticsSection = () => {
  return (
    featureAccessCheckSideMenuFunnels() ||
    featureAccessCheckSideMenuUninstalls()
  );
};

export const featureAccessCheckSideMenuEngageSection = () => {
  return (
    featureAccessCheckSideMenuEngageOverview() ||
    featureAccessCheckSideMenuEngageJourneys() ||
    featureAccessCheckSideMenuEngageRelays() ||
    featureAccessCheckSideMenuEngagePush() ||
    featureAccessCheckSideMenuEngageSms() ||
    featureAccessCheckSideMenuEngageWebPush() ||
    featureAccessCheckSideMenuEngageEmail() ||
    featureAccessCheckSideMenuEngageCustomChannel() ||
    featureAccessCheckSideMenuEngageInApp() ||
    featureAccessCheckSideMenuEngageOnSite() ||
    featureAccessCheckSideMenuEngageWhatsapp()
  );
};

export const featureAccessCheckSideMenuSegmentSection = () => {
  return (
    featureAccessCheckSideMenuSegmentLive() ||
    featureAccessCheckSideMenuSegmentStatic()
  );
};

export const featureAccessCheckSideMenuDataPlatformSection = () => {
  return (
    featureAccessCheckSideMenuDataManagement() ||
    featureAccessCheckSideMenuUploadData()
  );
};

export const featureAccessCheckSideMenuChannel = () => {
  return (
    featureAccessCheckSideMenuEngagePush() ||
    featureAccessCheckSideMenuEngageSms() ||
    featureAccessCheckSideMenuEngageEmail() ||
    featureAccessCheckSideMenuEngageCustomChannel() ||
    featureAccessCheckSideMenuEngageWhatsapp()
  );
};
