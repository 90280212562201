export const INVOICE_STATUS = {
  CREATED: {
    color: '#94969e',
    bgColor: '#e7e8e9',
    name: 'CREATED',
  },
  ISSUED: {
    color: '#FFFFFF',
    bgColor: '#102',
    name: 'ISSUED',
  },
  CONFIRMED: {
    color: '#FFFFFF',
    bgColor: '#0661d5',
    name: 'CONFIRMED',
  },
  REJECTED: {
    color: '#FFFFFF',
    bgColor: '#d33421',
    name: 'REJECTED',
  },
  PAYMENT_PENDING: {
    color: '#FFFFFF',
    bgColor: '#EF8F00',
    name: 'PAYMENT_PENDING',
  },
  PAYMENT_FAILED: {
    color: '#FFFFFF',
    bgColor: '#f76464',
    name: 'PAYMENT_FAILED',
  },
  PAID: {
    color: '#FFFFFF',
    bgColor: '#09d88c',
    name: 'PAID',
  },
};
