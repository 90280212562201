import {
  FILE_STORAGE_CHANGE_CURRENT,
  FILE_STORAGE_CREATE_FETCH,
  FILE_STORAGE_CREATE_FETCH_FAIL,
  FILE_STORAGE_CREATE_FETCH_SUCCESS,
  FILE_STORAGE_DELETE_FETCH,
  FILE_STORAGE_DELETE_FETCH_FAIL,
  FILE_STORAGE_DELETE_FETCH_SUCCESS,
  FILE_STORAGE_LIST_FETCH,
  FILE_STORAGE_LIST_FETCH_SUCCESS,
  FILE_STORAGE_UPDATE_FETCH,
  FILE_STORAGE_UPDATE_FETCH_FAIL,
  FILE_STORAGE_UPDATE_FETCH_SUCCESS,
  FILE_STORAGE_UPDATE_SUBMIT_FETCH,
  FILE_STORAGE_UPDATE_SUBMIT_FETCH_FAIL,
  FILE_STORAGE_UPDATE_SUBMIT_FETCH_SUCCESS,
  FILE_STORAGE_VIEW_FETCH,
  FILE_STORAGE_VIEW_FETCH_SUCCESS,
} from './FileStorageConstants';

const initialState = {
  page: {},
  pageRequest: {},
  errors: [],
  loadingFileStorageList: false,
  fileUpdateLoading: false,
  currentFileStorage: { enabled: false, type: 'LOCAL' },
  updateLoading: false,
  updateFetchLoading: false,
  submitLoading: false,
  loadingFileStorage: false,
  loadingDelete: false,
  errorsDelete: null,
};

export default function FileStorageStateReducer(state = initialState, action) {
  switch (action.type) {
    case FILE_STORAGE_LIST_FETCH:
      return {
        ...state,
        pageRequest: action.payload,
        loadingFileStorageList: true,
      };
    case FILE_STORAGE_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingFileStorageList: false };
    case FILE_STORAGE_CREATE_FETCH:
      return { ...state, fileUpdateLoading: true, updateLoading: true };
    case FILE_STORAGE_CREATE_FETCH_SUCCESS:
      return { ...state, fileUpdateLoading: false, updateLoading: false };
    case FILE_STORAGE_CREATE_FETCH_FAIL:
      return {
        ...state,
        errors: action.payload,
        fileUpdateLoading: false,
        updateLoading: false,
      };
    case FILE_STORAGE_CHANGE_CURRENT:
      return { ...state, currentFileStorage: action.payload };
    case FILE_STORAGE_UPDATE_FETCH:
      return {
        ...state,
        currentFileStorage: action.payload,
        updateFetchLoading: true,
      };
    case FILE_STORAGE_UPDATE_FETCH_FAIL:
      return {
        ...state,
        currentFileStorage: action.payload,
        updateFetchLoading: false,
      };
    case FILE_STORAGE_UPDATE_FETCH_SUCCESS:
      return {
        ...state,
        currentFileStorage: action.payload,
        updateFetchLoading: false,
      };
    case FILE_STORAGE_UPDATE_SUBMIT_FETCH:
      return {
        ...state,
        currentFileStorage: action.payload,
        submitLoading: true,
      };
    case FILE_STORAGE_UPDATE_SUBMIT_FETCH_SUCCESS:
      return {
        ...state,
        // currentFileStorage: action.payload,
        submitLoading: false,
      };
    case FILE_STORAGE_UPDATE_SUBMIT_FETCH_FAIL:
      return { ...state, errors: action.payload.errors, submitLoading: false };
    case FILE_STORAGE_VIEW_FETCH:
      return {
        ...state,
        currentFileStorage: action.payload,
        loadingFileStorage: true,
      };

    case FILE_STORAGE_VIEW_FETCH_SUCCESS:
      return {
        ...state,
        currentFileStorage: action.payload,
        loadingFileStorage: false,
      };
    case FILE_STORAGE_DELETE_FETCH:
      return { ...state, loadingDelete: true };
    case FILE_STORAGE_DELETE_FETCH_FAIL:
      return {
        ...state,
        errorsDelete: action.payload.errors,
        loadingDelete: false,
      };
    case FILE_STORAGE_DELETE_FETCH_SUCCESS:
      return { ...state, loadingDelete: false };

    default:
      return state;
  }
}
