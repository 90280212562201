import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Space, Form, Button } from 'antd';
import {
  buildFormValues,
  FormLayout,
  FormTailLayout,
} from '../../utils/FormUtils';

class FormBase extends React.Component {
  formRef = React.createRef();

  resetFields() {
    this.formRef.current.resetFields();
    this.props.onChange(this.formRef.current.getFieldsValue());
  }

  componentDidUpdate() {
    if (this.formRef.current) {
      this.formRef.current.setFieldsValue(this.props.value);
    }
  }

  render() {
    const styles = this.props.fullWidth ? { maxWidth: '100%' } : {};
    return (
      <Form
        style={styles}
        autoComplete="off"
        layout={this.props.layout}
        {...FormLayout}
        ref={this.formRef}
        fields={buildFormValues(this.props.errors, this.props.value)}
        onValuesChange={(changedValues, allValues) => {
          this.props.onChange(allValues);
          this.formRef.current.setFields(
            Object.keys(changedValues).map((x) => ({ name: x, errors: [] }))
          );
        }}
        onFinish={() => this.props.onFinish(this.props.value)}
      >
        {this.props.children}

        <Form.Item {...FormTailLayout}>
          <Space>
            {this.props.back && (
              <Link to={this.props.back}>
                <Button>Back</Button>
              </Link>
            )}
            {this.props.refresh && (
              <Button shape="round" onClick={() => this.resetFields()}>
                Reset
              </Button>
            )}
            {!this.props.hideSubmit && (
              <Button
                disabled={this.props.submitDisabled}
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Submit
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
    );
  }
}

FormBase.propTypes = {
  errors: PropTypes.array,
  value: PropTypes.object,
  back: PropTypes.string,
  refresh: PropTypes.bool,
  onFinish: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

export default FormBase;
