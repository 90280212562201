import React from 'react';
import { Radio } from 'antd';
import style from './UploadReceiptModalContent.module.less';
import StripedBox from '@Component/app/StripedBox';
import parsianLogo from '@Theme/images/parsian.png';
import { numberWithCommas } from '@Utils/RenderUtils';

const GatewayModalContent = ({
  gatewayModalInfo,
  currentGateway,
  changeGateway,
}) => {
  const rows = [
    {
      columns: [
        <span key="0">Total</span>,
        <span key="1">
          {numberWithCommas(gatewayModalInfo.finalPrice)} Rial
        </span>,
      ],
    },
    {
      columns: [
        <span key="0">Date</span>,
        <span key="1">
          <span style={{ color: 'rgba(56, 55, 59, 0.65)' }}>From</span>{' '}
          {gatewayModalInfo.startDate}{' '}
          <span style={{ color: 'rgba(56, 55, 59, 0.65)' }}>to</span>{' '}
          {gatewayModalInfo.endDate}
        </span>,
      ],
    },
  ];

  const cols = [
    {
      width: '50%',
      textAlign: 'left',
      color: 'rgba(56, 55, 59, 0.8)',
      fontWeight: '400',
    },
    {
      width: '50%',
      overflowWrap: 'anywhere',
      textAlign: 'left',
      color: '#38373BCC',
      fontWeight: '500',
    },
  ];

  const onChange = (e) => {
    changeGateway(e.target.value);
  };
  return (
    <div id="uploadReceiptModal">
      <div className={style.title}>Receipt Info</div>
      <StripedBox cols={cols} rows={rows} />
      <div className={style.title}>Payment Method</div>
      <Radio.Group
        onChange={onChange}
        defaultValue={currentGateway}
        className={style.radio}
      >
        <Radio.Button value="parsian">
          <img src={parsianLogo} alt="Logo" />
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};
export default GatewayModalContent;
