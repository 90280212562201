import users from './theme/images/icons/Users.svg';
import events from './theme/images/icons/Events.svg';
import funnels from './theme/images/icons/Funnels.svg';
import cohorts from './theme/images/icons/Cohorts.svg';
import uninstalls from './theme/images/icons/Uninstalls.svg';
import live from './theme/images/icons/Live.svg';
import overview from './theme/images/icons/Overview.svg';
import journeys from './theme/images/icons/Journeys.svg';
import Relays from './theme/images/icons/Relays.svg';
import push from './theme/images/icons/Push.svg';
import sms from './theme/images/icons/SMS.svg';
import webPush from './theme/images/icons/Web_Push.svg';
import email from './theme/images/icons/Email.svg';
import inApp from './theme/images/icons/In_App.svg';
import onSite from './theme/images/icons/On_Site.svg';
import liveSegment from './theme/images/icons/Live_segment.svg';
import staticSegment from './theme/images/icons/Static.svg';
import general from './theme/images/icons/General.svg';
import channels from './theme/images/icons/Channels.svg';
import sdk from './theme/images/icons/SDK.svg';
import webhooks from './theme/images/icons/Webhooks.svg';
import auditLog from './theme/images/icons/auditLog.svg';
import restApi from './theme/images/icons/Rest_API.svg';
import dataManagement from './theme/images/icons/Data_Management.svg';
import Alert from './theme/images/icons/Alert.svg';
import help from './theme/images/icons/Help.svg';
import customChannel from './theme/images/icons/CustomChannel.svg';
import billing from './theme/images/icons/Billing.svg';
import Upload from './theme/images/icons/Upload.svg';
import WhatsApp from './theme/images/icons/Whatsapp.svg';
import BackOffice from './theme/images/icons/BackOffice.svg';
import VendorManagement from './theme/images/icons/Vendor_Management.svg';
import {
  authorizedSideMenuAdminConfiguration,
  authorizedSideMenuAdminCreateProduct,
  authorizedSideMenuAdminFileStorage,
  authorizedSideMenuAdminManageProducts,
  authorizedSideMenuAdminReleaseNote,
  authorizedSideMenuAdminRoleList,
  authorizedSideMenuAdminSection,
  authorizedSideMenuAdminUserList,
  authorizedSideMenuAnalyticsSection,
  authorizedSideMenuBilling,
  authorizedSideMenuChannels,
  authorizedSideMenuCohorts,
  authorizedSideMenuContractTemplate,
  authorizedSideMenuDataPlatformData,
  authorizedSideMenuDataPlatformEventBusiness,
  authorizedSideMenuDataPlatformEventsAttributes,
  authorizedSideMenuDataPlatformEventsRevenue,
  authorizedSideMenuDataPlatformEventUpload,
  authorizedSideMenuDataPlatformSection,
  authorizedSideMenuDataPlatformSystemAttributes,
  authorizedSideMenuDataPlatformUpload,
  authorizedSideMenuDataPlatformUserAttributes,
  authorizedSideMenuDataPlatformUsersUpload,
  authorizedSideMenuEngageCustomChannel,
  authorizedSideMenuEngageEmail,
  authorizedSideMenuEngageinApp,
  authorizedSideMenuEngageJourneys,
  authorizedSideMenuEngageOnSite,
  authorizedSideMenuEngageOverview,
  authorizedSideMenuEngagePush,
  authorizedSideMenuEngageRelays,
  authorizedSideMenuEngageSection,
  authorizedSideMenuEngageSms,
  authorizedSideMenuEngageWebPush,
  authorizedSideMenuEngageWhatsapp,
  authorizedSideMenuEventsOption,
  authorizedSideMenuFinancialSection,
  authorizedSideMenuFunnels,
  authorizedSideMenuLive,
  authorizedSideMenuManagementDashboard,
  authorizedSideMenuSegmentSection,
  authorizedSideMenuSetting,
  authorizedSideMenuSettingChannelCustom,
  authorizedSideMenuSettingCustomDomain,
  authorizedSideMenuSettingDnd,
  authorizedSideMenuSettingEmail,
  authorizedSideMenuSettingFc,
  authorizedSideMenuSettingGeneral,
  authorizedSideMenuSettingMenu,
  authorizedSideMenuSettingPush,
  authorizedSideMenuSettingRestApiKey,
  authorizedSideMenuSettingRestApiToken,
  authorizedSideMenuSettingSdk,
  authorizedSideMenuSettingSms,
  authorizedSideMenuSettingTeamMember,
  authorizedSideMenuSettingTemplate,
  authorizedSideMenuSettingWebhooks,
  authorizedSideMenuSettingWhatsApp,
  authorizedSideMenuThirdParty,
  authorizedSideMenuTrackSection,
  authorizedSideMenuUninstalls,
  authorizedSideMenuUsersOption,
  authorizedSideMenuSettingAuditLog,
  authorizedSideMenuAlert,
} from './utils/AuthorityProvider';
import {
  featureAccessCheckSettingCustomDomainTab,
  featureAccessCheckSettingDNDTab,
  featureAccessCheckSettingFCTab,
  featureAccessCheckSettingGeneralTab,
  featureAccessCheckSideMenuAnalyticsSection,
  featureAccessCheckSideMenuAuditLog,
  featureAccessCheckSideMenuChannel,
  featureAccessCheckSideMenuDataManagement,
  featureAccessCheckSideMenuDataPlatformSection,
  featureAccessCheckSideMenuEngageCustomChannel,
  featureAccessCheckSideMenuEngageEmail,
  featureAccessCheckSideMenuEngageInApp,
  featureAccessCheckSideMenuEngageJourneys,
  featureAccessCheckSideMenuEngageOnSite,
  featureAccessCheckSideMenuEngageOverview,
  featureAccessCheckSideMenuEngagePush,
  featureAccessCheckSideMenuEngageRelays,
  featureAccessCheckSideMenuEngageSection,
  featureAccessCheckSideMenuEngageSms,
  featureAccessCheckSideMenuEngageWebPush,
  featureAccessCheckSideMenuEngageWhatsapp,
  featureAccessCheckSideMenuEventsOption,
  featureAccessCheckSideMenuFunnels,
  featureAccessCheckSideMenuRestApiKey,
  featureAccessCheckSideMenuRestApiToken,
  featureAccessCheckSideMenuSDK,
  featureAccessCheckSideMenuSegmentLive,
  featureAccessCheckSideMenuSegmentSection,
  featureAccessCheckSideMenuSegmentStatic,
  featureAccessCheckSideMenuSetting,
  featureAccessCheckSideMenuTeam,
  featureAccessCheckSideMenuTrackSection,
  featureAccessCheckSideMenuUninstalls,
  featureAccessCheckSideMenuUploadData,
  featureAccessCheckSideMenuUsersOption,
  featureAccessCheckSideMenuWebhook,
  featureAccessCheckSideMenuCohorts,
} from './utils/AccessManagement';
import { thirdPartyEnums } from '@Modules/admin/thirdParty/components/ThirdPartyTypes';
import { getProduct } from './utils/getProduct';

function routes(pid, props) {
  const currentProduct = getProduct(props.currentUser, pid);

  const navData = [
    ...(authorizedSideMenuThirdParty() &&
    currentProduct?.thirdPartyOption === thirdPartyEnums.PARENT
      ? [
          {
            path: `/admin/third-party/${pid}/list`,
            basePath: `/admin/third-party/${pid}`,
            icon: VendorManagement,
            testid: '',
            title: 'Vendor Management',
          },
        ]
      : []),
    ...(authorizedSideMenuTrackSection() &&
    featureAccessCheckSideMenuTrackSection()
      ? [
          {
            key: 'track',
            title: 'Track',
            subRoutes: [
              ...(authorizedSideMenuUsersOption() &&
              featureAccessCheckSideMenuUsersOption()
                ? [
                    {
                      path: `/product/${pid}/users/overview`,
                      basePath: `/product/${pid}/users/`,
                      icon: users,
                      testid: 'track_users',
                      title: 'Users',
                      menuIds: 'users',
                    },
                  ]
                : []),
              ...(authorizedSideMenuEventsOption() &&
              featureAccessCheckSideMenuEventsOption()
                ? [
                    {
                      path: `/product/${pid}/event-analytics`,
                      basePath: `/product/${pid}/event-analytics`,
                      icon: events,
                      testid: 'track-event-analytic',
                      title: 'Events',
                      menuIds: 'event-analytics',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(authorizedSideMenuAnalyticsSection() &&
    featureAccessCheckSideMenuAnalyticsSection()
      ? [
          {
            key: 'analytics',
            title: 'Analytics',
            subRoutes: [
              ...(authorizedSideMenuFunnels() &&
              featureAccessCheckSideMenuFunnels()
                ? [
                    {
                      path: `/product/${pid}/funnels/list`,
                      basePath: `/product/${pid}/funnels`,
                      icon: funnels,
                      testid: '',
                      title: 'Funnels',
                      menuIds: 'funnels',
                    },
                  ]
                : []),
              ...(authorizedSideMenuCohorts() &&
              featureAccessCheckSideMenuCohorts()
                ? [
                    {
                      path: `/product/${pid}/cohorts/list`,
                      basePath: `/product/${pid}/cohorts/list`,
                      icon: cohorts,
                      testid: '',
                      title: 'Cohorts',
                      menuIds: 'cohorts',
                    },
                  ]
                : []),
              ...(authorizedSideMenuUninstalls() &&
              featureAccessCheckSideMenuUninstalls()
                ? [
                    {
                      path: `/product/${pid}/uninstalls/overview`,
                      basePath: `/product/${pid}/uninstalls`,
                      icon: uninstalls,
                      testid: '',
                      title: 'Uninstalls',
                      menuIds: 'uninstalls',
                    },
                  ]
                : []),
              ...(authorizedSideMenuLive()
                ? [
                    {
                      path: `/product/${pid}/live/overview`,
                      basePath: `/product/${pid}/live/overview`,
                      icon: live,
                      testid: '',
                      title: 'Live',
                      menuIds: 'live',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(authorizedSideMenuEngageSection() &&
    featureAccessCheckSideMenuEngageSection()
      ? [
          {
            key: 'engage',
            title: 'Engage',
            subRoutes: [
              ...(authorizedSideMenuEngageOverview() &&
              featureAccessCheckSideMenuEngageOverview()
                ? [
                    {
                      path: `/product/${pid}/engagement/overview/all`,
                      basePath: `/product/${pid}/engagement/overview/all`,
                      icon: overview,
                      testid: 'engage-overview',
                      title: 'Overview',
                      menuIds: 'engagement/overview',
                    },
                  ]
                : []),
              ...(authorizedSideMenuEngageJourneys() &&
              featureAccessCheckSideMenuEngageJourneys()
                ? [
                    {
                      path: `/product/${pid}/journeys/list`,
                      basePath: `/product/${pid}/journey`,
                      icon: journeys,
                      testid: 'engage-journys',
                      title:
                        currentProduct?.thirdPartyOption !==
                        thirdPartyEnums.PARENT
                          ? 'Journeys'
                          : 'Journey Templates',
                      menuIds: 'journeys',
                    },
                  ]
                : []),
              ...(authorizedSideMenuEngageRelays() &&
              featureAccessCheckSideMenuEngageRelays()
                ? [
                    {
                      path: `/product/${pid}/relays/list`,
                      basePath: `/product/${pid}/relay`,
                      icon: Relays,
                      testid: 'engage-relays',
                      title: 'Relays',
                    },
                  ]
                : []),

              ...(authorizedSideMenuEngagePush() &&
              featureAccessCheckSideMenuEngagePush()
                ? [
                    {
                      path: `/product/${pid}/push-notifications/campaign-list/all`,
                      basePath: `/product/${pid}/push-notifications/`,
                      icon: push,
                      testid: 'engage-push',
                      title: 'Push',
                    },
                  ]
                : []),
              ...(authorizedSideMenuEngageSms() &&
              featureAccessCheckSideMenuEngageSms()
                ? [
                    {
                      path: `/product/${pid}/sms-messages/campaign-list/all`,
                      basePath: `/product/${pid}/sms-messages/`,
                      icon: sms,
                      testid: 'engage-sms',
                      title: 'SMS',
                    },
                  ]
                : []),
              ...(authorizedSideMenuEngageWebPush() &&
              featureAccessCheckSideMenuEngageWebPush()
                ? [
                    {
                      path: `/product/${pid}/web-notifications/campaign-list/all`,
                      basePath: `/product/${pid}/web-notifications/`,
                      icon: webPush,
                      testid: 'engage-web-push',
                      title: 'Web Push',
                    },
                  ]
                : []),
              ...(authorizedSideMenuEngageEmail() &&
              featureAccessCheckSideMenuEngageEmail()
                ? [
                    {
                      path: `/product/${pid}/emails/campaign-list/all`,
                      basePath: `/product/${pid}/emails/`,
                      icon: email,
                      testid: 'engage-email',
                      title: 'Email',
                    },
                  ]
                : []),
              ...(authorizedSideMenuEngageCustomChannel() &&
              featureAccessCheckSideMenuEngageCustomChannel()
                ? [
                    {
                      path: `/product/${pid}/custom-channel/campaign-list/all`,
                      basePath: `/product/${pid}/custom-channel/`,
                      icon: customChannel,
                      testid: 'engage-custom-channel',
                      title: 'Custom Channel',
                    },
                  ]
                : []),
              ...(authorizedSideMenuEngageinApp() &&
              featureAccessCheckSideMenuEngageInApp()
                ? [
                    {
                      path: `/product/${pid}/in-app/campaign-list/all`,
                      basePath: `/product/${pid}/in-app/`,
                      icon: inApp,
                      testid: '',
                      title: 'In-App',
                    },
                  ]
                : []),
              ...(authorizedSideMenuEngageOnSite() &&
              featureAccessCheckSideMenuEngageOnSite()
                ? [
                    {
                      path: `/product/${pid}/onSiteChannel/campaign-list/all`,
                      basePath: `/product/${pid}/onSiteChannel/`,
                      icon: onSite,
                      testid: '',
                      title: 'On Site',
                    },
                  ]
                : []),
              ...(authorizedSideMenuEngageWhatsapp() &&
              featureAccessCheckSideMenuEngageWhatsapp()
                ? [
                    {
                      path: `/product/${pid}/whatsapp/campaign-list/all`,
                      basePath: `/product/${pid}/whatsapp/`,
                      icon: WhatsApp,
                      testid: '',
                      title: 'WhatsApp',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(authorizedSideMenuSegmentSection() &&
    featureAccessCheckSideMenuSegmentSection()
      ? [
          {
            key: 'segment',
            title: 'Segment',
            subRoutes: [
              ...(!currentProduct?.sendify &&
              featureAccessCheckSideMenuSegmentLive()
                ? [
                    {
                      path: `/product/${pid}/segments/live/list`,
                      basePath: `/product/${pid}/segments/live`,
                      icon: liveSegment,
                      testid: 'live-segment',
                      title: 'Live Segment',
                    },
                  ]
                : []),

              ...(featureAccessCheckSideMenuSegmentStatic()
                ? [
                    {
                      path: `/product/${pid}/segments/static/list`,
                      basePath: `/product/${pid}/segments/static/`,
                      icon: staticSegment,
                      testid: 'static-segment',
                      title: 'Static Segment',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(authorizedSideMenuSettingMenu()
      ? [
          {
            key: 'settings',
            title: 'Settings',
            subRoutes: [
              ...(authorizedSideMenuSetting() &&
              featureAccessCheckSideMenuSetting()
                ? [
                    {
                      path: `/product/${pid}/settings${settingsPath()}`,
                      basePath: `/product/${pid}/settings`,
                      icon: general,
                      testid: 'setting-general',
                      title: 'General',
                    },
                  ]
                : []),
              ...(authorizedSideMenuChannels() &&
              featureAccessCheckSideMenuChannel()
                ? [
                    {
                      path: `/product/${pid}/channels${channelsPath()}`,
                      basePath: `/product/${pid}/channels`,
                      icon: channels,
                      testid: 'setting-channels',
                      title: 'Channels',
                    },
                  ]
                : []),
              ...(authorizedSideMenuSettingSdk() &&
              featureAccessCheckSideMenuSDK()
                ? [
                    {
                      path: `/product/${pid}/sdk/view`,
                      basePath: `/product/${pid}/sdk/`,
                      icon: sdk,
                      testid: 'setting-sdk',
                      title: 'SDK',
                    },
                  ]
                : []),
              ...(authorizedSideMenuSettingWebhooks() &&
              featureAccessCheckSideMenuWebhook()
                ? [
                    {
                      path: `/product/${pid}/webhooks/view`,
                      basePath: `/product/${pid}/webhooks/`,
                      icon: webhooks,
                      testid: 'setting-webhook',
                      title: 'Webhooks',
                    },
                  ]
                : []),
              ...(authorizedSideMenuSettingAuditLog() &&
              featureAccessCheckSideMenuAuditLog()
                ? [
                    {
                      path: `/product/${pid}/audit-log`,
                      basePath: `/product/${pid}/audit-log`,
                      icon: auditLog,
                      testid: 'audit-log',
                      title: 'Audit Log',
                    },
                  ]
                : []),
              ...(authorizedSideMenuSettingRestApiKey() &&
              featureAccessCheckSideMenuRestApiKey()
                ? [
                    {
                      path: `/product/${pid}/rest-api-key/view`,
                      basePath: `/product/${pid}/rest-api-key/`,
                      icon: restApi,
                      testid: 'restApi',
                      title: 'Rest API',
                    },
                  ]
                : []),
              ...(authorizedSideMenuSettingRestApiToken() &&
              featureAccessCheckSideMenuRestApiToken()
                ? [
                    {
                      path: `/product/${pid}/rest-api-token/view`,
                      basePath: `/product/${pid}/rest-api-token/`,
                      icon: restApi,
                      testid: 'restApiToken',
                      title: 'Rest API Token',
                    },
                  ]
                : []),
              ...(authorizedSideMenuSettingTeamMember() &&
              featureAccessCheckSideMenuTeam()
                ? [
                    {
                      path: `/product/${pid}/team/members`,
                      basePath: `/product/${pid}/team/members`,
                      icon: users,
                      testid: '',
                      title: 'Team',
                    },
                  ]
                : []),
              ...(authorizedSideMenuBilling()
                ? [
                    {
                      path: `/product/${pid}/billing/sla`,
                      basePath: `/product/${pid}/billing/`,
                      icon: billing,
                      testid: '',
                      title: 'Billing',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(authorizedSideMenuDataPlatformSection() &&
    featureAccessCheckSideMenuDataPlatformSection()
      ? [
          {
            key: 'dataplatform',
            title: 'Data Platform',
            subRoutes: [
              ...(authorizedSideMenuAlert()
                ? [
                    {
                      path: `/product/${pid}/alerts/list`,
                      basePath: `/product/${pid}/alerts/list`,
                      icon: Alert,
                      testid: 'alerts',
                      title: 'Alerts',
                    },
                  ]
                : []),

              ...(authorizedSideMenuDataPlatformData() &&
              featureAccessCheckSideMenuDataManagement()
                ? [
                    {
                      path: `/product/${pid}/data-management/${dataManagementPath()}`,
                      basePath: `/product/${pid}/data-management/`,
                      icon: dataManagement,
                      testid: 'setting-data-management',
                      title: 'Data Management',
                    },
                  ]
                : []),

              ...(authorizedSideMenuDataPlatformUpload() &&
              featureAccessCheckSideMenuUploadData()
                ? [
                    {
                      path: `/product/${pid}/upload-data/${uploadDataPath()}`,
                      basePath: `/product/${pid}/upload-data/`,
                      icon: Upload,
                      testid: 'upload-data',
                      title: 'Upload Data',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),

    {
      key: 'update-profile',
      title: props.currentUser.presentation,
      path: `/account/profile/update`,
      basePath: `/account/profile/`,
      icon: help,
      testid: 'update-profile',
    },
    {
      key: 'logout',
      title: 'Logout',
      clickFn: () => props.logoutFetch(),
      icon: help,
      testid: 'logout',
    },
    ...(authorizedSideMenuFinancialSection()
      ? [
          {
            key: 'back-office',
            title: 'Financial',
            path: `/back-office/invoice`,
            basePath: `/back-office/`,
            icon: BackOffice,
            testid: 'back-office',
          },
        ]
      : []),
    ...(authorizedSideMenuAdminSection()
      ? [
          {
            key: 'admin',
            title: 'Back-office',
            subRoutes: [
              ...(authorizedSideMenuAdminRoleList()
                ? [
                    {
                      path: `/account/role/list`,
                      basePath: `/account/role/`,
                      icon: help,
                      testid: 'admin-roles',
                      title: 'Roles',
                    },
                  ]
                : []),
              ...(authorizedSideMenuAdminUserList()
                ? [
                    {
                      path: `/account/user/list`,
                      basePath: `/account/user/`,
                      icon: help,
                      testid: 'admin-users',
                      title: 'Users',
                    },
                  ]
                : []),
              ...(authorizedSideMenuAdminReleaseNote()
                ? [
                    {
                      path: `/account/releaseNote/list`,
                      basePath: `/account/releaseNote/`,
                      icon: help,
                      testid: '',
                      title: 'Release Notes',
                    },
                  ]
                : []),
              ...(authorizedSideMenuAdminManageProducts()
                ? [
                    {
                      path: `/admin/adminProduct/list`,
                      basePath: `/admin/adminProduct/`,
                      icon: help,
                      testid: 'admin-manage-product',
                      title: 'Manage Products',
                    },
                  ]
                : []),
              ...(authorizedSideMenuAdminCreateProduct()
                ? [
                    {
                      path: `/admin/create-product`,
                      basePath: `/admin/create-product`,
                      icon: help,
                      testid: '',
                      title: 'Create Product',
                    },
                  ]
                : []),
              ...(authorizedSideMenuManagementDashboard()
                ? [
                    {
                      path: `/admin/management-dashboard`,
                      basePath: `/admin/management-dashboard`,
                      icon: help,
                      testid: '',
                      title: 'Dashboard',
                    },
                  ]
                : []),
              ...(authorizedSideMenuContractTemplate()
                ? [
                    {
                      path: `/admin/contract-template/list`,
                      basePath: `/admin/contract-template/`,
                      icon: help,
                      testid: 'admin-contract-template',
                      title: 'Contracts',
                    },
                  ]
                : []),
              ...(authorizedSideMenuAdminFileStorage()
                ? [
                    {
                      path: `/account/file-storage/list`,
                      basePath: `/account/file-storage/`,
                      icon: help,
                      testid: '',
                      title: 'File Storage',
                    },
                  ]
                : []),
              ...(authorizedSideMenuAdminConfiguration()
                ? [
                    {
                      path: `/base/configuration/update`,
                      basePath: `/base/configuration/`,
                      icon: help,
                      testid: '',
                      title: 'Configuration',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ];
  return { navData };
}

function dataManagementPath() {
  if (authorizedSideMenuDataPlatformSystemAttributes()) {
    return 'system/attributes';
  } else if (authorizedSideMenuDataPlatformUserAttributes()) {
    return 'user/attributes';
  } else if (authorizedSideMenuDataPlatformEventsAttributes()) {
    return 'events/attributes';
  } else if (authorizedSideMenuDataPlatformEventsRevenue()) {
    return 'events/revenue';
  } else if (authorizedSideMenuDataPlatformEventBusiness()) {
    return 'events/business';
  } else {
    return '';
  }
}

function uploadDataPath() {
  return authorizedSideMenuDataPlatformUsersUpload()
    ? 'users/upload'
    : authorizedSideMenuDataPlatformEventUpload()
    ? 'event/upload'
    : '';
}

function settingsPath() {
  return authorizedSideMenuSettingGeneral() &&
    featureAccessCheckSettingGeneralTab()
    ? '/general'
    : authorizedSideMenuSettingFc() && featureAccessCheckSettingFCTab()
    ? '/fc'
    : authorizedSideMenuSettingDnd() && featureAccessCheckSettingDNDTab()
    ? '/dnd'
    : authorizedSideMenuSettingCustomDomain() &&
      featureAccessCheckSettingCustomDomainTab()
    ? '/custom-domain'
    : '';
}

function channelsPath() {
  return authorizedSideMenuSettingPush() &&
    featureAccessCheckSideMenuEngagePush()
    ? '/push'
    : authorizedSideMenuSettingSms() && featureAccessCheckSideMenuEngageSms()
    ? '/sms'
    : authorizedSideMenuSettingEmail() &&
      featureAccessCheckSideMenuEngageEmail()
    ? '/email'
    : authorizedSideMenuSettingWhatsApp() &&
      featureAccessCheckSideMenuEngageWhatsapp()
    ? '/whatsapp'
    : authorizedSideMenuSettingTemplate() &&
      featureAccessCheckSideMenuEngageEmail()
    ? '/template'
    : authorizedSideMenuSettingChannelCustom() &&
      featureAccessCheckSideMenuEngageCustomChannel()
    ? '/custom'
    : '';
}

export default routes;
