export const PRODUCT_SDK_ANDROID_VIEW_FETCH = 'PRODUCT_SDK_ANDROID_VIEW_FETCH';
export const PRODUCT_SDK_ANDROID_VIEW_FETCH_FAIL =
  'PRODUCT_SDK_ANDROID_VIEW_FETCH_FAIL';
export const PRODUCT_SDK_ANDROID_VIEW_FETCH_SUCCESS =
  'PRODUCT_SDK_ANDROID_VIEW_FETCH_SUCCESS';

export const PRODUCT_SDK_WEB_VIEW_FETCH = 'PRODUCT_SDK_WEB_VIEW_FETCH';
export const PRODUCT_SDK_WEB_VIEW_FETCH_FAIL =
  'PRODUCT_SDK_WEB_VIEW_FETCH_FAIL';
export const PRODUCT_SDK_WEB_VIEW_FETCH_SUCCESS =
  'PRODUCT_SDK_WEB_VIEW_FETCH_SUCCESS';

export const PRODUCT_SDK_IOS_VIEW_FETCH = 'PRODUCT_SDK_IOS_VIEW_FETCH';
export const PRODUCT_SDK_IOS_VIEW_FETCH_FAIL =
  'PRODUCT_SDK_IOS_VIEW_FETCH_FAIL';
export const PRODUCT_SDK_IOS_VIEW_FETCH_SUCCESS =
  'PRODUCT_SDK_IOS_VIEW_FETCH_SUCCESS';
