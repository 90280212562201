import React from 'react';

import InputSelector from '@Component/form/InputSelector';
import { Form } from 'antd';
import Card from '@Utils/Card';
import { InputColorPicker } from '../inlineThemePicker/inputColorpicker';
import { InlineThemePicker } from '../inlineThemePicker';

const styles = {
  editContainer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  editFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 6,
    fontSize: 13,
    lineHeight: 1.43,
    color: '#3d404e',
  },

  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'left',
    marginBottom: '24px',
    width: '37px',
  },

  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    display: 'flex',
    flexDirection: 'row',
  },
  infoBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 30px',
    borderBottom: '1px solid #e7e8e9',
    backgroundColor: '#fdfdfd',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dataRowContainer: {
    padding: 30,
  },
  dataRowFirst: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dataRow: {
    paddingTop: 10,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

export default function OnSiteActions({
  handleChange,
  variation,
  disabled,
  isNotValid,
  index,
  customFonts,
  ...props
}) {
  return (
    <Card
      styles={{
        marginTop: 40,
      }}
    >
      <div style={styles.infoBoxHeader}>
        <div style={styles.headTitleContainer}>
          <span>Actions</span>
        </div>
      </div>
      <div style={styles.dataRowContainer}>
        {variation?.onSiteLayoutType === 'IMAGE' && (
          <div style={styles.dataRowFirst}>
            <div style={styles.editContainer}>
              <div
                style={{
                  ...styles.editLabel,
                }}
              >
                ON-CLICK ACTION
              </div>
              <div
                style={{
                  ...styles.editFieldContainer,
                  marginLeft: 20,
                  flex: 3,
                }}
              >
                <Form.Item
                  validateStatus={isNotValid && 'error'}
                  help={
                    isNotValid && 'you should input both button text and action'
                  }
                >
                  <InputSelector
                    dataCy="onclick-action-url"
                    style={{ maxWidth: '100%' }}
                    disabled={disabled}
                    type="richInline"
                    index={index}
                    attributeStyle={{
                      position: 'relative',
                      top: '-10px',
                      right: '-380px',
                    }}
                    id="deep-link-url"
                    journeySteps={props.journeySteps}
                    attributes={props.attributes}
                    events={props.events}
                    businessEvents={props.businessEvents}
                    hideEmojiPicker
                    placeholder="Deeplink or URL"
                    value={
                      variation?.onSiteLayoutType === 'TEXT'
                        ? variation?.androidButton1OnClickAction
                        : variation?.onSiteLayoutType === 'IMAGE'
                        ? variation?.androidOnClickAction
                        : ''
                    }
                    onChange={(value) => {
                      if (variation?.onSiteLayoutType === 'TEXT') {
                        handleChange('androidButton1OnClickAction', value);
                      } else if (variation?.onSiteLayoutType === 'IMAGE') {
                        handleChange('androidOnClickAction', value);
                      }
                    }}
                    size="large"
                  />
                </Form.Item>
                <span
                  style={{
                    color: '#94969e',
                    fontSize: '10px',
                    paddingLeft: '5px',
                    paddingTop: '5px',
                    display: 'block',
                  }}
                >
                  {
                    'Deeplink can be any URI (eg. myapp://productdetails/item/abc). URL can be HTTP or HTTPS (eg. https://www.abc.com)'
                  }
                </span>
              </div>
            </div>
          </div>
        )}
        {variation?.onSiteLayoutType === 'TEXT' && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 18 }}>
            <div style={styles.dataRowFirst}>
              <div style={styles.editContainer}>
                <div
                  style={{
                    ...styles.editLabel,
                    position: 'relative',
                    top: -10,
                  }}
                >
                  BUTTON TEXT
                </div>
                <div
                  style={{
                    ...styles.editFieldContainer,
                    marginLeft: 20,
                    flex: 3,
                  }}
                >
                  <InlineThemePicker
                    name="actions"
                    actionIndex={0}
                    variation={variation}
                    onChange={(value) => handleChange('theme', value)}
                    customFonts={customFonts}
                  />
                  <Form.Item
                    validateStatus={isNotValid && 'error'}
                    help={
                      isNotValid &&
                      'you should input both button text and action'
                    }
                  >
                    <InputSelector
                      type="richInline"
                      style={{ maxWidth: '100%' }}
                      index={index}
                      disabled={disabled}
                      attributeStyle={{
                        position: 'relative',
                        top: '-10px',
                        right: '-380px',
                        zIndex: 20,
                      }}
                      journeySteps={props.journeySteps}
                      attributes={props.attributes}
                      events={props.events}
                      businessEvents={props.businessEvents}
                      value={
                        variation && variation?.androidButton1Label
                          ? variation?.androidButton1Label
                          : ''
                      }
                      onChange={(value) =>
                        handleChange('androidButton1Label', value)
                      }
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div style={styles.dataRowFirst}>
              <div style={styles.editContainer}>
                <div
                  style={{
                    ...styles.editLabel,
                  }}
                >
                  ON-CLICK ACTION
                </div>
                <div
                  style={{
                    ...styles.editFieldContainer,
                    marginLeft: 20,
                    flex: 3,
                  }}
                >
                  <Form.Item
                    validateStatus={isNotValid && 'error'}
                    help={
                      isNotValid &&
                      'you should input both button text and action'
                    }
                  >
                    <InputSelector
                      dataCy="onclick-action-url"
                      style={{ maxWidth: '100%' }}
                      disabled={disabled}
                      type="richInline"
                      index={index}
                      attributeStyle={{
                        position: 'relative',
                        top: '-10px',
                        right: '-380px',
                      }}
                      id="deep-link-url"
                      journeySteps={props.journeySteps}
                      attributes={props.attributes}
                      events={props.events}
                      businessEvents={props.businessEvents}
                      hideEmojiPicker
                      placeholder="Deeplink or URL"
                      value={
                        variation?.onSiteLayoutType === 'TEXT'
                          ? variation?.androidButton1OnClickAction
                          : variation?.onSiteLayoutType === 'IMAGE'
                          ? variation?.androidOnClickAction
                          : ''
                      }
                      onChange={(value) => {
                        if (variation?.onSiteLayoutType === 'TEXT') {
                          handleChange('androidButton1OnClickAction', value);
                        } else if (variation?.onSiteLayoutType === 'IMAGE') {
                          handleChange('androidOnClickAction', value);
                        }
                      }}
                      size="large"
                    />
                  </Form.Item>
                  <span
                    style={{
                      color: '#94969e',
                      fontSize: '10px',
                      paddingLeft: '5px',
                      paddingTop: '5px',
                      display: 'block',
                    }}
                  >
                    {
                      'Deeplink can be any URI (eg. myapp://productdetails/item/abc). URL can be HTTP or HTTPS (eg. https://www.abc.com)'
                    }
                  </span>
                </div>
              </div>
            </div>
            <div style={styles.dataRowFirst}>
              <div
                style={{
                  gap: 24,
                  marginTop: 18,
                  alignItems: 'center',
                  ...styles.editContainer,
                }}
              >
                <div
                  style={{
                    ...styles.editLabel,
                  }}
                >
                  ACTION BACKGROUND
                </div>
                <InputColorPicker
                  name="actions"
                  actionIndex={0}
                  variation={variation}
                  onChange={(theme) => handleChange('theme', theme)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
