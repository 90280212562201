import texts from '@Utils/texts';
import React from 'react';
import Highlight from 'react-highlight.js';

const TrackEventFragmentAndroid = ({ highlight }) => {
  return (
    <>
      <div style={{ padding: 30 }}>
        <div className="fragment-content">
          <span>
            Users can create their own custom messages and send them to{' '}
            {texts.brandName}&nbsp;server. This can be done by calling
            <a
              style={{ paddingLeft: '5px' }}
              href={texts.androidEvent}
              target={'_blank'}
              rel="noreferrer"
            >
              recordEvent
            </a>
            .
          </span>
        </div>
      </div>
      <div className="rows-space">
        {highlight && (
          <div className="code-grey-card">
            <Highlight language={'javascript'} className="highlight">
              <div>{highlight}</div>
            </Highlight>
          </div>
        )}
      </div>
    </>
  );
};

export default TrackEventFragmentAndroid;
