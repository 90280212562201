import BaseApi from '../../../api/BaseApi';

const basePath = '/monitoring';
export default class SettingContractsApi {
  static features() {
    return BaseApi.post('/features/hierarchy/view');
  }
  static adminFeatures() {
    return BaseApi.post('/features/hierarchy/adminView');
  }
  static sla(body) {
    return BaseApi.post(basePath + '/sla/getMetrics', body);
  }
  static gatewayRequest(id, gateway) {
    return BaseApi.post('/billing/payment/' + gateway + '/create-order/' + id);
  }
  static transaction(token) {
    return BaseApi.post('/billing/payment/transaction/view/' + token);
  }
}
