import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input, Radio, Space, InputNumber } from 'antd';
import ScreenNameMapper from './ScreenNameMapper';
import ScreenDataMapper from './ScreenDataMapper';
import NumberOfScreensMapper from './NumberOfScreensMapper';
import EventsMapper from './EventsMapper';
import Card from '@Utils/Card';

const styles = {
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  editFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 6,
    fontSize: 13,
    lineHeight: 1.43,
    color: '#3d404e',
  },

  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'right',
  },
};

export default function InAppAdvanceSettings({
  channel,
  onChange,
  eventList,
  data,
}) {
  const [form] = Form.useForm();
  const formValues = form.getFieldValue();
  const [savedDataInjected, setSaveDataInjected] = useState(false);

  const formValueIsValid = (values) => {
    return (
      (values.setScreenName &&
        values.screenNameMapper &&
        values.screenNameMapper.length &&
        values.screenNameMapper[0]?.value) ||
      (values.setScreenData &&
        values.screenDataMapper &&
        values.screenDataMapper.length &&
        values.screenDataMapper[0]?.term) ||
      values.setScreenTime ||
      values.setSessionTime ||
      (values.setNumberOfScreens && values.numberOfScreenMapper?.value) ||
      (values.setEvents &&
        values.eventsMapper &&
        values.eventsMapper.length &&
        values.eventsMapper[0]?.value) ||
      (values.hasShowLimit && values.showLimit)
    );
  };

  useEffect(() => {
    if (!savedDataInjected && Object.keys(data).length) {
      const fieldsValue = {
        ...data,
        whenOption:
          data.setScreenName ||
          data.setScreenData ||
          data.setScreenTime ||
          data.setNumberOfScreens ||
          data.setEvents ||
          formValues?.whenOption === 'specific'
            ? 'specific'
            : 'any',
      };
      form.setFieldsValue(fieldsValue);
      onChange(fieldsValue, formValueIsValid(fieldsValue)); //<-- workaround for mapping data
      setSaveDataInjected(true);
    }
  }, [data]);

  return (
    <Card
      styles={{
        marginTop: 20,
        paddingBottom: 50,
        marginBottom: 60,
      }}
    >
      <div
        style={{
          padding: 16,
          borderBottom: 'solid 2px #f4f9ff',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <div style={{ fontSize: 16, color: 'black', fontWeight: '600' }}>
          Where and When to Show
        </div>
      </div>
      <Form
        form={form}
        name="when_where"
        initialValues={{
          whenOption: 'any',
          hasShowLimit: false,
          screenNameMapper: [
            {
              operator: 'EQUAL_TO',
              value: null,
              negate: false,
            },
          ],
          screenNameMapperConjunction: 'OR',
          screenDataMapper: [
            {
              operator: 'EQUAL_TO',
              term: null,
              value: null,
              negate: false,
              dataType: 'STRING',
            },
          ],
          screenDataMapperConjunction: 'OR',
          numberOfScreenMapper: {
            operator: 'EQUAL_TO',
            value: null,
            negate: false,
          },
          eventsMapper: [
            {
              operator: 'EQUAL_TO',
              value: null,
              negate: false,
            },
          ],
          eventsMapperConjunction: 'OR',
        }}
        onFinish={(values) => {
          let isValid = true;

          if (
            (values.hasShowLimit && !values.showLimit) ||
            (values.setScreenTime && !values.screenTime) ||
            (values.setSessionTime && !values.sessionTime)
          ) {
            isValid = false;
          }
          onChange(values, isValid);
        }}
        onFinishFailed={({ values, errorFields }) => {
          onChange(values, !errorFields?.length);
        }}
        onValuesChange={() => {
          form.submit();
        }}
        autoComplete="off"
        style={{
          maxWidth: '100%',
        }}
      >
        <div
          style={{
            ...styles.editContainer,
            marginTop: 20,
            alignItems: 'flex-start',
          }}
        >
          <div style={{ ...styles.editLabel, position: 'relative', top: 2 }}>
            Where &amp; When to Show
          </div>
          <div style={{ ...styles.editFieldContainer, marginLeft: 20 }}>
            <Form.Item
              name="whenOption"
              rules={[
                () => ({
                  validator(_, value) {
                    const values = form.getFieldsValue();
                    if (value === 'specific' && !formValueIsValid(values)) {
                      return Promise.reject(
                        new Error(
                          'You need to set atleast one of the following conditions.'
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={'any'}>
                    Show on any page as soon as user opens the site
                  </Radio>
                  <Radio value={'specific'}>
                    Show on specific pages and/or show when specific conditions
                    are met
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
        {formValues?.whenOption === 'specific' ? (
          <Form.Item
            shouldUpdate={(prevValues, curValues) => {
              return prevValues.whenOption !== curValues.whenOption;
            }}
            style={{ margin: 0 }}
          >
            {() => {
              return (
                <>
                  <div
                    style={{
                      ...styles.editContainer,
                      marginTop: 20,
                      alignItems: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        ...styles.editLabel,
                        position: 'relative',
                        top: 2,
                      }}
                    >
                      Where to Show
                    </div>
                    <div
                      style={{ ...styles.editFieldContainer, marginLeft: 20 }}
                    >
                      <Form.Item name="setScreenName" valuePropName="checked">
                        <Checkbox>
                          On pages that meet certain page name conditions
                        </Checkbox>
                      </Form.Item>

                      {formValues.setScreenName ? (
                        <Form.Item
                          shouldUpdate={(prevValues, curValues) => {
                            return (
                              prevValues.setScreenName !==
                              curValues.setScreenName
                            );
                          }}
                        >
                          {() => {
                            return <ScreenNameMapper form={form} />;
                          }}
                        </Form.Item>
                      ) : null}

                      <Form.Item name="setScreenData" valuePropName="checked">
                        <Checkbox>
                          On pages that meet certain page data conditions
                        </Checkbox>
                      </Form.Item>

                      {formValues.setScreenData ? (
                        <Form.Item
                          shouldUpdate={(prevValues, curValues) => {
                            return (
                              prevValues.setScreenData !==
                              curValues.setScreenData
                            );
                          }}
                        >
                          {() => {
                            return <ScreenDataMapper form={form} />;
                          }}
                        </Form.Item>
                      ) : null}
                    </div>
                  </div>

                  <div
                    style={{
                      ...styles.editContainer,
                      marginTop: 20,
                      alignItems: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        ...styles.editLabel,
                        position: 'relative',
                        top: 2,
                      }}
                    >
                      When to Show
                    </div>
                    <div
                      style={{ ...styles.editFieldContainer, marginLeft: 20 }}
                    >
                      <Form.Item
                        style={{ marginBottom: '0' }}
                        shouldUpdate={(prevValues, curValues) => {
                          return (
                            prevValues.setScreenTime !==
                              curValues.setScreenTime ||
                            (prevValues.screenTime && !curValues.screenTime)
                          );
                        }}
                      >
                        {() => {
                          const shouldSetScreenTime =
                            form.getFieldValue('setScreenTime');
                          const screenTimeValue =
                            form.getFieldValue('screenTime');
                          return (
                            <Form.Item
                              name="setScreenTime"
                              valuePropName="checked"
                              validateStatus={
                                shouldSetScreenTime && !screenTimeValue
                                  ? 'error'
                                  : 'success'
                              }
                            >
                              <Checkbox>
                                Total time spent on a page in a session by a
                                user is{' '}
                                <InputNumber
                                  min={1}
                                  style={{ width: '100px' }}
                                  value={screenTimeValue}
                                  controls={false}
                                  onChange={(value) => {
                                    const values = form.getFieldsValue();
                                    form.setFieldsValue({
                                      ...values,
                                      screenTime: value,
                                    });
                                    form.submit();
                                  }}
                                  disabled={!shouldSetScreenTime}
                                />{' '}
                                seconds
                              </Checkbox>
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                      <Form.Item name="screenTime" style={{ display: 'none' }}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        style={{ marginBottom: '0' }}
                        shouldUpdate={(prevValues, curValues) => {
                          return (
                            prevValues.setSessionTime !==
                              curValues.setSessionTime ||
                            (prevValues.sessionTime && !curValues.sessionTime)
                          );
                        }}
                      >
                        {() => {
                          const shouldSetSessionTime =
                            form.getFieldValue('setSessionTime');
                          const sessionTimeValue =
                            form.getFieldValue('sessionTime');
                          return (
                            <Form.Item
                              name="setSessionTime"
                              valuePropName="checked"
                              validateStatus={
                                shouldSetSessionTime && !sessionTimeValue
                                  ? 'error'
                                  : 'success'
                              }
                            >
                              <Checkbox>
                                Total time spent on your site in a session by a
                                user is{' '}
                                <InputNumber
                                  min={1}
                                  style={{ width: '100px' }}
                                  value={sessionTimeValue}
                                  controls={false}
                                  onChange={(value) => {
                                    const values = form.getFieldsValue();
                                    form.setFieldsValue({
                                      ...values,
                                      sessionTime: value,
                                    });
                                    form.submit();
                                  }}
                                  disabled={!shouldSetSessionTime}
                                />{' '}
                                seconds
                              </Checkbox>
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                      <Form.Item name="sessionTime" style={{ display: 'none' }}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="setNumberOfScreens"
                        valuePropName="checked"
                      >
                        <Checkbox>
                          Number of pages viewed in a session by a user
                        </Checkbox>
                      </Form.Item>
                      {formValues.setNumberOfScreens ? (
                        <Form.Item
                          shouldUpdate={(prevValues, curValues) => {
                            return (
                              prevValues.setNumberOfScreens !==
                              curValues.setNumberOfScreens
                            );
                          }}
                        >
                          {() => {
                            return <NumberOfScreensMapper form={form} />;
                          }}
                        </Form.Item>
                      ) : null}

                      <Form.Item name="setEvents" valuePropName="checked">
                        <Checkbox>User does one or more events</Checkbox>
                      </Form.Item>

                      {formValues.setEvents ? (
                        <Form.Item
                          shouldUpdate={(prevValues, curValues) => {
                            return prevValues.setEvents !== curValues.setEvents;
                          }}
                        >
                          {() => {
                            return (
                              <EventsMapper
                                eventList={eventList}
                                form={form}
                                channel={channel}
                              />
                            );
                          }}
                        </Form.Item>
                      ) : null}
                    </div>
                  </div>
                </>
              );
            }}
          </Form.Item>
        ) : null}
        <div
          style={{
            ...styles.editContainer,
            marginTop: 20,
            alignItems: 'flex-start',
          }}
        >
          <div style={{ ...styles.editLabel, position: 'relative', top: 2 }}>
            Show Limit
          </div>
          <div style={{ ...styles.editFieldContainer, marginLeft: 20 }}>
            <Form.Item
              shouldUpdate={(prevValues, curValues) => {
                return (
                  prevValues.hasShowLimit !== curValues.hasShowLimit ||
                  (prevValues.showLimit && !curValues.showLimit)
                );
              }}
            >
              {() => {
                const hasShowLimit = form.getFieldValue('hasShowLimit');
                const showLimit = form.getFieldValue('showLimit');
                return (
                  <Form.Item
                    name="hasShowLimit"
                    validateStatus={
                      hasShowLimit && !showLimit ? 'error' : 'success'
                    }
                  >
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value={false}>
                          Show any number of times if user doesn’t click or
                          dismiss notification
                        </Radio>
                        <Radio value={true}>
                          Show maximum of{' '}
                          <InputNumber
                            min={1}
                            style={{ width: '100px' }}
                            value={showLimit}
                            onChange={(value) => {
                              const values = form.getFieldsValue();
                              form.setFieldsValue({
                                ...values,
                                showLimit: value,
                              });
                              form.submit();
                            }}
                            disabled={!hasShowLimit}
                          />{' '}
                          times if user doesn’t click or dismiss notification
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item name="showLimit" style={{ display: 'none' }}>
              <Input />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Card>
  );
}
