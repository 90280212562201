import ListBase from '@Component/form/ListBase';
import { backOfficeInvoiceListAllFetch } from '@Redux/slices/account/backOfficeInvoiceSlice';
import { history } from '../../../../redux/store';

import Card from '@Utils/Card';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PAYMENT_STATUS, CONTRACT_STATUS } from './BackOfficeConstant';
function BackOfficeInvoice() {
  const dispatch = useDispatch();
  const backOfficeInvoiceListAll = useSelector(
    (state) => state.account.backOffice.backOfficeInvoiceListAll
  );

  const pageRequest = useSelector(
    (state) => state.account.backOffice.pageRequest
  );
  const loading = useSelector((state) => state.account.backOffice.loading);

  useEffect(() => {
    dispatch(backOfficeInvoiceListAllFetch({ pageRequest }));
  }, []);
  const columnInvoicesiList = [
    {
      title: 'Project Name',
      dataIndex: ['contract', 'product', 'name'],
      key: 'projectName',
      render: (text, record) => (
        <a
          onClick={() => {
            const contractId = record.contract?.id;
            const url = contractId
              ? `invoice-list/${contractId}`
              : 'invoice-list';
            history.push(url);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Contract Template',
      dataIndex: ['contract', 'contractTemplate', 'title'],
      key: 'contractTemplate',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Contract Status',
      dataIndex: ['contract', 'status'],
      key: 'contractStatus',
      render: (text) =>
        text ? (
          <span
            className="Status"
            style={{
              fontSize: 11,
              fontWeight: '600',
              padding: '5px 10px',
              textTransform: 'uppercase',
              borderRadius: '50px',
              backgroundColor: CONTRACT_STATUS[text]?.bgColor || '#e7e8e9',
              color: CONTRACT_STATUS[text]?.color || '#94969e',
            }}
          >
            {text}
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Last Payment Status',
      dataIndex: 'status',
      key: 'lastPaymentStatus',
      render: (text) =>
        text ? (
          <span
            className="Status"
            style={{
              fontSize: 11,
              fontWeight: '600',
              padding: '5px 10px',
              textTransform: 'uppercase',
              borderRadius: '50px',
              backgroundColor: PAYMENT_STATUS[text]?.bgColor || '#e7e8e9',
              color: PAYMENT_STATUS[text]?.color || '#94969e',
            }}
          >
            {text}
          </span>
        ) : (
          '-'
        ),
    },
  ];

  return (
    <>
      <Card loading={loading}>
        <ListBase
          onChange={(p) =>
            dispatch(
              backOfficeInvoiceListAllFetch({
                ...pageRequest,
                page: p.page,
              })
            )
          }
          page={backOfficeInvoiceListAll}
          pageRequest={pageRequest}
          columns={columnInvoicesiList}
          createLink={null}
        />
      </Card>
    </>
  );
}

export default BackOfficeInvoice;
