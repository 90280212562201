import React, { useCallback, useContext, useEffect, useState } from 'react';

import { AppContext } from '../../app';
import { Button, Form, Steps, Col, Row, Card } from 'antd';
import _ from 'lodash';
import BuildStep from './Childs/AcCallApiBuildStep';
import ReactJson from 'react-json-view';
import { tryParseJSONObject } from '../../utils/hooks';

const { Step } = Steps;

const DefaultData = {
  body: undefined,
  headers: null,
  method: 'POST',
  type: 'CALL_API',
  url: '',
};

const ReviewStep = (props) => {
  const { currentTab, reviewResponse, tab } = props;
  const dataCall = props.dataCall;

  const { headers } = dataCall;

  const isNotEmptyHeader_VALIDATION = () => {
    if (!headers.length) return false;
    return headers.length > 1 || headers[0].key || headers[0].value;
  };

  const onChangeStepI = (e) => {
    e.preventDefault();
    // onChangeStep(index);
  };

  const isJsonObject = useCallback(() => {
    try {
      if (reviewResponse) {
        const reviewResponseString = _.isString(reviewResponse)
          ? reviewResponse
          : JSON.stringify(reviewResponse);

        return tryParseJSONObject(reviewResponseString);
      }
    } catch (e) {
      return false;
    }
  }, [reviewResponse]);

  if (currentTab.index !== tab) {
    return '';
  }

  return (
    <div>
      <Row>
        <Col span={11}>
          <Card
            title="Request"
            bordered={true}
            bodyStyle={{ background: '#f9f9f9' }}
            extra={<a onClick={(e) => onChangeStepI(e, 0)}>Edit</a>}
          >
            <div>
              {dataCall.method && (
                <Row>
                  <Col span={4}>
                    <div className="acl-call-content">METHOD</div>
                  </Col>
                  <Col span={20}>
                    <div className="acl-call-content">{dataCall.method}</div>
                  </Col>
                </Row>
              )}
              {dataCall.url && (
                <Row>
                  <Col span={4}>
                    <div className="acl-call-content">URL</div>
                  </Col>
                  <Col span={20}>
                    <div className="acl-call-content">{dataCall.url}</div>
                  </Col>
                </Row>
              )}
              {dataCall.body && (
                <Row>
                  <Col span={4}>
                    <div className="acl-call-content">BODY</div>
                  </Col>
                  <Col span={20}>
                    <div className="acl-call-content">{dataCall.body}</div>
                  </Col>
                </Row>
              )}
              {isNotEmptyHeader_VALIDATION() && (
                <Row>
                  <Col span={4}>
                    <div className="acl-call-content">HEADERS</div>
                  </Col>
                  <Col span={20}>
                    <div className="acl-call-content">
                      {dataCall.headers.map((header, k) => (
                        <div
                          key={k}
                        >{`${header['key']}:${header['value']}`}</div>
                      ))}
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </Card>
        </Col>
        <Col span={1}></Col>
        <Col span={12}>
          <Card
            title="Response"
            bordered={true}
            bodyStyle={{ background: '#f9f9f9' }}
            extra={<a onClick={(e) => onChangeStepI(e, 1)}>Test again</a>}
          >
            <div className="acl-call__response">
              {isJsonObject() ? (
                <ReactJson src={isJsonObject()} />
              ) : (
                JSON.stringify(reviewResponse)
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const tabsData = [
  {
    key: 'build',
    title: 'Build API Request',
    status: 'wait',
  },
  {
    key: 'test',
    title: 'Test Response',
    status: 'wait',
  },
  {
    key: 'review',
    title: 'Review',
    status: 'wait',
  },
];

const AcCallApi = (props) => {
  const { journey, syncCache, testApiDataContext, loading } =
    useContext(AppContext);
  const [dataCall, setDataCall] = useState(Object.assign({}, DefaultData));
  const [response, setResponse] = useState();
  const [currentTab, setCurrentTab] = useState({ index: 0, key: 'build' });

  const currentStep = journey.steps.find(
    (step) => step.id + '' === props.node.id + ''
  );

  useEffect(() => {
    setDataCall(Object.assign({}, DefaultData, currentStep));
    setResponse(_.get(currentStep, 'ui.response', ''));
  }, []);

  useEffect(() => {
    if (testApiDataContext && !loading) {
      setResponse(testApiDataContext);
      onTabChange(2);
    }
  }, [testApiDataContext, loading]);

  const onTabChange = async (index) => {
    if (dataCall.url) {
      setCurrentTab({ index, key: tabsData[index].key });
    }
  };

  const onFinish = async () => {
    const currentStep2 = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );
    if (loading) return;

    if (response) {
      currentStep2.ui = Object.assign({}, currentStep.ui, {
        response: response,
      });
    }
    delete currentStep2.ui?.error;

    syncCache('update-step', currentStep2, true);
    props.closeModal();
  };

  return (
    <>
      <div className={'modal-body'}>
        <Steps
          type="navigation"
          current={currentTab.index}
          onChange={onTabChange}
          className="site-navigation-steps"
        >
          {tabsData.map((step) => (
            <Step
              key={step.key}
              status={step.status}
              title={step.title}
              icon={'null'}
            />
          ))}
        </Steps>
        <div className="steps-content acl-call__steps">
          <BuildStep
            {...props}
            tab={0}
            dataCall={dataCall}
            setDataCall={setDataCall}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            tabsData={tabsData}
            currentStep={currentStep}
            syncCache={syncCache}
            reviewResponse={response}
          />

          <BuildStep
            tab={1}
            {...props}
            isTestStep
            dataCall={dataCall}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            tabsData={tabsData}
            currentStep={currentStep}
            reviewResponse={response}
          />

          <ReviewStep
            {...props}
            tab={2}
            currentTab={currentTab}
            dataCall={dataCall}
            // syncCache={syncCache}
            reviewResponse={response}
          />
          {currentTab.index === 2 && (
            <div className={'modal-footer'}>
              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => onFinish()}
                  // disabled={!isValid || props.readOnly}
                  disabled={props.readOnly}
                >
                  SAVE
                </Button>
              </Form.Item>
            </div>
          )}
          {/* )} */}
        </div>
      </div>
    </>
  );
};
export default AcCallApi;
