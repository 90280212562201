import AdminProductUpdateComponent from '../component/AdminProductUpdateComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  adminProductChangeCurrent,
  adminProductUpdateFetch,
  adminProductUpdateSubmitFetch,
  adminProductCreateFetch,
  eventRateChangeCurrent,
  getParentProducts,
} from '../AdminProductActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentAdminProduct: state.admin.adminProduct.currentAdminProduct,
  errors: state.admin.adminProduct.errors,
  loadingAdminProduct: state.admin.adminProduct.loadingAdminProduct,
  submitLoading: state.admin.adminProduct.submitLoading,
  pathname: state.router.location.pathname,
  parentProducts: state.admin.adminProduct.parentProducts,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      adminProductChangeCurrent,
      adminProductUpdateFetch,
      adminProductUpdateSubmitFetch,
      adminProductCreateFetch,
      eventRateChangeCurrent,
      getParentProducts,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProductUpdateComponent);

AdminProductUpdateComponent.propTypes = {
  currentAdminProduct: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  adminProductChangeCurrent: PropTypes.func.isRequired,
  getParentProducts: PropTypes.func.isRequired,
  adminProductUpdateFetch: PropTypes.func.isRequired,
  adminProductUpdateSubmitFetch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
