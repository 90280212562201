import React, { useEffect, useRef, useState } from 'react';
import { Input, Modal, Spin, Alert } from 'antd';
import EmailEditor from 'react-email-editor';
import { getEmailCustomAttributes } from '../../../../utils/MergeTags';
import EmailTemplateSample from '../model/EmailTemplateSample.json';
import { useDispatch, useSelector } from 'react-redux';

import {
  emailTemplateToggleModal,
  emailTemplateCreateFetch,
  emailTemplateChangeCurrent,
  emailTemplateUpdateFetch,
  emailTemplateUpdateSubmitFetch,
} from '../EmailTemplateActions';

import {
  productSegmentListAttributesFetch,
  productSegmentListEventsFetch,
} from '../../../insight/productSegment/ProductSegmentActions';
import { isFunction } from 'lodash';

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'right',
  },
  editFieldContainer: {
    flex: 6,
  },
  editField: {
    marginLeft: 20,
    width: 350,
  },
  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    display: 'flex',
    flexDirection: 'row',
  },
  infoBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 30px',
    borderBottom: '1px solid #e7e8e9',
    backgroundColor: '#fdfdfd',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dataRowContainer: {
    padding: 30,
  },
};

const CONFIRMATION_STATUS = {
  ACCEPT: 'ACCEPT',
  DENY: 'DENY',
  SHOW: 'SHOW',
  HIDE: 'HIDE',
};

function EmailTemplateModal({ onDone, onCancel }) {
  const dispatch = useDispatch();
  const [showTemplateTitle, setShowTemplateTitle] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState(
    CONFIRMATION_STATUS.HIDE
  );

  const editor = useRef(null);

  const {
    isModalOpen,
    isModalEditorMode,
    selectedTemplateId,
    loadingUpdateSubmit,
    loadingCreate,
    attributes,
    events,
    loadingEvents,
    loadingAttributes,
    loadingUpdate,
    currentTemplate,
  } = useSelector((state) => {
    return {
      isModalOpen: state.channel.emailTemplate.isModalOpen,
      isModalEditorMode: state.channel.emailTemplate.isModalEditorMode,
      selectedTemplateId: state.channel.emailTemplate.selectedTemplateId,
      loadingUpdateSubmit: state.channel.emailTemplate.loadingUpdateSubmit,
      loadingCreate: state.channel.emailTemplate.loadingCreate,
      attributes: state.insight.productSegment.attributes,
      events: state.insight.productSegment.events,
      loadingEvents: state.insight.productSegment.loadingEvents,
      loadingAttributes: state.insight.productSegment.loadingAttributes,
      loadingUpdate: state.channel.emailTemplate.loadingUpdate,
      currentTemplate: state.channel.emailTemplate.currentTemplate,
    };
  });

  useEffect(() => {
    dispatch(productSegmentListAttributesFetch());
    dispatch(productSegmentListEventsFetch());
  }, []);

  useEffect(() => {
    if (selectedTemplateId) {
      dispatch(emailTemplateUpdateFetch(selectedTemplateId));
    }
  }, [selectedTemplateId]);

  useEffect(() => {
    const unlayerTemp = editor.current?.editor;

    const currentMessage =
      currentTemplate && currentTemplate.json ? currentTemplate.json : null;

    if (currentMessage) {
      setTimeout(
        () => unlayerTemp?.loadDesign(JSON.parse(currentMessage)),
        100
      );
    } else {
      setTimeout(() => unlayerTemp?.loadDesign(EmailTemplateSample), 100);
    }
  }, [currentTemplate]);

  useEffect(() => {
    if (confirmationStatus === CONFIRMATION_STATUS.ACCEPT) {
      setConfirmationStatus(CONFIRMATION_STATUS.HIDE);
      cancelModal();
    } else if (confirmationStatus === CONFIRMATION_STATUS.DENY) {
      setConfirmationStatus(CONFIRMATION_STATUS.HIDE);
    }
  }, [confirmationStatus]);

  const handleDone = () => {
    const unlayerTemp = editor.current?.editor;

    unlayerTemp?.exportHtml((data) => {
      dispatch(emailTemplateToggleModal(false, null, false));
      setShowTemplateTitle(false);
      //   dispatch(emailTemplateChangeCurrent({}));
      setIsEdited(false);
      if (isFunction(onDone)) {
        onDone(data);
      }
    });
  };

  const handleCancel = () => {
    const unlayerTemp = editor.current?.editor;
    unlayerTemp.saveDesign((data) => {
      const dataChanged =
        currentTemplate?.json !== JSON.stringify(data) || isEdited;

      if (dataChanged) {
        setConfirmationStatus(CONFIRMATION_STATUS.SHOW);
      } else {
        cancelModal();
      }
    });
  };

  const cancelModal = () => {
    dispatch(emailTemplateToggleModal(false, null, false));
    dispatch(emailTemplateChangeCurrent({}));
    setShowTemplateTitle(false);
    setIsEdited(false);
    if (isFunction(onCancel)) {
      onCancel();
    }
  };

  return (
    <>
      <Modal
        bodyStyle={
          showTemplateTitle
            ? { overflow: 'hidden', padding: 0 }
            : { padding: 0 }
        }
        width={'100%'}
        maskClosable={false}
        title={
          selectedTemplateId || isModalEditorMode
            ? `Edit email template ${currentTemplate?.name}`
            : 'Add new email template'
        }
        centered
        visible={isModalOpen}
        onCancel={() => {
          if (showTemplateTitle) {
            setShowTemplateTitle(false);
          } else {
            handleCancel();
          }
        }}
        onOk={() => {
          const unlayerTemp = editor.current?.editor;

          if (isModalEditorMode) {
            handleDone();
          } else {
            if (showTemplateTitle) {
              if (selectedTemplateId) {
                dispatch(
                  emailTemplateUpdateSubmitFetch(
                    selectedTemplateId,
                    currentTemplate,
                    () => {
                      handleDone();
                    }
                  )
                );
              } else {
                dispatch(
                  emailTemplateCreateFetch(currentTemplate, () => {
                    dispatch(emailTemplateChangeCurrent(currentTemplate));
                    handleDone();
                  })
                );
              }
            } else {
              unlayerTemp?.saveDesign((data) => {
                setIsEdited(currentTemplate?.json !== JSON.stringify(data));
                dispatch(
                  emailTemplateChangeCurrent({
                    ...currentTemplate,
                    json: JSON.stringify(data),
                  })
                );
                setShowTemplateTitle(true);
              });
            }
          }
        }}
        cancelText={
          isModalEditorMode
            ? 'CANCEL'
            : showTemplateTitle
            ? 'GO BACK'
            : 'CANCEL'
        }
        okText={
          isModalEditorMode ? 'DONE' : showTemplateTitle ? 'SAVE' : 'NEXT'
        }
        confirmLoading={loadingUpdateSubmit || loadingCreate}
      >
        <div
          style={
            showTemplateTitle ? { height: selectedTemplateId ? 130 : 70 } : {}
          }
        >
          {loadingUpdate || loadingAttributes || loadingEvents ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 40,
              }}
            >
              <Spin style={{ display: 'flex', alignSelf: 'center' }} />
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {showTemplateTitle && (
                <div>
                  <div style={styles.editContainer}>
                    <div style={styles.editLabel}>Template Name</div>
                    <div style={styles.editFieldContainer}>
                      <Input
                        value={
                          currentTemplate && currentTemplate.name
                            ? currentTemplate.name
                            : ''
                        }
                        onChange={(e) => {
                          setIsEdited(true);
                          dispatch(
                            emailTemplateChangeCurrent({
                              ...currentTemplate,
                              name: e.target.value,
                            })
                          );
                        }}
                        size="large"
                        style={styles.editField}
                      />
                    </div>
                  </div>
                  {selectedTemplateId && (
                    <div style={{ padding: 20 }}>
                      <Alert
                        message="Communications and journeys that were built with this template should update manually."
                        type="warning"
                        showIcon
                      />
                    </div>
                  )}
                </div>
              )}
              <EmailEditor
                style={showTemplateTitle ? { display: 'none' } : {}}
                ref={editor}
                onLoad={(unlayerTemp) => {
                  //   if (!editor.current) return;
                  if (selectedTemplateId) {
                    const currentMessage =
                      currentTemplate && currentTemplate.json
                        ? currentTemplate.json
                        : null;
                    if (currentMessage) {
                      setTimeout(
                        () =>
                          unlayerTemp.loadDesign(JSON.parse(currentMessage)),
                        100
                      );
                    }
                  } else {
                    setTimeout(
                      () => unlayerTemp.loadDesign(EmailTemplateSample),
                      100
                    );
                  }
                  setTimeout(
                    () =>
                      unlayerTemp.setMergeTags(
                        getEmailCustomAttributes(attributes, events)
                      ),
                    200
                  );
                }}
              />
            </div>
          )}
        </div>
      </Modal>

      <Modal
        title="Warning"
        visible={confirmationStatus === CONFIRMATION_STATUS.SHOW}
        onOk={() => {
          setConfirmationStatus(CONFIRMATION_STATUS.ACCEPT, () => {});
        }}
        okText="Yes"
        onCancel={() => {
          setConfirmationStatus(CONFIRMATION_STATUS.DENY, () => {});
        }}
        cancelText="No"
      >
        <p>
          All your changes will be deleted. are you sure you want to cancel
          editing?
        </p>
      </Modal>
    </>
  );
}

export default EmailTemplateModal;
