import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../app';
import { Form, Button } from 'antd';
import ProductSegmentPredicateComponent from '@Modules/insight/productSegment/component/ProductSegmentPredicateComponent';

const predicateDefault = {
  attribute: 0,
  event: null,
  type: 'HAS_DONE_EVENT',
  eventAggregation: 'EXISTS',
  negate: false,
  eventConjunction: 'AND',
};

const CondHasDoneEvent = (props) => {
  const [form] = Form.useForm();
  const { journey, syncCache } = useContext(AppContext);
  const {
    events,
    attributes,
    fetchRecommendDeviceAttributes,
    fetchRecommendEventAttributes,
    fetchRecommendUserAttributes,
    recommendDeviceAttributes,
    recommendEventAttributes,
    recommendUserAttributes,
  } = props.data;
  const [predicate, setPredicate] = useState(predicateDefault);

  useEffect(() => {
    const currentStep = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );
    if (currentStep.event) {
      setPredicate(Object.assign({}, predicateDefault, currentStep));
    } else {
      setPredicate(
        Object.assign({}, predicateDefault, currentStep, { event: null })
      );
    }
  }, []);

  const onFinish = () => {
    const currentStep = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );

    const currentStepUpdated = Object.assign({}, currentStep, predicate);
    delete currentStepUpdated.ui?.error;
    syncCache('update-step', currentStepUpdated, true);
    // openSnackBar("success", {
    //   message: "Successfully updated",
    // });
    props.closeModal();
  };

  const handleChangePredicate = (changedPredicate) => {
    setPredicate(changedPredicate);
  };

  return (
    <>
      <Form form={form} onFinish={onFinish} className="modal_wrap">
        <div className={'modal-body'}>
          <ProductSegmentPredicateComponent
            journey={true}
            readOnly={props.readOnly}
            type="HAS_DONE_EVENT"
            predicate={predicate}
            attributes={attributes}
            events={events}
            isInSegment={false}
            handleChangePredicate={(changedPredicate) =>
              handleChangePredicate(changedPredicate)
            }
            clearSegmentError={() => {
              //TODO Must Handle
            }}
            fetchRecommendEventAttributes={fetchRecommendEventAttributes}
            recommendEventAttributes={recommendEventAttributes}
            fetchRecommendDeviceAttributes={fetchRecommendDeviceAttributes}
            recommendDeviceAttributes={recommendDeviceAttributes}
            fetchRecommendUserAttributes={fetchRecommendUserAttributes}
            recommendUserAttributes={recommendUserAttributes}
            showEventAggregation
          />
        </div>
        <div className={'modal-footer'}>
          <Form.Item>
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              disabled={props.readOnly}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
export default CondHasDoneEvent;
