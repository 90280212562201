import { CAPTCHA_TOKEN, CAPTCHA_ENABLED } from './AuthConstants';
import BaseApi from '../../../api/BaseApi';

const basePath = '/auth/authentication';

class AuthApi {
  static currentUser() {
    return BaseApi.post(basePath + '/currentUser', {});
  }
  static login(body) {
    return BaseApi.post(basePath + '/login', body);
  }
  static logout() {
    return BaseApi.post(basePath + '/logout', {});
  }
  static register(body) {
    return BaseApi.post(basePath + '/register', body);
  }
  static verify(body) {
    return BaseApi.post(basePath + '/verify', body);
  }
  static forgotPassword(body) {
    return BaseApi.post(basePath + '/forgotPassword', body);
  }
  static forgotPasswordSubmit(body) {
    return BaseApi.post(basePath + '/forgotPasswordSubmit', body);
  }

  static resendOtpCode(otpId) {
    return BaseApi.post(`/otp/resend/${otpId}`);
  }

  static loginSecondStepByOTP(body) {
    return BaseApi.post('/auth/two-factor', body);
  }

  static captcha() {
    const headers = [CAPTCHA_TOKEN, CAPTCHA_ENABLED];
    return BaseApi.getImage(basePath + '/captcha', headers);
  }
}

export default AuthApi;
