import { createSlice } from '@reduxjs/toolkit';
import { showMessageError } from '@Utils/RenderUtils';
import ThirdPartyApi from '@Redux/slices/admin/thirdPartyApi';

const initialState = {
  currentThirdPartyProductInfo: {},
  currentThirdPartyProductJourneys: [],
  thirdPartyProducts: [],
  // Pagination -->
  pageRequest: {},
  page: {},
  // Pagination <--
  loading: false,
  match: {},
};

export const thirdParty = createSlice({
  name: 'thirdParty',
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    thirdPartyListFetchSuccess(state, { payload }) {
      state.thirdPartyProducts = payload.content;
      state.number = payload.number;
      state.size = payload.size;
      state.totalElements = payload.totalElements;
      state.totalPages = payload.totalPages;
      state.page = payload;
    },
    thirdPartyViewFetchSuccess(state, { payload }) {
      state.currentThirdPartyProductInfo = {
        id: payload.productId,
        name: payload.productName,
        enabled: payload.productEnabled,
      };
      state.currentThirdPartyProductJourneys = payload.journeys;
    },
    thirdPartyViewFetchFail(state, { error }) {
      showMessageError(error);
    },
    thirdPartyUpdateEnabledFetchSuccess(state, { response }) {
      state.currentThirdPartyProductJourneys =
        state.currentThirdPartyProductJourneys.map((journey) => {
          if (journey.id === response.id) {
            return {
              ...journey,
              enabled: response.enabled,
            };
          }
          return journey;
        });
    },
    thirdPartyUpdateEnabledFetchFail(state, { error }) {
      showMessageError(error);
    },
    thirdPartyUpdateClear(state) {
      state.currentThirdPartyProduct = {};
    },
  },
});

export default thirdParty.reducer;

export const {
  setLoading,
  thirdPartyListFetchSuccess,
  thirdPartyViewFetchSuccess,
  thirdPartyViewFetchFail,
  thirdPartyUpdateEnabledFetchSuccess,
  thirdPartyUpdateEnabledFetchFail,
  thirdPartyUpdateClear,
} = thirdParty.actions;
export const thirdPartyListFetch = (productId, body) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await ThirdPartyApi.list(productId, body);
    dispatch(thirdPartyListFetchSuccess(response));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(setLoading(false));
    showMessageError(e);
  }
};

export const thirdPartyViewFetch = (thirdPartyId, body) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await ThirdPartyApi.view(thirdPartyId, body);
    dispatch(thirdPartyViewFetchSuccess(response));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(thirdPartyViewFetchFail(e));
    dispatch(setLoading(false));
  }
};

// productId is the id of parent
// id is the id of third-party
export const thirdPartyUpdateEnabledFetch =
  (productId, id, enabled, payload) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await ThirdPartyApi.updateEnabled(productId, {
        id: id,
        enabled: !enabled,
      });
      dispatch(thirdPartyUpdateEnabledFetchSuccess(response));
      dispatch(thirdPartyListFetch(productId, payload));
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(thirdPartyUpdateEnabledFetchFail(e));
      dispatch(setLoading(false));
    }
  };
