import { history } from '../../../redux/store';
import { cloneDeep, filter, isNil } from 'lodash';
import {
  USER_LIST_FETCH,
  USER_LIST_FETCH_FAIL,
  USER_LIST_FETCH_SUCCESS,
  USER_CREATE_FETCH,
  USER_CREATE_FETCH_FAIL,
  USER_CREATE_FETCH_SUCCESS,
  USER_ROLE_LIST_FETCH,
  USER_ROLE_LIST_FETCH_FAIL,
  USER_ROLE_LIST_FETCH_SUCCESS,
  USER_CHANGE_CURRENT,
  USER_UPDATE_FETCH,
  USER_UPDATE_FETCH_FAIL,
  USER_UPDATE_FETCH_SUCCESS,
  USER_UPDATE_SUBMIT_FETCH,
  USER_UPDATE_SUBMIT_FETCH_FAIL,
  USER_UPDATE_SUBMIT_FETCH_SUCCESS,
  USER_PAGE_CHANGE_CURRENT,
  USER_VIEW_FETCH,
  USER_VIEW_FETCH_SUCCESS,
  USER_VIEW_FETCH_FAIL,
  USER_UPLOAD_PICTURE_FETCH,
  USER_UPLOAD_PICTURE_FETCH_SUCCESS,
  USER_UPLOAD_PICTURE_FETCH_FAIL,
  USER_CHANGE_PASSWORD_FETCH,
  USER_CHANGE_PASSWORD_FETCH_SUCCESS,
  USER_CHANGE_PASSWORD_FETCH_FAIL,
  USER_CHANGE_PASSWORD_SUBMIT_FETCH_SUCCESS,
  USER_CHANGE_PASSWORD_SUBMIT_FETCH_FAIL,
  USER_CHANGE_PASSWORD_SUBMIT_FETCH,
  USER_CP_SERVICE_LIST_FETCH,
  USER_CP_SERVICE_LIST_FETCH_SUCCESS,
  USER_CP_SERVICE_LIST_FETCH_FAIL,
  USER_PRODUCT_LIST_FETCH,
  USER_PRODUCT_LIST_FETCH_SUCCESS,
  USER_PRODUCT_LIST_FETCH_FAIL,
  USER_SEARCH_LIST_FETCH_SUCCESS,
  USER_SEARCH_LIST_FETCH_FAIL,
  USER_SEARCH_LIST_FETCH,
  ADMIN_USER_CLEAR_ERROR,
} from './UserConstants';

import UserApi from './UserApi';

const loadUserList = (dispatch, userPageRequest) => {
  dispatch({
    type: USER_LIST_FETCH,
    payload: userPageRequest,
  });
  UserApi.list(userPageRequest)
    .then((response) => {
      dispatch({
        type: USER_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: USER_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function userListFetch(userPageRequest) {
  return (dispatch) => loadUserList(dispatch, userPageRequest);
}

const loadUserSearchList = (dispatch, userPageRequest) => {
  dispatch({
    type: USER_SEARCH_LIST_FETCH,
    payload: userPageRequest,
  });
  UserApi.list(userPageRequest)
    .then((response) => {
      dispatch({
        type: USER_SEARCH_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: USER_SEARCH_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};
export function userSearchListFetch(userPageRequest) {
  return (dispatch) => loadUserSearchList(dispatch, userPageRequest);
}
export function userRoleListFetch() {
  return (dispatch) => {
    dispatch({
      type: USER_ROLE_LIST_FETCH,
    });
    UserApi.listRoles()
      .then((response) => {
        dispatch({
          type: USER_ROLE_LIST_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: USER_ROLE_LIST_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function userProductListFetch() {
  return (dispatch) => {
    dispatch({
      type: USER_PRODUCT_LIST_FETCH,
    });
    UserApi.listProducts()
      .then((response) => {
        dispatch({
          type: USER_PRODUCT_LIST_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: USER_PRODUCT_LIST_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function userServiceListFetch() {
  return (dispatch) => {
    dispatch({
      type: USER_CP_SERVICE_LIST_FETCH,
    });
    UserApi.listServices()
      .then((response) => {
        dispatch({
          type: USER_CP_SERVICE_LIST_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: USER_CP_SERVICE_LIST_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function userChangeCurrent(userDto) {
  return (dispatch) => {
    dispatch({
      type: USER_CHANGE_CURRENT,
      payload: userDto,
    });
  };
}

export function userPageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: USER_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function userCreateFetch(userDto) {
  return (dispatch) => {
    dispatch({
      type: USER_CREATE_FETCH,
      payload: userDto,
    });
    UserApi.create(userDto)
      .then((response) => {
        dispatch({
          type: USER_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        history.push('list');
      })
      .catch((response) => {
        dispatch({
          type: USER_CREATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function userUpdateFetch(id) {
  return (dispatch) => {
    dispatch({
      type: USER_UPDATE_FETCH,
    });
    UserApi.update(id)
      .then((response) => {
        dispatch({
          type: USER_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: USER_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function userUpdateSubmitFetch(id, userDto) {
  return (dispatch) => {
    dispatch({
      type: USER_UPDATE_SUBMIT_FETCH,
      payload: userDto,
    });
    UserApi.updateSubmit(id, userDto)
      .then((response) => {
        dispatch({
          type: USER_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        history.push('../list');
      })
      .catch((response) => {
        dispatch({
          type: USER_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function userChangePasswordFetch(id, userDto) {
  return (dispatch) => {
    dispatch({
      type: USER_CHANGE_PASSWORD_FETCH,
      payload: userDto,
    });
    UserApi.changePassword(id, userDto)
      .then((response) => {
        dispatch({
          type: USER_CHANGE_PASSWORD_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: USER_CHANGE_PASSWORD_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function userChangePasswordSubmitFetch(id, userDto) {
  return (dispatch) => {
    dispatch({
      type: USER_CHANGE_PASSWORD_SUBMIT_FETCH,
      payload: userDto,
    });
    UserApi.changePasswordSubmit(id, userDto)
      .then((response) => {
        dispatch({
          type: USER_CHANGE_PASSWORD_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        history.push('../list');
      })
      .catch((response) => {
        dispatch({
          type: USER_CHANGE_PASSWORD_SUBMIT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function userViewFetch(id, userDto) {
  return (dispatch) => {
    dispatch({ type: USER_VIEW_FETCH });
    UserApi.view(id, userDto)
      .then((response) => {
        dispatch({
          type: USER_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: USER_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function userUploadPictureFetch(file) {
  return (dispatch) => {
    dispatch({ type: USER_UPLOAD_PICTURE_FETCH });
    UserApi.uploadPicture(file)
      .then((response) => {
        dispatch({
          type: USER_UPLOAD_PICTURE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: USER_UPLOAD_PICTURE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function clearAdminUserErrors(errors, errorKey = null) {
  return (dispatch) => {
    if (errorKey === null) {
      dispatch({
        type: ADMIN_USER_CLEAR_ERROR,
        payload: [],
      });
    } else {
      if (Array.isArray(errorKey) && !isNil(errorKey)) {
        let filteredErrors = cloneDeep(errors);
        for (var i = 0; i <= errorKey.length; i++) {
          filteredErrors = filter(filteredErrors, (o) => o.key !== errorKey[i]);
        }
        dispatch({
          type: ADMIN_USER_CLEAR_ERROR,
          payload: filteredErrors,
        });
      } else {
        const filteredErrors = filter(errors, (o) => o.key !== errorKey);
        dispatch({
          type: ADMIN_USER_CLEAR_ERROR,
          payload: filteredErrors,
        });
      }
    }
  };
}
