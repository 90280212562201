exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ReportCardsSection-module__headerWrapper--3cb0C {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 16px;\n}\n.ReportCardsSection-module__headerDatePicker--30Jhz {\n  width: 55%;\n  margin-right: 4px !important;\n  background-color: white !important;\n}\n.ReportCardsSection-module__headerProductSelect--2bUmd {\n  width: 150px !important;\n}\n.ReportCardsSection-module__checkListMenuWrapper--2Fu33 {\n  width: 220;\n  background-color: white;\n  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;\n  border-radius: 2;\n}\n.ReportCardsSection-module__checkListMenu--3Ek-U {\n  width: 300px;\n  max-height: 300px !important;\n  overflow-y: scroll !important;\n}\n.ReportCardsSection-module__reportCardWrapper--3Ofwj {\n  background-color: white;\n  height: 365px;\n  border-radius: 5px;\n  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);\n  padding: 20px;\n}\n.ReportCardsSection-module__reportCardHeader--xhBsq {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 16px;\n}\n.ReportCardsSection-module__reportCardTitle--3QYNs {\n  font-size: 24px;\n  font-weight: 600;\n  color: #1B1B1D;\n}\n.ReportCardsSection-module__reportCardContent--1jzZb {\n  display: grid;\n  grid-template-columns: auto auto;\n  gap: 16px;\n}\n.ReportCardsSection-module__reportCardContentTitle--2kzq2 {\n  font-size: 18px;\n  font-weight: 600;\n  color: #38373B;\n}\n.ReportCardsSection-module__reportCardContentDesc--1fbyb {\n  font-size: 12px;\n  font-weight: 500;\n  color: #767577;\n}\n.ReportCardsSection-module__spinner--20iBG {\n  margin-top: 16px !important;\n  display: flex !important;\n  justify-content: center !important;\n  align-items: center !important;\n  height: calc(100% - 20%) !important;\n}\n.ReportCardsSection-module__menuItemWrapper--2Frtp label {\n  font-size: 12px;\n}\n", ""]);

// exports
exports.locals = {
	"headerWrapper": "ReportCardsSection-module__headerWrapper--3cb0C",
	"headerDatePicker": "ReportCardsSection-module__headerDatePicker--30Jhz",
	"headerProductSelect": "ReportCardsSection-module__headerProductSelect--2bUmd",
	"checkListMenuWrapper": "ReportCardsSection-module__checkListMenuWrapper--2Fu33",
	"checkListMenu": "ReportCardsSection-module__checkListMenu--3Ek-U",
	"reportCardWrapper": "ReportCardsSection-module__reportCardWrapper--3Ofwj",
	"reportCardHeader": "ReportCardsSection-module__reportCardHeader--xhBsq",
	"reportCardTitle": "ReportCardsSection-module__reportCardTitle--3QYNs",
	"reportCardContent": "ReportCardsSection-module__reportCardContent--1jzZb",
	"reportCardContentTitle": "ReportCardsSection-module__reportCardContentTitle--2kzq2",
	"reportCardContentDesc": "ReportCardsSection-module__reportCardContentDesc--1fbyb",
	"spinner": "ReportCardsSection-module__spinner--20iBG",
	"menuItemWrapper": "ReportCardsSection-module__menuItemWrapper--2Frtp"
};