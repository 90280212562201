import Layout, { Content } from 'antd/lib/layout/layout';
import React, { createContext, useEffect, useState, useRef } from 'react';
import AppBar from './components/Atom/Appbar';
import SegmentStep from './components/Steps/Segment';
import ExitTriggerStep from './components/Steps/ExitTrigger';
import ConversionTrackingStep from './components/Steps/ConversionTracking';
import FreqDndStep from './components/Steps/FreqDnd';
import Journey from './pages/root';
import { ON_STATIC_METHODS } from './utils/static';
import PublishStep from './components/Steps/Publish';
import { Alert } from 'antd';
import _, { isEqual, cloneDeep, isNil, isEmpty, debounce } from 'lodash';

// import Report from "./pages/report";
export const AppContext = createContext({
  journey: {},
  syncCache: () => {},
  eventsContext: [],
  businessEventsContext: [],
  attributes: [],
});

export const JourneyObjectModel = {
  conversionEvent: null,
  conversionEventFilterPredicates: [],
  crc: 0,
  exitTriggers: [],
  followDnd: true,
  followFrequencyCapping: true,
  queueMinutes: 0,
  segment: null,
  status: 'DRAFT',
  gapType: 'IMMEDIATELY',
  steps: [],
  tags: [],
};

const App = (props) => {
  // BugFix: switch between new page !!!
  const JourneyObjectModel_ = cloneDeep(JourneyObjectModel);

  const { mode } = props.data;
  const [currentStep, SetCurrentStep] = useState('design');
  const [data, SetData] = useState(JourneyObjectModel_);
  const [events_, SetEvents_] = useState([]);
  const [businessEvents_, SetBusinessEvents_] = useState([]);
  const [attributes_, SetAttributes_] = useState([]);
  const [segments_, SetSegments] = useState([]);
  const [testApiData_, SetTestApiData_] = useState();
  const [segmentReportViewData, setSegmentReportViewData] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const undoableStepsStates = useRef([]);
  const undoableStepsIndex = useRef(0);
  const [loadingFitView, setLoadingFitView] = useState(true);

  const isEqualUi = (oldElement, currentElement) => {
    let onMthIsNotEqual = false;
    let uiPositionIsEqual = false;
    let uiEdgesIsEqual = false;
    let typeEqual = false;
    for (const onMth of ON_STATIC_METHODS) {
      if (
        oldElement.hasOwnProperty(onMth) &&
        currentElement.hasOwnProperty(onMth)
      ) {
        if (!isEqual(oldElement[onMth], currentElement[onMth])) {
          onMthIsNotEqual = true;
          break;
        }
      } else if (
        !oldElement.hasOwnProperty(onMth) &&
        currentElement.hasOwnProperty(onMth)
      ) {
        if (
          Array.isArray(currentElement[onMth]) &&
          currentElement[onMth].length > 0
        ) {
          onMthIsNotEqual = true;
          break;
        }
      } else if (
        oldElement.hasOwnProperty(onMth) &&
        !currentElement.hasOwnProperty(onMth)
      ) {
        if (Array.isArray(oldElement[onMth]) && oldElement[onMth].length > 0) {
          onMthIsNotEqual = true;
          break;
        }
      }
    }

    if (oldElement?.ui?.position && currentElement?.ui?.position) {
      if (isEqual(oldElement.ui.position, currentElement.ui.position)) {
        uiPositionIsEqual = true;
      }
    }

    if (
      !oldElement.ui.hasOwnProperty('edges') &&
      !currentElement.ui.hasOwnProperty('edges')
    ) {
      uiEdgesIsEqual = true;
    } else if (
      oldElement.ui.hasOwnProperty('edges') &&
      currentElement.ui.hasOwnProperty('edges')
    ) {
      if (isEqual(oldElement.ui.edges, currentElement.ui.edges)) {
        uiEdgesIsEqual = true;
      }
    } else if (
      !oldElement.ui.hasOwnProperty('edges') &&
      currentElement.ui.hasOwnProperty('edges')
    ) {
      if (isEmpty(currentElement.ui.edges)) {
        uiEdgesIsEqual = true;
      }
    } else if (
      oldElement.ui.hasOwnProperty('edges') &&
      !currentElement.ui.hasOwnProperty('edges')
    ) {
      if (isEmpty(oldElement.ui.edges)) {
        uiEdgesIsEqual = true;
      }
    }
    if (oldElement.type === currentElement.type) {
      typeEqual = true;
    }

    return uiEdgesIsEqual && uiPositionIsEqual && !onMthIsNotEqual && typeEqual;
  };

  const replaceUi = (oldElements, currentElements) => {
    const changedElements = [];
    currentElements.forEach((currentElement) => {
      const oldElement = oldElements.find(
        (oldEl) => oldEl.id + '' === currentElement.id + ''
      );
      if (!isNil(oldElement)) {
        if (isEqual(oldElement, currentElement)) {
          changedElements.push(cloneDeep(currentElement));
        } else {
          let cloned = cloneDeep(currentElement);
          if (cloned.type !== oldElement.type) {
            cloned.type = oldElement.type;
          }
          ON_STATIC_METHODS.forEach((onMth) => {
            if (cloned.hasOwnProperty(onMth)) {
              cloned[onMth] = [];
            }
            if (oldElement.hasOwnProperty(onMth)) {
              cloned[onMth] = oldElement[onMth];
            }
          });

          const clonedUi = cloned.ui;
          // replace position
          clonedUi.position = oldElement.ui.position;
          // replace edges
          delete clonedUi['edges'];
          if (
            oldElement.ui.hasOwnProperty('edges') &&
            !isEmpty(oldElement.ui.edges) &&
            !isNil(oldElement.ui.edges)
          ) {
            clonedUi.edges = oldElement.ui.edges;
          }
          // replace edges and position
          cloned = { ...cloned, ui: { ...cloned.ui, ...clonedUi } };

          changedElements.push(cloned);
        }
      }
    });
    const currIds = currentElements.map((curr) => curr.id); // old elements ids
    const oldElementsIsNotInCurrent = oldElements.filter(
      (ol) => !currIds.includes(ol.id)
    );
    oldElementsIsNotInCurrent.forEach((item) => {
      changedElements.push(cloneDeep(item));
    });
    return changedElements;
  };

  const findIndexUnEqualUi = (index, action) => {
    const currentElements = cloneDeep(
      undoableStepsStates.current[undoableStepsIndex.current]
    );
    let foundIndex = index;
    let foundUiChange = false;
    if (action === 'undo-step') {
      while (foundIndex > 0 && !foundUiChange) {
        const previousElements = undoableStepsStates.current[foundIndex];
        for (const currentElement of currentElements) {
          const previousElement = previousElements.find(
            (preEl) => preEl.id + '' === currentElement.id + ''
          );
          if (
            (isNil(previousElement) && !isNil(currentElement)) ||
            !isEqualUi(previousElement, currentElement)
          ) {
            foundUiChange = true;
            break;
          }
        }
        if (!foundUiChange) {
          foundIndex = foundIndex - 1;
        }
      }
    } else if (action === 'redo-step') {
      while (
        foundIndex < undoableStepsStates.current.length - 1 &&
        !foundUiChange
      ) {
        const previousElements = undoableStepsStates.current[foundIndex];
        for (const currentElement of currentElements) {
          const previousElement = previousElements.find(
            (preEl) => preEl.id + '' === currentElement.id + ''
          );
          if (!isEqualUi(previousElement, currentElement)) {
            foundUiChange = true;
            break;
          }
        }
        if (!foundUiChange) {
          foundIndex = foundIndex + 1;
        }
      }
    }
    return foundIndex;
  };

  const setUndoable = (value, action = null) => {
    const clonedValue = cloneDeep(value);
    if (
      isEqual(
        clonedValue,
        undoableStepsStates.current[undoableStepsStates.current.length - 1]
      )
    ) {
      return;
    }
    if (
      action === 'new-step' &&
      isNil(props.data.journeyId) &&
      isEmpty(undoableStepsStates.current) &&
      undoableStepsIndex.current === 0
    ) {
      undoableStepsStates.current.push([]);
    }
    const copy = cloneDeep(undoableStepsStates.current);
    if (!isEmpty(copy) && undoableStepsIndex.current !== 0) {
      copy.length = undoableStepsIndex.current + 1; // delete all history after index
    }
    copy.push(clonedValue);
    undoableStepsStates.current = copy;
    undoableStepsIndex.current = copy.length - 1;
  };

  const debouncedSetUndoable = debounce(setUndoable, 500);

  const setUndoableIndex = (index) => {
    if (index === undoableStepsIndex.current) {
      return;
    }
    let foundIndex = index;
    const currentElements = cloneDeep(
      undoableStepsStates.current[undoableStepsIndex.current]
    );

    if (currentElements.length === undoableStepsStates.current[index].length) {
      if (index < undoableStepsIndex.current) {
        // in case of ctrl z
        foundIndex = findIndexUnEqualUi(index, 'undo-step');
        if (foundIndex < index) {
          for (let i = foundIndex + 1; i <= index; i = i + 1) {
            undoableStepsStates.current[i] = cloneDeep(
              undoableStepsStates.current[undoableStepsIndex.current]
            );
          }
        }
      } else if (index > undoableStepsIndex.current) {
        // in case of ctrl shift z
        foundIndex = findIndexUnEqualUi(index, 'redo-step');
      }
      const foundElements = cloneDeep(undoableStepsStates.current[foundIndex]);
      const replacedUi = cloneDeep(replaceUi(foundElements, currentElements));
      undoableStepsStates.current[foundIndex] = cloneDeep(replacedUi);
      if (
        !isNil(replacedUi) &&
        !isEqual(
          replacedUi,
          undoableStepsStates.current[undoableStepsIndex.current]
        )
      ) {
        props.localUpdateSteps(replacedUi);
      }
    } else {
      let clonedValue = cloneDeep(
        replaceUi(undoableStepsStates.current[foundIndex], currentElements)
      );
      if (currentElements.length === 0) {
        clonedValue = cloneDeep(undoableStepsStates.current[foundIndex]);
      }
      undoableStepsStates.current[foundIndex] = clonedValue;
      if (!isNil(clonedValue)) {
        props.localUpdateSteps(clonedValue);
      }
    }
    undoableStepsIndex.current = foundIndex;
  };

  useEffect(() => {
    if (props.data.journeyId) {
      props.update(props.data.journeyId, mode);
    }
  }, [props.data.journeyId]);

  useEffect(() => {
    //note: Just on response of update props.data.journey filled
    if (props.data.journey) {
      SetData(Object.assign({}, props.data.journey));
      if (
        undoableStepsStates.current[0] == undefined &&
        props.data.journey?.steps &&
        Array.isArray(props.data.journey.steps)
      ) {
        undoableStepsStates.current = [cloneDeep(props.data.journey.steps)];
      }
    }
  }, [props.data.journey]);

  useEffect(() => {
    //note: Just on response of update props.data.events filled
    if (props.data.events) {
      SetEvents_(props.data.events);
    }
  }, [props.data.events]);

  useEffect(() => {
    //note: Just on response of update props.data.events filled
    if (props.data.businessEvents) {
      SetBusinessEvents_(props.data.businessEvents);
    }
  }, [props.data.businessEvents]);

  useEffect(() => {
    //note: Just on response of update props.data.events filled
    if (props.data.attributes) {
      SetAttributes_(props.data.attributes);
    }
  }, [props.data.attributes]);

  useEffect(() => {
    //note: Just on response of update props.data.events filled
    if (props.data.segments) {
      SetSegments(props.data.segments);
    }
  }, [props.data.segments]);

  useEffect(() => {
    //note: Just on response of update props.data.events filled
    if (props.data.testApiData) {
      SetTestApiData_(props.data.testApiData);
    }
  }, [props.data.testApiData]);

  useEffect(() => {
    if (props.data.segmentReport) {
      setSegmentReportViewData(props.data.segmentReport);
    }
  }, [props.data.segmentReport]);

  /** Handle errors
   - Description: Handle server error
   */
  useEffect(() => {
    if (props.errors?.launch && data.steps) {
      const { status, errors } = props.errors?.launch;
      // NOT_ACCEPTABLE
      if (status === 'NOT_ACCEPTABLE') {
        const hasStepError = (stepIdParam) => {
          const result = errors.filter((error) => {
            const paragraph = error.key; //'steps[0].event';
            const regex = /[0-9]+/g;
            const stepId = paragraph.match(regex);
            if (stepId?.length) {
              if (stepIdParam + '' === stepId[0] + '') {
                return true;
              }
            }
          });
          return result;
        };
        const dataSteps = data?.steps?.map((step, i) => {
          // const hasStepError_ = hasStepError(step.id)
          const hasStepError_ = hasStepError(i);

          if (hasStepError_.length) {
            step.ui.error = `${hasStepError_.length} error(s) occurred`;
          } else if (step.ui.error) {
            delete step.ui.error;
          }
          return step;
        });

        const newData = Object.assign({}, data, { steps: dataSteps });
        setTimeout(() => syncCache('group-update', newData), 500);
      }
      // SetData(props.data.journey);
    }
  }, [props.errors?.launch]);

  /** CACHE MANGER
   - Description: Specifically use the function for update or sync cache by journey graph data.
   -              If needed force sync by remote can call remote api like delete-step
   */
  const syncCache = (
    action,
    info,
    remoteSave = false,
    callBack,
    callBackData = null
  ) => {
    if (action === 'undo-step') {
      setUndoableIndex(Math.max(0, undoableStepsIndex.current - 1));
    } else if (action === 'redo-step') {
      setUndoableIndex(
        Math.min(
          undoableStepsIndex.current + 1,
          undoableStepsStates.current.length - 1
        )
      );
    } else if (action === 'new-step') {
      let dataSteps = data.steps;
      let isExist = dataSteps.filter((stp) => stp.id === info.id);
      if (isExist.length > 0) return;

      // check duplicate
      dataSteps.push(info);
      let isDuplicate = false;
      debouncedSetUndoable(dataSteps, action);
      dataSteps.forEach((stp) => {
        let countSteps = dataSteps.filter((_stp) => _stp.id === stp.id); // todo check this
        if (countSteps.length > 1) {
          isDuplicate = true;
        }
      });
      if (isDuplicate) return;

      SetData(Object.assign({}, data, { steps: dataSteps }));
    } else if (action === 'update-step') {
      if (info) {
        const stepId = info.id;
        const data_ = callBackData || data;
        const stepsI = data_?.steps.map((step) => {
          if (_.isArray(info)) {
            // support batch
            const fIndex = info.findIndex((i) => i.id === step.id);
            if (fIndex >= 0) {
              return info[fIndex];
            }
          } else {
            if (step.id + '' === stepId + '') {
              return info;
            }
          }
          return step;
        });
        const dataPrime = Object.assign({}, data_, { steps: stepsI });
        debouncedSetUndoable(stepsI, action);
        if (callBack) {
          callBack(dataPrime);
        } else {
          SetData(dataPrime);
        }
        if (remoteSave) {
          if (props.data.journeyId)
            setTimeout(() => {
              props.updateSubmit({
                id: props.data.journeyId,
                body: dataPrime,
              });
            }, 1000);
        }
      }
    } else if (action === 'update-segment') {
      SetData((old) => Object.assign({}, old, { segment: info }));
    } else if (action === 'delete-step') {
      const stepId = info.id; // must be delete
      const tempStepIds = [].concat(stepId || []);
      const stepIds = tempStepIds.map((stepId) => stepId + '');
      const removeStep = async () => {
        const tempData = await new Promise((resolve) => {
          SetData((data_) => {
            resolve(data_);
            return data_;
          });
        });

        // 1- remove step from steps
        tempData['steps'] = tempData?.steps.filter(
          (step) => !stepIds.includes(step.id + '')
        );

        // 2- clear edges from step
        tempData['steps'] = tempData?.steps.map((step) => {
          let step_ = Object.assign({}, step);
          let edges = Object.assign({}, step?.ui?.edges);
          let methods = {};

          ON_STATIC_METHODS.forEach((mth) => {
            if (step[mth]?.length) {
              // clear from root
              let new_mth = step[mth].filter(
                (id) => !stepIds.includes(id + '')
              );
              methods[mth] = new_mth;
              // clear from ui.edges
              Object.keys(step?.ui?.edges || {}).forEach((edgeKey) => {
                if (
                  stepIds.includes(edges[edgeKey]?.source + '') ||
                  stepIds.includes(edges[edgeKey]?.target + '')
                ) {
                  delete edges[edgeKey];
                }
              });
            }
          });
          step_ = Object.assign({}, step, methods, {
            ui: Object.assign({}, step.ui, { edges: edges }),
          });
          // {[mth]: new_mth, ui: Object.assign({}, step.ui, {edges: edges})})

          return step_;
        });
        debouncedSetUndoable(tempData.steps);
        if (callBack) {
          const dataPromise = new Promise((resolve) => {
            SetData(() => {
              resolve(tempData);
              return tempData;
            });
          });
          dataPromise.then((r) => {
            callBack(r);
          });
        } else {
          SetData(tempData);
        }
        if (props.data.journeyId && remoteSave) {
          props.updateSubmit({
            id: props.data.journeyId,
            body: Object.assign({}, tempData),
            typeRequest: 'DELETE',
          });
        }
      };

      /* *
          TODO For Next Remove this node has an effect on other block(s),
          Blocks with following type like: SEND_SMS, SEND_EMAIL
      */
      removeStep();
    } else if (action === 'update-step-when-duplicate') {
      const stepIds = info.map((item) => item.id + '');
      let tempData = data;
      // remove old step
      tempData['steps'] = data?.steps.filter(
        (step) => !stepIds.includes(step.id + '')
      );
      // update step by new info
      tempData['steps'] = [...tempData['steps'], ...info];
      debouncedSetUndoable(tempData.steps);
      SetData(tempData);
      if (props.data.journeyId) {
        props.updateSubmit({
          id: props.data.journeyId,
          body: Object.assign({}, tempData),
        });
      }
    } else if (action === 'delete-edge') {
      const edge = info.edge; // must be delete

      const removeEdge = () => {
        let tempData = data;
        // clear edges from steps that included
        tempData['steps'] = data?.steps.map((step) => {
          let step_ = Object.assign({}, step);
          let edges = Object.assign({}, step?.ui?.edges);
          let methods = {};

          if (Number(step_.id) === Number(edge.source)) {
            // 1- remove edge from steps
            let new_targets = step[edge.sourceHandle].filter(
              (id) => id + '' !== edge.target + ''
            );
            methods[edge.sourceHandle] = new_targets;

            // 2- clear edges from ui.edges
            Object.keys(step?.ui?.edges || {}).forEach((edgeKey) => {
              if (edges[edgeKey].id + '' === edge.id + '') {
                delete edges[edgeKey];
              }
            });
          }

          step_ = Object.assign({}, step, methods, {
            ui: Object.assign({}, step.ui, { edges: edges }),
          });

          return step_;
        });
        debouncedSetUndoable(tempData.steps);
        // return tempData;
        if (callBack) {
          callBack(tempData);
        } else {
          SetData(tempData);
        }
        if (props.data.journeyId && remoteSave) {
          props.updateSubmit({
            id: props.data.journeyId,
            body: Object.assign({}, tempData),
          });
        }
      };

      removeEdge();
    } else if (action === 'group-update') {
      SetData((old) => Object.assign({}, old, info));
    } else if (action) {
      SetData((old) => Object.assign({}, old, { [action]: info }));
    }
  };

  const findTargetsOfEdge_ = (methodType, step) => {
    const targets = [];
    Object.keys(step.ui?.edges || {}).forEach((edgeKey) => {
      const edge_ = step.ui.edges[edgeKey];
      if (edge_.label === methodType) {
        targets.push(edge_.target);
      }
    });
    return targets;
  };

  const refineryCache = () => {
    let countServerEdge = 0;
    let countUiEdge = 0;
    const steps = data.steps.map((step) => {
      ON_STATIC_METHODS.forEach((onMethod) => {
        countServerEdge += step[onMethod]?.length || 0;
      });
      countUiEdge += Object.keys(step.ui?.edges || {}).length || 0;
      delete step.ui?.error;
      if (countUiEdge !== countServerEdge) {
        let step_ = Object.assign({}, step);
        if (countServerEdge > countUiEdge) {
          // clean and replace again edges
          ON_STATIC_METHODS.forEach((onMethod) => {
            // eslint-disable-next-line no-prototype-builtins
            if (step_.hasOwnProperty(onMethod)) {
              step_[onMethod] = findTargetsOfEdge_(onMethod, step_);
            }
          });
        }
        countServerEdge = countUiEdge;
        return step_;
      } else {
        return step;
      }
    });
    const data_ = Object.assign({}, data, { steps });
    SetData(data_);
    return { countServerEdge, countUiEdge, data: data_ };
  };

  return (
    <>
      <Layout>
        <AppContext.Provider
          value={{
            journey: data,
            syncCache,
            eventsContext: events_,
            businessEventsContext: businessEvents_,
            attributesContext: attributes_,
            segmentsContext: segments_,
            testApiDataContext: testApiData_,
            segmentReportViewContext: segmentReportViewData,
            loading: props.loading,
          }}
        >
          {/* <Header> */}
          <AppBar
            goNextStep={SetCurrentStep}
            currentStep={currentStep}
            refineryCache={refineryCache}
            {...props}
            loadingFitView={loadingFitView}
          />
          {showAlert ? (
            <Alert message={showAlert.message} type={showAlert.type} />
          ) : (
            ''
          )}

          {/* </Header> */}
          <Layout>
            <Content className="journey-wrapper">
              <Journey
                {...props}
                clearModalData={() => {
                  SetTestApiData_();
                  props.clearModalData();
                }}
                readOnly={mode === 'report'}
                currentStep={currentStep}
                showAlert={(alertInfo) => setShowAlert(alertInfo)}
                setLoadingFitView={setLoadingFitView}
                loadingFitView={loadingFitView}
              />
              <SegmentStep {...props} currentStep={currentStep} />
              <ExitTriggerStep {...props} currentStep={currentStep} />
              <ConversionTrackingStep {...props} currentStep={currentStep} />
              <FreqDndStep {...props} currentStep={currentStep} />
              <PublishStep
                goNextStep={SetCurrentStep}
                {...props}
                currentStep={currentStep}
              />
            </Content>
          </Layout>
        </AppContext.Provider>
        {/* {currentPath === "/report" && <Report />} */}
      </Layout>
    </>
  );
};

export default App;
