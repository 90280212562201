import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import FormBase from '../../../../component/form/FormBase';
import { notification } from 'antd';
import { isEmpty } from 'lodash';
import { CloseCircleOutlined } from '@ant-design/icons';
import { passwordValidation } from '@Utils/PasswordValidation';
import Card from '@Utils/Card';

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    width: 120,
    display: 'flex',
    color: '#9a9ca3',
    fontSize: 14,
    margin: '-18px 10px 0 10px',
  },
  row: {
    direction: 'ltr',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  box: {
    padding: 30,
  },
};

class ProfileChangePasswordComponent extends React.Component {
  componentDidMount() {
    this.props.profileChangePasswordFetch();
  }

  componentWillUnmount() {}

  renderPassword = () => {
    return (
      <div>
        <Card loading={this.props.loadingUpdatePassword} styles={styles.box}>
          <>
            <div style={styles.row}>
              <div style={styles.title}>OLD PASSWORD</div>
              <Form.Item>
                <Input.Password
                  name="password"
                  placeholder="Your old password"
                  autoComplete="new-password"
                  errorText={this.props.errors.oldPassword}
                  value={this.props.currentPassword.oldPassword}
                  onChange={(e) =>
                    this.props.passwordChangeCurrent({
                      ...this.props.currentPassword,
                      oldPassword: e.target.value,
                    })
                  }
                  style={{ width: 400, marginLeft: 90 }}
                />
              </Form.Item>
            </div>
            <div style={styles.row}>
              <div style={styles.title}>NEW PASSWORD</div>
              <Form.Item
                validateStatus={
                  passwordValidation(this.props.currentPassword.inputPassword)
                    ? 'error'
                    : null
                }
                help={passwordValidation(
                  this.props.currentPassword.inputPassword,
                  'profile'
                )}
              >
                <Input.Password
                  autoComplete="new-password"
                  placeholder="New password (at least 8 characters)"
                  errorText={this.props.errors.inputPassword}
                  value={this.props.currentPassword.inputPassword}
                  onChange={(e) =>
                    this.props.passwordChangeCurrent({
                      ...this.props.currentPassword,
                      inputPassword: e.target.value,
                    })
                  }
                  style={{ width: 400, marginLeft: 90 }}
                />
              </Form.Item>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 30,
              }}
            >
              <Link to={'/account/profile/update'}>
                <Button
                  type="primary"
                  size={'large'}
                  shape="round"
                  className="w-200"
                >
                  {' '}
                  Update Profile
                </Button>{' '}
              </Link>
              <Button
                type="primary"
                shape="round"
                className="w-200"
                size={'large'}
                loading={this.props.loadingUpdatePasswordSubmit}
                disabled={
                  !!passwordValidation(this.props.currentPassword.inputPassword)
                }
                onClick={() => {
                  if (
                    isEmpty(this.props.currentPassword.oldPassword) ||
                    isEmpty(this.props.currentPassword.inputPassword)
                  ) {
                    notification.open({
                      placement: 'bottomRight',
                      description: 'Please fill the required fields',
                      icon: <CloseCircleOutlined style={{ color: 'red' }} />,
                    });
                    return;
                  }
                  this.props.profileChangePasswordSubmitFetch(
                    this.props.currentPassword
                  );
                }}
              >
                SAVE
              </Button>
            </div>
          </>
        </Card>
      </div>
    );
  };

  renderOldPassword = () => {
    return (
      <div>
        <FormBase
          errors={this.props.errors}
          value={this.props.currentProfile}
          onFinish={(values) => {
            this.props.profileChangePasswordSubmitFetch(values);
          }}
          onChange={(values) => this.props.profileChangeCurrent(values)}
          back="view"
        >
          <Form.Item label="Email" name="email">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item label="Old Password" name="oldPassword">
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <Form.Item label="New Password" name="inputPassword">
            <Input.Password autoComplete="new-password" />
          </Form.Item>
        </FormBase>
      </div>
    );
  };

  render() {
    return this.renderPassword();
  }
}

export default ProfileChangePasswordComponent;
