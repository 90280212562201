import BaseApi from '../../../api/BaseApi';

const basePathFile = '/data/uploadCsv';

class UploadDataCsvApi {
  static uploadFile(file) {
    return BaseApi.uploadFile('/base/file/upload', file);
  }

  static createUsers(body) {
    return BaseApi.post(basePathFile + '/createUsersViaCsv', body);
  }
  static listAttributes(body) {
    return BaseApi.post(basePathFile + '/listAttributes', body);
  }
  static createEvent(body) {
    return BaseApi.post(basePathFile + '/createEventsViaCsv', body);
  }
  static listEvents(body) {
    return BaseApi.post(basePathFile + '/listEvents', body);
  }
  static fileProgressList(body) {
    return BaseApi.post('/data/fileProgress/list', body);
  }
}
export default UploadDataCsvApi;
