import React from 'react';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import { history } from '../../../../redux/store';
import {
  AuthorityProvider,
  hasAnyAuthority,
} from '../../../../utils/AuthorityProvider';
import { viewBoolean } from '../../../../utils/BooleanUtils';
import { Button, Input, Spin, Modal, notification } from 'antd';
import { isEmpty } from 'lodash';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import Card from '@Utils/Card';
const styles = {
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  loadingSpinStyle: {
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: 'white',
    flexDirection: 'column',
    padding: '80px 28px',
    borderRadius: 5,
    marginBottom: 30,
  },
};

class AdminProductListComponent extends React.Component {
  state = {
    name: '',
    productIdToDelete: null,
    confirmedProductId: null,
  };
  componentDidMount() {
    this.props.adminProductListFetch({
      ...this.props.pageRequest,
      name: null,
    });
  }

  viewItem(id) {
    if (hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_PRODUCT_VIEW)) {
      history.push(`view/${id}`);
    }
  }

  getNavigation() {
    return [
      { name: 'Home', path: '/' },
      { name: 'Admin Product List', path: '' },
    ];
  }
  handleChangeProductNameSearch = (name) => {
    this.setState({ name });
    if (name === '' || name === null) {
      this.props.adminProductListFetch({
        ...this.props.pageRequest,
        name: null,
      });
    }
  };
  render() {
    const columnList = [
      {
        sorter: false,
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: '3%',
      },
      {
        sorter: false,
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (
          <a onClick={() => this.viewItem(record.id)}>{text}</a>
        ),
        width: '47%',
        // ...getColumnTextSearchProps('name'),
      },
      {
        sorter: false,
        title: 'Enabled',
        dataIndex: 'enabled',
        key: 'enabled',
        render: (x) => viewBoolean(x),
        // ...getColumnBooleanSearchProps('enabled'),
      },
      {
        sorter: false,
        title: 'Demo',
        dataIndex: 'demo',
        key: 'demo',
        render: (x) => viewBoolean(x),
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '-action-',
        width: '5%',
        render: (x) =>
          defaultActions(
            x,
            {
              link: 'view/' + x.id,
              title: 'View',
              enabled: hasAnyAuthority(
                AuthorityProvider.ROLE_ADMIN_PRODUCT_VIEW
              ),
            },
            {
              link: 'update/' + x.id + '/product',
              title: 'Edit',
              enabled: hasAnyAuthority(
                AuthorityProvider.ROLE_ADMIN_PRODUCT_UPDATE
              ),
            },
            {
              link: 'update/' + x.id + '/contract',
              title: 'Contract',
              enabled: hasAnyAuthority(
                AuthorityProvider.ROLE_CONTRACT_VIEW,
                AuthorityProvider.ROLE_CONTRACT_LIST
              ),
            },
            {
              action: () => {
                this.setState({ productIdToDelete: x.id });
              },
              title: 'DELETE',
              enabled: hasAnyAuthority(
                AuthorityProvider.ROLE_ADMIN_PRODUCT_DELETE
              ),
            }
          ),
      },
    ];
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    return (
      <>
        <div>
          <Button
            type="primary"
            size={'large'}
            loading={this.props.loading}
            style={{ marginBottom: 15 }}
            onClick={() => this.props.adminProductIndexAllFetch()}
          >
            Index All Products
          </Button>
          <Card>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '12px',
                borderBottom: 'solid 2px #f4f9ff',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'start' }}>
                <Input
                  loading={true}
                  allowClear={
                    this.props.loadingSearchProductNameList ? false : true
                  }
                  placeholder={'Search by product name'}
                  onPressEnter={() =>
                    this.props.adminProductListFetch({
                      ...this.props.pageRequest,
                      page: 0,
                      name: this.state.name,
                    })
                  }
                  onChange={(e) =>
                    this.handleChangeProductNameSearch(e.target.value)
                  }
                  value={this.state.name}
                  style={{ width: 300, marginRight: 30, borderRadius: 5 }}
                  suffix={
                    isEmpty(this.state.name) ? (
                      <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    ) : (
                      this.props.loadingSearchProductNameList && (
                        <div style={styles.inputLoadingSpinStyle}>
                          <Spin indicator={inputLoadingSpin} />
                        </div>
                      )
                    )
                  }
                />
              </div>
            </div>
            {this.props.loadingProductNameList ? (
              <div style={styles.loadingSpinStyle}>
                <Spin indicator={loadingSpin} />
              </div>
            ) : (
              <ListBase
                onChange={this.props.adminProductListFetch}
                page={this.props.page}
                pageRequest={this.props.pageRequest}
                columns={columnList}
                // onClick={(row) => this.viewItem(row.id)}
                createLink={
                  hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_PRODUCT_CREATE)
                    ? 'create'
                    : null
                }
              />
            )}
          </Card>
        </div>
        <Modal
          title="Basic Modal"
          visible={this.state.productIdToDelete}
          onOk={() => {
            if (this.state.productIdToDelete == this.state.confirmedProductId) {
              this.props.adminProductDelete(
                this.state.confirmedProductId,
                (success) => {
                  if (success) {
                    notification['success']({
                      message: 'Success',
                      description: 'product will be deleted in a few minutes.',
                    });
                  } else {
                    notification['error']({
                      message: 'Error',
                      description:
                        'something went wrong, please try again later',
                    });
                  }
                }
              );
              this.setState({
                productIdToDelete: null,
                confirmedProductId: null,
              });
            } else {
              this.setState({
                productIdToDelete: null,
                confirmedProductId: null,
              });
            }
          }}
          onCancel={() => {
            this.setState({ productIdToDelete: null });
          }}
        >
          <p>If you want to delete this product, please enter its product id</p>
          <Input
            value={this.state.confirmedProductId}
            onChange={(e) => {
              this.setState({ confirmedProductId: e.target.value });
            }}
          />
        </Modal>
      </>
    );
  }
}

export default AdminProductListComponent;
