import CustomEventListComponent from '../component/CustomEventListComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  customEventPageChangeCurrent,
  customEventSearchListFetch,
  customEventListFetch,
  customEventDeleteFetch,
  customEventUpdateFetch,
  customEventChangeCurrent,
  customEventUpdateSubmitFetch,
  costumEventMissMatchReport,
  customEventMisMatchChangeCurrent,
} from '@Redux/slices/data/customEventSlice';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  page: state.dataModule.customEvent.page,
  pageRequest: state.dataModule.customEvent.pageRequest,
  loadingUpdateSubmit: state.dataModule.customEvent.loadingUpdateSubmit,
  loadingUpdate: state.dataModule.customEvent.loadingUpdate,
  currentCustomEvent: state.dataModule.customEvent.currentCustomEvent,
  loadingList: state.dataModule.customEvent.loadingList,
  loadingSearchList: state.dataModule.customEvent.loadingSearchList,
  loadingDelete: state.dataModule.customEvent.loadingDelete,
  loadingMissMatch: state.dataModule.customEvent.loadingMissMatch,
  customEventMissMatchFilter:
    state.dataModule.customEvent.customEventMissMatchFilter,
  // customEventMissMatch: state.dataModule.customEvent.customEventMissMatch,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      customEventPageChangeCurrent,
      customEventListFetch,
      customEventSearchListFetch,
      customEventDeleteFetch,
      customEventUpdateFetch,
      customEventChangeCurrent,
      customEventUpdateSubmitFetch,
      costumEventMissMatchReport,
      customEventMisMatchChangeCurrent,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomEventListComponent);

CustomEventListComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  customEventListFetch: PropTypes.func.isRequired,
  customEventDeleteFetch: PropTypes.func.isRequired,
  customEventPageChangeCurrent: PropTypes.func.isRequired,
  costumEventMissMatchReport: PropTypes.func.isRequired,
};
