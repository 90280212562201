/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { AppContext } from '../../app';
import { Button, Select, Form, Radio, Alert } from 'antd';
import openSnackBar from '../Atom/SnackBar';

import { Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import texts from '../../../../utils/texts';
const TrSpecificUsers = (props) => {
  const [form] = Form.useForm();
  const { journey, syncCache } = useContext(AppContext);
  const [fileUploadId, setFileUploadId] = useState();
  const [fileUploadPath, setFileUploadPath] = useState();
  const [loading, setLoading] = useState(true);
  const { events, attributes } = props.data;
  const attributes_sub_ = attributes.filter((attr) =>
    ['email', 'phone', 'userId'].includes(attr.name)
  );
  const currentStep = journey.steps.find(
    (step) => step.id + '' === props.node.id + ''
  );
  const attr = attributes.find((ev) => ev.id === currentStep.staticAttribute);
  const [selectedValue, setSelectedValue] = useState(attr && attr.name);
  const [collapseField, setCollapseField] = useState(
    currentStep.collapseField || null
  );

  let uploadProps = {
    name: 'file',
    action: '/api/web/base/file/upload',
    maxCount: 1,
    accept: '.csv, .txt, .xls, xlsx',
    beforeUpload: (file) => {
      const isCsv =
        file.type.includes('csv') ||
        file.type.includes('excel') ||
        file.type.includes('text');
      if (!isCsv) {
        message.error(`${file.name} is not a csv file`);
      }
      // return file.type === 'text/csv' ? true : Upload.LIST_IGNORE;
      return isCsv ? true : Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info);
      if (info.file.status === 'done') {
        setFileUploadId(info.file.response.result.id);
        setFileUploadPath(info.file.response.result.path);
      }
    },
  };

  if (currentStep.staticFile) {
    const name_ = decodeURI(currentStep.staticFile.split('/').pop());
    uploadProps['defaultFileList'] = [
      {
        uid: '1',
        name: name_,
        status: 'done',
        url: currentStep.staticFile,
      },
    ];
  }

  useEffect(() => {
    if (currentStep.staticFile) {
      setFileUploadPath(currentStep.staticFile);
    }
    setLoading(false);
  }, []);

  const collapseFieldsOptions = useCallback(() => {
    const temp_ = [{ value: null, title: 'Nothing' }];
    attributes?.filter((attr) => {
      if (attr.name === 'phone' || attr.name === 'email') {
        temp_.push({
          title: attr.title,
          value: attr.id,
        });
      }
    });
    return temp_;
  }, [attributes]);

  const onSelectChange = () => {};

  const onFinish = (e) => {
    // return
    let currentStep = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );
    const attr = attributes.find((ev) => ev.name === e.triggerAttr);
    currentStep['staticAttribute'] = attr.id;
    currentStep['collapseField'] = collapseField;
    if (!fileUploadPath) {
      openSnackBar('error', {
        message: 'Please select a file',
      });
      return;
    }
    currentStep['staticFile'] = fileUploadPath;
    // clean if has error
    delete currentStep.ui?.error;
    syncCache('update-step', currentStep, true);

    // openSnackBar("success", {
    //   message: "Successfully updated",
    // });
    props.closeModal();
  };

  const onChangeCollapseField = (value) => {
    setCollapseField(value);
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout={'horizontal'}
        className="modal_wrap"
        initialValues={{
          triggerAttr: selectedValue,
        }}
      >
        <div className={'modal-body'}>
          <Alert
            message={`Please ensure that the user IDs in your CSV file are those of existing users only. The journey will NOT get triggered for those users who don’t currently exist in your ${texts.brandName} project. Also, note that the process of triggering journeys through this trigger is not instantaneous and can take a few minutes based on the number of users.`}
            type="info"
          />
          <br />
          <br />
          {!loading && (
            <Form.Item
              label="Select a type"
              name="triggerAttr"
              rules={[{ required: true, message: 'Please add a type!' }]}
            >
              <Select
                showSearch
                style={{ width: 300 }}
                placeholder="Select a type"
                onChange={onSelectChange}
                defaultValue={selectedValue}
                allowClear
                disabled={props.readOnly}
              >
                {attributes_sub_?.map((item) => (
                  <Select.Option key={item.id} value={item.name}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item label="TRIGGER JOURNEY FOR" name="triggerFor">
            <Radio checked>Users in CSV file</Radio>
          </Form.Item>

          <Form.Item label="LIST OF USERS" name="listUsers">
            {/* <Button onClick={onUploadFile} 
                className={"style-button"} 
                disabled={props.loading}>SELECT FILE</Button> */}
            <Upload {...uploadProps}>
              <Button
                shape="round"
                icon={<UploadOutlined />}
                style={{ marginLeft: 20 }}
                ghost
              >
                SELECT FILE
              </Button>
            </Upload>
          </Form.Item>
          <div className={'csv__caption-size'}>
            Maximum file size: 50 MB. File format: CSV file with single user
            attribute on each row. Start entering values from the first
            row.&nbsp;
            <a href="/files/user_ids_sample.csv" download>
              Download sample CSV
            </a>{' '}
            file to see format.
          </div>
          <div>
            <br />
            <p>Remove duplicate with:</p>
            <Select
              disabled={props.readOnly}
              style={{ width: 300 }}
              value={collapseField}
              onChange={onChangeCollapseField}
              showSearch
              placeholder="Select option"
            >
              {collapseFieldsOptions()?.map((option, k) => (
                <Select.Option key={k} value={option.value}>
                  {option.title}
                </Select.Option>
              ))}
            </Select>
          </div>
          {/* <IconFilter className={"icon-sm"} /> */}
        </div>
        <div className={'modal-footer'}>
          <Form.Item>
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              disabled={props.readOnly}
            >
              Save and Return to Journey
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
export default TrSpecificUsers;
