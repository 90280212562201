import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import GatewayModalContent from './GatewayModalContent';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGatewayPayment } from '@Redux/slices/setting/billingSlice';

const GatewayModal = ({ isOpen, onClose, gatewayModalInfo, gateway }) => {
  const [currentGateway, setCurrentGateway] = useState('parsian');
  const loading = useSelector((state) => state.setting.billing.gatewayLoading);

  const dispatch = useDispatch();

  return (
    <Modal
      width={650}
      title={'Receipt Payment'}
      centered
      visible={isOpen}
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <Button key="cancel" shape="round" onClick={onClose}>
          Discard
        </Button>,
        <Button
          key="submit"
          shape="round"
          type="primary"
          loading={loading}
          onClick={() => {
            dispatch(
              fetchGatewayPayment(gatewayModalInfo.id, currentGateway, onClose)
            );
          }}
        >
          Submit
        </Button>,
      ]}
    >
      <GatewayModalContent
        gatewayModalInfo={gatewayModalInfo}
        currentGateway={currentGateway}
        changeGateway={() => setCurrentGateway(gateway)}
      />
    </Modal>
  );
};

export default GatewayModal;
