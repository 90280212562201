import CommunicationTestComponent from '../component/CommunicationTestComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  communicationPageChangeCurrent,
  communicationListSegmentsFetch,
  communicationTestFetch,
  communicationUpdateConversionFetch,
  communicationDraftSaveDiscard,
  communicationDraftSave,
  communicationAudienceSegmentReportFetch,
  segmentReportCurrent,
} from '../CommunicationActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  page: state.engage.communication.page,
  pageRequest: state.engage.communication.pageRequest,
  loadingTest: state.engage.communication.loadingTest,
  loadingSegment: state.engage.communication.loadingSegment,
  segments: state.engage.communication.segments,
  loadingUpdateConversion: state.engage.communication.loadingUpdateConversion,
  currentConversion: state.engage.communication.currentConversion,
  communicationData: state.engage.communication.communicationData,
  currentCommunication: state.engage.communication.currentCommunication,
  communicationDraft: state.engage.communication.communicationDraft,
  pathname: state.router.location.pathname,
  segmentReport: state.engage.communication.segmentReport,
  loadingReportView: state.engage.communication.loadingSegmentReport,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      segmentReportCurrent,
      communicationAudienceSegmentReportFetch,
      communicationDraftSaveDiscard,
      communicationDraftSave,
      communicationPageChangeCurrent,
      communicationListSegmentsFetch,
      communicationTestFetch,
      communicationUpdateConversionFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationTestComponent);

CommunicationTestComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  communicationListSegmentsFetch: PropTypes.func.isRequired,
  communicationTestFetch: PropTypes.func.isRequired,
};
