exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductSettingThrottlingComponent-module__loadingSpinStyle--3pInZ {\n  height: 180px;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  padding-top: 10px;\n  padding-bottom: 10px;\n}\n#w-33 .ant-form-item-with-help {\n  margin-bottom: 0 !important;\n}\n#w-33 .ant-table-cell {\n  width: 33% !important;\n}\n#w-33 .ant-input-number-suffix {\n  right: 17px !important;\n}\n#w-33 .ant-input-number-affix-wrapper {\n  width: 125px !important;\n}\n", ""]);

// exports
exports.locals = {
	"loadingSpinStyle": "ProductSettingThrottlingComponent-module__loadingSpinStyle--3pInZ"
};