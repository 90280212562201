import CommunicationPreviewComponent from '../component/CommunicationPreviewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  communicationViewFetch,
  communicationListSegmentsFetch,
  communicationListSmsChannelsFetch,
  communicationListPushChannelsFetch,
  communicationListEmailChannelsFetch,
  communicationListEventsFetch,
  communicationLaunchFetch,
  communicationListTagsFetch,
  communicationListCustomChannelsFetch,
  whatsappChannelTemplateViewFetch,
} from '../CommunicationActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentCommunication: state.engage.communication.currentCommunication,
  loadingView: state.engage.communication.loadingView,
  loadingSegment: state.engage.communication.loadingSegment,
  segments: state.engage.communication.segments,
  loadingEvents: state.engage.communication.loadingEvents,
  loadingSmsChannels: state.engage.communication.loadingSmsChannels,
  loadingPushChannels: state.engage.communication.loadingPushChannels,
  loadingEmailChannels: state.engage.communication.loadingEmailChannels,
  communicationData: state.engage.communication.communicationData,
  loadingLaunch: state.engage.communication.loadingLaunch,
  emailChannels: state.engage.communication.emailChannels,
  pushChannels: state.engage.communication.pushChannels,
  smsChannels: state.engage.communication.smsChannels,
  customChannels: state.engage.communication.customChannels,
  eventList: state.engage.communication.eventList,
  loadingListTags: state.engage.communication.loadingListTags,
  listTags: state.engage.communication.listTags,
  pathname: state.router.location.pathname,
  userProducts: state.account.auth.currentUser.products,
  whatsAppTemplateObject: state.engage.communication.whatsAppTemplateObject,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      communicationViewFetch,
      communicationListSegmentsFetch,
      communicationListSmsChannelsFetch,
      communicationListPushChannelsFetch,
      communicationListEmailChannelsFetch,
      communicationListEventsFetch,
      communicationLaunchFetch,
      communicationListTagsFetch,
      communicationListCustomChannelsFetch,
      whatsappChannelTemplateViewFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationPreviewComponent);

CommunicationPreviewComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  communicationViewFetch: PropTypes.func.isRequired,
};
