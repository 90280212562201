import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import RoleListContainer from './container/RoleListContainer';
import RoleUpdateContainer from './container/RoleUpdateContainer';
import RoleViewContainer from './container/RoleViewContainer';

function Role() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}list`}>
        <RoleListContainer />
      </Route>
      <Route path={`${match.path}create`}>
        <RoleUpdateContainer />
      </Route>
      <Route path={`${match.path}copy/:copyId`}>
        <RoleUpdateContainer />
      </Route>
      <Route path={`${match.path}update/:id`}>
        <RoleUpdateContainer />
      </Route>
      <Route path={`${match.path}view/:id`}>
        <RoleViewContainer />
      </Route>
    </Switch>
  );
}

export default Role;
