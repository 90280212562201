import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router';
import MembersViewContainer from './container/MembersViewContainer';
import MembersUpdateContainer from './container/MembersUpdateContainer';
import RolesViewContainer from './container/RolesViewContainer';
import RolesUpdateContainer from './container/RolesUpdateContainer';

function TeamMembers() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}members`}>
        <MembersViewContainer />
      </Route>
      <Route path={`${match.path}members/update/:id`}>
        <MembersUpdateContainer />
      </Route>
      <Route path={`${match.path}members/create`}>
        <MembersUpdateContainer />
      </Route>
      <Route path={`${match.path}roles`}>
        <RolesViewContainer />
      </Route>
      <Route path={`${match.path}roles/create`}>
        <RolesUpdateContainer />
      </Route>
      <Route path={`${match.path}roles/update/:id`}>
        <RolesUpdateContainer />
      </Route>
    </Switch>
  );
}

export default TeamMembers;
