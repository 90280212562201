import { setCohortExecutionConfig } from '@Redux/slices/analytics/cohortSlice';
import { viewDataMetric, viewDataTime } from './cohortReportConstants';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from 'antd';
import React from 'react';

const DataModelSettingCard = ({ cohortDataMetric, setCohortDataMetric }) => {
  const dispatch = useDispatch();

  const cohortExecutionConfig = useSelector(
    (state) => state.analytics.cohort.cohortExecutionConfig
  );

  const handleTimeFrameChange = (timeFrame) => {
    let temp = { ...cohortExecutionConfig };
    temp.cohortDataTimeFrame = timeFrame;
    dispatch(setCohortExecutionConfig(temp));
  };

  return (
    <>
      <div className="data-model-card-wrapper">
        <div className="data-model-card-header">View Data Over</div>
        <Radio.Group
          value={cohortExecutionConfig.cohortDataTimeFrame}
          onChange={(e) => handleTimeFrameChange(e.target.value)}
        >
          {viewDataTime.map((time) => {
            return (
              <Radio.Button key={time.value} value={time.value}>
                {time.label}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </div>
      <div>
        <div className="data-model-card-header">View Metrics As</div>
        <Radio.Group
          value={cohortDataMetric}
          onChange={(e) => setCohortDataMetric(e.target.value)}
        >
          {viewDataMetric.map((metric) => {
            return (
              <Radio.Button key={metric.value} value={metric.value}>
                {metric.label}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </div>
    </>
  );
};

export default DataModelSettingCard;
