import CustomAttributeListComponent from '../component/CustomAttributeListComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  customAttributePageChangeCurrent,
  customAttributeDeleteFetch,
  customAttributeSearchListFetch,
  customAttributeListFetch,
  customAttributeUpdateFetch,
  customAttributeUpdateSubmitFetch,
  customAttributeChangeCurrent,
} from '@Redux/slices/data/customAttributeSlice';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  page: state.dataModule.customAttribute.page,
  pageRequest: state.dataModule.customAttribute.pageRequest,
  loadingUpdateSubmit: state.dataModule.customAttribute.loadingUpdateSubmit,
  loadingUpdate: state.dataModule.customAttribute.loadingUpdate,
  currentCustomAttribute:
    state.dataModule.customAttribute.currentCustomAttribute,
  loadingSearchList: state.dataModule.customAttribute.loadingSearchList,
  loading: state.dataModule.dataApp.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      customAttributePageChangeCurrent,
      customAttributeListFetch,
      customAttributeSearchListFetch,
      customAttributeUpdateFetch,
      customAttributeDeleteFetch,
      customAttributeChangeCurrent,
      customAttributeUpdateSubmitFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomAttributeListComponent);

CustomAttributeListComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  customAttributeListFetch: PropTypes.func.isRequired,
  customAttributeDeleteFetch: PropTypes.func.isRequired,
  customAttributePageChangeCurrent: PropTypes.func.isRequired,
};
