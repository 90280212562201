import BaseApi from '../../../api/BaseApi';

const basePath = '/engagement/journey';
class journeyApi {
  static createJourney(body) {
    return BaseApi.post(basePath + '/create', body);
  }
  static updateJourney(id) {
    return BaseApi.post(basePath + '/update/' + id);
  }
  static copyJourney(id) {
    return BaseApi.post(basePath + '/copy/' + id);
  }
  static updateSubmitJourney(id, body) {
    return BaseApi.post(basePath + '/updateSubmit/' + id, body);
  }
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static listTags() {
    return BaseApi.post(basePath + '/listTags', {});
  }
  static view(id) {
    return BaseApi.post(basePath + '/view/' + id);
  }
  static delete(id) {
    return BaseApi.post(basePath + '/delete/' + id, {});
  }
  static getAttributes(body) {
    return BaseApi.post(basePath + '/listAttributes', body);
  }
  static getEvents(body) {
    return BaseApi.post(basePath + '/listEvents', body);
  }
  static getBusinessEvents(body) {
    return BaseApi.post(basePath + '/listBusinessEvents', { body });
  }
  static getBusinessEventsReport(id, body) {
    return BaseApi.post(basePath + '/report/businessEvent/' + id, body);
  }
  static getSegments(body) {
    return BaseApi.post(basePath + '/listSegments', body);
  }

  static getTemplateNames(productId) {
    return BaseApi.post(basePath + '/listTemplateNames/' + productId);
  }
  static getReportCommunication(body) {
    return BaseApi.post(basePath + '/report/communications', body);
  }
  static getEnterOrExitCommunication(body) {
    return BaseApi.post(basePath + '/report/enterOrExit', body);
  }
  static getReportOverview(id, body) {
    return BaseApi.post(basePath + '/report/overview', {
      journeyId: id,
      fromDate: body.fromDate,
      toDate: body.toDate,
    });
  }
  static getReportLive(id) {
    return BaseApi.post(basePath + '/report/live/' + id);
  }
  static engagedUsers(body) {
    return BaseApi.post(basePath + '/engagedUsers', body);
  }
  static launch(id, body) {
    return BaseApi.post(basePath + '/launch/' + id, body);
  }
  static stop(id) {
    return BaseApi.post(basePath + '/stop/' + id);
  }
  static pause(id) {
    return BaseApi.post(basePath + '/pause/' + id);
  }
  static testApi(body) {
    return BaseApi.post(basePath + '/testApi/', body);
  }
  static archive(id) {
    return BaseApi.post(basePath + '/archive/' + id, {});
  }
  static active(id) {
    return BaseApi.post(basePath + '/active/' + id, {});
  }
  static reportCsv(body) {
    return BaseApi.downLoadFile(
      basePath +
        `/report/overview/csv?productId=${body.productId}&journeyId=${body.journeyId}&fromDate=${body.fromDate}&toDate=${body.toDate}`
    );
  }
  static reportCommunicationsCsv(body) {
    return BaseApi.downLoadFile(
      basePath +
        `/report/communications-csv?productId=${body.productId}&journeyId=${body.journeyId}&fromDate=${body.fromDate}&toDate=${body.toDate}`
    );
  }
}

export default journeyApi;
