import React from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Popconfirm,
  Spin,
  DatePicker,
} from 'antd';
import { MinusCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import FormBase from '../../../../component/form/FormBase';
import UserStatus from '../model/UserStatus';
import { isEmpty } from '@antv/util';
import { cloneDeep } from 'lodash';
import { errorHandling } from '../../../../utils/FormUtils';
import {
  AuthorityProvider,
  hasAnyAuthority,
} from '../../../../utils/AuthorityProvider';
import { passwordValidation } from '@Utils/PasswordValidation';
import Card from '@Utils/Card';
import moment from 'moment';
const { Option } = Select;
const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  addNewSlotButton: {
    position: 'relative',
    left: 5,
    top: 0,
  },
  title: {
    display: 'flex',
    color: '#9a9ca3',
    fontSize: 14,
    margin: '17px 10px',
  },
  description: {
    borderRadius: 10,
    backgroundColor: '#efefef',
    padding: '10px 40px',
    color: '#9a9ca3',
    marginBottom: 10,
  },
};
class UserUpdateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId:
        this.props.pathname.includes('update') &&
        this.props.pathname.split('update/').pop(),
    };
  }
  componentWillMount() {
    this.props.userChangeCurrent({});
  }

  componentDidMount() {
    this.props.userRoleListFetch();
    this.props.userProductListFetch();
    if (this.state.userId) {
      this.props.userUpdateFetch(this.state.userId);
    }
  }

  componentWillUnmount() {
    this.props.userChangeCurrent({});
    this.props.clearAdminUserErrors(this.props.adminUserErrors.errors);
  }

  onSubmit() {
    this.props.userUpdateSubmitFetch(this.state.userId, this.props.currentUser);
  }

  onFinish() {
    if (this.state.userId) {
      this.props.userUpdateSubmitFetch(
        this.state.userId,
        this.props.currentUser
      );
    } else {
      this.props.userCreateFetch(this.props.currentUser);
    }
  }

  renderProductOptions = () => {
    let result = [];
    if (!isEmpty(this.props.products)) {
      result.push(
        <Option value={null}>
          {<span style={{ fontWeight: 'bolder' }}>All Products</span>}
        </Option>
      );
      this.props.products.forEach((item) => {
        result.push(
          <Option value={item.id}>
            {item && item.name ? <span>{item.name}</span> : null}
          </Option>
        );
      });
    }
    return result;
  };

  renderRoleOptions = () => {
    let result = [];
    if (!isEmpty(this.props.roles)) {
      this.props.roles.forEach((item) => {
        result.push(
          <Option value={item.id}>
            {item && item.name ? <span>{item.name}</span> : null}
          </Option>
        );
      });
    }
    return result;
  };
  addProduct = () => {
    const currentUserProduct =
      this.props.currentUser &&
      this.props.currentUser.userProducts &&
      Array.isArray(this.props.currentUser.userProducts)
        ? cloneDeep(this.props.currentUser.userProducts)
        : [];
    this.props.userChangeCurrent({
      ...this.props.currentUser,
      userProducts: currentUserProduct.concat({
        product: null,
        enabled: true,
        roles: [],
      }),
    });
  };
  confirm = (e, index) => {
    const currentUserProduct =
      this.props.currentUser &&
      this.props.currentUser.userProducts &&
      Array.isArray(this.props.currentUser.userProducts)
        ? cloneDeep(this.props.currentUser.userProducts)
        : [];
    currentUserProduct.splice(index, 1);
    this.props.userChangeCurrent({
      ...this.props.currentUser,
      userProducts: currentUserProduct,
    });
    this.props.clearAdminUserErrors(this.props.adminUserErrors.errors, [
      'userProducts[' + [index] + '].roles',
      'userProducts[' + [index] + '].product',
      'userProducts[' + [index] + '].enabled',
    ]);
  };
  handleChangeValue = (key, value, index) => {
    const currentUserProduct = cloneDeep(this.props.currentUser.userProducts);
    currentUserProduct[index][key] = value;
    this.props.userChangeCurrent({
      ...this.props.currentUser,
      userProducts: currentUserProduct,
    });
    this.props.clearAdminUserErrors(
      this.props.adminUserErrors.errors,
      'userProducts[' + [index] + '].' + key
    );
  };
  render() {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 40 }} spin />;
    return (
      <div>
        <Card
          styles={{
            padding: 30,
          }}
        >
          {this.props.loadingAdminUsers ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '100px',
              }}
            >
              <Spin indicator={loadingSpin} />{' '}
            </div>
          ) : (
            <React.Fragment>
              <FormBase
                layout="vertical"
                errors={this.props.errors}
                value={this.props.currentUser ? this.props.currentUser : ''}
                onChange={(values) => {
                  this.props.userChangeCurrent({
                    ...values,
                    expirationDate: this.props.currentUser.expirationDate
                      ? this.props.currentUser.expirationDate
                      : null,
                    userProducts: this.props.currentUser.userProducts,
                  });
                }}
                hideSubmit
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    marginBottom: 30,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-end',
                      width: 200,
                    }}
                  >
                    <div style={styles.title}>FIRST NAME</div>
                    <div style={styles.title}>LAST NAME</div>
                    <div style={styles.title}>EMAIL</div>
                    <div style={styles.title}>EXPIRATION DATE</div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 5,
                      width: 350,
                    }}
                  >
                    <Form.Item
                      name="firstName"
                      onChange={() => {
                        this.props.clearAdminUserErrors(
                          this.props.adminUserErrors.errors,
                          'firstName'
                        );
                      }}
                      validateStatus={
                        errorHandling(this.props.adminUserErrors, 'firstName')
                          ? 'error'
                          : null
                      }
                      help={errorHandling(
                        this.props.adminUserErrors,
                        'firstName'
                      )}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="lastName"
                      onChange={() => {
                        this.props.clearAdminUserErrors(
                          this.props.adminUserErrors.errors,
                          'lastName'
                        );
                      }}
                      validateStatus={
                        errorHandling(this.props.adminUserErrors, 'lastName')
                          ? 'error'
                          : null
                      }
                      help={errorHandling(
                        this.props.adminUserErrors,
                        'lastName'
                      )}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      onChange={() => {
                        this.props.clearAdminUserErrors(
                          this.props.adminUserErrors.errors,
                          'email'
                        );
                      }}
                      validateStatus={
                        errorHandling(this.props.adminUserErrors, 'email')
                          ? 'error'
                          : null
                      }
                      help={errorHandling(this.props.adminUserErrors, 'email')}
                    >
                      <Input />
                    </Form.Item>
                    <DatePicker
                      style={{ width: 150 }}
                      defaultValue={
                        this.props.currentUser?.expirationDate
                          ? moment(this.props.currentUser.expirationDate)
                          : null
                      }
                      onChange={(value) => {
                        this.props.userChangeCurrent({
                          ...this.props.currentUser,
                          expirationDate: value.format('YYYY-MM-DD'),
                        });
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end',
                      marginRight: 25,
                    }}
                  >
                    <div style={styles.title}>PHONE</div>
                    <div style={styles.title}>STATUS</div>
                    {!this.state.userId && (
                      <div style={styles.title}>PASSWORD</div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 5,
                      width: 350,
                    }}
                  >
                    <Form.Item name="phone">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="status"
                      validateStatus={
                        errorHandling(this.props.adminUserErrors, 'status')
                          ? 'error'
                          : null
                      }
                      help={errorHandling(this.props.adminUserErrors, 'status')}
                    >
                      <Select
                        onChange={() => {
                          this.props.clearAdminUserErrors(
                            this.props.adminUserErrors.errors,
                            'status'
                          );
                        }}
                        placeholder="Please select"
                        options={Object.keys(UserStatus).map((x) => ({
                          value: x,
                          label: UserStatus[x],
                        }))}
                      />
                    </Form.Item>
                    {!this.state.userId && (
                      <Form.Item
                        name="inputPassword"
                        onChange={() => {
                          this.props.clearAdminUserErrors(
                            this.props.adminUserErrors.errors,
                            'inputPassword'
                          );
                        }}
                        validateStatus={
                          passwordValidation(
                            this.props?.currentUser?.inputPassword
                          )
                            ? 'error'
                            : errorHandling(
                                this.props.adminUserErrors,
                                'inputPassword'
                              )
                            ? 'error'
                            : null
                        }
                        help={
                          passwordValidation(
                            this.props?.currentUser?.inputPassword,
                            'profile',
                            true
                          ) ||
                          errorHandling(
                            this.props.adminUserErrors,
                            'inputPassword'
                          )
                        }
                      >
                        <Input.Password autoComplete="new-password" />
                      </Form.Item>
                    )}
                  </div>
                </div>
                <div style={{ marginBottom: 20 }}>
                  {this.props.currentUser.userProducts &&
                    this.props.currentUser.userProducts.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <div style={{ ...styles.title, marginRight: 10 }}>
                            PRODUCT
                          </div>
                          <Form.Item
                            validateStatus={
                              errorHandling(
                                this.props.adminUserErrors,
                                'userProducts[' + [index] + '].product'
                              )
                                ? 'error'
                                : null
                            }
                            help={errorHandling(
                              this.props.adminUserErrors,
                              'userProducts[' + [index] + '].product'
                            )}
                          >
                            <Select
                              allowClear
                              showSearch
                              placeholder={'Search by product name'}
                              filterOption={(input, option) => {
                                if (option.children.props.children) {
                                  return (
                                    option.children.props.children
                                      .toString()
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }
                              }}
                              style={{ width: '250px', marginTop: 20 }}
                              value={item.product}
                              onChange={(val) => {
                                this.handleChangeValue('product', val, index);
                              }}
                            >
                              {this.renderProductOptions()}
                            </Select>
                          </Form.Item>
                          <div style={{ ...styles.title, marginLeft: '45px' }}>
                            ENABLED
                          </div>
                          <Form.Item>
                            <Select
                              disabled={true}
                              allowClear
                              style={{ width: '200px', marginTop: 20 }}
                              placeholder="Please select"
                              value={'Yes'}
                            >
                              <Option value={'Yes'} key={item.id}>
                                Yes
                              </Option>
                              <Option value={'No'} key={item.id}>
                                No
                              </Option>
                            </Select>
                          </Form.Item>
                          <div style={{ ...styles.title, marginLeft: '45px' }}>
                            ROLES
                          </div>
                          <Form.Item
                            validateStatus={
                              errorHandling(
                                this.props.adminUserErrors,
                                'userProducts[' + [index] + '].roles'
                              )
                                ? 'error'
                                : null
                            }
                            help={errorHandling(
                              this.props.adminUserErrors,
                              'userProducts[' + [index] + '].roles'
                            )}
                          >
                            <Select
                              mode="multiple"
                              allowClear
                              filterOption={(input, option) => {
                                if (option.children?.props?.children) {
                                  return (
                                    option.children?.props?.children
                                      .toString()
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }
                              }}
                              style={{
                                width: '250px',
                                marginRight: '10px',
                                marginTop: 20,
                              }}
                              placeholder="Please select"
                              value={item.roles}
                              onChange={(val) => {
                                this.handleChangeValue('roles', val, index);
                              }}
                            >
                              {this.renderRoleOptions()}
                            </Select>
                          </Form.Item>
                          <Popconfirm
                            title="Are you sure delete this slot?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={(e) => this.confirm(e, index)}
                            onCancel={(e) => e.stopPropagation()}
                          >
                            <MinusCircleOutlined
                              style={{ color: '#bbbcc1', fontSize: 20 }}
                            />
                          </Popconfirm>
                        </div>
                      );
                    })}
                </div>
                <Button
                  type="primary"
                  shape="round"
                  size={'large'}
                  ghost
                  style={styles.addNewSlotButton}
                  onClick={() => this.addProduct()}
                >
                  Add Product
                </Button>
              </FormBase>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Link to={this.state.userId ? '../list' : 'list'}>
                  <Button
                    type="primary"
                    shape="round"
                    size={'large'}
                    style={{ margin: '0px 5px' }}
                    className="w-200"
                  >
                    {'Back'}
                  </Button>
                </Link>
                <div>
                  {hasAnyAuthority(
                    AuthorityProvider.ROLE_ADMIN_USER_CHANGE_PASSWORD
                  ) && (
                    <Link to={`../changePassword/${this.state.userId}`}>
                      <Button
                        type="primary"
                        shape="round"
                        size={'large'}
                        loading={this.props.loading}
                        className="w-200"
                      >
                        {'Change Password'}
                      </Button>
                    </Link>
                  )}
                  <Button
                    loading={this.props.userUpdateLoading}
                    type="primary"
                    onClick={() => this.onFinish()}
                    shape="round"
                    size={'large'}
                    style={{ margin: '0px 5px' }}
                    className="w-200"
                  >
                    {'Submit'}
                  </Button>
                </div>
              </div>
            </React.Fragment>
          )}
        </Card>
      </div>
    );
  }
}

export default UserUpdateComponent;
