import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../app';
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
} from 'antd';
// import openSnackBar from '../Atom/SnackBar';
import { convertMinute, timesOptions } from '@Utils/DateUtils';
import moment from 'moment';
import { calcMinuteTime } from '@Component/journey/utils/hooks';

const styles = {
  title: {
    color: '#a7a7a7',
    textAlign: 'right',
    paddingRight: '25px',
  },
  input: {
    minWidth: '60px',
    margin: '0 0px 0 10px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    // lineHeight: '2.5em',
    rowGap: '10px',
  },
  select: {
    margin: '0 5px',
    minWidth: '100px',
  },
  selectEvent: {
    margin: '0 5px',
    minWidth: '100px',
    maxWidth: 'unset',
    width: 'unset',
  },
  radioGroup: {},
  formItem: {
    display: 'inline-block',
    marginBottom: '0px',
  },
};

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const ACTION_TYPES = {
  EXISTING_ATTRIBUTE: 'EXISTING_ATTRIBUTE',
  NEW_ATTRIBUTE: 'NEW_ATTRIBUTE',
};

const ATTRIBUTE_TYPES = [
  {
    name: 'String',
    value: 'STRING',
  },
  {
    name: 'Date',
    value: 'DATE',
  },
  {
    name: 'Number',
    value: 'NUMERIC',
  },
  {
    name: 'Boolean',
    value: 'BOOLEAN',
  },
];

const ATTRIBUTE_SET_TYPE_LIST = [
  {
    name: 'Set Value To',
    value: 'SET',
  },
  {
    name: 'Increase Value By',
    value: 'INCREASE',
  },
  {
    name: 'Decrease Value By',
    value: 'DECREASE',
  },
];

const defaultData = {
  actionType: 'NEW_ATTRIBUTE',
  attributeId: null,
  attributeName: null,
  attributeSetType: 'SET',
  attributeType: null,
  attributeValue: null,
};

const AcSetUserAttribute = (props) => {
  const [form] = Form.useForm();
  const { journey, syncCache } = useContext(AppContext);
  const [actionType, setActionType] = useState(ACTION_TYPES.EXISTING_ATTRIBUTE);
  const [inputType, setInputType] = useState(ATTRIBUTE_TYPES[0].value);

  const [attributeSetTypeList, setAttributeSetTypeList] = useState(
    ATTRIBUTE_SET_TYPE_LIST
  );
  const { attributes } = props.data;

  const currentStep = journey.steps.find(
    (step) => step.id + '' === props.node.id + ''
  );

  useEffect(() => {
    if (currentStep.actionType) {
      const attrib =
        currentStep.actionType === ACTION_TYPES.EXISTING_ATTRIBUTE
          ? attributes.find((at) => at.id === currentStep.attributeId)
          : {};
      const atType = attrib?.type || currentStep.attributeType;
      console.log('atType------', atType);
      form.setFieldsValue({
        actionType: currentStep.actionType,
        attributeId: currentStep.attributeId,
        attributeName: currentStep.attributeName,
        attributeSetType: currentStep.attributeSetType,
        attributeType: atType,
      });
      setActionType(currentStep.actionType);
      if (
        atType === ATTRIBUTE_TYPES[0].value ||
        atType === ATTRIBUTE_TYPES[3].value
      ) {
        setAttributeSetTypeList([ATTRIBUTE_SET_TYPE_LIST[0]]);
      }
      // setInputType(currentStep.attributeType);
      setInputType(atType);
      if (atType === ATTRIBUTE_TYPES[1].value) {
        if (atType !== currentStep.attributeType) {
          const time = convertMinute(currentStep.attributeValue);
          form.setFieldsValue({
            attributeValue: time.value,
            attributeDateType: time.type,
          });
        } else {
          form.setFieldsValue({
            attributeValue: moment(currentStep.attributeValue),
          });
        }
      } else {
        form.setFieldsValue({ attributeValue: currentStep.attributeValue });
      }
    }
  }, [currentStep]);

  const onFinish = async (dataForm) => {
    try {
      await form.validateFields();
      let attributeValue;
      let attributeType = dataForm.attributeType;
      // For increase or decrease Date manual handle
      if (
        dataForm.actionType === ACTION_TYPES.EXISTING_ATTRIBUTE &&
        dataForm.attributeSetType !== ATTRIBUTE_SET_TYPE_LIST[0].value
      ) {
        if (dataForm.attributeDateType) {
          attributeValue = calcMinuteTime(
            dataForm.attributeValue,
            dataForm.attributeDateType
          );
        } else {
          attributeValue = dataForm.attributeValue;
        }
        attributeType = ATTRIBUTE_TYPES[2].value;
      } else {
        attributeValue =
          dataForm.attributeType === ATTRIBUTE_TYPES[1].value
            ? moment(dataForm.attributeValue).format('YYYY-MM-DD')
            : dataForm.attributeValue;
      }
      let attributeSetType = dataForm.attributeSetType;
      if (dataForm.actionType === ACTION_TYPES.NEW_ATTRIBUTE) {
        attributeSetType = 'SET';
      }
      const currentStepUpdated = Object.assign({}, currentStep, dataForm, {
        attributeValue,
        attributeType,
        attributeSetType,
      });

      delete currentStepUpdated.ui?.error;
      syncCache('update-step', currentStepUpdated, true);
      props.closeModal();
    } catch (e) {
      console.log('error------', e);
    }
  };

  const InputAttributeValue = (type) => {
    let type_ = type;
    let min = undefined;
    if (form.getFieldValue('actionType') === ACTION_TYPES.EXISTING_ATTRIBUTE) {
      if (
        form.getFieldValue('attributeSetType') &&
        form.getFieldValue('attributeSetType') !==
          ATTRIBUTE_SET_TYPE_LIST[0].value
      ) {
        type_ = ATTRIBUTE_TYPES[2].value;
        if (form.getFieldValue('attributeType') === ATTRIBUTE_TYPES[1].value) {
          min = 0;
        }
      }
    }
    switch (type_) {
      case ATTRIBUTE_TYPES[1].value:
        return <DatePicker placeholder="Select Date" style={styles.input} />;

      case ATTRIBUTE_TYPES[2].value:
        return (
          <InputNumber
            placeholder="Enter a value"
            disabled={props?.readOnly}
            style={styles.input}
            min={min}
          />
        );

      case ATTRIBUTE_TYPES[3].value:
        return (
          <Select placeholder="Select ..." style={styles.input}>
            {[true, false].map((val, i) => (
              <Select.Option key={i} value={val}>
                {String(val)}
              </Select.Option>
            ))}
          </Select>
        );

      case ATTRIBUTE_TYPES[0].value:
      default:
        return (
          <Input
            placeholder="Enter a value"
            disabled={props?.readOnly}
            style={styles.input}
          />
        );
    }
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        className="modal_wrap"
        {...formItemLayout}
        initialValues={{
          actionType: ACTION_TYPES.EXISTING_ATTRIBUTE,
          attributeSetType: attributeSetTypeList[0].value,
        }}
        onFieldsChange={(changedFields) => {
          try {
            if (changedFields.length !== 1) return;
            const [changedField] = changedFields;
            const [fieldName] = changedField?.name || [];
            if (fieldName === 'actionType') {
              setActionType(changedField.value);
              const temp_ = { ...defaultData };
              delete temp_.actionType;
              form.setFieldsValue(temp_);
            } else if (fieldName === 'attributeType') {
              form.setFieldsValue({ attributeValue: null });
              setInputType(changedField.value);
            } else if (fieldName === 'attributeId') {
              const temp_ = { ...defaultData };
              delete temp_.actionType;
              delete temp_.attributeId;
              delete temp_.attributeType;
              form.setFieldsValue(temp_);

              const attr = attributes.find(
                (att) => att.id + '' === changedField.value + ''
              );
              if (attr) {
                setInputType(attr.type);
                form.setFieldsValue({ attributeType: attr.type });
                if (
                  attr.type === ATTRIBUTE_TYPES[0].value ||
                  attr.type === ATTRIBUTE_TYPES[3].value
                ) {
                  setAttributeSetTypeList([ATTRIBUTE_SET_TYPE_LIST[0]]);
                } else {
                  setAttributeSetTypeList(ATTRIBUTE_SET_TYPE_LIST);
                }
              }
            }
          } catch (e) {
            console.log('error----->>', e);
          }
        }}
      >
        <div className={'modal-body'}>
          <div className="content__item__no-flex" style={{ marginTop: 30 }}>
            <Form.Item
              label="ACTION TYPE"
              name="actionType"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
            >
              <Radio.Group disabled={props?.readOnly}>
                <Space direction="vertical">
                  <Radio value={ACTION_TYPES.EXISTING_ATTRIBUTE}>
                    Set value of an existing user attribute
                  </Radio>
                  <Radio value={ACTION_TYPES.NEW_ATTRIBUTE}>
                    Create a new user attribute & set its value
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="ACTION"
              // name="action"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
            >
              <div>
                {actionType === ACTION_TYPES.EXISTING_ATTRIBUTE ? (
                  <Space size="middle">
                    <Form.Item
                      label="For user attribute"
                      colon={false}
                      name="attributeId"
                      rules={[{ required: true }]}
                      requiredMark="optional"
                      help={false}
                    >
                      <Select
                        disabled={props?.readOnly}
                        placeholder="Select Attribute"
                        showSearch
                        filterOption={(input, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }
                        }}
                        style={styles.select}
                      >
                        <Select.OptGroup label="CUSTOM">
                          {attributes
                            .filter((at) => !at.system && at.updatableField)
                            .map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.title}
                              </Select.Option>
                            ))}
                        </Select.OptGroup>
                        <Select.OptGroup label="SYSTEM">
                          {attributes
                            .filter((at) => at.system && at.updatableField)
                            .map((item) => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.title}
                              </Select.Option>
                            ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="of data type"
                      colon={false}
                      name="attributeType"
                      rules={[{ required: true }]}
                      requiredMark="optional"
                      help={false}
                    >
                      <Input readOnly={true} placeholder="Type" />
                    </Form.Item>
                    {/* {form.getFieldValue('attributeId') ? ( */}
                    <Form.Item
                      shouldUpdate
                      // shouldUpdate={(prev, cur) =>
                      //   prev.attributeId !== cur.attributeId
                      // }
                      style={{ margin: 0 }}
                    >
                      {(props) => {
                        const attrId = props.getFieldValue('attributeId');
                        const attributeType =
                          props.getFieldValue('attributeType');
                        const attrSetType =
                          props.getFieldValue('attributeSetType');
                        if (!attrId) return null;
                        return (
                          <Space>
                            <Form.Item colon={false} name="attributeSetType">
                              <Select
                                disabled={props?.readOnly}
                                style={styles.select}
                              >
                                {attributeSetTypeList.map((option) => (
                                  <Select.Option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              rules={[{ required: true }]}
                              requiredMark="optional"
                              help={false}
                              colon={false}
                              name="attributeValue"
                            >
                              {InputAttributeValue(inputType)}
                            </Form.Item>
                            {attrSetType !== ATTRIBUTE_SET_TYPE_LIST[0].value &&
                            attributeType === ATTRIBUTE_TYPES[1].value ? (
                              <Form.Item
                                rules={[{ required: true }]}
                                requiredMark="optional"
                                help={false}
                                colon={false}
                                name="attributeDateType"
                              >
                                <Select
                                  placeholder="Select ..."
                                  disabled={props?.readOnly}
                                  style={styles.select}
                                >
                                  {timesOptions().map((option) => (
                                    <Select.Option
                                      key={option.id}
                                      value={option.id}
                                    >
                                      {option.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            ) : (
                              ''
                            )}
                          </Space>
                        );
                      }}
                    </Form.Item>
                  </Space>
                ) : (
                  ''
                )}
                {actionType === ACTION_TYPES.NEW_ATTRIBUTE ? (
                  <Space size="middle">
                    <Form.Item
                      colon={false}
                      label="Create user attribute"
                      name="attributeName"
                      rules={[{ required: true }]}
                      requiredMark="optional"
                      help={false}
                    >
                      <Input
                        disabled={props?.readOnly}
                        placeholder="Name of Attribute"
                        style={styles.input}
                      />
                    </Form.Item>
                    <Form.Item
                      colon={false}
                      label="of data type"
                      name="attributeType"
                      rules={[{ required: true }]}
                      requiredMark="optional"
                      help={false}
                    >
                      <Select
                        disabled={props?.readOnly}
                        placeholder="Select ..."
                        style={styles.select}
                      >
                        {ATTRIBUTE_TYPES.map((option) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      colon={false}
                      label="and set it's value to"
                      name="attributeValue"
                      rules={[{ required: true }]}
                      requiredMark="optional"
                      help={false}
                    >
                      {InputAttributeValue(inputType)}
                    </Form.Item>
                  </Space>
                ) : (
                  ''
                )}
              </div>
              <Form.Item shouldUpdate>
                {(props) => {
                  let attrSetType = props.getFieldValue('attributeSetType');
                  if (
                    actionType === ACTION_TYPES.EXISTING_ATTRIBUTE &&
                    attrSetType !== attributeSetTypeList[0].value
                  ) {
                    attrSetType = attrSetType.toLowerCase();
                    return (
                      <Alert
                        description={`	
                        If the selected user attribute has not been initialized, 
                        the value will first be set to the date when the user 
                        entered this block and then it will be ${attrSetType} by the value above.`}
                        type="info"
                        showIcon
                        style={{ width: 'max-content' }}
                      />
                    );
                  }
                }}
              </Form.Item>
            </Form.Item>
          </div>
        </div>
        <div className={'modal-footer'}>
          <Form.Item shouldUpdate>
            {(props) => {
              // const isFormValid = () =>
              //   form.getFieldsError().some((item) => item.errors.length === 0);

              return (
                <Button
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  disabled={props.readOnly}
                >
                  Save
                </Button>
              );
            }}
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
export default AcSetUserAttribute;
