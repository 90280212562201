import * as React from 'react';

function SvgPush(props) {
  return (
    <svg viewBox="0 0 18 26" width="1em" height="1em" {...props}>
      <path
        d="M11.111 1.717H1.156C.524 1.717 0 2.138 0 2.66v22.397c0 .516.516.943 1.156.943h12.895c.64 0 1.157-.421 1.157-.943V6.1a3.713 3.713 0 01-1.521-.114v13.943H1.52V4.752h10.324a3.664 3.664 0 01-.734-3.035zM7.604 24.482c.84 0 1.52-.68 1.52-1.517a1.52 1.52 0 00-1.52-1.518 1.518 1.518 0 10-.001 3.035zm7.113-19.576a2.453 2.453 0 100-4.906 2.453 2.453 0 000 4.906z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgPush;
