import * as React from 'react';

function SvgWeb(props) {
  return (
    <svg viewBox="0 0 26 23" width="1em" height="1em" {...props}>
      <path
        d="M23.556 0H2.445C1.095.003.003 1.186 0 2.647v16.991c.003 1.46 1.095 2.645 2.445 2.648h21.11c1.35-.003 2.444-1.187 2.445-2.648V2.648C25.999 1.185 24.905.002 23.556 0zM2.628 1.857h4.03c.426 0 .77.416.77.929 0 .513-.344.928-.77.928h-4.03c-.426 0-.77-.415-.77-.928 0-.513.344-.929.77-.929zm21.515 17.488c-.001.592-.482 1.083-1.062 1.084H2.918c-.579-.001-1.06-.492-1.06-1.084V5.57h22.285v13.774z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgWeb;
