import {
  CONFIGURATION_CHANGE_CURRENT,
  CONFIGURATION_LIST_ATTRIBUTES_FETCH,
  CONFIGURATION_LIST_ATTRIBUTES_FETCH_FAIL,
  CONFIGURATION_LIST_ATTRIBUTES_FETCH_SUCCESS,
  CONFIGURATION_UPDATE_FETCH,
  CONFIGURATION_UPDATE_FETCH_FAIL,
  CONFIGURATION_UPDATE_FETCH_SUCCESS,
  CONFIGURATION_UPDATE_SUBMIT_FETCH,
  CONFIGURATION_UPDATE_SUBMIT_FETCH_FAIL,
  CONFIGURATION_UPDATE_SUBMIT_FETCH_SUCCESS,
  CONFIGURATION_VIEW_FETCH,
  CONFIGURATION_VIEW_FETCH_FAIL,
  CONFIGURATION_VIEW_FETCH_SUCCESS,
} from './ConfigurationConstants';
import ConfigurationApi from './ConfigurationApi';
import { showMessageError } from '@Utils/RenderUtils';
import toastr from 'toastr';

export function configurationChangeCurrent(configuration) {
  return (dispatch) => {
    dispatch({
      type: CONFIGURATION_CHANGE_CURRENT,
      payload: configuration,
    });
  };
}

export function configurationUpdateFetch(configuration) {
  return (dispatch) => {
    dispatch({
      type: CONFIGURATION_UPDATE_FETCH,
      payload: configuration,
    });
    ConfigurationApi.update(configuration)
      .then((response) => {
        dispatch({
          type: CONFIGURATION_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: CONFIGURATION_UPDATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function configurationUpdateSubmitFetch(configuration) {
  return (dispatch) => {
    dispatch({
      type: CONFIGURATION_UPDATE_SUBMIT_FETCH,
      payload: configuration,
    });
    ConfigurationApi.updateSubmit(configuration)
      .then((response) => {
        dispatch({
          type: CONFIGURATION_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        //history.push('view');
        toastr.success('Has been updated successfully');
      })
      .catch((response) => {
        dispatch({
          type: CONFIGURATION_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function configurationViewFetch() {
  return (dispatch) => {
    dispatch({ type: CONFIGURATION_VIEW_FETCH });
    ConfigurationApi.view()
      .then((response) => {
        dispatch({
          type: CONFIGURATION_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: CONFIGURATION_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function configurationtListAttributesFetch() {
  return (dispatch) => {
    dispatch({
      type: CONFIGURATION_LIST_ATTRIBUTES_FETCH,
    });
    ConfigurationApi.listSystemAttributes()
      .then((response) => {
        dispatch({
          type: CONFIGURATION_LIST_ATTRIBUTES_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: CONFIGURATION_LIST_ATTRIBUTES_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
