import {
  AndroidFilled,
  AppleFilled,
  LinkOutlined,
  LoadingOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Segmented, Spin } from 'antd';
import moment from 'moment';
import React, { useCallback, useState } from 'react';

function WhatsAppPreview({
  variation,
  previewPage = false,
  whatsapp,
  index,
  loading = false,
}) {
  const [isPreviewAndroid, setIsPreviewAndroid] = useState(true);
  const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;

  let bodyText = whatsapp[index]?.bodyText || '';
  const variable = variation?.whatsAppVariables || {};

  const replacedVariables = useCallback(
    (text, object) => {
      for (const key in object) {
        text = text.replace(key, object[key]);
      }
      return text;
    },
    [variable]
  );

  const body = (
    <div className="sms-preview-body">
      <div className="clearfix">
        <div className="sms-preview-message">
          {whatsapp[index]?.headerText && (
            <div
              style={{
                fontWeight: 800,
                color: '#6e707a',
                marginBottom: 4,
              }}
            >
              {whatsapp[index]?.headerText}
            </div>
          )}
          {replacedVariables(bodyText, variable) &&
            replacedVariables(bodyText, variable)
              .split('\n')
              .map((item, i) => (
                <span key={i}>
                  {item}
                  <br />
                </span>
              ))}
          {whatsapp[index]?.footer && (
            <div
              style={{
                color: '#6e707a',
                marginBottom: 4,
              }}
            >
              {whatsapp[index]?.footer}
            </div>
          )}
        </div>
      </div>
      {whatsapp[index]?.templateButtons && (
        <div className="buttons-wrapper" style={{ marginTop: 30 }}>
          {whatsapp[index]?.templateButtons.buttons.map((button, i) => (
            <div key={i} className="btn">
              {button.typeOfAction === 'URL' ? (
                <LinkOutlined />
              ) : button.typeOfAction === 'PHONE_NUMBER' ? (
                <PhoneOutlined />
              ) : (
                ''
              )}
              <span style={{ paddingLeft: 4 }}>{button.text}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const mobilePlatformIconStyle = { fontSize: 16, color: '#777' };
  let mobilePlatformOptions = [
    {
      value: 'android',
      icon: <AndroidFilled style={mobilePlatformIconStyle} />,
    },
    {
      value: 'ios',
      icon: <AppleFilled style={mobilePlatformIconStyle} />,
    },
  ];

  return (
    <section
      style={{ direction: 'ltr', height: '100vh', paddingBottom: 0 }}
      className={`message-container__preview ${
        previewPage ? 'message-container__preview--static' : ''
      } overflow-hidden`}
    >
      <div className="margin-left-auto message-container__options">
        <div
          className="row justify-content-between"
          style={{ flexDirection: 'row-reverse' }}
        >
          <Segmented
            style={{ borderRadius: 8 }}
            onChange={(val) => {
              if (val === 'android') {
                setIsPreviewAndroid(true);
              } else {
                setIsPreviewAndroid(false);
              }
            }}
            options={mobilePlatformOptions}
          />
        </div>
      </div>
      <div className="pos-relative w-100 overflow-hidden">
        <div className="preview">
          {isPreviewAndroid ? (
            <div className="preview_sms preview_whatsapp preview_mobile preview_android">
              <div id="sms-android" className="preview-inner">
                <div className="preview-device empty"></div>
                <div className="preview-device mobile mobile_android"></div>
                <div className="preview-content">
                  <div className="sms-preview-date">
                    {moment().format('H:mm')}
                  </div>
                  <div className="sms-preview-header">
                    <div style={{ textAlign: 'left' }}>8758316578</div>
                  </div>
                  {loading ? (
                    <div
                      style={{
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                        paddingTop: 50,
                        paddingBottom: 50,
                      }}
                    >
                      <Spin indicator={loadingSpin} />
                    </div>
                  ) : (
                    body
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="preview_sms preview_mobile preview_ios preview_whatsapp">
              <div id="sms-android" className="preview-inner">
                <div className="preview-device empty"></div>
                <div className="preview-device mobile mobile_ios"></div>
                <div className="preview-content">
                  <div className="sms-preview-date">
                    {moment().format('H:mm')}
                  </div>
                  <div className="whatsapp-preview-header row">
                    <div className="col-6 text-ellipsis">8758316578</div>
                  </div>
                  <div className="sms-preview-sub-header sms-preview-sub-header_2">
                    {'Today ' + moment().format('H:mm')}
                  </div>
                  {body}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default WhatsAppPreview;
