import FileStorageListComponent from '../component/FileStorageListComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  fileStorageListFetch,
  fileStorageDeleteFetch,
  fileStorageUpdateSubmitFetch,
  FileStorageViewFetch,
  fileStorageUpdateFetch,
} from '../FileStorageAction';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  page: state.admin.fileStorage.page,
  pageRequest: state.admin.fileStorage.pageRequest,
  loadingFileStorageList: state.admin.fileStorage.loadingFileStorageList,
  updateFetchLoading: state.admin.fileStorage.updateFetchLoading,
  currentFileStorage: state.admin.fileStorage.currentFileStorage,
  submitLoading: state.admin.fileStorage.submitLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fileStorageListFetch,
      fileStorageUpdateSubmitFetch,
      fileStorageDeleteFetch,
      FileStorageViewFetch,
      fileStorageUpdateFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileStorageListComponent);

FileStorageListComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  fileStorageListFetch: PropTypes.func.isRequired,
};
