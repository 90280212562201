exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CreateProductForm-module__cardWrapper--2bT80 {\n  border-radius: 8px;\n}\n.CreateProductForm-module__textInput--2sH25 {\n  width: 50% !important;\n}\n.CreateProductForm-module__numberInput--3iRzW {\n  width: 50% !important;\n  border-radius: 8px !important;\n  padding: 0px 16px !important;\n  height: 34px !important;\n}\n.CreateProductForm-module__datePickerInput--3evdT {\n  width: 50% !important;\n  padding: 0px 16px !important;\n  height: 34px !important;\n}\n.CreateProductForm-module__actionButtonWrapper--14ouO {\n  display: flex;\n  flex-direction: row;\n  justify-content: right;\n}\n", ""]);

// exports
exports.locals = {
	"cardWrapper": "CreateProductForm-module__cardWrapper--2bT80",
	"textInput": "CreateProductForm-module__textInput--2sH25",
	"numberInput": "CreateProductForm-module__numberInput--3iRzW",
	"datePickerInput": "CreateProductForm-module__datePickerInput--3evdT",
	"actionButtonWrapper": "CreateProductForm-module__actionButtonWrapper--14ouO"
};