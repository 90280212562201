import React from 'react';
import { Select } from 'antd';
import { useDispatch } from 'react-redux';
import { slaDurationChanged } from '@Redux/slices/setting/billingSlice';
import texts from '@Utils/texts';
import moment from 'moment-jalaali';

export default function DurationSelect() {
  const dispatch = useDispatch();
  const list = [];

  for (let i = 1; i <= 1; i++) {
    const currentMonth = moment({ jMonth: moment().jMonth() + 1 }).subtract(
      i,
      'jMonth'
    );

    list.push({
      label: moment({ jMonth: moment().jMonth() + 1 })
        .subtract(i, 'jMonth')
        .format(texts.id === 'in' ? 'jMMMM' : 'MMMM'),
      value: JSON.stringify({
        fromDate: moment(currentMonth.startOf('jMonth')).format('YYYY-MM-DD'),
        toDate: moment(currentMonth.endOf('jMonth')).format('YYYY-MM-DD'),
      }),
    });
  }
  const val = JSON.parse(list[0].value);
  dispatch(
    slaDurationChanged({
      fromDate: val.fromDate,
      toDate: val.toDate,
    })
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ paddingRight: '10px' }}>Display SLA Metrics in:</span>
      <Select
        defaultValue={list[0].value}
        style={{ width: 120 }}
        onChange={(value) => {
          dispatch(slaDurationChanged(JSON.parse(value)));
        }}
        options={list}
      />
    </div>
  );
}
