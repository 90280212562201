import React, { useState, useEffect } from 'react';
import { Select, Card, Input, Button } from 'antd';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
  WhatsAppTemplateGeneralActionButtonTypes,
  WhatsAppTemplateActionButtonURLTypes,
  WhatsAppTemplateCallToActionButtons,
  WhatsAppTemplateActionButtonTypes,
  actionButtonProperties,
  checkTemplatePermissions,
} from '../model/WhatsAppConst';
import vars from '@Theme/styles/vars';
import {
  whatsAppChannelData,
  setCurrentWhatsAppChannelTemplate,
} from '@Redux/slices/channel/whatsAppChannelSlice';
import DeleteIcon from '@Theme/icons/delete.svg';
import lodashCloneDeep from 'lodash/cloneDeep';

const WhatsAppTemplateButtonConfig = ({ isEditMode }) => {
  const templatePermissions = checkTemplatePermissions();
  const isFormDisabled = isEditMode || !templatePermissions?.createOrUpdate;

  const dispatch = useDispatch();
  const { currentWhatsAppTemplate } = useSelector(
    whatsAppChannelData,
    shallowEqual
  );
  const [generalActionButtonType, setGeneralActionButtonType] = useState();
  const [actionButtonList, setActionButtonList] = useState([{}]);

  useEffect(() => {
    const tempActionButtonList = currentWhatsAppTemplate?.templateButtons
      ?.buttons ?? [{}];
    const tempActionButtonType = currentWhatsAppTemplate?.templateButtons
      ?.buttons
      ? currentWhatsAppTemplate?.templateButtons?.buttons?.[0].typeOfAction ===
        WhatsAppTemplateActionButtonTypes.QUICK_REPLY
        ? WhatsAppTemplateGeneralActionButtonTypes.QUICK_REPLY
        : WhatsAppTemplateGeneralActionButtonTypes.CALL_TO_ACTION
      : WhatsAppTemplateGeneralActionButtonTypes.QUICK_REPLY;
    setGeneralActionButtonType(tempActionButtonType);
    setActionButtonList(tempActionButtonList);
  }, [currentWhatsAppTemplate?.templateButtons]);

  const handleGeneralActionButtonTypeChange = (value) => {
    setGeneralActionButtonType(value);
    const tempActionType =
      value === WhatsAppTemplateGeneralActionButtonTypes.QUICK_REPLY
        ? WhatsAppTemplateActionButtonTypes.QUICK_REPLY
        : WhatsAppTemplateActionButtonTypes.PHONE_NUMBER;
    const temp = [actionButtonProperties[tempActionType]];
    setActionButtonList(temp);
    setCurrentWhatsAppTemplateButton(temp);
  };

  const setCurrentWhatsAppTemplateButton = (newValue) => {
    const tempCurrentWhatsAppTemplateButton = lodashCloneDeep(
      currentWhatsAppTemplate?.templateButtons
    );
    tempCurrentWhatsAppTemplateButton.buttons = newValue;

    dispatch(
      setCurrentWhatsAppChannelTemplate({
        ...currentWhatsAppTemplate,
        templateButtons: tempCurrentWhatsAppTemplateButton,
      })
    );
  };

  const addActionButton = () => {
    const tempActionButtonList = [...actionButtonList];
    let tempActionButtonType = '';
    if (
      generalActionButtonType ===
      WhatsAppTemplateGeneralActionButtonTypes.CALL_TO_ACTION
    ) {
      tempActionButtonType = tempActionButtonList.some(
        (button) =>
          button.typeOfAction === WhatsAppTemplateActionButtonTypes.PHONE_NUMBER
      )
        ? WhatsAppTemplateActionButtonTypes.URL
        : WhatsAppTemplateActionButtonTypes.PHONE_NUMBER;
    } else {
      tempActionButtonType = WhatsAppTemplateActionButtonTypes.QUICK_REPLY;
    }

    tempActionButtonList.push({
      ...actionButtonProperties[tempActionButtonType],
      id: Math.round(Math.random() * 100000) + tempActionButtonList.length,
    });
    setActionButtonList(tempActionButtonList);
    setCurrentWhatsAppTemplateButton(tempActionButtonList);
  };

  const deleteActionButton = (index) => {
    const temp = [...actionButtonList];
    temp.splice(index, 1);
    setActionButtonList(temp);
    setCurrentWhatsAppTemplateButton(temp);
  };

  const handleInputChange = (e, index) => {
    const temp = lodashCloneDeep(actionButtonList);
    temp[index][e.target.name] = e.target.value;
    setActionButtonList(temp);
    setCurrentWhatsAppTemplateButton(temp);
  };

  const handleSelectChange = (value, name, index) => {
    const temp = lodashCloneDeep(actionButtonList);
    temp[index][name] = value;
    if (name === 'typeOfAction') {
      value === WhatsAppTemplateActionButtonTypes.URL
        ? delete temp[index]['phoneNumber']
        : delete temp[index]['url'];
    }
    setActionButtonList(temp);
    setCurrentWhatsAppTemplateButton(temp);
  };

  return (
    <>
      <div style={styles.formContainer}>
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>TYPE OF ACTION</div>
          <div style={styles.editFieldContainer}>
            <Select
              name="generalActionTypes"
              value={generalActionButtonType}
              style={styles.editField}
              size="large"
              disabled={isFormDisabled}
              onChange={(generalActionTypes) =>
                handleGeneralActionButtonTypeChange(generalActionTypes)
              }
            >
              {Object.keys(WhatsAppTemplateGeneralActionButtonTypes)?.map(
                (item) => (
                  <Select.Option
                    value={WhatsAppTemplateGeneralActionButtonTypes[item]}
                    key={item}
                  >
                    {WhatsAppTemplateGeneralActionButtonTypes[item]}
                  </Select.Option>
                )
              )}
            </Select>
          </div>
        </div>
        {actionButtonList?.map((button, index) => {
          return (
            <Card
              key={index}
              style={styles.cardContainer}
              title={`Button ${index + 1}`}
              extra={
                index > 0 && !isFormDisabled ? (
                  <img
                    style={{ cursor: 'pointer' }}
                    src={DeleteIcon}
                    alt="delete"
                    onClick={() => deleteActionButton(index)}
                  />
                ) : null
              }
              headStyle={styles.cardHeaderStyle}
            >
              <div style={styles.formContainer}>
                <div style={styles.editContainer}>
                  <div style={styles.editLabel}>
                    <span style={styles.requiredMark}>*</span>
                    {`Button ${index + 1} TEXT`}
                  </div>
                  <div style={styles.editFieldContainer}>
                    <Input
                      name="text"
                      disabled={isFormDisabled}
                      onChange={(e) => handleInputChange(e, index)}
                      value={button?.text ?? ''}
                      size="large"
                      style={styles.editField}
                    />
                  </div>
                </div>
                {generalActionButtonType ===
                WhatsAppTemplateGeneralActionButtonTypes.CALL_TO_ACTION ? (
                  <>
                    <div style={styles.editContainer}>
                      <div style={styles.editLabel}>
                        <span style={styles.requiredMark}>*</span>
                        {`Button ${index + 1} TYPE`}
                      </div>
                      <div style={styles.editFieldContainer}>
                        <Select
                          name="typeOfAction"
                          value={button?.typeOfAction ?? ''}
                          style={styles.editField}
                          size="large"
                          disabled={isFormDisabled}
                          onChange={(typeOfAction) =>
                            handleSelectChange(
                              typeOfAction,
                              'typeOfAction',
                              index
                            )
                          }
                        >
                          {Object.keys(
                            WhatsAppTemplateCallToActionButtons
                          )?.map((item) => (
                            <Select.Option value={item} key={item}>
                              {WhatsAppTemplateCallToActionButtons[item]}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    {button.typeOfAction ===
                    WhatsAppTemplateActionButtonTypes.URL ? (
                      <div style={styles.editContainer}>
                        <div style={styles.editLabel}>
                          <span style={styles.requiredMark}>*</span>
                          {`Button ${index + 1} URL TYPE`}
                        </div>
                        <div style={styles.editFieldContainer}>
                          <Select
                            name="url"
                            value={button?.url ?? ''}
                            style={styles.editField}
                            size="large"
                            disabled={isFormDisabled}
                            onChange={(url) =>
                              handleSelectChange(url, 'url', index)
                            }
                          >
                            {Object.keys(
                              WhatsAppTemplateActionButtonURLTypes
                            )?.map((item) => (
                              <Select.Option value={item} key={item}>
                                {WhatsAppTemplateActionButtonURLTypes[item]}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    ) : (
                      <div style={styles.editContainer}>
                        <div style={styles.editLabel}>
                          <span style={styles.requiredMark}>*</span>
                          {`Button ${index + 1} PHONE`}
                        </div>
                        <div style={styles.editFieldContainer}>
                          <Input
                            name="phoneNumber"
                            disabled={isFormDisabled}
                            onChange={(e) => handleInputChange(e, index)}
                            value={button?.phoneNumber ?? ''}
                            size="large"
                            style={styles.editField}
                          />
                        </div>
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            </Card>
          );
        })}
        {(generalActionButtonType ===
          WhatsAppTemplateGeneralActionButtonTypes.QUICK_REPLY &&
          actionButtonList.length < 3 &&
          !isFormDisabled) ||
        (generalActionButtonType ===
          WhatsAppTemplateGeneralActionButtonTypes.CALL_TO_ACTION &&
          actionButtonList.length < 2 &&
          !isFormDisabled) ? (
          <Button
            style={styles.buttonContainer}
            shape="round"
            color="primary"
            onClick={addActionButton}
          >
            Add Another Button
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default WhatsAppTemplateButtonConfig;

const styles = {
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    fontWeight: '600',
    flex: 1,
  },
  editFieldContainer: {
    flex: 1,
  },
  editField: {
    marginLeft: 20,
    width: 350,
    backgroundColor: 'white',
  },
  cardContainer: {
    marginTop: 16,
    borderRadius: 8,
    backgroundColor: '#F8F8F8',
  },
  cardHeaderStyle: {
    color: '#38373B',
  },
  buttonContainer: {
    marginTop: 16,
    marginBottom: 16,
    color: vars.primaryColor,
    borderColor: vars.primaryColor,
  },
  requiredMark: {
    color: '#ff4d4f',
    fontSize: '14px',
    marginRight: '4px',
  },
};
