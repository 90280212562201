import BaseApi from '../../../api/BaseApi';

const basePath = '/data/businessEvent';

class BusinessEventApi {
  static create(body) {
    return BaseApi.post(basePath + '/create', body);
  }
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static trigger(body) {
    return BaseApi.post(basePath + '/triggerEvent', body);
  }
}
export default BusinessEventApi;
