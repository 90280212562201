import ProductSettingViewComponent from '../component/ProductRestApiViewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  productRestApiViewFetch,
  generateTokenFetch,
  revokeFetch,
} from '../ProductRestApiActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentProductRestApi: state.integration.productRestApi.currentProductRestApi,
  loadingProjectCredentials:
    state.integration.productRestApi.loadingProjectCredentials,
  generatedToken: state.integration.productRestApi.generatedToken,
  loadingGeneratedToken: state.integration.productRestApi.loadingGeneratedToken,
  loadingRevoke: state.integration.productRestApi.loadingRevoke,
  auth: state.account.auth,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      productRestApiViewFetch,
      generateTokenFetch,
      revokeFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSettingViewComponent);

ProductSettingViewComponent.propTypes = {
  currentProductRestApi: PropTypes.object.isRequired,
  productRestApiViewFetch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
