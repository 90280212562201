import { productSegmentRecommendEventAttributesFetch } from '@Modules/insight/productSegment/ProductSegmentActions';
import ModalEventFilterSelector from '@Modules/analytics/funnel/component/ModalEventFilterSelector';
import { useSelector } from 'react-redux';
import { Badge, Select } from 'antd';
import { useState } from 'react';
import './EventSelector.less';
import React from 'react';

const { Option, OptGroup } = Select;

const EventSelector = ({ currentAlert, setCurrentAlert, fieldName }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const listEvents = useSelector((state) => state.dataModule.alerts.listEvents);
  const recommendEventAttributes = useSelector(
    (state) => state.insight.productSegment.recommendEventAttributes
  );

  const renderOptions = (eventType, data) => {
    let systemItems = [];
    let customItems = [];
    if (data.length) {
      data.forEach((item) => {
        if (item.system) {
          systemItems.push(
            <Option key={item.id} value={item.id}>
              {item.title}
            </Option>
          );
        } else {
          customItems.push(
            <Option key={item.id} value={item.id}>
              {item.title}
            </Option>
          );
        }
      });
      if (eventType === 'system') {
        return systemItems;
      } else if (eventType === 'custom') {
        return customItems;
      }
    }
  };

  const onChangeEvents = (value, fieldName) => {
    const tempCurrentAlert = Object.assign({}, currentAlert);
    tempCurrentAlert[fieldName]['event'] = value;
    tempCurrentAlert[fieldName]['attributeFilters'] = [];
    tempCurrentAlert[fieldName]['conjunction'] = 'AND';
    setCurrentAlert(tempCurrentAlert);
  };

  const onEventModalChange = (changedPredicate, fieldName) => {
    const tempCurrentAlert = Object.assign({}, currentAlert);
    tempCurrentAlert[fieldName].conjunction = changedPredicate.conjunction;
    tempCurrentAlert[fieldName].attributeFilters =
      changedPredicate.eventPredicates;
    setCurrentAlert(tempCurrentAlert);
    setIsModalVisible(false);
  };

  return (
    <div className="alert_events">
      <Select
        showSearch
        style={{ width: '100%' }}
        value={currentAlert?.[fieldName]?.event}
        placeholder={'Select an Event'}
        onChange={(value) => onChangeEvents(value, fieldName)}
        required
        optionFilterProp="children"
      >
        <OptGroup label="SYSTEM">
          {renderOptions('system', listEvents)}
        </OptGroup>
        <OptGroup label="CUSTOM">
          {renderOptions('custom', listEvents)}
        </OptGroup>
      </Select>
      <Badge
        count={
          currentAlert?.[fieldName]?.['attributeFilters'] &&
          currentAlert?.[fieldName]?.['attributeFilters']?.length
        }
        showZero={false}
      >
        <div
          onClick={() => {
            if (currentAlert[fieldName]?.['event']) {
              setIsModalVisible(currentAlert[fieldName]?.['event']);
            }
          }}
          className="fl-filter fl-dark pointer"
          style={{ fontSize: 28 }}
        ></div>
      </Badge>
      <ModalEventFilterSelector
        currentPredicate={currentAlert?.[fieldName]}
        isModalVisible={currentAlert?.[fieldName]?.['event'] === isModalVisible}
        events={listEvents}
        onCancelHandler={() => setIsModalVisible(false)}
        onOkHandler={(changedPredicate) =>
          onEventModalChange(changedPredicate, fieldName)
        }
        handleChangePredicate={() => {}}
        fetchRecommendEventAttributes={
          productSegmentRecommendEventAttributesFetch
        }
        recommendEventAttributes={recommendEventAttributes}
      />
    </div>
  );
};

export default EventSelector;
