import UserBasicInfoComponent from '../component/UserBasicInfoComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  insightProductUserListAttributeFetch,
  insightProductUserViewFetch,
} from '../UsersActions';

const mapStateToProps = (state) => ({
  userLoading: state.track.users.userLoading,
  userErrors: state.track.users.userErrors,
  user: state.track.users.user,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      insightProductUserViewFetch,
      insightProductUserListAttributeFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBasicInfoComponent);

UserBasicInfoComponent.propTypes = {};
