import {
  FILE_STORAGE_CHANGE_CURRENT,
  FILE_STORAGE_CREATE_FETCH,
  FILE_STORAGE_CREATE_FETCH_FAIL,
  FILE_STORAGE_CREATE_FETCH_SUCCESS,
  FILE_STORAGE_DELETE_FETCH,
  FILE_STORAGE_DELETE_FETCH_FAIL,
  FILE_STORAGE_DELETE_FETCH_SUCCESS,
  FILE_STORAGE_LIST_FETCH,
  FILE_STORAGE_LIST_FETCH_FAIL,
  FILE_STORAGE_LIST_FETCH_SUCCESS,
  FILE_STORAGE_UPDATE_FETCH,
  FILE_STORAGE_UPDATE_FETCH_FAIL,
  FILE_STORAGE_UPDATE_FETCH_SUCCESS,
  FILE_STORAGE_UPDATE_SUBMIT_FETCH,
  FILE_STORAGE_UPDATE_SUBMIT_FETCH_FAIL,
  FILE_STORAGE_UPDATE_SUBMIT_FETCH_SUCCESS,
  FILE_STORAGE_VIEW_FETCH,
  FILE_STORAGE_VIEW_FETCH_FAIL,
  FILE_STORAGE_VIEW_FETCH_SUCCESS,
} from './FileStorageConstants';
import FileStorageApi from './FileStorageApi';
import { history } from '../../../redux/store';

const loadFileStorageList = (dispatch, fileStoragePageRequest) => {
  dispatch({
    type: FILE_STORAGE_LIST_FETCH,
    payload: fileStoragePageRequest,
  });
  FileStorageApi.list(fileStoragePageRequest)
    .then((response) => {
      dispatch({
        type: FILE_STORAGE_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: FILE_STORAGE_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function fileStorageListFetch(fileStoragePageRequest) {
  return (dispatch) => loadFileStorageList(dispatch, fileStoragePageRequest);
}

export function FileStorageCreateFetch(fileStorage) {
  return (dispatch) => {
    dispatch({
      type: FILE_STORAGE_CREATE_FETCH,
      payload: fileStorage,
    });
    FileStorageApi.create(fileStorage)
      .then((response) => {
        dispatch({
          type: FILE_STORAGE_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        history.push('list');
      })
      .catch((response) => {
        dispatch({
          type: FILE_STORAGE_CREATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function fileStorageChangeCurrent(fileStorage) {
  return (dispatch) => {
    dispatch({
      type: FILE_STORAGE_CHANGE_CURRENT,
      payload: fileStorage,
    });
  };
}
export function fileStorageUpdateFetch(id) {
  return (dispatch) => {
    dispatch({
      type: FILE_STORAGE_UPDATE_FETCH,
    });
    FileStorageApi.update(id)
      .then((response) => {
        dispatch({
          type: FILE_STORAGE_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: FILE_STORAGE_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function fileStorageUpdateSubmitFetch(id, fileStorage, mode) {
  return (dispatch) => {
    dispatch({
      type: FILE_STORAGE_UPDATE_SUBMIT_FETCH,
      payload: fileStorage,
    });
    FileStorageApi.updateSubmit(id, fileStorage)
      .then(() => {
        dispatch({
          type: FILE_STORAGE_UPDATE_SUBMIT_FETCH_SUCCESS,
          // payload: response,
        });
        if (mode === 'updatePage') {
          history.push('../list');
        }

        // loadCurrentUserFetch(dispatch);
      })
      .catch((response) => {
        dispatch({
          type: FILE_STORAGE_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function FileStorageViewFetch(id) {
  return (dispatch) => {
    dispatch({ type: FILE_STORAGE_VIEW_FETCH });
    FileStorageApi.view(id)
      .then((response) => {
        dispatch({
          type: FILE_STORAGE_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: FILE_STORAGE_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function fileStorageDeleteFetch(id, pageRequest) {
  return (dispatch) => {
    dispatch({ type: FILE_STORAGE_DELETE_FETCH });
    FileStorageApi.delete(id)
      .then((response) => {
        dispatch({
          type: FILE_STORAGE_DELETE_FETCH_SUCCESS,
          payload: response,
        });
        loadFileStorageList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: FILE_STORAGE_DELETE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
