import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import JourneyListContainer from './container/JourneyListContainer';
import JourneysOverviewContainer from './container/JourneysOverviewContainer';
import JourneyAddContainer from './container/JourneyAddContainer';
import { useSelector } from 'react-redux';
import { getProductId } from '@Utils/AuthorityProvider';
import { thirdPartyEnums } from '@Modules/admin/thirdParty/components/ThirdPartyTypes';
import { journeyTypes } from '@Modules/engage/journey/model/JourneyTypes';
import { getProduct } from '@Utils/getProduct';

function Journey(props) {
  const match = useRouteMatch();
  const currentUser = useSelector((state) => state.account.auth.currentUser);
  const getType = () => {
    const product = getProduct(currentUser, getProductId(location?.pathname));

    const currentProductThirdPartyOption = product?.thirdPartyOption;

    return currentProductThirdPartyOption === thirdPartyEnums.PARENT
      ? journeyTypes.JOURNEY_TEMPLATE
      : journeyTypes.JOURNEY;
  };

  return (
    <Switch>
      <Route exact path={`${match.path}list`}>
        <JourneyListContainer key="journey-list" type={getType()} {...props} />
      </Route>
      <Route path={`${match.path}archive`}>
        <JourneyListContainer type={getType()} isArchived={true} {...props} />
      </Route>
      <Route path={`${match.path}overview`}>
        <JourneysOverviewContainer type={getType()} {...props} />
      </Route>
      <Route path={`${match.path}new`}>
        <JourneyAddContainer type={getType()} {...props} />
      </Route>
    </Switch>
  );
}

export default Journey;
