/* eslint-disable react/no-unknown-property */
import * as React from 'react';

function SvgCustom(props) {
  return (
    <svg viewBox="0 0 80 70" width="1.1em" height="1.1em" {...props}>
      <defs>
        <clipPath id="clip-path">
          <path d="M253-868h495v815H253z" className="cls-1" />
        </clipPath>
        <clipPath id="clip-path-2">
          <path d="M-335-868h495v815h-495z" className="cls-1" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)">
        <use xlinkHref="#image" transform="translate(-211 -1422)" />
      </g>
      <g clip-path="url(#clip-path-2)">
        <use xlinkHref="#image" transform="translate(-800 -1422)" />
      </g>
      <path
        fill="#fff"
        d="M41 23H15L5 13a2 2 0 0 1 2-3h20a4 4 0 0 1 3 2ZM49 12l-8 7 5 4h17l10-10a2 2 0 0 0-1-3H51a4 4 0 0 0-2 2ZM72 51l2-2a1 1 0 0 0 1-1 16 16 0 0 0-3-6 1 1 0 0 0-2 0l-3 2a13 13 0 0 0-4-3v-3a1 1 0 0 0-1-1 16 16 0 0 0-6 0 1 1 0 0 0-1 1v3a12 12 0 0 0-2 1 13 13 0 0 0-2 2l-2-2a1 1 0 0 0-2 0 16 16 0 0 0-3 6 1 1 0 0 0 0 1l3 2a12 12 0 0 0 0 4l-3 2a1 1 0 0 0 0 2 16 16 0 0 0 3 5 1 1 0 0 0 2 0l2-1a12 12 0 0 0 4 2v3a1 1 0 0 0 1 1 16 16 0 0 0 6 0 1 1 0 0 0 1-1v-3a13 13 0 0 0 3-1 12 12 0 0 0 1-2l3 2a1 1 0 0 0 2 0 16 16 0 0 0 3-5 1 1 0 0 0-1-2l-2-2a12 12 0 0 0 0-4Zm-13 9a7 7 0 1 1 7-7 7 7 0 0 1-7 7Z"
        class="cls-4"
      />
      <path
        fill="#fff"
        d="m42 55 2-1v-2l-2-1a3 3 0 0 1-1-3 19 19 0 0 1 5-8 3 3 0 0 1 3-1l2 1h1v-3a3 3 0 0 1 3-3 19 19 0 0 1 9 0v-8H15v27a4 4 0 0 0 4 4h22a3 3 0 0 1 1-2Z"
        class="cls-4"
      />
    </svg>
  );
}

export default SvgCustom;
