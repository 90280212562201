import BaseApi from '../../../api/BaseApi';
const basePath = '/admin/contract-template';
export default class ContractTemplateApi {
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static create(body) {
    return BaseApi.post(basePath + '/create', body);
  }
  static updateSubmit(id, body) {
    return BaseApi.post(basePath + '/updateSubmit/' + id, body);
  }
  static update(id) {
    return BaseApi.post(basePath + '/update/' + id);
  }
  static view(id) {
    return BaseApi.post(basePath + '/view/' + id);
  }
  static delete(id) {
    return BaseApi.post(basePath + '/delete/' + id);
  }
  static disable(id) {
    return BaseApi.post(basePath + '/disable/' + id);
  }
  static publish(id) {
    return BaseApi.post(basePath + '/publish/' + id);
  }
  static unpublish(id) {
    return BaseApi.post(basePath + '/unpublish/' + id);
  }
}
