import React from 'react';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import { history } from '../../../../redux/store';
import {
  AuthorityProvider,
  hasAnyAuthority,
} from '../../../../utils/AuthorityProvider';
import { Input, Spin } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import Card from '@Utils/Card';

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};
class RoleListComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    name: '',
  };
  componentDidMount() {
    this.props.roleListFetch({
      ...this.props.pageRequest,
      page: 0,
      name: this.state.name,
    });
  }

  viewItem(id) {
    if (hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_ROLE_VIEW)) {
      history.push(`view/${id}`);
    }
  }

  getNavigation() {
    return [
      { name: 'Home', path: '/' },
      { name: 'Role List', path: '' },
    ];
  }
  handleChangeSearch = (name) => {
    this.setState({ name });
    if (name === '' || name == null) {
      this.props.roleListFetch({
        ...this.props.pageRequest,
        name: null,
        page: 0,
      });
    }
  };
  render() {
    const columnList = [
      {
        sorter: false,
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        // ...getColumnTextSearchProps('name'),
        render: (text, record) => (
          <a onClick={() => this.viewItem(record.id)}>{text}</a>
        ),
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '-action-',
        width: '5%',
        render: (x) =>
          defaultActions(
            x,
            {
              link: 'view/' + x.id,
              title: 'View',
              enabled: hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_ROLE_VIEW),
            },
            {
              link: 'update/' + x.id,
              title: 'Edit',
              enabled: hasAnyAuthority(
                AuthorityProvider.ROLE_ADMIN_ROLE_UPDATE
              ),
            },
            {
              link: 'copy/' + x.id,
              title: 'Duplicate',
              enabled: hasAnyAuthority(
                AuthorityProvider.ROLE_ADMIN_ROLE_CREATE
              ),
            },
            {
              action: (id) =>
                this.props.roleDeleteFetch(id, this.props.pageRequest),
              title: 'Delete',
              enabled: hasAnyAuthority(
                AuthorityProvider.ROLE_ADMIN_ROLE_DELETE
              ),
            }
          ),
      },
    ];

    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;

    return (
      <div>
        <Card>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              borderBottom: 'solid 2px #f4f9ff',
            }}
          >
            <Input
              loading={true}
              allowClear={this.props.loadingRoleSearchList ? false : true}
              placeholder={'Search by Role name'}
              onPressEnter={() =>
                this.props.roleSearchListFetch({
                  ...this.props.pageRequest,
                  page: 0,
                  name: this.state.name,
                })
              }
              onChange={(e) => this.handleChangeSearch(e.target.value)}
              value={this.state.name}
              style={{ width: 300, marginRight: 30, borderRadius: 5 }}
              suffix={
                isEmpty(this.state.name) ? (
                  <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                ) : (
                  this.props.loadingRoleSearchList && (
                    <div style={styles.inputLoadingSpinStyle}>
                      <Spin indicator={inputLoadingSpin} />
                    </div>
                  )
                )
              }
            />
          </div>
          {this.props.loadingRoleList ? (
            <div style={styles.loadingSpinStyle}>
              <Spin indicator={loadingSpin} />
            </div>
          ) : (
            <ListBase
              onChange={this.props.roleListFetch}
              page={this.props.page}
              pageRequest={this.props.pageRequest}
              columns={columnList}
              // onClick={(row) => this.viewItem(row.id)}
              createLink={
                hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_ROLE_CREATE)
                  ? 'create'
                  : null
              }
            />
          )}
        </Card>
      </div>
    );
  }
}

export default RoleListComponent;
