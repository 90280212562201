import * as React from 'react';

function SvgText(props) {
  return (
    <svg viewBox="0 0 17 16" width="1em" height="1em" {...props}>
      <g fill="#fff" transform="translate(-8 -5)" fillRule="evenodd">
        <path d="M21.75 11.25v6.45a1.35 1.35 0 01-1.35 1.35h-9.45l-1.633 1.633c-.597.597-1.067.395-1.066-.435L8.257 9.6c0-.746.597-1.35 1.343-1.35h9.15a3 3 0 003 3zm-3.75 3v-1.5h-1.5v1.5H18zm-2.25 0v-1.5h-1.5v1.5h1.5zm-2.25 0v-1.5H12v1.5h1.5z" />
        <circle cx={21.85} cy={8.15} r={2.25} />
      </g>
    </svg>
  );
}

export default SvgText;
