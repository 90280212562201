import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import JourneyListContainer from './container/JourneyListContainer';
import JourneysOverviewContainer from './container/JourneysOverviewContainer';
import JourneyAddContainer from './container/JourneyAddContainer';

function Journey(props) {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}list`}>
        <JourneyListContainer key="relay-list" type="RELAY" {...props} />
      </Route>
      <Route path={`${match.path}archive`}>
        <JourneyListContainer type="RELAY" isArchived={true} {...props} />
      </Route>
      <Route path={`${match.path}overview`}>
        <JourneysOverviewContainer type="RELAY" {...props} />
      </Route>
      <Route path={`${match.path}new`}>
        <JourneyAddContainer type="RELAY" {...props} />
      </Route>
    </Switch>
  );
}

export default Journey;
