import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import SmsChannelContainer from './sms/container/SmsChannelContainer';
import EmailChannelContainer from './email/container/EmailChannelContainer';
import EmailTemplateContainer from './emailTemplate/container/EmailTemplateContainer';
import PushChannelContainer from './push/container/PushChannelContainer';
import CustomChannelContainer from './custom/container/CustomChannelContainer';
import WhatsAppChannel from './whatsAppChannel';

function channels(props) {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}sms`}>
        <SmsChannelContainer />
      </Route>
      <Route exact path={`${match.path}email`}>
        <EmailChannelContainer />
      </Route>
      <Route exact path={`${match.path}whatsapp`}>
        <WhatsAppChannel />
      </Route>
      <Route exact path={`${match.path}template`}>
        <EmailTemplateContainer />
      </Route>
      <Route exact path={`${match.path}push`}>
        <PushChannelContainer {...props} />
      </Route>
      <Route exact path={`${match.path}custom`}>
        <CustomChannelContainer />
      </Route>
    </Switch>
  );
}

export default channels;
