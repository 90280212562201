import React from 'react';
import '../../style/ProductSdkAndroidStyle.less';

const SetUpItem = (props) => {
  return (
    <div className="rows">
      <div className="number">{props.number}</div>
      <div className="text">
        {props.title}
        <span style={{ fontWeight: 'normal', color: '#6e707a' }}>
          {props.description}
          <a href={props.url} target={'_blank'} rel="noreferrer">
            here
          </a>{' '}
        </span>
      </div>
    </div>
  );
};

export default SetUpItem;
