import * as React from 'react';

function SvgArrow(props) {
  return (
    <svg viewBox="0 0 27 22" width="1em" height="1em" {...props}>
      <path
        d="M27 11.026L16.974 1l-.806.806a.997.997 0 00.002 1.401l6.752 6.753H1v2.13h21.924l-6.754 6.754a.996.996 0 00-.002 1.401l.806.806 8.52-8.52L27 11.027z"
        stroke="#FFF"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgArrow;
