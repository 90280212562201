import { showMessageError } from '@Utils/RenderUtils';
import ProductUserApi from '@Modules/track/users/UsersApi';
import TeamMember from '@Modules/integration/teamMembers/TeamMembersApi';
import { createSlice } from '@reduxjs/toolkit';
import AlertApi from './alertApi';

const initialState = {
  alertList: [],
  loading: false,
  loadingEvent: false,
  listEvents: [],
  listUsers: [],
};

export const alerts = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlertList(state, { payload }) {
      state.alertList = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setLoadingEvent(state, { payload }) {
      state.loadingEvent = payload;
    },
    setListEvents(state, { payload }) {
      state.listEvents = payload;
    },
    setListUsers(state, { payload }) {
      state.listUsers = payload;
    },
  },
});

export default alerts.reducer;

export const {
  setAlertList,
  setLoading,
  setLoadingEvent,
  setListEvents,
  setListUsers,
} = alerts.actions;

export const alertListFetch = (body) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await AlertApi.list(body);
    dispatch(setAlertList(response));
  } catch (e) {
    showMessageError(e);
  } finally {
    dispatch(setLoading(false));
  }
};

export const alertCreate =
  ({ body, cb }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await AlertApi.create(body);
      cb & cb();
    } catch (e) {
      showMessageError(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const alertView =
  ({ id, cb }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await AlertApi.view(id);
      cb && cb(response);
    } catch (e) {
      showMessageError(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const alertEdit =
  ({ id, body, cb }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await AlertApi.update(id, body);
      cb & cb();
    } catch (e) {
      showMessageError(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const alertDelete =
  ({ id, cb }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await AlertApi.delete(id);
      cb & cb();
    } catch (e) {
      showMessageError(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const alertPauseAndResume =
  ({ id, enabled, cb }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await AlertApi.view(id);
      await AlertApi.update(id, { ...response, enabled });
      cb && cb();
    } catch (e) {
      showMessageError(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const alertListEventsFetch = () => async (dispatch) => {
  dispatch(setLoadingEvent(true));
  try {
    const response = await ProductUserApi.listEvents();
    dispatch(setListEvents(response));
  } catch (e) {
    showMessageError(e);
  } finally {
    dispatch(setLoadingEvent(false));
  }
};

export const alertListTeamMembers = (body) => async (dispatch) => {
  try {
    const response = await TeamMember.allTeam(body);
    dispatch(setListUsers(response));
  } catch (e) {
    showMessageError(e);
  }
};
