/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import './ProductSettingThrottlingComponent.module.less';
import { Form, Table, InputNumber, Spin } from 'antd';
import ProductSettingFooterComponent from './ProductSettingFooterComponent';
import { findIndex, isNil, cloneDeep, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import {
  throttlingUpdateFetch,
  throttlingUpdateSubmitFetch,
} from '../ProductSettingActions';
import Card from '@Utils/Card';

const ProductSettingThrottlingComponent = () => {
  const loadingThrottlingUpdate = useSelector(
    (state) => state.setting.productSetting.loadingThrottlingUpdate
  );
  const loadingThrottlingUpdateSubmit = useSelector(
    (state) => state.setting.productSetting.loadingThrottlingUpdateSubmit
  );
  const throttling = useSelector((state) => {
    return state.setting.productSetting.throttling;
  });

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    dispatch(throttlingUpdateFetch());
  }, []);

  useEffect(() => {
    const values = {
      messagePerDayEMAIL: null,
      messagePerDayPUSH: null,
      messagePerDaySMS: null,
      messagePerDayWEB_PUSH: null,
      messagePerSecondEMAIL: null,
      messagePerSecondPUSH: null,
      messagePerSecondSMS: null,
      messagePerSecondWEB_PUSH: null,
    };

    throttling?.throttlings?.forEach((item) => {
      const prefix =
        item.interval === 'SECOND' ? 'messagePerSecond' : 'messagePerDay';
      const key = `${prefix}${item.channelType}`;
      values[key] = item.threshold;
    });
    form.setFieldsValue(values);
  }, [throttling]);

  const Channels = () => {
    const data = [
      {
        channelType: 'PUSH',
        key: 'push',
        channels: 'Push',
        threshold: '',
        messagePerSecond: null,
        messagePerDay: null,
      },
      {
        channelType: 'SMS',
        key: 'sms',
        channels: 'SMS',
        threshold: '',
        messagePerSecond: null,
        messagePerDay: null,
      },
      {
        channelType: 'EMAIL',
        key: 'email',
        channels: 'Email',
        threshold: '',
        messagePerSecond: null,
        messagePerDay: null,
      },
      {
        channelType: 'WEB_PUSH',
        key: 'webPush',
        channels: 'Web Push',
        threshold: '',
        messagePerSecond: null,
        messagePerDay: null,
      },
    ];
    if (
      throttling &&
      throttling.throttlings &&
      Array.isArray(throttling.throttlings)
    ) {
      throttling.throttlings.forEach((item) => {
        let foundIndex = 0;
        if (!isNil(item.channelType)) {
          foundIndex = findIndex(
            data,
            (o) => o.channelType === item.channelType
          );
        }

        if (foundIndex > -1) {
          if (item.interval === 'SECOND') {
            data[foundIndex].messagePerSecond = item.threshold;
          } else if (item.interval === 'DAY') {
            data[foundIndex].messagePerDay = item.threshold;
          }
        }
      });
    }
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const columns = [
      {
        title: 'CHANNELS',
        dataIndex: 'channels',
        key: 'channels',
        render: (text) => (
          <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
        ),
      },
      {
        title: 'MESSAGE PER SECOND',
        dataIndex: 'messagePerSecond',
        key: 'messagePerSecond',
        render: (text, record) => {
          return (
            <Form.Item
              style={{ borderRadius: 5 }}
              name={[`messagePerSecond${record.channelType}`]}
              hasFeedback
              dependencies={[`messagePerDay${record.channelType}`]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue(
                        `messagePerDayName${record.channelType}`
                      ) ||
                      getFieldValue(`messagePerDayName${record.channelType}`) >
                        value
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Please enter a smaller number than per day')
                    );
                  },
                }),
              ]}
            >
              <InputNumber
                min={1}
                style={{ borderRadius: 5 }}
                placeholder="No Limites"
              />
            </Form.Item>
          );
        },
      },
      {
        title: 'MESSAGES PER DAY',
        dataIndex: 'messagePerDay',
        key: 'messagePerDay',
        render: (text, record) => {
          return (
            <Form.Item
              style={{ borderRadius: 5 }}
              name={`messagePerDay${record.channelType}`}
              dependencies={[`messagePerSecond${record.channelType}`]}
              hasFeedback
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue(`messagePerSecond${record.channelType}`) ||
                      getFieldValue(`messagePerSecond${record.channelType}`) <
                        value
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Please enter a larger number than per second')
                    );
                  },
                }),
              ]}
            >
              <InputNumber
                min={1}
                style={{ borderRadius: 5 }}
                placeholder="No Limites"
              />
            </Form.Item>
          );
        },
      },
    ];
    return (
      <Card>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '12px',
            paddingBottom: 0,
            paddingLeft: '20px',
          }}
        >
          <div style={{ fontSize: 13, color: '#3d404e' }}>
            {
              'Throttling helps control the number of messages sent out per second or day through a channel.  Note that the number of messages per second should be less than the number of messages per day.'
            }
          </div>
        </div>
        {loadingThrottlingUpdate ? (
          <div className="loadingSpinStyle">
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <Table
            className={'custom-table'}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        )}
      </Card>
    );
  };

  const onFinish = (values) => {
    console.log('Received values of form:', values);

    const throttlingObject = {};
    throttling.throttlings.forEach((item) => {
      const prefix =
        item.interval === 'SECOND' ? 'messagePerSecond' : 'messagePerDay';
      const key = `${prefix}${item.channelType}`;
      throttlingObject[key] = item;
    });
    Object.keys(throttlingObject).map((key) => {
      throttlingObject[key].threshold = values[key];
    });
    const updatedVals = Object.values(throttlingObject);
    dispatch(
      throttlingUpdateSubmitFetch({
        ...throttling,
        throttlings: updatedVals?.filter(
          (item) => 'threshold' in item && item.threshold
        ),
      })
    );
  };

  return (
    <Form
      name="ProductSettingThrottlingComponent"
      form={form}
      layout="inline"
      onFinish={onFinish}
      scrollToFirstError
    >
      <div
        id={'w-33'}
        style={{ padding: '30px 30px 85px 30px', width: '100%' }}
      >
        <Channels />

        <ProductSettingFooterComponent
          loading={loadingThrottlingUpdateSubmit}
        />
      </div>
    </Form>
  );
};
export default React.memo(ProductSettingThrottlingComponent);
