import BaseApi from '../../../api/BaseApi';
import {
  authorizedForDelayedDeliveryCheck,
  authorizedForInstantDeliveryCheck,
} from '@Utils/AuthorityProvider';

const basePath = '/engagement/communication';
const basePathFile = '/base/file';
const baseWhatsApp = '/channel/whatsappTemplate';

class CommunicationApi {
  static communicationReport(id, body = {}) {
    return BaseApi.post(basePath + '/communicationReport', {
      ...body,
      communicationId: id,
    });
  }
  static segmentReport(body) {
    return BaseApi.post(basePath + '/reportSegment', body);
  }
  static engagedUsers(body) {
    return BaseApi.post(basePath + '/engagedUsers', body);
  }
  static variationReport(body) {
    return BaseApi.post(basePath + '/variationReport', body);
  }
  static create(body) {
    return BaseApi.post(basePath + '/create', body);
  }
  static delete(id) {
    return BaseApi.post(basePath + '/delete/' + id, {});
  }
  static copy(id) {
    return BaseApi.post(basePath + '/copy/' + id, {});
  }
  static launch(id) {
    return BaseApi.post(basePath + '/launch/' + id, {});
  }
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static listEmailChannels() {
    return BaseApi.post(basePath + '/listEmailChannels', {});
  }
  static listEvents() {
    return BaseApi.post(basePath + '/listEvents', {});
  }
  static listPushChannels() {
    return BaseApi.post(basePath + '/listPushChannels', {});
  }
  static listSegments() {
    return BaseApi.post(basePath + '/listSegments', {});
  }
  static listSmsChannels() {
    return BaseApi.post(basePath + '/listSmsChannels', {});
  }
  static listCustomChannels() {
    return BaseApi.post(basePath + '/listCustomChannels', {});
  }
  static listTags() {
    return BaseApi.post(basePath + '/listTags', {});
  }
  static overview(body) {
    return BaseApi.post(basePath + '/overview', body);
  }
  static splitOverview(body) {
    return BaseApi.post(basePath + '/splitOverview', body);
  }
  static pause(id) {
    return BaseApi.post(basePath + '/pause/' + id, {});
  }
  static stop(id) {
    return BaseApi.post(basePath + '/stop/' + id, {});
  }
  static test(id, body) {
    return BaseApi.post(basePath + '/test/' + id, body);
  }
  static updateAudience(id) {
    return BaseApi.post(basePath + '/updateAudience/' + id, {});
  }
  static updateAudienceSubmit(id, body) {
    return BaseApi.post(basePath + '/updateAudienceSubmit/' + id, body);
  }
  static updateConversion(id) {
    return BaseApi.post(basePath + '/updateConversion/' + id, {});
  }
  static updateConversionSubmit(id, body) {
    return BaseApi.post(basePath + '/updateConversionSubmit/' + id, body);
  }
  static updateMessage(id) {
    return BaseApi.post(basePath + '/updateMessage/' + id, {});
  }
  static updateMessageSubmit(id, body) {
    return BaseApi.post(basePath + '/updateMessageSubmit/' + id, body);
  }
  static updateWhen(id) {
    return BaseApi.post(basePath + '/updateWhen/' + id, {});
  }
  static updateWhenThrottling() {
    return BaseApi.post('/setting/channelThrottling/update', {});
  }
  static updateWhenSubmit(id, body) {
    return BaseApi.post(basePath + '/updateWhenSubmit/' + id, body);
  }
  static view(id) {
    return BaseApi.post(basePath + '/view/' + id, {});
  }
  static uploadFile(file) {
    return BaseApi.uploadFile(basePathFile + '/upload-public', file);
  }
  static archive(id) {
    return BaseApi.post(basePath + '/archive/' + id, {});
  }
  static active(id) {
    return BaseApi.post(basePath + '/active/' + id, {});
  }
  static whatsappTemplateList(body) {
    return BaseApi.post(baseWhatsApp + '/list', body);
  }
  static whatsappTemplateView(id) {
    return BaseApi.post(baseWhatsApp + '/view/' + id);
  }
  static communicationFailuresReport(body) {
    return BaseApi.post(basePath + '/communicationFailuresReport', body);
  }
  static reportCsv(body) {
    return BaseApi.downLoadFile(
      basePath +
        `/communicationReport/csv?productId=${body.productId}&communicationId=${body.communicationId}&fromDate=${body.fromDate}&toDate=${body.toDate}`
    );
  }
  static deliveryCheck(id) {
    if (authorizedForInstantDeliveryCheck()) {
      return BaseApi.post(basePath + '/deliveryCheck/' + id);
    } else if (authorizedForDelayedDeliveryCheck()) {
      return BaseApi.post(basePath + '/delayedDeliveryCheck/' + id);
    }
  }
}

export default CommunicationApi;
