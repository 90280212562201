import React from 'react';
import { Route, useRouteMatch, Switch, useHistory } from 'react-router';
import BackOfficeInvoice from './component/BackOfficeInvoice';
import BackOfficeInvoiceList from './component/BackOfficeInvoiceList';
import PagesHeader from '@Component/layout/PagesHeader';
import PagesWrapper from '@Component/layout/PagesWrapper';
import ContractDownloadInvoice from '@Modules/setting/billing/components/invoiceInfo/downloadInvoice';

function Profile() {
  let match = useRouteMatch();
  let { location } = useHistory();
  const invoiceView = location.pathname.includes('/invoice-preview');

  return (
    <>
      {!invoiceView ? (
        <>
          <PagesHeader
            title="Financial"
            hasBackButton={
              location.pathname.includes('invoice-list') ? true : false
            }
            backUrl={'/back-office/invoice'}
          />
          <PagesWrapper>
            <Switch>
              <Route exact path={`${match.path}invoice`}>
                <BackOfficeInvoice />
              </Route>
              <Route
                exact
                path={`${match.path}invoice-list/:contractId`}
                component={(props) => {
                  return <BackOfficeInvoiceList {...props} />;
                }}
              />
              <Route
                exact
                path={`${match.path}invoice-list`}
                component={(props) => {
                  return <BackOfficeInvoiceList {...props} />;
                }}
              />
            </Switch>
          </PagesWrapper>
        </>
      ) : null}
      <Route
        exact
        path={`${match.path}invoice-list/product/:productId/invoice-preview/:id`}
      >
        <ContractDownloadInvoice />
      </Route>
    </>
  );
}

export default Profile;
