import React from 'react';

// import vars from '@Theme/styles/vars.js';
const styles = {
  wrapper: {
    padding: '40px 30px',
    backgroundColor: 'white',
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px 0px',
  },
};

export default function PagesSection({ children }) {
  return <div style={styles.wrapper}>{children}</div>;
}
