import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactFlow, {
  removeElements,
  addEdge,
  MiniMap,
  ReactFlowProvider,
  Controls,
  Background,
} from 'react-flow-renderer';
import Modal from 'react-modal';
import { Modal as ModalAnt } from 'antd';
import DeleteIcon from '../../assets/IconDelete';
import CopyIcon from '../../assets/IconCopy';
import './index.less';

import ModalContent from '../../components/ModalContent/ModalContent';
import {
  EventNodes,
  JourneyObjectModelSteps as JourneyModel,
  nodePlaceHolderStyle,
  NodesInbox,
  NodeTypes,
  ON_STATIC_METHODS,
  StepsWithCamp,
} from './../../utils/static';
// import ColorSelectorNode from './ColorSelectorNode';
// import DefaultNode from "./../../components/Node/DefaultNode";
import CustomNode from '../../components/Node/customNode';
import Sidebar from './../../components/Sidebar/Sidebar';
import openSnackBar from './../../components/Atom/SnackBar';
import { AppContext } from '../../app';
import SidebarReport from '../../components/Sidebar/SidebarReport';
import { dfsSearchPrime, findMaxId, usePrevious } from '../../utils/hooks';
import CommunicationApi from '../../../../modules/engage/communication/CommunicationApi';
import CustomEdge from './../../components/Atom/CustomEdge';
import Confirm from './../../components/Atom/Confirm';
import { showMessage } from '../../../../utils/RenderUtils';
import _, { isEmpty } from 'lodash';
import { useBannerStackSize } from '@Utils/bannerStackSize';
const { confirm } = ModalAnt;

// const initBgColor = '#fff';

const connectionLineStyle = { stroke: '#000' };
const snapGrid = [16, 16];
const nodeTypes = {
  defaultNode: CustomNode,
  circleNode: CustomNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

let lockDrop = false;

// -----------------
// elements: store journey graph data (node or edge)
// appContext: Cache of server data

const JourneyFlow = (props) => {
  const appContext = useContext(AppContext);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [elements, setElements] = useState([]);
  const selectedElements = useRef([]);
  const preElements = usePrevious(elements);
  const [draggableNodes, setDraggableNodes] = useState(true);
  const [onConnectState, setOnConnectState] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalClass, setOpenModalClass] = useState(false);

  // const [stepIsRemoved, setStepIsRemoved] = useState(false);
  const [edgeIsUpdated, setEdgeIsUpdated] = useState(false);
  const [stepIsDuplicated, setStepIsDuplicated] = useState([]);
  const [stepIsReplaced, setStepIsReplaced] = useState(false);

  const [nodeSelectedModal, setNodeSelectedModal] = useState();
  const { showAlert } = props;
  const { mode, currentProduct } = props.data;

  const removeSelectionBox = () => {
    const elements = document.getElementsByClassName(
      'react-flow__nodesselection-rect'
    );
    if (elements && elements.length > 0) {
      elements[0].remove();
    }
    if (selectedElements.current && selectedElements.current.length > 0) {
      selectedElements.current = [];
    }
  };

  const createNode = (event) => {
    if (props?.readOnly) return;
    const { id, label, position, sourceHandle } = event;
    const edgeTS = elements.filter((e) => e.source && e.source === id);
    const targetNode = elements.find((e) => e.id === id)?.data.key;
    let limitationTS = true;
    if (targetNode === 'TRAFFIC_SPLITTER') {
      limitationTS = edgeTS.length < 8;
    }
    setElements((els) => {
      const maxId = findMaxId(els);
      if (limitationTS) {
        return [
          ...els,
          {
            id: `${maxId + 1}`,
            type: 'defaultNode',
            data: {
              label: 'Drag & drop Action / Condition / Flow',
              subLabel: 'Control block from sidebar',
              nodeType: NodeTypes[4],
              key: NodeTypes[4],
              updateNode: (event, data) => replaceNode(event, data),
              deleteNode: deleteNode,
            },
            style: nodePlaceHolderStyle,
            position: position,
          },
          {
            id: `${maxId + 2}`,
            source: id,
            sourceHandle: sourceHandle,
            target: `${maxId + 1}`,
            targetHandle: 'handle_central',
            animated: false,
            type: 'custom',
            // type: "straight",
            arrowHeadType: 'arrow',
            // style: { stroke: "#000" },
            label: label,
            data: { text: label },
            // data:{ onChange: onChange2}
          },
        ];
      } else if (!limitationTS) {
        openSnackBar('error', {
          message: 'Connection error!',
          description: 'The number of splitters is limited.',
        });
        return els;
      }
    });
  };

  /**
   * @description: Find Affected WaitForDate Nodes by remove edge or node
   * @param {*} entity: Edge or Node Object
   * @param {*} type: edge or node String
   * @returns: Array of WaitForDate Nodes
   */
  const findAffectedWFDNodesByRemove = (entity, type = 'edge', journeyData) => {
    try {
      const JData = journeyData || appContext.journey;
      const { steps } = JData;
      // 1- Find all WaitForDate Nodes
      const allWFDNodesWithStepId = steps.filter((step) => {
        return step.type === JourneyModel.WAIT_FOR_DATE.type && step?.stepId;
      });

      if (allWFDNodesWithStepId.length) {
        // 2- Dfs to find connection
        const nodeSource =
          type !== 'edge'
            ? steps.find((item) => item.id + '' === entity.id + '')
            : steps.find((item) => item.id + '' === entity.source + '');
        const nodeTarget =
          type === 'edge'
            ? steps.find((item) => item.id + '' === entity.target + '')
            : null;

        if (nodeSource.type === JourneyModel.WAIT_FOR_DATE.type && !nodeTarget)
          return [];

        const nodesAffected = allWFDNodesWithStepId.filter((wfNode) => {
          // direct check
          if (nodeTarget) {
            if (wfNode.id + '' === nodeTarget.id + '') {
              const isEventNode = EventNodes.find(
                (en) => en === nodeSource.type
              );
              if (isEventNode) {
                if (wfNode.stepId + '' === nodeSource.id + '') {
                  return true;
                } else {
                  return false;
                }
              } else {
                // must be check exist connection stepId by nodeSource
                const evNode = steps.find(
                  (m) => m.id + '' === wfNode.stepId + ''
                );
                const hasConnection1 = dfsSearchPrime(
                  evNode,
                  nodeSource,
                  JData,
                  true
                );
                console.log('evNode====', evNode, hasConnection1);

                return hasConnection1;
              }
            }
          } else if (wfNode.stepId + '' === nodeSource.id + '') {
            return true;
          }
          // if (wfNode.id + '' === nodeSource.id + '') {
          //   return true;
          // }

          const hasConnection = dfsSearchPrime(
            nodeTarget || nodeSource, // source
            wfNode, // target WF_node
            JData
            // type
          );
          return hasConnection;
        });

        return nodesAffected;
      } else {
        return [];
      }
    } catch (error) {
      // TODO GrayLog
      console.log('error -->>', error);
      return [];
    }
  };
  const findAffectedTSNodesByRemove = (entity, type = 'edge', journeyData) => {
    try {
      const JData = journeyData || appContext.journey;
      const { steps } = JData;
      const TrafficSplitterNode = steps.filter((step) => {
        if (step.type === JourneyModel.TRAFFIC_SPLITTER.type) {
          var numberArrayOn = step?.on.map(Number);
          if (type === 'edge') {
            return numberArrayOn.includes(+entity.target);
          } else {
            return numberArrayOn.includes(+entity.id);
          }
        }
      });
      return TrafficSplitterNode;
    } catch (error) {
      // TODO GrayLog
      console.log('error -->>', error);
      return [];
    }
  };

  const resetTSConfirm = (
    entity,
    stepsThatUsed,
    deleteFunctionCallback,
    type = 'edge'
  ) => {
    const ids = stepsThatUsed.map((m) => {
      return 'State-' + m.id;
    });
    const uniqueIds = [...new Set(ids)];
    confirm({
      width: '50vw',
      title: `Warning to delete this ${type}`,
      content: `Remove this ${type} has an effect on "Traffic Splitter(s)".
      Block with following state have been reset: ${uniqueIds.toString()}.
      Please reconfigure them before publishing the journey.`,
      onOk() {
        removeSelectionBox();
        let entityId;
        if (type === 'edge') {
          entityId = +entity.target;
        } else {
          entityId = +entity[0].id;
        }

        let stepsMustUpdate = [];
        stepsThatUsed.forEach((m) => {
          const { ui, on, onWeights } = m;
          // Remove edge
          let edges = Object.assign({}, ui?.edges);
          Object.keys(edges || {}).forEach((key) => {
            if (Number(edges[key]?.target) === entityId) {
              delete edges[key];
            }
          });
          //Remove on&onWeight
          var numberArrayOn = on.map(Number);
          const index = numberArrayOn.indexOf(entityId);
          let onWeightsUpdated = [...onWeights];
          let onUpdated = [...on];
          if (index > -1) {
            onWeightsUpdated.splice(index, 1);
            onUpdated.splice(index, 1);
          }
          //Remove Traffic info
          const traffic = Object.assign({}, ui?.traffic);
          if (!isEmpty(traffic)) {
            delete traffic[entityId];
          }
          const step = appContext.journey.steps.find(
            (step) => step.id === m.id
          );
          let step_ = Object.assign({}, step, {
            on: onUpdated,
            onWeights: onWeightsUpdated,
            ui: Object.assign({}, ui, { traffic, edges }),
          });
          stepsMustUpdate.push(step_);
        });

        const callbackFunc = (dataPrime) => {
          if (stepsMustUpdate.length) {
            appContext.syncCache(
              'update-step',
              stepsMustUpdate,
              true,
              null,
              dataPrime
            );
          }
        };

        if (type === 'edge') {
          deleteFunctionCallback(false, entity);
          appContext.syncCache(
            'delete-edge',
            { edge: entity },
            false,
            callbackFunc,
            null
          );
        } else {
          deleteFunctionCallback(false, entity).then((r) => {
            setElements(r);
            appContext.syncCache(
              'delete-step',
              { id: entity.map((item) => item.id) },
              false,
              callbackFunc,
              null
            );
          });
        }
      },
      onCancel() {},
    });
  };

  const resetConfirm = (
    entity,
    stepsThatUsed,
    deleteFunctionCallback,
    type = 'edge'
  ) => {
    const ids = stepsThatUsed.map((m) => {
      return 'State-' + m.id;
    });
    const uniqueIds = [...new Set(ids)];

    confirm({
      width: '50vw',
      title: `Warning to delete this ${type}`,
      content: `Remove this ${type} has an effect on "Wait For Date block(s)".
      Blocks with following states have been reset: ${uniqueIds.toString()}.
      Please reconfigure them before publishing the journey.`,
      onOk() {
        removeSelectionBox();
        let stepsMustUpdate = [];
        stepsThatUsed.forEach((m) => {
          const step2 = appContext.journey.steps.find(
            (step) => step.id + '' === m.id + ''
          );
          let step_ = Object.assign({}, step2, {
            stepId: null,
            waitForAttributeType: 'USER_ATTRIBUTE',
            eventAttribute: null,
            delayMinutes: null,
            bufferMinutes: null,
            userAttribute: null,
          });
          stepsMustUpdate.push(step_);
        });

        const callbackFunc = (dataPrime) => {
          if (stepsMustUpdate.length) {
            // batch update
            appContext.syncCache(
              'update-step',
              stepsMustUpdate,
              true,
              null,
              dataPrime
            );
          }
        };

        if (type === 'edge') {
          deleteFunctionCallback(false, entity);
          appContext.syncCache(
            'delete-edge',
            { edge: entity },
            false,
            callbackFunc,
            null
          );
        } else {
          deleteFunctionCallback(false, entity).then((r) => {
            setElements(r);
            appContext.syncCache(
              'delete-step',
              { id: entity.map((item) => item.id) },
              false,
              callbackFunc,
              null
            );
          });
        }
      },
      onCancel() {},
    });
  };

  const deleteNode = async (journeyData, id) => {
    const ids = [].concat(id || []);
    if (!props?.readOnly) {
      // Get Last Elements
      const promiseElements = await new Promise((resolve) => {
        setElements((data) => {
          resolve(data);
          return data;
        });
      });

      const deleteFunction = async (syncRemote = true, nodeDetail) => {
        const nodeDetails = [].concat(nodeDetail || []);
        if (syncRemote) {
          appContext.syncCache('delete-step', { id: ids }, true);
        }
        const nodeDetailsKeys = nodeDetails.map(
          (nodeDetail) => nodeDetail.data.key
        );
        if (
          StepsWithCamp.filter((key) => nodeDetailsKeys.includes(key)).length >
            0 &&
          appContext.journey.status === 'DRAFT'
        ) {
          nodeDetails.forEach((nodeDetail) => {
            if (nodeDetail.data.step?.communication) {
              // if needed show alert: not have comId
            }
          });
        }
        // 1- remove the node
        let elementsFiltered = promiseElements.filter(
          (item) => !ids.includes(item.id)
        );
        // 2- remove edges of node (node is source or target)
        elementsFiltered = elementsFiltered.filter((item) => {
          if (item.type === 'custom') {
            if (
              Number(item.source) === Number(id) ||
              Number(item.target) === Number(id)
            ) {
              return false;
            }
          }
          return true;
        });
        return elementsFiltered;
      };
      const nodeDetails = promiseElements.filter((item) =>
        ids.includes(item.id)
      );
      // * Find nodes that must be reset when this node delete
      // * -> WAIT_FOR_DATE info is depend on WAIT_FOR_EVENT and OCC_EVENT
      let nodesMustBeReset = [];
      ids.forEach((id) => {
        const nodeDetail = nodeDetails.find((item) => item.id === id);
        let tempNodesMustBeReset = findAffectedWFDNodesByRemove(
          nodeDetail,
          'node',
          journeyData
        );
        nodesMustBeReset = nodesMustBeReset.concat(tempNodesMustBeReset);
      });
      // Traffic Spliter
      let nodesTSMustBeReset = [];
      ids.forEach((id) => {
        const nodeDetail = nodeDetails.find((item) => item.id === id);
        let tempNodesTSMustBeReset = findAffectedTSNodesByRemove(
          nodeDetail,
          'node',
          journeyData
        );
        nodesTSMustBeReset = nodesTSMustBeReset.concat(tempNodesTSMustBeReset);
      });

      if (nodesTSMustBeReset.length) {
        resetTSConfirm(nodeDetails, nodesTSMustBeReset, deleteFunction, 'node');
      } else if (nodesMustBeReset.length) {
        resetConfirm(nodeDetails, nodesMustBeReset, deleteFunction, 'node');
      } else {
        Confirm({
          title: 'Confirm to delete',
          icon: null,
          message: 'Are you sure you want to delete selected node(s)?',
          actionSuccess: () => {
            deleteFunction(true, nodeDetails).then((r) => {
              setElements(r);
            });
            removeSelectionBox();
          },
        });
      }
    }
  };

  const deleteEdge = async (id) => {
    try {
      if (!props.readOnly) {
        // Edge should not for placeholder node
        const edge = elements.find((item) => item.id === id);

        const isTargetPlaceHolder = elements.find(
          (elm) => elm.id === edge.target && elm.data?.key === 'PLACE_HOLDER'
        );
        if (isTargetPlaceHolder) {
          openSnackBar('warning', {
            description: "The Placeholder`s edge can't be removed!",
          });
          return;
        }
        // const edge = elements.find((item) => item.id === id);
        // * Find nodes that must be reset when this edge delete
        // * -> WAIT_FOR_DATE info is depend on WAIT_FOR_EVENT and OCC_EVENT
        const nodesMustBeReset = await findAffectedWFDNodesByRemove(edge);
        const nodesTSMustBeReset = await findAffectedTSNodesByRemove(edge);
        const deleteFunction = async (syncRemote = true) => {
          setElements((els_) => {
            let elementsFiltered = els_.filter((item) => item.id !== id);
            return elementsFiltered;
          });
          if (syncRemote) {
            appContext.syncCache('delete-edge', { edge }, true);
          }
        };
        if (nodesTSMustBeReset.length) {
          resetTSConfirm(edge, nodesTSMustBeReset, deleteFunction);
        } else if (nodesMustBeReset.length) {
          // show confirm reset
          resetConfirm(edge, nodesMustBeReset, deleteFunction);
        } else {
          Confirm({
            title: 'Confirm to delete edge',
            icon: null,
            message: 'Are you sure you want to delete this edge?',
            actionSuccess: async () => {
              deleteFunction();
            },
          });
        }
      }
    } catch (error) {
      // TODO GrayLog
      console.log('error->>>', error);
    }
  };

  const deleteSelection = () => {
    const ids = [];
    selectedElements.current.forEach((element) => {
      if (element.type === 'defaultNode') {
        ids.push(element.id);
      }
    });
    if (ids.length > 0) {
      deleteNode(appContext.journey, ids);
    }
  };
  const duplicateSelection = () => {
    const nodeIds = [];
    selectedElements.current.forEach((element) => {
      if (element.type === 'defaultNode') {
        nodeIds.push(element.id);
      }
    });
    const edgeIds = [];
    selectedElements.current.forEach((element) => {
      if (
        element.type === 'custom' &&
        nodeIds.includes(element.source) &&
        nodeIds.includes(element.target)
      ) {
        edgeIds.push(element.id);
      }
    });
    if (nodeIds.length > 0) {
      duplicateNode(nodeIds, edgeIds);
    }
  };
  // const asyncRemoveCampaign = (id) => {
  //   CommunicationApi.delete(id).then(() => {});
  // };
  const duplicateNode = async (id, edgeIds = null) => {
    if (!props?.readOnly) {
      const ids = [].concat(id || []);
      setElements((els) => {
        const maxId = findMaxId(els);
        const newNodesId = ids.map((id, index) => {
          return { id: `${maxId + index + 1}`, duplicateFromId: id };
        });
        const nodes = els.filter((item) => ids.includes(item.id));
        let edges = [];
        if (edgeIds && Array.isArray(edgeIds)) {
          edges = els.filter((item) => edgeIds.includes(item.id));
        }

        // async create campaign
        const commDuplicatePromises = [];
        nodes.forEach((element) => {
          if (StepsWithCamp.find((key) => element?.data?.key === key)) {
            // setLoading
            // read from cache if is new
            const appContext_ = useContext(AppContext);
            const cacheNodeData = appContext_.journey?.steps.find(
              (step) => step.id + '' === element.id
            );

            const commID =
              element?.data?.step?.communication || cacheNodeData.communication;
            if (commID) {
              commDuplicatePromises.push({
                request: CommunicationApi.copy(commID),
                id: element.id,
              });
            } else {
              showMessage('Relevant campaign not found', 'warning');
              // return els
            }
          }
        });
        if (commDuplicatePromises.length > 0) {
          Promise.all(commDuplicatePromises.map((item) => item.request)).then(
            (responses) => {
              setStepIsDuplicated(
                responses.map((response, index) => {
                  return {
                    campId: response.id,
                    nodeId: newNodesId.find(
                      (n) =>
                        n.duplicateFromId + '' ===
                        commDuplicatePromises[index].id
                    ).id,
                    duplicateFromId: commDuplicatePromises[index].id,
                  };
                })
              );
            }
          );
        }
        const duplicatedNodes = [];
        nodes.forEach((element) => {
          duplicatedNodes.push(
            Object.assign(
              {},
              { ...element },
              {
                id: newNodesId.find(
                  (n) => n.duplicateFromId + '' === element.id
                ).id,
                position: {
                  x: element.position.x + 20,
                  y: element.position.y + 20,
                },
                duplicateFromId: element.id,
              }
            )
          );
        });
        let newElements = [...els, ...duplicatedNodes];
        edges.forEach((element, index) => {
          newElements = addEdge(
            Object.assign(
              {},
              { ...element },
              {
                id: `${maxId + 1 + index + duplicatedNodes.length}`,
                source: duplicatedNodes.find(
                  (item) => item.duplicateFromId + '' === element.source + ''
                ).id,
                target: duplicatedNodes.find(
                  (item) => item.duplicateFromId + '' === element.target + ''
                ).id,
                duplicateFromId: element.id,
              }
            ),
            newElements
          );
        });
        return [...newElements];
      });
    }
  };

  const createEdge = () => {};

  const onSelectionChange = (elements) => {
    if (elements && Array.isArray(elements)) {
      let journeyElements = elements;
      if (props.type === 'RELAY') {
        journeyElements = journeyElements.filter(
          (item) => item.data?.key !== 'BUSINESS_EVENT_OCCURRENCE'
        );
      }
      selectedElements.current = journeyElements;
    } else {
      selectedElements.current = [];
    }
  };

  const onSelectionDragStop = (event, draggedNodes) => {
    setElements((els) => {
      return els.map((nd) => {
        const node = draggedNodes.find(
          (draggedNode) => draggedNode.id + '' === nd.id + ''
        );
        if (node) {
          return Object.assign({}, nd, { position: node.position });
        } else {
          return nd;
        }
      });
    });

    const currentSteps = [];

    draggedNodes.forEach((draggedNode) => {
      let currentStep = appContext.journey?.steps.find(
        (step) => step.id + '' === draggedNode.id + ''
      );
      if (currentStep?.ui) {
        currentStep.ui.position = draggedNode.position;
      }
      if (currentStep) {
        currentSteps.push(currentStep);
      }
    });
    appContext.syncCache('update-step', currentSteps);
  };

  // Open modal
  const onElementClick = (event, element) => {
    if (props.loading) {
      openSnackBar('warning', {
        message: 'Please be patient!',
        description: 'Please wait for journey to load.',
      });
      return;
    }

    // if(props?.readOnly) return
    try {
      if (element.type === 'custom') {
        deleteEdge(element.id);
        return;
      }
      if (element?.data?.key === 'END_JOURNEY') return;
      if (element?.data?.nodeType === NodeTypes[4]) return;

      if (event.srcElement) {
        if (
          event.srcElement.className &&
          typeof event.srcElement.className === 'string' &&
          (event.srcElement.className.includes('box-node__type__text') ||
            event.srcElement.className.includes('default-node__text__sub'))
        ) {
          setNodeSelectedModal(element);
          const modalFilled =
            document
              .querySelector('.ant-layout-sider-children')
              .getBoundingClientRect().width < 100;
          setOpenModalClass(modalFilled ? 'dnd-modal-filled' : '');
          setOpenModal(true);
        }
      } else if (props.readOnly) {
        setNodeSelectedModal(element);
        const modalFilled =
          document
            .querySelector('.ant-layout-sider-children')
            .getBoundingClientRect().width < 100;
        setOpenModalClass(modalFilled ? 'dnd-modal-filled' : '');
        setOpenModal(true);
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  // Drag other node to placeholder
  const replaceNode = (event, data) => {
    setElements((els) => {
      const edge_ = els.find(
        (elm) =>
          elm.type === 'custom' &&
          elm.target + '' === data.placeHolderNodeInfo.id + ''
      );
      // setEdgeIsUpdated(edgeMustBeUpdate)
      setStepIsReplaced(edge_);

      lockDrop = true;
      const placeHolderNode = els.find(
        (item) => item.id === data.placeHolderNodeInfo.id
      );
      const elms = els.slice();
      let placeholderIndex = elms.findIndex(
        (item) => item.id === data.placeHolderNodeInfo.id
      );

      let node = nodesInbox.find((item) => item.data.key === data.type);
      if (node.data.nodeType !== NodeTypes[0]) {
        node.id = placeHolderNode.id;

        elms[placeholderIndex] = {
          ...node,
          position: placeHolderNode.position,
        };
        // 3 elements must be update in cache
        return elms;
      } else {
        openSnackBar('error', {
          message: 'Connection error!',
          description: "Triggers don't allow incoming connection.",
        });
        return els;
      }
    });
  };

  const updateNodeByModal = (data) => {
    setElements((els) => {
      const elms = els.slice();
      elms.map((el) => {
        if (el.id === data.id) {
          // it's important that you create a new object here
          // in order to notify react flow about the change
          el.data = {
            ...el.data,
            userLabel: data.label,
          };
        }
        return el;
      });
      return elms;
    });
    setTimeout(() => {
      setOpenModal(false);
    }, 1000);
  };

  const nodesInbox = NodesInbox(
    createNode,
    createEdge,
    deleteNode,
    duplicateNode,
    updateNodeByModal,
    replaceNode
  );

  /** INIT JOURNEY
   *
  - Description: Convert server data to journey graph
  */
  useEffect(() => {
    if (props.readOnly) {
      setDraggableNodes(false);
    }
    //note: Just on response of update props.data.journey filled
    if (props.data.journey?.steps) {
      convertApiDataToJourneyGraph(props.data.journey?.steps);
    }
    // else if (appContext?.journey?.steps.length){
    //   convertApiDataToJourneyGraph(appContext.journey?.steps);
    // }
  }, [props.data.journey]);
  // }, [props.data.journey, appContext?.journey]);

  useEffect(() => {
    const handleKeyUp = (e) => {
      if (!e.shiftKey && e.ctrlKey && e.keyCode == 90) {
        removeSelectionBox();
        appContext.syncCache('undo-step');
      }
      if (e.shiftKey && e.ctrlKey && e.keyCode == 90) {
        removeSelectionBox();
        appContext.syncCache('redo-step');
      }
    };
    const handleMouseUp = () => {
      setTimeout(() => {
        const elements = document.getElementsByClassName(
          'react-flow__nodesselection-rect'
        );
        if (elements && elements.length > 0) {
          const nodes = selectedElements.current.filter(
            (item) => item.type === 'defaultNode'
          );
          if (nodes.length > 0) {
            elements[0].innerHTML = document.getElementsByClassName(
              'selection_rect_icons'
            )[0].outerHTML;
            document
              .getElementsByClassName('selection_rect_trash')[0]
              .addEventListener('click', () => deleteSelection());
            document
              .getElementsByClassName('selection_rect_copy')[0]
              .addEventListener('click', () => duplicateSelection());
          }
        }
      }, 100);
    };
    if (!props.readOnly) {
      window.document.addEventListener('mouseup', handleMouseUp);
      window.document.addEventListener('keyup', handleKeyUp);
      return () => {
        window.document.removeEventListener('mouseup', handleMouseUp);
        window.document.removeEventListener('keyup', handleKeyUp);
      };
    }
  }, []);

  useEffect(() => {
    if (
      props.type === 'RELAY' &&
      !props?.data?.journeyId &&
      !props.data.journey?.steps.length
    ) {
      const position = {
        x: 100,
        y: 200,
      };
      const node = nodesInbox.find(
        (item) => item.data.key === 'BUSINESS_EVENT_OCCURRENCE'
      );
      const maxId = findMaxId(elements);
      const newNode = {
        id: `${maxId + 1}`,
        ...node,
        position,
      };
      setElements((es) => es.concat(newNode));
    }
  }, []);

  /** SYNC MANAGER
   *
  - Description: After each update on elements sync
                by cache or server(optional)
  - Functions:
  -- 1- Sync Delete step
  -- 2- Sync Edge updated
  -- 3- Sync New step (node or edge)
  */
  useEffect(() => {
    // if (stepIsRemoved) {
    // appContext.syncCache('delete-step', { id: stepIsRemoved }, true);
    // setStepIsRemoved(false);
    // } else
    if (edgeIsUpdated) {
      let nodeMustBeUpdated = appContext.journey?.steps.find(
        (step) => step.id + '' === edgeIsUpdated.source
      );

      const nodeData = nodeMustBeUpdated[edgeIsUpdated.sourceHandle] || [];
      nodeMustBeUpdated[edgeIsUpdated.sourceHandle] = [
        ...nodeData,
        edgeIsUpdated.target,
      ];
      // assign more details about edges to node ui
      nodeMustBeUpdated['ui'] = {
        ...nodeMustBeUpdated['ui'],
        edges: Object.assign({}, nodeMustBeUpdated['ui']?.edges || {}, {
          [edgeIsUpdated.id]: edgeIsUpdated,
        }),
      };

      appContext.syncCache('update-step', nodeMustBeUpdated);
      setEdgeIsUpdated(false);
    } else if (stepIsReplaced) {
      const { target } = stepIsReplaced;
      // edge and target must be add to cache
      const newTargetNode = elements.find((item) => item.id === target);
      updateNodeToCache(newTargetNode);
      setStepIsReplaced(false);
    } else if (elements?.length && elements?.length !== preElements?.length) {
      // new element
      if (elements.length > preElements.length) {
        const newElmsSize = elements.length - preElements.length;
        Array(newElmsSize)
          .fill('')
          .forEach((item, i) => {
            // const newElement = elements[elements.length - 1];
            const newElement = elements[preElements.length + i];
            // check not exist in context
            if (newElement.type === 'defaultNode') {
              // placeholder node not saved on remote
              addNewNodeToCache(newElement);
            } else if (newElement.type === 'custom') {
              addNewEdgeCache(newElement);
            }
          });
      }
    }
  }, [elements]);

  useEffect(() => {
    if (!props?.data?.journeyId && props.setLoadingFitView) {
      props.setLoadingFitView(false);
    }
    if (
      reactFlowInstance &&
      elements.length > 0 &&
      props?.data?.events &&
      props.data.journey &&
      props.setLoadingFitView
    ) {
      props.setLoadingFitView(false);
      reactFlowInstance.fitView();
    }
  }, [props.data.journey, props?.data?.events]);

  const addNewNodeToCache = (newElement) => {
    // Server supported 2021-nov-01
    // if(newElement?.data?.nodeType === NodeTypes[4]) return

    // Check is duplicated
    let dNode = {}; // contain info of duplicated node
    let duplicateFromId = newElement.duplicateFromId;
    if (duplicateFromId) {
      const steps = appContext.journey.steps;
      dNode = Object.assign(
        {},
        steps.find((step) => Number(step.id) === Number(duplicateFromId))
      );
      // clear edges
      if (!_.isEmpty(dNode)) {
        ON_STATIC_METHODS.forEach((onMth) => {
          // eslint-disable-next-line no-prototype-builtins
          if (dNode.hasOwnProperty(onMth)) {
            dNode[onMth] = [];
          }
        });
      }
    }

    const ui_ = {
      id: newElement.id,
      position: newElement.position,
      label: newElement.data.label,
      key: newElement.data.key,
      nodeType: newElement.data.nodeType,
      duplicateFromId,
      predicate: dNode?.ui?.predicate,
    };

    appContext.syncCache(
      'new-step',
      Object.assign({}, JourneyModel[newElement?.data?.key], dNode, {
        id: Number(newElement.id),
        type: newElement.data.key,
        ui: Object.assign({}, ui_),
        // ui: Object.assign({}, ui_, {more: dNode?.ui?.more}),
      })
    );
  };

  const addNewEdgeCache = (newElement) => {
    const isExistEdge = appContext.journey?.steps.find((step) => {
      if (step.ui?.edges) {
        return Object.keys(step.ui.edges).find(
          (edgeId) => edgeId + '' === newElement.id + ''
        );
      } else {
        return false;
      }
    });
    if (isExistEdge) {
      return;
    }
    const { source, target, sourceHandle } = newElement;

    // check target not placeholder
    // const isTargetPlaceholder = elements.find(
    //     (elem) => elem.id + '' === target
    // );
    // if (isTargetPlaceholder?.data?.nodeType === NodeTypes[4]) return;

    // const nodeSource = elements.find((m) => m.id === source);
    let nodeMustBeUpdated = appContext.journey?.steps.find(
      (step) => step.id + '' === source
    );

    if (nodeMustBeUpdated) {
      if (sourceHandle) {
        // sourceHandle = "onSend" || "onDelivery" || ...
        // detect sourceHandle [onSend, onDelivery , ...]
        const nodeData = nodeMustBeUpdated[sourceHandle] || [];
        nodeMustBeUpdated[sourceHandle] = [...nodeData, target];
        // assign more details about edges to node ui
        nodeMustBeUpdated['ui'] = {
          ...nodeMustBeUpdated['ui'],
          edges: Object.assign({}, nodeMustBeUpdated['ui']?.edges || {}, {
            [newElement?.id]: newElement,
          }),
        };
      }
      appContext.syncCache('update-step', nodeMustBeUpdated);
    }
  };

  const updateNodeToCache = (newElement) => {
    // Check is duplicated
    let dNode = {}; // contain info of duplicated node
    let duplicateFromId = newElement.duplicateFromId;
    if (duplicateFromId) {
      const steps = appContext.journey.steps;
      dNode = Object.assign(
        {},
        steps.find((step) => Number(step.id) === Number(duplicateFromId))
      );
      // clear edges
      if (!_.isEmpty(dNode)) {
        ON_STATIC_METHODS.forEach((onMth) => {
          // eslint-disable-next-line no-prototype-builtins
          if (dNode.hasOwnProperty(onMth)) {
            // dNode[onMth] = []
          }
        });
      }
    }

    const ui_ = {
      id: newElement.id,
      position: newElement.position,
      label: newElement.data.label,
      key: newElement.data.key,
      nodeType: newElement.data.nodeType,
      duplicateFromId,
      predicate: dNode?.ui?.predicate,
    };

    appContext.syncCache(
      'update-step',
      Object.assign({}, JourneyModel[newElement?.data?.key], dNode, {
        id: Number(newElement.id),
        type: newElement.data.key,
        ui: Object.assign({}, ui_),
      })
    );
  };

  /** DUPLICATE SYNC MANAGER
     - Description: Duplicated node if one of statics.StepsWithCamp type
    -              communication created async, then need update step
    */
  useEffect(() => {
    if (stepIsDuplicated.length > 0) {
      // UPDATE ELEMENTS
      setElements((els) => {
        stepIsDuplicated.forEach((item) => {
          const campNode_ = Object.assign({}, item);
          let node_ = els.find(
            (node) => String(node.id) === String(campNode_.nodeId)
          );
          let nodeIndex = els.findIndex(
            (node) => String(node.id) === String(campNode_.nodeId)
          );
          let nodeUpdated = Object.assign({}, node_, {
            data: Object.assign({}, node_.data, {
              step: Object.assign({}, node_.data.step, {
                communication: campNode_.campId,
              }),
            }),
          });
          els[nodeIndex] = nodeUpdated;
        });
        return els;
      });

      // SYNC WITH CACHE AND SERVER
      const updatedNodes = [];
      stepIsDuplicated.forEach((item) => {
        let duplicateFromId = item.duplicateFromId;
        if (duplicateFromId) {
          const steps = appContext.journey.steps;
          let nodeI = steps.find(
            (step) => Number(step.id) === Number(item.nodeId)
          );
          if (nodeI && StepsWithCamp.find((key) => key === nodeI.type)) {
            nodeI['communication'] = item.campId; //newElement.data.step.communication
            updatedNodes.push(nodeI);
          }
        }
      });
      if (updatedNodes.length > 0) {
        appContext.syncCache('update-step-when-duplicate', updatedNodes);
      }
      setStepIsDuplicated([]);
    }
  }, [stepIsDuplicated]);

  const convertApiDataToJourneyGraph = (steps) => {
    if (steps && steps.length > 0) {
      // -----> nodes
      let nodes = steps.map((step) => {
        const position = step.ui.position;
        const duplicateFromId = step.ui?.duplicateFromId;
        const node = nodesInbox.find((item) => item.data.key === step.type);
        if (node) {
          const newNode = {
            id: `${step.id}`,
            ...node,
            data: Object.assign({}, node.data, {
              step: {
                event: step.event,
                communication: step.communication,
              },
              analyticInfo: step.analyticInfo,
              history: props.history,
            }),
            position,
            duplicateFromId,
          };
          // console.log('compare------', newNode, nodeElement, _.isEqual(newNode, nodeElement));
          return newNode;
        } else {
          return {
            id: false,
          };
        }
      });

      // const placeholderNodes = elements.filter(
      //     (item) => item.data.nodeType === NodeTypes[4]
      // );
      // const placeholderEdges = elements.filter(
      //     (item) => !!placeholderNodes.find((m) => m.id === item.target)
      // );

      const nodes_ = nodes.filter((item) => item.id !== false);

      if (nodes_.length !== steps.length) {
        showAlert({
          type: 'warning',
          message:
            'Displayed Incomplete Journey: One or more steps not supported on this Journey (Contact by admin)',
        });
        // showMessage("One or more steps not support on this Journey", "warn", "Displayed Incomplete Journey")
      }

      // -----> edges
      let edgesElements_ = [];
      steps
        .filter((step) => !_.isEqual(step.ui?.edges))
        .map((step) => {
          const edges = step.ui.edges;

          Object.keys(edges).map((edgeKey, j) => {
            let edge_ = edges[edgeKey];
            const targetsOfEdgeOnServer = step[edge_.label] || [];
            if (checkEdgeUiExitOnServer(targetsOfEdgeOnServer, edge_)) {
              if (_.isUndefined(_.get(edge_, 'data.countSameEdge'))) {
                edge_ = _.set(edge_, 'data.countSameEdge', j++);
              }
              edgesElements_.push(edge_);
            } else {
              // have conflict
              console.error('conflict-data---', edgeKey);
            }
          });
        });
      let elms_ = [];
      try {
        elms_ = [...nodes, ...edgesElements_].sort((a, b) => {
          return Number(a.id) > Number(b.id) ? 1 : -1;
        });
      } catch (e) {
        // console.log('place------error', e);
      }
      // console.log('error', nodes);
      setElements(elms_);
    } else if (steps && steps.length === 0) {
      setElements([]);
    }
  };

  /*
  const checkEdgeServerExistOnUi = (targetsOfEdgeOnServer, edge, edges) => {
    const edgeFiltered = Object.keys(edges).filter((edgeKey, j) => {
      let edge_ = edges[edgeKey];
      if (edge_.label === edge.label) {
        return true;
      }
      return false;
    });

    const edgeFilteredTarget = edgeFiltered.map((key) => {
      const edgeI = Object.keys(edges)[key];
      return Number(edgeI.target);
    });

    let isOk = true;
    isOk = targetsOfEdgeOnServer.find((ed) => {
      if (edgeFilteredTarget.find((m) => m === ed)) {
        return true;
      }
      return false;
    });
  }; */

  const checkEdgeUiExitOnServer = (targetsOfEdgeOnServer, edge_) => {
    return (
      targetsOfEdgeOnServer.findIndex(
        (tg) => Number(tg) === Number(edge_.target)
      ) >= 0
    );
  };

  const onLoad = (_reactFlowInstance) => {
    setReactFlowInstance(_reactFlowInstance);
    _reactFlowInstance.fitView();
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (event) => {
    event.preventDefault();
    const type = event.dataTransfer.getData('application/reactflow');
    if (!lockDrop) {
      const position = reactFlowInstance.project({
        x: event.clientX - 450,
        y: event.clientY - 150,
      });
      const node = nodesInbox.find((item) => item?.data?.key === type);
      if (!node) return;
      const maxId = findMaxId(elements);
      const newNode = {
        id: `${maxId + 1}`,
        ...node,
        position,
      };

      setElements((es) => es.concat(newNode));
    } else {
      lockDrop = false;
    }
  };

  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  // TOP connect place of node
  const onConnect = () => {};

  const onConnectDrop = (params) => {
    if (props?.readOnly) return;
    const edgeTS = elements.filter(
      (e) => e.source && e.source === params.source
    );
    const targetNode = elements.find((e) => e.id === params.source)?.data.key;
    let limitationTS = true;
    if (targetNode === 'TRAFFIC_SPLITTER') {
      limitationTS = edgeTS.length < 8;
    }
    setElements((els) => {
      const isExistConnection = els.find(
        (em) =>
          em.target &&
          em.target === params.target &&
          em.sourceHandle === params.sourceHandle &&
          em.source === params.source
      );
      // prevents create edge
      const isTargetPlaceholder = els.find(
        (em) => em.id === params.target && em.data.nodeType === NodeTypes[4]
      );
      const isTargetTriggerNode = els.find(
        (em) => em.id === params.target && em.data.nodeType === NodeTypes[0]
      );

      const maxId = findMaxId(els);
      const params_ = Object.assign({}, params);

      // count number edges between two nodes
      const countEdge = els.filter((elm) => {
        if (elm.target === params.target && elm.source === params.source) {
          return elm;
        }
      });

      if (
        !isExistConnection &&
        !isTargetPlaceholder &&
        !isTargetTriggerNode &&
        limitationTS
      ) {
        return addEdge(
          {
            id: `${maxId + 1}`,
            ...params_,
            animated: false,
            // style: { stroke: "#000" },
            arrowHeadType: 'arrow',
            // type: "straight",
            type: 'custom',
            data: {
              text: params.label,
              countSameEdge: countEdge.length,
            },
            targetHandle: 'handle_central',
          },
          els
        );
      } else if (isTargetTriggerNode) {
        openSnackBar('error', {
          message: 'Connection error!',
          description: "Triggers don't allow incoming connection.",
        });
        return els;
      } else if (isTargetPlaceholder) {
        openSnackBar('error', {
          message: 'Connection error!',
          description: "Can't link to placeholder.",
        });
        return els;
      } else if (isExistConnection) {
        openSnackBar('error', {
          message: 'Connection error!',
          description: 'Connection already exists.',
        });
        return els;
      } else if (!limitationTS) {
        openSnackBar('error', {
          message: 'Connection error!',
          description: 'The number of splitters is limited.',
        });
        return els;
      }
    });
  };

  const onNodeDrag = () => {};

  const onNodeDragStop = (event, node) => {
    setElements((els) => {
      return els.map((nd) => {
        if (nd.id === node.id) {
          return Object.assign({}, nd, node);
        } else {
          return nd;
        }
      });
    });

    let currentStep = appContext.journey?.steps.find(
      (step) => step.id + '' === node.id + ''
    );

    if (currentStep?.ui) {
      currentStep.ui.position = node.position;
    }
    // console.log("drag stop", currentStep)
    appContext.syncCache('update-step', currentStep);
  };

  // eslint-disable-next-line no-unused-vars
  const handleCloseModal = (statusClose, remoteSave = false) => {
    if (!statusClose) {
      props.clearModalData();
      setOpenModal(false);
    }
  };

  const findTargetId = (e) => {
    let targetId = e.target?.attributes?.nodeid?.nodeValue;
    // In firefox
    if (!targetId) {
      targetId = e.target?.attributes['data-nodeid']?.nodeValue;
    }
    // In Other browser
    if (!targetId) {
      let target_ = e
        .composedPath()
        ?.find((elm) => elm.className === 'box-node');
      if (!target_) {
        target_ = e.composedPath()?.find((elm) => !!elm.attributes?.nodeid);
      }
      // const target_ = e.composedPath()?.find( elm => elm.className === "box-node__type__icon")
      if (target_) {
        targetId = target_.attributes?.nodeid?.nodeValue;
      } else {
        const target_menu_ = e
          .composedPath()
          ?.find((elm) => elm.className === 'box-node__menu');
        targetId = target_menu_?.attributes?.nodeid?.nodeValue;
      }
    }
    return targetId;
  };

  const bannerStackSize = useBannerStackSize();

  let nodeStart_id;
  let handleStart_id;

  return (
    props.currentStep === 'design' && (
      <div>
        <Modal
          className={`dnd-modal ${openModalClass} dnd-modal-banner-${bannerStackSize}`}
          ariaHideApp={false}
          isOpen={openModal}
        >
          <ModalContent
            els={props}
            node={nodeSelectedModal}
            nodes={nodesInbox}
            content={props?.modals}
            closeModal={(stateClose, remoteSave) =>
              handleCloseModal(stateClose, remoteSave)
            }
            getSegment={props?.getSegment}
          />
        </Modal>
        <div className={'dndflow'} style={props?.style}>
          <ReactFlowProvider>
            {!props?.readOnly && (
              <Sidebar
                nodes={nodesInbox}
                currentProduct={currentProduct}
                elements={elements}
                type={props.type}
              />
            )}
            {mode === 'report' && (
              <SidebarReport
                nodes={nodesInbox}
                elements={elements}
                {...props}
              />
            )}

            <div className="reactflow-wrapper">
              <ReactFlow
                style={{ opacity: `${props.loadingFitView ? 0.3 : 1}` }}
                onSelectionChange={onSelectionChange}
                onSelectionDragStop={onSelectionDragStop}
                className={onConnectState ? 'reactflow__onconnect' : ''}
                elementsSelectable={!props.readOnly}
                elements={elements}
                onElementClick={onElementClick}
                onElementsRemove={onElementsRemove}
                onConnect={onConnect}
                edgeTypes={edgeTypes}
                // eslint-disable-next-line no-unused-vars
                onConnectStart={(e, { nodeId, handleType }) => {
                  // setNodeStart(nodeId);
                  nodeStart_id = nodeId;
                  handleStart_id = e.target.dataset.handleid;
                  setOnConnectState(true);
                }}
                onConnectStop={(e) => {
                  const startNode_ = elements.find(
                    (item) => item.id === nodeStart_id
                  );
                  // Start node couldn't be PLACEHOLDER node
                  if (startNode_.data?.nodeType === NodeTypes[4]) {
                    return;
                  }
                  // Detect target node Id
                  const targetId = findTargetId(e);

                  // Check not be revert edge
                  if (nodeStart_id + '' !== targetId + '') {
                    if (!targetId) {
                      createNode({
                        type: handleStart_id,
                        id: nodeStart_id,
                        label: handleStart_id,
                        position: {
                          x: e.offsetX, //position ? position.x + 120 : 320,
                          y: e.offsetY, //</div>position ? position.y + 100 : 200,
                        },
                        sourceHandle: handleStart_id,
                      });
                    } else {
                      onConnectDrop(
                        Object.assign(
                          {},
                          {
                            source: nodeStart_id,
                            target: targetId,
                            sourceHandle: handleStart_id,
                            label: handleStart_id,
                          }
                        )
                      );
                    }
                  }
                  setOnConnectState(false);
                }}
                onNodeDrag={onNodeDrag}
                onNodeDragStop={onNodeDragStop}
                // style={{ background: bgColor }}
                onLoad={onLoad}
                onDrop={onDrop}
                onDragOver={onDragOver}
                nodeTypes={nodeTypes}
                connectionLineStyle={connectionLineStyle}
                snapToGrid={true}
                snapGrid={snapGrid}
                defaultZoom={1}
                nodesDraggable={draggableNodes}
              >
                <div className={'node__bar selection_rect_icons'}>
                  <div
                    className={
                      'bar__icon bar__icon__trash selection_rect_trash'
                    }
                  >
                    <DeleteIcon />
                  </div>
                  <div
                    className={'bar__icon bar__icon__copy selection_rect_copy'}
                  >
                    <CopyIcon />{' '}
                  </div>
                </div>
                {!props.readOnly && (
                  <MiniMap
                    nodeStrokeColor={(n) => {
                      if (n.type === 'input') return '#0041d0';
                      if (n.type === 'defaultNode') return '#eee';
                      if (n.type === 'output') return '#ff0072';
                    }}
                    nodeColor={(n) => {
                      if (n.type === 'defaultNode') return '#eee';
                      return '#fff';
                    }}
                  />
                )}
                {!props.readOnly && <Controls className={'controls'} />}
                <Background color="#000" gap={'30'} />
              </ReactFlow>
            </div>
          </ReactFlowProvider>
        </div>
      </div>
    )
  );
};

export default JourneyFlow;
