import React from 'react';

const UploadCloud = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          d="M24.1558 24.539L18.1169 18.5001L12.0779 24.539"
          stroke="black"
          strokeWidth="1.50974"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.1169 18.5001V32.0877"
          stroke="black"
          strokeWidth="1.50974"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.7836 28.1473C32.2561 27.3446 33.4193 26.0743 34.0897 24.537C34.7601 22.9997 34.8995 21.2829 34.4858 19.6576C34.0721 18.0323 33.1289 16.591 31.8052 15.5613C30.4814 14.5316 28.8524 13.972 27.1753 13.9709H25.273C24.8161 12.2033 23.9643 10.5624 22.7819 9.17139C21.5994 7.78041 20.117 6.67558 18.4461 5.93997C16.7752 5.20436 14.9593 4.85711 13.1349 4.92433C11.3105 4.99155 9.52504 5.47148 7.9128 6.32806C6.30056 7.18463 4.90349 8.39555 3.82661 9.86978C2.74973 11.344 2.02106 13.0432 1.6954 14.8396C1.36973 16.6359 1.45554 18.4828 1.94636 20.2412C2.43719 21.9997 3.32027 23.6239 4.52921 24.992"
          stroke="black"
          strokeWidth="1.50974"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.1558 24.539L18.1169 18.5001L12.0779 24.539"
          stroke="black"
          strokeWidth="1.50974"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default UploadCloud;
