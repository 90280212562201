import React, { useContext } from 'react';

import { AppContext } from '../../app';
import { Button, Form, InputNumber, Select } from 'antd';
import { convertMinute, timesOptions } from '../../../../utils/DateUtils';
import { calcMinuteTime } from '@Component/journey/utils/hooks';

const FlowWaitForTime = (props) => {
  const [form] = Form.useForm();
  const { journey, syncCache } = useContext(AppContext);

  const onFinish = (e) => {
    let currentStep = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );
    currentStep['waitMinutes'] = calcMinuteTime(e.time, e.type);
    delete currentStep.ui?.error;
    syncCache('update-step', currentStep, true);
    // openSnackBar("success", {
    //   message: "Successfully updated",
    // });
    props.closeModal();
  };

  const selectedNode = journey?.steps?.filter(
    (item) => item.id + '' === props?.node?.id
  );
  const timeMinute = selectedNode.length && selectedNode[0]?.waitMinutes;

  const time = convertMinute(timeMinute);

  return (
    <>
      <Form form={form} onFinish={onFinish} className="modal_wrap">
        <div className={'modal-body'}>
          <div className="content__item">
            <span>
              <Form.Item
                label="Wait for"
                name="time"
                rules={[{ required: true, message: 'Please set an time!' }]}
                initialValue={time.value}
              >
                <InputNumber
                  type="number"
                  readOnly={props.readOnly}
                  style={{ width: 200 }}
                />
              </Form.Item>
            </span>
            <span>
              <Form.Item
                name="type"
                rules={[{ required: true, message: 'Please set an type!' }]}
                initialValue={time.type}
              >
                <Select
                  style={{ width: 200 }}
                  placeholder="Select Type"
                  disabled={props.readOnly}
                >
                  {timesOptions().map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </span>
            <span>
              <Form.Item>
                <div>and only then proceed from this block</div>
              </Form.Item>
            </span>
          </div>
        </div>
        <div className={'modal-footer'}>
          <Form.Item>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              disabled={props.readOnly}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
export default FlowWaitForTime;
