import { createSlice } from '@reduxjs/toolkit';
import UploadDataCsvApi from './dataPlatformApi';

const initialState = {
  fileInfo: null,
  loadingUploadFile: false,
  errorsUploadUserFile: [],
  errorsUploadEventFile: [],

  attributes: {
    loadingList: true,
    list: {},
  },
  events: {
    loadingList: true,
    list: {},
  },
  userFileProgressList: {
    loadingList: true,
    page: {},
    pageRequest: {
      direction: 'DESC',
      fileName: null,
      type: 'CREAT_USER',
      order: 'id',
      page: 0,
    },
  },
  eventFileProgressList: {
    loadingList: true,
    page: {},
    pageRequest: {
      direction: 'DESC',
      fileName: null,
      type: 'CREAT_EVENT',
      order: 'id',
      page: 0,
    },
  },
};

export const dataPlatformSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    uploadDataCsvUserFetchStart(state) {
      state.loadingUploadFile = true;
    },
    uploadDataCsvUserFetchSuccess(state, { payload }) {
      state.loadingUploadFile = false;
      state.fileInfo = payload;
    },
    uploadDataCsvUserFetchFail(state, { payload }) {
      state.loadingUploadFile = false;
      state.errorsUploadUserFile = payload;
    },
    uploadCsvListAttributeFetchStart(state) {
      state.attributes.loadingList = true;
      state.attributes.list = [];
    },
    uploadCsvListAttributeFetchSuccess(state, { payload }) {
      state.attributes.loadingList = false;
      state.attributes.list = payload;
    },
    uploadCsvListAttributeFetchFail(state) {
      state.attributes.loadingList = false;
      state.attributes.list = [];
    },
    //
    dataFileProgressListFetchStart(state) {
      state.userFileProgressList.loadingList = true;
      state.userFileProgressList.page = [];
    },
    dataFileProgressListFetchSuccess(state, { payload }) {
      state.userFileProgressList.loadingList = false;
      state.userFileProgressList.page = payload;
    },
    dataFileProgressListFetchFail(state) {
      state.userFileProgressList.loadingList = false;
      state.userFileProgressList.page = [];
    },
    //
    dataFileProgressEventListFetchStart(state) {
      state.eventFileProgressList.loadingList = true;
      state.eventFileProgressList.page = [];
    },
    dataFileProgressEventListFetchSuccess(state, { payload }) {
      state.eventFileProgressList.loadingList = false;
      state.eventFileProgressList.page = payload;
    },
    dataFileProgressEventListFetchFail(state) {
      state.eventFileProgressList.loadingList = false;
      state.eventFileProgressList.page = [];
    },
    //
    uploadDataCsvEventFetchStart(state) {
      state.loadingUploadFile = true;
    },
    uploadDataCsvEventFetchSuccess(state, { payload }) {
      state.loadingUploadFile = false;
      state.fileInfo = payload;
    },
    uploadDataCsvEventFetchFail(state, { payload }) {
      state.loadingUploadFile = false;
      state.errorsUploadEventFile = payload;
    },
    //
    uploadCsvListEventFetchStart(state) {
      state.events.loadingList = true;
      state.events.list = [];
    },
    uploadCsvListEventFetchSuccess(state, { payload }) {
      state.events.loadingList = false;
      state.events.list = payload;
    },
    uploadCsvListEventFetchFail(state, { payload }) {
      state.events.loadingList = true;
      state.events.list = payload;
    },
  },
});

export default dataPlatformSlice.reducer;

export const {
  uploadDataCsvUserFetchStart,
  uploadDataCsvUserFetchSuccess,
  uploadDataCsvUserFetchFail,
  uploadCsvListAttributeFetchStart,
  uploadCsvListAttributeFetchSuccess,
  uploadCsvListAttributeFetchFail,
  dataFileProgressListFetchStart,
  dataFileProgressListFetchSuccess,
  dataFileProgressListFetchFail,
  dataFileProgressEventListFetchStart,
  dataFileProgressEventListFetchSuccess,
  dataFileProgressEventListFetchFail,
  uploadDataCsvEventFetchStart,
  uploadDataCsvEventFetchSuccess,
  uploadDataCsvEventFetchFail,
  uploadCsvListEventFetchStart,
  uploadCsvListEventFetchSuccess,
  uploadCsvListEventFetchFail,
} = dataPlatformSlice.actions;

export const uploadDataCsvUserFetch =
  (file, attributesMap) => async (dispatch) => {
    dispatch(uploadDataCsvUserFetchStart());
    try {
      const fileResponse = await UploadDataCsvApi.uploadFile(file);
      const csvResponse = await UploadDataCsvApi.createUsers({
        attributesMap,
        fileName: fileResponse.fileName,
        filePath: fileResponse.path,
      });
      dispatch(uploadDataCsvUserFetchSuccess(csvResponse));
    } catch (error) {
      dispatch(uploadDataCsvUserFetchFail(error));
    }
  };

export const uploadCsvListAttributeFetch = (body) => async (dispatch) => {
  dispatch(uploadCsvListAttributeFetchStart());
  try {
    const response = await UploadDataCsvApi.listAttributes(body);
    dispatch(uploadCsvListAttributeFetchSuccess(response));
  } catch (error) {
    dispatch(uploadCsvListAttributeFetchFail(error));
  }
};

export const dataFileProgressListFetch = (body) => async (dispatch) => {
  dispatch(dataFileProgressListFetchStart());
  try {
    const response = await UploadDataCsvApi.fileProgressList(body);
    dispatch(dataFileProgressListFetchSuccess(response));
  } catch (error) {
    dispatch(dataFileProgressListFetchFail(error));
  }
};

export const dataFileProgressEventListFetch = (body) => async (dispatch) => {
  dispatch(dataFileProgressEventListFetchStart());
  try {
    const response = await UploadDataCsvApi.fileProgressList(body);
    dispatch(dataFileProgressEventListFetchSuccess(response));
  } catch (error) {
    dispatch(dataFileProgressEventListFetchFail(error));
  }
};
export const uploadDataCsvEventFetch =
  (file, attributesMap, eventName) => async (dispatch) => {
    dispatch(uploadDataCsvEventFetchStart());
    try {
      const fileResponse = await UploadDataCsvApi.uploadFile(file);
      const csvResponse = await UploadDataCsvApi.createEvent({
        attributesMap,
        filePath: fileResponse.path,
        fileName: fileResponse.fileName,
        eventName: eventName,
      });
      dispatch(uploadDataCsvEventFetchSuccess(csvResponse));
    } catch (error) {
      dispatch(uploadDataCsvEventFetchFail(error));
    }
  };

export const uploadCsvListEventFetch = (body) => async (dispatch) => {
  dispatch(uploadCsvListEventFetchStart());
  try {
    const response = await UploadDataCsvApi.listEvents(body);
    dispatch(uploadCsvListEventFetchSuccess(response));
  } catch (error) {
    dispatch(uploadCsvListEventFetchFail(error));
  }
};
