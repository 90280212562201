export const ADMIN_PRODUCT_CHANGE_CURRENT = 'ADMIN_PRODUCT_CHANGE_CURRENT';
export const ADMIN_PRODUCT_PAGE_CHANGE_CURRENT =
  'ADMIN_PRODUCT_PAGE_CHANGE_CURRENT';

export const ADMIN_PRODUCT_LIST_FETCH_SUCCESS =
  'ADMIN_PRODUCT_LIST_FETCH_SUCCESS';
export const ADMIN_PRODUCT_LIST_FETCH_FAIL = 'ADMIN_PRODUCT_LIST_FETCH_FAIL';
export const ADMIN_PRODUCT_LIST_FETCH = 'ADMIN_PRODUCT_LIST_FETCH';

export const ADMIN_PRODUCT_CREATE_FETCH = 'ADMIN_PRODUCT_CREATE_FETCH';
export const ADMIN_PRODUCT_CREATE_FETCH_FAIL =
  'ADMIN_PRODUCT_CREATE_FETCH_FAIL';
export const ADMIN_PRODUCT_CREATE_FETCH_SUCCESS =
  'ADMIN_PRODUCT_CREATE_FETCH_SUCCESS';

export const ADMIN_PRODUCT_UPDATE_FETCH = 'ADMIN_PRODUCT_UPDATE_FETCH';
export const ADMIN_PRODUCT_UPDATE_FETCH_FAIL =
  'ADMIN_PRODUCT_UPDATE_FETCH_FAIL';
export const ADMIN_PRODUCT_UPDATE_FETCH_SUCCESS =
  'ADMIN_PRODUCT_UPDATE_FETCH_SUCCESS';

export const ADMIN_PRODUCT_INDEX_FETCH = 'ADMIN_PRODUCT_INDEX_FETCH';
export const ADMIN_PRODUCT_INDEX_FETCH_FAIL = 'ADMIN_PRODUCT_INDEX_FETCH_FAIL';
export const ADMIN_PRODUCT_INDEX_FETCH_SUCCESS =
  'ADMIN_PRODUCT_INDEX_FETCH_SUCCESS';

export const ADMIN_PRODUCT_INDEX_ALL_FETCH = 'ADMIN_PRODUCT_INDEX_ALL_FETCH';
export const ADMIN_PRODUCT_INDEX_ALL_FETCH_FAIL =
  'ADMIN_PRODUCT_INDEX_ALL_FETCH_FAIL';
export const ADMIN_PRODUCT_INDEX_ALL_FETCH_SUCCESS =
  'ADMIN_PRODUCT_INDEX_ALL_FETCH_SUCCESS';

export const ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH =
  'ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH';
export const ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH_FAIL =
  'ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH_FAIL';
export const ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH_SUCCESS =
  'ADMIN_PRODUCT_UPDATE_SUBMIT_FETCH_SUCCESS';

export const ADMIN_PRODUCT_VIEW_FETCH = 'ADMIN_PRODUCT_VIEW_FETCH';
export const ADMIN_PRODUCT_VIEW_FETCH_FAIL = 'ADMIN_PRODUCT_VIEW_FETCH_FAIL';
export const ADMIN_PRODUCT_VIEW_FETCH_SUCCESS =
  'ADMIN_PRODUCT_VIEW_FETCH_SUCCESS';
export const EVENT_RATE_CHANGE_CURRENT = 'EVENT_RATE_CHANGE_CURRENT';

export const PARENT_PRODUCT_LIST_FETCH_SUCCESS =
  'PARENT_PRODUCT_LIST_FETCH_SUCCESS';

export const PARENT_PRODUCT_LIST_FETCH = 'PARENT_PRODUCT_LIST_FETCH';
