export const numberCommaSeparator = (value) =>
  `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

export const toEnDigit = (value) => {
  return value.replace(/[\u0660-\u0669\u06f0-\u06f9]/g, function (a) {
    return a.charCodeAt(0) & 0xf;
  });
};

export const toPersianDigit = (value) => {
  const farsiDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];

  return value?.toString().replace(/\d/g, (x) => farsiDigits[x]);
};

export const toPersianDigitWithSeparator = (value) => {
  return toPersianDigit(numberCommaSeparator(value));
};
