import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router';
import FunnelsList from './container/FunnelsListContainer';
import FunnelCreate from './container/funnelCreateContainer';
import FunnelView from './container/FunnelViewContainer';

function Funnel(props) {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}new`}>
        <FunnelCreate {...props} />
      </Route>
      <Route path={`${match.path}:id/edit`}>
        <FunnelCreate {...props} />
      </Route>
      <Route path={`${match.path}list`}>
        <FunnelsList {...props} />
      </Route>
      <Route path={`${match.path}:id/overview`}>
        <FunnelView {...props} />
      </Route>
    </Switch>
  );
}

export default Funnel;
