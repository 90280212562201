import React, { useEffect, useState } from 'react';
import { InputNumber } from 'antd';
export default function NumberBetweenInput(props) {
  const [from, setFrom] = useState(() => {
    if (props?.value) {
      const values = `${props.value}`.split(',');
      if (values.length) {
        return values[0];
      }
    }
    return null;
  });
  const [to, setTo] = useState(() => {
    if (props?.value) {
      const values = `${props.value}`.split(',');
      if (values.length > 1) {
        return values[1];
      }
    }
    return null;
  });

  useEffect(() => {
    props.onChange(`${from},${to}`);
  }, [from, to]);

  return (
    <div style={{ display: 'flex' }}>
      <InputNumber
        value={from}
        onChange={(value) => {
          console.log('from changed', value);
          setFrom(value);
        }}
      />
      <span style={{ padding: '5px' }}>AND</span>
      <InputNumber
        value={to}
        onChange={(value) => {
          console.log('to changed', value);
          setTo(value);
        }}
      />
    </div>
  );
}
