import { Checkbox, Dropdown, Menu, Spin, message } from 'antd';
import { cardMetrics, cardNames } from './dashboardConstants';
import styles from './ReportCardsSection.module.less';
import { numberWithCommas } from '@Utils/RenderUtils';
import { EditOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { isNil } from '@antv/util';
import React from 'react';

const ReportCard = ({
  cardTitle,
  metrics,
  selectedCards,
  setSelectedCards,
  totalReport,
  channelReport,
  typeReport,
}) => {
  const loading = useSelector(
    (state) => state.admin.managementDashboard.loading
  );
  const isMetricChecked = (metric) => {
    let tempMetrics = [];
    metrics.forEach((metric) => {
      tempMetrics.push(metric.key);
    });
    return tempMetrics.includes(metric);
  };

  const handleChangeMetrics = (e, metric) => {
    if (e.target.checked) {
      if (metrics.length < 6) {
        const tempSelectedCards = { ...selectedCards };
        Object.keys(selectedCards).forEach((card) => {
          if (card === cardTitle) {
            tempSelectedCards[card] = [...tempSelectedCards[card], metric];
          }
        });
        setSelectedCards(tempSelectedCards);
      } else {
        message.warning('You can have at max 6 items selected');
      }
    } else {
      if (metrics.length > 1) {
        const tempSelectedCards = { ...selectedCards };
        let tempMetric = [];
        Object.keys(selectedCards).forEach((card) => {
          if (card === cardTitle) {
            tempMetric = tempSelectedCards[card].filter(
              (card) => card.key !== metric.key
            );
            tempSelectedCards[card] = tempMetric;
          }
        });

        setSelectedCards(tempSelectedCards);
      } else {
        message.warning('You should have at least 1 item selected');
      }
    }
  };

  const menu = (
    <Menu>
      <div className={styles.checkListMenuWrapper}>
        <Menu selectable={false} className={styles.checkListMenu}>
          {cardMetrics[cardTitle]?.map((metric) => (
            <Menu.Item key={metric.key} className={styles.menuItemWrapper}>
              <Checkbox
                style={{ width: 200 }}
                onChange={(e) => handleChangeMetrics(e, metric)}
                checked={isMetricChecked(metric.key)}
              >
                {metric.label}
              </Checkbox>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </Menu>
  );

  const renderMetricValue = (metricKey, metricType) => {
    switch (cardTitle) {
      case 'EVENTS_AND_PROFILES':
        if (metricKey.includes('ACTIVE_USERS'))
          return !isNil(totalReport?.['ACTIVE_USERS']?.[metricType])
            ? numberWithCommas(totalReport?.['ACTIVE_USERS']?.[metricType])
            : '-';
        else
          return !isNil(totalReport?.[metricKey])
            ? numberWithCommas(totalReport?.[metricKey])
            : '-';
      case 'LUNCHED_CAMPAIGN':
        if (metricKey === 'ENDED_CAMPAIGNS')
          return !isNil(totalReport?.[metricKey]?.[metricType])
            ? numberWithCommas(totalReport?.[metricKey]?.[metricType])
            : '-';
        else
          return !isNil(channelReport?.['ENDED_CAMPAIGNS']?.[metricType])
            ? numberWithCommas(channelReport?.['ENDED_CAMPAIGNS']?.[metricType])
            : '-';
      // case 'RUNNING_COMMUNICATION_BY_CHANNEL':
      //   if (
      //     metricKey === 'RUNNING_CAMPAIGNS' ||
      //     metricKey === 'RUNNING_JOURNEYS' ||
      //     metricKey === 'RUNNING_RELAYS'
      //   )
      //     return !isNil(totalReport?.[metricKey]?.[metricType])
      //       ? numberWithCommas(totalReport?.[metricKey]?.[metricType])
      //       : '-';
      //   else
      //     return !isNil(channelReport?.['RUNNING_CAMPAIGNS']?.[metricType])
      //       ? numberWithCommas(
      //           channelReport?.['RUNNING_CAMPAIGNS']?.[metricType]
      //         )
      //       : '-';

      case 'RUNNING_COMMUNICATION_BY_TYPE':
        if (
          metricKey === 'RUNNING_CAMPAIGNS' ||
          metricKey === 'RUNNING_JOURNEYS' ||
          metricKey === 'RUNNING_RELAYS'
        )
          return !isNil(totalReport?.[metricKey])
            ? numberWithCommas(totalReport?.[metricKey])
            : '-';
        else
          return !isNil(typeReport?.['RUNNING_CAMPAIGNS']?.[metricType])
            ? numberWithCommas(typeReport?.['RUNNING_CAMPAIGNS']?.[metricType])
            : '-';
      case 'REVENUE':
        if (metricKey === 'REVENUE')
          return !isNil(totalReport?.[metricKey])
            ? numberWithCommas(totalReport?.[metricKey])
            : '-';
        else
          return !isNil(channelReport?.['REVENUE']?.[metricType])
            ? numberWithCommas(channelReport?.['REVENUE']?.[metricType])
            : '-';
      case 'TOTAL_INCREMENTAL_REVENUE':
        if (metricKey === 'INCREMENTAL_REVENUE')
          return !isNil(totalReport?.[metricKey]) &&
            !isNaN(totalReport?.[metricKey])
            ? numberWithCommas(totalReport?.[metricKey]?.toFixed(0))
            : '-';
        else
          return !isNil(channelReport?.['INCREMENTAL_REVENUE']?.[metricType]) &&
            !isNaN(channelReport?.['INCREMENTAL_REVENUE']?.[metricType])
            ? numberWithCommas(
                channelReport?.['INCREMENTAL_REVENUE']?.[metricType].toFixed(0)
              )
            : '-';
      case 'UPLIFT':
        if (metricKey === 'UPLIFT')
          return !isNil(totalReport?.[metricKey]) &&
            !isNaN(totalReport?.[metricKey])
            ? `${totalReport?.[metricKey].toFixed(2)}%`
            : '-';
        else
          return !isNil(channelReport?.['UPLIFT']?.[metricType]) &&
            !isNaN(channelReport?.['UPLIFT']?.[metricType])
            ? `${channelReport?.['UPLIFT']?.[metricType]?.toFixed(2)}%`
            : '-';
      case 'SENT_MESSAGE':
        if (metricKey === 'SENT_MESSAGES')
          return !isNil(totalReport?.[metricKey])
            ? numberWithCommas(totalReport?.[metricKey])
            : '-';
        else
          return !isNil(channelReport?.['SENT_MESSAGES']?.[metricType])
            ? numberWithCommas(channelReport?.['SENT_MESSAGES']?.[metricType])
            : '-';
      case 'CHANNEL_REACHABILITY':
        if (metricKey === 'REACHABLE_USERS')
          return !isNil(totalReport?.['REACHABLE_USERS'])
            ? numberWithCommas(totalReport?.['REACHABLE_USERS'])
            : '-';
        else
          return !isNil(channelReport?.['REACHABLE_USERS']?.[metricType])
            ? numberWithCommas(channelReport?.['REACHABLE_USERS']?.[metricType])
            : '-';

      default:
        return '-';
    }
  };

  return (
    <>
      <div className={styles.reportCardWrapper} id="report-card">
        <div className={styles.reportCardHeader}>
          <div className={styles.reportCardTitle}>{cardNames[cardTitle]}</div>
          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            <EditOutlined style={{ fontSize: '16px', color: '#767577' }} />
          </Dropdown>
        </div>
        {loading ? (
          <Spin className={styles.spinner} />
        ) : (
          <div className={styles.reportCardContent}>
            {metrics?.map((metric) => (
              <div key={metric.key}>
                {/* ToDo: set value */}
                <div className={styles.reportCardContentTitle}>
                  {renderMetricValue(metric.key, metric.type)}
                </div>
                <div className={styles.reportCardContentDesc}>
                  {metric.label}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ReportCard;
