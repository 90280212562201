exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ---blue---*/\n/* ---light theme---*/\n/* ---status---*/\n.ActionMenu-module__dropDownAction--1ypWS {\n  display: flex;\n  align-items: center;\n}\n.ActionMenu-module__dropDownActionMenu--34uto {\n  background-color: white;\n}\n.ActionMenu-module__ellipsisMenu--2dCzJ {\n  transform: rotateZ(90deg);\n  font-size: 20px;\n  color: #38373B !important;\n  width: 34px;\n  border: 1px solid #DCDCDD;\n  border-radius: 4px;\n  height: 34px;\n  display: flex!important;\n  align-items: center!important;\n  justify-content: center!important;\n  margin-left: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"dropDownAction": "ActionMenu-module__dropDownAction--1ypWS",
	"dropDownActionMenu": "ActionMenu-module__dropDownActionMenu--34uto",
	"ellipsisMenu": "ActionMenu-module__ellipsisMenu--2dCzJ"
};