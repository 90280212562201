import RolesUpdateComponent from '../component/RolesUpdateComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesUpdateComponent);

RolesUpdateComponent.propTypes = {};
