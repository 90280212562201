import React, { useEffect } from 'react';
import {
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  Space,
  InputNumber,
  Button,
  Row,
  Col,
  Switch,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import moment from 'moment';
import { clearErrorsContract } from '@Redux/slices/admin/adminProductContractSlice';
import { errorHandling } from '@Utils/FormUtils';
import { isEmpty } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
const styles = {
  form: {
    width: '90%',
    maxWidth: '90%',
    margin: '0 auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  itemTitle: {
    textAlign: 'right',
    paddingRight: '10px',
  },
};
function AdminProductContractForm({
  setContractData,
  contract = {},
  viewMode,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const contractTemplateList = useSelector(
    (state) => state.admin.contractTemplate.contractList
  );
  const errorsContract = useSelector(
    (state) => state.admin.contract.errorsContract
  );
  const loading = useSelector((state) => state.admin.contract.loading);
  const loadingContract = useSelector(
    (state) => state.admin.contract.loadingContract
  );

  useEffect(() => {
    if (!isEmpty(contract)) {
      form.setFieldsValue({
        startDate: moment(contract.startDate),
        endDate: moment(contract.endDate),
        customer: contract.customer,
        economicCode: contract.economicCode,
        nationalCode: contract.nationalCode,
        registrationCode: contract.registrationCode,
        phone: contract.phone,
        zipCode: contract.zipCode,
        address: contract.address,
        pricing: contract.pricing,
        includeSystemEvents: contract.includeSystemEvents,
      });
    }
  }, [contract, form]);
  const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;

  return (
    <>
      {loading || loadingContract ? (
        <Spin className="mainCard__loading" indicator={loadingSpin} />
      ) : (
        <Form
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={styles.form}
          onValuesChange={(value, allValues) => {
            const [fields] = Object.keys(value);
            if (errorsContract?.errors?.length) {
              dispatch(clearErrorsContract(fields));
            }
            setContractData(allValues);
          }}
          disabled={viewMode}
        >
          {isEmpty(contract) ? (
            <Form.Item label="Template" name="contractTemplate" required={true}>
              <Select
                placeholder="Select a Template"
                style={{
                  width: 200,
                }}
              >
                {contractTemplateList?.map((item) => (
                  <Select.Option
                    key={item.id}
                    value={item.id}
                    label={item.title}
                  >
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <p>{contract?.contractTemplate?.title}</p>
          )}
          <Form.Item
            label="Start Date"
            name={'startDate'}
            required={true}
            validateStatus={
              errorHandling(errorsContract, 'startDate') ? 'error' : null
            }
            help={errorHandling(errorsContract, 'startDate')}
          >
            <DatePicker showTime={{ format: 'HH:mm' }} style={{ width: 150 }} />
          </Form.Item>
          <Form.Item
            label="End Date"
            name={'endDate'}
            required={true}
            validateStatus={
              errorHandling(errorsContract, 'endDate') ? 'error' : null
            }
            help={errorHandling(errorsContract, 'endDate')}
          >
            <DatePicker
              //value={value}
              showTime={{ format: 'HH:mm' }}
              style={{ width: 150 }}
            />
          </Form.Item>
          <Form.Item
            label="Customer"
            name="customer"
            required={true}
            validateStatus={
              errorHandling(errorsContract, 'customer') ? 'error' : null
            }
            help={errorHandling(errorsContract, 'customer')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Economic Code"
            name="economicCode"
            required={true}
            validateStatus={
              errorHandling(errorsContract, 'economicCode') ? 'error' : null
            }
            help={errorHandling(errorsContract, 'economicCode')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="National Code"
            name="nationalCode"
            required={true}
            validateStatus={
              errorHandling(errorsContract, 'nationalCode') ? 'error' : null
            }
            help={errorHandling(errorsContract, 'nationalCode')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Registration Code"
            name="registrationCode"
            required={true}
            validateStatus={
              errorHandling(errorsContract, 'registrationCode') ? 'error' : null
            }
            help={errorHandling(errorsContract, 'registrationCode')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            required={true}
            validateStatus={
              errorHandling(errorsContract, 'phone') ? 'error' : null
            }
            help={errorHandling(errorsContract, 'phone')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="ZIP Code"
            name="zipCode"
            required={true}
            validateStatus={
              errorHandling(errorsContract, 'zipCode') ? 'error' : null
            }
            help={errorHandling(errorsContract, 'zipCode')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
            required={true}
            validateStatus={
              errorHandling(errorsContract, 'address') ? 'error' : null
            }
            help={errorHandling(errorsContract, 'address')}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Include System Events"
            name="includeSystemEvents"
            initialValue={false}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <hr />
          <Row>
            <Col span={8}>
              <div style={styles.itemTitle}>Price</div>
            </Col>
            <Col span={8}>
              <Form.List
                name="pricing"
                initialValue={[{ basePrice: undefined }]}
                disabled
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: 'flex' }}
                        align="baseline"
                      >
                        <Form.Item {...restField} name={[name, 'basePrice']}>
                          <InputNumber
                            placeholder="basePrice"
                            style={{ width: 150 }}
                            disabled={!isEmpty(contract)}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'mauMaxThreshold']}
                        >
                          <InputNumber
                            placeholder="mauMaxThreshold"
                            style={{ width: 150 }}
                            disabled={!isEmpty(contract)}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'pricePerMillionEvents']}
                        >
                          <InputNumber
                            placeholder="pricePerMillionEvents"
                            style={{ width: 150 }}
                            disabled={!isEmpty(contract)}
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          onClick={() => isEmpty(contract) && remove(name)}
                        />
                      </Space>
                    ))}
                    {fields.length <= 3 && (
                      <Form.Item>
                        <Button
                          onClick={() => {
                            isEmpty(contract) && add();
                          }}
                          type="primary"
                          shape="round"
                          ghost
                          icon={<PlusOutlined />}
                        >
                          Add field
                        </Button>
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}

export default AdminProductContractForm;
