/* eslint-disable max-len */
import React, { memo, useMemo, useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import { Layout } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import HeaderContainer from './layout/header/container/HeaderContainer';
import SiderContainer from './layout/header/container/SiderContainer';
import StickyBanner from './layout/header/component/stickyBanner';
import ErrorBoundary from './layout/ErrorBoundary';
import PageRoutes from '../component/utils/PageRoutes';
import Loading from '../component/utils/Loading';
import { cleanAppInternalError } from '../redux/appState';
import ReleaseNoteModal from '../component/releaseNoteModal/ReleaseNoteModal';
import { detectFlavor } from '@Utils/FlavorUtils';
import { createPortal } from 'react-dom';
import { BannerStackSizeProvider } from '@Utils/bannerStackSize';

const routesWithNewLayout = [
  '/billing/',
  '/data-management/',
  '/upload-data/',
  '/contract-template/',
  '/third-party/',
  '/back-office/',
  '/management-dashboard',
  '/create-product',
  '/cohorts',
  '/alerts',
];

const App = (props) => {
  const [bannerHeight, setBannerHeight] = useState(0);

  const [bannerStackSize, setBannerStackSize] = useState(0);

  const isUserLogin = useMemo(() => {
    return (
      props.currentUser &&
      !isNil(props.currentUser) &&
      !isEmpty(props.currentUser) &&
      props.currentUser?.login
    );
  }, [props.currentUser]);

  const isNotLoggedInWithoutLayout =
    props.location.pathname.includes('download');
  const downloadInvoice = props.location.pathname.includes('/invoice-preview');

  const disableGlobalHeader = useMemo(() => {
    return !!routesWithNewLayout.find((route) =>
      props.location?.pathname.includes(route)
    );
  }, [props.location]);

  if (props.location.pathname.includes('/billing/payment')) {
    return (
      <Layout>
        {props.loadingCurrentUser && !props.currentUser.login && <Loading />}
        <Layout>
          <ErrorBoundary
            location={props.location}
            status={props.app.errorStatus}
            cleanAppInternalError={props.cleanAppInternalError}
          >
            <PageRoutes isUserLogin={isUserLogin} />
          </ErrorBoundary>
        </Layout>
      </Layout>
    );
  }
  if (!isUserLogin) {
    return (
      <Layout>
        {props.loadingCurrentUser && !props.currentUser.login && <Loading />}
        <SiderContainer />

        <Layout>
          <ErrorBoundary
            location={props.location}
            status={props.app.errorStatus}
            cleanAppInternalError={props.cleanAppInternalError}
          >
            <PageRoutes />
          </ErrorBoundary>
        </Layout>
      </Layout>
    );
  }

  if (isNotLoggedInWithoutLayout || downloadInvoice) {
    return (
      <Layout>
        {props.loadingCurrentUser && !props.currentUser.login && <Loading />}
        <div>
          <PageRoutes isUserLogin={isUserLogin} />
        </div>
      </Layout>
    );
  }
  return (
    <div id={detectFlavor()} style={{ width: '100%' }}>
      {props.loadingCurrentUser && !props.currentUser.login && <Loading />}
      {createPortal(
        <StickyBanner
          setBannerHeight={setBannerHeight}
          currentUser={props.currentUser}
          loading={props.loadingCurrentUser}
          setBannerStackSize={setBannerStackSize}
        />,
        document.body
      )}
      <SiderContainer offset={bannerHeight} />
      <div
        style={{
          width: 'calc(100% - 222px)',
          marginLeft: 222,
          marginTop: bannerHeight,
        }}
      >
        <ErrorBoundary
          location={props.location}
          status={props.app.errorStatus}
          cleanAppInternalError={props.cleanAppInternalError}
        >
          {!disableGlobalHeader ? <HeaderContainer /> : null}
          <BannerStackSizeProvider bannerStackSize={bannerStackSize}>
            <PageRoutes isUserLogin={isUserLogin} />
          </BannerStackSizeProvider>
          <ReleaseNoteModal />
        </ErrorBoundary>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.account.auth.currentUser,
  loadingCurrentUser: state.account.auth.loadingCurrentUser,
  isMenuOpen: state.account.auth.isMenuOpen,
  location: state.router.location,
  app: state.app,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ cleanAppInternalError }, dispatch);

App.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(App));
