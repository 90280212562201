import { combineReducers } from 'redux';
import productSetting from '@Modules/setting/productSetting/ProductSettingState';
import billing from './billingSlice';
import invoice from './invoiceSlice';
import audit from './auditLogSlice';

const reducer = combineReducers({
  productSetting,
  billing,
  invoice,
  audit,
});

export default reducer;
