import JourneyEngagedUserComponent from '../component/JourneyEngagedUserComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { journeyEngagedUserReportFetch } from '../JourneyActions';
import { callExportJourneyFile } from '@Component/utils/CsvExport/CsvExportActions';
const mapStateToProps = (state) => ({
  journeyEngagedUserPageRequest:
    state.engage.journey.journeyEngagedUserPageRequest,
  journeyEngagedUser: state.engage.journey.journeyEngagedUser,
  loadingJourneyEngagedUser: state.engage.journey.loadingJourneyEngagedUser,
  pathname: state.router.location.pathname,
  journeyDetails: state.engage.journey.journeyDetails,
  currentUser: state.account.auth.currentUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      journeyEngagedUserReportFetch,
      callExportJourneyFile,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JourneyEngagedUserComponent);
