import {
  PROFILE_CHANGE_CURRENT,
  PASSWORD_CHANGE_CURRENT,
  PROFILE_CHANGE_PASSWORD_FETCH,
  PROFILE_CHANGE_PASSWORD_FETCH_FAIL,
  PROFILE_CHANGE_PASSWORD_FETCH_SUCCESS,
  PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH,
  PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH_FAIL,
  PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH_SUCCESS,
  PROFILE_UPDATE_FETCH,
  PROFILE_UPDATE_FETCH_FAIL,
  PROFILE_UPDATE_FETCH_SUCCESS,
  PROFILE_UPDATE_SUBMIT_FETCH,
  PROFILE_UPDATE_SUBMIT_FETCH_FAIL,
  PROFILE_UPDATE_SUBMIT_FETCH_SUCCESS,
  PROFILE_UPLOAD_PICTURE_FETCH,
  PROFILE_UPLOAD_PICTURE_FETCH_FAIL,
  PROFILE_UPLOAD_PICTURE_FETCH_SUCCESS,
  PROFILE_VIEW_FETCH,
  PROFILE_VIEW_FETCH_FAIL,
  PROFILE_VIEW_FETCH_SUCCESS,
  PROFILE_UPDATE_LAST_PRODUCT_FETCH_SUCCESS,
  PROFILE_UPDATE_LAST_PRODUCT_FETCH_FAIL,
  PROFILE_UPDATE_LAST_PRODUCT_FETCH,
  PROFILE_VERIFICATION_CODE,
  PROFILE_VERIFICATION_EMAIL,
  PROFILE_RESET_PASSWORD,
} from './ProfileConstants';
import ProfileApi from './ProfileApi';
import toastr from 'toastr';
import { showMessageError } from '../../../utils/RenderUtils';
import { loadCurrentUserFetch } from '../../account/auth/AuthActions';
import { message } from 'antd';

export function profileChangeCurrent(profile) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_CHANGE_CURRENT,
      payload: profile,
    });
  };
}

export function passwordChangeCurrent(profile) {
  return (dispatch) => {
    dispatch({
      type: PASSWORD_CHANGE_CURRENT,
      payload: profile,
    });
  };
}

export function profileUpdateFetch(profile) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_UPDATE_FETCH,
      payload: profile,
    });
    ProfileApi.update(profile)
      .then((response) => {
        dispatch({
          type: PROFILE_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PROFILE_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function profileUpdateSubmitFetch(profile) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_UPDATE_SUBMIT_FETCH,
      payload: profile,
    });
    ProfileApi.updateSubmit(profile)
      .then((response) => {
        dispatch({
          type: PROFILE_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been updated successfully');
      })
      .catch((response) => {
        dispatch({
          type: PROFILE_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function profileChangePasswordFetch(profile) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_CHANGE_PASSWORD_FETCH,
      payload: profile,
    });
    ProfileApi.changePassword(profile)
      .then((response) => {
        dispatch({
          type: PROFILE_CHANGE_PASSWORD_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PROFILE_CHANGE_PASSWORD_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function profileChangePasswordSubmitFetch(profile) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH,
      payload: profile,
    });
    ProfileApi.changePasswordSubmit(profile)
      .then((response) => {
        dispatch({
          type: PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been updated successfully');
      })
      .catch((response) => {
        dispatch({
          type: PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function profileViewFetch(profile) {
  return (dispatch) => {
    dispatch({ type: PROFILE_VIEW_FETCH });
    ProfileApi.view(profile)
      .then((response) => {
        dispatch({
          type: PROFILE_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PROFILE_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function profileUploadPictureFetch(file) {
  return (dispatch) => {
    dispatch({ type: PROFILE_UPLOAD_PICTURE_FETCH });
    ProfileApi.uploadPicture(file)
      .then((response) => {
        dispatch({
          type: PROFILE_UPLOAD_PICTURE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PROFILE_UPLOAD_PICTURE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function updateLastProductSubmit(body) {
  return (dispatch) => {
    dispatch({ type: PROFILE_UPDATE_LAST_PRODUCT_FETCH });
    ProfileApi.updateLastProductSubmit(body)
      .then((response) => {
        dispatch({
          type: PROFILE_UPDATE_LAST_PRODUCT_FETCH_SUCCESS,
          payload: response,
        });
        loadCurrentUserFetch(dispatch);
      })
      .catch((response) => {
        dispatch({
          type: PROFILE_UPDATE_LAST_PRODUCT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function emailVerificationOTPCodeFetch({ body, cb }) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_VERIFICATION_CODE,
      payload: { loading: true, error: false },
    });
    ProfileApi.getEmailVerificationOtpCode(body)
      .then((res) => {
        cb && cb();
        sessionStorage.setItem('otpId', res);
        dispatch({
          type: PROFILE_VERIFICATION_CODE,
          payload: { loading: false, error: false },
        });
      })
      .catch((response) => {
        showMessageError(response);
        sessionStorage.removeItem('otpId');
        dispatch({
          type: PROFILE_VERIFICATION_CODE,
          payload: { loading: false, error: true },
        });
      });
  };
}

export function verifyEmailFetch(body) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_VERIFICATION_EMAIL,
      payload: { loading: true },
    });
    ProfileApi.verifyEmail(body)
      .then(() => {
        dispatch({
          type: PROFILE_VERIFICATION_EMAIL,
          payload: {
            loading: false,
            emailVerifiedStatus: true,
            error: false,
          },
        });
        loadCurrentUserFetch(dispatch);
        sessionStorage.removeItem('timerDuration');
        sessionStorage.removeItem('otpId');
      })
      .catch((response) => {
        showMessageError(response);
        dispatch({
          type: PROFILE_VERIFICATION_EMAIL,
          payload: {
            loading: false,
            error: true,
            emailVerifiedStatus: false,
          },
        });
      });
  };
}

export function resetEmailVerificationState() {
  return (dispatch) => {
    dispatch({
      type: PROFILE_VERIFICATION_EMAIL,
      payload: {},
    });
  };
}

export function getResetPasswordVerificationCode({ body, cb, resendCaptcha }) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_RESET_PASSWORD,
      payload: true,
    });
    ProfileApi.getResetPassCode(body)
      .then((res) => {
        cb && cb({ email: body.userEmail, otpId: res });
      })
      .catch((error) => {
        resendCaptcha && resendCaptcha();
        showMessageError(error);
      })
      .finally(() => {
        dispatch({
          type: PROFILE_RESET_PASSWORD,
          payload: false,
        });
      });
  };
}

export function verifyResetPasswordWithOtp({ body, cb }) {
  return (dispatch) => {
    dispatch({
      type: PROFILE_RESET_PASSWORD,
      payload: true,
    });
    ProfileApi.verifyResetPass(body)
      .then(() => message.success('Password changed successfully'))
      .then(() => cb && cb())
      .catch((error) => showMessageError(error))
      .finally(() => {
        dispatch({
          type: PROFILE_RESET_PASSWORD,
          payload: false,
        });
      });
  };
}
