import SystemAttributeListComponent from '../component/SystemAttributeListComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  systemAttributePageChangeCurrent,
  systemAttributeSearchListFetch,
  systemAttributeListFetch,
  systemAttributeUpdateFetch,
  systemAttributeUpdateSubmitFetch,
} from '@Redux/slices/data/systemAttributeslice';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  page: state.dataModule.systemAttribute.page,
  pageRequest: state.dataModule.systemAttribute.pageRequest,
  loadingUpdateSubmit: state.dataModule.systemAttribute.loadingUpdateSubmit,
  loadingUpdate: state.dataModule.systemAttribute.loadingUpdate,
  currentSystemAttribute:
    state.dataModule.systemAttribute.currentSystemAttribute,
  loadingList: state.dataModule.systemAttribute.loadingList,
  loadingSearchList: state.dataModule.systemAttribute.loadingSearchList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      systemAttributePageChangeCurrent,
      systemAttributeListFetch,
      systemAttributeSearchListFetch,
      systemAttributeUpdateFetch,
      systemAttributeUpdateSubmitFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemAttributeListComponent);

SystemAttributeListComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  systemAttributeListFetch: PropTypes.func.isRequired,
  systemAttributeSearchListFetch: PropTypes.func.isRequired,
  systemAttributeDeleteFetch: PropTypes.func.isRequired,
  systemAttributePageChangeCurrent: PropTypes.func.isRequired,
};
