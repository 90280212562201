import * as React from 'react';

function SvgGeo(props) {
  return (
    <svg viewBox="0 0 16 26" width="1em" height="1em" {...props}>
      <path
        d="M7.788 0C3.494 0 0 3.407 0 7.701c0 .952.203 1.991.569 2.925H.563l.035.07.087.191L7.788 26l7.07-15.037.034-.07c.029-.064.064-.127.093-.197l.023-.064a7.78 7.78 0 00.569-2.92C15.577 3.408 12.083 0 7.788 0zm0 3.714A3.718 3.718 0 004.074 7.43a3.718 3.718 0 003.714 3.714 3.718 3.718 0 003.715-3.714 3.718 3.718 0 00-3.715-3.715z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgGeo;
