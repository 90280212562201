import {
  ACTIVE_MEMBERS_CHANGE_CURRENT,
  ACTIVE_TEAM_MEMBER_LIST_FETCH,
  ACTIVE_TEAM_MEMBER_LIST_FETCH_FAIL,
  ACTIVE_TEAM_MEMBER_LIST_FETCH_SUCCESS,
  INVITED_USER_CHANGE_CURRENT,
  ROLE_LIST_FETCH,
  ROLE_LIST_FETCH_FAIL,
  ROLE_LIST_FETCH_SUCCESS,
  TEAM_CREATE_FETCH,
  TEAM_CREATE_FETCH_FAIL,
  TEAM_CREATE_FETCH_SUCCESS,
  TEAM_MEMBER_CHANGE_CURRENT,
  TEAM_MEMBER_DELETE_FETCH,
  TEAM_MEMBER_DELETE_FETCH_FAIL,
  TEAM_MEMBER_DELETE_FETCH_SUCCESS,
  TEAM_MEMBER_REGISTER_FETCH,
  TEAM_MEMBER_REGISTER_FETCH_FAIL,
  TEAM_MEMBER_REGISTER_FETCH_SUCCESS,
  TEAM_MEMBER_VIEW_FETCH,
  TEAM_MEMBER_VIEW_FETCH_FAIL,
  TEAM_MEMBER_VIEW_FETCH_SUCCESS,
  TEAM_RESEND_INVITATION,
  TEAM_RESEND_INVITATION_FAIL,
  TEAM_RESEND_INVITATION_SUCCESS,
  TEAM_UPDATE_FETCH,
  TEAM_UPDATE_FETCH_FAIL,
  TEAM_UPDATE_FETCH_SUCCESS,
  TEAM_UPDATE_SUBMIT_FETCH,
  TEAM_UPDATE_SUBMIT_FETCH_FAIL,
  TEAM_UPDATE_SUBMIT_FETCH_SUCCESS,
} from './TeamMembersConstants';
import toastr from 'toastr';
import TeamMembersApi from '../teamMembers/TeamMembersApi';
import AuthApi from '../../../modules/account/auth/AuthApi';
import {
  AUTH_LOGOUT_FETCH,
  AUTH_LOGOUT_FETCH_SUCCESS,
  AUTH_LOGOUT_FETCH_FAIL,
} from '../../../modules/account/auth/AuthConstants';
import { history } from '../../../redux/store';
const loadTeamMembersList = (dispatch, pageRequest) => {
  dispatch({
    type: ACTIVE_TEAM_MEMBER_LIST_FETCH,
    payload: pageRequest,
  });
  TeamMembersApi.list(pageRequest)
    .then((response) => {
      dispatch({
        type: ACTIVE_TEAM_MEMBER_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: ACTIVE_TEAM_MEMBER_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function teamMembersFetchList(pageRequest) {
  return (dispatch) => loadTeamMembersList(dispatch, pageRequest);
}
export function membersChangeCurrent(customEvent) {
  return (dispatch) => {
    dispatch({
      type: ACTIVE_MEMBERS_CHANGE_CURRENT,
      payload: customEvent,
    });
  };
}
export function teamMembersViewFetch(id) {
  return (dispatch) => {
    dispatch({ type: TEAM_MEMBER_VIEW_FETCH });
    TeamMembersApi.view(id)
      .then((response) => {
        dispatch({
          type: TEAM_MEMBER_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: TEAM_MEMBER_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function teamMemberDeleteFetch(id, pageRequest) {
  return (dispatch) => {
    dispatch({ type: TEAM_MEMBER_DELETE_FETCH });
    TeamMembersApi.delete(id)
      .then((response) => {
        dispatch({
          type: TEAM_MEMBER_DELETE_FETCH_SUCCESS,
          payload: response,
        });
        loadTeamMembersList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: TEAM_MEMBER_DELETE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function listProductRolesFetch() {
  return (dispatch) => {
    dispatch({
      type: ROLE_LIST_FETCH,
    });
    TeamMembersApi.listProductRoles()
      .then((response) => {
        dispatch({
          type: ROLE_LIST_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: ROLE_LIST_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function newTeaMemberChangeCurrent(newTeamMember) {
  return (dispatch) => {
    dispatch({
      type: TEAM_MEMBER_CHANGE_CURRENT,
      payload: newTeamMember,
    });
  };
}
export function teamCreateFetch(team) {
  return (dispatch) => {
    dispatch({
      type: TEAM_CREATE_FETCH,
      payload: team,
    });
    TeamMembersApi.createTeamMember(team)
      .then((response) => {
        dispatch({
          type: TEAM_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Invitation has been sent successfully');
        history.push('../members');
      })
      .catch((response) => {
        dispatch({
          type: TEAM_CREATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function teamUpdateFetch(id) {
  return (dispatch) => {
    dispatch({
      type: TEAM_UPDATE_FETCH,
    });
    TeamMembersApi.updateTeamMember(id)
      .then((response) => {
        dispatch({
          type: TEAM_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: TEAM_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function resendInvitation(id) {
  return (dispatch) => {
    dispatch({
      type: TEAM_RESEND_INVITATION,
    });
    TeamMembersApi.resendInvitation(id)
      .then((response) => {
        dispatch({
          type: TEAM_RESEND_INVITATION_SUCCESS,
          payload: response,
        });
        toastr.success('Invitation has been sent successfully');
      })
      .catch((response) => {
        dispatch({
          type: TEAM_RESEND_INVITATION_FAIL,
          payload: response,
        });
      });
  };
}
export function teamUpdateSubmitFetch(id, team) {
  return (dispatch) => {
    dispatch({
      type: TEAM_UPDATE_SUBMIT_FETCH,
      payload: team,
    });
    TeamMembersApi.updateSubmitTeamMember(id, team)
      .then((response) => {
        dispatch({
          type: TEAM_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been updated successfully');
        history.push('../../members');
      })
      .catch((response) => {
        dispatch({
          type: TEAM_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function teamMemberRegisterFetch(user, token) {
  return (dispatch) => {
    dispatch({
      type: TEAM_MEMBER_REGISTER_FETCH,
      payload: user,
    });
    TeamMembersApi.register(user, token)
      .then((response) => {
        dispatch({
          type: TEAM_MEMBER_REGISTER_FETCH_SUCCESS,
          payload: response,
        });
        history.push('/');
      })
      .catch((response) => {
        dispatch({
          type: TEAM_MEMBER_REGISTER_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function invitedUserChangeCurrent(invitedUser) {
  return (dispatch) => {
    dispatch({
      type: INVITED_USER_CHANGE_CURRENT,
      payload: invitedUser,
    });
  };
}
export function logoutFetch() {
  return (dispatch) => {
    dispatch({
      type: AUTH_LOGOUT_FETCH,
      payload: {},
    });
    AuthApi.logout()
      .then((response) => {
        dispatch({
          type: AUTH_LOGOUT_FETCH_SUCCESS,
          payload: response,
        });
        localStorage.removeItem('path');
      })
      .catch((response) => {
        dispatch({
          type: AUTH_LOGOUT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
