export const ROLE_CHANGE_CURRENT = 'ROLE_CHANGE_CURRENT';
export const ROLE_PAGE_CHANGE_CURRENT = 'ROLE_PAGE_CHANGE_CURRENT';

export const ROLE_AUTHORITY_LIST_FETCH = 'ROLE_AUTHORITY_LIST_FETCH';
export const ROLE_AUTHORITY_LIST_FETCH_FAIL = 'ROLE_AUTHORITY_LIST_FETCH_FAIL';
export const ROLE_AUTHORITY_LIST_FETCH_SUCCESS =
  'ROLE_AUTHORITY_LIST_FETCH_SUCCESS';

export const ROLE_LIST_FETCH_SUCCESS = 'ROLE_LIST_FETCH_SUCCESS';
export const ROLE_LIST_FETCH_FAIL = 'ROLE_LIST_FETCH_FAIL';
export const ROLE_LIST_FETCH = 'ROLE_LIST_FETCH';

export const ROLE_SEARCH_LIST_FETCH_SUCCESS = 'ROLE_SEARCH_LIST_FETCH_SUCCESS';
export const ROLE_SEARCH_LIST_FETCH = 'ROLE_SEARCH_LIST_FETCH';
export const ROLE_SEARCH_LIST_FETCH_FAIL = 'ROLE_SEARCH_LIST_FETCH_FAIL';

export const ROLE_CREATE_FETCH = 'ROLE_CREATE_FETCH';
export const ROLE_CREATE_FETCH_FAIL = 'ROLE_CREATE_FETCH_FAIL';
export const ROLE_CREATE_FETCH_SUCCESS = 'ROLE_CREATE_FETCH_SUCCESS';

export const ROLE_COPY_FETCH = 'ROLE_COPY_FETCH';
export const ROLE_COPY_FETCH_FAIL = 'ROLE_COPY_FETCH_FAIL';
export const ROLE_COPY_FETCH_SUCCESS = 'ROLE_COPY_FETCH_SUCCESS';

export const ROLE_UPDATE_FETCH = 'ROLE_UPDATE_FETCH';
export const ROLE_UPDATE_FETCH_FAIL = 'ROLE_UPDATE_FETCH_FAIL';
export const ROLE_UPDATE_FETCH_SUCCESS = 'ROLE_UPDATE_FETCH_SUCCESS';

export const ROLE_UPDATE_SUBMIT_FETCH = 'ROLE_UPDATE_SUBMIT_FETCH';
export const ROLE_UPDATE_SUBMIT_FETCH_FAIL = 'ROLE_UPDATE_SUBMIT_FETCH_FAIL';
export const ROLE_UPDATE_SUBMIT_FETCH_SUCCESS =
  'ROLE_UPDATE_SUBMIT_FETCH_SUCCESS';

export const ROLE_VIEW_FETCH = 'ROLE_VIEW_FETCH';
export const ROLE_VIEW_FETCH_FAIL = 'ROLE_VIEW_FETCH_FAIL';
export const ROLE_VIEW_FETCH_SUCCESS = 'ROLE_VIEW_FETCH_SUCCESS';

export const ROLE_DELETE_FETCH = 'ROLE_DELETE_FETCH';
export const ROLE_DELETE_FETCH_FAIL = 'ROLE_DELETE_FETCH_FAIL';
export const ROLE_DELETE_FETCH_SUCCESS = 'ROLE_DELETE_FETCH_SUCCESS';

export const ADMIN_ROLE_CLEAR_ERROR = 'ADMIN_ROLE_CLEAR_ERROR';
