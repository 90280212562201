import { history } from '../../../redux/store';
import toastr from 'toastr';
import {
  JOURNEY_CREATE,
  JOURNEY_CREATE_SUCCESS,
  JOURNEY_CREATE_FAIL,
  COMMUNICATION_UPDATE_JOURNEY,
  COMMUNICATION_UPDATE_JOURNEY_SUCCESS,
  COMMUNICATION_UPDATE_JOURNEY_FAIL,
  COMMUNICATION_UPDATE_SUBMIT_JOURNEY,
  COMMUNICATION_UPDATE_SUBMIT_JOURNEY_SUCCESS,
  COMMUNICATION_UPDATE_SUBMIT_JOURNEY_FAIL,
  COMMUNICATION_UPDATE_STEPS_JOURNEY_SUCCESS,
  JOURNEY_CHANGE_CURRENT,
  JOURNEY_PAGE_CHANGE_CURRENT,
  JOURNEY_LIST_TAGS_FETCH,
  JOURNEY_LIST_TAGS_FETCH_SUCCESS,
  JOURNEY_LIST_TAGS_FETCH_FAIL,
  JOURNEY_VIEW_FETCH,
  JOURNEY_VIEW_FETCH_SUCCESS,
  JOURNEY_VIEW_FETCH_FAIL,
  JOURNEY_LIST_FETCH,
  JOURNEY_LIST_FETCH_SUCCESS,
  JOURNEY_LIST_FETCH_FAIL,
  JOURNEY_DELETE_FETCH,
  JOURNEY_DELETE_FETCH_FAIL,
  JOURNEY_DELETE_FETCH_SUCCESS,
  JOURNEY_LIST_ATTRIBUTES_FETCH,
  JOURNEY_LIST_ATTRIBUTES_FETCH_SUCCESS,
  JOURNEY_LIST_ATTRIBUTES_FETCH_FAIL,
  JOURNEY_LIST_EVENTS_FETCH,
  JOURNEY_LIST_EVENTS_FETCH_SUCCESS,
  JOURNEY_LIST_EVENTS_FETCH_FAIL,
  JOURNEY_LIST_BUSINESS_EVENTS_FETCH,
  JOURNEY_LIST_BUSINESS_EVENTS_FETCH_FAIL,
  JOURNEY_LIST_BUSINESS_EVENTS_FETCH_SUCCESS,
  JOURNEY_LIST_SEGMENTS_FETCH,
  JOURNEY_LIST_SEGMENTS_FETCH_SUCCESS,
  JOURNEY_LIST_SEGMENTS_FETCH_FAIL,
  JOURNEY_REPORT_OVERVIEW,
  JOURNEY_REPORT_OVERVIEW_SUCCESS,
  JOURNEY_REPORT_OVERVIEW_FAIL,
  JOURNEY_REPORT_COMMUNICATION_FETCH,
  JOURNEY_REPORT_COMMUNICATION_FETCH_SUCCESS,
  JOURNEY_REPORT_COMMUNICATION_FETCH_FAIL,
  JOURNEY_REPORT_ENTER_OR_EXIT_FETCH,
  JOURNEY_REPORT_ENTER_OR_EXIT_FETCH_SUCCESS,
  JOURNEY_REPORT_ENTER_OR_EXIT_FETCH_FAIL,
  JOURNEY_LANUCH,
  JOURNEY_LANUCH_SUCCESS,
  JOURNEY_LANUCH_FAIL,
  JOURNEY_COPY_FETCH_FAIL,
  JOURNEY_COPY_FETCH_SUCCESS,
  JOURNEY_COPY_FETCH,
  JOURNEY_STOP,
  JOURNEY_STOP_SUCCESS,
  JOURNEY_STOP_FAIL,
  JOURNEY_PAUSE,
  JOURNEY_PAUSE_SUCCESS,
  JOURNEY_PAUSE_FAIL,
  JOURNEY_REPORT_LIVE,
  JOURNEY_REPORT_LIVE_SUCCESS,
  JOURNEY_REPORT_LIVE_FAIL,
  JOURNEY_TEST_API,
  JOURNEY_TEST_API_SUCCESS,
  JOURNEY_TEST_API_FAIL,
  JOURNEY_LIST_SEARCH_FETCH_FAIL,
  JOURNEY_LIST_SEARCH_FETCH_SUCCESS,
  JOURNEY_LIST_SEARCH_FETCH,
  JOURNEY_LANUCH_CLEAR,
  JOURNEY_ENGAGED_USER_FETCH,
  JOURNEY_ENGAGED_USER_FETCH_SUCCESS,
  JOURNEY_ENGAGED_USER_FETCH_FAIL,
  JOURNEY_ARCHIVE_FETCH,
  JOURNEY_ARCHIVE_FETCH_SUCCESS,
  JOURNEY_ARCHIVE_FETCH_FAIL,
  JOURNEY_ACTIVE_FETCH,
  JOURNEY_ACTIVE_FETCH_SUCCESS,
  JOURNEY_ACTIVE_FETCH_FAIL,
  JOURNEY_BUSINESS_EVENTS_REPORT_FETCH,
  JOURNEY_BUSINESS_EVENTS_REPORT_FETCH_SUCCESS,
  JOURNEY_BUSINESS_EVENTS_REPORT_FETCH_FAIL,
  JOURNEY_REPORT_OVERVIEW_CSV_FETCH,
  JOURNEY_REPORT_OVERVIEW_CSV_SUCCESS,
  JOURNEY_REPORT_OVERVIEW_CSV_FAIL,
  JOURNEY_REPORT_COMMUNICATION_OVERVIEW_CSV_FETCH,
  JOURNEY_REPORT_COMMUNICATION_OVERVIEW_CSV_SUCCESS,
  JOURNEY_REPORT_COMMUNICATION_OVERVIEW_CSV_FAIL,
  JOURNEY_TEMPLATE_NAMES_LIST_FETCH,
  JOURNEY_TEMPLATE_NAMES_LIST_SUCCESS,
} from './JourneyConstants';
import JourneyApi from './JourneyApi';
// import CommunicationApi from '../communication/CommunicationApi';
import { showMessage, showMessageError } from '../../../utils/RenderUtils';
import journeyApi from './JourneyApi';
import moment from 'moment';

export function journeyCreate(data) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_CREATE,
      payload: data.body,
    });
    JourneyApi.createJourney(data.body)
      .then((response) => {
        dispatch({
          type: JOURNEY_CREATE_SUCCESS,
          payload: response,
          redirect: data.redirect,
          publishAfterCreate: data.publishAfterCreate,
        });
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_CREATE_FAIL,
          payload: response,
        });
      });
  };
}

//WORKAROUND FOR REMOVING DUPLICATE STEPS
const removeDuplicateSteps = (steps) =>
  steps.filter(
    (step, idx) =>
      steps.findIndex((innerStep) => innerStep.id == step.id) === idx
  );

export function journeyUpdate(id, mode) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_JOURNEY,
      payload: {},
    });
    JourneyApi.updateJourney(id)
      .then((response) => {
        const responseFixed = {
          ...response,
          steps: removeDuplicateSteps(response?.steps),
        };
        if (mode !== 'report') {
          dispatch({
            type: COMMUNICATION_UPDATE_JOURNEY_SUCCESS,
            payload: responseFixed,
          });
        } else {
          journeyGetReportLive(id, responseFixed)(dispatch);
        }
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_JOURNEY_FAIL,
          payload: response,
        });
      });
  };
}

export function journeyChangeCurrent(currentJourney) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_CHANGE_CURRENT,
      payload: currentJourney,
    });
  };
}

export function journeyListTagsFetch() {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_LIST_TAGS_FETCH,
    });
    JourneyApi.listTags()
      .then((response) => {
        dispatch({
          type: JOURNEY_LIST_TAGS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_LIST_TAGS_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function journeyUpdateSubmitTags(id, body) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_SUBMIT_JOURNEY,
      payload: body,
    });
    JourneyApi.updateSubmitJourney(id, body)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_SUBMIT_JOURNEY_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_SUBMIT_JOURNEY_FAIL,
          payload: response,
        });
      });
  };
}

export function journeyUpdateTags(id) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_JOURNEY,
      payload: {},
    });
    JourneyApi.updateJourney(id)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_JOURNEY_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_JOURNEY_FAIL,
          payload: response,
        });
      });
  };
}

export function journeyLocalUpdateSteps(steps) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_STEPS_JOURNEY_SUCCESS,
      payload: steps,
    });
  };
}

export function journeyUpdateSubmit(data) {
  return (dispatch) => {
    const fixedBody = {
      ...data?.body,
      steps: removeDuplicateSteps(data?.body?.steps),
    };
    dispatch({
      type: COMMUNICATION_UPDATE_SUBMIT_JOURNEY,
      payload: fixedBody,
    });
    JourneyApi.updateSubmitJourney(data.id, fixedBody)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_SUBMIT_JOURNEY_SUCCESS,
          payload: response,
          redirect: data.redirect,
          showNotificationMessage: data.showNotificationMessage,
        });
        if (data.typeRequest === 'DELETE') {
          showMessage('Step has been deleted successfully', 'success');
        }
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_SUBMIT_JOURNEY_FAIL,
          payload: response,
        });
      });
  };
}

export function journeyListFetch(communicationPageRequest) {
  return (dispatch) => loadJourneyList(dispatch, communicationPageRequest);
}

const loadJourneyList = (dispatch, communicationPageRequest) => {
  dispatch({
    type: JOURNEY_LIST_FETCH,
    payload: communicationPageRequest,
  });
  JourneyApi.list(communicationPageRequest)
    .then((response) => {
      dispatch({
        type: JOURNEY_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: JOURNEY_LIST_FETCH_FAIL,
        payload: response,
      });
      showMessageError(response);
    });
};
export function journeySearchListFetch(communicationPageRequest) {
  return (dispatch) =>
    loadJourneySearchList(dispatch, communicationPageRequest);
}

const loadJourneySearchList = (dispatch, communicationPageRequest) => {
  dispatch({
    type: JOURNEY_LIST_SEARCH_FETCH,
    payload: communicationPageRequest,
  });
  JourneyApi.list(communicationPageRequest)
    .then((response) => {
      dispatch({
        type: JOURNEY_LIST_SEARCH_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: JOURNEY_LIST_SEARCH_FETCH_FAIL,
        payload: response,
      });
      showMessageError(response);
    });
};

export function journeyPageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function journeyDeleteFetch(
  id,
  communicationPageRequest,
  fromDetailPage = false
) {
  return (dispatch) => {
    dispatch({ type: JOURNEY_DELETE_FETCH });
    JourneyApi.delete(id)
      .then((response) => {
        dispatch({
          type: JOURNEY_DELETE_FETCH_SUCCESS,
          payload: response,
        });
        showMessage('Has been deleted successfully', 'success');
        if (fromDetailPage) {
          history.push(
            fromDetailPage === 'RELAY'
              ? '../../relays/list'
              : '../../journeys/list'
          );
        } else {
          loadJourneyList(dispatch, communicationPageRequest);
        }
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_DELETE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function journeyListAttribtesFetch(productId) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_LIST_ATTRIBUTES_FETCH,
      payload: productId,
    });
    JourneyApi.getAttributes(productId)
      .then((response) => {
        dispatch({
          type: JOURNEY_LIST_ATTRIBUTES_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_LIST_ATTRIBUTES_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function journeyListEventsFetch(productId) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_LIST_EVENTS_FETCH,
      payload: productId,
    });
    JourneyApi.getEvents(productId)
      .then((response) => {
        dispatch({
          type: JOURNEY_LIST_EVENTS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_LIST_EVENTS_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function journeyListBusinessEventsFetch(productId) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_LIST_BUSINESS_EVENTS_FETCH,
      payload: productId,
    });
    JourneyApi.getBusinessEvents(productId)
      .then((response) => {
        dispatch({
          type: JOURNEY_LIST_BUSINESS_EVENTS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_LIST_BUSINESS_EVENTS_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function journeyBusinessEventsReportFetch(id, body) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_BUSINESS_EVENTS_REPORT_FETCH,
    });
    journeyApi
      .getBusinessEventsReport(id, body)
      .then((response) => {
        dispatch({
          type: JOURNEY_BUSINESS_EVENTS_REPORT_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_BUSINESS_EVENTS_REPORT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function journeyListSegmentsFetch(productId) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_LIST_SEGMENTS_FETCH,
      payload: productId,
    });
    JourneyApi.getSegments(productId)
      .then((response) => {
        dispatch({
          type: JOURNEY_LIST_SEGMENTS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_LIST_SEGMENTS_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function journeyListTemplateNames(productId) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_TEMPLATE_NAMES_LIST_FETCH,
      payload: productId,
    });
    JourneyApi.getTemplateNames(productId).then((response) => {
      dispatch({
        type: JOURNEY_TEMPLATE_NAMES_LIST_SUCCESS,
        payload: response,
      });
    });
  };
}

export function journeyCopyFetch(id, pageRequest, fromDetailPage = false) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_COPY_FETCH,
    });
    JourneyApi.copyJourney(id)
      .then((response) => {
        dispatch({
          type: JOURNEY_COPY_FETCH_SUCCESS,
          payload: response,
        });
        showMessage('Has been duplicated successfully', 'success');
        if (fromDetailPage) {
          history.push(
            fromDetailPage === 'RELAY'
              ? '../../relays/list'
              : '../../journeys/list'
          );
        } else {
          loadJourneyList(dispatch, pageRequest);
        }
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_COPY_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function journeyGetReportOverview(id, body) {
  let fromDate = body?.fromDate;
  const toDate = body?.toDate || moment().format('YYYY-MM-DD');

  return async (dispatch) => {
    try {
      const journeyDetails = await JourneyApi.view(id);
      if (!body?.fromDate) {
        fromDate = moment(journeyDetails.createdDate).format('YYYY-MM-DD');
      }
    } catch (error) {
      fromDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    }

    dispatch({
      type: JOURNEY_REPORT_OVERVIEW,
      payload: id,
    });

    JourneyApi.getReportOverview(id, { ...body, fromDate, toDate })
      .then((response) => {
        dispatch({
          type: JOURNEY_REPORT_OVERVIEW_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_REPORT_OVERVIEW_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function journeyViewFetch(id) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_VIEW_FETCH,
    });
    JourneyApi.view(id)
      .then((response) => {
        dispatch({
          type: JOURNEY_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_VIEW_FETCH_FAIL,
        });
        showMessageError(response);
      });
  };
}

export function journeyReportCommunicationReportFetch(body) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_REPORT_COMMUNICATION_FETCH,
      payload: body,
    });
    JourneyApi.getReportCommunication(body)
      .then((response) => {
        dispatch({
          type: JOURNEY_REPORT_COMMUNICATION_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_REPORT_COMMUNICATION_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function journeyReportEnterOrExitReportFetch(body) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_REPORT_ENTER_OR_EXIT_FETCH,
    });
    JourneyApi.getEnterOrExitCommunication(body)
      .then((response) => {
        dispatch({
          type: JOURNEY_REPORT_ENTER_OR_EXIT_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_REPORT_ENTER_OR_EXIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function journeyGetReportLive(id, body) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_REPORT_LIVE,
      payload: id,
    });

    JourneyApi.getReportLive(id)
      .then((response) => {
        dispatch({
          type: JOURNEY_REPORT_LIVE_SUCCESS,
          payload: response,
        });

        if (body.steps) {
          const steps = body.steps.map((step) => {
            const analyticInfoStep = response.filter(
              // eslint-disable-next-line max-nested-callbacks
              (live) => live.stepId === step.id
            )[0];
            const ui = Object.assign({}, step.ui, {
              analyticInfo: analyticInfoStep,
            });
            return Object.assign({}, step, ui);
          });
          const data = Object.assign({}, body, { steps: steps });
          // return Object.assign({}, body, steps);
          dispatch({
            type: COMMUNICATION_UPDATE_JOURNEY_SUCCESS,
            payload: data,
          });
        }
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_REPORT_LIVE_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function journeyLaunch(data) {
  return (dispatch) => {
    const fixedBody = {
      ...data?.body,
      steps: removeDuplicateSteps(data?.body?.steps),
    };
    dispatch({
      type: JOURNEY_LANUCH,
      payload: fixedBody,
    });
    JourneyApi.launch(data.id, fixedBody)
      .then((response) => {
        dispatch({
          type: JOURNEY_LANUCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_LANUCH_FAIL,
          payload: response,
        });
        if (response.status && response.errorMessage) {
          showMessage(response.errorMessage, 'error');
        } else if (response.status === 'NOT_ACCEPTABLE') {
          const hasCommunicationError = response.errors.find(
            (m) =>
              m.message.search('The communication should be completed') >= 0
          );
          const hasUnReachableError = response.errors.find(
            (m) => m.message.search('step.unreachable') >= 0
          );

          if (hasCommunicationError) {
            showMessageError({
              status: 'Error',
              errors: [
                {
                  message:
                    'Before publishing, please configure all Steps, or remove the ones not needed',
                  // key: 'NOT_ACCEPTABLE',
                },
              ],
            });
          } else if (hasUnReachableError) {
            showMessageError({
              status: 'Error',
              errors: [
                {
                  message:
                    'Before publishing, please ensure there are no unreachable Steps ',
                  // key: 'NOT_ACCEPTABLE',
                },
              ],
            });
          } else {
            showMessageError({
              status: 'Error',
              errors: [
                {
                  message: 'Before publishing, please fix all Steps errors!',
                },
              ],
            });
          }
        }
      });
  };
}

export function journeyClearLaunchData() {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_LANUCH_CLEAR,
    });
  };
}

export function journeyStop(id, pageRequest = null, fromDetailPage = false) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_STOP,
      payload: {},
    });
    JourneyApi.stop(id)
      .then((response) => {
        dispatch({
          type: JOURNEY_STOP_SUCCESS,
          payload: response,
        });
        if (fromDetailPage) {
          dispatch(journeyViewFetch(id));
          showMessage('Has been paused successfully', 'success');
        } else if (pageRequest) {
          loadJourneyList(dispatch, pageRequest);
          showMessage('Has been stopped successfully', 'success');
        }
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_STOP_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function journeyPause(id, pageRequest = null, fromDetailPage = false) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_PAUSE,
      payload: {},
    });
    JourneyApi.pause(id)
      .then((response) => {
        dispatch({
          type: JOURNEY_PAUSE_SUCCESS,
          payload: response,
        });
        if (fromDetailPage) {
          dispatch(journeyViewFetch(id));
          showMessage('Has been paused successfully', 'success');
        } else if (pageRequest) {
          loadJourneyList(dispatch, pageRequest);
          showMessage('Has been paused successfully', 'success');
        }
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_PAUSE_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function journeyTestApi(data) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_TEST_API,
      payload: data,
    });
    JourneyApi.testApi(data)
      .then((response) => {
        dispatch({
          type: JOURNEY_TEST_API_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_TEST_API_FAIL,
          payload: response,
        });
      });
  };
}
export function journeyEngagedUserReportFetch(engagedUserPageRequest) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_ENGAGED_USER_FETCH,
      payload: engagedUserPageRequest,
    });
    journeyApi
      .engagedUsers(engagedUserPageRequest)
      .then((response) => {
        dispatch({
          type: JOURNEY_ENGAGED_USER_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_ENGAGED_USER_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function journeyArchive(id) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_ARCHIVE_FETCH,
    });
    journeyApi
      .archive(id)
      .then((response) => {
        dispatch({
          type: JOURNEY_ARCHIVE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been archived successfully');
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_ARCHIVE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function journeyActivate(id) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_ACTIVE_FETCH,
    });
    journeyApi
      .active(id)
      .then((response) => {
        dispatch({
          type: JOURNEY_ACTIVE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been activated successfully');
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_ACTIVE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function journeyReportOverviewCsvFetch(body) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_REPORT_OVERVIEW_CSV_FETCH,
    });
    journeyApi
      .reportCsv(body)
      .then((response) => {
        dispatch({
          type: JOURNEY_REPORT_OVERVIEW_CSV_SUCCESS,
          payload: response,
        });
        toastr.success('Has been saved successfully');
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_REPORT_OVERVIEW_CSV_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function journeyReportCommunicationsOverviewCsvFetch(body) {
  return (dispatch) => {
    dispatch({
      type: JOURNEY_REPORT_COMMUNICATION_OVERVIEW_CSV_FETCH,
    });
    journeyApi
      .reportCommunicationsCsv(body)
      .then((response) => {
        dispatch({
          type: JOURNEY_REPORT_COMMUNICATION_OVERVIEW_CSV_SUCCESS,
          payload: response,
        });
        toastr.success('Has been saved successfully');
      })
      .catch((response) => {
        dispatch({
          type: JOURNEY_REPORT_COMMUNICATION_OVERVIEW_CSV_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
