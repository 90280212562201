import moment from 'moment/moment';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

import { isNil } from 'lodash';

export function viewDateTime(x) {
  if (x) {
    return moment(x).format('YYYY-MM-DD HH:mm:ss');
  }
  return x;
}

export function viewTime(x) {
  if (x) {
    if (moment(x).isValid()) {
      return moment(x).format('hh:mm a');
    } else if (moment(x, 'HH:mm:ss').isValid()) {
      return moment(x, 'HH:mm:ss').format('hh:mm a');
    }
  }
  return x;
}

export function viewDuration(x) {
  if (x) {
    return moment.duration(x, 'minutes').format('h [hrs], m [min]');
  }
  return x;
}

export function viewDate(x) {
  if (x) {
    return moment(x).format('YYYY-MM-DD');
  }
  return x;
}

export function viewEpochTime(x) {
  if (x) {
    return new Date(x).getTime();
  }
  return x;
}

export function readableDateTime(x) {
  if (x) {
    return moment(x).format("DD MMM [']YY, hh:mm a");
  }
  return x;
}

export function readableDate(x) {
  if (x) {
    return moment(x).format("DD MMM [']YY");
  }
  return x;
}

export function getDelayMinutes(value, duration) {
  let delayMinutes = value;
  if (
    duration === 'hours' ||
    duration === 'hour' ||
    duration === 'HOUR' ||
    duration === 'HOURS'
  ) {
    delayMinutes *= 60;
  } else if (
    duration === 'days' ||
    duration === 'day' ||
    duration === 'DAY' ||
    duration === 'DAYS'
  ) {
    delayMinutes = delayMinutes * 60 * 24;
  } else if (
    duration === 'weeks' ||
    duration === 'week' ||
    duration === 'WEEK' ||
    duration === 'WEEKS'
  ) {
    delayMinutes = delayMinutes * 60 * 24 * 7;
  } else if (
    duration === 'months' ||
    duration === 'month' ||
    duration === 'MONTH' ||
    duration === 'MONTHS'
  ) {
    delayMinutes = delayMinutes * 60 * 24 * 30;
  } else if (
    duration === 'years' ||
    duration === 'year' ||
    duration === 'YEAR' ||
    duration === 'YEARS'
  ) {
    delayMinutes = delayMinutes * 60 * 24 * 365;
  }
  return delayMinutes;
}

function isFullHour(minutes) {
  return minutes % 60 === 0 && minutes !== 0 && minutes !== '0';
}

function isFullDay(minutes) {
  return minutes % (60 * 24) === 0 && minutes !== 0 && minutes !== '0';
}

function isFullWeek(minutes) {
  return minutes % (60 * 24 * 7) === 0 && minutes !== 0 && minutes !== '0';
}

function isFullYear(minutes) {
  return minutes % (60 * 24 * 365) === 0 && minutes !== 0 && minutes !== '0';
}

export function convertMinute(minutes) {
  if (!isNil(minutes)) {
    if (isFullYear(minutes)) {
      return {
        type: 'YEAR',
        value: String(Math.round(minutes / (60 * 24 * 365))),
      };
    }
    // if (isFullMonths(minutes)) {
    //     return { type: 'MONTH', value: String(Math.round(minutes / (60 * 24 * 30))) };
    // }
    if (isFullWeek(minutes)) {
      return {
        type: 'WEEK',
        value: String(Math.round(minutes / (60 * 24 * 7))),
      };
    }
    if (isFullDay(minutes)) {
      return { type: 'DAY', value: String(Math.round(minutes / (60 * 24))) };
    }
    if (isFullHour(minutes)) {
      return { type: 'HOUR', value: String(Math.round(minutes / 60)) };
    }
    return { type: 'MINUTE', value: String(Math.round(minutes)) };
    // return { type: 'DAY', value: String(Math.round(1440 / (60 * 24))) };
  }
  return { type: 'DAY', value: '' };
}

export function timesOptions() {
  return [
    { name: 'Minutes', id: 'MINUTE', min: 1 },
    { name: 'Hours', id: 'HOUR', min: getDelayMinutes(1, 'hour') },
    { name: 'Days', id: 'DAY', min: getDelayMinutes(1, 'day') },
    { name: 'Weeks', id: 'WEEK', min: getDelayMinutes(1, 'week') },
    { name: 'Years', id: 'YEAR', min: getDelayMinutes(1, 'year') },
  ];
}
export function convertToJalali(gregorianDate) {
  const gregorianParts = gregorianDate.split('-').map(Number);

  const gregorianYear = gregorianParts[0];
  const gregorianMonth = gregorianParts[1];
  const gregorianDay = gregorianParts[2];

  const gy = gregorianYear - 1600;
  const gm = gregorianMonth - 1;
  const gd = gregorianDay - 1;

  let gDayNo =
    365 * gy +
    Math.floor((gy + 3) / 4) -
    Math.floor((gy + 99) / 100) +
    Math.floor((gy + 399) / 400);

  for (let i = 0; i < gm; ++i) {
    gDayNo += gregorianDaysInMonth(i);
  }
  if (gm > 1 && ((gy % 4 == 0 && gy % 100 != 0) || gy % 400 == 0)) {
    ++gDayNo;
  }
  gDayNo += gd;

  let jDayNo = gDayNo - 79;

  const jNP = Math.floor(jDayNo / 12053);
  jDayNo %= 12053;

  let jy = 979 + 33 * jNP + 4 * Math.floor(jDayNo / 1461);
  jDayNo %= 1461;

  if (jDayNo >= 366) {
    jy += Math.floor((jDayNo - 1) / 365);
    jDayNo = (jDayNo - 1) % 365;
  }

  let jm = 0;
  let jd = 0;

  for (let i = 0; i < 11 && jDayNo >= jalaliDaysInMonth(jy, i); ++i) {
    jDayNo -= jalaliDaysInMonth(jy, i);
    jm = i + 2;
  }

  jd = jDayNo + 1;

  return `${jy}/${jm}/${jd}`;
}

function gregorianDaysInMonth(month) {
  const daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  return daysPerMonth[month];
}

function jalaliDaysInMonth(year, month) {
  const isLeap =
    year % 33 === 1 ||
    year % 33 === 5 ||
    year % 33 === 9 ||
    year % 33 === 13 ||
    year % 33 === 17 ||
    year % 33 === 22 ||
    year % 33 === 26 ||
    year % 33 === 30;
  return month === 11 ? (isLeap ? 30 : 29) : 31 - Math.floor(month / 6);
}

export function convertDate(dateString, locale = 'en') {
  const date = new Date(dateString);
  const isPersian = locale.toLowerCase() === 'fa';

  const optionsFA = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC',
    timeZoneName: 'short',
  };

  if (isPersian) {
    // Format the date using Persian (Fa) locale
    const faLocale = 'fa';
    const faFormatter = new Intl.DateTimeFormat(faLocale, optionsFA);
    return faFormatter.format(date);
  } else {
    // Check if the locale is 'en' and format accordingly
    if (locale.toLowerCase() === 'en') {
      return date.toISOString();
    }
    // Format the date using English (En) locale with long month name
    const enLocale = 'en-US';
    const enFormatter = new Intl.DateTimeFormat(enLocale, options);
    return enFormatter.format(date);
  }
}
