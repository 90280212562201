import { Card, Col, InputNumber, Row, Select, Switch } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useContext, useEffect, useState, memo } from 'react';
import ProductSegmentPredicateComponent from '../../../../modules/insight/productSegment/component/ProductSegmentPredicateComponent';
import { AppContext } from '../../app';
import './styles.less';

const deadlineTypes = [
  {
    name: 'hours',
    displayName: 'Hours',
  },
  {
    name: 'days',
    displayName: 'Days',
  },
];

const predicateDefault = {
  // "conversionEvent": null,
  // "conversionEventFilterConjunction": null,
  // "conversionEventFilterPredicates": null,
  type: 'EVENT_OCCURRENCE',
};

const ConversionTrackingStep = (props) => {
  const { journey, syncCache } = useContext(AppContext);
  const {
    events,
    attributes,
    fetchRecommendDeviceAttributes,
    fetchRecommendEventAttributes,
    fetchRecommendUserAttributes,
    recommendDeviceAttributes,
    recommendEventAttributes,
    recommendUserAttributes,
  } = props.data;
  const [state, setState] = useState(false);
  const [controlGroupState, setControlGroupState] = useState(false);
  const [deadline, setDeadline] = useState(7);
  const [variation, setVariation] = useState();
  const [deadLineType, setDeadLineType] = useState('hours');
  const [predicate, setPredicate] = useState(predicateDefault);

  useEffect(() => {
    //note: Just on response of update props.data.journey filled
    if (journey) {
      setState(journey?.conversion);
      setControlGroupState(journey?.controlGroup);
      // setDeadline(props.data.journey?.conversionDeadlineMinutes);
      const deadLineTmp = getDefaultDeadlineType();
      setDeadline(deadLineTmp.time);
      setDeadLineType(deadLineTmp.type);

      const predicate_ = {
        eventConjunction: journey?.conversionEventFilterConjunction || 'OR',
        eventPredicates: journey?.conversionEventFilterPredicates,
        event: journey?.conversionEvent,
      };
      // const predicateUpdated = Object.assign({}, changedPredicate, predicate_)
      setPredicate(Object.assign({}, predicateDefault, predicate_));
    }
  }, [journey]);

  const onConversionStateChange = (state) => {
    setState(state);
    // syncCache("conversion", state);
    let predicate_ = {
      conversion: state,
      conversionEventFilterConjunction: '',
      conversionEventFilterPredicates: [],
      conversionEvent: null,
      conversionDeadlineMinutes: 0,
    };
    if (state) {
      predicate_.conversionDeadlineMinutes = 7 * 60;
    }
    syncCache('group-update', predicate_);
  };

  // const onEventChange = (value) => {
  //   const event = events.filter((ev) => ev.id === value)[0];
  //   syncCache('conversionEvent', event.id);
  // };

  const onInputDeadlineChange = (e) => {
    const value = e?.target?.value || e;
    let mValue = 0;
    if (deadLineType === 'hours') {
      mValue = 60 * value;
    } else {
      mValue = 60 * 24 * value;
    }
    syncCache('conversionDeadlineMinutes', mValue);
  };

  const onCheckboxChange = (e) => {
    setControlGroupState(e.target.checked);
    const state_group = {
      controlGroup: e.target.checked,
      controlGroupPercentage: 0,
    };
    syncCache('group-update', state_group);
  };

  const onVariationChange = (value) => {
    if (value > 100 || value < 0) return;
    setVariation(value);
    syncCache('controlGroupPercentage', value);
  };

  const getDefaultDeadlineType = () => {
    let time_ = 7;
    let type_ = 'hours';
    const timeInMinutes = journey?.conversionDeadlineMinutes;
    if (timeInMinutes) {
      if (timeInMinutes >= 60 * 24 && timeInMinutes % (60 * 24) === 0) {
        type_ = 'days';
        time_ = Math.round(journey?.conversionDeadlineMinutes / (60 * 24));
      } else {
        time_ = Math.round(journey?.conversionDeadlineMinutes / 60);
      }
    }
    return {
      time: time_,
      type: type_,
    };
  };

  const onChangeSelectType = (type) => {
    setDeadLineType(type);
    const value = deadline;
    let mValue = 0;
    if (type === 'hours') {
      mValue = 60 * value;
    } else {
      mValue = 60 * 24 * value;
    }
    syncCache('conversionDeadlineMinutes', mValue);
  };

  const handleChangePredicate = (changedPredicate) => {
    const predicate_ = {
      conversionEventFilterConjunction: changedPredicate.eventConjunction,
      conversionEventFilterPredicates: changedPredicate.eventPredicates,
      conversionEvent: changedPredicate.event,
    };
    const predicateUpdated = Object.assign({}, changedPredicate, predicate_);
    setPredicate(predicateUpdated);
    syncCache('group-update', predicate_);
  };

  const eventOccurrenceSteps = journey.steps.filter(
    (step) => step.type === 'EVENT_OCCURRENCE'
  );
  const isDynamic = eventOccurrenceSteps.length === 1;
  const dynamicEvent = isDynamic ? eventOccurrenceSteps[0] : null;
  const dynamicConversion = isDynamic && dynamicEvent.hasOwnProperty('event');
  return (
    <div>
      {props.currentStep === 'conversionTracking' && (
        <Row>
          {!props?.readOnly && (
            <Col span={20} offset={1}>
              <Card
                title="Conversion Tracking"
                bordered={false}
                style={{ minHeight: '300px', marginBottom: 30 }}
              >
                <div>
                  <label style={{ marginRight: '10px' }}>
                    Conversion Tracking
                  </label>
                  <Switch
                    defaultChecked={state}
                    checked={state}
                    onChange={onConversionStateChange}
                  />
                </div>
                {state && (
                  <div className="covtrac_box">
                    <div className="covtrac_box__select">
                      <ProductSegmentPredicateComponent
                        journey={true}
                        // readOnly={props.readOnly}
                        type={'EVENT_OCCURRENCE'}
                        predicate={predicate}
                        attributes={attributes}
                        events={events}
                        isInSegment={false}
                        handleChangePredicate={(changedPredicate) =>
                          handleChangePredicate(changedPredicate)
                        }
                        clearSegmentError={() => {
                          //TODO Must Handle
                        }}
                        fetchRecommendEventAttributes={
                          fetchRecommendEventAttributes
                        }
                        recommendEventAttributes={recommendEventAttributes}
                        fetchRecommendDeviceAttributes={
                          fetchRecommendDeviceAttributes
                        }
                        recommendDeviceAttributes={recommendDeviceAttributes}
                        fetchRecommendUserAttributes={
                          fetchRecommendUserAttributes
                        }
                        recommendUserAttributes={recommendUserAttributes}
                        dynamicEvent={dynamicEvent}
                        dynamicConversion={dynamicConversion}
                      />
                    </div>
                    {/* <Select
                    showSearch
                    style={{ width: 300 }}
                    placeholder="Select a event"
                    onChange={onEventChange}
                    defaultValue={journey?.conversionEvent}
                  >
                    {events?.map((item, i) => (
                      <Select.Option 
                        key={item.id} 
                        value={item.id}>
                        {item.title}
                      </Select.Option>
                    ))}
                  </Select> */}

                    <br />
                    <br />
                    <div>
                      <h3>CONVERSION DEADLINE</h3>
                      <div className={'covtrac_deadline'}>
                        <InputNumber
                          style={{
                            width: 100,
                            borderRadius: 5,
                            borderColor: !deadline ? 'red' : '',
                          }}
                          value={deadline}
                          onChange={onInputDeadlineChange}
                          min={0}
                        />
                        <Select
                          style={{ width: 200, margin: '0 20px' }}
                          placeholder="Select a type"
                          onChange={onChangeSelectType}
                          defaultValue={deadLineType}
                          value={deadLineType}
                        >
                          {deadlineTypes.map((item) => (
                            <Select.Option key={item.key} value={item.name}>
                              {item.displayName}
                            </Select.Option>
                          ))}
                        </Select>
                        <p> from delivery of message to user</p>
                      </div>
                    </div>

                    <br />
                    <br />
                    <div>
                      <h3>Control Group (CG)</h3>
                      <Checkbox
                        onChange={onCheckboxChange}
                        checked={controlGroupState}
                      />{' '}
                      Add a control group to measure effectiveness of{' '}
                      <span> {props.type.toLowerCase()}</span>
                    </div>
                    {controlGroupState && (
                      <div>
                        <label>Variation Distribution(%):&nbsp;</label>
                        <InputNumber
                          style={{ width: 300 }}
                          min={0}
                          max={100}
                          type="number"
                          defaultValue={journey?.controlGroupPercentage}
                          value={variation}
                          onChange={onVariationChange}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Card>
            </Col>
          )}
          {props?.readOnly && (
            <div className={'conv-tracking__readonly'}>
              <span>
                {events?.find((m) => m.id === journey?.conversionEvent)?.title}
              </span>
              <span>{deadline}</span>
              <span>{deadLineType}</span>
              <span>from delivery of message to user</span>
              <span>{(journey?.controlGroupPercentage || '') + '%'}</span>
              <span>
                Add a control group to measure effectiveness of journey
              </span>
            </div>
          )}
        </Row>
      )}
    </div>
  );
};

export default memo(ConversionTrackingStep);
