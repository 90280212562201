import BaseApi from '../../../api/BaseApi';

const basePathAndroid = '/sdk/android';
const basePathWeb = '/sdk/web';
const basePathIos = '/sdk/ios';
class ProductSdkApi {
  static viewAndroid() {
    return BaseApi.post(basePathAndroid + '/view', {});
  }
  static revokeAndroid() {
    return BaseApi.post(basePathAndroid + '/revoke', {});
  }
  static viewWeb() {
    return BaseApi.post(basePathWeb + '/view', {});
  }
  static revokeWeb() {
    return BaseApi.post(basePathWeb + '/revoke', {});
  }
  static viewIOS() {
    return BaseApi.post(basePathIos + '/view', {});
  }
}

export default ProductSdkApi;
