import { convertMinute } from '@Utils/DateUtils';
import { Card } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app';
import { GapBaseOptions } from '../../utils/static';
import './sidebarReport.less';
import { numberWithCommas } from '@Utils/RenderUtils';

const SidebarReport = (props) => {
  const { journey } = useContext(AppContext);
  const [eName, setEName] = useState();
  const [segment, setSegment] = useState();
  // eslint-disable-next-line no-unused-vars
  const [cGroup, setCGroup] = useState();
  const [deadline, setDeadline] = useState(2);
  const [deadLineType, setDeadLineType] = useState('hours');
  const { attributes, events, segments } = props.data;

  const overview = props.data.reportOverview;
  const usersInJourney = (overview && overview.inJourney) || 0;
  const userCount = (overview && overview.uniqueConversions) || 0;
  const totalEnt = (overview && overview.entries) || 0;
  const totalExi = (overview && overview.exits) || 0;
  const revenue = (overview && overview.revenue) || 0;
  const exitTriggers = journey.exitTriggers;

  const gapMinutesTimes = useCallback(() => {
    if (journey.gapMinutes) {
      const minuteObject = convertMinute(journey.gapMinutes);
      if (minuteObject) {
        return `${minuteObject?.value} ${minuteObject?.type}`;
      }
    }
    return;
  }, [journey.gapMinutes]);

  useEffect(() => {
    if (journey?.conversionEvent) {
      const event_ = props.data?.events?.find(
        (event) => event.id === journey?.conversionEvent
      );
      if (event_?.title) setEName(event_.title);
      if (event_?.controlGroupPercentage)
        setCGroup(event_.controlGroupPercentage);

      const deadLineTmp = getDefaultDeadlineType();
      setDeadline(deadLineTmp.time);
      setDeadLineType(deadLineTmp.type);
    }
  }, [journey?.conversionEvent]);

  useEffect(() => {
    const segment_ = props.data?.segments?.find(
      (seg) => seg.id === journey?.segment
    );
    setSegment(segment_);
  }, [journey?.segment]);

  const getDefaultDeadlineType = () => {
    let time_;
    let type_ = 'days';
    if (journey?.queueMinutes % 1440 !== 0) {
      type_ = 'hours';
      time_ = (journey?.queueMinutes / 60).toFixed(2);
    } else {
      time_ = journey?.queueMinutes / 1440;
    }
    return {
      time: time_,
      type: type_,
    };
  };

  const ExitTriggerList = useCallback(() => {
    if (exitTriggers.length) {
      return exitTriggers.map((step) => {
        let name = 'No Title';
        let type = 'EVENT';
        if (step.event) {
          name = `On ${events?.find((item) => item.id === step.event)?.title}`;
        } else if (step.segment) {
          name = `When User ${step?.segmentType} ${
            segments?.find((item) => item.id === step.segment)?.name
          }`;
          type = 'SEGMENT';
        } else if (step.attribute) {
          name = `When User's ${
            attributes?.find((item) => item.id === step.attribute)?.title
          } change`;
          type = 'ATTRIBUTE';
        }
        return {
          key: step.id,
          name,
          type,
        };
      });
    }
    return [];
  }, [exitTriggers, events]);

  return (
    <div className={'sidebar-report'}>
      <Card className={'sidebar-card'}>
        <Card.Grid className={'grid-style-full'}>
          <div className={'sidebar-card-title sidebar-head'}>
            USERS IN {props.type} NOW
          </div>
          <div className={'sidebar-card-count'}>{usersInJourney}</div>
        </Card.Grid>
        <Card.Grid className={'grid-style-half'}>
          <div className={'sidebar-card-title'}>TOTAL ENTRIES</div>
          <div>{totalEnt}</div>
        </Card.Grid>
        <Card.Grid className={'grid-style-half'}>
          <div className={'sidebar-card-title'}>TOTAL EXITS</div>
          <div>{totalExi}</div>
        </Card.Grid>
      </Card>

      <Card title={'SEGMENT & GAP'} className={'sidebar-card'}>
        <Card.Grid className={'grid-style-full'}>
          <div className={'sidebar-card-title'}>SEGMENT </div>
          <div>{segment?.name || 'All Users'}</div>
        </Card.Grid>
        <Card.Grid className={'grid-style-full'}>
          <div className={'sidebar-card-title'}>GAP BETWEEN</div>
          <div>
            {journey.gapType ? (
              <ul className={'sidebar-item__list'}>
                <li>
                  <label>GAP TYPE:</label>
                  <div>{journey.gapType}</div>
                </li>
                {journey.gapMinutes ? (
                  <li>
                    <label>GAP MINUTES:</label>
                    <div>{gapMinutesTimes()}</div>
                  </li>
                ) : (
                  ''
                )}
                {journey.gapBase ? (
                  <li>
                    <label>GAP BASE:</label>
                    <div>
                      {
                        GapBaseOptions.find(
                          (item) => journey.gapBase === item.value
                        )?.name
                      }
                    </div>
                  </li>
                ) : (
                  ''
                )}
              </ul>
            ) : (
              'OFF'
            )}
          </div>
        </Card.Grid>
      </Card>

      <Card className={'sidebar-card'} title={'EXIT TRIGGERS'}>
        {journey.exitTriggers?.length ? (
          ExitTriggerList()?.map((item, i) => {
            return (
              <Card.Grid key={`item-${i}`} className={'grid-style-full'}>
                <label className={'sidebar-card-title'}>{item.type}</label>
                <div>{item.name}</div>
              </Card.Grid>
            );
          })
        ) : (
          <Card.Grid className={'grid-style-full'}>Not Set</Card.Grid>
        )}
      </Card>

      <Card title="CONVERSION TRACKING" className={'sidebar-card'}>
        <Card.Grid className={'grid-style-full'}>
          <div className={'sidebar-card-title'}>CONVERSION EVENT</div>
          <div>
            {journey.conversion ? eName : 'Off'}
            {userCount ? ` (${userCount})` : ''}
          </div>
        </Card.Grid>
        <Card.Grid className={'grid-style-full'}>
          <div className={'sidebar-card-title'}>CONTROL GROUP PERCENTAGE</div>
          <div>
            {journey.controlGroupPercentage
              ? `${journey.controlGroupPercentage}%`
              : 'Off'}
          </div>
        </Card.Grid>
        <Card.Grid className={'grid-style-full'}>
          <div className={'sidebar-card-title'}>REVENUE</div>
          <div>{revenue ? numberWithCommas(revenue) : '-'}</div>
        </Card.Grid>
        {/* <Card.Grid className={"grid-style-full"}>
          <div>REVENUE (USD)</div>
          <div>-</div>
        </Card.Grid> */}
      </Card>

      <Card title="FC, DND & QUEUEING" className={'sidebar-card'}>
        <Card.Grid className={'grid-style-full'}>
          <div className={'sidebar-card-title'}>FC</div>
          <div>{journey.followFrequencyCapping ? 'Follow' : 'Ignore'}</div>
        </Card.Grid>
        <Card.Grid className={'grid-style-full'}>
          <div className={'sidebar-card-title'}>DND</div>
          <div>{journey.followDnd ? 'Follow' : 'Ignore'}</div>
        </Card.Grid>
        <Card.Grid className={'grid-style-full'}>
          <div className={'sidebar-card-title'}>Queueing </div>
          <div>
            {journey?.queueMinutes
              ? `Queue for ${deadline} ${deadLineType}`
              : 'Do not queue'}
          </div>
        </Card.Grid>
      </Card>
    </div>
  );
};

export default SidebarReport;
