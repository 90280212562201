import ProductSegmentListComponent from '../component/ProductSegmentListComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  productSegmentPageChangeCurrent,
  productSegmentListFetch,
  productSearchSegmentListFetch,
  productSegmentDeleteFetch,
  productSegmentCopyFetch,
  productSegmentFileUploadReset,
  productSegmentStaticCreateToggle,
  productSegmentFileUploadFetch,
  productSegmentCsvCreateFetch,
  productSegmentListAttributesFetch,
  productSegmentListReset,
  clearSegmentError,
  productSegmentArchive,
} from '../ProductSegmentActions';
import { callExportSegmentFile } from '@Component/utils/CsvExport/CsvExportActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  page: state.insight.productSegment.page,
  pageRequest: state.insight.productSegment.pageRequest,

  staticCreateOpen: state.insight.productSegment.staticCreateOpen,
  errorsUploadFile: state.insight.productSegment.errorsUploadFile,
  uploadedFile: state.insight.productSegment.uploadedFile,
  loadingUploadFile: state.insight.productSegment.loadingUploadFile,

  loadingCreate: state.insight.productSegment.loadingCreate,
  errorsCreate: state.insight.productSegment.errorsSegmentSubmit,
  saveClicked: state.insight.productSegment.saveClicked,

  attributes: state.insight.productSegment.attributes,
  loadingAttributes: state.insight.productSegment.loadingAttributes,
  loadingSegmentList: state.insight.productSegment.loadingSegmentList,
  loadingSegmentSearchList:
    state.insight.productSegment.loadingSegmentSearchList,
  loadingCopy: state.insight.productSegment.loadingCopy,
  loadingUpdate: state.insight.productSegment.loadingUpdate,
  archiveSuccess: state.insight.productSegment.archiveSuccess,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      productSegmentListAttributesFetch,
      productSegmentPageChangeCurrent,
      productSegmentListFetch,
      productSearchSegmentListFetch,
      productSegmentListReset,
      productSegmentDeleteFetch,
      productSegmentCopyFetch,
      productSegmentFileUploadReset,
      productSegmentStaticCreateToggle,
      productSegmentFileUploadFetch,
      productSegmentCsvCreateFetch,
      clearSegmentError,
      productSegmentArchive,
      callExportSegmentFile,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSegmentListComponent);

ProductSegmentListComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  productSegmentListFetch: PropTypes.func.isRequired,
  productSegmentDeleteFetch: PropTypes.func.isRequired,
  productSegmentPageChangeCurrent: PropTypes.func.isRequired,
};
