import React from 'react';
import { Input, Switch, Spin, Modal, Form, Select } from 'antd';
import { RingProgress } from '@ant-design/charts';
import _, { isNil, isEmpty, cloneDeep, filter } from 'lodash';
import {
  PlusOutlined,
  DownOutlined,
  UpOutlined,
  SearchOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { numberWithCommas } from '../../../../utils/RenderUtils';
import ProductSegmentPredicateComponent from './ProductSegmentPredicateComponent';
import styles from './ProductSegmentStyle';
import {
  getProductId,
  getSegmentType,
} from '../../../../utils/AuthorityProvider';
import { errorHandling } from '../../../../utils/FormUtils';

class ProductSegmentUpdateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.segmentType = getSegmentType(this.props.pathname);
    this.proedicateType = {
      ATTRIBUTE: 'ATTRIBUTE',
      DEVICE: 'DEVICE',
      BEHAVIOUR: 'BEHAVIOUR',
    };
    this.predicateTypeHeader = {
      ATTRIBUTE: 'User Attribute',
      DEVICE: 'Device Attribute',
      BEHAVIOUR: 'Behavioral',
    };
    this.emptyAttribute = {
      attribute: null,
      greaterThan: null,
      lessThan: null,
      negate: false,
      operator: null,
      stringValue: null,
      type: this.proedicateType.ATTRIBUTE,
    };
    this.emptyEvent = {
      event: null,
      eventAggregation: 'EXISTS',
      eventConjunction: 'AND',
      type: this.proedicateType.BEHAVIOUR,
      negate: false,
      eventPredicates: [],
    };
    this.emptyDevice = {
      deviceConjunction: 'AND',
      type: this.proedicateType.DEVICE,
      negate: false,
      devicePredicates: [],
    };
    this.emptyEventPredicate = {
      attribute: null,
      greaterThan: null,
      lessThan: null,
      negate: false,
      operator: null,
      stringValue: null,
    };
    this.emptyDevicePredicate = {
      attribute: null,
      greaterThan: null,
      lessThan: null,
      negate: false,
      operator: null,
      stringValue: null,
    };
    this.state = {
      selectorModalVisible: false,
      selectedHeader: this.predicateTypeHeader.ATTRIBUTE, // DEVICE, BEHAVIOUR
      addToTerms: null,
      selectedTermIndex: null,
      currentSegment: this.isTest() ? { test: true } : null,
      attributesSearch: '',
      eventSearch: '',
      deviceAttributeSearch: '',
      type:
        this.props.match?.params?.type === 'static'
          ? 'STATIC'
          : this.props.match?.params?.type === 'live'
          ? 'DYNAMIC'
          : null,
    };
  }

  componentWillMount() {
    this.props.clearSegmentError(this.props.errorsSegmentSubmit.errors);
  }

  componentDidMount() {
    const productId = getProductId(this.props.pathname);
    const currentProduct = this.props.currentUser?.products?.find(
      (p) => p.id === +productId
    );

    this.setState({
      currentSegment: {
        ...this.state.currentSegment,
        type: this.segmentType,
      },
    });

    if (currentProduct) {
      if (!currentProduct?.sendify) {
        this.props.productSegmentListAttributesFetch({});
        this.props.productSegmentListEventsFetch({});
        this.props.productSegmentListDeviceAttributesFetch({});
      } else {
        this.props.productSegmentListProductSourseFetch();
      }
    }

    if (this.props.match.params.copyId) {
      this.props.productSegmentCopyFetch(this.props.match.params.copyId);
    } else if (this.props.match.params.id) {
      this.props.productSegmentUpdateFetch(this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.productSegmentChangeCurrent({});
    this.props.clearSegmentError(this.props.errorsSegmentSubmit.errors);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.id &&
      !this.props.loadingUpdate &&
      prevProps.loadingUpdate &&
      !isNil(this.props.currentProductSegment) &&
      isEmpty(this.props.errorsUpdate)
    ) {
      let test = this.props.currentProductSegment.test;
      if (this.isTest()) {
        test = true;
      }
      this.setState(
        {
          currentSegment: {
            ...this.props.currentProductSegment,
            test,
          },
        },
        () => {
          this.props.productSegmentReportFetch({
            ...this.props.currentProductSegment,
            type: this.segmentType,
          });
        }
      );
    }
    if (
      this.props.match.params.copyId &&
      !this.props.loadingCopy &&
      prevProps.loadingCopy &&
      !isNil(this.props.currentProductSegment) &&
      isEmpty(this.props.errorsCopy)
    ) {
      this.setState(
        { currentSegment: this.props.currentProductSegment },
        () => {
          this.props.productSegmentReportFetch({
            ...this.props.currentProductSegment,
            type: this.segmentType,
          });
        }
      );
    }
    if (this.props.saveClicked && !prevProps.saveClicked) {
      if (this.props.match.params.id) {
        this.props.productSegmentUpdateSubmitFetch(
          this.props.match.params.id,
          this.filledSegment(),
          this.isTest() || this.isFromAudience()
        );
      } else if (this.props.match.params.copyId) {
        this.props.productSegmentCreateFetch(
          this.filledSegment(),
          this.props.match.params.copyId,
          this.isTest() || this.isFromAudience()
        );
      } else {
        this.props.productSegmentCreateFetch(
          this.filledSegment(),
          null,
          this.isTest() || this.isFromAudience()
        );
      }
    }
    if (this.props.resetClicked && !prevProps.resetClicked) {
      this.setState(
        { currentSegment: { ...this.state.currentSegment, terms: [] } },
        () => {
          this.props.productSegmentResetCompleted();
          this.props.productSegmentReportFetch(this.state.currentSegment);
          this.props.clearSegmentError(this.props.errorsSegmentSubmit.errors);
        }
      );
    }
  }

  isTest = () => {
    return (
      this.props.match.params.source &&
      this.props.match.params.source === 'test'
    );
  };

  isFromAudience = () => {
    return (
      this.props.match.params.source &&
      this.props.match.params.source === 'audience'
    );
  };

  filledSegment = () => {
    let result = cloneDeep(this.state.currentSegment);
    if (isNil(result)) {
      result = {};
    }
    if (isNil(result.type)) {
      result.type = this.segmentType;
    }
    if (isNil(result.test)) {
      result.test = false;
    }
    if (isNil(result.conjunction)) {
      result.conjunction = 'AND';
    }
    if (
      !isEmpty(result.predicates) &&
      Array.isArray(result.predicates) &&
      result.predicates.length > 0 &&
      isNil(result.predicates[0].negate)
    ) {
      result.predicates[0].negate = false;
    }
    return result;
  };

  handleSelectHeader = (selectedHeader) => {
    if (this.state.selectedHeader === selectedHeader) {
      this.setState({ selectedHeader: null });
    } else {
      this.setState({ selectedHeader });
    }
  };

  getMultipleStringValue = (value) => {
    let result = [];
    if (value && !isEmpty(value)) {
      result = value.split(/[\s;,]+/);
    }
    return result;
  };

  handleAddNewFilter = (addToTerms, selectedTermIndex) => {
    this.setState({
      selectorModalVisible: true,
      addToTerms,
      selectedTermIndex,
    });
  };

  handleAddAttribute = (predicateTypeHeader, id) => {
    const termsCopy =
      isNil(this.state.currentSegment) || isNil(this.state.currentSegment.terms)
        ? []
        : cloneDeep(this.state.currentSegment.terms);
    let emptyItem = null;
    if (predicateTypeHeader === this.predicateTypeHeader.ATTRIBUTE) {
      emptyItem = cloneDeep(this.emptyAttribute);
      emptyItem.attribute = id;
    } else if (predicateTypeHeader === this.predicateTypeHeader.BEHAVIOUR) {
      emptyItem = cloneDeep(this.emptyEvent);
      emptyItem.event = id;
    } else if (predicateTypeHeader === this.predicateTypeHeader.DEVICE) {
      emptyItem = cloneDeep(this.emptyDevice);
      const emptyDevicePredicate = cloneDeep(this.emptyDevicePredicate);
      emptyDevicePredicate.attribute = id;
      emptyItem.devicePredicates.push(emptyDevicePredicate);
    }
    if (this.state.addToTerms) {
      termsCopy.push({
        conjunction: 'AND',
        predicates: [emptyItem],
      });
    } else {
      // add to predicates of terms
      termsCopy[this.state.selectedTermIndex].predicates.push(emptyItem);
    }
    let currentSegment = { ...this.state.currentSegment };
    if (
      isNil(this.state.currentSegment) ||
      isNil(this.state.currentSegment.terms)
    ) {
      currentSegment = {
        ...this.state.currentSegment,
        conjunction: 'AND',
      };
    }
    this.setState({
      selectorModalVisible: false,
      addToTerms: null,
      selectedTermIndex: null,
      currentSegment: { ...currentSegment, terms: termsCopy },
    });
  };

  deletePredicate = (predicateIndex, termIndex) => {
    const termsCopy = cloneDeep(this.state.currentSegment.terms);
    termsCopy[termIndex].predicates.splice(predicateIndex, 1);
    if (termsCopy[termIndex].predicates.length === 0) {
      termsCopy.splice(termIndex, 1);
    }
    this.setState(
      {
        currentSegment: {
          ...this.state.currentSegment,
          terms: termsCopy,
        },
      },
      () => {
        this.props.productSegmentReportFetch(this.state.currentSegment);
      }
    );
  };

  isSegmentEmpty = () => {
    let result = false;
    if (isNil(this.state.currentSegment)) {
      result = true;
    }
    if (
      this.state.currentSegment &&
      (!this.state.currentSegment.terms ||
        (this.state.currentSegment.terms &&
          Array.isArray(this.state.currentSegment.terms) &&
          this.state.currentSegment.terms.length === 0))
    ) {
      result = true;
    }
    return result;
  };

  handleChangeTermConjunction = (selectedTermIndex) => {
    const alternateConjunction =
      this.state.currentSegment.terms[selectedTermIndex].conjunction === 'AND'
        ? 'OR'
        : 'AND';
    const termsCopy = cloneDeep(this.state.currentSegment.terms);
    termsCopy[selectedTermIndex].conjunction = alternateConjunction;
    this.setState({
      currentSegment: { ...this.state.currentSegment, terms: termsCopy },
    });
  };

  handleChangePredicate = (
    termIndex,
    predicateIndex,
    predicate,
    deleteMode = false
  ) => {
    const termsCopy = _.isArray(this.state.currentSegment.terms)
      ? [...this.state.currentSegment.terms]
      : [];
    termsCopy[termIndex].predicates[predicateIndex] = cloneDeep(predicate);
    this.setState(
      {
        currentSegment: {
          ...this.state.currentSegment,
          terms: termsCopy,
        },
      },
      () => {
        if (deleteMode) {
          this.props.productSegmentReportFetch(this.state.currentSegment);
        }
      }
    );
  };

  renderChart = () => {
    const totalUsers =
      this.props.currentReport.knownUsersCount +
        this.props.currentReport.unknownUsersCount || 0;
    const unreachableUser = this.props.currentReport.unreachableUsersCount || 0;
    const reachableUser = totalUsers - unreachableUser;
    const percentage = reachableUser / totalUsers;
    const config = {
      height: 144,
      width: 144,
      autoFit: false,
      percent: isNaN(percentage) ? 0 : percentage,
      color: ['#3e89ee', '#dddddd'],
      innerRadius: 0.8,
      radius: 0.98,
      progressStyle: {
        fillOpacity: 1,
        shadowColor: 'rgba(0, 0, 0, 0.05)',
        shadowBlur: 10,
        shadowOffsetX: 3,
        shadowOffsetY: 6,
      },
      statistic: {
        title: {
          style: {
            color: '#272d3b',
            fontSize: '18px',
          },
        },
      },
    };
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 30 }} spin />;
    return (
      <div style={{ padding: '0 5px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {this.props.loadingReport ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 144,
                height: 144,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  paddingTop: 50,
                  paddingBottom: 50,
                }}
              >
                <Spin indicator={inputLoadingSpin} />
              </div>
            </div>
          ) : (
            <RingProgress {...config} />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 20,
          }}
        >
          <div style={styles.chartLegendReachable}></div>
          <span style={{ color: '#1c1c1c' }}>Reachable</span>
          <div style={styles.chartLegendUnreachable}></div>
          <span style={{ color: '#1c1c1c' }}>Unreachable</span>
        </div>
      </div>
    );
  };

  renderChartSummaryItem = (title, number, isLast) => {
    return (
      <div
        style={
          isLast
            ? styles.summaryBox
            : {
                ...styles.summaryBox,
                borderBottom: 'solid 2px #f4f9ff',
              }
        }
      >
        <div style={styles.summaryNumber}>{number}</div>
        <div style={styles.summaryTitle}>{title}</div>
      </div>
    );
  };

  renderChartSummary = () => {
    const totalUsers =
      this.props.currentReport.knownUsersCount +
        this.props.currentReport.unknownUsersCount || 0;
    const unreachableUser = this.props.currentReport.unreachableUsersCount || 0;
    const reachableUser = totalUsers - unreachableUser;
    const data = [
      { title: 'TOTAL USERS', number: numberWithCommas(totalUsers) },
      {
        title: 'KNOWN USERS',
        number: numberWithCommas(this.props.currentReport.knownUsersCount) || 0,
      },
      {
        title: 'UNKNOWN USERS',
        number:
          numberWithCommas(this.props.currentReport.unknownUsersCount) || 0,
      },
      {
        title: 'PHONE REACHABLE USERS',
        number:
          numberWithCommas(this.props.currentReport.phoneReachableUsersCount) ||
          0,
      },
      {
        title: 'PUSH REACHABLE USERS',
        number:
          numberWithCommas(this.props.currentReport.pushReachableUsersCount) ||
          0,
      },
      {
        title: 'EMAIL REACHABLE USERS',
        number:
          numberWithCommas(this.props.currentReport.emailReachableUsersCount) ||
          0,
      },
      {
        title: 'WEBPUSH REACHABLE USERS',
        number:
          numberWithCommas(
            this.props.currentReport.webPushReachableUsersCount
          ) || 0,
      },
      {
        title: 'IN APP REACHABLE USERS',
        number:
          numberWithCommas(this.props.currentReport.inAppReachableUsersCount) ||
          0,
      },
      {
        title: 'ON SITE  REACHABLE USERS',
        number:
          numberWithCommas(
            this.props.currentReport.onSiteReachableUsersCount
          ) || 0,
      },
      {
        title: 'WHATSAPP REACHABLE USERS',
        number:
          numberWithCommas(
            this.props.currentReport.whatsAppReachableUsersCount
          ) || 0,
      },
      {
        title: 'TOTAL REACHABLE USERS',
        number: numberWithCommas(reachableUser),
      },
    ];
    return (
      <React.Fragment>
        {this.props.loadingCopy ||
        this.props.loadingUpdate ||
        this.props.loadingReport ? (
          <div style={{ paddingBottom: 30, paddingTop: 30 }}></div>
        ) : (
          <React.Fragment>
            {' '}
            {data.map((item) =>
              this.renderChartSummaryItem(item.title, item.number, data.length)
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  chartItem = (item, data) => {
    return this.renderChartSummaryItem(item.title, item.number, data.length);
  };

  renderSearchInTitle = (title) => {
    let value = null;
    if (title === this.predicateTypeHeader.ATTRIBUTE) {
      value = this.state.attributesSearch;
    } else if (title === this.predicateTypeHeader.BEHAVIOUR) {
      value = this.state.eventSearch;
    } else if (title === this.predicateTypeHeader.DEVICE) {
      value = this.state.deviceAttributeSearch;
    }
    return (
      <Input
        loading={false}
        allowClear
        placeholder={'Search...'}
        onFocus={() => {
          this.setState({ selectedHeader: title });
        }}
        onChange={(e) => {
          if (title === this.predicateTypeHeader.ATTRIBUTE) {
            this.setState({ attributesSearch: e.target.value });
          } else if (title === this.predicateTypeHeader.BEHAVIOUR) {
            this.setState({ eventSearch: e.target.value });
          } else if (title === this.predicateTypeHeader.DEVICE) {
            this.setState({
              deviceAttributeSearch: e.target.value,
            });
          }
        }}
        value={value}
        style={{ width: 300, marginRight: 30, borderRadius: 5 }}
        suffix={
          isEmpty(value) ? (
            <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          ) : null
        }
      />
    );
  };

  renderAttributeSelectorHeader = (title) => {
    return (
      <div style={styles.modalSectionHeader}>
        <div
          onClick={() => this.handleSelectHeader(title)}
          style={{ cursor: 'pointer' }}
        >
          {title}
        </div>
        <div style={styles.modalSelectionHeaderSearchContent}>
          {this.renderSearchInTitle(title)}
          {this.state.selectedHeader === title ? (
            <UpOutlined
              onClick={() => this.handleSelectHeader(title)}
              style={styles.modalSectionHeaderButton}
            />
          ) : (
            <DownOutlined
              onClick={() => this.handleSelectHeader(title)}
              style={styles.modalSectionHeaderButton}
            />
          )}
        </div>
      </div>
    );
  };

  renderAttributeSelectorItem = (item, predicateTypeHeader) => {
    return (
      <div
        onClick={() => this.handleAddAttribute(predicateTypeHeader, item.id)}
        style={styles.modalSectionSelectorContent}
      >
        <div>{item.title}</div>
        <div>{item.description ? item.description : ' '}</div>
        <div style={styles.modalSectionSelectorButtonContent}>
          <PlusOutlined style={styles.modalSectionSelectorButton} />
        </div>
      </div>
    );
  };

  searchCheck = (search, item) => {
    let isInName = false;
    let isInTitle = false;
    if (!isEmpty(item.name)) {
      isInName = item.name
        .toLowerCase()
        .includes(search.toString().toLowerCase());
    }
    if (!isEmpty(item.title)) {
      isInTitle = item.title
        .toLowerCase()
        .includes(search.toString().toLowerCase());
    }
    return isInName || isInTitle;
  };

  renderAttributeSelector = () => {
    return (
      <Modal
        width={810}
        title={''}
        centered
        footer={null}
        visible={this.state.selectorModalVisible}
        onOk={() => this.setState({ selectorModalVisible: false })}
        onCancel={() => this.setState({ selectorModalVisible: false })}
      >
        <div style={styles.modalContent}>
          {this.renderAttributeSelectorHeader(
            this.predicateTypeHeader.ATTRIBUTE
          )}
          <div style={styles.modalSection}>
            {this.state.selectedHeader === this.predicateTypeHeader.ATTRIBUTE &&
              filter(this.props.attributes, (o) => {
                if (isEmpty(this.state.attributesSearch)) {
                  return true;
                }
                return this.searchCheck(this.state.attributesSearch, o);
              }).map((x) =>
                this.renderAttributeSelectorItem(
                  x,
                  this.predicateTypeHeader.ATTRIBUTE
                )
              )}
          </div>
          {this.renderAttributeSelectorHeader(
            this.predicateTypeHeader.BEHAVIOUR
          )}
          <div style={styles.modalSection}>
            {this.state.selectedHeader === this.predicateTypeHeader.BEHAVIOUR &&
              filter(this.props.events, (o) => {
                if (isEmpty(this.state.eventSearch)) {
                  return true;
                }
                return this.searchCheck(this.state.eventSearch, o);
              }).map((x) =>
                this.renderAttributeSelectorItem(
                  x,
                  this.predicateTypeHeader.BEHAVIOUR
                )
              )}
          </div>
          {this.renderAttributeSelectorHeader(this.predicateTypeHeader.DEVICE)}
          <div style={styles.modalSection}>
            {this.state.selectedHeader === this.predicateTypeHeader.DEVICE &&
              filter(this.props.deviceAttributes, (o) => {
                if (isEmpty(this.state.deviceAttributeSearch)) {
                  return true;
                }
                return this.searchCheck(this.state.deviceAttributeSearch, o);
              }).map((x) =>
                this.renderAttributeSelectorItem(
                  x,
                  this.predicateTypeHeader.DEVICE
                )
              )}
          </div>
        </div>
      </Modal>
    );
  };

  handleClickReportFetch = () => {
    if (!this.props.loadingReport) {
      this.props.productSegmentReportFetch(this.state.currentSegment);
    }
  };

  handleClickTermConjunction = (isLast) => {
    if (isLast) {
      this.handleAddNewFilter(true, null);
    } else {
      const alternateConjunction =
        this.state.currentSegment.conjunction === 'AND' ? 'OR' : 'AND';
      this.setState({
        currentSegment: {
          ...this.state.currentSegment,
          conjunction: alternateConjunction,
        },
      });
    }
  };
  handleChangeSegmentName = (value) => {
    this.setState({
      currentSegment: { ...this.state.currentSegment, name: value },
    });
    this.props.clearSegmentError(this.props.errorsSegmentSubmit.errors, 'name');
  };
  handleChangeDataSource = (value) => {
    this.setState({
      currentSegment: {
        ...this.state.currentSegment,
        dataSourceProductId: value,
        type: 'STATIC',
      },
    });
    this.props.productSegmentListAttributesFetch({
      id: value,
    });
    this.props.productSegmentListEventsFetch({
      id: value,
    });
    this.props.productSegmentListDeviceAttributesFetch({
      id: value,
    });
  };
  renderTermConjunctionDivider = (isLast, conjunction) => {
    const isAnd = conjunction === 'AND';
    return (
      <div style={styles.termConjunctionDivideContent}>
        <div style={styles.termConjunctionDividerBorder}></div>
        {isAnd ? (
          <div
            onClick={() => this.handleClickTermConjunction(isLast)}
            style={{
              ...styles.conjunctionDividerText,
              border: isLast ? 'solid 1px #11b563' : 'none',
              boxShadow: isLast ? 'none' : '0 1px 6px 0 rgba(17, 181, 99, 0.5)',
              color: isLast ? '#53b16b' : 'white',
              backgroundColor: isLast ? '#fcfcfc' : '#53b16b',
            }}
          >
            {isLast ? '+ AND FILTER' : 'AND'}
          </div>
        ) : (
          <div
            onClick={() => this.handleClickTermConjunction(isLast)}
            style={{
              ...styles.conjunctionDividerText,
              border: isLast ? 'solid 1px #3ec6ff' : 'none',
              boxShadow: isLast
                ? 'none'
                : '0 1px 6px 0 rgba(62, 198, 255, 0.5)',
              color: isLast ? '#3ec6ff' : 'white',
              backgroundColor: isLast ? '#fcfcfc' : '#3ec6ff',
            }}
          >
            {isLast ? '+ OR FILTER' : 'OR'}
          </div>
        )}
        <div style={styles.termConjunctionDividerBorder}></div>
      </div>
    );
  };

  renderTerm = (item, index, isLast, conjunction) => {
    return (
      <div style={styles.termContainer}>
        <div style={styles.boxContent}>
          {item.predicates.map((predicate, predicateIndex) => (
            <ProductSegmentPredicateComponent
              key={predicateIndex}
              // mandatory props:
              predicate={predicate}
              errorsSegmentSubmit={this.props.errorsSegmentSubmit}
              clearSegmentError={(errors, key) =>
                this.props.clearSegmentError(errors, key)
              }
              attributes={this.props.attributes}
              events={this.props.events}
              handleChangePredicate={(changedPredicate, deleteMode) =>
                this.handleChangePredicate(
                  index,
                  predicateIndex,
                  changedPredicate,
                  deleteMode
                )
              }
              type={null} // EVENT_OCCURRENCE, HAS_DONE_EVENT, CHECK_USER_ATTRIBUTE
              // segment specific props:
              deviceAttributes={this.props.deviceAttributes}
              isInSegment={true}
              termConjunction={item.conjunction}
              predicateIndex={predicateIndex}
              termsIndex={index}
              isLast={predicateIndex === item.predicates.length - 1}
              handleAddNewFilter={() => this.handleAddNewFilter(false, index)}
              handleDeletePredicate={() =>
                this.deletePredicate(predicateIndex, index)
              }
              handleChangeTermConjunction={() =>
                this.handleChangeTermConjunction(index)
              }
              fetchRecommendEventAttributes={
                this.props.productSegmentRecommendEventAttributesFetch
              }
              recommendEventAttributes={this.props.recommendEventAttributes}
              fetchRecommendDeviceAttributes={
                this.props.productSegmentRecommendDeviceAttributesFetch
              }
              recommendDeviceAttributes={this.props.recommendDeviceAttributes}
              fetchRecommendUserAttributes={
                this.props.productSegmentRecommendUserAttributesFetch
              }
              recommendUserAttributes={this.props.recommendUserAttributes}
              showEventAggregation
            />
          ))}
        </div>
        {this.renderTermConjunctionDivider(isLast, conjunction)}
      </div>
    );
  };

  renderSegmentName = () => {
    const productId = getProductId(this.props.pathname);
    const currentProduct = this.props.currentUser?.products?.find(
      (p) => p.id === +productId
    );
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    return (
      <div style={styles.nameContainer}>
        {this.props.loadingUpdate || this.props.loadingCopy ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              paddingTop: 50,
              paddingBottom: 50,
            }}
          >
            <Spin indicator={inputLoadingSpin} />
          </div>
        ) : (
          <div style={styles.nameContent}>
            <div style={styles.name}>
              <div style={styles.nameTitle}>Segment Name</div>
              <Form layout="vertical">
                <Form.Item
                  validateStatus={
                    errorHandling(this.props.errorsSegmentSubmit, 'name')
                      ? 'error'
                      : null
                  }
                  help={errorHandling(this.props.errorsSegmentSubmit, 'name')}
                  id={
                    errorHandling(this.props.errorsSegmentSubmit, 'name')
                      ? 'error'
                      : null
                  }
                >
                  <Input
                    id={
                      errorHandling(this.props.errorsSegmentSubmit, 'name')
                        ? 'error'
                        : null
                    }
                    style={{
                      ...styles.nameInput,
                      marginTop: '25px',
                    }}
                    value={
                      this.state.currentSegment &&
                      !isNil(this.state.currentSegment.name)
                        ? this.state.currentSegment.name
                        : null
                    }
                    placeholder="Name Your Segment"
                    onChange={(e) =>
                      this.handleChangeSegmentName(e.target.value)
                    }
                  />
                </Form.Item>
              </Form>
            </div>
            {this.segmentType === 'STATIC' && currentProduct?.sendify && (
              <Form.Item name="dataSourceProductId">
                <Select
                  placeholder="Data Source ProductId"
                  value={
                    this.state.currentSegment &&
                    !isNil(this.state.currentSegment.dataSourceProductId)
                      ? this.state.currentSegment.dataSourceProductId
                      : null
                  }
                  onChange={(e) => this.handleChangeDataSource(e)}
                  style={{
                    width: 200,
                    marginTop: 25,
                  }}
                >
                  {this.props?.sourceProducts?.map((item) => (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                      label={item.name}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <div style={styles.testContent}>
              <div style={styles.testTitle}>Test Mode:</div>
              <div style={styles.testSwitchContent}>
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={(test) =>
                    this.setState({
                      currentSegment: {
                        ...this.state.currentSegment,
                        test,
                      },
                    })
                  }
                  checked={
                    this.state.currentSegment && this.state.currentSegment.test
                      ? this.state.currentSegment.test
                      : false
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const isFieldsEmpty = this.isSegmentEmpty();
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const productId = getProductId(this.props.pathname);
    const currentProduct = this.props.currentUser?.products?.find(
      (p) => p.id === +productId
    );
    return (
      <div>
        <div style={styles.content}>
          <div style={styles.segmentContent}>
            {this.renderSegmentName()}
            {this.props.loadingCopy || this.props.loadingUpdate ? (
              <div style={styles.terms}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    paddingTop: 50,
                    paddingBottom: 50,
                  }}
                >
                  <Spin indicator={inputLoadingSpin} />
                </div>
              </div>
            ) : currentProduct?.sendify &&
              !this.state.currentSegment?.dataSourceProductId ? (
              <div style={styles.addNewEmptySegment}>
                Please, select a product for segmentation.
              </div>
            ) : isFieldsEmpty ? (
              <div
                onClick={() => this.handleAddNewFilter(true, null)}
                style={styles.addNewEmptySegment}
              >
                You can add filters to your segment by clicking here
                <div style={styles.circlePlusContainer}>
                  <PlusOutlined style={styles.circlePlus} />
                </div>
              </div>
            ) : (
              <div style={styles.terms}>
                {this.state.currentSegment.terms.map((item, index) =>
                  this.renderTerm(
                    item,
                    index,
                    index === this.state.currentSegment.terms.length - 1,
                    this.state.currentSegment.conjunction
                  )
                )}
              </div>
            )}
          </div>
          <div style={styles.summary}>
            <div
              onClick={() => this.handleClickReportFetch()}
              style={styles.headTitleContainer}
            >
              <i className="fl-sync" style={styles.headIcon}></i>
            </div>
            <div style={styles.chart}>{this.renderChart()}</div>
            {this.renderChartSummary()}
          </div>
        </div>
        {this.renderAttributeSelector()}
      </div>
    );
  }
}

export default ProductSegmentUpdateComponent;
