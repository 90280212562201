import React from 'react';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import { isEmpty, isNil, cloneDeep } from 'lodash';
import {
  Button,
  Input,
  Modal,
  Select,
  Spin,
  Form,
  Space,
  InputNumber,
  Switch,
} from 'antd';
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import MethodTypes from '../model/MethodTypes';
import { LoadingOutlined } from '@ant-design/icons';
import './style.less';
import ContentWrap from '@Component/app/ContentWrap';
import {
  hasAnyAuthority,
  AuthorityProvider,
} from '../../../../utils/AuthorityProvider';
import Card from '@Utils/Card';

export const CUSTOM_CHANNEL_BODY = {
  id: '{{id}}',
  token: '{{token}}',
  message: '{{variation.message}}',
  subject: '{{variation.subject}}',
  image: '{{variation.image}}',
  keyValuePair: '{{variation.keyValuePairMap}}',
  icon: '{{variation.icon}}',
  badge: '{{variation.badge}}',
  attachments: '{{variation.attachmentsArray}}',
  userId: '{{user.userId}}',
  anonymousId: '{{user.anonymousId}}',
};
export const CustomProvider = {
  USERMOST: 'USERMOST',
  USERMOST_BATCH: 'USERMOST_BATCH',
};
class CustomChannelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.defaultBody = CUSTOM_CHANNEL_BODY;
    this.state = {
      modalId: null,
      provider: null,
      name: '',
      vendor: CustomProvider.USERMOST,
      headers: [{ key: null, value: null }],
      batchSize: null,
      permissions: {
        view: hasAnyAuthority(
          AuthorityProvider.ROLE_PRODUCT_CHANNEL_CUSTOM_VIEW
        ),
        createOrUpdate: hasAnyAuthority(
          AuthorityProvider.ROLE_PRODUCT_CHANNEL_CUSTOM_UPDATE,
          AuthorityProvider.ROLE_PRODUCT_CHANNEL_CUSTOM_CREATE
        ),
        delete: hasAnyAuthority(
          AuthorityProvider.ROLE_PRODUCT_CHANNEL_CUSTOM_DELETE
        ),
      },
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.props.customChannelListFetch(this.props.pageRequest);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentUser?.login !== this.props.currentUser?.login) {
      this.setState((prev) => ({
        ...prev,
        permissions: {
          view: hasAnyAuthority(
            AuthorityProvider.ROLE_PRODUCT_CHANNEL_CUSTOM_VIEW
          ),
          createOrUpdate: hasAnyAuthority(
            AuthorityProvider.ROLE_PRODUCT_CHANNEL_CUSTOM_UPDATE,
            AuthorityProvider.ROLE_PRODUCT_CHANNEL_CUSTOM_CREATE
          ),
          delete: hasAnyAuthority(
            AuthorityProvider.ROLE_PRODUCT_CHANNEL_CUSTOM_DELETE
          ),
        },
      }));
    }

    if (prevProps.loadingUpdateSubmit && !this.props.loadingUpdateSubmit) {
      this.handleCloseModal();
    } else if (prevProps.loadingCreate && !this.props.loadingCreate) {
      this.handleCloseModal();
    } else if (
      prevProps.loadingUpdate &&
      !this.props.loadingUpdate &&
      this.props.currentCustomChannel
    ) {
      const { body } = this.props.currentCustomChannel;
      let stringBody_ = body || JSON.stringify(this.defaultBody);
      stringBody_ = stringBody_.replace(
        '"{{variation.keyValuePairMap}}"',
        '{{variation.keyValuePairMap}}'
      );
      stringBody_ = stringBody_.replace(
        '"{{variation.attachmentsArray}}"',
        '{{variation.attachmentsArray}}'
      );
      this.formRef.current.setFieldsValue({
        ...this.props.currentCustomChannel,
        body: stringBody_,
      });
      const { headers } = this.props.currentCustomChannel;
      if (headers) {
        this.setState({ headers: headers });

        const keys = headers.map((item) => item.key);
        const values = headers.map((item) => item.value);
        this.formRef.current.setFieldsValue({
          ['h_key_']: keys,
          ['h_value_']: values,
        });
      }
    }
  }

  handleChangeSearch = (name) => {
    this.setState({ name });
    if (name === '' || name === null) {
      this.props.customChannelListFetch({
        ...this.props.pageRequest,
        page: 0,
        name: null,
      });
    }
  };

  handleChangeProvider = (provider) => {
    this.setState({ provider });
    this.props.customChannelListFetch({
      ...this.props.pageRequest,
      page: 0,
      provider,
    });
  };

  openModal = (x) => {
    this.props.customChannelUpdateFetch(
      x.id,
      this?.state.permissions?.createOrUpdate
    );
    this.setState({ modalId: x.id });
  };

  getHeaders = () => {
    const result = [];
    this.state.headers.forEach((item) => {
      if (!isNil(item.key) || !isNil(item.value)) {
        result.push(item);
      }
    });
    if (result.length === 0) {
      return null;
    }
    return result;
  };

  onOkModal = async () => {
    try {
      await this.formRef.current.validateFields();
      const { name, url, body, method, vendor, batchSize } =
        this.formRef.current.getFieldsValue();
      if (this.props.addModalOpen === true) {
        this.props.customChannelCreateFetch(
          {
            name,
            url,
            body,
            method,
            headers: this.getHeaders(),
            vendor: vendor
              ? CustomProvider.USERMOST_BATCH
              : CustomProvider.USERMOST,

            batchSize: batchSize && batchSize > 1 ? batchSize : null,
          },
          this.props.pageRequest
        );
      } else {
        this.props.customChannelUpdateSubmitFetch(
          this.state.modalId,
          {
            name,
            url,
            body,
            method,
            headers: this.getHeaders(),
            vendor: vendor
              ? CustomProvider.USERMOST_BATCH
              : CustomProvider.USERMOST,
            batchSize,
          },
          this.props.pageRequest
        );
      }
    } catch (e) {
      // console.log('error-', e);
    }
  };

  addKeyValuePair = (index) => {
    const headers = cloneDeep(this.state.headers);
    headers.splice(index + 1, 0, { key: null, value: null });
    this.setState({ headers });
  };

  deleteRow = (index) => {
    let cloned = cloneDeep(this.state.headers);
    if (this.state.headers.length === 1) {
      cloned = [{ key: null, value: null }];
    } else {
      cloned.splice(index, 1);
    }
    this.setState({ headers: cloned });
  };

  handleChangeKeyValue = (index, key, value) => {
    let headers = [...this.state.headers];
    headers[index] = { ...headers[index], [key]: value };
    this.setState({ headers });
  };

  checkRule = (item) => {
    if (item.key) return true;
    if (this.state.headers.length > 1) return true;
    return false;
  };

  renderContent = () => {
    return (
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          body: JSON.stringify(this.defaultBody),
        }}
        layout="horizontal"
        ref={this.formRef}
      >
        <ContentWrap loading={this.props.loadingUpdate}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
            className="cu-form-item"
          >
            <Input
              disabled={!this?.state.permissions?.createOrUpdate}
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="method"
            label="Method"
            rules={[{ required: true }]}
            className="cu-form-item"
          >
            <Select
              value={
                this.props.currentCustomChannel &&
                this.props.currentCustomChannel.method
                  ? this.props.currentCustomChannel.method
                  : ''
              }
              disabled={!this?.state.permissions?.createOrUpdate}
              onChange={(method) =>
                this.props.customChannelChangeCurrent({
                  ...this.props.currentCustomChannel,
                  method,
                })
              }
            >
              {Object.keys(MethodTypes).map((item) => (
                <Select.Option
                  key={item}
                  value={item}
                  label={MethodTypes[item]}
                  disabled={
                    MethodTypes[item] === MethodTypes.GET ||
                    MethodTypes[item] === MethodTypes.DELETE
                  }
                >
                  {MethodTypes[item]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Url"
            name="url"
            rules={[
              {
                required: true,
              },
              { type: 'url', warningOnly: true },
            ]}
            className="cu-form-item"
          >
            <Input
              disabled={!this?.state.permissions?.createOrUpdate}
              placeholder={'Enter URL starting with http:// or https://'}
              size="large"
            />
          </Form.Item>

          <Form.Item label="Body" name="body" className="cu-form-item">
            <Input.TextArea
              size="large"
              style={{ height: 250, maxWidth: 'unset' }}
              dir={'auto'}
            />
          </Form.Item>

          <Form.Item label="Header(s)" className="cu-form-item">
            {this?.state.permissions?.createOrUpdate
              ? this.state.headers.map((item, index) => (
                  <Space
                    key={index}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      // {...field}
                      name={['h_key_', index]}
                      rules={[{ required: this.checkRule(item), message: '' }]}
                    >
                      <Input
                        style={{ width: 100 }}
                        placeholder={'key'}
                        value={item.key}
                        onChange={(e) =>
                          this.handleChangeKeyValue(
                            index,
                            'key',
                            e.target.value
                          )
                        }
                      />
                    </Form.Item>
                    <div>=</div>
                    <Form.Item
                      // {...field}
                      name={['h_value_', index]}
                      rules={[{ required: this.checkRule(item), message: '' }]}
                    >
                      <Input
                        style={{ width: 200 }}
                        placeholder={'value'}
                        value={item.value}
                        onChange={(e) =>
                          this.handleChangeKeyValue(
                            index,
                            'value',
                            e.target.value
                          )
                        }
                      />
                    </Form.Item>
                    {index ? (
                      <MinusCircleOutlined
                        onClick={() => this.deleteRow(index)}
                        style={{ fontSize: '20px' }}
                      />
                    ) : (
                      ''
                    )}
                    <PlusCircleOutlined
                      onClick={() => this.addKeyValuePair(index)}
                      style={{ fontSize: '20px' }}
                    />
                  </Space>
                ))
              : '***'}
          </Form.Item>

          <Form.Item label="Send Batch" name="vendor" className="cu-form-item">
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(vendor) => {
                this.props.customChannelChangeCurrent({
                  ...this.props.currentCustomChannel,
                  vendor: vendor
                    ? CustomProvider.USERMOST_BATCH
                    : CustomProvider.USERMOST,
                });
              }}
              checked={
                this.props.currentCustomChannel?.vendor ===
                CustomProvider.USERMOST_BATCH
                  ? true
                  : false
              }
            />
          </Form.Item>
          {this.props.currentCustomChannel.vendor ===
            CustomProvider.USERMOST_BATCH && (
            <Form.Item
              label="Batch Size"
              name="batchSize"
              rules={[
                {
                  required: true,
                },
              ]}
              className="cu-form-item"
            >
              <InputNumber
                style={{ width: '100%' }}
                disabled={!this?.state.permissions?.createOrUpdate}
                min={1}
              />
            </Form.Item>
          )}
        </ContentWrap>
      </Form>
    );
  };

  renderModalTitle = () => {
    if (this.props.addModalOpen === true) {
      return 'Add New Custom Channel';
    }
    return 'Edit Custom Channel';
  };

  handleCloseModal = () => {
    this.props.customChannelToggleAddModal(false);
    this.setState({ modalId: null, headers: [{ key: null, value: null }] });
    this.formRef.current.resetFields();
    this.props.customChannelChangeCurrent({
      body: null,
      headers: [{ key: null, value: null }],
      method: null,
      name: null,
      url: null,
      vendor: null,
      batchSize: undefined,
    });
  };

  renderModal = () => {
    const visible = !isNil(this.state.modalId) || this.props.addModalOpen;
    return (
      <Modal
        width={600}
        title={this.renderModalTitle()}
        centered
        visible={visible}
        onOk={() => this.handleCloseModal()}
        onCancel={() => this.handleCloseModal()}
        footer={[
          <Button
            key={0}
            shape="round"
            onClick={() => {
              this.handleCloseModal();
            }}
          >
            CANCEL
          </Button>,
          <Button
            loading={this.props.loadingUpdateSubmit || this.props.loadingCreate}
            key="submit"
            shape="round"
            type="primary"
            disabled={!this?.state.permissions?.createOrUpdate}
            onClick={() => this.onOkModal()}
          >
            Save
          </Button>,
        ]}
      >
        <div>{this.renderContent()}</div>
      </Modal>
    );
  };

  render() {
    const columnList = [
      {
        sorter: false,
        title: 'configuration name',
        dataIndex: 'name',
        key: 'name',
        width: 300,
        // ...getColumnTextSearchProps('name'),
      },
      {
        sorter: false,
        title: 'URL',
        dataIndex: 'url',
        key: 'url',
        width: 300,
        render: (x) => x,
        // ...getColumnTextSearchProps('title'),
      },
      {
        title: 'action',
        dataIndex: '',
        key: '-action-',
        width: '5%',
        render: (x) =>
          defaultActions(
            x,
            {
              action: () => this.openModal(x),
              title: this?.state.permissions?.createOrUpdate ? 'Edit' : 'View',
              enabled: this.state.permissions?.view,
              disablePopConfirm: true,
            },
            // { link: 'copy/' + x.id, title: 'Duplicate', enabled: hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_EVENT_CREATE) },
            {
              action: (id) =>
                this.props.customChannelDeleteFetch(id, this.props.pageRequest),
              title: 'Delete',
              enabled: this?.state.permissions?.delete,
            }
          ),
      },
    ];

    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    return (
      <div>
        <Card title="Your Custom Channel" loading={this.props.loadingList}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '0 16px',
            }}
          >
            <Input
              loading={true}
              allowClear={this.props.loadingSearchList ? false : true}
              placeholder={'Search by configuration name'}
              onPressEnter={() =>
                this.props.customChannelSearchListFetch({
                  ...this.props.pageRequest,
                  page: 0,
                  name: this.state.name,
                })
              }
              onChange={(e) => this.handleChangeSearch(e.target.value)}
              value={this.state.name}
              style={{ width: 300, borderRadius: 5 }}
              suffix={
                isEmpty(this.state.name) ? (
                  <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                ) : (
                  this.props.loadingSearchList && (
                    <div className={'cu-input-loading-style'}>
                      <Spin indicator={inputLoadingSpin} />
                    </div>
                  )
                )
              }
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Select
                onChange={(provider) => this.handleChangeProvider(provider)}
                value={this.state.provider}
                defaultValue="all"
                style={{ width: 120 }}
                className={'chart-selector'}
                bordered={false}
              >
                <Select.Option value={null}>All Providers</Select.Option>
              </Select>
            </div>
          </div>
          <ListBase
            onChange={this.props.customChannelListFetch}
            page={this.props.page}
            pageRequest={this.props.pageRequest}
            columns={columnList}
            // onClick={(row) => this.viewItem(row.id)}
            createLink={null}
          />
        </Card>
        {this.renderModal()}
      </div>
    );
  }
}

export default CustomChannelComponent;
