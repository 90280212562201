import React, { useCallback, useState } from 'react';
import { DatePicker, Divider, InputNumber, Select, Switch } from 'antd';
import { find as lodashFind, isNil, isEmpty, range } from 'lodash';
import moment from 'moment';
import styles from './TrOccurrenceBusinessEventConfiguration.module.less';
import { getDelayMinutes } from '@Utils/DateUtils';
import restrictionOperator from '@Utils/model/restrictionOperator';
import notRestrictionOperator from '@Utils/model/notRestrictionOperator';

export const useTrOccurrenceBusinessEventAggregation = (
  props,
  userEventTerms
) => {
  const [relativeDateType, setRelativeDateType] = useState(null);
  const [relativeDates, setRelativeDates] = useState(null);

  const [
    { aggregation, operator, negate, stringValue, lessThan, greaterThan },
    setValues,
  ] = useState({
    aggregation: 'EXISTS',
    operator: null,
    negate: false,
    stringValue: null,
    lessThan: null,
    greaterThan: null,
  });

  const handleResetDatePicker = () => {
    setRelativeDateType(null);
    setRelativeDates(null);

    setValues((previous) => ({
      ...previous,
      operator: null,
      stringValue: null,
      lessThan: null,
      greaterThan: null,
      negate: false,
    }));
  };

  const handleUpdateAggregationFields = (values) => {
    if (!values.aggregation) {
      values.aggregation = 'EXISTS';
    }
    if (values.attribute) {
      values.aggregation = `${values.aggregation}-${values.attribute}`;
    }
    if (values) setValues(values);
  };

  const getAggregationFormProps = () => {
    if (operator === 'BETWEEN' && Math.abs(greaterThan) > Math.abs(lessThan)) {
      return {
        help: 'The range upperbound value must be bigger than lowerbound',
        validateStatus: 'error',
      };
    }

    if (['EQUAL_TO', 'DATE_EQUALS'].includes(operator) && !stringValue) {
      return {
        help: 'The input value should not be undefined',
        validateStatus: 'error',
      };
    }

    return {
      help: null,
      validateStatus: 'success',
    };
  };

  const aggregationValue = () => {
    const list = getAttributes();

    let eventAttributeCombineAggregation = {
      EXISTS: 'at least once',
      OCCURRENCE: 'no. of occurrences',
    };
    list.forEach((item) => {
      eventAttributeCombineAggregation[
        `MAXIMUM-${item.id}`
      ] = `maximun of ${item.title}`;
      eventAttributeCombineAggregation[
        `MINIMUM-${item.id}`
      ] = `minimum of ${item.title}`;
    });
    return eventAttributeCombineAggregation;
  };

  const getAttributes = useCallback(() => {
    const foundEvent = lodashFind(
      props?.data?.events,
      (o) => o.id === userEventTerms.event
    );
    return foundEvent && Array.isArray(foundEvent.attributes)
      ? foundEvent.attributes.filter(
          ({ aggregationField, type }) =>
            aggregationField && ['DATE', 'NUMERIC'].includes(type)
        )
      : [];
  }, [props, userEventTerms]);

  const renderAggregationOptions = () => {
    const eventAggregationText = aggregationValue();

    return Object.keys(eventAggregationText).map((x) => ({
      value: x,
      label: eventAggregationText[x],
    }));
  };

  const renderOperatorSelectorOptions = (
    type,
    negate = false,
    aggregation = false
  ) => {
    const operatorText = negate ? notRestrictionOperator : restrictionOperator;
    return (
      <React.Fragment>
        {type !== 'DATE' ? (
          <Select.Option value={'EQUAL_TO'}>
            {operatorText['EQUAL_TO']}
          </Select.Option>
        ) : null}
        {!aggregation ? (
          <Select.Option value={'IS_NOT_EMPTY'}>
            {operatorText['IS_NOT_EMPTY']}
          </Select.Option>
        ) : (
          ''
        )}
        {(type === 'STRING' || type === 'NUMERIC') &&
          aggregation !== 'OCCURRENCE' && (
            <Select.Option value={'ONE_OF'}>
              {operatorText['ONE_OF']}
            </Select.Option>
          )}
        {type === 'STRING' && (
          <Select.Option value={'STARTS_WITH'}>
            {operatorText['STARTS_WITH']}
          </Select.Option>
        )}
        {type === 'STRING' && (
          <Select.Option value={'ENDS_WITH'}>
            {operatorText['ENDS_WITH']}
          </Select.Option>
        )}
        {type === 'STRING' && (
          <Select.Option value={'MATCHES_REGEX'}>
            {operatorText['MATCHES_REGEX']}
          </Select.Option>
        )}
        {type === 'STRING' && (
          <Select.Option value={'CONTAINS'}>
            {operatorText['CONTAINS']}
          </Select.Option>
        )}
        {(type === 'DATE' || type === 'NUMERIC') && (
          <Select.Option value={'BETWEEN'}>
            {operatorText['BETWEEN']}
          </Select.Option>
        )}
        {type === 'DATE' && (
          <Select.Option value={'DATE_EQUALS'}>
            {operatorText['DATE_EQUALS']}
          </Select.Option>
        )}
      </React.Fragment>
    );
  };

  const renderAggregationOperatorSelector = () => {
    const list = getAttributes();

    const attrId = Number(aggregation.split('-')[1]);

    const foundItem = lodashFind(list, (o) => o.id === attrId);

    const type = foundItem && foundItem.type ? foundItem.type : 'NUMERIC';

    return (
      <div style={{ float: 'left', marginRight: '10px' }}>
        <Select
          placeholder="Select an Operator"
          style={{ width: 160 }}
          value={operator}
          onChange={(operator) => {
            setValues((previous) => ({
              ...previous,
              stringValue: null,
              lessThan: null,
              greaterThan: null,
              operator,
            }));
          }}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    marginRight: 10,
                    fontSize: 14,
                    fontWeight: 600,
                    color: '#1c1c1c',
                  }}
                >
                  Negate Mode:
                </div>
                <div>
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(negate) =>
                      setValues((previous) => ({ ...previous, negate }))
                    }
                    checked={negate}
                  />
                </div>
              </div>
            </div>
          )}
        >
          {renderOperatorSelectorOptions(type, negate, true)}
        </Select>
      </div>
    );
  };

  const getMultipleStringValue = (value) => {
    let result = [];
    if (value && !isEmpty(value)) {
      result = value.split(/[;,]+/);
    }
    return result;
  };

  const renderAggregationDatePicker = () => {
    return (
      <React.Fragment>
        {operator === 'BETWEEN' && isNil(relativeDateType) && (
          <div className={styles.flexRow}>
            <Select
              style={{ width: 200 }}
              value={relativeDateType}
              placeholder={'Select...'}
              onChange={setRelativeDateType}
            >
              <Select.Option value={'DATE'}>{'date'}</Select.Option>
              <Select.Option value={'LAST-LAST'}>
                {'LAST X time to Y time'}
              </Select.Option>
              <Select.Option value={'NEXT-NEXT'}>
                {'NEXT X time to Y time'}
              </Select.Option>
              <Select.Option value={'LAST-NEXT'}>
                {'LAST X time to NEXT Y time'}
              </Select.Option>
            </Select>
          </div>
        )}
        {operator === 'DATE_EQUALS' && (
          <DatePicker
            placeholder={'Date'}
            allowClear={true}
            disabledDate={(value) => {
              if (!isNil(stringValue)) {
                return value >= moment(stringValue);
              }
            }}
            disabledTime={() => {
              if (!isNil(stringValue)) {
                return {
                  disabledHours: () =>
                    moment(stringValue).hour() === 0 &&
                    moment(stringValue).minute() === 0
                      ? range(24, 24)
                      : range(moment(stringValue).hour() + 1, 24),
                  disabledMinutes: () =>
                    moment(stringValue).hour() === 0 &&
                    moment(stringValue).minute() === 0
                      ? range(60, 60)
                      : range(moment(stringValue).minute(), 60),
                };
              }
            }}
            value={!isNil(stringValue) ? moment(stringValue) : null}
            showTime={false}
            style={{ width: 180 }}
            onChange={(value) => {
              setValues((previous) => ({
                ...previous,
                stringValue: value
                  ? value.startOf('minute').toISOString()
                  : null,
              }));
            }}
          />
        )}
        {operator === 'BETWEEN' && relativeDateType === 'DATE' && (
          <div className={styles.flexRow}>
            <DatePicker
              placeholder={'Start Date (optional)'}
              allowClear={true}
              disabledDate={(value) => {
                if (!isNil(lessThan)) {
                  return value >= moment(lessThan);
                }
              }}
              disabledTime={() => {
                if (!isNil(lessThan)) {
                  return {
                    disabledHours: () =>
                      moment(lessThan).hour() === 0 &&
                      moment(lessThan).minute() === 0
                        ? range(24, 24)
                        : range(moment(lessThan).hour() + 1, 24),
                    disabledMinutes: () =>
                      moment(lessThan).hour() === 0 &&
                      moment(lessThan).minute() === 0
                        ? range(60, 60)
                        : range(moment(lessThan).minute(), 60),
                  };
                }
              }}
              value={!isNil(greaterThan) ? moment(greaterThan) : null}
              showTime={{ format: 'HH:mm' }}
              style={{ width: 180 }}
              onChange={(value) => {
                setValues((previous) => ({
                  ...previous,
                  greaterThan: value
                    ? value.startOf('minute').toISOString()
                    : null,
                }));
              }}
            />
            <div className={styles.toContent}>to</div>
            <DatePicker
              placeholder={'End Date (optional)'}
              allowClear={true}
              disabledDate={(value) => {
                if (!isNil(greaterThan)) {
                  return value <= moment(greaterThan);
                }
              }}
              disabledTime={() => {
                if (!isNil(greaterThan)) {
                  return {
                    disabledHours: () =>
                      moment(greaterThan).hour() === 23 &&
                      moment(greaterThan).minute() === 59
                        ? null
                        : range(0, moment(greaterThan).hour()),
                    disabledMinutes: () =>
                      moment(greaterThan).hour() === 23 &&
                      moment(greaterThan).minute() === 59
                        ? null
                        : range(0, moment(greaterThan).minute() + 1),
                  };
                }
              }}
              value={!isNil(lessThan) ? moment(lessThan) : null}
              showTime={{ format: 'HH:mm' }}
              style={{ width: 180 }}
              onChange={(value) => {
                setValues((previous) => ({
                  ...previous,
                  lessThan: value
                    ? value.startOf('minute').toISOString()
                    : null,
                }));
              }}
            />
            <div
              style={{ cursor: 'pointer', marginLeft: 5 }}
              onClick={() => handleResetDatePicker()}
            >
              <i className="fl-sync fl-dark" style={{ fontSize: 34 }}></i>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderAggregationPredicateValue = () => {
    const list = getAttributes();

    const attrId = Number(aggregation.split('-')[1]);

    const foundItem = lodashFind(list, (o) => o.id === attrId);

    const type =
      foundItem && foundItem.type
        ? foundItem.type
        : aggregation === 'OCCURRENCE'
        ? 'NUMERIC'
        : null;

    return (
      <React.Fragment>
        {type === 'NUMERIC' && operator === 'ONE_OF' && (
          <div style={{ float: 'right' }}>
            <Select
              value={getMultipleStringValue(stringValue)}
              onChange={(value) => {
                setValues((previous) => ({
                  ...previous,
                  stringValue: value.join(),
                }));
              }}
              mode="tags"
              style={{ width: 180 }}
            />
          </div>
        )}
        {type === 'NUMERIC' && operator === 'EQUAL_TO' && (
          <div style={{ float: 'left' }}>
            <InputNumber
              value={stringValue}
              onChange={(stringValue) =>
                setValues((previous) => ({ ...previous, stringValue }))
              }
              style={{ width: 180 }}
            />
          </div>
        )}
        {type === 'NUMERIC' && operator === 'BETWEEN' && (
          <div style={{ float: 'left' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <InputNumber
                value={greaterThan}
                onChange={(greaterThan) =>
                  setValues((previous) => ({ ...previous, greaterThan }))
                }
                style={{ width: 140 }}
              />
              <div className={styles.toContent}>to</div>
              <InputNumber
                value={lessThan}
                onChange={(lessThan) =>
                  setValues((previous) => ({ ...previous, lessThan }))
                }
                style={{ width: 140 }}
              />
            </div>
          </div>
        )}
        {type === 'DATE' && ['DATE_EQUALS', 'BETWEEN'].includes(operator) && (
          <div>{renderAggregationDatePicker()}</div>
        )}
      </React.Fragment>
    );
  };

  const changeRelativeDate = (
    relativeDateDuration,
    relativeDateValue,
    isFrom,
    key,
    value
  ) => {
    const minutes = getDelayMinutes(relativeDateValue, relativeDateDuration);

    switch (relativeDateType) {
      case 'LAST-LAST':
        {
          if (isFrom) {
            setValues((previous) => ({
              ...previous,
              greaterThan: minutes * -1,
            }));
          } else {
            setValues((previous) => ({ ...previous, lessThan: minutes * -1 }));
          }
        }
        break;
      case 'NEXT-NEXT':
        {
          if (isFrom) {
            setValues((previous) => ({
              ...previous,
              greaterThan: minutes,
            }));
          } else {
            setValues((previous) => ({ ...previous, lessThan: minutes }));
          }
        }
        break;
      case 'LAST-NEXT':
        {
          if (isFrom) {
            setValues((previous) => ({
              ...previous,
              greaterThan: minutes * -1,
            }));
          } else {
            setValues((previous) => ({ ...previous, lessThan: minutes }));
          }
        }
        break;
    }

    setRelativeDates((previous) => ({ ...previous, [key]: value }));
  };

  const renderAggregationDatePickerBetween = () => {
    const list = getAttributes();

    const attrId = Number(aggregation.split('-')[1]);

    const foundItem = lodashFind(list, (o) => o.id === attrId);

    const type = foundItem && foundItem.type ? foundItem.type : null;

    return (
      <React.Fragment>
        {type === 'DATE' &&
          operator === 'BETWEEN' &&
          ['LAST-LAST', 'LAST-NEXT', 'NEXT-NEXT'].includes(
            relativeDateType
          ) && (
            <div style={{ minWidth: 500 }}>
              <div
                className={styles.flexRow}
                style={{
                  minWidth: 500,
                }}
              >
                <div
                  className={styles.toContent}
                  style={{
                    marginLeft: 0,
                  }}
                >
                  {relativeDateType === 'NEXT-NEXT' ? 'next' : 'last'}
                </div>
                <InputNumber
                  value={
                    !isNil(relativeDates?.valueFrom)
                      ? Number(relativeDates?.valueFrom)
                      : null
                  }
                  onChange={(value) => {
                    changeRelativeDate(
                      relativeDateType?.durationFrom,
                      value,
                      true,
                      'valueFrom',
                      value
                    );
                  }}
                  style={{
                    width: 80,
                    height: 32,
                    marginRight: 10,
                  }}
                />
                <Select
                  style={{ width: 100 }}
                  value={relativeDates?.durationFrom}
                  placeholder={'Select...'}
                  onChange={(value) => {
                    changeRelativeDate(
                      value,
                      relativeDates?.valueFrom,
                      true,
                      'durationFrom',
                      String(value)
                    );
                  }}
                >
                  <Select.Option value={'MINUTE'}>Minutes</Select.Option>
                  <Select.Option value={'HOUR'}>Hours</Select.Option>
                  <Select.Option value={'DAY'}>Days</Select.Option>
                  <Select.Option value={'WEEK'}>Weeks</Select.Option>
                  <Select.Option value={'YEAR'}>Year</Select.Option>
                </Select>
                <div className={styles.toContent}>to</div>
                <div
                  className={styles.toContent}
                  style={{
                    marginLeft: 0,
                  }}
                >
                  {relativeDateType === 'LAST-LAST' ? 'last' : 'next'}
                </div>
                <InputNumber
                  value={
                    !isNil(relativeDates?.valueTo)
                      ? Number(relativeDates?.valueTo)
                      : null
                  }
                  onChange={(value) => {
                    changeRelativeDate(
                      relativeDates?.durationTo,
                      value,
                      false,
                      'valueTo',
                      value
                    );
                  }}
                  style={{
                    width: 80,
                    height: 32,
                    marginRight: 10,
                  }}
                />
                <Select
                  style={{ width: 100 }}
                  value={relativeDates?.durationTo}
                  placeholder={'Select...'}
                  onChange={(value) => {
                    changeRelativeDate(
                      value,
                      relativeDates?.valueTo,
                      false,
                      'durationTo',
                      String(value)
                    );
                  }}
                >
                  <Select.Option value={'MINUTE'}>Minutes</Select.Option>
                  <Select.Option value={'HOUR'}>Hours</Select.Option>
                  <Select.Option value={'DAY'}>Days</Select.Option>
                  <Select.Option value={'WEEK'}>Weeks</Select.Option>
                  <Select.Option value={'YEAR'}>Year</Select.Option>
                </Select>
                <div
                  style={{
                    cursor: 'pointer',
                    marginLeft: 5,
                  }}
                  onClick={() => handleResetDatePicker()}
                >
                  <i className="fl-sync fl-dark" style={{ fontSize: 34 }}></i>
                </div>
              </div>
            </div>
          )}
      </React.Fragment>
    );
  };

  const renderAggregationSelector = () => {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: 24 }}>
        <Select
          style={{ float: 'left', width: 280, marginRight: '10px' }}
          value={aggregation}
          onChange={(aggregation) => {
            setValues((previous) => ({
              ...previous,
              aggregation,
              operator: null,
            }));
          }}
          options={renderAggregationOptions()}
        />
        {aggregation !== 'EXISTS' && renderAggregationOperatorSelector()}
        {renderAggregationPredicateValue()}
        {renderAggregationDatePickerBetween()}
      </div>
    );
  };
  console.log('aggregation', aggregation);

  const [type, attribute] = aggregation?.split('-');

  return {
    renderAggregationSelector,
    getAggregationFormProps,
    handleUpdateAggregationFields,
    aggregation: type,
    attribute: !isNil(attribute) ? Number(attribute) : null,
    operator,
    negate,
    stringValue: operator !== 'BETWEEN' ? stringValue : null,
    lessThan: operator === 'BETWEEN' ? lessThan : null,
    greaterThan: operator === 'BETWEEN' ? greaterThan : null,
  };
};
