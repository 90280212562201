import { Card, Col, Row } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../app';
import JourneyFlow from '../../pages/root';
import { EditFilled as EditIcon } from '@ant-design/icons';

import './styles.less';
import SegmentStep from './Segment';
import ConversionTrackingStep from './ConversionTracking';
import FreqDndStep from './FreqDnd';
const PublishStep = (props) => {
  const { journey } = useContext(AppContext);
  // console.log("Publish------", journey);
  return (
    props.currentStep === 'publish' && (
      <div>
        <Row>
          <Col span={22} offset={1}>
            <Card
              title="Design"
              className="publish-preview-journey"
              bordered={false}
              style={{ minHeight: '300px' }}
              extra={<EditIcon onClick={() => props.goNextStep('design')} />}
            >
              <JourneyFlow
                {...props}
                readOnly={true}
                style={{ minHeight: '500px' }}
                currentStep={'design'}
              />
            </Card>
            <br />
            <br />
            <Card
              title="Segment"
              bordered={false}
              // style={{ minHeight: "300px" }}
              extra={<EditIcon onClick={() => props.goNextStep('segment')} />}
            >
              <SegmentStep {...props} readOnly={true} currentStep={'segment'} />
            </Card>
            <br />
            <br />
            {journey.conversion && (
              <>
                <Card
                  title="Conversion Tracking"
                  bordered={false}
                  // style={{ minHeight: "300px" }}
                  extra={
                    <EditIcon
                      onClick={() => props.goNextStep('conversionTracking')}
                    />
                  }
                >
                  <ConversionTrackingStep
                    {...props}
                    readOnly={true}
                    currentStep={'conversionTracking'}
                  />
                </Card>
                <br />
                <br />
              </>
            )}

            <Card
              title="Frequency Capping and DND"
              bordered={false}
              // style={{ minHeight: "300px" }}
              extra={
                <EditIcon
                  onClick={() => props.goNextStep('frequencyCappingandDND')}
                />
              }
            >
              <FreqDndStep
                {...props}
                readOnly={true}
                currentStep={'frequencyCappingandDND'}
              />
            </Card>
          </Col>
        </Row>
      </div>
    )
  );
};

export default PublishStep;
