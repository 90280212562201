import React from 'react';
import { Input, Button, Form, Switch } from 'antd';
import { Link } from 'react-router-dom';
import Card from '@Utils/Card';
import EmailVerificationModal from './EmailVerificationModal';
import './VerificationModal.less';
import isNil from 'lodash/isNil';

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    width: 120,
    // display: 'flex',
    color: '#9a9ca3',
    fontSize: 14,
    margin: '15px 10px',
  },
  row: {
    direction: 'ltr',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  box: {
    padding: 30,
  },
};
class ProfileUpdateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVerificationModalOpen: false,
      isTimerSet: false,
    };
  }

  componentDidMount() {
    this.props.profileUpdateFetch();
  }

  componentWillUnmount() {
    this.props.profileChangeCurrent({});
  }

  onFinish = (values) => {
    this.props.profileUpdateSubmitFetch(values);
  };

  isTimerEnabled = () => {
    this.setState({ isTimerSet: true });
  };

  handleVerifyEmail = () => {
    if (!sessionStorage.getItem('timerDuration') && !this.state.isTimerSet) {
      let verificationCodeBody = {
        userId: this.props.currentUser.userId,
        userEmail: this.props.currentProfile.email,
      };
      this.props.emailVerificationOTPCodeFetch({
        body: verificationCodeBody,
        cb: this.setState({ isVerificationModalOpen: true }),
      });
    } else {
      this.setState({ isVerificationModalOpen: true });
    }
  };

  handleCloseModal = () => {
    this.setState({ isVerificationModalOpen: false });
    this.props.resetEmailVerificationState();
  };

  renderProfileUpdate = () => {
    return (
      <>
        <div id="verification">
          <Card styles={styles.box} loading={this.props.loadingUpdate}>
            <React.Fragment>
              <div id="email" style={styles.row}>
                <div style={styles.title}>EMAIL</div>
                <Form.Item
                  className="ant-form-item email-input"
                  hasFeedback
                  validateStatus={
                    this.props.currentUser?.emailVerified ? 'success' : ''
                  }
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    placeholder="Email"
                    value={this.props.currentProfile.email}
                    onChange={(e) =>
                      this.props.profileChangeCurrent({
                        ...this.props.currentProfile,
                        email: e.target.value,
                      })
                    }
                    style={{ width: 300, height: 35 }}
                    disabled={
                      this.props.currentUser?.emailVerified ||
                      sessionStorage.getItem('timerDuration')
                    }
                  />
                </Form.Item>
              </div>
              {!this.props.currentUser.emailVerified ? (
                <div id="verification" style={styles.row}>
                  <div style={styles.title}></div>
                  <div className="emailVerificationBox">
                    <div className="emailVerificationTitle">
                      <div>
                        <i
                          className="fl-info"
                          style={{
                            color: '#f76464',
                            fontSize: 28,
                          }}
                        ></i>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        Email Verification Required
                      </div>
                    </div>
                    <div className="emailVerificationDescription">
                      <div style={{ marginBottom: '10px' }}>
                        To ensure the security of your account and unlock all
                        the feature and benefits, please verify your email
                        address
                      </div>
                      <Button
                        type="primary"
                        shape="round"
                        size={'medium'}
                        className="w-200"
                        onClick={this.handleVerifyEmail}
                      >
                        Verify Email Now
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}
              <div style={styles.row}>
                <div style={styles.title}>FIRST NAME</div>
                <Input
                  placeholder="First Name"
                  errorText={this.props.errors.firstName}
                  value={this.props.currentProfile.firstName}
                  onChange={(e) =>
                    this.props.profileChangeCurrent({
                      ...this.props.currentProfile,
                      firstName: e.target.value,
                    })
                  }
                  style={{ width: 300, height: 35 }}
                />
              </div>
              <div style={styles.row}>
                <div style={styles.title}>LAST NAME</div>
                <Input
                  placeholder="Last Name"
                  errorText={this.props.errors.lastName}
                  value={this.props.currentProfile.lastName}
                  onChange={(e) =>
                    this.props.profileChangeCurrent({
                      ...this.props.currentProfile,
                      lastName: e.target.value,
                    })
                  }
                  style={{ width: 300, height: 35 }}
                />
              </div>
              <div style={styles.row}>
                <div style={styles.title}>TWO FACTOR AUTHENTICATION</div>
                <Switch
                  id={'two-factor-authentication-switch-id'}
                  data-cy="two-factor-authentication-switch"
                  disabled={
                    !isNil(
                      this.props.currentUser.serverForcedTwoFactorAuthentication
                    ) || !this.props.currentUser.emailVerified
                  }
                  checked={
                    this.props.currentUser
                      .serverForcedTwoFactorAuthentication ??
                    this.props.currentProfile?.enableTwoFactorAuthentication
                  }
                  onChange={(e) =>
                    this.props.profileChangeCurrent({
                      ...this.props.currentProfile,
                      enableTwoFactorAuthentication: e,
                    })
                  }
                  style={{ position: 'relative', top: 5, left: 10 }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 30,
                }}
              >
                <Link to={'/account/profile/changePassword'}>
                  <Button
                    type="primary"
                    shape="round"
                    size={'large'}
                    className="w-200"
                    disabled={!this.props.currentUser?.emailVerified}
                  >
                    change password
                  </Button>
                </Link>
                <Button
                  type="primary"
                  shape="round"
                  size={'large'}
                  loading={this.props.loadingUpdateSubmit}
                  className="w-200"
                  onClick={() => {
                    this.props.profileUpdateSubmitFetch(
                      this.props.currentProfile
                    );
                  }}
                  disabled={!this.props.currentUser?.emailVerified}
                >
                  SAVE
                </Button>
              </div>
            </React.Fragment>
          </Card>
        </div>
        <EmailVerificationModal
          isOpen={this.state.isVerificationModalOpen}
          onClose={this.handleCloseModal}
          isTimerEnabled={this.isTimerEnabled}
        />
      </>
    );
  };

  render() {
    return this.renderProfileUpdate();
  }
}

export default ProfileUpdateComponent;
