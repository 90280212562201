import React from 'react';
import WhatsAppProvidersList from './components/WhatsAppProvidersList';
import AvailableWhatsAppProviders from './components/AvailableWhatsAppProviders';
import WhatsAppTemplatesList from './components/WhatsAppTemplatesList';

export default function WhatsAppChannel() {
  return (
    <>
      <WhatsAppProvidersList />
      <WhatsAppTemplatesList />
      <AvailableWhatsAppProviders />
    </>
  );
}
