import ReleaseNoteApi from './ReleaseNoteApi';
import { history } from '../../../redux/store';
import {
  RELEASE_NOTE_CHANGE_CURRENT,
  RELEASE_NOTE_CREATE_FETCH,
  RELEASE_NOTE_CREATE_FETCH_FAIL,
  RELEASE_NOTE_CREATE_FETCH_SUCCESS,
  RELEASE_NOTE_DELETE_FETCH,
  RELEASE_NOTE_DELETE_FETCH_FAIL,
  RELEASE_NOTE_DELETE_FETCH_SUCCESS,
  RELEASE_NOTE_LIST_FETCH,
  RELEASE_NOTE_LIST_FETCH_FAIL,
  RELEASE_NOTE_LIST_FETCH_SUCCESS,
  RELEASE_NOTE_UPDATE_FETCH,
  RELEASE_NOTE_UPDATE_FETCH_FAIL,
  RELEASE_NOTE_UPDATE_FETCH_SUCCESS,
  RELEASE_NOTE_UPDATE_SUBMIT_FETCH,
  RELEASE_NOTE_UPDATE_SUBMIT_FETCH_FAIL,
  RELEASE_NOTE_UPDATE_SUBMIT_FETCH_SUCCESS,
} from './ReleaseNoteConstants';

const loadReleaseNoteList = (dispatch, pageRequest) => {
  dispatch({
    type: RELEASE_NOTE_LIST_FETCH,
    payload: pageRequest,
  });
  ReleaseNoteApi.list(pageRequest)
    .then((response) => {
      dispatch({
        type: RELEASE_NOTE_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: RELEASE_NOTE_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function releaseNoteFetchList(pageRequest) {
  return (dispatch) => loadReleaseNoteList(dispatch, pageRequest);
}
export function releaseNoteCreateFetch(note) {
  return (dispatch) => {
    dispatch({
      type: RELEASE_NOTE_CREATE_FETCH,
      payload: note,
    });
    ReleaseNoteApi.create(note)
      .then((response) => {
        dispatch({
          type: RELEASE_NOTE_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        history.push('list');
      })
      .catch((response) => {
        dispatch({
          type: RELEASE_NOTE_CREATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function releaseNoteUpdateFetch(id) {
  return (dispatch) => {
    dispatch({
      type: RELEASE_NOTE_UPDATE_FETCH,
      payload: id,
    });
    ReleaseNoteApi.update(id)
      .then((response) => {
        dispatch({
          type: RELEASE_NOTE_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: RELEASE_NOTE_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function releaseNoteUpdateSubmitFetch(id, note) {
  return (dispatch) => {
    dispatch({
      type: RELEASE_NOTE_UPDATE_SUBMIT_FETCH,
      payload: note,
    });
    ReleaseNoteApi.updateSubmit(id, note)
      .then((response) => {
        dispatch({
          type: RELEASE_NOTE_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        history.push('../list');
      })
      .catch((response) => {
        dispatch({
          type: RELEASE_NOTE_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function releaseNoteDeleteFetch(id, pageRequest) {
  return (dispatch) => {
    dispatch({ type: RELEASE_NOTE_DELETE_FETCH });
    ReleaseNoteApi.delete(id)
      .then((response) => {
        dispatch({
          type: RELEASE_NOTE_DELETE_FETCH_SUCCESS,
          payload: response,
        });
        loadReleaseNoteList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: RELEASE_NOTE_DELETE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function releaseNoteChangeCurrent(note) {
  return (dispatch) => {
    dispatch({
      type: RELEASE_NOTE_CHANGE_CURRENT,
      payload: note,
    });
  };
}
