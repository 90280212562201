import { bindActionCreators } from 'redux';
import {
  executeFunnel,
  stopFunnelExecution,
  updateFunnelExecutionParameters,
  viewFunnel,
  viewFunnelExecution,
} from '@Modules/analytics/funnel/FunnelAction';
import { connect } from 'react-redux';
import FunnelView from '../component/FunnelView';

const mapStateToProps = (state) => ({
  ...state.analytics.funnel.view,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      viewFunnel: viewFunnel,
      viewFunnelExecution: viewFunnelExecution,
      executeFunnel: executeFunnel,
      stopFunnelExecution: stopFunnelExecution,
      updateFunnelExecutionParameters: updateFunnelExecutionParameters,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FunnelView);
