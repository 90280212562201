import { isEmpty } from 'lodash';

export function removeSpaceEnter(_, value) {
  let newVal = value
    .replace(/(<([^>]+)>)/gi, '')
    .trim()
    .replace(/&nbsp;/g, '')
    .replace(/&lt;br&gt;/g, '')
    .replace(/\r?\n|\r/g, '');
  if (newVal === '' || isEmpty(newVal)) {
    return Promise.reject(new Error('description is required!'));
  }
  return Promise.resolve();
}
