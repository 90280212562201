import { uniq } from 'lodash';
import * as XLSX from 'xlsx';

export const isNumeric = (value) => {
  if (!value) return true;
  return !isNaN(value) && !isNaN(parseFloat(value));
};

export const isBool = (value) => {
  if (!value) return true;
  return ['false', 'true'].includes(value.toLocaleLowerCase());
};

export const isDate = (dateStr) => {
  if (!dateStr) return true;
  if (
    !/^(\d{4})-(\d{2})-(\d{2})(T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((([+-]\d\d:?\d\d)|Z)?))?$/gm.test(
      dateStr
    )
  ) {
    return false;
  }
  var td = new Date(dateStr);
  return (
    Object.prototype.toString.call(td) === '[object Date]' &&
    !isNaN(td.getTime())
  );
};

export const isPhoneNumber = (value) => {
  return /^\+[0-9]{6,19}$/.test(value);
};

export const isEmail = (value) => {
  if (!value) return true;
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(value).toLowerCase()
  );
};

export const isAlphaNumeric = (value) => {
  return /^[a-zA-Z0-9_.\\-]*$/.test(value);
};

export const getCSVData = (file) =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function (e) {
        const text = e.target.result;
        var lines = text.split('\n');
        var result = [];
        var headers = lines[0].split(',');

        for (var i = 1; i < lines.length; i++) {
          var obj = {};
          var currentline = lines[i].split(',');

          for (var j = 0; j < headers.length; j++) {
            let h = headers[j]?.trim()?.replace(/(\r\n|\n|\r)/gm, '');
            if (h == '') {
              h = new Array(j).join(' ');
            }
            obj[h] = currentline[j]?.trim()?.replace(/(\r\n|\n|\r)/gm, '');
          }

          result.push(obj);
        }
        resolve(result);
      };
      reader.readAsText(file);
    } catch (error) {
      reject(error);
    }
  });

export const getExcelData = (file) =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Extracting headers from the first row
        const headers = jsonData.shift();

        // Creating objects with headers as keys
        const result = jsonData.map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            const key = header.trim();
            const value = row[index];
            obj[key] =
              value && typeof value === 'string' ? value.trim() : value;
          });
          return obj;
        });

        resolve(result);
      };
      if (file.name.endsWith('.xlsx')) {
        reader.readAsArrayBuffer(file);
      } else {
        reject(new Error('Unsupported file format'));
      }
    } catch (error) {
      reject(error);
    }
  });

export const validateData = (source, data) => {
  const output = {
    data: [],
    isValid: true,
    errorMessage: null,
    attributesMap: {},
  };

  const keys = Object.keys(data[0]);
  if (!data.length || !keys.length) {
    output.errorMessage = 'Invalid CSV file.';
    output.isValid = false;
    return output;
  }

  if (!keys.includes('userId') || !data[0].userId) {
    output.errorMessage = 'Invalid CSV file. userId is mandatory.';
    output.isValid = false;
    return output;
  }

  if (uniq(keys).length != keys.length) {
    output.errorMessage = 'you have duplicate column';
    output.isValid = false;
    return output;
  }

  if (keys.length > 70) {
    output.errorMessage = 'users can not have more than 50 attribute';
    output.isValid = false;
    return output;
  }

  keys.forEach((key) => {
    const sourceItem = source.find((s) => s.name == key);
    let value = data[0][key];
    let valueType = '';
    let error = null;

    if (!key) {
      error = "attribute name couldn't be empty";
      output.isValid = false;
    }

    if (sourceItem) {
      if (key !== 'userId' && !sourceItem.updatableField) {
        error = 'this field is not editable';
        valueType = '-';
        output.isValid = false;
      } else {
        switch (sourceItem.type) {
          case 'NUMERIC':
            if (isNumeric(value)) {
              valueType = 'NUMERIC';
              output.attributesMap[key] = 'NUMERIC';
            } else {
              valueType = 'Not Match';
              error = 'value type is not mached with existing type';
              output.isValid = false;
            }
            break;
          case 'BOOLEAN':
            if (isBool(value)) {
              valueType = 'BOOLEAN';
              output.attributesMap[key] = 'BOOLEAN';
            } else {
              valueType = 'Not Match';
              error = 'value type is not mached with existing type';
              output.isValid = false;
            }
            break;

          case 'DATE':
            if (isDate(value)) {
              valueType = 'DATE';
              output.attributesMap[key] = 'DATE';
            } else {
              valueType = 'Not Match';
              error = 'date string should be ISO-8601 format';
              output.isValid = false;
            }
            break;

          case 'ARRAY':
            valueType = '-';
            error = 'Map and Array data types are not allowed for CSV uploads.';
            output.isValid = false;
            break;

          case 'OBJECT':
            valueType = '-';
            error = 'Map and Array data types are not allowed for CSV uploads.';
            output.isValid = false;
            break;

          default:
            valueType = 'STRING';
            output.attributesMap[key] = 'STRING';
            break;
        }
      }

      //special cases
      if (key === 'userId' && !isAlphaNumeric(value)) {
        error = 'userId is invalid (Only -_. and English Letters and Numbers)';
        output.isValid = false;
      }

      if (key === 'email' && !isEmail(value)) {
        error = 'email address is not valid';
        output.isValid = false;
      }
      if (
        key === 'gender' &&
        value &&
        !['male', 'female', 'other'].includes(value?.toLocaleLowerCase())
      ) {
        error = 'gender value should be one of "male", "female" or "other"';
        output.isValid = false;
      }
    } else {
      if (key.length > 50) {
        error = "attribute names couldn't be more than 50 charachter";
        output.isValid = false;
      }
      if (!isAlphaNumeric(key)) {
        error = 'attribute name is not acceptable';
        output.isValid = false;
      }
      if (isNumeric(value)) {
        valueType = ['NUMERIC', 'STRING'];
        output.attributesMap[key] = 'NUMERIC';
      } else if (isDate(value)) {
        valueType = ['DATE', 'STRING'];
        output.attributesMap[key] = 'DATE';
      } else if (isBool(value)) {
        valueType = ['BOOLEAN', 'STRING'];
        output.attributesMap[key] = 'BOOLEAN';
      } else {
        valueType = ['STRING'];
        output.attributesMap[key] = 'STRING';
      }
      if (!value) {
        valueType = ['STRING', 'DATE', 'NUMERIC', 'BOOLEAN'];
        output.attributesMap[key] = 'STRING';
      }
    }

    if (
      output.attributesMap[key] === 'STRING' &&
      value &&
      value.length > 1000
    ) {
      error = "values couldn't have more than 1000 charachter";
      output.isValid = false;
    }

    output.data.push({
      attribute: key,
      type: sourceItem ? 'Existing' : 'New',
      value,
      sourceType: sourceItem ? sourceItem.type : '-',
      valueType,
      error,
    });
  });

  if (!output.isValid && !output.errorMessage) {
    output.errorMessage = 'ERROR! Preview based on first 2 rows of data.';
  }

  return output;
};

export const eventValidateData = (source, data) => {
  const output = {
    data: [],
    isValid: true,
    errorMessage: null,
    attributesMap: {},
    eventName: null,
  };

  const keys = Object.keys(data[0]);
  if (!data.length || !keys.length) {
    output.errorMessage = 'Invalid CSV file.';
    output.isValid = false;
    return output;
  }

  if (!keys.includes('userId') || !data[0].userId) {
    output.errorMessage = 'Invalid CSV file. Column userId is mandatory.';
    output.isValid = false;
    return output;
  }
  if (!keys.includes('eventName') || !data[0].eventName) {
    output.errorMessage = 'Invalid CSV file. Column eventName is mandatory.';
    output.isValid = false;
    return output;
  }
  if (!keys.includes('eventTime') || !data[0].eventTime) {
    output.errorMessage = 'Invalid CSV file. Column eventTime is mandatory.';
    output.isValid = false;
    return output;
  }

  const eventDetails = source.find((s) => s.name == data[0]['eventName']);
  const eventTime = data[0].eventTime;
  if (eventDetails?.system) {
    output.errorMessage = 'this event is not editable.';
    output.isValid = false;
    return output;
  }

  keys.forEach((key) => {
    const sourceItem = eventDetails?.attributes?.find((s) => s.name == key);
    let value = data[0][key];
    let valueType = '';
    let error = null;
    output.eventName = data[0].eventName;

    if (!key) {
      error = "value couldn't be empty";
      output.isValid = false;
    }
    if (key === 'eventName' || key === 'userId' || key === 'eventTime') {
      switch (key) {
        case 'eventName':
          output.data.push({
            attribute: key,
            type: eventDetails ? 'Existing' : 'New',
            value,
            sourceType: 'STRING',
            valueType: 'STRING',
            error,
          });
          output.attributesMap[key] = 'STRING';
          break;
        case 'userId':
          if (!isAlphaNumeric(value)) {
            error =
              'userId is invalid (Only -_. and English Letters and Numbers)';
            output.isValid = false;
          }
          output.data.push({
            attribute: key,
            type: 'Existing',
            value,
            sourceType: 'STRING',
            valueType: 'STRING',
            error,
          });
          output.attributesMap[key] = 'STRING';
          break;
        case 'eventTime':
          if (isDate(eventTime)) {
            output.data.push({
              attribute: key,
              type: 'Existing',
              value,
              sourceType: 'DATE',
              valueType: 'DATE',
              error,
            });
            output.attributesMap[key] = 'DATE';
          } else {
            output.isValid = false;
            output.data.push({
              attribute: key,
              type: 'Existing',
              value,
              sourceType: 'DATE',
              valueType: 'Not Match',
              error: 'date string should be ISO- 8601 format',
            });
          }
      }
    } else {
      if (sourceItem) {
        if (sourceItem.system) {
          error = 'this field is not editable';
          valueType = '-';
          output.isValid = false;
        } else {
          switch (sourceItem.type) {
            case 'NUMERIC':
              if (isNumeric(value)) {
                valueType = 'NUMERIC';
                output.attributesMap[key] = 'NUMERIC';
              } else {
                valueType = 'Not Match';
                error = 'value type is not mached with existing type';
                output.isValid = false;
              }
              break;
            case 'BOOLEAN':
              if (isBool(value.toString())) {
                valueType = 'BOOLEAN';
                output.attributesMap[key] = 'BOOLEAN';
              } else {
                valueType = 'Not Match';
                error = 'value type is not mached with existing type';
                output.isValid = false;
              }
              break;

            case 'DATE':
              if (isDate(value)) {
                valueType = 'DATE';
                output.attributesMap[key] = 'DATE';
              } else {
                valueType = 'Not Match';
                error = 'date string should be ISO-8601 format';
                output.isValid = false;
              }
              break;
            default:
              valueType = 'STRING';
              output.attributesMap[key] = 'STRING';
              break;
          }
        }
      } else {
        if (key.length > 100) {
          error = "attribute names couldn't be more than 100 charachter";
          output.isValid = false;
        }
        if (!isAlphaNumeric(key)) {
          error = 'attribute name is not acceptable';
          output.isValid = false;
        }
        if (isNumeric(value)) {
          valueType = ['NUMERIC', 'STRING'];
          output.attributesMap[key] = 'NUMERIC';
        } else if (isDate(value)) {
          valueType = ['DATE', 'STRING'];
          output.attributesMap[key] = 'DATE';
        } else if (isBool(value.toString())) {
          valueType = ['BOOLEAN', 'STRING'];
          output.attributesMap[key] = 'BOOLEAN';
        } else {
          valueType = ['STRING'];
          output.attributesMap[key] = 'STRING';
        }
        if (!value) {
          valueType = ['STRING', 'DATE', 'NUMERIC', 'BOOLEAN'];
          output.attributesMap[key] = 'STRING';
        }
      }
      if (
        output.attributesMap[key] === 'STRING' &&
        value &&
        value.length > 1000
      ) {
        error = "values couldn't have more than 1000 charachter";
        output.isValid = false;
      }
      output.data.push({
        attribute: key,
        type: sourceItem ? 'Existing' : 'New',
        value: value.toString(),
        sourceType: sourceItem ? sourceItem.type : '-',
        valueType,
        error,
      });
    }
  });

  if (!output.isValid && !output.errorMessage) {
    output.errorMessage = 'ERROR! Preview based on first 2 rows of data.';
  }

  return output;
};
