import { combineReducers } from 'redux';
import productRestApi from './productRestApi/ProductRestApiState';
import productRestApiTokens from './productRestApiToken/ApiTokenState';
import productSdkAndroid from './productSdk/ProductSdkState';
import webhook from './webhook/WebhookState';
import teamMembers from './teamMembers/TeamMembersState';

const SdkReducer = combineReducers({
  productRestApi,
  productRestApiTokens,
  productSdkAndroid,
  webhook,
  teamMembers,
});

export default SdkReducer;
