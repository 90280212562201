import { createSlice } from '@reduxjs/toolkit';
import InvoiceApi from './invoiceApi';
import { showMessageError } from '@Utils/RenderUtils';

const initialState = {
  page: {},
  pageRequest: {
    direction: 'DESC',
    enabled: true,
    order: 'createdDate',
    name: '',
    page: 0,
  },
  loadingList: false,
  errors: [],

  invoice: {},
  loadingInvoice: false,

  loadingUploadReceipts: false,
  loadingFetchInvoice: false,
  selectedInvoiceInfo: [],
};
export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    loadInvoiceListStart(state, { payload }) {
      state.pageRequest = payload;
      state.loadingList = true;
    },
    invoiceListFetched(state, { payload }) {
      state.page = payload;
      state.loadingList = false;
    },
    invoiceListFetchedErrors(state, { payload }) {
      state.errors = payload;
      state.loadingList = false;
    },
    invoiceViewStart(state) {
      state.loadingInvoice = true;
    },
    invoiceViewFetched(state, { payload }) {
      state.invoice = payload;
      state.loadingInvoice = false;
    },
    invoiceViewErrors(state, { payload }) {
      state.errors = payload;
      state.loadingInvoice = false;
    },
    setLoadingUploadReceipts(state, { payload }) {
      state.loadingUploadReceipts = payload;
    },
    setLoadingFetchInvoice(state, { payload }) {
      state.loadingFetchInvoice = payload;
    },
    setSelectedInvoiceInfo(state, { payload }) {
      state.selectedInvoiceInfo = payload;
    },
  },
});
export default invoiceSlice.reducer;

export const {
  loadInvoiceListStart,
  invoiceListFetched,
  invoiceListFetchedErrors,
  invoiceViewStart,
  invoiceViewFetched,
  invoiceViewErrors,
  setLoadingUploadReceipts,
  setLoadingFetchInvoice,
  setSelectedInvoiceInfo,
} = invoiceSlice.actions;

export const fetchInvoiceList = (pageRequest) => async (dispatch) => {
  dispatch(loadInvoiceListStart(pageRequest));
  try {
    const response = await InvoiceApi.list(pageRequest);
    dispatch(invoiceListFetched(response));
  } catch (err) {
    dispatch(invoiceListFetchedErrors(err));
    showMessageError(err);
  }
};

export const fetchInvoiceView = (id) => async (dispatch) => {
  dispatch(invoiceViewStart());
  try {
    const response = await InvoiceApi.view(id);
    dispatch(invoiceViewFetched(response));
  } catch (err) {
    dispatch(invoiceViewErrors(err));
    showMessageError(err);
  }
};

export const uploadPaymentReceipt =
  (id, body, cb) => async (dispatch, getState) => {
    const state = getState();
    dispatch(setLoadingUploadReceipts(true));
    await InvoiceApi.uploadReceipt(id, body)
      .then(() => cb && cb())
      .then(() => dispatch(fetchInvoiceList(state.setting.invoice.pageRequest)))
      .catch((error) => showMessageError(error))
      .finally(() => dispatch(setLoadingUploadReceipts(false)));
  };

export const fetchInvoicePaymentReceipt = (id) => async (dispatch) => {
  dispatch(setLoadingFetchInvoice(true));
  await InvoiceApi.viewReceipt(id)
    .then((resp) => dispatch(setSelectedInvoiceInfo(resp)))
    .catch((error) => showMessageError(error))
    .finally(() => dispatch(setLoadingFetchInvoice(false)));
};

export const confirmInvoice = (id, cb) => async () => {
  await InvoiceApi.confirm(id)
    .then((resp) => cb && cb(resp))
    .catch((error) => {
      showMessageError(error);
    });
};

export const rejectInvoice = (id, body, cb) => async () => {
  await InvoiceApi.reject(id, body)
    .then((resp) => cb && cb(resp))
    .catch((error) => {
      showMessageError(error);
    });
};
