import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { isNil } from '@antv/util';
import styles from './BusinessEventCreateComponent.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { businessEventTriggerFetch } from '@Redux/slices/data/businessEventSlice';

const InputValue = ({ errorsTrigger, item, value, onChange }) => {
  const [fieldValidate, setFieldValidate] = useState('');

  const fildeItem = `eventAttributes['${item.name}']`;

  useEffect(() => {
    if (!isEmpty(errorsTrigger)) {
      const errorInfo = errorsTrigger.find((err) => err.key === fildeItem);
      setFieldValidate(errorInfo?.message);
    } else {
      setFieldValidate('');
    }
  }, [errorsTrigger]);

  switch (item.type) {
    case 'NUMERIC':
      return (
        <Form.Item
          name={['eventAttributes', item.name]}
          // rules={[
          //   {
          //     required: true,
          //     message: 'This field is required.',
          //   },
          // ]}
          {...(fieldValidate && {
            hasFeedback: true,
            help: fieldValidate,
            validateStatus: 'error',
          })}
        >
          <InputNumber
            placeholder="Enter a value"
            value={value}
            onChange={onChange}
            style={{ width: 150 }}
          />
        </Form.Item>
      );
    case 'BOOLEAN':
      return (
        <Form.Item
          name={['eventAttributes', item.name]}
          // rules={[
          //   {
          //     required: true,
          //     message: 'This field is required.',
          //   },
          // ]}
        >
          <Select placeholder="Select ..." onChange={onChange} allowClear>
            <Select.Option value={true}>True</Select.Option>
            <Select.Option value={false}>False</Select.Option>
          </Select>
        </Form.Item>
      );
    case 'DATE':
      return (
        <Form.Item
          name={['eventAttributes', item.name]}
          // rules={[
          //   {
          //     required: true,
          //     message: 'This field is required.',
          //   },
          // ]}
        >
          <DatePicker
            value={value}
            showTime={{ format: 'HH:mm' }}
            style={{ width: 150 }}
            onChange={onChange}
          />
        </Form.Item>
      );
    default:
      return (
        <Form.Item
          name={['eventAttributes', item.name]}
          // rules={[
          //   {
          //     required: true,
          //     message: 'This field is required.',
          //   },
          // ]}
          {...(fieldValidate && {
            hasFeedback: true,
            help: fieldValidate,
            validateStatus: 'error',
          })}
        >
          <Input
            placeholder="Enter a value"
            value={value}
            onChange={onChange}
          />
        </Form.Item>
      );
  }
};
const AttrType = (props) => {
  switch (props.type) {
    case 'NUMERIC':
      return 'Number';
    case 'BOOLEAN':
      return 'Boolean';
    case 'DATE':
      return 'Date';
    default:
      return 'String';
  }
};
export default function BusinessEventTriggerComponent(props) {
  const productId = props?.productId;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const loadingTrigger = useSelector(
    (state) => state.dataModule.dataApp.loading
  );
  const errorsTrigger = useSelector(
    (state) => state.dataModule.businessEvent.errorsTrigger
  );
  useEffect(() => {
    if (!errorsTrigger?.length && !loadingTrigger) {
      props.setVisible(false);
      form.resetFields();
    }
  }, [loadingTrigger]);
  const eventAttributes = props?.currentBussinesEventAttr?.eventAttributes;

  return (
    <Modal
      title={
        props.viewMode
          ? 'BUSINESS EVENT ATTRIBUTE VALUES'
          : 'TRIGGER BUSINESS EVENT'
      }
      visible={props.visible}
      width="90vw"
      confirmLoading={loadingTrigger}
      okText="Trigger Business Event"
      cancelText="Cancel"
      onCancel={() => props.handleCancel(form)}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            const enterdEventAttributes = values.eventAttributes;
            let eventAttributes = {};
            for (const property in enterdEventAttributes) {
              if (
                !isNil(enterdEventAttributes[property]) &&
                String(enterdEventAttributes[property]).length > 0
              ) {
                eventAttributes[property] = enterdEventAttributes[property];
              }
            }
            dispatch(
              businessEventTriggerFetch({
                eventName: props?.currentBussinesEventAttr?.name,
                eventId: props.currentBussinesEventAttr?.id,
                productId: parseInt(productId),
                eventTime: new Date(),
                eventAttributes,
              })
            );
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      footer={props.viewMode && null}
    >
      <Form
        id="trigger"
        name="businessEvent"
        autoComplete="off"
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        className={styles.businessEvent}
        form={form}
      >
        <Row style={{ marginBottom: 20 }}>
          <Col span={4} className={styles.itemTitle}>
            event name
          </Col>
          <Col span={10}>
            <Input
              value={
                props.viewMode
                  ? props?.currentBussinesEventAttr?.businessEventName
                  : props?.currentBussinesEventAttr?.name
              }
              disabled={true}
            />
          </Col>
        </Row>
        {props.viewMode && (
          <Row style={{ marginBottom: 20 }}>
            <Col span={4} className={styles.itemTitle}>
              Instance Id
            </Col>
            <Col span={10}>
              <span>
                {props?.currentBussinesEventAttr?.businessEventInstanceId}
              </span>
            </Col>
          </Row>
        )}
        <Row style={{ marginBottom: 24 }}>
          <Col span={4} className={styles.itemTitle}>
            attributes
          </Col>
          <Col span={10} className={styles.itemTitle}>
            attribute name
          </Col>
          {!props.viewMode && (
            <Col span={5} className={styles.itemTitle}>
              attribute data type
            </Col>
          )}
          <Col span={5} className={styles.itemTitle}>
            attribute value
          </Col>
        </Row>
        {props.viewMode
          ? eventAttributes &&
            Object.entries(
              props?.currentBussinesEventAttr?.eventAttributes
            ).map(([key, value]) => (
              <Row key={key} className={styles.itemValue}>
                <Col span={10} push={4}>
                  <div>{key}</div>
                </Col>
                <Col span={5} push={4}>
                  <Input value={value} disabled={true} />
                </Col>
              </Row>
            ))
          : props.currentBussinesEventAttr?.attributes.map((item, index) => (
              <Row key={index}>
                <Col span={10} push={4}>
                  <div>{item.name}</div>
                </Col>
                <Col span={5} push={4}>
                  <AttrType type={item.type} />
                </Col>
                <Col span={5} push={4}>
                  <InputValue item={item} errorsTrigger={errorsTrigger} />
                </Col>
              </Row>
            ))}
      </Form>
    </Modal>
  );
}
