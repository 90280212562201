import { Button, Form } from 'antd';
import { SyncOutlined, CloseOutlined } from '@ant-design/icons';
import React from 'react';
import { useParams } from 'react-router-dom';

const FunnelExecutionButtons = ({
  executing,
  stopping,
  executionId,
  executionParams,
  executeFunnel,
  stopFunnelExecution,
}) => {
  const { id } = useParams();

  return (
    <div style={{ padding: 12 }}>
      <Form.Item style={{ marginBottom: 0 }}>
        <Button
          type="primary"
          htmlType="submit"
          shape="circle"
          icon={<SyncOutlined />}
          hidden={executing}
          onClick={() => {
            executionParams.funnelId = id;
            executeFunnel(executionParams);
          }}
        />
        <Button
          type="primary"
          htmlType="submit"
          shape="circle"
          icon={<CloseOutlined />}
          hidden={!executing}
          onClick={() => {
            stopFunnelExecution(executionId);
          }}
          disabled={stopping}
        />
      </Form.Item>
    </div>
  );
};

export default FunnelExecutionButtons;
