import { history } from '../../../redux/store';
import {
  ROLE_LIST_FETCH,
  ROLE_LIST_FETCH_FAIL,
  ROLE_LIST_FETCH_SUCCESS,
  ROLE_CREATE_FETCH,
  ROLE_CREATE_FETCH_FAIL,
  ROLE_CREATE_FETCH_SUCCESS,
  ROLE_AUTHORITY_LIST_FETCH,
  ROLE_AUTHORITY_LIST_FETCH_FAIL,
  ROLE_AUTHORITY_LIST_FETCH_SUCCESS,
  ROLE_CHANGE_CURRENT,
  ROLE_UPDATE_FETCH,
  ROLE_UPDATE_FETCH_FAIL,
  ROLE_UPDATE_FETCH_SUCCESS,
  ROLE_UPDATE_SUBMIT_FETCH,
  ROLE_UPDATE_SUBMIT_FETCH_FAIL,
  ROLE_UPDATE_SUBMIT_FETCH_SUCCESS,
  ROLE_DELETE_FETCH,
  ROLE_DELETE_FETCH_FAIL,
  ROLE_DELETE_FETCH_SUCCESS,
  ROLE_VIEW_FETCH,
  ROLE_VIEW_FETCH_FAIL,
  ROLE_VIEW_FETCH_SUCCESS,
  ROLE_PAGE_CHANGE_CURRENT,
  ROLE_COPY_FETCH_SUCCESS,
  ROLE_COPY_FETCH,
  ROLE_COPY_FETCH_FAIL,
  ROLE_SEARCH_LIST_FETCH_SUCCESS,
  ROLE_SEARCH_LIST_FETCH_FAIL,
  ROLE_SEARCH_LIST_FETCH,
  ADMIN_ROLE_CLEAR_ERROR,
} from './RoleConstants';
import RoleApi from './RoleApi';
import { cloneDeep, filter, isNil } from 'lodash';

const loadRoleList = (dispatch, rolePageRequest) => {
  dispatch({
    type: ROLE_LIST_FETCH,
    payload: rolePageRequest,
  });
  RoleApi.list(rolePageRequest)
    .then((response) => {
      dispatch({
        type: ROLE_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: ROLE_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function roleListFetch(rolePageRequest) {
  return (dispatch) => loadRoleList(dispatch, rolePageRequest);
}
const loadRoleSearchList = (dispatch, rolePageRequest) => {
  dispatch({
    type: ROLE_SEARCH_LIST_FETCH,
    payload: rolePageRequest,
  });
  RoleApi.list(rolePageRequest)
    .then((response) => {
      dispatch({
        type: ROLE_SEARCH_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: ROLE_SEARCH_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};
export function roleSearchListFetch(rolePageRequest) {
  return (dispatch) => loadRoleSearchList(dispatch, rolePageRequest);
}
export function roleAuthorityListFetch() {
  return (dispatch) => {
    dispatch({
      type: ROLE_AUTHORITY_LIST_FETCH,
    });
    RoleApi.listAuthorities()
      .then((response) => {
        dispatch({
          type: ROLE_AUTHORITY_LIST_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: ROLE_AUTHORITY_LIST_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function roleChangeCurrent(role) {
  return (dispatch) => {
    dispatch({
      type: ROLE_CHANGE_CURRENT,
      payload: role,
    });
  };
}

export function rolePageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: ROLE_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function roleCreateFetch(role, copyId) {
  return (dispatch) => {
    dispatch({
      type: ROLE_CREATE_FETCH,
      payload: role,
    });
    RoleApi.create(role)
      .then((response) => {
        dispatch({
          type: ROLE_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        if (copyId) {
          history.push('../list');
        } else {
          history.push('list');
        }
      })
      .catch((response) => {
        dispatch({
          type: ROLE_CREATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function roleCopyFetch(id) {
  return (dispatch) => {
    dispatch({
      type: ROLE_COPY_FETCH,
    });
    RoleApi.copy(id)
      .then((response) => {
        dispatch({
          type: ROLE_COPY_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: ROLE_COPY_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function roleUpdateFetch(id) {
  return (dispatch) => {
    dispatch({
      type: ROLE_UPDATE_FETCH,
    });
    RoleApi.update(id)
      .then((response) => {
        dispatch({
          type: ROLE_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: ROLE_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function roleUpdateSubmitFetch(id, role) {
  return (dispatch) => {
    dispatch({
      type: ROLE_UPDATE_SUBMIT_FETCH,
      payload: role,
    });
    RoleApi.updateSubmit(id, role)
      .then((response) => {
        dispatch({
          type: ROLE_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        history.push('../list');
      })
      .catch((response) => {
        dispatch({
          type: ROLE_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function roleDeleteFetch(id, rolePageRequest) {
  return (dispatch) => {
    dispatch({ type: ROLE_DELETE_FETCH });
    RoleApi.delete(id)
      .then((response) => {
        dispatch({
          type: ROLE_DELETE_FETCH_SUCCESS,
          payload: response,
        });
        loadRoleList(dispatch, rolePageRequest);
      })
      .catch((response) => {
        dispatch({
          type: ROLE_DELETE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function roleViewFetch(id) {
  return (dispatch) => {
    dispatch({ type: ROLE_VIEW_FETCH });
    RoleApi.view(id)
      .then((response) => {
        dispatch({
          type: ROLE_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: ROLE_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function clearRolesErrors(errors, errorKey = null) {
  return (dispatch) => {
    if (errorKey === null) {
      dispatch({
        type: ADMIN_ROLE_CLEAR_ERROR,
        payload: [],
      });
    } else {
      if (Array.isArray(errorKey) && !isNil(errorKey)) {
        let filteredErrors = cloneDeep(errors);
        for (var i = 0; i <= errorKey.length; i++) {
          filteredErrors = filter(filteredErrors, (o) => o.key !== errorKey[i]);
        }
        dispatch({
          type: ADMIN_ROLE_CLEAR_ERROR,
          payload: filteredErrors,
        });
      } else {
        const filteredErrors = filter(errors, (o) => o.key !== errorKey);
        dispatch({
          type: ADMIN_ROLE_CLEAR_ERROR,
          payload: filteredErrors,
        });
      }
    }
  };
}
