/* eslint-disable max-len */
import React from 'react';
import { Input, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { renderFullName } from '../../../../utils/RenderUtils';
import { viewDuration, readableDateTime } from '../../../../utils/DateUtils';
import { HashBase64 } from '@Component/utils/HashBase64';
import vars from '@Theme/styles/vars.js';
import Card from '@Utils/Card';
class UserBasicInfoComponent extends React.Component {
  state = {
    name: 'Amir Fazelinia',
    phone: '989122222222',
    email: 'amirfazelinia@gmail.com',
    isInEditMode: false,
  };

  componentDidMount() {
    const first = this.props.pathname.split('view/')[1];
    const encodedUserId = first.split('/basic')[0];
    const userId = HashBase64().decode(encodedUserId);
    this.props.insightProductUserViewFetch(userId);
    this.props.insightProductUserListAttributeFetch();
  }

  renderItem = (item) => {
    if (this.props.user && this.props.user[item]) {
      return this.props.user[item];
    }
    return '-';
  };

  renderDurationItem = (item) => {
    if (this.props.user && this.props.user[item]) {
      return viewDuration(this.props.user[item]);
    }
    return '-';
  };

  renderDate = (item) => {
    if (this.props.user && this.props.user[item]) {
      return readableDateTime(this.props.user[item]);
    }
    return '-';
  };

  renderContact = () => {
    return (
      <Card loading={this.props.userLoading}>
        <div>
          <div
            style={{
              padding: 16,
              borderBottom: 'solid 2px #f4f9ff',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ fontSize: 16, color: 'black', fontWeight: '600' }}>
              Contact
            </div>
            {false && (
              <React.Fragment>
                {this.state.isInEditMode ? (
                  <div style={{ color: vars.primaryColor, cursor: 'pointer' }}>
                    <Button
                      type="link"
                      shape="round"
                      onClick={() => this.setState({ isInEditMode: false })}
                      style={{ fontSize: 12, fontWeight: 600 }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      type="primary"
                      shape="round"
                      onClick={() => this.setState({ isInEditMode: false })}
                      style={{
                        fontSize: 12,
                        fontWeight: 600,

                        width: 100,
                        boxShadow: '0 3px 6px 0 rgba(29, 136, 246, 0.5)',
                      }}
                    >
                      SAVE
                    </Button>
                  </div>
                ) : (
                  <div
                    onClick={() => this.setState({ isInEditMode: true })}
                    style={{ color: vars.primaryColor, cursor: 'pointer' }}
                  >
                    <EditOutlined style={{ marginRight: 10 }} />
                    <span style={{ fontSize: 12, fontWeight: '600' }}>
                      EDIT
                    </span>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                NAME
              </span>
              {this.state.isInEditMode ? (
                <Input
                  style={{
                    width: 180,
                    position: 'relative',
                    bottom: 2,
                    borderRadius: 5,
                    border: 'solid 1px #707070',
                  }}
                  size="small"
                  placeholder="Name"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              ) : (
                <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                  {this.props.user
                    ? renderFullName(
                        this.props.user.firstName,
                        this.props.user.lastName
                      )
                    : '-'}
                </span>
              )}
            </div>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <div style={{ display: 'flex', flex: 1 }}>
                <span
                  style={{ color: '#707070', fontSize: 12, marginRight: 20 }}
                >
                  PHONE
                </span>
                {this.state.isInEditMode ? (
                  <Input
                    style={{
                      width: 180,
                      position: 'relative',
                      bottom: 2,
                      borderRadius: 5,
                      border: 'solid 1px #707070',
                    }}
                    size="small"
                    placeholder="Phone"
                    value={this.state.phone}
                    onChange={(e) => this.setState({ phone: e.target.value })}
                  />
                ) : (
                  <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                    {this.renderItem('phone')}
                  </span>
                )}
              </div>
              <div style={{ display: 'flex', flex: 1 }}>
                <span
                  style={{ color: '#707070', fontSize: 12, marginRight: 20 }}
                >
                  HASHED PHONE
                </span>
                {this.state.isInEditMode ? (
                  <Input
                    style={{
                      width: 180,
                      position: 'relative',
                      bottom: 2,
                      borderRadius: 5,
                      border: 'solid 1px #707070',
                    }}
                    size="small"
                    placeholder="Phone"
                    value={this.state.phone}
                    onChange={(e) => this.setState({ phone: e.target.value })}
                  />
                ) : (
                  <span
                    style={{
                      color: '#1c1c1c',
                      fontSize: 12,
                      width: '175px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {this.renderItem('hashedPhone')}
                  </span>
                )}
              </div>
            </div>

            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <div style={{ display: 'flex', flex: 1 }}>
                <span
                  style={{ color: '#707070', fontSize: 12, marginRight: 20 }}
                >
                  EMAIL
                </span>
                {this.state.isInEditMode ? (
                  <Input
                    style={{
                      width: 180,
                      position: 'relative',
                      bottom: 2,
                      borderRadius: 5,
                      border: 'solid 1px #707070',
                    }}
                    size="small"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                ) : (
                  <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                    {this.renderItem('email')}
                  </span>
                )}
              </div>
              <div style={{ display: 'flex', flex: 1 }}>
                <span
                  style={{ color: '#707070', fontSize: 12, marginRight: 20 }}
                >
                  HASHED EMAIL
                </span>
                {this.state.isInEditMode ? (
                  <Input
                    style={{
                      width: 180,
                      position: 'relative',
                      bottom: 2,
                      borderRadius: 5,
                      border: 'solid 1px #707070',
                    }}
                    size="small"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                ) : (
                  <span
                    style={{
                      color: '#1c1c1c',
                      fontSize: 12,
                      width: '175px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {this.renderItem('hashedEmail')}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  };

  renderLocation = () => {
    return (
      <Card
        styles={{
          marginTop: 20,
        }}
        loading={this.props.userLoading}
      >
        <div>
          {' '}
          <div
            style={{
              padding: 16,
              borderBottom: 'solid 2px #f4f9ff',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <div style={{ fontSize: 16, color: 'black', fontWeight: '600' }}>
              Location
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              paddingBottom: 0,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                COUNTRY
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('country')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                CITY
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('city')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                LOCALITY
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('locality')}
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                STATE
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('state')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                POSTAL CODE
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('postalCode')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                TIME ZONE
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.props.user && this.props.user.timeZone
                  ? this.props.user.timeZone
                  : '-'}
              </span>
            </div>
          </div>
        </div>
      </Card>
    );
  };

  renderFirstAcquisitionDetails = () => {
    return (
      <Card
        styles={{
          marginTop: 20,
        }}
        loading={this.props.userLoading}
      >
        <div>
          <div
            style={{
              padding: 16,
              borderBottom: 'solid 2px #f4f9ff',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <div style={{ fontSize: 16, color: 'black', fontWeight: '600' }}>
              First Acquisition Details
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              paddingBottom: 0,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                CHANNEL
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('acquisitionChannel')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                CAMPAIGN SOURCE
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('acquisitionSource')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                CAMPAIGN MEDIUM
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('acquisitionMedium')}
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              paddingBottom: 0,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                CAMPAIGN NAME
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('acquisitionName')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                REFERER HOST
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('acquisitionReferrerHost')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                REFERER URL
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('acquisitionReferrerUrl')}
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                LANDING PAGE
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('acquisitionLandingPage')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}></div>
            <div style={{ display: 'flex', flex: 1 }}></div>
          </div>
        </div>
      </Card>
    );
  };

  renderActivityMetadataDetails = () => {
    return (
      <Card
        styles={{
          marginTop: 20,
        }}
        loading={this.props.userLoading}
      >
        <div>
          <div
            style={{
              padding: 16,
              borderBottom: 'solid 2px #f4f9ff',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <div style={{ fontSize: 16, color: 'black', fontWeight: '600' }}>
              Activity Metadata
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              paddingBottom: 0,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                LAST SEEN
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderDate('lastSeen')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                CREATED DATE
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderDate('createdDate')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                IDENTIFIED DATE
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderDate('identifiedDate')}
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                TOTAL SESSIONS
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('totalSessions')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
                TOTAL TIME
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderDurationItem('totalTime')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}></div>
          </div>
        </div>
      </Card>
    );
  };

  render() {
    return (
      <div>
        {this.renderContact()}
        {this.renderLocation()}
        {this.renderActivityMetadataDetails()}
        {this.renderFirstAcquisitionDetails()}
      </div>
    );
  }
}
export default UserBasicInfoComponent;
