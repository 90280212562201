import ProductSettingScheduledReportComponent from '../component/ProductSettingScheduledReportComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  productChangeCurrent,
  productUpdateFetch,
  productUpdateSubmitFetch,
} from '../ProductSettingActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentProduct: state.setting.productSetting.currentProduct,
  errors: state.setting.productSetting.errors,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      productChangeCurrent,
      productUpdateFetch,
      productUpdateSubmitFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSettingScheduledReportComponent);

ProductSettingScheduledReportComponent.propTypes = {
  currentProduct: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  productChangeCurrent: PropTypes.func.isRequired,
  productUpdateFetch: PropTypes.func.isRequired,
  productUpdateSubmitFetch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
