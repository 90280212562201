import {
  cardNames,
  defaultMetrics,
  cardReportEnums,
} from './dashboardConstants';
import styles from './ReportCardsSection.module.less';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import React from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Menu,
  Select,
  message,
} from 'antd';

const ReportCardsSectionHeader = ({
  productList,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  productName,
  setProductName,
  selectedCards,
  setSelectedCards,
  reportsEnum,
  setReportsEnum,
}) => {
  const handleDatePickerChange = (val) => {
    setStartDate(val[0]);
    setEndDate(val[1]);
  };

  const handleSelectChange = (val) => {
    setProductName(val);
  };

  const isCardChecked = (cardName) => {
    let tempSelectedCard = Object.keys(selectedCards);
    return tempSelectedCard.includes(cardName);
  };

  const handleChangeCards = (e, cardName) => {
    if (e.target.checked) {
      setSelectedCards((prevState) => {
        return {
          ...prevState,
          [cardName]: defaultMetrics[cardName],
        };
      });
      let tempReportsEnum = [...reportsEnum];
      cardReportEnums[cardName]?.map((report) => tempReportsEnum.push(report));
      setReportsEnum(tempReportsEnum);
    } else {
      if (Object.keys(selectedCards).length > 1) {
        const tempSelectedCard = { ...selectedCards };
        delete tempSelectedCard[cardName];
        setSelectedCards(tempSelectedCard);

        const tempReportEnum = reportsEnum.filter(
          (report) => !cardReportEnums[cardName].includes(report)
        );
        setReportsEnum(tempReportEnum);
      } else {
        message.warning('You should have at least 1 item selected');
      }
    }
  };

  const menu = (
    <Menu>
      <div className={styles.checkListMenuWrapper}>
        <Menu selectable={false} className={styles.checkListMenu}>
          {Object.keys(cardNames)?.map((cardName, index) => (
            <Menu.Item key={index}>
              <Checkbox
                style={{ width: 200 }}
                onChange={(e) => handleChangeCards(e, cardName)}
                checked={isCardChecked(cardName)}
              >
                {cardNames[cardName]}
              </Checkbox>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </Menu>
  );

  return (
    <div className={styles.headerWrapper}>
      <div>
        <DatePicker.RangePicker
          className={styles.headerDatePicker}
          allowClear={false}
          disabled={[false, false]}
          value={startDate && endDate ? [startDate, endDate] : [null, null]}
          onChange={(val) => handleDatePickerChange(val)}
          disabledDate={(date) => date && date > moment().startOf('day')}
        />
        <Select
          showSearch
          className={styles.headerProductSelect}
          placeholder="Product Name"
          value={productName}
          onChange={(e) => handleSelectChange(e)}
          filterOption={(input, option) => {
            if (option.children) {
              return (
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }
          }}
        >
          {productList?.map((product) => (
            <Select.Option value={product.id} key={product.id}>
              {product.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
        <Button>
          <PlusOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default ReportCardsSectionHeader;
