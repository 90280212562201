import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import EmailVerificationModalContent from './EmailVerificationModalContent';
import EmailVerificationModalSuccess from './EmailVerificationModalSuccess';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmailFetch } from '../ProfileActions';

const EmailVerificationModal = ({ isOpen, onClose, isTimerEnabled }) => {
  const dispatch = useDispatch();
  const [otpCode, setOtpCode] = useState('');

  const { emailVerification } = useSelector((state) => state.account.profile);

  const handleVerifyEmail = () => {
    let verifyEmailBody = {
      otpId: sessionStorage.getItem('otpId'),
      otpCode: otpCode,
    };
    dispatch(verifyEmailFetch(verifyEmailBody));
  };

  const handleCloseVerificationModal = () => {
    onClose();
    setOtpCode('');
  };

  return (
    <Modal
      width={650}
      title="Email Verification"
      centered
      visible={isOpen}
      onOk={handleCloseVerificationModal}
      onCancel={handleCloseVerificationModal}
      footer={[
        emailVerification?.emailVerifiedStatus ? (
          <Button
            key="submit"
            shape="round"
            type="primary"
            onClick={handleCloseVerificationModal}
          >
            Done
          </Button>
        ) : (
          <Button
            loading={emailVerification?.loading}
            key="submit"
            shape="round"
            type="primary"
            onClick={handleVerifyEmail}
            disabled={otpCode === ''}
          >
            Confirm
          </Button>
        ),
      ]}
    >
      {emailVerification?.emailVerifiedStatus ? (
        <EmailVerificationModalSuccess />
      ) : (
        <EmailVerificationModalContent
          otpCode={otpCode}
          setOtpCode={setOtpCode}
          isTimerEnabled={isTimerEnabled}
        />
      )}
    </Modal>
  );
};

export default EmailVerificationModal;
