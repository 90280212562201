import React from 'react';
// import vars from '@Theme/styles/vars.js';
const styles = {
  page: {
    padding: '30px',
  },
};

export default function PagesWrapper({ children }) {
  return <div style={styles.page}>{children}</div>;
}
