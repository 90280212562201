import React, { useEffect, useState } from 'react';
import AdminProductArchivedContract from './AdminProductArchivedContract';
import AdminProductContractForm from './AdminProductContractForm';
import { Alert, Button, Modal, Space, Spin } from 'antd';
import Card from '@Utils/Card';
import { useDispatch, useSelector } from 'react-redux';
import {
  archivedContractListFetch,
  cleareCurrentContract,
  clearErrorsContract,
  contractCleareActiveContract,
  contractCreateFetch,
  contractUpdateSubmitFetch,
  contractViewActiveFetch,
} from '@Redux/slices/admin/adminProductContractSlice';
import { isEmpty } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import { AuthorityProvider, hasAnyAuthority } from '@Utils/AuthorityProvider';
import { contractTemplateListFetch } from '@Redux/slices/admin/contractTemplateSlice';

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: 16,
  },
};
function AdminProductContract() {
  const [contractData, setContractData] = useState();
  const dispatch = useDispatch();
  const activeContract = useSelector(
    (state) => state.admin.contract.activeContract
  );
  const currentContract = useSelector(
    (state) => state.admin.contract.currentContract
  );

  const errorsContract = useSelector(
    (state) => state.admin.contract.errorsContract
  );
  const loading = useSelector((state) => state.admin.contract.loading);
  const archivedContractList = useSelector(
    (state) => state.admin.contract.archivedContractList
  );
  const createLoading = useSelector(
    (state) => state.admin.contract.createLoading
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewMode, setViewMode] = useState(false);

  useEffect(() => {
    if (isModalOpen && !createLoading && !loading && isEmpty(errorsContract)) {
      setIsModalOpen(false);
    }
  }, [createLoading, loading]);
  useEffect(() => {
    dispatch(contractTemplateListFetch({}));
    dispatch(archivedContractListFetch({}));
    dispatch(contractViewActiveFetch());
    return () => {
      dispatch(contractCleareActiveContract());
    };
  }, []);

  const handleOk = () => {
    if (currentContract?.id) {
      dispatch(
        contractUpdateSubmitFetch(currentContract?.id, {
          ...currentContract,
          ...contractData,
        })
      );
      dispatch(cleareCurrentContract());
      dispatch(contractViewActiveFetch());
    } else {
      dispatch(contractCreateFetch(contractData));
      dispatch(contractViewActiveFetch());
    }
    dispatch(clearErrorsContract());
  };
  const handleCancel = () => {
    dispatch(clearErrorsContract());
    dispatch(cleareCurrentContract());
    setIsModalOpen(false);
  };
  const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;

  return (
    <>
      {loading ? (
        <Spin className="mainCard__loading" indicator={loadingSpin} />
      ) : isEmpty(activeContract) ? (
        <Alert
          message="There is no active contract"
          type="warning"
          action={
            <Space>
              <Button
                className="w-200"
                style={{ marginRight: 40 }}
                shape="round"
                size={'large'}
                type="primary"
                onClick={() => {
                  setViewMode(false);
                  dispatch(clearErrorsContract());
                  setIsModalOpen(true);
                }}
              >
                New
              </Button>
            </Space>
          }
          closable
        />
      ) : (
        <Card title="Product Contract">
          <AdminProductContractForm
            contract={activeContract}
            setContractData={setContractData}
          />
          <div style={styles.row}>
            <Button
              className="w-200"
              style={{ marginRight: 40 }}
              shape="round"
              size={'large'}
              type="primary"
              onClick={() => {
                setViewMode(false);
                dispatch(clearErrorsContract());
                setIsModalOpen(true);
              }}
              disabled={
                !hasAnyAuthority(AuthorityProvider.ROLE_CONTRACT_CREATE)
              }
            >
              New
            </Button>
            <Button
              className="w-200"
              style={{ marginRight: 40 }}
              shape="round"
              size={'large'}
              type="primary"
              onClick={() => {
                dispatch(clearErrorsContract());
                dispatch(
                  contractUpdateSubmitFetch(activeContract?.id, {
                    ...activeContract,
                    ...contractData,
                  })
                );
              }}
              disabled={
                !hasAnyAuthority(AuthorityProvider.ROLE_CONTRACT_UPDATE)
              }
            >
              Edit
            </Button>
          </div>
        </Card>
      )}
      {isModalOpen && (
        <Modal
          title="Create New Contract"
          width={900}
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleOk}
              disabled={viewMode}
            >
              Submit
            </Button>,
          ]}
        >
          <AdminProductContractForm
            setContractData={setContractData}
            contract={currentContract}
            viewMode={viewMode}
          />
        </Modal>
      )}
      {archivedContractList.length > 0 ? (
        <AdminProductArchivedContract
          dataList={archivedContractList}
          setIsModalOpen={setIsModalOpen}
          setViewMode={setViewMode}
        />
      ) : (
        ''
      )}
    </>
  );
}

export default AdminProductContract;
