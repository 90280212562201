import React from 'react';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import magLogo from '../../../../theme/icons/mag.png';
import kaveLogo from '../../../../theme/icons/kave.png';
import Farapayamak from '../../../../theme/icons/farapayamak.png';
import Mediana from '../../../../theme/icons/mediana.png';
import NikSMS from '../../../../theme/icons/niksms.jpg';
import Rahyab from '../../../../theme/icons/rahyab.png';
import twilioLogo from '../../../../theme/icons/twilio.png';
import pishgamrayan from '../../../../theme/icons/pishgamrayan.png';
import privateSsp from '../../../../theme/icons/private ssp.svg';
import mci from '../../../../theme/icons/mci.png';
import { isEmpty, isNil, cloneDeep } from 'lodash';
import { Button, Input, Modal, Select, Spin, Form, InputNumber } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import SmsProviders, { InheritableSmsProviders } from '../model/SmsProviders';
import texts from '../../../../utils/texts';
import { hasAnyAuthority, AuthorityProvider } from '@Utils/AuthorityProvider';
import { LoadingOutlined } from '@ant-design/icons';
import style from '../../channel.module.less';
import Card from '@Utils/Card';
const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    fontWeight: '600',
    flex: 1,
  },
  editFieldContainer: {
    flex: 1,
  },
  editField: {
    marginLeft: 20,
    width: 350,
  },
  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    display: 'flex',
    flexDirection: 'row',
  },
  infoBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 30px',
    borderBottom: '1px solid #e7e8e9',
    backgroundColor: '#fdfdfd',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dataRowContainer: {
    padding: 30,
  },
};

const RenderDocsLink = ({ show }) => {
  if (!show) return null;
  return (
    <Button
      key="link"
      className={`text-title-md ${style.btnDocuments}`}
      type="link"
      href="#"
    >
      <u>View Documentation</u>
    </Button>
  );
};

class SmsChannelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalId: null,
      vendor: null,
      name: '',
      deliveryByVendor: null,
    };

    this.permissions = {
      createOrUpdate: hasAnyAuthority(
        AuthorityProvider.ROLE_PRODUCT_CHANNEL_SMS_UPDATE,
        AuthorityProvider.ROLE_PRODUCT_CHANNEL_SMS_CREATE
      ),
      delete: hasAnyAuthority(
        AuthorityProvider.ROLE_PRODUCT_CHANNEL_SMS_DELETE
      ),
      view: hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CHANNEL_SMS_VIEW),
    };
  }

  componentDidMount() {
    this.props.smsChannelListFetch(this.props.pageRequest);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingUpdateSubmit && !this.props.loadingUpdateSubmit) {
      this.setState({ modalId: null });
    }
    if (prevProps.loadingCreate && !this.props.loadingCreate) {
      this.setState({ modalId: null });
    }
  }

  addProvider = (vendor) => {
    if (this.permissions?.createOrUpdate) {
      this.props.smsChannelChangeCurrent({
        name: null,
        domain: null,
        apiKey: null,
        password: null,
        vendor,
        sender: null,
        username: null,
        customSendHeaders: [],
        deliveryByVendor: null,
        token: null,
      });
      this.setState({ modalId: true });
    }
  };
  searchItem = () => {
    this.props.smsChanneSearchlListFetch({
      ...this.props.pageRequest,
      page: 0,
      name: this.state.name,
    });
  };
  renderSystem = (x) => {
    if (isNil(x)) {
      return '--';
    }
    return x ? 'System' : 'Custom';
  };

  handleChangeSearch = (name) => {
    this.setState({ name });
    if (name === '' || name === null) {
      this.props.smsChannelListFetch({
        ...this.props.pageRequest,
        page: 0,
        name: null,
      });
    }
  };

  handleChangeProvider = (vendor) => {
    this.setState({ vendor });
    this.props.smsChannelListFetch({
      ...this.props.pageRequest,
      page: 0,
      vendor,
    });
  };

  openModal = (x) => {
    this.props.smsChannelUpdateFetch(x.id, this?.permissions?.createOrUpdate);
    this.setState({ modalId: x.id });
  };

  onOkModal = () => {
    if (this.state.modalId === true) {
      this.props.smsChannelCreateFetch(
        this.props.currentSmsChannel,
        this.props.pageRequest
      );
    } else {
      this.props.smsChannelUpdateSubmitFetch(
        this.state.modalId,
        this.props.currentSmsChannel,
        this.props.pageRequest
      );
    }
  };

  setAttribute = (key, value) => {
    const currentSmsChannel = cloneDeep(this.props.currentSmsChannel);
    currentSmsChannel[key] = value;
    this.props.smsChannelChangeCurrent(currentSmsChannel);
  };
  addKeyValue = () => {
    let headers = this.props.currentSmsChannel.customSendHeaders;
    headers.push({ key: '', value: '' });
    this.props.smsChannelChangeCurrent({
      ...this.props.currentSmsChannel,
      customSendHeaders: headers,
    });
  };
  handleRemoveKeyValue = (keyValueIndex) => {
    let headers = this.props.currentSmsChannel.customSendHeaders;
    headers = headers.filter((_, i) => i !== keyValueIndex);
    this.props.smsChannelChangeCurrent({
      ...this.props.currentSmsChannel,
      customSendHeaders: headers,
    });
  };

  handleChangeKey = (keyValueIndex, value) => {
    let { customSendHeaders } = this.props.currentSmsChannel;
    customSendHeaders = [
      ...customSendHeaders.slice(0, keyValueIndex),
      {
        ...customSendHeaders[keyValueIndex],
        key: value,
      },
      ...customSendHeaders.slice(keyValueIndex + 1),
    ];
    this.props.smsChannelChangeCurrent({
      ...this.props.currentSmsChannel,
      customSendHeaders: customSendHeaders,
    });
  };

  handleChangeValue = (keyValueIndex, value) => {
    let { customSendHeaders } = this.props.currentSmsChannel;
    customSendHeaders = [
      ...customSendHeaders.slice(0, keyValueIndex),
      {
        ...customSendHeaders[keyValueIndex],
        value: value,
      },
      ...customSendHeaders.slice(keyValueIndex + 1),
    ];
    this.props.smsChannelChangeCurrent({
      ...this.props.currentSmsChannel,
      customSendHeaders: customSendHeaders,
    });
  };

  providerOneOf = (providers) =>
    providers.includes(this.props.currentSmsChannel.vendor) ||
    providers.includes(this.props.currentSmsChannel.deliveryByVendor);

  // eslint-disable-next-line complexity
  renderContent = () => {
    return this.props.loadingUpdate ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin style={{ display: 'flex', alignSelf: 'center' }} />
      </div>
    ) : (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>{'SSP'}</div>
            <div style={styles.editFieldContainer}>
              <Select
                value={
                  this.props.currentSmsChannel &&
                  this.props.currentSmsChannel.vendor
                    ? this.props.currentSmsChannel.vendor
                    : null
                }
                style={styles.editField}
                size="large"
                disabled={!this?.permissions?.createOrUpdate}
                onChange={(vendor) =>
                  this.props.smsChannelChangeCurrent({
                    ...this.props.currentSmsChannel,
                    vendor,
                  })
                }
              >
                {Object.keys(SmsProviders).map((item) => (
                  <Select.Option value={item} key={item}>
                    {SmsProviders[item]}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        }
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>Name</div>
          <div style={styles.editFieldContainer}>
            <Input
              disabled={!this?.permissions?.createOrUpdate}
              onChange={(e) =>
                this.props.smsChannelChangeCurrent({
                  ...this.props.currentSmsChannel,
                  name: e.target.value,
                })
              }
              value={
                this.props.currentSmsChannel &&
                this.props.currentSmsChannel.name
                  ? this.props.currentSmsChannel.name
                  : ''
              }
              size="large"
              style={styles.editField}
            />
          </div>
        </div>
        {this.providerOneOf(['PRIVATE']) && (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>URL</div>
            <div style={styles.editFieldContainer}>
              <Input
                disabled={!this?.permissions?.createOrUpdate}
                onChange={(e) =>
                  this.props.smsChannelChangeCurrent({
                    ...this.props.currentSmsChannel,
                    customSendUrl: e.target.value,
                  })
                }
                value={
                  this.props.currentSmsChannel &&
                  this.props.currentSmsChannel.customSendUrl
                    ? this.props.currentSmsChannel.customSendUrl
                    : ''
                }
                size="large"
                style={styles.editField}
              />
            </div>
          </div>
        )}
        {this.providerOneOf(['PRIVATE']) && (
          <>
            <div style={styles.editContainer}>
              <div style={styles.editLabel}>Header (s)</div>
              <div style={styles.editFieldContainer}>
                {this.props.currentSmsChannel.customSendHeaders.map(
                  (item, keyValueIndex) => {
                    const keyCyData = 'push-key' + (keyValueIndex + 1);
                    const valueCyData = 'push-value' + (keyValueIndex + 1);
                    const removeKeyValueCyData =
                      'remove-key-value' + (keyValueIndex + 1);
                    return (
                      <div key={keyValueIndex} id="keyValue">
                        <Form.Item>
                          <Input
                            placeholder="Key"
                            data-cy={keyCyData}
                            value={item && item.key ? item.key : ''}
                            onChange={(e) =>
                              this.handleChangeKey(
                                keyValueIndex,
                                e.target.value
                              )
                            }
                          />
                        </Form.Item>
                        <span style={{ margin: '15px 10px' }}>=</span>
                        <Form.Item>
                          <Input
                            placeholder="Value"
                            data-cy={valueCyData}
                            value={item && item.value ? item.value : ''}
                            onChange={(e) =>
                              this.handleChangeValue(
                                keyValueIndex,
                                e.target.value
                              )
                            }
                          />
                        </Form.Item>

                        <i
                          data-cy={removeKeyValueCyData}
                          onClick={() => {
                            this.handleRemoveKeyValue(keyValueIndex);
                          }}
                          className="fl-dark fl-delete pointer"
                          style={{
                            fontSize: 24,
                            color: 'rgb(187, 188, 193)',
                            margin: '15px 10px',
                          }}
                        />
                      </div>
                    );
                  }
                )}
                <Button
                  style={{ width: 350 }}
                  block
                  onClick={() => this.addKeyValue()}
                >
                  Add Key-Value
                </Button>
              </div>
            </div>

            <div style={styles.editContainer}>
              <div style={styles.editLabel}>{'Delivery SSP'}</div>
              <div style={styles.editFieldContainer}>
                <Select
                  value={
                    this.props.currentSmsChannel &&
                    this.props.currentSmsChannel.deliveryByVendor
                      ? this.props.currentSmsChannel.deliveryByVendor
                      : null
                  }
                  style={styles.editField}
                  size="large"
                  disabled={!this?.permissions?.createOrUpdate}
                  placeholder={'No Delivery'}
                  onChange={(deliveryByVendor) => {
                    let value;
                    if (deliveryByVendor === 'NO_DELIVERY') {
                      value = null;
                    } else {
                      value = deliveryByVendor;
                    }
                    this.props.smsChannelChangeCurrent({
                      ...this.props.currentSmsChannel,
                      deliveryByVendor: value,
                      domain: null,
                      apiKey: null,
                      password: null,
                      sender: null,
                      username: null,
                      token: null,
                    });
                  }}
                >
                  {Object.keys(InheritableSmsProviders).map((item) => (
                    <Select.Option value={item} key={item}>
                      {InheritableSmsProviders[item]}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </>
        )}
        {this.providerOneOf([
          'MAGFA',
          'KAVE_NEGAR',
          'FARA_PAYAMAK',
          'RAHYAB',
          'PISHGAMRAYAN',
          'NIK_SMS',
          'TWILIO',
          'MEDIANA',
          'PARDIS',
        ]) &&
          !this.providerOneOf(['PRIVATE']) && (
            <div style={styles.editContainer}>
              <div style={styles.editLabel}>Sender No.</div>
              <div style={styles.editFieldContainer}>
                <Input
                  disabled={!this?.permissions?.createOrUpdate}
                  onChange={(e) =>
                    this.props.smsChannelChangeCurrent({
                      ...this.props.currentSmsChannel,
                      sender: e.target.value,
                    })
                  }
                  value={
                    this.props.currentSmsChannel &&
                    this.props.currentSmsChannel.sender
                      ? this.props.currentSmsChannel.sender
                      : ''
                  }
                  size="large"
                  style={styles.editField}
                />
              </div>
            </div>
          )}
        {this.props.currentSmsChannel && this.providerOneOf(['MAGFA']) && (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>Domain</div>
            <div style={styles.editFieldContainer}>
              <Input
                disabled={!this?.permissions?.createOrUpdate}
                onChange={(e) =>
                  this.props.smsChannelChangeCurrent({
                    ...this.props.currentSmsChannel,
                    domain: e.target.value,
                  })
                }
                value={
                  this.props.currentSmsChannel &&
                  this.props.currentSmsChannel.domain
                    ? this.props.currentSmsChannel.domain
                    : ''
                }
                size="large"
                style={styles.editField}
              />
            </div>
          </div>
        )}
        {this.props.currentSmsChannel &&
          this.providerOneOf([
            'MAGFA',
            'FARA_PAYAMAK',
            'RAHYAB',
            'NIK_SMS',
            'PARDIS',
          ]) && (
            <div style={styles.editContainer}>
              <div style={styles.editLabel}>Username</div>
              <div style={styles.editFieldContainer}>
                <Input
                  disabled={!this?.permissions?.createOrUpdate}
                  onChange={(e) =>
                    this.props.smsChannelChangeCurrent({
                      ...this.props.currentSmsChannel,
                      username: e.target.value,
                    })
                  }
                  value={
                    this.props.currentSmsChannel &&
                    this.props.currentSmsChannel.username
                      ? this.props.currentSmsChannel.username
                      : ''
                  }
                  size="large"
                  style={styles.editField}
                />
              </div>
            </div>
          )}
        {this.props.currentSmsChannel &&
          this.providerOneOf([
            'MAGFA',
            'FARA_PAYAMAK',
            'RAHYAB',
            'NIK_SMS',
            'PARDIS',
          ]) && (
            <div style={styles.editContainer}>
              <div style={styles.editLabel}>Password</div>
              <div style={styles.editFieldContainer}>
                <Input.Password
                  disabled={!this?.permissions?.createOrUpdate}
                  autoComplete="new-password"
                  onChange={(e) =>
                    this.props.smsChannelChangeCurrent({
                      ...this.props.currentSmsChannel,
                      password: e.target.value,
                    })
                  }
                  value={
                    this?.permissions?.createOrUpdate
                      ? this.props?.currentSmsChannel?.password ||
                        (this.props?.currentSmsChannel?.createdDate && '***')
                      : '***'
                  }
                  size="large"
                  style={styles.editField}
                />
              </div>
            </div>
          )}
        {this.props.currentSmsChannel &&
          this.providerOneOf(['KAVE_NEGAR', 'MEDIANA']) && (
            <div style={styles.editContainer}>
              <div style={styles.editLabel}>API Key</div>
              <div style={styles.editFieldContainer}>
                <Input.Password
                  autoComplete="new-password"
                  disabled={!this?.permissions?.createOrUpdate}
                  onChange={(e) =>
                    this.props.smsChannelChangeCurrent({
                      ...this.props.currentSmsChannel,
                      apiKey: e.target.value,
                    })
                  }
                  value={
                    this?.permissions?.createOrUpdate
                      ? this.props?.currentSmsChannel?.apiKey ||
                        (this.props?.currentSmsChannel?.createdDate && '***')
                      : '***'
                  }
                  size="large"
                  style={styles.editField}
                />
              </div>
            </div>
          )}
        {this.props.currentSmsChannel && this.providerOneOf(['TWILIO']) && (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>Account SID</div>
            <div style={styles.editFieldContainer}>
              <Input
                disabled={!this?.permissions?.createOrUpdate}
                onChange={(e) =>
                  this.props.smsChannelChangeCurrent({
                    ...this.props.currentSmsChannel,
                    sid: e.target.value,
                  })
                }
                value={
                  this.props.currentSmsChannel &&
                  this.props.currentSmsChannel.sid
                    ? this.props.currentSmsChannel.sid
                    : ''
                }
                size="large"
                style={styles.editField}
              />
            </div>
          </div>
        )}
        {this.props.currentSmsChannel &&
          this.providerOneOf(['PISHGAMRAYAN', 'TWILIO']) && (
            <div style={styles.editContainer}>
              <div style={styles.editLabel}>Auth Token</div>
              <div style={styles.editFieldContainer}>
                <Input
                  disabled={!this?.permissions?.createOrUpdate}
                  onChange={(e) =>
                    this.props.smsChannelChangeCurrent({
                      ...this.props.currentSmsChannel,
                      token: e.target.value,
                    })
                  }
                  value={
                    this?.permissions?.createOrUpdate
                      ? this.props?.currentSmsChannel?.token ||
                        (this.props?.currentSmsChannel?.createdDate && '***')
                      : '***'
                  }
                  size="large"
                  style={styles.editField}
                />
              </div>
            </div>
          )}
        {this.props.currentSmsChannel && this.providerOneOf(['TWILIO']) && (
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>Messaging Service Id</div>
            <div style={styles.editFieldContainer}>
              <Input
                disabled={!this?.permissions?.createOrUpdate}
                onChange={(e) =>
                  this.props.smsChannelChangeCurrent({
                    ...this.props.currentSmsChannel,
                    messagingServiceId: e.target.value,
                  })
                }
                value={
                  this.props.currentSmsChannel &&
                  this.props.currentSmsChannel.messagingServiceId
                    ? this.props.currentSmsChannel.messagingServiceId
                    : ''
                }
                size="large"
                style={styles.editField}
              />
            </div>
          </div>
        )}
        {this.props.currentSmsChannel &&
          this.providerOneOf([
            'MAGFA',
            'KAVE_NEGAR',
            'RAHYAB',
            'PISHGAMRAYAN',
            'NIK_SMS',
            'PRIVATE',
          ]) && (
            <div style={styles.editContainer}>
              <div style={styles.editLabel}>Batch Size</div>
              <div style={styles.editFieldContainer}>
                <InputNumber
                  disabled={!this?.permissions?.createOrUpdate}
                  onChange={(e) =>
                    this.props.smsChannelChangeCurrent({
                      ...this.props.currentSmsChannel,
                      batchSize: e,
                    })
                  }
                  value={
                    this.props.currentSmsChannel &&
                    this.props.currentSmsChannel.batchSize
                      ? this.props.currentSmsChannel.batchSize
                      : ''
                  }
                  style={styles.editField}
                  min={2}
                />
              </div>
            </div>
          )}
      </div>
    );
  };

  renderModalTitle = () => {
    if (this.state.modalId === true) {
      return 'Add New SSP';
    }
    return 'Edit SSP';
  };

  renderModal = () => {
    return (
      <Modal
        width={650}
        title={this.renderModalTitle()}
        centered
        visible={!isNil(this.state.modalId)}
        onOk={() => this.setState({ modalId: null })}
        onCancel={() => this.setState({ modalId: null })}
        footer={[
          <Button
            shape="round"
            key="cancel"
            onClick={() => this.setState({ modalId: null })}
          >
            Cancel
          </Button>,
          <Button
            loading={this.props.loadingUpdateSubmit || this.props.loadingCreate}
            key="submit"
            shape="round"
            type="primary"
            onClick={() => this.onOkModal()}
            disabled={!this?.permissions?.createOrUpdate}
          >
            Save
          </Button>,
          // eslint-disable-next-line react/jsx-key
          <RenderDocsLink show={this.providerOneOf(['PRIVATE'])} />,
        ]}
      >
        <div>{this.renderContent()}</div>
      </Modal>
    );
  };

  render() {
    const columnList = [
      {
        sorter: false,
        title: 'SSP',
        dataIndex: 'vendor',
        key: 'vendor',
        width: 300,
        render: (x) => SmsProviders[x],
        // ...getColumnTextSearchProps('title'),
      },
      {
        sorter: false,
        title: 'configuration name',
        dataIndex: 'name',
        key: 'name',
        width: 300,
        // ...getColumnTextSearchProps('name'),
      },
      {
        title: 'action',
        dataIndex: '',
        key: '-action-',
        width: '5%',
        render: (x) =>
          defaultActions(
            x,
            {
              action: () => this.openModal(x),
              title: this.permissions?.createOrUpdate ? 'Edit' : 'View',
              enabled: this?.permissions?.view,
              disablePopConfirm: true,
            },
            // { link: 'copy/' + x.id, title: 'Duplicate', enabled: hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_EVENT_CREATE) },
            {
              action: (id) =>
                this.props.smsChannelDeleteFetch(id, this.props.pageRequest),
              title: 'Delete',
              enabled: this?.permissions?.delete,
            },
            {
              action: (id) => this.props.smsChannelRefreshTokenFetch(id),
              title: 'Refresh Token',
              enabled: true,
            }
          ),
      },
    ];

    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    return (
      <div>
        <Card
          title="Your SMS Service Providers (SSP)"
          loading={this.props.loadingList}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '0 16px',
            }}
          >
            <Input
              loading={true}
              allowClear={this.props.loadingSearchList ? false : true}
              placeholder={'Search by configuration name'}
              onPressEnter={this.searchItem}
              onChange={(e) => this.handleChangeSearch(e.target.value)}
              value={this.state.name}
              style={{ width: 300, borderRadius: 5 }}
              suffix={
                isEmpty(this.state.name) ? (
                  <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                ) : (
                  this.props.loadingSearchList && (
                    <div style={styles.inputLoadingSpinStyle}>
                      <Spin indicator={inputLoadingSpin} />
                    </div>
                  )
                )
              }
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Select
                onChange={(vendor) => this.handleChangeProvider(vendor)}
                value={this.state.vendor}
                defaultValue="all"
                style={{ width: 120 }}
                className={'chart-selector'}
                bordered={false}
              >
                <Select.Option value={null}>All Providers</Select.Option>
                {Object.keys(SmsProviders).map((item) => (
                  <Select.Option value={item} key={item}>
                    {SmsProviders[item]}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <ListBase
            onChange={this.props.smsChannelListFetch}
            page={this.props.page}
            pageRequest={this.props.pageRequest}
            columns={columnList}
            // onClick={(row) => this.viewItem(row.id)}
            createLink={null}
          />
        </Card>
        <Card
          styles={{ marginTop: 20 }}
          title="Available SMS Service Providers (SSP)"
        >
          <div style={styles.dataRowContainer}>
            <p style={{ color: '#767577' }}>
              Select a SSP below to configure and add to your project
            </p>
            <div className={style.serviceProviderContainer}>
              {texts.id !== 'um' ? (
                <>
                  <div
                    onClick={() => {
                      this.addProvider('KAVE_NEGAR');
                      this.deleteStatePrivate?.();
                    }}
                    className={style.serviceProviderBox}
                  >
                    <img src={kaveLogo} />
                    <span>Kavenegar</span>
                  </div>
                  <div
                    onClick={() => {
                      this.addProvider('MAGFA');
                      this.deleteStatePrivate?.();
                    }}
                    className={style.serviceProviderBox}
                  >
                    <img src={magLogo} />
                    <span>Magfa</span>
                  </div>
                  <div
                    onClick={() => {
                      this.addProvider('FARA_PAYAMAK');
                      this.deleteStatePrivate?.();
                    }}
                    className={style.serviceProviderBox}
                  >
                    <img src={Farapayamak} />
                    <span>Farapayamak</span>
                  </div>
                  <div
                    onClick={() => {
                      this.addProvider('RAHYAB');
                      this.deleteStatePrivate?.();
                    }}
                    className={style.serviceProviderBox}
                  >
                    <img src={Rahyab} />
                    <span>Rahyab</span>
                  </div>
                  <div
                    onClick={() => {
                      this.addProvider('MEDIANA');
                      this.deleteStatePrivate?.();
                    }}
                    className={style.serviceProviderBox}
                  >
                    <img src={Mediana} />
                    <span>Mediana</span>
                  </div>
                </>
              ) : null}
              <div
                onClick={() => {
                  this.addProvider('TWILIO');
                  this.deleteStatePrivate?.();
                }}
                className={style.serviceProviderBox}
              >
                <img src={twilioLogo} />
                <span>Twilio</span>
              </div>
              <div
                onClick={() => {
                  this.addProvider('PISHGAMRAYAN');
                  this.deleteStatePrivate?.();
                }}
                className={style.serviceProviderBox}
              >
                <img src={pishgamrayan} />
                <span>Pishgamrayan</span>
              </div>

              <div
                onClick={() => {
                  this.addProvider('NIK_SMS');
                  this.deleteStatePrivate?.();
                }}
                className={style.serviceProviderBox}
              >
                <img src={NikSMS} />
                <span>NIK SMS</span>
              </div>
              <div
                onClick={() => {
                  this.addProvider('PARDIS');
                }}
                className={style.serviceProviderBox}
              >
                <img src={mci} />
                <span>Pardis</span>
              </div>

              <div
                onClick={() => {
                  this.addProvider('PRIVATE');
                }}
                className={style.serviceProviderBox}
              >
                <img src={privateSsp} />
                <span>Private SSP</span>
              </div>
            </div>
          </div>
        </Card>

        {this.renderModal()}
      </div>
    );
  }
}

export default SmsChannelComponent;
