import {
  JOURNEY_PAGE_CHANGE_CURRENT,
  JOURNEY_LIST_FETCH,
  JOURNEY_LIST_FETCH_FAIL,
  JOURNEY_LIST_FETCH_SUCCESS,
  JOURNEY_DELETE_FETCH,
  JOURNEY_DELETE_FETCH_FAIL,
  JOURNEY_DELETE_FETCH_SUCCESS,
  JOURNEY_CREATE,
  JOURNEY_CREATE_SUCCESS,
  JOURNEY_CREATE_FAIL,
  COMMUNICATION_UPDATE_JOURNEY_SUCCESS,
  COMMUNICATION_UPDATE_JOURNEY,
  COMMUNICATION_UPDATE_JOURNEY_FAIL,
  COMMUNICATION_UPDATE_SUBMIT_JOURNEY,
  COMMUNICATION_UPDATE_SUBMIT_JOURNEY_SUCCESS,
  COMMUNICATION_UPDATE_SUBMIT_JOURNEY_FAIL,
  COMMUNICATION_UPDATE_STEPS_JOURNEY_SUCCESS,
  JOURNEY_CHANGE_CURRENT,
  JOURNEY_LIST_ATTRIBUTES_FETCH,
  JOURNEY_LIST_ATTRIBUTES_FETCH_SUCCESS,
  JOURNEY_LIST_ATTRIBUTES_FETCH_FAIL,
  JOURNEY_LIST_EVENTS_FETCH,
  JOURNEY_LIST_EVENTS_FETCH_SUCCESS,
  JOURNEY_LIST_EVENTS_FETCH_FAIL,
  JOURNEY_LIST_BUSINESS_EVENTS_FETCH,
  JOURNEY_LIST_BUSINESS_EVENTS_FETCH_FAIL,
  JOURNEY_LIST_BUSINESS_EVENTS_FETCH_SUCCESS,
  JOURNEY_LIST_SEGMENTS_FETCH,
  JOURNEY_LIST_SEGMENTS_FETCH_SUCCESS,
  JOURNEY_LIST_SEGMENTS_FETCH_FAIL,
  JOURNEY_REPORT_OVERVIEW_SUCCESS,
  JOURNEY_REPORT_OVERVIEW_FAIL,
  JOURNEY_REPORT_OVERVIEW,
  JOURNEY_LANUCH,
  JOURNEY_LANUCH_FAIL,
  JOURNEY_LANUCH_SUCCESS,
  JOURNEY_COPY_FETCH_FAIL,
  JOURNEY_COPY_FETCH_SUCCESS,
  JOURNEY_COPY_FETCH,
  JOURNEY_LIST_TAGS_FETCH,
  JOURNEY_LIST_TAGS_FETCH_SUCCESS,
  JOURNEY_LIST_TAGS_FETCH_FAIL,
  JOURNEY_VIEW_FETCH,
  JOURNEY_VIEW_FETCH_SUCCESS,
  JOURNEY_VIEW_FETCH_FAIL,
  JOURNEY_STOP,
  JOURNEY_STOP_SUCCESS,
  JOURNEY_STOP_FAIL,
  JOURNEY_PAUSE,
  JOURNEY_PAUSE_SUCCESS,
  JOURNEY_PAUSE_FAIL,
  JOURNEY_REPORT_LIVE,
  JOURNEY_REPORT_LIVE_SUCCESS,
  JOURNEY_REPORT_LIVE_FAIL,
  JOURNEY_TEST_API,
  JOURNEY_TEST_API_SUCCESS,
  JOURNEY_TEST_API_FAIL,
  JOURNEY_REPORT_COMMUNICATION_FETCH_SUCCESS,
  JOURNEY_REPORT_COMMUNICATION_FETCH_FAIL,
  JOURNEY_REPORT_COMMUNICATION_FETCH,
  JOURNEY_REPORT_ENTER_OR_EXIT_FETCH_SUCCESS,
  JOURNEY_REPORT_ENTER_OR_EXIT_FETCH_FAIL,
  JOURNEY_REPORT_ENTER_OR_EXIT_FETCH,
  JOURNEY_LIST_SEARCH_FETCH_FAIL,
  JOURNEY_LIST_SEARCH_FETCH_SUCCESS,
  JOURNEY_LIST_SEARCH_FETCH,
  JOURNEY_LANUCH_CLEAR,
  JOURNEY_ENGAGED_USER_FETCH,
  JOURNEY_ENGAGED_USER_FETCH_SUCCESS,
  JOURNEY_ENGAGED_USER_FETCH_FAIL,
  JOURNEY_ARCHIVE_FETCH,
  JOURNEY_ARCHIVE_FETCH_SUCCESS,
  JOURNEY_ARCHIVE_FETCH_FAIL,
  JOURNEY_ACTIVE_FETCH,
  JOURNEY_ACTIVE_FETCH_FAIL,
  JOURNEY_ACTIVE_FETCH_SUCCESS,
  JOURNEY_BUSINESS_EVENTS_REPORT_FETCH,
  JOURNEY_BUSINESS_EVENTS_REPORT_FETCH_FAIL,
  JOURNEY_BUSINESS_EVENTS_REPORT_FETCH_SUCCESS,
  JOURNEY_TEMPLATE_NAMES_LIST_SUCCESS, JOURNEY_TEMPLATE_NAMES_LIST_FETCH
} from "./JourneyConstants";
// import {
//   COMMUNICATION_DELETE_FETCH,
//   COMMUNICATION_DELETE_FETCH_FAIL,
//   COMMUNICATION_DELETE_FETCH_SUCCESS,
// } from '../communication/CommunicationConstants';
const initialState = {
  loadingListTags: false,
  loadingSearchList: false,
  listTags: [],
  loadingOverview: false,
  loadingJourneyCommunicationReport: false,
  journeyCommunicationReportRequest: null,
  loadingJourneyEnterOrExit: false,
  loadingJourneyDetails: false,
  loadingList: false,
  loadingDelete: false,
  loadingDuplicate: false,
  loadingUpdateJourney: false,
  loadingCreateJourney: false,
  loadingSubmitUpdateJourney: false,
  loadingTemplateNamesList: false,

  errorCreateJourney: [],
  errorUpdateJourney: [],
  errorUpdateSubmitJourney: [],
  errorList: [],
  errorDelete: [],
  errorDuplicate: [],
  errorOverview: [],
  errorEnterOrExit: [],
  errorJourneyDetails: [],

  successUpdateSubmitJourney: null,
  successUpdateJourney: null,
  successCreateJourney: null,

  overviewData: {},
  journeyDetails: {},
  journeyCommunicationReportData: {},
  journeyCommunicationEnterOrExit: {},
  currentJourney: {},
  page: {},
  pageRequest: {},
  journeyEngagedUserPageRequest: {},
  journeyEngagedUser: {},
  errorsJourneyEngagedUser: [],
  loadingJourneyEngagedUser: false,
  loadingSegmentReport: false,
  loadingActivate: false,
  loadingArchive: false,
  archiveSuccess: false,
  activeSuccess: false,

  loadingBusinessEventsList: false,
  businessEvents: [],
  errorBusinessEvents: [],

  loadingBusinessEventsReport: false,
  businessEventsReport: [],
  errorBusinessEventsReport: [],

  templateNames: [],
  segments: [],
};

export default function JourneyStateReducer(state = initialState, action) {
  switch (action.type) {
    case JOURNEY_CREATE:
      return { ...state, loadingCreateJourney: true };
    case JOURNEY_CREATE_SUCCESS:
      return {
        ...state,
        loadingCreateJourney: false,
        redirect: action.redirect,
        successCreateJourney: action.payload,
        publishAfterCreate: action.publishAfterCreate,
      };
    case JOURNEY_CREATE_FAIL:
      return {
        ...state,
        loadingCreateJourney: false,
        errorCreateJourney: action.payload,
      };

    case JOURNEY_LIST_TAGS_FETCH:
      return { ...state, loadingListTags: true };
    case JOURNEY_LIST_TAGS_FETCH_SUCCESS:
      return { ...state, loadingListTags: false, listTags: action.payload };
    case JOURNEY_LIST_TAGS_FETCH_FAIL:
      return { ...state, loadingListTags: false };

    case JOURNEY_REPORT_ENTER_OR_EXIT_FETCH:
      return {
        ...state,
        loadingJourneyEnterOrExit: true,
        errorEnterOrExit: [],
      };
    case JOURNEY_REPORT_ENTER_OR_EXIT_FETCH_SUCCESS:
      return {
        ...state,
        journeyCommunicationEnterOrExit: action.payload,
        loadingJourneyEnterOrExit: false,
      };
    case JOURNEY_REPORT_ENTER_OR_EXIT_FETCH_FAIL:
      return {
        ...state,
        loadingJourneyEnterOrExit: false,
        errorEnterOrExit: action.payload,
      };

    case JOURNEY_VIEW_FETCH:
      return { ...state, loadingJourneyDetails: true };
    case JOURNEY_VIEW_FETCH_SUCCESS:
      return {
        ...state,
        loadingJourneyDetails: false,
        journeyDetails: action.payload,
      };
    case JOURNEY_VIEW_FETCH_FAIL:
      return {
        ...state,
        loadingJourneyDetails: false,
        errorJourneyDetails: action.payload,
      };

    case COMMUNICATION_UPDATE_JOURNEY:
      return {
        ...state,
        loadingUpdateJourney: true,
        successUpdateJourney: null,
      };
    case COMMUNICATION_UPDATE_JOURNEY_SUCCESS:
      // hameye requestaro migirim
      return {
        ...state,
        loadingUpdateJourney: false,
        successUpdateJourney: action.payload,
        currentJourney: action.payload,
      };
    case COMMUNICATION_UPDATE_JOURNEY_FAIL:
      return {
        ...state,
        loadingUpdateJourney: false,
        errorUpdateJourney: action.payload,
      };

    case COMMUNICATION_UPDATE_SUBMIT_JOURNEY:
      return {
        ...state,
        loadingUpdateSubmitJourney: true,
        successUpdateSubmitJourney: null,
        showNotificationMessage: null,
      };
    case COMMUNICATION_UPDATE_SUBMIT_JOURNEY_SUCCESS:
      return {
        ...state,
        loadingUpdateSubmitJourney: false,
        successUpdateJourney: action.payload,
        redirect: action.redirect,
        successUpdateSubmitJourney: true,
        showNotificationMessage: action.showNotificationMessage,
      };
    case COMMUNICATION_UPDATE_STEPS_JOURNEY_SUCCESS:
      return {
        ...state,
        successUpdateJourney: {
          ...state.successUpdateJourney,
          steps: action.payload,
        },
        successUpdateSubmitJourney: true,
      };
    case COMMUNICATION_UPDATE_SUBMIT_JOURNEY_FAIL:
      return {
        ...state,
        loadingUpdateSubmitJourney: false,
        errorUpdateSubmitJourney: action.payload,
      };

    case JOURNEY_CHANGE_CURRENT:
      return { ...state, currentJourney: action.payload };

    case JOURNEY_PAGE_CHANGE_CURRENT:
      return { ...state, pageRequest: action.payload };

    case JOURNEY_LIST_FETCH:
      return {
        ...state,
        pageRequest: action.payload,
        loadingList: true,
        successUpdateJourney: null,
        successCreateJourney: null,
        successUpdateSubmitJourney: null,
        showNotificationMessage: null,
        loadingLaunch: null,
        errorLaunch: null,
        launchData: null,
        redirect: null,
      };
    case JOURNEY_LIST_FETCH_FAIL:
      return { ...state, loadingList: false, errorList: action.payload };
    case JOURNEY_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingList: false };

    case JOURNEY_LIST_SEARCH_FETCH:
      return {
        ...state,
        pageRequest: action.payload,
        loadingSearchList: true,
        successUpdateJourney: null,
        successCreateJourney: null,
        successUpdateSubmitJourney: null,
        loadingLaunch: null,
        errorLaunch: null,
        launchData: null,
        redirect: null,
      };
    case JOURNEY_LIST_SEARCH_FETCH_FAIL:
      return { ...state, loadingSearchList: false, errorList: action.payload };
    case JOURNEY_LIST_SEARCH_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingSearchList: false };

    case JOURNEY_DELETE_FETCH:
      return { ...state, loadingDelete: true };
    case JOURNEY_DELETE_FETCH_FAIL:
      return { ...state, loadingDelete: false, errorDelete: action.payload };
    case JOURNEY_DELETE_FETCH_SUCCESS:
      return { ...state, loadingDelete: false };

    case JOURNEY_COPY_FETCH:
      return { ...state, loadingDuplicate: true };
    case JOURNEY_COPY_FETCH_FAIL:
      return {
        ...state,
        loadingDuplicate: false,
        errorDuplicate: action.payload,
      };
    case JOURNEY_COPY_FETCH_SUCCESS:
      return { ...state, loadingDuplicate: false };

    // Attriutes
    case JOURNEY_LIST_ATTRIBUTES_FETCH:
      return { ...state, loadingAttrList: true };
    case JOURNEY_LIST_ATTRIBUTES_FETCH_SUCCESS:
      return { ...state, loadingAttrList: false, attributes: action.payload };
    case JOURNEY_LIST_ATTRIBUTES_FETCH_FAIL:
      return { ...state, loadingAttrList: false, error: action.payload };

    // Segments
    case JOURNEY_LIST_SEGMENTS_FETCH:
      return { ...state, loadingSegmentsList: true };
    case JOURNEY_LIST_SEGMENTS_FETCH_SUCCESS:
      return { ...state, loadingSegmentsList: false, segments: action.payload };
    case JOURNEY_LIST_SEGMENTS_FETCH_FAIL:
      return {
        ...state,
        loadingSegmentsList: false,
        errorSegments: action.payload,
      };

    // Events
    case JOURNEY_LIST_EVENTS_FETCH:
      return { ...state, loadingEventsList: true };
    case JOURNEY_LIST_EVENTS_FETCH_SUCCESS:
      return { ...state, loadingEventsList: false, events: action.payload };
    case JOURNEY_LIST_EVENTS_FETCH_FAIL:
      return {
        ...state,
        loadingEventsList: false,
        errorEvents: action.payload,
      };

    // Business Events
    case JOURNEY_LIST_BUSINESS_EVENTS_FETCH:
      return { ...state, loadingBusinessEventsList: true };
    case JOURNEY_LIST_BUSINESS_EVENTS_FETCH_SUCCESS:
      return {
        ...state,
        loadingBusinessEventsList: false,
        businessEvents: action.payload,
      };
    case JOURNEY_LIST_BUSINESS_EVENTS_FETCH_FAIL:
      return {
        ...state,
        loadingBusinessEventsList: false,
        errorBusinessEvents: action.payload,
      };
    // Business Events Report
    case JOURNEY_BUSINESS_EVENTS_REPORT_FETCH:
      return { ...state, loadingBusinessEventsReport: true };
    case JOURNEY_BUSINESS_EVENTS_REPORT_FETCH_SUCCESS:
      return {
        ...state,
        loadingBusinessEventsReport: false,
        businessEventsReport: action.payload,
      };
    case JOURNEY_BUSINESS_EVENTS_REPORT_FETCH_FAIL:
      return {
        ...state,
        loadingBusinessEventsReport: false,
        errorBusinessEventsReport: action.payload,
      };
    case JOURNEY_REPORT_COMMUNICATION_FETCH_SUCCESS:
      return {
        ...state,
        loadingJourneyCommunicationReport: false,
        journeyCommunicationReportData: action.payload,
      };
    case JOURNEY_REPORT_COMMUNICATION_FETCH_FAIL:
      return { ...state, loadingJourneyCommunicationReport: false };
    case JOURNEY_REPORT_COMMUNICATION_FETCH:
      return {
        ...state,
        loadingJourneyCommunicationReport: true,
        journeyCommunicationReportRequest: action.payload,
      };

    // REPORTS
    case JOURNEY_REPORT_OVERVIEW:
      return { ...state, loadingOverview: true };
    case JOURNEY_REPORT_OVERVIEW_SUCCESS:
      return { ...state, loadingOverview: false, overviewData: action.payload };
    case JOURNEY_REPORT_OVERVIEW_FAIL:
      return {
        ...state,
        loadingOverview: false,
        errorOverview: action.payload,
      };

    // REPORT LIVE
    case JOURNEY_REPORT_LIVE:
      return { ...state, loadingLive: true };
    case JOURNEY_REPORT_LIVE_SUCCESS:
      return { ...state, loadingLive: false, liveData: action.payload };
    case JOURNEY_REPORT_LIVE_FAIL:
      return { ...state, loadingLive: false, errorLive: action.payload };

    // LAUNCH
    case JOURNEY_LANUCH:
      return {
        ...state,
        loadingLaunch: true,
        errorLaunch: null,
        launchData: null,
      };
    case JOURNEY_LANUCH_SUCCESS:
      return { ...state, loadingLaunch: false, launchData: action.payload };
    case JOURNEY_LANUCH_FAIL:
      return { ...state, loadingLaunch: false, errorLaunch: action.payload };
    case JOURNEY_LANUCH_CLEAR:
      return { ...state, loadingLaunch: false, launchData: null };

    // STOP
    case JOURNEY_STOP:
      return { ...state, loadingStop: true, errorStop: null, stopData: null };
    case JOURNEY_STOP_SUCCESS:
      return { ...state, loadingStop: false, stopData: action.payload };
    case JOURNEY_STOP_FAIL:
      return { ...state, loadingStop: false, errorStop: action.payload };

    // PAUSE
    case JOURNEY_PAUSE:
      return {
        ...state,
        loadingPause: true,
        errorPause: null,
        pauseData: null,
      };
    case JOURNEY_PAUSE_SUCCESS:
      return { ...state, loadingPause: false, pauseData: action.payload };
    case JOURNEY_PAUSE_FAIL:
      return { ...state, loadingPause: false, errorPause: action.payload };

    // TEST API
    case JOURNEY_TEST_API:
      return {
        ...state,
        loadingTestApi: true,
        errorTestApi: null,
        testApiData: null,
      };
    case JOURNEY_TEST_API_SUCCESS:
      return { ...state, loadingTestApi: false, testApiData: action.payload };
    case JOURNEY_TEST_API_FAIL:
      return { ...state, loadingTestApi: false, errorTestApi: action.payload };

    //ENGAGED USER
    case JOURNEY_ENGAGED_USER_FETCH:
      return {
        ...state,
        journeyEngagedUserPageRequest: action.payload,
        loadingJourneyEngagedUser: true,
      };
    case JOURNEY_ENGAGED_USER_FETCH_FAIL:
      return {
        ...state,
        loadingJourneyEngagedUser: false,
        errorsJourneyEngagedUser: action.payload,
      };
    case JOURNEY_ENGAGED_USER_FETCH_SUCCESS:
      return {
        ...state,
        journeyEngagedUser: action.payload,
        loadingJourneyEngagedUser: false,
      };
    case JOURNEY_ARCHIVE_FETCH:
      return { ...state, loadingArchive: true };
    case JOURNEY_ARCHIVE_FETCH_SUCCESS:
      return {
        ...state,
        loadingArchive: false,
        archiveSuccess: !state.archiveSuccess,
      };
    case JOURNEY_ARCHIVE_FETCH_FAIL:
      return {
        ...state,
        loadingArchive: false,
        errorsArchive: action.payload.errors,
      };
    case JOURNEY_ACTIVE_FETCH:
      return { ...state, loadingActivate: true };
    case JOURNEY_ACTIVE_FETCH_FAIL:
      return { ...state, loadingActivate: false };
    case JOURNEY_ACTIVE_FETCH_SUCCESS:
      return {
        ...state,
        loadingActivate: false,
        activeSuccess: !state.activeSuccess,
      };
    case JOURNEY_TEMPLATE_NAMES_LIST_FETCH:
      return {
        ...state,
        loadingTemplateNamesList: true,
      };
    case JOURNEY_TEMPLATE_NAMES_LIST_SUCCESS:
      return {
        ...state,
        loadingTemplateNamesList: false,
        templateNames: action.payload,
      };
    default:
      return state;
  }
}
