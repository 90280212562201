import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router';
import ReleaseNoteList from './component/ReleaseNoteListComponent';
import ReleaseNoteUpdate from './component/ReleaseNoteUpdate';

function ReleaseNote() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}list`}>
        <ReleaseNoteList />
      </Route>
      <Route path={`${match.path}create`}>
        <ReleaseNoteUpdate />
      </Route>
      <Route path={`${match.path}update/:id`}>
        <ReleaseNoteUpdate />
      </Route>
    </Switch>
  );
}

export default ReleaseNote;
