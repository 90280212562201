import vars from '@Theme/styles/vars.js';
const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
  },
  headIcon: {
    border: '1px solid',
    borderRadius: '50%',
    fontSize: '28px',
    color: '#94969e',
    marginLeft: 10,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  segmentContent: {
    display: 'flex',
    flex: 8,
    flexDirection: 'column',
  },
  terms: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
  },
  summary: {
    flex: 2,
    marginLeft: 32,
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    position: 'relative',
  },
  chart: {
    width: '80%',
    paddingBottom: 10,
    marginBottom: 10,
    paddingTop: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: 'solid 2px #f4f9ff',
  },
  chartLegendReachable: {
    backgroundColor: vars.primaryColor,
    width: 8,
    height: 8,
    borderRadius: 50,
    marginRight: 5,
  },
  chartLegendUnreachable: {
    backgroundColor: '#e5e5e5',
    width: 8,
    height: 8,
    borderRadius: 50,
    marginRight: 5,
    marginLeft: 20,
  },
  addNewEmptySegment: {
    display: 'flex',
    cursor: 'pointer',
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200,
  },
  circlePlusContainer: {
    display: 'flex',
    marginLeft: 10,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#e7f0fa',
    width: 30,
    height: 30,
  },
  circlePlus: {
    color: vars.primaryColor,
  },
  nameContainer: {
    height: 80,
    marginBottom: 16,
    display: 'flex',
    backgroundColor: 'white',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 5,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
  },
  nameContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 16,
    marginRight: 16,
  },
  name: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nameTitle: {
    fontSize: 14,
    marginRight: 16,
    fontWeight: 600,
    color: '#1c1c1c',
  },
  nameInput: {
    width: 320,
  },
  testContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  testTitle: {
    fontSize: 14,
    paddingRight: 16,
    fontWeight: 600,
    color: '#1c1c1c',
  },
  testSwitchContent: {
    width: 50,
  },
  termContainer: {
    margin: 16,
  },
  summaryBox: {
    width: '80%',
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  summaryTitle: {
    color: '#707070',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  summaryNumber: {
    color: '#1c1c1c',
    fontSize: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    marginTop: 30,
  },
  modalSection: {
    maxHeight: 400,
    paddingRight: 5,
    paddingLeft: 5,
    overflow: 'scroll',
  },
  modalSectionHeader: {
    padding: 10,
    backgroundColor: '#efefef',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalSelectionHeaderSearchContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  modalSectionHeaderButton: {
    color: '#1c1c1c',
    padding: 5,
    position: 'relative',
    top: 4,
    cursor: 'pointer',
  },
  modalSectionSelectorContent: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#fcfcfc',
    border: 'solid 1px #efefef',
    padding: 10,
    marginTop: 10,
    marginBottom: 10,
    lineHeight: 2,
  },
  modalSectionSelectorButtonContent: {
    display: 'flex',
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#e7f0fa',
    width: 30,
    height: 30,
  },
  modalSectionSelectorButton: {
    color: vars.primaryColor,
  },
  toContent: {
    backgroundColor: '#e5e5e5',
    borderRadius: 2,
    padding: '4px 8px',
    marginRight: 10,
    marginLeft: 10,
    color: '#1c1c1c',
    fontSize: 14,
    float: 'left',
    minHeight: 32,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  predicateConjunctionDividerContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 10,
  },
  predicateConjunctionDividerBorderLeft: {
    borderBottom: '2px dashed #efefef',
    width: '2%',
  },
  predicateConjunctionDividerBorderRight: {
    borderBottom: '2px dashed #efefef',
    width: '100%',
  },
  conjunctionDividerText: {
    cursor: 'pointer',
    position: 'relative',
    top: 10,
    borderRadius: 2,
    padding: '5px 15px',
    fontSize: 10,
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
  },
  termConjunctionDivideContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 0',
  },
  termConjunctionDividerBorder: {
    borderBottom: '2px solid #efefef',
    width: '100%',
  },
  eventPredicateContent: {
    border: 'dashed 0.5px #707070',
    paddingBottom: 30,
    backgroundColor: '#fcfcfc',
  },
  eventPredicateAttributeHeader: {
    margin: '16px 24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  eventPredicateAttributeHeaderTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: '#1c1c1c',
  },
  eventPredicateAttributeDelete: {
    cursor: 'pointer',
    color: vars.primaryColor,
    fontSize: 12,
    fontWeight: 600,
  },
  eventPredicateAttribute: {
    display: 'flex',
    flexDirection: 'row',
    margin: '16px 24px',
  },
  eventPredicateAttributeOperatorContent: {
    marginLeft: 10,
    marginRight: 10,
  },
  eventPredicateAttributeAdd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative',
    top: 15,
    fontSize: 12,
  },
  boxContent: {
    border: 'dashed 0.5px #707070',
    paddingBottom: 30,
    backgroundColor: '#fcfcfc',
  },
  boxAttributeHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  boxAttributeHeaderTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: '#1c1c1c',
  },
  boxAttributeHeaderDelete: {
    cursor: 'pointer',
    color: vars.primaryColor,
    fontSize: 12,
    fontWeight: 600,
  },
  boxAttribute: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 16,
  },
  boxAttributeFlex: {
    display: 'flex',
    flexDirection: 'row',
  },
  boxAttributeOperatorContent: {
    marginLeft: 10,
    marginRight: 10,
  },
  boxAttributeOperatorValue: {
    height: 55,
  },
  boxBehaviourHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  boxBehaviourHeaderTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: '#1c1c1c',
  },
  boxBehaviourHeaderDelete: {
    cursor: 'pointer',
    color: vars.primaryColor,
    fontSize: 12,
    fontWeight: 600,
  },
  boxBehaviour: {
    display: 'flex',
    flexDirection: 'row',
    margin: '16px 0',
  },
  methodsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  methodsContainerWebPush: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  methodDescription: {
    textAlign: 'center',
    fontSize: 13,
    lineHeight: 1.5,
    color: 'rgb(61, 64, 78)',
  },
  methodImage: {
    width: 'auto',
    height: 58,
    marginTop: 30,
    marginBottom: 20,
  },
  methodTitle: {
    fontWeight: '600',
    color: '#3d404e',
    lineHeight: '1.43',
    fontSize: 14,
    paddingBottom: 30,
  },
  methodContainer: {
    border: '1px solid #e7e8e9',
    borderRadius: '5px',
    padding: '20px',
    height: 300,
    width: 247,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  attrPredicateContent: {
    marginTop: '30px',
  },
  attrTitle: {
    paddingLeft: '5px',
    paddingTop: '5px',
  },
};

export default styles;
