import { Card, Col, Popconfirm, Row, Select, Space, Table } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  MinusCircleOutlined,
  PlusCircleOutlined as AddIcon,
} from '@ant-design/icons';
import './styles.less';
import Modal from 'antd/lib/modal/Modal';
import ProductSegmentPredicateComponent from '../../../../modules/insight/productSegment/component/ProductSegmentPredicateComponent';
import { TriggerComponent } from '../ModalContent/TrEnterExits';
import ProductSegmentUpdateComponentTest from '../../../../modules/insight/productSegment/component/ProductSegmentUpdateComponentAttribute';
import { AppContext } from '../../app';
import { TriggerEnterExistOnStep } from '../../utils/static';
import openSnackBar from '../Atom/SnackBar';
import _ from 'lodash';
import TrChangeInUserAttrContent, {
  isValidPredicates,
} from '../ModalContent/TrChangeInUserAttr';

const exitTriggerTypes = [
  {
    value: 'EVENT',
    name: 'When user does an event ',
  },
  {
    value: 'SEGMENT',
    name: 'When user enter or exit a segment ',
  },
  {
    value: 'ATTRIBUTE',
    name: "When user's profile attribute changes",
    // disable: true,
  },
];

const predicateDefault = {
  event: null,
  type: 'EVENT_OCCURRENCE',
};

// const predicateAttrDefault = {
//   attribute: null,
//   type: 'EXIT_TRIGGER_ATTRIBUTE',
// };

export const ProductSegmentUpdateComponent_ = (props) => {
  const { events, attributes } = props.data;
  const { handleChangePredicate, currentProductSegment } = props;

  const props_ = {
    journey: true,
    currentProductSegment: currentProductSegment || {},
    currentReport: {},
    errors: [],
    attributes: attributes,
    events: events,
    deviceAttributes: {},
  };

  return (
    <ProductSegmentUpdateComponentTest
      {...props_}
      handleChangePredicate={handleChangePredicate}
    />
  );
};

const ExitTriggerStep = (props) => {
  const { journey, syncCache } = useContext(AppContext);

  const [triggerType, setTriggerType] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const {
    events,
    attributes,
    segments,
    fetchRecommendDeviceAttributes,
    fetchRecommendEventAttributes,
    fetchRecommendUserAttributes,
    recommendDeviceAttributes,
    recommendEventAttributes,
    recommendUserAttributes,
  } = props.data;

  const [eventTrigger, setEventTrigger] = useState({});
  const [eventPredicate, setEventPredicate] = useState(predicateDefault);
  const [attributeTrigger, setAttributeTrigger] = useState({
    attribute: 0,
    conjunctionType: 'AND',
    predicates: [],
    attributeType: 'ANY',
  });
  const [tempState, setTempState] = useState();
  const [stepData, setStepData] = useState([]);
  const [enterExitType, setEnterExitType] = useState();
  const [segment, setSegment] = useState();

  useEffect(() => {
    if (journey?.exitTriggers?.length > 0) {
      setStepData(journey?.exitTriggers);
    }
  }, [journey?.exitTriggers]);

  const handleDelete = (key) => {
    setStepData((old) => {
      const data_ = [...old]?.filter((item) => item.id !== key);
      syncCache('exitTriggers', data_);
      return data_;
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <a onClick={() => handleModal(record.key)}>{text}</a>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text) => <p>{text}</p>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <a>
              <MinusCircleOutlined />
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const dataTable = useCallback(() => {
    if (stepData?.length && events?.length) {
      return stepData.map((step) => {
        let name = 'No Title';
        let type = 'EVENT';
        if (step.event) {
          name = `On ${events?.find((item) => item.id === step.event)?.title}`;
        } else if (step.segment) {
          name = `When User ${step?.segmentType} ${
            segments?.find((item) => item.id === step.segment)?.name
          }`;
          type = 'SEGMENT';
        } else if (step.attribute) {
          const attrName = attributes?.find(
            (item) => item.id === (step.attribute?.id || step.attribute)
          );

          name = `When User's ${attrName?.title} change`;
          type = 'ATTRIBUTE';
        }
        return {
          key: step.id,
          name,
          type,
        };
      });
    }
    return [];
  }, [stepData, events]);

  useEffect(() => {
    if (segment) {
      props.getSegment(segment);
    }
  }, [segment]);

  const onChangeState = (value) => {
    setEnterExitType(value);
    setSegment(null);
  };

  const onChangeTrigger = (e) => {
    setTriggerType(e);
  };

  const clearModal_ = () => {
    // setAttributeType()
    setEnterExitType();
    setSegment();
    setTriggerType();
    setEventTrigger({});
    setEventPredicate(predicateDefault);
    setAttributeTrigger({});
  };

  const handleModal = (key) => {
    clearModal_();
    setIsModalVisible((old) => !old);
    if (_.isNumber(key)) {
      setSelectedItem(key);
    } else {
      setSelectedItem();
    }
    if (key && isModalVisible === false) {
      const item = stepData.find((item) => item.id === key);
      let type_;
      if (item?.event) {
        type_ = exitTriggerTypes[0].value;
        const predicate_ = Object.assign({}, predicateDefault, {
          event: item.event,
          eventPredicates: item?.eventFilterPredicates,
          eventConjunction: item?.eventFilterConjunction || 'AND',
        });
        setEventPredicate(predicate_);
      } else if (item?.segment) {
        type_ = exitTriggerTypes[1].value;

        setSegment(item.segment);
        setEnterExitType(item.segmentType);
      } else if (item?.attribute) {
        type_ = exitTriggerTypes[2].value;
        setAttributeTrigger({
          attribute: item.attribute?.id || item.attribute,
          conjunctionType: item.attributeFilterConjunction,
          predicates: item.attributeFilterPredicates,
          attributeType: item.attributeType,
        });
      }
      if (type_) setTriggerType(type_);
    }
  };

  const handleOk = () => {
    let stepData_;
    let hasError = false;
    if (triggerType === exitTriggerTypes[0].value) {
      stepData_ = eventTrigger;
      if (!eventTrigger.event) {
        hasError = true;
        // return
      }
    } else if (triggerType === exitTriggerTypes[1].value) {
      const filterData = {
        segment: segment,
        segmentType: enterExitType,
      };
      stepData_ = filterData;
      if (!segment || !enterExitType) {
        hasError = true;
        // return
      }
    } else if (triggerType === exitTriggerTypes[2].value) {
      stepData_ = tempState;
      if (!stepData_.attribute) {
        hasError = true;
      } else {
        const predicates = stepData_?.attributeFilterPredicates || [];
        hasError = !isValidPredicates(predicates);
      }
    }
    if (hasError) {
      openSnackBar('error', {
        message: 'Please fill out all fields',
      });
      return;
    }
    if (stepData_) {
      if (selectedItem >= 0) {
        // update
        setStepData((old) => {
          const old_ = [...old];
          const item_ = old_.find((item) => item.id === selectedItem);
          const itemIndex = old_.findIndex((item) => item.id === selectedItem);
          old_[itemIndex] = Object.assign({}, item_, stepData_, {
            type: triggerType,
          });
          syncCache('exitTriggers', old_);
          return old_;
        });
      } else {
        // insert
        setStepData((old) => {
          old.push({
            id: old.length + 1,
            ...stepData_,
            type: triggerType,
          });
          syncCache('exitTriggers', old);
          return old;
        });
      }
      handleModal();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChangeEventPredicate = (changedPredicate) => {
    if (changedPredicate?.eventPredicates) {
      const filterData = {
        event: changedPredicate.event,
        eventFilterPredicates: changedPredicate?.eventPredicates,
        eventFilterConjunction: changedPredicate?.eventConjunction || 'AND',
      };
      setEventTrigger(filterData);
    }
    setEventPredicate(changedPredicate);
  };

  if (props.currentStep !== 'exitTrigger') return null;
  return (
    <div>
      <Row>
        <Col span={20} offset={1}>
          <Card
            className="custom__card"
            title={'Exit Triggers Scenario'}
            extra={
              <AddIcon className={'custom__card__icon'} onClick={handleModal} />
            }
            bordered={false}
            style={{ minHeight: '300px' }}
            headStyle={{ display: 'flex' }}
          >
            {dataTable()?.length ? (
              <div>
                <Table
                  pagination={true}
                  columns={columns}
                  dataSource={dataTable()}
                />
              </div>
            ) : (
              <div className="custom__card__placeholder" onClick={handleModal}>
                Click Here To Add Exit Trigger
              </div>
            )}
          </Card>

          {isModalVisible ? (
            <Modal
              title="Add Exit Scenario"
              width="70vw"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <div>
                <Select
                  style={{ width: 300 }}
                  placeholder="Select a Trigger"
                  onChange={onChangeTrigger}
                  value={triggerType}
                >
                  {exitTriggerTypes.map((item) => (
                    <Select.Option
                      key={item.key}
                      value={item.value}
                      disabled={item.disable}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                {triggerType && (
                  <div className="exit_box">
                    {triggerType === exitTriggerTypes[0].value && (
                      <div>
                        <div>
                          <label>End journey when user does</label>
                          <ProductSegmentPredicateComponent
                            journey={true}
                            readOnly={props.readOnly}
                            type="EVENT_OCCURRENCE"
                            predicate={eventPredicate}
                            attributes={attributes}
                            events={events}
                            isInSegment={false}
                            // handleChangeTermConjunction={handleChangeEventConjunction}
                            handleChangePredicate={(changedPredicate) =>
                              handleChangeEventPredicate(changedPredicate)
                            }
                            clearSegmentError={() => {
                              //TODO Must Handle
                            }}
                            fetchRecommendEventAttributes={
                              fetchRecommendEventAttributes
                            }
                            recommendEventAttributes={recommendEventAttributes}
                            fetchRecommendDeviceAttributes={
                              fetchRecommendDeviceAttributes
                            }
                            recommendDeviceAttributes={
                              recommendDeviceAttributes
                            }
                            fetchRecommendUserAttributes={
                              fetchRecommendUserAttributes
                            }
                            recommendUserAttributes={recommendUserAttributes}
                          />
                        </div>
                      </div>
                    )}
                    {triggerType === exitTriggerTypes[1].value && (
                      <div>
                        <TriggerComponent
                          {...props}
                          title={'End journey when user'}
                          onChangeState={onChangeState}
                          enterExitType={enterExitType}
                          setEnterExitType={setEnterExitType}
                          segment={segment}
                          setSegment={setSegment}
                          userState={TriggerEnterExistOnStep}
                        />
                      </div>
                    )}
                    {triggerType === exitTriggerTypes[2].value && (
                      <div>
                        <TrChangeInUserAttrContent
                          {...props}
                          attributeTrigger={attributeTrigger}
                          setAttributeTrigger={(data) => {
                            setTempState(data);
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Modal>
          ) : (
            ''
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ExitTriggerStep;
