const CommunicationTypes = {
  ONE_TIME: 'One Time',
  TRIGGERED: 'Triggered',
  RECURRING: 'Recurring',
  TRANSACTIONAL: 'Transactional',
  JOURNEY: 'Journey',
  RELAY: 'Relay',
};

export default CommunicationTypes;
