import React from 'react';
import { Modal, Button, Input, Select, Spin, Switch, InputNumber } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { isNil, isEmpty } from 'lodash';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import WebhookType from '../model/WebhookType';
import { LoadingOutlined } from '@ant-design/icons';
import texts from '../../../../utils/texts';
import '../../../integration/productRestApi/style/ProductRestApiViewStyle.less';
import Card from '@Utils/Card';
import '../style/webhookStyle.css';
const spinStyles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};
const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'right',
  },
  editField: {
    marginLeft: 20,
    width: 350,
    borderRadius: 8,
  },
};

class WebhookViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAllEventsSelected: true,
      editingId: null,
      canBatchSize: false,
      url: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.loadingCreate &&
        !this.props.loadingCreate &&
        isEmpty(this.props.errorCreate)) ||
      (prevProps.loadingUpdateSubmit &&
        !this.props.loadingUpdateSubmit &&
        isEmpty(this.props.errorUpdateSubmit))
    ) {
      this.props.webhookModalToggle(false);
    }
  }

  componentDidMount() {
    this.props.webhookListFetch(this.props.pageRequest);
    this.props.webhookViewFetch();
    this.props.listEventsFetch();
  }

  componentWillUnmount() {
    this.props.webhookPageChangeCurrent({});
  }

  handleChangeSearch = (url) => {
    this.setState({ url });
    if (url === '' || url === null) {
      this.props.webhookListFetch({
        ...this.props.pageRequest,
        page: 0,
        url: null,
      });
    }
  };

  onOkModal = () => {
    if (!this.state.canBatchSize) {
      this.props.currentWebhook.batchSize = null;
    }

    if (!isNil(this.state.editingId)) {
      this.props.webhookUpdateSubmitFetch(
        this.state.editingId,
        this.props.currentWebhook,
        this.props.pageRequest
      );
    } else {
      this.props.webhookCreateFetch(
        {
          ...this.props.currentWebhook,
          events: ['SYSTEM_EVENT', 'CUSTOM_EVENT'].includes(
            this.props.currentWebhook.type
          )
            ? this.state.isAllEventsSelected
              ? []
              : this.props.currentWebhook.events
            : null,
          format: this.props.currentWebhook.format
            ? this.props.currentWebhook.format
            : 'JSON',
          enabled: !isNil(this.props.currentWebhook.enabled)
            ? this.props.currentWebhook.enabled
            : false,
          enableBeginSession: ['SYSTEM_EVENT'].includes(
            this.props.currentWebhook.type
          )
            ? !isNil(this.props.currentWebhook.enableBeginSession)
              ? this.props.currentWebhook.enableBeginSession
              : false
            : null,
        },
        this.props.pageRequest
      );
    }
  };

  handleChangeType = (type) => {
    this.setState({ type });
    this.props.webhookListFetch({ ...this.props.pageRequest, page: 0, type });
  };

  handleInitialState = (events) => {
    let isSelected = events?.length ? false : true;
    this.setState({ isAllEventsSelected: isSelected });
  };

  handleEdit = (id) => {
    this.setState({ editingId: id }, () => {
      this.props.webhookUpdateFetch(id, this.handleInitialState);
      this.props.webhookModalToggle(true);
    });
  };

  handleDelete = (id) => {
    this.props.webhookDeleteFetch(id, this.props.pageRequest);
  };

  renderContent = () => {
    const webhookDataCategory = [
      { label: 'System Event', value: 'SYSTEM_EVENT' },
      { label: 'Custom Event', value: 'CUSTOM_EVENT' },
      { label: 'Communication', value: 'COMMUNICATION' },
      { label: 'Journey', value: 'JOURNEY' },
    ];

    const renderEvents = (data) => {
      let items = [];

      if (data.length) {
        data.forEach((item) => {
          if (!item.system) {
            items.push(item);
          }
        });
      }

      return items.map((event) => (
        <Select.Option value={event.id} key={event.id}>
          {event.title}
        </Select.Option>
      ));
    };

    return this.props.loadingUpdate ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin style={{ display: 'flex', alignSelf: 'center' }} />
      </div>
    ) : (
      <div>
        <div style={styles.editContainer}>
          <div style={{ ...styles.editLabel, flex: 1.5 }}>URL</div>
          <div style={{ flex: 4 }}>
            <Input
              value={
                this.props.currentWebhook && this.props.currentWebhook.url
                  ? this.props.currentWebhook.url
                  : ''
              }
              onChange={(e) =>
                this.props.webhookChangeCurrent({
                  ...this.props.currentWebhook,
                  url: e.target.value,
                })
              }
              size="large"
              style={{ ...styles.editField, width: 308 }}
            />
          </div>
        </div>
        <div style={styles.editContainer}>
          <div style={{ ...styles.editLabel, flex: 1.5 }}>Type</div>
          <div style={{ flex: 4 }}>
            <div style={{ marginLeft: 20 }}>
              <Select
                onChange={(type) => {
                  this.props.webhookChangeCurrent({
                    ...this.props.currentWebhook,
                    events: ['SYSTEM_EVENT', 'CUSTOM_EVENT'].includes(type)
                      ? []
                      : null,
                    type,
                  });
                  this.setState({ isAllEventsSelected: true });
                }}
                value={
                  this.props.currentWebhook && this.props.currentWebhook.type
                    ? this.props.currentWebhook.type
                    : ''
                }
                style={{ width: 308 }}
                className={'chart-selector'}
                bordered={false}
              >
                {webhookDataCategory.map((category) => (
                  <Select.Option key={category.value} value={category.value}>
                    {category.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div style={styles.editContainer}>
          <div style={{ ...styles.editLabel, flex: 1.5 }}>Batch Size</div>
          <div style={{ flex: 4 }}>
            <Switch
              checked={this.state.canBatchSize}
              onChange={(checked) => {
                this.setState({ canBatchSize: checked });
                this.props.webhookChangeCurrent({
                  ...this.props.currentWebhook,
                  batchSize: null,
                });
              }}
              checkedChildren="Yes"
              unCheckedChildren="No"
              style={{ marginLeft: 20 }}
            />
          </div>
        </div>
        {this.state.canBatchSize && (
          <div style={styles.editContainer}>
            <div style={{ ...styles.editLabel, flex: 1.5 }}></div>
            <div style={{ flex: 4 }}>
              <InputNumber
                min={1}
                placeholder="1"
                required={this.state.canBatchSize}
                value={
                  this.props.currentWebhook &&
                  this.props.currentWebhook.batchSize
                    ? this.props.currentWebhook.batchSize
                    : undefined
                }
                onChange={(value) => {
                  this.props.webhookChangeCurrent({
                    ...this.props.currentWebhook,
                    batchSize:
                      value && Number(value) > 1 ? Number(value) : null,
                  });
                }}
                style={{ ...styles.editField, width: 308 }}
              />
            </div>
          </div>
        )}
        {['SYSTEM_EVENT'].includes(this.props.currentWebhook.type) && (
          <div style={styles.editContainer}>
            <div style={{ ...styles.editLabel, flex: 1.5 }}>
              Includes Begin Session
            </div>
            <div style={{ flex: 4 }}>
              <div style={{ marginLeft: 20 }}>
                <Switch
                  checked={
                    this.props.currentWebhook &&
                    this.props.currentWebhook.enableBeginSession
                  }
                  onChange={(enableBeginSession) =>
                    this.props.webhookChangeCurrent({
                      ...this.props.currentWebhook,
                      enableBeginSession,
                    })
                  }
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                />
              </div>
            </div>
          </div>
        )}
        {['CUSTOM_EVENT'].includes(this.props.currentWebhook.type) && (
          <>
            <div style={styles.editContainer}>
              <div style={{ ...styles.editLabel, flex: 1.5 }}>All Events</div>
              <div style={{ flex: 4 }}>
                <div style={{ marginLeft: 20 }}>
                  <Switch
                    checked={this.state.isAllEventsSelected}
                    onChange={(selected) => {
                      this.setState({ isAllEventsSelected: selected });
                      this.props.webhookChangeCurrent({
                        ...this.props.currentWebhook,
                        events: [],
                      });
                    }}
                    checkedChildren="All"
                  />
                </div>
              </div>
            </div>
            {!this.state.isAllEventsSelected && (
              <div style={styles.editContainer}>
                <div style={{ ...styles.editLabel, flex: 1.5 }}>
                  Selected Events
                </div>
                <div className="webhook-selector" style={{ flex: 4 }}>
                  <div style={{ marginLeft: 20 }}>
                    <Select
                      showArrow
                      mode="multiple"
                      value={
                        this.props.currentWebhook &&
                        this.props.currentWebhook.events
                          ? this.props.currentWebhook.events
                          : []
                      }
                      onChange={(events) => {
                        this.props.webhookChangeCurrent({
                          ...this.props.currentWebhook,
                          events,
                        });
                      }}
                      style={{ width: 308 }}
                      className={'chart-selector'}
                      bordered={false}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {renderEvents(this.props.listEvents)}
                    </Select>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <div style={styles.editContainer}>
          <div style={{ ...styles.editLabel, flex: 1.5 }}>Enabled</div>
          <div style={{ flex: 4 }}>
            <div style={{ marginLeft: 20 }}>
              <Switch
                checked={
                  this.props.currentWebhook && this.props.currentWebhook.enabled
                }
                onChange={(enabled) =>
                  this.props.webhookChangeCurrent({
                    ...this.props.currentWebhook,
                    enabled,
                  })
                }
                checkedChildren="Yes"
                unCheckedChildren="No"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  onCancelModal = () => {
    this.setState({ editingId: null });
    this.props.webhookChangeCurrent({});
    this.props.webhookModalToggle(false);
  };

  renderModal = () => {
    return (
      <Modal
        width={600}
        title={
          isNil(this.state.editingId)
            ? 'Add Webhook Credential'
            : 'Edit Webhook Credential'
        }
        centered
        visible={this.props.webhookCreateOpen}
        onCancel={() => this.onCancelModal()}
        footer={[
          <Button
            key="button"
            shape="round"
            onClick={() => this.onCancelModal()}
          >
            CANCEL
          </Button>,
          <Button
            loading={this.props.loadingUpdateSubmit || this.props.loadingCreate}
            key="submit"
            type="primary"
            shape="round"
            onClick={() => this.onOkModal()}
          >
            {this.state.editingId ? 'Update' : 'Create'}
          </Button>,
        ]}
      >
        <div>{this.renderContent()}</div>
      </Modal>
    );
  };

  renderWebhookDetails = () => {
    return (
      <div style={{ marginBottom: 20 }}>
        <Card
          title="Webhook Credentials"
          linkText="VIEW DOCUMENTATION"
          linkHref={texts.webhook}
          loading={this.props.loadingView}
        >
          <div className="column-content">
            <div className="item">
              <span className="item-title">NAME</span>
              <span className="item-description">{this.props.view.name}</span>
            </div>
            <div className="item">
              <span className="item-title">WEBHOOK KEY</span>
              <span className="item-description">
                {this.props.view.webHookKey}
              </span>
            </div>
            <div className="item">
              <span className="item-title">LICENSE CODE</span>
              <span className="item-description">
                {this.props.view.licenceCode}
              </span>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  renderOptions = () => {
    const result = [];
    Object.keys(WebhookType).forEach((key) => {
      result.push(
        <Select.Option value={key}>{WebhookType[key]}</Select.Option>
      );
    });
    return result;
  };

  render() {
    const columnList = [
      {
        sorter: false,
        title: 'URL',
        dataIndex: 'url',
        key: 'url',
        // ...getColumnTextSearchProps('title'),
      },
      {
        sorter: false,
        title: 'type',
        dataIndex: 'type',
        key: 'type',
        render: (x) => WebhookType[x],
        // ...getColumnTextSearchProps('name'),
      },
      {
        sorter: false,
        title: 'Format',
        dataIndex: 'format',
        key: 'format',
        render: (x) => x,
      },
      {
        sorter: false,
        title: 'enabled',
        dataIndex: 'enabled',
        key: 'enabled',
        render: (x) => (x ? 'Yes' : 'No'),
        // ...getColumnEnumSearchProps('type', AttributeType),
      },
      {
        title: 'action',
        dataIndex: '',
        key: '-action-',
        render: (x) =>
          defaultActions(
            x,
            {
              action: (id) => this.handleEdit(id),
              title: 'Edit',
              enabled: true,
              disablePopConfirm: true,
            },
            {
              action: (id) => this.handleDelete(id),
              title: 'Delete',
              enabled: true,
              disablePopConfirm: false,
            }
          ),
      },
    ];
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    return (
      <div style={{ padding: 30 }}>
        {this.renderWebhookDetails()}
        <Card>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
            }}
          >
            <Input
              loading={true}
              allowClear={this.props.loadingSearchList ? false : true}
              placeholder={'Search by URL'}
              // eslint-disable-next-line max-len
              onPressEnter={() =>
                this.props.webhookListFetch({
                  ...this.props.pageRequest,
                  page: 0,
                  url: this.state.url,
                })
              }
              onChange={(e) => this.handleChangeSearch(e.target.value)}
              value={this.state.url}
              style={{ width: 300, marginRight: 30, borderRadius: 5 }}
              suffix={
                isEmpty(this.state.url) ? (
                  <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                ) : (
                  this.props.loadingSearchList && (
                    <div style={spinStyles.inputLoadingSpinStyle}>
                      <Spin indicator={inputLoadingSpin} />
                    </div>
                  )
                )
              }
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Select
                onChange={(searchStatus) => this.handleChangeType(searchStatus)}
                value={
                  this.props.pageRequest && this.props.pageRequest.type
                    ? this.props.pageRequest.type
                    : null
                }
                defaultValue={null}
                style={{ width: 150 }}
                className={'chart-selector'}
                bordered={false}
              >
                <Select.Option value={null}>All Types</Select.Option>
                {this.renderOptions()}
              </Select>
            </div>
          </div>
          {this.props.loadingListWebhook ? (
            <div style={spinStyles.loadingSpinStyle}>
              <Spin indicator={loadingSpin} />
            </div>
          ) : (
            <ListBase
              onChange={this.props.webhookListFetch}
              page={this.props.listWebhook}
              pageRequest={this.props.pageRequest}
              columns={columnList}
              onClick={(row) => this.viewItem(row.id)}
              createLink={null}
            />
          )}
        </Card>
        {this.renderModal()}
      </div>
    );
  }
}

export default WebhookViewComponent;
