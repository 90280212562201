import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { isNil, isEmpty } from 'lodash';
import { variationLetter } from '../../../../../utils/FormUtils';

const VariationSection = ({
  variationDistribution,
  conversionTracking,
  isEditDisable,
  handleVariationDistributionChange,
}) => {
  const currentConversion = useSelector(
    (state) => state.engage.communication.currentConversion
  );

  const variationCount = currentConversion?.variations?.length ?? 1;

  let widthPercentage = variationDistribution.nonControlGroupPercentage;
  if (
    !conversionTracking.controlGroupEnable ||
    !conversionTracking.conversionEnable
  ) {
    widthPercentage = 100;
  }

  useEffect(() => {
    if (conversionTracking.winnerMechanismEnabled) {
      const variationCount = currentConversion?.variations?.length ?? 1;
      let newVariations = new Array(variationCount).fill(
        Number((100 / variationCount).toFixed())
      );
      if (variationCount === 3) {
        newVariations[newVariations.length - 1] = 34;
      }
      handleVariationDistributionChange({
        ...variationDistribution,
        variations: newVariations,
      });
    }
  }, [conversionTracking.winnerMechanismEnabled]);

  const getFlexWidth = (item, index, variationCount) => {
    let result = 0;
    if (isNil(item) || isEmpty(variationDistribution.variations)) {
      result = (100 / variationCount).toFixed();
    } else {
      result = variationDistribution.variations[index];
    }
    return result;
  };

  if (variationCount === 1) {
    return (
      <>
        <div style={{ width: widthPercentage + '%' }}>
          <div style={{ backgroundColor: '#96dbfa', height: 4 }}></div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>A</div>
              <div>100%</div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (variationCount === 2) {
    return (
      <>
        <Slider
          disabled={
            isEditDisable() || conversionTracking.winnerMechanismEnabled
          }
          style={{ width: widthPercentage + '%' }}
          value={variationDistribution.variations[0]}
          railStyle={
            conversionTracking.winnerMechanismEnabled
              ? { backgroundColor: '#cccccc' }
              : { backgroundColor: '#96dbfa' }
          }
          onChange={(value) =>
            handleVariationDistributionChange({
              ...variationDistribution,
              variations: [value, 100 - value],
            })
          }
        />
        <div
          style={{
            width: widthPercentage + '%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: !isNil(variationDistribution.variations[0])
                ? variationDistribution.variations[0]
                : 50,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div>A</div>
            <div>
              {(!isNil(variationDistribution.variations[0])
                ? variationDistribution.variations[0]
                : 50) + '%'}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: !isNil(variationDistribution.variations[1])
                ? variationDistribution.variations[1]
                : 50,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div>B</div>
            <div>
              {(!isNil(variationDistribution.variations[1])
                ? variationDistribution.variations[1]
                : 50) + '%'}
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: '32px',
            border: '1px solid #91D5FF',
            borderRadius: '4px',
            padding: '12px',
            display: 'flex',
          }}
        >
          <div style={{ alignItems: 'center' }}>
            <i
              className="fl-info"
              style={{
                color: '#63C1FB',
                fontSize: 27,
              }}
            ></i>
          </div>
          <div style={{ fontSize: '12px', color: '#464646' }}>
            You do not have to specify the variation distribution since Send
            Winning Variation Automatically option is Enabled. The winning
            variation (or control group) will be sent (or not sent if control
            group is the winner) to the remaining audience based on the results
            of the test.
          </div>
        </div>
      </>
    );
  } else {
    const handlePositions = [];
    let sum = 0;
    for (let i = 0; i < variationDistribution.variations.length; i++) {
      sum += Number(variationDistribution.variations[i]);
      handlePositions.push(sum);
    }
    const labelVariations = isEmpty(variationDistribution.variations)
      ? new Array(variationCount).fill((100 / variationCount).toFixed())
      : variationDistribution.variations;

    return (
      <>
        {isEmpty(handlePositions) ? null : (
          <Range
            disabled={
              isEditDisable() || conversionTracking.winnerMechanismEnabled
            }
            style={{ width: widthPercentage + '%' }}
            value={handlePositions}
            railStyle={
              conversionTracking.winnerMechanismEnabled
                ? { backgroundColor: '#cccccc' }
                : { backgroundColor: '#96dbfa' }
            }
            allowCross={false}
            onChange={(variations) => {
              const diffVariation = new Array(variations.length);
              variations.forEach((item, index) => {
                if (index === 0) {
                  diffVariation[index] = item;
                } else if (index === variations.length - 1) {
                  variations[index] = 100;
                  diffVariation[index] = 100 - variations[index - 1];
                } else {
                  diffVariation[index] = item - variations[index - 1];
                }
              });
              handleVariationDistributionChange({
                ...variationDistribution,
                variations: diffVariation,
              });
            }}
          />
        )}
        <div
          style={{
            width: widthPercentage + '%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {labelVariations.map((item, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: !isNil(variationDistribution.variations[index])
                  ? variationDistribution.variations[index]
                  : getFlexWidth(item, index, variationCount),
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div>{variationLetter[index]}</div>
              <div>{getFlexWidth(item, index, variationCount) + '%'}</div>
            </div>
          ))}
        </div>
        <div
          style={{
            marginTop: '32px',
            border: '1px solid #91D5FF',
            borderRadius: '4px',
            padding: '12px',
            display: 'flex',
          }}
        >
          <div style={{ alignItems: 'center' }}>
            <i
              className="fl-info"
              style={{
                color: '#63C1FB',
                fontSize: 27,
              }}
            ></i>
          </div>
          <div style={{ fontSize: '12px', color: '#464646' }}>
            You do not have to specify the variation distribution since Send
            Winning Variation Automatically option is Enabled. The winning
            variation (or control group) will be sent (or not sent if control
            group is the winner) to the remaining audience based on the results
            of the test.
          </div>
        </div>
      </>
    );
  }
};

export default VariationSection;
