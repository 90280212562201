import React from 'react';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import { Input, Spin } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { viewBoolean } from '@Utils/BooleanUtils';
import { AuthorityProvider, hasAnyAuthority } from '@Utils/AuthorityProvider';
import { history } from '../../../../redux/store';
import Card from '@Utils/Card';
const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};
class FileStorageListComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    name: '',
    currentFileStorage: null,
    id: null,
  };
  componentDidMount() {
    this.props.fileStorageListFetch({
      ...this.props.pageRequest,
      page: 0,
      name: this.state.name,
      order: 'id',
      direction: 'DESC',
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.updateFetchLoading && !this.props.updateFetchLoading) {
      this.setState(
        { currentFileStorage: this.props.currentFileStorage },
        () => {
          this.props.fileStorageUpdateSubmitFetch(
            this.state.id,
            {
              ...this.state.currentFileStorage,
              enabled:
                this.state.currentFileStorage.enabled === true ? false : true,
            },
            'listPage'
          );
        }
      );
    }
    if (prevProps.submitLoading && !this.props.submitLoading) {
      this.props.fileStorageListFetch({
        ...this.props.pageRequest,
        order: 'id',
      });
    }
  }

  viewItem(id) {
    if (hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_FILE_STORAGE_VIEW)) {
      history.push(`view/${id}`);
    }
  }

  handleChangeSearch = (name) => {
    this.setState({ name });
    if (name === '' || name == null) {
      this.props.fileStorageListFetch({
        ...this.props.pageRequest,
        name: null,
        page: 0,
        direction: 'DESC',
        order: 'id',
      });
    }
  };
  render() {
    const columnList = [
      {
        sorter: false,
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 400,
        render: (text, record) => (
          <a onClick={() => this.viewItem(record.id)}>{text}</a>
        ),
      },
      {
        sorter: false,
        title: 'Enabled',
        dataIndex: 'enabled',
        key: 'enabled',
        render: (x) => viewBoolean(x),
      },
      {
        sorter: false,
        title: 'MaximumSize',
        dataIndex: 'maximumSize',
        key: 'maximumSize',
      },
      {
        sorter: false,
        title: 'RemainingSize',
        dataIndex: 'remainingSize',
        key: 'remainingSize',
      },
      {
        sorter: false,
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        sorter: false,
        title: 'UploadedSize',
        dataIndex: 'uploadedSize',
        key: 'uploadedSize',
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '-action-',
        width: '5%',
        render: (x) =>
          defaultActions(
            x,
            {
              link: 'view/' + x.id,
              title: 'View',
              enabled: hasAnyAuthority(
                AuthorityProvider.ROLE_ADMIN_FILE_STORAGE_VIEW
              ),
            },
            {
              link: 'update/' + x.id,
              title: 'Edit',
              enabled: hasAnyAuthority(
                AuthorityProvider.ROLE_ADMIN_FILE_STORAGE_UPDATE
              ),
            },
            {
              action: (id) => {
                this.props.fileStorageDeleteFetch(id, this.props.pageRequest);
              },
              title: 'Delete',
              enabled: hasAnyAuthority(
                AuthorityProvider.ROLE_ADMIN_FILE_STORAGE_DELETE
              ),
            },
            {
              action: (id) => {
                this.setState({ id });
                this.props.fileStorageUpdateFetch(id);
              },
              title: x.enabled === true ? 'Disable' : 'Enable',
              enabled: hasAnyAuthority(
                AuthorityProvider.ROLE_ADMIN_FILE_STORAGE_UPDATE
              ),
            }
          ),
      },
    ];

    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    return (
      <div>
        <Card>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              borderBottom: 'solid 2px #f4f9ff',
            }}
          >
            <Input
              loading={true}
              allowClear={this.props.loadingFileStorageList ? false : true}
              placeholder={'Search by name'}
              onPressEnter={() =>
                this.props.fileStorageListFetch({
                  ...this.props.pageRequest,
                  page: 0,
                  name: this.state.name,
                  direction: 'DESC',
                  order: 'id',
                })
              }
              onChange={(e) => this.handleChangeSearch(e.target.value)}
              value={this.state.name}
              style={{ width: 300, marginRight: 30, borderRadius: 5 }}
              suffix={
                isEmpty(this.state.name) ? (
                  <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                ) : (
                  this.props.loadingFileStorageList && (
                    <div style={styles.inputLoadingSpinStyle}>
                      <Spin indicator={inputLoadingSpin} />
                    </div>
                  )
                )
              }
            />
          </div>
          {this.props.loadingFileStorageList ? (
            <div style={styles.loadingSpinStyle}>
              <Spin indicator={loadingSpin} />
            </div>
          ) : (
            <ListBase
              onChange={this.props.fileStorageListFetch}
              page={this.props.page}
              pageRequest={this.props.pageRequest}
              columns={columnList}
              // onClick={(row) => this.viewItem(row.id)}
            />
          )}
        </Card>
      </div>
    );
  }
}

export default FileStorageListComponent;
