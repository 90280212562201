import { createSlice } from '@reduxjs/toolkit';
import RevenueMappingApi from './revenueMappingApi';
import { showMessageError } from '@Utils/RenderUtils';
import toastr from 'toastr';
const initialState = {
  loadingList: false,
  loadingCurrencies: false,
  currencies: [],
  errorsCurrencies: [],

  currentRevenueMapping: {},

  errorsUpdate: [],
  loadingUpdate: false,

  errorsUpdateSubmit: [],
  loadingUpdateSubmit: false,
};

export const revenueMappingSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    revenueMappingChangeCurrent(state, { payload }) {
      state.currentRevenueMapping = payload;
    },
    revenueMappingUpdateFetchStart(state) {
      state.loadingUpdate = true;
    },
    revenueMappingUpdateFetchSuccess(state, { payload }) {
      state.loadingUpdate = false;
      state.currentRevenueMapping = payload;
    },
    revenueMappingUpdateFetchFail(state, { payload }) {
      state.loadingUpdate = false;
      state.errorsUpdate = payload.errors;
    },
    revenueMappingUpdateSubmitFetchStart(state) {
      state.loadingUpdateSubmit = true;
    },
    revenueMappingUpdateSubmitFetchSuccess(state) {
      state.loadingUpdateSubmit = false;
    },
    revenueMappingUpdateSubmitFetchFail(state, { payload }) {
      state.errorsUpdateSubmit = payload.errors;
      state.loadingUpdateSubmit = false;
    },
    revenueMappingListCurrenciesFetchStart(state) {
      state.loadingCurrencies = true;
      state.loadingList = true;
    },
    revenueMappingListCurrenciesFetchSuccess(state, { payload }) {
      state.loadingCurrencies = false;
      state.loadingList = false;
      state.currencies = payload;
    },
    revenueMappingListCurrenciesFetchFail(state, { payload }) {
      state.loadingCurrencies = false;
      state.loadingList = false;
      state.errorsCurrencies = payload.errors;
    },
  },
});

export default revenueMappingSlice.reducer;

export const {
  revenueMappingChangeCurrent,
  revenueMappingUpdateFetchStart,
  revenueMappingUpdateFetchSuccess,
  revenueMappingUpdateFetchFail,
  revenueMappingUpdateSubmitFetchStart,
  revenueMappingUpdateSubmitFetchSuccess,
  revenueMappingUpdateSubmitFetchFail,
  revenueMappingListCurrenciesFetchStart,
  revenueMappingListCurrenciesFetchSuccess,
  revenueMappingListCurrenciesFetchFail,
} = revenueMappingSlice.actions;

export const revenueMappingUpdateFetch = () => async (dispatch) => {
  dispatch(revenueMappingUpdateFetchStart());
  try {
    RevenueMappingApi.update()
      .then((response) => {
        dispatch(revenueMappingUpdateFetchSuccess(response));
      })
      .catch((error) => {
        dispatch(revenueMappingUpdateFetchFail(error));
      });
  } catch (error) {
    showMessageError(error);
  }
};
export const revenueMappingUpdateSubmitFetch =
  (revenueMapping) => async (dispatch) => {
    dispatch(revenueMappingUpdateSubmitFetchStart());
    try {
      const response = await RevenueMappingApi.updateSubmit(revenueMapping);
      dispatch(revenueMappingUpdateSubmitFetchSuccess(response));
      toastr.success('Has been updated successfully');
    } catch (error) {
      dispatch(revenueMappingUpdateSubmitFetchFail(error));
      showMessageError(error);
    }
  };
export const revenueMappingListCurrenciesFetch = () => async (dispatch) => {
  dispatch(revenueMappingListCurrenciesFetchStart());
  try {
    const response = await RevenueMappingApi.listCurrencies();

    dispatch(revenueMappingListCurrenciesFetchSuccess(response));
  } catch (error) {
    dispatch(revenueMappingListCurrenciesFetchFail(error));
  }
};
