import {
  EVENT_CHANGE_CURRENT,
  PRODUCT_EVENT_ANALYTICS_FETCH,
  PRODUCT_EVENT_ANALYTICS_FETCH_FAIL,
  PRODUCT_EVENT_ANALYTICS_FETCH_SUCCESS,
  PRODUCT_EVENT_LIST_FETCH,
  PRODUCT_EVENT_LIST_FETCH_FAIL,
  PRODUCT_EVENT_LIST_FETCH_SUCCESS,
} from './EventAnalyticsConstants';
import EventAnalyticsApi from '../events/EventAnalyticsApi';
export function productEventAnalyzeFetch(body) {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_EVENT_ANALYTICS_FETCH,
      payload: body,
    });
    EventAnalyticsApi.getEventAnalytics(body)
      .then((response) => {
        dispatch({
          type: PRODUCT_EVENT_ANALYTICS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_EVENT_ANALYTICS_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function productEventListFetch() {
  return (dispatch) => {
    dispatch({
      type: PRODUCT_EVENT_LIST_FETCH,
    });
    EventAnalyticsApi.eventList()
      .then((response) => {
        dispatch({
          type: PRODUCT_EVENT_LIST_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: PRODUCT_EVENT_LIST_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function eventChangeCurrent(event) {
  return (dispatch) => {
    dispatch({
      type: EVENT_CHANGE_CURRENT,
      payload: event,
    });
  };
}
