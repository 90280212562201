import lodashFindIndex from 'lodash/findIndex';

export function fileFormatChecker(fileUrl, acceptedFileUrls) {
  if (fileUrl && fileUrl.includes('.')) {
    const fileSuffix = fileUrl.split('.').pop();
    const accepted = acceptedFileUrls;
    const foundIndex = lodashFindIndex(accepted, (item) =>
      fileSuffix.includes(item)
    );
    return foundIndex;
  } else {
    return null;
  }
}
