import React from 'react';
import { DatePicker, Form } from 'antd';
import moment from 'moment';

const FunnelDatePicker = ({
  executing,
  executionParams,
  updateFunnelExecutionParameters,
}) => {
  return (
    <div>
      <Form name="funnel_report_date" layout="inline" disabled={executing}>
        <Form.Item
          name="date"
          label="Date Range"
          initialValue={[
            moment(executionParams.startDate, 'YYYY-MM-DD'),
            moment(executionParams.endDate, 'YYYY-MM-DD'),
          ]}
        >
          <DatePicker.RangePicker
            allowClear={false}
            showNow
            disabledDate={(date) =>
              date &&
              (date > moment().startOf('day') ||
                date < moment().subtract(90, 'day'))
            }
            onChange={(val) => {
              updateFunnelExecutionParameters({
                startDate: val[0].format('YYYY-MM-DD'),
                endDate: val[1].format('YYYY-MM-DD'),
              });
            }}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default FunnelDatePicker;
