import { isNil } from 'lodash';
import { getProductId } from './AuthorityProvider';
import { getProduct } from './getProduct';
import { thirdPartyEnums } from '@Modules/admin/thirdParty/components/ThirdPartyTypes';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export const useIsParentProduct = (currentUser) => {
  const location = useLocation();

  const pid = getProductId(location?.pathname);

  if (isNil(pid)) return false;

  const product = getProduct(currentUser, pid);

  if (isNil(product)) return false;

  return product.thirdPartyOption === thirdPartyEnums.PARENT;
};

export const isParentProduct = (currentUser, location) => {
  const pid = getProductId(location?.pathname);

  if (isNil(pid)) return false;

  const product = getProduct(currentUser, pid);

  if (isNil(product)) return false;

  return product.thirdPartyOption === thirdPartyEnums.PARENT;
};
