import { notification } from 'antd';

const openSnackBar = (type, info) => {
  notification[type]({
    message: info.message,
    description: info.description,
    placement: 'bottomRight',
  });
};

export default openSnackBar;
