import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import CommunicationListContainer from './container/CommunicationListContainer';
import CommunicationOverviewContainer from './container/CommunicationOverviewContainer';

export default function CommunicationList(props) {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}all`}>
        <CommunicationListContainer {...props} />
      </Route>
      <Route exact path={`${match.path}archive/`}>
        <CommunicationListContainer {...props} isArchived={true} />
      </Route>
      <Route exact path={`${match.path}overview/`}>
        <CommunicationOverviewContainer {...props} />
      </Route>
    </Switch>
  );
}
