import React, { useContext, useEffect, useState } from 'react';
import {
  CaretRightOutlined,
  EditFilled,
  LoadingOutlined,
  ArrowLeftOutlined as BackIcon,
} from '@ant-design/icons';
import { Select, Spin } from 'antd';

import { JourneySteps } from '../../utils/static';
import './appbar.less';
import { Button, Modal } from 'antd';
import { AppContext, JourneyObjectModel } from '../../app';
import Moment from 'moment';
import { useRef } from 'react';
import openSnackBar from './SnackBar';
import { thirdPartyEnums } from '@Modules/admin/thirdParty/components/ThirdPartyTypes';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { journeyTypes } from '@Modules/engage/journey/model/JourneyTypes';
import { useIsParentProduct } from '@Utils/isParentProduct';

const { confirm } = Modal;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const AppBar = (props) => {
  const appContext = useContext(AppContext);
  const inputTitleRef = useRef();

  const {
    journey,
    journeyId,
    newJourney,
    mode,
    publishAfterCreate,
    currentProduct,
    templateNames,
  } = props.data;

  const history = useHistory();
  const currentUser = useSelector((state) => state.account.auth.currentUser);

  const isParent = useIsParentProduct(currentUser);

  const [currentStep, setCurrentStep] = useState(0);
  const [name, setName] = useState(
    props.type === journeyTypes.RELAY
      ? 'Relay ' + Moment().format('YY-MM-DD.SSS')
      : props.type === journeyTypes.JOURNEY
      ? 'Journey ' + Moment().format('YY-MM-DD.SSS')
      : ''
  );
  const saveDraftButton = useRef();

  useEffect(() => {
    if (journey?.name) {
      setName(journey.name);
    }
  }, [journey]);

  useEffect(() => {
    if (newJourney) {
      if (newJourney.id && publishAfterCreate) {
        props.launch({
          id: newJourney.id,
          body: Object.assign({}, appContext.journey, {
            name,
          }),
        });
      } else if (newJourney.id) {
        let currentJourney = { ...appContext.journey };
        if (
          appContext.journey &&
          appContext.journey.hasOwnProperty('steps') &&
          Object.keys(appContext.journey).length === 1
        ) {
          currentJourney = { ...JourneyObjectModel, ...currentJourney };
        }
        props.updateSubmit({
          id: newJourney.id,
          body: Object.assign({}, currentJourney, { name }),
        });
        if (isParent) history.goBack();
      }
    }
  }, [newJourney]);

  useEffect(() => {
    const cu_index = JourneySteps.findIndex((m) => m.key === props.currentStep);
    setCurrentStep(cu_index);
  }, [props.currentStep]);

  /**
   * Save journey to server
   * @param {*} status //publish or draft
   */
  // eslint-disable-next-line no-unused-vars
  const save = async (status, redirect = true) => {
    const jnId = newJourney?.id || journeyId;

    if (jnId) {
      // if created
      if (status === 'publish') {
        // HasTriggerMinimalStep
        let hasMinimalStep = true;
        if (!appContext.journey?.steps?.length) {
          hasMinimalStep = false;
        } else if (
          !appContext.journey.steps.find(
            (step) => step?.ui?.nodeType === 'TRIGGER'
          )
        ) {
          hasMinimalStep = false;
        }
        if (!hasMinimalStep) {
          openSnackBar('error', {
            message: 'Minimal Trigger error!',
            description: 'Please add at least one Trigger to this Journey',
          });
          console.error('Not has minimal step');
          return;
        }
        const cacheRefinerid = props.refineryCache();

        // console.log('test------', cacheRefinerid, appContext.journey);
        if (cacheRefinerid?.countUiEdge !== cacheRefinerid?.countServerEdge) {
          props.submitLog({
            level: 'WARN',
            message: `Found problem in data journey #${jnId}`,
          });
          openSnackBar('error', {
            message: 'Unexpected error!',
            description:
              'Please contact by admin! Found problem in data journey.',
          });
          return;
        }
        const journeyData = cacheRefinerid.data;

        confirm({
          title: `Are you sure you want to publish the ${props.type.toLowerCase()}?`,
          onOk() {
            props.launch({
              id: jnId,
              body: Object.assign({}, journeyData, {
                name,
              }),
            });
          },
          onCancel() {},
        });
      } else {
        const cacheRefinerid = props.refineryCache();
        const journeyData = cacheRefinerid.data;

        // Clean step.ui.error
        const steps = journeyData.steps.map((step) => {
          delete step.ui.error;
          return step;
        });
        const body = Object.assign({}, journeyData, {
          steps,
          name,
        });
        props.updateSubmit({
          id: jnId,
          body,
          redirect: false,
          showNotificationMessage: true,
        });
        if (isParent) history.goBack();
      }
    } else {
      // if not created
      // NOTE: After created auto updated
      if (status === 'publish') {
        confirm({
          title: `Are you sure you want to publish the ${props.type.toLowerCase()}?`,
          onOk() {
            props.create({
              body: {
                name,
                type: props.type,
              },
              redirect: false,
              publishAfterCreate: true,
            });
          },
          onCancel() {},
        });
      } else {
        props.create({
          body: {
            name,
            type: props.type,
          },
          redirect: false,
          showNotificationMessage: true,
        });
      }
    }
  };

  const goNext = async () => {
    if (props.currentStep === 'publish') {
      save('publish');
    } else {
      // detect nextStep if exist
      const nextStepI =
        currentStep + 1 > JourneySteps.length - 1
          ? currentStep
          : currentStep + 1;

      if (nextStepI !== currentStep) {
        // get nextStep
        const nextStep = JourneySteps.find((m, i) => nextStepI === i);
        goTo_(nextStep.key, nextStepI, false);
      }
    }
  };

  const goTo_ = (page, i, redirect) => {
    save('draft', redirect);
    setCurrentStep(i);
    props.goNextStep(page);
  };

  // eslint-disable-next-line no-unused-vars
  const goTo = (page, i, redirect) => {
    if (props.loading) return;
    setCurrentStep(i);
    props.goNextStep(page);
  };

  const changeStatus = (status) => {
    switch (status) {
      case 'stop':
        confirm({
          title: 'Do you want to stop this journey?',
          onOk() {
            props.stop(journeyId);
          },
          onCancel() {},
        });
        break;
      case 'start':
        confirm({
          title: 'Do you want to start this journey?',
          onOk() {
            props.launch({
              id: journeyId,
              body: Object.assign({}, appContext.journey, {
                name,
              }),
            });
          },
          onCancel() {},
        });
        break;
      case 'pause':
        confirm({
          title: 'Do you want to sunset this journey?',
          onOk() {
            props.pause(journeyId);
          },
          onCancel() {},
        });
        break;
    }
  };

  const goEdit = (e) => {
    e.preventDefault();
    const url = props.history.location.pathname.replace('/report', '/view');
    props.history.push(url);
  };

  const handleTemplateNameChange = (value) => {
    setName(value);
  };

  return (
    <div
      className={
        props.currentStep === 'design' ? 'appbar' : 'appbar appbar__margin'
      }
    >
      <div className={'appbar__header'}>
        <div className={'header__left'}>
          {mode === 'report' ? (
            <span
              onClick={() => props.history.goBack()}
              className={'appbar__journey-back'}
            >
              <BackIcon style={{ fontSize: '1.2em' }} />
            </span>
          ) : (
            ''
          )}
          {mode !== 'report' &&
            currentProduct?.thirdPartyOption !== thirdPartyEnums.PARENT && (
              <EditFilled
                className={'appbar__header__icon'}
                onClick={() => inputTitleRef.current.focus()}
              />
            )}
          {currentProduct?.thirdPartyOption === thirdPartyEnums.PARENT && (
            <div>
              <span> TYPE: </span>
              <Select
                className={'appbar__name__template'}
                onChange={handleTemplateNameChange}
                disabled={!isNil(journey?.name)}
                value={name}
              >
                {templateNames.map((templateName) => {
                  return (
                    <Select.Option
                      key={templateName.name}
                      value={templateName.name}
                    >
                      {templateName.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          )}
          {currentProduct?.thirdPartyOption !== thirdPartyEnums.PARENT && (
            <input
              disabled={mode === 'report'}
              ref={inputTitleRef}
              className={'appbar__name'}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          )}
        </div>
        <div className={'appbar__tools'}>
          <div>
            {(props.loading || props.loadingFitView) && (
              <Spin indicator={antIcon} />
            )}
            {/* {mode !== 'report' && (
              <Tooltip
                title={`Auto Save (every 1 min.) is ${autoSave ? 'ON' : 'OFF'}`}
              >
                <Switch
                  defaultChecked
                  style={{ width: 30 }}
                  value={false}
                  onChange={(val) => {
                    autoSave.current = val;
                  }}
                />
              </Tooltip>
            )} */}
            {mode !== 'report' && (
              <Button
                onClick={save}
                type={
                  currentProduct?.thirdPartyOption === thirdPartyEnums.PARENT
                    ? 'primary'
                    : 'default'
                }
                disabled={props.loading}
                shape="round"
                ref={saveDraftButton}
              >
                SAVE DRAFT
              </Button>
            )}
            {mode !== 'report' &&
              currentProduct?.thirdPartyOption !== thirdPartyEnums.PARENT && (
                <Button
                  type="primary"
                  shape="round"
                  disabled={props.loading}
                  onClick={goNext}
                >
                  {props.currentStep === 'publish' ? 'PUBLISH' : 'NEXT'}
                </Button>
              )}
            {mode === 'report' && (
              <span className={'appbar__status'}>
                {!props.loading && (
                  <span>
                    &nbsp;STATUS:&nbsp;
                    <span className={`${'appbar__status--' + journey?.status}`}>
                      {journey?.status}
                    </span>
                    &nbsp;
                    <span className={`${'appbar__status--' + journey?.status}`}>
                      {journey?.status === 'STOPPED' ||
                      journey?.status === 'PAUSED' ? (
                        <a onClick={goEdit}>(edit)</a>
                      ) : (
                        ''
                      )}
                    </span>
                  </span>
                )}
                <span>
                  {(journey?.status === 'PAUSED' ||
                    journey?.status === 'STOPPED') && (
                    <Button
                      disabled={props.loading}
                      onClick={() => changeStatus('start')}
                    >
                      PLAY
                    </Button>
                  )}
                  {(journey?.status === 'RUNNING' ||
                    journey?.status === 'UPCOMING' ||
                    journey?.status === 'ENDED') && (
                    <Button
                      disabled={props.loading}
                      onClick={() => changeStatus('stop')}
                    >
                      STOP
                    </Button>
                  )}
                  {(journey?.status === 'RUNNING' ||
                    journey?.status === 'UPCOMING' ||
                    journey?.status === 'ENDED') && (
                    <Button
                      disabled={props.loading}
                      onClick={() => changeStatus('pause')}
                    >
                      PAUSE
                    </Button>
                  )}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
      {mode !== 'report' && (
        <div
          className={'appbar__journey-steps'}
          hidden={currentProduct?.thirdPartyOption === thirdPartyEnums.PARENT}
        >
          <div
            onClick={() => props.history.goBack()}
            className={'appbar__journey-back'}
          >
            <BackIcon style={{ fontSize: '1.2em' }} />
          </div>
          {JourneySteps.map((step, index) => (
            <div
              className={
                'journey-step' +
                (currentStep === index ? ' active' : '') +
                (props.loading ? ' step--on-loading' : '')
              }
              key={index}
              onClick={() => goTo(step.key, index, false)}
            >
              <div className={'journey-step__name'}>{step.name}</div>
              {JourneySteps.length > index + 1 && (
                <CaretRightOutlined style={{ color: '#eaeaea' }} />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default AppBar;
