export const OVERVIEW_LIST_TAG_FETCH = 'OVERVIEW_LIST_TAG_FETCH';
export const OVERVIEW_LIST_TAG_FETCH_FAIL = 'OVERVIEW_LIST_TAG_FETCH_FAIL';
export const OVERVIEW_LIST_TAG_FETCH_SUCCESS =
  'OVERVIEW_LIST_TAG_FETCH_SUCCESS';

export const OVERVIEW_FETCH = 'OVERVIEW_FETCH';
export const OVERVIEW_FETCH_FAIL = 'OVERVIEW_FETCH_FAIL';
export const OVERVIEW_FETCH_SUCCESS = 'OVERVIEW_FETCH_SUCCESS';

export const OVERVIEW_SPLIT_OVERVIEW_FETCH = 'OVERVIEW_SPLIT_OVERVIEW_FETCH';
export const OVERVIEW_SPLIT_OVERVIEW_FETCH_FAIL =
  'OVERVIEW_SPLIT_OVERVIEW_FETCH_FAIL';
export const OVERVIEW_SPLIT_OVERVIEW_FETCH_SUCCESS =
  'OVERVIEW_SPLIT_OVERVIEW_FETCH_SUCCESS';
