export const thirdPartyEnums = {
  NORMAL: 'NORMAL',
  PARENT: 'PARENT',
  CHILD: 'CHILD',
};

export const PAGE_LOCATION = {
  LIST: '/list',
  VIEW: '/view',
};
