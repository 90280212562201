export function findProductThrottlingEnabled(currentUser, activeProductId) {
  let currentProject = null;
  if (currentUser && Array.isArray(currentUser.products)) {
    currentProject = currentUser.products.filter(
      (item) => activeProductId === item.id
    );
  }
  if (
    currentProject &&
    Array.isArray(currentProject) &&
    currentProject[0].hasOwnProperty('throttlingEnabled') &&
    currentProject[0].throttlingEnabled === true
  ) {
    return true;
  }
  return false;
}
