export const flavors = {
  INTRACK: 'intrack',
  MCI: 'mci',
  DK: 'dk',
  SNAPP: 'snapp',
  OZONE: 'ozone',
};

export function detectFlavor() {
  const location = window.location.href;
  if (location.includes('snapp.promo')) {
    return flavors.SNAPP;
  } else if (location.includes('mci.promo')) {
    return flavors.MCI;
  } else if (location.includes('ecrm.digikala.promo')) {
    return flavors.DK;
  } else if (location.includes('ecrm.ozone.ir')) {
    return flavors.OZONE;
  } else {
    return flavors.INTRACK;
  }
}
