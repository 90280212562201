import { showMessageError } from '../../../utils/RenderUtils';

import {
  OVERVIEW_LIST_TAG_FETCH,
  OVERVIEW_LIST_TAG_FETCH_FAIL,
  OVERVIEW_LIST_TAG_FETCH_SUCCESS,
  OVERVIEW_FETCH,
  OVERVIEW_FETCH_FAIL,
  OVERVIEW_FETCH_SUCCESS,
  OVERVIEW_SPLIT_OVERVIEW_FETCH,
  OVERVIEW_SPLIT_OVERVIEW_FETCH_FAIL,
  OVERVIEW_SPLIT_OVERVIEW_FETCH_SUCCESS,
} from './OverviewConstants';
import OverviewApi from './OverviewApi';

export function overviewListTagsFetch() {
  return (dispatch) => {
    dispatch({
      type: OVERVIEW_LIST_TAG_FETCH,
    });
    OverviewApi.listTags()
      .then((response) => {
        dispatch({
          type: OVERVIEW_LIST_TAG_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: OVERVIEW_LIST_TAG_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function overviewFetch(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: OVERVIEW_FETCH,
      payload: pageRequest,
    });
    OverviewApi.overview(pageRequest)
      .then((response) => {
        dispatch({
          type: OVERVIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: OVERVIEW_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function overviewSplitOverviewFetch(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: OVERVIEW_SPLIT_OVERVIEW_FETCH,
      payload: pageRequest,
    });
    OverviewApi.splitOverview(pageRequest)
      .then((response) => {
        dispatch({
          type: OVERVIEW_SPLIT_OVERVIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: OVERVIEW_SPLIT_OVERVIEW_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
