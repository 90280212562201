import EmailTemplateComponent from '../component/EmailTemplateComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  emailTemplateDuplicateFetch,
  emailTemplateDeleteFetch,
  emailTemplateUpdateSubmitFetch,
  emailSearchTemplateListFetch,
  emailTemplateListFetch,
  emailTemplateChangeCurrent,
  emailTemplateToggleModal,
} from '../EmailTemplateActions';

import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  page: state.channel.emailTemplate.page,
  loadingList: state.channel.emailTemplate.loadingList,
  loadingSearchList: state.channel.emailTemplate.loadingSearchList,
  pageRequest: state.channel.emailTemplate.pageRequest,
  errors: state.channel.emailTemplate.errors,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      emailTemplateDeleteFetch,
      emailTemplateDuplicateFetch,
      emailSearchTemplateListFetch,
      emailTemplateUpdateSubmitFetch,
      emailTemplateListFetch,
      emailTemplateChangeCurrent,
      emailTemplateToggleModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailTemplateComponent);

EmailTemplateComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
};
