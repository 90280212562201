export const EMAIL_TEMPLATE_DELETE_FETCH = 'EMAIL_TEMPLATE_DELETE_FETCH';
export const EMAIL_TEMPLATE_DELETE_FETCH_FAIL =
  'EMAIL_TEMPLATE_DELETE_FETCH_FAIL';
export const EMAIL_TEMPLATE_DELETE_FETCH_SUCCESS =
  'EMAIL_TEMPLATE_DELETE_FETCH_SUCCESS';

export const EMAIL_TEMPLATE_DUPLICATE_FETCH = 'EMAIL_TEMPLATE_DUPLICATE_FETCH';
export const EMAIL_TEMPLATE_DUPLICATE_FETCH_FAIL =
  'EMAIL_TEMPLATE_DUPLICATE_FETCH_FAIL';
export const EMAIL_TEMPLATE_DUPLICATE_FETCH_SUCCESS =
  'EMAIL_TEMPLATE_DUPLICATE_FETCH_SUCCESS';

export const EMAIL_TEMPLATE_CREATE_FETCH = 'EMAIL_TEMPLATE_CREATE_FETCH';
export const EMAIL_TEMPLATE_CREATE_FETCH_FAIL =
  'EMAIL_TEMPLATE_CREATE_FETCH_FAIL';
export const EMAIL_TEMPLATE_CREATE_FETCH_SUCCESS =
  'EMAIL_TEMPLATE_CREATE_FETCH_SUCCESS';

export const EMAIL_TEMPLATE_LIST_ALL_FETCH = 'EMAIL_TEMPLATE_LIST_ALL_FETCH';
export const EMAIL_TEMPLATE_LIST_ALL_FETCH_FAIL =
  'EMAIL_TEMPLATE_LIST_ALL_FETCH_FAIL';
export const EMAIL_TEMPLATE_LIST_ALL_FETCH_SUCCESS =
  'EMAIL_TEMPLATE_LIST_ALL_FETCH_SUCCESS';

export const EMAIL_TEMPLATE_VIEW_FETCH = 'EMAIL_TEMPLATE_VIEW_FETCH';
export const EMAIL_TEMPLATE_VIEW_FETCH_FAIL = 'EMAIL_TEMPLATE_VIEW_FETCH_FAIL';
export const EMAIL_TEMPLATE_VIEW_FETCH_SUCCESS =
  'EMAIL_TEMPLATE_VIEW_FETCH_SUCCESS';

export const EMAIL_TEMPLATE_SEARCH_LIST_FETCH =
  'EMAIL_TEMPLATE_SEARCH_LIST_FETCH';
export const EMAIL_TEMPLATE_SEARCH_LIST_FETCH_FAIL =
  'EMAIL_TEMPLATE_SEARCH_LIST_FETCH_FAIL';
export const EMAIL_TEMPLATE_SEARCH_LIST_FETCH_SUCCESS =
  'EMAIL_TEMPLATE_SEARCH_LIST_FETCH_SUCCESS';

export const EMAIL_TEMPLATE_LIST_FETCH = 'EMAIL_TEMPLATE_LIST_FETCH';
export const EMAIL_TEMPLATE_LIST_FETCH_FAIL = 'EMAIL_TEMPLATE_LIST_FETCH_FAIL';
export const EMAIL_TEMPLATE_LIST_FETCH_SUCCESS =
  'EMAIL_TEMPLATE_LIST_FETCH_SUCCESS';

export const EMAIL_TEMPLATE_UPDATE_FETCH = 'EMAIL_TEMPLATE_UPDATE_FETCH';
export const EMAIL_TEMPLATE_UPDATE_FETCH_FAIL =
  'EMAIL_TEMPLATE_UPDATE_FETCH_FAIL';
export const EMAIL_TEMPLATE_UPDATE_FETCH_SUCCESS =
  'EMAIL_TEMPLATE_UPDATE_FETCH_SUCCESS';

export const EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH =
  'EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH';
export const EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH_FAIL =
  'EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH_FAIL';
export const EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH_SUCCESS =
  'EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH_SUCCESS';

export const EMAIL_TEMPLATE_CHANGE_CURRENT = 'EMAIL_TEMPLATE_CHANGE_CURRENT';
export const EMAIL_TEMPLATE_PAGE_CHANGE_CURRENT =
  'EMAIL_TEMPLATE_PAGE_CHANGE_CURRENT';
export const EMAIL_TEMPLATE_TOGGLE_MODAL = 'EMAIL_TEMPLATE_TOGGLE_MODAL';
