import * as React from 'react';

function SvgWait(props) {
  return (
    <svg viewBox="0 0 25 27" width="1em" height="1em" {...props}>
      <g
        transform="translate(1 1)"
        stroke="#FFF"
        fill="none"
        fillRule="evenodd"
      >
        <circle strokeWidth={2} cx={11.5} cy={13.5} r={11} />
        <path
          d="M5.5 23L4 25m13.5-2l1.5 2"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <path d="M12 1v1" strokeWidth={3} strokeLinecap="round" />
        <path
          strokeWidth={1.5}
          strokeLinecap="round"
          d="M11.574 6.943v7.279l4.637 3.287"
        />
        <circle fill="#EF5350" cx={11.5} cy={14} r={1} />
      </g>
    </svg>
  );
}

export default SvgWait;
