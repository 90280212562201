exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".teamInvitation-module__layout--1QM5X {\n  display: flex;\n  flex-direction: column;\n  width: 150px;\n}\n.teamInvitation-module__layoutItem--3TuEN {\n  color: grey;\n  font-size: 15px;\n  margin-top: 11px;\n  margin-bottom: 28px;\n  margin-right: 20px;\n}\n.teamInvitation-module__input--tDOaV {\n  margin-top: 10px;\n  margin-bottom: 10px;\n  width: 200px;\n}\n.teamInvitation-module__register-wrapper--3HZ-V {\n  background-color: #f2f2f2;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n.teamInvitation-module__register-container--1KEAu {\n  width: -moz-fit-content;\n  width: fit-content;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background-color: #fff;\n  padding: 24px;\n  height: 100%;\n  width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"layout": "teamInvitation-module__layout--1QM5X",
	"layoutItem": "teamInvitation-module__layoutItem--3TuEN",
	"input": "teamInvitation-module__input--tDOaV",
	"register-wrapper": "teamInvitation-module__register-wrapper--3HZ-V",
	"register-container": "teamInvitation-module__register-container--1KEAu"
};