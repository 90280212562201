import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const styles = {
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};

const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;

export default function InputSpinner() {
  return (
    <div style={styles.inputLoadingSpinStyle}>
      <Spin indicator={inputLoadingSpin} />
    </div>
  );
}
