import { combineReducers } from 'redux';
import smsChannel from '@Modules/channel/sms/SmsChannelState';
import emailChannel from '@Modules/channel/email/EmailChannelState';
import pushChannel from '@Modules/channel/push/PushChannelState';
import customChannel from '@Modules/channel/custom/CustomChannelState';
import emailTemplate from '@Modules/channel/emailTemplate/EmailTemplateState';
import whatsAppChannel from './whatsAppChannelSlice';

const reducer = combineReducers({
  smsChannel,
  emailChannel,
  emailTemplate,
  pushChannel,
  customChannel,
  whatsAppChannel,
});
export default reducer;
