import * as React from 'react';

function SvgInApp(props) {
  return (
    <svg viewBox="0 0 17 26" width="1em" height="1em" {...props}>
      <path
        d="M15.015 26H1.235C.552 26 0 25.542 0 24.99V1.01C0 .45.56 0 1.235 0h13.78c.683 0 1.235.458 1.235 1.01v23.98c0 .56-.552 1.01-1.235 1.01zm-6.89-1.625a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25zM1.625 3.25h13V19.5h-13V3.25z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgInApp;
