import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './BusinessEventCreateComponent.module.less';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  businessEventCreateFetch,
  businessEventCreateClearError,
} from '@Redux/slices/data/businessEventSlice';
import Card from '@Utils/Card';

export default function BusinessEventCreateComponent() {
  const dispatch = useDispatch();
  const [nameError, setNameError] = useState('');
  const loadingCreate = useSelector(
    (state) => state.dataModule.dataApp.loading
  );
  const errors = useSelector(
    (state) => state.dataModule.businessEvent.errorsCreate
  );

  useEffect(() => {
    return () => {
      dispatch(businessEventCreateClearError(errors));
      setNameError('');
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(errors)) {
      const nameErrorInfo = errors.find((err) => {
        return err.key === 'name';
      });

      setNameError(nameErrorInfo?.message);
    }
  }, [errors]);

  const attrNameError = (field) => {
    const attrErrorInfo = errors.find((err) => {
      return err.key === field;
    });
    if (attrErrorInfo?.message) {
      return {
        hasFeedback: true,
        help: attrErrorInfo?.message,
        validateStatus: 'error',
      };
    } else null;
  };

  const { Option } = Select;
  const typeOptions = [
    { name: 'String', id: 'STRING' },
    { name: 'Number', id: 'NUMERIC' },
    { name: 'Boolean', id: 'BOOLEAN' },
    { name: 'Date', id: 'DATE' },
  ];

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = (values) => {
    dispatch(businessEventCreateFetch(values));
  };
  return (
    <div>
      <Card>
        <div className={styles.header}>
          <div className={styles.headerTitle}>Create New Business Event</div>
        </div>
        <div>
          <Form
            name="businessEvent"
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className={styles.businessEvent}
            fields={[
              {
                attributes: [
                  {
                    type: 'BOOLEAN',
                  },
                ],
              },
            ]}
          >
            <Row style={{ marginBottom: 20 }}>
              <Col span={4} className={styles.itemTitle}>
                event name
              </Col>
              <Col span={10}>
                <Form.Item
                  name={'name'}
                  rules={[
                    {
                      required: true,
                      message: 'name is required',
                    },
                  ]}
                  {...(nameError && {
                    hasFeedback: true,
                    help: nameError,
                    validateStatus: 'error',
                  })}
                  style={{ maxWidth: 400 }}
                >
                  <Input onChange={() => setNameError('')} id="error" />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: 8 }}>
              <Col span={4} className={styles.itemTitle}>
                attributes
              </Col>
              <Col span={12} className={styles.itemTitle}>
                attribute name
              </Col>
              <Col span={8} className={styles.itemTitle}>
                attribute data type
              </Col>
            </Row>
            <Form.List name="attributes" initialValue={[{ type: 'STRING' }]}>
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, index, arry) => (
                      <Row key={field.key} align="start">
                        <Col span={10} push={4}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'name']}
                            fieldKey={[field.fieldKey, 'name']}
                            rules={[
                              {
                                required: true,
                                message: 'Attribute name is required',
                              },
                            ]}
                            {...attrNameError(`attributes[${index}].name`)}
                          >
                            <Input
                              onChange={() =>
                                dispatch(
                                  businessEventCreateClearError(
                                    errors,
                                    `attributes[${index}].name`
                                  )
                                )
                              }
                              placeholder="Attribute Name"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8} push={6}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'type']}
                            fieldKey={[field.fieldKey, 'type']}
                            rules={[
                              {
                                required: true,
                                message: 'Attribute type is required',
                              },
                            ]}
                          >
                            <Select
                              style={{ width: 120 }}
                              placeholder={'Select Type'}
                            >
                              {typeOptions.map((option) => (
                                <Option key={option.id} value={option.id}>
                                  {option.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          {arry.length !== 1 && (
                            <div
                              onClick={() => {
                                remove(field.name);
                              }}
                              className={`fl-delete fl-dark ${styles.removeIcon}`}
                            ></div>
                          )}
                        </Col>
                      </Row>
                    ))}

                    <Button
                      onClick={() => {
                        add();
                      }}
                      type="primary"
                      shape="round"
                      ghost
                    >
                      add attribute
                    </Button>
                  </>
                );
              }}
            </Form.List>
            <div className="dnd-footer">
              <Button
                type="primary"
                shape="round"
                size={'large'}
                htmlType="submit"
                className="w-200"
                loading={loadingCreate}
              >
                create business event
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </div>
  );
}
