import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
};

const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;

export default function Loading() {
  return (
    <div style={styles.loadingSpinStyle}>
      <Spin indicator={loadingSpin} />
    </div>
  );
}
