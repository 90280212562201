import BaseApi from '../../../api/BaseApi';
const basePath = '/report/management-dashboard';
export default class managementDashboardApi {
  static get(body) {
    return BaseApi.post(basePath, body);
  }
  static store(body) {
    return BaseApi.post(basePath + '/store-customization', body);
  }
  static downloadReport(body) {
    return BaseApi.post(basePath + '/download', body);
  }
}
