import AnalyticsComponent from '../../../../component/analyticsChart/AnalyticsComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  productEventListFetch,
  productEventAnalyzeFetch,
  eventChangeCurrent,
} from '../EventAnalyticsActions';

import {
  productSegmentRecommendUserAttributesFetch,
  productSegmentRecommendDeviceAttributesFetch,
  productSegmentRecommendEventAttributesFetch,
} from '../../../insight/productSegment/ProductSegmentActions';

const mapStateToProps = (state) => ({
  eventList: state.track.events.eventList,
  eventAnalytics: state.track.events.eventAnalytics,
  eventAnalyticsValue: state.track.events.eventAnalyticsValue,
  loadingEventAnalytics: state.track.events.loadingEventAnalytics,
  isRefreshed: state.track.events.isRefreshed,
  eventAnalyticsErrors: state.track.events.eventAnalyticsErrors,
  pathname: state.router.location.pathname,
  loadingRecommendUserAttributes:
    state.insight.productSegment.loadingRecommendUserAttributes,
  recommendUserAttributes: state.insight.productSegment.recommendUserAttributes,
  loadingRecommendEventAttributes:
    state.insight.productSegment.loadingRecommendEventAttributes,
  recommendEventAttributes:
    state.insight.productSegment.recommendEventAttributes,
  loadingRecommendDeviceAttributes:
    state.insight.productSegment.loadingRecommendDeviceAttributes,
  recommendDeviceAttributes:
    state.insight.productSegment.recommendDeviceAttributes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      productEventListFetch,
      productEventAnalyzeFetch,
      eventChangeCurrent,
      productSegmentRecommendUserAttributesFetch,
      productSegmentRecommendDeviceAttributesFetch,
      productSegmentRecommendEventAttributesFetch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsComponent);

AnalyticsComponent.propTypes = {};
