import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../app';
import { Button, Form, Input, Select, Modal } from 'antd';
import ProductSegmentPredicateComponent from './../../../../modules/insight/productSegment/component/ProductSegmentPredicateComponent';
import { convertMinute, timesOptions } from '../../../../utils/DateUtils';
import { calcMinuteTime, isUsedOnWaitForDate } from '../../utils/hooks';
import _ from 'lodash';
const { confirm } = Modal;

const predicateDefault = {
  event: null,
  type: 'WAIT_FOR_EVENT',
  negate: false,
  eventAggregation: 'EXISTS',
};

const FlowWaitForEvent = (props) => {
  const [form] = Form.useForm();
  const { journey, syncCache } = useContext(AppContext);
  const {
    events,
    attributes,
    fetchRecommendDeviceAttributes,
    fetchRecommendEventAttributes,
    fetchRecommendUserAttributes,
    recommendDeviceAttributes,
    recommendEventAttributes,
    recommendUserAttributes,
  } = props.data;
  const [predicate, setPredicate] = useState(predicateDefault);

  useEffect(() => {
    const currentStep = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );
    setPredicate(Object.assign({}, predicateDefault, currentStep));
  }, []);

  const resetConfirm = (stepsThatUsed, e) => {
    const ids = stepsThatUsed.map((m) => {
      return 'State-' + m.id + ',';
    });

    confirm({
      width: '50vw',
      title: `Warning to change this node`,
      content: `Making a change has an effect on Wait For Date block(s),
      Blocks with following states have been reset:${ids}.
      Please reconfigure them before publishing the journey.`,
      onOk() {
        let stepsMustUpdate = [];
        stepsThatUsed.forEach((m) => {
          const step2 = journey.steps.find(
            (step) => step.id + '' === m.id + ''
          );
          let step_ = Object.assign({}, step2, {
            stepId: null,
            waitForAttributeType: 'USER_ATTRIBUTE',
            eventAttribute: null,
            delayMinutes: null,
            bufferMinutes: null,
            userAttribute: null,
          });
          stepsMustUpdate.push(step_);
          // syncCache("update-step", step_, false);
        });

        const currentStep = journey.steps.find(
          (step) => step.id + '' === props.node.id + ''
        );
        const waitMinutes = calcMinuteTime(e.time, e.type);
        const currentStepUpdated = Object.assign({}, currentStep, predicate, {
          waitMinutes,
        });
        _.unset(currentStepUpdated, 'ui.error');

        if (stepsMustUpdate.length) {
          // batch update
          syncCache(
            'update-step',
            [currentStepUpdated, ...stepsMustUpdate],
            true
          );
        } else {
          syncCache('update-step', currentStepUpdated, true);
        }
        setTimeout(() => props.closeModal(false, true), 100);
      },
      onCancel() {},
    });
  };

  const onFinish = (e) => {
    const currentStep = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );
    // check is used on WaitForDate or no
    if (currentStep?.event && predicate?.event !== currentStep?.event) {
      const stepsThatUsed = isUsedOnWaitForDate(journey, props.node.id);
      if (stepsThatUsed.length) {
        resetConfirm(stepsThatUsed, e);
        return;
      }
    }

    const waitMinutes = calcMinuteTime(e.time, e.type);
    const currentStepUpdated = Object.assign({}, currentStep, predicate, {
      waitMinutes,
    });
    delete currentStepUpdated.ui?.error;
    syncCache('update-step', currentStepUpdated, true);
    // props.closeModal()
    setTimeout(() => props.closeModal(false, true), 100);
  };

  // const calcMinuteTime = (time, type) => {
  //   let z = timesOptions().filter((t) => t.id === type)[0].min;
  //   return Number(time * z);
  // };

  const handleChangePredicate = (changedPredicate) => {
    setPredicate(changedPredicate);
  };

  const selectedNode = journey?.steps?.filter(
    (item) => item.id + '' === props?.node?.id
  );
  const timeMinute = selectedNode.length && selectedNode[0]?.waitMinutes;
  const time = convertMinute(timeMinute);

  return (
    <>
      <Form form={form} onFinish={onFinish} className="modal_wrap">
        <div className={'modal-body'}>
          <Form.Item>
            <ProductSegmentPredicateComponent
              readOnly={props.readOnly}
              journey={true}
              type="WAIT_FOR_EVENT"
              predicate={predicate}
              attributes={attributes}
              events={events}
              isInSegment={false}
              handleChangePredicate={(changedPredicate) =>
                handleChangePredicate(changedPredicate)
              }
              clearSegmentError={() => {
                //TODO Must Handle
              }}
              fetchRecommendEventAttributes={fetchRecommendEventAttributes}
              recommendEventAttributes={recommendEventAttributes}
              fetchRecommendDeviceAttributes={fetchRecommendDeviceAttributes}
              recommendDeviceAttributes={recommendDeviceAttributes}
              fetchRecommendUserAttributes={fetchRecommendUserAttributes}
              recommendUserAttributes={recommendUserAttributes}
            />
          </Form.Item>
          <div className="content__item content__item__time">
            <span>
              <Form.Item
                label="Up to"
                name="time"
                rules={[
                  {
                    required: true,
                    message: 'Please set an time!',
                  },
                ]}
                initialValue={time.value}
              >
                <Input readOnly={props.readOnly} style={{ width: 200 }} />
              </Form.Item>
            </span>
            <span>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Please set an type!',
                  },
                ]}
                initialValue={time.type}
              >
                <Select
                  style={{ width: 200 }}
                  placeholder="Select Type"
                  disabled={props.readOnly}
                >
                  {timesOptions().map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </span>
            <span>
              <Form.Item>
                <div>
                  from entry into this block and only then proceed from this
                  block
                </div>
              </Form.Item>
            </span>
          </div>
        </div>
        <div className={'modal-footer'}>
          <Form.Item>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              disabled={props.readOnly}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
export default FlowWaitForEvent;
