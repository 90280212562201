import * as React from 'react';

function SvgWaitForDate(props) {
  return (
    <svg viewBox="0 0 25 25" width="1em" height="1em" {...props}>
      <g fill="#FFF" fillRule="evenodd">
        <path
          d="M21.236 3.862v-.925A1.948 1.948 0 0019.282 1h-.204a1.948 1.948 0 00-1.954 1.937v.925H7.877v-.925A1.948 1.948 0 005.923 1h-.205a1.947 1.947 0 00-1.953 1.937v.925H1V24h23V3.862h-2.764zm-3.132-.925a.97.97 0 01.974-.965h.204a.97.97 0 01.974.965V5.46a.971.971 0 01-.974.966h-.204a.971.971 0 01-.974-.966V2.937zm-13.36 0a.97.97 0 01.974-.965h.205a.97.97 0 01.973.965V5.46a.97.97 0 01-.973.966h-.205a.97.97 0 01-.973-.966V2.937zM1.98 4.834h1.785v.626c0 1.069.876 1.937 1.953 1.937h.205A1.948 1.948 0 007.877 5.46v-.626h9.247v.626c0 1.069.877 1.937 1.954 1.937h.204a1.948 1.948 0 001.954-1.937v-.626h1.784v5.213H1.98V4.834zm21.04 18.194H1.98v-12.01h21.04v12.01h.001z"
          stroke="#FFF"
        />
        <path d="M5.65 17.952c.568 0 1.027-.456 1.027-1.019s-.46-1.019-1.028-1.019c-.567 0-1.027.456-1.027 1.02 0 .562.46 1.018 1.027 1.018zm0 3.398c.568 0 1.027-.458 1.027-1.02 0-.563-.46-1.02-1.028-1.02-.567 0-1.027.457-1.027 1.02 0 .562.46 1.02 1.027 1.02zm3.425-3.398c.567 0 1.027-.456 1.027-1.019s-.46-1.019-1.027-1.019-1.028.456-1.028 1.02c0 .562.46 1.018 1.028 1.018zm0 3.398c.567 0 1.027-.458 1.027-1.02 0-.563-.46-1.02-1.027-1.02s-1.028.457-1.028 1.02c0 .562.46 1.02 1.028 1.02zm3.425-6.794c.568 0 1.028-.456 1.028-1.019s-.46-1.02-1.028-1.02c-.567 0-1.027.457-1.027 1.02s.46 1.02 1.027 1.02zm0 3.396c.568 0 1.028-.456 1.028-1.019s-.46-1.019-1.028-1.019c-.567 0-1.027.456-1.027 1.02 0 .562.46 1.018 1.027 1.018zm0 3.398c.568 0 1.028-.458 1.028-1.02 0-.563-.46-1.02-1.028-1.02-.567 0-1.027.457-1.027 1.02 0 .562.46 1.02 1.027 1.02zm3.426-6.794c.567 0 1.027-.456 1.027-1.019s-.46-1.02-1.027-1.02c-.568 0-1.028.457-1.028 1.02s.46 1.02 1.028 1.02zm0 3.396c.567 0 1.027-.456 1.027-1.019s-.46-1.019-1.027-1.019c-.568 0-1.028.456-1.028 1.02 0 .562.46 1.018 1.028 1.018zm0 3.398c.567 0 1.027-.458 1.027-1.02 0-.563-.46-1.02-1.027-1.02-.568 0-1.028.457-1.028 1.02 0 .562.46 1.02 1.028 1.02zm3.424-6.794c.568 0 1.029-.456 1.029-1.019s-.46-1.02-1.028-1.02c-.568 0-1.028.457-1.028 1.02s.46 1.02 1.028 1.02zm0 3.396c.568 0 1.029-.456 1.029-1.019s-.46-1.019-1.028-1.019c-.568 0-1.028.456-1.028 1.02 0 .562.46 1.018 1.028 1.018z" />
      </g>
    </svg>
  );
}

export default SvgWaitForDate;
