import {
  SMS_CHANNEL_DELETE_FETCH,
  SMS_CHANNEL_DELETE_FETCH_FAIL,
  SMS_CHANNEL_DELETE_FETCH_SUCCESS,
  SMS_CHANNEL_CREATE_FETCH,
  SMS_CHANNEL_CREATE_FETCH_FAIL,
  SMS_CHANNEL_CREATE_FETCH_SUCCESS,
  SMS_CHANNEL_LIST_FETCH,
  SMS_CHANNEL_LIST_FETCH_FAIL,
  SMS_CHANNEL_LIST_FETCH_SUCCESS,
  SMS_CHANNEL_UPDATE_FETCH,
  SMS_CHANNEL_UPDATE_FETCH_FAIL,
  SMS_CHANNEL_UPDATE_FETCH_SUCCESS,
  SMS_CHANNEL_UPDATE_SUBMIT_FETCH,
  SMS_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
  SMS_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
  SMS_CHANNEL_REFRESH_TOKEN_FETCH,
  SMS_CHANNEL_REFRESH_TOKEN_FETCH_FAIL,
  SMS_CHANNEL_REFRESH_TOKEN_FETCH_SUCCESS,
  SMS_CHANNEL_CHANGE_CURRENT,
  SMS_CHANNEL_PAGE_CHANGE_CURRENT,
  INFO_ENUM_LIST_FETCH,
  INFO_ENUM_LIST_FETCH_FAIL,
  INFO_ENUM_LIST_FETCH_SUCCESS,
  SMS_CHANNEL_SEARCH_LIST_FETCH,
  SMS_CHANNEL_SEARCH_LIST_FETCH_SUCCESS,
  SMS_CHANNEL_SEARCH_LIST_FETCH_FAIL,
} from './SmsChannelConstants';
import SmsChannelApi from './SmsChannelApi';
import toastr from 'toastr';
import { showMessageError } from '../../../utils/RenderUtils';

const loadSmsChannelList = (dispatch, pageRequest) => {
  dispatch({
    type: SMS_CHANNEL_LIST_FETCH,
    payload: pageRequest,
  });
  SmsChannelApi.list(pageRequest)
    .then((response) => {
      dispatch({
        type: SMS_CHANNEL_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: SMS_CHANNEL_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function smsChannelListFetch(pageRequest) {
  return (dispatch) => loadSmsChannelList(dispatch, pageRequest);
}
const loadSmsSearchChannelList = (dispatch, pageRequest) => {
  dispatch({
    type: SMS_CHANNEL_SEARCH_LIST_FETCH,
    payload: pageRequest,
  });
  SmsChannelApi.list(pageRequest)
    .then((response) => {
      dispatch({
        type: SMS_CHANNEL_SEARCH_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: SMS_CHANNEL_SEARCH_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function smsChanneSearchlListFetch(pageRequest) {
  return (dispatch) => loadSmsSearchChannelList(dispatch, pageRequest);
}

export function smsChannelChangeCurrent(smsChannel) {
  return (dispatch) => {
    dispatch({
      type: SMS_CHANNEL_CHANGE_CURRENT,
      payload: smsChannel,
    });
  };
}

export function smsChannelPageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: SMS_CHANNEL_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function smsChannelCreateFetch(smsChannel, pageRequest) {
  return (dispatch) => {
    dispatch({
      type: SMS_CHANNEL_CREATE_FETCH,
      payload: smsChannel,
    });
    SmsChannelApi.create(smsChannel)
      .then((response) => {
        dispatch({
          type: SMS_CHANNEL_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been created successfully');
        loadSmsChannelList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: SMS_CHANNEL_CREATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function smsChannelUpdateFetch(id, isEdit = true) {
  return (dispatch) => {
    dispatch({
      type: SMS_CHANNEL_UPDATE_FETCH,
    });
    const method = isEdit ? SmsChannelApi.update : SmsChannelApi.view;
    method(id)
      .then((response) => {
        dispatch({
          type: SMS_CHANNEL_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: SMS_CHANNEL_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function smsChannelUpdateSubmitFetch(id, smsChannel, pageRequest) {
  return (dispatch) => {
    dispatch({
      type: SMS_CHANNEL_UPDATE_SUBMIT_FETCH,
      payload: smsChannel,
    });
    SmsChannelApi.updateSubmit(id, smsChannel)
      .then((response) => {
        dispatch({
          type: SMS_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been updated successfully');
        loadSmsChannelList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: SMS_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function smsChannelDeleteFetch(id, pageRequest) {
  return (dispatch) => {
    dispatch({ type: SMS_CHANNEL_DELETE_FETCH });
    SmsChannelApi.delete(id)
      .then((response) => {
        dispatch({
          type: SMS_CHANNEL_DELETE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been deleted successfully');
        loadSmsChannelList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: SMS_CHANNEL_DELETE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function smsChannelRefreshTokenFetch(id) {
  return (dispatch) => {
    dispatch({ type: SMS_CHANNEL_REFRESH_TOKEN_FETCH });
    SmsChannelApi.refreshToken(id)
      .then((response) => {
        dispatch({
          type: SMS_CHANNEL_REFRESH_TOKEN_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Token refresh requested successfully');
      })
      .catch((response) => {
        dispatch({
          type: SMS_CHANNEL_REFRESH_TOKEN_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function infoEnumListFetch() {
  return (dispatch) => {
    dispatch({ type: INFO_ENUM_LIST_FETCH });
    SmsChannelApi.enumList()
      .then((response) => {
        dispatch({
          type: INFO_ENUM_LIST_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: INFO_ENUM_LIST_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
