import BaseApi from '../../../api/BaseApi';

const basePath = '/auth/profile';
class ProfileApi {
  static view() {
    return BaseApi.post(basePath + '/view', {});
  }
  static update() {
    return BaseApi.post(basePath + '/update', {});
  }
  static updateSubmit(body) {
    return BaseApi.post(basePath + '/updateSubmit', body);
  }
  static changePassword() {
    return BaseApi.post(basePath + '/changePassword', {});
  }
  static updateLastProductSubmit(body) {
    return BaseApi.post(basePath + '/updateLastProductSubmit', body);
  }
  static changePasswordSubmit(body) {
    return BaseApi.post(basePath + '/changePasswordSubmit', body);
  }
  static uploadPicture(file) {
    return BaseApi.uploadFile(basePath + '/uploadPicture', file);
  }
  static getEmailVerificationOtpCode(body) {
    return BaseApi.post(basePath + '/getEmailVerificationOtpCode', body);
  }
  static verifyEmail(body) {
    return BaseApi.post(basePath + '/verifyEmail', body);
  }
  static getResetPassCode(body) {
    return BaseApi.post(basePath + '/reset-password', body);
  }
  static verifyResetPass(body) {
    return BaseApi.post(basePath + '/reset-password-submit', body);
  }
}

export default ProfileApi;
