/* eslint-disable max-len */
import React from 'react';

import { isNil } from 'lodash';

import { viewDuration, readableDateTime } from '../../../../utils/DateUtils';

class UserDeviceDetailsComponent extends React.Component {
  renderItem = (item) => {
    if (this.props.device && !isNil(this.props.device[item])) {
      if (Array.isArray(this.props.device[item])) {
        return this.props.device[item].toString();
      }
      return this.props.device[item];
    }
    return '-';
  };

  renderGeo = (item) => {
    if (this.props.device && !isNil(this.props.device[item])) {
      return (
        'lat: ' +
        this.props.device[item].lat +
        ', lon: ' +
        this.props.device[item].lon
      );
    }
    return '-';
  };

  renderDurationItem = (item) => {
    if (this.props.device && !isNil(this.props.device[item])) {
      return viewDuration(this.props.device[item]);
    }
    return '-';
  };

  renderDate = (item) => {
    if (this.props.device && !isNil(this.props.device[item])) {
      return readableDateTime(this.props.device[item]);
    }
    return '-';
  };

  renderWeb = () => {
    const labelStyle = this.props.labelStyle
      ? this.props.labelStyle
      : { color: '#B2B2B2', marginRight: 20 };
    const valueStyle = this.props.valueStyle
      ? this.props.valueStyle
      : { color: '#1C1C1C' };
    return (
      <div style={{ marginTop: 5 }}>
        <div style={{ backgroundColor: '#fcfcfc', padding: 16 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>OS</span>
              <span style={valueStyle}>{this.renderItem('os')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>WEB BROWSER</span>
              <span style={valueStyle}>{this.renderItem('webBrowser')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>WEB DEVICE</span>
              <span style={valueStyle}>{this.renderItem('webDevice')}</span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>LAST SEEN </span>
              <span style={valueStyle}>{this.renderDate('lastSeen')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>TOTAL SESSIONS</span>
              <span style={valueStyle}>{this.renderItem('totalSessions')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>TOTAL TIME </span>
              <span style={valueStyle}>
                {this.renderDurationItem('totalTime')}
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>WEB PUSH SUBSCRIBE</span>
              <span style={valueStyle}>
                {this.renderItem('webPushSubscribeUrl')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>REFERRER URL</span>
              <span style={valueStyle}>{this.renderItem('referrerUrl')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>REFERRER HOST</span>
              <span style={valueStyle}>{this.renderItem('referrerHost')}</span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>CAMPAIGN MEDIUM</span>
              <span style={valueStyle}>
                {this.renderItem('campaignMedium')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>CAMPAIGN NAME</span>
              <span style={valueStyle}>{this.renderItem('campaignName')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>CAMPAIGN SOURCE</span>
              <span style={valueStyle}>
                {this.renderItem('campaignSource')}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderMobile = () => {
    const labelStyle = this.props.labelStyle
      ? this.props.labelStyle
      : { color: '#B2B2B2', marginRight: 20 };
    const valueStyle = this.props.valueStyle
      ? this.props.valueStyle
      : { color: '#1C1C1C' };
    return (
      <div>
        <div style={{ backgroundColor: '#fcfcfc', padding: 16 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>DEVICE MANUFACTURER</span>
              <span style={valueStyle}>
                {this.renderItem('deviceManufacturer')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>DEVICE BRAND</span>
              <span style={valueStyle}>{this.renderItem('deviceBrand')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>DEVICE MODEL</span>
              <span style={valueStyle}>{this.renderItem('deviceModel')}</span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 20,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>DEVICE ID</span>
              <span style={valueStyle}>{this.renderItem('deviceId')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>ANDROID ID</span>
              <span style={valueStyle}>{this.renderItem('androidId')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>VENDOR ID</span>
              <span style={valueStyle}>{this.renderItem('vendorId')}</span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 20,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>OS</span>
              <span style={valueStyle}>{this.renderItem('os')}</span>
            </div>
            <div style={{ display: 'flex', flex: 2 }}>
              <span style={labelStyle}>CARRIER</span>
              <span style={valueStyle}>{this.renderItem('carrier')}</span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 20,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>LOCALE</span>
              <span style={valueStyle}>{this.renderItem('locale')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>TIMEZONE</span>
              <span style={valueStyle}>{this.renderItem('timezone')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>GEO POINT</span>
              <span style={valueStyle}>{this.renderGeo('geoPoint')}</span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>APP ID</span>
              <span style={valueStyle}>{this.renderItem('appId')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>APP VERSION</span>
              <span style={valueStyle}>{this.renderItem('appVersion')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>APP INSTALL DATE</span>
              <span style={valueStyle}>
                {this.renderDate('appInstalledDate')}
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 20,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>API VERSION</span>
              <span style={valueStyle}>{this.renderItem('apiVersion')}</span>
            </div>
            <div style={{ display: 'flex', flex: 2 }}>
              <span style={labelStyle}>ADVERTISING ID </span>
              <span style={valueStyle}>{this.renderItem('advertisingId')}</span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>LAST SEEN</span>
              <span style={valueStyle}>{this.renderDate('lastSeen')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>TOTAL SESSIONS </span>
              <span style={valueStyle}>{this.renderItem('totalSessions')}</span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span style={labelStyle}>TOTAL TIME</span>
              <span style={valueStyle}>
                {this.renderDurationItem('totalTime')}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderRow = () => {
    if (this.props.device && !isNil(this.props.device.sdkType)) {
      if (
        this.props.device.sdkType === 'ANDROID' ||
        this.props.device.sdkType === 'IOS'
      ) {
        return this.renderMobile();
      } else {
        return this.renderWeb();
      }
    }
  };

  render() {
    return this.renderRow();
  }
}
export default UserDeviceDetailsComponent;
