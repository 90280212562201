import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Switch,
  Upload,
  message,
} from 'antd';
import Features from './ContractFeatures';
import { useDispatch, useSelector } from 'react-redux';
import { communicationUploadFileFetch } from '@Modules/engage/communication/CommunicationActions';
import {
  contractTemplateCreateFetch,
  contractTemplateUpdateSubmitFetch,
  contractTemplateUpdateFetch,
  contractTemplateViewFetch,
} from '@Redux/slices/admin/contractTemplateSlice';
import styles from './ContractCreate.module.less';
import { Link, useParams, useLocation } from 'react-router-dom';
import { communicationUploadFileReset } from '@Modules/engage/communication/CommunicationActions';
import Card from '@Utils/Card';

function ContractCreate() {
  const id = useParams().id;
  const path = useLocation().pathname;
  const mode = path.substring(path.lastIndexOf('/') + 1);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [features, setFeatures] = useState([]);
  const currentContractTemplate = useSelector(
    (state) => state.admin.contractTemplate.currentContractTemplate
  );
  const loadingUploadFile = useSelector(
    (state) => state.engage.communication.loadingUploadFile
  );
  const loading = useSelector((state) => state.admin.contractTemplate.loading);
  const [current, setCurrent] = useState({});
  const uploadedFile = useSelector(
    (state) => state.engage.communication.uploadedFile
  );

  useEffect(() => {
    if (id) {
      if (mode === 'view') {
        dispatch(contractTemplateViewFetch(id));
      } else {
        dispatch(contractTemplateUpdateFetch(id));
      }
    } else {
      setCurrent({});
      form.resetFields();
    }
    return () => {
      setCurrent({});
      dispatch(communicationUploadFileReset());
    };
  }, []);

  useEffect(() => {
    if (currentContractTemplate && !path.includes('create')) {
      setCurrent(currentContractTemplate);
      form.setFieldsValue({
        ...currentContractTemplate,
      });
    } else {
      form.setFieldsValue({
        ...current,
      });
    }
  }, [currentContractTemplate]);

  useEffect(() => {
    if (uploadedFile) {
      setCurrent({
        ...current,
        slaContractFile: uploadedFile.path,
      });
    }
  }, [loadingUploadFile]);

  const onFinish = (values) => {
    if (id) {
      dispatch(
        contractTemplateUpdateSubmitFetch(id, {
          ...currentContractTemplate,
          ...values,
          slaContractFile: current.slaContractFile,
          ...(features && { features }),
        })
      );
    } else {
      dispatch(
        contractTemplateCreateFetch({
          ...values,
          features,
          slaContractFile: current.slaContractFile,
        })
      );
    }
  };

  const initialValues = {
    enabled: true,
    slaMetrics: {
      EVENT_RECEIVE_SLA: {
        description:
          'Receiving up to 10,000 events per second (including Custom and Business events)',
        threshold: 99,
        penalty: 1,
        evaluationCriteria:
          'The number of 200 responses divided by the total event sending requests (including 200 and 500 responses)',
      },
      EVENT_PROCESS_SLA: {
        evaluationCriteria:
          'The number of registered events divided by the total number of received events',
        description:
          'Processing up to 10,000 events per second (including Custom and Business events)',
        threshold: 98,
        penalty: 1,
      },
      EVENT_QUEUE_TIME_SLA: {
        evaluationCriteria:
          'The number of events that took more than 30 minutes to process divided by the total number of processed events',
        description:
          'Processing received events (including Custom and Business events) in less than half an hour',
        threshold: 90,
        penalty: 1,
      },

      CAMPAIGN_SUCCESS_SLA: {
        evaluationCriteria:
          'The number of successful executed communications divided by the total number of scheduled communications',
        description: 'Successful launch of communications',
        threshold: 95,
        penalty: 1,
      },
      UPTIME_SLA: {
        evaluationCriteria: 'Dashboard availability per month',
        description: 'Dashboard availability per month',
        threshold: 99,
        penalty: 1,
      },
    },
  };

  return (
    <Card loading={loading} styles={{ padding: 16 }}>
      <Form
        id="contrac-template"
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 12,
        }}
        style={{ maxWidth: '100%' }}
        className={styles.form}
        {...(id &&
          !currentContractTemplate?.enabled && {
            disabled: mode === 'view',
          })}
      >
        {!id && (
          <Form.Item name="enabled" label="Enabled" valuePropName="checked">
            <Switch />
          </Form.Item>
        )}
        <Form.Item
          name="title"
          label="Title"
          className={styles.itemLable}
          labelClassName="custom-label-class"
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input />
        </Form.Item>
        <Form.Item name="totalPenalty" label="Total Penalty">
          <Input />
        </Form.Item>
        <Form.Item name="slaContractFile" label="SLA Contract File">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input
              onChange={(e) =>
                setCurrent({
                  ...current,
                  slaContractFile: e.target.value,
                })
              }
              value={current.slaContractFile}
            />
            <Uploder selector="defaultPushIcon" />
          </div>
          <p className={styles.itemHint}>
            Here You can upload the SLA file of this contract as PDF.
          </p>
        </Form.Item>
        <hr />
        <h2 className={styles.itemTitle}>SLA:</h2>
        <p className={styles.itemSubtitle}>Event Tracking:</p>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Description"
              name={['slaMetrics', 'EVENT_RECEIVE_SLA', 'description']}
              initialValue="Receiving up to 10,000 events per second (including Custom and Business events)"
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Monthly SLA22"
              name={['slaMetrics', 'EVENT_RECEIVE_SLA', 'threshold']}
              initialValue="99"
            >
              <InputNumber addonAfter="%" style={{ width: 100 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Penalty"
              name={['slaMetrics', 'EVENT_RECEIVE_SLA', 'penalty']}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Assessment Measure"
              name={['slaMetrics', 'EVENT_RECEIVE_SLA', 'evaluationCriteria']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <p className={styles.itemSubtitle}>Event Processing:</p>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Description"
              name={['slaMetrics', 'EVENT_PROCESS_SLA', 'description']}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Monthly SLA"
              name={['slaMetrics', 'EVENT_PROCESS_SLA', 'threshold']}
            >
              <InputNumber addonAfter="%" style={{ width: 100 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Penalty"
              name={['slaMetrics', 'EVENT_PROCESS_SLA', 'penalty']}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Assessment Measure"
              name={['slaMetrics', 'EVENT_PROCESS_SLA', 'evaluationCriteria']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <p className={styles.itemSubtitle}>Event Processing delay:</p>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Description"
              name={['slaMetrics', 'EVENT_QUEUE_TIME_SLA', 'description']}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Monthly SLA"
              name={['slaMetrics', 'EVENT_QUEUE_TIME_SLA', 'threshold']}
            >
              <InputNumber addonAfter="%" style={{ width: 100 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Penalty"
              name={['slaMetrics', 'EVENT_QUEUE_TIME_SLA', 'penalty']}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Assessment Measure"
              name={[
                'slaMetrics',
                'EVENT_QUEUE_TIME_SLA',
                'evaluationCriteria',
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <p className={styles.itemSubtitle}>Communications:</p>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Description"
              name={['slaMetrics', 'CAMPAIGN_SUCCESS_SLA', 'description']}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Monthly SLA"
              name={['slaMetrics', 'CAMPAIGN_SUCCESS_SLA', 'threshold']}
            >
              <InputNumber addonAfter="%" style={{ width: 100 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Penalty"
              name={['slaMetrics', 'CAMPAIGN_SUCCESS_SLA', 'penalty']}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Assessment Measure"
              name={[
                'slaMetrics',
                'CAMPAIGN_SUCCESS_SLA',
                'evaluationCriteria',
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <p className={styles.itemSubtitle}>Dashboard Availability:</p>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Description"
              name={['slaMetrics', 'UPTIME_SLA', 'description']}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Monthly SLA"
              name={['slaMetrics', 'UPTIME_SLA', 'threshold']}
            >
              <InputNumber addonAfter="%" style={{ width: 100 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="Penalty"
              name={['slaMetrics', 'UPTIME_SLA', 'penalty']}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Assessment Measure"
              name={['slaMetrics', 'UPTIME_SLA', 'evaluationCriteria']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <hr />
        <h2 className={styles.itemTitle}>Features:</h2>
        <Features
          setFeatures={setFeatures}
          currentFeatures={current?.features}
          {...(id &&
            !currentContractTemplate?.enabled && {
              disabledTree: mode === 'view',
            })}
          isAdmin={true}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 10,
            paddingBottom: 30,
          }}
        >
          <Link to={id ? '../list' : 'list'}>
            <Button
              type="primary"
              shape="round"
              size={'large'}
              className="w-200"
            >
              {'Back'}
            </Button>
          </Link>
          <Button
            type="primary"
            shape="round"
            size={'large'}
            className="w-200"
            htmlType="submit"
          >
            {'Submit'}
          </Button>
        </div>
      </Form>
    </Card>
  );
}

const Uploder = ({ selector }) => {
  const dispatch = useDispatch();
  const acceptedImageUrlFormat = '.pdf';
  const currentProduct = useSelector(
    (state) => state.setting.productSetting.currentProduct
  );
  const image = currentProduct.defaultPushIcon || null;

  const props = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload: (file) => {
      const validUrl = file.type.includes('pdf');
      if (!validUrl) {
        message.error('Invalid PDF URL');
      }
      return validUrl || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Upload
      {...props}
      accept={acceptedImageUrlFormat}
      fileList={image ? [image] : []}
      id={selector}
      key={selector}
      onChange={(object) => {
        dispatch(
          communicationUploadFileFetch(object.fileList[0].originFileObj)
        );
      }}
    >
      <Button
        id={selector}
        key={selector}
        type="primary"
        shape="round"
        size={'small'}
        ghost
        style={{
          marginLeft: 16,
        }}
      >
        Upload
      </Button>
    </Upload>
  );
};

export default ContractCreate;
