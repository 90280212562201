export const NodeTypes = [
  'TRIGGER',
  'ACTION',
  'CONDITION',
  'FLOWCONTROL',
  'PLACE_HOLDER',
];

const nodeActionStyle = {
  border: '1px solid #00b875',
  borderRadius: '7px',
  padding: 0,
  background: '#fff',
};
const nodeTriggerStyle = {
  border: '1px solid #008bfa',
  borderRadius: '7px',
  padding: 0,
  background: '#fff',
};
const nodeConditionStyle = {
  border: '1px solid #ffa726',
  borderRadius: '7px',
  padding: 0,
  background: '#fff',
};
const nodeFlowStyle = {
  border: '1px solid #fe5e5f',
  borderRadius: '7px',
  padding: 0,
  background: '#fff',
};
export const nodePlaceHolderStyle = {
  borderRadius: '5px',
  padding: 0,
  border: '2px dashed #c5c5c5',
  background: 'rgb(243 246 248)',
};

const PortsSendEmail = [
  {
    name: 'onSend',
    title: 'On Send',
  },
  {
    name: 'onDelivery',
    title: 'On Delivery',
  },
  {
    name: 'onClick',
    title: 'On Click',
  },
  {
    name: 'onUnsubscribe',
    title: 'On Unsubscribe',
  },
  {
    name: 'onFailure',
    title: 'On Failure',
  },
];
const PortsSendSms = [
  {
    name: 'onSend',
    title: 'On Send',
  },
  {
    name: 'onClick',
    title: 'On Click',
  },
  {
    name: 'onDelivery',
    title: 'On Delivery',
  },
  {
    name: 'onFailure',
    title: 'On Failure',
  },
];

const PortsSendPush = [
  {
    name: 'onSend',
    title: 'On Send',
  },
  {
    name: 'onClick',
    title: 'On Click',
  },
  {
    name: 'onDelivery',
    title: 'On Delivery',
  },
  {
    name: 'onFailure',
    title: 'On Failure',
  },
  // {
  //   name: "onView",
  //   title: "On View",
  // },
];

const PortsShowInApp = [
  {
    name: 'onSend',
    title: 'On Send',
  },
  {
    name: 'delivered',
    title: 'On Delivered',
  },
  {
    name: 'click',
    title: 'On Click',
  },
  {
    name: 'closed',
    title: 'On Close',
  },
  {
    name: 'onTimeOut',
    title: 'On Time out',
  },
];

const PortsShowOnSiteNotif = PortsShowInApp;

const PortsSendWebPush = [
  {
    name: 'onSend',
    title: 'On Send',
  },
  {
    name: 'onDelivery',
    title: 'On Delivery',
  },
  {
    name: 'onClick',
    title: 'On Click',
  },
  {
    name: 'onFailure',
    title: 'On Failure',
  },
];

const PortsShendWhatsApp = [
  {
    name: 'onSend',
    title: 'On Send',
  },
  {
    name: 'onFailure',
    title: 'On Failure',
  },
  {
    name: 'onDelivery',
    title: 'On Delivery',
  },
  // {
  //   name: 'onRead',
  //   title: 'On Read',
  // },
  {
    name: 'onClick',
    title: 'On Click',
  },
];

const PortsCustomChannel = [
  {
    name: 'onSend',
    title: 'On Send',
  },
  {
    name: 'onDelivery',
    title: 'On Delivery',
  },
  {
    name: 'onClick',
    title: 'On Click',
  },
  {
    name: 'onFailure',
    title: 'On Failure',
  },
];

const PortsCallApi = [
  {
    name: 'onSuccess',
    title: 'On Success',
  },
  {
    name: 'onFailure',
    title: 'On Failure',
  },
];

const PortsConditions = [
  {
    name: 'onYes',
    title: 'Yes',
  },
  {
    name: 'onNo',
    title: 'No',
  },
];

const PortsWaitForEvent = [
  {
    name: 'onEvent',
    title: 'On Event',
  },
  {
    name: 'onTimeOut',
    title: 'On Timeout',
  },
];

const PortsWaitForDate = [
  {
    name: 'onDate',
    title: 'On Date',
  },
  {
    name: 'onWindowMiss',
    title: 'On Window Miss',
  },
];

export const NodesInbox = (
  createNode,
  createEdge,
  deleteNode,
  duplicateNode,
  updateNodeByModal,
  replaceNode
) => [
  {
    type: 'defaultNode',
    data: {
      key: 'EVENT_OCCURRENCE',
      label: 'Occurrence of Event',
      subLabel: 'When user does an event',
      nodeType: 'TRIGGER',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      updateNodeByModal: updateNodeByModal,
      iconName: 'star',
      events: [],
    },
    style: nodeTriggerStyle,
  },
  {
    type: 'defaultNode',
    hide: true,
    data: {
      key: 'BUSINESS_EVENT_OCCURRENCE',
      label: 'Occurrence of business Event',
      subLabel: 'When business does an event',
      nodeType: 'TRIGGER',
      createNode: createNode,
      createEdge: createEdge,
      // deleteNode: deleteNode,
      // duplicateNode: duplicateNode,
      updateNodeByModal: updateNodeByModal,
      iconName: 'star',
      events: [],
    },
    style: nodeTriggerStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'ENTER_EXIT_SEGMENT',
      label: 'Enter/Exit/Is In Segment',
      subLabel: 'When user enters / exits / is in a segment',
      nodeType: 'TRIGGER',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      updateNodeByModal: updateNodeByModal,
      iconName: 'tupe',
      events: [],
    },
    // disabled: true,
    style: nodeTriggerStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'CHANGE_IN_USER_ATTRIBUTE',
      label: 'Change in User Attribute',
      subLabel: 'When value of user attribute changes',
      nodeType: 'TRIGGER',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      updateNodeByModal: updateNodeByModal,
      iconName: 'user',
      events: [],
    },
    // disabled: true,
    style: nodeTriggerStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'SPECIFIC_USERS',
      label: 'For Specific Users',
      subLabel: 'For specific users in CSV file',
      nodeType: 'TRIGGER',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      updateNodeByModal: updateNodeByModal,
      iconName: 'csv',
      events: [],
    },
    // disabled: true,
    style: nodeTriggerStyle,
  },
  // {
  //   type: "defaultNode",
  //   data: {
  //     key: "enterGeoFence",
  //     label: "Enter/Exit Geo-fence",
  //     subLabel: "When user enters / exits a geo-fence",
  //     nodeType: "TRIGGER",
  //     createNode: createNode,
  //     createEdge: createEdge,
  //     deleteNode: deleteNode,
  //     duplicateNode: duplicateNode,
  //     updateNodeByModal: updateNodeByModal,
  //     iconName: "geo",
  //     events: [],
  //   },
  //   disabled: true,
  //   style: nodeTriggerStyle,
  // },
  {
    type: 'defaultNode',
    data: {
      key: 'SEND_EMAIL',
      label: 'Send Email',
      nodeType: 'ACTION',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      updateNodeByModal: updateNodeByModal,
      iconName: 'unread',
      events: PortsSendEmail,
    },
    // disabled: true,
    style: nodeActionStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'SEND_SMS',
      label: 'Send SMS',
      nodeType: 'ACTION',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'text',
      events: PortsSendSms,
    },
    style: nodeActionStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'SEND_PUSH',
      label: 'Send Push',
      nodeType: 'ACTION',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'push',
      events: PortsSendPush,
    },
    // disabled: true,
    style: nodeActionStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'SEND_IN_APP',
      label: 'Show In-App',
      nodeType: 'ACTION',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'in-app',
      events: PortsShowInApp,
    },
    //disabled: true,
    style: nodeActionStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'SEND_ON_SITE',
      label: 'Show On-Site Notification',
      nodeType: 'ACTION',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'web',
      events: PortsShowOnSiteNotif,
    },
    //disabled: true,
    style: nodeActionStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'SEND_WEB_PUSH',
      label: 'Send Web Push',
      nodeType: 'ACTION',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'web-push',
      events: PortsSendWebPush,
    },
    // disabled: true,
    style: nodeActionStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'SEND_WHATSAPP',
      label: 'Send WhatsApp',
      nodeType: 'ACTION',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'whatsapp',
      events: PortsShendWhatsApp,
    },
    // disabled: true,
    style: nodeActionStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'CALL_API',
      label: 'Call API',
      nodeType: 'ACTION',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'code',
      events: PortsCallApi,
    },
    // disabled: true,
    style: nodeActionStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'SEND_CUSTOM',
      label: 'Send Custom',
      nodeType: 'ACTION',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'custom_channel',
      events: PortsCustomChannel,
    },
    // disabled: true,
    style: nodeActionStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'SET_USER_ATTRIBUTE',
      label: 'Set User Attribute',
      nodeType: 'ACTION',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'user',
      events: [],
    },
    // disabled: true,
    style: nodeActionStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'IS_IN_SEGMENT',
      label: 'Is In Segment',
      subLabel: 'Check if user is in segment',
      nodeType: 'CONDITION',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'tupe',
      events: PortsConditions,
    },
    style: nodeConditionStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'HAS_DONE_EVENT',
      label: 'Has Done Event',
      subLabel: 'Check if user has done event',
      nodeType: 'CONDITION',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'star',
      events: PortsConditions,
    },
    style: nodeConditionStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'CHECK_USER_ATTRIBUTE',
      label: 'Check User Attribute',
      subLabel: 'Check value of user attribute',
      nodeType: 'CONDITION',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'user',
      events: PortsConditions,
    },
    style: nodeConditionStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'IS_USER_REACHABLE',
      label: 'Is User Reachable',
      subLabel: 'Check if user is reachable on a channel',
      nodeType: 'CONDITION',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'in-app',
      events: PortsConditions,
    },
    style: nodeConditionStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'WAIT_FOR_TIME',
      label: 'Wait For Some Time',
      // subLabel: "Wait for 1 day",
      nodeType: 'FLOWCONTROL',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'wait',
      events: [],
    },
    style: nodeFlowStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'WAIT_FOR_TIME_SLOT',
      label: 'Wait For Time Slots',
      nodeType: 'FLOWCONTROL',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'wait-range',
      events: [],
    },
    // disabled: true,
    style: nodeFlowStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'WAIT_FOR_EVENT',
      label: 'Wait For Event',
      subLabel: 'Wait for user to perform event',
      nodeType: 'FLOWCONTROL',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'star',
      events: PortsWaitForEvent,
    },
    // disabled: true,
    style: nodeFlowStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'WAIT_FOR_DATE',
      label: 'Wait For Date',
      nodeType: 'FLOWCONTROL',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'wait-for-date',
      events: PortsWaitForDate,
    },
    // disabled: true,
    style: nodeFlowStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'TRAFFIC_SPLITTER',
      label: 'Traffic Splitter',
      nodeType: 'FLOWCONTROL',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'traffic_splitter',
      events: [],
    },
    style: nodeFlowStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'END_JOURNEY',
      label: 'End Journey',
      nodeType: 'FLOWCONTROL',
      createNode: createNode,
      createEdge: createEdge,
      deleteNode: deleteNode,
      duplicateNode: duplicateNode,
      iconName: 'stop',
      events: [],
    },
    style: nodeFlowStyle,
  },
  {
    type: 'defaultNode',
    data: {
      key: 'PLACE_HOLDER',
      label: 'Drag & drop Action / Condition / Flow',
      nodeType: 'PLACE_HOLDER',
      subLabel: 'Control block from sidebar',
      // createNode: createNode,
      // createEdge: createEdge,
      deleteNode: deleteNode,
      updateNode: replaceNode,
      // duplicateNode: duplicateNode,
      // iconName: 'stop',
      // events: [],
    },
    style: nodePlaceHolderStyle,
  },
];
//TODO: Remove extra json data
export const Events = [
  {
    name: 'Added To Cart',
    displayName: 'Added To Cart',
    category: 'application',
  },
  {
    name: 'Added to Cart',
    displayName: 'Added to Cart',
    category: 'application',
  },
  {
    name: 'Browse',
    displayName: 'Browse',
    category: 'application',
  },
  {
    name: 'Cart - Start Checkout',
    displayName: 'Cart - Start Checkout',
    category: 'application',
  },
  {
    name: 'Cart - Viewed',
    displayName: 'Cart - Viewed',
    category: 'application',
  },
  {
    name: 'Checkout - Address Added',
    displayName: 'Checkout - Address Added',
    category: 'application',
  },
  {
    name: 'Checkout - Completed',
    displayName: 'Checkout - Completed',
    category: 'application',
  },
  {
    name: 'Checkout - Payment Added',
    displayName: 'Checkout - Payment Added',
    category: 'application',
  },
  {
    name: 'Checkout - Shipping Method Selected',
    displayName: 'Checkout - Shipping Method Selected',
    category: 'application',
  },
  {
    name: 'Checkout Started',
    displayName: 'Checkout Started',
    category: 'application',
  },
  {
    name: 'Health Insurance Purchased',
    displayName: 'Health Insurance Purchased',
    category: 'application',
  },
  {
    name: 'Interests Added',
    displayName: 'Interests Added',
    category: 'application',
  },
  {
    name: 'Language Added',
    displayName: 'Language Added',
    category: 'application',
  },
  {
    name: 'Order Placed',
    displayName: 'Order Placed',
    category: 'application',
  },
  {
    name: 'Product - Added to Cart',
    displayName: 'Product - Added to Cart',
    category: 'application',
  },
  {
    name: 'Product - Category Viewed',
    displayName: 'Product - Category Viewed',
    category: 'application',
  },
  {
    name: 'Product - Page Viewed',
    displayName: 'Product - Page Viewed',
    category: 'application',
  },
  {
    name: 'Product - Quantity Selected',
    displayName: 'Product - Quantity Selected',
    category: 'application',
  },
  {
    name: 'Product - Removed from Cart',
    displayName: 'Product - Removed from Cart',
    category: 'application',
  },
  {
    name: 'Product Purchase Successful',
    displayName: 'Product Purchase Successful',
    category: 'application',
  },
  {
    name: 'Product Shared',
    displayName: 'Product Shared',
    category: 'application',
  },
  {
    name: 'Promotion Clicked',
    displayName: 'Promotion Clicked',
    category: 'application',
  },
  {
    name: 'Search',
    displayName: 'Search',
    category: 'application',
  },
  {
    name: 'Sort Applied',
    displayName: 'Sort Applied',
    category: 'application',
  },
  {
    name: 'Subscription Purchased',
    displayName: 'Subscription Purchased',
    category: 'application',
  },
  {
    name: 'User Registered',
    displayName: 'User Registered',
    category: 'application',
  },
  {
    name: 'Video Viewed',
    displayName: 'Video Viewed',
    category: 'application',
  },
  {
    name: 'Wishlist - Added',
    displayName: 'Wishlist - Added',
    category: 'application',
  },
  {
    name: 'Wishlist - Viewed',
    displayName: 'Wishlist - Viewed',
    category: 'application',
  },
  {
    name: 'test_1',
    displayName: 'test_1',
    category: 'application',
  },
  {
    name: 'test_2',
    displayName: 'test_2',
    category: 'application',
  },
  {
    name: 'test_event_2',
    displayName: 'test_event_2',
    category: 'application',
  },
  {
    name: 'test_event_3',
    displayName: 'test_event_3',
    category: 'application',
  },
  {
    name: 'test_event_4',
    displayName: 'test_event_4',
    category: 'application',
  },
  {
    name: 'test_event_5',
    displayName: 'test_event_5',
    category: 'application',
  },
  {
    name: 'app_crashed',
    displayName: 'App Crashed',
    category: 'system',
  },
  {
    name: 'app_installed',
    displayName: 'App Installed',
    category: 'system',
  },
  {
    name: 'app_uninstalled',
    displayName: 'App Uninstalled',
    category: 'system',
  },
  {
    name: 'email_accepted',
    displayName: 'Email Accepted',
    category: 'system',
  },
  {
    name: 'email_bounce',
    displayName: 'Email Bounced',
    category: 'system',
  },
  {
    name: 'email_click',
    displayName: 'Email Click',
    category: 'system',
  },
  {
    name: 'email_control_group',
    displayName: 'Email Control Attempted',
    category: 'system',
  },
  {
    name: 'email_delivered',
    displayName: 'Email Delivered',
    category: 'system',
  },
  {
    name: 'email_open',
    displayName: 'Email Open',
    category: 'system',
  },
  {
    name: 'email_queued',
    displayName: 'Email Queued',
    category: 'system',
  },
  {
    name: 'email_rejected',
    displayName: 'Email Rejected',
    category: 'system',
  },
  {
    name: 'email_spam',
    displayName: 'Email Spam Report',
    category: 'system',
  },
  {
    name: 'email_unsubscribe',
    displayName: 'Email Unsubscribe',
    category: 'system',
  },
  {
    name: 'feedback_view',
    displayName: 'On-site Feedback View',
    category: 'system',
  },
  {
    name: 'gcm_notification_response',
    displayName: 'Push (Mobile) Sent',
    category: 'system',
  },
  {
    name: 'gcm_registered',
    displayName: 'GCM/APNs Registered',
    category: 'system',
  },
  {
    name: 'goal_accomplish',
    displayName: 'Campaign Conversion',
    category: 'system',
  },
  {
    name: 'journey_end',
    displayName: 'Journey Ended',
    category: 'system',
  },
  {
    name: 'journey_start',
    displayName: 'Journey Started',
    category: 'system',
  },
  {
    name: 'notification_close',
    displayName: 'Notification (On-site & In-app) Close',
    category: 'system',
  },
  {
    name: 'notification_view',
    displayName: 'Notification (On-site & In-app) Impression',
    category: 'system',
  },
  {
    name: 'push_notification_accepted',
    displayName: 'Push (Web & Mobile) Accepted',
    category: 'system',
  },
  {
    name: 'push_notification_click',
    displayName: 'Push (Web & Mobile) Click',
    category: 'system',
  },
  {
    name: 'push_notification_close',
    displayName: 'Push (Web & Mobile) Dismiss',
    category: 'system',
  },
  {
    name: 'push_notification_control_group',
    displayName: 'Push Notification Control Attempted',
    category: 'system',
  },
  {
    name: 'push_notification_item_view',
    displayName: 'Push (Mobile) Carousel Item Viewed',
    category: 'system',
  },
  {
    name: 'push_notification_opt_out',
    displayName: 'Web Push Subscribe Process - Unsubscribed',
    category: 'system',
  },
  {
    name: 'push_notification_prompt_allowed',
    displayName: 'Web Push Subscribe Notification Allowed',
    category: 'system',
  },
  {
    name: 'push_notification_prompt_denied',
    displayName: 'Web Push Subscribe Notification Denied',
    category: 'system',
  },
  {
    name: 'push_notification_prompt_view',
    displayName: 'Web Push Subscribe Notification Viewed',
    category: 'system',
  },
  {
    name: 'push_notification_queued',
    displayName: 'Push (Web & Mobile) Queued',
    category: 'system',
  },
  {
    name: 'push_notification_rating_submitted',
    displayName: 'Push (Mobile) Rating Submitted',
    category: 'system',
  },
  {
    name: 'push_notification_received',
    displayName: 'Push (Mobile) Received',
    category: 'system',
  },
  {
    name: 'push_notification_registered',
    displayName: 'Push (Web) Registered',
    category: 'system',
  },
  {
    name: 'push_notification_rejected',
    displayName: 'Push (Web & Mobile) Rejected',
    category: 'system',
  },
  {
    name: 'push_notification_unregistered',
    displayName: 'Push (Web) Unregistered',
    category: 'system',
  },
  {
    name: 'push_notification_view',
    displayName: 'Push (Web & Mobile) Impression',
    category: 'system',
  },
  {
    name: 'push_notification_window_allowed',
    displayName: 'Web Push Subscribe Successful',
    category: 'system',
  },
  {
    name: 'push_notification_window_denied',
    displayName: 'Web Push Subscribe Denied',
    category: 'system',
  },
  {
    name: 'push_notification_window_view',
    displayName: 'Web Push Subscribe Viewed',
    category: 'system',
  },
  {
    name: 'sms_accepted',
    displayName: 'SMS Accepted',
    category: 'system',
  },
  {
    name: 'sms_click',
    displayName: 'SMS Click',
    category: 'system',
  },
  {
    name: 'sms_control_group',
    displayName: 'SMS Control Attempted',
    category: 'system',
  },
  {
    name: 'sms_failed',
    displayName: 'SMS Failed',
    category: 'system',
  },
  {
    name: 'sms_queued',
    displayName: 'SMS Queued',
    category: 'system',
  },
  {
    name: 'sms_rejected',
    displayName: 'SMS Rejected',
    category: 'system',
  },
  {
    name: 'sms_sent',
    displayName: 'SMS Sent',
    category: 'system',
  },
  {
    name: 'user_logged_in',
    displayName: 'User Login',
    category: 'system',
  },
  {
    name: 'user_logged_out',
    displayName: 'User Logout',
    category: 'system',
  },
  {
    name: 'user_session_started',
    displayName: 'Session Started',
    category: 'system',
  },
  {
    name: 'whatsapp_accepted',
    displayName: 'WhatsApp Accepted',
    category: 'system',
  },
  {
    name: 'whatsapp_click',
    displayName: 'WhatsApp Click',
    category: 'system',
  },
  {
    name: 'whatsapp_control_group',
    displayName: 'WhatsApp Control Attempted',
    category: 'system',
  },
  {
    name: 'whatsapp_failed',
    displayName: 'WhatsApp Failed',
    category: 'system',
  },
  {
    name: 'whatsapp_queued',
    displayName: 'WhatsApp Queued',
    category: 'system',
  },
  {
    name: 'whatsapp_read',
    displayName: 'WhatsApp Read',
    category: 'system',
  },
  {
    name: 'whatsapp_rejected',
    displayName: 'WhatsApp Rejected',
    category: 'system',
  },
  {
    name: 'whatsapp_sent',
    displayName: 'WhatsApp Sent',
    category: 'system',
  },
];

export const TriggerEnterExistState = [
  {
    name: 'ENTERS',
    displayName: 'enters',
  },
  {
    name: 'ENTERS_OR_IS_ALREADY_IN',
    displayName: 'enters or is already in',
  },
  {
    name: 'IS_ALREADY_IN',
    displayName: 'is already in',
  },
  {
    name: 'EXITS',
    displayName: 'exits',
  },
];

export const TriggerEnterExistOnStep = [
  {
    name: 'ENTER',
    displayName: 'enter',
  },
  {
    name: 'EXIT',
    displayName: 'exit',
  },
];

export const Segments = [
  {
    privateServiceProvider: false,
    id: '~2dl42e2',
    name: 'Viewed cramp',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '7fa9h47',
    name: 'Saif test',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~2dl42kl',
    name: 'Loan Started',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~23dbge5',
    name: 'Loan Expired',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~8lj99e',
    name: 'Credit Not Approved',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~7cddd49',
    name: 'Add to cart but no purchase',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '3mg2g0a',
    name: 'last 7 days browse no P',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~fie801',
    name: 'uninstalled',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~5oig4e1',
    name: 'purchased offline 1 month',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '4b9hed6',
    name: 'Test',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~1b8o5jc',
    name: 'Specific product viewed',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~4ikdkj3',
    name: 'Fist Time Login',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '78dei6d',
    name: 'Female 30d buy',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '87elf55',
    name: 'New Users',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '35behkl',
    name: 'Mobile browser users',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~7cde026',
    name: '3 pucrhcases',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '843o37h',
    name: 'click on campaign 123',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~3jj6nga',
    name: 'test',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '2k3m6a6',
    name: 'uninstalled app',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '38mc4mb',
    name: 'CLV less than 500',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '13n2baf',
    name: 'Renewal',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~5l7j569',
    name: 'Inactive users D30',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~7fobcb9',
    name: 'Purchase Incomplete',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~5hlli4b',
    name: 'Purchase incomplete',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~6njo20a',
    name: 'Inactive users d30',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~75gj14o',
    name: 'Inactive users d30',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~792gd76',
    name: 'Inactive users D45',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~6njo1ab',
    name: 'Inactive users D30',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '44cm2ln',
    name: 'rz-live-segment',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~3n54a87',
    name: 'INACTIVE USERS D30',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~2o3m0ol',
    name: 'App Uninstalled',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~634e42e',
    name: 'Inactive 1 year',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '5h7jahj',
    name: 'organic search',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~8ibg8i2',
    name: 'Product View',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~7ml6b66',
    name: 'test',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~8f0il35',
    name: 'Birthday',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~5oigg72',
    name: 'Inactive users D30',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~111760e',
    name: 'Inactive users D30',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~4bnj87e',
    name: 'Checkout 30d',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '80i1gon',
    name: 'INACTIVE USERS D30',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '2nejj72',
    name: 'women 20-40',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~2khoddm',
    name: 'Age and Click 7d',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '3mg23ml',
    name: 'RZ_Test_Segment',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '2k3m74d',
    name: 'test',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '1l2emg8',
    name: 'Cross sell',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '752gj4j',
    name: 'test',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '1ajmbm0',
    name: 'Order Delivered',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '7il6i69',
    name: 'Test',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~3jj6mac',
    name: 'Test_doc',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '4lk2ng',
    name: 'Test',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '1hghanh',
    name: 'test campaign : Push',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '80i1h4o',
    name: 'Test Device',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '22o9mke',
    name: 'test',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~4m6bikn',
    name: 'New Web Users',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~50h95n5',
    name: 'New Users (Post 16th June)',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~2o3lof7',
    name: 'tst',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~mf9i67',
    name: 'Users with OS in 2020',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~6njo06f',
    name: 'Users with OS',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '1amg9g',
    name: 'Has a Phone Number',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~7ja8mk9',
    name: 'test',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~6dc6dol',
    name: 'Active Subs',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '6g91lmo',
    name: 'test',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '~1lgh31k',
    name: 'New Users',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~1i5jfml',
    name: 'New Users (Post 22nd April 2020)',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '2nejkm3',
    name: 'no p 3 months',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '4b9h3lc',
    name: 'Users 3 months no purchase',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
  {
    privateServiceProvider: false,
    id: '~17n23oc',
    name: 'Users with CLV < 5000',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '1e5jogn',
    name: 'Test',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: true,
  },
  {
    privateServiceProvider: false,
    id: '3c89in5',
    name: 'test',
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    processed: true,
    userRolling: false,
  },
];

export const TriggerEnterExistStatsResponseCall = {
  stats: {
    status: 'COMPLETED',
    processed: true,
    'total-users': 0,
    'segment-users': 40886,
    'known-segment-users': 8256,
    'unknown-segment-users': 32630,
    'reachable-users': 40886,
    'percentage-completed': 100,
    'events-to-process': 0,
    'events-processed': 0,
    'android-device-count': 0,
    'ios-device-count': 0,
    'web-device-count': 0,
  },
  trafficSegmentDto: {
    privateServiceProvider: false,
    licenseCode: 'aa1326d6',
    id: '~2dl42e2',
    name: 'Viewed cramp',
    campaignCount: 0,
    journeyCount: 0,
    logicalOperator: 'AND',
    includeDeletedDevice: false,
    status: 'ACTIVE',
    visibility: 'VISIBLE',
    testOnly: false,
    editable: true,
    visitorType: {
      value: 'NEW',
    },
    lastSessionTime: {
      operator: 'EXISTS',
      values: ['true'],
    },
    reachability: {
      operator: 'IN',
      values: ['PUSH_NOTIFICATION'],
    },
    behavior: {
      logicalOperator: 'AND',
      events: [
        {
          id: '7ee233b0',
          eventName: 'Checkout - Completed',
          function: 'COUNT',
          attribute: '1',
          operandType: 'number',
          operator: 'EQ',
          category: 'application',
          values: ['0'],
          filters: {},
        },
        {
          id: '~c6646991',
          eventName: 'Product - Category Viewed',
          function: 'COUNT',
          attribute: '1',
          operandType: 'number',
          operator: 'GT',
          category: 'application',
          values: ['0'],
          filters: {},
        },
      ],
    },
    createdByUser: 'Divya',
    lastModifiedByUser: 'Divya',
    createdOn: '2021-02-02T08:32:05.000Z',
    lastModifiedOn: '2021-02-02T03:02:05.000Z',
    processed: true,
    userRolling: false,
  },
};

export const TrEnterGeoFence_Types = [
  {
    name: 'enters',
    displayName: 'Enters',
  },
  {
    name: 'exists',
    displayName: 'Exists',
  },
];

export const JourneySteps = [
  {
    key: 'design',
    name: 'Design',
  },
  {
    key: 'segment',
    name: 'Segment & Gap',
  },
  {
    key: 'exitTrigger',
    name: 'Exit Scenario',
  },
  {
    key: 'conversionTracking',
    name: 'Conversion Tracking',
  },
  {
    key: 'frequencyCappingandDND',
    name: 'Frequency Capping and DND',
  },
  {
    key: 'publish',
    name: 'Preview',
  },
];

export const JourneyObjectModel = {
  // controlGroup: false,
  // controlGroupPercentage: 0,
  // conversion: false,
  // conversionDeadlineMinutes: 0,
  conversionEvent: null,
  // conversionEventFilterConjunction: "AND",
  conversionEventFilterPredicates: [],
  crc: 0,
  exitTriggers: [],
  followDnd: true,
  followFrequencyCapping: true,
  name: 'str',
  queueMinutes: 0,
  segment: null,
  status: 'DRAFT',
  gapType: 'IMMEDIATELY',
  steps: [],
  tags: [],
};

export const JourneyObjectModelSteps = {
  EVENT_OCCURRENCE: {
    // event: 0,
    // eventFilterConjunction: "AND",
    // eventFilterPredicates: [
    //   {
    //     attribute: 0,
    //     greaterThan: 0.1,
    //     greaterThanDate: "2021-03-07T14:18:08+03:30",
    //     greaterThanInclusive: false,
    //     lessThan: 0.1,
    //     lessThanDate: "2021-03-07T14:18:08+03:30",
    //     lessThanInclusive: false,
    //     negate: false,
    //     operator: "EQUAL_TO",
    //     stringValue: "str",
    //   },
    // ],
    id: 0,
    on: [],
    type: 'EVENT_OCCURRENCE',
    ui: {},
  },
  ENTER_EXIT_SEGMENT: {
    communication: 0,
    edges: [],
    enterExitType: 'ENTERS',
    id: 0,
    on: [],
    repeatDays: [],
    repeatEvery: 1,
    repeatTime: '',
    repeatType: null,
    segment: '',
    type: 'ENTER_EXIT_SEGMENT',
    ui: {},
  },
  SPECIFIC_USERS: {
    communication: 0,
    id: 0,
    on: [],
    staticAttribute: 0,
    staticFile: '',
    type: 'SPECIFIC_USERS',
    ui: {},
  },
  SEND_SMS: {
    communication: 0,
    id: 0,
    onDelivery: [],
    onFailure: [],
    onSend: [],
    type: 'SEND_SMS',
    ui: {},
  },
  SEND_PUSH: {
    communication: 0,
    edges: [],
    id: 0,
    onClick: [],
    onDelivery: [],
    onFailure: [],
    onSend: [],
    onView: [],
    type: 'SEND_SMS',
    ui: {},
  },
  IS_IN_SEGMENT: {
    id: 0,
    onNo: [],
    onYes: [],
    // segment: 0,
    type: 'IS_IN_SEGMENT',
    ui: {},
  },
  HAS_DONE_EVENT: {
    // attribute: 0,
    // event: 0,
    // eventAggregation: "EXISTS",
    // eventAttribute: 0,
    // eventFilterConjunction: "AND",
    // eventFilterPredicates: [
    //   {
    //     attribute: 0,
    //     greaterThan: 0.1,
    //     greaterThanDate: "2021-03-07T14:18:08+03:30",
    //     greaterThanInclusive: false,
    //     lessThan: 0.1,
    //     lessThanDate: "2021-03-07T14:18:08+03:30",
    //     lessThanInclusive: false,
    //     negate: false,
    //     operator: "EQUAL_TO",
    //     stringValue: "str",
    //   },
    // ],
    // greaterThan: 0.1,
    // greaterThanDate: "2021-03-07T14:18:08+03:30",
    // greaterThanInclusive: false,
    id: 0,
    // lessThan: 0.1,
    // lessThanDate: "2021-03-07T14:18:08+03:30",
    // lessThanInclusive: false,
    // negate: false,
    onNo: [],
    onYes: [],
    // operator: "EQUAL_TO",
    stringValue: 'str',
    type: 'HAS_DONE_EVENT',
    ui: {},
  },
  CHECK_USER_ATTRIBUTE: {
    // attribute: 0,
    // greaterThan: 0.1,
    // greaterThanDate: "2021-03-07T14:18:08+03:30",
    // greaterThanInclusive: false,
    id: 0,
    // lessThan: 0.1,
    // lessThanDate: "2021-03-07T14:18:08+03:30",
    // lessThanInclusive: false,
    // negate: false,
    onNo: [],
    onYes: [],
    // operator: "EQUAL_TO",
    stringValue: 'str',
    type: 'CHECK_USER_ATTRIBUTE',
    ui: {},
  },
  IS_USER_REACHABLE: {
    channel: null,
    id: 0,
    onNo: [],
    onYes: [],
    type: 'IS_USER_REACHABLE',
    ui: {},
  },
  WAIT_FOR_TIME: {
    id: 0,
    on: [],
    type: 'WAIT_FOR_TIME',
    ui: {},
    waitMinutes: 0,
  },
  WAIT_FOR_TIME_SLOT: {
    communication: 0,
    id: 0,
    floodGate: true,
    on: [],
    timeSlots: [
      // {
      //   "endTime": "12:56:06.475",
      //   "startTime": "12:56:06.475",
      //   "weekDayType": "DAILY"
      // }
    ],
    type: 'WAIT_FOR_TIME_SLOT',
    ui: {},
    useUserTimeZone: false,
  },
  WAIT_FOR_EVENT: {
    id: 0,
    onEvent: [],
    onTimeOut: [],
    type: 'WAIT_FOR_EVENT',
    ui: {},
    waitMinutes: 0,
  },
  WAIT_FOR_DATE: {
    bufferMinutes: null,
    communication: null,
    delayMinutes: null,
    edges: [],
    eventAttribute: null,
    id: 0,
    onDate: [],
    onWindowMiss: [],
    stepId: null,
    type: 'WAIT_FOR_DATE',
    ui: {},
    userAttribute: null,
    waitForAttributeType: 'USER_ATTRIBUTE',
  },
  END_JOURNEY: {
    id: 0,
    type: 'END_JOURNEY',
    ui: {},
  },
  TRAFFIC_SPLITTER: {
    id: 0,
    on: [],
    onWeights: [],
    type: 'TRAFFIC_SPLITTER',
    ui: {},
  },
  CALL_API: {
    // body: "",
    // communication: 0,
    headers: [],
    id: 0,
    method: 'POST',
    onFailure: [],
    onSuccess: [],
    type: 'CALL_API',
    ui: {},
    url: '',
  },
  PLACE_HOLDER: {
    id: 0,
    type: 'PLACE_HOLDER',
    ui: {},
  },
  CHANGE_IN_USER_ATTRIBUTE: {
    attribute: null,
    communication: 0,
    conjunctionType: 'AND',
    edges: [0],
    id: 0,
    on: [0],
    predicates: [
      // {
      //   attribute: null,
      //   // {
      //   //   aggregationField: false,
      //   //   id: 0,
      //   //   name: 'str',
      //   //   ordering: 0,
      //   //   pii: false,
      //   //   system: false,
      //   //   title: 'str',
      //   //   type: 'BOOLEAN',
      //   // },
      //   greaterThan: 'str',
      //   id: 0,
      //   lessThan: 'str',
      //   negate: false,
      //   operator: 'EQUAL_TO',
      //   stringValue: 'str',
      //   type: 'NEW_VALUE',
      // },
    ],
    valueChangeType: 'ANY',
  },
  SET_USER_ATTRIBUTE: {
    // actionType: 'NEW_ATTRIBUTE',
    // attributeId: 0,
    // attributeName: 'str',
    // attributeSetType: 'INCREASE',
    // attributeType: 'BOOLEAN',
    // attributeValue: 'str',
    // communication: 0,
    // edges: [0],
    id: 0,
    // on: [0],
    type: 'SET_USER_ATTRIBUTE',
    ui: {},
  },
};

export const JourneyObjectModelSample = {
  controlGroup: false,
  controlGroupPercentage: 0,
  conversion: false,
  conversionDeadlineMinutes: 0,
  conversionEvent: 0,
  conversionEventFilterConjunction: 'AND',
  conversionEventFilterPredicates: [
    {
      attribute: 0,
      greaterThan: 'str',
      id: 0,
      lessThan: 'str',
      negate: false,
      operator: 'EQUAL_TO',
      stringValue: 'str',
    },
  ],
  crc: 0,
  exitTriggers: [
    {
      attribute: 0,
      attributeFilterConjunction: 'AND',
      attributeFilterPredicates: [
        {
          attribute: 0,
          greaterThan: 'str',
          id: 0,
          lessThan: 'str',
          negate: false,
          operator: 'EQUAL_TO',
          stringValue: 'str',
          type: 'NEW_VALUE',
        },
      ],
      attributeType: 'ANY',
      event: 0,
      eventFilterConjunction: 'AND',
      eventFilterPredicates: [
        {
          attribute: 0,
          greaterThan: 'str',
          id: 0,
          lessThan: 'str',
          negate: false,
          operator: 'EQUAL_TO',
          stringValue: 'str',
        },
      ],
      id: 0,
      segment: 0,
      segmentType: 'ENTER',
      type: 'EVENT',
    },
  ],
  followDnd: false,
  followFrequencyCapping: false,
  name: 'str',
  queueMinutes: 0,
  segment: 0,
  steps: [
    {
      event: 0,
      eventConjunction: 'AND',
      eventPredicates: [
        {
          attribute: 0,
          greaterThan: 'str',
          lessThan: 'str',
          negate: false,
          operator: 'EQUAL_TO',
          stringValue: 'str',
        },
      ],
      id: 0,
      on: [0],
      ui: {
        str: {
          prop1: 'val1',
          prop2: 'val2',
          prop3: 'val3',
        },
      },
      type: 'EVENT_OCCURRENCE',
    },
    {
      communication: 0,
      id: 0,
      onDelivery: [0],
      onFailure: [0],
      onSend: [0],
      ui: {
        str: {
          prop1: 'val1',
          prop2: 'val2',
          prop3: 'val3',
        },
      },
      type: 'SEND_SMS',
    },
    {
      body: 'str',
      communication: 0,
      headers: [
        {
          key: 'str',
          value: 'str',
        },
      ],
      id: 0,
      method: 'GET',
      onFailure: [0],
      onSuccess: [0],
      type: 'CALL_API',
      ui: {
        str: {
          prop1: 'val1',
          prop2: 'val2',
          prop3: 'val3',
        },
      },
      url: 'str',
    },
    {
      id: 0,
      onNo: [0],
      onYes: [0],
      segment: 0,
      ui: {
        str: {
          prop1: 'val1',
          prop2: 'val2',
          prop3: 'val3',
        },
      },
      type: 'IS_IN_SEGMENT',
    },
    {
      attribute: 0,
      event: 0,
      eventAggregation: 'EXISTS',
      eventAttribute: 0,
      eventConjunction: 'AND',
      eventPredicates: [
        {
          attribute: 0,
          greaterThan: 'str',
          lessThan: 'str',
          negate: false,
          operator: 'EQUAL_TO',
          stringValue: 'str',
        },
      ],
      greaterThan: 'str',
      id: 0,
      lessThan: 'str',
      negate: false,
      onNo: [0],
      onYes: [0],
      operator: 'EQUAL_TO',
      stringValue: 'str',
      ui: {
        str: {
          prop1: 'val1',
          prop2: 'val2',
          prop3: 'val3',
        },
      },
      type: 'HAS_DONE_EVENT',
    },
    {
      attribute: 0,
      greaterThan: 'str',
      id: 0,
      lessThan: 'str',
      negate: false,
      onNo: [0],
      onYes: [0],
      operator: 'EQUAL_TO',
      stringValue: 'str',
      ui: {
        str: {
          prop1: 'val1',
          prop2: 'val2',
          prop3: 'val3',
        },
      },
      type: 'CHECK_USER_ATTRIBUTE',
    },
    {
      channel: 'PUSH',
      id: 0,
      onNo: [0],
      onYes: [0],
      ui: {
        str: {
          prop1: 'val1',
          prop2: 'val2',
          prop3: 'val3',
        },
      },
      type: 'IS_USER_REACHABLE',
    },
    {
      id: 0,
      on: [0],
      ui: {
        str: {
          prop1: 'val1',
          prop2: 'val2',
          prop3: 'val3',
        },
      },
      waitMinutes: 0,
      type: 'WAIT_FOR_TIME',
    },
    {
      id: 0,
      ui: {
        str: {
          prop1: 'val1',
          prop2: 'val2',
          prop3: 'val3',
        },
      },
      type: 'END_JOURNEY',
    },
  ],
  tags: ['string'],
};

export const Channels = [
  {
    name: 'PUSH',
    id: 'PUSH',
  },
  {
    name: 'SMS',
    id: 'SMS',
  },
  {
    name: 'WEB PUSH',
    id: 'WEB_PUSH',
  },
  {
    name: 'EMAIL',
    id: 'EMAIL',
  },
  {
    name: 'ON SITE',
    id: 'ON_SITE',
  },
  {
    name: 'IN APP',
    id: 'IN_APP',
  },
  {
    name: 'WHATSAPP',
    id: 'WHATSAPP',
  },
];

export const ON_STATIC_METHODS = [
  'on',
  'onSend',
  'onOpen',
  'onClick',
  'onUnsubscribe',
  'onBounce',
  'onDelivery',
  'onFailure',
  'onPush',
  'onView',
  'onDismiss',
  'onClose',
  'onYes',
  'onNo',
  'onEvent',
  'onTimeOut',
  'onDate',
  'onWindowMiss',
  'onSuccess',
  'delivered',
  'click',
  'closed',
];

export const StepsAvailableForChannel = [
  'EVENT_OCCURRENCE',
  'CALL_API',
  'WAIT_FOR_EVENT',
  'BUSINESS_EVENT_OCCURRENCE',
];

export const EventNodes = ['EVENT_OCCURRENCE', 'WAIT_FOR_EVENT'];

// export const CollapseFields = [null, 0 , 1]
export const AllTimesType = {
  DAILY: 'Daily',
  WEEKEND: 'Weekend',
  WEEKDAY: 'Weekday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
};

export const StepsWithCamp = [
  'SEND_SMS',
  'SEND_PUSH',
  'SEND_EMAIL',
  'SEND_PUSH',
  'SEND_WEB_PUSH',
  'SEND_CUSTOM',
  'SEND_IN_APP',
  'SEND_ON_SITE',
  'SEND_WHATSAPP',
];

export const defaultExitTriggersData = [
  {
    attribute: 0,
    attributeFilterConjunction: 'AND',
    attributeFilterPredicates: [
      {
        attribute: 0,
        greaterThan: 'str',
        id: 0,
        lessThan: 'str',
        negate: false,
        operator: 'EQUAL_TO',
        stringValue: 'str',
        type: 'NEW_VALUE',
      },
    ],
    attributeType: 'ANY',
    event: 0,
    eventFilterConjunction: 'AND',
    eventFilterPredicates: [
      {
        attribute: 0,
        greaterThan: 'str',
        id: 0,
        lessThan: 'str',
        negate: false,
        operator: 'EQUAL_TO',
        stringValue: 'str',
      },
    ],
    id: 0,
    segment: 0,
    segmentType: 'ENTER',
    type: 'EVENT',
  },
];

export const AttributeTriggerStatusType = [
  {
    name: 'any',
    value: 'ANY',
  },
  {
    name: 'specific',
    value: 'SPECIFIC',
  },
];

export const delayTypeOptions = [
  {
    name: 'Immediately',
    value: 'immediately',
  },
  {
    name: 'After',
    value: 'after',
  },
  {
    name: 'Before',
    value: 'before',
  },
];

export const delayTypeOptionsSegmentStep = [
  {
    name: 'Never',
    value: 'NEVER',
  },
  {
    name: 'Immediately',
    value: 'IMMEDIATELY',
  },
  {
    name: 'After',
    value: 'AFTER',
  },
];

export const GapBaseOptions = [
  {
    value: 'START',
    name: 'from the beginning date of journey',
  },
  {
    value: 'END',
    name: 'from the end date of journey',
  },
];
