import React, { useContext } from 'react';

import { AppContext } from '../../app';
import { Button, Select, Form } from 'antd';

const CondIsInSegment = (props) => {
  const [form] = Form.useForm();
  const { journey, syncCache } = useContext(AppContext);
  const { segments } = props.data;

  const onFinish = (e) => {
    let currentStep = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );
    currentStep['segment'] = e.triggerEvent;
    delete currentStep.ui?.error;
    syncCache('update-step', currentStep, true);
    // openSnackBar("success", {
    //   message: "Successfully updated",
    // });
    props.closeModal();
  };

  const onSelectChange = () => {
    // syncCache("conversionEvent", value);
  };

  const selectedNode = journey?.steps?.filter(
    (item) => item.id + '' === props?.node?.id
  );

  return (
    <>
      <Form form={form} onFinish={onFinish} className="modal_wrap">
        <div className={'modal-body'}>
          <Form.Item
            label="Check if user is in "
            name="triggerEvent"
            rules={[{ required: true, message: 'Please add an segment!' }]}
          >
            <Select
              style={{ width: 300 }}
              showSearch
              filterOption={(input, option) => {
                if (option.label) {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }
              }}
              placeholder="Select a segment"
              onChange={onSelectChange}
              defaultValue={selectedNode?.length && selectedNode[0]?.segment}
              allowClear
              disabled={props.readOnly}
            >
              {segments?.map((item) => (
                <Select.Option key={item.id} value={item.id} label={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <IconFilter className={"icon-sm"} /> */}
        </div>
        <div className={'modal-footer'}>
          <Form.Item>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              disabled={props.readOnly}
            >
              Save State
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
export default CondIsInSegment;
