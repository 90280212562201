import ListBase from '@Component/form/ListBase';
import React, { useState } from 'react';
import moment from 'moment/moment';
import { isEmpty } from 'lodash';
import { Button, Input, Spin } from 'antd';
import {
  DownloadOutlined,
  LoadingOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import styles from './FileProgressList.module.less';
import {
  dataFileProgressListFetch,
  dataFileProgressEventListFetch,
} from '@Redux/slices/data/dataPlatformSlice';
import { useDispatch } from 'react-redux';
import Card from '@Utils/Card';

const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;

export function readableDate(x) {
  if (x) {
    return moment(x).format('DD MMM[,] YYYY');
  }
  return x;
}
export function renderFileStatus(x) {
  switch (x) {
    case 'COMPLETED_WITH_ERROR':
      return (
        <span
          className={`${styles.listsStatusError} ${styles.listsStatusDefult}`}
        >
          {x}
        </span>
      );
    case 'SUCCEEDED':
      return (
        <span
          className={`${styles.listsStatusSuccess} ${styles.listsStatusDefult}`}
        >
          {x}
        </span>
      );
    case 'NEW':
      return <span className={styles.listsStatusDefult}>NOT PROCESSED</span>;
    case 'IN_PROGRESS':
      return <span className={styles.listsStatusDefult}>IN PROGRESS</span>;
    default:
      return <span className={styles.listsStatusDefult}>{x}</span>;
  }
}
function FileProgressList(props) {
  const [name, setName] = useState(null);
  const dispatch = useDispatch();
  let columns;
  columns = [
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
      fixed: 'left',
      render: (value) => {
        return (
          <span
            style={{
              display: 'block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '200px',
            }}
            title={value}
          >
            {value ? value : 'UNKNOWN'}
          </span>
        );
      },
    },
    {
      title: 'uploaded by',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (text) => <span>{text.presentation}</span>,
    },
    {
      title: 'processed records',
      dataIndex: 'totalProcessedRecords',
      key: 'totalProcessedRecords',
    },
    {
      title: 'uploaded on',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (x) => readableDate(x),
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      render: (x) => renderFileStatus(x),
    },
    {
      title: 'action',
      dataIndex: 'status',
      key: 'errorFilePath',
      fixed: 'left',
      width: 100,
      render: (text, record) => {
        if (text === 'SUCCEEDED') {
          return (
            <a href={record.uploadPath} className={styles.listsDownload}>
              <DownloadOutlined />
            </a>
          );
        } else if (text == 'COMPLETED_WITH_ERROR') {
          return (
            <span
              href={record.errorFilePath}
              className={styles.listsLink}
              onClick={() => {
                props.fixUploadedFile(record.errorFilePath);
              }}
            >
              Fix
            </span>
          );
        } else {
          return (
            <a
              href={record.uploadPath}
              target={'blank'}
              className={styles.listsDownload}
            >
              <DownloadOutlined />
            </a>
          );
        }
      },
    },
  ];
  const handleChangeSearch = (e) => {
    const value = e.target.value;
    if (!value) {
      if (props.type === 'CREAT_USER') {
        dispatch(
          dataFileProgressListFetch({
            ...props.fileProgressList.pageRequest,
            fileName: null,
            page: 0,
          })
        );
      } else {
        dispatch(
          dataFileProgressEventListFetch({
            ...props.fileProgressList.pageRequest,
            fileName: null,
            page: 0,
          })
        );
      }
    }
    setName(value);
  };
  return (
    <Card
      styles={{ marginTop: 20, padding: 24 }}
      loading={props.fileProgressList.loadingList}
    >
      <div className={styles.lists}>
        <div className={styles.listsHeader}>
          <div className={styles.listsHeaderTitle}>
            <span>History </span>
            <Button
              type="primary"
              shape="circle"
              size={'small'}
              className={styles.listsHeaderIcon}
              onClick={() => {
                if (props.type === 'CREAT_USER') {
                  dispatch(
                    dataFileProgressListFetch(
                      props.fileProgressList.pageRequest
                    )
                  );
                } else if (props.type === 'CREAT_EVENT') {
                  dispatch(
                    dataFileProgressEventListFetch(
                      props.fileProgressList.pageRequest
                    )
                  );
                }
              }}
            >
              {' '}
              <i className={`fl-sync fl-light`} />
            </Button>
          </div>
          <Input
            loading={true}
            allowClear={props.fileProgressList.loadingList ? false : true}
            placeholder={'Search for a report'}
            onPressEnter={() => {
              if (props.type === 'CREAT_USER') {
                dispatch(
                  dataFileProgressListFetch({
                    ...props.fileProgressList.pageRequest,
                    fileName: name,
                    page: 0,
                  })
                );
              } else {
                dispatch(
                  dataFileProgressEventListFetch({
                    ...props.fileProgressList.pageRequest,
                    fileName: name,
                    page: 0,
                  })
                );
              }
            }}
            onChange={(e) => {
              handleChangeSearch(e);
            }}
            value={name}
            style={{ width: 300, borderRadius: 5 }}
            suffix={
              isEmpty(name) ? (
                <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              ) : (
                props.fileProgressList.loadingList && (
                  <div style={styles.inputLoadingSpinStyle}>
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                )
              )
            }
          />
        </div>
        <ListBase
          onChange={(p) => {
            if (props.type === 'CREAT_USER') {
              dispatch(
                dataFileProgressListFetch({
                  ...props.fileProgressList.pageRequest,
                  page: p.page,
                })
              );
            } else {
              dispatch(
                dataFileProgressEventListFetch({
                  ...props.fileProgressList.pageRequest,
                  page: p.page,
                })
              );
            }
          }}
          page={props.fileProgressList.page}
          pageRequest={props.fileProgressList.pageRequest}
          columns={columns}
        />
      </div>
    </Card>
  );
}

export default FileProgressList;
