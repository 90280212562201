import PagesHeader from '@Component/layout/PagesHeader';
//import PagesSection from '@Component/layout/PagesSection';
import PagesWrapper from '@Component/layout/PagesWrapper';
import React from 'react';
import SystemAttributeListContainer from './components/systemAttribute/container/SystemAttributeListContainer';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import CustomAttributeListContainer from './components/customAttribute/container/CustomAttributeListContainer';
import RevenueMappingContainer from './components/revenueMapping/container/RevenueMappingContainer';
import CustomEventListContainer from './components/customEvent/container/CustomEventListContainer';
import BusinessEventListComponent from './components/businessEvent/component/BusinessEventListComponent';
import BusinessEventCreateComponent from './components/businessEvent/component/BusinessEventCreateComponent';
import CsvExportLog from '@Component/utils/CsvExport/CsvExportLog';

function DataManagement() {
  let match = useRouteMatch();
  return (
    <>
      <PagesHeader
        title="Data Management"
        subMenuItems={[
          { title: 'System Attributes', route: `../system/attributes` },
          { title: 'User Attributes', route: `../user/attributes` },
          { title: 'Custom Events', route: `../events/attributes` },
          { title: 'Revenue Mapping', route: `../events/revenue` },
          { title: 'Business Events', route: `../events/business` },
          { title: 'CSV Export Log', route: `../csv/csv-export-log` },
        ]}
      />
      <PagesWrapper>
        <Switch>
          <Route exact path={`${match.path}system/attributes`}>
            <SystemAttributeListContainer />
          </Route>
          <Route path={`${match.path}user/attributes`}>
            <CustomAttributeListContainer />
          </Route>
          <Route path={`${match.path}events/attributes`}>
            <CustomEventListContainer />
          </Route>
          <Route path={`${match.path}events/revenue`}>
            <RevenueMappingContainer />
          </Route>
          <Route path={`${match.path}events/business`}>
            <BusinessEventListComponent />
          </Route>
          <Route path={`${match.path}events/business-events-new`}>
            <BusinessEventCreateComponent />
          </Route>
          <Route path={`${match.path}csv/csv-export-log`}>
            <CsvExportLog type="DATA_MANAGMENT" />
          </Route>
        </Switch>
      </PagesWrapper>
    </>
  );
}

export default DataManagement;
