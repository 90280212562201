import * as React from 'react';

function SvgTupe(props) {
  return (
    <svg viewBox="0 0 26 26" width="1em" height="1em" {...props}>
      <path
        d="M17.772 5.248a.43.43 0 00.147.587c2.103 1.54 3.506 4.035 3.506 6.823 0 .22 0 .477-.037.697-.037.22.147.44.369.477l3.653.697c.258.036.516-.11.516-.367.074-.477.074-.99.074-1.504 0-4.475-2.25-8.4-5.72-10.748-.22-.146-.516-.073-.627.147l-1.881 3.191zm-1.875 15.69c-1.144.403-2.398.55-3.69.44-4.095-.403-7.38-3.815-7.6-7.923-.296-4.879 3.615-8.914 8.486-8.914 1.07 0 2.066.184 3.025.55.222.074.443 0 .554-.183l1.882-3.191c.147-.22.037-.513-.185-.624A13.575 13.575 0 0012.133.03C5.676.47.473 5.642.031 12.06-.486 19.654 5.566 26 13.093 26c1.77 0 3.468-.367 5.018-.99a.452.452 0 00.221-.624l-1.845-3.228c-.147-.183-.369-.293-.59-.22zm5.15-5.231c-.59 1.65-1.734 3.044-3.136 4.034-.185.147-.258.404-.148.587l1.845 3.192c.11.22.406.293.627.146 2.51-1.65 4.428-4.108 5.35-7.006.074-.257-.073-.513-.331-.55l-3.69-.697c-.222-.037-.406.073-.517.294z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgTupe;
