import React from 'react';
import { Input, Modal, Button } from 'antd';
import { history } from '../../../../redux/store';
import vars from '@Theme/styles/vars.js';
const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    direction: 'ltr',
    fontWeight: '700',
    color: '#3d404e',
    fontSize: 18,
    textAlign: 'left',
  },
  rowItem: {
    display: 'flex',
    flexDirection: 'row',
    color: '#707070',
    marginTop: 10,
    marginBottom: 10,
  },
  buttons: {
    direction: 'ltr',
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: '#ffffff',
    background: vars.primaryColor,
    cursor: 'pointer',
    position: 'relative',
    fontSize: 13,
    fontWeight: '600',
    padding: '0 35px',
    margin: '0px 5px',
  },
};
class RolesUpdateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingId: null,
      url: '',
      openModal: true,
    };
  }

  render() {
    return (
      <Modal
        width={'85%'}
        title={<div style={styles.header}>Create Role</div>}
        visible={this.state.openModal}
        onCancel={() => {
          if (this.props.pathname.includes('create')) {
            history.push('../roles');
          } else {
            history.push('../roles');
          }
        }}
        footer={
          <Button
            type="primary"
            shape="round"
            size={'large'}
            loading={this.props.loading}
            style={styles.buttons}
            onClick={() => this.setState({ permissionsModalOpen: false })}
          >
            {'SAVE'}
          </Button>
        }
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 50,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 50,
              color: '#707070',
            }}
          >
            <div style={{ marginBottom: 30 }}>ROLE NAME</div>
            <div>PERMISSIONS</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 50,
              color: '#707070',
              width: 300,
            }}
          >
            <Input></Input>
          </div>
        </div>
      </Modal>
    );
  }
}
export default RolesUpdateComponent;
