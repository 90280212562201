exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ConfigurationUpdateComponent-module__inner--1KclH {\n  background-color: white;\n  border-radius: 5px;\n  padding: 30px;\n  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);\n}\n.ConfigurationUpdateComponent-module__itemInput--3YbfE {\n  font-size: 13px;\n  color: #3d404e;\n  margin-left: 20px;\n  margin-bottom: 30px;\n}\n.ConfigurationUpdateComponent-module__itemInput--3YbfE > div {\n  margin-bottom: 0;\n}\n.ConfigurationUpdateComponent-module__itemHint--240I2,\n.ConfigurationUpdateComponent-module__itemHint--240I2 > h2 {\n  font-size: 12px !important;\n  color: #787878;\n}\n.ConfigurationUpdateComponent-module__loadingSpinStyle--2BYRZ {\n  height: 180px;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  padding-top: 10px;\n  padding-bottom: 10px;\n}\nhr {\n  height: 1px !important;\n  background-color: #ccc;\n  border: 0;\n  margin: 20px 0;\n}\nh3 {\n  margin-bottom: 30px;\n}\n#configuration .ant-form-item-label > label {\n  color: #707070 !important;\n  text-transform: uppercase;\n  font-size: 13px;\n}\n#configuration .ant-form-item-with-help .ant-form-item-explain {\n  position: absolute;\n}\n", ""]);

// exports
exports.locals = {
	"inner": "ConfigurationUpdateComponent-module__inner--1KclH",
	"itemInput": "ConfigurationUpdateComponent-module__itemInput--3YbfE",
	"itemHint": "ConfigurationUpdateComponent-module__itemHint--240I2",
	"loadingSpinStyle": "ConfigurationUpdateComponent-module__loadingSpinStyle--2BYRZ"
};