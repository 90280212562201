import JourneyReportComponent from '../component/JourneyReportComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  journeyUpdate,
  journeyStop,
  journeyPause,
  journeyLaunch,
  journeyGetReportOverview,
  journeyUpdateSubmit,
  journeyListAttribtesFetch,
  journeyListEventsFetch,
  journeyListBusinessEventsFetch,
  journeyBusinessEventsReportFetch,
  journeyListSegmentsFetch,
  journeyGetReportLive,
  journeyClearLaunchData,
} from '../JourneyActions';

import {
  productSegmentReportViewFetch,
  productSegmentRecommendUserAttributesFetch,
  productSegmentRecommendDeviceAttributesFetch,
  productSegmentRecommendEventAttributesFetch,
} from '../../../insight/productSegment/ProductSegmentActions';

import { clearModalData } from '../../communication/CommunicationActions';

const mapStateToProps = (state) => ({
  loadingUpdateJourney: state.engage.journey.loadingUpdateJourney,
  successUpdateJourney: state.engage.journey.successUpdateJourney,
  errorUpdateJourney: state.engage.journey.errorUpdateJourney,

  attributes: state.engage.journey.attributes,

  loadingUpdateSubmitJourney: state.engage.journey.loadingUpdateSubmitJourney,
  successUpdateSubmitJourney: state.engage.journey.successUpdateSubmitJourney,
  errorUpdateSubmitJourney: state.engage.journey.errorUpdateSubmitJourney,

  loadingGetSegments: state.engage.journey.loadingSegmentsList,
  successGetSegments: state.engage.journey.segments,
  errorGetSegments: state.engage.journey.errorSegments,

  loadingGetEvents: state.engage.journey.loadingEventsList,
  successGetEvents: state.engage.journey.events,
  errorGetEvents: state.engage.journey.errorEvents,

  loadingGetBusinessEvents: state.engage.journey.loadingBusinessEventsList,
  successGetBusinessEvents: state.engage.journey.businessEvents,
  errorGetBusinessEvents: state.engage.journey.errorBusinessEvents,

  loadingBusinessEventsReport: state.engage.journey.loadingBusinessEventsReport,
  successGetBusinessEventsReport: state.engage.journey.businessEventsReport,
  errorGetBusinessEventsReport: state.engage.journey.errorBusinessEventsReport,

  loadingOverview: state.engage.journey.loadingOverview,
  overviewData: state.engage.journey.overviewData,
  errorOverview: state.engage.journey.errorOverview,

  loadingLive: state.engage.journey.loadingLive,
  liveData: state.engage.journey.liveData,
  errorLive: state.engage.journey.errorLive,

  loadingLaunch: state.engage.journey.loadingLaunch,
  launchData: state.engage.journey.launchData,
  errorLaunch: state.engage.journey.errorLaunch,

  loadingStop: state.engage.journey.loadingStop,
  stopData: state.engage.journey.stopData,
  errorStop: state.engage.journey.errorStop,

  loadingPause: state.engage.journey.loadingPause,
  pauseData: state.engage.journey.pauseData,
  errorPause: state.engage.journey.errorPause,

  loadingSegmentReportView: state.insight.productSegment.loadingReportView,
  segmentReportView: state.insight.productSegment.currentReport,
  errorSegmentReportView: state.insight.productSegment.errorReportView,
  loadingRecommendUserAttributes:
    state.insight.productSegment.loadingRecommendUserAttributes,
  recommendUserAttributes: state.insight.productSegment.recommendUserAttributes,
  loadingRecommendEventAttributes:
    state.insight.productSegment.loadingRecommendEventAttributes,
  recommendEventAttributes:
    state.insight.productSegment.recommendEventAttributes,
  loadingRecommendDeviceAttributes:
    state.insight.productSegment.loadingRecommendDeviceAttributes,
  recommendDeviceAttributes:
    state.insight.productSegment.recommendDeviceAttributes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      journeyUpdate,
      journeyStop,
      journeyPause,
      journeyLaunch,
      clearModalData,
      journeyClearLaunchData,
      journeyGetReportOverview,
      journeyUpdateSubmit,
      journeyListEventsFetch,
      journeyListBusinessEventsFetch,
      journeyBusinessEventsReportFetch,
      journeyListSegmentsFetch,
      journeyListAttribtesFetch,
      journeyGetReportLive,
      productSegmentReportViewFetch,
      productSegmentRecommendUserAttributesFetch,
      productSegmentRecommendDeviceAttributesFetch,
      productSegmentRecommendEventAttributesFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JourneyReportComponent);

JourneyReportComponent.propTypes = {};
