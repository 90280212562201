import React from 'react';
import {
  Input,
  Checkbox,
  Button,
  Select,
  Pagination,
  Tooltip,
  Spin,
} from 'antd';
import { isEmpty, isNil } from 'lodash';
import { Link } from 'react-router-dom';
import {
  SearchOutlined,
  ExportOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { numberWithCommas, renderFullName } from '../../../utils/RenderUtils';
import { readableDateTime } from '../../../utils/DateUtils';
import { useState, useEffect } from 'react';
import '../searchUserListBase/style/SearchUserListBase.less';
import { getSegmentId } from '../../../utils/AuthorityProvider';
import { HashBase64 } from '@Component/utils/HashBase64';
import CsvButton from '@Component/utils/CsvExport/CsvButton';
import vars from '@Theme/styles/vars';
import { featureAccessCheckCSVExport } from '@Utils/AccessManagement';
import ExportSegmentModal from './ExportSegmentModal';

const SearchUserListBase = (props) => {
  const usersPathName = '/users/search';
  const segmentsPathName = '/segments/';
  const usersDetailPathName = 'view/';
  const segmentsDetailPathName = '../../../users/view/';
  useEffect(() => {
    if (props.pathname.includes(usersPathName)) {
      props.insightProductUserListFetch({
        page: 0,
        known: true,
        query: '',
        segment: null,
      });
      props.insightProductUserListAttributeFetch();
    }
    if (props.pathname.includes(segmentsPathName)) {
      const segmentId = getSegmentId(props.pathname);
      props.insightProductUserListFetch({
        page: 0,
        known: true,
        query: '',
        segment: Number(segmentId),
      });
      props.insightProductUserListAttributeFetch();
    }
  }, []);
  const [showAttributes, setShowAttributes] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleCloseExportModal = () => {
    setIsExportModalOpen(false);
    setSelectedColumns([]);
  };

  const handleOpenExportModal = () => {
    setIsExportModalOpen(true);
  };

  const handleExportSegment = () => {
    props.callExportSegmentFile({
      id: getSegmentId(props.pathname),
      body: {
        headers: selectedColumns,
      },
      cb: handleCloseExportModal,
    });
  };

  const isSearchUnknown = () => {
    return props.usersListPageRequest.known === false;
  };

  const handleChangeSearch = (query) => {
    props.insightProductUserPageChangeCurrent({
      ...props.usersListPageRequest,
      query,
    });
    if (isEmpty(query) || isNil(query)) {
      props.insightProductUserListFetch({
        ...props.usersListPageRequest,
        page: 0,
        query: null,
      });
    }
  };

  const renderSearch = () => {
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    return (
      <div className="searchBoxContainer">
        <div
          style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}
        >
          <div className="flex-center">
            <Input
              loading={true}
              allowClear={props.usersSerachListLoading ? false : true}
              placeholder={
                props.usersListPageRequest.known === true
                  ? 'Search by user ID, email or phone'
                  : 'Search by anonymous ID'
              }
              onPressEnter={() =>
                props.insightProductUserSearchListFetch({
                  ...props.usersListPageRequest,
                  page: 0,
                })
              }
              onChange={(e) => handleChangeSearch(e.target.value)}
              value={
                props.usersListPageRequest && props.usersListPageRequest.query
              }
              style={{ width: 300, borderRadius: 5 }}
              suffix={
                props.usersListPageRequest &&
                isEmpty(props.usersListPageRequest.query) ? (
                  <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                ) : (
                  props.usersSerachListLoading && (
                    <div className="inputLoadingSpinStyle">
                      <Spin indicator={inputLoadingSpin} />
                    </div>
                  )
                )
              }
            />

            {props.csvExport && featureAccessCheckCSVExport() && (
              <CsvButton
                label="List Of Users"
                callApi={handleOpenExportModal}
                type="segment"
              ></CsvButton>
            )}

            <Select
              onChange={(known) =>
                props.insightProductUserListFetch({
                  ...props.usersListPageRequest,
                  page: 0,
                  known,
                })
              }
              value={props.usersListPageRequest.known}
              defaultValue="known"
              style={{ width: 160, marginLeft: 15 }}
              className={'chart-selector'}
              bordered={false}
            >
              <Select.Option value={true}>Known Users</Select.Option>
              <Select.Option value={false}>Unknown Users</Select.Option>
            </Select>
          </div>
          {false && (
            <div
              onClick={() => setShowAttributes(!showAttributes)}
              style={{
                fontSize: 13,
                color: vars.primaryColor,
                cursor: 'pointer',
              }}
            >
              {showAttributes ? 'Hide Attributes' : 'Show Attributes'}
            </div>
          )}
        </div>
        {showAttributes && (
          <div
            style={{ marginTop: 60, display: 'flex', flexDirection: 'column' }}
          >
            <div className="searchSection">
              <div className="searchRow">
                <div className="searchTitle">BASIC INFORMATION</div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>User ID</Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>Name</Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>Phone</Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>
                    Date of Birth
                  </Checkbox>
                </div>
              </div>
              <div className="searchRowNotFirst">
                <div className="searchTitle"></div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>User Type</Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>Email</Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>Gender</Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>Company</Checkbox>
                </div>
              </div>
            </div>
            <div className="searchSection">
              <div className="searchRow">
                <div className="searchTitle">LOCATION</div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>City</Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>Country</Checkbox>
                </div>
                <div className="searchItem"></div>
                <div className="searchItem"></div>
              </div>
            </div>
            <div className="searchSection">
              <div className="searchRow">
                <div className="searchTitle">ACTIVITY</div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>Identified</Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>
                    Total Time (App Only)
                  </Checkbox>
                </div>
                <div className="searchItem"></div>
                <div className="searchItem"></div>
              </div>
            </div>
            <div className="searchSection">
              <div className="searchRow">
                <div className="searchTitle">ACQUISITION</div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>Channel</Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>
                    Campaign Medium
                  </Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>
                    Referrer Host
                  </Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>Landing Page</Checkbox>
                </div>
              </div>
              <div className="searchRowNotFirst">
                <div style={{ display: 'flex', flex: 1 }}></div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>
                    Campaign Source
                  </Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>
                    Campaign Name
                  </Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>Referrer URL</Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>User ID</Checkbox>
                </div>
              </div>
            </div>
            <div className="searchSection">
              <div className="searchRow">
                <div className="searchTitle">ENGAGEMENT</div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>
                    Total Sessions
                  </Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>Created On</Checkbox>
                </div>
                <div className="searchItem">
                  <Checkbox disabled={isSearchUnknown()}>Last Seen</Checkbox>
                </div>
                <div className="searchItem"></div>
              </div>
            </div>
            <div className="searchSection">
              <div className="searchRow">
                <div className="searchTitle">CUSTOM ATTRIBUTES</div>
                <div className="searchItem"></div>
                <div className="searchItem"></div>
                <div className="searchItem"></div>
                <div className="searchItem"></div>
              </div>
            </div>
            {!isSearchUnknown() && (
              <div className="searchButtonContainerFlexEnd">
                <Button
                  shape="round"
                  type="link"
                  style={{ fontSize: 12, fontWeight: 600 }}
                >
                  RESET
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    width: 100,
                    boxShadow: '0 3px 6px 0 rgba(29, 136, 246, 0.5)',
                  }}
                >
                  APPLY
                </Button>
              </div>
            )}
            {isSearchUnknown() && (
              <div className="searchButtonContainerSpaceBetween">
                <div style={{ fontSize: 12, color: '#1c1c1c' }}>
                  Attributes are unavailable for unknown users
                </div>
                <div>
                  <Button
                    shape="round"
                    type="link"
                    style={{ fontSize: 12, fontWeight: 600 }}
                  >
                    RESET
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    style={{
                      fontSize: 12,
                      fontWeight: 600,
                      width: 100,
                      boxShadow: '0 3px 6px 0 rgba(29, 136, 246, 0.5)',
                    }}
                  >
                    APPLY
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderUserId = (object) => {
    if (!isNil(object.userId)) {
      return object.userId;
    }
    return (
      <Tooltip placement={'top'} title={'Anonymous'}>
        {object.anonymousId}
        <i
          className="fl-info"
          style={{
            color: '#bbbcc1',
            fontSize: 24,
            fontWeight: 400,
            position: 'relative',
            top: 6,
          }}
        ></i>
      </Tooltip>
    );
  };

  const renderSingleUser = (item) => {
    return (
      <div key={item.id} className="resultContainer">
        <div className="resultRow">
          <Link
            to={
              props.pathname.includes(usersPathName)
                ? usersDetailPathName + HashBase64().encode(item.id) + '/basic'
                : segmentsDetailPathName +
                  HashBase64().encode(item.id) +
                  '/basic'
            }
          >
            <div>
              <span className="resultTitle">USER ID</span>
              <span className="resultValueBlue">{renderUserId(item)}</span>
            </div>
          </Link>
        </div>
        <div className="resultRow">
          <div className="resultSection">
            <span className="resultTitle">NAME</span>
            <span className="resultValue">
              {renderFullName(item.firstName, item.lastName)}
            </span>
          </div>
          <div className="resultSection">
            <span className="resultTitle">PHONE</span>
            <span className="resultValue">
              {isNil(item.phone) ? '-' : item.phone}
            </span>
          </div>
          <div className="resultSection">
            <span className="resultTitle">CREATED ON</span>
            <span className="resultValue">
              {isNil(item.createdDate)
                ? '-'
                : readableDateTime(item.createdDate)}
            </span>
          </div>
        </div>
        <div className="resultRowLast">
          <div className="resultSection">
            <span className="resultTitle">EMAIL</span>
            <span className="resultValue">
              {isNil(item.email) ? '-' : item.email}
            </span>
          </div>
          <div className="resultSection">
            <span className="resultTitle">TOTAL SESSIONS</span>
            <span className="resultValue">
              {isNil(item.totalSessions)
                ? '-'
                : numberWithCommas(item.totalSessions)}
            </span>
          </div>
          <div className="resultSection">
            <span className="resultTitle">LAST SEEN</span>
            <span className="resultValue">
              {isNil(item.lastSeen) ? '-' : readableDateTime(item.lastSeen)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderPagination = (list) => {
    if (list) {
      return (
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          <Pagination
            showSizeChanger={false}
            current={list.number + 1}
            pageSize={list.size}
            total={list.totalElements}
            onChange={(target) =>
              props.insightProductUserListFetch({
                ...props.usersListPageRequest,
                page: target - 1,
              })
            }
          />
        </div>
      );
    }
  };

  const renderResultsCount = () => {
    const count = props.usersListLoading
      ? 0
      : props.usersList
      ? props.usersList.totalElements
      : 0;
    if (count === 0 && props.usersListLoading) {
      return 'Loading Results...';
    }
    if (count === 0 && !props.usersListLoading) {
      return 'No results have been found.';
    }
    if (count === 1) {
      return '1 result have been found.';
    }
    if (count == 10000) {
      return 'More than ' + count + ' results have been found.';
    }
    return count + ' results have been found.';
  };

  const renderResults = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: 20,
            paddingBottom: 10,
          }}
        >
          <div
            style={{
              color: '#1c1c1c',
              fontSize: 12,
              marginLeft: 20,
              fontWeight: '600',
            }}
          >
            {renderResultsCount()}
          </div>
          {false && (
            <Button
              type="link"
              shape="round"
              style={{ fontSize: 12, fontWeight: 600 }}
            >
              <ExportOutlined
                style={{ color: vars.primaryColor, fontSize: 14 }}
              />
              Export result
            </Button>
          )}
        </div>
        {props.usersListLoading === true ? (
          <div className="loadingSpinStyle">
            <Spin indicator={loadingSpinStyle} />
          </div>
        ) : (
          <div>
            {props.usersList &&
              props.usersList.content.map((item) => renderSingleUser(item))}
            {renderPagination(props.usersList)}
          </div>
        )}
      </div>
    );
  };
  const loadingSpinStyle = <LoadingOutlined style={{ fontSize: 30 }} spin />;
  return (
    <div>
      {renderSearch()}
      {renderResults()}
      <ExportSegmentModal
        isExportModalOpen={isExportModalOpen}
        onCancelModal={handleCloseExportModal}
        handleExportSegment={handleExportSegment}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
      />
    </div>
  );
};
export default SearchUserListBase;
