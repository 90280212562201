import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Transfer, Button, Switch } from 'antd';
import FormBase from '../../../../component/form/FormBase';
import { errorHandling } from '../../../../utils/FormUtils';
import Card from '@Utils/Card';
class RoleUpdateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productId:
        this.props.pathname.includes('update') &&
        this.props.pathname.split('update/').pop(),
      copyId:
        this.props.pathname.includes('copy') &&
        this.props.pathname.split('copy/').pop(),
    };
  }
  componentDidMount() {
    this.props.roleAuthorityListFetch();
    if (this.state.copyId) {
      this.props.roleCopyFetch(this.state.copyId);
    } else if (this.state.productId) {
      this.props.roleUpdateFetch(this.state.productId);
    }
  }

  hasId() {
    return this.state.productId || this.state.copyId;
  }

  componentWillUnmount() {
    this.props.roleChangeCurrent({});
  }

  onFinish() {
    if (this.state.productId) {
      this.props.roleUpdateSubmitFetch(
        this.state.productId,
        this.props.currentRole
      );
    } else {
      this.props.roleCreateFetch(this.props.currentRole, this.state.copyId);
    }
  }
  render() {
    return (
      <div>
        <Card loading={this.props.loadingUpdateRole} styles={{ padding: 30 }}>
          <FormBase
            errors={this.props.errors}
            value={this.props.currentRole}
            onChange={(values) => this.props.roleChangeCurrent(values)}
            hideSubmit
          >
            <Form.Item
              onChange={() =>
                this.props.clearRolesErrors?.(this.props.errors.errors, 'name')
              }
              validateStatus={errorHandling(this.props.errors, 'name')}
              help={errorHandling(this.props.errors, 'name') ? 'error' : null}
              label="Name"
              name="name"
            >
              <Input style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="Team Member" name="teamMember">
              <Switch
                checked={
                  this.props.currentRole && this.props.currentRole.teamMember
                }
              ></Switch>
            </Form.Item>
            <Form.Item
              label="Authorities"
              name="authorities"
              valuePropName="targetKeys"
            >
              <Transfer
                listStyle={{
                  minWidth: 300,
                  height: 300,
                }}
                dataSource={this.props.authorities.map((x) => ({
                  key: x.id,
                  title: x.message,
                }))}
                titles={['Source', 'Target']}
                showSearch
                render={(item) => item.title}
              />
            </Form.Item>
          </FormBase>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Link to={this.hasId() ? '../list' : 'list'}>
              <Button
                type="primary"
                shape="round"
                size={'large'}
                loading={this.props.loading}
                className="w-200"
              >
                {'Back'}
              </Button>
            </Link>
            <Button
              onClick={() => this.onFinish()}
              type="primary"
              shape="round"
              size={'large'}
              loading={this.props.roleSubmitLoading}
              className="w-200"
            >
              {'Submit'}
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default RoleUpdateComponent;
