import * as React from 'react';

function SvgUser(props) {
  return (
    <svg viewBox="0 0 16 16" width="1em" height="1em" {...props}>
      <g fill="#fff" fillRule="evenodd">
        <path
          d="M8.208 6.898c1.805 0 3.262-1.482 3.262-3.306S10.013.286 8.208.286c-1.806 0-3.273 1.482-3.273 3.306s1.467 3.306 3.273 3.306z"
          fillRule="nonzero"
        />
        <path d="M8.208 9.102C5.66 9.102.57 10.392.57 12.959v1.249c0 .832.676 1.506 1.493 1.506h12.288c.824 0 1.492-.667 1.492-1.506v-1.249c0-2.568-5.089-3.857-7.636-3.857z" />
      </g>
    </svg>
  );
}

export default SvgUser;
