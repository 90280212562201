import { defaultActions } from '@Component/form/ListBase';
import {
  clearErrorsContract,
  contractDeleteFetch,
  contractViewFetch,
} from '@Redux/slices/admin/adminProductContractSlice';
import { AuthorityProvider, hasAnyAuthority } from '@Utils/AuthorityProvider';
import Card from '@Utils/Card';
import { readableDateTime } from '@Utils/DateUtils';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Table, Tag } from 'antd';
import moment from 'moment';
const { Panel } = Collapse;
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

function AdminProductArchivedContract({
  dataList,
  setIsModalOpen,
  setViewMode,
}) {
  const dispatch = useDispatch();
  const loaingList = useSelector((state) => state.admin.contract.loaingList);

  const figureStatus = (x) => {
    if (moment(x?.endDate, 'YYYY-MM-DD').isBefore(moment())) {
      return 'past';
    } else if (
      moment(x?.startDate, 'YYYY-MM-DD').isBefore(moment()) &&
      !moment(x?.endDate, 'YYYY-MM-DD').isBefore(moment())
    ) {
      return 'active';
    } else {
      return 'future';
    }
  };
  const columns = [
    {
      title: 'Template',
      dataIndex: ['contractTemplate', 'title'],
      key: 'contractTemplate',
    },
    {
      title: 'Contractor',
      dataIndex: 'contractor',
      key: 'contractor',
    },

    {
      title: 'Start Date',
      key: 'startDate',
      dataIndex: 'startDate',
      render: (date) => readableDateTime(date),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date) => readableDateTime(date),
    },
    {
      title: 'STATUS',
      dataIndex: '',
      key: 'status',
      render: (x) => {
        if (figureStatus(x) === 'past') {
          return <Tag color="gold">past</Tag>;
        } else if (figureStatus(x) === 'active') {
          return <Tag color="blue">active</Tag>;
        } else {
          return <Tag color="green">future</Tag>;
        }
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: '5%',
      render: (x) =>
        defaultActions(
          x,

          {
            action: (id) => {
              dispatch(clearErrorsContract());
              dispatch(contractViewFetch(id));
              setViewMode(false);
              setIsModalOpen(true);
            },
            title: 'Edit',
            enabled:
              hasAnyAuthority(AuthorityProvider.ROLE_CONTRACT_UPDATE) &&
              figureStatus(x) === 'future',
            disablePopConfirm: true,
          },
          {
            action: (id) => {
              dispatch(clearErrorsContract());
              dispatch(contractViewFetch(id));
              setViewMode(true);
              setIsModalOpen(true);
            },
            title: 'View',
            enabled: hasAnyAuthority(AuthorityProvider.ROLE_CONTRACT_VIEW),
            disablePopConfirm: true,
          },
          {
            action: (id) => {
              dispatch(contractDeleteFetch(id));
            },
            title: 'Delete',
            enabled:
              hasAnyAuthority(AuthorityProvider.ROLE_CONTRACT_DELETE) &&
              figureStatus(x) === 'future',
          }
        ),
    },
  ];
  const panelStyle = {
    marginBottom: 24,
    border: 'none',
    backgroundColor: '#fff',
  };
  return (
    <Card loading={loaingList} styles={{ marginTop: 20 }}>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel header="Archived Contract" key="1" style={panelStyle}>
          <Table columns={columns} dataSource={dataList} pagination={false} />
        </Panel>
      </Collapse>
    </Card>
  );
}

export default AdminProductArchivedContract;
