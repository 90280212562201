import moment from 'moment';
import {
  EVENT_CHANGE_CURRENT,
  PRODUCT_EVENT_ANALYTICS_FETCH,
  PRODUCT_EVENT_ANALYTICS_FETCH_FAIL,
  PRODUCT_EVENT_ANALYTICS_FETCH_SUCCESS,
  PRODUCT_EVENT_LIST_FETCH,
  PRODUCT_EVENT_LIST_FETCH_FAIL,
  PRODUCT_EVENT_LIST_FETCH_SUCCESS,
} from './EventAnalyticsConstants';

const lastWeek = moment().subtract(7, 'days');
const initialState = {
  eventLoading: false,
  eventListErrors: [],
  eventList: {},
  eventAnalytics: {},
  loadingEventAnalytics: false,
  eventAnalyticsErrors: [],
  isRefreshed: false,
  eventAnalyticsValue: {
    eventAnalyzeType: 'ALL_SYSTEM_EVENTS',
    uniqueness: false,
    fromDate: lastWeek,
    toDate: moment(),
  },
};
export default function usersStateReducer(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_EVENT_LIST_FETCH:
      return { ...state, eventLoading: true, eventListErrors: [] };
    case PRODUCT_EVENT_LIST_FETCH_FAIL:
      return {
        ...state,
        eventLoading: false,
        eventListErrors: action.payload.errors,
      };
    case PRODUCT_EVENT_LIST_FETCH_SUCCESS:
      return { ...state, eventLoading: false, eventList: action.payload };
    case PRODUCT_EVENT_ANALYTICS_FETCH:
      return {
        ...state,
        loadingEventAnalytics: true,
        eventAnalyticsErrors: [],
        isRefreshed: false,
      };
    case PRODUCT_EVENT_ANALYTICS_FETCH_FAIL:
      return {
        ...state,
        loadingEventAnalytics: false,
        eventAnalyticsErrors: action.payload.errors,
      };
    case PRODUCT_EVENT_ANALYTICS_FETCH_SUCCESS:
      return {
        ...state,
        loadingEventAnalytics: false,
        eventAnalytics: action.payload,
        isRefreshed: false,
      };
    case EVENT_CHANGE_CURRENT:
      return {
        ...state,
        eventAnalyticsValue: action.payload,
        errors: [],
        isRefreshed: true,
      };
    default:
      return state;
  }
}
