import {
  cohortListFetch,
  cohortDelete,
} from '@Redux/slices/analytics/cohortSlice';
import { hasAnyAuthority, AuthorityProvider } from '@Utils/AuthorityProvider';
import ListBase, { defaultActions } from '@Component/form/ListBase';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import InputSpinner from '../components/InputSpinner';
import { SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Loading from '../components/Loading';
import { history } from '@Redux/store';
import vars from '@Theme/styles/vars';
import { isEmpty } from 'lodash';
import Card from '@Utils/Card';
import { Input } from 'antd';
import './cohortList.less';

export default function CohortList() {
  const dispatch = useDispatch();

  const cohortList = useSelector((state) => state.analytics.cohort.cohortList);
  const loading = useSelector((state) => state.analytics.cohort.loading);
  const [search, setSearch] = useState('');
  const cohortListFilter = {
    direction: 'DESC',
    name: null,
    order: 'id',
    page: 0,
  };

  useEffect(() => {
    getList();
  }, []);

  const getList = (body = cohortListFilter) => {
    dispatch(cohortListFetch(body));
  };

  const handlePressEnterInput = () => {
    getList({
      direction: 'DESC',
      name: search,
      order: 'id',
      page: 0,
    });
  };

  const handleOnChangeInput = (e) => {
    setSearch(e.target.value);
    if (isEmpty(e.target.value)) {
      getList({
        direction: 'DESC',
        name: null,
        order: 'id',
        page: 0,
      });
    }
  };

  const getItemLink = (id) => {
    return `../cohorts/report/${id}`;
  };

  const columnList = [
    {
      sorter: false,
      title: 'COHORT NAME',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (x, object) => (
        <Link id={'list-name-' + x} to={getItemLink(object.id)}>
          <span
            style={{
              color: vars.primaryColor,
              fontSize: 14,
              cursor: 'pointer',
            }}
          >
            {x}
          </span>
        </Link>
      ),
    },
    {
      sorter: false,
      title: 'FIRST EVENT',
      dataIndex: 'firstEventFilter',
      key: 'firstEventFilter',
      width: 300,
      render: (x) => <>{x?.event?.name ?? '-'}</>,
    },
    {
      sorter: false,
      title: 'SECOND EVENT',
      dataIndex: 'returnEventFilter',
      key: 'returnEventFilter',
      width: 300,
      render: (x) => <>{x?.event?.name ?? '-'}</>,
    },
    {
      sorter: false,
      title: 'SPLIT BY',
      dataIndex: 'splitBy',
      key: 'splitBy',
      width: 300,
      render: (x) => <>{x?.name ?? '-'}</>,
    },
    {
      title: 'action',
      dataIndex: '',
      key: '-action-',
      width: '5%',
      render: (x) =>
        defaultActions(
          x,
          {
            title: 'Edit',
            action: () => history.push(`edit/${x.id}`),
            enabled: hasAnyAuthority(AuthorityProvider.ROLE_COHORT_UPDATE),
            disablePopConfirm: true,
          },
          {
            title: 'Delete',
            action: () => {
              dispatch(cohortDelete({ id: x.id, cb: getList }));
            },
            enabled: hasAnyAuthority(AuthorityProvider.ROLE_COHORT_DELETE),
          }
        ),
    },
  ];

  return (
    <Card>
      <div className="card-wrapper">
        <Input
          loading={true}
          allowClear={!loading}
          placeholder={'Search by cohort name'}
          onPressEnter={handlePressEnterInput}
          onChange={(e) => {
            handleOnChangeInput(e);
          }}
          value={search}
          className="search-input"
          suffix={
            isEmpty(search) ? (
              <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            ) : (
              loading && <InputSpinner />
            )
          }
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <ListBase
          onChange={getList}
          page={cohortList}
          pageRequest={cohortListFilter}
          columns={columnList}
        />
      )}
    </Card>
  );
}
