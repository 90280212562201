import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Input, Checkbox, Button } from 'antd';
import { showMessageError } from '../../../utils/RenderUtils';
import styles from './HomeComponent.module.less';
import { isNil } from 'lodash';
import LoginSecondStep from './LoginSecondStep';
import { getCaptcha } from '@Modules/account/auth/AuthActions';
import { useDispatch } from 'react-redux';
import { RedoOutlined } from '@ant-design/icons';
import ResetPassFirstStep from './ResetPassFirstStep';
import ResetPassSecondStep from './ResetPassSecondStep';
import inTrackLogo from '../../../../static/images/intrack/black-logo.png';
import dkLogo from '../../../../static/images/flavors/dk/dk-logo.png';
import mciLogo from '../../../../static/images/flavors/mci/mci-logo-login-page.png';
import snappLogo from '../../../../static/images/flavors/snapp/snapp-logo.png';
import ozoneLogo from '../../../../static/images/flavors/ozone/ozone-logo.png';
import { detectFlavor, flavors } from '@Utils/FlavorUtils';
import { thirdPartyEnums } from '@Modules/admin/thirdParty/components/ThirdPartyTypes';

const isStageMode = false; //process.env.ENVMode === 'stage'; TODO: temporarity for testing recapthca on stage
// const RECAPTCHA_SITE_KEY = '6Le1JpEhAAAAAMK-I0AkWFUCxriD_TZM6WwBbqg6';
// const RECAPTCHA_SCRIPT_ID = 'recaptchaScript';

const pages = {
  SIGN_UP: 'signup',
  LOGIN: 'login',
  LOGIN_SECOND_STEP: 'otp',
  RESET_PASS_FIRST_STEP: 'resetPassFirstStep',
  RESET_PASS_SECOND_STEP: 'resetPassSecondStep',
};

let logo;
switch (detectFlavor()) {
  case flavors.INTRACK:
    logo = inTrackLogo;
    break;
  case flavors.DK:
    logo = dkLogo;
    break;
  case flavors.MCI:
    logo = mciLogo;
    break;
  case flavors.SNAPP:
    logo = snappLogo;
    break;
  case flavors.OZONE:
    logo = ozoneLogo;
    break;
}

const SignInForm = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(
    isNil(props.registerResponse)
      ? props?.currentUser?.email ?? ''
      : props.registerResponse.email
  );

  const [password, setPassword] = useState('');
  // const [rememberMe, setRememberMe] = useState('');
  const [token, setToken] = useState(null);
  const [captchaEnabled, setCaptchaEnabled] = useState(null);
  const [captchaCode, setCaptchaCode] = useState(null);

  const handleGetCaptcha = () => {
    dispatch(getCaptcha(setToken, setCaptchaEnabled));
    setCaptchaCode(null);
  };

  useEffect(() => {
    handleGetCaptcha();
  }, []);

  const fetchLogin = () => {
    if (!isStageMode && captchaEnabled && !token) return;
    props.loginFetch({
      login: {
        username: email,
        password: password,
        rememberMe: props?.rememberMe,
        captchaToken: captchaEnabled ? token : null,
        captchaCode: captchaEnabled ? captchaCode?.trim() : null,
      },
      cb: props.goToLoginSecondStep,
      resendCaptcha: handleGetCaptcha,
    });
  };

  const handlePressEnter = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      fetchLogin();
    }
  };

  const handleClickSignUpButton = () => {
    showMessageError({
      status: 'Error',
      message: 'Please contact admin',
    });
  };

  let loginButtonStyle = {};
  if (detectFlavor() === flavors.DK) {
    loginButtonStyle = {
      backgroundColor: '#F05060',
      borderColor: '#F05060',
    };
  } else if (detectFlavor() === flavors.SNAPP) {
    loginButtonStyle = {
      backgroundColor: '#00E261',
      borderColor: '#00E261',
    };
  } else if (detectFlavor() === flavors.OZONE) {
    loginButtonStyle = {
      backgroundColor: '#6537FF',
      borderColor: '#6537FF',
    };
  }

  return (
    <div className={styles.form}>
      <img
        // TODO: The following logic should be handled in a better way, like
        //  using a pre-set size for logo, and having all logos fitted in the
        //  specified size
        width={detectFlavor() === flavors.MCI ? 80 : 150}
        src={logo}
        style={{ margin: '0 auto 40px' }}
        alt={'logo'}
      />
      <div className={styles.form__item}>
        <div className={styles.form__title}>Email</div>
        <Input
          id="int-login-username"
          onKeyPress={(ev) => handlePressEnter(ev)}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Type your Email"
          className={styles.form__input}
        />
      </div>
      <div className={styles.form__item}>
        <div className={styles.form__title}>Password</div>
        <Input.Password
          id="int-login-password"
          onKeyPress={(ev) => handlePressEnter(ev)}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Type your password"
          className={styles.form__input}
        />
      </div>

      <div
        className={styles['form__captcha-container']}
        style={{ display: captchaEnabled ? '' : 'none' }}
      >
        <div className={` ${styles.captchaContainer}`}>
          <img
            id="captcha-image"
            alt="captcha"
            style={{ borderRadius: '3px 0px 0px 3px' }}
          />
          <div
            onClick={props.recaptchaLoading ? null : handleGetCaptcha}
            className={styles.reCaptcha}
          >
            <RedoOutlined />
          </div>
        </div>

        <div>
          <Input
            id="int-login-captcha"
            onKeyPress={(ev) => handlePressEnter(ev)}
            value={captchaCode}
            onChange={(e) => setCaptchaCode(e.target.value)}
            placeholder="Type your captcha code"
            className={styles.form__input}
          />
        </div>
      </div>

      <div className={styles['form__remember-container']}>
        <div>
          <Checkbox
            id="int-login-remember"
            value={props?.rememberMe}
            onChange={(e) => props?.setRememberMe(e.target.checked)}
            className={styles['form__remember-me']}
          >
            Remember Me
          </Checkbox>
        </div>
        <div>
          <div
            onClick={() => props.goToResetPassFirstStep()}
            className={styles['form__reset-password']}
          >
            Reset Password
          </div>
        </div>
      </div>
      <div className={styles['form__button-container']}>
        <Button
          id="int-login-submit"
          loading={props.loginLoading}
          onClick={() => fetchLogin()}
          type="primary"
          className={styles.form__login}
          data-badge="inline"
          disabled={!isStageMode && !token && captchaEnabled}
          title={!token && captchaEnabled ? 'please wait ... ' : ''}
          style={loginButtonStyle}
        >
          LOGIN
        </Button>
        <div className={styles.form__singup}>
          <span className={styles.form__singup__description}>
            Don&apos;t have an account?
          </span>
          <span
            onClick={() => handleClickSignUpButton()}
            className={styles.form__singup__button}
          >
            Sign Up
          </span>
        </div>
      </div>
    </div>
  );
};
const HomeComponent = (props) => {
  const { state, search } = useLocation();

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(
    state?.currentPage || pages.LOGIN
  );
  const [email, setEmail] = useState(
    isNil(props.registerResponse) ? '' : props.registerResponse.email
  );
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [rememberMe, setRememberMe] = useState('');

  useEffect(() => {
    if (
      !props.returnUrl ||
      props.returnUrl === '/' ||
      props.returnUrl === '/register'
    ) {
      return;
    }

    const searchParams = new URLSearchParams(search);

    if (props.currentUser?.login) return;

    searchParams.set('returnUrl', props.returnUrl);

    history.replace(`/?${searchParams.toString()}`);
  }, [props.currentUser, props.returnUrl]);

  useEffect(() => {
    const activeProduct = userHasActiveProduct(props.currentUser);
    const currentPath = location.pathname;

    const searchParams = new URLSearchParams(location.search);

    const returnUrl = searchParams.get('returnUrl');

    if (
      activeProduct &&
      currentPath === '/' &&
      props.currentUser?.emailVerified
    ) {
      let activeProductObject = props.currentUser.products.find(
        (x) => x.id === activeProduct
      );

      if (returnUrl) {
        history.push(returnUrl);
      } else if (
        activeProductObject.thirdPartyOption !== thirdPartyEnums.PARENT
      ) {
        history.push(`/product/${activeProduct}/users/overview`);
      } else {
        history.push(`/admin/third-party/${activeProduct}/list`);
      }
    } else if (!props.currentUser?.emailVerified && props.currentUser?.login) {
      history.push(`/account/profile/update`);
    }
  }, [props.currentUser]);

  const handleSelectedStep = (selectedStep) => {
    setCurrentPage(selectedStep);
  };

  const resetSteps = () => {
    setCurrentPage(pages.LOGIN);
  };

  const userHasActiveProduct = (currentUser) => {
    return (
      (currentUser && currentUser.login && currentUser.lastProduct) ||
      (currentUser?.products &&
        Array.isArray(currentUser?.products) &&
        currentUser?.products.length > 0 &&
        currentUser?.products[0].id)
    );
  };

  const handleClickLoginButton = () => {
    resetSteps();
    setEmail('');
    setPassword('');
    setName('');
    setPhone('');
    //setRememberMe(false);
  };

  const renderSignUpForm = () => {
    return (
      <div className={styles.form}>
        <img
          width={150}
          src={logo}
          style={{ margin: '0 auto 40px' }}
          alt={'logo'}
        />
        <div className={styles.form__item}>
          <div className={styles.form__title}>Name</div>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Your Name"
            className={styles.form__input}
          />
        </div>
        <div className={styles.form__item}>
          <div className={styles.form__title}>Work Email</div>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="name@company.com"
            className={styles.form__input}
          />
        </div>
        <div className={styles.form__item}>
          <div className={styles.form__title}>Phone Number</div>
          <Input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="tel"
            placeholder="+989123456789"
            className={styles.form__input}
          />
        </div>
        <div className={styles['form__item--last']}>
          <div className={styles.form__title}>Password</div>
          <Input.Password
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password (at least 8 characters)"
            className={styles.form__input}
          />
        </div>
        <div className={styles['form__button-container']}>
          <Button
            type="primary"
            shape="round"
            className={styles.form__login}
            onClick={() => {
              showMessageError({
                status: 'Error',
                message: 'Please contact admin',
              });
            }}
          >
            SIGN UP
          </Button>
          <div className={styles.form__singup}>
            <span className={styles.form__singup__description}>
              Already have an account?
            </span>
            <span
              className={styles.form__singup__button}
              onClick={() => handleClickLoginButton()}
            >
              Login
            </span>
          </div>
        </div>
      </div>
    );
  };

  const goToLoginSecondStep = ({ otpId }) => {
    handleSelectedStep(pages.LOGIN_SECOND_STEP);
    history.push('/', {
      currentPage: pages.LOGIN_SECOND_STEP,
      otpId: otpId,
      rememberMe: rememberMe,
    });
  };

  const goToLoginFirstStep = () => {
    handleSelectedStep(pages.LOGIN);
    history.push({
      pathname: '/',
      search,
      state: {
        currentPage: pages.LOGIN,
        userId: props.currentUser?.userId,
        rememberMe: rememberMe,
      },
    });
    sessionStorage.removeItem('timerDuration');
  };

  const goToResetPassFirstStep = (email) => {
    setCurrentPage(pages.RESET_PASS_FIRST_STEP);
    history.push({
      pathname: '/',
      search,
      state: {
        currentPage: pages.RESET_PASS_FIRST_STEP,
        email,
      },
    });
    sessionStorage.removeItem('timerDuration');
  };

  const goToResetPassSecondStep = ({ email, otpId }) => {
    setCurrentPage(pages.RESET_PASS_SECOND_STEP);
    history.push({
      pathname: '/',
      search,
      state: {
        currentPage: pages.RESET_PASS_SECOND_STEP,
        email,
        otpId,
      },
    });
  };

  const renderCurrentForm = () => {
    switch (currentPage) {
      case pages.LOGIN:
        return (
          <SignInForm
            registerResponse={props.registerResponse}
            loginFetch={props.loginFetch}
            returnUrl={props.returnUrl}
            defaultPath={props.defaultPath}
            goToLoginSecondStep={goToLoginSecondStep}
            loginLoading={props.loginLoading}
            currentUser={props.currentUser}
            rememberMe={rememberMe}
            setRememberMe={setRememberMe}
            goToResetPassFirstStep={goToResetPassFirstStep}
            recaptchaLoading={props.recaptchaLoading}
          />
        );
      case pages.LOGIN_SECOND_STEP:
        return <LoginSecondStep goToLoginFirstStep={goToLoginFirstStep} />;
      case pages.SIGN_UP:
        return renderSignUpForm();
      case pages.RESET_PASS_FIRST_STEP:
        return (
          <ResetPassFirstStep
            goToLoginFirstStep={goToLoginFirstStep}
            goToResetPassSecondStep={goToResetPassSecondStep}
          />
        );
      case pages.RESET_PASS_SECOND_STEP:
        return (
          <ResetPassSecondStep
            goToResetPassFirstStep={goToResetPassFirstStep}
            goToLoginFirstStep={goToLoginFirstStep}
          />
        );
      default:
        return null;
    }
  };

  const renderSideForm = () => {
    switch (currentPage) {
      case pages.LOGIN:
      case pages.LOGIN_SECOND_STEP:
        return (
          <div
            id={`login-side-${detectFlavor()}`}
            className={styles[`home-page__right-section-${detectFlavor()}`]}
          />
        );
      case pages.RESET_PASS_FIRST_STEP:
      case pages.RESET_PASS_SECOND_STEP:
        return (
          <div
            id={`reset-side-${detectFlavor()}`}
            className={styles[`home-page__right-section-${detectFlavor()}`]}
          />
        );
      case pages.SIGN_UP:
        return (
          <div
            id={`sign-up-side-${detectFlavor()}`}
            className={styles[`home-page__right-section-${detectFlavor()}`]}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles[`home-page-${detectFlavor()}`]}>
      <div className={styles[`home-page__box-${detectFlavor()}`]}>
        <div className={styles['home-page__left-section']}>
          {renderCurrentForm()}
        </div>
        {renderSideForm()}
      </div>
      {/* <div id="recaptcha"></div> */}
    </div>
  );
};
export default HomeComponent;
