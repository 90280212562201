import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
export default function ReleaseNoteModal() {
  const [showModal, setShowModal] = useState(null);
  const currentUser = useSelector((state) => state.account.auth.currentUser);
  useEffect(() => {
    if (!showModal && currentUser.lastReleaseNote) {
      setShowModal(currentUser.lastReleaseNote);
    }
  }, [currentUser.lastReleaseNote]);
  return (
    <Modal
      width={1080}
      bodyStyle={{ paddingTop: 15 }}
      title={
        <span style={{ fontSize: 16, fontWeight: 'bold' }}>
          {' '}
          Release Note
          <span
            style={{
              fontSize: 11,
              color: 'grey',
              marginLeft: 5,
              fontWeight: 'normal',
            }}
          >
            ({showModal && showModal?.releaseDate})
          </span>
        </span>
      }
      centered
      visible={showModal}
      onOk={() => setShowModal(false)}
      onCancel={() => setShowModal(false)}
      footer={[
        <Button
          style={{ paddingRight: 40, paddingLeft: 40 }}
          shape="round"
          key="ok"
          onClick={() => setShowModal(false)}
        >
          Ok
        </Button>,
      ]}
    >
      <div>
        <div
          style={{
            fontSize: 15,
            fontWeight: '500',
            marginBottom: 10,
            letterSpacing: '.2px',
          }}
        >
          Update Version {showModal && showModal?.releaseNoteNumber}
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: showModal && showModal?.description,
          }}
        />
      </div>
    </Modal>
  );
}
