import ProfileUpdateComponent from '../component/ProfileUpdateComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  profileChangeCurrent,
  profileUpdateFetch,
  profileUpdateSubmitFetch,
  profileUploadPictureFetch,
  emailVerificationOTPCodeFetch,
  resetEmailVerificationState,
} from '../ProfileActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentProfile: state.account.profile.currentProfile,
  loadingUpdateSubmit: state.account.profile.loadingUpdateSubmit,
  loadingUpdate: state.account.profile.loadingUpdate,
  errors: state.account.profile.errors,
  currentUser: state.account.auth.currentUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      profileChangeCurrent,
      profileUpdateFetch,
      profileUpdateSubmitFetch,
      profileUploadPictureFetch,
      emailVerificationOTPCodeFetch,
      resetEmailVerificationState,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileUpdateComponent);

ProfileUpdateComponent.propTypes = {
  currentProfile: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  profileChangeCurrent: PropTypes.func.isRequired,
  profileUpdateFetch: PropTypes.func.isRequired,
  profileUpdateSubmitFetch: PropTypes.func.isRequired,
  profileUploadPictureFetch: PropTypes.func.isRequired,
};
