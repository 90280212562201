import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  uninstallOverviewChangeCurrent,
  uninstallOverviewFetch,
} from '../UninstallActions';
import styles from './uninstalls.module.less';
import { Line } from '@ant-design/charts';
import { Pie } from '@ant-design/plots';
import { Table, Tag, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import noDataIcon from '../../../../theme/images/no-data.png';
import {
  SyncOutlined,
  PauseCircleOutlined,
  StopOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  FormOutlined,
} from '@ant-design/icons';
import { numberWithCommas } from '@Utils/RenderUtils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import Card from '@Utils/Card';
const loadingSpin = <LoadingOutlined className={styles.spinColor} spin />;
const UninstallOverViewComponent = () => {
  const loadingOverview = useSelector(
    (state) => state.analytics.uninstalls.loadingOverview
  );
  const uninstallOverviewInterval = useSelector(
    (state) => state.analytics.uninstalls.uninstallOverviewInterval
  );
  const uninstallOverview = useSelector(
    (state) => state.analytics.uninstalls.uninstallOverview
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(uninstallOverviewFetch(uninstallOverviewInterval));
  }, [uninstallOverviewInterval]);
  useEffect(() => {
    const lastWeek = moment().subtract(7, 'days');
    return () => {
      dispatch(
        uninstallOverviewChangeCurrent({ fromDate: lastWeek, toDate: moment() })
      );
    };
  }, []);
  const renderBoxNumbers = () => {
    return (
      <div className={styles.layoutRow}>
        {loadingOverview ? (
          <div className={styles.spin}>
            {' '}
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <React.Fragment>
            <div className={styles.box}>
              <div>TOTAL UNINSTALLS</div>
              <div>{numberWithCommas(uninstallOverview?.totalUninstall)}</div>
              <div>
                <span>Android:</span>
                <span>
                  {numberWithCommas(uninstallOverview?.uninstall?.android)}
                </span>
              </div>
              <div>
                <span>IOS:</span>
                <span>
                  {numberWithCommas(uninstallOverview?.uninstall?.ios)}
                </span>
              </div>
              {/* <span>
                <span>Unique:</span>
                <span>
                  {numberWithCommas(uninstallOverview?.uninstall?.total)}
                </span>
              </span> */}
            </div>
            <div className={styles.box}>
              <div>REACHABLE USERS</div>
              <div>
                {numberWithCommas(
                  uninstallOverview?.uninstallReachability?.total
                )}
              </div>
              <div>
                <span>SMS:</span>
                <span>
                  {numberWithCommas(
                    uninstallOverview?.uninstallReachability?.smsReachable
                  )}
                </span>
              </div>
              <div>
                <span>Email:</span>
                <span>
                  {numberWithCommas(
                    uninstallOverview?.uninstallReachability?.emailReachable
                  )}
                </span>
              </div>
              <div>
                <span>WebPush:</span>
                <span>
                  {numberWithCommas(
                    uninstallOverview?.uninstallReachability?.webPushReachable
                  )}
                </span>
              </div>
            </div>
            <div className={styles.box}>
              <div>TOTAL INSTALLS</div>
              <div>{numberWithCommas(uninstallOverview?.totalInstall)}</div>
              <div>
                <span>Android:</span>
                <span>
                  {numberWithCommas(uninstallOverview?.install?.android)}
                </span>
              </div>
              <div>
                <span>IOS:</span>
                <span>{numberWithCommas(uninstallOverview?.install?.ios)}</span>
              </div>
              {/* <span>
                <span>Unique:</span>
                <span>
                  {numberWithCommas(uninstallOverview?.uninstall?.total)}
                </span>
              </span> */}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  const renderDiagram = () => {
    const data = [];
    if (
      uninstallOverview &&
      uninstallOverview.trend &&
      Array.isArray(uninstallOverview.trend)
    ) {
      uninstallOverview?.trend.forEach((item) => {
        data.push(
          {
            time: item.time,
            value: item.uninstall.total,
            category: 'Total Uninstall',
          },
          {
            time: item.time,
            value: item.uninstall.android,
            category: 'Android Uninstall',
          },
          {
            time: item.time,
            value: item.uninstall.ios,
            category: 'Ios Uninstall',
          },
          {
            time: item.time,
            value: item.install.total,
            category: 'Total Install',
          },
          {
            time: item.time,
            value: item.install.android,
            category: 'Android Install',
          },
          {
            time: item.time,
            value: item.install.ios,
            category: 'Ios Install',
          }
        );
      });
    }
    const config = {
      appendPadding: [0, 0, 60, 20],
      data,
      xField: 'time',
      yField: 'value',
      seriesField: 'category',
      legend: {
        position: 'right',
      },

      smooth: true,
      animation: {
        appear: {
          animation: 'path-in',
          duration: 4000,
        },
      },
      xAxis: {
        label: {
          autoRotate: true,
          formatter: (text) => {
            return text;
          },
        },
      },
    };
    return (
      <Card styles={{ margin: '20px 0 20px', padding: 20 }}>
        <span className={styles.diagramTitle}>Install & Uninstall Trends</span>
        {loadingOverview ? (
          <div className={styles.spin}>
            {' '}
            <Spin indicator={loadingSpin} />
          </div>
        ) : isEmpty(data) || data.length === 0 ? (
          renderNoData()
        ) : (
          <Line {...config}></Line>
        )}
      </Card>
    );
  };
  const renderTable = () => {
    const dataSource = uninstallOverview?.communicationsLeadingToUninstalls;
    const columns = [
      {
        title: 'campaign name',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <span>{text}</span>,
      },
      {
        title: 'type',
        dataIndex: 'type',
        key: 'type',
        render: (text) => <span className={styles.tableValue}>{text}</span>,
      },
      {
        title: 'status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          switch (status) {
            case 'RUNNING':
              return (
                <Tag icon={<SyncOutlined />} color="geekblue">
                  {status}
                </Tag>
              );
            case 'PAUSED':
              return (
                <Tag icon={<PauseCircleOutlined />} color="gold">
                  {status}
                </Tag>
              );
            case 'STOPPED':
              return (
                <Tag icon={<StopOutlined />} color="volcano">
                  {status}
                </Tag>
              );
            case 'ENDED':
              return (
                <Tag icon={<CheckCircleOutlined />} color="green">
                  {status}
                </Tag>
              );
            case 'UPCOMING':
              return (
                <Tag icon={<ClockCircleOutlined />} color="purple">
                  {status}
                </Tag>
              );
            case 'DRAFT' || 'ARCHIVED':
              return (
                <Tag icon={<FormOutlined />} color="blue">
                  {status}
                </Tag>
              );
          }
        },
      },
      {
        title: 'delivered',
        dataIndex: 'delivered',
        key: 'delivered',
        render: (text) => (
          <span className={styles.tableValue}>{numberWithCommas(text)}</span>
        ),
      },
      {
        title: 'unique clicks',
        dataIndex: 'uniqueClicks',
        key: 'uniqueClicks',
        render: (text) => (
          <span className={styles.tableValue}>{numberWithCommas(text)}</span>
        ),
      },
      {
        title: 'unique conversions',
        dataIndex: 'uniqueConversions',
        key: 'uniqueConversions',
        render: (text) => (
          <span className={styles.tableValue}>{numberWithCommas(text)}</span>
        ),
      },
      {
        title: 'revenue',
        dataIndex: 'revenue',
        key: 'revenue',
        render: (text) => (
          <span className={styles.tableValue}>{numberWithCommas(text)}</span>
        ),
      },
      {
        title: 'uninstalls',
        dataIndex: 'uninstalls',
        key: 'uninstalls',
        render: (text) => (
          <span className={styles.tableValue}>{numberWithCommas(text)}</span>
        ),
      },
    ];
    return (
      <Card styles={{ padding: 20 }}>
        <span className={styles.tableTitle}>
          Push Campaigns Leading to Most Uninstalls
        </span>

        {loadingOverview ? (
          <div className={styles.spin}>
            {' '}
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <Table
            bordered
            pagination={true}
            dataSource={dataSource}
            columns={columns}
          ></Table>
        )}
      </Card>
    );
  };
  const renderAndroidApisVersion = () => {
    const data = [];
    if (
      uninstallOverview &&
      uninstallOverview.uninstallDevicesDetails &&
      uninstallOverview.uninstallDevicesDetails?.androidApiVersionsCount &&
      Array.isArray(
        uninstallOverview.uninstallDevicesDetails?.androidApiVersionsCount
      )
    ) {
      const initialValue = 0;
      const sum =
        uninstallOverview.uninstallDevicesDetails?.androidApiVersionsCount?.reduce(
          (acc, curr) => acc + curr.value,
          initialValue
        );
      let total = 0;
      uninstallOverview.uninstallDevicesDetails?.androidApiVersionsCount?.forEach(
        (item) => {
          let percent = ((item.value * 100) / sum).toFixed(0);
          if (percent > 3) {
            data.push({ key: item.key, value: item.value });
          } else {
            total += parseInt(item.value);
          }
        }
      );
      data.push({ key: 'Others', value: total });
    }
    const config = {
      data,
      padding: 0,
      appendPadding: 0,
      angleField: 'value',
      colorField: 'key',
      radius: 0.7,
      width: 300,
      height: 300,
      // legend: {
      //   position: 'right',
      //   verticalAlign: 'middle',
      //   offsetY: -20,
      // },
      legend: false,
      label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          fontSize: 14,
          textAlign: 'center',
        },
      },
      interactions: [
        {
          type: 'element-active',
        },
      ],
    };
    return data.isEmpty || data.length === 0 ? (
      <span></span>
    ) : (
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 30,
          fontSize: 16,
          color: 'grey',
        }}
      >
        <p>Android Api Version</p>
        <Pie {...config} />
      </section>
    );
  };
  const renderAndroidAppVersion = () => {
    const data = [];
    if (
      uninstallOverview &&
      uninstallOverview.uninstallDevicesDetails &&
      uninstallOverview.uninstallDevicesDetails?.androidAppVersionsCount &&
      Array.isArray(
        uninstallOverview.uninstallDevicesDetails?.androidAppVersionsCount
      )
    ) {
      const initialValue = 0;
      const sum =
        uninstallOverview.uninstallDevicesDetails?.androidAppVersionsCount?.reduce(
          (acc, curr) => acc + curr.value,
          initialValue
        );
      let total = 0;
      uninstallOverview.uninstallDevicesDetails?.androidAppVersionsCount?.forEach(
        (item) => {
          let percent = ((item.value * 100) / sum).toFixed(0);
          if (percent > 3) {
            data.push({ key: item.key, value: item.value });
          } else {
            total += parseInt(item.value);
          }
        }
      );
      data.push({ key: 'Others', value: total });
    }
    const config = {
      data,
      padding: 0,
      appendPadding: 0,
      angleField: 'value',
      colorField: 'key',
      radius: 0.7,
      width: 380,
      height: 300,
      // legend: {
      //   position: 'right',
      //   verticalAlign: 'middle',
      //   offsetX: 5,
      //   offsetY: -20,
      // },
      legend: false,
      label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          fontSize: 14,
          textAlign: 'center',
        },
      },
      interactions: [
        {
          type: 'element-active',
        },
      ],
    };
    return data.isEmpty || data.length === 0 ? (
      <span></span>
    ) : (
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 30,
          fontSize: 16,
          color: 'grey',
        }}
      >
        <p>Android App Version</p>
        <Pie {...config} />
      </section>
    );
  };
  const renderDeviceModels = () => {
    const data = [];
    if (
      uninstallOverview &&
      uninstallOverview.uninstallDevicesDetails &&
      uninstallOverview.uninstallDevicesDetails?.deviceModelsCount &&
      Array.isArray(
        uninstallOverview.uninstallDevicesDetails?.deviceModelsCount
      ) &&
      !isEmpty(uninstallOverview.uninstallDevicesDetails?.deviceModelsCount)
    ) {
      const initialValue = 0;
      const sum =
        uninstallOverview.uninstallDevicesDetails?.deviceModelsCount?.reduce(
          (acc, curr) => acc + curr.value,
          initialValue
        );
      let total = 0;
      uninstallOverview.uninstallDevicesDetails?.deviceModelsCount?.forEach(
        (item) => {
          let percent = ((item.value * 100) / sum).toFixed(0);

          if (percent > 3) {
            data.push({ type: item.key, value: item.value });
          } else {
            total += parseInt(item.value);
          }
        }
      );
      data.push({ type: 'Others', value: total });
    }
    const config = {
      autoFit: true,
      limitInPlot: true,
      data,
      padding: 0,
      appendPadding: 0,
      angleField: 'value',
      colorField: 'type',
      radius: 0.7,
      width: 400,
      height: 300,
      // legend: {
      //   position: 'right',
      //   verticalAlign: 'middle',
      //   offsetX: 30,
      // },
      legend: false,
      label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          fontSize: 14,
          textAlign: 'center',
        },
      },
      interactions: [
        {
          type: 'element-active',
        },
      ],
    };
    return data.isEmpty || data.length === 0 ? (
      <span></span>
    ) : (
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 30,
          fontSize: 16,
          color: 'grey',
        }}
      >
        <p>Device Model</p>
        <Pie {...config} />
      </section>
    );
  };
  const renderPieChart = () => {
    return (
      <Card styles={{ padding: 20, margin: '20px 0 20px' }}>
        <span className={styles.chartDescription}>Technology</span>
        {loadingOverview ? (
          <div className={styles.spin}>
            {' '}
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <section className={styles.chartColumn}>
            <section className={styles.chartPie}>
              {renderAndroidApisVersion()}
              {renderAndroidAppVersion()}
              {renderDeviceModels()}
            </section>
          </section>
        )}
      </Card>
    );
  };
  const renderNoData = () => {
    return (
      <span className={styles.column}>
        <img src={noDataIcon} style={{ width: 60, height: 60 }} alt="" />
        <span className={styles.empty}>No Data</span>
      </span>
    );
  };
  return (
    <div className={styles.layout}>
      {renderBoxNumbers()}
      {renderDiagram()}
      {renderTable()}
      {renderPieChart()}
    </div>
  );
};

export default UninstallOverViewComponent;
