import { CloseOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, DatePicker, Progress } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import {
  cohortExecute,
  cohortStopExecution,
  setCohortExecutionConfig,
} from '@Redux/slices/analytics/cohortSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { percentageWithoutDecimalPart } from '@Utils/RenderUtils';

const HeaderTitles = () => {
  const { cohortId } = useParams();
  const dispatch = useDispatch();

  const [isHovering, setIsHovering] = useState(false);

  const cohortExecutionConfig = useSelector(
    (state) => state.analytics.cohort.cohortExecutionConfig
  );
  const loadingExecution = useSelector(
    (state) => state.analytics.cohort.loadingExecution
  );
  const loadingStopExecution = useSelector(
    (state) => state.analytics.cohort.loadingStopExecution
  );
  const executing = useSelector((state) => state.analytics.cohort.executing);
  const cohortExecutionReport = useSelector(
    (state) => state.analytics.cohort.cohortExecutionReport
  );

  const items = [
    {
      key: 'firstEvent',
      label: 'First Event',
      value:
        cohortExecutionReport?.cohort?.firstEventFilter?.event?.name ?? '-',
    },
    {
      key: 'returnEvent',
      label: 'Return Event',
      value:
        cohortExecutionReport?.cohort?.returnEventFilter?.event?.name ?? '-',
    },
    {
      key: 'splitBy',
      label: 'Split By',
      value: cohortExecutionReport?.cohort?.splitBy?.name ?? '-',
    },
  ];

  const handleExecuteCohort = () => {
    dispatch(cohortExecute(cohortId));
  };

  const handleStopExecutionCohort = () => {
    dispatch(cohortStopExecution(cohortExecutionReport?.id));
  };

  const handleChangeDateRange = (dates) => {
    let temp = { ...cohortExecutionConfig };
    temp.fromDate = dates[0];
    temp.toDate = dates[1];
    dispatch(setCohortExecutionConfig(temp));
  };

  const renderExecutionButtonIcon = () => {
    return isHovering ? (
      <CloseOutlined
        style={{
          fontSize: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      />
    ) : (
      <Progress
        width={35}
        type="circle"
        percent={percentageWithoutDecimalPart(
          cohortExecutionReport?.processedUsersCount,
          cohortExecutionReport?.totalUsersCount,
          true
        )}
        format={(percent) =>
          loadingExecution ? (
            <span></span>
          ) : (
            <span style={{ color: '#fff' }}>{percent}%</span>
          )
        }
        strokeColor="#fff"
        trailColor="#BBDBFC"
        style={{
          width: 36,
          height: 36,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    );
  };

  return (
    <>
      <div className="date-picker-wrapper">
        <DatePicker.RangePicker
          allowClear={false}
          disabled={[false, false]}
          value={
            cohortExecutionConfig.fromDate && cohortExecutionConfig.toDate
              ? [cohortExecutionConfig.fromDate, cohortExecutionConfig.toDate]
              : [null, null]
          }
          onChange={(val) => handleChangeDateRange(val)}
          disabledDate={(date) => date && date > moment().startOf('day')}
        />
      </div>
      <div className="report-card-titles-wrapper">
        {items.map((item) => {
          return (
            <div key={item.key} className="report-card-titles-wrapper">
              <div className="report-card-title">{item.label}:</div>
              <div className="report-card-title-value">{item.value}</div>
            </div>
          );
        })}

        <>
          <Button
            type="primary"
            htmlType="submit"
            shape="circle"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            icon={executing ? renderExecutionButtonIcon() : <SyncOutlined />}
            onClick={
              executing ? handleStopExecutionCohort : handleExecuteCohort
            }
            disabled={loadingStopExecution}
            style={{ width: 40, height: 40 }}
          />
        </>
      </div>
    </>
  );
};

export default HeaderTitles;
