import React, { useEffect, useState } from 'react';
import '../../style/ProductSdkAndroidStyle.less';
import Highlight from 'react-highlight.js';
const NumberRowItem = (props) => {
  const [highlight, setHighlight] = useState(null);
  useEffect(() => {
    setHighlight(null);
    setTimeout(() => {
      setHighlight(props.highlightContent);
    }, 100);
  }, [props.highlightContent]);

  return (
    <div className="row-item">
      <div className="rows">
        <div className="number">{props.number}</div>
        <div className="text">{props.title}</div>
        {props.url && (
          <React.Fragment>
            <span
              style={{
                fontWeight: 'normal',
                color: '#6e707a',
                fontSize: '13px',
                padding: '0 5px',
              }}
            >
              {props.urlDescription ? props.urlDescription : 'Check'}
            </span>
            <a
              style={{ fontSize: '13px' }}
              href={props.url}
              target={'_blank'}
              rel="noreferrer"
            >
              here
            </a>
          </React.Fragment>
        )}
      </div>
      <div className="rows-space">
        {highlight && (
          <div className="code-grey-card">
            <Highlight language={'javascript'} style={{ borderRadius: 5 }}>
              <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                {highlight}
              </div>
            </Highlight>
          </div>
        )}
      </div>
      {props.note && (
        <div
          className="rows-space"
          style={{ color: '#6e707a', fontSize: '13px' }}
        >
          {props.note}
        </div>
      )}
    </div>
  );
};
export default NumberRowItem;
