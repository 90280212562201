import React from 'react';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';

import { isEmpty } from 'lodash';
import { Input, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';

import EmailTemplateModal from './EmailTemplateModal';
import Card from '@Utils/Card';

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};

class EmailTemplateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: null,
      provider: null,
      name: '',
      showTemplateTitle: false,
    };
  }

  componentDidMount() {
    this.props.emailTemplateListFetch(this.props.pageRequest);
  }

  handleChangeSearch = (name) => {
    this.setState({ name });
    if (name === '' || name === null) {
      this.props.emailTemplateListFetch({
        ...this.props.pageRequest,
        page: 0,
        name: null,
      });
    }
  };

  render() {
    const columnList = [
      {
        sorter: false,
        title: 'template name',
        dataIndex: 'name',
        key: 'name',
        width: 300,
      },
      {
        title: 'action',
        dataIndex: '',
        key: '-action-',
        width: '5%',
        render: (x) =>
          defaultActions(
            x,
            {
              action: () => {
                this.props.emailTemplateToggleModal(true, x.id);
              },
              title: 'Edit',
              enabled: true,
              disablePopConfirm: true,
            },
            {
              action: (id) =>
                this.props.emailTemplateDeleteFetch(id, this.props.pageRequest),
              title: 'Delete',
              enabled: true,
            },
            {
              action: (id) => {
                console.log('ID', id);
                this.props.emailTemplateDuplicateFetch(
                  id,
                  this.props.pageRequest
                );
              },

              title: 'Duplicate',
              enabled: true,
            }
          ),
      },
    ];

    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    return (
      <div>
        <Card title="Your Email Templates" loading={this.props.loadingList}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: 16,
            }}
          >
            <Input
              loading={true}
              allowClear={this.props.loadingSearchList ? false : true}
              placeholder={'Search by template name'}
              // eslint-disable-next-line max-len
              onPressEnter={() =>
                this.props.emailSearchTemplateListFetch({
                  ...this.props.pageRequest,
                  page: 0,
                  name: this.state.name,
                })
              }
              onChange={(e) => this.handleChangeSearch(e.target.value)}
              value={this.state.name}
              style={{ width: 300, borderRadius: 5 }}
              suffix={
                isEmpty(this.state.name) ? (
                  <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                ) : (
                  this.props.loadingSearchList && (
                    <div style={styles.inputLoadingSpinStyle}>
                      <Spin indicator={inputLoadingSpin} />
                    </div>
                  )
                )
              }
            />
          </div>
          <ListBase
            onChange={this.props.emailTemplateListFetch}
            page={this.props.page}
            pageRequest={this.props.pageRequest}
            columns={columnList}
            // onClick={(row) => this.viewItem(row.id)}
            createLink={null}
          />
        </Card>
        <EmailTemplateModal
          onDone={() => {
            this.props.emailTemplateListFetch(this.props.pageRequest);
          }}
        />
      </div>
    );
  }
}

export default EmailTemplateComponent;
