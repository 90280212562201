import React, { useCallback, useContext, useEffect, useState } from 'react';

import { delayTypeOptions, EventNodes } from './../../utils/static';
import { AppContext } from '../../app';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Switch,
} from 'antd';
import openSnackBar from '../Atom/SnackBar';
import { convertMinute, timesOptions } from '../../../../utils/DateUtils';
import { calcMinuteTime, dfsSearchPrime } from '../../utils/hooks';
import { useSelector } from 'react-redux';
import { useIsParentProduct } from '@Utils/isParentProduct';

const styles = {
  title: {
    color: '#a7a7a7',
    textAlign: 'right',
    paddingRight: '25px',
  },
  input: {
    width: '60px',
    margin: '0 0px 0 10px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    // lineHeight: '2.5em',
    rowGap: '10px',
  },
  select: {
    margin: '0 5px',
    minWidth: '100px',
  },
  selectEvent: {
    margin: '0 5px',
    minWidth: '100px',
    maxWidth: 'unset',
    width: 'unset',
  },
  radioGroup: {},
  formItem: {
    display: 'inline-block',
    marginBottom: '0px',
  },
};

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const WAIT_TYPES = ['USER_ATTRIBUTE', 'EVENT_ATTRIBUTE'];

export const TimeDelayComponent = (props) => {
  const FormItemStyle = props?.FormItemStyle;
  const DelayOptions = props?.delayTypeOptions || delayTypeOptions;
  const AvailableSelectTime = props?.AvailableSelectTime;

  return (
    <>
      <Form.Item name="delayType" style={FormItemStyle || styles.formItem}>
        <Select
          style={styles.select}
          disabled={props?.readOnly}
          placeholder="Select"
        >
          {DelayOptions.map((option) => (
            <Select.Option key={option.value} value={option.value}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        style={{ margin: '0px' }}
        shouldUpdate={(prevValues, curValues) => {
          return prevValues.delayType !== curValues.delayType;
        }}
      >
        {({ getFieldValue }) => {
          return getFieldValue('delayType') &&
            AvailableSelectTime.includes(getFieldValue('delayType')) ? (
            <div style={styles.row}>
              <Form.Item
                name="delayFactor"
                style={FormItemStyle || styles.formItem}
              >
                <Input
                  placeholder={'#'}
                  disabled={props?.readOnly}
                  style={styles.input}
                />
              </Form.Item>
              <Form.Item
                name="delayConst"
                style={FormItemStyle || styles.formItem}
              >
                <Select disabled={props?.readOnly} style={styles.select}>
                  {timesOptions().map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          ) : (
            ''
          );
        }}
      </Form.Item>
    </>
  );
};

const FlowWaitForDate = (props) => {
  const [form] = Form.useForm();
  const { journey, syncCache } = useContext(AppContext);
  const currentNode_ = journey?.steps?.find(
    (item) => item.id + '' === props?.node?.id
  );
  const [currentNode, setCurrentNode] = useState(currentNode_);
  const [bufferActived, setBufferActived] = useState(
    currentNode?.bufferMinutes
  );
  const [isEventAttribute, setIsEventAttribute] = useState(false);
  const [eventOptions, setEventOptions] = useState([]);
  const [eventAttrOptions, setEventAttrOptions] = useState([]);
  const [ignoreYear, setIgnoreYear] = useState(currentNode?.ignoreYear);
  const [birthday, setBirthday] = useState(false);
  const { events, attributes } = props.data;

  const currentUser = useSelector((state) => state.account.auth.currentUser);

  const isParent = useIsParentProduct(currentUser);

  useEffect(() => {
    if (!isEventAttribute) {
      setBirthday(true);
    } else {
      setBirthday(false);
    }
  }, [isEventAttribute]);
  const onFinish = (e) => {
    let isValid = true;
    if (e.waitForAttributeType === WAIT_TYPES[0]) {
      if (!e.userAttribute) isValid = false;
    }
    if (e.waitForAttributeType === WAIT_TYPES[1]) {
      if (!e.stepId || !e.eventAttribute) {
        isValid = false;
      }
    }
    if (!isValid) {
      openSnackBar('error', {
        message: 'Please fill all required fields!',
      });
      return;
    }

    let currentStep = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );

    if (e.delayType) {
      if (e.delayType === delayTypeOptions[0].value) {
        currentStep['delayMinutes'] = 0;
      } else if (e.delayType === delayTypeOptions[1].value) {
        currentStep['delayMinutes'] = calcMinuteTime(
          e.delayFactor,
          e.delayConst
        );
      } else {
        currentStep['delayMinutes'] =
          -1 * calcMinuteTime(e.delayFactor, e.delayConst);
      }
    }
    if (bufferActived) {
      currentStep['bufferMinutes'] = calcMinuteTime(e.buffer, e.bufferConst);
    } else {
      currentStep['bufferMinutes'] = 0;
    }

    currentStep['userAttribute'] = e.userAttribute;
    currentStep['waitForAttributeType'] = e.waitForAttributeType;
    currentStep['eventAttribute'] = e.eventAttribute;
    currentStep['stepId'] = e.stepId;
    currentStep['ignoreYear'] = ignoreYear;

    delete currentStep.ui?.error;
    syncCache('update-step', currentStep, true);
    props.closeModal();
  };

  const userAttrOptions = useCallback(() => {
    return attributes.filter((attr) => attr.type === 'DATE') || [];
  }, [attributes]);

  const getEventOptions = () => {
    const eventNodes = journey.steps.filter((step) =>
      EventNodes.find((en) => en === step.type && step.event)
    );
    if (!eventNodes.length) {
      return [];
    }
    const currentId = props?.node?.id;
    const current = journey.steps.find(
      (step) => step.id + '' === currentId + ''
    );
    // DFS to find path from EventNodes to WaitForDate
    const result = eventNodes.filter((eventNode) => {
      return dfsSearchPrime(eventNode, current, journey, true);
    });

    return result;
  };

  useEffect(() => {
    setCurrentNode(currentNode_);
  }, [currentNode_]);

  const initDelayType = useCallback(() => {
    const value = currentNode?.delayMinutes;
    if (value === 0) {
      return delayTypeOptions[0].value;
    } else if (value > 0) {
      return delayTypeOptions[1].value;
    } else if (value < 0) {
      return delayTypeOptions[2].value;
    } else {
      return null;
    }
  }, [currentNode?.delayMinutes]);

  const time_d = convertMinute(Math.abs(currentNode?.delayMinutes));
  const time_b = convertMinute(currentNode?.bufferMinutes);

  useEffect(() => {
    const eventOptions_ = getEventOptions().map((step) => {
      const event = events.find((ev) => ev.id + '' === step.event + '');
      return {
        label: `(STEP-${step.id}) ${event?.title}`,
        value: step.id,
        eventAttributes: event?.attributes,
      };
    });
    setEventOptions(eventOptions_);

    if (form.getFieldValue('stepId') && eventOptions_?.length) {
      const eventAttributes = eventOptions_.find(
        (m) => m.value + '' === form.getFieldValue('stepId') + ''
      )?.eventAttributes;
      if (eventAttributes) {
        const eventAttributesDate =
          eventAttributes.filter((attr) => attr.type === 'DATE') || [];
        setEventAttrOptions(eventAttributesDate);
      }
    }

    if (currentNode?.stepId) {
      //!TODO must handle remove event node
      const isStillExistEventNode = eventOptions_.find((item) => {
        return item.value + '' === currentNode?.stepId + '';
      });
      if (!isStillExistEventNode) {
        setCurrentNode((old) =>
          Object.assign({}, old, {
            bufferMinutes: null,
            communication: null,
            delayMinutes: null,
            eventAttribute: null,
            stepId: null,
            waitForAttributeType: WAIT_TYPES[0],
          })
        );
        form.setFieldsValue({
          waitForAttributeType: WAIT_TYPES[0],
          userAttribute: null,
          eventAttribute: null,
          stepId: null,
          delayType: null,
          delayConst: 'Day',
          delayFactor: '',
        });
      }
    }
  }, [journey.steps]);

  useEffect(() => {
    if (currentNode?.waitForAttributeType === WAIT_TYPES[1]) {
      setIsEventAttribute(true);
    } else {
      setIsEventAttribute(false);
    }
  }, [currentNode]);

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        className="modal_wrap"
        {...formItemLayout}
        initialValues={{
          waitForAttributeType:
            currentNode?.waitForAttributeType || WAIT_TYPES[0],
          userAttribute: currentNode?.userAttribute || null,
          eventAttribute: currentNode?.eventAttribute || null,
          stepId: currentNode?.stepId ? currentNode?.stepId + '' : null,
          delayType: initDelayType(),
          delayConst: time_d.type,
          delayFactor: time_d.value,
          buffer: time_b.value,
          bufferConst: time_b.type,
          ignoreYear: currentNode?.ignoreYear || false,
        }}
        onFieldsChange={(e) => {
          try {
            const fieldName = e[0]?.name[0];
            if (fieldName === 'userAttribute') {
              setIgnoreYear(false);
            }
            if (fieldName === 'waitForAttributeType') {
              if (e[0].value === WAIT_TYPES[1]) setIsEventAttribute(true);
              else setIsEventAttribute(false);
            } else if (fieldName === 'stepId' && eventOptions?.length) {
              form.setFieldsValue({ eventAttribute: null });
              const eventAttributes = eventOptions.find(
                (m) => m.value + '' === e[0].value + ''
              )?.eventAttributes;
              if (eventAttributes) {
                const eventAttributesDate =
                  eventAttributes.filter((attr) => attr.type === 'DATE') || [];
                setEventAttrOptions(eventAttributesDate);
              }
            }
          } catch (e) {
            console.log('error----->>', e);
          }
        }}
      >
        <div className={'modal-body'}>
          <div className="content__item__no-flex">
            <Form.Item
              label="TYPE OF WAIT"
              name="waitForAttributeType"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
            >
              <Radio.Group disabled={props?.readOnly}>
                <Space direction="vertical">
                  <Radio value={WAIT_TYPES[0]}>
                    Wait for the date & time value of a user attribute
                  </Radio>
                  <Radio
                    value={WAIT_TYPES[1]}
                    disabled={!eventOptions?.length && !isParent}
                  >
                    Wait for the date & time value of an event attribute (eg.
                    Departure time attribute of Flight Booked event){' '}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {isEventAttribute ? (
              <Form.Item
                style={{
                  margin: '0px',
                  minHeight: '0px',
                }}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 24 }}
              >
                <Form.Item
                  label="WAIT EVENT"
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                >
                  <div style={styles.row}>
                    <span>
                      Wait for the date & time value of an attribute of the
                      following event
                    </span>
                    <Form.Item
                      name="stepId"
                      style={{
                        display: 'inline-block',
                        marginBottom: '0px',
                      }}
                    >
                      <Select
                        disabled={props?.readOnly || isParent}
                        style={styles.selectEvent}
                        placeholder="Select Event"
                      >
                        {eventOptions?.map((option) => {
                          return (
                            <Select.Option
                              key={option.value}
                              value={option.value + ''}
                            >
                              {option.label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Form.Item>
              </Form.Item>
            ) : (
              ''
            )}
            <Form.Item
              label="WAIT DETAILS"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
            >
              <div style={styles.row}>
                <span>
                  Wait for the date & time of{' '}
                  {isEventAttribute ? 'event attribute' : 'user attribute'}
                </span>
                {isEventAttribute ? (
                  <Form.Item
                    name="eventAttribute"
                    style={{
                      display: 'inline-block',
                      marginBottom: '0px',
                    }}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Select
                      disabled={props?.readOnly || isParent}
                      style={styles.selectEvent}
                      placeholder="Select Attribute"
                    >
                      {eventAttrOptions?.map((option) => {
                        return (
                          <Select.Option key={option.name} value={option.id}>
                            {option.title}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="userAttribute"
                    style={{
                      display: 'inline-block',
                      marginBottom: '0px',
                    }}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Select
                      disabled={props?.readOnly || isParent}
                      style={styles.selectEvent}
                      placeholder="Select Attribute"
                    >
                      {userAttrOptions()?.map((option) => {
                        return (
                          <Select.Option key={option.name} value={option.id}>
                            {option.title}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                <span style={{ marginLeft: '5px' }}>
                  and then proceed from this block
                </span>
                <TimeDelayComponent
                  {...props}
                  AvailableSelectTime={[
                    delayTypeOptions[1].value,
                    delayTypeOptions[2].value,
                  ]}
                />
              </div>
            </Form.Item>
            {birthday && (
              <Col span={12} offset={4} style={{ marginBottom: 24 }}>
                <Checkbox
                  checked={ignoreYear}
                  onChange={(e) => {
                    setIgnoreYear(e.target.checked);
                  }}
                >
                  Ignore year of the date (roll over month and day)
                </Checkbox>
              </Col>
            )}
            <Form.Item
              label="ADVANCED OPTIONS"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
            >
              <Switch
                disabled={props?.readOnly}
                checked={bufferActived}
                onChange={(e) => {
                  setBufferActived(e);
                }}
              />
            </Form.Item>
            {bufferActived ? (
              <div>
                <Form.Item label="WAIT TIME BUFFER">
                  <div style={styles.row}>
                    <span>Allow for a buffer of</span>
                    <Form.Item
                      name="buffer"
                      style={{
                        display: 'inline-block',
                        marginBottom: '0px',
                      }}
                    >
                      <Input disabled={props?.readOnly} style={styles.input} />
                    </Form.Item>
                    <Form.Item
                      name="bufferConst"
                      style={{
                        display: 'inline-block',
                        marginBottom: '0px',
                      }}
                    >
                      <Select disabled={props?.readOnly} style={styles.select}>
                        {timesOptions().map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <span style={{ marginLeft: '10px' }}>
                      from the date & time value of the above attribute
                    </span>
                    <br />
                  </div>
                  <Alert
                    style={{ marginTop: '15px' }}
                    showIcon
                    message={`This is useful when the information coming from your systems is
                                            delayed and you still want the users to proceed from this block even 
                                            though the information is delayed. Eg. if the value of renewal date 
                                            for a user is 1 Jan, 4:00pm. But your system only sends this information to 
                                            at 4:30pm. If you have a buffer time of 30 minutes or more, 
                                            then this user will proceed from this block. Otherwise, this user will miss the date.`}
                  />
                </Form.Item>
                <br />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={'modal-footer'}>
          <Form.Item>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              disabled={props.readOnly}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
export default FlowWaitForDate;
