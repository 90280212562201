import RoleListComponent from '../component/RoleListComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  rolePageChangeCurrent,
  roleListFetch,
  roleSearchListFetch,
  roleDeleteFetch,
} from '../RoleActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  page: state.admin.role.page,
  pageRequest: state.admin.role.pageRequest,
  loadingRoleList: state.admin.role.loadingRoleList,
  loadingRoleSearchList: state.admin.role.loadingRoleSearchList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      rolePageChangeCurrent,
      roleListFetch,
      roleSearchListFetch,
      roleDeleteFetch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RoleListComponent);

RoleListComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  roleListFetch: PropTypes.func.isRequired,
  roleDeleteFetch: PropTypes.func.isRequired,
  rolePageChangeCurrent: PropTypes.func.isRequired,
};
