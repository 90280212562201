import CommunicationWhenComponent from '../component/CommunicationWhenComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  communicationUpdateWhenFetch,
  clearCommunicationWhenError,
  communicationUpdateSubmitWhenFetch,
  communicationListEventsFetch,
  communicationUpdateWhenThrottlingFetch,
} from '../CommunicationActions';
import {
  productSegmentRecommendUserAttributesFetch,
  productSegmentRecommendDeviceAttributesFetch,
  productSegmentRecommendEventAttributesFetch,
} from '../../../insight/productSegment/ProductSegmentActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentUser: state.account.auth.currentUser,
  page: state.engage.communication.page,
  pageRequest: state.engage.communication.pageRequest,
  loadingEvents: state.engage.communication.loadingEvents,
  loadingUpdateThrottlingWhen:
    state.engage.communication.loadingUpdateThrottlingWhen,
  loadingUpdateWhen: state.engage.communication.loadingUpdateWhen,
  loadingUpdateWhenSubmit: state.engage.communication.loadingUpdateWhenSubmit,
  communicationData: state.engage.communication.communicationData,
  currentWhen: state.engage.communication.currentWhen,
  eventList: state.engage.communication.eventList,
  pathname: state.router.location.pathname,
  errorUpdateWhenSubmit: state.engage.communication.errorUpdateWhenSubmit,
  loadingRecommendUserAttributes:
    state.insight.productSegment.loadingRecommendUserAttributes,
  recommendUserAttributes: state.insight.productSegment.recommendUserAttributes,
  loadingRecommendEventAttributes:
    state.insight.productSegment.loadingRecommendEventAttributes,
  recommendEventAttributes:
    state.insight.productSegment.recommendEventAttributes,
  loadingRecommendDeviceAttributes:
    state.insight.productSegment.loadingRecommendDeviceAttributes,
  recommendDeviceAttributes:
    state.insight.productSegment.recommendDeviceAttributes,
  defaultThrottling: state.engage.communication.defaultThrottling,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      communicationUpdateSubmitWhenFetch,
      communicationUpdateWhenFetch,
      communicationListEventsFetch,
      clearCommunicationWhenError,
      productSegmentRecommendUserAttributesFetch,
      productSegmentRecommendDeviceAttributesFetch,
      productSegmentRecommendEventAttributesFetch,
      communicationUpdateWhenThrottlingFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationWhenComponent);

CommunicationWhenComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  communicationUpdateWhenFetch: PropTypes.func.isRequired,
  communicationListEventsFetch: PropTypes.func.isRequired,
  communicationUpdateSubmitWhenFetch: PropTypes.func.isRequired,
};
