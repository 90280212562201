export const ACTIVE_TEAM_MEMBER_LIST_FETCH = 'ACTIVE_TEAM_MEMBER_LIST_FETCH';
export const ACTIVE_TEAM_MEMBER_LIST_FETCH_FAIL =
  'ACTIVE_TEAM_MEMBER_LIST_FETCH_FAIL';
export const ACTIVE_TEAM_MEMBER_LIST_FETCH_SUCCESS =
  'ACTIVE_TEAM_MEMBER_LIST_FETCH_SUCCESS';
export const ACTIVE_MEMBERS_CHANGE_CURRENT = 'ACTIVE_MEMBERS_CHANGE_CURRENT';
export const TEAM_MEMBER_VIEW_FETCH = 'TEAM_MEMBER_VIEW_FETCH';
export const TEAM_MEMBER_VIEW_FETCH_SUCCESS = 'TEAM_MEMBER_VIEW_FETCH_SUCCESS';
export const TEAM_MEMBER_VIEW_FETCH_FAIL = 'TEAM_MEMBER_VIEW_FETCH_FAIL';
export const TEAM_MEMBER_DELETE_FETCH = 'TEAM_MEMBER_DELETE_FETCH';
export const TEAM_MEMBER_DELETE_FETCH_SUCCESS =
  'TEAM_MEMBER_DELETE_FETCH_SUCCESS';
export const TEAM_MEMBER_DELETE_FETCH_FAIL = 'TEAM_MEMBER_DELETE_FETCH_FAIL';
export const ROLE_LIST_FETCH = 'ROLE_LIST_FETCH';
export const ROLE_LIST_FETCH_SUCCESS = 'ROLE_LIST_FETCH_SUCCESS';
export const ROLE_LIST_FETCH_FAIL = 'ROLE_LIST_FETCH_FAIL';
export const TEAM_MEMBER_CHANGE_CURRENT = 'TEAM_MEMBER_CHANGE_CURRENT';
export const TEAM_CREATE_FETCH = 'TEAM_CREATE_FETCH';
export const TEAM_CREATE_FETCH_SUCCESS = 'TEAM_CREATE_FETCH_SUCCESS';
export const TEAM_CREATE_FETCH_FAIL = 'TEAM_CREATE_FETCH_FAIL';
export const TEAM_MEMBER_REGISTER_FETCH = 'TEAM_MEMBER_REGISTER_FETCH';
export const TEAM_MEMBER_REGISTER_FETCH_SUCCESS =
  'TEAM_MEMBER_REGISTER_FETCH_SUCCESS';
export const TEAM_MEMBER_REGISTER_FETCH_FAIL =
  'TEAM_MEMBER_REGISTER_FETCH_FAIL';
export const INVITED_USER_CHANGE_CURRENT = 'INVITED_USER_CHANGE_CURRENT';
export const TEAM_UPDATE_FETCH = 'TEAM_UPDATE_FETCH';
export const TEAM_UPDATE_FETCH_SUCCESS = 'TEAM_UPDATE_FETCH_SUCCESS';
export const TEAM_UPDATE_FETCH_FAIL = 'TEAM_UPDATE_FETCH_FAIL';
export const TEAM_UPDATE_SUBMIT_FETCH = 'TEAM_UPDATE_SUBMIT_FETCH';
export const TEAM_UPDATE_SUBMIT_FETCH_SUCCESS =
  'TEAM_UPDATE_SUBMIT_FETCH_SUCCESS';
export const TEAM_UPDATE_SUBMIT_FETCH_FAIL = 'TEAM_UPDATE_SUBMIT_FETCH_FAIL';
export const TEAM_RESEND_INVITATION = 'TEAM_RESEND_INVITATION';
export const TEAM_RESEND_INVITATION_SUCCESS = 'TEAM_RESEND_INVITATION_SUCCESS';
export const TEAM_RESEND_INVITATION_FAIL = 'TEAM_RESEND_INVITATION_FAIL';
