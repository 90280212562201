import {
  adminProductCreateWithContract,
  getContractTemplates,
  getParentProducts,
} from './CreateProductApi';
import styles from './CreateProductForm.module.less';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Segmented,
  Select,
  Space,
  Switch,
} from 'antd';
import { thirdPartyEnums } from '@Utils/ThirdPartyOptions';
import { useHistory, useLocation } from 'react-router';
import { isNil } from 'lodash';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const CreateProductForm = () => {
  const location = useLocation();
  const history = useHistory();
  const [contractTemplates, setContractTemplates] = useState([]);
  const [parentProducts, setParentProducts] = useState([]);
  const [thirdPartyOption, setThirdPartyOption] = useState(
    location.state ? location.state.thirdPartyOption : thirdPartyEnums.NORMAL
  );
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    getContractTemplates({ cb: setContractTemplates });
  }, []);

  useEffect(() => {
    getParentProducts({ cb: setParentProducts });
  }, []);

  useEffect(() => {
    if (isNil(form.getFieldValue('thirdPartyOption'))) {
      form.setFieldsValue({ thirdPartyOption: thirdPartyOption });
    }
  }, [thirdPartyOption]);

  const handleThirdPartyOptionChange = (checked) => {
    setThirdPartyOption(checked);
    if (checked !== thirdPartyEnums.CHILD) {
      form.setFieldsValue({ parentProductId: null });
    } else {
      getParentProducts({ cb: setParentProducts });
    }
  };

  const onFinish = (values) => {
    const tempForm = {
      contract: values.contractTemplateId
        ? {
            address: values.address,
            contractTemplateId: values.contractTemplateId,
            customer: values.customer,
            economicCode: values.economicCode.toString(),
            endDate: moment(values.endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
            nationalCode: values.nationalCode.toString(),
            phone: values.phone,
            registrationCode: values.registrationCode.toString(),
            startDate: moment(values.startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
            zipCode: values.zipCode.toString(),
            pricing: values.pricing,
            includeSystemEvents: values.includeSystemEvents,
          }
        : null,
      product: {
        description: values.description,
        enabled: values.enabled,
        mau: values.mau,
        name: values.name,
        thirdPartyOption: values.thirdPartyOption,
        parentProductId: values.parentProductId,
      },
    };

    adminProductCreateWithContract({
      body: tempForm,
      setLoading,
      cb: () => {
        form.resetFields();
        form.setFieldsValue({ thirdPartyOption: thirdPartyEnums.NORMAL });
        setThirdPartyOption(thirdPartyEnums.NORMAL);
        if (location.state) history.goBack(); // if we came from third party page
      },
    });
  };

  return (
    <Card className={styles.cardWrapper}>
      <Form
        form={form}
        style={{ maxWidth: '100%' }}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
      >
        {/* ProjectName */}
        <Form.Item
          className="rowTest"
          label="Project Name"
          name="name"
          initialValue=""
          rules={[
            {
              required: true,
              message: 'Project Name is required!',
            },
          ]}
        >
          <Input className={styles.textInput} />
        </Form.Item>
        {/* Normal-Parent-Child*/}
        <Form.Item
          label="Third Party Option"
          name="thirdPartyOption"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: 'Third Party Option is required!',
            },
          ]}
        >
          <Segmented
            style={{ borderRadius: 8 }}
            onChange={(val) => handleThirdPartyOptionChange(val)}
            defaultValue={thirdPartyOption}
            options={Object.values(thirdPartyEnums).map((val) => ({
              label: val,
              value: val,
            }))}
          />
        </Form.Item>
        {/* Parent Product */}
        <Form.Item
          label="Parent Product"
          name="parentProductId"
          rules={[
            {
              required: thirdPartyOption === thirdPartyEnums.CHILD,
              message: 'Parent Product Id is required!',
            },
          ]}
          hidden={thirdPartyOption !== thirdPartyEnums.CHILD}
          initialValue=""
        >
          <Select className={styles.textInput}>
            {parentProducts.map((parent) => {
              return (
                <Select.Option key={parent.id} value={parent.id}>
                  {parent.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {/* Description */}
        <Form.Item
          label="Description"
          name="description"
          initialValue=""
          rules={[
            {
              required: true,
              message: 'Description is required!',
            },
          ]}
        >
          <Input className={styles.textInput} />
        </Form.Item>
        {/* MAU */}
        <Form.Item
          label="MAU"
          name="mau"
          initialValue=""
          rules={[
            {
              required: true,
              message: 'MAU is required!',
            },
          ]}
        >
          <InputNumber className={styles.numberInput} controls={false} />
        </Form.Item>
        {/* Enabled */}
        <Form.Item
          label="Enabled"
          name="enabled"
          initialValue={false}
          rules={[
            {
              required: true,
              message: 'Enabled is required!',
            },
          ]}
        >
          <Switch />
        </Form.Item>
        {/* Contract */}
        <Form.Item
          label="Contract"
          name="contractTemplateId"
          initialValue=""
          hidden={thirdPartyOption === thirdPartyEnums.CHILD}
          rules={[
            {
              required: thirdPartyOption !== thirdPartyEnums.CHILD,
              message: 'Contract is required!',
            },
          ]}
        >
          <Select className={styles.textInput}>
            {contractTemplates.map((template) => {
              return (
                <Select.Option key={template.id} value={template.id}>
                  {template.title}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {/* Start Date */}
        <Form.Item
          label="Start Date"
          name="startDate"
          initialValue=""
          hidden={thirdPartyOption === thirdPartyEnums.CHILD}
          rules={[
            {
              required: thirdPartyOption !== thirdPartyEnums.CHILD,
              message: 'Start Date is required!',
            },
          ]}
        >
          <DatePicker className={styles.datePickerInput} />
        </Form.Item>
        {/* End Date */}
        <Form.Item
          label="End Date"
          name="endDate"
          initialValue=""
          hidden={thirdPartyOption === thirdPartyEnums.CHILD}
          rules={[
            {
              required: thirdPartyOption !== thirdPartyEnums.CHILD,
              message: 'End Date is required!',
            },
          ]}
        >
          <DatePicker className={styles.datePickerInput} />
        </Form.Item>
        {/* Customer */}
        <Form.Item
          label="Customer"
          name="customer"
          initialValue=""
          hidden={thirdPartyOption === thirdPartyEnums.CHILD}
          rules={[
            {
              required: thirdPartyOption !== thirdPartyEnums.CHILD,
              message: 'Customer is required!',
            },
          ]}
        >
          <Input className={styles.textInput} />
        </Form.Item>
        {/*  Economic Code */}
        <Form.Item
          label=" Economic Code"
          name="economicCode"
          initialValue=""
          hidden={thirdPartyOption === thirdPartyEnums.CHILD}
          rules={[
            {
              required: thirdPartyOption !== thirdPartyEnums.CHILD,
              message: ' Economic Code is required!',
            },
          ]}
        >
          <InputNumber className={styles.numberInput} controls={false} />
        </Form.Item>
        {/*  National Code */}
        <Form.Item
          label=" National Code"
          name="nationalCode"
          initialValue=""
          hidden={thirdPartyOption === thirdPartyEnums.CHILD}
          rules={[
            {
              required: thirdPartyOption !== thirdPartyEnums.CHILD,
              message: ' National Code is required!',
            },
          ]}
        >
          <InputNumber className={styles.numberInput} controls={false} />
        </Form.Item>
        {/*  Registration Code */}
        <Form.Item
          label=" Registration Code"
          name="registrationCode"
          initialValue=""
          hidden={thirdPartyOption === thirdPartyEnums.CHILD}
          rules={[
            {
              required: thirdPartyOption !== thirdPartyEnums.CHILD,
              message: ' Registration Code is required!',
            },
          ]}
        >
          <InputNumber className={styles.numberInput} controls={false} />
        </Form.Item>
        {/*  Phone */}
        <Form.Item
          label=" Phone"
          name="phone"
          initialValue=""
          hidden={thirdPartyOption === thirdPartyEnums.CHILD}
          rules={[
            {
              required: thirdPartyOption !== thirdPartyEnums.CHILD,
              message: ' Phone is required!',
            },
          ]}
        >
          <Input className={styles.textInput} />
        </Form.Item>
        {/*  Zip Code */}
        <Form.Item
          label="Zip Code"
          name="zipCode"
          initialValue=""
          hidden={thirdPartyOption === thirdPartyEnums.CHILD}
          rules={[
            {
              required: thirdPartyOption !== thirdPartyEnums.CHILD,
              message: 'Zip Code is required!',
            },
          ]}
        >
          <InputNumber className={styles.numberInput} controls={false} />
        </Form.Item>
        {/*  Address */}
        <Form.Item
          label="Address"
          name="address"
          initialValue=""
          hidden={thirdPartyOption === thirdPartyEnums.CHILD}
          rules={[
            {
              required: thirdPartyOption !== thirdPartyEnums.CHILD,
              message: 'Address is required!',
            },
          ]}
        >
          <Input className={styles.textInput} />
        </Form.Item>
        <Form.Item
          label="Include System Events"
          name="includeSystemEvents"
          hidden={thirdPartyOption === thirdPartyEnums.CHILD}
          initialValue={false}
        >
          <Switch />
        </Form.Item>
        <Row hidden={thirdPartyOption === thirdPartyEnums.CHILD}>
          <Col span={8}>
            <div style={{ textAlign: 'right', paddingRight: 8 }}>Price</div>
          </Col>
          <Col span={8}>
            <Form.List
              name="pricing"
              initialValue={[{ basePrice: undefined }]}
              disabled
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: 'flex' }}
                      align="baseline"
                    >
                      <Form.Item {...restField} name={[name, 'basePrice']}>
                        <InputNumber
                          placeholder="basePrice"
                          style={{ width: 150 }}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'mauMaxThreshold']}
                      >
                        <InputNumber
                          placeholder="mauMaxThreshold"
                          style={{ width: 150 }}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'pricePerMillionEvents']}
                      >
                        <InputNumber
                          placeholder="pricePerMillionEvents"
                          style={{ width: 150 }}
                        />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  {fields.length <= 3 && (
                    <Form.Item>
                      <Button
                        onClick={() => {
                          add();
                        }}
                        type="primary"
                        shape="round"
                        ghost
                        icon={<PlusOutlined />}
                      >
                        Add field
                      </Button>
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <div className={styles.actionButtonWrapper}>
          <Form.Item>
            <Button
              className="w-200"
              loading={loading}
              shape="round"
              size={'large'}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Card>
  );
};

export default CreateProductForm;
