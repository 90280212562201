import { fontsListFetch, uploadNewFont } from '../ProductSettingActions';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Form, Upload, message, List } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

const ProductSettingFonts = () => {
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();

  const loadingUploadedFonts = useSelector(
    (state) => state.setting.productSetting.loadingUploadedFonts
  );
  const uploadedFonts = useSelector(
    (state) => state.setting.productSetting.uploadedFonts
  );

  useEffect(() => {
    getFontList();
  }, []);

  const getFontList = () => {
    dispatch(fontsListFetch({}));
  };

  const customRequest = async ({ file, onError }) => {
    if (file.type !== 'font/ttf' && !file.name.endsWith('.ttf')) {
      message.error('Only TTF font files are accepted.');
      onError(new Error('Invalid file type.'));
      return;
    }

    dispatch(
      uploadNewFont({ file, cbLoading: setUploading, cbSuccess: getFontList })
    );
  };

  return (
    <div>
      <Card styles={{ padding: '40px 30px', borderRadius: '5px' }}>
        <Form>
          <Form.Item name="font">
            <span
              style={{
                color: '#707070',
                fontSize: 14,
                marginRight: 20,
                width: 110,
              }}
            >
              Fonts:
            </span>
            <Upload
              customRequest={customRequest}
              showUploadList={false}
              accept=".ttf,application/x-font-ttf"
            >
              <Button
                icon={uploading ? <LoadingOutlined /> : <UploadOutlined />}
                disabled={uploading}
              >
                {uploading ? 'Uploading...' : 'Click to Upload'}
              </Button>
            </Upload>
          </Form.Item>
        </Form>

        <List
          loading={loadingUploadedFonts}
          header={<div style={{ fontWeight: '700' }}>Uploaded Fonts</div>}
          bordered
          dataSource={uploadedFonts}
          renderItem={(item) => <List.Item>{item?.file?.fileName}</List.Item>}
        />
      </Card>
    </div>
  );
};

export default ProductSettingFonts;
