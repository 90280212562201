export const API_TOKEN_CREATE_FETCH = 'API_TOKEN_CREATE_FETCH';
export const API_TOKEN_CREATE_FETCH_FAIL = 'API_TOKEN_CREATE_FETCH_FAIL';
export const API_TOKEN_CREATE_FETCH_SUCCESS = 'API_TOKEN_CREATE_FETCH_SUCCESS';

export const API_TOKEN_REVOKE_FETCH = 'API_TOKEN_REVOKE_FETCH';
export const API_TOKEN_REVOKE_FETCH_FAIL = 'API_TOKEN_REVOKE_FETCH_FAIL';
export const API_TOKEN_REVOKE_FETCH_SUCCESS = 'API_TOKEN_REVOKE_FETCH_SUCCESS';

export const API_TOKEN_REVOKE_ALL_FETCH = 'API_TOKEN_REVOKE_ALL_FETCH';
export const API_TOKEN_REVOKE_ALL_FETCH_FAIL =
  'API_TOKEN_REVOKE_ALL_FETCH_FAIL';
export const API_TOKEN_REVOKE_ALL_FETCH_SUCCESS =
  'API_TOKEN_REVOKE_ALL_FETCH_SUCCESS';

export const API_TOKEN_LIST_FETCH = 'API_TOKEN_LIST_FETCH';
export const API_TOKEN_LIST_FETCH_FAIL = 'API_TOKEN_LIST_FETCH_FAIL';
export const API_TOKEN_LIST_FETCH_SUCCESS = 'API_TOKEN_LIST_FETCH_SUCCESS';

export const API_TOKEN_PAGE_CHANGE_CURRENT = 'API_TOKEN_PAGE_CHANGE_CURRENT';

export const API_TOKEN_DETAILS_MODAL_VIEW = 'API_TOKEN_MODAL_VIEW';
export const API_TOKEN_REVOKE_ALL_MODAL_VIEW =
  'API_TOKEN_REVOKE_ALL_MODAL_VIEW';
