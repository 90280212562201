import CommunicationMessageComponent from '../component/CommunicationMessageComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  communicationUpdateMessageFetch,
  communicationUpdateSubmitMessageFetch,
  communicationListSmsChannelsFetch,
  communicationListPushChannelsFetch,
  communicationListEmailChannelsFetch,
  whatsappChannelTemplateListFetch,
  whatsappChannelTemplateViewFetch,
  whatsappChannelTemplateViewChange,
  communicationListCustomChannelsFetch,
  communicationUploadFileFetch,
  communicationUploadFileReset,
  communicationUpdateAudienceFetch,
  clearCommunicationMessageError,
  resetCurrentMessage,
} from '../CommunicationActions';
import {
  emailTemplateChangeCurrent,
  emailTemplateListAllFetch,
  emailTemplateToggleModal,
} from '../../../channel/emailTemplate/EmailTemplateActions';
import {
  productSegmentListAttributesFetch,
  productSegmentListEventsFetch,
} from '../../../insight/productSegment/ProductSegmentActions';
import { customChannelUpdateFetch } from '../../../channel/custom/CustomChannelActions';
import { journeyListBusinessEventsFetch } from '../../journey/JourneyActions';
import PropTypes from 'prop-types';
import { fontsListFetch } from '@Modules/setting/productSetting/ProductSettingActions';

const mapStateToProps = (state) => ({
  currentUser: state.account.auth.currentUser,
  loadingSmsChannels: state.engage.communication.loadingSmsChannels,
  loadingPushChannels: state.engage.communication.loadingPushChannels,
  loadingEmailChannels: state.engage.communication.loadingEmailChannels,
  loadingCustomChannels: state.engage.communication.loadingCustomChannels,
  loadingUpdateMessage: state.engage.communication.loadingUpdateMessage,
  loadingUpdateMessageSubmit:
    state.engage.communication.loadingUpdateMessageSubmit,
  communicationData: state.engage.communication.communicationData,
  successUpdateMessageSubmit:
    state.engage.communication.successUpdateMessageSubmit,
  currentMessage: state.engage.communication.currentMessage,
  emailChannels: state.engage.communication.emailChannels,
  whatsAppTemplate: state.engage.communication.whatsAppTemplate,
  pushChannels: state.engage.communication.pushChannels,
  smsChannels: state.engage.communication.smsChannels,
  customChannels: state.engage.communication.customChannels,
  customChannel: state.channel.customChannel.currentCustomChannel,
  uploadedFile: state.engage.communication.uploadedFile,
  loadingUploadFile: state.engage.communication.loadingUploadFile,
  errorsUploadFile: state.engage.communication.errorsUploadFile,
  pathname: state.router.location.pathname,
  attributes: state.insight.productSegment.attributes,
  events: state.insight.productSegment.events,
  loadingViewTemplate: state.channel.emailTemplate.loadingView,
  errorsTemplate: state.channel.emailTemplate.errors,
  currentTemplate: state.channel.emailTemplate.currentTemplate,
  errorsViewTemplate: state.channel.emailTemplate.errorsView,
  listAllTemplate: state.channel.emailTemplate.listAll,
  errorsListAllTemplate: state.channel.emailTemplate.errorsListAll,
  loadingListAllTemplate: state.channel.emailTemplate.loadingListAll,
  currentAudience: state.engage.communication.currentAudience,
  loadingUpdateAudience: state.engage.communication.loadingUpdateAudience,
  errorUpdateMessageSubmit: state.engage.communication.errorUpdateMessageSubmit,
  loadingCustomChannelUpdate: state.channel.customChannel.loadingUpdate,
  businessEvents: state.engage.journey.businessEvents,
  userProducts: state.account.auth.currentUser.products,
  whatsAppTemplatePageRequest:
    state.engage.communication.whatsAppTemplatePageRequest,
  whatsAppTemplateObject: state.engage.communication.whatsAppTemplateObject,
  loadingWhatsAppTemplateObject:
    state.engage.communication.loadingWhatsAppTemplateObject,
  loadingUploadedFonts: state.setting.productSetting.loadingUploadedFonts,
  uploadedFonts: state.setting.productSetting.uploadedFonts,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      communicationUpdateAudienceFetch,
      communicationUpdateMessageFetch,
      communicationUpdateSubmitMessageFetch,
      communicationListEmailChannelsFetch,
      whatsappChannelTemplateListFetch,
      whatsappChannelTemplateViewFetch,
      whatsappChannelTemplateViewChange,
      communicationListPushChannelsFetch,
      communicationListSmsChannelsFetch,
      communicationListCustomChannelsFetch,
      productSegmentListEventsFetch,
      productSegmentListAttributesFetch,
      communicationUploadFileFetch,
      communicationUploadFileReset,
      emailTemplateListAllFetch,
      emailTemplateChangeCurrent,
      clearCommunicationMessageError,
      customChannelUpdateFetch,
      journeyListBusinessEventsFetch,
      emailTemplateToggleModal,
      resetCurrentMessage,
      fontsListFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationMessageComponent);

CommunicationMessageComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  communicationUpdateMessageFetch: PropTypes.func.isRequired,
  communicationUpdateSubmitMessageFetch: PropTypes.func.isRequired,
};
