import React, { memo, useContext, useEffect, useState } from 'react';
import './circleNode.less';

import { Handle } from 'react-flow-renderer';

import { NodeIcon } from '../../utils/icons';
import {
  Channels,
  NodeTypes,
  StepsWithCamp,
  TriggerEnterExistState,
} from '../../utils/static';
import DeleteIcon from '../../assets/IconDelete';
import CopyIcon from '../../assets/IconCopy';
import ArrowIcon from '../../assets/IconArrow';
import ErrorIcon from '../../assets/IconError';
import ErrorIconHover from '../../assets/IconErrorHover';
// import EnterIcon from "./../../assets/IconEnter";
// import UserIcon from "./../../assets/IconUserBlack";
// import Confirm from '../Atom/Confirm';

import {
  BarChartOutlined,
  UserOutlined as UserIcon,
  LoginOutlined as EnterIcon,
} from '@ant-design/icons';
import { convertMinute } from '../../../../utils/DateUtils';
import { AppContext } from '../../app';
import { Tooltip } from 'antd';
import _ from 'lodash';

const customNode = (props) => {
  const {
    key,
    label,
    subLabel,
    createNode,
    createEdge,
    updateNode,
    nodeType,
    iconName,
    events,
    analyticInfo,
    step,
  } = props.data;

  const [visibleBar, setVisibleBar] = useState(false);
  const [visiblePorts, setVisiblePorts] = useState(false);
  const [startLongPress, setStartLongPress] = useState(false);
  const [lock, setLock] = useState(false);
  const [errorButtonHover, setErrorButtonHover] = useState(false);
  const [selectedPort, setSelectedPort] = useState();
  const {
    journey,
    eventsContext,
    businessEventsContext,
    attributesContext,
    segmentsContext,
  } = useContext(AppContext);

  let ports = [
    {
      name: 'on',
      title: null,
    },
  ];

  ports = (events?.length && events) || ports;

  const enterNode = () => {
    if (props.data.key === 'BUSINESS_EVENT_OCCURRENCE') {
      return;
    }
    setVisibleBar(true);
  };

  const outNode = () => {
    setVisibleBar(false);
  };

  useEffect(() => {
    let timerId;
    if (startLongPress) {
      timerId = setTimeout(() => {
        createEdge({
          type: selectedPort.name,
          id: props.id,
          label: selectedPort.title,
        });
        setLock(true);
      }, 2000);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
    // }, [callback, ms, startLongPress]);
  }, [startLongPress]);

  const onLongPress = (e, port) => {
    if (e.type === 'mouseup' && !lock) {
      setStartLongPress(false);
      createNode({
        type: port.name,
        id: props.id,
        label: port.name,
        position: {
          x: props.xPos + 320,
          y: props.yPos,
        },
        sourceHandle: port.name,
      });
    } else if (e.type === 'mousedown') {
      setSelectedPort(port);
      setStartLongPress(true);
    } else {
      setLock(false);
    }
  };

  const onDrop = (event) => {
    event.preventDefault();
    const type = event.dataTransfer.getData('application/reactflow');
    try {
      typeof updateNode === 'function' &&
        updateNode(event, {
          type,
          placeHolderNodeInfo: props,
        });
    } catch (e) {
      console.log('[DN] onDrop Error', e);
    }
  };

  const deleteNode = () => {
    props.data?.deleteNode(journey, props.id);
  };

  const copyNode = () => {
    props.data?.duplicateNode(props.id);
  };

  //   const boxNodeHover = (state) => {
  //     setErrorButtonHover(state);
  //   };

  let subLabel_ = '';
  const node_ = journey?.steps?.find((item) => item.id + '' === props.id);
  if (node_) {
    if (key === 'SEND_SMS') {
      // subLabel_ = "SENS_SMS: test1"
    } else if (key === 'WAIT_FOR_TIME') {
      const timeMinute = node_?.waitMinutes;
      if (timeMinute) {
        const time = convertMinute(timeMinute);
        let isMoreThanOne = false;
        if (time.value > 1) {
          isMoreThanOne = true;
        }
        subLabel_ = `Wait for ${time.value} ${time.type}${
          isMoreThanOne ? 'S' : ''
        }`;
      }
    } else if (key === 'WAIT_FOR_DATE') {
      const delayMinutes = node_?.delayMinutes;
      let typeWait = '';
      let time = '';
      if (!_.isNull(delayMinutes) && delayMinutes !== 0) {
        time = convertMinute(delayMinutes);
        typeWait = 'before';
        if (time.value > 0) {
          typeWait = 'after';
        }
        typeWait += ` ${Math.abs(time.value)} ${time.type}`;
      }

      let name = '';
      if (node_.eventAttribute) {
        let eventAttr = {};
        eventsContext.findIndex((ev) => {
          eventAttr = ev.attributes.find(
            (at) => at.id === node_.eventAttribute
          );
          if (eventAttr) {
            return true;
          }
          return false;
        });
        name = eventAttr?.title;
      } else if (node_.userAttribute) {
        const attr = node_.userAttribute;
        name = attributesContext.find((ec) => ec.id === attr)?.title;
      }
      if (name || typeWait) {
        subLabel_ = `Wait for ${name} ${typeWait}`;
      }
    } else if (key === 'EVENT_OCCURRENCE') {
      const name = eventsContext.find((ec) => ec.id === node_.event)?.title;
      if (name) {
        subLabel_ = eventsContext.find((ec) => ec.id === node_.event)?.title;
      }
    } else if (key === 'BUSINESS_EVENT_OCCURRENCE') {
      const name = businessEventsContext.find(
        (ec) => ec.id === node_.businessEvent
      )?.title;
      if (name) {
        subLabel_ = businessEventsContext.find(
          (ec) => ec.id === node_.businessEvent
        )?.title;
      }
    } else if (key === 'IS_IN_SEGMENT') {
      const name = segmentsContext.find((ec) => ec.id === node_.segment)?.name;
      if (name) {
        subLabel_ = `Is In Segment: ${name}`;
      }
    } else if (key === 'HAS_DONE_EVENT') {
      const name = eventsContext.find((ec) => ec.id === node_.event)?.title;
      if (name) {
        subLabel_ = `Has Done Event: ${name}`;
      }
    } else if (key === 'CHECK_USER_ATTRIBUTE') {
      const name = attributesContext.find(
        (ec) => ec.id === node_.attribute
      )?.title;
      if (name) {
        subLabel_ = `Check if : ${name}`;
      }
    } else if (key === 'IS_USER_REACHABLE') {
      const name = Channels.find((ec) => ec.id === node_.channel)?.name;
      if (name) {
        subLabel_ = `Check if user reachable on a ${name}`;
      }
    } else if (key === 'WAIT_FOR_EVENT') {
      const name = eventsContext.find((ec) => ec.id === node_.event)?.title;
      if (name) {
        subLabel_ = `Wait for Event: ${name}`;
      }
    } else if (key === 'SPECIFIC_USERS') {
      const file = node_.staticFile;
      if (file) {
        const fileName = decodeURI(file.split('/').pop() || '');
        subLabel_ = `For specific users in: ${fileName.slice(0, 20)}`;
      }
    } else if (key === 'CALL_API') {
      let url_ = node_?.url;
      if (url_) {
        url_ = url_.slice(0, 20);
        subLabel_ = `Call API: ${url_}...`;
      }
    } else if (key === 'SET_USER_ATTRIBUTE') {
      let actionType = node_?.actionType;
      if (actionType) {
        let attributeValue = node_?.attributeValue;
        const attributeSetType = (
          node_?.attributeSetType || 'SET'
        ).toLowerCase();

        const prefix = attributeSetType === 'SET' ? 'to' : 'by';

        let attribName = node_?.attributeName;
        if (actionType !== 'NEW_ATTRIBUTE') {
          const attrib = attributesContext.find(
            (ec) => ec.id === node_.attributeId
          );
          if (attrib) {
            attribName = attrib.name;
            if (attrib.type === 'DATE') {
              if (node_.attributeSetType !== 'SET') {
                const converted = convertMinute(node_.attributeValue);
                attributeValue = `${converted.value} ${converted.type}`;
              }
            }
          }
        }
        subLabel_ = `${_.startCase(
          attributeSetType
        )} ${attribName} ${prefix} ${attributeValue}`;
      }
    } else if (key === 'ENTER_EXIT_SEGMENT') {
      const segment = node_?.segment;
      if (segment) {
        const segmentObject = segmentsContext.find(
          (item) => item.id === segment
        );
        const name =
          segmentObject?.name.length >= 30
            ? segmentObject?.name.slice(0, 30) + '...'
            : segmentObject?.name;
        const enterExitTypeObject = TriggerEnterExistState.find(
          (ts) => ts.name === node_.enterExitType
        );
        subLabel_ = `When User ${enterExitTypeObject.displayName} segment ${name}`;
      }
    } else if (key === 'CHANGE_IN_USER_ATTRIBUTE') {
      if (node_?.attribute) {
        const attrib = attributesContext.find(
          (ec) => ec.id === node_.attribute
        );
        subLabel_ = `When User's ${attrib?.title} changes`;
      }
    }
    // else if (key === 'SPECIFIC_USERS') {
    //   const staticFile = node_?.staticFile;
    //   if (staticFile) subLabel_ = `For specific users in CSV file`;
    // }
    else if (key === 'WAIT_FOR_TIME_SLOT') {
      const timeSlots = node_?.timeSlots;
      if (timeSlots?.length) {
        const firstItem = timeSlots[0];
        const st = firstItem?.startTime?.slice(0, 5);
        const et = firstItem?.endTime?.slice(0, 5);
        subLabel_ = `Proceed from this block on ${firstItem.weekDayType} between ${st} - ${et}`;
      }
    }
  }

  let isClickable = true;
  if (key === 'END_JOURNEY' || nodeType === NodeTypes[4]) {
    isClickable = false;
  }

  let hasPorts = true;
  if (key === 'END_JOURNEY' || nodeType === NodeTypes[4]) {
    hasPorts = false;
  }

  return (
    <div
      className={'defaultnode'}
      onPointerOver={enterNode}
      onPointerLeave={outNode}
      onDrop={onDrop}
      onMouseOver={() => {
        setVisiblePorts(true);
        // console.log("onDragOver-node----------", e);
      }}
      onMouseLeave={() => {
        setVisiblePorts(false);
      }}
    >
      <div
        className={'node__bar'}
        onPointerOver={enterNode}
        onPointerLeave={outNode}
      >
        <div
          onClick={deleteNode}
          className={'bar__icon bar__icon__trash'}
          style={{ visibility: visibleBar ? 'visible' : 'hidden' }}
        >
          <DeleteIcon />
        </div>
        {nodeType !== NodeTypes[4] && (
          <div
            onClick={copyNode}
            className={'bar__icon bar__icon__copy'}
            style={{
              visibility: visibleBar ? 'visible' : 'hidden',
            }}
          >
            <CopyIcon />
          </div>
        )}
      </div>
      <div
        className={`box-node box-node__clickable--${isClickable}`}
        // onMouseOut={ () => boxNodeHover(false)}
        nodeId={props.id}
      >
        {node_?.ui?.error && (
          <div
            className={'box-node__error'}
            // onMouseOver={ () => boxNodeHover(true)}
            // onMouseOut={ () => boxNodeHover(false)}
          >
            <Tooltip
              title={node_?.ui?.error}
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              color={'#fee8e8'}
              placement={'right'}
              className={'box-node__error__tooltip'}
              onVisibleChange={(visible) => {
                setErrorButtonHover(visible);
              }}
              overlayInnerStyle={{
                color: 'red',
                fontSize: '.9em',
                padding: '10px',
                borderRadius: '8px',
              }}
            >
              <div>{errorButtonHover ? <ErrorIconHover /> : <ErrorIcon />}</div>
              {/* <WarningFilled /> */}
            </Tooltip>
          </div>
        )}
        <div className={'box-node__label'}>STATE-{props.id}</div>
        {nodeType !== NodeTypes[4] && (
          <NodeIcon nodeId={props.id} name={iconName} type={nodeType} />
        )}
        <div className={'box-node__type__text default-node__text'}>
          {subLabel_ ? '' : label}
          {(subLabel || subLabel_) && (
            <div className={'default-node__text__sub'}>
              {subLabel_ || subLabel}
            </div>
          )}
        </div>
        {analyticInfo && (
          <div className="box-node__report_info">
            <div>
              <EnterIcon />
              <span>{analyticInfo.entries}</span>
            </div>
            <div>
              <UserIcon />
              <span>{Math.abs(analyticInfo.entries - analyticInfo.exits)}</span>
            </div>
          </div>
        )}
        <Handle
          type="source"
          position="right"
          id={'handle_central'}
          className="point_central"
        />
        <Handle
          type="target"
          position="top"
          // id={"a_target_top_" + props.id}
          id={'top_target'}
          className="point_target"
        />
        {visiblePorts && hasPorts && (
          <div
            className={`box-mode__type__handler back_icon--${nodeType.toLocaleLowerCase()}`}
          >
            <ArrowIcon />
          </div>
        )}
      </div>

      {analyticInfo && StepsWithCamp.find((sp) => sp === key) && (
        <div
          onClick={() => {
            const currentPath = props.data.history.location.pathname;
            const pId =
              currentPath.split('/').length > 2 && currentPath.split('/')[2];
            let path_ = '';
            switch (key) {
              case StepsWithCamp[0]:
                path_ = `/product/${pId}/sms-messages/campaigns/${step.communication}/overview`;
                break;
              case StepsWithCamp[1]:
                path_ = `/product/${pId}/push-notifications/campaigns/${step.communication}/overview`;
                break;
              case StepsWithCamp[2]:
                path_ = `/product/${pId}/emails/campaigns/${step.communication}/overview`;
                break;
              case StepsWithCamp[4]:
                path_ = `/product/${pId}/web-notifications/campaigns/${step.communication}/overview`;
                break;
              case StepsWithCamp[5]:
                path_ = `/product/${pId}/custom-channel/campaigns/${step.communication}/overview`;
                break;
            }
            if (pId && path_) {
              if (
                currentPath.split('/').length > 4 &&
                currentPath.split('/')[4]
              ) {
                const JID = Number(currentPath.split('/')[4]);
                if (JID > 0) {
                  props.data.history.push(`${path_}?journey=${JID}`);
                }
              }
            }
          }}
          className="box-node__report_link"
        >
          <BarChartOutlined />
        </div>
      )}

      {visiblePorts && hasPorts && (
        <div className={'box-node__menu'} nodeId={props.id}>
          <ul>
            {ports.map((port, key) => (
              <li
                key={key}
                onMouseDown={(e) => onLongPress(e, port)}
                onMouseUp={(e) => onLongPress(e, port)}
              >
                <Handle
                  type={'source'}
                  position="right"
                  // id={`${port.name}_${props.id}`}
                  id={`${port.name}`}
                  // onConnect={(params) =>
                  //   console.log("handle onConnect---Handle", params)
                  // }
                />
                <span>{port.title}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default memo(customNode);
