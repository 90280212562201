import React from 'react';
// import { Modal, Button, Input, Select, Spin, Switch } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
// import { isNil, isEmpty } from 'lodash';
import vars from '@Theme/styles/vars.js';
import { history } from '../../../../redux/store';
const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    direction: 'ltr',
    fontWeight: '700',
    color: '#3d404e',
    fontSize: 17,
    textAlign: 'left',
    padding: 30,
    borderBottom: '1px solid #eee',
  },
  addButton: {
    fontSize: 20,
    color: vars.primaryColor,
    cursor: 'pointer',
    marginLeft: 16,
  },
  tabItem: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: '500',
    color: '#3d404e',
    borderBottom: '1px solid #eee',
    fontSize: 14,
    paddingLeft: 30,
    paddingTop: 30,
  },
};
class RolesViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingId: null,
      url: '',
    };
  }
  render() {
    return (
      <div style={{ padding: 30 }}>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: 5,
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
          }}
        >
          <div style={styles.header}>
            <div>
              Roles
              <PlusCircleOutlined
                id="int-add"
                onClick={() => {
                  history.push('roles/create');
                }}
                style={styles.addButton}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RolesViewComponent;
