exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ContractCreate-module__form--1FFCv {\n  padding: 16px 24px;\n}\n.ContractCreate-module__formLable--2ssdl lable {\n  color: #9a9ca3;\n  font-size: 14px;\n  font-size: 16px;\n  text-transform: uppercase;\n  text-align: left;\n  font-weight: 600;\n  margin-bottom: 16px;\n}\n.ContractCreate-module__itemTitle--2bBHI {\n  font-size: 16px;\n  text-transform: uppercase;\n  text-align: left;\n  font-weight: 600;\n  margin-bottom: 16px;\n}\n.ContractCreate-module__itemSubtitle--2SCL3 {\n  color: #707070;\n  font-size: 14px;\n  text-transform: uppercase;\n  text-align: left;\n  font-weight: 600;\n  margin-bottom: 16px;\n}\n.ContractCreate-module__itemLable--2-Wdc {\n  padding: 0 16px;\n}\n.ContractCreate-module__itemHint--2zMbJ {\n  color: #94969e;\n  font-size: 10px;\n  padding-top: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"form": "ContractCreate-module__form--1FFCv",
	"formLable": "ContractCreate-module__formLable--2ssdl",
	"itemTitle": "ContractCreate-module__itemTitle--2bBHI",
	"itemSubtitle": "ContractCreate-module__itemSubtitle--2SCL3",
	"itemLable": "ContractCreate-module__itemLable--2-Wdc",
	"itemHint": "ContractCreate-module__itemHint--2zMbJ"
};