import {
  API_TOKEN_CREATE_FETCH,
  API_TOKEN_CREATE_FETCH_FAIL,
  API_TOKEN_CREATE_FETCH_SUCCESS,
  API_TOKEN_LIST_FETCH,
  API_TOKEN_LIST_FETCH_FAIL,
  API_TOKEN_LIST_FETCH_SUCCESS,
  API_TOKEN_DETAILS_MODAL_VIEW,
  API_TOKEN_PAGE_CHANGE_CURRENT,
  API_TOKEN_REVOKE_ALL_FETCH,
  API_TOKEN_REVOKE_ALL_FETCH_FAIL,
  API_TOKEN_REVOKE_ALL_FETCH_SUCCESS,
  API_TOKEN_REVOKE_FETCH,
  API_TOKEN_REVOKE_FETCH_FAIL,
  API_TOKEN_REVOKE_FETCH_SUCCESS,
  API_TOKEN_REVOKE_ALL_MODAL_VIEW,
} from '@Modules/integration/productRestApiToken/ApiTokenConstants';
import {
  PRODUCT_REST_API_VIEW_FETCH,
  PRODUCT_REST_API_VIEW_FETCH_FAIL,
  PRODUCT_REST_API_VIEW_FETCH_SUCCESS,
} from '@Modules/integration/productRestApi/ProductRestApiConstants';

const initialState = {
  tokensList: {},
  loadingTokensList: false,
  pageRequest: {
    order: 'createdDate',
    direction: 'DESC',
  },
  errorListTokens: [],

  loadingCreate: false,
  createdTokenDetails: {},
  errorCreate: [],
  tokenDetailsModalView: false,

  loadingRevoke: false,
  errorRevoke: [],

  loadingRevokeAll: false,
  errorRevokeAll: [],
  tokenRevokeAllModalView: false,

  currentProductRestApi: {},
  loadingProjectCredentials: false,

  page: 1,
};

export default function ProductRestApiTokenSettingStateReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PRODUCT_REST_API_VIEW_FETCH_SUCCESS:
      return {
        ...state,
        currentProductRestApi: action.payload,
        loadingProjectCredentials: false,
      };
    case PRODUCT_REST_API_VIEW_FETCH:
      return { ...state, loadingProjectCredentials: true };
    case PRODUCT_REST_API_VIEW_FETCH_FAIL:
      return {
        ...state,
        currentProductRestApi: action.payload,
        loadingProjectCredentials: false,
      };
    case API_TOKEN_CREATE_FETCH:
      return {
        ...state,
        loadingCreate: true,
      };
    case API_TOKEN_CREATE_FETCH_FAIL:
      return {
        ...state,
        loadingCreate: false,
        errorCreate: action.payload,
      };
    case API_TOKEN_CREATE_FETCH_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        loadingCreate: false,
        errorCreate: [],
        createdTokenDetails: action.payload,
        tokenDetailsModalView: true,
      };
    case API_TOKEN_REVOKE_FETCH:
      return {
        ...state,
        loadingRevoke: true,
      };
    case API_TOKEN_REVOKE_FETCH_FAIL:
      return {
        ...state,
        loadingRevoke: false,
        errorRevoke: action.payload,
      };
    case API_TOKEN_REVOKE_FETCH_SUCCESS:
      return {
        ...state,
        loadingRevoke: false,
        errorRevoke: [],
      };
    case API_TOKEN_REVOKE_ALL_FETCH:
      return {
        ...state,
        loadingRevokeAll: true,
      };
    case API_TOKEN_REVOKE_ALL_FETCH_FAIL:
      return {
        ...state,
        loadingRevokeAll: false,
        errorRevokeAll: action.payload,
      };
    case API_TOKEN_REVOKE_ALL_FETCH_SUCCESS:
      return {
        ...state,
        loadingRevokeAll: false,
        errorRevokeAll: [],
      };
    case API_TOKEN_LIST_FETCH:
      return {
        ...state,
        tokensList: action.payload,
      };
    case API_TOKEN_LIST_FETCH_FAIL:
      return {
        ...state,
        loadingTokensList: true,
      };
    case API_TOKEN_LIST_FETCH_SUCCESS:
      return {
        ...state,
        loadingTokensList: false,
        tokensList: action.payload,
        errorListTokens: [],
      };
    case API_TOKEN_PAGE_CHANGE_CURRENT:
      return {
        ...state,
        loadingTokensList: false,
        errorListTokens: action.payload,
      };
    case API_TOKEN_DETAILS_MODAL_VIEW:
      return {
        ...state,
        tokenDetailsModalView: action.payload,
      };
    case API_TOKEN_REVOKE_ALL_MODAL_VIEW:
      return {
        ...state,
        tokenRevokeAllModalView: action.payload,
      };
    default:
      return state;
  }
}
