import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProductSegmentArchiveListComponent from '../component/ProductSegmentArchiveListComponent';
import {
  productSegmentListFetch,
  productSearchSegmentListFetch,
  productSegmentActivate,
} from '../ProductSegmentActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  page: state.insight.productSegment.page,
  pageRequest: state.insight.productSegment.pageRequest,
  loadingSegmentList: state.insight.productSegment.loadingSegmentList,
  loadingSegmentSearchList:
    state.insight.productSegment.loadingSegmentSearchList,
  activeSuccess: state.insight.productSegment.activeSuccess,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      productSegmentListFetch,
      productSearchSegmentListFetch,
      productSegmentActivate,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSegmentArchiveListComponent);

ProductSegmentArchiveListComponent.propTypes = {
  productSegmentListFetch: PropTypes.func.isRequired,
};
