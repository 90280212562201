export const USER_CHANGE_CURRENT = 'USER_CHANGE_CURRENT';
export const USER_PAGE_CHANGE_CURRENT = 'USER_PAGE_CHANGE_CURRENT';

export const USER_ROLE_LIST_FETCH = 'USER_ROLE_LIST_FETCH';
export const USER_ROLE_LIST_FETCH_FAIL = 'USER_ROLE_LIST_FETCH_FAIL';
export const USER_ROLE_LIST_FETCH_SUCCESS = 'USER_ROLE_LIST_FETCH_SUCCESS';

export const USER_PRODUCT_LIST_FETCH = 'USER_PRODUCT_LIST_FETCH';
export const USER_PRODUCT_LIST_FETCH_FAIL = 'USER_PRODUCT_LIST_FETCH_FAIL';
export const USER_PRODUCT_LIST_FETCH_SUCCESS =
  'USER_PRODUCT_LIST_FETCH_SUCCESS';

export const USER_CP_SERVICE_LIST_FETCH = 'USER_CP_SERVICE_LIST_FETCH';
export const USER_CP_SERVICE_LIST_FETCH_FAIL =
  'USER_CP_SERVICE_LIST_FETCH_FAIL';
export const USER_CP_SERVICE_LIST_FETCH_SUCCESS =
  'USER_CP_SERVICE_LIST_FETCH_SUCCESS';

export const USER_LIST_FETCH_SUCCESS = 'USER_LIST_FETCH_SUCCESS';
export const USER_LIST_FETCH_FAIL = 'USER_LIST_FETCH_FAIL';
export const USER_LIST_FETCH = 'USER_LIST_FETCH';

export const USER_CREATE_FETCH = 'USER_CREATE_FETCH';
export const USER_CREATE_FETCH_FAIL = 'USER_CREATE_FETCH_FAIL';
export const USER_CREATE_FETCH_SUCCESS = 'USER_CREATE_FETCH_SUCCESS';

export const USER_UPDATE_FETCH = 'USER_UPDATE_FETCH';
export const USER_UPDATE_FETCH_FAIL = 'USER_UPDATE_FETCH_FAIL';
export const USER_UPDATE_FETCH_SUCCESS = 'USER_UPDATE_FETCH_SUCCESS';

export const USER_UPDATE_SUBMIT_FETCH = 'USER_UPDATE_SUBMIT_FETCH';
export const USER_UPDATE_SUBMIT_FETCH_FAIL = 'USER_UPDATE_SUBMIT_FETCH_FAIL';
export const USER_UPDATE_SUBMIT_FETCH_SUCCESS =
  'USER_UPDATE_SUBMIT_FETCH_SUCCESS';

export const USER_VIEW_FETCH = 'USER_VIEW_FETCH';
export const USER_VIEW_FETCH_FAIL = 'USER_VIEW_FETCH_FAIL';
export const USER_VIEW_FETCH_SUCCESS = 'USER_VIEW_FETCH_SUCCESS';

export const USER_CHANGE_PASSWORD_FETCH = 'USER_CHANGE_PASSWORD_FETCH';
export const USER_CHANGE_PASSWORD_FETCH_FAIL =
  'USER_CHANGE_PASSWORD_FETCH_FAIL';
export const USER_CHANGE_PASSWORD_FETCH_SUCCESS =
  'USER_CHANGE_PASSWORD_FETCH_SUCCESS';

export const USER_CHANGE_PASSWORD_SUBMIT_FETCH =
  'USER_CHANGE_PASSWORD_SUBMIT_FETCH';
export const USER_CHANGE_PASSWORD_SUBMIT_FETCH_FAIL =
  'USER_CHANGE_PASSWORD_SUBMIT_FETCH_FAIL';
export const USER_CHANGE_PASSWORD_SUBMIT_FETCH_SUCCESS =
  'USER_CHANGE_PASSWORD_SUBMIT_FETCH_SUCCESS';

export const USER_UPLOAD_PICTURE_FETCH = 'USER_UPLOAD_PICTURE_FETCH';
export const USER_UPLOAD_PICTURE_FETCH_FAIL = 'USER_UPLOAD_PICTURE_FETCH_FAIL';
export const USER_UPLOAD_PICTURE_FETCH_SUCCESS =
  'USER_UPLOAD_PICTURE_FETCH_SUCCESS';

export const USER_SEARCH_LIST_FETCH = 'USER_SEARCH_LIST_FETCH';
export const USER_SEARCH_LIST_FETCH_FAIL = 'USER_SEARCH_LIST_FETCH_FAIL';
export const USER_SEARCH_LIST_FETCH_SUCCESS = 'USER_SEARCH_LIST_FETCH_SUCCESS';

export const ADMIN_USER_CLEAR_ERROR = 'ADMIN_USER_CLEAR_ERROR';
