import React from 'react';
import { isNil, find as lodashFind } from 'lodash';
import moment from 'moment';
import { renderCampaignStatus, renderTag } from '../../../../utils/RenderUtils';
import { getJourneyId } from '../../../../utils/AuthorityProvider';
import { renderDelay } from '../../../../utils/ReducerUtils';
import {
  convertMinute,
  readableDateTime,
  viewTime,
} from '../../../../utils/DateUtils';
import { isEmpty } from 'lodash';

const styles = {
  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'capitalize',
  },
  infoBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 30px',
    borderBottom: '1px solid #e7e8e9',
    backgroundColor: '#fdfdfd',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dataRowContainer: {
    padding: 30,
  },
  dataRowFirst: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dataRow: {
    paddingTop: 10,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dataValue: {
    direction: 'ltr',
    fontSize: 13,
    color: '#3d404e',
  },
  dataContainer: {
    display: 'flex',
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
  },
  dataLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '20px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
  },
};

class JourneyDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.journeyId = getJourneyId(this.props.pathname);
    this.state = {
      segment: 0,
      activeVariationIndex: 0,
    };
  }

  componentDidMount() {
    this.props.journeyViewFetch(this.journeyId);
    this.props.journeyListTagsFetch();
  }

  getStartDate = (preview = false) => {
    let startDate = moment().toDate();
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.startDate
    ) {
      startDate = this.props.currentCommunication.startDate;
    }
    if (preview) {
      return !startDate ? '-' : readableDateTime(startDate);
    }
    return startDate;
  };

  getEndDate = (preview = false) => {
    let endDate = null;
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.endDate &&
      this.props.currentCommunication.type !== 'ONE_TIME'
    ) {
      endDate = this.props.currentCommunication.endDate;
    }
    if (preview) {
      return !endDate ? '-' : readableDateTime(endDate);
    }
    return endDate;
  };

  renderAudienceType = () => {
    let result = null;
    if (
      (this.props.currentCommunication &&
        (isNil(this.props.currentCommunication.segments) ||
          isEmpty(this.props.currentCommunication.segments)) &&
        (isNil(this.props.currentCommunication.ignoreSegments) ||
          isEmpty(this.props.currentCommunication.ignoreSegments))) ||
      (this.props.currentCommunication &&
        this.props.currentCommunication.segments &&
        this.props.currentCommunication.segments.length === 1 &&
        (!this.props.currentCommunication.ignoreSegments ||
          (this.props.currentCommunication.ignoreSegments &&
            this.props.currentCommunication.ignoreSegments.length === 0)))
    ) {
      result = 'Single Segment';
    } else {
      result = 'Multiple Segment';
    }
    return result;
  };

  renderPushPackageNames = () => {
    const result = [];
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.pushChannels &&
      this.props.pushChannels
    ) {
      this.props.currentCommunication.pushChannels.forEach((item) => {
        const foundItem = lodashFind(
          this.props.pushChannels,
          (o) => o.id === item
        );
        if (foundItem && foundItem.packageName) {
          result.push(foundItem.packageName);
        }
      });
    }
    if (result.length > 0) {
      return result.join(', ');
    } else {
      return '-';
    }
  };

  renderWeekDay = (days) => {
    let result = '';
    const daysName = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    if (Array.isArray(days) && days.length > 0) {
      days.forEach((day, index) => {
        result += daysName[day];
        if (index !== days.length - 1) {
          result += ', ';
        }
      });
    }
    return result;
  };

  renderMonthDay = (days) => {
    let result = '';
    if (Array.isArray(days) && days.length > 0) {
      days.forEach((day, index) => {
        if (day == -1) {
          result = 'Last day of month';
          if (index !== days.length - 1) {
            result += ', ';
          }
        } else {
          const dayNumber = Number(day) + 1;
          result += dayNumber + '';
          if (index !== days.length - 1) {
            result += ', ';
          }
        }
      });
    }
    return result;
  };

  renderRecuringTime = () => {
    let result = '-';
    if (this.props.currentCommunication) {
      if (this.props.currentCommunication.repeatType === 'DAY') {
        result =
          'Send Every ' +
          this.props.currentCommunication.repeatEvery +
          ' ' +
          'Day' +
          (this.props.currentCommunication.repeatEvery == 1 ? ' ' : 's ') +
          ' at ' +
          viewTime(this.props.currentCommunication.repeatTime);
      } else if (this.props.currentCommunication.repeatType === 'WEEK') {
        result =
          'Send Every ' +
          this.props.currentCommunication.repeatEvery +
          ' ' +
          'Week' +
          (this.props.currentCommunication.repeatEvery == 1 ? ' ' : 's ') +
          this.renderWeekDay() +
          ' at ' +
          viewTime(this.props.currentCommunication.repeatTime);
      } else if (this.props.currentCommunication.repeatType === 'MONTH') {
        result =
          'Send Every ' +
          this.props.currentCommunication.repeatEvery +
          ' ' +
          'Month' +
          (this.props.currentCommunication.repeatEvery == 1 ? ' ' : 's ') +
          this.renderMonthDay() +
          ' at ' +
          viewTime(this.props.currentCommunication.repeatTime);
      }
    }
    return result;
  };

  renderDeliveryTime = () => {
    let result = '';
    if (
      this.props.currentCommunication &&
      (isNil(this.props.currentCommunication.delayMinutes) ||
        this.props.currentCommunication.delayMinutes == 0)
    ) {
      result = 'On Event Occurrence';
    } else {
      const delayMinutes = this.props.currentCommunication.delayMinutes;
      result = renderDelay(delayMinutes) + ', After Event Occurrencesss';
    }
    return result;
  };

  renderConversionTime = () => {
    let result = '-';
    if (
      this.props.journeyDetails &&
      (this.props.journeyDetails.conversionDeadlineMinutes === null ||
        this.props.journeyDetails.conversionDeadlineMinutes == 0)
    ) {
      result = 'On Event Occurrence';
    } else {
      const delayMinutes = this.props.journeyDetails.conversionDeadlineMinutes;
      result = renderDelay(delayMinutes) + ', After Event Occurrence';
    }
    return result;
  };

  isJourneyType = () => {
    return (
      this.props.currentCommunication &&
      this.props.currentCommunication.type === 'JOURNEY'
    );
  };
  isRelayType = () => {
    return (
      this.props.currentCommunication &&
      this.props.currentCommunication.type === 'RELAY'
    );
  };

  renderCampaignType = () => {
    let result = '';
    if (this.props.currentCommunication) {
      if (this.props.currentCommunication.type === 'TRIGGERED') {
        result = 'Triggered';
      } else if (this.props.currentCommunication.type === 'ONE_TIME') {
        result = 'One Time';
      } else if (this.props.currentCommunication.type === 'RECURRING') {
        result = 'Recurring';
      } else if (this.props.currentCommunication.type === 'TRANSACTIONAL') {
        result = 'Transactional';
      } else if (this.props.currentCommunication.type === 'JOURNEY') {
        result = 'Journey';
      } else if (this.props.currentCommunication.type === 'RELAY') {
        result = 'Relay';
      }
    }
    return result;
  };

  renderTriggeredEvent = () => {
    let result = '';
    if (
      this.props.currentCommunication &&
      !isNil(this.props.currentCommunication.event)
    ) {
      const foundItem = lodashFind(
        this.props.eventList,
        (o) => o.id === this.props.currentCommunication.event
      );
      if (foundItem && foundItem.title) {
        result = foundItem.title;
      }
    }
    return result ? result : '-';
  };

  renderEventName = (obj) => {
    let result = '-';
    if (obj && obj.title) {
      result = obj.title;
    }
    return result;
  };

  renderTags = () => {
    if (
      this.props.journeyDetails &&
      this.props.journeyDetails.tags &&
      Array.isArray(this.props.journeyDetails.tags) &&
      this.props.journeyDetails.tags.length > 0
    ) {
      let result = [];
      this.props.journeyDetails.tags.forEach((item) => {
        result.push(
          <span style={{ marginRight: 10 }}>
            {renderTag(item, this.props.listTags)}
          </span>
        );
      });
      return result;
    }
    return '-';
  };

  renderPostfixQueue = (value, text) => {
    if (!isNil(value) && !isNil(text)) {
      if (value && value == 1) {
        return 'Up to ' + value + ' ' + text.toLowerCase();
      } else {
        return 'Up to ' + value + ' ' + text.toLowerCase() + 's';
      }
    }
    return '-';
  };

  renderQueueMinutes = () => {
    if (this.props.journeyDetails.queueMinutes) {
      const queue = convertMinute(this.props.journeyDetails.queueMinutes);
      return this.renderPostfixQueue(queue.value, queue.type);
    }
    return '';
  };

  renderPreview = () => {
    const controlGroupPercentage =
      this.props.journeyDetails &&
      this.props.journeyDetails.controlGroupPercentage
        ? this.props.journeyDetails.controlGroupPercentage
        : null;

    return (
      <div className="raman-table">
        <div style={{ background: 'none', backgroundColor: 'white' }}>
          <div style={{ border: '1px solid #F1F1F3' }}>
            <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
              <div style={styles.headTitleContainer}>
                <span>{this.props.type.toLowerCase()} Metadata</span>
              </div>
            </div>
            <div style={styles.dataRowContainer}>
              <div style={styles.dataRowFirst}>
                <div style={styles.dataContainer}>
                  <span style={styles.dataLabel}>{this.props.type} Status</span>
                  <span style={styles.dataValue}>
                    {this.props.journeyDetails &&
                    this.props.journeyDetails.status
                      ? renderCampaignStatus(this.props.journeyDetails.status)
                      : '-'}
                  </span>
                </div>
                <div style={styles.dataContainer}>
                  <span style={styles.dataLabel}>Tags</span>
                  <span style={styles.dataValue}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {this.renderTags()}
                    </div>
                  </span>
                </div>
                <div style={styles.dataContainer}></div>
              </div>
            </div>
          </div>
        </div>
        {false && (
          <div
            className="raman-paper-profile"
            style={{
              background: 'none',
              marginTop: 40,
              backgroundColor: 'white',
            }}
          >
            <div style={{ border: '1px solid #F1F1F3' }}>
              <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                <div style={styles.headTitleContainer}>
                  <span>Audience</span>
                </div>
              </div>
              <div style={styles.dataRowContainer}>
                <div style={styles.dataRowFirst}>
                  <div style={styles.dataContainer}>
                    <span style={styles.dataLabel}>COMMUNICATION NAME</span>
                    <span style={styles.dataValue}>
                      {this.props.currentCommunication &&
                      this.props.currentCommunication.name
                        ? this.props.currentCommunication.name
                        : '-'}
                    </span>
                  </div>
                  <div style={styles.dataContainer}>
                    <span style={styles.dataLabel}>CONTENT TYPE</span>
                    <span style={styles.dataValue}>
                      {this.props.currentCommunication &&
                      this.props.currentCommunication.transactional === false
                        ? 'Promotional'
                        : 'Transactional'}
                    </span>
                  </div>
                  <div style={styles.dataContainer}>
                    <span style={styles.dataLabel}>AUDIENCE TYPE:</span>
                    <span style={styles.dataValue}>
                      {this.renderAudienceType()}
                    </span>
                  </div>
                </div>
                <div style={styles.dataRow}>
                  <div style={styles.dataContainer}>
                    <span style={styles.dataLabel}>Send to</span>
                    <span style={styles.dataValue}>{this.renderSendTo()}</span>
                  </div>
                  <div style={styles.dataContainer}></div>
                  <div style={styles.dataContainer}></div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className="raman-paper-profile"
          style={{
            background: 'none',
            marginTop: 40,
            backgroundColor: 'white',
          }}
        >
          <div style={{ border: '1px solid #F1F1F3' }}>
            <div style={styles.infoBoxHeader}>
              <div style={styles.headTitleContainer}>
                <span>Details</span>
              </div>
            </div>
            <div style={styles.dataRowContainer}>
              <div style={styles.dataRowFirst}>
                <div style={styles.dataContainer}>
                  <span style={styles.dataLabel}>Segments</span>
                  <span style={styles.dataValue}>
                    {this.props.journeyDetails &&
                    this.props.journeyDetails.segment &&
                    this.props.journeyDetails.segment.name
                      ? this.props.journeyDetails.segment.name
                      : '-'}
                  </span>
                </div>
              </div>
              <div style={styles.dataRow}>
                <div style={styles.dataContainer}>
                  <span style={styles.dataLabel}>FC</span>
                  <span style={styles.dataValue}>
                    {this.props.journeyDetails &&
                    this.props.journeyDetails.followFrequencyCapping === true
                      ? 'Follow'
                      : 'Ignore'}
                  </span>
                </div>
                <div style={styles.dataContainer}>
                  <span style={styles.dataLabel}>DND</span>
                  <span style={styles.dataValue}>
                    {this.props.journeyDetails &&
                    this.props.journeyDetails.followDnd === true
                      ? 'Follow'
                      : 'Ignore'}
                  </span>
                </div>
                <div style={styles.dataContainer}>
                  <span style={styles.dataLabel}>QUEUEING</span>
                  <span style={styles.dataValue}>
                    {this.props.journeyDetails &&
                    this.props.journeyDetails.queueMinutes
                      ? this.renderQueueMinutes()
                      : 'Do Not Queue'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background: 'none',
            marginTop: 40,
            backgroundColor: 'white',
            marginBottom: 40,
          }}
        >
          <div style={{ border: '1px solid #F1F1F3' }}>
            <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
              <div style={styles.headTitleContainer}>
                <span>Conversion Tracking</span>
              </div>
            </div>
            <div style={styles.dataRowContainer}>
              <div style={styles.dataRowFirst}>
                <div style={styles.dataContainer}>
                  <span style={styles.dataLabel}>CONVERSION TRACKING</span>
                  <span style={styles.dataValue}>
                    {this.props.journeyDetails &&
                    this.props.journeyDetails.conversion
                      ? 'on'
                      : 'off'}
                  </span>
                </div>
                <div style={styles.dataContainer}>
                  {this.props.journeyDetails &&
                    this.props.journeyDetails.conversion && (
                      <span style={styles.dataLabel}>CONVERSION EVENT</span>
                    )}
                  {this.props.journeyDetails &&
                    this.props.journeyDetails.conversion &&
                    this.props.journeyDetails.conversionEvent && (
                      <span style={styles.dataValue}>
                        {this.renderEventName(
                          this.props.journeyDetails.conversionEvent
                        )}
                      </span>
                    )}
                </div>
                <div style={styles.dataContainer}>
                  {this.props.journeyDetails &&
                    this.props.journeyDetails.conversion && (
                      <span style={styles.dataLabel}>CONVERSION DEADLINE</span>
                    )}
                  {this.props.journeyDetails &&
                    this.props.journeyDetails.conversion &&
                    this.props.journeyDetails.conversionDeadlineMinutes && (
                      <span style={styles.dataValue}>
                        {this.renderConversionTime()}
                      </span>
                    )}
                </div>
              </div>
              <div style={styles.dataRow}>
                <div style={styles.dataContainer}>
                  {this.props.journeyDetails &&
                    this.props.journeyDetails.controlGroup && (
                      <span style={styles.dataLabel}>CONTROL GROUP</span>
                    )}
                  {this.props.journeyDetails &&
                    this.props.journeyDetails.controlGroup && (
                      <span style={styles.dataValue}>
                        {this.props.journeyDetails.controlGroup
                          ? 'Enabled ' + '(' + controlGroupPercentage + '%)'
                          : 'Disabled'}
                      </span>
                    )}
                </div>
                <div style={styles.dataContainer}></div>
                <div style={styles.dataContainer}></div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background: 'none',
            marginTop: 40,
            backgroundColor: 'white',
            marginBottom: 40,
          }}
        >
          <div style={{ border: '1px solid #F1F1F3' }}>
            <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
              <div style={styles.headTitleContainer}>
                <span>Activity Metadata</span>
              </div>
            </div>
            <div style={styles.dataRowContainer}>
              <div style={styles.dataRowFirst}>
                <div style={styles.dataContainer}>
                  <span style={styles.dataLabel}>Created By</span>
                  <span style={styles.dataValue}>
                    {this.props.journeyDetails &&
                    this.props.journeyDetails.createdBy
                      ? this.props.journeyDetails.createdBy.presentation
                      : '-'}
                  </span>
                </div>
                <div style={styles.dataContainer}>
                  <span style={styles.dataLabel}>Created Date</span>
                  <span style={styles.dataValue}>
                    {this.props.journeyDetails &&
                    this.props.journeyDetails.createdDate
                      ? readableDateTime(this.props.journeyDetails.createdDate)
                      : '-'}
                  </span>
                </div>
                <div style={styles.dataContainer}></div>
              </div>
              <div style={styles.dataRowFirst}>
                <div style={styles.dataContainer}>
                  <span style={styles.dataLabel}>Modified By</span>
                  <span style={styles.dataValue}>
                    {this.props.journeyDetails &&
                    this.props.journeyDetails.modifiedBy
                      ? this.props.journeyDetails.modifiedBy.presentation
                      : '-'}
                  </span>
                </div>
                <div style={styles.dataContainer}>
                  <span style={styles.dataLabel}>Modified Date</span>
                  <span style={styles.dataValue}>
                    {this.props.journeyDetails &&
                    this.props.journeyDetails.modifiedDate
                      ? readableDateTime(this.props.journeyDetails.modifiedDate)
                      : '-'}
                  </span>
                </div>
                <div style={styles.dataContainer}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderSendTo = () => {
    let str = '';
    let result = '';
    let notResult = '';
    if (
      this.props.segments &&
      this.props.currentCommunication &&
      this.props.currentCommunication.segments &&
      this.props.currentCommunication.segments.length > 0
    ) {
      this.props.currentCommunication.segments.forEach((item, index) => {
        const foundItem = lodashFind(this.props.segments, (o) => o.id === item);
        if (
          foundItem &&
          foundItem.name &&
          index < this.props.currentCommunication.segments.length - 1
        ) {
          result += foundItem.name + ', ';
        } else if (foundItem && foundItem.name) {
          result += foundItem.name;
        }
      });
    }
    if (
      this.props.segments &&
      this.props.currentCommunication &&
      this.props.currentCommunication.ignoreSegments &&
      this.props.currentCommunication.ignoreSegments.length > 0
    ) {
      this.props.currentCommunication.ignoreSegments.forEach((item, index) => {
        const foundItem = lodashFind(this.props.segments, (o) => o.id === item);
        if (
          foundItem &&
          foundItem.name &&
          index < this.props.currentCommunication.ignoreSegments.length - 1
        ) {
          notResult += foundItem.name + ', ';
        } else if (foundItem && foundItem.name) {
          notResult += foundItem.name;
        }
      });
    }
    if (result) {
      str +=
        'Users in' +
        (this.props.currentCommunication.segments.length > 1
          ? ' (' + this.props.currentCommunication.segmentsConjunction + ')'
          : '') +
        ': ' +
        result;
    }
    if (notResult) {
      str +=
        (isEmpty(str) ? '' : ', and ') +
        'Users Not in' +
        (this.props.currentCommunication.ignoreSegments.length > 1
          ? ' (' +
            this.props.currentCommunication.ignoreSegmentsConjunction +
            ')'
          : '') +
        ': ' +
        notResult;
    }
    if (!result && !notResult) {
      str = 'All Users (No Specific Segment)';
    }
    return str;
  };

  render() {
    return <div style={{ padding: 30 }}>{this.renderPreview()}</div>;
  }
}

export default JourneyDetailsComponent;
