import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router';
import UninstallOverViewComponent from '../uninstalls/component/UninstallOverViewComponent';
import UninstallAnalyzeComponent from '../uninstalls/component/UninstallAnalyzeComponent';
import UninstallUsersComponent from '../uninstalls/component/UninstallUsersComponent';

function Uninstall() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}overview`}>
        <UninstallOverViewComponent />
      </Route>
      <Route path={`${match.path}analyze`}>
        <UninstallAnalyzeComponent />
      </Route>
      <Route path={`${match.path}users`}>
        <UninstallUsersComponent />
      </Route>
    </Switch>
  );
}

export default Uninstall;
