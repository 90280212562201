import React from 'react';
import { Layout } from 'antd';
import { NavLink } from 'react-router-dom';
import style from '@Modules/layout/header/component/HeaderComponent.module.less';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
const { Header } = Layout;

import vars from '@Theme/styles/vars.js';
const styles = {
  menuActive: {
    color: vars.primaryColor,
    textAlign: 'center',
    fontSize: 13,
    width: 'auto',
    borderBottom: '2px solid',
    borderColor: vars.primaryColor,
    cursor: 'pointer',
    position: 'relative',
    height: '39px',
    lineHeight: '31px',
    paddingRight: 10,
    paddingLeft: 10,
  },
  menuNormal: {
    color: vars.light800,
    opacity: '80%',
    fontSize: 13,
    width: 'auto',
    cursor: 'pointer',
    height: '39px',
    lineHeight: '31px',
    textAlign: 'center',
    paddingRight: 10,
    paddingLeft: 10,
  },
};

export default function PagesHeader({
  title,
  subMenuItems = [],
  actions = null,
  button = null,
  hasBackButton = false,
  backUrl = '/',
  historyGoBack = false,
}) {
  const history = useHistory();

  const renderBackButton = (historyGoBack = false) => {
    return (
      <ArrowLeftOutlined
        onClick={() =>
          historyGoBack ? history.goBack() : history.push(backUrl)
        }
        className={style.icon_back}
      />
    );
  };

  return (
    <Header className={style.header}>
      <div className={style.top_header}>
        {/* TODO: back button */}
        <div className={style.left_header}>
          <div className={style.header_title}>
            {/* TODO: back button */}
            {hasBackButton ? renderBackButton(historyGoBack) : null}
            <h6>{title}</h6>
          </div>
        </div>
        <div className={style.right_header}>
          {actions}
          {button}
        </div>
      </div>
      {subMenuItems.length ? (
        <div className={style.tab_menu}>
          {subMenuItems.map((menuItem) => (
            <NavLink
              key={menuItem.route}
              to={menuItem?.route}
              style={styles.menuNormal}
              activeStyle={styles.menuActive}
            >
              {menuItem?.title}
            </NavLink>
          ))}
        </div>
      ) : null}
    </Header>
  );
}
