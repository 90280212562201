import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

class PageBase extends React.Component {
  static getLink(item) {
    if (item.path) {
      return (
        <Breadcrumb.Item key={item.path}>
          <Link to={item.path}>{item.name}</Link>
        </Breadcrumb.Item>
      );
    } else {
      return <Breadcrumb.Item key={'current'}>{item.name}</Breadcrumb.Item>;
    }
  }

  getBreadCrumb() {
    const { title } = this.props;
    return (
      <div>
        <h3>{title}</h3>
        <Breadcrumb>
          {this.props.navigation &&
            this.props.navigation.map((item) => PageBase.getLink(item))}
        </Breadcrumb>
      </div>
    );
  }

  render() {
    const { children } = this.props;
    return (
      <div>
        <span>{this.getBreadCrumb()}</span>
        <div>{children}</div>
      </div>
    );
  }
}

PageBase.propTypes = {
  title: PropTypes.string,
  navigation: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

export default PageBase;
