export const JOURNEY_CREATE = 'JOURNEY_CREATE';
export const JOURNEY_CREATE_FAIL = 'JOURNEY_CREATE_FAIL';
export const JOURNEY_CREATE_SUCCESS = 'JOURNEY_CREATE__SUCCESS';

export const COMMUNICATION_UPDATE_JOURNEY = 'COMMUNICATION_UPDATE_JOURNEY';
export const COMMUNICATION_UPDATE_JOURNEY_FAIL =
  'COMMUNICATION_UPDATE_JOURNEY_FAIL';
export const COMMUNICATION_UPDATE_JOURNEY_SUCCESS =
  'COMMUNICATION_UPDATE_JOURNEY_SUCCESS';

export const COMMUNICATION_UPDATE_SUBMIT_JOURNEY =
  'COMMUNICATION_UPDATE_SUBMIT_JOURNEY';
export const COMMUNICATION_UPDATE_SUBMIT_JOURNEY_SUCCESS =
  'COMMUNICATION_UPDATE_SUBMIT_JOURNEY_SUCCESS';
export const COMMUNICATION_UPDATE_SUBMIT_JOURNEY_FAIL =
  'COMMUNICATION_UPDATE_SUBMIT_JOURNEY_FAIL';
export const COMMUNICATION_UPDATE_STEPS_JOURNEY_SUCCESS =
  'COMMUNICATION_UPDATE_STEPS_JOURNEY_SUCCESS';

export const JOURNEY_CHANGE_CURRENT = 'JOURNEY_CHANGE_CURRENT';

export const JOURNEY_UPDATE_TAGS = 'JOURNEY_UPDATE_TAGS';
export const JOURNEY_UPDATE_TAGS_SUCCESS = 'JOURNEY_UPDATE_TAGS_SUCCESS';
export const JOURNEY_UPDATE_TAGS_FAIL = 'JOURNEY_UPDATE_TAGS_FAIL';

export const JOURNEY_UPDATE_SUBMIT_TAGS = 'JOURNEY_UPDATE_SUBMIT_TAGS';
export const JOURNEY_UPDATE_SUBMIT_TAGS_SUCCESS =
  'JOURNEY_UPDATE_SUBMIT_TAGS_SUCCESS';
export const JOURNEY_UPDATE_SUBMIT_TAGS_FAIL =
  'JOURNEY_UPDATE_SUBMIT_TAGS_FAIL';

export const JOURNEY_PAGE_CHANGE_CURRENT = 'JOURNEY_PAGE_CHANGE_CURRENT';

export const JOURNEY_LIST_FETCH = 'JOURNEY_LIST_FETCH';
export const JOURNEY_LIST_FETCH_SUCCESS = 'JOURNEY_LIST_FETCH_SUCCESS';
export const JOURNEY_LIST_FETCH_FAIL = 'JOURNEY_LIST_FETCH_FAIL';

export const JOURNEY_LIST_TAGS_FETCH = 'JOURNEY_LIST_TAGS_FETCH';
export const JOURNEY_LIST_TAGS_FETCH_SUCCESS =
  'JOURNEY_LIST_TAGS_FETCH_SUCCESS';
export const JOURNEY_LIST_TAGS_FETCH_FAIL = 'JOURNEY_LIST_TAGS_FETCH_FAIL';

export const JOURNEY_VIEW_FETCH = 'JOURNEY_VIEW_FETCH';
export const JOURNEY_VIEW_FETCH_SUCCESS = 'JOURNEY_VIEW_FETCH_SUCCESS';
export const JOURNEY_VIEW_FETCH_FAIL = 'JOURNEY_VIEW_FETCH_FAIL';

export const JOURNEY_DELETE_FETCH = 'JOURNEY_DELETE_FETCH';
export const JOURNEY_DELETE_FETCH_FAIL = 'JOURNEY_DELETE_FETCH_FAIL';
export const JOURNEY_DELETE_FETCH_SUCCESS = 'JOURNEY_DELETE_FETCH_SUCCESS';

export const JOURNEY_LIST_ATTRIBUTES_FETCH = 'JOURNEY_LIST_ATTRIBUTES_FETCH';
export const JOURNEY_LIST_ATTRIBUTES_FETCH_SUCCESS =
  'JOURNEY_LIST_ATTRIBUTES_FETCH_SUCCESS';
export const JOURNEY_LIST_ATTRIBUTES_FETCH_FAIL =
  'JOURNEY_LIST_ATTRIBUTES_FETCH_FAIL';

export const JOURNEY_LIST_SEGMENTS_FETCH = 'JOURNEY_LIST_SEGMENTS_FETCH';
export const JOURNEY_LIST_SEGMENTS_FETCH_SUCCESS =
  'JOURNEY_LIST_SEGMENTS_FETCH_SUCCESS';
export const JOURNEY_LIST_SEGMENTS_FETCH_FAIL =
  'JOURNEY_LIST_SEGMENTS_FETCH_FAIL';

export const JOURNEY_LIST_EVENTS_FETCH = 'JOURNEY_LIST_EVENTS_FETCH';
export const JOURNEY_LIST_EVENTS_FETCH_SUCCESS =
  'JOURNEY_LIST_EVENTS_FETCH_SUCCESS';
export const JOURNEY_LIST_EVENTS_FETCH_FAIL = 'JOURNEY_LIST_EVENTS_FETCH_FAIL';

export const JOURNEY_LIST_BUSINESS_EVENTS_FETCH =
  'JOURNEY_LIST_BUSINESS_EVENTS_FETCH';
export const JOURNEY_LIST_BUSINESS_EVENTS_FETCH_SUCCESS =
  'JOURNEY_LIST_BUSINESS_EVENTS_FETCH_SUCCESS';
export const JOURNEY_LIST_BUSINESS_EVENTS_FETCH_FAIL =
  'JOURNEY_LIST_BUSINESS_EVENTS_FETCH_FAIL';

export const JOURNEY_BUSINESS_EVENTS_REPORT_FETCH =
  'JOURNEY_BUSINESS_EVENTS_REPORT_FETCH';
export const JOURNEY_BUSINESS_EVENTS_REPORT_FETCH_SUCCESS =
  'JOURNEY_BUSINESS_EVENTS_REPORT_FETCH_SUCCESS';
export const JOURNEY_BUSINESS_EVENTS_REPORT_FETCH_FAIL =
  'JOURNEY_BUSINESS_EVENTS_REPORT_FETCH_FAIL';

export const JOURNEY_REPORT_OVERVIEW = 'JOURNEY_REPORT_OVERVIEW';
export const JOURNEY_REPORT_OVERVIEW_SUCCESS =
  'JOURNEY_REPORT_OVERVIEW_SUCCESS';
export const JOURNEY_REPORT_OVERVIEW_FAIL = 'JOURNEY_REPORT_OVERVIEW_FAIL';

export const JOURNEY_REPORT_COMMUNICATION_FETCH =
  'JOURNEY_REPORT_COMMUNICATION_FETCH';
export const JOURNEY_REPORT_COMMUNICATION_FETCH_SUCCESS =
  'JOURNEY_REPORT_COMMUNICATION_FETCH_SUCCESS';
export const JOURNEY_REPORT_COMMUNICATION_FETCH_FAIL =
  'JOURNEY_REPORT_COMMUNICATION_FETCH_FAIL';

export const JOURNEY_REPORT_ENTER_OR_EXIT_FETCH =
  'JOURNEY_REPORT_ENTER_OR_EXIT_FETCH';
export const JOURNEY_REPORT_ENTER_OR_EXIT_FETCH_SUCCESS =
  'JOURNEY_REPORT_ENTER_OR_EXIT_FETCH_SUCCESS';
export const JOURNEY_REPORT_ENTER_OR_EXIT_FETCH_FAIL =
  'JOURNEY_REPORT_ENTER_OR_EXIT_FETCH_FAIL';

export const JOURNEY_REPORT_LIVE = 'JOURNEY_REPORT_LIVE';
export const JOURNEY_REPORT_LIVE_SUCCESS = 'JOURNEY_REPORT_LIVE_SUCCESS';
export const JOURNEY_REPORT_LIVE_FAIL = 'JOURNEY_REPORT_LIVE_FAIL';

export const JOURNEY_LANUCH = 'JOURNEY_LANUCH';
export const JOURNEY_LANUCH_SUCCESS = 'JOURNEY_LANUCH_SUCCESS';
export const JOURNEY_LANUCH_FAIL = 'JOURNEY_LANUCH_FAIL';
export const JOURNEY_LANUCH_CLEAR = 'JOURNEY_LANUCH_CLEAR';

export const JOURNEY_STOP = 'JOURNEY_STOP';
export const JOURNEY_STOP_SUCCESS = 'JOURNEY_STOP_SUCCESS';
export const JOURNEY_STOP_FAIL = 'JOURNEY_STOP_FAIL';

export const JOURNEY_PAUSE = 'JOURNEY_PAUSE';
export const JOURNEY_PAUSE_SUCCESS = 'JOURNEY_PAUSE_SUCCESS';
export const JOURNEY_PAUSE_FAIL = 'JOURNEY_PAUSE_FAIL';

export const JOURNEY_COPY_FETCH_FAIL = 'JOURNEY_COPY_FETCH_FAIL';
export const JOURNEY_COPY_FETCH_SUCCESS = 'JOURNEY_COPY_FETCH_SUCCESS';
export const JOURNEY_COPY_FETCH = 'JOURNEY_COPY_FETCH';

export const JOURNEY_TEST_API_FAIL = 'JOURNEY_TEST_API_FAIL';
export const JOURNEY_TEST_API_SUCCESS = 'JOURNEY_TEST_API_SUCCESS';
export const JOURNEY_TEST_API = 'JOURNEY_TEST_API';

export const JOURNEY_LIST_SEARCH_FETCH = 'JOURNEY_LIST_SEARCH_FETCH';
export const JOURNEY_LIST_SEARCH_FETCH_SUCCESS =
  'JOURNEY_LIST_SEARCH_FETCH_SUCCESS';
export const JOURNEY_LIST_SEARCH_FETCH_FAIL = 'JOURNEY_LIST_SEARCH_FETCH_FAIL';
export const JOURNEY_ENGAGED_USER_FETCH = 'JOURNEY_ENGAGED_USER_FETCH';
export const JOURNEY_ENGAGED_USER_FETCH_SUCCESS =
  'JOURNEY_ENGAGED_USER_FETCH_SUCCESS';
export const JOURNEY_ENGAGED_USER_FETCH_FAIL =
  'JOURNEY_ENGAGED_USER_FETCH_FAIL';

export const JOURNEY_ARCHIVE_FETCH = 'JOURNEY_ARCHIVE_FETCH';
export const JOURNEY_ARCHIVE_FETCH_FAIL = 'JOURNEY_ARCHIVE_FETCH_FAIL';
export const JOURNEY_ARCHIVE_FETCH_SUCCESS = 'JOURNEY_ARCHIVE_FETCH_SUCCESS';

export const JOURNEY_ACTIVE_FETCH = 'JOURNEY_ACTIVE_FETCH';
export const JOURNEY_ACTIVE_FETCH_FAIL = 'JOURNEY_ACTIVE_FETCH_FAIL';
export const JOURNEY_ACTIVE_FETCH_SUCCESS = 'JOURNEY_ACTIVE_FETCH_SUCCESS';

export const JOURNEY_REPORT_OVERVIEW_CSV_FETCH =
  'JOURNEY_REPORT_OVERVIEW_CSV_FETCH';
export const JOURNEY_REPORT_OVERVIEW_CSV_SUCCESS =
  'JOURNEY_REPORT_OVERVIEW_CSV_SUCCESS';
export const JOURNEY_REPORT_OVERVIEW_CSV_FAIL =
  'JOURNEY_REPORT_OVERVIEW_CSV_FAIL';

export const JOURNEY_REPORT_COMMUNICATION_OVERVIEW_CSV_FETCH =
  'JOURNEY_REPORT_COMMUNICATION_OVERVIEW_CSV_FETCH';
export const JOURNEY_REPORT_COMMUNICATION_OVERVIEW_CSV_SUCCESS =
  'JOURNEY_REPORT_COMMUNICATION_OVERVIEW_CSV_SUCCESS';
export const JOURNEY_REPORT_COMMUNICATION_OVERVIEW_CSV_FAIL =
  'JOURNEY_REPORT_COMMUNICATION_OVERVIEW_CSV_FAIL';

export const JOURNEY_TEMPLATE_NAMES_LIST_FETCH =
  'JOURNEY_TEMPLATE_NAMES_LIST_FETCH';

export const JOURNEY_TEMPLATE_NAMES_LIST_SUCCESS =
  'JOURNEY_TEMPLATE_NAMES_LIST_SUCCESS';
