import WebhookViewComponent from '../component/WebhookViewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  webhookCreateFetch,
  webhookDeleteFetch,
  webhookListFetch,
  webhookUpdateFetch,
  webhookUpdateSubmitFetch,
  webhookViewFetch,
  webhookChangeCurrent,
  webhookModalToggle,
  webhookPageChangeCurrent,
  listEventsFetch,
} from '../WebhookActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  listWebhook: state.integration.webhook.listWebhook,
  loadingListWebhook: state.integration.webhook.loadingListWebhook,
  pageRequest: state.integration.webhook.pageRequest,
  errorListWebhook: state.integration.webhook.errorListWebhook,

  loadingCreate: state.integration.webhook.loadingCreate,
  errorCreate: state.integration.webhook.errorCreate,

  errorUpdateSubmit: state.integration.webhook.errorUpdateSubmit,
  loadingUpdateSubmit: state.integration.webhook.loadingUpdateSubmit,

  loadingUpdate: state.integration.webhook.loadingUpdate,
  currentWebhook: state.integration.webhook.currentWebhook,
  errorUpdate: state.integration.webhook.errorUpdate,

  batchSize: state.integration.webhook.batchSize,

  loadingDelete: state.integration.webhook.loadingDelete,
  errorDelete: state.integration.webhook.errorDelete,

  loadingView: state.integration.webhook.loadingView,
  errorView: state.integration.webhook.errorView,
  view: state.integration.webhook.view,

  webhookCreateOpen: state.integration.webhook.webhookCreateOpen,
  listEvents: state.integration.webhook.listEvents,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      webhookCreateFetch,
      webhookDeleteFetch,
      webhookListFetch,
      webhookUpdateFetch,
      webhookUpdateSubmitFetch,
      webhookViewFetch,
      webhookChangeCurrent,
      webhookModalToggle,
      webhookPageChangeCurrent,
      listEventsFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebhookViewComponent);

WebhookViewComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
};
