import UserInteractionsComponent from '../component/UserInteractionsComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  insightProductUserViewFetch,
  insightProductUserEventFetch,
  insightProductUserCommunicationsFetch,
  insightProductUserListEventsFetch,
  insightProductUserJourneyFetch,
  insightProductUserRelayFetch,
} from '../UsersActions';
import { communicationListSegmentsFetch } from '../../../engage/communication/CommunicationActions';

const mapStateToProps = (state) => ({
  eventPageRequest: state.track.users.eventPageRequest,
  eventLoading: state.track.users.eventLoading,
  eventErrors: state.track.users.eventErrors,
  event: state.track.users.event,
  communicationsPageRequest: state.track.users.communicationsPageRequest,
  communicationsLoading: state.track.users.communicationsLoading,
  communicationsErrors: state.track.users.communicationsErrors,
  communications: state.track.users.communications,
  pathname: state.router.location.pathname,
  userLoading: state.track.users.userLoading,
  attributesListLoading: state.track.users.attributesListLoading,
  segments: state.engage.communication.segments,
  eventsList: state.track.users.eventsList,
  journeys: state.track.users.journeys,
  journeyPageRequest: state.track.users.journeyPageRequest,
  journeysLoading: state.track.users.journeysLoading,
  journeysErrors: state.track.users.journeysErrors,
  relaysLoading: state.track.users.relaysLoading,
  relays: state.track.users.relays,
  relayPageRequest: state.track.users.relayPageRequest,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      communicationListSegmentsFetch,
      insightProductUserEventFetch,
      insightProductUserViewFetch,
      insightProductUserCommunicationsFetch,
      insightProductUserListEventsFetch,
      insightProductUserJourneyFetch,
      insightProductUserRelayFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInteractionsComponent);

UserInteractionsComponent.propTypes = {};
