export const AlertCheckFrequency = [
  { label: 'Day', value: 'DAILY' },
  { label: 'Week', value: 'WEEKLY' },
  { label: 'Hour', value: 'HOURLY' },
];

export const AlertOccurrenceCondition = [
  { label: 'Occurrences', value: 'TOTAL' },
  { label: 'Uniques', value: 'UNIQUE' },
];

export const AlertTriggerThresholdOperator = [
  { label: 'Above', value: 'GREATER_THAN' },
];
