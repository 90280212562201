import { Tabs } from 'antd';
import React from 'react';
import Configuration from './TrOccurrenceBusinessEventConfiguration';
import TrOccurrenceBusinessEventReport from './TrOccurrenceBusinessEventReport';

export default function TrOccurrenceBusinessEvent(props) {
  const { TabPane } = Tabs;
  return (
    <Tabs defaultActiveKey="1" style={{ margin: '0 20px', height: '100%' }}>
      <TabPane tab="Configuration" key="1" style={{ height: '100%' }}>
        <Configuration {...props} />
      </TabPane>
      {props.data.journeyId && (
        <TabPane tab="History" key="2">
          <TrOccurrenceBusinessEventReport {...props} />
        </TabPane>
      )}
    </Tabs>
  );
}
