import {
  RELEASE_NOTE_CHANGE_CURRENT,
  RELEASE_NOTE_CREATE_FETCH,
  RELEASE_NOTE_CREATE_FETCH_FAIL,
  RELEASE_NOTE_CREATE_FETCH_SUCCESS,
  RELEASE_NOTE_DELETE_FETCH,
  RELEASE_NOTE_DELETE_FETCH_FAIL,
  RELEASE_NOTE_DELETE_FETCH_SUCCESS,
  RELEASE_NOTE_LIST_FETCH,
  RELEASE_NOTE_LIST_FETCH_FAIL,
  RELEASE_NOTE_LIST_FETCH_SUCCESS,
  RELEASE_NOTE_UPDATE_FETCH,
  RELEASE_NOTE_UPDATE_FETCH_FAIL,
  RELEASE_NOTE_UPDATE_FETCH_SUCCESS,
  RELEASE_NOTE_UPDATE_SUBMIT_FETCH,
  RELEASE_NOTE_UPDATE_SUBMIT_FETCH_FAIL,
  RELEASE_NOTE_UPDATE_SUBMIT_FETCH_SUCCESS,
} from './ReleaseNoteConstants';

const initialState = {
  page: {},
  pageRequest: {},
  loadingReleaseNoteList: false,
  noteUpdateLoading: false,
};

export default function ReleaseNoteStateReducer(state = initialState, action) {
  switch (action.type) {
    case RELEASE_NOTE_LIST_FETCH:
      return {
        ...state,
        pageRequest: action.payload,
        loadingReleaseNoteList: true,
      };
    case RELEASE_NOTE_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingReleaseNoteList: false };
    case RELEASE_NOTE_LIST_FETCH_FAIL:
      return {
        ...state,
        pageRequest: action.payload,
        loadingReleaseNoteList: false,
      };
    case RELEASE_NOTE_CREATE_FETCH:
      return { ...state, noteUpdateLoading: true };
    case RELEASE_NOTE_CREATE_FETCH_SUCCESS:
      return { ...state, noteUpdateLoading: false };
    case RELEASE_NOTE_CREATE_FETCH_FAIL:
      return {
        ...state,
        noteUpdateLoading: false,
      };
    case RELEASE_NOTE_UPDATE_FETCH_SUCCESS:
      return {
        ...state,
        currentNote: action.payload,
        noteUpdateLoading: false,
      };
    case RELEASE_NOTE_UPDATE_FETCH:
      return { ...state, noteUpdateLoading: true };
    case RELEASE_NOTE_UPDATE_FETCH_FAIL:
      return {
        ...state,
        noteUpdateLoading: false,
      };
    case RELEASE_NOTE_UPDATE_SUBMIT_FETCH:
      return { ...state, noteUpdateLoading: true };
    case RELEASE_NOTE_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, noteUpdateLoading: false };
    case RELEASE_NOTE_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        noteUpdateLoading: false,
      };
    case RELEASE_NOTE_DELETE_FETCH:
      return { ...state, loadingReleaseNoteList: true };
    case RELEASE_NOTE_DELETE_FETCH_SUCCESS:
      return { ...state, loadingReleaseNoteList: false };
    case RELEASE_NOTE_DELETE_FETCH_FAIL:
      return { ...state, loadingReleaseNoteList: false };
    case RELEASE_NOTE_CHANGE_CURRENT:
      return { ...state, currentNote: action.payload };
    default:
      return state;
  }
}
