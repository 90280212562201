import { Button, Modal } from 'antd';
import React, { useEffect } from 'react';
import WhatsAppProviderModalContent from './WhatsAppProviderModalContent';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
  whatsAppChannelData,
  fetchWhatsAppChannelProvider,
  updateWhatsAppChannelProvider,
  createWhatsAppChannelProvider,
  setCurrentWhatsAppChannel,
} from '@Redux/slices/channel/whatsAppChannelSlice';
import { checkPermissions } from '../model/WhatsAppConst';
import { isNil } from '@antv/util';

const WhatsAppProviderModal = ({ isOpen, onClose, providerId = null }) => {
  const dispatch = useDispatch();
  const permissions = checkPermissions();

  const { loadingUpdateSubmit, loadingCreate, currentWhatsAppChannel } =
    useSelector(whatsAppChannelData, shallowEqual);

  useEffect(() => {
    if (!isNil(providerId)) {
      dispatch(
        fetchWhatsAppChannelProvider(providerId, permissions.createOrUpdate)
      );
    }

    return () => {
      dispatch(setCurrentWhatsAppChannel({}));
    };
  }, []);

  const handleCreateWhatsAppProvider = () => {
    dispatch(createWhatsAppChannelProvider(currentWhatsAppChannel, onClose));
  };

  const handleEditWhatsAppProvider = () => {
    dispatch(
      updateWhatsAppChannelProvider(providerId, currentWhatsAppChannel, onClose)
    );
  };

  return (
    <Modal
      width={650}
      title={providerId ? 'Edit WSP' : 'Add New WSP'}
      centered
      visible={isOpen}
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <Button key="cancel" shape="round" onClick={onClose}>
          CANCEL
        </Button>,
        <Button
          loading={loadingUpdateSubmit || loadingCreate}
          key="submit"
          shape="round"
          type="primary"
          disabled={!permissions.createOrUpdate}
          onClick={
            providerId
              ? handleEditWhatsAppProvider
              : handleCreateWhatsAppProvider
          }
        >
          Save
        </Button>,
      ]}
    >
      <WhatsAppProviderModalContent />
    </Modal>
  );
};

export default WhatsAppProviderModal;
