import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;

export default function Confirm({ title, icon, message, actionSuccess }) {
  confirm({
    title: title,
    icon: icon || <ExclamationCircleOutlined />,
    content: message,
    onOk() {
      actionSuccess();
    },
    onCancel() {},
  });
}
