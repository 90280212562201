exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BusinessEventCreateComponent-module__businessEvent--v_wPR {\n  padding: 30px !important;\n  max-width: 80% !important;\n}\n.BusinessEventCreateComponent-module__header--1nB2J {\n  padding: 12px;\n  border-bottom: solid 2px #f4f9ff;\n}\n.BusinessEventCreateComponent-module__headerTitle--3un7b {\n  color: black;\n  font-size: 16px;\n  font-weight: 600;\n  padding-left: 8px;\n}\n.BusinessEventCreateComponent-module__item--dezzK {\n  align-items: center;\n  direction: ltr;\n  text-align: left;\n  display: flex;\n  flex-direction: row;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  margin-bottom: 10px;\n  margin-top: 10px;\n}\n.BusinessEventCreateComponent-module__itemTitle--3p986 {\n  color: #9a9ca3;\n  font-size: 12px;\n  text-transform: uppercase;\n  text-align: left;\n  font-weight: 600;\n}\n.BusinessEventCreateComponent-module__itemValue--1KwMq > div {\n  margin-bottom: 24px;\n}\n.BusinessEventCreateComponent-module__itemInput--1yV94 {\n  font-size: 13px;\n  color: #3d404e;\n  margin-left: 20px;\n}\n.BusinessEventCreateComponent-module__itemInput--1yV94 > div {\n  margin-bottom: 0;\n}\n.BusinessEventCreateComponent-module__itemHint--1AlkR,\n.BusinessEventCreateComponent-module__itemHint--1AlkR > h2 {\n  font-size: 12px !important;\n  color: #787878;\n}\n.BusinessEventCreateComponent-module__loadingSpin--25ASM {\n  height: 180px;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  padding-top: 10;\n  padding-bottom: 10;\n}\n.BusinessEventCreateComponent-module__removeIcon--2UW0A {\n  font-size: 28px;\n  margin-bottom: 24px;\n  position: absolute;\n  left: 130px;\n  top: 2px;\n}\n", ""]);

// exports
exports.locals = {
	"businessEvent": "BusinessEventCreateComponent-module__businessEvent--v_wPR",
	"header": "BusinessEventCreateComponent-module__header--1nB2J",
	"headerTitle": "BusinessEventCreateComponent-module__headerTitle--3un7b",
	"item": "BusinessEventCreateComponent-module__item--dezzK",
	"itemTitle": "BusinessEventCreateComponent-module__itemTitle--3p986",
	"itemValue": "BusinessEventCreateComponent-module__itemValue--1KwMq",
	"itemInput": "BusinessEventCreateComponent-module__itemInput--1yV94",
	"itemHint": "BusinessEventCreateComponent-module__itemHint--1AlkR",
	"loadingSpin": "BusinessEventCreateComponent-module__loadingSpin--25ASM",
	"removeIcon": "BusinessEventCreateComponent-module__removeIcon--2UW0A"
};