import UserViewComponent from '../component/UserViewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userViewFetch, userChangeCurrent } from '../UserActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentUser: state.admin.user.currentUser,
  loadingUser: state.admin.user.loadingUser,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userViewFetch,
      userChangeCurrent,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserViewComponent);

UserViewComponent.propTypes = {
  currentUser: PropTypes.object.isRequired,
  userViewFetch: PropTypes.func.isRequired,
  userChangeCurrent: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
