import React from 'react';
import { Table, Alert, Tooltip, Tag, Select } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './uploadUserData.module.less';

export default function FilePreview({
  data,
  isValid,
  errorMessage,
  handleChangeAttrTypeChange,
  filePath,
}) {
  if (!filePath && !data && !isValid && !errorMessage) {
    return null;
  }

  const columns = [
    {
      title: 'ATTRIBUTE',
      dataIndex: 'attribute',
      key: 'attribute',
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'EXISTING DATA TYPE',
      dataIndex: 'sourceType',
      key: 'sourceType',
    },
    {
      title: 'UPLOADED DATA TYPE',
      dataIndex: 'valueType',
      key: 'valueType',
      render: (text, record) => {
        let options = ['STRING', 'DATE', 'NUMERIC', 'BOOLEAN'];
        if (record.type === 'New') {
          options = record.valueType;
        }
        return (
          <>
            {record.type === 'New' && record.attribute !== 'eventName' ? (
              <Select
                defaultValue={options[0]}
                style={{ width: 120 }}
                onChange={(value) => {
                  handleChangeAttrTypeChange(record.attribute, value);
                }}
              >
                {options.map((opt) => (
                  <Select.Option key={opt} value={opt}>
                    {opt}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <span>{text}</span>
            )}
          </>
        );
      },
    },
    {
      title: 'ROW 2 VALUE',
      dataIndex: 'value',
      key: 'value',
      render: (value) => {
        return (
          <span
            style={{
              display: 'block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
            }}
            title={value}
          >
            {value}
          </span>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'error',
      key: 'error',
      render: (value) => {
        return (
          <>
            {!value ? (
              <Tag color="success">OK</Tag>
            ) : (
              <Tooltip placement="bottom" title={value}>
                <Tag icon={<ExclamationCircleOutlined />} color="error">
                  Error
                </Tag>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];
  return (
    <div className={styles.root} style={{ marginBottom: '30px' }}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          {filePath ? 'Failure details' : 'Preview'}
        </div>
      </div>
      <div className={styles.content}>
        {filePath ? (
          <>
            <Alert
              message="Error on multiple data rows. Use message under we_errors column to resolve each row."
              type="error"
              showIcon
            />
            <br />
            <p>
              We have added an extra value at the end of each row of your CSV
              file. This value denotes the errors, wherever present, on that
              row.
            </p>
            <a href={filePath} download className={styles.contentButton}>
              DOWNLOAD CSV FILE
            </a>
          </>
        ) : (
          <>
            {!isValid ? (
              <>
                <Alert
                  message={errorMessage || 'Error'}
                  type="error"
                  showIcon
                />
              </>
            ) : (
              <Alert
                message="Good to go! Preview based on first row of data."
                type="success"
                showIcon
              />
            )}
            <br />

            {data && data.length ? (
              <Table pagination={false} dataSource={data} columns={columns} />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}
