import * as React from 'react';

function SvgErrorHover() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18.222"
      viewBox="0 0 17.034 15.222"
    >
      <g>
        <path
          fill="#fee8e8"
          stroke="#e33a2e"
          d="M15.82 12.222L9.155.667a1.334 1.334 0 0 0-2.31 0L.18 12.222a1.334 1.334 0 0 0 1.155 2h13.33a1.334 1.334 0 0 0 1.155-2z"
          transform="translate(-1061.483 -364.389) translate(1062 364.889)"
        />
        <path
          fill="#f61d1d"
          d="M8.62 5.333H7.38a.444.444 0 0 0-.442.489l.356 3.556a.444.444 0 0 0 .442.4h.528a.444.444 0 0 0 .442-.4l.356-3.556a.444.444 0 0 0-.441-.489zM8 10.667a.889.889 0 1 0 .889.889.889.889 0 0 0-.889-.889z"
          transform="translate(-1061.483 -364.389) translate(1062 364.889)"
        />
      </g>
    </svg>
  );
}

export default SvgErrorHover;
