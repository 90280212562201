import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import FormBase from '../../../../component/form/FormBase';
import { passwordValidation } from '@Utils/PasswordValidation';
import Card from '@Utils/Card';

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    display: 'flex',
    color: '#9a9ca3',
    fontSize: 14,
    margin: '15px 10px',
  },
  description: {
    borderRadius: 10,
    backgroundColor: '#efefef',
    padding: '10px 40px',
    color: '#9a9ca3',
    marginBottom: 10,
  },
};
class UserChangePasswordComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId:
        this.props.pathname.includes('changePassword') &&
        this.props.pathname.split('changePassword/').pop(),
    };
  }
  componentDidMount() {
    this.props.userChangePasswordFetch(this.state.userId);
  }

  componentWillUnmount() {
    this.props.userChangeCurrent({});
  }

  onFinish() {
    this.props.userChangePasswordSubmitFetch(
      this.state.userId,
      this.props.currentUser
    );
  }
  render() {
    return (
      <div>
        <Card
          styles={{
            padding: 30,
          }}
        >
          <FormBase
            errors={this.props.errors}
            value={this.props.currentUser}
            onFinish={(values) => this.onFinish(values)}
            onChange={(values) => this.props.userChangeCurrent(values)}
            hideSubmit
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{ ...styles.title, width: '70px', marginRight: '20px' }}
              >
                NAME:
              </div>
              <Form.Item style={{ width: '400px' }} name="presentation">
                <Input disabled={true} />
              </Form.Item>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {' '}
              <div
                style={{ ...styles.title, width: '70px', marginRight: '20px' }}
              >
                EMAIL:
              </div>
              <Form.Item style={{ width: '400px' }} name="email">
                <Input disabled={true} />
              </Form.Item>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {' '}
              <div
                style={{ ...styles.title, width: '70px', marginRight: '20px' }}
              >
                PHONE:
              </div>{' '}
              <Form.Item style={{ width: '400px' }} name="phone">
                <Input disabled={true} />
              </Form.Item>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {' '}
              <div
                style={{ ...styles.title, width: '70px', marginRight: '20px' }}
              >
                PASSWORD:
              </div>
              <Form.Item
                validateStatus={
                  passwordValidation(this.props.currentUser.inputPassword)
                    ? 'error'
                    : null
                }
                help={passwordValidation(this.props.currentUser.inputPassword)}
                style={{ width: '400px' }}
                name="inputPassword"
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>
            </div>
          </FormBase>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to={`../view/${this.state.userId}`}>
              <Button
                type="primary"
                shape="round"
                size={'large'}
                style={{ margin: '0px 5px' }}
                className="w-200"
              >
                {'Back'}
              </Button>
            </Link>
            <Button
              type="primary"
              loading={this.props.loadingChangePassSubmit}
              onClick={(values) => this.onFinish(values)}
              disabled={
                !!passwordValidation(this.props.currentUser.inputPassword)
              }
              shape="round"
              size={'large'}
              style={{ margin: '0px 5px' }}
              className="w-200"
            >
              {'Submit'}
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default UserChangePasswordComponent;
