import React, { useMemo, useEffect } from 'react';
import { Form, Input, Col, Row, Button, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import vars from '@Theme/styles/vars';
const { Option, OptGroup } = Select;

const selectFilterOption = (input, option) => {
  if (option.children) {
    return (
      option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  }
};

const IN_APP_SYSTEM_EVENTS = [
  '[INT]_app_crashed',
  '[INT]_app_installed',
  '[INT]_fcm_registered',
  // '[INT]_push_notification_received',
  // '[INT]_push_notification_click',
  '[INT]_IN_APP_DELIVERED',
  '[INT]_IN_APP_VIEW_FAILED',
  '[INT]_IN_APP_CLICK',
  '[INT]_IN_APP_CLOSED',
  '[INT]_IN_APP_VIEWED',
];

const ON_SITE_SYSTEM_EVENTS = [
  '[INT]_ON_SITE_DELIVERED',
  '[INT]_ON_SITE_VIEW_FAILED',
  '[INT]_ON_SITE_CLICK',
  '[INT]_ON_SITE_CLOSED',
  '[INT]_ON_SITE_VIEWED',
  // '[INT]_web_push_click',
  // '[INT]_web_push_received',
  '[INT]_web_push_registered',
];

export default function EventsMapper({ channel, form, eventList }) {
  useEffect(() => {
    form.validateFields();
  }, []);
  const userEventOptions = useMemo(() => {
    const options = [];
    if (!eventList) return options;
    const channelSpecificEvents =
      channel === 'IN_APP' ? IN_APP_SYSTEM_EVENTS : ON_SITE_SYSTEM_EVENTS;
    options.push({
      groupLabel: 'SYSTEM',
      list: eventList.filter(
        (e) =>
          e.system &&
          [
            '[INT]_login',
            '[INT]_logout',
            '[INT]_begin_session',
            ...channelSpecificEvents,
          ].includes(e.name)
      ),
    });
    options.push({
      groupLabel: 'CUSTOM',
      list: eventList.filter((e) => !e.system),
    });
    return options;
  }, [eventList]);

  return (
    <div className="relay-mapper-area" style={{ marginLeft: 0 }}>
      <Form.Item name="eventsMapperConjunction" style={{ display: 'none' }}>
        <Input defaultValue={'OR'} />
      </Form.Item>
      <Form.List name="eventsMapper">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, idx) => (
              <>
                <Row key={field.key} gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'value']}
                      rules={[
                        { required: true, message: 'please select an event' },
                      ]}
                    >
                      <Select
                        // onChange={(eventId) => {
                        //   const formValues = form.getFieldsValue();
                        //   formValues.eventAttrs = [
                        //     {
                        //       businessAttr: null,
                        //       operator: null,
                        //       sourceAttr: null,
                        //       negate: false,
                        //     },
                        //   ];
                        //   formValues.eventAttrsConjunction = 'AND';
                        //   form.setFieldsValue(formValues);
                        //   setUserEventTerms({
                        //     event: eventId,
                        //     predicates: [],
                        //     type: 'BEHAVIOUR',
                        //     conjunction: 'AND',
                        //   });
                        // }}
                        placeholder="Select An Event"
                        showSearch
                        filterOption={selectFilterOption}
                      >
                        {userEventOptions?.map((group) => (
                          <OptGroup
                            key={group.groupLabel}
                            label={group.groupLabel}
                          >
                            {group.list.map((e) => (
                              <Option key={e.id} value={e.name}>
                                {e.title}
                              </Option>
                            ))}
                          </OptGroup>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* <Col span={2}>
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) => {
                        return !prevValues?.userEvent && curValues?.userEvent;
                      }}
                    >
                      {() => {
                        return (
                          <Badge
                            // count={userEventTerms?.predicates?.length || 0}
                            showZero={false}
                          >
                            <div
                              onClick={() => {
                                // if (userEventTerms?.event) {
                                //   setShowEventFilter('USER');
                                // }
                              }}
                              className="fl-filter fl-dark"
                              // title={
                              //   userEventTerms?.event
                              //     ? 'please select an event first'
                              //     : 'filter selected event by its attribute'
                              // }
                              style={{
                                fontSize: 28,
                                // cursor: userEventTerms?.event
                                //   ? 'pointer'
                                //   : 'not-allowed',
                              }}
                            ></div>
                          </Badge>
                        );
                      }}
                    </Form.Item>
                  </Col> */}
                  {fields.length > 1 ? (
                    <Col span="2">
                      <MinusCircleOutlined
                        style={{ marginTop: '10px' }}
                        onClick={() => remove(field.name)}
                      />
                    </Col>
                  ) : null}
                </Row>
                {idx < fields.length - 1 ? (
                  <Row>
                    <Col span="24">
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => {
                          return (
                            prevValues.eventsMapperConjunction !==
                            curValues.eventsMapperConjunction
                          );
                        }}
                      >
                        {() => {
                          const formValues = form.getFieldsValue();
                          const conjunction =
                            formValues.eventsMapperConjunction;
                          return (
                            <div
                              style={{ position: 'relative', width: '100%' }}
                            >
                              <div
                                style={{
                                  border: '1px dashed #E9E9E9',
                                  position: 'absolute',
                                  width: '100%',
                                  top: '50%',
                                }}
                              />
                              <Button
                                onClick={() => {
                                  // formValues.eventsMapperConjunction =
                                  //   conjunction === 'AND' ? 'OR' : 'AND';
                                  // form.setFieldsValue(formValues);
                                }}
                                style={{
                                  color: '#FFF',
                                  backgroundColor:
                                    conjunction === 'AND'
                                      ? vars.btnOrOpration
                                      : vars.primaryColor,
                                  opacity: 1,
                                }}
                              >
                                {conjunction}
                              </Button>
                            </div>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}
              </>
            ))}

            <Row>
              <Col span="24">
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => {
                    return (
                      prevValues.eventsMapperConjunction !==
                      curValues.eventsMapperConjunction
                    );
                  }}
                >
                  {() => {
                    const formValues = form.getFieldsValue();
                    const conjunction = formValues.eventsMapperConjunction;

                    return (
                      <Form.Item>
                        <div
                          style={{
                            border: '1px dashed #E9E9E9',
                            position: 'absolute',
                            width: '100%',
                            top: '50%',
                          }}
                        />
                        <Button
                          onClick={() => {
                            add({
                              negate: false,
                              operator: 'EQUAL_TO',
                              term: 'EVENT_NAME',
                              value: null,
                            });
                            setTimeout(() => {
                              form.validateFields();
                            }, 500);
                          }}
                          icon={<PlusOutlined />}
                          style={{
                            border: 'solid 1px',
                            borderColor:
                              conjunction === 'AND'
                                ? vars.btnOrOpration
                                : vars.primaryColor,
                            color:
                              conjunction === 'AND'
                                ? vars.btnOrOpration
                                : vars.primaryColor,
                            backgroundColor: '#FFFFFF',
                            opacity: 1,
                          }}
                        >
                          Add Mapping
                        </Button>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </div>
  );
}
