import React from 'react';
import { useHistory } from 'react-router-dom';
import ActionMenu from '../../../layout/header/component/ActionMenu.js';

export default function FunnelReportHeaderActions(props) {
  const history = useHistory();
  const pathname = history.location.pathname;
  const id = pathname.slice(
    pathname.indexOf('funnels/') + 8,
    pathname.indexOf('/overview')
  );

  const result = [];
  const items = [
    {
      key: 'edit',
      title: 'Edit',
      isPrimary: true,
      onClick: function () {
        history.push('./edit');
      },
      enabled: !props.executing,
    },
    {
      key: 'force-execute',
      title: 'Force Execute',
      isPrimary: false,
      onClick: () => {
        props.executionParams.funnelId = id;
        props.forceExecuteFunnel(props.executionParams);
      },
      enabled: !props.executing,
      disablePopConfirm: true,
    },
    {
      key: 'delete',
      title: 'Delete',
      isPrimary: false,
      action: () =>
        props.deleteFunnel(id, () => {
          history.push('../list');
        }),
      enabled: !props.executing,
    },
  ];
  result.push(<ActionMenu actions={items}></ActionMenu>);
  return <span style={{ position: 'relative', top: 2 }}>{result}</span>;
}
