// import { InfoCircleOutlined } from '@ant-design/icons';
import { calcMinuteTime } from '@Component/journey/utils/hooks';
import {
  Card,
  Col,
  Form,
  Row,
  Select,
  // Tooltip
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { convertMinute } from '../../../../utils/DateUtils';
import { AppContext } from '../../app';
import {
  delayTypeOptions,
  delayTypeOptionsSegmentStep,
  GapBaseOptions,
} from '../../utils/static';
import { TimeDelayComponent } from '../ModalContent/FlowWaitForDate';
import './styles.less';

const styles = {
  title: {
    color: '#a7a7a7',
    textAlign: 'right',
    paddingRight: '25px',
    // paddingTop: '5px'
  },
  input: {
    width: '100px',
    margin: '0 5px',
  },
  row: {
    // margin: '2% 0',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    lineHeight: '2.5em',
    // backgroundColor: "red"
    // border: "1px solid #eee"
  },
  select: {
    margin: '0 5px',
    minWidth: '100px',
  },
  radioGroup: {},
  formItem: {
    display: 'inline-block',
    margin: '10px',
    // marginBottom: "7px", marginRight: "7px"
  },
};

const ANALYTIC_INT_INFO = [
  {
    name: 'Known Users',
    value: '0',
  },
  {
    name: 'Unknown Users',
    value: '0',
  },
  {
    name: 'Reachable Users',
    value: '0',
  },
  {
    name: 'UnReachable Users',
    value: '0',
  },
];

const SegmentStep = (props) => {
  const formRef = React.createRef();
  const { segments } = props.data;
  const { journey, syncCache } = useContext(AppContext);
  const [analyticInfo, setAnalyticInfo] = useState(ANALYTIC_INT_INFO);
  const [disableGapBase, setDisableGapBase] = useState(false);
  let segmentsFiltered =
    segments &&
    segments.filter((seg) => !seg.test && seg.status !== 'ARCHIVED');

  const { segmentReportViewContext } = useContext(AppContext);
  const segmentData = segmentReportViewContext?.data;
  const foundItem = (segments || []).find((seg) => seg.id === journey?.segment);

  useEffect(() => {
    if (props?.currentStep === 'segment' && journey?.segment) {
      syncCache('update-segment', journey?.segment);
      props.getSegment(journey?.segment);
    }
  }, [journey?.segment, props?.currentStep]);

  useEffect(() => {
    if (
      !journey?.gapType ||
      journey?.gapType === delayTypeOptionsSegmentStep[0].value ||
      journey?.gapType === delayTypeOptionsSegmentStep[1].value
    ) {
      setDisableGapBase(true);
    } else {
      setDisableGapBase(false);
    }
  }, [journey?.gapType]);

  useEffect(() => {
    const reachableUsers =
      (segmentData?.knownUsersCount || 0) +
      (segmentData?.unknownUsersCount || 0) -
      (segmentData?.unreachableUsersCount || 0);
    let analytic_ = [...ANALYTIC_INT_INFO];
    analytic_[0].value = segmentData?.knownUsersCount || 0;
    analytic_[1].value = segmentData?.unknownUsersCount || 0;
    analytic_[2].value = reachableUsers || 0;
    analytic_[3].value = segmentData?.unreachableUsersCount || 0;
    setAnalyticInfo(analytic_);
  }, [segmentData]);

  const onSelectChange = (value) => {
    if (value) {
      const segment = (segmentsFiltered || []).find((seg) => seg.id === value);
      syncCache('update-segment', segment.id);
      props.getSegment(segment.id);
    } else {
      syncCache('update-segment', null);
    }
  };

  const getGapTime = (e) => {
    if (e.delayType !== delayTypeOptions[0].value)
      return calcMinuteTime(e.delayFactor, e.delayConst);
    return 0;
  };

  const onFieldsChange = () => {
    const fields = formRef.current.getFieldsValue();
    let data_ = {};
    if (fields['delayType'] === delayTypeOptionsSegmentStep[0].value) {
      formRef.current.setFieldsValue({ gapBase: undefined });
      formRef.current.setFieldsValue({ delayFactor: undefined });
      data_['gapMinutes'] = undefined;
      data_['gapBase'] = undefined;
      setDisableGapBase(true);
    } else if (fields['delayType'] === delayTypeOptionsSegmentStep[1].value) {
      formRef.current.setFieldsValue({ gapBase: undefined });
      formRef.current.setFieldsValue({ delayFactor: undefined });
      data_['gapMinutes'] = 0;
      data_['gapBase'] = undefined;
      setDisableGapBase(false);
    } else if (fields['delayType'] === delayTypeOptionsSegmentStep[2].value) {
      setDisableGapBase(false);
      if (!fields['gapBase'] && !fields['delayFactor']) {
        formRef.current.setFieldsValue({ gapBase: 'START' });
        formRef.current.setFieldsValue({ delayFactor: undefined });
        data_['gapMinutes'] = 60 * 24;
        if (!fields['gapBase']) {
          data_['gapBase'] = 'START';
        }
      }
      if (fields['gapBase']) {
        data_['gapBase'] = fields['gapBase'];
      }
      data_['gapMinutes'] = getGapTime(fields);
    }

    if (fields['delayType']) {
      data_['gapType'] = fields['delayType'];
    }
    syncCache('group-update', data_);
  };
  if (journey?.segment) {
    if (foundItem?.status === 'ARCHIVED') {
      segmentsFiltered.push(foundItem);
    }
  }
  const archiveHint =
    foundItem?.status === 'ARCHIVED' ? (
      <span
        style={{
          fontSize: 10,
          color: 'red',
          paddingLeft: '5px',
          paddingTop: '5px',
          display: 'block',
        }}
      >
        {'This segment has been archived!'}
      </span>
    ) : (
      ''
    );

  const time_d = convertMinute(journey?.gapMinutes);

  return (
    props?.currentStep === 'segment' && (
      <div>
        {!props?.readOnly && (
          <Row>
            <Col span={15} offset={1}>
              <Card title="Segment" bordered={false}>
                <Select
                  showSearch
                  style={{ width: 300, marginRight: '15px' }}
                  placeholder="Select a segment"
                  onChange={onSelectChange}
                  defaultValue={journey?.segment}
                  filterOption={(input, option) => {
                    if (option.label) {
                      return (
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  <Select.Option value={null} key={null}>
                    All Users
                  </Select.Option>
                  {segmentsFiltered?.map((item) => (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                      label={item.name}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                {/* <Tooltip
                  placement="bottom"
                  title={`
              If journey has started by "Enter/Exit Trigger" or "For Special Users" node,
               the target segment in running state of journey will be share of chosen segment in trigger node and this segment.`}
                >
                  <InfoCircleOutlined />
                </Tooltip> */}
                {archiveHint}
              </Card>

              <Card
                title={`Gap Between ${props.type.toLowerCase()}`}
                bordered={false}
                style={{ marginTop: '10px', textTransform: 'capitalize' }}
              >
                <Form
                  ref={formRef}
                  className="modal_wrap"
                  initialValues={{
                    delayType:
                      journey.gapType || delayTypeOptionsSegmentStep[0].value,
                    delayConst: time_d.type,
                    delayFactor: time_d.value,
                    gapBase: journey.gapBase,
                  }}
                  onFieldsChange={onFieldsChange}
                >
                  <div className="display-flex flex-wrap align-item-center">
                    <span>
                      Let the exiting user enter the {props.type.toLowerCase()}
                      &nbsp;
                    </span>
                    <TimeDelayComponent
                      {...props}
                      delayTypeOptions={delayTypeOptionsSegmentStep}
                      AvailableSelectTime={[
                        delayTypeOptionsSegmentStep[2].value,
                      ]}
                      FormItemStyle={styles.formItem}
                    />
                    <Form.Item
                      name="gapBase"
                      style={{ display: 'inline-block', marginBottom: '0px' }}
                    >
                      <Select
                        style={styles.select}
                        disabled={props?.readOnly || disableGapBase}
                        placeholder={'Select Base'}
                      >
                        {GapBaseOptions.map((option, i) => (
                          <Select.Option key={i} value={option.value}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Form>
              </Card>
            </Col>
            <Col span={5} offset={1}>
              <Card bordered={false}>
                {analyticInfo.map((item, i) => (
                  <div className={'segment-analytic-item'} key={i}>
                    <h3>{item.value}</h3>
                    <p>{item.name} </p>
                  </div>
                ))}
              </Card>
            </Col>
          </Row>
        )}
        {props?.readOnly && (
          <Row>
            Selected Segment is : {!journey.segment ? 'All Users' : ''}
            {journey?.segment &&
              segments?.find((m) => m.id === journey?.segment)?.name}
          </Row>
        )}
      </div>
    )
  );
};

export default SegmentStep;
