export const CONFIGURATION_CHANGE_CURRENT = 'CONFIGURATION_CHANGE_CURRENT';

export const CONFIGURATION_UPDATE_FETCH = 'CONFIGURATION_UPDATE_FETCH';
export const CONFIGURATION_UPDATE_FETCH_FAIL =
  'CONFIGURATION_UPDATE_FETCH_FAIL';
export const CONFIGURATION_UPDATE_FETCH_SUCCESS =
  'CONFIGURATION_UPDATE_FETCH_SUCCESS';

export const CONFIGURATION_UPDATE_SUBMIT_FETCH =
  'CONFIGURATION_UPDATE_SUBMIT_FETCH';
export const CONFIGURATION_UPDATE_SUBMIT_FETCH_FAIL =
  'CONFIGURATION_UPDATE_SUBMIT_FETCH_FAIL';
export const CONFIGURATION_UPDATE_SUBMIT_FETCH_SUCCESS =
  'CONFIGURATION_UPDATE_SUBMIT_FETCH_SUCCESS';

export const CONFIGURATION_VIEW_FETCH = 'CONFIGURATION_VIEW_FETCH';
export const CONFIGURATION_VIEW_FETCH_FAIL = 'CONFIGURATION_VIEW_FETCH_FAIL';
export const CONFIGURATION_VIEW_FETCH_SUCCESS =
  'CONFIGURATION_VIEW_FETCH_SUCCESS';

export const CONFIGURATION_LIST_ATTRIBUTES_FETCH_SUCCESS =
  'CONFIGURATION_LIST_ATTRIBUTES_FETCH_SUCCESS';
export const CONFIGURATION_LIST_ATTRIBUTES_FETCH_FAIL =
  'CONFIGURATION_LIST_ATTRIBUTES_FETCH_FAIL';
export const CONFIGURATION_LIST_ATTRIBUTES_FETCH =
  'CONFIGURATION_LIST_ATTRIBUTES_FETCH';
