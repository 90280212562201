import BaseApi from '../../../api/BaseApi';

const basePath = '/cohort';
const executeBasePath = '/cohort-execution';

export default class CohortApi {
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static create(body) {
    return BaseApi.post(basePath + '/', body);
  }
  static update(id, body) {
    return BaseApi.put(`${basePath}/${id}`, body);
  }
  static view(id) {
    return BaseApi.get(`${basePath}/${id}`);
  }
  static delete(id) {
    return BaseApi.delete(`${basePath}/${id}`);
  }
  static execute(body) {
    return BaseApi.post(executeBasePath, body);
  }
  static viewExecution(id) {
    return BaseApi.get(`${executeBasePath}/${id}`);
  }
  static forceExecute(body) {
    return BaseApi.post(`${executeBasePath}/force`, body);
  }
  static stopExecute(id) {
    return BaseApi.get(`${executeBasePath}/stop/${id}`);
  }
  static lastExecution(id) {
    return BaseApi.get(`${executeBasePath}/last/${id}`);
  }
}
