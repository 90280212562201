import { combineReducers } from 'redux';
import auth from '@Modules/account/auth/AuthState';
import profile from '@Modules/account/profile/ProfileState';
import backOffice from './backOfficeInvoiceSlice';
const reducer = combineReducers({
  auth,
  profile,
  backOffice,
});

export default reducer;
