import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const exportElementToPdf = (printContentId) => {
  const elementToPrint = document.getElementById(printContentId); // Replace with your ID
  html2canvas(elementToPrint, { allowTaint: true }) // Allow cross-origin images
    .then((canvas) => {
      const image = canvas.toDataURL('image/png'); // Convert to PNG data URL

      const imgData = new Image();
      imgData.src = image;

      imgData.onload = () => {
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'px', // Optional: Set unit for measurements
          format: [elementToPrint.clientWidth, elementToPrint.clientHeight], // Set PDF size to match element
        });

        const aspectRatio = imgData.width / imgData.height;

        // Calculate the width and height to fit the image to the page
        let imgWidth = pdf.internal.pageSize.getWidth();
        let imgHeight = imgWidth / aspectRatio;

        if (imgHeight > pdf.internal.pageSize.getHeight()) {
          imgHeight = pdf.internal.pageSize.getHeight();
          imgWidth = imgHeight * aspectRatio;
        }

        // Calculate positioning to center the image
        const xPos = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
        const yPos = (pdf.internal.pageSize.getHeight() - imgHeight) / 2;

        pdf.addImage(imgData, 'PNG', xPos, yPos, imgWidth, imgHeight); // Add image to PDF
        pdf.save('dashboardReport.pdf'); // Download the PDF
      };
    })
    .catch((error) => {
      console.error('Error capturing element:', error);
    });
};
