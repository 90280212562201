import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { viewDataMetric, viewDataTime } from './cohortReportConstants';
import React, { useEffect, useState } from 'react';
import DataModelSettingCard from './DataModelSettingCard';
import { useDispatch, useSelector } from 'react-redux';
import { EllipsisOutlined } from '@ant-design/icons';
import { calculateNumberOfColumns } from '../helper';
import { Button, Card, Popover, Table } from 'antd';
import { percentage } from '@Utils/RenderUtils';
import Loading from '../components/Loading';
import HeaderTitles from './HeaderTitles';
import './cohortReport.less';

import {
  setCohortExecutionReport,
  setCohortExecutionConfig,
  setExecutingId,
  getCohortLastExecution,
} from '@Redux/slices/analytics/cohortSlice';

export default function CohortReport() {
  const { cohortId } = useParams();
  const dispatch = useDispatch();

  const executing = useSelector((state) => state.analytics.cohort.executing);
  const loading = useSelector((state) => state.analytics.cohort.loading);
  const loadingExecution = useSelector(
    (state) => state.analytics.cohort.loadingExecution
  );
  const cohortExecutionReport = useSelector(
    (state) => state.analytics.cohort.cohortExecutionReport
  );
  const cohortExecutionConfig = useSelector(
    (state) => state.analytics.cohort.cohortExecutionConfig
  );
  const loadingTable = useSelector(
    (state) => state.analytics.cohort.loadingTable
  );

  const [dataSettingCardVisible, setDataSettingCardVisible] = useState(false);
  const [numberOfColumns, setNumberOfColumns] = useState();
  const [dataSource, setDataSource] = useState();
  const [columns, setColumns] = useState([]);
  const [dataRanges, setDataRanges] = useState([]);
  const [cohortDataMetric, setCohortDataMetric] = useState(
    viewDataMetric[1].value
  );

  useEffect(() => {
    dispatch(getCohortLastExecution(cohortId));

    return () => {
      dispatch(setExecutingId(null));
      dispatch(setCohortExecutionReport(null));
      dispatch(setCohortExecutionConfig({}));
    };
  }, [cohortId]);

  useEffect(() => {
    let numberOfColumns = calculateNumberOfColumns({
      startDate: cohortExecutionConfig?.fromDate,
      endDate: cohortExecutionConfig?.toDate,
      timeFrame: cohortExecutionConfig?.cohortDataTimeFrame,
    });
    setNumberOfColumns(numberOfColumns);
    const maxCount = getMaxCount(cohortExecutionReport);
    if (maxCount) {
      setDataRanges(getThreeRanges(maxCount));
    }
  }, [loadingExecution, cohortExecutionReport]);

  useEffect(() => {
    handleTableColumns(numberOfColumns);
    handleTableRows(numberOfColumns);
  }, [cohortExecutionReport, cohortDataMetric, numberOfColumns, dataRanges]);

  const getMaxCount = (data) => {
    let maxCount = 0;

    data?.result.tables.forEach((table) => {
      table.rows.forEach((row) => {
        row.executionCells.forEach((cell) => {
          if (cell.count > maxCount) {
            maxCount = cell.count;
          }
        });
      });
    });

    return maxCount;
  };

  const getThreeRanges = (maxCount) => {
    const rangeSize = Math.ceil(maxCount / 3);
    const ranges = [
      { min: 0, max: rangeSize - 1 },
      { min: rangeSize, max: 2 * rangeSize - 1 },
      { min: 2 * rangeSize, max: maxCount },
    ];

    return ranges;
  };

  const renderTimeFrameTitle = (timeFrame, index) => {
    let temp = viewDataTime.find((ele) => ele.value === timeFrame);
    return `${temp?.title} ${index}`;
  };

  const handleTableColumns = (numberOfColumns) => {
    let tempTimeFrameColumns = [];
    for (let i = 0; i < numberOfColumns; i++) {
      tempTimeFrameColumns.push({
        title: renderTimeFrameTitle(
          cohortExecutionConfig.cohortDataTimeFrame,
          i
        ),
        dataIndex: `date${i}`,
        // width: '100px',
        render: (value) => {
          return {
            props: {
              className: `cell-wrapper ${
                value || value === 0
                  ? 'data-cell-range1'
                  : cohortExecutionReport?.result?.tables?.length > 0
                  ? 'empty-cell'
                  : ''
              }`,
            },
            children: <div className="cohort-cell">{value}</div>,
          };
        },
      });
    }
    const columns = [
      {
        title: (
          <div style={{ color: 'rgba(61, 64, 78, 0.55)', textAlign: 'left' }}>
            first event Occurrence
          </div>
        ),
        dataIndex: 'mainHeader',
        children: [
          {
            title: 'Date',
            key: '',
            dataIndex: 'dateColumn',
            width: '200px',
          },
          {
            title: 'Total Users',
            key: 'totalUsersCount',
            dataIndex: 'totalUsersCount',
            width: '200px',
          },
        ],
        colSpan: 2,
      },
      {
        title: (
          <div style={{ color: 'rgba(61, 64, 78, 0.55)', textAlign: 'left' }}>
            Users who did return event
          </div>
        ),
        children: tempTimeFrameColumns,
      },
    ];

    setColumns(columns);
  };

  const renderCellClassNames = (value) => {
    if (dataRanges.length) {
      if (value >= dataRanges[2].min) {
        return 'data-cell-range3';
      } else if (value >= dataRanges[1].min && value <= dataRanges[1].max) {
        return 'data-cell-range2';
      } else {
        return 'data-cell-range1';
      }
    }
    return 'data-cell-range1';
  };

  const renderUserCountValue = (count, totalUserCount) => {
    const backgroundClass = renderCellClassNames(count);
    return (
      <>
        {cohortDataMetric === 'number' ? (
          <div className={`cohort-cell common-value-cell ${backgroundClass}`}>
            {count}
          </div>
        ) : (
          <div className={`cohort-cell common-value-cell ${backgroundClass}`}>
            {percentage(count, totalUserCount, true) + '%'}
          </div>
        )}
      </>
    );
  };

  const handleSettingRows = ({ rows, key, numberOfColumns }) => {
    let tempRows = rows.map((row, index) => {
      let temp = {};
      for (let i = 0; i < numberOfColumns - index; i++) {
        temp = {
          ...temp,
          [`date${i}`]: 0,
        };
      }

      row?.executionCells?.forEach((element) => {
        temp = {
          ...temp,
          [`date${element.returnEventOccurrence.index}`]: renderUserCountValue(
            element.count,
            row?.totalUserCount
          ),
        };
      });

      return {
        key: `${Math.random()}${key}${index}`,
        dateColumn:
          cohortExecutionConfig.cohortDataTimeFrame === 'DAY'
            ? `${row?.intervalStartDate}`
            : `${row?.intervalStartDate} to ${row?.intervalEndDate}`,
        totalUsersCount: row?.totalUserCount,
        ...temp,
      };
    });

    return tempRows;
  };

  const handleTableRows = (numberOfColumns) => {
    let tempDataSource = [];
    if (cohortExecutionReport?.result?.tables?.length > 1) {
      cohortExecutionReport?.result?.tables?.forEach((element, index) => {
        tempDataSource.push({
          key: index,
          dateColumn:
            element?.splitByAttribute !== ''
              ? element?.splitByAttribute
              : 'Others',
          children: handleSettingRows({
            rows: element?.rows,
            key: element?.splitByAttribute,
            numberOfColumns,
          }),
        });
      });
    } else if (cohortExecutionReport?.result?.tables?.length === 1) {
      tempDataSource = handleSettingRows({
        rows: cohortExecutionReport?.result?.tables?.[0]?.rows,
        numberOfColumns,
      });
    } else {
      tempDataSource = [{ dateColumn: '-', totalUsersCount: '-' }];
    }

    setDataSource(tempDataSource);
  };

  return (
    <>
      {loading ? (
        <Card>
          <Loading />
        </Card>
      ) : (
        <Card
          size="small"
          className="report-card-wrapper"
          title={<HeaderTitles />}
          extra={
            <Popover
              content={
                <DataModelSettingCard
                  cohortDataMetric={cohortDataMetric}
                  setCohortDataMetric={setCohortDataMetric}
                />
              }
              trigger="click"
              visible={dataSettingCardVisible}
              onVisibleChange={setDataSettingCardVisible}
            >
              <Button type="text" onClick={() => {}}>
                <EllipsisOutlined className="ellipsisMenu" />
              </Button>
            </Popover>
          }
        >
          <Table
            id="cohort-table"
            columns={columns}
            dataSource={dataSource}
            bordered
            pagination={false}
            scroll={{ x: 'max-content' }}
            defaultExpandedRowKeys={[0]}
            loading={loadingTable || executing}
          />
        </Card>
      )}
    </>
  );
}
