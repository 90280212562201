import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import ListBase, { defaultActions } from '@Component/form/ListBase';
import { readableDateTime } from '@Utils/DateUtils';
import {
  numberWithCommas,
  renderLowerFont,
  showMessageError,
} from '@Utils/RenderUtils';
import { Input, Spin } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import styles from './ProductSegmentArchiveListComponent.module.less';
import vars from '@Theme/styles/vars.js';
import Card from '@Utils/Card';
import { history } from '../../../../redux/store';
import { getProductId } from '../../../../utils/AuthorityProvider';

export default function ProductSegmentArchiveListComponent(props) {
  const [name, setName] = useState('');
  const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
  const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;

  useEffect(() => {
    props.productSegmentListFetch({
      direction: 'DESC',
      order: 'modifiedDate',
      name: '',
      page: 0,
      status: ['ARCHIVED'],
      types:
        props.match.params.type === 'static'
          ? ['STATIC', 'STATIC_CSV']
          : ['DYNAMIC'],
    });
  }, [props.activeSuccess]);
  const renderProcessingBadge = (x, object) => {
    if (object.status && object.status === 'PROCESSING') {
      return (
        <span
          style={{
            backgroundColor: '#388cf2',
            marginLeft: 5,
            borderRadius: 5,
            color: 'white',
            fontSize: 10,
            padding: '2px 5px',
          }}
        >
          IN PROGRESS
        </span>
      );
    }
    return null;
  };
  const columns = [
    {
      title: 'segment name',
      width: 200,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (x, object) => {
        if (object.status && object.status === 'PROCESSING') {
          return (
            <div
              onClick={() => {
                {
                  showMessageError({
                    status: 'Error',
                    message: 'Please contact admin',
                  });
                }
              }}
            >
              <span
                style={{
                  color: vars.primaryColor,
                  fontSize: 14,
                  cursor: 'pointer',
                }}
              >
                {x}
              </span>
              {renderProcessingBadge(x, object)}
            </div>
          );
        } else {
          return (
            <a
              onClick={() => {
                const url = `${object.id}/overview`;

                history.push(url);
              }}
            >
              {x}
            </a>
          );
        }
      },
    },
    {
      title: 'user',
      width: 100,
      dataIndex: 'segmentSize',
      key: 'segmentSize',
      render: (x) => renderLowerFont(numberWithCommas(x)),
    },
    {
      title: 'communications',
      dataIndex: 'communications',
      key: 'communications',
      width: 150,
      render: (x) => {
        const pid = getProductId(location?.pathname);
        return (
          x &&
          x
            .map((c) => (
              <a
                key={`${pid}-${c.communicationId}-link`}
                target="_blank"
                rel="noopener noreferrer"
                href={`/product/${pid}/${c.channelType.toLowerCase()}-messages/campaigns/${
                  c.communicationId
                }/overview`}
              >
                {c.communicationId}
              </a>
            ))
            .reduce((acc, x) => (acc === null ? [x] : [acc, ', ', x]))
        );
      },
    },
    {
      title: 'journeys',
      dataIndex: 'journeys',
      key: 'journeys',
      width: 150,
      render: (x) => renderLowerFont(numberWithCommas(x)),
    },
    {
      title: 'created date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: 150,
      render: (x) => renderLowerFont(readableDateTime(x)),
    },
    {
      title: 'action',
      key: 'action',
      fixed: 'left',
      width: '5%',
      render: (x) => {
        return defaultActions(x, {
          action: (id) => props.productSegmentActivate(id),
          title: 'Active',
          enabled: true,
        });
      },
    },
  ];
  const handleChangeSearch = (name) => {
    setName(name);
    if (name === '' || name == null) {
      props.productSegmentListFetch({
        ...props.pageRequest,
        page: 0,
        name: null,
      });
    }
  };
  return (
    <div>
      <Card>
        <div className={styles.header}>
          <Input
            loading={true}
            allowClear={props.loadingSegmentSearchList ? false : true}
            placeholder={'Search by segment name'}
            onPressEnter={() =>
              props.productSearchSegmentListFetch({
                ...props.pageRequest,
                page: 0,
                name: name,
              })
            }
            onChange={(e) => handleChangeSearch(e.target.value)}
            value={name}
            suffix={
              isEmpty(name) ? (
                <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              ) : (
                props.loadingSegmentSearchList && (
                  <div className={styles.inputLoadingSpin}>
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                )
              )
            }
            className={styles.headerSearch}
          />
        </div>
        {props.loadingSegmentList ? (
          <div className={styles.loadingSpin}>
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <ListBase
            onChange={props.productSegmentListFetch}
            page={props.page}
            pageRequest={props.pageRequest}
            columns={columns}
          />
        )}
      </Card>
    </div>
  );
}
