import React from 'react';
import { history } from '../../../../redux/store';
import {
  AuthorityProvider,
  hasAnyAuthority,
} from '../../../../utils/AuthorityProvider';
import { allSteps } from '../../../../utils/CommunicationSteps';
import { isNil, find as lodashFind, findIndex } from 'lodash';
import { Button, Modal } from 'antd';
// import CommunicationDiscardModalComponent from './CommunicationDiscardModalComponent'
const styles = {
  footer: {
    position: 'fixed',
    bottom: 0,
    height: 60,
    width: '100%',
    padding: '10px 30px',
    background: 'white',
  },
};

class CommunicationFooterComponent extends React.Component {
  constructor(props) {
    super(props);
    const currentStep = this.props.step;
    this.steps = isNil(this.props.steps) ? allSteps : this.props.steps;
    this.step = currentStep;

    this.state = {
      isOpenModal: false,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.steps !== this.props.steps) {
      this.steps = isNil(this.props.steps) ? allSteps : this.props.steps;
    }
  }

  viewItem(id) {
    if (hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_ATTRIBUTE_VIEW)) {
      history.push(`view/${id}`);
    }
  }

  clickBack = () => {
    if (isNil(this.props.steps)) {
      const currentIndex = findIndex(this.steps, (o) => o.step === this.step);
      if (currentIndex > -1 && currentIndex !== 0) {
        history.push(this.steps[currentIndex - 1].step);
      }
    } else {
      this.props.handleClickBack();
    }
  };
  clickBackButton = () => {
    if (this.props.isEdited()) {
      this.setState({ isOpenModal: true });
    } else {
      this.clickBack();
    }
  };
  getCurrentIndex = () => {
    return findIndex(this.steps, (o) => o.step === this.step);
  };

  clickTest = () => {
    if (!isNil(this.props.handleClickTest)) {
      this.props.handleClickTest();
    }
  };

  clickNext = () => {
    if (!isNil(this.props.handleClickNext)) {
      this.props.handleClickNext();
    }
  };

  skipTest = () => {
    history.push('preview');
  };

  renderButtonTitle = () => {
    let result = '';
    const foundItem = lodashFind(this.steps, (o) => o.step === this.step);
    if (foundItem) {
      result = foundItem.nextButtonTitle;
    }
    return result;
  };

  renderFooter = () => {
    return (
      <div style={styles.footer} className={'modal-footer'}>
        {this.getCurrentIndex() === 0 && <div />}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {this.step === 'test' && (
            <Button
              id="int-communication-test"
              disabled={this.props.readOnly}
              type="primary"
              shape="round"
              size={'large'}
              className="w-200"
              loading={this.props.loadingTest}
              style={
                this.props.readOnly
                  ? {
                      background: 'gray',
                      margin: '0px 5px',
                    }
                  : { margin: '0px 5px' }
              }
              onClick={() => this.clickTest()}
            >
              {'TEST CAMPAIGN'}
            </Button>
          )}
          {!this.props.hideNextButton && (
            <React.Fragment>
              {this.props.disableLaunchButton || this.props.readOnly ? (
                <Button
                  type="primary"
                  id="int-communication-launch"
                  shape="round"
                  size={'large'}
                  loading={this.props.loadingNext}
                  style={{
                    background: 'gray',
                    margin: '0px 5px',
                  }}
                  className="w-200"
                >
                  {'Already Launched'}
                </Button>
              ) : (
                <Button
                  type="primary"
                  id="int-communication-launch"
                  disabled={this.props.readOnly}
                  shape="round"
                  size={'large'}
                  loading={this.props.loadingNext}
                  style={{ margin: '0px 5px' }}
                  onClick={() => this.clickNext()}
                  className="w-200"
                >
                  {this.props.loadingNext &&
                  this.renderButtonTitle() === 'LAUNCH CAMPAIGN'
                    ? 'Loading...'
                    : this.renderButtonTitle()}
                </Button>
              )}
            </React.Fragment>
          )}
        </div>{' '}
        {this.getCurrentIndex() !== 0 && (
          <Button
            id="int-communication-back"
            shape="round"
            size={'large'}
            loading={false}
            className="w-200"
            onClick={() => this.clickBackButton()}
          >
            {'BACK'}
          </Button>
        )}
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderFooter()}
        {/* <CommunicationDiscardModalComponent
        show={this.state.isOpenModal}
        onDone={() => this.clickBack()}
      /> */}
        <Modal
          width={600}
          title={<span style={{ fontWeight: 'bold' }}>Are You Sure?</span>}
          visible={this.state.isOpenModal}
          onOk={() => this.clickBack()}
          okText="Yes, Continue"
          onCancel={() => this.setState({ isOpenModal: false })}
          cancelText="No"
        >
          <p>
            {' '}
            Any information entered in this step will be lost if you do not save
            this step. Would you like to continue?
          </p>
        </Modal>
      </div>
    );
  }
}

export default CommunicationFooterComponent;
