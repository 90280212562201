import ConfigurationUpdateComponent from '../component/ConfigurationUpdateComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  configurationChangeCurrent,
  configurationUpdateFetch,
  configurationUpdateSubmitFetch,
  configurationtListAttributesFetch,
} from '../ConfigurationActions';

import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentConfiguration: state.admin.configuration.currentConfiguration,
  errors: state.admin.configuration.errors,
  loadingUpdate: state.admin.configuration.loadingUpdate,
  attributes: state.admin.configuration.attributes,
  loadingAttributes: state.admin.configuration.loadingAttributes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      configurationChangeCurrent,
      configurationUpdateFetch,
      configurationUpdateSubmitFetch,
      configurationtListAttributesFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigurationUpdateComponent);

ConfigurationUpdateComponent.propTypes = {
  currentConfiguration: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  configurationChangeCurrent: PropTypes.func.isRequired,
  configurationUpdateFetch: PropTypes.func.isRequired,
  configurationUpdateSubmitFetch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
