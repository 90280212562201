import { Divider, Form, Input, Menu } from 'antd';
import React, { useState, useRef, useEffect } from 'react';

const { TextArea } = Input;

const TextareaConfiguration = ({
  value,
  onChange,
  data,
  textBoxValue,
  setTextBoxValue,
  minRows,
  name,
  item,
  label,
  required,
}) => {
  const [showAttributePicker, setShowAttributePicker] = useState(false);

  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref.current?.contains(event.target)) {
        setShowAttributePicker(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [ref]);

  return (
    <div style={{ position: 'relative' }}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required,
          },
        ]}
      >
        <TextArea
          id={item}
          dir={'auto'}
          autoSize={{
            minRows: minRows,
            maxRows: 5,
          }}
          size={'large'}
          value={value}
          onChange={onChange}
        />
      </Form.Item>
      <span
        ref={ref}
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          paddingRight: 10,
        }}
      >
        <i
          onClick={() => {
            setShowAttributePicker(!showAttributePicker);
          }}
          className="fl-dark fl-user input-suffix-icon"
        ></i>
        {showAttributePicker && (
          <AttributesSelector
            data={data}
            textBoxValue={textBoxValue}
            setTextBoxValue={setTextBoxValue}
            setShowAttributePicker={setShowAttributePicker}
            item={item}
          />
        )}
      </span>
    </div>
  );
};

const AttributesSelector = ({
  data,
  textBoxValue,
  setTextBoxValue,
  setShowAttributePicker,
  item,
}) => {
  const [activeMenu, setActiveMenu] = useState('first');
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    if (activeMenu !== 'first') {
      const selectItem = data?.find((item) => item.key === activeMenu);
      setAttributes(selectItem.child);
    }
  }, [activeMenu]);
  function appendMessage(value) {
    const element = document.getElementById(item);
    const cursorStart = element?.selectionStart;
    const cursorEnd = element?.selectionEnd;
    if (textBoxValue) {
      setTextBoxValue(
        `${textBoxValue.slice(0, cursorStart)}${value}${textBoxValue.slice(
          cursorEnd
        )}`
      );
    } else {
      setTextBoxValue(value);
    }
    element.focus();
    setShowAttributePicker(false);
  }
  function handleChangeAttributeSearch(value) {
    const selectItem = data?.find((item) => item.key === activeMenu);
    let attributes = selectItem.child;
    if (value !== '') {
      attributes = attributes.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(value.toLowerCase());
      });
    }
    setAttributes(attributes);
  }
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 9,
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        width: 200,
        backgroundColor: 'white',
        borderRadius: 5,
        right: 0,
      }}
    >
      {activeMenu === 'first' ? (
        <Menu
          onClick={({ key }) => {
            setActiveMenu(key);
          }}
        >
          {data.map((item) => (
            <Menu.Item key={item.key}>
              {item.value}
              <i
                style={{
                  fontSize: 25,
                  position: 'absolute',
                  top: 8,
                  right: 0,
                }}
                className="fl-dark fl-chevron-right"
              ></i>
            </Menu.Item>
          ))}
        </Menu>
      ) : (
        <div>
          <Menu
            onClick={() => setActiveMenu('first')}
            style={{ height: 45, backgroundColor: '#f5f5f6' }}
          >
            <Menu.Item key="back">
              <i
                style={{
                  fontSize: 25,
                  position: 'relative',
                  top: 6,
                  right: 7,
                }}
                className="fl-dark fl-chevron-left"
              ></i>
              Back
            </Menu.Item>
          </Menu>
          <div style={{ padding: 10 }}>
            <Input
              placeholder="Search..."
              allowClear
              onChange={(e) => {
                handleChangeAttributeSearch(e.target.value);
              }}
            />
          </div>
          <Divider style={{ margin: '5px 0 0 0' }} />
          <Menu
            onClick={({ key }) => {
              appendMessage(key);
            }}
            style={{ height: 200, overflow: 'scroll' }}
          >
            {attributes?.map((child) => (
              <Menu.Item key={child.key}>{child.value}</Menu.Item>
            ))}
          </Menu>
        </div>
      )}
    </div>
  );
};
export default TextareaConfiguration;
