import BaseApi from '../../../api/BaseApi';
const basePath = '/billing/contract';
export default class ContractApi {
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static create(body) {
    return BaseApi.post(basePath + '/create', body);
  }
  static updateSubmit(id, body) {
    return BaseApi.post(basePath + '/updateSubmit/' + id, body);
  }
  static view(id) {
    return BaseApi.post(basePath + '/view/' + id);
  }
  static viewActive() {
    return BaseApi.post(basePath + '/viewActive', {});
  }
  static delete(id) {
    return BaseApi.post(basePath + '/delete/' + id);
  }
}
