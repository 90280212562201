export const PROFILE_CHANGE_CURRENT = 'PROFILE_CHANGE_CURRENT';
export const PASSWORD_CHANGE_CURRENT = 'PASSWORD_CHANGE_CURRENT';

export const PROFILE_VIEW_FETCH = 'PROFILE_VIEW_FETCH';
export const PROFILE_VIEW_FETCH_FAIL = 'PROFILE_VIEW_FETCH_FAIL';
export const PROFILE_VIEW_FETCH_SUCCESS = 'PROFILE_VIEW_FETCH_SUCCESS';

export const PROFILE_UPDATE_FETCH = 'PROFILE_UPDATE_FETCH';
export const PROFILE_UPDATE_FETCH_FAIL = 'PROFILE_UPDATE_FETCH_FAIL';
export const PROFILE_UPDATE_FETCH_SUCCESS = 'PROFILE_UPDATE_FETCH_SUCCESS';

export const PROFILE_UPDATE_SUBMIT_FETCH = 'PROFILE_UPDATE_SUBMIT_FETCH';
export const PROFILE_UPDATE_SUBMIT_FETCH_FAIL =
  'PROFILE_UPDATE_SUBMIT_FETCH_FAIL';
export const PROFILE_UPDATE_SUBMIT_FETCH_SUCCESS =
  'PROFILE_UPDATE_SUBMIT_FETCH_SUCCESS';

export const PROFILE_CHANGE_PASSWORD_FETCH = 'PROFILE_CHANGE_PASSWORD_FETCH';
export const PROFILE_CHANGE_PASSWORD_FETCH_FAIL =
  'PROFILE_CHANGE_PASSWORD_FETCH_FAIL';
export const PROFILE_CHANGE_PASSWORD_FETCH_SUCCESS =
  'PROFILE_CHANGE_PASSWORD_FETCH_SUCCESS';

export const PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH =
  'PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH';
export const PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH_FAIL =
  'PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH_FAIL';
export const PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH_SUCCESS =
  'PROFILE_CHANGE_PASSWORD_SUBMIT_FETCH_SUCCESS';

export const PROFILE_UPLOAD_PICTURE_FETCH = 'PROFILE_UPLOAD_PICTURE_FETCH';
export const PROFILE_UPLOAD_PICTURE_FETCH_FAIL =
  'PROFILE_UPLOAD_PICTURE_FETCH_FAIL';
export const PROFILE_UPLOAD_PICTURE_FETCH_SUCCESS =
  'PROFILE_UPLOAD_PICTURE_FETCH_SUCCESS';

export const PROFILE_UPDATE_LAST_PRODUCT_FETCH =
  'PROFILE_UPDATE_LAST_PRODUCT_FETCH';
export const PROFILE_UPDATE_LAST_PRODUCT_FETCH_SUCCESS =
  'PROFILE_UPDATE_LAST_PRODUCT_FETCH_SUCCESS';
export const PROFILE_UPDATE_LAST_PRODUCT_FETCH_FAIL =
  'PROFILE_UPDATE_LAST_PRODUCT_FETCH_FAIL';

export const PROFILE_VERIFICATION_CODE = 'PROFILE_VERIFICATION_CODE';
export const PROFILE_VERIFICATION_EMAIL = 'PROFILE_VERIFICATION_EMAIL';

export const PROFILE_RESET_PASSWORD = 'PROFILE_RESET_PASSWORD';
