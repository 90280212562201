import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Table, Tag, Spin } from 'antd';
import { history } from '../../../../redux/store';
import { viewDateTime } from '../../../../utils/DateUtils';
import UserStatus from '../model/UserStatus';
import {
  AuthorityProvider,
  hasAnyAuthority,
} from '../../../../utils/AuthorityProvider';
import { viewBoolean } from '../../../../utils/BooleanUtils';
import { LoadingOutlined } from '@ant-design/icons';
import { isNil, isEmpty } from 'lodash';
const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    display: 'flex',
    color: '#9a9ca3',
    fontSize: 14,
    margin: '15px 30px',
  },
  description: {
    borderRadius: 10,
    backgroundColor: '#efefef',
    padding: '10px 40px',
    color: '#9a9ca3',
    marginBottom: 10,
  },
};
class UserViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId:
        this.props.pathname.includes('view') &&
        this.props.pathname.split('view/').pop(),
    };
  }
  componentDidMount() {
    this.props.userViewFetch(this.state.userId);
  }

  render() {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 40 }} spin />;
    return (
      <React.Fragment>
        {isEmpty(this.props.currentUser) ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 250,
              marginBottom: 250,
            }}
          >
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <Modal
            width={'65%'}
            title={
              <div style={{ display: 'flex', color: '#707070', fontSize: 17 }}>
                VIEW USER
              </div>
            }
            centered
            visible={!isEmpty(this.props.currentUser)}
            onCancel={() => {
              history.push('../list');
            }}
            footer={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Link to="../list">
                  <Button
                    type="primary"
                    shape="round"
                    size={'large'}
                    loading={this.props.loading}
                    style={{ margin: '0px 5px' }}
                    className="w-200"
                  >
                    {'Back'}
                  </Button>
                </Link>
                <div>
                  {hasAnyAuthority(
                    AuthorityProvider.ROLE_ADMIN_USER_UPDATE
                  ) && (
                    <Button
                      type="primary"
                      shape="round"
                      size={'large'}
                      loading={this.props.loading}
                      className="w-200"
                      onClick={() => {
                        this.props.userChangeCurrent({});
                        history.push(`../update/${this.state.userId}`);
                      }}
                    >
                      {'Edit'}
                    </Button>
                  )}
                  {hasAnyAuthority(
                    AuthorityProvider.ROLE_ADMIN_USER_CHANGE_PASSWORD
                  ) && (
                    <Link to={`../changePassword/${this.state.userId}`}>
                      <Button
                        type="primary"
                        shape="round"
                        size={'large'}
                        loading={this.props.loading}
                        className="w-200"
                      >
                        {'Change Password'}
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            }
          >
            <div>{this.renderContent()}</div>
          </Modal>
        )}
      </React.Fragment>
    );
  }

  renderContent = () => {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 40 }} spin />;
    const columns = [
      {
        title: 'Product',
        dataIndex: 'product',
        key: 'product',
        render: (x) => (isNil(x) ? 'All Products' : x.name),
      },
      {
        title: 'Enabled',
        dataIndex: 'enabled',
        key: 'enabled',
        render: (x) => (isNil(x) ? '-' : viewBoolean(x)),
      },
      {
        title: 'Roles',
        dataIndex: 'roles',
        key: 'roles',
        render: (roles) =>
          isNil(roles) ? (
            '-'
          ) : (
            <React.Fragment>
              {roles.map((role) => {
                return (
                  <Tag color="#626de0" key={role.name}>
                    {role.name}
                  </Tag>
                );
              })}
            </React.Fragment>
          ),
      },
    ];
    return (
      <React.Fragment>
        {this.props.loadingUser ||
        isEmpty(this.props.currentUser) ||
        !this.props.currentUser ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 250,
              marginBottom: 250,
            }}
          >
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <div style={styles.title}>CREATED DATE</div>
                <div style={styles.title}>MODIFIED DATE</div>
                <div style={styles.title}>FIRST NAME</div>
                <div style={styles.title}>EMAIL</div>
                <div style={styles.title}>STATUS</div>
                <div style={styles.title}>EXPIRATION DATE</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: 70,
                }}
              >
                <div style={styles.description}>
                  {viewDateTime(this.props.currentUser.createdDate)
                    ? viewDateTime(this.props.currentUser.createdDate)
                    : 'No data'}
                </div>
                <div style={styles.description}>
                  {viewDateTime(this.props.currentUser.modifiedDate)
                    ? viewDateTime(this.props.currentUser.modifiedDate)
                    : 'No data'}
                </div>
                <div style={styles.description}>
                  {this.props.currentUser.firstName
                    ? this.props.currentUser.firstName
                    : 'No data'}
                </div>
                <div style={styles.description}>
                  {this.props.currentUser.email
                    ? this.props.currentUser.email
                    : 'No data'}
                </div>
                <div style={styles.description}>
                  {UserStatus[this.props.currentUser.status]
                    ? UserStatus[this.props.currentUser.status]
                    : 'No data'}
                </div>
                <div style={styles.description}>
                  {this.props.currentUser.expirationDate
                    ? this.props.currentUser.expirationDate
                    : 'No data'}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <div style={styles.title}>CREATED BY</div>
                <div style={styles.title}>MODIFIED BY</div>
                <div style={styles.title}>LAST NAME</div>
                <div style={styles.title}>PHONE</div>
                <div style={styles.title}>LAST SEEN</div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={styles.description}>
                  {this.props.currentUser.createdBy
                    ? this.props.currentUser.createdBy.presentation
                    : 'No data'}
                </div>
                <div style={styles.description}>
                  {this.props.currentUser.modifiedBy
                    ? this.props.currentUser.modifiedBy.presentation
                    : 'No data'}
                </div>
                <div style={styles.description}>
                  {this.props.currentUser.lastName
                    ? this.props.currentUser.lastName
                    : 'No data'}
                </div>
                <div style={styles.description}>
                  {this.props.currentUser.phone
                    ? this.props.currentUser.phone
                    : 'No data'}
                </div>
                <div style={styles.description}>
                  {viewDateTime(this.props.currentUser.lastSeen)
                    ? viewDateTime(this.props.currentUser.lastSeen)
                    : 'No data'}
                </div>
              </div>
            </div>
            <div style={{ margin: '50px 10px' }}>
              <Table
                pagination={false}
                columns={columns}
                dataSource={this.props.currentUser.userProducts}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };
}

export default UserViewComponent;
