import UserListComponent from '../component/UserListComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  userPageChangeCurrent,
  userListFetch,
  userSearchListFetch,
  userRoleListFetch,
} from '../UserActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  page: state.admin.user.page,
  pageRequest: state.admin.user.pageRequest,
  roles: state.admin.user.roles,
  loadingUserList: state.admin.user.loadingUserList,
  loadingSearchUserList: state.admin.user.loadingSearchUserList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userPageChangeCurrent,
      userRoleListFetch,
      userSearchListFetch,
      userListFetch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserListComponent);

UserListComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  userRoleListFetch: PropTypes.func.isRequired,
  userListFetch: PropTypes.func.isRequired,
  userPageChangeCurrent: PropTypes.func.isRequired,
};
