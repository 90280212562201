import React, { useEffect } from 'react';
import { Form, Input, Col, Row, Button, Divider, Switch, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import vars from '@Theme/styles/vars';
import { OPERATORS } from './helpers';
const { Option } = Select;

export default function ScreenNameMapper({ form }) {
  useEffect(() => {
    form.validateFields();
  }, []);
  return (
    <div className="relay-mapper-area" style={{ marginLeft: 0 }}>
      <Form.Item
        name="screenNameMapperConjunction"
        style={{ display: 'none' }}
        defaultValue="OR"
      >
        <Input />
      </Form.Item>
      <Form.List name="screenNameMapper">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, idx) => (
              <>
                <Row key={field.key} gutter={16}>
                  <Col span="6">
                    <Input value={'Screen Name'} disabled />
                  </Col>
                  <Col span="4">
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) => {
                        return (
                          curValues?.screenNameMapper?.length > idx &&
                          curValues?.screenNameMapper[idx] &&
                          prevValues?.screenNameMapper?.length > idx &&
                          prevValues?.screenNameMapper[idx] &&
                          curValues?.screenNameMapper[idx]?.negate !==
                            prevValues?.screenNameMapper[idx]?.negate
                        );
                      }}
                    >
                      {() => {
                        const formValues = form.getFieldsValue();
                        const checked =
                          formValues.screenNameMapper &&
                          formValues.screenNameMapper.length &&
                          formValues.screenNameMapper[idx]?.negate;
                        return (
                          <Form.Item
                            {...field}
                            name={[field.name, 'operator']}
                            rules={[
                              { required: true, message: 'select operator' },
                            ]}
                            style={{ width: '100%' }}
                          >
                            <Select
                              style={{ width: '100%' }}
                              defaultValue="EQUAL_TO"
                              onChange={(op) => {
                                const formValues = form.getFieldsValue();
                                if (op === 'IS_NOT_EMPTY') {
                                  formValues.screenNameMapper[idx].value = '-';
                                } else {
                                  formValues.screenNameMapper[idx].value =
                                    op === 'ONE_OF' ? [] : null;
                                }
                                form.setFieldsValue(formValues);
                              }}
                              dropdownRender={(menu) => (
                                <div>
                                  {menu}
                                  <Divider style={{ margin: '4px 0' }} />
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginRight: 10,
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: '#1c1c1c',
                                      }}
                                    >
                                      Negate:
                                    </div>
                                    <div>
                                      <Switch
                                        checkedChildren="Yes"
                                        unCheckedChildren="No"
                                        onChange={(value) => {
                                          const formValues =
                                            form.getFieldsValue();

                                          if (
                                            formValues.screenNameMapper[idx]
                                          ) {
                                            formValues.screenNameMapper[
                                              idx
                                            ].negate = value;
                                          } else {
                                            formValues.screenNameMapper[idx] = {
                                              negate: value,
                                            };
                                          }

                                          form.setFieldsValue(formValues);
                                          form.submit();
                                        }}
                                        checked={checked}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            >
                              {OPERATORS.filter((op) => {
                                return op.dataTypes.includes('STRING');
                              }).map((op) => (
                                <Option value={op.name} key={op.name}>
                                  {checked ? op.negateLabel : op.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col span="8">
                    <Form.Item
                      shouldUpdate={(prevValues, curValues) => {
                        return (
                          curValues?.screenNameMapper?.length > idx &&
                          curValues?.screenNameMapper[idx] &&
                          prevValues?.screenNameMapper?.length > idx &&
                          prevValues?.screenNameMapper[idx] &&
                          curValues?.screenNameMapper[idx]?.operator !==
                            prevValues?.screenNameMapper[idx]?.operator
                        );
                      }}
                    >
                      {() => {
                        const formValues = form.getFieldsValue();
                        const operator =
                          formValues.screenNameMapper &&
                          formValues.screenNameMapper.length &&
                          formValues.screenNameMapper[idx]?.operator;

                        return (
                          <Form.Item
                            {...field}
                            name={[field.name, 'value']}
                            style={{
                              display:
                                operator === 'IS_NOT_EMPTY' ? 'none' : 'block',
                            }}
                            // label=" "
                            // validateStatus="error"
                            rules={[
                              {
                                required: true,
                                message: 'please enter a value',
                              },
                            ]}
                          >
                            {operator === 'ONE_OF' ? (
                              <Select
                                mode="tags"
                                style={{
                                  width: '100%',
                                }}
                              />
                            ) : (
                              <Input />
                            )}
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  {fields.length > 1 ? (
                    <Col span="2">
                      <MinusCircleOutlined
                        style={{ marginTop: '10px' }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Col>
                  ) : null}
                </Row>
                {idx < fields.length - 1 ? (
                  <Row>
                    <Col span="24">
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => {
                          return (
                            prevValues.screenNameMapperConjunction !==
                            curValues.screenNameMapperConjunction
                          );
                        }}
                      >
                        {() => {
                          const formValues = form.getFieldsValue();
                          const conjunction =
                            formValues.screenNameMapperConjunction;
                          return (
                            <div
                              style={{ position: 'relative', width: '100%' }}
                            >
                              <div
                                style={{
                                  border: '1px dashed #E9E9E9',
                                  position: 'absolute',
                                  width: '100%',
                                  top: '50%',
                                }}
                              />
                              <Button
                                onClick={() => {
                                  // formValues.screenNameMapperConjunction =
                                  //   conjunction === 'AND' ? 'OR' : 'AND';
                                  // form.setFieldsValue(formValues);
                                  // form.submit();
                                }}
                                style={{
                                  color: '#FFF',
                                  backgroundColor:
                                    conjunction === 'AND'
                                      ? vars.btnOrOpration
                                      : vars.primaryColor,
                                  opacity: 1,
                                }}
                              >
                                {conjunction}
                              </Button>
                            </div>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}
              </>
            ))}

            <Row>
              <Col span="24">
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => {
                    return (
                      prevValues.screenNameMapperConjunction !==
                      curValues.screenNameMapperConjunction
                    );
                  }}
                >
                  {() => {
                    const formValues = form.getFieldsValue();
                    const conjunction = formValues.screenNameMapperConjunction;

                    return (
                      <Form.Item>
                        <div
                          style={{
                            border: '1px dashed #E9E9E9',
                            position: 'absolute',
                            width: '100%',
                            top: '50%',
                          }}
                        />
                        <Button
                          onClick={() => {
                            add({
                              negate: false,
                              operator: 'EQUAL_TO',
                              value: null,
                            });
                            setTimeout(() => {
                              form.validateFields();
                            }, 500);
                          }}
                          icon={<PlusOutlined />}
                          style={{
                            border: 'solid 1px',
                            borderColor:
                              conjunction === 'AND'
                                ? vars.btnOrOpration
                                : vars.primaryColor,
                            color:
                              conjunction === 'AND'
                                ? vars.btnOrOpration
                                : vars.primaryColor,
                            backgroundColor: '#FFFFFF',
                            opacity: 1,
                          }}
                        >
                          Add Mapping
                        </Button>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form.List>
    </div>
  );
}
