import {
  CUSTOM_CHANNEL_DELETE_FETCH,
  CUSTOM_CHANNEL_DELETE_FETCH_FAIL,
  CUSTOM_CHANNEL_DELETE_FETCH_SUCCESS,
  CUSTOM_CHANNEL_CREATE_FETCH,
  CUSTOM_CHANNEL_CREATE_FETCH_FAIL,
  CUSTOM_CHANNEL_CREATE_FETCH_SUCCESS,
  CUSTOM_CHANNEL_LIST_FETCH,
  CUSTOM_CHANNEL_LIST_FETCH_FAIL,
  CUSTOM_CHANNEL_LIST_FETCH_SUCCESS,
  CUSTOM_CHANNEL_UPDATE_FETCH,
  CUSTOM_CHANNEL_UPDATE_FETCH_FAIL,
  CUSTOM_CHANNEL_UPDATE_FETCH_SUCCESS,
  CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH,
  CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
  CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
  CUSTOM_CHANNEL_CHANGE_CURRENT,
  CUSTOM_CHANNEL_PAGE_CHANGE_CURRENT,
  CUSTOM_CHANNEL_SEARCH_LIST_FETCH,
  CUSTOM_CHANNEL_SEARCH_LIST_FETCH_SUCCESS,
  CUSTOM_CHANNEL_SEARCH_LIST_FETCH_FAIL,
  CUSTOM_CHANNEL_TOGGLE_ADD_MODAL,
} from './CustomChannelConstants';

const initialState = {
  page: {},
  loadingList: false,
  loadingSearchList: false,
  pageRequest: {},
  errors: [],

  currentCustomChannel: {},

  errorsUpdate: [],
  loadingUpdate: false,

  errorsUpdateSubmit: [],
  loadingUpdateSubmit: false,

  errorsDelete: [],
  loadingDelete: false,

  errorsCreate: [],
  loadingCreate: false,

  addModalOpen: false,
};

// eslint-disable-next-line complexity
export default function CustomChannelState(state = initialState, action) {
  switch (action.type) {
    case CUSTOM_CHANNEL_LIST_FETCH:
      return { ...state, pageRequest: action.payload, loadingList: true };
    case CUSTOM_CHANNEL_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingList: false };
    case CUSTOM_CHANNEL_LIST_FETCH_FAIL:
      return { ...state, errors: action.payload.errors, loadingList: false };

    case CUSTOM_CHANNEL_SEARCH_LIST_FETCH:
      return { ...state, pageRequest: action.payload, loadingSearchList: true };
    case CUSTOM_CHANNEL_SEARCH_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingSearchList: false };
    case CUSTOM_CHANNEL_SEARCH_LIST_FETCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loadingSearchList: false,
      };

    case CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH:
      return { ...state, loadingUpdateSubmit: true };
    case CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        errorsUpdateSubmit: action.payload.errors,
        loadingUpdateSubmit: false,
      };
    case CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingUpdateSubmit: false };

    case CUSTOM_CHANNEL_UPDATE_FETCH:
      return { ...state, loadingUpdate: true };
    case CUSTOM_CHANNEL_UPDATE_FETCH_FAIL:
      return {
        ...state,
        errorsUpdate: action.payload.errors,
        loadingUpdate: false,
      };
    case CUSTOM_CHANNEL_UPDATE_FETCH_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        currentCustomChannel: action.payload,
      };

    case CUSTOM_CHANNEL_CREATE_FETCH:
      return { ...state, loadingCreate: true };
    case CUSTOM_CHANNEL_CREATE_FETCH_FAIL:
      return {
        ...state,
        errorsCreate: action.payload.errors,
        loadingCreate: false,
      };
    case CUSTOM_CHANNEL_CREATE_FETCH_SUCCESS:
      return { ...state, loadingCreate: false };

    case CUSTOM_CHANNEL_DELETE_FETCH:
      return { ...state, loadingDelete: true };
    case CUSTOM_CHANNEL_DELETE_FETCH_FAIL:
      return {
        ...state,
        errorsDelete: action.payload.errors,
        loadingDelete: false,
      };
    case CUSTOM_CHANNEL_DELETE_FETCH_SUCCESS:
      return { ...state, loadingDelete: false };

    case CUSTOM_CHANNEL_CHANGE_CURRENT:
      return { ...state, currentCustomChannel: action.payload };

    case CUSTOM_CHANNEL_PAGE_CHANGE_CURRENT:
      return { ...state, pageRequest: action.payload };

    case CUSTOM_CHANNEL_TOGGLE_ADD_MODAL:
      return { ...state, addModalOpen: action.payload };

    default:
      return state;
  }
}
