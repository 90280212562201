import ProfileChangePasswordComponent from '../component/ProfileChangePasswordComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  passwordChangeCurrent,
  profileChangePasswordFetch,
  profileChangePasswordSubmitFetch,
} from '../ProfileActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentPassword: state.account.profile.currentPassword,
  errors: state.account.profile.errors,
  loadingUpdatePassword: state.account.profile.loadingUpdatePassword,
  loadingUpdatePasswordSubmit:
    state.account.profile.loadingUpdatePasswordSubmit,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      passwordChangeCurrent,
      profileChangePasswordFetch,
      profileChangePasswordSubmitFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileChangePasswordComponent);

ProfileChangePasswordComponent.propTypes = {
  currentProfile: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  profileChangeCurrent: PropTypes.func.isRequired,
  profileChangePasswordFetch: PropTypes.func.isRequired,
  profileChangePasswordSubmitFetch: PropTypes.func.isRequired,
};
