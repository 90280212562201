import React, { useEffect, useState } from 'react';
// import { Input, Form, Upload, Modal } from 'antd';
import style from './BackOfficeConfirmationReceiptModalContent.module.less';
import StripedBox from '@Component/app/StripedBox';
import { Input, Collapse, Col, Row, List } from 'antd';
import { useSelector } from 'react-redux';
const Panel = Collapse.Panel;

const BackOfficeConfirmationReceiptModalContent = ({
  receipts,
  description,
  selectedInvoiceId,
}) => {
  //Check
  const { page: invoiceList } = useSelector((state) => state.setting.invoice);
  const [selectedInvoice, setSelectedInvoice] = useState();

  useEffect(() => {
    invoiceList?.content?.forEach((invoice) => {
      if (invoice.id === selectedInvoiceId) {
        setSelectedInvoice(invoice);
      }
    });
  }, [selectedInvoiceId]);

  const rows = [
    {
      columns: [
        <span key="0">Total</span>,
        <span key="1">{selectedInvoice?.finalPrice} Rial</span>,
      ],
    },
    {
      columns: [
        <span key="0">Date</span>,
        <span key="1">
          <span style={{ color: 'rgba(56, 55, 59, 0.65)' }}>From</span>{' '}
          {selectedInvoice?.startDate}{' '}
          <span style={{ color: 'rgba(56, 55, 59, 0.65)' }}>to</span>{' '}
          {selectedInvoice?.endDate}
        </span>,
      ],
    },
    {
      columns: [
        <span key="0">User Comment</span>,
        <span key="1">{description}</span>,
      ],
    },
  ];

  const cols = [
    {
      width: '50%',
      textAlign: 'left',
      color: 'rgba(56, 55, 59, 0.8)',
      fontWeight: '400',
    },
    {
      width: '50%',
      overflowWrap: 'anywhere',
      textAlign: 'left',
      color: '#38373BCC',
      fontWeight: '500',
    },
  ];

  return (
    <div id="backOfficeUploadReceipt">
      <p className={style.header}>
        <div>
          Please ensure the company has paid its debt within the specified time
          period.
        </div>
        <div>
          Approve if the information is accurate, and disapprove otherwise.
        </div>
      </p>
      <div className={style.title}>Payment Info</div>
      {/* Check */}
      <StripedBox cols={cols} rows={rows} title="Digikala" />
      <div style={{ margin: '16px 0px 8px' }} className={style.title}>
        Receipts
      </div>
      <>
        {receipts.map((receipt, index) => {
          return (
            <Collapse bordered={true} key={receipt?.id}>
              <Panel
                header={
                  <div className={style.panelTitle}>{`Receipt ${
                    index + 1
                  }`}</div>
                }
                className={style.customPanel}
              >
                <>
                  <Row gutter={[8, 16]} align="middle">
                    <Col span={12}>Upload Receipt</Col>
                    <Col span={12}>
                      <List>
                        <List.Item
                          style={{
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '8px',
                          }}
                        >
                          <List.Item.Meta
                            style={{
                              fontWeight: '500 !important',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            avatar={
                              <img
                                src={receipt?.filePath}
                                alt={receipt.fileName}
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  objectFit: 'cover',
                                }}
                              />
                            }
                            title={<span>{receipt?.fileName || '--'}</span>}
                          />
                        </List.Item>
                      </List>
                    </Col>
                    <Col span={12}>Amount (IRR)</Col>
                    <Col span={12}>
                      <Input
                        name="amount"
                        type="number"
                        value={receipt.amount}
                        disabled
                      />
                    </Col>
                    <Col span={12}>Reference Number</Col>
                    <Col span={12}>
                      <Input
                        name="referenceNo"
                        value={receipt.referenceNo}
                        disabled
                      />
                    </Col>
                  </Row>
                </>
              </Panel>
            </Collapse>
          );
        })}
      </>
    </div>
  );
};

export default BackOfficeConfirmationReceiptModalContent;
