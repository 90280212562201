const SmsProvider = {
  MAGFA: 'Magfa',
  KAVE_NEGAR: 'Kavenegar',
  TWILIO: 'Twilio',
  FARA_PAYAMAK: 'Farapayamak',
  RAHYAB: 'Rahyab',
  PISHGAMRAYAN: 'Pishgamrayan',
  NIK_SMS: 'Nik SMS',
  PRIVATE: 'Private',
  MEDIANA: 'Mediana',
  PARDIS: 'Pardis',
};

export default SmsProvider;

export const InheritableSmsProviders = {
  NO_DELIVERY: 'No Delivery',
  MAGFA: 'Magfa',
  KAVE_NEGAR: 'Kavenegar',
  FARA_PAYAMAK: 'Farapayamak',
  PISHGAMRAYAN: 'Pishgamrayan',
};
