/* eslint-disable complexity */
import {
  COMMUNICATION_CHANGE_CURRENT,
  COMMUNICATION_PAGE_CHANGE_CURRENT,
  COMMUNICATION_LIST_FETCH,
  COMMUNICATION_LIST_FETCH_FAIL,
  COMMUNICATION_LIST_FETCH_SUCCESS,
  COMMUNICATION_CREATE_CLEAR,
  COMMUNICATION_CREATE_FETCH,
  COMMUNICATION_CREATE_FETCH_FAIL,
  COMMUNICATION_CREATE_FETCH_SUCCESS,
  COMMUNICATION_DRAFT_SAVE,
  COMMUNICATION_DRAFT_SAVE_DISCARD,
  COMMUNICATION_AUDIENCE_CREATE_FETCH,
  COMMUNICATION_AUDIENCE_CREATE_FETCH_SUCCESS,
  COMMUNICATION_AUDIENCE_CREATE_FETCH_FAIL,
  COMMUNICATION_COPY_FETCH,
  COMMUNICATION_COPY_FETCH_FAIL,
  COMMUNICATION_COPY_FETCH_SUCCESS,
  COMMUNICATION_DELETE_FETCH,
  COMMUNICATION_DELETE_FETCH_FAIL,
  COMMUNICATION_DELETE_FETCH_SUCCESS,
  COMMUNICATION_LAUNCH_FETCH,
  COMMUNICATION_LAUNCH_FETCH_FAIL,
  COMMUNICATION_LAUNCH_FETCH_SUCCESS,
  COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH_SUCCESS,
  COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH_FAIL,
  COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH,
  COMMUNICATION_LIST_EVENTS_FETCH_SUCCESS,
  COMMUNICATION_LIST_EVENTS_FETCH_FAIL,
  COMMUNICATION_LIST_EVENTS_FETCH,
  COMMUNICATION_LIST_PUSH_CHANNELS_FETCH_SUCCESS,
  COMMUNICATION_LIST_PUSH_CHANNELS_FETCH_FAIL,
  COMMUNICATION_LIST_PUSH_CHANNELS_FETCH,
  COMMUNICATION_LIST_SEGMENTS_FETCH_SUCCESS,
  COMMUNICATION_LIST_SEGMENTS_FETCH_FAIL,
  COMMUNICATION_LIST_SEGMENTS_FETCH,
  COMMUNICATION_LIST_SMS_CHANNELS_FETCH_SUCCESS,
  COMMUNICATION_LIST_SMS_CHANNELS_FETCH_FAIL,
  COMMUNICATION_LIST_SMS_CHANNELS_FETCH,
  COMMUNICATION_LIST_TAG_FETCH,
  COMMUNICATION_LIST_TAG_FETCH_SUCCESS,
  COMMUNICATION_LIST_TAG_FETCH_FAIL,
  COMMUNICATION_OVERVIEW_FETCH,
  COMMUNICATION_OVERVIEW_FETCH_FAIL,
  COMMUNICATION_OVERVIEW_FETCH_SUCCESS,
  COMMUNICATION_SPLIT_OVERVIEW_FETCH,
  COMMUNICATION_SPLIT_OVERVIEW_FETCH_FAIL,
  COMMUNICATION_SPLIT_OVERVIEW_FETCH_SUCCESS,
  COMMUNICATION_SPLIT_OVERVIEW_PAGE_CHANGE_CURRENT,
  COMMUNICATION_PAUSE_FETCH,
  COMMUNICATION_PAUSE_FETCH_FAIL,
  COMMUNICATION_PAUSE_FETCH_SUCCESS,
  COMMUNICATION_STOP_FETCH,
  COMMUNICATION_STOP_FETCH_FAIL,
  COMMUNICATION_STOP_FETCH_SUCCESS,
  COMMUNICATION_TEST_FETCH,
  COMMUNICATION_TEST_FETCH_FAIL,
  COMMUNICATION_TEST_FETCH_SUCCESS,
  COMMUNICATION_AUDIENCE_RESET,
  COMMUNICATION_UPDATE_AUDIENCE_FETCH,
  COMMUNICATION_UPDATE_AUDIENCE_FETCH_FAIL,
  COMMUNICATION_UPDATE_AUDIENCE_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH,
  COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH_FAIL,
  COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_CONVERSION_FETCH,
  COMMUNICATION_UPDATE_CONVERSION_FETCH_FAIL,
  COMMUNICATION_UPDATE_CONVERSION_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH,
  COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH_FAIL,
  COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_MESSAGE_FETCH,
  COMMUNICATION_UPDATE_MESSAGE_FETCH_FAIL,
  COMMUNICATION_UPDATE_MESSAGE_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH,
  COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH_FAIL,
  COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_WHEN_FETCH,
  COMMUNICATION_UPDATE_WHEN_FETCH_FAIL,
  COMMUNICATION_UPDATE_WHEN_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH,
  COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH_FAIL,
  COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH_SUCCESS,
  COMMUNICATION_VIEW_FETCH,
  COMMUNICATION_VIEW_FETCH_FAIL,
  COMMUNICATION_VIEW_FETCH_SUCCESS,
  COMMUNICATION_COMMUNICATION_REPORT_FETCH,
  COMMUNICATION_COMMUNICATION_REPORT_FETCH_FAIL,
  COMMUNICATION_COMMUNICATION_REPORT_FETCH_SUCCESS,
  COMMUNICATION_VARIATION_REPORT_FETCH,
  COMMUNICATION_VARIATION_REPORT_FETCH_FAIL,
  COMMUNICATION_VARIATION_REPORT_FETCH_SUCCESS,
  COMMUNICATION_UPLOAD_FILE_FETCH,
  COMMUNICATION_UPLOAD_FILE_FETCH_SUCCESS,
  COMMUNICATION_UPLOAD_FILE_FETCH_FAIL,
  COMMUNICATION_UPLOAD_FILE_RESET,
  COMMUNICATION_ENGAGED_USER_FETCH,
  COMMUNICATION_ENGAGED_USER_FETCH_FAIL,
  COMMUNICATION_ENGAGED_USER_FETCH_SUCCESS,
  COMMUNICATION_ENGAGED_USER_PAGE_CHANGE_CURRENT,
  COMMUNICATION_CHANGE_CURRENT_AUDIENCE,
  COMMUNICATION_SEARCH_LIST_FETCH,
  COMMUNICATION_SEARCH_LIST_FETCH_SUCCESS,
  COMMUNICATION_SEARCH_LIST_FETCH_FAIL,
  COMMUNICATION_SEARCH_ENGAGED_USER_FETCH,
  COMMUNICATION_SEARCH_ENGAGED_USER_FETCH_SUCCESS,
  COMMUNICATION_SEARCH_ENGAGED_USER_FETCH_FAIL,
  COMMUNICATION_WHEN_CLEAR_ERROR,
  COMMUNICATION_AUDIENCE_CLEAR_CREATE_ERROR,
  COMMUNICATION_AUDIENCE_CLEAR_UPDATE_ERROR,
  COMMUNICATION_TRACKING_CLEAR_ERROR,
  COMMUNICATION_MESSAGE_CLEAR_ERROR,
  COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH,
  COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH_SUCCESS,
  COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH_FAIL,
  COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH,
  COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH_FAIL,
  COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH_SUCCESS,
  SEGMENT_REPORT_CHANGE_CURRENT,
  COMMUNICATION_ACTIVE_FETCH_SUCCESS,
  COMMUNICATION_ARCHIVE_FETCH,
  COMMUNICATION_ARCHIVE_FETCH_SUCCESS,
  COMMUNICATION_ARCHIVE_FETCH_FAIL,
  COMMUNICATION_ACTIVE_FETCH,
  COMMUNICATION_ACTIVE_FETCH_FAIL,
  COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH_FAIL,
  COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH,
  WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH,
  WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH_SUCCESS,
  WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH_FAIL,
  WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH,
  WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH_SUCCESS,
  WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH_FAIL,
  WHATSAPP_CHANNEL_TEMPLAE_VIEW_CHANGE,
  COMMUNICATION_FAILURES_REPORT_FETCH,
  COMMUNICATION_FAILURES_REPORT_SUCCESS,
  COMMUNICATION_FAILURES_REPORT_FAIL,
} from './CommunicationConstants';

const initialState = {
  loadingList: false,
  loadingSearchList: false,
  loadingCreate: false,
  loadingDelete: false,
  loadingLaunch: false,
  loadingEmailChannels: false,
  loadingEvents: false,
  loadingPushChannels: false,
  loadingSegment: false,
  loadingSmsChannels: false,
  loadingCustomChannels: false,
  loadingPause: false,
  loadingStop: false,
  loadingTest: false,
  loadingUpdateAudience: false,
  loadingUpdateAudienceSubmit: false,
  loadingUpdateConversion: false,
  loadingUpdateConversionSubmit: false,
  loadingUpdateMessage: false,
  loadingUpdateMessageSubmit: false,
  loadingUpdateThrottlingWhen: false,
  loadingUpdateWhen: false,
  loadingUpdateWhenSubmit: false,
  loadingView: false,
  loadingCopy: false,
  loadingCommunicationReport: false,
  loadingVariationReport: false,
  loadingUploadFile: false,
  loadingCommunicationEngagedUser: false,
  loadingListTags: false,
  loadingOverview: false,
  loadingSplitOverview: false,
  loadingWhatsAppTemplate: false,
  loadingWhatsAppTemplateObject: false,

  errorList: [],
  errorCreate: [],
  errorCreateCommunication: [],
  errorDelete: [],
  errorLaunch: [],
  errorEmailChannels: [],
  errorEvents: [],
  errorPushChannels: [],
  errorSegment: [],
  errorSmsChannels: [],
  errorPause: [],
  errorStop: [],
  errorTest: [],
  errorUpdateAudience: [],
  errorUpdateConversion: [],
  errorUpdateAudienceSubmit: [],
  errorUpdateConversionSubmit: [],
  errorUpdateMessage: [],
  errorUpdateMessageSubmit: [],
  errorUpdateWhen: [],
  errorUpdateWhenSubmit: [],
  errorView: [],
  errorsCopy: [],
  errorsCommunicationReport: [],
  errorsVariationReport: [],
  errorsUploadFile: [],
  errorsCommunicationEngagedUser: [],
  errorsListTags: [],
  errorsOverview: [],
  errorsSplitOverview: [],
  errorWhatsAppTemplate: [],

  currentCommunication: {},
  communicationReport: {},
  communicationEngagedUser: {},
  currentVariationReport: [],
  uploadedFile: null,
  listTags: [],
  overview: null,
  splitOverview: null,
  communicationDraft: null,
  defaultThrottling: {},

  page: {},
  communicationEngagedUserPageRequest: {},
  pageRequest: {},
  pageRequestOverview: {},
  pageRequestSplitOverview: {},
  emailChannels: [],
  eventList: [],
  pushChannels: [],
  segments: [],
  smsChannels: [],
  customChannels: [],
  currentAudience: {},
  currentConversion: {},
  currentMessage: {},
  currentWhen: {},
  variationReportPageRequest: {},
  communicationReportPageRequest: {},
  loadingSearchCommunicationEngagedUser: false,
  errorType: null,
  whatsAppTemplate: {},
  whatsAppTemplatePageRequest: {},

  errors: [],
  currentCustomAttribute: {},
  segmentReport: {},
  segmentReportError: {},
  loadingSegmentReport: false,
  loadingActivate: false,
  loadingArchive: false,
  archiveSuccess: false,
  activeSuccess: false,
  whatsAppTemplateObject: {},
  failures: [],
  loadingFailure: false,
};
export default function CommunicationStateReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case COMMUNICATION_WHEN_CLEAR_ERROR:
      return {
        ...state,
        errorUpdateWhenSubmit: {
          ...state.errorUpdateWhenSubmit,
          errors: action.payload,
        },
      };
    case COMMUNICATION_TRACKING_CLEAR_ERROR:
      return {
        ...state,
        errorUpdateConversionSubmit: {
          ...state.errorUpdateConversionSubmit,
          errors: action.payload,
        },
      };
    case COMMUNICATION_MESSAGE_CLEAR_ERROR:
      return {
        ...state,
        errorUpdateMessageSubmit: {
          ...state.errorUpdateMessageSubmit,
          errors: action.payload,
        },
      };
    case COMMUNICATION_LIST_FETCH:
      return { ...state, pageRequest: action.payload, loadingList: true };
    case COMMUNICATION_LIST_FETCH_FAIL:
      return { ...state, loadingList: false, errorList: action.payload };
    case COMMUNICATION_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingList: false };

    case COMMUNICATION_SEARCH_LIST_FETCH:
      return { ...state, pageRequest: action.payload, loadingSearchList: true };
    case COMMUNICATION_SEARCH_LIST_FETCH_FAIL:
      return { ...state, loadingSearchList: false, errorList: action.payload };
    case COMMUNICATION_SEARCH_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingSearchList: false };

    case COMMUNICATION_CREATE_CLEAR:
      return {
        ...state,
        loadingCreate: false,
        communicationData: null,
        successCreated: null,
        errorCreate: null,
      };

    case COMMUNICATION_DRAFT_SAVE:
      return { ...state, communicationDraft: action.payload };

    case COMMUNICATION_DRAFT_SAVE_DISCARD:
      return { ...state, communicationDraft: null };

    case COMMUNICATION_COMMUNICATION_REPORT_FETCH:
      return {
        ...state,
        loadingCommunicationReport: true,
        communicationReportPageRequest: action.payload,
      };
    case COMMUNICATION_COMMUNICATION_REPORT_FETCH_FAIL:
      return {
        ...state,
        loadingCommunicationReport: false,
        errorsCommunicationReport: action.payload,
      };
    case COMMUNICATION_COMMUNICATION_REPORT_FETCH_SUCCESS:
      return {
        ...state,
        communicationReport: action.payload,
        loadingCommunicationReport: false,
      };

    case COMMUNICATION_VARIATION_REPORT_FETCH:
      return {
        ...state,
        variationReportPageRequest: action.payload,
        loadingVariationReport: true,
      };
    case COMMUNICATION_VARIATION_REPORT_FETCH_FAIL:
      return {
        ...state,
        loadingVariationReport: false,
        errorsVariationReport: action.payload,
      };
    case COMMUNICATION_VARIATION_REPORT_FETCH_SUCCESS:
      return {
        ...state,
        currentVariationReport: action.payload,
        loadingVariationReport: false,
      };

    case COMMUNICATION_AUDIENCE_CREATE_FETCH:
      return { ...state, loadingCreate: true };
    case COMMUNICATION_AUDIENCE_CREATE_FETCH_FAIL:
      return {
        ...state,
        loadingCreate: false,
        errorCreateCommunication: action.payload,
      };
    case COMMUNICATION_AUDIENCE_CREATE_FETCH_SUCCESS:
      return { ...state, loadingCreate: false };

    case COMMUNICATION_CREATE_FETCH:
      return {
        ...state,
        loadingCreate: true,
        communicationData: null,
        successCreated: null,
        errorCreate: null,
      };
    case COMMUNICATION_CREATE_FETCH_FAIL:
      return { ...state, loadingCreate: false, errorCreate: action.payload };
    case COMMUNICATION_CREATE_FETCH_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        communicationData: action.payload,
        successCreated: true,
      };

    case COMMUNICATION_DELETE_FETCH:
      return { ...state, loadingDelete: true };
    case COMMUNICATION_DELETE_FETCH_FAIL:
      return { ...state, loadingDelete: false, errorDelete: action.payload };
    case COMMUNICATION_DELETE_FETCH_SUCCESS:
      return { ...state, loadingDelete: false };

    case COMMUNICATION_LAUNCH_FETCH:
      return { ...state, loadingLaunch: true };
    case COMMUNICATION_LAUNCH_FETCH_FAIL:
      return { ...state, loadingLaunch: false, errorLaunch: action.payload };
    case COMMUNICATION_LAUNCH_FETCH_SUCCESS:
      return {
        ...state,
        loadingLaunch: false,
        currentCommunication: action.payload,
      };

    case COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH:
      return { ...state, loadingEmailChannels: true };
    case COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH_FAIL:
      return {
        ...state,
        loadingEmailChannels: false,
        errorEmailChannels: action.payload,
      };
    case COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH_SUCCESS:
      return {
        ...state,
        loadingEmailChannels: false,
        emailChannels: action.payload,
      };

    case COMMUNICATION_LIST_EVENTS_FETCH:
      return { ...state, loadingEvents: true };
    case COMMUNICATION_LIST_EVENTS_FETCH_FAIL:
      return { ...state, loadingEvents: false, errorEvents: action.payload };
    case COMMUNICATION_LIST_EVENTS_FETCH_SUCCESS:
      return { ...state, loadingEvents: false, eventList: action.payload };

    case COMMUNICATION_LIST_PUSH_CHANNELS_FETCH:
      return { ...state, loadingPushChannels: true };
    case COMMUNICATION_LIST_PUSH_CHANNELS_FETCH_FAIL:
      return {
        ...state,
        loadingPushChannels: false,
        errorPushChannels: action.payload,
      };
    case COMMUNICATION_LIST_PUSH_CHANNELS_FETCH_SUCCESS:
      return {
        ...state,
        loadingPushChannels: false,
        pushChannels: action.payload,
      };

    case COMMUNICATION_COPY_FETCH_SUCCESS:
      return { ...state, loadingCopy: false };
    case COMMUNICATION_COPY_FETCH:
      return { ...state, loadingCopy: true, errorsCopy: [] };
    case COMMUNICATION_COPY_FETCH_FAIL:
      return {
        ...state,
        loadingCopy: false,
        errorsCopy: action.payload.errors,
      };

    case COMMUNICATION_LIST_SEGMENTS_FETCH:
      return { ...state, loadingSegment: true };
    case COMMUNICATION_LIST_SEGMENTS_FETCH_FAIL:
      return { ...state, loadingSegment: false, errorSegment: action.payload };
    case COMMUNICATION_LIST_SEGMENTS_FETCH_SUCCESS:
      return { ...state, loadingSegment: false, segments: action.payload };

    case COMMUNICATION_LIST_SMS_CHANNELS_FETCH:
      return { ...state, loadingSmsChannels: true };
    case COMMUNICATION_LIST_SMS_CHANNELS_FETCH_FAIL:
      return {
        ...state,
        loadingSmsChannels: false,
        errorSmsChannels: action.payload,
      };
    case COMMUNICATION_LIST_SMS_CHANNELS_FETCH_SUCCESS:
      return {
        ...state,
        loadingSmsChannels: false,
        smsChannels: action.payload,
      };

    case COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH:
      return { ...state, loadingCustomChannels: true };
    case COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH_FAIL:
      return {
        ...state,
        loadingCustomChannels: false,
        errorCustomChannels: action.payload,
      };
    case COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH_SUCCESS:
      return {
        ...state,
        loadingCustomChannels: false,
        customChannels: action.payload,
      };

    case COMMUNICATION_LIST_TAG_FETCH:
      return { ...state, loadingListTags: true };
    case COMMUNICATION_LIST_TAG_FETCH_FAIL:
      return {
        ...state,
        loadingListTags: false,
        errorsListTags: action.payload,
      };
    case COMMUNICATION_LIST_TAG_FETCH_SUCCESS:
      return { ...state, loadingListTags: false, listTags: action.payload };

    case COMMUNICATION_OVERVIEW_FETCH:
      return {
        ...state,
        loadingOverview: true,
        pageRequestOverview: action.payload,
      };
    case COMMUNICATION_OVERVIEW_FETCH_FAIL:
      return {
        ...state,
        loadingOverview: false,
        errorsOverview: action.payload,
      };
    case COMMUNICATION_OVERVIEW_FETCH_SUCCESS:
      return { ...state, loadingOverview: false, overview: action.payload };

    case COMMUNICATION_SPLIT_OVERVIEW_FETCH:
      return {
        ...state,
        loadingSplitOverview: true,
        pageRequestSplitOverview: action.payload,
      };
    case COMMUNICATION_SPLIT_OVERVIEW_FETCH_FAIL:
      return {
        ...state,
        loadingSplitOverview: false,
        errorsSplitOverview: action.payload,
      };
    case COMMUNICATION_SPLIT_OVERVIEW_FETCH_SUCCESS:
      return {
        ...state,
        loadingSplitOverview: false,
        splitOverview: action.payload,
      };

    case COMMUNICATION_SPLIT_OVERVIEW_PAGE_CHANGE_CURRENT:
      return { ...state, pageRequestSplitOverview: action.payload };

    case COMMUNICATION_PAUSE_FETCH:
      return { ...state, loadingPause: true };
    case COMMUNICATION_PAUSE_FETCH_FAIL:
      return { ...state, loadingPause: false, errorPause: action.payload };
    case COMMUNICATION_PAUSE_FETCH_SUCCESS:
      return {
        ...state,
        loadingPause: false,
        currentCommunication: action.payload,
      };

    case COMMUNICATION_STOP_FETCH:
      return { ...state, loadingStop: true };
    case COMMUNICATION_STOP_FETCH_FAIL:
      return { ...state, loadingStop: false, errorStop: action.payload };
    case COMMUNICATION_STOP_FETCH_SUCCESS:
      return {
        ...state,
        loadingStop: false,
        currentCommunication: action.payload,
      };

    case COMMUNICATION_TEST_FETCH:
      return { ...state, loadingTest: true };
    case COMMUNICATION_TEST_FETCH_FAIL:
      return { ...state, loadingTest: false, errorStop: action.payload };
    case COMMUNICATION_TEST_FETCH_SUCCESS:
      return { ...state, loadingTest: false };

    case COMMUNICATION_AUDIENCE_RESET:
      return { ...state, currentAudience: {} };

    case COMMUNICATION_UPDATE_AUDIENCE_FETCH:
      return { ...state, loadingUpdateAudience: true };
    case COMMUNICATION_UPDATE_AUDIENCE_FETCH_FAIL:
      return {
        ...state,
        loadingUpdateAudience: false,
        errorUpdateAudience: action.payload,
      };
    case COMMUNICATION_UPDATE_AUDIENCE_FETCH_SUCCESS:
      return {
        ...state,
        loadingUpdateAudience: false,
        currentAudience: action.payload,
      };

    case COMMUNICATION_CHANGE_CURRENT_AUDIENCE:
      return { ...state, currentAudience: action.payload };

    case COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH:
      return { ...state, loadingUpdateAudienceSubmit: true };
    case COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        loadingUpdateAudienceSubmit: false,
        errorUpdateAudienceSubmit: action.payload,
      };
    case COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH_SUCCESS:
      return {
        ...state,
        loadingUpdateAudienceSubmit: false,
        currentWhen: action.payload,
        errorUpdateAudienceSubmit: [],
      };

    case COMMUNICATION_UPDATE_CONVERSION_FETCH:
      return { ...state, loadingUpdateConversion: true };
    case COMMUNICATION_UPDATE_CONVERSION_FETCH_FAIL:
      return {
        ...state,
        loadingUpdateConversion: false,
        errorUpdateConversion: action.payload,
      };
    case COMMUNICATION_UPDATE_CONVERSION_FETCH_SUCCESS:
      return {
        ...state,
        loadingUpdateConversion: false,
        currentConversion: action.payload,
      };

    case COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH:
      return {
        ...state,
        loadingUpdateConversionSubmit: true,
        currentCommunication: null,
      };
    case COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        loadingUpdateConversionSubmit: false,
        errorUpdateConversionSubmit: action.payload,
      };
    case COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH_SUCCESS:
      return {
        ...state,
        loadingUpdateConversionSubmit: false,
        currentCommunication: action.payload,
      };

    case COMMUNICATION_UPDATE_MESSAGE_FETCH:
      return { ...state, loadingUpdateMessage: true };
    case COMMUNICATION_UPDATE_MESSAGE_FETCH_FAIL:
      return {
        ...state,
        loadingUpdateMessage: false,
        errorUpdateMessage: action.payload,
      };
    case COMMUNICATION_UPDATE_MESSAGE_FETCH_SUCCESS:
      return {
        ...state,
        loadingUpdateMessage: false,
        currentMessage: action.payload,
      };

    case COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH:
      return {
        ...state,
        loadingUpdateMessageSubmit: true,
        successUpdateMessageSubmit: null,
      };
    case COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        loadingUpdateMessageSubmit: false,
        errorUpdateMessageSubmit: action.payload,
      };
    case COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH_SUCCESS:
      return {
        ...state,
        loadingUpdateMessageSubmit: false,
        successUpdateMessageSubmit: true,
        currentConversion: action.payload,
      };

    case COMMUNICATION_UPDATE_WHEN_FETCH:
      return { ...state, loadingUpdateWhen: true };
    case COMMUNICATION_UPDATE_WHEN_FETCH_FAIL:
      return {
        ...state,
        loadingUpdateWhen: false,
        errorUpdateWhen: action.payload,
      };
    case COMMUNICATION_UPDATE_WHEN_FETCH_SUCCESS:
      return {
        ...state,
        loadingUpdateWhen: false,
        currentWhen: action.payload,
      };

    case COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH:
      return { ...state, loadingUpdateWhenSubmit: true };
    case COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        loadingUpdateWhenSubmit: false,
        errorUpdateWhenSubmit: action.payload,
      };
    case COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH_SUCCESS:
      return {
        ...state,
        loadingUpdateWhenSubmit: false,
        currentMessage: action.payload,
      };

    case COMMUNICATION_VIEW_FETCH:
      return { ...state, loadingView: true };
    case COMMUNICATION_VIEW_FETCH_FAIL:
      return {
        ...state,
        loadingView: false,
        errorUpdateWhenSubmit: action.payload,
      };
    case COMMUNICATION_VIEW_FETCH_SUCCESS:
      return {
        ...state,
        loadingView: false,
        currentCommunication: action.payload,
      };

    case COMMUNICATION_UPLOAD_FILE_FETCH:
      return { ...state, loadingUploadFile: true };
    case COMMUNICATION_UPLOAD_FILE_FETCH_FAIL:
      return {
        ...state,
        loadingUploadFile: false,
        errorsUploadFile: action.payload,
      };
    case COMMUNICATION_UPLOAD_FILE_FETCH_SUCCESS:
      return {
        ...state,
        loadingUploadFile: false,
        uploadedFile: action.payload,
      };
    case COMMUNICATION_UPLOAD_FILE_RESET:
      return { ...state, loadingUploadFile: false, uploadedFile: null };

    case COMMUNICATION_PAGE_CHANGE_CURRENT:
      return { ...state, pageRequest: action.payload };

    case COMMUNICATION_ENGAGED_USER_FETCH:
      return {
        ...state,
        communicationEngagedUserPageRequest: action.payload,
        loadingCommunicationEngagedUser: true,
      };
    case COMMUNICATION_ENGAGED_USER_FETCH_FAIL:
      return {
        ...state,
        loadingCommunicationEngagedUser: false,
        errorsCommunicationEngagedUser: action.payload,
      };
    case COMMUNICATION_ENGAGED_USER_FETCH_SUCCESS:
      return {
        ...state,
        communicationEngagedUser: action.payload,
        loadingCommunicationEngagedUser: false,
      };

    case COMMUNICATION_SEARCH_ENGAGED_USER_FETCH:
      return {
        ...state,
        communicationEngagedUserPageRequest: action.payload,
        loadingSearchCommunicationEngagedUser: true,
      };
    case COMMUNICATION_SEARCH_ENGAGED_USER_FETCH_FAIL:
      return {
        ...state,
        loadingSearchCommunicationEngagedUser: false,
        errorsCommunicationEngagedUser: action.payload,
      };
    case COMMUNICATION_SEARCH_ENGAGED_USER_FETCH_SUCCESS:
      return {
        ...state,
        communicationEngagedUser: action.payload,
        loadingSearchCommunicationEngagedUser: false,
      };

    case COMMUNICATION_ENGAGED_USER_PAGE_CHANGE_CURRENT:
      return { ...state, communicationEngagedUserPageRequest: action.payload };

    case COMMUNICATION_CHANGE_CURRENT:
      return { ...state, currentCommunication: action.payload };

    case COMMUNICATION_AUDIENCE_CLEAR_CREATE_ERROR:
      return { ...state, errorCreateCommunication: [], errorCreate: [] };
    case COMMUNICATION_AUDIENCE_CLEAR_UPDATE_ERROR:
      return { ...state, errorUpdateAudienceSubmit: [] };

    case COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH:
      return {
        ...state,
        segmentReport: action.payload,
        loadingSegmentReport: true,
      };
    case COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH_SUCCESS:
      return {
        ...state,
        segmentReport: action.payload,
        loadingSegmentReport: false,
      };
    case COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH_FAIL:
      return {
        ...state,
        segmentReport: action.payload,
        segmentReportError: action.payload,
        loadingSegmentReport: false,
      };
    case SEGMENT_REPORT_CHANGE_CURRENT:
      return { ...state, segmentReport: action.payload, errors: [] };
    case COMMUNICATION_ARCHIVE_FETCH:
      return { ...state, loadingArchive: true };
    case COMMUNICATION_ARCHIVE_FETCH_SUCCESS:
      return {
        ...state,
        loadingArchive: false,
        archiveSuccess: !state.archiveSuccess,
      };
    case COMMUNICATION_ARCHIVE_FETCH_FAIL:
      return {
        ...state,
        loadingArchive: false,
        errorsArchive: action.payload.errors,
      };
    case COMMUNICATION_ACTIVE_FETCH:
      return { ...state, loadingActivate: true };
    case COMMUNICATION_ACTIVE_FETCH_FAIL:
      return { ...state, loadingActivate: false };
    case COMMUNICATION_ACTIVE_FETCH_SUCCESS:
      return {
        ...state,
        loadingActivate: false,
        activeSuccess: !state.activeSuccess,
      };
    case COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH:
      return { ...state, loadingUpdateThrottlingWhen: true };
    case COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH_FAIL:
      return {
        ...state,
        loadingUpdateThrottlingWhen: false,
      };
    case COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH_SUCCESS:
      return {
        ...state,
        loadingUpdateThrottlingWhen: false,
        defaultThrottling: action.payload,
      };
    case WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH:
      return {
        ...state,
        loadingWhatsAppTemplate: true,
        whatsAppTemplatePageRequest: action.payload,
      };
    case WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH_FAIL:
      return {
        ...state,
        loadingWhatsAppTemplate: false,
        errorWhatsAppTemplate: action.payload,
      };
    case WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH_SUCCESS:
      return {
        ...state,
        loadingWhatsAppTemplate: false,
        whatsAppTemplate: action.payload,
      };
    case WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH:
      return {
        ...state,
        loadingWhatsAppTemplateObject: true,
      };
    case WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH_FAIL:
      return {
        ...state,
        loadingWhatsAppTemplateObject: false,
      };
    case WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH_SUCCESS:
      return {
        ...state,
        loadingWhatsAppTemplateObject: false,
        whatsAppTemplateObject: Object.assign(state.whatsAppTemplateObject, {
          [action.payload.index]: action.payload.response,
        }),
      };
    case WHATSAPP_CHANNEL_TEMPLAE_VIEW_CHANGE:
      return {
        ...state,
        whatsAppTemplateObject: Object.assign(state.whatsAppTemplateObject, {
          [action.payload.index]: action.payload.value,
        }),
      };
    case COMMUNICATION_FAILURES_REPORT_FETCH:
      return {
        ...state,
        loadingFailure: true,
      };
    case COMMUNICATION_FAILURES_REPORT_SUCCESS:
      return {
        ...state,
        loadingFailure: false,
        failures: action.payload.failures,
      };
    case COMMUNICATION_FAILURES_REPORT_FAIL:
      return {
        ...state,
        loadingFailure: false,
      };
    default:
      return state;
  }
}
