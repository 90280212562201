import React from 'react';
import { Button, Input, message, Modal, Select, Spin } from 'antd';
// import { SearchOutlined } from '@ant-design/icons';
// import { isEmpty } from 'lodash';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import { LoadingOutlined } from '@ant-design/icons';
import '../../../integration/productRestApi/style/ProductRestApiViewStyle.less';
import Card from '@Utils/Card';
import ApiTokenStatus from '@Modules/integration/productRestApiToken/model/ApiTokenStatus';
import { readableDateTime } from '@Utils/DateUtils';
import texts from '@Utils/texts';

const spinStyles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};

class ApiTokenViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      identifier: '',
      status: null,
      tokenDetailsModalView: false,
    };
  }

  componentDidMount() {
    this.props.apiTokenListFetch(this.props.pageRequest);
    this.props.productRestApiViewFetch();
  }

  componentWillUnmount() {
    this.props.apiTokenPageChangeCurrent({});
  }

  renderOptions = () => {
    const result = [];
    Object.keys(ApiTokenStatus).forEach((key) => {
      result.push(
        <Select.Option value={key}>{ApiTokenStatus[key]}</Select.Option>
      );
    });
    return result;
  };

  handleRevoke = (id) => {
    this.props.apiTokenRevokeFetch(id, this.props.pageRequest);
  };

  handleChangeIdentifier = (identifier) => {
    this.setState({ identifier });
    this.props.apiTokenListFetch({
      ...this.props.pageRequest,
      status: this.state.status,
      identifier: identifier,
    });
  };

  handleChangeStatus = (status) => {
    this.setState({ status });
    this.props.apiTokenListFetch({
      ...this.props.pageRequest,
      identifier: this.state.identifier,
      status: status,
    });
  };

  onOkModal = () => {
    this.props.apiTokenModalView(false);
  };

  handleCopyClick = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success('Token copied to clipboard');
      },
      (err) => {
        console.error('Unable to copy to clipboard', err);
        message.error('Failed to copy token');
      }
    );
  };

  renderDetailsModalContent = () => {
    return (
      <div className="column-content">
        <div className="item">
          <span className="item-title">Identifier</span>
          <span className="item-description">
            {this.props.createdTokenDetails?.identifier}
          </span>
        </div>
        <div className="item">
          <span className="item-title">Access Token</span>
          <Input
            style={{ width: '70%' }}
            disabled={true}
            value={this.props.createdTokenDetails?.access_token}
          />
          <Button
            onClick={() =>
              this.handleCopyClick(this.props.createdTokenDetails?.access_token)
            }
          >
            Copy
          </Button>
        </div>
        <div className="item">
          <span className="item-title">Expires In</span>
          <span className="item-description">
            {this.props.createdTokenDetails?.expires_in}
          </span>
        </div>
      </div>
    );
  };

  renderDetailsModal = () => {
    return (
      <Modal
        width={750}
        title={'Token Details'}
        centered
        visible={this.props.tokenDetailsModalView}
        onCancel={() => this.onOkModal()}
        footer={[
          <Button
            key="submit"
            type="primary"
            shape="round"
            onClick={() => this.onOkModal()}
          >
            {'OK'}
          </Button>,
        ]}
      >
        <div>{this.renderDetailsModalContent()}</div>
      </Modal>
    );
  };

  onCloseRevokeModal = () => {
    this.props.apiTokenRevokeAllModalToggle(false);
  };

  onConfirmRevokeAll = () => {
    this.props.apiTokenRevokeAllModalToggle(false);
    this.props.apiTokenRevokeAllFetch(this.props.pageRequest);
  };

  render() {
    const columnList = [
      {
        sorter: false,
        title: 'Identifier',
        dataIndex: 'identifier',
        key: 'createdDate',
        render: (x) => <span>{x}</span>,
      },
      {
        sorter: false,
        title: 'Created By',
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: (text) => <span>{text.presentation}</span>,
      },
      {
        sorter: false,
        title: 'Created Date',
        dataIndex: 'createdDate',
        key: 'createdDate',
        render: (date) => readableDateTime(date),
      },
      {
        sorter: false,
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'action',
        dataIndex: '',
        key: '-action-',
        render: (x) =>
          defaultActions(x, {
            action: (id) => this.handleRevoke(id),
            title: 'Revoke',
            enabled: true,
          }),
      },
    ];
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    // const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;

    return (
      <div
        style={{
          padding: 30,
          gap: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Card
          title="Project Credentials"
          linkHref={texts.restApi}
          linkText="VIEW DOCUMENTATION"
          loading={this.props.loadingProjectCredentials}
        >
          <>
            <div className="column-content">
              <div className="item">
                <span className="item-title">NAME</span>
                <span className="item-description">
                  {this.props.currentProductRestApi.name}
                </span>
              </div>
              <div className="item">
                <span className="item-title">LICENSE CODE</span>
                <span className="item-description">
                  {this.props.currentProductRestApi.licenceCode}
                </span>
              </div>
            </div>
          </>
        </Card>
        <Card>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
            }}
          >
            {/*<Input*/}
            {/*  loading={true}*/}
            {/*  allowClear={!this.props.loadingTokensList}*/}
            {/*  placeholder={'Search by Identifier'}*/}
            {/*  // eslint-disable-next-line max-len*/}
            {/*  onPressEnter={() =>*/}
            {/*    this.props.apiTokenListFetch({*/}
            {/*      ...this.props.pageRequest,*/}
            {/*      identifier: this.state.identifier,*/}
            {/*      status: this.state.status,*/}
            {/*    })*/}
            {/*  }*/}
            {/*  onChange={(e) => this.handleChangeIdentifier(e.target.value)}*/}
            {/*  value={this.state.identifier}*/}
            {/*  style={{ width: 300, marginRight: 30, borderRadius: 5 }}*/}
            {/*  suffix={*/}
            {/*    isEmpty(this.state.identifier) ? (*/}
            {/*      <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />*/}
            {/*    ) : (*/}
            {/*      this.props.loadingTokensList && (*/}
            {/*        <div style={spinStyles.inputLoadingSpinStyle}>*/}
            {/*          <Spin indicator={inputLoadingSpin} />*/}
            {/*        </div>*/}
            {/*      )*/}
            {/*    )*/}
            {/*  }*/}
            {/*/>*/}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Select
                onChange={(searchStatus) =>
                  this.handleChangeStatus(searchStatus)
                }
                value={this.state.status}
                defaultValue={null}
                style={{ width: 150 }}
                className={'chart-selector'}
                bordered={false}
              >
                <Select.Option value={null}>All Types</Select.Option>
                {this.renderOptions()}
              </Select>
            </div>
          </div>
          {this.props.loadingTokensList ? (
            <div style={spinStyles.loadingSpinStyle}>
              <Spin indicator={loadingSpin} />
            </div>
          ) : (
            <ListBase
              onChange={this.props.apiTokenListFetch}
              page={this.props.tokensList}
              pageRequest={this.props.pageRequest}
              columns={columnList}
              createLink={null}
            />
          )}
        </Card>
        {this.renderDetailsModal()}
      </div>
    );
  }
}

export default ApiTokenViewComponent;
