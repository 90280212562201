import PagesHeader from '@Component/layout/PagesHeader';
import PagesWrapper from '@Component/layout/PagesWrapper';
import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import UploadEventData from './components/UploadEventData';
import UploadUserData from './components/UploadUserData';

function DataManagement() {
  let match = useRouteMatch();
  return (
    <>
      <PagesHeader
        title="Data Management"
        subMenuItems={[
          { title: 'Upload User Data', route: `../users/upload` },
          { title: 'Upload Events Data', route: `../event/upload` },
        ]}
      />
      <PagesWrapper>
        <Switch>
          <Route exact path={`${match.path}users/upload`}>
            <UploadUserData />
          </Route>
          <Route path={`${match.path}event/upload`}>
            <UploadEventData />
          </Route>
        </Switch>
      </PagesWrapper>
    </>
  );
}

export default DataManagement;
