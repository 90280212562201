import BaseApi from '../../../api/BaseApi';

const basePathProduct = '/setting/product';
const basePathDnd = '/setting/dndTime';
const basePathFC = '/setting/frequencyCappings';
const basePathThrottling = '/setting/channelThrottling';
const basePathCustomDomain = '/setting/customCampaignUrl';
const basePathFile = '/base/file';
class ProductSettingApi {
  static productListTimezones() {
    return BaseApi.post(basePathProduct + '/listTimezones', {});
  }
  static productUpdate() {
    return BaseApi.post(basePathProduct + '/update', {});
  }
  static customaDomainUpdate() {
    return BaseApi.post(basePathCustomDomain + '/update', {});
  }
  static customDomainUpdateSubmit(body) {
    return BaseApi.post(basePathCustomDomain + '/updateSubmit', body);
  }
  static productUpdateSubmit(body) {
    return BaseApi.post(basePathProduct + '/updateSubmit', body);
  }
  static dndUpdate() {
    return BaseApi.post(basePathDnd + '/update', {});
  }
  static dndUpdateSubmit(body) {
    return BaseApi.post(basePathDnd + '/updateSubmit', body);
  }
  static fcUpdate() {
    return BaseApi.post(basePathFC + '/update', {});
  }
  static fcUpdateSubmit(body) {
    return BaseApi.post(basePathFC + '/updateSubmit', body);
  }
  static throttlingUpdate() {
    return BaseApi.post(basePathThrottling + '/update', {});
  }
  static throttlingUpdateSubmit(body) {
    return BaseApi.post(basePathThrottling + '/updateSubmit', body);
  }
  static fontList(body = {}) {
    return BaseApi.post('/engagement/font/list', body);
  }
  static uploadFont(file) {
    return BaseApi.uploadFile(basePathFile + '/upload', file);
  }
}

export default ProductSettingApi;
