import {
  AUTH_CURRENT_USER_FETCH_SUCCESS,
  AUTH_CURRENT_USER_FETCH,
  AUTH_CURRENT_USER_FETCH_FAIL,
  AUTH_LOGIN_CHANGE_CURRENT,
  AUTH_LOGIN_FETCH,
  AUTH_LOGIN_FETCH_FAIL,
  AUTH_LOGIN_FETCH_SUCCESS,
  AUTH_LOGOUT_FETCH_SUCCESS,
  CHANGE_MENU_LAYOUT,
  CHANGE_ACTIVE_PRODUCT,
  AUTH_VERIFICATION_CODE,
  AUTH_LOGIN_SECOND_STEP,
  AUTH_LOADING_RECAPTCHA,
} from './AuthConstants';

const initialState = {
  currentLogin: {},
  loadingCurrentUser: false,
  currentUser: {},
  loginLoading: false,
  loginErrors: [],
  // Opening and closing side menu is disabled, this field can be removed
  isMenuOpen: true,
  activeProduct: null,
  loginOTPCode: {
    loading: false,
    error: true,
  },
  loginSecondStepStatus: {},
  recaptchaLoading: false,
};

export default function AuthStateReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_ACTIVE_PRODUCT:
      return { ...state, activeProduct: action.payload };
    case CHANGE_MENU_LAYOUT:
      return { ...state, isMenuOpen: action.payload };
    case AUTH_CURRENT_USER_FETCH_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        loadingCurrentUser: false,
      };
    case AUTH_CURRENT_USER_FETCH:
      return { ...state, loadingCurrentUser: true };
    case AUTH_CURRENT_USER_FETCH_FAIL:
      return { ...state, loadingCurrentUser: false };
    case AUTH_LOGIN_CHANGE_CURRENT:
      return { ...state, currentLogin: action.payload, loginErrors: [] };
    case AUTH_LOGIN_FETCH_FAIL:
      return {
        ...state,
        loginLoading: false,
        loginErrors: action.payload.errors,
      };
    case AUTH_LOGIN_FETCH_SUCCESS:
      return { ...state, loginLoading: false, currentUser: action.payload };
    case AUTH_LOGIN_FETCH:
      return { ...state, loginLoading: true };
    case AUTH_LOGOUT_FETCH_SUCCESS:
      return { ...state, currentUser: { login: false }, activeProduct: null };
    case AUTH_VERIFICATION_CODE:
      return { ...state, loginOTPCode: action.payload };
    case AUTH_LOGIN_SECOND_STEP:
      return { ...state, loginSecondStepStatus: action.payload };
    case AUTH_LOADING_RECAPTCHA:
      return { ...state, recaptchaLoading: action.payload };
    default:
      return state;
  }
}
