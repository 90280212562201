import ListBase, { defaultActions } from '@Component/form/ListBase';
import { AuthorityProvider, hasAnyAuthority } from '@Utils/AuthorityProvider';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { history } from '../../../../redux/store';
import {
  releaseNoteDeleteFetch,
  releaseNoteFetchList,
} from '../ReleaseNoteAction';
import Card from '@Utils/Card';
const styles = {
  box: {
    padding: 20,
  },
  spin: {
    width: '100%',
    height: 100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
  },
};
export default function ReleaseNoteList() {
  const dispatch = useDispatch();
  const [noteName, setNoteName] = useState('');
  const pageRequest = useSelector(
    (state) => state.admin.releaseNote.pageRequest
  );
  const loadingReleaseNoteList = useSelector(
    (state) => state.admin.releaseNote.loadingReleaseNoteList
  );

  const page = useSelector((state) => state.admin.releaseNote.page);
  useEffect(() => {
    dispatch(
      releaseNoteFetchList({
        ...pageRequest,
        direction: 'DESC',
        order: 'id',
        page: 0,
      })
    );
  }, []);

  const columnList = [
    {
      sorter: false,
      title: 'Release Note Number',
      dataIndex: 'releaseNoteNumber',
      key: 'releaseNoteNumber',
      render: (text, record) => (
        <a onClick={() => history.push(`update/${record.id}`)}>{text}</a>
      ),
    },
    {
      sorter: false,
      title: 'Release Note Date',
      dataIndex: 'releaseDate',
      key: 'releaseDate',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: '-action-',
      width: '10%',
      render: (x) =>
        defaultActions(
          x,
          {
            link: 'update/' + x.id,
            title: 'Edit',
            enabled: hasAnyAuthority(
              AuthorityProvider.ROLE_ADMIN_PRODUCT_RELEASE_NOTES
            ),
          },

          {
            action: (id) => dispatch(releaseNoteDeleteFetch(id, pageRequest)),
            title: 'Delete',
            enabled: hasAnyAuthority(
              AuthorityProvider.ROLE_ADMIN_PRODUCT_RELEASE_NOTES
            ),
          }
        ),
    },
  ];
  return (
    <Card loading={loadingReleaseNoteList} styles={styles.box}>
      <Input
        style={{ width: 300, margin: 10 }}
        suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
        loading={true}
        placeholder={'Search by release note name'}
        onPressEnter={() =>
          dispatch(
            releaseNoteFetchList({
              ...pageRequest,
              page: 0,
              releaseNoteNumber: noteName,
            })
          )
        }
        onChange={(e) => setNoteName(e.target.value)}
        value={noteName}
      />
      <ListBase
        onChange={(p) => dispatch(releaseNoteFetchList(p))}
        // onClick={(row) => history.push(`update/${row.id}`)}
        page={page}
        pageRequest={pageRequest}
        columns={columnList}
      />{' '}
    </Card>
  );
}
