import React from 'react';
import { Input, Select, Form, Button } from 'antd';
import store from '../../../../redux/store';
import { getProductId } from '../../../../utils/AuthorityProvider';
import { find as lodashFind, isEmpty } from 'lodash';
import ProductSettingFooterComponent from './ProductSettingFooterComponent';
import isNil from 'lodash/isNil';
import { Upload, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { communicationUploadFileFetch } from '../../../engage/communication/CommunicationActions';
import Card from '@Utils/Card';
const styles = {
  loadingSpinStyle: {
    height: 187,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  settingBoxContainer: {
    padding: '40px 30px',
  },
};

class ProductSettingGeneralComponent extends React.Component {
  componentDidMount() {
    this.props.productUpdateFetch();
    this.props.productTimeZoneListFetch();
  }
  componentDidUpdate(prevProps) {
    if (
      !this.props.loadingUploadFile &&
      prevProps.loadingUploadFile &&
      this.props.uploadedFile &&
      this.props.uploadedFile.path
    ) {
      this.props.productChangeCurrent({
        ...this.props.currentProduct,
        defaultPushIcon: this.props.uploadedFile.path,
      });
    }
  }

  componentWillUnmount() {
    this.props.productChangeCurrent({});
  }
  checkValidImageUrl = (url) => {
    var types = ['jpg', 'jpeg', 'png', 'gif'];
    if (isNil(url) || isEmpty(url)) {
      return true;
    } else {
      var extension = url.split('.').pop();
      if (types.includes(extension)) {
        return true;
      }
    }
  };
  handleClickSave = () => {
    this.props.productUpdateSubmitFetch(this.props.currentProduct);
  };

  getDefaultProjectName = (currentUser) => {
    let pid = getProductId(store.getState().router.location.pathname);
    let productName = '';
    if (
      currentUser &&
      currentUser.login &&
      currentUser.products &&
      Array.isArray(currentUser.products) &&
      currentUser.products.length > 0 &&
      currentUser.products[0].id
    ) {
      productName = currentUser.products[0].name;
    }
    if (
      pid &&
      currentUser &&
      currentUser.login &&
      currentUser.products &&
      Array.isArray(currentUser.products) &&
      currentUser.products.length > 0
    ) {
      const foundItem = lodashFind(currentUser.products, (o) => o.id === pid);
      productName = foundItem.name ? foundItem.name : productName;
    }
    return productName;
  };

  render() {
    return (
      <div>
        <Card
          styles={styles.settingBoxContainer}
          loading={this.props.loadingUpdate && this.props.loadingZones}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: 16,
              paddingLeft: 40,
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                paddingBottom: 20,
              }}
            >
              <span
                style={{
                  color: '#707070',
                  fontSize: 14,
                  marginRight: 20,
                  width: 110,
                  textAlign: 'right',
                }}
              >
                PROJECT NAME
              </span>
              <Input
                style={{
                  position: 'relative',
                  borderRadius: 5,
                  top: -5,
                  width: 325,
                  paddingLeft: 10,
                  paddingRight: 10,
                  cursor: 'pointer',
                }}
                disabled
                value={this.getDefaultProjectName(this.props.currentUser)}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                paddingBottom: 20,
              }}
            >
              <span
                style={{
                  color: '#707070',
                  fontSize: 14,
                  marginRight: 20,
                  width: 110,
                  textAlign: 'right',
                }}
              >
                DESCRIPTION
              </span>
              <Input
                style={{
                  position: 'relative',
                  borderRadius: 5,
                  top: -5,
                  width: 325,
                  paddingLeft: 10,
                  paddingRight: 10,
                  cursor: 'pointer',
                }}
                value={
                  this.props.currentProduct &&
                  this.props.currentProduct.description
                }
                onChange={(e) =>
                  this.props.productChangeCurrent({
                    ...this.props.currentProduct,
                    description: e.target.value,
                  })
                }
              />
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                paddingBottom: 20,
              }}
            >
              <span
                style={{
                  color: '#707070',
                  fontSize: 14,
                  marginRight: 20,
                  width: 110,
                  textAlign: 'right',
                }}
              >
                TIMEZONE
              </span>
              <Select
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                onChange={(timezone) =>
                  this.props.productChangeCurrent({
                    ...this.props.currentProduct,
                    timezone,
                  })
                }
                value={
                  this.props.currentProduct &&
                  this.props.currentProduct.timezone
                }
                style={{ width: 325, position: 'relative', top: -5 }}
                className={'chart-selector'}
                bordered={false}
              >
                {this.props.zones &&
                  this.props.zones.zones &&
                  this.props.zones.zones.map((item) => (
                    <Select.Option value={item.key} key={item.key}>
                      {item.displayName}
                    </Select.Option>
                  ))}
              </Select>
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                paddingBottom: 20,
              }}
            >
              <span
                style={{
                  color: '#707070',
                  fontSize: 14,
                  marginRight: 20,
                  width: 110,
                  textAlign: 'right',
                }}
              >
                DEFAULT LOGO
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'relative',
                  top: -5,
                }}
              >
                <Form.Item
                  style={{
                    width: 325,
                    cursor: 'pointer',
                    margin: 0,
                  }}
                  validateStatus={
                    this.checkValidImageUrl(
                      this.props.currentProduct.defaultPushIcon
                    )
                      ? null
                      : 'warning'
                  }
                  help={
                    this.checkValidImageUrl(
                      this.props.currentProduct.defaultPushIcon
                    )
                      ? null
                      : 'Invalid Image URL'
                  }
                >
                  <Input
                    value={this.props.currentProduct.defaultPushIcon}
                    dir={'auto'}
                    onChange={(e) => {
                      this.props.productChangeCurrent({
                        ...this.props.currentProduct,
                        defaultPushIcon: e.target.value,
                      });
                    }}
                    placeholder="https://www.abc.com/image.png"
                  />
                </Form.Item>

                {this.props.currentProduct.defaultPushIcon &&
                !isNil(this.props.currentProduct.defaultPushIcon) ? (
                  <Button
                    type="primary"
                    shape="round"
                    ghost
                    onClick={() => {
                      this.props.productChangeCurrent({
                        ...this.props.currentProduct,
                        defaultPushIcon: '',
                      });
                    }}
                    size={'small'}
                    style={{
                      marginLeft: 15,
                      top: 5,
                    }}
                  >
                    Reset
                  </Button>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginLeft: 16 }}>or</span>
                    <Uploder
                      selector="defaultPushIcon"
                      logo={this.props.currentProduct?.defaultPushIcon}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
        <ProductSettingFooterComponent
          loading={this.props.loadingUpdateSubmit}
          handleClickSave={() => this.handleClickSave()}
        />
      </div>
    );
  }
}
const Uploder = ({ selector }) => {
  const dispatch = useDispatch();
  const acceptedImageUrlFormat = '.jpeg,.jpg,.png,.gif';
  const currentProduct = useSelector(
    (state) => state.setting.productSetting.currentProduct
  );
  const image = currentProduct.defaultPushIcon || null;

  const props = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload: (file) => {
      const validUrl =
        file.type.includes('png') ||
        file.type.includes('jpg') ||
        file.type.includes('jpeg');
      if (!validUrl) {
        message.error('Invalid Image URL');
      }
      return validUrl || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <Upload
      {...props}
      accept={acceptedImageUrlFormat}
      fileList={image ? [image] : []}
      id={selector}
      key={selector}
      onChange={(object) => {
        dispatch(
          communicationUploadFileFetch(object.fileList[0].originFileObj)
        );
      }}
    >
      <Button
        id={selector}
        key={selector}
        type="primary"
        shape="round"
        size={'small'}
        ghost
        style={{
          marginLeft: 16,
        }}
      >
        Upload
      </Button>
    </Upload>
  );
};

export default ProductSettingGeneralComponent;
