import { createSlice } from '@reduxjs/toolkit';
import { showMessageError } from '@Utils/RenderUtils';
import toastr from 'toastr';
import SystemAttributeApi from './systemAttributeApi';
const initialState = {
  page: {},
  loadingList: false,
  loadingSearchList: false,
  pageRequest: {},
  errors: [],

  currentSystemAttribute: {},

  errorsUpdate: [],
  loadingUpdate: false,

  errorsUpdateSubmit: [],
  loadingUpdateSubmit: false,
};

export const SystemAttributeSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    loadSystemAttributeListStart(state, { payload }) {
      state.pageRequest = payload;
      state.loadingList = true;
    },
    loadSystemAttributeListSuccess(state, { payload }) {
      state.page = payload;
      state.loadingList = false;
    },
    loadSystemAttributeListFail(state, { payload }) {
      state.errors = payload.errors;
      state.loadingList = false;
    },
    //
    loadSystemAttributeSearchListStart(state, { payload }) {
      state.pageRequest = payload;
      state.loadingSearchList = true;
    },
    loadSystemAttributeSearchListSuccess(state, { payload }) {
      state.page = payload;
      state.loadingSearchList = false;
    },
    loadSystemAttributeSearchListFail(state, { payload }) {
      state.errors = payload.errors;
      state.loadingSearchList = false;
    },
    //
    systemAttributePageChangeCurrent(state, { payload }) {
      state.pageRequest = payload;
    },
    //
    systemAttributeUpdateFetchStart(state) {
      state.loadingUpdate = true;
    },
    systemAttributeUpdateFetchSuccess(state, { payload }) {
      state.loadingUpdate = false;
      state.currentSystemAttribute = payload;
    },
    systemAttributeUpdateFetchFail(state, { payload }) {
      state.loadingUpdate = false;
      state.errors = payload.errors;
    },
    //
    systemAttributeUpdateSubmitFetchStart(state) {
      state.loadingUpdateSubmit = true;
    },
    systemAttributeUpdateSubmitFetchSuccess(state) {
      state.loadingUpdateSubmit = false;
    },
    systemAttributeUpdateSubmitFetchFail(state, { payload }) {
      state.loadingUpdateSubmit = false;
      state.errors = payload.errors;
    },
  },
});

export default SystemAttributeSlice.reducer;

export const {
  loadSystemAttributeListStart,
  loadSystemAttributeListSuccess,
  loadSystemAttributeListFail,
  loadSystemAttributeSearchListStart,
  loadSystemAttributeSearchListSuccess,
  loadSystemAttributeSearchListFail,
  systemAttributePageChangeCurrent,
  systemAttributeUpdateFetchStart,
  systemAttributeUpdateFetchSuccess,
  systemAttributeUpdateFetchFail,
  systemAttributeUpdateSubmitFetchStart,
  systemAttributeUpdateSubmitFetchSuccess,
  systemAttributeUpdateSubmitFetchFail,
} = SystemAttributeSlice.actions;

const loadSystemAttributeList =
  (systemAttributePageRequest) => async (dispatch) => {
    dispatch(loadSystemAttributeListStart(systemAttributePageRequest));
    try {
      const response = await SystemAttributeApi.list(
        systemAttributePageRequest
      );
      dispatch(loadSystemAttributeListSuccess(response));
    } catch (error) {
      dispatch(loadSystemAttributeListFail(error));
    }
  };

export const systemAttributeListFetch =
  (systemAttributePageRequest) => (dispatch) => {
    try {
      dispatch(loadSystemAttributeList(systemAttributePageRequest));
    } catch (error) {
      showMessageError(error);
    }
  };

const loadSystemAttributeSearchList =
  (systemAttributePageRequest) => async (dispatch) => {
    dispatch(loadSystemAttributeSearchListStart(systemAttributePageRequest));
    try {
      const response = await SystemAttributeApi.list(
        systemAttributePageRequest
      );
      dispatch(loadSystemAttributeSearchListSuccess(response));
    } catch (error) {
      dispatch(loadSystemAttributeSearchListFail(error));
    }
  };

export const systemAttributeSearchListFetch =
  (systemAttributePageRequest) => (dispatch) => {
    try {
      dispatch(loadSystemAttributeSearchList(systemAttributePageRequest));
    } catch (error) {
      showMessageError(error);
    }
  };

export const systemAttributeUpdateFetch = (id) => async (dispatch) => {
  dispatch(systemAttributeUpdateFetchStart());
  try {
    const response = await SystemAttributeApi.update(id);
    dispatch(systemAttributeUpdateFetchSuccess(response));
  } catch (error) {
    dispatch(systemAttributeUpdateFetchFail(error));
  }
};
export const systemAttributeUpdateSubmitFetch =
  (id, systemAttribute, pageRequest) => async (dispatch) => {
    dispatch(systemAttributeUpdateSubmitFetchStart());
    try {
      const response = await SystemAttributeApi.updateSubmit(
        id,
        systemAttribute
      );
      dispatch(systemAttributeUpdateSubmitFetchSuccess(response));
      toastr.success('Has been updated successfully');
      dispatch(loadSystemAttributeList(pageRequest));
    } catch (error) {
      dispatch(systemAttributeUpdateSubmitFetchFail(error));
      showMessageError(error);
    }
  };
