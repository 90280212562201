exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProfileCard-module__wrapper--xY3Rn {\n  direction: rtl;\n  margin-bottom: 16px;\n}\n.ProfileCard-module__type--lgGzS {\n  background-color: #F5F5F5;\n  margin-left: 4px;\n  margin-bottom: 4px;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.ProfileCard-module__type--lgGzS > span {\n  writing-mode: vertical-rl;\n}\n.ProfileCard-module__profile--1TUiI {\n  font-size: 12px;\n  text-align: right;\n  background-color: #FAFAFA;\n  padding: 16px 8px;\n}\n.ProfileCard-module__profileItem--1s0Q- {\n  padding: 4px;\n}\n.ProfileCard-module__profileItem--1s0Q- span:first-child {\n  display: inline-block;\n  position: relative;\n  padding-left: 8px;\n}\n.ProfileCard-module__profileItem--1s0Q- span:first-child:before {\n  content: ':';\n  position: absolute;\n  top: 0;\n  left: 4px;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "ProfileCard-module__wrapper--xY3Rn",
	"type": "ProfileCard-module__type--lgGzS",
	"profile": "ProfileCard-module__profile--1TUiI",
	"profileItem": "ProfileCard-module__profileItem--1s0Q-"
};