import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Select, Form, Button, Tooltip } from 'antd';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import RecurringSelector from '../../../form/RecurringSelector';
import { AppContext } from '../../app';

import {
  TriggerEnterExistState as userState,
  TriggerEnterExistOnStep,
} from '../../utils/static';
import openSnackBar from '../Atom/SnackBar';

const styles = {
  selectStyle: {
    width: '200px',
    marginRight: '15px',
  },
  recurringStyle: {
    paddingLeft: '12px',
    paddingBottom: '15px',
  },
  duplicateStyle: {
    paddingLeft: '12px',
  },
};

export const TriggerComponent = (props) => {
  const {
    title,
    enterExitType,
    onChangeState,
    collapseField,
    setCollapseField,
    segment,
    setSegment,
    recurringData,
    setRecurringData,
    // userStateFiltered,
  } = props;
  const { segments: segmentsData, attributes } = props.data;

  const { segmentReportViewContext } = useContext(AppContext);
  const segmentData = segmentReportViewContext?.data;
  const segmentLoading = segmentReportViewContext?.loading;
  //   const segmentError = segmentReportViewContext?.error;

  const [segmentInfo] = useState();
  const [segments, setSegments] = useState([]);
  const selectedSegment = segmentsData.find((im) => im.id === segment);

  useEffect(() => {
    const segmentsDataFiltered = segmentsData.filter(
      (item) => !item.test && item.status !== 'ARCHIVED'
    );
    if (selectedSegment?.status === 'ARCHIVED') {
      setSegments([...segmentsDataFiltered, selectedSegment]);
    } else {
      setSegments(segmentsDataFiltered);
    }
  }, [selectedSegment]);
  const archiveHint =
    selectedSegment?.status === 'ARCHIVED' ? (
      <span
        style={{
          fontSize: 10,
          color: 'red',
          paddingLeft: '5px',
          paddingTop: '5px',
          display: 'block',
        }}
      >
        {'This segment has been archived!'}
      </span>
    ) : (
      ''
    );
  // useEffect(() => {
  //   if (recurringData && selectedSegment?.type === 'STATIC') {
  //     setSegment(null);
  //   }
  // }, [recurringData]);

  const createTreeView = useCallback(() => {
    const trafficSegmentDto = segmentInfo?.trafficSegmentDto;
    let content = '';
    if (trafficSegmentDto) {
      let tree = {
        whose: ['Last seen contains a value', 'Reachable on PUSH_NOTIFICATION'],
        whoAre: ['New users'],
        whoHave: [
          'not performed the event, Checkout - Completed',
          'performed the event, Product - Category Viewed count greater than 0',
        ],
      };
      content = Object.keys(tree).map((key) => {
        return (
          <div className="tree-item" key={key}>
            <div className="tree-item__child">
              <div className="tr_arrow tree-item__label">{key}</div>
            </div>
            <div className="tree-item__list">
              {tree[key].map((im, i) => (
                <div className="tree-item__child" key={i}>
                  <div className="tr_arrow">{im}</div>
                </div>
              ))}
            </div>
          </div>
        );
      });
    }
    return <div>{content}</div>;
  }, []);

  const collapseFieldsOptions = useCallback(() => {
    const temp_ = [{ value: null, title: 'Nothing' }];
    attributes?.filter((attr) => {
      if (attr.name === 'phone' || attr.name === 'email') {
        temp_.push({
          title: attr.title,
          value: attr.id,
        });
      }
    });
    return temp_;
  }, [attributes]);

  const sumUsers = () => {
    return !_.isEmpty(segmentData)
      ? segmentData.unknownUsersCount + segmentData.knownUsersCount
      : 0;
  };

  const isDisabled = (eventItem) => {
    switch (enterExitType) {
      case userState[0].name:
      case userState[1].name:
      case userState[3].name:
      case TriggerEnterExistOnStep[0].name:
      case TriggerEnterExistOnStep[1].name:
        if (
          eventItem.rollingTimeWindow ||
          eventItem.type === 'STATIC' ||
          eventItem.type === 'STATIC_CSV'
        ) {
          return true;
        }
        break;
      // case userState[2].name:
      //   if (
      //     recurringData?.repeatType &&
      //     (eventItem.type === 'STATIC' || eventItem.type === 'STATIC_CSV')
      //   ) {
      //     return true;
      //   }
      //   break;
      // case userState[2].name:
      // return !!eventItem.rollingTimeWindow;
    }
    return false;
  };

  const userState_ = props?.userState || userState;

  return (
    <>
      <div className={'form-item'}>
        <label>{title}</label>
        <Select
          disabled={props.readOnly}
          style={styles.selectStyle}
          value={enterExitType}
          onChange={onChangeState}
        >
          {userState_.map((eventItem, k) => (
            <Select.Option key={k} value={eventItem.name}>
              {eventItem.displayName}
            </Select.Option>
          ))}
        </Select>
        <Select
          disabled={props.readOnly || !enterExitType}
          style={styles.selectStyle}
          value={segment}
          onChange={setSegment}
          showSearch
          placeholder="Select Segment"
          filterOption={(input, option) => {
            return (
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          loading={!segments?.length || segmentLoading}
        >
          {segments.map((eventItem, k) => (
            <Select.Option
              key={k}
              value={eventItem.id}
              disabled={isDisabled(eventItem)}
            >
              {eventItem.name}
            </Select.Option>
          ))}
        </Select>
        {!props?.userState ? (
          <Tooltip
            // old condition
            // title={`
            //   Segments that contain a rolling time window on
            //   a user profile attribute are disabled because
            //   they cannot be used to trigger journeys.
            //   Static Lists are available for selection only when “is already in”
            //   operator is used and recurring must be disabled.`}
            title={`
              In the Enter/Exit Trigger, Live segments that contain a rolling time window on an attribute are disabled in all variants except "is already in". 
              There is no constraint to choose any segment in the "is already" variant. `}
          >
            <InfoCircleOutlined />
          </Tooltip>
        ) : (
          ''
        )}
        {archiveHint}
      </div>
      <div className="enter_exit__recurring" style={styles.recurringStyle}>
        {enterExitType === userState[1].name ||
        enterExitType === userState[2].name ? (
          <>
            <RecurringSelector
              title=""
              data={recurringData}
              setData={setRecurringData}
              readOnly={props.readOnly}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      {enterExitType === userState[1].name ||
      enterExitType === userState[2].name ? (
        <div>
          <div style={styles.duplicateStyle}>
            <span>Remove duplicate with:</span>
            <Select
              disabled={props.readOnly}
              style={styles.selectStyle}
              value={collapseField}
              onChange={setCollapseField}
              showSearch
              placeholder="Select option"
            >
              {collapseFieldsOptions()?.map((option, k) => (
                <Select.Option key={k} value={option.value}>
                  {option.title}
                </Select.Option>
              ))}
            </Select>
          </div>
          <br />
        </div>
      ) : (
        ''
      )}
      {!segmentLoading ? (
        <div className="detail_segment">
          <div className="segment__info">
            <div>
              <div className={'stats__title'}>Total Users:</div>
              <div className={'stats__count'}>
                {(segmentData && Intl.NumberFormat().format(sumUsers() || 0)) ||
                  0}
              </div>
            </div>
            <div>
              <div className={'stats__title'}>Known Users:</div>
              <div className={'stats__count'}>
                {(segmentData &&
                  Intl.NumberFormat().format(
                    segmentData?.knownUsersCount || 0
                  )) ||
                  0}
              </div>
            </div>
            <div>
              <div className={'stats__title'}>Unknown Users:</div>
              <div className={'stats__count'}>
                {(segmentData &&
                  Intl.NumberFormat().format(
                    segmentData?.unknownUsersCount || 0
                  )) ||
                  0}
              </div>
            </div>
            <div>
              <div className={'stats__title'}>Reachable Users:</div>
              <div className={'stats__count'}>
                {/* <b> */}
                {segmentData ? (
                  <>
                    {Intl.NumberFormat().format(
                      (sumUsers() || 0) -
                        (segmentData?.unreachableUsersCount || 0)
                    )}
                    <Tooltip
                      placement={'top'}
                      title={() => (
                        <div>
                          Email: {segmentData?.emailReachableUsersCount || 0}
                          <br />
                          SMS: {segmentData?.phoneReachableUsersCount || 0}
                          <br />
                          Push: {segmentData?.pushReachableUsersCount || 0}
                          <br />
                          Web Push:{' '}
                          {segmentData?.webPushReachableUsersCount || 0}
                          <br />
                        </div>
                      )}
                    >
                      <i
                        className="fl-info"
                        style={{
                          color: '#bbbcc1',
                          fontSize: 24,
                          fontWeight: 400,
                          position: 'relative',
                          top: 6,
                        }}
                      ></i>
                    </Tooltip>
                  </>
                ) : null}
                {/* </b> */}
              </div>
            </div>
          </div>
          <div className="segment__tree">
            <div className="tree__title">
              Segment {selectedSegment && selectedSegment.name} contains all
              users
            </div>
            <div>{createTreeView()}</div>
          </div>
        </div>
      ) : (
        <div className="detail_segment">
          <LoadingOutlined style={{ fontSize: '2em' }} />
        </div>
      )}
    </>
  );
};

const TrEnterExitsContent = (props) => {
  const [form] = Form.useForm();
  const { journey, syncCache } = useContext(AppContext);
  // const { segments: segmentsData, attributes } = props.data;
  const currentStep = journey.steps.find(
    (step) => step.id + '' === props.node.id + ''
  );

  const [enterExitType, setEnterExitType] = useState(currentStep.enterExitType);
  const [segment, setSegment] = useState(currentStep.segment);
  const [collapseField, setCollapseField] = useState(
    currentStep.collapseField || null
  );
  const [recurringData, setRecurringData] = useState({
    repeatDays: currentStep.repeatDays,
    repeatTime: currentStep.repeatTime,
    repeatType: currentStep.repeatType,
    repeatEvery: currentStep.repeatEvery || 1,
  });

  const onFinish = () => {
    let isValid = true;
    if (!segment) {
      isValid = false;
    }
    if (recurringData.repeatType) {
      if (!recurringData.repeatTime || !recurringData.repeatEvery) {
        isValid = false;
      }
    }
    if (!isValid) {
      openSnackBar('error', {
        message: 'Error',
        description: 'Please fill all fields',
      });
      return;
    }
    const currentStepUpdated = Object.assign({}, currentStep, {
      enterExitType,
      segment,
      ...recurringData,
      collapseField,
    });
    // clean if has error
    _.unset(currentStepUpdated, 'ui.error');

    syncCache('update-step', currentStepUpdated, true);
    setTimeout(() => props.closeModal(false, true), 100);
  };

  useEffect(() => {
    if (segment) {
      props.getSegment(segment);
    }
  }, [segment]);

  const onChangeState = (value) => {
    setEnterExitType(value);
    setSegment(null);
    setRecurringData({});
  };

  return (
    <>
      <Form form={form} onFinish={onFinish} style={{ maxWidth: '100%' }}>
        <div className={'modal-body'}>
          <TriggerComponent
            {...props}
            title={'Trigger journey when user'}
            enterExitType={enterExitType}
            collapseField={collapseField}
            setCollapseField={setCollapseField}
            onChangeState={onChangeState}
            segment={segment}
            setSegment={setSegment}
            recurringData={recurringData}
            setRecurringData={setRecurringData}
          />
        </div>
        <div
          className={'modal-footer'}
          style={{ position: 'absolute', bottom: -52, width: '100%' }}
        >
          <Form.Item>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              disabled={props.readOnly}
            >
              Save
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};
export default TrEnterExitsContent;
