export const getLastItemFromPath = (path) => {
  const pathSegments = path.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];
  return lastSegment;
};

export const calculateNumberOfColumns = ({ startDate, endDate, timeFrame }) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  let numberOfColumns = 0;
  const daysDifference = Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;

  switch (timeFrame) {
    case 'DAY':
      numberOfColumns = daysDifference;
      break;
    case 'WEEK': {
      numberOfColumns = Math.ceil(daysDifference / 7);
      break;
    }
    case 'MONTH': {
      const maxDaysPerColumn = 30; // Maximum number of days per column
      numberOfColumns = Math.ceil(daysDifference / maxDaysPerColumn);
      break;
    }
    default:
      console.error('Invalid time frame');
  }

  return numberOfColumns;
};
