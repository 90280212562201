import React from 'react';
import { isNil } from 'lodash';
import {
  Button,
  Input,
  Spin,
  Popconfirm,
  message,
  Radio,
  Select,
  Upload,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import vars from '@Theme/styles/vars';
import {
  hasAnyAuthority,
  AuthorityProvider,
} from '../../../../utils/AuthorityProvider';
import Card from '@Utils/Card';

const styles = {
  spinStyle: {
    height: 280,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'right',
  },
  editFieldContainer: {
    flex: 6,
  },
  editField: {
    marginLeft: 20,
    width: 350,
  },
  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    display: 'flex',
    flexDirection: 'row',
  },
  infoBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 30px',
    borderBottom: '1px solid #e7e8e9',
    backgroundColor: '#fdfdfd',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dataRowContainer: {
    padding: 30,
  },
};

class PushChannelComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAndroidIndex: 0,
      selectedIosIndex: 0,
      showUploadButton: true,
      showFcmServerKeyUploadButton: true,
    };
    this.dataAndroid = { pushChannelType: 'APP', mobileDevice: 'ANDROID' };
    this.dataIos = { pushChannelType: 'APP', mobileDevice: 'IOS' };
    this.dataFirebase = { pushChannelType: 'WEB', webPushKeyType: 'FIREBASE' };
    this.dataBrowser = { pushChannelType: 'WEB', webPushKeyType: 'BROWSER' };
  }

  componentDidMount() {
    this.canCreateOrUpdate = hasAnyAuthority(
      AuthorityProvider.ROLE_PRODUCT_CHANNEL_PUSH_UPDATE,
      AuthorityProvider.ROLE_PRODUCT_CHANNEL_PUSH_CREATE
    );

    const productId = this.props.match?.params?.productId;
    const currentProduct = this.props.currentUser?.products?.find(
      (p) => p.id === +productId
    );

    this.props.pushChannelListFetch(this.dataAndroid);
    this.props.pushChannelListFetch(this.dataIos);

    if (
      currentProduct?.webPushConfigs?.managedByCustomer &&
      currentProduct?.webPushConfigs?.webPushKeyType === 'FIREBASE'
    ) {
      this.props.pushChannelListFetch(this.dataFirebase, this.dataIos);
    }
    if (
      currentProduct?.webPushConfigs?.managedByCustomer &&
      currentProduct?.webPushConfigs?.webPushKeyType === 'BROWSER'
    ) {
      this.props.pushChannelListFetch(this.dataBrowser, this.dataIos);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.loadingUploadFile &&
      prevProps.loadingUploadFile &&
      this.props.uploadedFile &&
      this.props.uploadedFile.path
    ) {
      if (
        this.props.currentIosPushChannel &&
        this.props.currentIosPushChannel.authType === 'AUTH_KEY'
      ) {
        this.props.pushChannelChangeCurrent(
          {
            ...this.props.currentIosPushChannel,
            authKeyFile: this.props.uploadedFile.path,
            authType: 'AUTH_KEY',
          },
          'IOS'
        );
      } else if (
        this.props.currentIosPushChannel &&
        this.props.currentIosPushChannel.authType !== 'AUTH_KEY'
      ) {
        this.props.pushChannelChangeCurrent(
          {
            ...this.props.currentIosPushChannel,
            certificateFile: this.props.uploadedFile.path,
          },
          'IOS'
        );
      } else if (
        this.props.currentAndroidPushChannel &&
        this.props.currentAndroidPushChannel.pushVersion === 'V1'
      ) {
        this.props.pushChannelChangeCurrent(
          {
            ...this.props.currentAndroidPushChannel,
            pushVersion: 'V1',
          },
          'ANDROID'
        );
      } else if (
        this.props.currentAndroidPushChannel &&
        this.props.currentAndroidPushChannel.pushVersion === 'LEGACY'
      ) {
        this.props.pushChannelChangeCurrent(
          {
            ...this.props.currentAndroidPushChannel,
            pushVersion: 'LEGACY',
          },
          'ANDROID'
        );
      }
    }
    if (prevProps.loadingUpdateSubmit && !this.props.loadingUpdateSubmit) {
      this.setState({ modalId: null });
    }

    if (
      this.state.selectedAndroidIndex === 0 &&
      prevProps.loadingAndroidList &&
      !this.props.loadingAndroidList &&
      this.props.androidList &&
      Array.isArray(this.props.androidList)
    ) {
      if (this.props.androidList.length === 0) {
        this.props.pushChannelChangeList(
          [
            {
              mobileDevice: 'ANDROID',
              pushVersion: 'V1',
              packageName: null,
              fcmServerKey: null,
              fcmServerKeyV1: null,
            },
          ],
          'ANDROID'
        );
      } else {
        const currentAndroidPushChannel = isNil(this.props.androidList[0])
          ? null
          : this.props.androidList[0];
        if (
          this.state?.editMode?.type !== 'ANDROID' &&
          currentAndroidPushChannel?.packageName
        ) {
          this.props.pushChannelChangeCurrent(
            currentAndroidPushChannel,
            'ANDROID'
          );
        }
      }
    }
    if (
      this.state.selectedIosIndex === 0 &&
      prevProps.loadingIosList &&
      !this.props.loadingIosList &&
      this.props.iosList &&
      Array.isArray(this.props.iosList)
    ) {
      if (this.props.iosList.length === 0) {
        this.props.pushChannelChangeList(
          [
            {
              mobileDevice: 'IOS',
              authKeyFile: null,
              authKeyId: null,
              authType: 'AUTH_KEY',
              certificateFile: null,
              defaultPushMode: 'DEVELOPMENT',
              packageName: null,
              pushPassword: null,
              teamId: null,
            },
          ],
          'IOS'
        );
      } else {
        const currentIosPushChannel = isNil(this.props.iosList[0])
          ? null
          : this.props.iosList[0];
        if (
          this.state?.editMode?.type !== 'IOS' &&
          (currentIosPushChannel?.authKeyId ||
            currentIosPushChannel?.certificateFile)
        ) {
          this.props.pushChannelChangeCurrent(currentIosPushChannel, 'IOS');
        }
      }
    }

    if (
      prevProps.toggleChangeAndroidList !==
        this.props.toggleChangeAndroidList &&
      this.props.androidList &&
      Array.isArray(this.props.androidList) &&
      this.props.androidList.length > 0
    ) {
      this.setState({
        selectedAndroidIndex: this.props.androidList.length - 1,
      });
    }
    if (
      prevProps.toggleChangeIosList !== this.props.toggleChangeIosList &&
      this.props.iosList &&
      Array.isArray(this.props.iosList) &&
      this.props.iosList.length > 0
    ) {
      this.setState({
        selectedIosIndex: this.props.iosList.length - 1,
      });
    }
  }

  handleClickCredential = (index, type) => {
    if (type === 'ANDROID') {
      this.setState(
        {
          selectedAndroidIndex: index,
          pushVersion: this.props.currentAndroidPushChannel.pushVersion,
        },
        () => {
          this.props.pushChannelChangeCurrent(
            this.props.androidList[index],
            type
          );
          this.forceUpdate();
        }
      );
    }
    if (type === 'IOS') {
      this.setState(
        {
          selectedIosIndex: index,
          authType: this.props.currentIosPushChannel.authType,
        },
        () => {
          this.props.pushChannelChangeCurrent(this.props.iosList[index], type);
          this.forceUpdate();
        }
      );
    }
  };

  removeCredential = (index, type) => {
    let foundId = null;
    const newState = {};
    if (
      type === 'ANDROID' &&
      this.props.androidList &&
      this.props.androidList[index]
    ) {
      foundId = this.props.androidList[index].id;
      if (!isNil(foundId)) {
        this.props.pushChannelDeleteFetch(foundId, this.dataAndroid);

        if (
          this.state?.editMode?.type === 'ANDROID' &&
          this.state?.editMode?.id === foundId
        ) {
          newState.editMode = {};
        }
        newState.selectedAndroidIndex = index > 0 ? index - 1 : 0;
        this.setState(newState);
      } else {
        const copyList = [...this.props.androidList];
        copyList.splice(index, 1);
        this.props.pushChannelChangeList(copyList, type);
      }
    }
    if (type === 'IOS' && this.props.iosList && this.props.iosList[index]) {
      foundId = this.props.iosList[index].id;
      if (!isNil(foundId)) {
        this.props.pushChannelDeleteFetch(foundId, this.dataIos);
        if (
          this.state?.editMode?.type === 'IOS' &&
          this.state?.editMode?.id === foundId
        ) {
          newState.editMode = {};
        }
        newState.selectedAndroidIndex = index > 0 ? index - 1 : 0;
        this.setState(newState);
      } else {
        const copyList = [...this.props.iosList];
        copyList.splice(index, 1);
        this.props.pushChannelChangeList(copyList, type);
      }
    }
  };

  addCredential = (type) => {
    if (type === 'ANDROID') {
      if (
        this.props.androidList &&
        this.props.androidList.length > 0 &&
        this.props.androidList[this.props.androidList.length - 1] &&
        this.props.androidList[this.props.androidList.length - 1].id
      ) {
        this.props.pushChannelChangeList(
          [...this.props.androidList].concat({
            mobileDevice: 'ANDROID',
            packageName: null,
            fcmServerKey: null,
            fcmServerKeyV1: null,
            pushVersion: 'V1',
          }),
          'ANDROID'
        );
        this.setState({ showFcmServerKeyUploadButton: true });
      } else {
        message.info('Complete current credential');
      }
    }
    if (type === 'IOS') {
      if (
        this.props.iosList &&
        this.props.iosList.length > 0 &&
        this.props.iosList[this.props.iosList.length - 1] &&
        this.props.iosList[this.props.iosList.length - 1].id
      ) {
        this.props.pushChannelChangeList(
          [...this.props.iosList].concat({
            mobileDevice: 'IOS',
            authKeyFile: null,
            authKeyId: null,
            authType: 'AUTH_KEY',
            certificateFile: null,
            defaultPushMode: 'DEVELOPMENT',
            packageName: null,
            pushPassword: null,
            teamId: null,
          }),
          'IOS'
        );
        this.setState({ showUploadButton: true });
      } else {
        message.info('Complete current credential');
      }
    }
  };

  handleClickSave = (mobileDeviceData) => {
    if (mobileDeviceData.mobileDevice === 'ANDROID') {
      if (
        this.props.currentAndroidPushChannel &&
        this.props.currentAndroidPushChannel.id
      ) {
        this.props.pushChannelUpdateSubmitFetch(
          this.props.currentAndroidPushChannel.id,
          {
            ...this.props.currentAndroidPushChannel,
            ...this.dataAndroid,
          },
          mobileDeviceData
        );
      } else {
        this.props.pushChannelCreateAndroidFetch(
          {
            ...this.props.currentAndroidPushChannel,
            mobileDevice: 'ANDROID',
            pushChannelType: 'APP',
          },
          mobileDeviceData
        );
      }
    } else if (mobileDeviceData.mobileDevice === 'IOS') {
      if (
        this.props.currentIosPushChannel &&
        this.props?.currentIosPushChannel?.id
      ) {
        this.props.pushIOSChannelUpdateSubmitFetch(
          this.props?.currentIosPushChannel?.id,
          {
            ...this.props.currentIosPushChannel,
            ...this.dataIos,
          },
          mobileDeviceData
        );
      } else {
        this.props.pushChannelCreateIosFetch(
          {
            ...this.props.currentIosPushChannel,
            mobileDevice: 'IOS',
            pushChannelType: 'APP',
          },
          mobileDeviceData
        );
      }
    } else if (mobileDeviceData.webPushKeyType === 'FIREBASE') {
      if (this.props?.currentfirebaseChannel?.id) {
        this.props.pushFirebaseChannelUpdateSubmitFetch(
          this.props?.currentfirebaseChannel?.id,
          {
            ...this.props?.currentfirebaseChannel,
            ...this.dataFirebase,
          },
          mobileDeviceData
        );
      } else {
        this.props.pushChannelCreateFirebaseFetch({
          ...this.props?.currentfirebaseChannel,
          webPushKeyType: 'FIREBASE',
          pushChannelType: 'WEB',
        });
      }
    } else if (mobileDeviceData.webPushKeyType === 'BROWSER') {
      if (this.props?.currentBrowserChannel?.id) {
        this.props.pushBrowserChannelUpdateSubmitFetch(
          this.props?.currentBrowserChannel?.id,
          {
            ...this.props?.currentBrowserChannel,
            ...this.dataBrowser,
          },
          mobileDeviceData
        );
      } else {
        this.props.pushChannelCreateBrowserFetch({
          ...this.props?.currentBrowserChannel,
          webPushKeyType: 'BROWSER',
          pushChannelType: 'WEB',
        });
      }
    }
  };

  renderUploader = () => {
    return (
      <Upload
        onChange={({ file }) => {
          if (file.status !== 'uploading') {
            this.props.communicationUploadFileFetch(file);
            this.setState({ showUploadButton: false });
            if (isNil(this.props.currentIosPushChannel)) {
              this.props.pushChannelChangeCurrent(
                {
                  ...this.props.currentIosPushChannel,
                  authType: 'AUTH_KEY',
                },
                'IOS'
              );
            }
          }
        }}
        beforeUpload={() => false}
      >
        <Button
          type="primary"
          shape="round"
          size={'small'}
          loading={this.props.loadingUploadFile}
          ghost
          style={{
            top:
              isNil(this.props.currentIosPushChannel) ||
              this.props.currentIosPushChannel.authType === 'AUTH_KEY'
                ? 0
                : 4,
          }}
        >
          Upload
        </Button>
      </Upload>
    );
  };

  renderFileReader = ({ isEditable }) => {
    return (
      <Upload
        accept="application/json"
        onChange={({ file }) => {
          this.handleJsonUpload({ file });
        }}
        beforeUpload={() => false}
      >
        {
          <Button
            type="primary"
            shape="round"
            size={'small'}
            disabled={!isEditable}
            loading={this.props.loadingAndroidUploadFile}
            ghost
          >
            Upload
          </Button>
        }
      </Upload>
    );
  };

  handleJsonUpload = ({ file }) => {
    if (file.status !== 'uploading') {
      this.setState({ showFcmServerKeyUploadButton: false });
      if (isNil(this.props.currentAndroidPushChannel)) {
        this.props.pushChannelChangeCurrent(
          {
            ...this.props.currentAndroidPushChannel,
            pushVersion: 'V1',
          },
          'ANDROID'
        );
      }
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const data = reader.result;
          this.props.currentAndroidPushChannel.fcmServerKeyV1 =
            JSON.parse(data);
          document.getElementById('fcmServerKeyV1').value = JSON.stringify(
            this.props.currentAndroidPushChannel.fcmServerKeyV1,
            null,
            2
          );
        } catch (e) {
          message.error('Invalid file format');
        }
      };
      reader.readAsText(file);
    }
  };

  renderTextAreaFcmServerKeyV1 = () => {
    return (
      <Input.TextArea
        id="fcmServerKeyV1"
        value={
          this.props.currentAndroidPushChannel.fcmServerKeyV1
            ? JSON.stringify(
                this.props.currentAndroidPushChannel.fcmServerKeyV1,
                null,
                2
              )
            : ''
        }
        readOnly
        rows={10}
        style={{
          marginTop: 20,
          marginLeft: 15,
        }}
        disabled={true}
      />
    );
  };

  renderAndroidCredential = () => {
    const isEditable =
      this.canCreateOrUpdate &&
      (!this.props?.currentAndroidPushChannel?.id ||
        (this.state.editMode?.type === 'ANDROID' &&
          this.state.editMode?.id ===
            this.props.currentAndroidPushChannel?.id));
    const antIcon = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    return this.props.loadingAndroidList ? (
      <div style={styles.spinStyle}>
        <Spin indicator={antIcon} />
      </div>
    ) : (
      <div style={styles.dataRowContainer}>
        <div>
          <div style={{ ...styles.editContainer, marginTop: 20 }}>
            <div style={styles.editLabel}>FCM API Version</div>
            <div style={styles.editFieldContainer}>
              <Radio.Group
                style={{ marginLeft: 20 }}
                value={
                  isNil(this.props.currentAndroidPushChannel)
                    ? 'V1'
                    : this.props.currentAndroidPushChannel.pushVersion
                }
                disabled={!isEditable}
                onChange={(e) => {
                  this.setState(() => {
                    this.props.pushChannelChangeCurrent(
                      {
                        ...this.props.currentAndroidPushChannel,
                        pushVersion: e.target.value,
                      },
                      'ANDROID'
                    );
                  });
                }}
              >
                <Radio value={'V1'}>Version 1</Radio>
                <Radio value={'LEGACY'}>Legacy Version</Radio>
              </Radio.Group>
            </div>
          </div>
          <div style={styles.editContainer}>
            <div style={styles.editLabel}>Package name</div>
            <div style={styles.editFieldContainer}>
              <Input
                value={
                  this.props.currentAndroidPushChannel &&
                  this.props.currentAndroidPushChannel.packageName
                }
                disabled={!isEditable}
                onChange={(e) =>
                  this.props.pushChannelChangeCurrent(
                    {
                      ...this.props.currentAndroidPushChannel,
                      packageName: e.target.value,
                    },
                    'ANDROID'
                  )
                }
                size="large"
                style={styles.editField}
              />
            </div>
          </div>
          {isNil(this.props.currentAndroidPushChannel) ||
          (this.props.currentAndroidPushChannel &&
            this.props.currentAndroidPushChannel.pushVersion &&
            this.props.currentAndroidPushChannel.pushVersion === 'V1') ? (
            <React.Fragment>
              <div style={{ ...styles.editContainer, marginTop: 20 }}>
                <div style={styles.editLabel}>FCM SERVER KEY (V1)</div>
                <div
                  style={{
                    ...styles.editFieldContainer,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {this.canCreateOrUpdate &&
                    !this.state.showFcmServerKeyUploadButton && (
                      <React.Fragment>
                        <span
                          style={{
                            paddingLeft: 15,
                            paddingRight: 15,
                            color: 'rgb(61, 64, 78)',
                            fontSize: 13,
                            lineHeight: '18.59px',
                          }}
                        ></span>
                        <Button
                          type="primary"
                          shape="round"
                          disabled={!isEditable}
                          onClick={() => {
                            this.setState({
                              showFcmServerKeyUploadButton: true,
                            });
                            this.props.currentAndroidPushChannel.fcmServerKeyV1 =
                              null;
                            document.getElementById('fcmServerKeyV1').value =
                              '';
                            this.props.pushChannelChangeCurrent(
                              {
                                ...this.props.currentAndroidPushChannel,
                                pushVersion: 'V1',
                              },
                              'ANDROID'
                            );
                          }}
                          size={'small'}
                          ghost
                        >
                          Reset
                        </Button>
                      </React.Fragment>
                    )}
                  {this.canCreateOrUpdate &&
                    this.state.showFcmServerKeyUploadButton && (
                      <React.Fragment>
                        <span
                          style={{
                            paddingLeft: 15,
                            paddingRight: 15,
                            color: 'rgb(61, 64, 78)',
                            fontSize: 13,
                            lineHeight: '18.59px',
                          }}
                        ></span>
                        {this.renderFileReader({ isEditable })}
                      </React.Fragment>
                    )}
                  {this.renderTextAreaFcmServerKeyV1()}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div style={{ ...styles.editContainer, marginTop: 20 }}>
                <div style={styles.editLabel}>FCM Server Key</div>
                <div style={styles.editFieldContainer}>
                  <Input
                    value={
                      this.canCreateOrUpdate
                        ? this.props?.currentAndroidPushChannel?.fcmServerKey ||
                          (this.props?.currentAndroidPushChannel?.id &&
                          !isEditable
                            ? '***'
                            : '')
                        : '***'
                    }
                    disabled={!isEditable}
                    onChange={(e) =>
                      this.props.pushChannelChangeCurrent(
                        {
                          ...this.props.currentAndroidPushChannel,
                          fcmServerKey: e.target.value,
                        },
                        'ANDROID'
                      )
                    }
                    size="large"
                    style={styles.editField}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
          <div style={{ ...styles.editContainer, marginTop: 20 }}>
            <div style={styles.editLabel} />
            <div style={styles.editFieldContainer}>
              {!this.props?.currentAndroidPushChannel?.id ||
              (this.state.editMode?.type === 'ANDROID' &&
                this.state.editMode?.id ===
                  this.props?.currentAndroidPushChannel?.id) ? (
                <>
                  <Button
                    type="primary"
                    shape="round"
                    loading={
                      this.props.loadingCreateAndroid ||
                      this.props.loadingUpdateSubmit
                    }
                    disabled={!this.canCreateOrUpdate}
                    size={'large'}
                    style={{ marginLeft: 20 }}
                    className="w-200"
                    onClick={() => {
                      this.handleClickSave(this.dataAndroid);
                      this.setState({
                        editMode: {},
                      });
                    }}
                  >
                    {this.props?.currentAndroidPushChannel &&
                    this.props?.currentAndroidPushChannel?.id
                      ? 'SAVE'
                      : 'ADD NEW'}
                  </Button>
                  {this.props?.currentAndroidPushChannel?.id && (
                    <Button
                      shape="round"
                      loading={
                        this.props.loadingCreateAndroid ||
                        this.props.loadingUpdateSubmit
                      }
                      disabled={!this.canCreateOrUpdate}
                      size={'large'}
                      style={{ marginLeft: 20 }}
                      className="w-200"
                      onClick={() => {
                        this.props.pushChannelFetchItem({
                          id: this.props?.currentAndroidPushChannel?.id,
                          type: 'ANDROID',
                          isEdit: false,
                        });
                        this.setState({
                          editMode: {},
                        });
                      }}
                    >
                      CANCEL
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  type="primary"
                  shape="round"
                  loading={
                    this.props.loadingCreateAndroid ||
                    this.props.loadingUpdateSubmit
                  }
                  disabled={!this.canCreateOrUpdate}
                  size={'large'}
                  style={{ marginLeft: 20 }}
                  className="w-200"
                  onClick={() => {
                    this.props.pushChannelFetchItem({
                      id: this.props.currentAndroidPushChannel?.id,
                      type: 'ANDROID',
                      isEdit: this.canCreateOrUpdate,
                    });

                    this.setState({
                      editMode: {
                        type: 'ANDROID',
                        id: this.props.currentAndroidPushChannel.id,
                      },
                    });
                  }}
                >
                  EDIT
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderFirebase = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const isEditable =
      this.canCreateOrUpdate &&
      (!this.props?.currentfirebaseChannel?.id ||
        (this.state.editMode?.type === 'FIREBASE' &&
          this.state.editMode?.id === this.props?.currentfirebaseChannel?.id));

    return this.props.loadingFirebaseList ? (
      <div style={styles.spinStyle}>
        <Spin indicator={antIcon} />
      </div>
    ) : (
      <div style={styles.dataRowContainer}>
        <div>
          <div style={{ ...styles.editContainer, marginTop: 20 }}>
            <div style={styles.editLabel}>FCM Server Key</div>
            <div style={styles.editFieldContainer}>
              <Input
                value={
                  this.canCreateOrUpdate
                    ? this.props?.currentfirebaseChannel?.fcmServerKey ||
                      (this.props?.currentfirebaseChannel?.id && !isEditable
                        ? '***'
                        : '')
                    : '***'
                }
                disabled={!isEditable}
                onChange={(e) => {
                  this.props.pushChannelChangeCurrent(
                    {
                      ...this.props?.currentfirebaseChannel,
                      fcmServerKey: e.target.value,
                    },
                    'FIREBASE'
                  );
                }}
                size="large"
                style={styles.editField}
              />
            </div>
          </div>
          <div style={{ ...styles.editContainer, marginTop: 20 }}>
            <div style={styles.editLabel} />
            <div style={styles.editFieldContainer}>
              {!this.props?.currentfirebaseChannel?.id ||
              (this.state.editMode?.type === 'FIREBASE' &&
                this.state.editMode?.id ===
                  this.props?.currentfirebaseChannel?.id) ? (
                <>
                  <Button
                    type="primary"
                    shape="round"
                    loading={this.props.loadingUpdateSubmit}
                    size={'large'}
                    style={{ marginLeft: 20 }}
                    className="w-200"
                    disabled={!this.canCreateOrUpdate}
                    onClick={() => {
                      this.handleClickSave(this.dataFirebase);
                      this.setState({ editMode: {} });
                    }}
                  >
                    {this.props?.currentfirebaseChannel?.id
                      ? 'SAVE'
                      : 'ADD NEW'}
                  </Button>
                  {this.props?.currentfirebaseChannel?.id && (
                    <Button
                      shape="round"
                      loading={this.props.loadingUpdateSubmit}
                      disabled={!this.canCreateOrUpdate}
                      size={'large'}
                      style={{ marginLeft: 20 }}
                      className="w-200"
                      onClick={() => {
                        this.props.pushChannelFetchItem({
                          id: this.props?.currentfirebaseChannel?.id,
                          type: 'FIREBASE',
                          isEdit: false,
                        });
                        this.setState({
                          editMode: {},
                        });
                      }}
                    >
                      CANCEL
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  type="primary"
                  shape="round"
                  loading={this.props.loadingUpdateSubmit}
                  disabled={!this.canCreateOrUpdate}
                  size={'large'}
                  style={{ marginLeft: 20 }}
                  className="w-200"
                  onClick={() => {
                    this.props.pushChannelFetchItem({
                      id: this.props?.currentfirebaseChannel?.id,
                      type: 'FIREBASE',
                      isEdit: this.canCreateOrUpdate,
                    });

                    this.setState({
                      editMode: {
                        type: 'FIREBASE',
                        id: this.props?.currentfirebaseChannel?.id,
                      },
                    });
                  }}
                >
                  EDIT
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderBrowser = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const isEditable =
      this.canCreateOrUpdate &&
      (!this.props?.currentBrowserChannel?.id ||
        (this.state.editMode?.type === 'BROWSER' &&
          this.state.editMode?.id === this.props?.currentBrowserChannel?.id));
    return this.props.loadingBrowserList ? (
      <div style={styles.spinStyle}>
        <Spin indicator={antIcon} />
      </div>
    ) : (
      <div style={styles.dataRowContainer}>
        <div>
          <div style={{ ...styles.editContainer, marginTop: 20 }}>
            <div style={styles.editLabel}>VAPID Public Key</div>
            <div style={styles.editFieldContainer}>
              <Input
                value={this.props?.currentBrowserChannel?.webPublicKey}
                disabled={!isEditable}
                onChange={(e) => {
                  this.props.pushChannelChangeCurrent(
                    {
                      ...this.props?.currentBrowserChannel,
                      webPublicKey: e.target.value,
                    },
                    'BROWSER'
                  );
                }}
                size="large"
                style={styles.editField}
              />
            </div>
          </div>
          <div style={{ ...styles.editContainer, marginTop: 20 }}>
            <div style={styles.editLabel}>VAPID private key</div>
            <div style={styles.editFieldContainer}>
              <Input
                value={
                  this.canCreateOrUpdate
                    ? this.props?.currentBrowserChannel?.webPrivateKey ||
                      (this.props?.currentBrowserChannel?.id && !isEditable
                        ? '***'
                        : '')
                    : '***'
                }
                disabled={!isEditable}
                onChange={(e) => {
                  this.props.pushChannelChangeCurrent(
                    {
                      ...this.props?.currentBrowserChannel,
                      webPrivateKey: e.target.value,
                    },
                    'BROWSER'
                  );
                }}
                size="large"
                style={styles.editField}
              />
            </div>
          </div>
          <div style={{ ...styles.editContainer, marginTop: 20 }}>
            <div style={styles.editLabel} />
            <div style={styles.editFieldContainer}>
              {!this.props?.currentBrowserChannel?.id ||
              (this.state.editMode?.type === 'BROWSER' &&
                this.state.editMode?.id ===
                  this.props?.currentBrowserChannel?.id) ? (
                <>
                  <Button
                    type="primary"
                    shape="round"
                    loading={
                      this.props.loadingCreateAndroid ||
                      this.props.loadingUpdateSubmit
                    }
                    size={'large'}
                    style={{ marginLeft: 20 }}
                    className="w-200"
                    disabled={!this.canCreateOrUpdate}
                    onClick={() => {
                      this.handleClickSave(this.dataBrowser);
                      this.setState({
                        editMode: {},
                      });
                    }}
                  >
                    {this.props?.currentBrowserChannel?.id ? 'SAVE' : 'ADD NEW'}
                  </Button>
                  {this.props?.currentBrowserChannel?.id && (
                    <Button
                      shape="round"
                      loading={
                        this.props.loadingCreateIos ||
                        this.props.loadingUpdateSubmit
                      }
                      disabled={!this.canCreateOrUpdate}
                      size={'large'}
                      style={{ marginLeft: 20 }}
                      className="w-200"
                      onClick={() => {
                        this.props.pushChannelFetchItem({
                          id: this.props?.currentBrowserChannel?.id,
                          type: 'BROWSER',
                          isEdit: false,
                        });
                        this.setState({
                          editMode: {},
                        });
                      }}
                    >
                      CANCEL
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  type="primary"
                  shape="round"
                  loading={
                    this.props.loadingCreateIos ||
                    this.props.loadingUpdateSubmit
                  }
                  disabled={!this.canCreateOrUpdate}
                  size={'large'}
                  style={{ marginLeft: 20 }}
                  className="w-200"
                  onClick={() => {
                    this.props.pushChannelFetchItem({
                      id: this.props?.currentBrowserChannel?.id,
                      type: 'BROWSER',
                      isEdit: this.canCreateOrUpdate,
                    });

                    this.setState({
                      editMode: {
                        type: 'BROWSER',
                        id: this.props?.currentBrowserChannel?.id,
                      },
                    });
                  }}
                >
                  EDIT
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderIosCredential = () => {
    const isEditable =
      this.canCreateOrUpdate &&
      (!this.props?.currentIosPushChannel?.id ||
        (this.state.editMode?.type === 'IOS' &&
          this.state.editMode?.id === this.props?.currentIosPushChannel?.id));
    const antIcon = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    return this.props.loadingIosList ? (
      <div style={styles.spinStyle}>
        <Spin indicator={antIcon} />
      </div>
    ) : (
      <div style={styles.dataRowContainer}>
        <div>
          <div style={{ ...styles.editContainer, marginTop: 20 }}>
            <div style={styles.editLabel}>APNS AUTHENTICATION TYPE</div>
            <div style={styles.editFieldContainer}>
              <Radio.Group
                style={{ marginLeft: 20 }}
                value={
                  isNil(this.props.currentIosPushChannel)
                    ? 'AUTH_KEY'
                    : this.props.currentIosPushChannel.authType
                }
                disabled={!isEditable}
                onChange={(e) => {
                  this.setState({ showUploadButton: true }, () => {
                    this.props.pushChannelChangeCurrent(
                      {
                        authKeyFile: null,
                        authKeyId: null,
                        certificateFile: null,
                        defaultPushMode: 'DEVELOPMENT',
                        packageName: null,
                        pushPassword: null,
                        teamId: null,
                        authType: e.target.value,
                      },
                      'IOS'
                    );
                  });
                }}
              >
                <Radio value={'AUTH_KEY'}>Auth Key</Radio>
                <Radio value={'CERTIFICATE'}>Certificate</Radio>
              </Radio.Group>
            </div>
          </div>
          {isNil(this.props.currentIosPushChannel) ||
          (this.props.currentIosPushChannel &&
            this.props.currentIosPushChannel.authType &&
            this.props.currentIosPushChannel.authType === 'AUTH_KEY') ? (
            <React.Fragment>
              <div style={{ ...styles.editContainer, marginTop: 20 }}>
                <div style={styles.editLabel}>AUTH KEY</div>
                <div
                  style={{
                    ...styles.editFieldContainer,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Input
                    loading={this.props.loadingUploadFile}
                    placeholder={'https://www.abc.com/document.p8'}
                    value={
                      this.canCreateOrUpdate
                        ? this.props?.currentIosPushChannel?.authKeyFile ||
                          (this.props?.currentIosPushChannel?.id && !isEditable
                            ? '***'
                            : '')
                        : '***'
                    }
                    disabled={!isEditable}
                    onChange={(e) =>
                      this.props.pushChannelChangeCurrent(
                        {
                          ...this.props.currentIosPushChannel,
                          authKeyFile: e.target.value,
                          authType: 'AUTH_KEY',
                        },
                        'IOS'
                      )
                    }
                    size="middle"
                    style={{ ...styles.editField, width: 350 }}
                  ></Input>
                  {!this.state.showUploadButton && (
                    <Button
                      type="primary"
                      shape="round"
                      disabled={!isEditable}
                      onClick={() => {
                        this.setState({ showUploadButton: true });
                        this.props.pushChannelChangeCurrent(
                          {
                            ...this.props.currentIosPushChannel,
                            authKeyFile: null,
                            authType: 'AUTH_KEY',
                          },
                          'IOS'
                        );
                      }}
                      size={'small'}
                      ghost
                      style={{
                        marginLeft: 15,
                      }}
                    >
                      Reset
                    </Button>
                  )}
                  {this.canCreateOrUpdate && this.state.showUploadButton && (
                    <React.Fragment>
                      <span
                        style={{
                          paddingLeft: 15,
                          paddingRight: 15,
                          color: 'rgb(61, 64, 78)',
                          fontSize: 13,
                          lineHeight: '18.59px',
                        }}
                      >
                        or
                      </span>
                      {this.renderUploader()}
                    </React.Fragment>
                  )}
                </div>
              </div>
              <div style={{ ...styles.editContainer, marginTop: 20 }}>
                <div style={styles.editLabel}>AUTH KEY ID</div>
                <div style={styles.editFieldContainer}>
                  <Input
                    value={
                      this.props.currentIosPushChannel &&
                      this.props.currentIosPushChannel.authKeyId
                    }
                    disabled={!isEditable}
                    onChange={(e) =>
                      this.props.pushChannelChangeCurrent(
                        {
                          ...this.props.currentIosPushChannel,
                          authKeyId: e.target.value,
                        },
                        'IOS'
                      )
                    }
                    size="large"
                    style={styles.editField}
                  />
                </div>
              </div>
              <div style={{ ...styles.editContainer, marginTop: 20 }}>
                <div style={styles.editLabel}>TEAM ID</div>
                <div style={styles.editFieldContainer}>
                  <Input
                    value={
                      this.props.currentIosPushChannel &&
                      this.props.currentIosPushChannel.teamId
                    }
                    disabled={!isEditable}
                    onChange={(e) =>
                      this.props.pushChannelChangeCurrent(
                        {
                          ...this.props.currentIosPushChannel,
                          teamId: e.target.value,
                        },
                        'IOS'
                      )
                    }
                    size="large"
                    style={styles.editField}
                  />
                </div>
              </div>
              <div style={{ ...styles.editContainer, marginTop: 20 }}>
                <div style={styles.editLabel}>BUNDLE IDENTIFIER</div>
                <div style={styles.editFieldContainer}>
                  <Input
                    value={
                      this.props.currentIosPushChannel &&
                      this.props.currentIosPushChannel.packageName
                    }
                    disabled={!isEditable}
                    onChange={(e) =>
                      this.props.pushChannelChangeCurrent(
                        {
                          ...this.props.currentIosPushChannel,
                          packageName: e.target.value,
                        },
                        'IOS'
                      )
                    }
                    size="large"
                    style={styles.editField}
                  />
                </div>
              </div>
              <div style={{ ...styles.editContainer, marginTop: 20 }}>
                <div style={styles.editLabel}>DEFAULT PUSH MODE</div>
                <div style={{ ...styles.editFieldContainer, marginLeft: 10 }}>
                  <Select
                    value={
                      this.props.currentIosPushChannel
                        ? this.props.currentIosPushChannel.defaultPushMode
                        : 'DEVELOPMENT'
                    }
                    disabled={!isEditable}
                    onChange={(value) =>
                      this.props.pushChannelChangeCurrent(
                        {
                          ...this.props.currentIosPushChannel,
                          defaultPushMode: value,
                        },
                        'IOS'
                      )
                    }
                    style={{ marginLeft: 10, width: 150 }}
                  >
                    <Select.Option value={'DEVELOPMENT'} key={'DEVELOPMENT'}>
                      Development
                    </Select.Option>
                    <Select.Option value={'PRODUCTION'} key={'PRODUCTION'}>
                      Production
                    </Select.Option>
                  </Select>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div style={{ ...styles.editContainer, marginTop: 20 }}>
                <div style={styles.editLabel}>PUSH CERTIFICATE</div>
                <div style={styles.editFieldContainer}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Input
                      placeholder={'https://www.abc.com/document.p12'}
                      value={
                        this.canCreateOrUpdate
                          ? this.props?.currentIosPushChannel
                              ?.certificateFile ||
                            (this.props?.currentIosPushChannel?.id &&
                            !isEditable
                              ? '***'
                              : '')
                          : '***'
                      }
                      disabled={!isEditable}
                      onChange={(e) =>
                        this.props.pushChannelChangeCurrent(
                          {
                            ...this.props.currentIosPushChannel,
                            certificateFile: e.target.value,
                            authType: 'CERTIFICATE',
                          },
                          'IOS'
                        )
                      }
                      size="middle"
                      style={{ ...styles.editField, width: 350 }}
                    ></Input>
                    {this.canCreateOrUpdate && !this.state.showUploadButton && (
                      <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                          this.setState({ showUploadButton: true });
                          this.props.pushChannelChangeCurrent(
                            {
                              ...this.props.currentIosPushChannel,
                              certificateFile: null,
                              authType: 'CERTIFICATE',
                            },
                            'IOS'
                          );
                        }}
                        size={'small'}
                        ghost
                        style={{
                          position: 'realtive',
                          top: 4,
                          marginLeft: 15,
                        }}
                      >
                        Reset
                      </Button>
                    )}
                    {this.canCreateOrUpdate && this.state.showUploadButton && (
                      <React.Fragment>
                        <span
                          style={{
                            paddingLeft: 15,
                            paddingRight: 15,
                            color: 'rgb(61, 64, 78)',
                            fontSize: 13,
                            lineHeight: '18.59px',
                            marginTop: '5px',
                          }}
                        >
                          or
                        </span>
                        {this.renderUploader()}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ ...styles.editContainer, marginTop: 20 }}>
                <div style={styles.editLabel}>PUSH PASSWORD</div>
                <div style={styles.editFieldContainer}>
                  <Input
                    value={
                      this.canCreateOrUpdate
                        ? this.props?.currentIosPushChannel?.pushPassword ||
                          (this.props?.currentIosPushChannel?.id && !isEditable
                            ? '***'
                            : '')
                        : '***'
                    }
                    disabled={!isEditable}
                    onChange={(e) =>
                      this.props.pushChannelChangeCurrent(
                        {
                          ...this.props.currentIosPushChannel,
                          pushPassword: e.target.value,
                        },
                        'IOS'
                      )
                    }
                    size="large"
                    style={styles.editField}
                  />
                </div>
              </div>
              <div style={{ ...styles.editContainer, marginTop: 20 }}>
                <div style={styles.editLabel}>BUNDLE IDENTIFIER</div>
                <div style={styles.editFieldContainer}>
                  <Input
                    value={
                      this.props.currentIosPushChannel &&
                      this.props.currentIosPushChannel.packageName
                    }
                    disabled={!isEditable}
                    onChange={(e) =>
                      this.props.pushChannelChangeCurrent(
                        {
                          ...this.props.currentIosPushChannel,
                          packageName: e.target.value,
                        },
                        'IOS'
                      )
                    }
                    size="large"
                    style={styles.editField}
                  />
                </div>
              </div>
              <div style={{ ...styles.editContainer, marginTop: 20 }}>
                <div style={styles.editLabel}>DEFAULT PUSH MODE</div>
                <div style={{ ...styles.editFieldContainer, marginLeft: 10 }}>
                  <Select
                    value={
                      this.props.currentIosPushChannel
                        ? this.props.currentIosPushChannel.defaultPushMode
                        : 'DEVELOPMENT'
                    }
                    disabled={!isEditable}
                    onChange={(value) =>
                      this.props.pushChannelChangeCurrent(
                        {
                          ...this.props.currentIosPushChannel,
                          defaultPushMode: value,
                        },
                        'IOS'
                      )
                    }
                    style={{ marginLeft: 10, width: 150 }}
                  >
                    <Select.Option value={'DEVELOPMENT'} key={'DEVELOPMENT'}>
                      Development
                    </Select.Option>
                    <Select.Option value={'PRODUCTION'} key={'PRODUCTION'}>
                      Production
                    </Select.Option>
                  </Select>
                </div>
              </div>
            </React.Fragment>
          )}

          <div style={{ ...styles.editContainer, marginTop: 20 }}>
            <div style={styles.editLabel} />
            <div style={styles.editFieldContainer}>
              {!this.props?.currentIosPushChannel?.id ||
              (this.state.editMode?.type === 'IOS' &&
                this.state.editMode?.id ===
                  this.props?.currentIosPushChannel?.id) ? (
                <>
                  <Button
                    type="primary"
                    shape="round"
                    loading={
                      this.props.loadingCreateIos ||
                      this.props.loadingIosUpdateSubmit
                    }
                    size={'large'}
                    style={{ marginLeft: 20 }}
                    className="w-200"
                    disabled={!this.canCreateOrUpdate}
                    onClick={() => {
                      this.handleClickSave(this.dataIos);
                      this.setState({
                        editMode: {},
                      });
                    }}
                  >
                    {this.props.currentIosPushChannel &&
                    this.props?.currentIosPushChannel?.id
                      ? 'SAVE'
                      : 'ADD NEW'}
                  </Button>
                  {this.props?.currentIosPushChannel?.id && (
                    <Button
                      shape="round"
                      loading={
                        this.props.loadingCreateIos ||
                        this.props.loadingUpdateSubmit
                      }
                      disabled={!this.canCreateOrUpdate}
                      size={'large'}
                      style={{ marginLeft: 20 }}
                      className="w-200"
                      onClick={() => {
                        this.props.pushChannelFetchItem({
                          id: this.props?.currentIosPushChannel?.id,
                          type: 'IOS',
                          isEdit: false,
                        });
                        this.setState({
                          editMode: {},
                        });
                      }}
                    >
                      CANCEL
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  type="primary"
                  shape="round"
                  loading={
                    this.props.loadingCreateIos ||
                    this.props.loadingUpdateSubmit
                  }
                  disabled={!this.canCreateOrUpdate}
                  size={'large'}
                  style={{ marginLeft: 20 }}
                  className="w-200"
                  onClick={() => {
                    this.props.pushChannelFetchItem({
                      id: this.props.currentIosPushChannel?.id,
                      type: 'IOS',
                      isEdit: this.canCreateOrUpdate,
                    });

                    this.setState({
                      editMode: {
                        type: 'IOS',
                        id: this.props?.currentIosPushChannel?.id,
                      },
                    });
                  }}
                >
                  EDIT
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderCredentialBar = (type, selectedIndex, credentials) => {
    let activeIndex = selectedIndex;
    return (
      <div
        style={{
          borderBottom: '1px solid #e7e8e9',
          width: '100%',
          backgroundColor: '#FFFFFF',
          padding: '0 15px',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        {credentials.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: '3px solid',
                  borderColor:
                    activeIndex === index ? vars.primaryColor : '#fff',
                  width: credentials.length === 1 ? 120 : 160,
                  marginLeft: 20,
                  paddingRight: 5,
                  paddingLeft: 5,
                }}
              >
                <div
                  id={'credential' + index}
                  onClick={() => this.handleClickCredential(index, type)}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}
                >
                  <span
                    style={{
                      color: '#3d404e',
                      fontWeight: '500',
                      lineHeight: '60px',
                    }}
                  >
                    {'Credential ' + (index + 1)}
                  </span>
                </div>
                {this.canCreateOrUpdate &&
                  (selectedIndex > 0 ||
                    (selectedIndex === 0 && credentials[0]?.id)) > 0 && (
                    <Popconfirm
                      title="Are you sure to delete this credential?"
                      onConfirm={() => this.removeCredential(index, type)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <CloseOutlined
                        style={{
                          color: '#bbbcc1',
                          fontSize: 16,
                          paddingLeft: 5,
                          cursor: 'pointer',
                        }}
                      />
                    </Popconfirm>
                  )}
              </div>
              {this.canCreateOrUpdate &&
                index === credentials.length - 1 &&
                credentials.length < 5 && (
                  <i
                    onClick={() => this.addCredential(type)}
                    className="fl-add-circle"
                    style={{
                      display: 'flex',
                      alignSelf: 'center',
                      fontSize: '40px',
                      color: vars.primaryColor,
                      marginLeft: 10,
                      cursor: 'pointer',
                    }}
                  ></i>
                )}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  render() {
    const productId = this.props.match?.params?.productId;
    const currentProduct = this.props.currentUser?.products?.find(
      (p) => p.id === +productId
    );

    return (
      <div>
        <Card
          styles={{
            marginBottom: 20,
          }}
          title="Android"
        >
          {this.renderCredentialBar(
            'ANDROID',
            this.state.selectedAndroidIndex,
            this.props.androidList
          )}
          {this.renderAndroidCredential()}
        </Card>
        <Card
          styles={{
            marginBottom: 20,
          }}
          title="IOS"
        >
          {this.renderCredentialBar(
            'IOS',
            this.state.selectedIosIndex,
            this.props.iosList
          )}
          {this.renderIosCredential()}
        </Card>
        {currentProduct?.webPushConfigs?.managedByCustomer &&
          currentProduct?.webPushConfigs?.webPushKeyType === 'FIREBASE' && (
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 5,
                boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  padding: 16,
                  borderBottom: 'solid 2px #f4f9ff',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <div
                  style={{ fontSize: 16, color: 'black', fontWeight: '600' }}
                >
                  WEB PUSH
                </div>
              </div>
              {this.renderFirebase()}
            </div>
          )}
        {currentProduct?.webPushConfigs?.managedByCustomer &&
          currentProduct?.webPushConfigs.webPushKeyType === 'BROWSER' && (
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: 5,
                boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  padding: 16,
                  borderBottom: 'solid 2px #f4f9ff',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <div
                  style={{ fontSize: 16, color: 'black', fontWeight: '600' }}
                >
                  WEB PUSH
                </div>
              </div>
              {this.renderBrowser()}
            </div>
          )}
      </div>
    );
  }
}

export default PushChannelComponent;
