import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import './style.less';

const ComponentWrap = (props) => {
  return (
    <div>
      {props.loading ? (
        <div className="cw-spin-wrap">
          <Spin style={{ display: 'flex', alignSelf: 'center' }} />
        </div>
      ) : (
        props.children
      )}
    </div>
  );
};
ComponentWrap.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

export default ComponentWrap;
