import { showMessageError } from '../../../utils/RenderUtils';
import moment from 'moment';
import {
  UNINSTALL_OVERVIEW_FETCH_SUCCESS,
  UNINSTALL_OVERVIEW_FETCH,
  UNINSTALL_OVERVIEW_FETCH_FAIL,
  UNINSTALL_OVERVIEW_CHANGE_VALUE,
  UNINSTALL_USERS_CHANGE_VALUE,
  UNINSTALL_USERS_FETCH,
  UNINSTALL_USERS_FETCH_SUCCESS,
  UNINSTALL_USERS_FETCH_FAIL,
  UNINSTALL_ANALYZE_FETCH_LIST_FAIL,
  UNINSTALL_ANALYZE_FETCH_LIST_SUCCESS,
  UNINSTALL_ANALYZE_FETCH_LIST,
  UNINSTALL_ANALYZE_CHANGE_VALUE,
  UNINSTALL_ANALYZE_FETCH_FAIL,
  UNINSTALL_ANALYZE_FETCH,
  UNINSTALL_ANALYZE_FETCH_SUCCESS,
} from './UninstallConstants';
import UninstallsApi from './UninstallsApi';

const lastWeek = moment().subtract(7, 'days');
const initialState = {
  loadingOverview: false,
  uninstallOverview: {},
  uninstallOverviewInterval: { fromDate: lastWeek, toDate: moment() },
  uninstallUsersFilter: {
    fromDate: lastWeek,
    toDate: moment(),
    page: 0,
    pageSize: 10,
    query: '',
  },
  uninstallUsers: {},
  uninstallAnalyze: {},
  uninstallAnalyzeFilter: {
    fromDate: lastWeek,
    toDate: moment(),
    over: '',
    split: '',
  },
  attributes: [],
};

export default function ProductSettingStateReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case UNINSTALL_OVERVIEW_FETCH_SUCCESS:
      return {
        ...state,
        loadingOverview: false,
        uninstallOverview: action.payload,
      };
    case UNINSTALL_ANALYZE_FETCH_LIST_SUCCESS:
      return {
        ...state,
        loadingOverview: false,
        attributes: action.payload,
      };

    case UNINSTALL_OVERVIEW_FETCH:
      return { ...state, loadingOverview: true };
    case UNINSTALL_OVERVIEW_FETCH_FAIL:
      return { ...state, loadingOverview: false };
    case UNINSTALL_OVERVIEW_CHANGE_VALUE:
      return { ...state, uninstallOverviewInterval: action.payload };
    case UNINSTALL_USERS_CHANGE_VALUE:
      return { ...state, uninstallUsersFilter: action.payload };
    case UNINSTALL_USERS_FETCH:
      return { ...state, loadingOverview: true };
    case UNINSTALL_USERS_FETCH_FAIL:
      return { ...state, loadingOverview: false };
    case UNINSTALL_USERS_FETCH_SUCCESS:
      return {
        ...state,
        loadingOverview: false,
        uninstallUsers: action.payload,
      };
    case UNINSTALL_ANALYZE_FETCH_LIST:
      return { ...state, loadingOverview: true };
    case UNINSTALL_ANALYZE_FETCH_LIST_FAIL:
      return { ...state, loadingOverview: false };
    case UNINSTALL_ANALYZE_CHANGE_VALUE:
      return { ...state, uninstallAnalyzeFilter: action.payload };
    case UNINSTALL_ANALYZE_FETCH:
      return { ...state, loadingOverview: true };
    case UNINSTALL_ANALYZE_FETCH_SUCCESS:
      return {
        ...state,
        loadingOverview: false,
        uninstallAnalyze: action.payload,
      };
    case UNINSTALL_ANALYZE_FETCH_FAIL:
      return { ...state, loadingOverview: false };

    default:
      return state;
  }
}

export function uninstallOverviewFetch(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: UNINSTALL_OVERVIEW_FETCH,
      payload: pageRequest,
    });
    UninstallsApi.uninstallOverview(pageRequest)
      .then((response) => {
        dispatch({
          type: UNINSTALL_OVERVIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: UNINSTALL_OVERVIEW_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function uninstallOverviewChangeCurrent(uninstallOverviewInterval) {
  return (dispatch) => {
    dispatch({
      type: UNINSTALL_OVERVIEW_CHANGE_VALUE,
      payload: uninstallOverviewInterval,
    });
  };
}

export function uninstallUsersFetch(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: UNINSTALL_USERS_FETCH,
    });
    UninstallsApi.uninstallUsers(pageRequest)
      .then((response) => {
        dispatch({
          type: UNINSTALL_USERS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: UNINSTALL_USERS_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function uninstallUsersChangeCurrent(uninstallUsersFilter) {
  return (dispatch) => {
    dispatch({
      type: UNINSTALL_USERS_CHANGE_VALUE,
      payload: uninstallUsersFilter,
    });
  };
}

export function uninstallAnalyzeFetchAttribute(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: UNINSTALL_ANALYZE_FETCH_LIST,
      payload: pageRequest,
    });
    UninstallsApi.uninstallList()
      .then((response) => {
        dispatch({
          type: UNINSTALL_ANALYZE_FETCH_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: UNINSTALL_ANALYZE_FETCH_LIST_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function uninstallAnalyzeFetch(body) {
  return (dispatch) => {
    dispatch({
      type: UNINSTALL_ANALYZE_FETCH,
      payload: body,
    });
    UninstallsApi.uninstallAnalyze(body)
      .then((response) => {
        dispatch({
          type: UNINSTALL_ANALYZE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: UNINSTALL_ANALYZE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function uninstallAnalyzeChangeCurrent(uninstallAnalyzeFilter) {
  return (dispatch) => {
    dispatch({
      type: UNINSTALL_ANALYZE_CHANGE_VALUE,
      payload: uninstallAnalyzeFilter,
    });
  };
}
