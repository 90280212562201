import React, { useEffect } from 'react';

import { Input, Spin } from 'antd';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import { SearchOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import vars from '@Theme/styles/vars';
import Card from '@Utils/Card';

const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  statusBadge: {
    fontSize: 11,
    fontWeight: '600',
    padding: '5px 10px',
    textTransform: 'uppercase',
    borderRadius: '50px',
    color: vars.light100,
  },
};

export default function FunnelList(props) {
  const updateList = (params) => {
    props.listFunnels(params);
  };

  useEffect(() => {
    props.updateFunnelListParameters({
      direction: 'DESC',
      name: null,
      order: 'id',
      page: 0,
    });
    updateList({
      direction: 'DESC',
      name: null,
      order: 'id',
      page: 0,
    });
  }, []);

  const columnList = [
    {
      title: 'funnel name',
      width: '100%',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (x, object) => (
        <Link
          id={'int-funnel-list-name-' + object.id}
          to={`../funnels/${object.id}/overview`}
        >
          <span
            style={{
              color: vars.primaryColor,
              fontSize: 14,
              cursor: 'pointer',
            }}
          >
            {x}
          </span>
        </Link>
      ),
    },
    {
      title: 'action',
      key: 'action',
      fixed: 'left',
      width: 100,
      render: (x, object) =>
        defaultActions(
          x,
          {
            link: `../funnels/${object.id}/edit`,
            title: 'Edit',
            enabled: true,
          },
          {
            action: (id) => {
              props.deleteFunnel(id, () =>
                updateList({ ...props.pageRequest })
              );
            },
            title: 'Delete',
            enabled: true,
          }
        ),
    },
  ];

  return (
    <div>
      <Card>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '12px',
            borderBottom: 'solid 2px #f4f9ff',
          }}
        >
          <Input
            loading={true}
            allowClear={!props.fetching}
            placeholder={'Search by funnel name'}
            onPressEnter={() => {
              props.updateFunnelListParameters({ page: 0 });
              updateList({ ...props.pageRequest, page: 0 });
            }}
            onChange={(e) => {
              if (
                e.target.value === '' ||
                (e.type === 'click' && !e.target.value)
              ) {
                props.updateFunnelListParameters({
                  page: 0,
                  name: null,
                });
                updateList({
                  ...props.pageRequest,
                  name: null,
                  page: 0,
                });
              }
              props.updateFunnelListParameters({ name: e.target.value });
            }}
            value={props.pageRequest.name}
            style={{ width: 300, marginRight: 30, borderRadius: 5 }}
            suffix={
              isEmpty(props.pageRequest.name) ? (
                <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              ) : (
                props.fetching && (
                  <div style={styles.inputLoadingSpinStyle}>
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                )
              )
            }
          />
        </div>
        {props.fetching ? (
          <div style={styles.loadingSpinStyle}>
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <ListBase
            onChange={(p) => {
              updateList({
                ...props.pageRequest,
                page: p.page,
              });
            }}
            page={props.page}
            pageRequest={props.pageRequest}
            columns={columnList}
          />
        )}
      </Card>
    </div>
  );
}
