import RevenueMappingComponent from '../component/RevenueMappingComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  revenueMappingUpdateFetch,
  revenueMappingChangeCurrent,
  revenueMappingUpdateSubmitFetch,
  revenueMappingListCurrenciesFetch,
} from '@Redux/slices/data/revenueMappingSlice';
import { productSegmentListEventsFetch } from '@Modules/insight/productSegment/ProductSegmentActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  loadingUpdateSubmit: state.dataModule.revenueMapping.loadingUpdateSubmit,
  errorsUpdateSubmit: state.dataModule.revenueMapping.errorsUpdateSubmit,
  loadingUpdate: state.dataModule.revenueMapping.loadingUpdate,
  errorsUpdate: state.dataModule.revenueMapping.errorsUpdate,
  currentRevenueMapping: state.dataModule.revenueMapping.currentRevenueMapping,
  errorsCurrencies: state.dataModule.revenueMapping.errorsCurrencies,
  currencies: state.dataModule.revenueMapping.currencies,
  loadingCurrencies: state.dataModule.revenueMapping.loadingCurrencies,
  loadingEvents: state.insight.productSegment.loadingEvents,
  events: state.insight.productSegment.events,
  loadingList: state.dataModule.revenueMapping.loadingList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      revenueMappingUpdateFetch,
      revenueMappingChangeCurrent,
      revenueMappingUpdateSubmitFetch,
      revenueMappingListCurrenciesFetch,
      productSegmentListEventsFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RevenueMappingComponent);

RevenueMappingComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  customEventListFetch: PropTypes.func.isRequired,
  customEventDeleteFetch: PropTypes.func.isRequired,
  customEventPageChangeCurrent: PropTypes.func.isRequired,
};
