import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import ConfigurationUpdateContainer from './container/ConfigurationUpdateContainer';

function Configuration() {
  let match = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route exact path={`${match.path}update`}>
          <ConfigurationUpdateContainer />
        </Route>
      </Switch>
    </div>
  );
}

export default Configuration;
