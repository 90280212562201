export function getEmailCustomAttributes(attributes, events) {
  const emailAttributes = {};
  const userAttributes = {};
  const eventAttributes = {};
  if (attributes && Array.isArray(attributes) && attributes.length > 0) {
    attributes.forEach((item) => {
      userAttributes[item.id] = {
        name: item.title,
        value: item.system
          ? '{{user["system"]["' + item.name + '"]}}'
          : '{{user["custom"]["' + item.name + '"]}}',
      };
    });
  }
  if (events && Array.isArray(events) && events.length > 0) {
    events.forEach((item) => {
      const innerMergeTags = {};
      item.attributes.forEach((attribute) => {
        innerMergeTags[attribute.id] = {
          name: attribute.title,
          value: attribute.system
            ? '{{event["custom"]["' +
              item.title +
              '"]["system"]["' +
              attribute.name +
              '"]}}'
            : '{{event["custom"]["' +
              item.title +
              '"]["custom"]["' +
              attribute.name +
              '"]}}',
        };
      });
      eventAttributes[item.id] = {
        name: item.title,
        mergeTags: innerMergeTags,
      };
    });
  }
  emailAttributes.userCustomAttributes = {
    name: 'User Attributes',
    mergeTags: userAttributes,
  };
  emailAttributes.eventAttributes = {
    name: 'Custom Events',
    mergeTags: eventAttributes,
  };
  return emailAttributes;
}
