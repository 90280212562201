export const CONTRACT_STATUS = {
  PUBLISHED: {
    color: '#FFFFFF',
    bgColor: '#3BC12F',
  },
  DRAFT: {
    color: '#FFFFFF',
    bgColor: '#ffc107',
  },
  DISABLED: {
    color: '#94969e',
    bgColor: '#e7e8e9',
  },
};

export const CONTRACT_ACTIONS = {
  VIEW: ['PUBLISHED', 'DISABLED'],
  EDIT: ['DRAFT'],
  DELETE: ['DRAFT'],
  PUBLISH: ['DRAFT', 'DISABLED'],
  UNPUBLISH: ['PUBLISHED'],
  DISABLE: ['PUBLISHED'],
};
