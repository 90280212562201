exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductSegmentArchiveListComponent-module__root--2SMtC {\n  background-color: white;\n  border-radius: 5px;\n  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);\n}\n.ProductSegmentArchiveListComponent-module__header--nQkg4 {\n  padding: 12px;\n  border-bottom: solid 2px #f4f9ff;\n}\n.ProductSegmentArchiveListComponent-module__headerSearch--3XBlx {\n  width: 300px !important;\n  margin-right: 30px;\n  border-radius: 5px !important;\n}\n.ProductSegmentArchiveListComponent-module__inputLoadingSpin--1kgJT {\n  align-items: center;\n  justify-content: center;\n  display: flex;\n}\n.ProductSegmentArchiveListComponent-module__loadingSpin--3STkc {\n  height: 180px;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  padding-top: 10;\n  padding-bottom: 10;\n}\n", ""]);

// exports
exports.locals = {
	"root": "ProductSegmentArchiveListComponent-module__root--2SMtC",
	"header": "ProductSegmentArchiveListComponent-module__header--nQkg4",
	"headerSearch": "ProductSegmentArchiveListComponent-module__headerSearch--3XBlx",
	"inputLoadingSpin": "ProductSegmentArchiveListComponent-module__inputLoadingSpin--1kgJT",
	"loadingSpin": "ProductSegmentArchiveListComponent-module__loadingSpin--3STkc"
};