import { combineReducers } from 'redux';
import funnel from '@Modules/analytics/funnel/FunnelState';
import uninstalls from '@Modules/analytics/uninstalls/UninstallActions';
import cohort from './cohortSlice';

const reducer = combineReducers({
  funnel,
  uninstalls,
  cohort,
});

export default reducer;
