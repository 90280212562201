import { createSlice } from '@reduxjs/toolkit';
import billingApis from './billingApis';
import { showMessageError } from '@Utils/RenderUtils';

const initialState = {
  features: [],
  sla: {},
  slaDuration: null,
  loading: false,
  gatewayLoading: false,
  gatewayInfo: {},
  transactionInfo: {},
};

export const billing = createSlice({
  name: 'businessEvent',
  initialState,
  reducers: {
    featuresFetchSuccess(state, { payload }) {
      state.features = [payload];
      state.loading = false;
    },
    slaFetchSuccess(state, { payload }) {
      state.sla = payload;
      state.loading = false;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    slaDurationChanged(state, { payload }) {
      state.slaDuration = payload;
    },
    setGatewayLoading(state, { payload }) {
      state.gatewayLoading = payload;
    },
    gatewayFetchSuccess(state, { payload }) {
      state.gatewayInfo = payload;
    },
    TransactionResponseFetchSuccess(state, { payload }) {
      state.transactionInfo = payload;
    },
  },
});

export default billing.reducer;

export const {
  featuresFetchSuccess,
  slaFetchSuccess,
  setLoading,
  slaDurationChanged,
  setGatewayLoading,
  gatewayFetchSuccess,
  TransactionResponseFetchSuccess,
} = billing.actions;

export const fetchFeatures = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await billingApis.features();
    setTimeout(() => {
      dispatch(featuresFetchSuccess(response));
    }, 1000);
  } catch (e) {
    dispatch(setLoading(false));
  }
};

export const fetchAdminFeatures = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await billingApis.adminFeatures();
    setTimeout(() => {
      dispatch(featuresFetchSuccess(response));
    }, 1000);
  } catch (e) {
    dispatch(setLoading(false));
  }
};

export const fetchSla = (body) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const response = await billingApis.sla(body);

    setTimeout(() => {
      dispatch(slaFetchSuccess(response));
    }, 1000);
  } catch (e) {
    dispatch(setLoading(false));
  }
};
export const fetchGatewayPayment =
  (id, gateway, onClose) => async (dispatch) => {
    dispatch(setGatewayLoading(true));
    try {
      const response = await billingApis.gatewayRequest(id, gateway);
      dispatch(gatewayFetchSuccess(response));
      dispatch(setGatewayLoading(false));
      onClose();
      if (response && response.href) {
        window.open(response.href);
      }
    } catch (e) {
      dispatch(setGatewayLoading(false));
      showMessageError(e);
    }
  };
export const fetchTransactionResponse = (token) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await billingApis.transaction(token);
    dispatch(TransactionResponseFetchSuccess(response));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(setLoading(false));
  }
};
