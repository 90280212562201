import UserAttrComponent from '../component/UserAttrComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  insightProductUserActivityReportFetch,
  insightProductUserListAttributeFetch,
  insightProductUserReachabilityReportFetch,
  insightProductUserViewFetch,
} from '../UsersActions';

const mapStateToProps = (state) => ({
  reachabilityLoading: state.track.users.reachabilityLoading,
  reachabilityErrors: state.track.users.reachabilityErrors,
  reachability: state.track.users.reachability,

  activityPageRequest: state.track.users.activityPageRequest,
  activityLoading: state.track.users.activityLoading,
  activityErrors: state.track.users.activityErrors,
  activity: state.track.users.activity,
  pathname: state.router.location.pathname,
  userLoading: state.track.users.userLoading,
  userErrors: state.track.users.userErrors,
  user: state.track.users.user,
  attributesListLoading: state.track.users.attributesListLoading,
  attributesListErrors: state.track.users.attributesListErrors,
  attributesList: state.track.users.attributesList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      insightProductUserActivityReportFetch,
      insightProductUserReachabilityReportFetch,
      insightProductUserViewFetch,
      insightProductUserListAttributeFetch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserAttrComponent);

UserAttrComponent.propTypes = {};
