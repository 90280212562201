export const PAYMENT_STATUS = {
  CREATED: {
    color: '#94969e',
    bgColor: '#e7e8e9',
  },
  ISSUED: {
    color: '#FFFFFF',
    bgColor: '#3a91eb',
  },
  PAYMENT_PENDING: {
    color: '#FFFFFF',
    bgColor: '#EF8F00',
  },
  PAYMENT_FAILED: {
    color: '#FFFFFF',
    bgColor: '#f76464',
  },
  PAID: {
    color: '#FFFFFF',
    bgColor: '#09d88c',
  },
};
export const CONTRACT_STATUS = {
  ENDED: {
    color: '#FFFFFF',
    bgColor: '#f76464',
  },
  PENDING: {
    color: '#FFFFFF',
    bgColor: '#EF8F00',
  },
  ACTIVE: {
    color: '#FFFFFF',
    bgColor: '#3BC12F',
  },
  ENDING: {
    color: '#FFFFFF',
    bgColor: '#ffc107',
  },
  NO_CONTRACT: {
    color: '#94969e',
    bgColor: '#e7e8e9',
  },
};
