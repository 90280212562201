import React, { createContext, useContext } from 'react';

const BannerStackSizeContext = createContext(0);

const useBannerStackSize = () => useContext(BannerStackSizeContext);

const BannerStackSizeProvider = ({ bannerStackSize, children }) => {
  return (
    <BannerStackSizeContext.Provider value={bannerStackSize}>
      {children}
    </BannerStackSizeContext.Provider>
  );
};

export { useBannerStackSize, BannerStackSizeProvider };
