import BaseApi from '../../../api/BaseApi';

const providerBasePath = '/channel/whatsapp';
const templateBasePath = '/channel/whatsappTemplate';

class WhatsAppChannelApi {
  //WhatsApp Provider//
  static create(body) {
    return BaseApi.post(providerBasePath + '/create', body);
  }
  static delete(id) {
    return BaseApi.post(providerBasePath + '/delete/' + id, {});
  }
  static list(data) {
    return BaseApi.post(providerBasePath + '/list', data);
  }
  static update(id) {
    return BaseApi.post(providerBasePath + '/update/' + id, {});
  }
  static view(id) {
    return BaseApi.post(providerBasePath + '/view/' + id, {});
  }
  static updateSubmit(id, body) {
    return BaseApi.post(providerBasePath + '/updateSubmit/' + id, body);
  }
  //WhatsApp Template//
  static createTemplate(body) {
    return BaseApi.post(templateBasePath + '/create', body);
  }
  static deleteTemplate(id) {
    return BaseApi.post(templateBasePath + '/delete/' + id, {});
  }
  static listTemplate(data) {
    return BaseApi.post(templateBasePath + '/list', data);
  }
  static updateTemplate(id) {
    return BaseApi.post(templateBasePath + '/update/' + id, {});
  }
  static viewTemplate(id) {
    return BaseApi.post(templateBasePath + '/view/' + id, {});
  }
  static updateSubmitTemplate(id, body) {
    return BaseApi.post(templateBasePath + '/updateSubmit/' + id, body);
  }
}

export default WhatsAppChannelApi;
