import ListBase from '@Component/form/ListBase';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Spin, Button, Input } from 'antd';
import {
  LoadingOutlined,
  DownloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import styles from './CsvExportLog.module.less';
import { viewDateTime } from '@Utils/DateUtils';
import Btnstyles from './CsvButton.module.less';
import { featureAccessCheckCSVExport } from '@Utils/AccessManagement';

import { useSelector, useDispatch } from 'react-redux';
import { csvListFetch, profileCsvListFetch } from './CsvExportActions';
import Card from '@Utils/Card';

const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 30 }} spin />;

const STATUS = {
  AWAITING: {
    color: '#94969e',
    bgColor: '#e7e8e9',
  },
  RUNNING: {
    color: '#FFFFFF',
    bgColor: '#ffc107',
  },
  COMPLETED: {
    color: '#FFFFFF',
    bgColor: '#09d88c',
  },
  FAILED: {
    color: '#FFFFFF',
    bgColor: '#f76464',
  },
  ABORTED: {
    color: '#FFFFFF',
    bgColor: '#f76464',
  },
  CANCELED: {
    color: '#FFFFFF',
    bgColor: '#f76464',
  },
  DISABLED: {
    color: '#',
    bgColor: '#94969e',
  },
};

export default function CsvExportLog({ type }) {
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const page = useSelector((state) => state.csv.page);
  const pageRequest = useSelector((state) => state.csv.pageRequest);
  const loadingUpdateSubmit = useSelector(
    (state) => state.csv.loadingUpdateSubmit
  );
  const loadingList = useSelector((state) => state.csv.loadingList);

  useEffect(() => {
    handleDispatch({ page: 0 });
  }, [loadingUpdateSubmit]);

  const handleDispatch = (page, resetQuery = false) => {
    if (type === 'DATA_MANAGMENT') {
      dispatch(
        csvListFetch({
          ...pageRequest,
          ...page,
          order: 'createdDate',
          direction: 'DESC',
          query: resetQuery ? null : query,
        })
      );
    } else {
      dispatch(
        profileCsvListFetch({
          ...pageRequest,
          ...page,
          order: 'createdDate',
          direction: 'DESC',
          query: resetQuery ? null : query,
        })
      );
    }
  };

  const columnList = [
    {
      sorter: false,
      title: 'category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      sorter: false,
      title: 'name',
      dataIndex: 'collectionName',
      key: 'collectionName',
    },
    {
      sorter: false,
      title: 'date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (x) => viewDateTime(x),
    },

    {
      sorter: false,
      title: 'user name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      sorter: false,
      title: 'records',
      dataIndex: 'totalRecordsCount',
      key: 'totalRecordsCount',
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      fixed: 'left',
      render: (x) => (
        <span
          style={{
            fontSize: 11,
            fontWeight: '600',
            padding: '5px 10px',
            textTransform: 'uppercase',
            borderRadius: '50px',
            backgroundColor: STATUS[x]?.bgColor || '#e7e8e9',
            color: STATUS[x]?.color || '#94969e',
          }}
        >
          {x}
        </span>
      ),
    },
    {
      title: 'action',
      dataIndex: '',
      key: '-action-',
      width: '5%',
      render: (data) => {
        return data.status === 'COMPLETED' && featureAccessCheckCSVExport() ? (
          <Button
            type="link"
            shape="rounded"
            className={`${Btnstyles.btnCsv}`}
            onClick={() => {
              handleExportCsv(data?.id, data.productId);
              // dispatch(callExportCsvDownload(data?.id, data.productId));
            }}
          >
            <div>
              <DownloadOutlined />
            </div>
          </Button>
        ) : (
          <div style={{ textAlign: 'center' }}>-</div>
        );
      },
    },
  ];

  const handleExportCsv = (campaignId, productId) => {
    const downloadUrl = `/api/web/report/download/${campaignId}?productId=${productId}`;
    const anchor = document.createElement('a');
    anchor.href = downloadUrl;
    anchor.click();
  };

  return (
    <section className={styles.wrapper}>
      <Card>
        <header>
          <Input
            allowClear={loadingList ? false : true}
            placeholder={`Search by communication${
              type === 'DATA_MANAGMENT' ? '/user' : ''
            } name`}
            onPressEnter={() => handleDispatch({ page: 0 })}
            onChange={(e) => {
              setQuery(e.target.value);
              if (e.type === 'click') {
                handleDispatch({ page: 0 }, true);
              }
            }}
            value={query}
            style={{ width: 300, borderRadius: 5 }}
            suffix={
              isEmpty(query) ? (
                <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              ) : (
                loadingList && (
                  <div className={styles.inputLoadingSpinStyle}>
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                )
              )
            }
          />
        </header>
        <main>
          {loadingList ? (
            <div className={styles.loadingSpinStyle}>
              <Spin indicator={loadingSpin} />
            </div>
          ) : (
            <ListBase
              onChange={handleDispatch}
              page={page}
              pageRequest={pageRequest}
              columns={columnList}
              createLink={null}
            />
          )}
        </main>
      </Card>
    </section>
  );
}
