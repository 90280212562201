import { Button, Col, Form, Input, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app';
import { InputNumber, Alert, Space } from 'antd';

import { isEmpty } from 'lodash';
import openSnackBar from '../Atom/SnackBar';

function TrafficSplitter(props) {
  const [form] = Form.useForm();
  const [traffic, setTraffic] = useState();
  const formRef = React.useRef(null);

  const { journey, syncCache } = useContext(AppContext);

  useEffect(() => {
    const currentStep = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );
    if (currentStep) {
      setTraffic(Object.assign({}, currentStep?.ui?.traffic));
    }
  }, []);

  const currentStep = journey.steps.find(
    (step) => step.id + '' === props.node.id + ''
  );

  const { on, onWeights } = currentStep;
  const list = [];
  on?.forEach((x) => {
    list.push(journey.steps.find((step) => step.id + '' === x + ''));
  });

  const onFill = () => {
    const value = 100 / on.length;
    const result = {};
    on.forEach((key, idx) => {
      const { label } = form.getFieldValue(key);
      return (result[key] = {
        label,
        onWeight: idx === on.length - 1 ? Math.ceil(value) : Math.floor(value),
      });
    });
    formRef.current?.setFieldsValue(result);
    setTraffic(result);
  };

  const onFinish = (value) => {
    let currentStep = journey.steps.find(
      (step) => step.id + '' === props.node.id + ''
    );
    const ui = Object.assign({}, currentStep?.ui, { traffic });
    let onWeights = list.map((item) => value[item.id].onWeight);

    var sum = onWeights.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    if (sum > 100) {
      openSnackBar('warning', {
        description: 'In total, the percentages exceed 100',
      });
    }
    currentStep['onWeights'] = onWeights;
    const currentStepUpdated = Object.assign({}, currentStep, { ui });
    syncCache('update-step', currentStepUpdated, true);
    props.closeModal();
  };

  return (
    <>
      {!isEmpty(list) ? (
        <Form
          form={form}
          ref={formRef}
          onFinish={onFinish}
          className="modal_wrap"
          onValuesChange={(changedValues, allValues) => {
            setTraffic(allValues);
          }}
          id="traffic-splitter"
        >
          <div className={'modal-body'}>
            <Button shape="round" type="primary" onClick={onFill}>
              Split equally
            </Button>
            <Row justify="start" style={{ padding: '24px 0 12px ' }}>
              <Col span={8}>
                <h3>Branch Name</h3>
              </Col>
              <Col span={4} offset={1}>
                <h3>Percentage</h3>
              </Col>
            </Row>
            {traffic &&
              list?.map((item, index) => (
                <Row key={index} justify="start">
                  <Col span={8}>
                    <Form.Item
                      name={[`${item?.ui.id}`, 'label']}
                      initialValue={
                        (!isEmpty(currentStep?.ui.traffic) &&
                          currentStep?.ui?.traffic[item.ui.id]?.label) ||
                        item?.ui.label
                      }
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={4} offset={1}>
                    <Form.Item
                      name={[`${item?.ui.id}`, 'onWeight']}
                      initialValue={
                        onWeights?.length > 0
                          ? traffic[item?.ui.id]?.onWeight || 0
                          : 0
                      }
                    >
                      <InputNumber min={0} max={100} addonAfter="%" />
                    </Form.Item>
                  </Col>
                </Row>
              ))}
          </div>
          <div className={'modal-footer'}>
            <Form.Item>
              <Button
                shape="round"
                type="primary"
                htmlType="submit"
                disabled={props.readOnly}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      ) : (
        <Space
          direction="vertical"
          style={{
            width: '100%',
            padding: 24,
          }}
        >
          <Alert message="The traffic splitter has not been set" banner />
        </Space>
      )}
    </>
  );
}

export default TrafficSplitter;
