import UserDevicesComponent from '../component/UserDevicesComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  insightProductUserDeviceFetch,
  insightProductUserViewFetch,
} from '../UsersActions';

const mapStateToProps = (state) => ({
  deviceList: state.track.users.deviceList,
  deviceListLoading: state.track.users.deviceListLoading,
  deviceListErrors: state.track.users.deviceListErrors,
  pathname: state.router.location.pathname,
  userLoading: state.track.users.userLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      insightProductUserDeviceFetch,
      insightProductUserViewFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDevicesComponent);

UserDevicesComponent.propTypes = {};
