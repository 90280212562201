import CommunicationOverviewComponent from '../component/OverviewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  overviewFetch,
  overviewSplitOverviewFetch,
  overviewListTagsFetch,
} from '../OverviewActions';
import { revenueMappingUpdateFetch } from '@Redux/slices/data/revenueMappingSlice';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  loadingListTags: state.engage.overview.loadingListTags,
  loadingSplitOverview: state.engage.overview.loadingSplitOverview,
  errorsListTags: state.engage.overview.errorsListTags,
  errorsOverview: state.engage.overview.errorsOverview,
  errorsSplitOverview: state.engage.overview.errorsSplitOverview,
  listTags: state.engage.overview.listTags,
  overview: state.engage.overview.overview,
  splitOverview: state.engage.overview.splitOverview,
  pageRequestOverview: state.engage.overview.pageRequestOverview,
  pageRequestSplitOverview: state.engage.overview.pageRequestSplitOverview,
  pathname: state.router.location.pathname,
  loadingOverview: state.engage.overview.loadingOverview,
  currentRevenueMapping: state.dataModule.revenueMapping.currentRevenueMapping,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      overviewFetch,
      overviewSplitOverviewFetch,
      overviewListTagsFetch,
      revenueMappingUpdateFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationOverviewComponent);

CommunicationOverviewComponent.propTypes = {
  overviewSplitOverviewFetch: PropTypes.func.isRequired,
  overviewFetch: PropTypes.func.isRequired,
  overviewListTagsFetch: PropTypes.func.isRequired,
};
