import BaseApi from '../../../api/BaseApi';

const basePath = '/ui';
class UiControllerApi {
  static create(body) {
    const body_ = Object.assign({}, body, {
      message: `@CLIENT_LOG: ${body.message}`,
    });
    return BaseApi.post(basePath + '/log', body_);
  }
}

export default UiControllerApi;
