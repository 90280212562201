import * as React from 'react';

function SvgStop(props) {
  return (
    <svg viewBox="0 0 25 27" width="1em" height="1em" {...props}>
      <g fill="#FFF" fillRule="evenodd">
        <path d="M11 1.876a1.875 1.875 0 113.75 0v9.998a1.875 1.875 0 11-3.75 0V1.876z" />
        <path
          d="M4.37 22.631C-.124 18.14-.124 10.83 4.37 6.338a1.152 1.152 0 011.629 1.63c-3.594 3.593-3.594 9.44 0 13.034 3.593 3.594 9.44 3.594 13.034 0 3.594-3.594 3.594-9.441 0-13.035a1.152 1.152 0 011.63-1.63c4.492 4.493 4.492 11.802 0 16.294A11.484 11.484 0 0112.516 26c-2.95 0-5.9-1.123-8.147-3.369z"
          stroke="#FFF"
        />
      </g>
    </svg>
  );
}

export default SvgStop;
