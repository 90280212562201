import BaseApi from '../../../api/BaseApi';

export default class Audit {
  static auditList(body) {
    return BaseApi.post('/audit/', body);
  }
  static types() {
    return BaseApi.get('/audit/types');
  }
  static users() {
    return BaseApi.get('/audit/users');
  }
}
