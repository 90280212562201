import CommunicationListComponent from '../component/CommunicationListComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  communicationPageChangeCurrent,
  communicationListFetch,
  communicationSearchListFetch,
  communicationDeleteFetch,
  communicationCopyFetch,
  communicationListTagsFetch,
  communicationDraftSaveDiscard,
  communicationUpdateAudienceFetch,
  communicationUpdateSubmitAudienceFetch,
  communicationUpdateTagsSubmitFetch,
  communicationChangeCurrentAudience,
  communicationStopFetch,
  communicationArchive,
  communicationActivate,
} from '../CommunicationActions';
import { callExportCommunicationFile } from '@Component/utils/CsvExport/CsvExportActions';

import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  page: state.engage.communication.page,
  currentAudience: state.engage.communication.currentAudience,
  errorUpdateAudience: state.engage.communication.errorUpdateAudience,
  loadingUpdateAudience: state.engage.communication.loadingUpdateAudience,
  loadingUpdateAudienceSubmit:
    state.engage.communication.loadingUpdateAudienceSubmit,
  errorUpdateAudienceSubmit:
    state.engage.communication.errorUpdateAudienceSubmit,
  pageRequest: state.engage.communication.pageRequest,
  loadingCopy: state.engage.communication.loadingCopy,
  errorsCopy: state.engage.communication.errorsCopy,
  loadingListTags: state.engage.communication.loadingListTags,
  listTags: state.engage.communication.listTags,
  pathname: state.router.location.pathname,
  loadingList: state.engage.communication.loadingList,
  loadingSearchList: state.engage.communication.loadingSearchList,
  archiveSuccess: state.engage.communication.archiveSuccess,
  activeSuccess: state.engage.communication.activeSuccess,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      communicationDraftSaveDiscard,
      communicationPageChangeCurrent,
      communicationListFetch,
      communicationSearchListFetch,
      communicationDeleteFetch,
      communicationCopyFetch,
      communicationListTagsFetch,
      communicationUpdateSubmitAudienceFetch,
      communicationUpdateAudienceFetch,
      communicationUpdateTagsSubmitFetch,
      communicationChangeCurrentAudience,
      communicationStopFetch,
      communicationArchive,
      communicationActivate,
      callExportCommunicationFile,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationListComponent);

CommunicationListComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  communicationListFetch: PropTypes.func.isRequired,
  communicationDeleteFetch: PropTypes.func.isRequired,
  communicationPageChangeCurrent: PropTypes.func.isRequired,
};
