import {
  PUSH_CHANNEL_DELETE_FETCH,
  PUSH_CHANNEL_DELETE_FETCH_FAIL,
  PUSH_CHANNEL_DELETE_FETCH_SUCCESS,
  PUSH_CHANNEL_ANDROID_CREATE_FETCH,
  PUSH_CHANNEL_ANDROID_CREATE_FETCH_FAIL,
  PUSH_CHANNEL_ANDROID_CREATE_FETCH_SUCCESS,
  PUSH_CHANNEL_IOS_CREATE_FETCH,
  PUSH_CHANNEL_IOS_CREATE_FETCH_FAIL,
  PUSH_CHANNEL_IOS_CREATE_FETCH_SUCCESS,
  PUSH_CHANNEL_ANDROID_LIST_FETCH,
  PUSH_CHANNEL_ANDROID_LIST_FETCH_FAIL,
  PUSH_CHANNEL_ANDROID_LIST_FETCH_SUCCESS,
  PUSH_CHANNEL_ANDROID_LIST_CHANGE_CURRENT,
  PUSH_CHANNEL_IOS_LIST_FETCH,
  PUSH_CHANNEL_IOS_LIST_FETCH_FAIL,
  PUSH_CHANNEL_IOS_LIST_FETCH_SUCCESS,
  PUSH_CHANNEL_IOS_LIST_CHANGE_CURRENT,
  PUSH_CHANNEL_UPDATE_SUBMIT_FETCH,
  PUSH_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
  PUSH_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
  PUSH_CHANNEL_ANDROID_CHANGE_CURRENT,
  PUSH_CHANNEL_IOS_CHANGE_CURRENT,
  PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH,
  PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
  PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
  PUSH_CHANNEL_FIREBASE_CHANGE_CURRENT,
  PUSH_CHANNEL_FIREBASE_CREATE_FETCH,
  PUSH_CHANNEL_FIREBASE_CREATE_FETCH_FAIL,
  PUSH_CHANNEL_FIREBASE_CREATE_FETCH_SUCCESS,
  PUSH_CHANNEL_BROWSER_CHANGE_CURRENT,
  PUSH_CHANNEL_BROWSER_CREATE_FETCH,
  PUSH_CHANNEL_BROWSER_CREATE_FETCH_FAIL,
  PUSH_CHANNEL_BROWSER_CREATE_FETCH_SUCCESS,
  PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH,
  PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
  PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
  PUSH_CHANNEL_FIREBASE_LIST_FETCH,
  PUSH_CHANNEL_FIREBASE_LIST_FETCH_SUCCESS,
  PUSH_CHANNEL_FIREBASE_LIST_FETCH_FAIL,
  PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH,
  PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
  PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
  PUSH_CHANNEL_BROWSER_LIST_FETCH,
  PUSH_CHANNEL_BROWSER_LIST_FETCH_SUCCESS,
  PUSH_CHANNEL_BROWSER_LIST_FETCH_FAIL,
  PUSH_CHANNEL_FETCH_ITEM_FAILED,
} from './PushChannelConstants';
import PushChannelApi from './PushChannelApi';
import toastr from 'toastr';
import { showMessageError } from '../../../utils/RenderUtils';

const loadPushChannelList = (dispatch, data) => {
  if (data.mobileDevice === 'ANDROID') {
    dispatch({
      type: PUSH_CHANNEL_ANDROID_LIST_FETCH,
    });
  } else if (data.mobileDevice === 'IOS') {
    dispatch({
      type: PUSH_CHANNEL_IOS_LIST_FETCH,
    });
  } else if (data.webPushKeyType === 'FIREBASE') {
    dispatch({
      type: PUSH_CHANNEL_FIREBASE_LIST_FETCH,
    });
  } else if (data.webPushKeyType === 'BROWSER') {
    dispatch({
      type: PUSH_CHANNEL_BROWSER_LIST_FETCH,
    });
  }
  PushChannelApi.list(data)
    .then((res) => {
      const response = res.sort((item1, item2) => item1.id - item2.id);
      if (data.mobileDevice === 'ANDROID') {
        dispatch({
          type: PUSH_CHANNEL_ANDROID_LIST_FETCH_SUCCESS,
          payload: response,
        });
      } else if (data.mobileDevice === 'IOS') {
        dispatch({
          type: PUSH_CHANNEL_IOS_LIST_FETCH_SUCCESS,
          payload: response,
        });
      } else if (data.webPushKeyType === 'FIREBASE') {
        dispatch({
          type: PUSH_CHANNEL_FIREBASE_LIST_FETCH_SUCCESS,
          payload: response,
        });
      } else if (data.webPushKeyType === 'BROWSER') {
        dispatch({
          type: PUSH_CHANNEL_BROWSER_LIST_FETCH_SUCCESS,
          payload: response,
        });
      }
    })
    .catch((response) => {
      if (data.mobileDevice === 'ANDROID') {
        dispatch({
          type: PUSH_CHANNEL_ANDROID_LIST_FETCH_FAIL,
          payload: response,
        });
      } else if (data.mobileDevice === 'IOS') {
        dispatch({
          type: PUSH_CHANNEL_IOS_LIST_FETCH_FAIL,
          payload: response,
        });
      } else if (data.webPushKeyType === 'FIREBASE') {
        dispatch({
          type: PUSH_CHANNEL_FIREBASE_LIST_FETCH_FAIL,
          payload: response,
        });
      } else if (data.webPushKeyType === 'BROWSER') {
        dispatch({
          type: PUSH_CHANNEL_BROWSER_LIST_FETCH_FAIL,
          payload: response,
        });
      }
    });
};

export function pushChannelListFetch(data) {
  return (dispatch) => loadPushChannelList(dispatch, data);
}

export function pushChannelFetchItem({ id, type, isEdit }) {
  return (dispatch) => {
    if (type === 'ANDROID') {
      dispatch({
        type: PUSH_CHANNEL_ANDROID_LIST_FETCH,
      });
    } else if (type === 'IOS') {
      dispatch({
        type: PUSH_CHANNEL_IOS_LIST_FETCH,
      });
    } else if (type === 'FIREBASE') {
      dispatch({
        type: PUSH_CHANNEL_FIREBASE_LIST_FETCH,
      });
    } else if (type === 'BROWSER') {
      dispatch({
        type: PUSH_CHANNEL_BROWSER_LIST_FETCH,
      });
    }

    const method = isEdit ? PushChannelApi.update : PushChannelApi.view;
    method(id)
      .then((pushChannel) => {
        if (type === 'ANDROID') {
          dispatch({
            type: PUSH_CHANNEL_ANDROID_CHANGE_CURRENT,
            payload: { ...pushChannel, id },
          });
        }
        if (type === 'IOS') {
          dispatch({
            type: PUSH_CHANNEL_IOS_CHANGE_CURRENT,
            payload: { ...pushChannel, id },
          });
        }
        if (type === 'FIREBASE') {
          dispatch({
            type: PUSH_CHANNEL_FIREBASE_CHANGE_CURRENT,
            payload: { ...pushChannel, id },
          });
        }
        if (type === 'BROWSER') {
          dispatch({
            type: PUSH_CHANNEL_BROWSER_CHANGE_CURRENT,
            payload: { ...pushChannel, id },
          });
        }
      })
      .catch((response) => {
        dispatch({
          type: PUSH_CHANNEL_FETCH_ITEM_FAILED,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function pushChannelChangeCurrent(pushChannel, type = 'ANDROID') {
  return (dispatch) => {
    if (type === 'ANDROID') {
      dispatch({
        type: PUSH_CHANNEL_ANDROID_CHANGE_CURRENT,
        payload: pushChannel,
      });
    }
    if (type === 'IOS') {
      dispatch({
        type: PUSH_CHANNEL_IOS_CHANGE_CURRENT,
        payload: pushChannel,
      });
    }
    if (type === 'FIREBASE') {
      dispatch({
        type: PUSH_CHANNEL_FIREBASE_CHANGE_CURRENT,
        payload: pushChannel,
      });
    }
    if (type === 'BROWSER') {
      dispatch({
        type: PUSH_CHANNEL_BROWSER_CHANGE_CURRENT,
        payload: pushChannel,
      });
    }
  };
}

export function pushChannelChangeList(list, type = 'ANDROID') {
  return (dispatch) => {
    if (type === 'ANDROID') {
      dispatch({
        type: PUSH_CHANNEL_ANDROID_LIST_CHANGE_CURRENT,
        payload: list,
      });
      dispatch(pushChannelChangeCurrent(list[list.length - 1], type));
    }
    if (type === 'IOS') {
      dispatch({
        type: PUSH_CHANNEL_IOS_LIST_CHANGE_CURRENT,
        payload: list,
      });
      dispatch(pushChannelChangeCurrent(list[list.length - 1], type));
    }
  };
}

export function pushChannelCreateAndroidFetch(pushChannel) {
  return (dispatch) => {
    dispatch({
      type: PUSH_CHANNEL_ANDROID_CREATE_FETCH,
      payload: pushChannel,
    });
    PushChannelApi.create(pushChannel)
      .then((response) => {
        dispatch({
          type: PUSH_CHANNEL_ANDROID_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been created successfully');
        loadPushChannelList(dispatch, {
          mobileDevice: 'ANDROID',
          pushChannelType: 'APP',
        });

        dispatch(
          pushChannelChangeCurrent(
            {
              ...pushChannel,
              id: response.id,
            },
            'ANDROID'
          )
        );
      })
      .catch((response) => {
        dispatch({
          type: PUSH_CHANNEL_ANDROID_CREATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function pushChannelCreateIosFetch(pushChannel) {
  return (dispatch) => {
    dispatch({
      type: PUSH_CHANNEL_IOS_CREATE_FETCH,
      payload: pushChannel,
    });
    PushChannelApi.create(pushChannel)
      .then((response) => {
        dispatch({
          type: PUSH_CHANNEL_IOS_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been created successfully');
        loadPushChannelList(dispatch, {
          mobileDevice: 'IOS',
          pushChannelType: 'APP',
        });

        dispatch(
          pushChannelChangeCurrent(
            {
              ...pushChannel,
              id: response.id,
            },
            'IOS'
          )
        );
      })
      .catch((response) => {
        dispatch({
          type: PUSH_CHANNEL_IOS_CREATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function pushChannelCreateFirebaseFetch(pushChannel) {
  return (dispatch) => {
    dispatch({
      type: PUSH_CHANNEL_FIREBASE_CREATE_FETCH,
      payload: pushChannel,
    });
    PushChannelApi.create(pushChannel)
      .then((response) => {
        dispatch({
          type: PUSH_CHANNEL_FIREBASE_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been created successfully');
        loadPushChannelList(dispatch, {
          pushChannelType: 'WEB',
          webPushKeyType: 'FIREBASE',
        });

        dispatch(
          pushChannelChangeCurrent(
            {
              ...pushChannel,
              id: response.id,
            },
            'FIREBASE'
          )
        );
      })
      .catch((response) => {
        dispatch({
          type: PUSH_CHANNEL_FIREBASE_CREATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function pushChannelCreateBrowserFetch(pushChannel) {
  return (dispatch) => {
    dispatch({
      type: PUSH_CHANNEL_BROWSER_CREATE_FETCH,
      payload: pushChannel,
    });
    PushChannelApi.create(pushChannel)
      .then((response) => {
        dispatch({
          type: PUSH_CHANNEL_BROWSER_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been created successfully');
        loadPushChannelList(dispatch, {
          pushChannelType: 'WEB',
          webPushKeyType: 'BROWSER',
        });

        dispatch(
          pushChannelChangeCurrent(
            {
              ...pushChannel,
              id: response.id,
            },
            'BROWSER'
          )
        );
      })
      .catch((response) => {
        dispatch({
          type: PUSH_CHANNEL_BROWSER_CREATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function pushChannelUpdateSubmitFetch(id, pushChannel, data) {
  return (dispatch) => {
    dispatch({
      type: PUSH_CHANNEL_UPDATE_SUBMIT_FETCH,
      payload: pushChannel,
    });
    PushChannelApi.updateSubmit(id, pushChannel)
      .then((response) => {
        dispatch({
          type: PUSH_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been updated successfully');
        loadPushChannelList(dispatch, data);
      })
      .catch((response) => {
        dispatch({
          type: PUSH_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function pushIOSChannelUpdateSubmitFetch(id, pushChannel, data) {
  return (dispatch) => {
    dispatch({
      type: PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH,
      payload: pushChannel,
    });
    PushChannelApi.updateSubmit(id, pushChannel)
      .then((response) => {
        dispatch({
          type: PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been updated successfully');
        loadPushChannelList(dispatch, data);
      })
      .catch((response) => {
        dispatch({
          type: PUSH_IOS_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function pushChannelDeleteFetch(id, data) {
  return (dispatch) => {
    dispatch({
      type: PUSH_CHANNEL_DELETE_FETCH,
    });
    PushChannelApi.delete(id)
      .then((response) => {
        dispatch({
          type: PUSH_CHANNEL_DELETE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been deleted successfully');
        loadPushChannelList(dispatch, data);
      })
      .catch((response) => {
        dispatch({
          type: PUSH_CHANNEL_DELETE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function pushFirebaseChannelUpdateSubmitFetch(id, pushChannel, data) {
  return (dispatch) => {
    dispatch({
      type: PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH,
      payload: pushChannel,
    });
    PushChannelApi.updateSubmit(id, pushChannel)
      .then((response) => {
        dispatch({
          type: PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been updated successfully');
        loadPushChannelList(dispatch, data);
      })
      .catch((response) => {
        dispatch({
          type: PUSH_FIREBASE_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function pushBrowserChannelUpdateSubmitFetch(id, pushChannel, data) {
  return (dispatch) => {
    dispatch({
      type: PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH,
      payload: pushChannel,
    });
    PushChannelApi.updateSubmit(id, pushChannel)
      .then((response) => {
        dispatch({
          type: PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been updated successfully');
        loadPushChannelList(dispatch, data);
      })
      .catch((response) => {
        dispatch({
          type: PUSH_BROWSER_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
