import ProductSdkViewComponent from '../component/ProductSdkViewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  productSdkAndroidViewFetch,
  productSdkWebViewFetch,
  productSdkIosViewFetch,
} from '../ProductSdkActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentProductSdkAndroid:
    state.integration.productSdkAndroid.currentProductSdkAndroid,
  loadingProductSdkAndroidView:
    state.integration.productSdkAndroid.loadingProductSdkAndroidView,
  errorsProductSdkAndroid:
    state.integration.productSdkAndroid.errorsProductSdkAndroid,
  currentProductSdkWeb:
    state.integration.productSdkAndroid.currentProductSdkWeb,
  loadingProductSdkWebView:
    state.integration.productSdkAndroid.loadingProductSdkWebView,
  errorsProductSdkWeb: state.integration.productSdkAndroid.errorsProductSdkWeb,
  currentProductSdkIos:
    state.integration.productSdkAndroid.currentProductSdkIos,
  loadingProductSdkIosView:
    state.integration.productSdkAndroid.loadingProductSdkIosView,
  errorsProductSdkIos: state.integration.productSdkAndroid.errorsProductSdkIos,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      productSdkAndroidViewFetch,
      productSdkWebViewFetch,
      productSdkIosViewFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSdkViewComponent);

ProductSdkViewComponent.propTypes = {
  currentProductRestApi: PropTypes.object.isRequired,
  productSdkAndroidViewFetch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
