import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Features from '@Modules/admin/contractTemplate/components/ContractFeatures';
import {
  contractCleareActiveContract,
  contractViewActiveFetch,
} from '@Redux/slices/admin/adminProductContractSlice';
import { isEmpty } from 'lodash';
import { Alert, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function ProductBillingFeatures() {
  const dispatch = useDispatch();
  const activeContract = useSelector(
    (state) => state.admin.contract.activeContract
  );
  const isLoading = useSelector((state) => state.admin.contract.loading);
  useEffect(() => {
    dispatch(contractViewActiveFetch());
    return () => {
      dispatch(contractCleareActiveContract());
    };
  }, []);

  return (
    <>
      <strong>
        According to your contract, you have access to the following features:
      </strong>
      <strong style={{ display: 'block', padding: '10px' }}>
        Feature list:
      </strong>
      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 35 }} spin />} />
        </div>
      ) : !isEmpty(activeContract) ? (
        <Features
          disabledTree
          currentFeatures={activeContract.contractTemplate.features}
          isAdmin={false}
        />
      ) : (
        <Alert
          message="There is no active contract for this product."
          type="warning"
        />
      )}
    </>
  );
}
