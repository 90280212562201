import React, { useState } from 'react';
import { Form, Input, Select } from 'antd';

const { Option } = Select;

const TimeInput = ({ value = {}, onChange }) => {
  const [duration, setDuration] = useState(value.duration);
  const [period, setPeriod] = useState('day');

  const triggerChange = (changedValue) => {
    onChange?.({ duration, period, ...value, ...changedValue });
  };

  const onDurationChange = (e) => {
    const newValue = e.target.value;
    setDuration(newValue);
    triggerChange({ duration: newValue });
  };

  const onPeriodChange = (newValue) => {
    setPeriod(newValue);
    triggerChange({ period: newValue });
  };

  return (
    <span>
      <Input
        type="text"
        value={value.duration || duration}
        onChange={onDurationChange}
        style={{ width: 100 }}
      />
      <Select
        value={value.period || period}
        style={{ width: 80, margin: '0 8px' }}
        onChange={onPeriodChange}
      >
        <Option value="60">Minutes</Option>
        <Option value="3600">Hours</Option>
        <Option value="86400">Days</Option>
      </Select>
    </span>
  );
};

const FunnelCompletionTime = ({
  executing,
  executionParams,
  updateFunnelExecutionParameters,
}) => {
  const onChange = (values) => {
    updateFunnelExecutionParameters({
      completionTime:
        parseInt(values.ctime.duration) * parseInt(values.ctime.period),
    });
  };

  const checkValue = (_, value) => {
    if (value.duration > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('duration must be greater than zero!'));
  };

  function calculateDurationAndPeriod(completionTime) {
    const daySeconds = 86400;
    if (completionTime % daySeconds === 0) {
      return {
        duration: Math.floor(executionParams.completionTime / daySeconds),
        period: `${daySeconds}`,
      };
    }
    const hourSeconds = 3600;
    if (completionTime % hourSeconds === 0) {
      return {
        duration: Math.floor(executionParams.completionTime / hourSeconds),
        period: `${hourSeconds}`,
      };
    }
    const minuteSeconds = 60;
    if (completionTime % minuteSeconds === 0) {
      return {
        duration: Math.floor(executionParams.completionTime / minuteSeconds),
        period: `${minuteSeconds}`,
      };
    }
  }

  return (
    <div>
      <Form
        name="funnel_report_time"
        layout="inline"
        onValuesChange={onChange}
        initialValues={{
          ctime: calculateDurationAndPeriod(executionParams.completionTime),
        }}
        disabled={executing}
      >
        <Form.Item
          name="ctime"
          label="Completion Time"
          tooltip="The time in which user should reach last step after reaching the first step."
          rules={[{ validator: checkValue }]}
        >
          <TimeInput />
        </Form.Item>
      </Form>
    </div>
  );
};

export default FunnelCompletionTime;
