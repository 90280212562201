import React from 'react';
import { Input, Spin, Modal, Select } from 'antd';
import { isNil, isEmpty, cloneDeep, filter } from 'lodash';
import {
  PlusOutlined,
  DownOutlined,
  UpOutlined,
  SearchOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import ProductSegmentPredicateComponent from './ProductSegmentPredicateComponent';
import styles from './ProductSegmentStyle';
import { getSegmentType } from '../../../../utils/AuthorityProvider';

const typesEnd = [
  {
    name: 'any',
    value: 'ANY',
  },
  {
    name: 'specific',
    value: 'SPECIFIC',
  },
];

class ProductSegmentUpdateComponentTest extends React.Component {
  constructor(props) {
    super(props);
    this.segmentType = getSegmentType(this.props.pathname);
    this.proedicateType = {
      ATTRIBUTE: 'ATTRIBUTE',
      DEVICE: 'DEVICE',
      BEHAVIOUR: 'BEHAVIOUR',
    };
    this.predicateTypeHeader = {
      ATTRIBUTE: 'User Attribute',
      DEVICE: 'Device Attribute',
      BEHAVIOUR: 'Behavioral',
    };
    this.emptyAttribute = {
      attribute: null,
      greaterThan: null,
      lessThan: null,
      negate: false,
      operator: null,
      stringValue: null,
      type: this.proedicateType.ATTRIBUTE,
    };
    this.emptyEvent = {
      event: null,
      eventAggregation: 'EXISTS',
      eventConjunction: 'AND',
      type: this.proedicateType.BEHAVIOUR,
      negate: false,
      eventPredicates: [],
    };
    this.emptyDevice = {
      deviceConjunction: 'AND',
      type: this.proedicateType.DEVICE,
      negate: false,
      devicePredicates: [],
    };
    this.emptyEventPredicate = {
      attribute: null,
      greaterThan: null,
      lessThan: null,
      negate: false,
      operator: null,
      stringValue: null,
    };
    this.emptyDevicePredicate = {
      attribute: null,
      greaterThan: null,
      lessThan: null,
      negate: false,
      operator: null,
      stringValue: null,
    };
    this.state = {
      selectorModalVisible: false,
      selectedHeader: this.predicateTypeHeader.ATTRIBUTE, // DEVICE, BEHAVIOUR
      addToTerms: null,
      selectedTermIndex: null,
      currentSegment: null,
      attributesSearch: '',
      eventSearch: '',
      deviceAttributeSearch: '',
      attributeType: '',
      attribute: '',
    };
  }

  setAttributeType = (v) => {
    this.setState({ attributeType: v });
  };
  setAttribute = (v) => {
    this.setState({ attribute: v });
  };

  componentDidUpdate(preProps, preStats) {
    // let result = cloneDeep(this.state.currentSegment);
    if (preStats.currentSegment !== this.state.currentSegment) {
      const dt = this.filledSegment();
      if (dt) {
        this.props.handleChangePredicate(dt);
      }
    }
    if (this.props.currentProductSegment !== preProps.currentProductSegment) {
      console.log('cu-------', this.props.currentProductSegment);
      this.setState({
        currentSegment: { ...this.props.currentProductSegment },
      });
      const { attributeType, attribute } = this.props.currentProductSegment;
      this.setState({
        attributeType,
        attribute,
      });
    }
  }

  filledSegment = () => {
    const { attribute, attributeType } = this.state;
    let result = cloneDeep(this.state.currentSegment);
    if (isNil(result)) {
      result = {};
    }
    if (isNil(result.type)) {
      result.type = this.segmentType;
    }
    if (isNil(result.test)) {
      result.test = false;
    }
    if (isNil(result.conjunction)) {
      result.conjunction = 'AND';
    }
    if (
      !isEmpty(result.predicates) &&
      Array.isArray(result.predicates) &&
      result.predicates.length > 0 &&
      isNil(result.predicates[0].negate)
    ) {
      result.predicates[0].negate = false;
    }
    result = Object.assign({}, result, { attributeType, attribute });
    return result;
  };

  handleSelectHeader = (selectedHeader) => {
    if (this.state.selectedHeader === selectedHeader) {
      this.setState({ selectedHeader: null });
    } else {
      this.setState({ selectedHeader });
    }
  };

  getMultipleStringValue = (value) => {
    let result = [];
    if (value && !isEmpty(value)) {
      result = value.split(/[\s;,]+/);
    }
    return result;
  };

  handleAddNewFilter = (addToTerms, selectedTermIndex) => {
    this.setState({
      selectorModalVisible: true,
      addToTerms,
      selectedTermIndex,
    });
  };

  handleAddAttribute = (predicateTypeHeader, id) => {
    const termsCopy =
      isNil(this.state.currentSegment) || isNil(this.state.currentSegment.terms)
        ? []
        : cloneDeep(this.state.currentSegment.terms);
    let emptyItem = null;
    if (predicateTypeHeader === this.predicateTypeHeader.ATTRIBUTE) {
      emptyItem = cloneDeep(this.emptyAttribute);
      emptyItem.attribute = id;
    } else if (predicateTypeHeader === this.predicateTypeHeader.BEHAVIOUR) {
      emptyItem = cloneDeep(this.emptyEvent);
      emptyItem.event = id;
    } else if (predicateTypeHeader === this.predicateTypeHeader.DEVICE) {
      emptyItem = cloneDeep(this.emptyDevice);
      const emptyDevicePredicate = cloneDeep(this.emptyDevicePredicate);
      emptyDevicePredicate.attribute = id;
      emptyItem.devicePredicates.push(emptyDevicePredicate);
    }
    if (this.state.addToTerms) {
      termsCopy.push({
        conjunction: 'AND',
        predicates: [emptyItem],
      });
    } else {
      // add to predicates of terms
      termsCopy[this.state.selectedTermIndex].predicates.push(emptyItem);
    }
    let currentSegment = { ...this.state.currentSegment };
    if (
      isNil(this.state.currentSegment) ||
      isNil(this.state.currentSegment.terms)
    ) {
      currentSegment = { ...this.state.currentSegment, conjunction: 'AND' };
    }
    this.setState({
      selectorModalVisible: false,
      addToTerms: null,
      selectedTermIndex: null,
      currentSegment: { ...currentSegment, terms: termsCopy },
    });
  };

  deletePredicate = (predicateIndex, termIndex) => {
    const termsCopy = cloneDeep(this.state.currentSegment.terms);
    termsCopy[termIndex].predicates.splice(predicateIndex, 1);
    if (termsCopy[termIndex].predicates.length === 0) {
      termsCopy.splice(termIndex, 1);
    }
    this.setState(
      { currentSegment: { ...this.state.currentSegment, terms: termsCopy } },
      () => {
        // this.props.productSegmentReportFetch(this.state.currentSegment);
      }
    );
  };

  isSegmentEmpty = () => {
    let result = false;
    if (isNil(this.state.currentSegment)) {
      result = true;
    }
    if (
      this.state.currentSegment &&
      (!this.state.currentSegment.terms ||
        (this.state.currentSegment.terms &&
          Array.isArray(this.state.currentSegment.terms) &&
          this.state.currentSegment.terms.length === 0))
    ) {
      result = true;
    }
    return result;
  };

  handleChangeTermConjunction = (selectedTermIndex) => {
    const alternateConjunction =
      this.state.currentSegment.terms[selectedTermIndex].conjunction === 'AND'
        ? 'OR'
        : 'AND';
    const termsCopy = cloneDeep(this.state.currentSegment.terms);
    termsCopy[selectedTermIndex].conjunction = alternateConjunction;
    this.setState({
      currentSegment: { ...this.state.currentSegment, terms: termsCopy },
    });
  };

  handleChangePredicate = (
    termIndex,
    predicateIndex,
    predicate,
    deleteMode = false
  ) => {
    const termsCopy = cloneDeep(this.state.currentSegment.terms);
    termsCopy[termIndex].predicates[predicateIndex] = cloneDeep(predicate);
    this.setState(
      { currentSegment: { ...this.state.currentSegment, terms: termsCopy } },
      () => {
        if (deleteMode) {
          // this.props.productSegmentReportFetch(this.state.currentSegment);
        }
      }
    );
  };

  renderSearchInTitle = (title) => {
    let value = null;
    if (title === this.predicateTypeHeader.ATTRIBUTE) {
      value = this.state.attributesSearch;
    } else if (title === this.predicateTypeHeader.BEHAVIOUR) {
      value = this.state.eventSearch;
    } else if (title === this.predicateTypeHeader.DEVICE) {
      value = this.state.deviceAttributeSearch;
    }
    return (
      <Input
        loading={false}
        allowClear
        placeholder={'Search...'}
        onFocus={() => {
          this.setState({ selectedHeader: title });
        }}
        onChange={(e) => {
          if (title === this.predicateTypeHeader.ATTRIBUTE) {
            this.setState({ attributesSearch: e.target.value });
          } else if (title === this.predicateTypeHeader.BEHAVIOUR) {
            this.setState({ eventSearch: e.target.value });
          } else if (title === this.predicateTypeHeader.DEVICE) {
            this.setState({ deviceAttributeSearch: e.target.value });
          }
        }}
        value={value}
        style={{ width: 300, marginRight: 30, borderRadius: 5 }}
        suffix={
          isEmpty(value) ? (
            <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
          ) : null
        }
      />
    );
  };

  renderAttributeSelectorHeader = (title) => {
    return (
      <div style={styles.modalSectionHeader}>
        <div
          onClick={() => this.handleSelectHeader(title)}
          style={{ cursor: 'pointer' }}
        >
          {title}
        </div>
        <div style={styles.modalSelectionHeaderSearchContent}>
          {this.renderSearchInTitle(title)}
          {this.state.selectedHeader === title ? (
            <UpOutlined
              onClick={() => this.handleSelectHeader(title)}
              style={styles.modalSectionHeaderButton}
            />
          ) : (
            <DownOutlined
              onClick={() => this.handleSelectHeader(title)}
              style={styles.modalSectionHeaderButton}
            />
          )}
        </div>
      </div>
    );
  };

  renderAttributeSelectorItem = (item, predicateTypeHeader) => {
    return (
      <div
        onClick={() => this.handleAddAttribute(predicateTypeHeader, item.id)}
        style={styles.modalSectionSelectorContent}
      >
        <div>{item.title}</div>
        <div>{item.description ? item.description : ' '}</div>
        <div style={styles.modalSectionSelectorButtonContent}>
          <PlusOutlined style={styles.modalSectionSelectorButton} />
        </div>
      </div>
    );
  };

  searchCheck = (search, item) => {
    let isInName = false;
    let isInTitle = false;
    if (!isEmpty(item.name)) {
      isInName = item.name
        .toLowerCase()
        .includes(search.toString().toLowerCase());
    }
    if (!isEmpty(item.title)) {
      isInTitle = item.title
        .toLowerCase()
        .includes(search.toString().toLowerCase());
    }
    return isInName || isInTitle;
  };

  renderAttributeSelector = () => {
    return (
      <Modal
        width={810}
        title={''}
        centered
        footer={null}
        visible={this.state.selectorModalVisible}
        onOk={() => this.setState({ selectorModalVisible: false })}
        onCancel={() => this.setState({ selectorModalVisible: false })}
      >
        <div style={styles.modalContent}>
          {this.renderAttributeSelectorHeader(
            this.predicateTypeHeader.ATTRIBUTE
          )}
          <div style={styles.modalSection}>
            {this.state.selectedHeader === this.predicateTypeHeader.ATTRIBUTE &&
              filter(this.props.attributes, (o) => {
                if (isEmpty(this.state.attributesSearch)) {
                  return true;
                }
                return this.searchCheck(this.state.attributesSearch, o);
              }).map((x) =>
                this.renderAttributeSelectorItem(
                  x,
                  this.predicateTypeHeader.ATTRIBUTE
                )
              )}
          </div>
        </div>
      </Modal>
    );
  };

  handleClickReportFetch = () => {
    if (!this.props.loadingReport) {
      // this.props.productSegmentReportFetch(this.state.currentSegment);
    }
  };

  handleClickTermConjunction = (isLast) => {
    if (isLast) {
      this.handleAddNewFilter(true, null);
    } else {
      const alternateConjunction =
        this.state.currentSegment.conjunction === 'AND' ? 'OR' : 'AND';
      this.setState({
        currentSegment: {
          ...this.state.currentSegment,
          conjunction: alternateConjunction,
        },
      });
    }
  };

  handleChangeSegmentName = (value) => {
    this.setState({
      currentSegment: { ...this.state.currentSegment, name: value },
    });
  };

  renderTermConjunctionDivider = (isLast, conjunction) => {
    const isAnd = conjunction === 'AND';
    return (
      <div style={styles.termConjunctionDivideContent}>
        <div style={styles.termConjunctionDividerBorder}></div>
        {isAnd ? (
          <div
            onClick={() => this.handleClickTermConjunction(isLast)}
            style={{
              ...styles.conjunctionDividerText,
              border: isLast ? 'solid 1px #11b563' : 'none',
              boxShadow: isLast ? 'none' : '0 1px 6px 0 rgba(17, 181, 99, 0.5)',
              color: isLast ? '#53b16b' : 'white',
              backgroundColor: isLast ? '#fcfcfc' : '#53b16b',
            }}
          >
            {isLast ? '+ AND FILTER' : 'AND'}
          </div>
        ) : (
          <div
            onClick={() => this.handleClickTermConjunction(isLast)}
            style={{
              ...styles.conjunctionDividerText,
              border: isLast ? 'solid 1px #3ec6ff' : 'none',
              boxShadow: isLast
                ? 'none'
                : '0 1px 6px 0 rgba(62, 198, 255, 0.5)',
              color: isLast ? '#3ec6ff' : 'white',
              backgroundColor: isLast ? '#fcfcfc' : '#3ec6ff',
            }}
          >
            {isLast ? '+ OR FILTER' : 'OR'}
          </div>
        )}
        <div style={styles.termConjunctionDividerBorder}></div>
      </div>
    );
  };

  // eslint-disable-next-line no-unused-vars
  renderTerm = (item, index, isLast, conjunction) => {
    return (
      <div style={styles.termContainer}>
        <div style={styles.boxContent}>
          {item.predicates?.map((predicate, predicateIndex) => (
            <ProductSegmentPredicateComponent
              // mandatory props:
              key={predicateIndex}
              predicate={predicate}
              errorsSegmentSubmit={this.props.errorsSegmentSubmit}
              clearSegmentError={(errors, key) =>
                this.props.clearSegmentError(errors, key)
              }
              attributes={this.props.attributes}
              events={this.props.events}
              handleChangePredicate={(changedPredicate, deleteMode) =>
                this.handleChangePredicate(
                  index,
                  predicateIndex,
                  changedPredicate,
                  deleteMode
                )
              }
              type={null} // EVENT_OCCURRENCE, HAS_DONE_EVENT, CHECK_USER_ATTRIBUTE
              // segment specific props:
              deviceAttributes={this.props.deviceAttributes}
              isInSegment={true}
              termConjunction={item.conjunction}
              predicateIndex={predicateIndex}
              termsIndex={index}
              isLast={predicateIndex === item.predicates.length - 1}
              handleAddNewFilter={() => this.handleAddNewFilter(false, index)}
              handleDeletePredicate={() =>
                this.deletePredicate(predicateIndex, index)
              }
              handleChangeTermConjunction={() =>
                this.handleChangeTermConjunction(index)
              }
            />
          ))}
        </div>
      </div>
    );
  };

  onChangeAttributeType = (type) => {
    this.setAttributeType(type);
    this.props.handleChangePredicate({
      attributeType: type,
    });
    // setAttributeTrigger(old => Object.assign({}, old, { attributeType: type }));
  };

  onChangeAttribute = (val) => {
    this.setAttribute(val);
    this.props.handleChangePredicate({
      attribute: val,
    });
  };

  renderSelectAttribute = () => {
    return (
      <div>
        <div className="exit_box__space__span">
          <span>End journey when there is</span>
          <span>
            <Select
              style={{ width: 100 }}
              placeholder="Select type"
              onChange={this.onChangeAttributeType}
              value={this.state.attributeType}
            >
              {typesEnd.map((item) => (
                <Select.Option key={item.key} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </span>
          <span>change in user attribute</span>
          <span>
            <Select
              style={{ width: 150 }}
              placeholder="Select Attribute"
              onChange={this.onChangeAttribute}
              value={this.state.attribute}
              showSearch
              filterOption={(input, option) => {
                if (option.value) {
                  return (
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }
                return '';
              }}
            >
              {this.props.attributes.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.title}
                </Select.Option>
              ))}
            </Select>
          </span>
        </div>
      </div>
    );
  };

  render() {
    const isFieldsEmpty = this.isSegmentEmpty();
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const { attributeType, attribute } = this.state;

    return (
      <div>
        {this.renderSelectAttribute()}
        {attributeType === typesEnd[1].value && attribute ? (
          <div style={styles.content}>
            <div style={styles.segmentContent}>
              {/* {this.renderSegmentName()} */}
              {this.props.loadingCopy || this.props.loadingUpdate ? (
                <div style={styles.terms}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      alignContent: 'center',
                      justifyContent: 'center',
                      paddingTop: 50,
                      paddingBottom: 50,
                    }}
                  >
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                </div>
              ) : isFieldsEmpty ? (
                <div
                  onClick={() => this.handleAddNewFilter(true, null)}
                  style={styles.addNewEmptySegment}
                >
                  You can add filters to your segment by clicking here
                  <div style={styles.circlePlusContainer}>
                    <PlusOutlined style={styles.circlePlus} />
                  </div>
                </div>
              ) : (
                <div style={styles.terms}>
                  {this.state.currentSegment.terms.map((item, index) =>
                    this.renderTerm(
                      item,
                      index,
                      index === this.state.currentSegment.terms.length - 1,
                      this.state.currentSegment.conjunction
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          ''
        )}
        {this.renderAttributeSelector()}
      </div>
    );
  }
}

export default ProductSegmentUpdateComponentTest;
