import { cloneDeep as _clone } from 'lodash';
import moment from 'moment';
import momentDuration from 'moment-duration-format';

momentDuration(moment);

export function buildErrorMapFromArray(array) {
  if (!array) {
    return {};
  }
  return array.reduce((map, obj) => {
    if (obj.key in map) {
      map[obj.key] = `${map[obj.key]}, ${obj.message}`;
    } else {
      map[obj.key] = obj.message;
    }
    return map;
  }, {});
}

export function test() {}

export function renderDelay(value, format = 'minutes') {
  return moment
    .duration(Number(value), format)
    .format('d [Days], h [Hours], m [Minutes]', { trim: 'all' });
}

export function renameKey(obj, key, newKey) {
  const clonedObj = _clone(obj);
  clonedObj[newKey] = _clone(obj[key]);
  delete clonedObj[key];
  return clonedObj;
}
