import { MinusCircleOutlined } from '@ant-design/icons';
import ProductSegmentPredicateComponent from '@Modules/insight/productSegment/component/ProductSegmentPredicateComponent';
import { Button, Col, Divider, Form, Row, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
const { Option } = Select;
import _ from 'lodash';
import { AppContext } from '@Component/journey/app';
import openSnackBar from '../Atom/SnackBar';

const typesOptions = [
  {
    name: 'ANY',
    displayName: 'any',
  },
  {
    name: 'SPECIFIC',
    displayName: 'specific',
  },
];

const valuesOptions = [
  {
    name: 'NEW_VALUE',
    displayName: 'New Value',
  },
  {
    name: 'OLD_VALUE',
    displayName: 'Old Value',
  },
];

export const isValidPredicates = (predicates) => {
  const foundFirstAnomaly = predicates?.find((item) => {
    if (!item.operator) return true;
    if (item.operator !== 'IS_NOT_EMPTY') {
      if (item.operator === 'BETWEEN') {
        if (_.isNull(item.greaterThan) && _.isNull(item.lessThan)) {
          return true;
        }
      } else if (!item.stringValue) {
        return true;
      }
    }
    return false;
  });

  return !foundFirstAnomaly;
};

// ** MAIN
const TrChangeInUserAttrContent = (props) => {
  const { journey, syncCache } = useContext(AppContext);

  const [form] = Form.useForm();
  const { attributeTrigger, setAttributeTrigger } = props;
  const { attributes } = props.data;
  const [predicates, setPredicates] = useState([]);
  const [attribute, setAttribute] = useState({});
  const [conjunction, setConjunction] = useState('OR');
  const [triggerType, setTriggerType] = useState('ANY');

  const currentStep = attributeTrigger
    ? attributeTrigger
    : journey.steps.find((step) => step.id + '' === props?.node?.id + '');

  useEffect(() => {
    const tType =
      currentStep.attributeType ||
      currentStep.valueChangeType ||
      typesOptions[0].name;

    form.setFieldsValue({
      triggerType: tType,
      attribute: currentStep.attribute,
    });
    setTriggerType(tType);
    // convert object to attribute id
    if (currentStep.predicates) {
      const predicates_ = currentStep.predicates?.map((item) => {
        return Object.assign({}, item, { attribute: item.attribute?.id });
      });
      setPredicates(predicates_ || []);
    }
    setConjunction(currentStep.conjunctionType || 'OR');
    const attrObject = attributes.find(
      (atr) => atr.id === currentStep.attribute
    );
    setAttribute(attrObject);
  }, [currentStep]);

  useEffect(() => {
    if (attributeTrigger) {
      setAttributeTrigger({
        attribute: attribute?.id,
        attributeFilterConjunction: conjunction,
        attributeFilterPredicates: predicates,
        attributeType: triggerType,
      });
    }
  }, [attribute, predicates, conjunction, triggerType]);

  const onFieldsChange = (update) => {
    if (update?.attribute) {
      const selectAttr = attributes.find(
        (item) => item.id === update?.attribute
      );
      setAttribute(selectAttr);
      if (form.getFieldValue('triggerType') === 'SPECIFIC') {
        setPredicates([
          {
            attribute: attribute?.id,
            negate: false,
          },
        ]);
      }
    } else if (update?.triggerType) {
      setTriggerType(update?.triggerType);
      if (update?.triggerType === 'SPECIFIC') {
        setPredicates([
          {
            attribute: attribute?.id,
            negate: false,
          },
        ]);
      } else {
        setPredicates([]);
      }
    }
  };

  const onSubmit = async () => {
    try {
      await form.validateFields();
      const isValid = isValidPredicates(predicates);

      if (!isValid) {
        openSnackBar('error', {
          description: 'please fill all fields!',
        });
        return;
      }

      // !FIX SERVER predicates.attribute is object !
      // convert attribute to object
      const predicates_ = [...predicates].map((item) => {
        const attrObject = attributes.find((atr) => atr.id === item.attribute);
        return Object.assign({}, item, { attribute: attrObject });
      });
      const currentStepUpdated = Object.assign({}, currentStep, {
        predicates: predicates_,
        conjunctionType: conjunction,
        attribute: attribute.id,
        valueChangeType: triggerType,
      });
      delete currentStepUpdated.ui?.error;
      syncCache('update-step', currentStepUpdated, true);

      props.closeModal();
    } catch (error) {
      console.log('error-----', error);
    }
  };

  return (
    <>
      <Form
        form={form}
        // onFieldsChange={onFieldsChange}
        onValuesChange={onFieldsChange}
        initialValues={{
          triggerType: typesOptions[0].name,
        }}
        className="modal-form"
      >
        <div className={'modal-body'}>
          <Row gutter={24} wrap>
            <Col flex="none">
              <Form.Item
                name="triggerType"
                label={`${
                  attributeTrigger ? 'End' : 'Trigger '
                } journey when there is`}
                rules={[{ required: true }]}
                requiredMark="optional"
              >
                <Select
                  placeholder="Select a option"
                  style={{ width: 100 }}
                  disabled={props.readOnly}
                >
                  {typesOptions.map((item) => (
                    <Option key={item.name} value={item.name}>
                      {item.displayName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="none">
              <Form.Item
                name="attribute"
                label={
                  attributeTrigger
                    ? 'change in user attribute'
                    : 'change in value of user attribute'
                }
                rules={[{ required: true }]}
                requiredMark="optional"
              >
                <Select
                  disabled={props.readOnly}
                  style={{ minWidth: 150 }}
                  placeholder="Select Attribute"
                  showSearch
                  filterOption={(input, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  <Select.OptGroup label="CUSTOM">
                    {attributes
                      .filter((at) => !at.system)
                      .map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.title}
                        </Select.Option>
                      ))}
                  </Select.OptGroup>
                  <Select.OptGroup label="SYSTEM">
                    {attributes
                      .filter((at) => at.system)
                      .map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.title}
                        </Select.Option>
                      ))}
                  </Select.OptGroup>
                </Select>
              </Form.Item>
            </Col>
            <Col flex="auto">
              {attribute?.type ? (
                <div style={{ paddingTop: '5px' }}>
                  of data type <b>{attribute.type}</b>
                  {triggerType === typesOptions[1].name ? ', where' : ''}
                </div>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Divider />
          {predicates.length && !_.isEmpty(attribute) ? (
            <Row>
              <Col flex="auto">
                <UserAttrPredicateComponent
                  {...props}
                  predicates={predicates}
                  setPredicates={setPredicates}
                  attribute={attribute}
                  conjunction={conjunction}
                  setConjunction={setConjunction}
                />
              </Col>
            </Row>
          ) : (
            ''
          )}
        </div>
        {!attributeTrigger ? (
          <div className={'modal-footer'}>
            <Form.Item>
              <Button
                type="primary"
                shape="round"
                onClick={onSubmit}
                disabled={props.readOnly}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        ) : (
          ''
        )}
      </Form>
    </>
  );
};

export default TrChangeInUserAttrContent;

// ** INNER Component
export const UserAttrPredicateComponent = (props) => {
  const { attribute, predicates, setPredicates, conjunction, setConjunction } =
    props;

  const {
    attributes,
    events,
    fetchRecommendDeviceAttributes,
    fetchRecommendEventAttributes,
    fetchRecommendUserAttributes,
    recommendDeviceAttributes,
    recommendEventAttributes,
    recommendUserAttributes,
  } = props.data;

  useEffect(() => {
    setPredicates((old) => {
      let rows_ = [...old];
      rows_ = rows_.map((r) => {
        return Object.assign({}, r, {
          attribute: attribute.id,
          type: r.type || 'NEW_VALUE',
        });
      });
      return rows_;
    });
  }, [attribute]);

  const remove = (index) => {
    if (predicates.length > 1)
      setPredicates((old) => {
        return old.filter((r, i) => i !== index);
      });
  };
  const add = () => {
    setPredicates((old) => {
      const rows_ = [...old];
      rows_.push({ attribute: attribute.id, negate: false, type: 'NEW_VALUE' });
      return rows_;
    });
  };

  const renderConjunctionButton = (i) => {
    if (predicates.length === i + 1) {
      if (conjunction === 'OR') {
        return (
          <Button onClick={add} className="ci_btn ci_condition__or_base">
            + OR FILTER
          </Button>
        );
      } else {
        return (
          <Button onClick={add} className="ci_btn ci_condition__and_base">
            + AND FILTER
          </Button>
        );
      }
    } else if (conjunction === 'OR') {
      return (
        <Button
          onClick={() => setConjunction('AND')}
          className="ci_btn ci_condition__or_select"
        >
          OR
        </Button>
      );
    } else if (conjunction === 'AND') {
      return (
        <Button
          onClick={() => setConjunction('OR')}
          className="ci_btn ci_condition__and_select"
        >
          AND
        </Button>
      );
    }
  };

  const handleChangePredicate = (changedPredicate, index) => {
    setPredicates((old) => {
      let data = [...old];
      data[index] = changedPredicate;
      // !FIX: remove
      data = data.map((item) => {
        return Object.assign({}, item, {
          attribute: changedPredicate.attribute,
        });
      });
      return data;
    });
  };

  const onChangeType = (value, index) => {
    setPredicates((old) => {
      let data = [...old];
      const elem = data[index];
      elem.type = value;
      data[index] = elem;
      return data;
    });
  };

  // const selectRules = [{ required: true, message: '' }];

  return (
    <div className="ci_container">
      {predicates.map((predicate, index) => (
        <Row className={'ci_row'} key={index} gutter={[24, 24]}>
          <Col span="3">
            {/* <Form.Item rules={[{ required: true }]} name={'name__' + index}> */}
            <Form.Item>
              <Select
                value={predicate.type || valuesOptions[0].displayName}
                onChange={(e) => onChangeType(e, index)}
              >
                {valuesOptions.map((item, i) => (
                  <Select.Option key={i} value={item.name}>
                    {item.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col flex="auto">
            <ProductSegmentPredicateComponent
              journey={true}
              readOnly={props.readOnly}
              type="CHANGE_IN_USER_ATTRIBUTE"
              predicate={predicate}
              attributes={attributes}
              events={events}
              isInSegment={false}
              predicates={predicates}
              predicateIndex={index}
              handleChangePredicate={(changedPredicate) =>
                handleChangePredicate(changedPredicate, index)
              }
              clearSegmentError={() => {
                //TODO Must Handle
              }}
              fetchRecommendEventAttributes={fetchRecommendEventAttributes}
              recommendEventAttributes={recommendEventAttributes}
              fetchRecommendDeviceAttributes={fetchRecommendDeviceAttributes}
              recommendDeviceAttributes={recommendDeviceAttributes}
              fetchRecommendUserAttributes={fetchRecommendUserAttributes}
              recommendUserAttributes={recommendUserAttributes}
            />
          </Col>
          <Col span="2">
            <div className="ci_cell">
              {predicates.length > 1 ? (
                <MinusCircleOutlined
                  className={'ci_actions__icon'}
                  onClick={() => remove(index)}
                />
              ) : (
                ''
              )}
            </div>
          </Col>
          <Col span="24" className="ci_condition">
            {renderConjunctionButton(index)}
          </Col>
        </Row>
      ))}
    </div>
  );
};
