import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import ProductSettingAuditLogContainer from './container/ProductSettingAuditLogContainer';
import ProductSettingDndContainer from './container/ProductSettingDndContainer';
import ProductSettingCustomDomainContainer from './container/ProductSettingCustomDomainContainer';
import ProductSettingFcContainer from './container/ProductSettingFcContainer';
import ProductSettingGeneralContainer from './container/ProductSettingGeneralContainer';
import ProductSettingScheduledReportContainer from './container/ProductSettingScheduledReportContainer';
import ProductSettingThrottlingContainer from '@Modules/setting/productSetting/component/ProductSettingThrottlingComponent';
import ProductSettingFonts from './component/ProductSettingFonts';

function ProductSegment() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}audit`}>
        <ProductSettingAuditLogContainer />
      </Route>
      <Route exact path={`${match.path}dnd`}>
        <ProductSettingDndContainer />
      </Route>
      <Route exact path={`${match.path}custom-domain`}>
        <ProductSettingCustomDomainContainer />
      </Route>
      <Route exact path={`${match.path}fc`}>
        <ProductSettingFcContainer />
      </Route>
      <Route exact path={`${match.path}general`}>
        <ProductSettingGeneralContainer />
      </Route>
      <Route exact path={`${match.path}report`}>
        <ProductSettingScheduledReportContainer />
      </Route>
      <Route exact path={`${match.path}throttling`}>
        <ProductSettingThrottlingContainer />
      </Route>
      <Route exact path={`${match.path}fonts`}>
        <ProductSettingFonts />
      </Route>
    </Switch>
  );
}

export default ProductSegment;
