import BaseApi from '../../../api/BaseApi';

const basePath = '/billing/invoice';
const basePathFile = '/base/file';

export default class InvoiceApi {
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static view(id) {
    return BaseApi.post(basePath + '/view/' + id);
  }
  static viewReceipt(id) {
    return BaseApi.post(basePath + `/${id}/manual-tx/list`);
  }
  static uploadReceipt(id, body) {
    return BaseApi.post(basePath + `/${id}/manual-tx/create`, body);
  }
  static uploadFile(file) {
    return BaseApi.uploadFile(basePathFile + '/upload', file);
  }
  static confirm(id) {
    return BaseApi.post(basePath + '/confirm/' + id);
  }
  static reject(id, body) {
    return BaseApi.post(basePath + '/reject/' + id, body);
  }
}
