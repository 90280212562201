import RolesViewComponent from '../component/RolesViewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  activeTeamMembers: state.integration.teamMembers.activeTeamMembers,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RolesViewComponent);

// RolesViewComponent.propTypes = {};
