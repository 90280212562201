import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import InputSelector from './InputSelector';
import { Button, Form } from 'antd';

const FunctionsPopulates = (props) => {
  const [decoratedText, setDecoratedText] = useState(
    props?.itemBlock?.decoratedText || ''
  );

  const functionLabel = decoratedText?.includes('urlEncode')
    ? 'urlEncode'
    : decoratedText.includes('ceil')
    ? 'ceil'
    : decoratedText.includes('floor')
    ? 'floor'
    : decoratedText.includes('jalaliDateTime')
    ? 'jalaliDateTime'
    : decoratedText.includes('jalaliDate')
    ? 'jalaliDate'
    : decoratedText.includes('gregorianDateTime')
    ? 'gregorianDateTime'
    : decoratedText.includes('gregorianDate')
    ? 'gregorianDate'
    : decoratedText.includes('orElse')
    ? 'orElse'
    : decoratedText.includes('addMinutes')
    ? 'addMinutes'
    : '';
  let argumant = decoratedText
    .replace(`{{${functionLabel}(`, '')
    .replace(')}}', '')
    .split(',');

  const [clicked, setClicked] = useState(false);
  const [cuurentFirstArg, setCuurentFirstArg] = useState(argumant[0]);
  const [cuurentSecondtArg, setCuurentSecondArg] = useState(argumant[1]);

  useEffect(() => {
    return () => {
      setCuurentFirstArg('');
      setCuurentSecondArg('');
    };
  }, []);

  const oneArg = !(
    decoratedText.includes('{{orElse') || decoratedText.includes('{{addMinutes')
  );
  const attributes = useSelector(
    (state) => state.insight.productSegment.attributes
  );
  const events = useSelector((state) => state.insight.productSegment.events);
  const businessEvents = useSelector(
    (state) => state.engage.journey.businessEvents
  );

  const hasArg = decoratedText.includes(`{{${functionLabel}(`);
  const renderArg = (arg) => {
    const HANDLE_REGEX_ATTR = /{{[a-zA-Z]+\[[^}}]*]}}+/g;
    const isAttr = HANDLE_REGEX_ATTR.test(arg);
    if (isAttr) {
      return arg.replace('{{', '').replace('}}', '');
    } else {
      return `"${arg}"`;
    }
  };
  const onFinish = (values) => {
    const firstArg = values?.firstArg;
    const secondArg = values?.secondArg;
    let newDecoratedText;

    if (!oneArg) {
      if (functionLabel === 'addMinutes') {
        newDecoratedText = `{{${functionLabel}(${renderArg(
          firstArg
        )} , ${secondArg.replace('{{', '').replace('}}', '')})}}`;
      } else {
        newDecoratedText = `{{${functionLabel}(${renderArg(
          firstArg
        )} , ${renderArg(secondArg)})}}`;
      }
    } else {
      newDecoratedText = `{{${functionLabel}(${renderArg(firstArg)})}}`;
    }
    setClicked(true);

    props.insertAttributes(
      props.itemBlock.blockKey,
      props.itemBlock.start,
      props.itemBlock.start + decoratedText.length,
      newDecoratedText
    );

    setDecoratedText(newDecoratedText);
  };
  return (
    <div>
      <Form
        layout="vertical"
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: '8px',
          paddingBottom: '8px',
        }}
        onFinish={onFinish}
        onFieldsChange={() => {
          setClicked(false);
        }}
        initialValues={{
          firstArg: `${hasArg ? cuurentFirstArg : ''}`,
          secondArg: `${hasArg ? cuurentSecondtArg : ''}`,
        }}
      >
        <span
          style={{
            paddingRight: '8px',
            color: '#95969e',
            fontSize: '13px',
            textAlign: 'right',
          }}
        >
          {functionLabel}
        </span>

        <Form.Item
          name="firstArg"
          style={{ marginRight: '8px', marginBottom: '0' }}
        >
          <InputSelector
            attributes={attributes}
            events={events}
            businessEvents={businessEvents}
            size="middle"
            attributeStyle={{
              position: 'relative',
              top: '5px',
              fontSize: '10px',
            }}
            hideEmojiPicker={true}
            hideFunctionPopulate
            funcPopulate
          />
        </Form.Item>

        {!oneArg && (
          <Form.Item name="secondArg" style={{ marginBottom: '0' }}>
            <InputSelector
              attributes={attributes}
              events={events}
              businessEvents={businessEvents}
              size="middle"
              attributeStyle={{
                position: 'relative',
              }}
              hideEmojiPicker={true}
              hideFunctionPopulate
              funcPopulate
            />
          </Form.Item>
        )}
        <Form.Item style={{ marginBottom: '0' }}>
          <Button
            htmlType="submit"
            type={clicked ? 'primary' : 'defult'}
            shape="circle"
            style={{
              marginLeft: '8px',
              fontSize: '9px',
              fontWeight: 'normal',
            }}
          >
            insert
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FunctionsPopulates;
