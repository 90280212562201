import ActionMenu from '@Modules/layout/header/component/ActionMenu';
import {
  cohortDelete,
  cohortForceExecute,
} from '@Redux/slices/analytics/cohortSlice';
import {
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';
import PagesWrapper from '@Component/layout/PagesWrapper';
import PagesHeader from '@Component/layout/PagesHeader';
import { history } from '../../../redux/store';
import CohortCreate from './cohortCreate';
import CohortReport from './cohortReport';
import CohortList from './cohortList';
import { Button } from 'antd';
import React from 'react';
import { getLastItemFromPath } from './helper';
import { useDispatch, useSelector } from 'react-redux';

export default function Cohort() {
  const { productId } = useParams();
  const dispatch = useDispatch();
  let match = useRouteMatch();

  const executing = useSelector((state) => state.analytics.cohort.executing);

  const handleCreateCohort = () => {
    history.push('create');
  };

  const handleEditCohort = () => {
    const cohortId = getLastItemFromPath(location.pathname);
    history.push(`/product/${productId}/cohorts/edit/${cohortId}`);
  };

  const handleDeleteCohort = () => {
    const cohortId = getLastItemFromPath(location.pathname);
    dispatch(
      cohortDelete({
        id: cohortId,
        cb: () => history.push(`/product/${productId}/cohorts/list`),
      })
    );
  };

  const handleForceExecutionCohort = () => {
    const cohortId = getLastItemFromPath(location.pathname);
    dispatch(cohortForceExecute(cohortId));
  };

  const items = [
    {
      key: 'edit',
      title: 'Edit',
      isPrimary: true,
      enabled: !executing,
      onClick: handleEditCohort,
    },
    {
      key: 'delete',
      title: 'Delete',
      isPrimary: false,
      enabled: !executing,
      action: handleDeleteCohort,
    },
    {
      key: 'forceExecute',
      title: 'Force Execute',
      isPrimary: false,
      enabled: !executing,
      disablePopConfirm: true,
      action: handleForceExecutionCohort,
    },
  ];

  const renderHeaderAction = () => {
    return (
      <>
        {location.pathname.includes('cohorts/list') ? (
          <Button type="primary" onClick={handleCreateCohort}>
            New
          </Button>
        ) : location.pathname.includes('cohorts/report') ? (
          <>
            <ActionMenu actions={items}></ActionMenu>
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <PagesHeader
        title="Cohorts"
        hasBackButton={
          location.pathname.includes('cohorts/create') ||
          location.pathname.includes('cohorts/edit') ||
          location.pathname.includes('cohorts/report')
            ? true
            : false
        }
        backUrl={`/product/${productId}/cohorts/list`}
        actions={renderHeaderAction()}
      />
      <PagesWrapper>
        <Switch>
          <Route exact path={`${match.path}list`}>
            <CohortList />
          </Route>
          <Route exact path={`${match.path}create`}>
            <CohortCreate />
          </Route>
          <Route exact path={`${match.path}edit/:cohortId`}>
            <CohortCreate />
          </Route>
          <Route exact path={`${match.path}report/:cohortId`}>
            <CohortReport />
          </Route>
        </Switch>
      </PagesWrapper>
    </>
  );
}
