import React from 'react';
import { Select, TimePicker, Button, Popconfirm, Spin } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { cloneDeep, isNil } from 'lodash';
import moment from 'moment';
import ProductSettingFooterComponent from './ProductSettingFooterComponent';
import { LoadingOutlined } from '@ant-design/icons';
import Card from '@Utils/Card';
const styles = {
  loadingSpinStyle: {
    height: 143,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  addNewSlotButton: {
    marginLeft: 20,
  },
};

class ProductSettingDndComponent extends React.Component {
  componentDidMount() {
    this.props.dndUpdateFetch();
  }

  componentWillUnmount() {
    this.props.dndChangeCurrent({});
  }

  handleClickSave() {
    this.props.dndUpdateSubmitFetch(this.props.dnd);
  }

  handleChangeValue = (key, value, index) => {
    const dndTimes = cloneDeep(this.props.dnd.dndTimes);
    dndTimes[index][key] = value;
    this.props.dndChangeCurrent({ ...this.props.dnd, dndTimes });
  };

  getDndType = (index) => {
    return this.props.dnd &&
      this.props.dnd.dndTimes &&
      Array.isArray(this.props.dnd.dndTimes)
      ? this.props.dnd.dndTimes[index].dndTimeType
      : null;
  };

  addDndTime = () => {
    const currentDndTimes =
      this.props.dnd &&
      this.props.dnd.dndTimes &&
      Array.isArray(this.props.dnd.dndTimes)
        ? cloneDeep(this.props.dnd.dndTimes)
        : [];
    this.props.dndChangeCurrent({
      ...this.props.dnd,
      dndTimes: currentDndTimes.concat({
        dndTimeType: null,
        endTime: null,
        startTime: null,
      }),
    });
  };

  confirm = (e, index) => {
    const currentDndTimes =
      this.props.dnd &&
      this.props.dnd.dndTimes &&
      Array.isArray(this.props.dnd.dndTimes)
        ? cloneDeep(this.props.dnd.dndTimes)
        : [];
    currentDndTimes.splice(index, 1);
    this.props.dndChangeCurrent({
      ...this.props.dnd,
      dndTimes: currentDndTimes,
    });
  };

  cancel = (e) => {
    e.stopPropagation();
  };

  renderDndItems = (index) => {
    const allDnd = {
      DAILY: 'Daily',
      WEEKEND: 'Weekend',
      WEEKDAY: 'Weekday',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday',
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
    };
    const selectedDndTypes = [];
    this.props.dnd.dndTimes.forEach((item) => {
      selectedDndTypes.push(item.dndTimeType);
    });
    const availableDnd = cloneDeep(allDnd);
    selectedDndTypes.forEach((item) => {
      delete availableDnd[item];
    });
    return (
      <React.Fragment>
        (
        {Object.keys(availableDnd).map((type) => (
          <Select.Option value={type} key={type}>
            {availableDnd[type]}
          </Select.Option>
        ))}
        )
        {this.getDndType(index) && (
          <Select.Option
            value={this.getDndType(index)}
            key={this.getDndType(index)}
          >
            {this.getDndType(index)}
          </Select.Option>
        )}
      </React.Fragment>
    );
  };

  renderDnd = () => {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const format = 'HH:mm';
    return (
      <Card
        styles={{
          paddingBottom: 30,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '12px',
            paddingBottom: 0,
            paddingLeft: '20px',
          }}
        >
          <div style={{ fontSize: 13, color: '#3d404e' }}>
            {
              'Avoid disturbing your users with messages during odd hours by specifying the DND (Do Not Disturb) time slots when messages should not be delivered. Please note that DND does not apply to In-app and On-site campaigns.'
            }
          </div>
        </div>
        {!this.props.loadingDndUpdate ? (
          <div style={{ padding: 20 }}>
            {this.props.dnd &&
              this.props.dnd.dndTimes &&
              Array.isArray(this.props.dnd.dndTimes) &&
              this.props.dnd.dndTimes.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: 800,
                    marginBottom: 20,
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginRight: 50,
                      }}
                    >
                      <Select
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        onChange={(dndTimeType) =>
                          this.handleChangeValue(
                            'dndTimeType',
                            dndTimeType,
                            index
                          )
                        }
                        value={this.getDndType(index)}
                        style={{ width: 200 }}
                        className={'chart-selector'}
                        bordered={false}
                      >
                        {this.renderDndItems(index)}
                      </Select>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginRight: 50,
                      }}
                    >
                      <span style={{ marginTop: 3, marginRight: 20 }}>
                        from
                      </span>
                      <TimePicker
                        style={{ width: 150 }}
                        value={
                          isNil(item.startTime)
                            ? null
                            : moment(item.startTime, 'HH:mm:ss')
                        }
                        onChange={(time) =>
                          this.handleChangeValue(
                            'startTime',
                            isNil(time)
                              ? null
                              : moment(time).format('HH:mm:ss'),
                            index
                          )
                        }
                        format={format}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <span style={{ marginTop: 3, marginRight: 20 }}>to</span>
                      <TimePicker
                        style={{ width: 150 }}
                        value={
                          isNil(item.endTime)
                            ? null
                            : moment(item.endTime, 'HH:mm:ss')
                        }
                        onChange={(time) =>
                          this.handleChangeValue(
                            'endTime',
                            isNil(time)
                              ? null
                              : moment(time).format('HH:mm:ss'),
                            index
                          )
                        }
                        format={format}
                      />
                    </div>
                  </div>
                  <div style={{ paddingLeft: 20, paddingTop: 5 }}>
                    <Popconfirm
                      title="Are you sure delete this slot?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={(e) => this.confirm(e, index)}
                      onCancel={(e) => this.cancel(e, index)}
                    >
                      <MinusCircleOutlined
                        style={{ color: '#bbbcc1', fontSize: 20 }}
                      />
                    </Popconfirm>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div style={styles.loadingSpinStyle}>
            <Spin indicator={loadingSpin} />
          </div>
        )}
        <Button
          type="primary"
          shape="round"
          ghost
          size={'large'}
          style={styles.addNewSlotButton}
          onClick={() => this.addDndTime()}
        >
          Add new dnd time slot
        </Button>
      </Card>
    );
  };

  render() {
    return (
      <div style={{ padding: 30 }}>
        {this.renderDnd()}
        <ProductSettingFooterComponent
          loading={this.props.loadingDndUpdateSubmit}
          handleClickSave={() => this.handleClickSave()}
        />
      </div>
    );
  }
}

export default ProductSettingDndComponent;
