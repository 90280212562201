import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './ProfileCard.module.less';
import { invoiceLable } from './static';
import { profileType, profileVendor } from './static';
function ProfileCard({ type, contract = {} }) {
  const [profileValue, setProfileValue] = useState({});
  useEffect(() => {
    if (type === profileType.customer) {
      setProfileValue({
        name: contract.customer,
        registrationCode: contract.registrationCode,
        ecomomicCode: contract.economicCode,
        nationalCode: contract.nationalCode,
        address: contract.address,
        zipCode: contract.zipCode,
        phone: contract.phone,
      });
    } else {
      setProfileValue(profileVendor);
    }
  }, [contract]);
  return (
    <div className={styles.wrapper}>
      <Row>
        <Col span={1}>
          <div className={styles.type}>
            <span>{type}</span>
          </div>
        </Col>
        <Col span={23}>
          <Row className={styles.profile} style={{ marginBottom: 4 }}>
            <Col className={styles.profileItem} span={9}>
              <span>{invoiceLable.name}</span>
              <span>{profileValue.name}</span>
            </Col>
            <Col className={styles.profileItem} span={5}>
              <span>{invoiceLable.registrationCode}</span>
              <span>{profileValue.registrationCode}</span>
            </Col>
            <Col className={styles.profileItem} span={5}>
              <span>{invoiceLable.economicCode}</span>
              <span>{profileValue.ecomomicCode}</span>
            </Col>
            <Col className={styles.profileItem} span={5}>
              <span>{invoiceLable.nationalID}</span>
              <span>{profileValue.nationalCode}</span>
            </Col>
          </Row>
          <Row className={styles.profile}>
            <Col className={styles.profileItem} span={14}>
              <span>{invoiceLable.address}</span>
              <span>{profileValue.address}</span>
            </Col>
            <Col className={styles.profileItem} span={5}>
              <span>{invoiceLable.zipCode}</span>
              <span>{profileValue.zipCode}</span>
            </Col>
            <Col className={styles.profileItem} span={5}>
              <span>{invoiceLable.phone}</span>
              <span>{profileValue.phone}</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ProfileCard;
