import { createSlice } from '@reduxjs/toolkit';
import toastr from 'toastr';
import { showMessageError } from '@Utils/RenderUtils';
import ContractTemplateApi from './contractTemplateApi';
import { history } from '@Redux/store';

const initialState = {
  currentContractTemplate: {},
  contractList: [],
  loading: false,
};

export const contracts = createSlice({
  name: 'contractTemplate',
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    contractTemplateListFetchSuccess(state, { payload }) {
      state.contractList = payload;
    },
    contractTemplateCreateFetchSuccess() {
      toastr.success('Has been created successfully');
    },
    contractTemplateCreateFetchFail(state, { payload }) {
      showMessageError(payload);
    },
    contractTemplateUpdateSubmitFetchSuccess(state, { payload }) {
      state.currentContractTemplate = payload;
      toastr.success('Has been created successfully');
    },
    contractTemplateUpdateSubmitFetchFail(state, { payload }) {
      showMessageError(payload);
    },
    contractTemplateUpdateFetchSuccess(state, { payload }) {
      state.currentContractTemplate = payload;
    },
    contractTemplateUpdateFetchFail(state, { payload }) {
      showMessageError(payload);
    },
    contractTemplateUpdateClear(state) {
      state.currentContractTemplate = {};
    },
    contractTemplateChangeStatusSuccess() {
      toastr.success('Has Changed successfully');
    },
    contractTemplateChangeStatusFail(state, { payload }) {
      showMessageError(payload);
    },
    contractTemplateDeleteSuccess() {
      toastr.success('Has Deleted successfully');
    },
    contractTemplateDeleteFail(state, { payload }) {
      showMessageError(payload);
    },
    contractTemplateViewFetchSuccess(state, { payload }) {
      state.currentContractTemplate = payload;
    },
    contractTemplateViewFetchFail(state, { payload }) {
      showMessageError(payload);
    },
  },
});

export default contracts.reducer;

export const {
  contractTemplateListFetchSuccess,
  contractTemplateCreateFetchSuccess,
  contractTemplateCreateFetchFail,
  contractTemplateViewFetchSuccess,
  contractTemplateViewFetchFail,
  contractTemplateUpdateSubmitFetchSuccess,
  contractTemplateUpdateSubmitFetchFail,
  contractTemplateUpdateFetchSuccess,
  contractTemplateUpdateFetchFail,
  contractTemplateChangeStatusSuccess,
  contractTemplateChangeStatusFail,
  setLoading,
  contractTemplateUpdateClear,
  contractTemplateDeleteSuccess,
  contractTemplateDeleteFail,
} = contracts.actions;

export const contractTemplateListFetch = (body) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await ContractTemplateApi.list(body);
    dispatch(contractTemplateListFetchSuccess(response));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(setLoading(false));
    showMessageError(e);
  }
};

export const contractTemplateCreateFetch = (contract) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await ContractTemplateApi.create(contract);
    dispatch(contractTemplateCreateFetchSuccess(response));
    dispatch(setLoading(false));
    history.push('list');
  } catch (e) {
    dispatch(contractTemplateCreateFetchFail(e));
    dispatch(setLoading(false));
    showMessageError(e);
  }
};

export const contractTemplateUpdateSubmitFetch =
  (id, contract) => async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await ContractTemplateApi.updateSubmit(id, contract);
      dispatch(contractTemplateUpdateSubmitFetchSuccess(response));
      dispatch(setLoading(false));
      history.push('../list');
    } catch (e) {
      dispatch(contractTemplateUpdateSubmitFetchFail(e));
      dispatch(setLoading(false));
      showMessageError(e);
    }
  };

export const contractTemplateUpdateFetch = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await ContractTemplateApi.update(id);
    dispatch(contractTemplateUpdateFetchSuccess(response));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(contractTemplateUpdateFetchFail(e));
    dispatch(setLoading(false));
    showMessageError(e);
  }
};

export const contractTemplateViewFetch = (id, contract) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const response = await ContractTemplateApi.view(id, contract);
    dispatch(contractTemplateViewFetchSuccess(response));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(contractTemplateViewFetchFail(e));
    dispatch(setLoading(false));
  }
};

export const contractTemplateDeleteFetch = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await ContractTemplateApi.delete(id);
    dispatch(contractTemplateDeleteSuccess(response));
    const response2 = await ContractTemplateApi.list({});
    dispatch(contractTemplateListFetchSuccess(response2));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(contractTemplateDeleteFail(e));
    dispatch(setLoading(false));
    showMessageError(e);
  }
};

export const contractTemplateDisableFetch = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await ContractTemplateApi.disable(id);
    dispatch(contractTemplateChangeStatusSuccess(response));
    const response2 = await ContractTemplateApi.list({});
    dispatch(contractTemplateListFetchSuccess(response2));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(contractTemplateChangeStatusFail(e));
    dispatch(setLoading(false));
    showMessageError(e);
  }
};

export const contractTemplatePublishFetch = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await ContractTemplateApi.publish(id);
    dispatch(contractTemplateChangeStatusSuccess(response));
    const response2 = await ContractTemplateApi.list({});
    dispatch(contractTemplateListFetchSuccess(response2));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(contractTemplateChangeStatusFail(e));
    dispatch(setLoading(false));
    showMessageError(e);
  }
};

export const contractTemplateUnpublishFetch = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await ContractTemplateApi.unpublish(id);
    dispatch(contractTemplateChangeStatusSuccess(response));
    const response2 = await ContractTemplateApi.list({});
    dispatch(contractTemplateListFetchSuccess(response2));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(contractTemplateChangeStatusFail(e));
    dispatch(setLoading(false));
    showMessageError(e);
  }
};
