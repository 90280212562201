import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import FilePreview from './FilePreview';
import FileProgressList from './FileProgressList';
import {
  getCSVData,
  getExcelData,
  eventValidateData,
  isAlphaNumeric,
} from './helpers';
import styles from './uploadEventData.module.less';
import { useDispatch, useSelector } from 'react-redux';
import {
  uploadCsvListEventFetch,
  dataFileProgressEventListFetch,
  uploadDataCsvEventFetch,
} from '@Redux/slices/data/dataPlatformSlice';
import Card from '@Utils/Card';
import EventDataCSV from '../../../../theme/images/eventDataCSV.jpg';

let attrMap = {};
let eventName = '';
function UploadEventData() {
  const [previewData, setPreviewData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const inputEl = useRef(null);
  const dispatch = useDispatch();
  const loadingUploadFile = useSelector(
    (state) => state.dataModule.dataCsv.loadingUploadFile
  );
  const eventsList = useSelector(
    (state) => state.dataModule.dataCsv.events.list
  );
  const fileProgressList = useSelector(
    (state) => state.dataModule.dataCsv.eventFileProgressList
  );
  useEffect(() => {
    dispatch(uploadCsvListEventFetch());
    dispatch(
      dataFileProgressEventListFetch({
        direction: 'DESC',
        type: 'CREAT_EVENT',
        order: 'id',
        page: 0,
      })
    );
  }, []);
  useEffect(() => {
    if (loading && !loadingUploadFile) {
      attrMap = {};
      setLoading(false);
      setSelectedFile(null);
      setPreviewData(null);
      setTimeout(() => {
        dispatch(
          dataFileProgressEventListFetch({
            direction: 'DESC',
            type: 'CREAT_EVENT',
            order: 'id',
            page: 0,
          })
        );
      }, 500);
    }
  }, [loadingUploadFile]);

  const changeAttrMap = (key, value) => {
    attrMap[key] = value;
  };
  const onFileSelect = async (e) => {
    const file = e.target.files[0];
    if (file.size > 25000000) {
      setErrorMessage('file size should be less than 25MB');
      return;
    }
    if (!isAlphaNumeric(file.name)) {
      setErrorMessage(
        'Please enter a valid fileName (Only -_. and English Letters and Numbers)'
      );
      return;
    }
    // if (!(file.type.includes('text') || file.type.includes('csv'))) {
    //   message.error('file type should be csv or plain text');
    //   return;
    // }
    setSelectedFile(file);
    try {
      const lastIndex = file.name.lastIndexOf('.');
      const fileExtension = file.name.substring(lastIndex + 1);
      let csv;
      if (fileExtension === 'csv') {
        csv = await getCSVData(file);
      } else {
        csv = await getExcelData(file);
      }
      const validation = eventValidateData(eventsList, csv);
      setPreviewData(validation);
      attrMap = validation.attributesMap;
      eventName = validation.eventName;
    } catch (error) {
      setErrorMessage(`${file.name} has unknown problem`);
      return false;
    }
  };
  const submitFile = () => {
    setLoading(true);
    dispatch(uploadDataCsvEventFetch(selectedFile, attrMap, eventName));
  };

  const fixUploadedFile = (filePath) => {
    setPreviewData({ filePath });
  };
  return (
    <div>
      <FilePreview
        {...previewData}
        handleChangeAttrTypeChange={changeAttrMap}
        type="event"
      />
      <Card
        styles={{
          display: !previewData || !previewData.isValid ? 'block' : 'none',
        }}
      >
        <div className={styles.header}>
          <div className={styles.headerTitle}>
            {previewData ? 'Fixed your file?' : 'Upload Event Data'}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={styles.content}>
            <div>
              <span className="display-block">
                {previewData ? (
                  <span>Upload your fixed file again.</span>
                ) : (
                  <>
                    <span>
                      Please consider that <i>userId</i>, <i>eventName</i> and{' '}
                      <i>eventTime</i> are mandatory.
                    </span>
                    <span>
                      {' '}
                      You can download the{' '}
                      <a href="/files/event_data_sample.csv" download>
                        sample CSV
                      </a>{' '}
                      to see the required format.
                    </span>
                  </>
                )}
              </span>
            </div>
            <div>
              <input
                type="file"
                name="file"
                id="file"
                ref={inputEl}
                className={styles.contentInput}
                accept=".csv, .xlsx"
                onChange={onFileSelect}
                onClick={() => {
                  inputEl.current.value = '';
                }}
              />
              <label htmlFor="file" className={styles.contentButton}>
                SELECT FILE
              </label>
              <div
                className={`csv__caption-size ${
                  errorMessage ? ' csv__caption-error' : ''
                }`}
              >
                {errorMessage || 'Maximum file size: 25MB'}
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <img width={550} src={EventDataCSV} />
          </div>
        </div>
      </Card>
      {!previewData ? (
        <FileProgressList
          fileProgressList={fileProgressList}
          fixUploadedFile={fixUploadedFile}
          type="CREAT_EVENT"
        />
      ) : (
        <div className="dnd-footer">
          <Button
            type="text"
            shape="round"
            size={'large'}
            className="cancel"
            onClick={() => {
              setPreviewData(null);
              setSelectedFile(null);
              attrMap = {};
            }}
          >
            CANCEL
          </Button>
          {previewData.isValid ? (
            <Button
              loading={loading}
              type="primary"
              shape="round"
              size={'large'}
              disabled={loading}
              onClick={submitFile}
            >
              CONFIRM &amp; PROCEED
            </Button>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default UploadEventData;
