import React, { useMemo } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import PagesHeader from '@Component/layout/PagesHeader';
import PagesWrapper from '@Component/layout/PagesWrapper';
import PagesSection from '@Component/layout/PagesSection';
import ProductBillingFeatures from './components/BillingFeatures';
import BillingSLA from './components/BillingSLA';
import DurationSelect from './components/DurationSelect';
import BillingInvoice from './components/BillingInvoice';
import ContractDownloadInvoice from './components/invoiceInfo/downloadInvoice';
import { useSelector } from 'react-redux';
import { useIsParentProduct } from '@Utils/isParentProduct';

export default function ProductBilling() {
  let match = useRouteMatch();
  let { location } = useHistory();
  const downloadInvoice = location.pathname.includes('/invoice-preview');

  const currentUser = useSelector((state) => state.account.auth.currentUser);

  const isParent = useIsParentProduct(currentUser);

  const actions = useMemo(() => {
    if (location.pathname.includes('sla') && !isParent)
      return <DurationSelect />;
    return null;
  }, [location.pathname]);

  return (
    <>
      {!downloadInvoice ? (
        <>
          <PagesHeader
            title="Billing"
            subMenuItems={
              isParent
                ? [
                    { title: 'Features', route: `features` },
                    { title: 'Invoice', route: `invoice` },
                  ]
                : [
                    { title: 'SLA', route: `sla` },
                    { title: 'Features', route: `features` },
                    { title: 'Invoice', route: `invoice` },
                  ]
            }
            actions={actions}
          />

          <PagesWrapper>
            <PagesSection>
              <Switch>
                <Route exact path={`${match.path}/sla`}>
                  <BillingSLA />
                </Route>
                <Route path={`${match.path}/features`}>
                  <ProductBillingFeatures />
                </Route>
                <Route path={`${match.path}/invoice`}>
                  <BillingInvoice />
                </Route>
              </Switch>
            </PagesSection>
          </PagesWrapper>
        </>
      ) : null}
      <Route exact path={`${match.path}/invoice-preview/:id`}>
        <ContractDownloadInvoice />
      </Route>
    </>
  );
}
