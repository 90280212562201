import {
  EMAIL_TEMPLATE_DELETE_FETCH,
  EMAIL_TEMPLATE_DELETE_FETCH_FAIL,
  EMAIL_TEMPLATE_DELETE_FETCH_SUCCESS,
  EMAIL_TEMPLATE_CREATE_FETCH,
  EMAIL_TEMPLATE_CREATE_FETCH_FAIL,
  EMAIL_TEMPLATE_CREATE_FETCH_SUCCESS,
  EMAIL_TEMPLATE_LIST_FETCH,
  EMAIL_TEMPLATE_LIST_FETCH_FAIL,
  EMAIL_TEMPLATE_LIST_FETCH_SUCCESS,
  EMAIL_TEMPLATE_LIST_ALL_FETCH,
  EMAIL_TEMPLATE_LIST_ALL_FETCH_FAIL,
  EMAIL_TEMPLATE_LIST_ALL_FETCH_SUCCESS,
  EMAIL_TEMPLATE_UPDATE_FETCH,
  EMAIL_TEMPLATE_UPDATE_FETCH_FAIL,
  EMAIL_TEMPLATE_UPDATE_FETCH_SUCCESS,
  EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH,
  EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH_FAIL,
  EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH_SUCCESS,
  EMAIL_TEMPLATE_VIEW_FETCH_FAIL,
  EMAIL_TEMPLATE_VIEW_FETCH_SUCCESS,
  EMAIL_TEMPLATE_VIEW_FETCH,
  EMAIL_TEMPLATE_CHANGE_CURRENT,
  EMAIL_TEMPLATE_PAGE_CHANGE_CURRENT,
  EMAIL_TEMPLATE_TOGGLE_MODAL,
  EMAIL_TEMPLATE_SEARCH_LIST_FETCH,
  EMAIL_TEMPLATE_SEARCH_LIST_FETCH_FAIL,
  EMAIL_TEMPLATE_SEARCH_LIST_FETCH_SUCCESS,
} from './EmailTemplateConstants';

const initialState = {
  page: {},
  loadingList: false,
  loadingSearchList: false,
  pageRequest: {
    direction: 'DESC',
    order: 'modifiedDate',
  },
  errors: [],

  currentTemplate: {},

  listAll: [],
  errorsListAll: [],
  loadingListAll: false,

  errorsUpdate: [],
  loadingUpdate: false,

  errorsUpdateSubmit: [],
  loadingUpdateSubmit: false,

  errorsDelete: [],
  loadingDelete: false,

  errorsCreate: [],
  loadingCreate: false,

  errorsView: [],
  loadingView: false,

  isModalOpen: false,
  isModalEditorMode: false,
  selectedTemplateId: null,
};

export default function EmailTemplateState(state = initialState, action) {
  switch (action.type) {
    case EMAIL_TEMPLATE_VIEW_FETCH:
      return { ...state, loadingView: true };
    case EMAIL_TEMPLATE_VIEW_FETCH_SUCCESS:
      return { ...state, currentTemplate: action.payload, loadingView: false };
    case EMAIL_TEMPLATE_VIEW_FETCH_FAIL:
      return {
        ...state,
        errorsView: action.payload.errors,
        loadingView: false,
      };

    case EMAIL_TEMPLATE_LIST_FETCH:
      return { ...state, pageRequest: action.payload, loadingList: true };
    case EMAIL_TEMPLATE_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingList: false };
    case EMAIL_TEMPLATE_LIST_FETCH_FAIL:
      return { ...state, errors: action.payload.errors, loadingList: false };

    case EMAIL_TEMPLATE_SEARCH_LIST_FETCH:
      return { ...state, pageRequest: action.payload, loadingSearchList: true };
    case EMAIL_TEMPLATE_SEARCH_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingSearchList: false };
    case EMAIL_TEMPLATE_SEARCH_LIST_FETCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loadingSearchList: false,
      };

    case EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH:
      return { ...state, loadingUpdateSubmit: true };
    case EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        errorsUpdateSubmit: action.payload.errors,
        loadingUpdateSubmit: false,
      };
    case EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingUpdateSubmit: false };

    case EMAIL_TEMPLATE_UPDATE_FETCH:
      return { ...state, loadingUpdate: true };
    case EMAIL_TEMPLATE_UPDATE_FETCH_FAIL:
      return {
        ...state,
        errorsUpdate: action.payload.errors,
        loadingUpdate: false,
      };
    case EMAIL_TEMPLATE_UPDATE_FETCH_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        currentTemplate: action.payload,
      };

    case EMAIL_TEMPLATE_LIST_ALL_FETCH:
      return { ...state, loadingListAll: true };
    case EMAIL_TEMPLATE_LIST_ALL_FETCH_FAIL:
      return {
        ...state,
        errorsListAll: action.payload.errors,
        loadingListAll: false,
      };
    case EMAIL_TEMPLATE_LIST_ALL_FETCH_SUCCESS:
      return { ...state, loadingListAll: false, listAll: action.payload };

    case EMAIL_TEMPLATE_CREATE_FETCH:
      return { ...state, loadingCreate: true };
    case EMAIL_TEMPLATE_CREATE_FETCH_FAIL:
      return {
        ...state,
        errorsCreate: action.payload.errors,
        loadingCreate: false,
      };
    case EMAIL_TEMPLATE_CREATE_FETCH_SUCCESS:
      return { ...state, loadingCreate: false };

    case EMAIL_TEMPLATE_DELETE_FETCH:
      return { ...state, loadingDelete: true };
    case EMAIL_TEMPLATE_DELETE_FETCH_FAIL:
      return {
        ...state,
        errorsDelete: action.payload.errors,
        loadingDelete: false,
      };
    case EMAIL_TEMPLATE_DELETE_FETCH_SUCCESS:
      return { ...state, loadingDelete: false };

    case EMAIL_TEMPLATE_CHANGE_CURRENT:
      return { ...state, currentTemplate: action.payload };

    case EMAIL_TEMPLATE_PAGE_CHANGE_CURRENT:
      return { ...state, pageRequest: action.payload };

    case EMAIL_TEMPLATE_TOGGLE_MODAL:
      if (action.payload?.templateId !== undefined) {
        return {
          ...state,
          isModalOpen: action.payload?.isOpen,
          selectedTemplateId: action.payload?.templateId,
          isModalEditorMode: action.payload?.editorMode,
        };
      } else {
        return {
          ...state,
          isModalOpen: action.payload?.isOpen,
          isModalEditorMode: action.payload?.editorMode,
        };
      }

    default:
      return state;
  }
}
