import {
  SMS_CHANNEL_DELETE_FETCH,
  SMS_CHANNEL_DELETE_FETCH_FAIL,
  SMS_CHANNEL_DELETE_FETCH_SUCCESS,
  SMS_CHANNEL_CREATE_FETCH,
  SMS_CHANNEL_CREATE_FETCH_FAIL,
  SMS_CHANNEL_CREATE_FETCH_SUCCESS,
  SMS_CHANNEL_LIST_FETCH,
  SMS_CHANNEL_LIST_FETCH_FAIL,
  SMS_CHANNEL_LIST_FETCH_SUCCESS,
  SMS_CHANNEL_UPDATE_FETCH,
  SMS_CHANNEL_UPDATE_FETCH_FAIL,
  SMS_CHANNEL_UPDATE_FETCH_SUCCESS,
  SMS_CHANNEL_UPDATE_SUBMIT_FETCH,
  SMS_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL,
  SMS_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS,
  SMS_CHANNEL_CHANGE_CURRENT,
  SMS_CHANNEL_PAGE_CHANGE_CURRENT,
  INFO_ENUM_LIST_FETCH_SUCCESS,
  SMS_CHANNEL_SEARCH_LIST_FETCH,
  SMS_CHANNEL_SEARCH_LIST_FETCH_SUCCESS,
  SMS_CHANNEL_SEARCH_LIST_FETCH_FAIL,
} from './SmsChannelConstants';

const initialState = {
  page: {},
  loadingList: false,
  loadingSearchList: false,
  pageRequest: {},
  errors: [],

  currentSmsChannel: {},

  errorsUpdate: [],
  loadingUpdate: false,

  errorsUpdateSubmit: [],
  loadingUpdateSubmit: false,

  errorsDelete: [],
  loadingDelete: false,

  errorsCreate: [],
  loadingCreate: false,

  enums: [],
};

// eslint-disable-next-line complexity
export default function SmsChannelState(state = initialState, action) {
  switch (action.type) {
    case SMS_CHANNEL_LIST_FETCH:
      return { ...state, pageRequest: action.payload, loadingList: true };
    case SMS_CHANNEL_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingList: false };
    case SMS_CHANNEL_LIST_FETCH_FAIL:
      return { ...state, errors: action.payload.errors, loadingList: false };

    case SMS_CHANNEL_SEARCH_LIST_FETCH:
      return { ...state, pageRequest: action.payload, loadingSearchList: true };
    case SMS_CHANNEL_SEARCH_LIST_FETCH_SUCCESS:
      return { ...state, page: action.payload, loadingSearchList: false };
    case SMS_CHANNEL_SEARCH_LIST_FETCH_FAIL:
      return { ...state, errors: action.payload, loadingSearchList: false };

    case SMS_CHANNEL_UPDATE_SUBMIT_FETCH:
      return { ...state, loadingUpdateSubmit: true };
    case SMS_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        errorsUpdateSubmit: action.payload.errors,
        loadingUpdateSubmit: false,
      };
    case SMS_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingUpdateSubmit: false };

    case SMS_CHANNEL_UPDATE_FETCH:
      return { ...state, loadingUpdate: true };
    case SMS_CHANNEL_UPDATE_FETCH_FAIL:
      return {
        ...state,
        errorsUpdate: action.payload.errors,
        loadingUpdate: false,
      };
    case SMS_CHANNEL_UPDATE_FETCH_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        currentSmsChannel: action.payload,
      };

    case SMS_CHANNEL_CREATE_FETCH:
      return { ...state, loadingCreate: true };
    case SMS_CHANNEL_CREATE_FETCH_FAIL:
      return {
        ...state,
        errorsCreate: action.payload.errors,
        loadingCreate: false,
      };
    case SMS_CHANNEL_CREATE_FETCH_SUCCESS:
      return { ...state, loadingCreate: false };

    case SMS_CHANNEL_DELETE_FETCH:
      return { ...state, loadingDelete: true };
    case SMS_CHANNEL_DELETE_FETCH_FAIL:
      return {
        ...state,
        errorsDelete: action.payload.errors,
        loadingDelete: false,
      };
    case SMS_CHANNEL_DELETE_FETCH_SUCCESS:
      return { ...state, loadingDelete: false };

    case SMS_CHANNEL_CHANGE_CURRENT:
      return { ...state, currentSmsChannel: action.payload };

    case SMS_CHANNEL_PAGE_CHANGE_CURRENT:
      return { ...state, pageRequest: action.payload };

    case INFO_ENUM_LIST_FETCH_SUCCESS:
      return { ...state, enums: action.payload };

    default:
      return state;
  }
}
