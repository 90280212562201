const RestrictionOperator = {
  //GENERAL
  EQUAL_TO: 'Equal to',
  IS_NOT_EMPTY: 'Is not empty',
  //STRING
  ONE_OF: 'One of',
  STARTS_WITH: 'Starts with',
  ENDS_WITH: 'Ends with',
  MATCHES_REGEX: 'Matches regex',
  CONTAINS: 'Contains',
  //NUMERIC_DATE
  BETWEEN: 'Between',
  // Date
  DATE_EQUALS: 'Date Equal to',
};

export default RestrictionOperator;
