export const cardNames = {
  EVENTS_AND_PROFILES: 'Events and Profiles',
  LUNCHED_CAMPAIGN: 'Launched Campaigns',
  // RUNNING_COMMUNICATION_BY_CHANNEL: 'Running Campaigns(By Channel)',    TODO: whenever we have split by channel data for Running campaign
  RUNNING_COMMUNICATION_BY_TYPE: 'Running Campaigns',
  REVENUE: 'Total Revenue',
  TOTAL_INCREMENTAL_REVENUE: 'Total Incremental Revenue',
  UPLIFT: 'Total Uplift',
  SENT_MESSAGE: 'Sent Messages',
  CHANNEL_REACHABILITY: 'Channel Reachability',
  // DELIVERY_RATE: 'Delivery Rate',
};

export const cardMetrics = {
  EVENTS_AND_PROFILES: [
    {
      label: 'System Events',
      key: 'SYSTEM_EVENTS',
      type: '',
    },
    {
      label: 'Custom Events',
      key: 'CUSTOM_EVENTS',
      type: '',
    },
    {
      label: 'Total Users(DAU)',
      key: 'ACTIVE_USERS_DAU',
      type: 'DAU',
    },
    {
      label: 'Total Users(WAU)',
      key: 'ACTIVE_USERS_WAU',
      type: 'WAU',
    },
    {
      label: 'Total Users(MAU)',
      key: 'ACTIVE_USERS_MAU',
      type: 'MAU',
    },
    {
      label: 'Total Users',
      key: 'TOTAL_USERS',
      type: '',
    },
    {
      label: 'Known Users',
      key: 'KNOWN_USERS',
      type: '',
    },
    {
      label: 'Unknown Users',
      key: 'UNKNOWN_USERS',
      type: '',
    },
  ],
  LUNCHED_CAMPAIGN: [
    {
      label: 'Launched Campaigns',
      key: 'ENDED_CAMPAIGNS',
      type: 'TOTAL',
    },
    {
      label: 'Launched SMS Campaigns',
      key: 'ENDED_CAMPAIGNS_SMS',
      type: 'SMS',
    },
    {
      label: 'Launched Email Campaigns',
      key: 'ENDED_CAMPAIGNS_EMAIL',
      type: 'EMAIL',
    },
    {
      label: 'Launched Push Campaigns',
      key: 'ENDED_CAMPAIGNS_PUSH',
      type: 'PUSH',
    },
    {
      label: 'Launched Web Push Campaigns',
      key: 'ENDED_CAMPAIGNS_WEB_PUSH',
      type: 'WEB_PUSH',
    },
    {
      label: 'Launched On-site Campaigns',
      key: 'ENDED_CAMPAIGNS_ON_SITE',
      type: 'ON_SITE',
    },
    {
      label: 'Launched In-app Campaigns',
      key: 'ENDED_CAMPAIGNS_IN_APP',
      type: 'IN_APP',
    },
    {
      label: 'Launched Custom Channel Campaigns',
      key: 'ENDED_CAMPAIGNS_CUSTOM_CHANNEL',
      type: 'CUSTOM',
    },
    {
      label: 'Launched Whatsapp Campaigns',
      key: 'ENDED_CAMPAIGNS_WHATSAPP',
      type: 'WHATSAPP',
    },
  ],
  // RUNNING_COMMUNICATION_BY_CHANNEL: [
  //   {
  //     label: 'Running Campaigns',
  //     key: 'RUNNING_CAMPAIGNS',
  //     type: 'TOTAL',
  //   },
  //   {
  //     label: 'Running SMS Campaigns',
  //     key: 'RUNNING_CAMPAIGNS_SMS',
  //     type: 'SMS',
  //   },
  //   {
  //     label: 'Running Email Campaigns',
  //     key: 'RUNNING_CAMPAIGNS_EMAIL',
  //     type: 'EMAIL',
  //   },
  //   {
  //     label: 'Running Push Campaigns',
  //     key: 'RUNNING_CAMPAIGNS_PUSH',
  //     type: 'PUSH',
  //   },
  //   {
  //     label: 'Running Web Push Campaigns',
  //     key: 'RUNNING_CAMPAIGNS_WEB_PUSH',
  //     type: 'WEB_PUSH',
  //   },
  //   {
  //     label: 'Running On-site Campaigns',
  //     key: 'RUNNING_CAMPAIGNS_ON_SITE',
  //     type: 'ON_SITE',
  //   },
  //   {
  //     label: 'Running In-app Campaigns',
  //     key: 'RUNNING_CAMPAIGNS_IN_APP',
  //     type: 'IN_APP',
  //   },
  //   {
  //     label: 'Running Custom Channel Campaigns',
  //     key: 'RUNNING_CAMPAIGNS_CUSTOM_CHANNEL',
  //     type: 'CUSTOM',
  //   },
  //   {
  //     label: 'Running Whatsapp Campaigns',
  //     key: 'RUNNING_CAMPAIGNS_WHATSAPP',
  //     type: 'WHATSAPP',
  //   },
  //   {
  //     label: 'Running Journeys',
  //     key: 'RUNNING_JOURNEYS',
  //     type: 'TOTAL',
  //   },
  //   {
  //     label: 'Running Relays',
  //     key: 'RUNNING_RELAYS',
  //     type: 'TOTAL',
  //   },
  // ],
  RUNNING_COMMUNICATION_BY_TYPE: [
    {
      label: 'Running Campaigns',
      key: 'RUNNING_CAMPAIGNS',
      type: 'TOTAL',
    },
    {
      label: 'Running One Time Campaigns',
      key: 'RUNNING_CAMPAIGNS_ONE_TIME',
      type: 'ONE_TIME',
    },
    {
      label: 'Running Triggered Campaigns',
      key: 'RUNNING_CAMPAIGNS_TRIGGERED',
      type: 'TRIGGERED',
    },
    {
      label: 'Running Recurring Campaigns',
      key: 'RUNNING_CAMPAIGNS_RECURRING',
      type: 'RECURRING',
    },
    {
      label: 'Running Transactional Campaigns',
      key: 'RUNNING_CAMPAIGNS_TRANSACTIONAL',
      type: 'TRANSACTIONAL',
    },
    {
      label: 'Running Journeys',
      key: 'RUNNING_JOURNEYS',
      type: 'TOTAL',
    },
    {
      label: 'Running Relays',
      key: 'RUNNING_RELAYS',
      type: 'TOTAL',
    },
  ],
  REVENUE: [
    {
      label: 'Total Revenue (Rial)',
      key: 'REVENUE',
      type: '',
    },
    {
      label: 'Total Revenue Per SMS',
      key: 'REVENUE_SMS',
      type: 'SMS',
    },
    {
      label: 'Total Revenue Per Email',
      key: 'REVENUE_EMAIL',
      type: 'EMAIL',
    },
    {
      label: 'Total Revenue Per Push',
      key: 'REVENUE_PUSH',
      type: 'PUSH',
    },
    {
      label: 'Total Revenue Per Web push',
      key: 'REVENUE_WEB_PUSH',
      type: 'WEB_PUSH',
    },
    {
      label: 'Total Revenue Per On-site',
      key: 'REVENUE_ON_SITE',
      type: 'ON_SITE',
    },
    {
      label: 'Total Revenue Per In-app',
      key: 'REVENUE_IN_APP',
      type: 'IN_APP',
    },
    {
      label: 'Total Revenue Per Custom Channel',
      key: 'REVENUE_CUSTOM_CHANNEL',
      type: 'CUSTOM',
    },
    {
      label: 'Total Revenue Per Whatsapp',
      key: 'REVENUE_WHATSAPP',
      type: 'WHATSAPP',
    },
  ],
  TOTAL_INCREMENTAL_REVENUE: [
    {
      label: 'Total Incremental Revenue',
      key: 'INCREMENTAL_REVENUE',
      type: '',
    },
    {
      label: 'Total Incremental Revenue Per SMS',
      key: 'INCREMENTAL_REVENUE_SMS',
      type: 'SMS',
    },
    {
      label: 'Total Incremental Revenue Per Email',
      key: 'INCREMENTAL_REVENUE_EMAIL',
      type: 'EMAIL',
    },
    {
      label: 'Total Incremental Revenue Per Push',
      key: 'INCREMENTAL_REVENUE_PUSH',
      type: 'PUSH',
    },
    {
      label: 'Total Incremental Revenue Per Web push',
      key: 'INCREMENTAL_REVENUE_WEB_PUSH',
      type: 'WEB_PUSH',
    },
    {
      label: 'Total Incremental Revenue Per On-site',
      key: 'INCREMENTAL_REVENUE_ON_SITE',
      type: 'ON_SITE',
    },
    {
      label: 'Total Incremental Revenue Per In-app',
      key: 'INCREMENTAL_REVENUE_IN_APP',
      type: 'IN_APP',
    },
    {
      label: 'Total Incremental Revenue Per Custom Channel',
      key: 'INCREMENTAL_REVENUE_CUSTOM_CHANNEL',
      type: 'CUSTOM',
    },
    {
      label: 'Total Incremental Revenue Per Whatsapp',
      key: 'INCREMENTAL_REVENUE_WHATSAPP',
      type: 'WHATSAPP',
    },
  ],
  UPLIFT: [
    {
      label: 'Total Uplift',
      key: 'UPLIFT',
      type: '',
    },
    {
      label: 'Uplift Per SMS',
      key: 'UPLIFT_SMS',
      type: 'SMS',
    },
    {
      label: 'Uplift Per Email',
      key: 'UPLIFT_EMAIL',
      type: 'EMAIL',
    },
    {
      label: 'Uplift Per Push',
      key: 'UPLIFT_PUSH',
      type: 'PUSH',
    },
    {
      label: 'Uplift Per Web push',
      key: 'UPLIFT_WEB_PUSH',
      type: 'WEB_PUSH',
    },
    {
      label: 'Uplift Per On-site',
      key: 'UPLIFT_ON_SITE',
      type: 'ON_SITE',
    },
    {
      label: 'Uplift Per In-app',
      key: 'UPLIFT_IN_APP',
      type: 'IN_APP',
    },
    {
      label: 'Uplift Per Custom Channel',
      key: 'UPLIFT_CUSTOM_CHANNEL',
      type: 'CUSTOM',
    },
    {
      label: 'Uplift Per Whatsapp',
      key: 'UPLIFT_WHATSAPP',
      type: 'WHATSAPP',
    },
  ],
  SENT_MESSAGE: [
    {
      label: 'Sent Messages',
      key: 'SENT_MESSAGES',
      type: '',
    },
    {
      label: 'SMS Sent Messages',
      key: 'SENT_MESSAGES_SMS',
      type: 'SMS',
    },
    {
      label: 'Email Sent Messages',
      key: 'SENT_MESSAGES_EMAIL',
      type: 'EMAIL',
    },
    {
      label: 'Push Sent Messages',
      key: 'SENT_MESSAGES_PUSH',
      type: 'PUSH',
    },
    {
      label: 'Web push Sent Messages',
      key: 'SENT_MESSAGES_WEB_PUSH',
      type: 'WEB_PUSH',
    },
    {
      label: 'On-site Sent Messages',
      key: 'SENT_MESSAGES_ON_SITE',
      type: 'ON_SITE',
    },
    {
      label: 'In-app Sent Messages',
      key: 'SENT_MESSAGES_IN_APP',
      type: 'IN_APP',
    },
    {
      label: 'Custom Channel Sent Messages',
      key: 'SENT_MESSAGES_CUSTOM_CHANNEL',
      type: 'CUSTOM',
    },
    {
      label: 'Whatsapp Sent Messages',
      key: 'SENT_MESSAGES_WHATSAPP',
      type: 'WHATSAPP',
    },
  ],
  CHANNEL_REACHABILITY: [
    {
      label: 'Total Reachable Users',
      key: 'REACHABLE_USERS',
      type: 'TOTAL',
    },
    {
      label: 'SMS Reachable Users',
      key: 'REACHABLE_USERS_SMS',
      type: 'SMS',
    },
    {
      label: 'Email Reachable Users',
      key: 'REACHABLE_USERS_EMAIL',
      type: 'EMAIL',
    },
    {
      label: 'Push Reachable Users',
      key: 'REACHABLE_USERS_PUSH',
      type: 'PUSH',
    },
    {
      label: 'Web Push Reachable Users',
      key: 'REACHABLE_USERS_WEB_PUSH',
      type: 'WEB_PUSH',
    },
    {
      label: 'On-site Reachable Users',
      key: 'REACHABLE_USERS_ON_SITE',
      type: 'ON_SITE',
    },
    {
      label: 'In-app Reachable Users',
      key: 'REACHABLE_USERS_IN_APP',
      type: 'IN_APP',
    },
    {
      label: 'Whatsapp Reachable Users',
      key: 'REACHABLE_USERS_WHATSAPP',
      type: 'WHATSAPP',
    },
  ],

  // DELIVERY_RATE: [
  //   {
  //     label: 'Delivery Rate', //???
  //     key: 'DELIVERY_RATE',
  //   },
  //   {
  //     label: 'Delivery Rate Per SMS',
  //     key: 'DELIVERY_RATE_SMS',
  //   },
  //   {
  //     label: 'Delivery Rate Per Email',
  //     key: 'DELIVERY_RATE_EMAIL',
  //   },
  //   {
  //     label: 'Delivery Rate Per Push',
  //     key: 'DELIVERY_RATE_PUSH',
  //   },
  //   {
  //     label: 'Delivery Rate Per Web push',
  //     key: 'DELIVERY_RATE_WEB_PUSH',
  //   },
  //   {
  //     label: 'Delivery Rate Per On-site',
  //     key: 'DELIVERY_RATE_ON_SITE',
  //   },
  //   {
  //     label: 'Delivery Rate Per In-app',
  //     key: 'DELIVERY_RATE_IN_APP',
  //   },
  //   {
  //     label: 'Delivery Rate Per Custom Channel',
  //     key: 'DELIVERY_RATE_CUSTOM_CHANNEL',
  //   },
  //   {
  //     label: 'Delivery Rate Per Whatsapp',
  //     key: 'DELIVERY_RATE_WHATSAPP',
  //   },
  // ],
};

export const defaultMetrics = {
  EVENTS_AND_PROFILES: [
    {
      label: 'System Events',
      key: 'SYSTEM_EVENTS',
      type: '',
    },
    {
      label: 'Custom Events',
      key: 'CUSTOM_EVENTS',
      type: '',
    },
  ],
  LUNCHED_CAMPAIGN: [
    {
      label: 'Launched Campaigns',
      key: 'ENDED_CAMPAIGNS',
      type: 'TOTAL',
    },
    {
      label: 'Launched SMS Campaigns',
      key: 'ENDED_CAMPAIGNS_SMS',
      type: 'SMS',
    },
  ],
  // RUNNING_COMMUNICATION_BY_CHANNEL: [
  //   {
  //     label: 'Running Campaigns',
  //     key: 'RUNNING_CAMPAIGNS',
  //     type: 'TOTAL',
  //   },
  //   {
  //     label: 'Running SMS Campaigns',
  //     key: 'RUNNING_CAMPAIGNS_SMS',
  //     type: 'SMS',
  //   },
  // ],
  RUNNING_COMMUNICATION_BY_TYPE: [
    {
      label: 'Running Campaigns',
      key: 'RUNNING_CAMPAIGNS',
      type: 'TOTAL',
    },
    {
      label: 'Running One Time Campaigns',
      key: 'RUNNING_CAMPAIGNS_ONE_TIME',
      type: 'ONE_TIME',
    },
  ],
  REVENUE: [
    {
      label: 'Total Revenue (Rial)',
      key: 'REVENUE',
      type: '',
    },
    {
      label: 'Total Revenue Per SMS',
      key: 'REVENUE_SMS',
      type: 'SMS',
    },
  ],
  TOTAL_INCREMENTAL_REVENUE: [
    {
      label: 'Total Incremental Revenue',
      key: 'INCREMENTAL_REVENUE',
      type: '',
    },
    {
      label: 'Total Incremental Revenue Per SMS',
      key: 'INCREMENTAL_REVENUE_SMS',
      type: 'SMS',
    },
  ],
  UPLIFT: [
    {
      label: 'Total Uplift',
      key: 'UPLIFT',
      type: '',
    },
    {
      label: 'Uplift Per SMS',
      key: 'UPLIFT_SMS',
      type: 'SMS',
    },
  ],
  SENT_MESSAGE: [
    {
      label: 'Sent Messages',
      key: 'SENT_MESSAGES',
      type: '',
    },
    {
      label: 'SMS Sent Messages',
      key: 'SENT_MESSAGES_SMS',
      type: 'SMS',
    },
  ],
  CHANNEL_REACHABILITY: [
    {
      label: 'Total Reachable Users',
      key: 'REACHABLE_USERS',
      type: 'TOTAL',
    },
    {
      label: 'SMS Reachable Users',
      key: 'REACHABLE_USERS_SMS',
      type: 'SMS',
    },
  ],

  // DELIVERY_RATE: [
  //   {
  //     label: 'Delivery Rate', //???
  //     key: 'DELIVERY_RATE',
  //   },
  //   {
  //     label: 'Delivery Rate Per SMS',
  //     key: 'DELIVERY_RATE_SMS',
  //   },
  //   {
  //     label: 'Delivery Rate Per Email',
  //     key: 'DELIVERY_RATE_EMAIL',
  //   },
  // ],
};

export const reportsEnum = [
  'SYSTEM_EVENTS',
  'CUSTOM_EVENTS',
  'ACTIVE_USERS',
  'ENDED_CAMPAIGNS',
  'RUNNING_CAMPAIGNS',
  'RUNNING_JOURNEYS',
  'RUNNING_RELAYS',
  'SENT_MESSAGES',
  'REVENUE',
  'INCREMENTAL_REVENUE',
  'UPLIFT',
  'TOTAL_USERS',
  'KNOWN_USERS',
  'UNKNOWN_USERS',
  'REACHABLE_USERS',
  // 'DELIVERY_RATE',
];

export const cardReportEnums = {
  EVENTS_AND_PROFILES: [
    'SYSTEM_EVENTS',
    'CUSTOM_EVENTS',
    'ACTIVE_USERS',
    'TOTAL_USERS',
    'KNOWN_USERS',
    'UNKNOWN_USERS',
  ],
  LUNCHED_CAMPAIGN: ['ENDED_CAMPAIGNS'],
  // RUNNING_COMMUNICATION_BY_CHANNEL: [
  //   'RUNNING_CAMPAIGNS',
  //   'RUNNING_JOURNEYS',
  //   'RUNNING_RELAYS',
  // ],
  RUNNING_COMMUNICATION_BY_TYPE: [
    'RUNNING_CAMPAIGNS',
    'RUNNING_JOURNEYS',
    'RUNNING_RELAYS',
  ],
  REVENUE: ['REVENUE'],
  TOTAL_INCREMENTAL_REVENUE: ['INCREMENTAL_REVENUE'],
  UPLIFT: ['UPLIFT'],
  SENT_MESSAGE: ['SENT_MESSAGES'],
  CHANNEL_REACHABILITY: ['REACHABLE_USERS'],
};
