import CommunicationOverviewComponent from '../component/CommunicationOverviewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  communicationOverviewFetch,
  communicationSplitOverviewFetch,
  communicationListTagsFetch,
  communicationSplitOverviewPageChangeCurrent,
} from '../CommunicationActions';
import PropTypes from 'prop-types';
import { revenueMappingUpdateFetch } from '@Redux/slices/data/revenueMappingSlice';

const mapStateToProps = (state) => ({
  loadingOverview: state.engage.communication.loadingOverview,
  loadingSplitOverview: state.engage.communication.loadingSplitOverview,
  loadingListTags: state.engage.communication.loadingListTags,
  pageRequestOverview: state.engage.communication.pageRequestOverview,
  pageRequestSplitOverview: state.engage.communication.pageRequestSplitOverview,
  errorsListTags: state.engage.communication.errorsListTags,
  errorsOverview: state.engage.communication.errorsOverview,
  errorsSplitOverview: state.engage.communication.errorsSplitOverview,
  overview: state.engage.communication.overview,
  listTags: state.engage.communication.listTags,
  splitOverview: state.engage.communication.splitOverview,
  pathname: state.router.location.pathname,
  currentRevenueMapping: state.dataModule.revenueMapping.currentRevenueMapping,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      communicationOverviewFetch,
      communicationSplitOverviewFetch,
      communicationListTagsFetch,
      communicationSplitOverviewPageChangeCurrent,
      revenueMappingUpdateFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationOverviewComponent);

CommunicationOverviewComponent.propTypes = {
  communicationSplitOverviewFetch: PropTypes.func.isRequired,
  communicationOverviewFetch: PropTypes.func.isRequired,
};
