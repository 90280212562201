import { combineReducers } from 'redux';
import adminProduct from '@Modules/admin/adminProduct/AdminProductState';
import configuration from '@Modules/admin/configuration/ConfigurationState';
import role from '@Modules/admin/role/RoleState';
import user from '@Modules/admin/user/UserState';
import fileStorage from '@Modules/admin/fileStorage/FileStorageState';
import releaseNote from '@Modules/admin/releaseNote/ReleaseNoteState';
import thirdParty from './thirdPartySlice';
import contractTemplate from './contractTemplateSlice';
import contract from './adminProductContractSlice';
import managementDashboard from './managementDashboardSlice';

const reducer = combineReducers({
  adminProduct,
  configuration,
  role,
  user,
  fileStorage,
  releaseNote,
  thirdParty,
  contractTemplate,
  contract,
  managementDashboard,
});
export default reducer;
