import React, { useEffect, useState } from 'react';
import { findIndex } from 'lodash';
import { Input, Select, TimePicker } from 'antd';
import moment from 'moment';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import vars from '@Theme/styles/vars';

const styles = {
  renderDays: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15,
  },
  editContainer: {
    marginTop: 20,
    alignItems: 'flex-start',
  },
  editLabel: {
    position: 'relative',
    paddingBottom: '10px',
    borderBottom: '1px solid #eee',
    marginBottom: '15px',
  },
  editFieldContainer: {},

  editFieldContainer_inner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  editFieldContainer_box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // padding: '10px',
    border: '1px solid #eee',
    padding: '10px 10px',
  },
  weekDay: {
    display: 'flex',
    borderRadius: 5,
    marginLeft: 4,
    marginRight: 4,
    color: vars.primaryColor,
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    borderColor: vars.primaryColor,
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  selectedWeekDay: {
    display: 'flex',
    borderRadius: 5,
    marginLeft: 4,
    marginRight: 4,
    color: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    borderColor: vars.primaryColor,
    backgroundColor: vars.primaryColor,
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
};
const RecurringSelector = (props) => {
  const [repeatDays, setRepeatDays] = useState([]);
  const [repeatType, setRepeatType] = useState('WEEK'); // when change recurring be enabled or disabled
  const [repeatTime, setRepeatTime] = useState();
  const [enable, setEnable] = useState(false);
  const [repeatEveryValue, setRepeatEveryValue] = useState(1);
  const { title, data, setData, readOnly } = props;
  useEffect(() => {
    if (data) {
      setRepeatEveryValue(data.repeatEvery);
      setRepeatDays(data.repeatDays);
      setRepeatType(data.repeatType);
      setRepeatTime(data.repeatTime);
      if (data.repeatType) {
        setEnable(true);
      }
    }
  }, []);

  useEffect(() => {
    setData({
      repeatDays,
      repeatType,
      repeatTime,
      repeatEvery: repeatEveryValue,
    });
  }, [repeatDays, repeatType, repeatTime, repeatEveryValue]);

  const isEditDisable = () => {
    return readOnly;
  };

  const toggleDay = (weekDay) => {
    if (!isEditDisable()) {
      const repeatDays_ = [...repeatDays];
      const foundIndex = findIndex(repeatDays, (o) => o === weekDay);
      if (foundIndex > -1) {
        repeatDays_.splice(foundIndex, 1);
      } else {
        repeatDays_.push(weekDay);
      }
      // this.setState({ repeatDays });
      setRepeatDays(repeatDays_);
    }
  };

  const isWeekDaySelected = (weekDay) => {
    return (
      repeatDays && Array.isArray(repeatDays) && repeatDays.includes(weekDay)
    );
  };

  const renderWeekDays = () => {
    const weekDayNames = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
    const result = [];
    weekDayNames.forEach((item, index) => {
      result.push(
        <div
          id={'int-communication-week-day-' + index}
          onClick={() => toggleDay(index)}
          style={
            isWeekDaySelected(index) ? styles.selectedWeekDay : styles.weekDay
          }
        >
          {item}
        </div>
      );
    });
    return result;
  };

  const renderMonthDays = (day) => {
    if (day[1] === -1) {
      return (
        <div
          id={'int-communication-month-day-' + day[1]}
          onClick={() => toggleDay(day[1])}
          style={
            isWeekDaySelected(day[1])
              ? { ...styles.selectedWeekDay, width: 130 }
              : { ...styles.weekDay, width: 130 }
          }
        >
          {day[0]}
        </div>
      );
    } else {
      return (
        <div
          id={'int-communication-month-day-' + day[1]}
          onClick={() => toggleDay(day[1])}
          style={
            isWeekDaySelected(day[1]) ? styles.selectedWeekDay : styles.weekDay
          }
        >
          {day[0]}
        </div>
      );
    }
  };

  const renderMonthWeeks = () => {
    const monthDayNamesChunks = [
      [
        ['1st', 0],
        ['2nd', 1],
        ['3rd', 2],
        ['4th', 3],
        ['5th', 4],
        ['6th', 5],
      ],
      [
        ['7th', 6],
        ['8th', 7],
        ['9th', 8],
        ['10th', 9],
        ['11th', 10],
        ['12th', 11],
      ],
      [
        ['13th', 12],
        ['14th', 13],
        ['15th', 14],
        ['16th', 15],
        ['17th', 16],
        ['18th', 17],
      ],
      [
        ['19th', 18],
        ['20th', 19],
        ['21st', 20],
        ['22nd', 21],
        ['23rd', 22],
        ['24th', 23],
      ],
      [
        ['25th', 24],
        ['26th', 25],
        ['27th', 26],
        ['28th', 27],
        ['29th', 28],
        ['30th', 29],
      ],
      [
        ['31st', 30],
        ['Last Day of Month', -1],
      ],
    ];
    const result = [];
    monthDayNamesChunks.forEach((chunk, chunkIndex) => {
      result.push(
        <div
          style={
            chunkIndex === monthDayNamesChunks.length - 1
              ? { display: 'flex', flexDirection: 'row' }
              : {
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 8,
                }
          }
        >
          {chunk.map((day) => renderMonthDays(day))}
        </div>
      );
    });
    return result;
  };

  const handleChangeRepeatValue = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e === '' || regex.test(e)) {
      setRepeatEveryValue(e);
    }
  };

  const handleChangeRepeatType = (repeatType_) => {
    if (repeatType !== repeatType_) {
      setRepeatDays([]);
      setRepeatType(repeatType_);
    }
  };

  const handleEnable = (e) => {
    setEnable(e.target.checked);
    if (e.target.checked) {
      setRepeatType('DAY');
    } else {
      setRepeatType(null);
    }
    setRepeatDays([]);
    setRepeatTime(null);
  };

  return (
    <div style={styles.editContainer}>
      {title && <div style={styles.editLabel}>{title}</div>}
      <div style={styles.editFieldContainer}>
        <div style={styles.editFieldContainer_inner}>
          <div style={{ padding: '16px 0px' }}>
            <Checkbox
              disabled={readOnly}
              checked={enable}
              onChange={handleEnable}
            >
              Has Recurring{' '}
            </Checkbox>
          </div>
          {enable ? (
            <div style={styles.editFieldContainer_box}>
              <span style={{ marginTop: 5 }}>Repeat every</span>
              <Input
                disabled={isEditDisable()}
                value={repeatEveryValue}
                onChange={(e) => handleChangeRepeatValue(e.target.value)}
                style={{
                  width: 60,
                  marginLeft: 20,
                  marginRight: 20,
                }}
              />

              <Select
                disabled={isEditDisable()}
                placeholder={'Select Type'}
                style={{ width: 130, marginRight: 20 }}
                value={repeatType}
                onChange={(e) => handleChangeRepeatType(e)}
              >
                <Select.Option value={'DAY'}>
                  {repeatEveryValue === 1 ? 'Day' : 'Days'}
                </Select.Option>
                <Select.Option value={'WEEK'}>
                  {repeatEveryValue === 1 ? 'Week' : 'Weeks'}
                </Select.Option>
                <Select.Option value={'MONTH'}>
                  {repeatEveryValue === 1 ? 'Month' : 'Months'}
                </Select.Option>
              </Select>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {repeatType === 'WEEK' && (
                  <div style={styles.renderDays}>
                    <span
                      style={{
                        marginRight: 10,
                        marginTop: 5,
                      }}
                    >
                      on
                    </span>
                    {renderWeekDays()}
                  </div>
                )}
                {repeatType === 'MONTH' && (
                  <div style={styles.renderDays}>
                    <span
                      style={{
                        marginRight: 10,
                        marginTop: 5,
                      }}
                    >
                      on
                    </span>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {renderMonthWeeks()}
                    </div>
                  </div>
                )}
                <div>
                  <span>at</span>
                  <TimePicker
                    disabled={isEditDisable()}
                    format={'HH:mm'}
                    value={repeatTime ? moment(repeatTime, 'HH:mm:ss') : null}
                    onChange={(value) =>
                      setRepeatTime(value.format('HH:mm:ss'))
                    }
                    style={{ marginLeft: 20 }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default RecurringSelector;
