export const AUTH_CURRENT_USER_FETCH = 'AUTH_CURRENT_USER_FETCH';
export const AUTH_CURRENT_USER_FETCH_FAIL = 'AUTH_CURRENT_USER_FETCH_FAIL';
export const AUTH_CURRENT_USER_FETCH_SUCCESS =
  'AUTH_CURRENT_USER_FETCH_SUCCESS';

export const AUTH_LOGIN_FETCH = 'AUTH_LOGIN_FETCH';
export const AUTH_LOGIN_FETCH_FAIL = 'AUTH_LOGIN_FETCH_FAIL';
export const AUTH_LOGIN_FETCH_SUCCESS = 'AUTH_LOGIN_FETCH_SUCCESS';

export const AUTH_LOGOUT_FETCH = 'AUTH_LOGOUT_FETCH';
export const AUTH_LOGOUT_FETCH_FAIL = 'AUTH_LOGOUT_FETCH_FAIL';
export const AUTH_LOGOUT_FETCH_SUCCESS = 'AUTH_LOGOUT_FETCH_SUCCESS';

export const AUTH_REGISTER_FETCH = 'AUTH_REGISTER_FETCH';
export const AUTH_REGISTER_FETCH_FAIL = 'AUTH_REGISTER_FETCH_FAIL';
export const AUTH_REGISTER_FETCH_SUCCESS = 'AUTH_REGISTER_FETCH_SUCCESS';

export const AUTH_FORGOT_FETCH = 'AUTH_FORGOT_FETCH';
export const AUTH_FORGOT_FETCH_FAIL = 'AUTH_FORGOT_FETCH_FAIL';
export const AUTH_FORGOT_FETCH_SUCCESS = 'AUTH_FORGOT_FETCH_SUCCESS';

export const AUTH_FORGOT_SUBMIT_FETCH = 'AUTH_FORGOT_SUBMIT_FETCH';
export const AUTH_FORGOT_SUBMIT_FETCH_FAIL = 'AUTH_FORGOT_SUBMIT_FETCH_FAIL';
export const AUTH_FORGOT_SUBMIT_FETCH_SUCCESS =
  'AUTH_FORGOT_SUBMIT_FETCH_SUCCESS';

export const AUTH_LOGIN_CHANGE_CURRENT = 'AUTH_LOGIN_CHANGE_CURRENT';

export const CHANGE_MENU_LAYOUT = 'CHANGE_MENU_LAYOUT';

export const CHANGE_ACTIVE_PRODUCT = 'CHANGE_ACTIVE_PRODUCT';

export const AUTH_VERIFICATION_CODE = 'AUTH_VERIFICATION_CODE';
export const AUTH_LOGIN_SECOND_STEP = 'AUTH_LOGIN_SECOND_STEP';

export const AUTH_LOADING_RECAPTCHA = 'AUTH_LOADING_RECAPTCHA';

export const CAPTCHA_TOKEN = 'Captcha-Token';
export const CAPTCHA_ENABLED = 'Captcha-Enabled';
