import FunnelList from '../component/FunnelList';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  listFunnels,
  updateFunnelListParameters,
  deleteFunnel,
} from '../FunnelAction';

const mapStateToProps = (state) => ({
  ...state.analytics.funnel.list,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      listFunnels: listFunnels,
      updateFunnelListParameters: updateFunnelListParameters,
      deleteFunnel: deleteFunnel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FunnelList);
