import React from 'react';
import { useState } from 'react';
import CommunicationAudienceContainer from '../../../communication/container/CommunicationAudienceContainer';
import CommunicationWhenContainer from '../../../communication/container/CommunicationWhenContainer';
import CommunicationMessageContainer from '../../../communication/container/CommunicationMessageContainer';
import CommunicationConversionContainer from '../../../communication/container/CommunicationConversionContainer';
import CommunicationTestContainer from '../../../communication/container/CommunicationTestContainer';
// import { showMessage } from '../../../../utils/RenderUtils';
// * Send_PUSH

const SendPushComponent = (props) => {
  const title =
    props.type === 'JOURNEY' || props.type === 'RELAY'
      ? 'Basic Info'
      : 'Audience';
  const stepsDefaultPush = [
    {
      step: 'audience',
      nextButtonTitle: 'SAVE & CONTINUE',
      title: title,
    },
    { step: 'when', nextButtonTitle: 'SAVE & CONTINUE', title: 'When' },
    { step: 'message', nextButtonTitle: 'SAVE & CONTINUE', title: 'Message' },
    {
      step: 'conversion',
      nextButtonTitle: 'SAVE & CONTINUE',
      title: 'Tracking',
    },
    { step: 'test', nextButtonTitle: 'SAVE CAMPAIGN', title: 'Test Campaign' },
  ];
  const [step, setStep] = useState('audience');
  if (step === 'audience') {
    return (
      <CommunicationAudienceContainer
        communicationId={props.dataNode.communication}
        journey={props.type}
        channel={'PUSH'}
        journeySteps={stepsDefaultPush}
        updateJourney={props.updateJourney}
        dataNode={props.dataNode}
        readOnly={props.readOnly}
        goToStepOnJourney={setStep}
      />
    );
  } else if (step === 'when') {
    return (
      <CommunicationWhenContainer
        journey={props.type}
        channel={'PUSH'}
        communicationId={props.dataNode.communication}
        journeySteps={stepsDefaultPush}
        readOnly={props.readOnly}
        journeyStepsForVariable={props.journeyStepsForVariable}
        goToStepOnJourney={setStep}
      />
    );
  } else if (step === 'message') {
    return (
      <CommunicationMessageContainer
        journey={props.type}
        channel={'PUSH'}
        communicationId={props.dataNode.communication}
        journeySteps={stepsDefaultPush}
        readOnly={props.readOnly}
        journeyStepsForVariable={props.journeyStepsForVariable}
        goToStepOnJourney={setStep}
      />
    );
  } else if (step === 'conversion') {
    return (
      <CommunicationConversionContainer
        journey={props.type}
        communicationId={props.dataNode.communication}
        dataNode={props.dataNode}
        // updateJourney={props.updateJourney}
        journeySteps={stepsDefaultPush}
        closeModal={props.closeModal}
        readOnly={props.readOnly}
        goToStepOnJourney={setStep}
      />
    );
  } else if (step === 'test') {
    return (
      <CommunicationTestContainer
        journey={props.type}
        communicationId={props.dataNode.communication}
        journeySteps={stepsDefaultPush}
        closeModal={props.closeModal}
        dataNode={props.dataNode}
        updateJourney={props.updateJourney}
        readOnly={props.readOnly}
        goToStepOnJourney={setStep}
      />
    );
  }
  return null;
};

export default SendPushComponent;
