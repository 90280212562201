import React from 'react';
import CountUp from 'react-countup';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const OverviewBox = (props) => {
  const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 30 }} spin />;
  return (
    <div
      className="user-status"
      style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        height: 200,
        borderRadius: 5,
        position: 'relative',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
      }}
    >
      {false && (
        <div style={{ position: 'absolute', right: 10, top: 3 }}>
          <InfoCircleOutlined style={{ color: '#7D7D7D', fontSize: 12 }} />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-center',
          alignItems: 'center',
          flexDirection: 'column',
          height: 150,
        }}
      >
        <div
          style={{
            display: 'flex',
            color: '#202020',
            fontSize: 54,
          }}
        >
          {props.activityLoading ? (
            <div>
              <Spin indicator={inputLoadingSpin} />{' '}
            </div>
          ) : !props.segmentUsers ? (
            <CountUp
              start={0}
              decimals={0}
              duration={0.5}
              decimal=","
              separator=","
              end={props.usersNumbers}
            ></CountUp>
          ) : (
            <div>{props.usersNumbers}</div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            color: '#707070',
            fontSize: 14,
            lineHeight: '10px',
            justifyItems: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {props.usersStatus}
        </div>
        {false && (
          <div
            style={{
              display: 'flex',
              color: '#707070',
              fontSize: 14,
              marginTop: 20,
            }}
          >
            Today{' '}
            <span style={{ color: '#59CC8C', marginLeft: 10 }}>+1,067</span>
          </div>
        )}
        {props.knownUsers && (
          <div
            style={{
              display: 'flex',
              color: '#1c1c1c',
              fontSize: 14,
              marginTop: 20,
              justifyItems: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {props.knownUsersnumbers}
          </div>
        )}
        {false && (
          <div
            style={{
              display: 'flex',
              color: '#1c1c1c',
              fontSize: 14,
              marginTop: 20,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {this.renderNumber(this.state.webMonthlyActiveUsers)}
              <br />
              <span style={{ color: '#707070' }}>WEB</span>
            </div>
            <div>
              {this.renderNumber(this.state.androidMonthlyActiveUsers)}
              <br />
              <span style={{ color: '#707070' }}>ANDROID</span>
            </div>
            <div>
              {this.renderNumber(this.state.iosMonthlyActiveUsers)}
              <br />
              <span style={{ color: '#707070' }}>iOS</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default OverviewBox;
