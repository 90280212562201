import { createSlice } from '@reduxjs/toolkit';
import { showMessageError } from '@Utils/RenderUtils';
//import { history } from '@Redux/store';
import toastr from 'toastr';
import { appLoading } from './dataSlice';
import CustomAttributeApi from './customAttributeApi';

const initialState = {
  page: {},
  loadingList: false,
  loadingSearchList: false,
  pageRequest: {},
  errors: [],

  currentCustomAttribute: {},

  errorsUpdate: [],
  loadingUpdate: false,

  errorsUpdateSubmit: [],
  loadingUpdateSubmit: false,
  loadingDelete: false,
};

export const customAttributeSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    loadCustomAttributeListStart(state, { payload }) {
      state.pageRequest = payload;
    },
    loadCustomAttributeListSuccess(state, { payload }) {
      state.page = payload;
    },
    loadCustomAttributeListFail(state, { payload }) {
      state.error = payload;
    },
    loadCustomAttributeSearchListStart(state, { payload }) {
      state.pageRequest = payload;
      state.loadingSearchList = true;
    },
    loadCustomAttributeSearchListSuccess(state, { payload }) {
      state.page = payload;
      state.loadingSearchList = false;
    },
    loadCustomAttributeSearchListFail(state, { payload }) {
      state.error = payload.errors;
      state.loadingSearchList = false;
    },
    customAttributeChangeCurrent(state, { payload }) {
      state.currentCustomAttribute = payload;
    },
    customAttributePageChangeCurrent(state, { payload }) {
      state.pageRequest = payload;
    },
    customAttributeUpdateFetchStart(state) {
      state.loadingUpdate = true;
    },
    customAttributeUpdateFetchSuccess(state, { payload }) {
      state.currentCustomAttribute = payload;
      state.loadingUpdate = false;
    },
    customAttributeUpdateFetchFail(state, { payload }) {
      state.errors = payload.errors;
      state.loadingUpdate = false;
    },
    customAttributeUpdateSubmitFetchStart(state) {
      state.loadingUpdateSubmit = true;
    },
    customAttributeUpdateSubmitFetchSuccess(state) {
      state.loadingUpdateSubmit = false;
    },
    customAttributeUpdateSubmitFetchFail(state, { payload }) {
      state.errors = payload.errors;
      state.loadingUpdateSubmit = false;
    },
    customAttributeDeleteFetchSuccess(state, { payload }) {
      state.currentCustomAttribute = payload;
    },
    customAttributeDeleteFetchFail(state, { payload }) {
      state.erros = payload.errors;
    },
  },
});

export default customAttributeSlice.reducer;

export const {
  loadCustomAttributeListStart,
  loadCustomAttributeListSuccess,
  loadCustomAttributeListError,
  loadCustomAttributeSearchListStart,
  loadCustomAttributeSearchListSuccess,
  loadCustomAttributeSearchListFail,
  customAttributeChangeCurrent,
  customAttributePageChangeCurrent,
  customAttributeUpdateFetchStart,
  customAttributeUpdateFetchSuccess,
  customAttributeUpdateFetchFail,
  customAttributeUpdateSubmitFetchSuccess,
  customAttributeUpdateSubmitFetchFail,
  customAttributeUpdateSubmitFetchStart,
  customAttributeDeleteFetchFail,
  customAttributeDeleteFetchSuccess,
} = customAttributeSlice.actions;

const loadCustomAttributeList =
  (customAttributePageRequest) => async (dispatch) => {
    dispatch(appLoading(true));
    dispatch(loadCustomAttributeListStart(customAttributePageRequest));
    try {
      const response = await CustomAttributeApi.list(
        customAttributePageRequest
      );
      dispatch(loadCustomAttributeListSuccess(response));
      dispatch(appLoading(false));
    } catch (e) {
      dispatch(loadCustomAttributeListError(e));
      dispatch(appLoading(false));
    }
  };
export const customAttributeListFetch =
  (customAttributePageRequest) => (dispatch) => {
    try {
      dispatch(loadCustomAttributeList(customAttributePageRequest));
    } catch (e) {
      showMessageError(e);
    }
  };

const loadCustomAttributeSearchList =
  (customAttributePageRequest) => async (dispatch) => {
    dispatch(loadCustomAttributeSearchListStart(customAttributePageRequest));
    try {
      const response = await CustomAttributeApi.list(
        customAttributePageRequest
      );
      dispatch(loadCustomAttributeSearchListSuccess(response));
    } catch (e) {
      dispatch(loadCustomAttributeSearchListFail(e));
    }
  };

export const customAttributeSearchListFetch =
  (customAttributePageRequest) => (dispatch) => {
    try {
      dispatch(loadCustomAttributeSearchList(customAttributePageRequest));
    } catch (error) {
      showMessageError(error);
    }
  };

export const customAttributeUpdateFetch = (id) => async (dispatch) => {
  dispatch(customAttributeUpdateFetchStart());
  try {
    const response = await CustomAttributeApi.update(id);
    dispatch(customAttributeUpdateFetchSuccess(response));
  } catch (error) {
    dispatch(customAttributeUpdateFetchFail(error));
  }
};
export const customAttributeUpdateSubmitFetch =
  (id, customAttribute, pageRequest) => async (dispatch) => {
    dispatch(customAttributeUpdateSubmitFetchStart());
    try {
      CustomAttributeApi.updateSubmit(id, customAttribute)
        .then(() => {
          toastr.success('Has been updated successfully');
          dispatch(customAttributeUpdateSubmitFetchSuccess());
          dispatch(loadCustomAttributeList(pageRequest));
        })
        .catch((error) => {
          dispatch(customAttributeUpdateSubmitFetchFail(error));
          showMessageError(error);
        });
    } catch (error) {
      showMessageError(error);
    }
  };

export const customAttributeDeleteFetch =
  (id, customAttributePageRequest) => async (dispatch) => {
    dispatch(appLoading(true));
    try {
      const response = await CustomAttributeApi.delete(id);
      dispatch(customAttributeDeleteFetchSuccess(response));
      dispatch(loadCustomAttributeList(customAttributePageRequest));

      dispatch(appLoading(false));
    } catch (error) {
      dispatch(customAttributeDeleteFetchFail(error));
      dispatch(appLoading(false));
    }
  };
