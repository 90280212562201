import PushChannelComponent from '../component/PushChannelComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  pushChannelListFetch,
  pushChannelCreateAndroidFetch,
  pushChannelCreateIosFetch,
  pushChannelUpdateSubmitFetch,
  pushChannelDeleteFetch,
  pushChannelChangeCurrent,
  pushChannelFetchItem,
  pushIOSChannelUpdateSubmitFetch,
  pushChannelChangeList,
  pushChannelCreateFirebaseFetch,
  pushChannelCreateBrowserFetch,
  pushBrowserChannelUpdateSubmitFetch,
  pushFirebaseChannelUpdateSubmitFetch,
} from '../PushChannelActions';
import { communicationUploadFileFetch } from '../../../engage/communication/CommunicationActions';
import { currentUserFetch } from '../../../account/auth/AuthActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  uploadedFile: state.engage.communication.uploadedFile,
  loadingUploadFile: state.engage.communication.loadingUploadFile,
  errorsUploadFile: state.engage.communication.errorsUploadFile,
  toggleChangeAndroidList: state.channel.pushChannel.toggleChangeAndroidList,
  toggleChangeIosList: state.channel.pushChannel.toggleChangeIosList,
  androidList: state.channel.pushChannel.androidList,
  loadingAndroidList: state.channel.pushChannel.loadingAndroidList,
  errorsAndroid: state.channel.pushChannel.errorsAndroid,
  iosList: state.channel.pushChannel.iosList,
  loadingIosList: state.channel.pushChannel.loadingIosList,
  errorsIos: state.channel.pushChannel.errorsIos,
  loadingUpdateSubmit: state.channel.pushChannel.loadingUpdateSubmit,
  loadingIosUpdateSubmit: state.channel.pushChannel.loadingIosUpdateSubmit,
  currentAndroidPushChannel:
    state.channel.pushChannel.currentAndroidPushChannel,
  currentIosPushChannel: state.channel.pushChannel.currentIosPushChannel,
  currentfirebaseChannel: state.channel.pushChannel.currentfirebaseChannel,
  currentBrowserChannel: state.channel.pushChannel.currentBrowserChannel,
  loadingDelete: state.channel.pushChannel.loadingDelete,
  loadingCreateAndroid: state.channel.pushChannel.loadingCreateAndroid,
  loadingCreateIos: state.channel.pushChannel.loadingCreateIos,
  currentUser: state.account.auth.currentUser,
  firebaseList: state.channel.pushChannel.firebaseList,
  loadingFirebaseList: state.channel.pushChannel.loadingFirebaseList,
  browserList: state.channel.pushChannel.browserList,
  loadingBrowserList: state.channel.pushChannel.loadingBrowserList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      communicationUploadFileFetch,
      pushIOSChannelUpdateSubmitFetch,
      pushChannelListFetch,
      pushChannelCreateAndroidFetch,
      pushChannelCreateIosFetch,
      pushChannelUpdateSubmitFetch,
      pushChannelDeleteFetch,
      pushChannelChangeCurrent,
      pushChannelFetchItem,
      pushChannelChangeList,
      currentUserFetch,
      pushChannelCreateFirebaseFetch,
      pushChannelCreateBrowserFetch,
      pushBrowserChannelUpdateSubmitFetch,

      pushFirebaseChannelUpdateSubmitFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PushChannelComponent);

PushChannelComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  customAttributeListFetch: PropTypes.func.isRequired,
  customAttributeDeleteFetch: PropTypes.func.isRequired,
  customAttributePageChangeCurrent: PropTypes.func.isRequired,
};
