import React from 'react';
import { useEffect } from 'react';
import { Spin, Input } from 'antd';
import ProductSettingFooterComponent from './ProductSettingFooterComponent';
import { LoadingOutlined } from '@ant-design/icons';
import texts from '../../../../utils/texts';
import Card from '@Utils/Card';

const ProductSettingCustomDomainComponent = (props) => {
  useEffect(() => {
    props.customDomainUpdateFetch();
  }, []);

  const handleClickSave = () => {
    props.customDomainUpdateSubmitFetch(props.currentURL);
  };
  const content = `Any URL that you include in your SMS campaigns gets shortened to a smaller URL to reduce the number of characters in the message. Eg. A URL such as ${texts.shortnerSampleUrl} will get shortened to ${texts.shortnerDomain}/xyz in the actual SMS message received by the customer. The domain of this shortened URL is always ${texts.shortnerDomain} by default. You can choose to change this domain from ${texts.shortnerDomain} to a custom domain of your choice for branding purposes. Eg. If your custom domain is wsins.com then the shortened URL will be wsins.com/xyz instead of ${texts.shortnerDomain}/xyz.To make this change, please point your custom domain's CNAME entry to ${texts.shortnerDomain} and keep the TTL as 30 minutes. Once you've made this change, please enter your custom domain below and save the settings.`;
  const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
  return (
    <div style={{ padding: 20 }}>
      <Card
        styles={{
          paddingBottom: 30,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '12px',
            paddingBottom: 0,
            paddingLeft: '20px',
          }}
        >
          <div style={{ fontSize: 13, color: '#3d404e' }}>{content}</div>
          {props.loadingCustomDomainUpdate ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <Spin indicator={loadingSpin} />
            </div>
          ) : (
            <div
              style={{
                padding: 30,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
                color: '#707070',
              }}
            >
              <div>CUSTOM DOMAIN</div>
              <div style={{ width: '60%', paddingLeft: 40 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                  }}
                >
                  <Input
                    value={
                      props.currentURL && props.currentURL.customCampaignUrl
                        ? props.currentURL.customCampaignUrl
                        : ''
                    }
                    onChange={(e) =>
                      props.customDomainChangeCurrent({
                        ...props.currentURL,
                        customCampaignUrl: e.target.value,
                      })
                    }
                    placeholder="www.google.com"
                  />
                  <div style={{ paddingTop: 5, fontSize: 12 }}>
                    Please exclude the http:// or https:// part
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
      <ProductSettingFooterComponent
        loading={props.loadingCustomDomainSubmit}
        handleClickSave={() => handleClickSave()}
      />
    </div>
  );
};

export default ProductSettingCustomDomainComponent;
