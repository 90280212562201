import FunnelReportHeaderActions from '../component/FunnelReportHeaderActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { deleteFunnel, forceExecuteFunnel } from '../FunnelAction';

const mapStateToProps = (state) => ({
  executing: state.analytics.funnel.view.executing,
  executionParams: state.analytics.funnel.view.executionParams,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forceExecuteFunnel: forceExecuteFunnel,
      deleteFunnel: deleteFunnel,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FunnelReportHeaderActions);
