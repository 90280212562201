/* eslint-disable no-useless-escape */
import _ from 'lodash';
import lodashFind from 'lodash/find';
import { isEmpty } from 'lodash';

export const FormLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
export const FormTailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export const emailRegEx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const variationLetter = ['A', 'B', 'C', 'D', 'E', 'F'];

export function buildFormValues(errors, data) {
  data = flatten(data);
  const result = data
    ? Object.keys(data).map((name) => ({
        name: nameToArray(name),
        value: data[name],
      }))
    : [];
  if (errors) {
    errors.forEach((err) => {
      let key = nameToArray(err.key.split('[').join('.['));
      let old = result.find((x) => _.isEqual(x.name, key));
      if (old) {
        if (old.errors) {
          old.errors.push(err.message);
        } else {
          old.errors = [err.message];
        }
      } else {
        result.push({ name: key, errors: [err.message] });
      }
    });
  }
  return result;
}

const flatten = (obj, path = '') => {
  if (!(obj instanceof Object)) {
    return { [path.replace(/\.$/g, '')]: obj };
  }

  if (obj instanceof Array) {
    if (Object.keys(obj).every((key) => !(obj[key] instanceof Object))) {
      return { [path.replace(/\.$/g, '')]: obj };
    }
  }

  return Object.keys(obj).reduce((output, key) => {
    return obj instanceof Array
      ? { ...output, ...flatten(obj[key], path + '[' + key + '].') }
      : { ...output, ...flatten(obj[key], path + key + '.') };
  }, {});
};

const nameToArray = (name) => {
  const result = Array();
  let split = name.split('.');
  split.forEach((str) => {
    if (str.startsWith('[') && str.endsWith(']')) {
      result.push(parseInt(str.substr(1, str.length - 1)));
    } else {
      result.push(str);
    }
  });
  return result;
};

export function errorHandling(errors, errorType) {
  if (!isEmpty(errors) && Array.isArray(errors.errors)) {
    const foundItem = lodashFind(
      errors.errors,
      (item) => item.key === errorType
    );
    if (foundItem) {
      return foundItem.message;
    }
  }
  return false;
}
