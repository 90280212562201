//TODO: lazy loading
const inTrack = {
  id: 'in',
  hello: 'hello intrack',
  brandName: 'intrack',
  brandname: 'intrack',
  BrandName: 'InTrack',
  BRANDNAME: 'INTRACK',
  docs: 'https://docs.intrack.ir',
  campaignApi: 'https://docs.intrack.ir/transactional-campaign-api',
  documents: 'https://intrack.ir/documents/',
  restApi: 'https://docs.intrack.ir/rest-api',
  config: 'InTrackConfig()',
  SDK: 'https://docs.intrack.ir/android-sdk#adding-the-sdk-to-the-project',
  Initialization: 'https://docs.intrack.ir/android-sdk#initialization',
  //    webSdk
  webSdkStart: 'https://docs.intrack.ir/web-sdk#getting-started',
  webSdk: 'https://docs.intrack.ir/web-sdk',
  webSdkNoti: 'https://docs.intrack.ir/web-sdk#web-push-notifications',
  webSdkEvent: 'https://docs.intrack.ir/web-sdk#custom-events',
  webSdkLogin: 'https://docs.intrack.ir/web-sdk#login-and-logout',
  flutterSdkLogin: 'https://docs.intrack.ir/flutter-sdk#login-and-logout',
  sw: 'https://static1.intrack.ir/api/web/download/sdk/sw.js',
  manifest: 'https://static1.intrack.ir/api/web/download/sdk/manifest.json',
  webSdkProfile: 'https://docs.intrack.ir/web-sdk#profile-updates',
  // mobile-SDK
  mobileSdk: 'https://docs.intrack.ir/mobile-sdk',
  mobileSdkLogin: 'https://docs.intrack.ir/mobile-sdk#login-and-logout',
  profile: 'https://docs.intrack.ir/mobile-sdk#profile-updates',
  flutterProfile: 'https://docs.intrack.ir/flutter-sdk#profile-updates',
  addingDependency: 'https://docs.intrack.ir/mobile-sdk#getting-started',
  mobileSdkNoti:
    'https://docs.intrack.ir/mobile-sdk#mobile-push-messages-and-notifications',
  mobileSdkEvent: 'https://docs.intrack.ir/mobile-sdk#custom-events',
  flutterSdkEvent: 'https://docs.intrack.ir/flutter-sdk#custom-events',
  // android
  androidSdkLogin: 'https://docs.intrack.ir/android-sdk#login-and-logout',
  androidProfile: 'https://docs.intrack.ir/android-sdk#profile-updates',
  androidEvent: 'https://docs.intrack.ir/android-sdk#custom-events',
  androidSdk: 'https://docs.intrack.ir/android-sdk',
  // ios
  iosSdkLogin: 'https://docs.intrack.ir/ios-sdk#login-and-logout',
  iosProfile: 'https://docs.intrack.ir/ios-sdk#profile-updates',
  iosEvent: 'https://docs.intrack.ir/ios-sdk#custom-events',
  iosSdk: 'https://docs.intrack.ir/ios-sdk',
  iosSdkNoti:
    'https://docs.intrack.ir/ios-sdk#mobile-push-messages-and-notifications',
  iosAdding: 'https://docs.intrack.ir/ios-sdk#adding-the-sdk-to-the-project',
  iosInitialization: 'https://docs.intrack.ir/ios-sdk#sdk-integration',
  // webhook
  webhook: 'https://docs.intrack.ir/webhook',
  shortnerSampleUrl: 'https://www.intrack.ir/search/category-mobile-phone/',
  shortnerDomain: 'intk.ir',
  importSw: `if ('function' === typeof importScripts) {
	importScripts("https://static1.intrack.ir/api/web/download/sdk/inTrack-sw.js");
}\n`,
};

const Usermost = {
  id: 'um',
  hello: 'hello usermost',
  brandName: 'Usermost',
  brandname: 'usermost',
  BrandName: 'Usermost',
  BRANDNAME: 'USERMOST',
  docs: 'https://docs.usermost.com',
  campaignApi: 'https://docs.usermost.com/transactional-campaign-api',
  documents: 'https://usermost.com/documents/',
  restApi: 'https://docs.usermost.com/rest-api',
  SDK: 'https://docs.usermost.com/android-sdk#adding-the-sdk-to-the-project',
  sw: 'https://static1.usermost.com/api/web/download/sdk/sw.js',
  manifest: 'https://static1.usermost.com/api/web/download/sdk/manifest.json',
  config: 'UsermostConfig()',
  // mobile-sdk
  addingDependency: 'https://docs.usermost.com/mobile-sdk#getting-started',
  addingFlutterDependency:
    'http://docs.usermost.com/flutter-sdk#adding-dependency',
  mobileSdkNoti:
    'https://docs.usermost.com/mobile-sdk#mobile-push-messages-and-notifications',
  mobileSdkEvent: 'https://docs.usermost.com/mobile-sdk#custom-events',
  profile: 'https://docs.usermost.com/mobile-sdk#profile-updates',
  mobileSdk: 'https://docs.usermost.com/',
  mobileSdkLogin: 'https://docs.intrack.ir/mobile-sdk#login-and-logout',
  // webSdk
  webSdk: 'https://docs.usermost.com/web-sdk',
  webSdkStart: 'https://docs.usermost.com/web-sdk#getting-started',
  webSdkLogin: 'https://docs.usermost.com/web-sdk#login-and-logout',
  webSdkNoti: 'https://docs.usermost.com/web-sdk#web-push-notifications',
  webSdkEvent: 'https://docs.usermost.com/web-sdk#custom-events',
  webSdkProfile: 'https://docs.usermost.com/web-sdk#profile-updates',
  // android
  androidSdkLogin: 'https://docs.usermost.com/android-sdk#login-and-logout',
  androidProfile: 'https://docs.usermost.com/android-sdk#profile-updates',
  androidEvent: 'https://docs.usermost.com/android-sdk#custom-events',
  androidSdk: 'https://docs.usermost.com/android-sdk',
  Initialization: 'https://docs.usermost.ir/android-sdk#initialization',
  // webhook
  webhook: 'https://docs.usermost.com/webhook',
  //shortner
  shortnerSampleUrl: 'https://www.usermost.com/search/category-mobile-phone/',
  shortnerDomain: 'usrmo.com',
  importSw: `importScripts("https://static1.usermost.com/api/web/download/sdk/usermost-sw.js");`,
};

// console.log(process.env.THEME);
export default process.env.THEME === 'Usermost' ? Usermost : inTrack;
