import ProductSegmentUpdateComponent from '../component/ProductSegmentUpdateComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  productSegmentChangeCurrent,
  productSegmentCopyFetch,
  productSegmentUpdateFetch,
  productSegmentUpdateSubmitFetch,
  productSegmentCreateFetch,
  productSegmentListAttributesFetch,
  productSegmentListEventsFetch,
  productSegmentReportFetch,
  productSegmentResetCompleted,
  productSegmentListDeviceAttributesFetch,
  clearSegmentError,
  productSegmentRecommendUserAttributesFetch,
  productSegmentRecommendDeviceAttributesFetch,
  productSegmentRecommendEventAttributesFetch,
  productSegmentListProductSourseFetch,
} from '../ProductSegmentActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentProductSegment: state.insight.productSegment.currentProductSegment,
  currentReport: state.insight.productSegment.currentReport,
  errors: state.insight.productSegment.errors,
  attributes: state.insight.productSegment.attributes,
  events: state.insight.productSegment.events,
  deviceAttributes: state.insight.productSegment.deviceAttributes,
  errorsUpdate: state.insight.productSegment.errorsUpdate,
  loadingUpdate: state.insight.productSegment.loadingUpdate,
  errorsCopy: state.insight.productSegment.errorsCopy,
  loadingCopy: state.insight.productSegment.loadingCopy,
  loadingReport: state.insight.productSegment.loadingReport,
  saveClicked: state.insight.productSegment.saveClicked,
  resetClicked: state.insight.productSegment.resetClicked,
  pathname: state.router.location.pathname,
  errorsSegmentSubmit: state.insight.productSegment.errorsSegmentSubmit,
  loadingRecommendUserAttributes:
    state.insight.productSegment.loadingRecommendUserAttributes,
  recommendUserAttributes: state.insight.productSegment.recommendUserAttributes,
  loadingRecommendEventAttributes:
    state.insight.productSegment.loadingRecommendEventAttributes,
  recommendEventAttributes:
    state.insight.productSegment.recommendEventAttributes,
  loadingRecommendDeviceAttributes:
    state.insight.productSegment.loadingRecommendDeviceAttributes,
  recommendDeviceAttributes:
    state.insight.productSegment.recommendDeviceAttributes,
  currentUser: state.account.auth.currentUser,
  sourceProducts: state.insight.productSegment.sourceProducts,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      productSegmentListAttributesFetch,
      productSegmentListEventsFetch,
      productSegmentListDeviceAttributesFetch,
      productSegmentChangeCurrent,
      productSegmentCopyFetch,
      productSegmentUpdateFetch,
      productSegmentUpdateSubmitFetch,
      productSegmentCreateFetch,
      productSegmentReportFetch,
      productSegmentResetCompleted,
      clearSegmentError,
      productSegmentRecommendUserAttributesFetch,
      productSegmentRecommendDeviceAttributesFetch,
      productSegmentRecommendEventAttributesFetch,
      productSegmentListProductSourseFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSegmentUpdateComponent);

ProductSegmentUpdateComponent.propTypes = {
  currentProductSegment: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  attributes: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  productSegmentListAttributesFetch: PropTypes.func.isRequired,
  productSegmentListEventsFetch: PropTypes.func.isRequired,
  productSegmentChangeCurrent: PropTypes.func.isRequired,
  productSegmentCopyFetch: PropTypes.func.isRequired,
  productSegmentUpdateFetch: PropTypes.func.isRequired,
  productSegmentListProductSourseFetch: PropTypes.func.isRequired,
  productSegmentUpdateSubmitFetch: PropTypes.func.isRequired,
  productSegmentCreateFetch: PropTypes.func.isRequired,
  productSegmentReportFetch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
