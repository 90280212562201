import {
  API_TOKEN_CREATE_FETCH,
  API_TOKEN_CREATE_FETCH_FAIL,
  API_TOKEN_CREATE_FETCH_SUCCESS,
  API_TOKEN_REVOKE_FETCH,
  API_TOKEN_REVOKE_FETCH_FAIL,
  API_TOKEN_REVOKE_FETCH_SUCCESS,
  API_TOKEN_LIST_FETCH,
  API_TOKEN_LIST_FETCH_FAIL,
  API_TOKEN_LIST_FETCH_SUCCESS,
  API_TOKEN_PAGE_CHANGE_CURRENT,
  API_TOKEN_REVOKE_ALL_FETCH_SUCCESS,
  API_TOKEN_REVOKE_ALL_FETCH,
  API_TOKEN_REVOKE_ALL_FETCH_FAIL,
  API_TOKEN_DETAILS_MODAL_VIEW,
  API_TOKEN_REVOKE_ALL_MODAL_VIEW,
} from './ApiTokenConstants';
import ApiTokenApi from './ApiTokenApi';
import toastr from 'toastr';

export function apiTokenCreateFetch(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: API_TOKEN_CREATE_FETCH,
    });
    ApiTokenApi.create()
      .then((response) => {
        dispatch({
          type: API_TOKEN_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Token generated successfully');
        dispatch(apiTokenListFetch(pageRequest));
      })
      .catch((response) => {
        dispatch({
          type: API_TOKEN_CREATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function apiTokenRevokeFetch(id, pageRequest) {
  return (dispatch) => {
    dispatch({
      type: API_TOKEN_REVOKE_FETCH,
    });
    ApiTokenApi.revokeToken(id)
      .then((response) => {
        dispatch({
          type: API_TOKEN_REVOKE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Token revoked successfully');
        dispatch(apiTokenListFetch(pageRequest));
      })
      .catch((response) => {
        dispatch({
          type: API_TOKEN_REVOKE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function apiTokenRevokeAllFetch(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: API_TOKEN_REVOKE_ALL_FETCH,
    });
    ApiTokenApi.revokeAll()
      .then((response) => {
        dispatch({
          type: API_TOKEN_REVOKE_ALL_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('All tokens revoked successfully');
        dispatch(apiTokenListFetch(pageRequest));
      })
      .catch((response) => {
        dispatch({
          type: API_TOKEN_REVOKE_ALL_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function apiTokenListFetch(body) {
  return (dispatch) => {
    dispatch({
      type: API_TOKEN_LIST_FETCH,
      payload: body,
    });
    ApiTokenApi.list(body)
      .then((response) => {
        dispatch({
          type: API_TOKEN_LIST_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: API_TOKEN_LIST_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function apiTokenPageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: API_TOKEN_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function apiTokenModalView(open) {
  return (dispatch) => {
    dispatch({
      type: API_TOKEN_DETAILS_MODAL_VIEW,
      payload: open,
    });
  };
}

export function apiTokenRevokeAllModalToggle(open) {
  return (dispatch) => {
    dispatch({
      type: API_TOKEN_REVOKE_ALL_MODAL_VIEW,
      payload: open,
    });
  };
}
