import { timesOptions } from '@Utils/DateUtils';
import _, { isEmpty } from 'lodash';
import { useEffect, useRef } from 'react';
import {
  EventNodes,
  JourneyObjectModelSteps,
  NodesInbox,
  StepsAvailableForChannel,
} from './static';

// Hook
export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function findMaxId(elements) {
  const max =
    elements.length &&
    elements.reduce((prev, current) => {
      return Number(prev.id) > Number(current.id) ? prev : current;
    });
  return Number(max?.id || 0);
}

export function filterJourneyVariableSteps(steps, currentStepId) {
  try {
    return steps.filter((step) => {
      if (step.type === StepsAvailableForChannel[0] && !step?.event)
        return false;
      if (
        step.type === StepsAvailableForChannel[1] &&
        step?.ui?.response?.errorMessage
      )
        return false;
      if (
        step.type === StepsAvailableForChannel[1] &&
        isEmpty(step?.ui?.response)
      )
        return false;
      if (step.type === StepsAvailableForChannel[2] && !step?.event)
        return false;
      if (step.type === StepsAvailableForChannel[3] && !step?.businessEvent)
        return false;
      if (
        StepsAvailableForChannel.includes(step.type) &&
        Number(step.id) !== Number(currentStepId)
      )
        return true;
    });
  } catch (e) {
    // TODO GrayLog
    console.log('error->>', e);
    return [];
  }
}

export const GetNodePortsByType = (type) => {
  try {
    if (type === 'PLACE_HOLDER') return [];

    const node = NodesInbox().find((m) => m.data?.key === type);

    if (node) {
      const events = _.get(node, 'data.events', []);
      // const events = node.data.events;
      if (events.length) {
        return events;
      } else {
        return [
          {
            name: 'on',
          },
        ];
      }
    }
    return [];
  } catch (e) {
    // TODO GrayLog
    console.log('error->>', e);
    return [];
  }
};

export const isUsedOnWaitForDate = (journey, nodeId) => {
  const allWaitForDate = journey.steps.filter(
    (step) => step.type === JourneyObjectModelSteps.WAIT_FOR_DATE.type
  );
  if (allWaitForDate.length) {
    // CHECK STRAIGHT
    const stepsThatUsed = allWaitForDate.filter((wd) => {
      return wd.stepId + '' === nodeId + '';
    });
    // CHECK NOT STRAIGHT

    return stepsThatUsed;
  }
  return [];
};

export const isJourneyVariableNode = (journey, nodeId) => {
  const allJourneyVariableNodes = journey.steps.filter((step) =>
    StepsAvailableForChannel.includes(step.type)
  );
  if (allJourneyVariableNodes.length) {
    const isFound = allJourneyVariableNodes.find((node) => {
      return node.id + '' === nodeId + '';
    });
    return !!isFound;
  }
  return false;
};

export const dfsSearchToFindPath = (
  startNode_,
  targetWfNode_,
  journey,
  detect = false
) => {
  try {
    const isEventNode = EventNodes.find((en) => en === startNode_.type);
    // 100% Must be OCC_Event Or WF_Event node
    let parentNode;
    if (isEventNode) {
      parentNode = startNode_;
    } else {
      parentNode = journey.steps.find(
        (step) => step.id + '' === targetWfNode_.stepId + ''
      );
    }

    if (!parentNode) return;
    if (!EventNodes.find((en) => en === parentNode.type)) {
      console.log('error-----', parentNode);
      // TODO: Add to GrayLog (it's not possible)
      return;
    }

    const dfsSearch = (eventNode, checkConnection = false) => {
      let targetNodes = [];
      const eventNodePorts = GetNodePortsByType(eventNode.type);

      eventNodePorts.forEach((port) => {
        const targets = eventNode[port.name] || [];
        if (targets.length) {
          targetNodes.push(...targets);
        }
      });

      if (targetNodes.length) {
        const children = Array.from(new Set(targetNodes));
        let isExist;
        if (detect) {
          isExist = children.find(
            (child) => child + '' === targetWfNode_.id + ''
          );
        } else if (!checkConnection) {
          isExist = children.find((child) => child + '' === startNode_.id + '');
        }
        if (checkConnection) {
          const hasConnection =
            checkConnection &&
            children.find((child) => child + '' === targetWfNode_.id + '');
          if (hasConnection) return true;
        }

        if (isExist) {
          // check connection with WF_date node
          if (detect) {
            return true;
          } else {
            const isInPath = dfsSearch(startNode_, true);
            if (isInPath || isInPath === undefined) {
              return true;
            }
          }
          return false;
        } else {
          // recursive find
          return children.find((child) => {
            const nextChild = journey.steps.find(
              (stp) => stp.id + '' === child + ''
            );
            return dfsSearch(nextChild);
          });
        }
      } else {
        return false;
      }
    };
    return dfsSearch(parentNode);
  } catch (e) {
    // TODO GrayLog
    console.log('error->>', e);
    return null;
  }
};

export const dfsSearchPrime = (
  startNode_,
  targetWfNode_,
  journey,
  detect = false
) => {
  try {
    const isEventNode = EventNodes.find((en) => en === startNode_.type);

    const parentNode = startNode_;

    const dfsSearch = (eventNode, targetNode) => {
      const targetNode_ = targetNode || targetWfNode_; // static
      let targetNodes = [];
      const eventNodePorts = GetNodePortsByType(eventNode.type);

      eventNodePorts.forEach((port) => {
        const targets = eventNode[port.name] || [];
        if (targets.length) {
          targetNodes.push(...targets);
        }
      });

      if (targetNodes.length) {
        const children = Array.from(new Set(targetNodes));
        let isExist;
        isExist = children.find((child) => child + '' === targetNode_.id + '');
        if (isExist) {
          return true;
        } else {
          // recursive find
          return children.find((child) => {
            const nextChild = journey.steps.find(
              (stp) => stp.id + '' === child + ''
            );
            return dfsSearch(nextChild, targetNode_);
          });
        }
      } else {
        return false;
      }
    };
    const hasConnection = dfsSearch(parentNode);

    // stepId this node has connection by startNode ??
    if (hasConnection) {
      if (!isEventNode) {
        const topNode = journey.steps.find(
          (item) => item.id === targetWfNode_.stepId
        );
        return dfsSearch(topNode, startNode_);
      } else {
        if (detect) {
          return true;
        }
        return startNode_.id + '' === targetWfNode_.stepId + '';
      }
    } else {
      return false;
    }
  } catch (e) {
    // TODO GrayLog
    console.log('error->>', e);
    return null;
  }
};

export const tryParseJSONObject = (jsonString) => {
  try {
    var o = JSON.parse(jsonString);
    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {
    // TODO GrayLog
    console.log('error->>', e);
    return false;
  }

  return false;
};

export const calcMinuteTime = (time, type) => {
  try {
    if (time && type) {
      const timeType = timesOptions().find((t) => t.id === type);
      if (timeType) {
        const z = timeType?.min;
        return Number(time * z);
      }
    }
    return 0;
  } catch (error) {
    // TODO GrayLog
    console.log('error->>>', error);
    return 0;
  }
};
