import React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import './VerificationModal.less';

const EmailVerificationModalSuccess = () => {
  return (
    <div id="verification">
      <div className="modalTitle">
        <CheckCircleFilled className="checkIcon" style={{ color: '#05B48A' }} />
        Verification Successful
      </div>
      <div className="modalDescription">
        Your email has been successfully verified. You&apos;re now ready to
        enjoy all the features and benefits.
      </div>
    </div>
  );
};

export default EmailVerificationModalSuccess;
