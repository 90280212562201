import React from 'react';
import { useState } from 'react';
import CommunicationAudienceContainer from '../../../communication/container/CommunicationAudienceContainer';
import CommunicationMessageContainer from '../../../communication/container/CommunicationMessageContainer';
import CommunicationConversionContainer from '../../../communication/container/CommunicationConversionContainer';
import CommunicationTestContainer from '../../../communication/container/CommunicationTestContainer';
import { useSelector } from 'react-redux';
import { journeyTypes } from '@Modules/engage/journey/model/JourneyTypes';
import { useIsParentProduct } from '@Utils/isParentProduct';
// import { showMessage } from '../../../../utils/RenderUtils';

// * SEND_SMS

const SendSmsStep = (props) => {
  const currentUser = useSelector((state) => state.account.auth.currentUser);

  const isParent = useIsParentProduct(currentUser);

  const title = [
    journeyTypes.RELAY,
    journeyTypes.JOURNEY,
    journeyTypes.JOURNEY_TEMPLATE,
  ].includes(props.type) // JOURNEY_TEMPLATE types are JOURNEY here
    ? 'Basic Info'
    : 'Audience';
  const stepsDefault = [
    {
      step: 'audience',
      nextButtonTitle: 'SAVE & CONTINUE',
      title: title,
    },
    { step: 'message', nextButtonTitle: 'SAVE & CONTINUE', title: 'Message' },
    {
      step: 'conversion',
      nextButtonTitle: !isParent ? 'SAVE & CONTINUE' : 'SAVE CAMPAIGN',
      title: 'Tracking',
    },
    !isParent && {
      step: 'test',
      nextButtonTitle: 'SAVE CAMPAIGN',
      title: 'Test Campaign',
    },
  ].filter(Boolean);
  const [step, setStep] = useState('audience');
  if (step === 'audience') {
    return (
      <CommunicationAudienceContainer
        communicationId={props.dataNode.communication}
        journey={props.type}
        journeySteps={stepsDefault}
        updateJourney={props.updateJourney}
        dataNode={props.dataNode}
        readOnly={props.readOnly}
        goToStepOnJourney={setStep}
      />
    );
    // } else if (step === 'when') {
    //     return <CommunicationWhenContainer journey={true} goToStepOnJourney={setStep}/>;
  } else if (step === 'message') {
    return (
      <CommunicationMessageContainer
        journey={props.type}
        communicationId={props.dataNode.communication}
        journeySteps={stepsDefault}
        readOnly={props.readOnly}
        journeyStepsForVariable={props.journeyStepsForVariable}
        goToStepOnJourney={setStep}
      />
    );
  } else if (step === 'conversion') {
    return (
      <CommunicationConversionContainer
        journey={props.type}
        communicationId={props.dataNode.communication}
        journeySteps={stepsDefault}
        closeModal={props.closeModal}
        dataNode={props.dataNode}
        updateJourney={props.updateJourney}
        readOnly={props.readOnly}
        goToStepOnJourney={setStep}
      />
    );
  } else if (step === 'test') {
    return !isParent ? (
      <CommunicationTestContainer
        journey={props.type}
        communicationId={props.dataNode.communication}
        journeySteps={stepsDefault}
        closeModal={props.closeModal}
        dataNode={props.dataNode}
        updateJourney={props.updateJourney}
        readOnly={props.readOnly}
        goToStepOnJourney={setStep}
      />
    ) : null;
  }
  // else if (step === 'preview') {
  //     return <CommunicationPreviewContainer
  //         journey={true}
  //         journeySteps={stepsDefault}
  //         dataNode={props.dataNode}
  //         closeModal={props.closeModal}
  //         updateJourney={props.updateJourney}
  //         goToStepOnJourney={setStep}/>;
  // }
  return null;
};

export default SendSmsStep;
