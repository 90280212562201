import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import UserChangePasswordContainer from './container/UserChangePasswordContainer';
import UserListContainer from './container/UserListContainer';
import UserUpdateContainer from './container/UserUpdateContainer';
import UserViewContainer from './container/UserViewContainer';

function User() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}list`}>
        <UserListContainer />
      </Route>
      <Route path={`${match.path}create`}>
        <UserUpdateContainer />
      </Route>
      <Route path={`${match.path}update/:id`}>
        <UserUpdateContainer />
      </Route>
      <Route path={`${match.path}view/:id`}>
        <UserViewContainer />
      </Route>
      <Route path={`${match.path}changePassword/:id`}>
        <UserChangePasswordContainer />
      </Route>
    </Switch>
  );
}

export default User;
