import CommunicationDetailsComponent from '../component/CommunicationDetailsComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  communicationViewFetch,
  communicationListSegmentsFetch,
  communicationListSmsChannelsFetch,
  communicationListPushChannelsFetch,
  communicationListEmailChannelsFetch,
  whatsappChannelTemplateListFetch,
  communicationListEventsFetch,
  communicationListTagsFetch,
} from '../CommunicationActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentCommunication: state.engage.communication.currentCommunication,
  loadingSegment: state.engage.communication.loadingSegment,
  segments: state.engage.communication.segments,
  loadingEvents: state.engage.communication.loadingEvents,
  loadingSmsChannels: state.engage.communication.loadingSmsChannels,
  loadingPushChannels: state.engage.communication.loadingPushChannels,
  loadingEmailChannels: state.engage.communication.loadingEmailChannels,
  loadingListTags: state.engage.communication.loadingListTags,
  listTags: state.engage.communication.listTags,
  emailChannels: state.engage.communication.emailChannels,
  pushChannels: state.engage.communication.pushChannels,
  smsChannels: state.engage.communication.smsChannels,
  eventList: state.engage.communication.eventList,
  pathname: state.router.location.pathname,
  loadingView: state.engage.communication.loadingView,
  loadingCommunicationEngagedUser:
    state.engage.communication.loadingCommunicationEngagedUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      communicationViewFetch,
      communicationListSegmentsFetch,
      communicationListSmsChannelsFetch,
      communicationListPushChannelsFetch,
      communicationListEmailChannelsFetch,
      whatsappChannelTemplateListFetch,
      communicationListEventsFetch,
      communicationListTagsFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationDetailsComponent);

CommunicationDetailsComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  communicationViewFetch: PropTypes.func.isRequired,
};
