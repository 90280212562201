import BaseApi from '../../../api/BaseApi';

const basePath = '/insight/productUser';
class ProductUserApi {
  static activityReport(body) {
    return BaseApi.post(basePath + '/activityReport', body);
  }
  static analyze(body) {
    return BaseApi.post(basePath + '/analyze', body);
  }
  static communications(id, body) {
    return BaseApi.post(basePath + '/communications/' + id, body);
  }
  static devices(id) {
    return BaseApi.post(basePath + '/devices/' + id, {});
  }
  static events(id, body) {
    return BaseApi.post(basePath + '/events/' + id, body);
  }
  static list(body) {
    return BaseApi.post(basePath + '/list', body);
  }
  static listAttributes() {
    return BaseApi.post(basePath + '/listAttributes', {});
  }
  static listEvents() {
    return BaseApi.post('/insight/productSegment/listEvents', {});
    // return BaseApi.post(basePath + '/listEvents', {});
  }
  static reachabilityReport() {
    return BaseApi.post(basePath + '/reachabilityReport', {});
  }
  static view(id) {
    return BaseApi.post(basePath + '/view/' + id, {});
  }
  static journeys(id, body) {
    return BaseApi.post(basePath + '/journeys/' + id, body);
  }
}

export default ProductUserApi;
