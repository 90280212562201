import React from 'react';
import { isEmpty, isNil } from 'lodash';
export function passwordValidation(value, type = 'other', skipStyle = false) {
  let eightCharacters = true;
  let upperLowerCase = true;
  let specialChar = true;
  let number = true;
  let operator = false;
  if (!isNil(value) && !isEmpty(value)) {
    if (value.length >= 8) {
      eightCharacters = false;
    }
    if (/\d/.test(value)) {
      number = false;
    }
    if (/[A-Z]/.test(value) && /[a-z]/.test(value)) {
      upperLowerCase = false;
    }
    if (
      value.includes('!') ||
      value.includes('@') ||
      value.includes('#') ||
      value.includes('$') ||
      value.includes('%') ||
      value.includes('^') ||
      value.includes('&') ||
      value.includes('+') ||
      value.includes('=') ||
      value.includes('_') ||
      value.includes('-') ||
      value.includes('?')
    ) {
      specialChar = false;
    }
    if (value.includes('<') || value.includes('>')) {
      operator = true;
    }
    if (
      eightCharacters ||
      upperLowerCase ||
      specialChar ||
      number ||
      operator
    ) {
      return (
        <div
          style={
            skipStyle
              ? null
              : {
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: type === 'other' ? 0 : 90,
                }
          }
        >
          {' '}
          {eightCharacters && <div>- At least 8 characters</div>}
          {upperLowerCase && (
            <div>- A mixture of both uppercase and lowercase letters.</div>
          )}
          {number && <div>- A mixture of letters and numbers.</div>}
          {specialChar && (
            <div>
              - Inclusion of at least one special character !@#$%^&+=-_?
            </div>
          )}
          {operator && <div>{'-Do not use > or <  in your password'}</div>}
        </div>
      );
    }
    return null;
  }
  return null;
}
