import {
  EMAIL_TEMPLATE_DELETE_FETCH,
  EMAIL_TEMPLATE_DELETE_FETCH_FAIL,
  EMAIL_TEMPLATE_DELETE_FETCH_SUCCESS,
  EMAIL_TEMPLATE_CREATE_FETCH,
  EMAIL_TEMPLATE_CREATE_FETCH_FAIL,
  EMAIL_TEMPLATE_CREATE_FETCH_SUCCESS,
  EMAIL_TEMPLATE_VIEW_FETCH,
  EMAIL_TEMPLATE_VIEW_FETCH_FAIL,
  EMAIL_TEMPLATE_VIEW_FETCH_SUCCESS,
  EMAIL_TEMPLATE_LIST_FETCH,
  EMAIL_TEMPLATE_LIST_FETCH_FAIL,
  EMAIL_TEMPLATE_LIST_FETCH_SUCCESS,
  EMAIL_TEMPLATE_LIST_ALL_FETCH,
  EMAIL_TEMPLATE_LIST_ALL_FETCH_FAIL,
  EMAIL_TEMPLATE_LIST_ALL_FETCH_SUCCESS,
  EMAIL_TEMPLATE_UPDATE_FETCH,
  EMAIL_TEMPLATE_UPDATE_FETCH_FAIL,
  EMAIL_TEMPLATE_UPDATE_FETCH_SUCCESS,
  EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH,
  EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH_FAIL,
  EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH_SUCCESS,
  EMAIL_TEMPLATE_CHANGE_CURRENT,
  EMAIL_TEMPLATE_PAGE_CHANGE_CURRENT,
  EMAIL_TEMPLATE_TOGGLE_MODAL,
  EMAIL_TEMPLATE_SEARCH_LIST_FETCH,
  EMAIL_TEMPLATE_SEARCH_LIST_FETCH_FAIL,
  EMAIL_TEMPLATE_SEARCH_LIST_FETCH_SUCCESS,
  EMAIL_TEMPLATE_DUPLICATE_FETCH,
  EMAIL_TEMPLATE_DUPLICATE_FETCH_FAIL,
  EMAIL_TEMPLATE_DUPLICATE_FETCH_SUCCESS,
} from './EmailTemplateConstants';
import EmailTemplateApi from './EmailTemplateApi';
import toastr from 'toastr';
import { showMessageError } from '../../../utils/RenderUtils';
import { isFunction } from 'lodash';

const loadEmailTemplateList = (dispatch, pageRequest) => {
  dispatch({
    type: EMAIL_TEMPLATE_LIST_FETCH,
    payload: pageRequest,
  });
  EmailTemplateApi.list(pageRequest)
    .then((response) => {
      dispatch({
        type: EMAIL_TEMPLATE_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: EMAIL_TEMPLATE_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function emailTemplateListFetch(pageRequest) {
  return (dispatch) => loadEmailTemplateList(dispatch, pageRequest);
}
const loadSearchEmailTemplateList = (dispatch, pageRequest) => {
  dispatch({
    type: EMAIL_TEMPLATE_SEARCH_LIST_FETCH,
    payload: pageRequest,
  });
  EmailTemplateApi.list(pageRequest)
    .then((response) => {
      dispatch({
        type: EMAIL_TEMPLATE_SEARCH_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: EMAIL_TEMPLATE_SEARCH_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function emailSearchTemplateListFetch(pageRequest) {
  return (dispatch) => loadSearchEmailTemplateList(dispatch, pageRequest);
}
export function emailTemplateChangeCurrent(emailTemplate) {
  return (dispatch) => {
    dispatch({
      type: EMAIL_TEMPLATE_CHANGE_CURRENT,
      payload: emailTemplate,
    });
  };
}

export function emailTemplateToggleModal(
  isOpen,
  templateId = undefined,
  editorMode = false
) {
  return (dispatch) => {
    dispatch({
      type: EMAIL_TEMPLATE_TOGGLE_MODAL,
      payload: { isOpen, templateId, editorMode },
    });
  };
}

export function emailTemplatePageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: EMAIL_TEMPLATE_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function emailTemplateCreateFetch(emailTemplate, callBack) {
  return (dispatch) => {
    dispatch({
      type: EMAIL_TEMPLATE_CREATE_FETCH,
      payload: emailTemplate,
    });
    EmailTemplateApi.create(emailTemplate)
      .then((response) => {
        dispatch({
          type: EMAIL_TEMPLATE_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been created successfully');
        if (isFunction(callBack)) {
          callBack(response);
        }
      })
      .catch((response) => {
        dispatch({
          type: EMAIL_TEMPLATE_CREATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function emailTemplateListAllFetch() {
  return (dispatch) => {
    dispatch({
      type: EMAIL_TEMPLATE_LIST_ALL_FETCH,
    });
    EmailTemplateApi.listAll()
      .then((response) => {
        dispatch({
          type: EMAIL_TEMPLATE_LIST_ALL_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: EMAIL_TEMPLATE_LIST_ALL_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function emailTemplateViewFetch(id) {
  return (dispatch) => {
    dispatch({
      type: EMAIL_TEMPLATE_VIEW_FETCH,
    });
    EmailTemplateApi.update(id)
      .then((response) => {
        dispatch({
          type: EMAIL_TEMPLATE_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: EMAIL_TEMPLATE_VIEW_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function emailTemplateUpdateFetch(id) {
  return (dispatch) => {
    dispatch({
      type: EMAIL_TEMPLATE_UPDATE_FETCH,
    });
    EmailTemplateApi.update(id)
      .then((response) => {
        dispatch({
          type: EMAIL_TEMPLATE_UPDATE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: EMAIL_TEMPLATE_UPDATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function emailTemplateUpdateSubmitFetch(id, emailTemplate, callBack) {
  return (dispatch) => {
    dispatch({
      type: EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH,
      payload: emailTemplate,
    });
    EmailTemplateApi.updateSubmit(id, emailTemplate)
      .then((response) => {
        dispatch({
          type: EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been updated successfully');
        if (isFunction(callBack)) {
          callBack(response);
        }
      })
      .catch((response) => {
        dispatch({
          type: EMAIL_TEMPLATE_UPDATE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function emailTemplateDeleteFetch(id, pageRequest) {
  return (dispatch) => {
    dispatch({ type: EMAIL_TEMPLATE_DELETE_FETCH });
    EmailTemplateApi.delete(id)
      .then((response) => {
        dispatch({
          type: EMAIL_TEMPLATE_DELETE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been deleted successfully');
        loadEmailTemplateList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: EMAIL_TEMPLATE_DELETE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function emailTemplateDuplicateFetch(id, pageRequest) {
  return (dispatch) => {
    dispatch({ type: EMAIL_TEMPLATE_DUPLICATE_FETCH });
    EmailTemplateApi.duplicate(id)
      .then((response) => {
        dispatch({
          type: EMAIL_TEMPLATE_DUPLICATE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been duplicated successfully');
        loadEmailTemplateList(dispatch, pageRequest);
      })
      .catch((response) => {
        dispatch({
          type: EMAIL_TEMPLATE_DUPLICATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
