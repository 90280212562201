/* eslint-disable no-prototype-builtins */
import React from 'react';
import {
  Button,
  Select,
  Table,
  Pagination,
  Modal,
  Empty,
  Tooltip,
  Spin,
  Input,
} from 'antd';
import { isEmpty, isNil, find as lodashFind } from 'lodash';
import { readableDateTime, viewDateTime } from '../../../../utils/DateUtils';
import UserDeviceDetailsComponent from './UserDeviceDetailsComponent';
import { numberWithCommas } from '../../../../utils/RenderUtils';
import { LoadingOutlined } from '@ant-design/icons';
import LatestMessageStatus from '../../../engage/communication/model/LatestMessageStatus';
import { HashBase64 } from '../../../../component/utils/HashBase64';
import vars from '@Theme/styles/vars.js';
import Card from '@Utils/Card';
class UserInteractionsComponent extends React.Component {
  state = {
    openedIndex: -1,
    communicationOpenedIndex: -1,
    journeyOpenIndex: -1,
    relayOpenIndex: -1,
    interactionSelector: this.props.location.search.includes('communications')
      ? 'COMMUNICATIONS'
      : this.props.location.search.includes('journeys')
      ? 'JOURNEYS'
      : this.props.location.search.includes('relays')
      ? 'RELAYS'
      : 'EVENTS',
    query: null,
  };
  getUrlParamValue(x) {
    let deCodeParam = '';
    if (this.props.location.search.includes('&type=events')) {
      let url = this.props.location.search.replace('p=', '');
      let eventURL = url.replace('&type=events', '');
      deCodeParam = HashBase64().decode(eventURL);
    } else if (this.props.location.search.includes('&type=communications')) {
      let url = this.props.location.search.replace('p=', '');
      let communicationURL = url.replace('&type=communications', '');
      deCodeParam = HashBase64().decode(communicationURL);
    } else if (this.props.location.search.includes('&type=journeys')) {
      let journeysURL = this.props.location.search
        .replace('p=', '')
        .replace('&type=journeys', '');
      deCodeParam = HashBase64().decode(journeysURL);
    } else if (this.props.location.search.includes('&type=relays')) {
      let relaysURL = this.props.location.search
        .replace('p=', '')
        .replace('&type=relays', '');
      deCodeParam = HashBase64().decode(relaysURL);
    } else {
      deCodeParam = HashBase64().decode(this.props.location.search);
    }
    const params = new URLSearchParams(deCodeParam);
    return +params.get(x);
  }
  componentDidMount() {
    const first = this.props.pathname.split('view/')[1];
    const encodedUserId = first.split('/interactions')[0];
    const userId = HashBase64().decode(encodedUserId);

    const pageValue = this.getUrlParamValue('page') - 1;
    this.props.communicationListSegmentsFetch();
    this.props.insightProductUserEventFetch(userId, {
      page: `${
        this.props.location.search.includes('&type=events') ? pageValue : 0
      }`,
      segment: null,
      query: null,
    });
    this.props.insightProductUserCommunicationsFetch(userId, {
      page: `${
        this.props.location.search.includes('&type=communications')
          ? pageValue
          : 0
      }`,
    });
    this.props.insightProductUserViewFetch(userId);
    this.props.insightProductUserListEventsFetch();
    this.props.insightProductUserJourneyFetch(userId, {
      journeyType: 'JOURNEY',
      page: `${
        this.props.location.search.includes('&type=journeys') ? pageValue : 0
      }`,
    });
    this.props.insightProductUserRelayFetch(userId, {
      journeyType: 'RELAY',
      page: `${
        this.props.location.search.includes('&type=relays') ? pageValue : 0
      }`,
    });
  }
  handleOpenModal = (openedIndex) => {
    this.setState({ openedIndex });
  };

  handleOpenCommunicationModal = (communicationOpenedIndex) => {
    this.setState({ communicationOpenedIndex });
  };
  handleOpenJourneyModal = (journeyOpenIndex) => {
    this.setState({ journeyOpenIndex });
  };
  handleOpenRelayModal = (relayOpenIndex) => {
    this.setState({ relayOpenIndex });
  };
  handleChangeStatus = (interactionSelector) => {
    this.setState({ interactionSelector }, () => {
      if (interactionSelector === 'EVENTS') {
        this.props.history.push({
          pathname: this.props.history.location.pathname,
          search:
            'p=' +
            HashBase64().encode(`?page= ${+this.props.event.number + 1}`) +
            '&type=events',
        });
      } else if (interactionSelector === 'COMMUNICATIONS') {
        this.props.history.push({
          pathname: this.props.history.location.pathname,
          search:
            'p=' +
            HashBase64().encode(
              `?page= ${+this.props.communications.number + 1}`
            ) +
            '&type=communications',
        });
      } else if (interactionSelector === 'JOURNEYS') {
        this.props.history.push({
          pathname: this.props.history.location.pathname,
          search:
            'p=' +
            HashBase64().encode(`?page= ${+this.props.journeys.number + 1}`) +
            '&type=journeys',
        });
      } else if (interactionSelector === 'RELAYS') {
        if (this.props.relays) {
          this.props.history.push({
            pathname: this.props.history.location.pathname,
            search:
              'p=' +
              HashBase64().encode(`?page= ${+this.props.relays.number + 1}`) +
              '&type=relays',
          });
        }
      }
    });
  };

  renderCustomAttributeValue = (item) => {
    const value =
      this.props.event.content[this.state.openedIndex].attributes[item];
    try {
      const eventName = this.props.event.content[this.state.openedIndex].name;
      const eventDetails = lodashFind(
        this.props.eventsList,
        (o) => o.name === eventName
      );
      const attrDetails = eventDetails.attributes.find((o) => o.name === item);

      switch (attrDetails?.type) {
        case 'DATE':
          return viewDateTime(value);
        case 'NUMERIC':
          return numberWithCommas(value);
        case 'BOOLEAN':
        case 'BOOL':
          return value ? 'True' : 'False';
        case 'ARRAY':
        case 'OBJECT':
          return JSON.stringify(value);
        default:
          return value.toString();
      }
    } catch (error) {
      return value;
    }
  };

  renderCustomAttributeItem = (item) => {
    let result = [];
    if (item == -1) {
      result.push(<div style={{ display: 'flex', flex: 1 }}></div>);
    } else if (item) {
      result.push(
        <div style={{ display: 'flex', flex: 1 }}>
          <span
            style={{
              color: '#707070',
              fontSize: 12,
              marginRight: 20,
            }}
          >
            {item}:
          </span>
          <span
            style={{
              color: '#1c1c1c',
              fontSize: 12,
              wordBreak: 'break-word',
            }}
          >
            {this.renderCustomAttributeValue(item)}
          </span>
        </div>
      );
    }
    return result;
  };

  renderCustomAttributes = () => {
    let result = [];
    if (
      (this.props.event &&
        Array.isArray(this.props.event.content) &&
        !isNil(this.props.event.content[this.state.openedIndex]) &&
        !isNil(this.props.event.content[this.state.openedIndex].attributes) &&
        !isEmpty(this.props.event.content[this.state.openedIndex].attributes),
      this.props.eventsList &&
        Array.isArray(this.props.eventsList) &&
        this.props.eventsList.length > 0)
    ) {
      const customAttributes = Object.keys(
        this.props.event.content[this.state.openedIndex].attributes
      );
      let i = 0;
      let j = customAttributes.length;
      let temparray = [];
      const chunk = 3;
      for (i = 0, j = customAttributes.length; i < j; i += chunk) {
        temparray = customAttributes.slice(i, i + chunk);
        if (temparray.length === 1) {
          temparray.push('-1');
        } else if (temparray.length === 2) {
          temparray.push('-1', '-1');
        }
        result.push(
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              paddingBottom: 0,
            }}
          >
            {this.renderCustomAttributeItem(temparray[0])}
            {this.renderCustomAttributeItem(temparray[1])}
            {this.renderCustomAttributeItem(temparray[2])}
          </div>
        );
      }
    } else {
      result.push(<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />);
    }
    return result;
  };
  renderExteraAttributes = () => {
    let elements = {};
    if (
      (this.props.event &&
        Array.isArray(this.props.event.content) &&
        !isNil(this.props.event.content[this.state.openedIndex]) &&
        this.props.event.content[this.state.openedIndex].communicationId) ||
      this.props.event.content[this.state.openedIndex].variationId ||
      this.props.event.content[this.state.openedIndex].journeyId
    ) {
      elements.variationId =
        this.props.event.content[this.state.openedIndex].variationId;
      elements.communicationId =
        this.props.event.content[this.state.openedIndex].communicationId;
      elements.journeyId =
        this.props.event.content[this.state.openedIndex].journeyId;
      return (
        <div
          style={{
            display: 'flex',
            padding: '16px 16px 0px',
            borderTop: '1px solid #f0f0f0',
            marginTop: '16px',
          }}
        >
          {Object.keys(elements).map((key, index) => {
            return (
              elements[key] && (
                <div key={index} style={{ display: 'flex', flex: '0 0 33%' }}>
                  <span
                    style={{
                      color: '#707070',
                      fontSize: 12,
                      marginRight: 20,
                    }}
                  >
                    {key}:
                  </span>
                  <span style={{ fontSize: 12 }}>{elements[key]}</span>
                </div>
              )
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  renderModalTitle = () => {
    let result = '';
    if (
      this.props.event &&
      Array.isArray(this.props.event.content) &&
      !isNil(this.props.event.content[this.state.openedIndex])
    ) {
      result = this.props.event.content[this.state.openedIndex].name;
    }
    return result;
  };

  renderModalDeviceContent = () => {
    let result = [];
    if (
      this.props.event &&
      Array.isArray(this.props.event.content) &&
      !isNil(this.props.event.content[this.state.openedIndex]) &&
      !isNil(this.props.event.content[this.state.openedIndex].device)
    ) {
      result.push(
        <UserDeviceDetailsComponent
          device={this.props.event.content[this.state.openedIndex].device}
          labelStyle={{
            color: '#707070',
            fontSize: 12,
            marginRight: 5,
          }}
          valueStyle={{ color: '#1c1c1c', fontSize: 12 }}
        />
      );
    } else {
      result.push(<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />);
    }
    return result;
  };

  renderDeviceType = (index) => {
    let result = 'N/A';
    if (
      this.props.event &&
      Array.isArray(this.props.event.content) &&
      !isNil(this.props.event.content[index]) &&
      !isNil(this.props.event.content[index].device)
    ) {
      const type = this.props.event.content[index].device.sdkType;
      if (type === 'ANDROID') {
        result = 'Android';
      }
      if (type === 'IOS') {
        result = 'iOS';
      }
      if (type === 'WEB') {
        result = 'Web';
      }
    }
    return result;
  };

  renderChannelType = (type) => {
    let result = 'N/A';
    if (type === 'PUSH') {
      result = 'Push';
    } else if (type === 'SMS') {
      result = 'SMS';
    } else if (type === 'EMAIL') {
      result = 'Email';
    } else if (type === 'WEB_PUSH') {
      result = 'Web Push';
    } else if (type === 'CUSTOM') {
      result = 'Custom Channel';
    } else if (type === 'ON_SITE') {
      result = 'On Site';
    } else if (type === 'IN_APP') {
      result = 'In APP';
    }
    return result;
  };

  renderModal = () => {
    return (
      <Modal
        width={900}
        title={this.renderModalTitle()}
        centered
        visible={this.state.openedIndex !== -1}
        onOk={() => this.setState({ openedIndex: -1 })}
        onCancel={() => this.setState({ openedIndex: -1 })}
        footer={[
          <Button
            shape="round"
            key=""
            onClick={() => this.setState({ openedIndex: -1 })}
          >
            CLOSE
          </Button>,
        ]}
      >
        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              padding: 12,
              backgroundColor: '#efefef',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ color: '#000000', fontSize: 14 }}>Attributes</div>
          </div>
          {this.renderCustomAttributes()}
          {this.renderExteraAttributes()}
        </div>
        <div>
          <div
            style={{
              padding: 12,
              backgroundColor: '#efefef',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ color: '#000000', fontSize: 14 }}>
              {this.renderDeviceType(this.state.openedIndex) + ' Device'}
            </div>
          </div>
          {this.renderModalDeviceContent()}
        </div>
      </Modal>
    );
  };

  renderCommunicationField = (field) => {
    if (
      !isNil(
        this.props.communications.content[this.state.communicationOpenedIndex][
          field
        ]
      )
    ) {
      return this.props.communications.content[
        this.state.communicationOpenedIndex
      ][field];
    }
    return 'N/A';
  };

  renderCommunicationBoolean = (field) => {
    if (
      !isNil(
        this.props.communications.content[this.state.communicationOpenedIndex][
          field
        ]
      )
    ) {
      if (
        this.props.communications.content[this.state.communicationOpenedIndex][
          field
        ]
      ) {
        return 'True';
      } else {
        return 'False';
      }
    }
    return 'N/A';
  };

  renderCommunicationDate = (field) => {
    if (
      !isNil(
        this.props.communications.content[this.state.communicationOpenedIndex][
          field
        ]
      )
    ) {
      return readableDateTime(
        this.props.communications.content[this.state.communicationOpenedIndex][
          field
        ]
      );
    }
    return 'N/A';
  };

  renderJourneyId = () => {
    const journeyId =
      this.props.communications.content[this.state.communicationOpenedIndex]
        .journeyId;
    if (!isNil(journeyId)) {
      return (
        <a href={'../../../journey/' + journeyId + '/overview'}>{journeyId}</a>
      );
    }
    return 'N/A';
  };

  renderJourneySection = () => {
    const noItem =
      isNil(
        this.props.communications.content[this.state.communicationOpenedIndex]
          .journeyId
      ) &&
      isNil(
        this.props.communications.content[this.state.communicationOpenedIndex]
          .journeyStepId
      ) &&
      isNil(
        this.props.communications.content[this.state.communicationOpenedIndex]
          .journeyStateId
      );
    if (noItem) {
      return null;
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 16,
          paddingBottom: 0,
        }}
      >
        <div style={{ display: 'flex', flex: 1 }}>
          {!isNil(
            this.props.communications.content[
              this.state.communicationOpenedIndex
            ].journeyId
          ) && (
            <React.Fragment>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Journey ID:
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderJourneyId()}
              </span>
            </React.Fragment>
          )}
        </div>
        <div style={{ display: 'flex', flex: 1 }}>
          {!isNil(
            this.props.communications.content[
              this.state.communicationOpenedIndex
            ].journeyStepId
          ) && (
            <React.Fragment>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Journey Step ID:
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationField('journeyStepId')}
              </span>
            </React.Fragment>
          )}
        </div>
        <div style={{ display: 'flex', flex: 1 }}>
          {!isNil(
            this.props.communications.content[
              this.state.communicationOpenedIndex
            ].journeyStateId
          ) && (
            <React.Fragment>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Journey State ID:
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationField('journeyStateId')}
              </span>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  renderCommunicationId = () => {
    if (
      !isNil(
        this.props.communications.content[this.state.communicationOpenedIndex]
          .communicationId
      )
    ) {
      let result =
        '/campaigns/' +
        this.props.communications.content[this.state.communicationOpenedIndex]
          .communicationId +
        '/details';
      const type =
        this.props.communications.content[this.state.communicationOpenedIndex]
          .channel;
      if (type === 'PUSH') {
        result = 'push-notifications' + result;
      }
      if (type === 'SMS') {
        result = 'sms-messages' + result;
      }
      if (type === 'EMAIL') {
        result = 'emails' + result;
      }
      if (type === 'WEB_PUSH') {
        result = 'web-notifications' + result;
      }
      if (type === 'CUSTOM') {
        result = 'channel-push' + result;
      }
      if (type === 'IN_APP') {
        result = 'in-app' + result;
      }
      return (
        <a href={'../../../' + result}>
          {
            this.props.communications.content[
              this.state.communicationOpenedIndex
            ].communicationId
          }
        </a>
      );
    }
    return 'N/A';
  };

  contentJourneyModal = () => {
    const item = this.props.journeys.content[this.state.journeyOpenIndex];
    let content = [];

    for (const [key, value] of Object.entries(item)) {
      switch (key) {
        case 'variables':
          break;
        case 'endDate':
        case 'startDate':
          content.push(
            <li
              style={{
                display: 'flex',
                flex: '0 0 33%',
                paddingBottom: '16px',
              }}
            >
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                {key}:
              </span>
              <span style={{ fontSize: 12 }}>{readableDateTime(value)}</span>
            </li>
          );
          break;
        case 'controlGroup':
          content.push(
            <li
              style={{
                display: 'flex',
                flex: '0 0 33%',
                paddingBottom: '16px',
              }}
            >
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                {key}:
              </span>
              <span style={{ fontSize: 12 }}>{value ? 'true' : 'false'}</span>
            </li>
          );
          break;
        case 'journeyId':
          content.push(
            <li
              style={{
                display: 'flex',
                flex: '0 0 33%',
                paddingBottom: '16px',
              }}
            >
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                {key}:
              </span>
              <a
                style={{ fontSize: 12 }}
                target={'blank'}
                href={'../../../journey/' + value + '/overview'}
                rel="noreferrer"
              >
                {value}
              </a>
            </li>
          );
          break;
        case 'visitedSteps':
        case 'currentSteps':
          content.push(
            <li
              style={{
                display: 'flex',
                flex: '0 0 33%',
                paddingBottom: '16px',
              }}
            >
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                {key}:
              </span>
              <span style={{ fontSize: 12 }}>
                {!isEmpty(value) ? value.join() : '-'}
              </span>
            </li>
          );
          break;
        default:
          content.push(
            <li
              style={{
                display: 'flex',
                flex: '0 0 33%',
                paddingBottom: '16px',
              }}
            >
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                {key}:
              </span>
              <span style={{ fontSize: 12 }}>{value}</span>
            </li>
          );
      }
    }
    return content;
  };
  contentRelayModal = () => {
    const item = this.props.relays.content[this.state.relayOpenIndex];
    let content = [];

    for (const [key, value] of Object.entries(item)) {
      switch (key) {
        case 'variables':
          break;
        case 'endDate':
        case 'startDate':
          content.push(
            <li
              style={{
                display: 'flex',
                flex: '0 0 33%',
                paddingBottom: '16px',
              }}
            >
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                {key}:
              </span>
              <span style={{ fontSize: 12 }}>{readableDateTime(value)}</span>
            </li>
          );
          break;
        case 'controlGroup':
          content.push(
            <li
              style={{
                display: 'flex',
                flex: '0 0 33%',
                paddingBottom: '16px',
              }}
            >
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                {key}:
              </span>
              <span style={{ fontSize: 12 }}>{value ? 'true' : 'false'}</span>
            </li>
          );
          break;
        case 'journeyId':
          content.push(
            <li
              style={{
                display: 'flex',
                flex: '0 0 33%',
                paddingBottom: '16px',
              }}
            >
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                RelayId:
              </span>
              <a
                style={{ fontSize: 12 }}
                target={'blank'}
                href={'../../../relay/' + value + '/overview'}
                rel="noreferrer"
              >
                {value}
              </a>
            </li>
          );
          break;
        case 'visitedSteps':
        case 'currentSteps':
          content.push(
            <li
              style={{
                display: 'flex',
                flex: '0 0 33%',
                paddingBottom: '16px',
              }}
            >
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                {key}:
              </span>
              <span style={{ fontSize: 12 }}>
                {!isEmpty(value) ? value.join() : '-'}
              </span>
            </li>
          );
          break;
        default:
          content.push(
            <li
              style={{
                display: 'flex',
                flex: '0 0 33%',
                paddingBottom: '16px',
              }}
            >
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                {key}:
              </span>
              <span style={{ fontSize: 12 }}>{value}</span>
            </li>
          );
      }
    }
    return content;
  };
  renderJourneyModal = () => {
    return (
      <Modal
        width={1100}
        centered
        visible={this.state.journeyOpenIndex !== -1}
        onOk={() => this.setState({ journeyOpenIndex: -1 })}
        onCancel={() => this.setState({ journeyOpenIndex: -1 })}
        footer={[
          <Button
            shape="round"
            key=""
            onClick={() => this.setState({ journeyOpenIndex: -1 })}
          >
            CLOSE
          </Button>,
        ]}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '16px 16px 0',
            flexWrap: 'wrap',
          }}
        >
          {this.contentJourneyModal()}
        </div>
      </Modal>
    );
  };
  renderRelayModal = () => {
    return (
      <Modal
        width={1100}
        centered
        visible={this.state.relayOpenIndex !== -1}
        onOk={() => this.setState({ relayOpenIndex: -1 })}
        onCancel={() => this.setState({ relayOpenIndex: -1 })}
        footer={[
          <Button
            shape="round"
            key=""
            onClick={() => this.setState({ relayOpenIndex: -1 })}
          >
            CLOSE
          </Button>,
        ]}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '16px 16px 0',
            flexWrap: 'wrap',
          }}
        >
          {this.contentRelayModal()}
        </div>
      </Modal>
    );
  };
  renderCommunicationModal = () => {
    return (
      <Modal
        width={1100}
        centered
        visible={this.state.communicationOpenedIndex !== -1}
        onOk={() => this.setState({ communicationOpenedIndex: -1 })}
        onCancel={() => this.setState({ communicationOpenedIndex: -1 })}
        footer={[
          <Button
            shape="round"
            key=""
            onClick={() => this.setState({ communicationOpenedIndex: -1 })}
          >
            CLOSE
          </Button>,
        ]}
      >
        <div style={{ marginBottom: 20 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              paddingBottom: 0,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Channel:
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderChannelType(
                  this.props.communications.content[
                    this.state.communicationOpenedIndex
                  ].channel
                )}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Communication ID:
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationId()}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Variation ID:
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderChannelType(
                  this.props.communications.content[
                    this.state.communicationOpenedIndex
                  ].variationId
                )}
              </span>
            </div>
          </div>
          {this.renderJourneySection()}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              paddingBottom: 0,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Click Count:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationField('clickCount')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Last Click Date:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationDate('lastClickDate')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Follow FC:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationBoolean('followFrequencyCapping')}
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              paddingBottom: 0,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Has Converted:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationBoolean('converted')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Conversion Date:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationDate('conversionDate')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              paddingBottom: 0,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Delivery Status:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {
                  LatestMessageStatus[
                    this.props.communications.content[
                      this.state.communicationOpenedIndex
                    ].deliveryStatus
                  ]
                }
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Delivery Status Date:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationDate('deliveryStatusDate')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              paddingBottom: 0,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Sent:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationBoolean('sent')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Sent Date:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationDate('sentDate')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              paddingBottom: 0,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Has Failed:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationBoolean('failed')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Failed Description:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationField('description')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              paddingBottom: 0,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Execution Date:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationDate('executionDate')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                ID:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationField('id')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              paddingBottom: 0,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Event Date:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationDate('eventDate')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Notification Date:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationDate('notificationDate')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Message ID:{' '}
              </span>
              <span
                style={{
                  color: '#1c1c1c',
                  fontSize: 12,
                  wordBreak: 'break-word',
                }}
              >
                {this.renderCommunicationField('messageId')}
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              paddingBottom: 0,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Has Queued:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationBoolean('queued')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Number of Tries:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationField('tries')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                }}
              >
                Revenue:{' '}
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderCommunicationField('revenue')}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  // eslint-disable-next-line consistent-return
  renderPagination = (list) => {
    if (list) {
      const first = this.props.pathname.split('view/')[1];
      const encodedUserId = first.split('/interactions')[0];
      const userId = HashBase64().decode(encodedUserId);
      return (
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Pagination
            showSizeChanger={false}
            current={list.number + 1}
            pageSize={list.size}
            total={list.totalElements}
            onChange={(target) => {
              if (this.state.interactionSelector === 'EVENTS') {
                this.props.insightProductUserEventFetch(userId, {
                  ...this.props.eventPageRequest,
                  page: target - 1,
                  query: !isNil(this.state.query) ? this.state.query : null,
                });
                const params = HashBase64().encode(`?page=${+target}`);
                this.props.history.push({
                  pathname: this.props.history.location.pathname,
                  search: 'p=' + params + '&type=events',
                });
              } else if (this.state.interactionSelector === 'JOURNEYS') {
                this.props.insightProductUserJourneyFetch(userId, {
                  ...this.props.journeyPageRequest,
                  page: target - 1,
                  journeyType: 'JOURNEY',
                });

                const params = HashBase64().encode(`?page=${+target}`);
                this.props.history.push({
                  pathname: this.props.history.location.pathname,
                  search: 'p=' + params + '&type=journeys',
                });
              } else if (this.state.interactionSelector === 'RELAYS') {
                this.props.insightProductUserRelayFetch(userId, {
                  ...this.props.relayPageRequest,
                  page: target - 1,
                  journeyType: 'RELAY',
                });

                const params = HashBase64().encode(`?page=${+target}`);
                this.props.history.push({
                  pathname: this.props.history.location.pathname,
                  search: 'p=' + params + '&type=relays',
                });
              } else {
                this.props.insightProductUserCommunicationsFetch(userId, {
                  ...this.props.communicationsPageRequest,
                  page: target - 1,
                });
                const params = HashBase64().encode(`?page=${+target}`);
                this.props.history.push({
                  pathname: this.props.history.location.pathname,
                  search: 'p=' + params + '&type=communications',
                });
              }
            }}
          />
        </div>
      );
    }
  };

  renderMessageStatus = (object) => {
    let result = [];
    if (isNil(object.variationId) || !object.hasOwnProperty('variationId')) {
      result.push(<span>Control Group</span>);
    } else if (
      object.failed &&
      (isEmpty(object.description) || isNil(object.description))
    ) {
      result.push(<span>Failed</span>);
    } else if (
      object.failed &&
      !(isEmpty(object.description) || isNil(object.description))
    ) {
      result.push(
        <Tooltip placement="top" title={object.description}>
          <span>Failed</span>
        </Tooltip>
      );
    } else if (object.queued) {
      result.push(<span>Queued</span>);
    } else if (
      !isNil(object.deliveryStatus) &&
      !(isEmpty(object.deliveryStatusDate) || isNil(object.deliveryStatusDate))
    ) {
      result.push(
        <Tooltip
          placement="top"
          title={readableDateTime(object.deliveryStatusDate)}
        >
          <span>{LatestMessageStatus[object.deliveryStatus]}</span>
        </Tooltip>
      );
    } else if (!isNil(object.deliveryStatus)) {
      result.push(<span>{LatestMessageStatus[object.deliveryStatus]}</span>);
    } else if (
      object.sent === true &&
      (isNil(object.sentDate) || !object.hasOwnProperty('sentDate'))
    ) {
      result.push(<span>{'Sent'}</span>);
    } else if (
      object.sent === true &&
      !(isNil(object.sentDate) || !object.hasOwnProperty('sentDate'))
    ) {
      result.push(
        <Tooltip placement="top" title={readableDateTime(object.sentDate)}>
          <span>{'Sent'}</span>
        </Tooltip>
      );
    }
    return result;
  };

  renderConvertedDate = (object) => {
    let result = [];
    if (!isNil(object.converted) && isNil(object.conversionDate)) {
      result.push(
        <span>{object.converted ? 'Converted' : 'Not Converted'}</span>
      );
    } else if (!isNil(object.converted) && !isNil(object.conversionDate)) {
      result.push(
        <Tooltip
          placement="top"
          title={readableDateTime(object.conversionDate)}
        >
          <span>{object.converted ? 'Converted' : 'Not Converted'}</span>
        </Tooltip>
      );
    } else {
      result.push(<span>N/A</span>);
    }
    return result;
  };
  renderSegmentsItems = () => {
    const result = [];
    if (this.props.segments && Array.isArray(this.props.segments)) {
      this.props.segments.forEach((item) => {
        result.push(<Select.Option value={item.id}>{item.name}</Select.Option>);
      });
    }
    return result;
  };
  renderInteractions = () => {
    const data = [];
    let columns = [];
    if (this.state.interactionSelector === 'EVENTS') {
      if (
        this.props.event &&
        this.props.event.content &&
        Array.isArray(this.props.event.content)
      ) {
        this.props.event.content.forEach((item) => {
          data.push({
            key: item.time + item.name,
            event: item.name,
            time: readableDateTime(item.time),
            channel: '-',
          });
        });
      }
      columns = [
        {
          title: 'event',
          dataIndex: 'event',
          key: 'event',
          render: (text, object, index) => (
            <span
              onClick={() => this.handleOpenModal(index)}
              style={{
                color: vars.primaryColor,
                fontSize: 14,
                cursor: 'pointer',
              }}
            >
              {text}
            </span>
          ),
        },
        {
          title: 'time',
          dataIndex: 'time',
          key: 'time',
          render: (text) => (
            <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
          ),
        },
        {
          title: 'channel',
          dataIndex: 'channel',
          key: 'time',
          render: (text, record, index) => (
            <span style={{ fontSize: 14, fontWeight: '600' }}>
              {this.renderDeviceType(index)}
            </span>
          ),
        },
        // {
        //     title: 'ACTION',
        //     dataIndex: '',
        //     key: '-action-',
        //     width: 300,
        //     render: (text, record, index) => defaultActions(record,
        //         { action: () => this.handleOpenModal(index), title: 'Details', enabled: true, disablePopConfirm: true },
        //         // { link: 'copy/' + x.id, title: 'Duplicate', enabled: hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_EVENT_CREATE) },
        //         // { action: (id) => this.props.customEventDeleteFetch(id, this.props.pageRequest), title: 'Delete', enabled: hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_EVENT_DELETE) },
        //     ),
        // },
      ];
    }
    if (this.state.interactionSelector === 'COMMUNICATIONS') {
      if (
        this.props.communications &&
        this.props.communications.content &&
        Array.isArray(this.props.communications.content)
      ) {
        this.props.communications.content.forEach((item) => {
          data.push({ ...item, key: item.id + item.channel });
        });
      }
      columns = [
        {
          title: 'channel',
          dataIndex: 'channel',
          key: 'channel',
          render: (text, object, index) => (
            <span
              onClick={() => this.handleOpenCommunicationModal(index)}
              style={{
                color: vars.primaryColor,
                fontSize: 14,
                cursor: 'pointer',
              }}
            >
              {this.renderChannelType(text)}
            </span>
          ),
        },
        {
          title: 'latest status',
          dataIndex: 'type',
          key: 'type',
          render: (x, object) => this.renderMessageStatus(object),
        },
        {
          title: 'execution date',
          dataIndex: 'executionDate',
          key: 'executionDate',
          render: (x) => readableDateTime(x),
        },
        {
          title: 'CONVERSION DETAILS',
          dataIndex: 'conversionDetails',
          key: 'conversionDetails',
          render: (x, object) => this.renderConvertedDate(object),
        },
        {
          title: 'revenue',
          dataIndex: 'revenue',
          key: 'revenue',
          render: (x, object) => {
            if (isNil(object.revenue) || !object.hasOwnProperty('revenue')) {
              return <span>N/A</span>;
            }
            return numberWithCommas(object.revenue);
          },
        },
      ];
    }
    if (this.state.interactionSelector === 'JOURNEYS') {
      if (
        this.props.journeys?.content &&
        Array.isArray(this.props.journeys.content)
      ) {
        data.push(...this.props.journeys.content);
      }
      columns = [
        {
          title: 'ID',
          dataIndex: 'journeyId',
          key: 'journeyId',
          render: (text, object, index) => (
            <span
              onClick={() => this.handleOpenJourneyModal(index)}
              style={{
                color: vars.primaryColor,
                fontSize: 14,
                cursor: 'pointer',
              }}
            >
              {text}
            </span>
          ),
        },
        {
          title: 'START DATE',
          dataIndex: 'startDate',
          key: 'startDate',
          render: (x) => readableDateTime(x),
        },
        {
          title: 'END DATE',
          dataIndex: 'endDate',
          key: 'endDate',
          render: (x) => (x ? readableDateTime(x) : '-'),
        },
        {
          title: 'END REASON',
          dataIndex: 'endReason',
          key: 'endReason',
          render: (x) => (x ? x : '-'),
        },
        {
          title: 'CONTROL GROUP',
          dataIndex: 'controlGroup',
          key: 'controlGroup',
          render: (text) => <span>{text ? 'TRUE' : 'FALSE'}</span>,
        },
      ];
    }
    if (this.state.interactionSelector === 'RELAYS') {
      if (
        this.props.relays?.content &&
        Array.isArray(this.props.relays.content)
      ) {
        data.push(...this.props.relays.content);
      }
      columns = [
        {
          title: 'ID',
          dataIndex: 'journeyId',
          key: 'journeyId',
          render: (text, object, index) => (
            <span
              onClick={() => this.handleOpenRelayModal(index)}
              style={{
                color: vars.primaryColor,
                fontSize: 14,
                cursor: 'pointer',
              }}
            >
              {text}
            </span>
          ),
        },
        {
          title: 'START DATE',
          dataIndex: 'startDate',
          key: 'startDate',
          render: (x) => readableDateTime(x),
        },
        {
          title: 'END DATE',
          dataIndex: 'endDate',
          key: 'endDate',
          render: (x) => (x ? readableDateTime(x) : '-'),
        },
        {
          title: 'END REASON',
          dataIndex: 'endReason',
          key: 'endReason',
          render: (x) => (x ? x : '-'),
        },
        {
          title: 'CONTROL GROUP',
          dataIndex: 'controlGroup',
          key: 'controlGroup',
          render: (text) => <span>{text ? 'TRUE' : 'FALSE'}</span>,
        },
      ];
    }
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const first = this.props.pathname.split('view/')[1];
    const encodedUserId = first.split('/interactions')[0];
    const userId = HashBase64().decode(encodedUserId);
    return (
      <Card styles={{ paddingBottom: 8 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '12px',
            paddingBottom: 0,
            paddingLeft: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              color: '#000000',
              fontSize: 16,
              fontWeight: '600',
            }}
          >
            <span style={{ paddingRight: 10 }}>Last</span>{' '}
            <Select
              onChange={(interactionSelector) =>
                this.handleChangeStatus(interactionSelector)
              }
              value={this.state.interactionSelector}
              style={{ width: 150, marginRight: 40 }}
              className={'chart-selector'}
              bordered={false}
            >
              <Select.Option value="EVENTS">Events</Select.Option>
              <Select.Option value="COMMUNICATIONS">
                Communications
              </Select.Option>
              <Select.Option value="JOURNEYS">Journeys</Select.Option>
              <Select.Option value="RELAYS">Relays</Select.Option>
            </Select>
            {this.state.interactionSelector === 'EVENTS' && (
              <Input
                allowClear
                placeholder={'Search by event name'}
                onPressEnter={(event) => {
                  this.setState({
                    query: event.target.value,
                  });
                  this.props.insightProductUserEventFetch(userId, {
                    ...this.props.eventPageRequest,
                    page: 0,
                    query: event.target.value,
                  });
                }}
                onChange={(event) => {
                  if (
                    isEmpty(event.target.value) ||
                    isNil(event.target.value)
                  ) {
                    this.setState({ query: null });
                    this.props.insightProductUserEventFetch(userId, {
                      ...this.props.eventPageRequest,
                      page: this.props.event.number,
                      query: null,
                    });
                  }
                }}
                style={{
                  width: 300,
                  marginRight: 30,
                  borderRadius: 5,
                }}
              />
            )}
          </div>
        </div>
        {this.state.interactionSelector === 'EVENTS' ? (
          this.props.userLoading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <Spin indicator={inputLoadingSpin} />
            </div>
          ) : (
            <React.Fragment>
              {this.props.eventLoading ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    paddingTop: 40,
                    paddingBottom: 40,
                  }}
                >
                  <Spin indicator={inputLoadingSpin} />
                </div>
              ) : (
                <Table
                  className={'custom-table'}
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                />
              )}
              {this.renderPagination(this.props.event)}
            </React.Fragment>
          )
        ) : this.state.interactionSelector === 'JOURNEYS' ? (
          this.props.userLoading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <Spin indicator={inputLoadingSpin} />
            </div>
          ) : this.props.journeysLoading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <Spin indicator={inputLoadingSpin} />
            </div>
          ) : (
            <>
              {' '}
              <Table
                className={'custom-table'}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
              {this.renderPagination(this.props.journeys)}
            </>
          )
        ) : this.state.interactionSelector === 'RELAYS' ? (
          this.props.userLoading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <Spin indicator={inputLoadingSpin} />
            </div>
          ) : this.props.relaysLoading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                paddingTop: 40,
                paddingBottom: 40,
              }}
            >
              <Spin indicator={inputLoadingSpin} />
            </div>
          ) : (
            <>
              {' '}
              <Table
                className={'custom-table'}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
              {this.renderPagination(this.props.relays)}
            </>
          )
        ) : this.props.userLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              paddingTop: 40,
              paddingBottom: 40,
            }}
          >
            <Spin indicator={inputLoadingSpin} />
          </div>
        ) : (
          <React.Fragment>
            {this.props.communicationsLoading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  paddingTop: 40,
                  paddingBottom: 40,
                }}
              >
                <Spin indicator={inputLoadingSpin} />
              </div>
            ) : (
              <Table
                className={'custom-table'}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
            )}
            {this.renderPagination(this.props.communications)}
          </React.Fragment>
        )}
      </Card>
    );
  };

  render() {
    return (
      <div>
        {this.renderInteractions()}
        {this.state.openedIndex !== -1 && this.renderModal()}
        {this.state.communicationOpenedIndex !== -1 &&
          this.renderCommunicationModal()}
        {this.state.journeyOpenIndex !== -1 && this.renderJourneyModal()}
        {this.state.relayOpenIndex !== -1 && this.renderRelayModal()}
      </div>
    );
  }
}
export default UserInteractionsComponent;
