/* eslint-disable indent */
import React from 'react';
import { Link } from 'react-router-dom';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import {
  DownOutlined,
  SearchOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { history } from '../../../../redux/store';
import {
  AuthorityProvider,
  getProductId,
  hasAnyAuthority,
} from '../../../../utils/AuthorityProvider';
import { readableDateTime } from '../../../../utils/DateUtils';
import { Badge, Button, Dropdown, Input, Modal, Select, Spin } from 'antd';
import { isEmpty, isNil } from 'lodash';
import {
  numberWithCommas,
  renderLowerFont,
  renderTag,
} from '../../../../utils/RenderUtils';
import toastr from 'toastr';
import TagSelector from '../../../../component/form/TagSelector';
import TagInput from '../../../../component/form/TagInput';
import vars from '@Theme/styles/vars';
import Card from '@Utils/Card';
import { thirdPartyEnums } from '@Modules/admin/thirdParty/components/ThirdPartyTypes';
import { journeyTypes } from '@Modules/engage/journey/model/JourneyTypes';
import { isParentProduct } from '@Utils/isParentProduct';
import { getProduct } from '@Utils/getProduct';
const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  statusBadge: {
    padding: '2px 5px 3px 5px',
    fontSize: 11,
    fontWeight: '600',
    height: '20px',
    lineHeight: '18px',
    borderRadius: '5px',
    textTransform: 'uppercase',
  },
};

const allStatus = [
  'DRAFT',
  'UPCOMING',
  'RUNNING',
  'ENDED',
  'PAUSED',
  'STOPPED',
];

class JourneyListComponent extends React.Component {
  state = {
    name: this.getUrlParamValue('name') ?? '',
    searchStatus: this.getUrlParamValue('status') ?? null,
    selectedTagsRowIndex: -1,
  };

  isNotParent() {
    return !isParentProduct(this.props.currentUser, location);
  }

  getUrlParamValue(x) {
    return new URLSearchParams(this.props.location?.search).get(x);
  }

  deleteUrlParam(x) {
    const params = new URLSearchParams(this.props.location?.search);
    if (params.has(x)) {
      params.delete(x);
      return '?' + params.toString();
    }
    return this.props.location?.search;
  }

  deleteUrlParams(keysToDelete) {
    const params = new URLSearchParams(this.props.location?.search);

    keysToDelete.forEach((key) => {
      if (params.has(key)) {
        params.delete(key);
      }
    });

    return params.toString() ? '?' + params.toString() : '';
  }

  updateQueryStringParameter = (uri, key, value) => {
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    var separator = uri?.indexOf('?') !== -1 ? '&' : '?';
    if (uri?.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      return uri + separator + key + '=' + value;
    }
  };

  componentDidMount() {
    if (isNil(this.props.currentUser.products)) return;
    this.didDataFetch();
    let pid = getProductId(this.props.pathname);
    let product = getProduct(this.props.currentUser, pid);

    if (product?.thirdPartyOption !== thirdPartyEnums.PARENT) {
      this.props.communicationListTagsFetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isNil(this.props.currentUser.products) &&
      isNil(prevProps.currentUser.products)
    ) {
      this.didDataFetch();
    }
    if (this.props.pathname !== prevProps.pathname) {
      this.didDataFetch();
    }
    if (
      this.props.archiveSuccess !== prevProps.archiveSuccess ||
      this.props.activeSuccess !== prevProps.activeSuccess
    ) {
      this.didDataFetch();
    }
    if (
      this.props.loadingUpdateSubmitJourney === false &&
      prevProps.loadingUpdateSubmitJourney === true &&
      isEmpty(this.props.errorUpdateSubmitJourney)
    ) {
      this.setState({ selectedTagsRowIndex: -1 }, () => {
        this.props.journeyListFetch(this.props.pageRequest);
        toastr.success('Has been updated successfully');
      });
    }
  }

  componentWillUnmount() {
    this.props.journeyPageChangeCurrent({});
  }

  didDataFetch = () => {
    // this.props.changeMenuLayout(true);
    const pageValue = this.getUrlParamValue('page') - 1;
    const nameValue = this.getUrlParamValue('name');
    const statusValue = this.getUrlParamValue('status');
    const tagsValue =
      this.getUrlParamValue('tags') === null
        ? null
        : this.getUrlParamValue('tags').split(',');

    const Archived = this.props.pathname.includes('archive');
    let type = this.props.type;
    if (Archived) {
      this.props.journeyListFetch({
        ...this.props.pageRequest,
        name: nameValue,
        page: `${pageValue > 0 ? pageValue : 0}`,
        direction: 'DESC',
        order: 'modifiedDate',
        archived: Archived,
        type: type,
        status:
          statusValue === 'ALL'
            ? allStatus
            : statusValue === null
            ? null
            : [statusValue],
        tags: tagsValue,
      });
    } else {
      this.props.journeyListFetch({
        ...this.props.pageRequest,
        name: nameValue,
        page: `${pageValue > 0 ? pageValue : 0}`,
        direction: 'DESC',
        order: 'modifiedDate',
        archived: Archived,
        status:
          statusValue === 'ALL'
            ? allStatus
            : statusValue === null
            ? null
            : [statusValue],
        type: type,
        tags: tagsValue,
      });
    }
  };

  renderTagSelector = () => {
    return (
      <Dropdown
        overlay={
          <TagSelector
            listTags={this.props.listTags}
            selectedTags={
              this.props.pageRequest &&
              this.props.pageRequest.tags &&
              Array.isArray(this.props.pageRequest.tags)
                ? this.props.pageRequest.tags
                : null
            }
            onApply={(tags) => this.handleApplyTags(tags)}
          />
        }
        trigger={['click']}
      >
        <div
          id="tag-selector"
          style={{
            width: 80,
            marginRight: 20,
            display: 'flex',
            flexDirection: 'row',
            color: '#1C1C1C',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F3F3F3',
            cursor: 'pointer',
          }}
        >
          <Badge
            offset={[10, 0]}
            count={this.renderSelectedCount('tags')}
            size="small"
            showZero={false}
          >
            Tags
          </Badge>{' '}
          <DownOutlined
            style={{ marginLeft: 20, color: '#b6b6b6', fontSize: 12 }}
          />
        </div>
      </Dropdown>
    );
  };

  handleApplyTags = (tags) => {
    let params;
    const newUrlPrams = this.deleteUrlParam('page');

    document.getElementById('tag-selector').click();
    if ((Array.isArray(tags) && tags.length === 0) || isNil(tags)) {
      this.props.journeyListFetch({
        ...this.props.pageRequest,
        tags: null,
        page: 0,
      });
      params = this.deleteUrlParam('tags');
    } else {
      this.props.journeyListFetch({ ...this.props.pageRequest, tags, page: 0 });
      params = this.updateQueryStringParameter(newUrlPrams, 'tags', tags);
    }

    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: params,
    });
  };

  renderSelectedCount = (selector) => {
    if (
      this.props.pageRequest &&
      this.props.pageRequest[selector] &&
      Array.isArray(this.props.pageRequest[selector]) &&
      this.props.pageRequest[selector].length > 0
    ) {
      return this.props.pageRequest[selector].length;
    }
    return null;
  };

  viewItem(id) {
    if (hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_ATTRIBUTE_VIEW)) {
      history.push(`view/${id}`);
    }
  }

  getItemLink = (row) => {
    const type = this.props?.type === 'RELAY' ? 'relay' : 'journey';
    if (row.status === 'DRAFT') {
      return `../${type}/${row.id}/view`;
    } else {
      return `../${type}/${row.id}/overview`;
    }
  };

  handleClickMenu = () => {
    // this.props.changeMenuLayout(false);
    this.props.communicationDraftSaveDiscard();
  };

  getNavigation() {
    return [{ name: 'Custom Attribute List', path: '' }];
  }

  handleChangeSearch = (name) => {
    this.setState({ name });
    if (name === '' || name === null) {
      this.props.journeyListFetch({
        ...this.props.pageRequest,
        page: 0,
        name: null,
      });

      const UrlPrams = this.deleteUrlParams(['name', 'page']);

      this.props.history.push({
        pathname: this.props.history.location.pathname,
        search: UrlPrams,
      });
    }
  };

  handleSearchName = () => {
    this.props.journeySearchListFetch({
      ...this.props.pageRequest,
      page: 0,
      name: this.state.name,
    });

    const newUrlPrams = this.deleteUrlParam('page');

    const params = this.updateQueryStringParameter(
      newUrlPrams,
      'name',
      this.state.name
    );

    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: params,
    });
  };

  openTagList = (index) => {
    if (this.props.page.content[index].id) {
      this.setState({ selectedTagsRowIndex: index }, () => {
        this.props.journeyUpdateTags(
          this.props.page.content[this.state.selectedTagsRowIndex].id
        );
      });
    }
  };

  renderListTags = (index, tags) => {
    if (tags && Array.isArray(tags) && tags.length > 0) {
      let result = [];
      result.push(
        <span style={{ marginRight: 10 }}>
          {renderTag(tags[0], this.props.listTags)}
        </span>
      );
      if (tags.length > 1) {
        result.push(
          <span
            onClick={() => this.openTagList(index)}
            style={{
              fontSize: 12,
              cursor: 'pointer',
              color: vars.primaryColor,
              textDecoration: 'underline',
            }}
          >
            {'+' + (tags.length - 1) + ' more'}
          </span>
        );
      }
      return result;
    }
    return '-';
  };

  renderAllTags = () => {
    return (
      <TagInput
        containerStyle={{
          display: 'flex',
          flexDirection: 'column',
          flex: 6,
          fontSize: 13,
          lineHeight: 1.43,
          color: '#3d404e',
        }}
        disabled={false}
        style={{ width: 500 }}
        tags={this.props.currentJourney.tags}
        onChange={(value) =>
          this.props.journeyChangeCurrent({
            ...this.props.currentJourney,
            tags: value,
          })
        }
        listTags={this.props.listTags}
        hideDescription
      />
    );
  };

  renderTagsModal = () => {
    return (
      <Modal
        bodyStyle={{ overflow: 'scroll' }}
        width={600}
        title={
          this.state.selectedTagsRowIndex > -1 &&
          this.props.page.content[this.state.selectedTagsRowIndex].name
        }
        centered
        visible={this.state.selectedTagsRowIndex !== -1}
        onCancel={() => this.setState({ selectedTagsRowIndex: -1 })}
        footer={[
          <Button
            key="cancel"
            shape="round"
            onClick={() => this.setState({ selectedTagsRowIndex: -1 })}
          >
            CANCEL
          </Button>,
          <Button
            disabled={['ENDED', 'RUNNING'].includes(
              this.props.page.content[this.state.selectedTagsRowIndex].status
            )}
            loading={this.props.loadingUpdateSubmitJourney}
            key="submit"
            shape="round"
            type="primary"
            onClick={() => this.onOkModal()}
          >
            Update
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{ paddingRight: 20, paddingTop: 10 }}>Tags </span>
          {this.renderAllTags()}
        </div>
      </Modal>
    );
  };

  onOkModal = () => {
    this.props.journeyUpdateSubmitTags(
      this.props.page.content[this.state.selectedTagsRowIndex].id,
      this.props.currentJourney
    );
  };

  handleChangeStatus = (searchStatus) => {
    let params;
    const newUrlPrams = this.deleteUrlParam('page');

    this.setState({ searchStatus });
    if (searchStatus === 'ALL') {
      this.props.journeyListFetch({
        ...this.props.pageRequest,
        page: 0,
        status: allStatus,
      });
      params = this.updateQueryStringParameter(newUrlPrams, 'status', 'ALL');
    } else {
      this.props.journeyListFetch({
        ...this.props.pageRequest,
        page: 0,
        status: [`${searchStatus}`],
      });
      params = this.updateQueryStringParameter(newUrlPrams, 'status', [
        `${searchStatus}`,
      ]);
    }

    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: params,
    });
  };

  renderJourneyStatus = (status) => {
    if (this.props.pathname.includes('archive')) {
      return (
        <span
          style={{
            ...styles.statusBadge,
            backgroundColor: '#94969e',
            color: '#fff',
          }}
        >
          ARCHIVED
        </span>
      );
    }
    if (status === 'DRAFT') {
      return (
        <span
          style={{
            ...styles.statusBadge,
            backgroundColor: '#e7e8e9',
            color: '#94969e',
          }}
        >
          DRAFT
        </span>
      );
    } else if (status === 'UPCOMING') {
      return (
        <span
          style={{
            ...styles.statusBadge,
            backgroundColor: '#ffc107',
            color: '#FFFFFF',
          }}
        >
          UPCOMING
        </span>
      );
    } else if (status === 'RUNNING') {
      return (
        <span
          style={{
            ...styles.statusBadge,
            backgroundColor: '#008bfa',
            color: '#FFFFFF',
          }}
        >
          RUNNING
        </span>
      );
    } else if (status === 'ENDED') {
      return (
        <span
          style={{
            ...styles.statusBadge,
            backgroundColor: '#09d88c',
            color: '#FFFFFF',
          }}
        >
          ENDED
        </span>
      );
    } else if (status === 'PAUSED') {
      return (
        <span
          style={{
            ...styles.statusBadge,
            backgroundColor: vars.warningColor,
            color: '#FFFFFF',
          }}
        >
          PAUSED
        </span>
      );
    } else if (status === 'STOPPED') {
      return (
        <span
          style={{
            ...styles.statusBadge,
            backgroundColor: vars.errorColor,
            color: '#FFFFFF',
          }}
        >
          STOPPED
        </span>
      );
    }
    return null;
  };

  onChangeHandler = (pg) => {
    this.props.journeyListFetch(pg);
    const params = this.updateQueryStringParameter(
      this.props.location?.search,
      'page',
      +pg.page + 1
    );

    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: params,
    });
  };

  render() {
    let type;
    switch (this.props.type) {
      case journeyTypes.JOURNEY:
        type = 'journey';
        break;
      case journeyTypes.RELAY:
        type = 'relay';
        break;
      case journeyTypes.JOURNEY_TEMPLATE:
        type = 'journey';
        break;
      default:
        type = 'journey';
    }
    let columns;
    columns = [
      {
        title: 'name',
        width: 200,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        render: (x, object) => (
          <Link
            to={this.getItemLink(object)}
            onClick={() => this.handleClickMenu()}
          >
            <span
              style={{
                color: vars.primaryColor,
                fontSize: 14,
                cursor: 'pointer',
              }}
            >
              {x}
            </span>
          </Link>
        ),
      },
      {
        title: 'status',
        dataIndex: 'status',
        key: 'status',
        width: 40,
        render: (x) => this.renderJourneyStatus(x),
      },
      this.isNotParent() && {
        title: 'tags',
        dataIndex: 'tags',
        key: 'status',
        width: 180,
        render: (x, object, index) => this.renderListTags(index, x),
      },
      this.isNotParent() && {
        title: 'launch Date',
        dataIndex: 'launchDate',
        key: 'launchDate',
        width: 120,
        render: (x) => renderLowerFont(readableDateTime(x)),
      },
      this.isNotParent() && {
        title: 'enters',
        dataIndex: 'enters',
        key: 'enters',
        width: 50,
        render: (x) => renderLowerFont(numberWithCommas(x)),
      },
      this.isNotParent() && {
        title: 'exits',
        dataIndex: 'exits',
        key: 'exits',
        width: 50,
        render: (x) => renderLowerFont(numberWithCommas(x)),
      },
      {
        title: 'modified Date',
        dataIndex: 'modifiedDate',
        key: 'modifiedDate',
        width: 120,
        render: (x) => renderLowerFont(readableDateTime(x)),
      },
      {
        ...(this.props.isArchived
          ? {
              title: 'action',
              key: 'action',
              fixed: 'left',
              width: '5%',
              render: (x, object, index) =>
                defaultActions(
                  x,
                  {
                    link: `../${type}/${x.id}/view`,
                    title: 'Edit',
                    enabled: true,
                    onClick: () => this.handleClickMenu(),
                  },
                  {
                    action: () => this.openTagList(index),
                    title: 'Tags',
                    disablePopConfirm: true,
                    enabled: true,
                  },
                  {
                    action: (id) => this.props.journeyActivate(id),
                    title: 'Active',
                    enabled: this.props.isArchived,
                  }
                ),
            }
          : {
              title: 'action',
              key: 'action',
              fixed: 'left',
              width: '5%',
              render: (x, object, index) => {
                return defaultActions(
                  x,
                  this.isNotParent() && {
                    link: `../${type}/${x.id}/report`,
                    title: 'Live View',
                    enabled: !['DRAFT'].includes(x.status),
                  },
                  {
                    link: `../${type}/${x.id}/view`,
                    title: 'Edit',
                    enabled: ['DRAFT', 'STOPPED', 'PAUSED'].includes(x.status),
                    onClick: () => this.handleClickMenu(),
                  },
                  this.isNotParent() && {
                    action: (id) =>
                      this.props.journeyPause(id, this.props.pageRequest),
                    title: 'Pause',
                    enabled:
                      ['RUNNING'].includes(x.status) && this.isNotParent(),
                  },
                  this.isNotParent() && {
                    action: (id) =>
                      this.props.journeyStop(id, this.props.pageRequest),
                    title: 'Stop',
                    enabled: ['RUNNING', 'PAUSED'].includes(x.status),
                  },
                  this.isNotParent() && {
                    action: (id) =>
                      this.props.journeyCopyFetch(id, this.props.pageRequest),
                    title: 'Duplicate',
                    enabled: true,
                  },
                  {
                    action: (id) =>
                      this.props.journeyDeleteFetch(id, this.props.pageRequest),
                    title: 'Delete',
                    enabled: ['DRAFT'].includes(x.status),
                  },
                  this.isNotParent() && {
                    action: () => this.openTagList(index),
                    title: 'Tags',
                    disablePopConfirm: true,
                    enabled: ['DRAFT', 'STOPPED'].includes(x.status),
                  },
                  this.isNotParent() && {
                    action: (id) => this.props.journeyArchive(id),
                    title: 'Archive',
                    enabled: ['DRAFT', 'STOPPED'].includes(x.status),
                  }
                );
              },
            }),
      },
    ].filter(Boolean);

    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    return (
      <div>
        <Card>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              borderBottom: 'solid 2px #f4f9ff',
            }}
          >
            <div>
              <Input
                loading={true}
                allowClear={this.props.loadingSearchList ? false : true}
                placeholder={'Search by name'}
                onPressEnter={this.handleSearchName}
                onChange={(e) => this.handleChangeSearch(e.target.value)}
                value={this.state.name}
                style={{ width: 300, borderRadius: 5 }}
                suffix={
                  isEmpty(this.state.name) ? (
                    <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  ) : (
                    this.props.loadingSearchList && (
                      <div style={styles.inputLoadingSpinStyle}>
                        <Spin indicator={inputLoadingSpin} />
                      </div>
                    )
                  )
                }
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.isNotParent() && this.renderTagSelector()}
              {!this.props.isArchived && this.isNotParent() && (
                <Select
                  onChange={(searchStatus) =>
                    this.handleChangeStatus(searchStatus)
                  }
                  value={
                    this.props?.pageRequest?.status?.length >= 6 ||
                    this.props?.pageRequest?.status === null
                      ? 'ALL'
                      : this.props?.pageRequest?.status
                  }
                  style={{ width: 120 }}
                  className={'chart-selector'}
                  bordered={false}
                >
                  <Select.Option value="ALL">All Statuses</Select.Option>
                  <Select.Option value="RUNNING">Running</Select.Option>
                  <Select.Option value="PAUSED">Paused</Select.Option>
                  <Select.Option value="STOPPED">Stopped</Select.Option>
                  <Select.Option value="DRAFT">Draft</Select.Option>
                </Select>
              )}
            </div>
          </div>
          {this.props.loadingList ? (
            <div style={styles.loadingSpinStyle}>
              <Spin indicator={loadingSpin} />
            </div>
          ) : (
            <ListBase
              onChange={this.onChangeHandler}
              page={this.props.page}
              pageRequest={this.props.pageRequest}
              columns={columns}
              // onClick={(row) => return null}
              createLink={
                hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_USER_CREATE)
                  ? 'create'
                  : null
              }
            />
          )}
        </Card>
        {this.state.selectedTagsRowIndex > -1 && this.renderTagsModal()}
      </div>
    );
  }
}

export default JourneyListComponent;
