import CommunicationEngagedUserComponent from '../component/CommunicationEngagedUserComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  communicationEngagedUserReportFetch,
  communicationSearchEngagedUserReportFetch,
  communicationEngagedUserPageChangeCurrent,
} from '../CommunicationActions';
import { callExportCommunicationFile } from '@Component/utils/CsvExport/CsvExportActions';

import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  loadingCommunicationEngagedUser:
    state.engage.communication.loadingCommunicationEngagedUser,
  errorsCommunicationEngagedUser:
    state.engage.communication.errorsCommunicationEngagedUser,
  communicationEngagedUser: state.engage.communication.communicationEngagedUser,
  communicationEngagedUserPageRequest:
    state.engage.communication.communicationEngagedUserPageRequest,
  pathname: state.router.location.pathname,
  loadingSearchCommunicationEngagedUser:
    state.engage.communication.loadingSearchCommunicationEngagedUser,
  currentUser: state.account.auth.currentUser,
  errors: state.csv.errors,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      communicationEngagedUserReportFetch,
      communicationEngagedUserPageChangeCurrent,
      communicationSearchEngagedUserReportFetch,
      callExportCommunicationFile,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationEngagedUserComponent);

CommunicationEngagedUserComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  communicationListFetch: PropTypes.func.isRequired,
  communicationDeleteFetch: PropTypes.func.isRequired,
  communicationPageChangeCurrent: PropTypes.func.isRequired,
};
