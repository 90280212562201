import React from 'react';
import { Table } from 'antd';
import { isNil } from 'lodash';
import ok from '../../../../theme/images/icons/ok.png';
import not_ok from '../../../../theme/images/icons/not_ok.png';
import { HashBase64 } from '@Component/utils/HashBase64';
import Card from '@Utils/Card';

class UserChannelsComponent extends React.Component {
  state = {};

  componentDidMount() {
    const first = this.props.pathname.split('view/')[1];
    const encodedUserId = first.split('/channels')[0];
    const userId = HashBase64().decode(encodedUserId);
    this.props.insightProductUserViewFetch(userId);
  }

  renderItem = (user, channel, item) => {
    if (user && !isNil(user[channel]) && !isNil(user[channel][item])) {
      return user[channel][item];
    }
    return '-';
  };

  renderChannels = () => {
    const user = this.props.user;
    const data = [
      {
        key: 'reachability',
        name: 'Reachability',
        push: user && user.pushReachable,
        sms: user && user.smsReachable,
        webPush: user && user.webPushReachable,
        email: user && user.emailReachable,
        inApp: user && user.inAppReachable,
        onSite: user && user.onSiteReachable,
        whatsapp: user && user.whatsAppReachable,
      },
      {
        key: 'opt',
        name: 'Opt-In',
        push: user && user.pushOptIn,
        sms: user && user.smsOptIn,
        webPush: user && user.webPushOptIn,
        email: user && user.emailOptIn,
        inApp: user && user.inAppOptIn,
        onSite: user && user.onSiteOptIn,
        whatsapp: user && user.whatsAppOptIn,
      },
      // {
      //     key: 'totalMessageSent',
      //     name: 'Total Message Sent',
      //     push: this.renderItem(user, 'push', 'totalMessageSent'),
      //     sms: this.renderItem(user, 'sms', 'totalMessageSent'),
      //     webPush: this.renderItem(user, 'webPush', 'totalMessageSent'),
      //     email: this.renderItem(user, 'email', 'totalMessageSent'),
      // },
      // {
      //     key: 'totalCampaigns',
      //     name: 'Total Campaigns',
      //     push: this.renderItem(user, 'push', 'totalCampaigns'),
      //     sms: this.renderItem(user, 'sms', 'totalCampaigns'),
      //     webPush: this.renderItem(user, 'webPush', 'totalCampaigns'),
      //     email: this.renderItem(user, 'email', 'totalCampaigns'),
      // },
      // {
      //     key: 'totalConversions',
      //     name: 'Total Conversions',
      //     push: this.renderItem(user, 'push', 'totalConversions'),
      //     sms: this.renderItem(user, 'sms', 'totalConversions'),
      //     webPush: this.renderItem(user, 'webPush', 'totalConversions'),
      //     email: this.renderItem(user, 'email', 'totalConversions'),
      // },
    ];
    const columns = [
      {
        title: '',
        dataIndex: 'name',
        key: 'name',
        render: (text) => (
          <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
        ),
      },
      {
        title: 'push',
        dataIndex: 'push',
        key: 'push',
        render: (text) => {
          if (text === true || text === false) {
            return (
              <img
                src={text === true ? ok : not_ok}
                style={{ width: 20, height: 'auto' }}
                alt=""
              />
            );
          }
          return (
            <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
          );
        },
      },
      {
        title: 'SMS',
        dataIndex: 'sms',
        key: 'sms',
        render: (text) => {
          if (text === true || text === false) {
            return (
              <img
                src={text === true ? ok : not_ok}
                style={{ width: 20, height: 'auto' }}
                alt=""
              />
            );
          }
          return (
            <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
          );
        },
      },
      {
        title: 'web ',
        dataIndex: 'webPush',
        key: 'webPush',
        render: (text) => {
          if (text === true || text === false) {
            return (
              <img
                src={text === true ? ok : not_ok}
                style={{ width: 20, height: 'auto' }}
                alt=""
              />
            );
          }
          return (
            <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
          );
        },
      },
      {
        title: 'email',
        dataIndex: 'email',
        key: 'email',
        render: (text) => {
          if (text === true || text === false) {
            return (
              <img
                src={text === true ? ok : not_ok}
                style={{ width: 20, height: 'auto' }}
                alt=""
              />
            );
          }
          return (
            <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
          );
        },
      },
      {
        title: 'In App',
        dataIndex: 'inApp',
        key: 'inApp',
        render: (text) => {
          if (text === true || text === false || !text) {
            return (
              <img
                src={text === true ? ok : not_ok}
                style={{ width: 20, height: 'auto' }}
                alt=""
              />
            );
          }
          return (
            <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
          );
        },
      },
      {
        title: 'On Site',
        dataIndex: 'onSite',
        key: 'onSite',
        render: (text) => {
          if (text === true || text === false || !text) {
            return (
              <img
                src={text === true ? ok : not_ok}
                style={{ width: 20, height: 'auto' }}
                alt=""
              />
            );
          }
          return (
            <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
          );
        },
      },
      {
        title: 'whatsapp',
        dataIndex: 'whatsapp',
        key: 'whatsapp',
        render: (text) => {
          if (text === true || text === false || !text) {
            return (
              <img
                src={text === true ? ok : not_ok}
                style={{ width: 20, height: 'auto' }}
                alt=""
              />
            );
          }
          return (
            <span style={{ fontSize: 14, fontWeight: '600' }}>{text}</span>
          );
        },
      },
    ];
    return (
      <Card loading={this.props.userLoading}>
        <div>
          {' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              paddingBottom: 0,
              paddingLeft: '20px',
            }}
          >
            <div style={{ color: '#000000', fontSize: 16, fontWeight: '600' }}>
              Channel Reachability
            </div>
          </div>
          <Table
            className={'custom-table'}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </div>
      </Card>
    );
  };

  render() {
    return <div style={{ padding: 30 }}>{this.renderChannels()}</div>;
  }
}
export default UserChannelsComponent;
