import * as React from 'react';

function SvgCsv(props) {
  return (
    <svg viewBox="0 0 26 16" width="1em" height="1em" {...props}>
      <path
        d="M23.75 15.937a2.257 2.257 0 002.25-2.25V2.25A2.257 2.257 0 0023.75 0H2.25A2.257 2.257 0 000 2.25v11.437a2.257 2.257 0 002.25 2.25h21.5zM6.812 5.6c-.573 0-1.017.215-1.33.646-.315.43-.472 1.03-.472 1.799 0 1.6.6 2.4 1.802 2.4.504 0 1.114-.126 1.83-.378v1.276c-.588.246-1.246.369-1.973.369-1.044 0-1.842-.317-2.395-.95-.553-.634-.83-1.543-.83-2.727 0-.746.136-1.4.408-1.961a2.945 2.945 0 011.17-1.291c.51-.3 1.106-.45 1.79-.45.697 0 1.397.169 2.1.506l-.49 1.237a8.126 8.126 0 00-.81-.334 2.416 2.416 0 00-.8-.142zm8.166 4.02c0 .648-.233 1.159-.7 1.532-.466.373-1.115.56-1.946.56-.766 0-1.443-.144-2.032-.432V9.866c.484.216.894.368 1.23.456.335.089.642.133.92.133.334 0 .59-.064.768-.192.179-.127.268-.317.268-.569 0-.14-.04-.266-.118-.376a1.318 1.318 0 00-.346-.316c-.152-.102-.462-.264-.93-.486-.439-.206-.768-.404-.987-.594a2.23 2.23 0 01-.525-.663 1.891 1.891 0 01-.197-.883c0-.635.216-1.134.646-1.498.43-.363 1.025-.544 1.784-.544.373 0 .73.044 1.068.132.339.088.693.213 1.063.373l-.491 1.183a7.182 7.182 0 00-.95-.329 3.057 3.057 0 00-.739-.093c-.288 0-.509.067-.662.201a.664.664 0 00-.231.525.65.65 0 00.093.351c.062.1.161.197.297.29.136.093.457.261.965.503.67.321 1.13.643 1.379.965.249.322.373.717.373 1.185zm5.967-5.183h1.537l-2.44 7.176h-1.659l-2.435-7.176h1.537l1.35 4.27c.075.252.153.546.233.881.08.336.13.569.15.7.036-.301.158-.828.368-1.58l1.36-4.271z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgCsv;
