import * as React from 'react';

function SvgWhatsapp(props) {
  return (
    <svg viewBox="0 0 26 26" width="1em" height="1em" {...props}>
      <path
        d="M22.1 3.778A12.785 12.785 0 0012.985 0C5.885 0 .107 5.779.107 12.883c0 2.27.594 4.49 1.721 6.44L0 26l6.83-1.793a12.88 12.88 0 006.155 1.57h.005c7.1 0 12.883-5.78 12.883-12.884 0-3.443-1.34-6.677-3.773-9.115zm-9.11 19.825a10.7 10.7 0 01-5.454-1.493l-.391-.233-4.052 1.06 1.081-3.95-.254-.406a10.632 10.632 0 01-1.64-5.698c0-5.906 4.804-10.71 10.715-10.71 2.859 0 5.55 1.118 7.571 3.139a10.652 10.652 0 013.134 7.576c-.005 5.911-4.81 10.715-10.71 10.715zm5.87-8.023c-.32-.163-1.904-.94-2.199-1.046-.294-.107-.507-.163-.726.162-.213.32-.833 1.046-1.02 1.265-.188.213-.376.243-.696.08-.32-.162-1.361-.502-2.59-1.599-.955-.853-1.605-1.91-1.793-2.23-.188-.319-.02-.497.143-.654.147-.142.32-.376.482-.564.162-.188.213-.32.32-.538.106-.213.056-.401-.026-.564-.08-.162-.726-1.747-.99-2.392-.259-.63-.528-.543-.726-.553-.188-.01-.401-.01-.614-.01a1.19 1.19 0 00-.859.4c-.294.32-1.127 1.103-1.127 2.687 0 1.585 1.153 3.113 1.315 3.331.163.214 2.27 3.469 5.5 4.86.767.33 1.366.528 1.833.68.772.244 1.473.209 2.026.128.62-.092 1.905-.777 2.174-1.529.269-.751.269-1.396.188-1.528-.077-.143-.29-.224-.615-.386z"
        fill="#FFF"
      />
    </svg>
  );
}

export default SvgWhatsapp;
