import React, { useEffect } from 'react';
import './inlineThemePicker.less';
import { InputNumber } from 'antd';

const BorderRadiusInput = ({ variation, onChange }) => {
  const borderRadius = variation?.theme?.['border_radius'];

  const onBorderRadiusChange = (value) => {
    onChange({
      ...(variation?.theme ?? {}),
      border_radius: value,
    });
  };

  useEffect(() => {
    if (typeof borderRadius === 'undefined') onBorderRadiusChange('16');
  }, [borderRadius]);

  return (
    <div
      style={{
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        flex: 3,
      }}
    >
      <InputNumber
        className="radius-input"
        value={typeof borderRadius === 'undefined' ? 16 : borderRadius}
        addonAfter={<div style={{ height: '100%' }}>px</div>}
        onChange={(event) => {
          onBorderRadiusChange(event);
        }}
      />
    </div>
  );
};

export { BorderRadiusInput };
