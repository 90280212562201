import AdminProductListComponent from '../component/AdminProductListComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  adminProductPageChangeCurrent,
  adminProductListFetch,
  adminProductIndexAllFetch,
  adminProductDelete,
} from '../AdminProductActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  page: state.admin.adminProduct.page,
  pageRequest: state.admin.adminProduct.pageRequest,
  loadingProductNameList: state.admin.adminProduct.loadingProductNameList,
  loadingSearchProductNameList:
    state.admin.adminProduct.loadingSearchProductNameList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      adminProductDelete,
      adminProductPageChangeCurrent,
      adminProductListFetch,
      adminProductIndexAllFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProductListComponent);

AdminProductListComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  adminProductListFetch: PropTypes.func.isRequired,
  adminProductPageChangeCurrent: PropTypes.func.isRequired,
  adminProductIndexAllFetch: PropTypes.func.isRequired,
};
