import {
  ACTIVE_MEMBERS_CHANGE_CURRENT,
  ACTIVE_TEAM_MEMBER_LIST_FETCH,
  ACTIVE_TEAM_MEMBER_LIST_FETCH_FAIL,
  ACTIVE_TEAM_MEMBER_LIST_FETCH_SUCCESS,
  INVITED_USER_CHANGE_CURRENT,
  ROLE_LIST_FETCH,
  ROLE_LIST_FETCH_FAIL,
  ROLE_LIST_FETCH_SUCCESS,
  TEAM_CREATE_FETCH,
  TEAM_CREATE_FETCH_FAIL,
  TEAM_CREATE_FETCH_SUCCESS,
  TEAM_MEMBER_CHANGE_CURRENT,
  TEAM_MEMBER_DELETE_FETCH,
  TEAM_MEMBER_DELETE_FETCH_FAIL,
  TEAM_MEMBER_DELETE_FETCH_SUCCESS,
  TEAM_MEMBER_REGISTER_FETCH,
  TEAM_MEMBER_REGISTER_FETCH_SUCCESS,
  TEAM_MEMBER_VIEW_FETCH,
  TEAM_MEMBER_VIEW_FETCH_FAIL,
  TEAM_MEMBER_VIEW_FETCH_SUCCESS,
  TEAM_RESEND_INVITATION,
  TEAM_RESEND_INVITATION_SUCCESS,
  TEAM_UPDATE_FETCH,
  TEAM_UPDATE_FETCH_SUCCESS,
  TEAM_UPDATE_SUBMIT_FETCH,
  TEAM_UPDATE_SUBMIT_FETCH_SUCCESS,
} from './TeamMembersConstants';
import { AUTH_LOGOUT_FETCH_SUCCESS } from '../../../modules/account/auth/AuthConstants';
const initialState = {
  teamMembers: [],
  teamMembersPageRequest: {},
  teamMembersLoading: false,
  changed: false,
  loadingViewPermissions: false,
  permissions: null,
  loadingDelete: false,
  roleList: {},
  loadingRoleList: false,
  currentTeamMember: {},
  createTeamLoading: false,
  registerLoading: false,
  currentInvitedUser: {},
  updateTeamLoading: false,
  updateSubmitLoading: false,
  resendInvitationLoading: false,
  registerResponse: null,
};

export default function usersStateReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVE_TEAM_MEMBER_LIST_FETCH:
      return {
        ...state,
        teamMembersLoading: true,
        // activityErrors: [],
        teamMembersPageRequest: action.payload,
      };
    case ACTIVE_TEAM_MEMBER_LIST_FETCH_FAIL:
      return {
        ...state,
        teamMembersLoading: false,
        // activityErrors: action.payload.errors,
      };
    case ACTIVE_TEAM_MEMBER_LIST_FETCH_SUCCESS:
      return {
        ...state,
        teamMembersLoading: false,
        teamMembers: action.payload,
      };
    case ACTIVE_MEMBERS_CHANGE_CURRENT:
      return { ...state, teamMembers: action.payload, changed: true };
    case TEAM_MEMBER_VIEW_FETCH:
      return {
        ...state,
        permissions: action.payload,
        loadingViewPermissions: true,
      };
    case TEAM_MEMBER_VIEW_FETCH_FAIL:
      return {
        ...state,
        permissions: action.payload,
        loadingViewPermissions: false,
      };
    case TEAM_MEMBER_VIEW_FETCH_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
        loadingViewPermissions: false,
      };
    case TEAM_MEMBER_DELETE_FETCH:
      return { ...state, loadingDelete: true };
    case TEAM_MEMBER_DELETE_FETCH_FAIL:
      return {
        ...state,
        errorsDelete: action.payload.errors,
        loadingDelete: false,
      };
    case TEAM_MEMBER_DELETE_FETCH_SUCCESS:
      return { ...state, loadingDelete: false };
    case ROLE_LIST_FETCH_FAIL:
      return {
        ...state,
        loadingRoleList: false,
      };
    case ROLE_LIST_FETCH_SUCCESS:
      return { ...state, loadingRoleList: false, roleList: action.payload };
    case ROLE_LIST_FETCH:
      return {
        ...state,
        loadingRoleList: true,
      };
    case TEAM_MEMBER_CHANGE_CURRENT:
      return { ...state, currentTeamMember: action.payload };
    case TEAM_CREATE_FETCH:
      return { ...state, createTeamLoading: true };
    case TEAM_CREATE_FETCH_SUCCESS:
      return { ...state, createTeamLoading: false };
    case TEAM_CREATE_FETCH_FAIL:
      return {
        ...state,
        createTeamLoading: false,
      };
    case TEAM_MEMBER_REGISTER_FETCH:
      return {
        ...state,
        registerLoading: true,
      };
    case TEAM_MEMBER_REGISTER_FETCH_SUCCESS:
      return {
        ...state,
        registerLoading: false,
        registerResponse: action.payload,
      };
    case INVITED_USER_CHANGE_CURRENT:
      return { ...state, currentInvitedUser: action.payload };
    case TEAM_UPDATE_FETCH:
      return {
        ...state,
        updateTeamLoading: true,
      };
    case TEAM_UPDATE_FETCH_SUCCESS:
      return {
        ...state,
        updateTeamLoading: false,
        currentTeamMember: action.payload,
      };
    case TEAM_RESEND_INVITATION:
      return {
        ...state,
        resendInvitationLoading: true,
      };
    case TEAM_RESEND_INVITATION_SUCCESS:
      return {
        ...state,
        resendInvitationLoading: false,
      };
    case TEAM_UPDATE_SUBMIT_FETCH:
      return {
        ...state,
        updateSubmitLoading: true,
      };
    case TEAM_UPDATE_SUBMIT_FETCH_SUCCESS:
      return {
        ...state,
        updateSubmitLoading: false,
      };
    case AUTH_LOGOUT_FETCH_SUCCESS:
      return {
        ...state,
        currentUser: { login: false },
        activeProduct: null,
      };
    default:
      return state;
  }
}
