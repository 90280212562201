import React from 'react';
import { isNil, find as lodashFind } from 'lodash';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { convertMinute } from '../../../../utils/DateUtils';
import CommunicationStepsComponent from './CommunicationStepsComponent';
import { history } from '../../../../redux/store';
import {
  getChannel,
  getCommunicationId,
  getCommunicationStep,
  getProductId,
} from '../../../../utils/AuthorityProvider';
import { renderDelay } from '../../../../utils/ReducerUtils';
import { viewDateTime, viewTime } from '../../../../utils/DateUtils';
import { variationLetter } from '../../../../utils/FormUtils';
import { isEmpty } from 'lodash';
import { Spin } from 'antd';
import CommunicationFooterComponent from './CommunicationFooterComponent';
import CommunicationPreviewWidgetComponent from './CommunicationPreviewWidgetComponent';
import { renderTag } from '../../../../utils/RenderUtils';
import { LoadingOutlined } from '@ant-design/icons';
import { transactionalSteps } from '../../../../utils/CommunicationSteps';
import texts from '../../../../utils/texts';
import vars from '@Theme/styles/vars';
import Card from '@Utils/Card';
const styles = {
  headTitleContainer: {
    fontSize: 18,
    fontWeight: '500',
    color: '#3d404e',
    display: 'flex',
    flexDirection: 'row',
  },
  infoBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 30px',
    borderBottom: '1px solid #e7e8e9',
    backgroundColor: '#fdfdfd',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dataRowContainer: {
    padding: 30,
  },
  dataRowFirst: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dataRow: {
    paddingTop: 10,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dataValue: {
    direction: 'ltr',
    fontSize: 13,
    color: '#3d404e',
    marginLeft: '20px',
  },
  dataContainer: {
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
  },
  dataLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '20px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
  },
  tableRow: {
    borderBottom: '1px solid #eee',
  },
};

class CommunicationPreviewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.step = getCommunicationStep(this.props.pathname);
    this.channel = getChannel(this.props.pathname);
    if (props.journey) {
      this.communicationId =
        props.communicationId ||
        (props.communicationData && props.communicationData.id);
      // this.communicationId =
      //     (props.communicationData && props.communicationData.id) ||
      //     (this.props.dataNode && this.props.dataNode.communication);
    } else {
      this.communicationId = getCommunicationId(this.props.pathname);
    }
    this.state = {
      segment: 0,
      isPreviewAndroid: true,
      activeVariationIndex: 0,
      variationIndex: 0,
    };
  }

  componentDidMount() {
    this.props.communicationViewFetch(this.communicationId);
    this.props.communicationListEmailChannelsFetch();
    this.props.communicationListPushChannelsFetch();
    this.props.communicationListSmsChannelsFetch();
    this.props.communicationListEventsFetch();
    this.props.communicationListSegmentsFetch();
    this.props.communicationListTagsFetch();
    this.props.communicationListCustomChannelsFetch();
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.loadingView &&
      prevProps.loadingView &&
      this.props.currentCommunication
    ) {
      this.props?.currentCommunication?.variations.map((variation, index) => {
        if (variation.whatsAppTemplate) {
          this.props.whatsappChannelTemplateViewFetch(
            variation.whatsAppTemplate,
            index
          );
        }
      });
      if (
        !this.state.activeVariationIndex &&
        this.props?.currentCommunication?.variations?.length
      ) {
        this.setState({
          activeVariationIndex:
            this.props?.currentCommunication?.variations[0]?.id,
        });
      }
    }
  }

  goToStep = (step) => {
    history.push(step);
  };

  handleClickNext = () => {
    if (this.props.journey) {
      this.props.updateJourney(
        'update-step',
        Object.assign({}, this.props.dataNode, {
          communication: this.communicationId,
        })
      );
      // setTimeout(() => this.props.closeModal(), 1000);
      this.props.closeModal();
    } else {
      this.props.communicationLaunchFetch(this.communicationId, {});
    }
  };

  getStartDate = (preview = false) => {
    let startDate = moment().toDate();
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.startDate
    ) {
      startDate = this.props.currentCommunication.startDate;
    }
    if (preview) {
      return !startDate ? '-' : viewDateTime(startDate);
    }
    return startDate;
  };

  getEndDate = (preview = false) => {
    let endDate = null;
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.endDate &&
      this.props.currentCommunication.type !== 'ONE_TIME'
    ) {
      endDate = this.props.currentCommunication.endDate;
    }
    if (preview) {
      return !endDate ? '-' : viewDateTime(endDate);
    }
    return endDate;
  };

  renderAudienceType = () => {
    let result = null;
    if (
      (this.props.currentCommunication &&
        (isNil(this.props.currentCommunication.segments) ||
          isEmpty(this.props.currentCommunication.segments)) &&
        (isNil(this.props.currentCommunication.ignoreSegments) ||
          isEmpty(this.props.currentCommunication.ignoreSegments))) ||
      (this.props.currentCommunication &&
        this.props.currentCommunication.segments &&
        this.props.currentCommunication.segments.length === 1 &&
        (!this.props.currentCommunication.ignoreSegments ||
          (this.props.currentCommunication.ignoreSegments &&
            this.props.currentCommunication.ignoreSegments.length === 0)))
    ) {
      result = 'Single Segment';
    } else {
      result = 'Multiple Segment';
    }
    return result;
  };

  renderPushPackageNames = () => {
    const result = [];
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.pushChannels &&
      this.props.pushChannels
    ) {
      this.props.currentCommunication.pushChannels.forEach((item) => {
        const foundItem = lodashFind(
          this.props.pushChannels,
          (o) => o.id === item
        );
        if (foundItem && foundItem.packageName) {
          result.push(foundItem.packageName);
        }
      });
    }
    if (result.length > 0) {
      return result.join(', ');
    } else {
      return '-';
    }
  };

  renderWeekDay = (days) => {
    let result = '';
    const daysName = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    if (Array.isArray(days) && days.length > 0) {
      days.forEach((day, index) => {
        result += daysName[day];
        if (index !== days.length - 1) {
          result += ', ';
        }
      });
    }
    return result;
  };

  renderMonthDay = (days) => {
    let result = '';
    if (Array.isArray(days) && days.length > 0) {
      days.forEach((day, index) => {
        if (day == -1) {
          result = 'Last day of month';
          if (index !== days.length - 1) {
            result += ', ';
          }
        } else {
          const dayNumber = Number(day) + 1;
          result += dayNumber + '';
          if (index !== days.length - 1) {
            result += ', ';
          }
        }
      });
    }
    return result;
  };

  renderRecuringTime = () => {
    let result = '-';
    if (this.props.currentCommunication) {
      if (this.props.currentCommunication.repeatType === 'DAY') {
        result =
          'Send Every ' +
          this.props.currentCommunication.repeatEvery +
          ' ' +
          'Day' +
          (this.props.currentCommunication.repeatEvery == 1 ? ' ' : 's ') +
          ' at ' +
          viewTime(this.props.currentCommunication.repeatTime);
      } else if (this.props.currentCommunication.repeatType === 'WEEK') {
        result =
          'Send Every ' +
          this.props.currentCommunication.repeatEvery +
          ' ' +
          'Week' +
          (this.props.currentCommunication.repeatEvery == 1 ? ' ' : 's ') +
          this.renderWeekDay() +
          ' at ' +
          viewTime(this.props.currentCommunication.repeatTime);
      } else if (this.props.currentCommunication.repeatType === 'MONTH') {
        result =
          'Send Every ' +
          this.props.currentCommunication.repeatEvery +
          ' ' +
          'Month' +
          (this.props.currentCommunication.repeatEvery == 1 ? ' ' : 's ') +
          this.renderMonthDay() +
          ' at ' +
          viewTime(this.props.currentCommunication.repeatTime);
      }
    }
    return result;
  };

  renderDeliveryTime = () => {
    let result = '';
    if (
      (this.props.currentCommunication &&
        this.props.currentCommunication.startDate === null) ||
      this.props.currentCommunication.delayMinutes == 0
    ) {
      result =
        this.props?.currentCommunication?.channel === 'IN_APP'
          ? "On User's Device Request"
          : 'On Event Occurrence';
    } else {
      const delayMinutes = this.props.currentCommunication.delayMinutes;
      result = renderDelay(delayMinutes) + ', After Event Occurrence';
    }
    return result;
  };

  renderConversionTime = () => {
    let result = '-';
    if (
      this.props.currentCommunication &&
      (this.props.currentCommunication.conversionDeadlineMinutes === null ||
        this.props.currentCommunication.conversionDeadlineMinutes == 0)
    ) {
      result = 'On Event Occurrence';
    } else {
      const delayMinutes =
        this.props.currentCommunication.conversionDeadlineMinutes;
      result = renderDelay(delayMinutes) + ', After Event Occurrence';
    }
    return result;
  };

  renderCampaignType = () => {
    let result = '';
    if (this.props.currentCommunication) {
      if (this.props.currentCommunication.type === 'TRIGGERED') {
        result = 'Triggered';
      } else if (this.props.currentCommunication.type === 'ONE_TIME') {
        result = 'One Time';
      } else if (this.props.currentCommunication.type === 'RECURRING') {
        result = 'Recurring';
      } else if (this.props.currentCommunication.type === 'TRANSACTIONAL') {
        result = 'Transactional';
      } else if (this.props.currentCommunication.type === 'JOURNEY') {
        result = 'Journey';
      }
    }
    return result;
  };

  renderTriggeredEvent = () => {
    let result = '';
    if (
      this.props.currentCommunication &&
      !isNil(this.props.currentCommunication.event)
    ) {
      const foundItem = lodashFind(
        this.props.eventList,
        (o) => o.id === this.props.currentCommunication.event
      );
      if (foundItem && foundItem.title) {
        result = foundItem.title;
      }
    }
    return result ? result : '-';
  };

  changeVariation = (activeVariationIndex) => {
    this.setState({ activeVariationIndex });
  };

  renderVariations = () => {
    const result = [];
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.variations &&
      Array.isArray(this.props.currentCommunication.variations)
    ) {
      this.props.currentCommunication.variations.forEach((item, index) => {
        result.push(
          <div
            onClick={() => {
              this.changeVariation(item.id);
              this.setState({ variationIndex: index });
            }}
            style={{
              borderBottom:
                item.id === this.state.activeVariationIndex
                  ? '3px solid'
                  : '3px solid',
              borderColor:
                item.id === this.state.activeVariationIndex
                  ? vars.primaryColor
                  : '#ffffff',
              width: 120,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 20,
              paddingRight: 5,
              paddingLeft: 5,
              cursor: 'pointer',
            }}
          >
            <span
              style={{
                color: '#3d404e',
                fontWeight: '500',
                lineHeight: '50px',
              }}
            >
              {'Variation ' + variationLetter[index]}
            </span>
          </div>
        );
      });
    }
    return result;
  };

  renderEventName = (id) => {
    let result = '-';
    const foundItem = lodashFind(this.props.eventList, (o) => o.id === id);
    if (foundItem && foundItem.title) {
      result = foundItem.title;
    }
    return result;
  };

  renderTags = () => {
    if (
      this.props.currentCommunication &&
      this.props.currentCommunication.tags &&
      Array.isArray(this.props.currentCommunication.tags) &&
      this.props.currentCommunication.tags.length > 0
    ) {
      let result = [];
      this.props.currentCommunication.tags.forEach((item) => {
        result.push(
          <span style={{ marginRight: 10 }}>
            {renderTag(item, this.props.listTags)}
          </span>
        );
      });
      return result;
    }
    return '-';
  };

  renderPostfixQueue = (value, text) => {
    if (!isNil(value) && !isNil(text)) {
      if (value && value == 1) {
        return 'Up to ' + value + ' ' + text.toLowerCase();
      } else {
        return 'Up to ' + value + ' ' + text.toLowerCase() + 's';
      }
    }
    return '-';
  };

  renderQueueMinutes = () => {
    if (this.props.currentCommunication.queueMinutes) {
      const queue = convertMinute(this.props.currentCommunication.queueMinutes);
      return this.renderPostfixQueue(queue.value, queue.type);
    }
    return '';
  };

  renderPreviewCustomChannel = () => {
    if (this.props.currentCommunication.variations) {
      if (!this.state.activeVariationIndex) {
        return null;
      }
      const currentVariationInfo =
        this.props.currentCommunication.variations.find(
          (cvar) => cvar.id === this.state.activeVariationIndex
        );
      if (!currentVariationInfo) {
        return null;
      }
      const customChannelId = currentVariationInfo.customChannel;
      const customChannel = this.props.customChannels.find(
        (item) => item.id === customChannelId
      );
      if (customChannel) {
        return (
          <div style={styles.dataRowContainer}>
            <div style={styles.dataRowFirst}>
              <div style={styles.dataContainer}>
                <span style={styles.dataLabel}>Service Provider</span>
                <span style={styles.dataValue}>
                  {customChannel.name || '-'}
                </span>
              </div>
              <div style={styles.dataContainer}>
                <span style={styles.dataLabel}>Subject</span>
                <span style={styles.dataValue}>
                  {currentVariationInfo.subject || '-'}
                </span>
              </div>
              <div style={styles.dataContainer}>
                <span style={styles.dataLabel}>Message</span>
                <span style={styles.dataValue}>
                  <div
                    style={{
                      maxHeight: '100px',
                      overflow: 'auto',
                      border: '1px solid #eee',
                      margin: '5px 0',
                      padding: '5px',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        currentVariationInfo.message || '-'
                      ),
                    }}
                  />
                </span>
              </div>
            </div>
            <div style={styles.dataRow}>
              <div style={styles.dataContainer}>
                <span style={styles.dataLabel}>Icon</span>
                <span style={styles.dataValue}>
                  {currentVariationInfo.icon || '-'}
                </span>
              </div>
              <div style={styles.dataContainer}>
                <span style={styles.dataLabel}>Image</span>
                <span style={styles.dataValue}>
                  {currentVariationInfo.image || '-'}
                </span>
              </div>
              <div style={styles.dataContainer}></div>
            </div>
            <div style={styles.dataRow}>
              <div style={styles.dataContainer}>
                <span style={styles.dataLabel}>Key-Value Pairs</span>
                <span style={styles.dataValue}>
                  {currentVariationInfo.keyValuePair?.length ? (
                    <table>
                      <tr style={styles.tableRow}>
                        <th style={styles.dataLabel}>KEY</th>
                        <th></th>
                        <th style={styles.dataLabel}>VALUE</th>
                      </tr>
                      {currentVariationInfo.keyValuePair.map((item, index) => {
                        return (
                          <tr style={styles.tableRow} key={index}>
                            <td>{item.key}</td>
                            <td>=</td>
                            <td>{item.value}</td>
                          </tr>
                        );
                      })}
                    </table>
                  ) : (
                    'off'
                  )}
                </span>
              </div>
            </div>
          </div>
        );
      }
    }
  };

  renderProductTitle = () => {
    let pid = getProductId(this.props.pathname);
    return (
      (this.props?.userProducts &&
        this.props?.userProducts?.find((p) => p.id === pid)?.name) ||
      texts?.BrandName
    );
  };

  renderPreview = () => {
    let pid = getProductId(this.props.pathname);

    const apiCallCurlText =
      'curl -X POST <HOST>/api/sdk/accounts/<YOUR_' +
      texts.BRANDNAME +
      '_LICENSE_CODE>/transactionCommunication \\  \n ' +
      "–header 'Authorization: Bearer <YOUR_" +
      texts.BRANDNAME +
      "_API_KEY>' + \\  \n" +
      "–header 'Content-Type: application/json' \\  \n" +
      "–data '{  \n" +
      '"communicationId": ' +
      this.communicationId +
      ',  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"userId": "userId",  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"anonymousId": null,  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"variationTokens": {  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"someMessageToken": "desired value"  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 },  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"overrideData": {  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"email": "12@3",  \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"phone": "09121234567", \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"queueMinutes": 2 \n' +
      '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0}  \n' +
      "}'";
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    return (
      <div className="raman-table">
        {this.props.currentCommunication.type !== 'TRANSACTIONAL' && (
          <div
            style={{
              background: 'none',
              marginTop: 40,
              backgroundColor: 'white',
            }}
          >
            <Card>
              <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
                <div style={styles.headTitleContainer}>
                  <span>Audience</span>
                  {false && (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.goToStep('audience')}
                    >
                      <i
                        className="fl-edit"
                        style={{
                          border: '1px solid',
                          borderRadius: '50%',
                          fontSize: '28px',
                          color: '#94969e',
                          marginLeft: 10,
                        }}
                      ></i>
                    </div>
                  )}
                </div>
              </div>
              {this.props.loadingPushChannels ||
              this.props.loadingSegment ||
              this.props.loadingView ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    paddingTop: 50,
                    paddingBottom: 50,
                  }}
                >
                  <Spin indicator={loadingSpin} />
                </div>
              ) : (
                <div style={styles.dataRowContainer}>
                  <div style={styles.dataRowFirst}>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>COMMUNICATION NAME</span>
                      <span
                        style={styles.dataValue}
                        data-cy="communication-name"
                      >
                        {this.props.currentCommunication &&
                        this.props.currentCommunication.name
                          ? this.props.currentCommunication.name
                          : '-'}
                      </span>
                    </div>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>CONTENT TYPE</span>
                      <span style={styles.dataValue} data-cy="content-type">
                        {this.props.currentCommunication &&
                        this.props.currentCommunication.transactional === false
                          ? 'Promotional'
                          : 'Transactional'}
                      </span>
                    </div>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>AUDIENCE TYPE</span>
                      <span style={styles.dataValue} data-cy="audience-type">
                        {this.renderAudienceType()}
                      </span>
                    </div>
                  </div>
                  <div style={styles.dataRow}>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>Send to</span>
                      <span style={styles.dataValue}>
                        {this.renderSendTo()}
                      </span>
                    </div>
                    <div style={styles.dataContainer}>
                      {this.channel === 'PUSH' && (
                        <React.Fragment>
                          <span style={styles.dataLabel}>Target Device</span>
                          <span style={styles.dataValue}>
                            {this.props.currentCommunication &&
                            isNil(this.props.currentCommunication.mobileDevice)
                              ? 'Both Android & iOS'
                              : this.props.currentCommunication.mobileDevice ===
                                'ANDROID'
                              ? 'Android Only'
                              : 'iOS Only'}
                          </span>
                        </React.Fragment>
                      )}
                    </div>
                    <div style={styles.dataContainer}>
                      {this.channel === 'PUSH' && (
                        <React.Fragment>
                          <span style={styles.dataLabel}>Target Apps</span>
                          <span style={styles.dataValue}>
                            {this.renderPushPackageNames()}
                          </span>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div style={styles.dataRow}>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>Tags</span>
                      <span style={styles.dataValue}>{this.renderTags()}</span>
                    </div>
                    <div style={styles.dataContainer}></div>
                    <div style={styles.dataContainer}></div>
                  </div>
                </div>
              )}
            </Card>
          </div>
        )}
        {this.props.currentCommunication.type !== 'TRANSACTIONAL' && (
          <div
            className="raman-paper-profile"
            style={{
              background: 'none',
              marginTop: 40,
              backgroundColor: 'white',
            }}
          >
            <Card>
              <div style={styles.infoBoxHeader}>
                <div style={styles.headTitleContainer}>
                  <span>When</span>
                  {false && (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.goToStep('when')}
                    >
                      <i
                        className="fl-edit"
                        style={{
                          border: '1px solid',
                          borderRadius: '50%',
                          fontSize: '28px',
                          color: '#94969e',
                          marginLeft: 10,
                        }}
                      ></i>
                    </div>
                  )}
                </div>
              </div>
              {this.props.loadingView || this.props.loadingEvents ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    paddingTop: 50,
                    paddingBottom: 50,
                  }}
                >
                  <Spin indicator={loadingSpin} />
                </div>
              ) : (
                <div style={styles.dataRowContainer}>
                  <div style={styles.dataRowFirst}>
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>CAMPAIGN TYPE</span>
                      <span style={styles.dataValue} data-cy="campaign-type">
                        {this.renderCampaignType()}
                      </span>
                    </div>
                    {this.props.currentCommunication &&
                      this.props.currentCommunication.type === 'TRIGGERED' && (
                        <div style={styles.dataContainer}>
                          <span style={styles.dataLabel}>TRIGGER EVENT:</span>
                          <span style={styles.dataValue}>
                            {this.renderTriggeredEvent()}
                          </span>
                        </div>
                      )}
                    <div style={styles.dataContainer}>
                      <span style={styles.dataLabel}>DELIVERY TIME</span>
                      <span style={styles.dataValue}>
                        {this.props.currentCommunication &&
                        this.props.currentCommunication.type === 'ONE_TIME'
                          ? this.getStartDate(true)
                          : this.props.currentCommunication &&
                            this.props.currentCommunication.type === 'RECURRING'
                          ? this.renderRecuringTime()
                          : this.renderDeliveryTime()}
                      </span>
                    </div>
                    {this.props.currentCommunication &&
                      this.props.currentCommunication.type === 'ONE_TIME' && (
                        <div style={styles.dataContainer}>
                          <span style={styles.dataLabel}>THROTTLING:</span>
                          <span style={styles.dataValue}>
                            {this.props.currentCommunication &&
                            this.props.currentCommunication.followThrottling ===
                              true
                              ? 'Follow'
                              : 'Ignore'}
                          </span>
                        </div>
                      )}
                    {this.props.currentCommunication &&
                      this.props.currentCommunication.type === 'RECURRING' && (
                        <div style={styles.dataContainer}></div>
                      )}
                  </div>
                  {this.props.currentCommunication &&
                    this.props.currentCommunication.type !== 'ONE_TIME' && (
                      <div style={styles.dataRow}>
                        <div style={styles.dataContainer}>
                          <span style={styles.dataLabel}>START DATE</span>
                          <span style={styles.dataValue}>
                            {this.getStartDate(true)}
                          </span>
                        </div>
                        <div style={styles.dataContainer}>
                          <span style={styles.dataLabel}>END DATE</span>
                          <span style={styles.dataValue}>
                            {this.getEndDate(true)}
                          </span>
                        </div>
                        <div style={styles.dataContainer}>
                          <span style={styles.dataLabel}>DND</span>
                          <span style={styles.dataValue} data-cy="follow-dnd">
                            {this.props?.currentCommunication?.channel ===
                            'IN_APP'
                              ? '-'
                              : this.props.currentCommunication &&
                                this.props.currentCommunication.followDnd ===
                                  true
                              ? 'Follow'
                              : 'Ignore'}
                          </span>
                        </div>
                      </div>
                    )}
                  {this.props.currentCommunication &&
                    this.props.currentCommunication.type !== 'ONE_TIME' && (
                      <div style={styles.dataRow}>
                        <div style={styles.dataContainer}>
                          <span style={styles.dataLabel}>FC</span>
                          <span style={styles.dataValue}>
                            {this.props?.currentCommunication?.channel ===
                            'IN_APP'
                              ? '-'
                              : this.props.currentCommunication &&
                                this.props.currentCommunication
                                  .followFrequencyCapping === true
                              ? 'Follow'
                              : 'Ignore'}
                          </span>
                        </div>
                        <div style={styles.dataContainer}>
                          <span style={styles.dataLabel}>QUEUEING</span>
                          <span style={styles.dataValue}>
                            {this.props.currentCommunication &&
                            this.props.currentCommunication.queueMinutes
                              ? this.renderQueueMinutes()
                              : this.props?.currentCommunication?.channel ===
                                'IN_APP'
                              ? '-'
                              : 'Do Not Queue'}
                          </span>
                        </div>
                        <div style={styles.dataContainer}>
                          <span style={styles.dataLabel}>THROTTLING:</span>
                          <span style={styles.dataValue}>
                            {this.props?.currentCommunication?.channel ===
                            'IN_APP'
                              ? '-'
                              : this.props.currentCommunication &&
                                this.props.currentCommunication
                                  .followThrottling === true
                              ? 'Follow'
                              : 'Ignore'}
                          </span>
                        </div>
                      </div>
                    )}
                  {!(
                    this.props.currentCommunication &&
                    this.props.currentCommunication.type !== 'ONE_TIME'
                  ) && (
                    <div style={styles.dataRow}>
                      <div style={styles.dataContainer}>
                        <span style={styles.dataLabel}>FC</span>
                        <span style={styles.dataValue}>
                          {this.props.currentCommunication &&
                          this.props.currentCommunication
                            .followFrequencyCapping === true
                            ? 'Follow'
                            : 'Ignore'}
                        </span>
                      </div>
                      <div style={styles.dataContainer}>
                        <span style={styles.dataLabel}>DND</span>
                        <span style={styles.dataValue} data-cy="follow-dnd">
                          {this.props.currentCommunication &&
                          this.props.currentCommunication.followDnd === true
                            ? 'Follow'
                            : 'Ignore'}
                        </span>
                      </div>
                      <div style={styles.dataContainer}>
                        <span style={styles.dataLabel}>QUEUEING</span>
                        <span style={styles.dataValue}>
                          {this.props.currentCommunication &&
                          this.props.currentCommunication.queueMinutes
                            ? this.renderQueueMinutes()
                            : 'Do Not Queue'}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Card>
          </div>
        )}

        {this.props.currentCommunication.type === 'TRANSACTIONAL' && (
          <div
            className="raman-paper-profile"
            style={{
              background: 'none',
              marginTop: 40,
              paddingBottom: 40,
              backgroundColor: 'white',
            }}
          >
            <div style={styles.infoBoxHeader}>
              <div style={styles.headTitleContainer}>
                <span>API Call Details to Send Messages to Users</span>
                {false && (
                  <div style={{ cursor: 'pointer' }}>
                    <i
                      className="fl-edit"
                      style={{
                        border: '1px solid',
                        borderRadius: '50%',
                        fontSize: '28px',
                        color: '#94969e',
                        marginLeft: 10,
                      }}
                    ></i>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                marginTop: 30,
                marginBottom: 30,
              }}
            >
              Once this campaign is launched on the {texts.brandName} dashboard,
              please call our{' '}
              <a href={texts.campaignApi} target={'blank'}>
                {' '}
                transactional campaign API{' '}
              </a>
              to trigger messages to users. Refer to our{' '}
              <a href={texts.campaignApi} target={'blank'}>
                {' '}
                documentation{' '}
              </a>
              documentation for more details on transactional campaigns. Here’s
              a sample cURL request for this campaign. In the code snippet
              below, make sure you replace: (1) &lt;Your API key&gt; with your{' '}
              <a href={`/product/${pid}/rest-api/view`} target={'blank'}>
                {' '}
                {texts.brandName} API Key{' '}
              </a>
              , and (2) token values with the relevant token values
            </div>
            <div
              style={{
                ...styles.dataRowFirst,
                marginLeft: 40,
                padding: 30,
                marginRight: 40,
                marginTop: 30,
                textAlign: 'left',
                direction: 'ltr',
                position: 'relative',
                backgroundColor: '#f4f4f6',
                borderRadius: '5px',
                whiteSpace: 'pre-line',
              }}
            >
              {apiCallCurlText}
            </div>
          </div>
        )}
        {this.props.currentCommunication.type === 'TRANSACTIONAL' && (
          <div
            className="raman-paper-profile"
            style={{
              background: 'none',
              marginTop: 40,
              backgroundColor: 'white',
            }}
          >
            <div>
              <div style={styles.infoBoxHeader}>
                <div style={styles.headTitleContainer}>
                  <span>Basic Info</span>
                  {false && (
                    <div style={{ cursor: 'pointer' }}>
                      <i
                        className="fl-edit"
                        style={{
                          border: '1px solid',
                          borderRadius: '50%',
                          fontSize: '28px',
                          color: '#94969e',
                          marginLeft: 10,
                        }}
                      ></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                ...styles.dataRowFirst,
                marginLeft: 40,
                marginTop: 30,
                paddingBottom: 40,
              }}
            >
              <div style={styles.dataContainer}>
                <span style={styles.dataLabel}>COMMUNICATION NAME</span>
                <span style={styles.dataValue}>
                  {this.props.currentCommunication &&
                  this.props.currentCommunication.name
                    ? this.props.currentCommunication.name
                    : '-'}
                </span>
              </div>
              <div style={styles.dataContainer}>
                <span style={styles.dataLabel}>CAMPAIGN TYPE</span>
                <span style={styles.dataValue}>
                  {this.renderCampaignType()}
                </span>
              </div>
            </div>
          </div>
        )}
        <div
          className="raman-paper-profile"
          style={{
            background: 'none',
            marginTop: 40,
            backgroundColor: 'white',
          }}
        >
          <Card>
            <div style={styles.infoBoxHeader}>
              <div style={styles.headTitleContainer}>
                <span>Message </span>
                {false && (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.goToStep('message')}
                  >
                    <i
                      className="fl-edit"
                      style={{
                        border: '1px solid',
                        borderRadius: '50%',
                        fontSize: '28px',
                        color: '#94969e',
                        marginLeft: 10,
                      }}
                    ></i>
                  </div>
                )}
              </div>
            </div>
            {this.props.loadingView || this.props.loadingSmsChannels ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  paddingTop: 50,
                  paddingBottom: 50,
                }}
              >
                <Spin indicator={loadingSpin} />
              </div>
            ) : (
              <React.Fragment>
                <div
                  style={{
                    borderBottom: '1px solid #e7e8e9',
                    width: '100%',
                    backgroundColor: '#FFFFFF',
                    padding: '0 15px',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {this.renderVariations()}
                </div>
                {this.props.currentCommunication.channel !== 'CUSTOM' ? (
                  <CommunicationPreviewWidgetComponent
                    currentCommunication={this.props.currentCommunication}
                    smsChannels={this.props.smsChannels}
                    customChannels={this.props.customChannels}
                    whatsAppTemplateObject={this.props.whatsAppTemplateObject}
                    index={this.state.variationIndex}
                    activeVariationIndex={this.state.activeVariationIndex}
                    productName={this.renderProductTitle()}
                  />
                ) : (
                  ''
                )}
                {this.props.currentCommunication.channel === 'CUSTOM' ? (
                  <div>{this.renderPreviewCustomChannel()}</div>
                ) : (
                  ''
                )}
              </React.Fragment>
            )}
          </Card>
        </div>
        <div
          style={{
            background: 'none',
            marginTop: 40,
            backgroundColor: 'white',
            marginBottom: 40,
          }}
        >
          <Card>
            <div style={{ ...styles.infoBoxHeader, flexDirection: 'row' }}>
              <div style={styles.headTitleContainer}>
                <span>Conversion Tracking</span>
                {false && (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.goToStep('conversion')}
                  >
                    <i
                      className="fl-edit"
                      style={{
                        border: '1px solid',
                        borderRadius: '50%',
                        fontSize: '28px',
                        color: '#94969e',
                        marginLeft: 10,
                      }}
                    ></i>
                  </div>
                )}
              </div>
            </div>
            {this.props.loadingView ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  paddingTop: 50,
                  paddingBottom: 50,
                }}
              >
                <Spin indicator={loadingSpin} />
              </div>
            ) : (
              <div style={styles.dataRowContainer}>
                <div style={styles.dataRowFirst}>
                  <div style={styles.dataContainer}>
                    <span style={styles.dataLabel}>CONVERSION TRACKING</span>
                    <span
                      style={styles.dataValue}
                      data-cy="conversion-tracking"
                    >
                      {this.props.currentCommunication &&
                      this.props.currentCommunication.conversion
                        ? 'on'
                        : 'off'}
                    </span>
                  </div>
                  <div style={styles.dataContainer}>
                    {this.props.currentCommunication &&
                      this.props.currentCommunication.conversion && (
                        <span style={styles.dataLabel}>CONVERSION EVENT</span>
                      )}
                    {this.props.currentCommunication &&
                      this.props.currentCommunication.conversion &&
                      this.props.currentCommunication.conversionEvent && (
                        <span
                          style={styles.dataValue}
                          data-cy="conversion-event"
                        >
                          {this.renderEventName(
                            this.props.currentCommunication.conversionEvent
                          )}
                        </span>
                      )}
                  </div>
                  <div style={styles.dataContainer}>
                    {this.props.currentCommunication &&
                      this.props.currentCommunication.conversion && (
                        <span style={styles.dataLabel}>
                          CONVERSION DEADLINE:
                        </span>
                      )}
                    {this.props.currentCommunication &&
                      this.props.currentCommunication.conversion &&
                      this.props.currentCommunication.conversionEvent && (
                        <span style={styles.dataValue}>
                          {this.renderConversionTime()}
                        </span>
                      )}
                  </div>
                </div>
                <div style={styles.dataRow}>
                  <div style={styles.dataContainer}>
                    {this.props.currentCommunication &&
                      this.props.currentCommunication.conversion && (
                        <span style={styles.dataLabel}>CONTROL GROUP</span>
                      )}
                    {this.props.currentCommunication &&
                      this.props.currentCommunication.conversion && (
                        <span style={styles.dataValue}>
                          {this.props.currentCommunication.controlGroup
                            ? 'Enabled ' +
                              '(' +
                              this.props.currentCommunication
                                .controlGroupPercentage +
                              '%)'
                            : 'Disabled'}
                        </span>
                      )}
                  </div>
                  <div style={styles.dataContainer}>
                    {this.props.currentCommunication &&
                      this.props.currentCommunication
                        .winnerMechanismEnabled && (
                        <span style={styles.dataLabel}>WINNER MECHANISM</span>
                      )}
                    {this.props.currentCommunication &&
                      this.props.currentCommunication
                        .winnerMechanismEnabled && (
                        <span style={styles.dataValue}>
                          {this.props.currentCommunication
                            .winnerMechanismEnabled
                            ? 'Enabled '
                            : 'Disabled'}
                        </span>
                      )}
                  </div>
                  <div style={styles.dataContainer}></div>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
    );
  };

  isLaunchDisabled = () => {
    return (
      this.props.currentCommunication &&
      this.props.currentCommunication.status &&
      (this.props.currentCommunication.status === 'UPCOMING' ||
        this.props.currentCommunication.status === 'RUNNING' ||
        this.props.currentCommunication.status === 'ENDED')
    );
  };
  isEdited = () => {
    return false;
  };
  renderFooter = () => {
    return (
      <CommunicationFooterComponent
        step={'preview'}
        isEdited={this.isEdited}
        loadingNext={this.props.loadingLaunch || this.props.loadingView}
        steps={this.props.journey && this.props.journeySteps}
        journey={this.props.journey}
        readOnly={this.props.readOnly}
        handleClickNext={() => this.handleClickNext()}
        disableLaunchButton={this.isLaunchDisabled()}
        channel={this.channel}
      />
    );
  };

  renderSendTo = () => {
    let str = '';
    let result = '';
    let notResult = '';
    if (
      this.props.segments &&
      this.props.currentCommunication &&
      this.props.currentCommunication.segments &&
      this.props.currentCommunication.segments.length > 0
    ) {
      this.props.currentCommunication.segments.forEach((item, index) => {
        const foundItem = lodashFind(this.props.segments, (o) => o.id === item);
        if (
          foundItem &&
          foundItem.name &&
          index < this.props.currentCommunication.segments.length - 1
        ) {
          result += foundItem.name + ', ';
        } else if (foundItem && foundItem.name) {
          result += foundItem.name;
        }
      });
    }
    if (
      this.props.segments &&
      this.props.currentCommunication &&
      this.props.currentCommunication.ignoreSegments &&
      this.props.currentCommunication.ignoreSegments.length > 0
    ) {
      this.props.currentCommunication.ignoreSegments.forEach((item, index) => {
        const foundItem = lodashFind(this.props.segments, (o) => o.id === item);
        if (
          foundItem &&
          foundItem.name &&
          index < this.props.currentCommunication.ignoreSegments.length - 1
        ) {
          notResult += foundItem.name + ', ';
        } else if (foundItem && foundItem.name) {
          notResult += foundItem.name;
        }
      });
    }
    if (result) {
      str +=
        'Users in' +
        (this.props.currentCommunication.segments.length > 1
          ? ' (' + this.props.currentCommunication.segmentsConjunction + ')'
          : '') +
        ': ' +
        result;
    }
    if (notResult) {
      str +=
        (isEmpty(str) ? '' : ', and ') +
        'Users Not in' +
        (this.props.currentCommunication.ignoreSegments.length > 1
          ? ' (' +
            this.props.currentCommunication.ignoreSegmentsConjunction +
            ')'
          : '') +
        ': ' +
        notResult;
    }
    if (!result && !notResult) {
      str = 'All Users (No Specific Segment)';
    }
    return str;
  };

  renderSteps = () => {
    return (
      <CommunicationStepsComponent
        isEdited={this.isEdited}
        step={'preview'}
        creationStep={
          this.props.currentCommunication &&
          this.props.currentCommunication.creationStep
            ? this.props.currentCommunication.creationStep
            : null
        }
        steps={
          this.props.currentCommunication.type === 'TRANSACTIONAL'
            ? transactionalSteps
            : this.props.journey
            ? this.props.journeySteps
            : null
        }
        journey={this.props.journey}
        goToStepOnJourney={this.props.goToStepOnJourney}
        channel={this.channel}
      />
    );
  };

  render() {
    return (
      <div style={{ padding: 30, position: 'relative' }}>
        {this.renderSteps()}
        {this.renderPreview()}
        {this.renderFooter()}
      </div>
    );
  }
}

export default CommunicationPreviewComponent;
