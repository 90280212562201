/* eslint-disable complexity */
import {
  PRODUCT_SEGMENT_SAVE_CLICKED,
  PRODUCT_SEGMENT_RESET_COMPLETED,
  PRODUCT_SEGMENT_RESET_CLICKED,
  PRODUCT_SEGMENT_LIST_FETCH,
  PRODUCT_SEGMENT_LIST_FETCH_SUCCESS,
  PRODUCT_SEGMENT_LIST_RESET,
  PRODUCT_SEGMENT_CHANGE_CURRENT,
  PRODUCT_SEGMENT_UPDATE_FETCH,
  PRODUCT_SEGMENT_UPDATE_FETCH_SUCCESS,
  PRODUCT_SEGMENT_UPDATE_FETCH_FAIL,
  PRODUCT_SEGMENT_CREATE_FETCH,
  PRODUCT_SEGMENT_CREATE_FETCH_FAIL,
  PRODUCT_SEGMENT_CREATE_FETCH_SUCCESS,
  PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH,
  PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH_FAIL,
  PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH_SUCCESS,
  PRODUCT_SEGMENT_PAGE_CHANGE_CURRENT,
  PRODUCT_SEGMENT_VIEW_FETCH_SUCCESS,
  PRODUCT_SEGMENT_VIEW_FETCH,
  PRODUCT_SEGMENT_VIEW_FETCH_FAIL,
  PRODUCT_SEGMENT_COPY_FETCH,
  PRODUCT_SEGMENT_COPY_FETCH_SUCCESS,
  PRODUCT_SEGMENT_COPY_FETCH_FAIL,
  PRODUCT_SEGMENT_LIST_EVENTS_FETCH_SUCCESS,
  PRODUCT_SEGMENT_LIST_EVENTS_FETCH_FAIL,
  PRODUCT_SEGMENT_LIST_EVENTS_FETCH,
  PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH_SUCCESS,
  PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH_FAIL,
  PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH,
  PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH,
  PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH_SUCCESS,
  PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH_FAIL,
  PRODUCT_SEGMENT_REPORT_FETCH,
  PRODUCT_SEGMENT_REPORT_FETCH_SUCCESS,
  PRODUCT_SEGMENT_REPORT_FETCH_FAIL,
  PRODUCT_SEGMENT_REPORT_VIEW_FETCH_SUCCESS,
  PRODUCT_SEGMENT_SIZE_REPORT_FETCH,
  PRODUCT_SEGMENT_SIZE_REPORT_FETCH_SUCCESS,
  PRODUCT_SEGMENT_SIZE_REPORT_FETCH_FAIL,
  PRODUCT_SEGMENT_ARCHIVE_FETCH,
  PRODUCT_SEGMENT_ARCHIVE_FETCH_FAIL,
  PRODUCT_SEGMENT_ARCHIVE_FETCH_SUCCESS,
  PRODUCT_SEGMENT_ACTIVATE_FETCH,
  PRODUCT_SEGMENT_ACTIVATE_FETCH_FAIL,
  PRODUCT_SEGMENT_ACTIVATE_FETCH_SUCCESS,
  PRODUCT_SEGMENT_FILE_UPLOAD_FETCH_FAIL,
  PRODUCT_SEGMENT_FILE_UPLOAD_FETCH_SUCCESS,
  PRODUCT_SEGMENT_FILE_UPLOAD_FETCH,
  PRODUCT_SEGMENT_FILE_UPLOAD_RESET,
  PRODUCT_SEGMENT_STATIC_CREATE_TOGGLE,
  PRODUCT_SEGMENT_DELETE_FETCH,
  PRODUCT_SEGMENT_DELETE_FETCH_FAIL,
  PRODUCT_SEGMENT_DELETE_FETCH_SUCCESS,
  PRODUCT_SEGMENT_SEARCH_LIST_FETCH,
  PRODUCT_SEGMENT_SEARCH_LIST_FETCH_SUCCESS,
  PRODUCT_SEGMENT_SEARCH_LIST_FETCH_FAIL,
  PRODUCT_SEGMENT_LIST_FETCH_FAIL,
  PRODUCT_SEGMENT_REPORT_VIEW_FETCH,
  PRODUCT_SEGMENT_REPORT_VIEW_FETCH_FAIL,
  CLEAR_SEGMENT_ERROR,
  PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH,
  PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH_FAIL,
  PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH_SUCCESS,
  PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH,
  PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH_FAIL,
  PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH_SUCCESS,
  PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH,
  PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH_FAIL,
  PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH_SUCCESS,
  PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH_FAIL,
  PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH_SUCCESS,
  PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH,
} from './ProductSegmentConstants';

const initialState = {
  page: {},
  pageRequest: {},
  errors: [],
  currentProductSegment: {},
  currentReport: {},
  currentSegmentId: null,
  attributes: [],
  events: [],
  deviceAttributes: [],
  loadingSegmentList: false,
  loadingSegmentSearchList: false,
  loadingOverviewList: false,
  loadingReportView: false,

  fileUploadOpen: false,
  staticCreateOpen: false,

  uploadedFile: null,

  saveClicked: false,
  resetClicked: false,

  errorsUpdate: [],
  errorsCopy: [],
  errorsSegmentSubmit: [],
  errorsReport: [],
  errorsUploadFile: [],
  errorsDelete: [],

  loadingUploadFile: false,
  loadingDeviceAttributes: false,
  loadingAttributes: false,
  loadingEvents: false,
  loadingReport: false,
  loadingUpdate: false,
  loadingUpdateSubmit: false,
  loadingCopy: false,
  loadingCreate: false,
  loadingActivate: false,
  loadingArchive: false,
  archiveSuccess: false,
  activeSuccess: false,
  loadingSegmentSizeReport: false,
  loadingRecommendUserAttributes: false,
  loadingRecommendEventAttributes: false,
  loadingRecommendDeviceAttributes: false,

  loadingDelete: false,
  segmentSizeReport: [],
  recommendUserAttributes: {},
  recommendEventAttributes: {},
  recommendDeviceAttributes: {},

  loadingSourceProducts: false,
  sourceProducts: [],
};

export default function ProductSegmentStateReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CLEAR_SEGMENT_ERROR:
      return {
        ...state,
        errorsSegmentSubmit: {
          ...state.errorsSegmentSubmit,
          errors: action.payload,
        },
      };
    case PRODUCT_SEGMENT_SAVE_CLICKED:
      return { ...state, saveClicked: true };
    case PRODUCT_SEGMENT_RESET_CLICKED:
      return { ...state, resetClicked: true };
    case PRODUCT_SEGMENT_RESET_COMPLETED:
      return { ...state, resetClicked: false };
    case PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH_SUCCESS:
      return {
        ...state,
        attributes: action.payload,
        loadingAttributes: false,
      };
    case PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH:
      return { ...state, loadingAttributes: true };
    case PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH_FAIL:
      return { ...state, loadingAttributes: false };
    case PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH_SUCCESS:
      return {
        ...state,
        deviceAttributes: action.payload,
        loadingDeviceAttributes: false,
      };
    case PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH:
      return { ...state, loadingDeviceAttributes: true };
    case PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH_FAIL:
      return { ...state, loadingDeviceAttributes: false };
    case PRODUCT_SEGMENT_LIST_EVENTS_FETCH_SUCCESS:
      return { ...state, events: action.payload, loadingEvents: false };
    case PRODUCT_SEGMENT_LIST_EVENTS_FETCH_FAIL:
      return { ...state, loadingEvents: false };
    case PRODUCT_SEGMENT_LIST_EVENTS_FETCH:
      return { ...state, loadingEvents: true };

    case PRODUCT_SEGMENT_LIST_FETCH:
      return {
        ...state,
        pageRequest: action.payload,
        loadingSegmentList: true,
      };
    case PRODUCT_SEGMENT_LIST_FETCH_SUCCESS:
      return {
        ...state,
        page: action.payload,
        loadingSegmentList: false,
      };
    case PRODUCT_SEGMENT_LIST_FETCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loadingSegmentList: false,
      };

    case PRODUCT_SEGMENT_SEARCH_LIST_FETCH:
      return {
        ...state,
        pageRequest: action.payload,
        loadingSegmentSearchList: true,
      };
    case PRODUCT_SEGMENT_SEARCH_LIST_FETCH_SUCCESS:
      return {
        ...state,
        page: action.payload,
        loadingSegmentSearchList: false,
      };
    case PRODUCT_SEGMENT_SEARCH_LIST_FETCH_FAIL:
      return {
        ...state,
        errors: action.payload.errors,
        loadingSegmentSearchList: false,
      };

    case PRODUCT_SEGMENT_LIST_RESET:
      return { ...state, page: {} };
    case PRODUCT_SEGMENT_CREATE_FETCH_FAIL:
      return {
        ...state,
        errorsSegmentSubmit: action.payload,
        loadingCreate: false,
      };
    case PRODUCT_SEGMENT_CREATE_FETCH:
      return {
        ...state,
        loadingCreate: true,
        saveClicked: false,
        errorsSegmentSubmit: {},
      };
    case PRODUCT_SEGMENT_CREATE_FETCH_SUCCESS:
      return { ...state, loadingCreate: false };
    case PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH_FAIL:
      return {
        ...state,
        errorsSegmentSubmit: action.payload,
        loadingUpdateSubmit: false,
      };
    case PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH_SUCCESS:
      return { ...state, loadingUpdateSubmit: false };
    case PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH:
      return {
        ...state,
        loadingUpdateSubmit: true,
        saveClicked: false,
        errorsSegmentSubmit: {},
      };
    case PRODUCT_SEGMENT_CHANGE_CURRENT:
      return {
        ...state,
        currentProductSegment: action.payload,
        errors: [],
        currentReport: {},
        currentSizeReport: {},
      };
    case PRODUCT_SEGMENT_UPDATE_FETCH_SUCCESS:
      return {
        ...state,
        currentProductSegment: action.payload,
        loadingUpdate: false,
      };
    case PRODUCT_SEGMENT_UPDATE_FETCH:
      return { ...state, loadingUpdate: true, errorsUpdate: [] };
    case PRODUCT_SEGMENT_UPDATE_FETCH_FAIL:
      return {
        ...state,
        loadingUpdate: false,
        errorsUpdate: action.payload.errors,
      };
    case PRODUCT_SEGMENT_COPY_FETCH_SUCCESS:
      return {
        ...state,
        currentProductSegment: action.payload,
        loadingCopy: false,
      };
    case PRODUCT_SEGMENT_COPY_FETCH:
      return { ...state, loadingCopy: true, errorsCopy: [] };
    case PRODUCT_SEGMENT_COPY_FETCH_FAIL:
      return {
        ...state,
        loadingCopy: false,
        errorsCopy: action.payload.errors,
      };
    case PRODUCT_SEGMENT_DELETE_FETCH_SUCCESS:
      return { ...state, loadingDelete: false };
    case PRODUCT_SEGMENT_DELETE_FETCH:
      return { ...state, loadingDelete: true, errorsDelete: [] };
    case PRODUCT_SEGMENT_DELETE_FETCH_FAIL:
      return {
        ...state,
        loadingDelete: false,
        errorsDelete: action.payload.errors,
      };
    case PRODUCT_SEGMENT_VIEW_FETCH_SUCCESS:
      return {
        ...state,
        currentProductSegment: action.payload,
        loadingOverviewList: false,
      };
    case PRODUCT_SEGMENT_VIEW_FETCH_FAIL:
      return { ...state, loadingOverviewList: false };
    case PRODUCT_SEGMENT_VIEW_FETCH:
      return { ...state, loadingOverviewList: true };
    case PRODUCT_SEGMENT_REPORT_FETCH_SUCCESS:
      return {
        ...state,
        currentReport: action.payload,
        loadingReport: false,
        errorsSegmentSubmit: {},
      };
    case PRODUCT_SEGMENT_REPORT_FETCH:
      return { ...state, errorsReport: [], loadingReport: true };
    case PRODUCT_SEGMENT_REPORT_FETCH_FAIL:
      return {
        ...state,
        loadingReport: false,
        errorsSegmentSubmit: action.payload,
      };
    case PRODUCT_SEGMENT_REPORT_VIEW_FETCH:
      return {
        ...state,
        loadingReportView: true,
        currentSegmentId: action.payload,
      };
    case PRODUCT_SEGMENT_REPORT_VIEW_FETCH_SUCCESS:
      return {
        ...state,
        currentReport: action.payload,
        loadingReportView: false,
      };
    case PRODUCT_SEGMENT_REPORT_VIEW_FETCH_FAIL:
      return {
        ...state,
        errorReportView: action.payload,
        loadingReportView: false,
      };
    case PRODUCT_SEGMENT_PAGE_CHANGE_CURRENT:
      return { ...state, pageRequest: action.payload };
    case PRODUCT_SEGMENT_SIZE_REPORT_FETCH:
      return { ...state, loadingSegmentSizeReport: true };
    case PRODUCT_SEGMENT_SIZE_REPORT_FETCH_SUCCESS:
      return {
        ...state,
        loadingSegmentSizeReport: false,
        segmentSizeReport: action.payload,
      };
    case PRODUCT_SEGMENT_SIZE_REPORT_FETCH_FAIL:
      return { ...state, loadingSegmentSizeReport: false };
    case PRODUCT_SEGMENT_ARCHIVE_FETCH:
      return { ...state, loadingArchive: true };
    case PRODUCT_SEGMENT_ARCHIVE_FETCH_SUCCESS:
      return {
        ...state,
        loadingArchive: false,
        archiveSuccess: !state.archiveSuccess,
      };
    case PRODUCT_SEGMENT_ARCHIVE_FETCH_FAIL:
      return {
        ...state,
        loadingArchive: false,
        errorsArchive: action.payload.errors,
      };
    case PRODUCT_SEGMENT_ACTIVATE_FETCH:
      return { ...state, loadingActivate: true };
    case PRODUCT_SEGMENT_ACTIVATE_FETCH_FAIL:
      return { ...state, loadingActivate: false };
    case PRODUCT_SEGMENT_ACTIVATE_FETCH_SUCCESS:
      return {
        ...state,
        loadingActivate: false,
        activeSuccess: !state.activeSuccess,
      };
    case PRODUCT_SEGMENT_STATIC_CREATE_TOGGLE:
      return { ...state, staticCreateOpen: action.payload };
    case PRODUCT_SEGMENT_FILE_UPLOAD_FETCH:
      return { ...state, loadingUploadFile: true };
    case PRODUCT_SEGMENT_FILE_UPLOAD_FETCH_FAIL:
      return {
        ...state,
        loadingUploadFile: false,
        errorsUploadFile: action.payload,
      };
    case PRODUCT_SEGMENT_FILE_UPLOAD_FETCH_SUCCESS:
      return {
        ...state,
        loadingUploadFile: false,
        uploadedFile: action.payload,
      };
    case PRODUCT_SEGMENT_FILE_UPLOAD_RESET:
      return { ...state, loadingUploadFile: false, uploadedFile: null };
    case PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH_SUCCESS:
      return {
        ...state,
        loadingRecommendUserAttributes: false,
        recommendUserAttributes: Object.assign(state.recommendUserAttributes, {
          [action.payload.userAttributeId]: action.payload.response,
        }),
      };
    case PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH:
      return { ...state, loadingRecommendUserAttributes: true };
    case PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH_FAIL:
      return {
        ...state,
        loadingRecommendUserAttributes: false,
        errorsUpdate: action.payload.errors,
      };
    case PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH_SUCCESS:
      return {
        ...state,
        loadingRecommendEventAttributes: false,
        recommendEventAttributes: Object.assign(
          state.recommendEventAttributes,
          {
            [action.payload.eventAttributeId]: action.payload.response,
          }
        ),
      };
    case PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH:
      return { ...state, loadingRecommendEventAttributes: true };
    case PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH_FAIL:
      return {
        ...state,
        loadingRecommendEventAttributes: false,
        errorsUpdate: action.payload.errors,
      };
    case PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH_SUCCESS:
      return {
        ...state,
        loadingRecommendDeviceAttributes: false,
        recommendDeviceAttributes: Object.assign(
          state.recommendDeviceAttributes,
          {
            [action.payload.deviceAttributeId]: action.payload.response,
          }
        ),
      };
    case PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH:
      return { ...state, loadingRecommendDeviceAttributes: true };
    case PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH_FAIL:
      return {
        ...state,
        loadingRecommendDeviceAttributes: false,
        errorsUpdate: action.payload.errors,
      };
    case PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH:
      return { ...state, loadingSourceProducts: true };
    case PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH_FAIL:
      return { ...state, loadingSourceProducts: false };
    case PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH_SUCCESS:
      return {
        ...state,
        sourceProducts: action.payload,
        loadingSourceProducts: false,
      };
    default:
      return state;
  }
}
