import React, { useState, useEffect } from 'react';
import { isNull } from 'lodash';
import { notification } from 'antd';

import Modal from 'antd/lib/modal/Modal';
import ProductSegmentPredicateComponent from '@Modules/insight/productSegment/component/ProductSegmentPredicateComponent';

function ModalEventFilterSelector(props) {
  const [predicates, setPredicates] = useState(null);

  useEffect(() => {
    const currentPredicates = Object.assign({}, props.currentPredicate);
    currentPredicates.eventPredicates =
      currentPredicates.predicates || currentPredicates.attributeFilters || [];
    currentPredicates.eventConjunction = currentPredicates.conjunction || 'AND';
    currentPredicates.type = 'BEHAVIOUR';
    setPredicates(currentPredicates);
  }, [props?.currentPredicate?.event]);

  return (
    <Modal
      width={1080}
      title={'Event Filter'}
      centered
      visible={props.isModalVisible}
      onOk={() => {
        const currentPredicates = Object.assign({}, predicates);
        currentPredicates.conjunction =
          currentPredicates.eventConjunction || 'AND';

        currentPredicates.predicates = currentPredicates.eventPredicates.filter(
          (item) => item.attribute
        );

        const isValid = currentPredicates.predicates.every((item) => {
          switch (item.operator) {
            case 'IS_NOT_EMPTY':
              return true;
            case 'EQUAL_TO':
            case 'ONE_OF':
            case 'STARTS_WITH':
            case 'ENDS_WITH':
            case 'CONTAINS':
            case 'MATCHES_REGEX':
              return (
                'stringValue' in item &&
                !isNull(item.stringValue) &&
                item.stringValue !== ''
              );
            case 'BETWEEN':
              switch (item.relativeDateType) {
                case 'DATE':
                  return (
                    (!isNull(item.greaterThan) && item.greaterThan !== '') ||
                    (!isNull(item.lessThan) && item.lessThan !== '')
                  );
                case 'LAST-LAST':
                case 'NEXT-NEXT':
                case 'LAST-NEXT':
                  if (
                    !item.relativeDateDurationFrom &&
                    !item.relativeDateDurationTo
                  ) {
                    return false;
                  }

                  if (
                    item.relativeDateDurationFrom &&
                    (!item.relativeDateValueFrom ||
                      item.relativeDateValueFrom === '' ||
                      isNull(item.relativeDateValueFrom))
                  ) {
                    return false;
                  }
                  if (
                    item.relativeDateDurationTo &&
                    (!item.relativeDateValueTo ||
                      item.relativeDateValueTo === '' ||
                      isNull(item.relativeDateValueTo))
                  ) {
                    return false;
                  }

                  if (
                    !item.relativeDateDurationFrom &&
                    item.relativeDateValueFrom
                  ) {
                    return false;
                  }

                  if (
                    !item.relativeDateDurationTo &&
                    item.relativeDateValueTo
                  ) {
                    return false;
                  }

                  return true;
                default:
                  return (
                    (!isNull(item.greaterThan) && item.greaterThan !== '') ||
                    (!isNull(item.lessThan) && item.lessThan !== '')
                  );
              }
            default:
              break;
          }
        });

        if (!isValid) {
          notification.error({
            key: 'Error',
            description: 'Please fill all fields or remove unused filters',
            placement: 'bottomRight',
          });
        } else {
          props.onOkHandler(currentPredicates);
        }
      }}
      onCancel={props.onCancelHandler}
    >
      <div>
        <ProductSegmentPredicateComponent
          isFunnel={true}
          deepInIntegration={props.deepInIntegration}
          selectEventDisabled={true}
          type="HAS_DONE_EVENT"
          predicate={predicates}
          events={props.events}
          handleChangePredicate={(changedPredicate) => {
            setPredicates(changedPredicate);
          }}
          fetchRecommendEventAttributes={props.fetchRecommendEventAttributes}
          recommendEventAttributes={props.recommendEventAttributes}
        />
      </div>
    </Modal>
  );
}

export default ModalEventFilterSelector;
