import React from 'react';
import './style.less';

const StripedBox = ({ rows, cols, title, bg }) => {
  return (
    <div className="root" style={{ background: bg ?? '#F8F8F8' }}>
      {title ? <div className="title">{title}</div> : null}
      {rows?.map((ele, idx) => {
        return (
          <div
            key={idx}
            className={`${idx !== rows.length - 1 ? 'rowBorder' : ''} row`}
          >
            {ele?.columns?.map((col, index) => (
              <div key={index} style={{ ...cols[index] }}>
                {col}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default StripedBox;
