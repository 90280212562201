import React from 'react';
import moment from 'moment';
import { isEmpty, find as lodashFind } from 'lodash';
import { numberWithCommas } from '../../../../utils/RenderUtils';
import { viewDateTime } from '../../../../utils/DateUtils';
import { HashBase64 } from '../../../../component/utils/HashBase64';
import Card from '@Utils/Card';

class UserAttrComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const first = this.props.pathname.split('view/')[1];
    const encodedUserId = first.split('/attributes')[0];
    const userId = HashBase64().decode(encodedUserId);
    this.props.insightProductUserViewFetch(userId);
    this.props.insightProductUserListAttributeFetch();
  }

  renderItem = (item) => {
    if (this.props.user && this.props.user[item]) {
      return this.props.user[item];
    }
    return '-';
  };

  renderSystemAttributes = () => {
    return (
      <Card
        loading={this.props.userLoading || this.props.attributesListLoading}
      >
        <>
          <div
            style={{
              padding: 16,
              borderBottom: 'solid 2px #f4f9ff',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <div style={{ fontSize: 16, color: 'black', fontWeight: '600' }}>
              System Attributes
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
            }}
          >
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                  textTransform: 'uppercase',
                }}
              >
                Gender
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('gender')}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                  textTransform: 'uppercase',
                }}
              >
                Date of Birth
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.props.user && this.props.user.birthday
                  ? moment(this.props.user.birthday).format('YYYY-MM-DD')
                  : '-'}
              </span>
            </div>
            <div style={{ display: 'flex', flex: 1 }}>
              <span
                style={{
                  color: '#707070',
                  fontSize: 12,
                  marginRight: 20,
                  textTransform: 'uppercase',
                }}
              >
                Company
              </span>
              <span style={{ color: '#1c1c1c', fontSize: 12 }}>
                {this.renderItem('company')}
              </span>
            </div>
          </div>
        </>
      </Card>
    );
  };

  renderCustomAttributeValue = (item) => {
    let result = '-';
    if (this.props.user) {
      if (item.type === 'STRING') {
        result = this.props.user.attributes[item.name]
          ? this.props.user.attributes[item.name]
          : '-';
      } else if (item.type === 'DATE') {
        result = this.props.user.attributes[item.name]
          ? viewDateTime(this.props.user.attributes[item.name])
          : '-';
      } else if (item.type === 'NUMERIC') {
        result = numberWithCommas(this.props.user.attributes[item.name]);
      } else if (item.type === 'BOOLEAN' || item.type === 'BOOL') {
        result = this.props.user.attributes[item.name] ? 'True' : 'False';
      } else if (item.type === 'ARRAY' || item.type === 'OBJECT') {
        result = JSON.stringify(this.props.user.attributes[item.name]);
      }
    }
    return result;
  };

  renderCustomAttributeItem = (item) => {
    let result = [];
    if (item == -1) {
      result.push(<div style={{ display: 'flex', flex: 1 }}></div>);
    } else if (item) {
      const fountItem = lodashFind(
        this.props.attributesList,
        (o) => o.name === item
      );
      if (fountItem) {
        result.push(
          <div style={{ display: 'flex', flex: 1 }}>
            <span style={{ color: '#707070', fontSize: 12, marginRight: 20 }}>
              {fountItem.title}:
            </span>
            <span style={{ color: '#1c1c1c', fontSize: 12 }}>
              {this.renderCustomAttributeValue(fountItem)}
            </span>
          </div>
        );
      }
    }
    return result;
  };

  renderCustomAttributesItems = () => {
    let result = [];
    if (
      this.props.user &&
      this.props.user.attributes &&
      !isEmpty(this.props.user.attributes) &&
      this.props.attributesList &&
      Array.isArray(this.props.attributesList) &&
      this.props.attributesList.length > 0
    ) {
      const customAttributes = Object.keys(this.props.user.attributes);
      let i = 0;
      let j = customAttributes.length;
      let temparray = [];
      const chunk = 3;
      for (i = 0, j = customAttributes.length; i < j; i += chunk) {
        temparray = customAttributes.slice(i, i + chunk);
        if (temparray.length === 1) {
          temparray.push('-1');
        } else if (temparray.length === 2) {
          temparray.push('-1', '-1');
        }
        result.push(
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              // textTransform: 'uppercase',
            }}
          >
            {this.renderCustomAttributeItem(temparray[0])}
            {this.renderCustomAttributeItem(temparray[1])}
            {this.renderCustomAttributeItem(temparray[2])}
          </div>
        );
      }
    } else {
      result.push(
        <div
          style={{
            color: '#3d404e',
            textAlign: 'center',
            fontSize: 18,
            fontWeight: '500',
            padding: 40,
          }}
        >
          No Data Available
        </div>
      );
    }
    return result;
  };

  renderCustomAttributes = () => {
    return (
      <Card
        styles={{
          marginTop: 20,
        }}
        loading={this.props.userLoading || this.props.attributesListLoading}
      >
        <>
          <div
            style={{
              padding: 16,
              borderBottom: 'solid 2px #f4f9ff',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <div style={{ fontSize: 16, color: 'black', fontWeight: '600' }}>
              Custom Attributes
            </div>
          </div>
          {this.renderCustomAttributesItems()}
        </>
      </Card>
    );
  };

  render() {
    return (
      <div>
        {this.renderSystemAttributes()}
        {this.renderCustomAttributes()}
      </div>
    );
  }
}
export default UserAttrComponent;
