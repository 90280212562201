import {
  CONFIGURATION_CHANGE_CURRENT,
  CONFIGURATION_UPDATE_FETCH,
  CONFIGURATION_UPDATE_FETCH_SUCCESS,
  CONFIGURATION_UPDATE_FETCH_FAIL,
  CONFIGURATION_UPDATE_SUBMIT_FETCH_FAIL,
  CONFIGURATION_VIEW_FETCH_SUCCESS,
  CONFIGURATION_LIST_ATTRIBUTES_FETCH_FAIL,
  CONFIGURATION_LIST_ATTRIBUTES_FETCH,
  CONFIGURATION_LIST_ATTRIBUTES_FETCH_SUCCESS,
} from './ConfigurationConstants';

const initialState = {
  errors: [],
  currentConfiguration: {},
  loadingUpdate: false,
  attributes: [],
  loadingAttributes: false,
};

export default function ConfigurationStateReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CONFIGURATION_UPDATE_SUBMIT_FETCH_FAIL:
      return { ...state, errors: action.payload.errors };
    case CONFIGURATION_CHANGE_CURRENT:
      return { ...state, currentConfiguration: action.payload, errors: [] };
    case CONFIGURATION_UPDATE_FETCH:
      return { ...state, loadingUpdate: true };
    case CONFIGURATION_UPDATE_FETCH_SUCCESS:
      return {
        ...state,
        currentConfiguration: action.payload,
        loadingUpdate: false,
      };
    case CONFIGURATION_UPDATE_FETCH_FAIL:
      return { ...state, loadingUpdate: false };
    case CONFIGURATION_VIEW_FETCH_SUCCESS:
      return { ...state, currentConfiguration: action.payload };
    case CONFIGURATION_LIST_ATTRIBUTES_FETCH_SUCCESS:
      return {
        ...state,
        attributes: action.payload,
        loadingAttributes: false,
      };
    case CONFIGURATION_LIST_ATTRIBUTES_FETCH:
      return { ...state, loadingAttributes: true };
    case CONFIGURATION_LIST_ATTRIBUTES_FETCH_FAIL:
      return { ...state, loadingAttributes: false };
    default:
      return state;
  }
}
