import React from 'react';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import { history } from '../../../../redux/store';
import {
  AuthorityProvider,
  getProductId,
  getSegmentType,
  hasAnyAuthority,
} from '../../../../utils/AuthorityProvider';
import { readableDateTime } from '../../../../utils/DateUtils';
import {
  numberWithCommas,
  renderLowerFont,
  showMessageError,
} from '../../../../utils/RenderUtils';
import {
  Input,
  Select,
  Modal,
  Button,
  Upload,
  Switch,
  Spin,
  Form,
  message,
} from 'antd';
import { isEmpty, isNil, find as lodashFind } from 'lodash';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import liveSegmentBlack from '../../../../theme/images/icons/Live_segment_black.svg';
import styles from './ProductSegmentStyle';
import segmentCsv from '../../../../theme/icons/segmentCsv.svg';
import segmentStatic from '../../../../theme/icons/segmentStatic.svg';
import { LoadingOutlined } from '@ant-design/icons';
import { errorHandling } from '../../../../utils/FormUtils';
import { fileFormatChecker } from '../../../../utils/FileFormatChecker';
import CsvButton from '@Component/utils/CsvExport/CsvButton';
import texts from '../../../../utils/texts';
import vars from '@Theme/styles/vars.js';
import Card from '@Utils/Card';
import { featureAccessCheckCSVExport } from '@Utils/AccessManagement';
class ProductSegmentListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.segmentType = getSegmentType(this.props.pathname);
    this.state = {
      fileUploadOpen: false,
      csvFileFormatError: false,
      name: '',
      currentSegment: {
        test: false,
        name: null,
        type: 'STATIC_CSV',
        staticAttribute: null, // email, phone
      },
    };
  }

  componentDidMount() {
    let types = ['DYNAMIC'];
    if (this.segmentType === 'STATIC') {
      types = ['STATIC', 'STATIC_CSV'];
    }
    this.props.productSegmentListReset();
    this.props.productSegmentListFetch({
      direction: 'DESC',
      order: 'modifiedDate',
      name: '',
      page: 0,
      status: ['PROCESSING', 'ENABLED'],
      types,
    });
    this.props.productSegmentListAttributesFetch({});
  }

  componentDidUpdate(prevProps) {
    if (this.props.archiveSuccess !== prevProps.archiveSuccess) {
      this.componentDidMount();
    }
    if (this.props.pathname !== prevProps.pathname) {
      this.segmentType = getSegmentType(this.props.pathname);
      this.componentDidMount();
    }
    if (
      !this.props.loadingCreate &&
      prevProps.loadingCreate &&
      (isNil(this.props.errorsCreate.errors) ||
        isEmpty(this.props.errorsCreate.errors))
    ) {
      let foundItem = null;
      if (
        Array.isArray(this.props.attributes) &&
        this.props.attributes.length > 0
      ) {
        foundItem = lodashFind(
          this.props.attributes,
          (item) => item.name === 'userId'
        );
      }
      this.props.productSegmentFileUploadReset();
      this.setState({
        fileUploadOpen: false,
        currentSegment: {
          test: false,
          name: null,
          type: 'STATIC_CSV',
          staticAttribute: !isNil(foundItem) ? foundItem.id : null,
        },
      });
      this.props.productSegmentStaticCreateToggle(false);
    }
    if (
      !this.props.loadingUploadFile &&
      prevProps.loadingUploadFile &&
      !isNil(this.props.uploadedFile) &&
      this.props.uploadedFile.path
    ) {
      this.setState({
        currentSegment: {
          ...this.state.currentSegment,
          staticFile: this.props.uploadedFile.path,
        },
      });
    }
    if (
      !this.props.loadingAttributes &&
      prevProps.loadingAttributes &&
      Array.isArray(this.props.attributes) &&
      this.props.attributes.length > 0
    ) {
      const foundItem = lodashFind(
        this.props.attributes,
        (item) => item.name === 'userId'
      );
      this.setState({
        currentSegment: {
          ...this.state.currentSegment,
          staticAttribute: foundItem.id,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.productSegmentPageChangeCurrent({});
    this.props.clearSegmentError(this.props.errorsCreate.errors);
  }

  handleChangeSearch = (name) => {
    this.setState({ name });
    if (name === '' || name == null) {
      this.props.productSegmentListFetch({
        ...this.props.pageRequest,
        page: 0,
        name: null,
      });
    }
  };

  getNavigation() {
    return [{ name: 'Segment List', path: '' }];
  }

  renderUserCount = (x) => {
    return numberWithCommas(x);
  };

  renderCampaignsCount = (x) => {
    return numberWithCommas(x);
  };

  renderJourneysCount = (x) => {
    return numberWithCommas(x);
  };

  renderProcessingBadge = (x, object) => {
    if (object.status && object.status === 'PROCESSING') {
      return (
        <span
          style={{
            backgroundColor: '#388cf2',
            marginLeft: 5,
            borderRadius: 5,
            color: 'white',
            fontSize: 10,
            padding: '2px 5px',
          }}
        >
          IN PROGRESS
        </span>
      );
    }
    return null;
  };

  onOkFileUpload = () => {
    this.props.productSegmentCsvCreateFetch(
      this.state.currentSegment,
      this.props.pageRequest
    );
  };

  onCancelFileUpload = () => {
    this.props.productSegmentStaticCreateToggle(false);
    this.props.clearSegmentError(this.props.errorsCreate.errors);
    this.props.productSegmentFileUploadReset();
    this.setState({
      fileUploadOpen: false,
      currentSegment: {
        ...this.state.currentSegment,
        test: false,
        name: null,
        type: 'STATIC_CSV',
        staticFile: null,
      },
    });
  };

  handleRemoveUploaded = () => {
    this.props.productSegmentFileUploadReset();
  };

  handleSelectUpload = ({ fileList }) => {
    if (
      Array.isArray(fileList) &&
      fileList.length > 0 &&
      fileList[0].originFileObj
    ) {
      let csvFileFormatError = false;
      if (fileList[0].originFileObj.name) {
        if (fileFormatChecker(fileList[0].originFileObj.name, ['csv']) > -1) {
          csvFileFormatError = false;
        } else {
          csvFileFormatError = true;
        }

        this.setState({ csvFileFormatError });
      }
      if (csvFileFormatError) {
        return;
      }
      this.props.productSegmentFileUploadFetch(fileList[0].originFileObj);
    }
  };

  handleClickStaticModal = () => {
    this.setState({ fileUploadOpen: true }, () => {
      this.props.productSegmentStaticCreateToggle(false);
    });
  };

  handleClickStaticCreator = () => {
    this.props.productSegmentStaticCreateToggle(false);
    setTimeout(() => history.push('create'), 0);
  };

  renderSelectorType = () => {
    const foundItem = lodashFind(
      this.props.attributes,
      (item) => item.id === this.state.currentSegment.staticAttribute
    );
    if (foundItem && foundItem.name === 'userId') {
      return 'User IDs';
    }
    if (foundItem && foundItem.name === 'phone') {
      return 'Phone Numbers';
    }
    if (foundItem && foundItem.name === 'email') {
      return 'Emails';
    }
  };
  selectorType = () => {
    const foundItem = lodashFind(
      this.props.attributes,
      (item) => item.id === this.state.currentSegment.staticAttribute
    );

    if (foundItem && foundItem.name !== 'userId') {
      return true;
    } else {
      return false;
    }
  };

  renderSelectOptions = () => {
    let result = [];
    if (
      this.props.attributes &&
      Array.isArray(this.props.attributes) &&
      this.props.attributes.length > 0
    ) {
      const attributes_sub_ = this.props.attributes.filter((attr) =>
        ['email', 'phone', 'userId'].includes(attr.name)
      );
      attributes_sub_.forEach((item) => {
        result.push(
          <Select.Option value={item.id} key={item.id}>
            {item.title}
          </Select.Option>
        );
      });
    }
    return result;
  };

  renderCsvFileSegmentSelector = () => {
    return (
      <Modal
        width={810}
        title={'Create a Static Segment Using CSV File'}
        centered
        bodyStyle={{ paddingBottom: 0 }}
        visible={this.state.fileUploadOpen}
        onOk={() => this.onOkFileUpload()}
        onCancel={() => {
          this.props.clearSegmentError(this.props.errorsCreate.errors);
          this.setState({ fileUploadOpen: false, csvFileFormatError: false });
        }}
        footer={[
          <Button
            key="cancel-button"
            shape="round"
            onClick={() => {
              this.setState({ csvFileFormatError: false });
              this.onCancelFileUpload();
            }}
          >
            CANCEL
          </Button>,
          <Button
            loading={this.props.loadingCreate}
            key="submit"
            type="primary"
            shape="round"
            onClick={() => this.onOkFileUpload()}
          >
            SAVE
          </Button>,
        ]}
      >
        <div style={{ marginBottom: 30 }}>
          <div>
            Please note that the{' '}
            <span style={{ fontWeight: 'bold' }}>
              {this.renderSelectorType()}
            </span>{' '}
            in the CSV file need to be those of your existing users only i.e
            users that exist in your {texts.brandName} project. Also, you can
            specify which type of user data exists in the file with{' '}
            <span style={{ fontWeight: 'bold' }}>User Data Type</span> selector
            (i.e. User Id, Phone Number, or Email).
          </div>
        </div>
        <div style={styles.name}>
          <div style={styles.nameTitle}>Segment Name</div>
          <Form.Item
            validateStatus={
              errorHandling(this.props.errorsCreate, 'name') ? 'error' : null
            }
            help={errorHandling(this.props.errorsCreate, 'name')}
            style={{ marginTop: 25 }}
          >
            <Input
              style={{ ...styles.nameInput, width: 200 }}
              value={
                this.state.currentSegment &&
                !isNil(this.state.currentSegment.name)
                  ? this.state.currentSegment.name
                  : null
              }
              placeholder="Name Your Segment"
              onChange={(e) => {
                this.props.clearSegmentError(
                  this.props.errorsCreate.errors,
                  'name'
                );
                this.setState({
                  currentSegment: {
                    ...this.state.currentSegment,
                    name: e.target.value,
                  },
                });
              }}
            />
          </Form.Item>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: 140,
              marginLeft: 20,
            }}
          >
            <div style={{ paddingRight: 10 }}>Test Mode</div>
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(test) =>
                this.setState({
                  currentSegment: { ...this.state.currentSegment, test },
                })
              }
              checked={
                this.state.currentSegment && this.state.currentSegment.test
                  ? this.state.currentSegment.test
                  : false
              }
            />
          </div>
        </div>
        <div style={{ ...styles.name, marginTop: 20 }}>
          <div style={styles.nameTitle}>User Data Type</div>
          <Select
            filterOption={(input, option) =>
              option.props.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            dropdownMenuStyle={{ maxHeight: 150 }}
            style={{ fontSize: 13, width: 360, color: '#333' }}
            value={
              this.state.currentSegment &&
              this.state.currentSegment.staticAttribute
                ? this.state.currentSegment.staticAttribute
                : null
            }
            onChange={(staticAttribute) =>
              this.setState({
                currentSegment: {
                  ...this.state.currentSegment,
                  staticAttribute,
                },
              })
            }
          >
            {this.renderSelectOptions()}
          </Select>
        </div>
        <p
          style={{
            ...styles.name,
            marginTop: '10px',
            color: 'gray',
            fontSize: 12,
          }}
        >
          {this.selectorType()
            ? ' Using "Phone Number" or "Email Address" will cause a delay in segment creation time; preferred identifier is User ID '
            : ''}
        </p>
        <div style={{ ...styles.name, marginTop: 20 }}>
          <div style={{ ...styles.nameTitle, width: 100, textAlign: 'right' }}>
            CSV File
          </div>
          <div style={{ width: 360 }}>{this.renderUploader()}</div>
        </div>
        <div style={{ marginTop: 24 }}>
          <div className={'csv__caption-size'}>
            Maximum file size: 3 MB. File format: CSV file with single user
            attribute on each row. Start entering values from the first
            row.&nbsp;
            <a href="/files/user_ids_sample.csv" download>
              Download sample CSV
            </a>{' '}
            file to see format.
          </div>
        </div>
      </Modal>
    );
  };

  renderStaticSegmentSelector = () => {
    return (
      <Modal
        width={550}
        title={'Select the Type of Segment'}
        centered
        footer={null}
        visible={this.props.staticCreateOpen}
        onOk={() => this.props.productSegmentStaticCreateToggle(false)}
        onCancel={() => this.props.productSegmentStaticCreateToggle(false)}
      >
        <div style={styles.methodsContainer}>
          <div
            onClick={() => this.handleClickStaticModal()}
            className="method-push-container"
            style={styles.methodContainer}
          >
            <div style={styles.methodTitle}>Create Segment using CSV File</div>
            <div>
              <img src={segmentCsv} style={{ maxWidth: '100%' }} alt="" />
            </div>
          </div>
          <div
            onClick={() => this.handleClickStaticCreator()}
            className="method-push-container"
            style={styles.methodContainer}
          >
            <div style={styles.methodTitle}>Create with Segment Editor</div>
            <div>
              <img src={segmentStatic} style={{ maxWidth: '100%' }} alt="" />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  renderUploader = () => {
    let showButton =
      isNil(this.props.uploadedFile) || isEmpty(this.props.uploadedFile);
    if (showButton) {
      return (
        <div style={{ marginTop: 25 }}>
          <Form.Item
            validateStatus={
              errorHandling(this.props.errorsCreate, 'staticFile')
                ? 'error'
                : this.state.csvFileFormatError
                ? 'error'
                : null
            }
            help={
              errorHandling(this.props.errorsCreate, 'staticFile')
                ? errorHandling(this.props.errorsCreate, 'staticFile')
                : this.state.csvFileFormatError
                ? 'Invalid File Format'
                : null
            }
          >
            <Upload
              fileList={
                this.props.uploadedFile && this.props.uploadedFile.path
                  ? [this.props.uploadedFile]
                  : []
              }
              onChange={(object) => {
                this.props.clearSegmentError(
                  this.props.errorsCreate.errors,
                  'staticFile'
                );
                this.handleSelectUpload(object);
              }}
              onRemove={this.handleRemoveUploaded}
              accept=".csv, .txt, .xls, xlsx"
              beforeUpload={(file) => {
                const isCsv =
                  file.type.includes('csv') ||
                  file.type.includes('excel') ||
                  file.type.includes('text');
                if (!isCsv) {
                  message.error(`${file.name} is not a csv file`);
                  return Upload.LIST_IGNORE;
                }
                if (file.size > 3000000) {
                  message.error('file size should be less than 3MB');
                  return Upload.LIST_IGNORE;
                }
                // return file.type === 'text/csv' ? true : Upload.LIST_IGNORE;
                return true;
              }}
            >
              <Button
                type="primary"
                shape="round"
                size={'small'}
                loading={this.props.loadingUploadFile}
                style={{
                  direction: 'ltr',
                  textTransform: 'uppercase',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  fontSize: 12,
                  padding: '0px 30px',
                  height: 35,
                  fontWeight: '600',
                  border:
                    errorHandling(this.props.errorsCreate, 'staticFile') ||
                    this.state.csvFileFormatError
                      ? '2px solid'
                      : '2px solid',
                  borderColor:
                    errorHandling(this.props.errorsCreate, 'staticFile') ||
                    this.state.csvFileFormatError
                      ? 'red'
                      : vars.primaryColor,
                }}
              >
                Select File
              </Button>
            </Upload>
          </Form.Item>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <a href={this.props.uploadedFile.path} download>
              {this.props.uploadedFile.fileName}
            </a>
          </div>
          <CloseOutlined
            onClick={() => {
              this.handleRemoveUploaded();
              this.setState({ csvFileFormatError: false });
            }}
            style={{
              color: '#bbbcc1',
              fontSize: 14,
              paddingLeft: 5,
              paddingTop: 5,
              cursor: 'pointer',
            }}
          />
        </div>
      );
    }
  };

  render() {
    const columns = [
      {
        title: 'segment name',
        width: 200,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        render: (x, object) => {
          if (object.status && object.status === 'PROCESSING') {
            return (
              <div
                onClick={() => {
                  {
                    showMessageError({
                      status: 'Error',
                      message: 'Please contact admin',
                    });
                  }
                }}
              >
                <span
                  style={{
                    color: vars.primaryColor,
                    fontSize: 14,
                    cursor: 'pointer',
                  }}
                >
                  {x}
                </span>
                {this.renderProcessingBadge(x, object)}
              </div>
            );
          } else {
            return (
              <a
                onClick={() => {
                  const url = `${object.id}/overview`;

                  history.push(url);
                }}
              >
                {x}
              </a>
            );
          }
        },
      },
      {
        title: 'users',
        width: 100,
        dataIndex: 'segmentSize',
        key: 'segmentSize',
        render: (x) => renderLowerFont(numberWithCommas(x)),
      },
      {
        title: 'communications',
        dataIndex: 'communications',
        key: 'communications',
        width: 150,
        render: (x) => {
          const pid = getProductId(location?.pathname);
          return (
            x &&
            x
              .map((c) => (
                <a
                  key={`${pid}-${c.communicationId}-link`}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/product/${pid}/${c.channelType.toLowerCase()}-messages/campaigns/${
                    c.communicationId
                  }/overview`}
                >
                  {c.communicationId}
                </a>
              ))
              .reduce((acc, x) => (acc === null ? [x] : [acc, ', ', x]))
          );
        },
      },
      {
        title: 'journeys',
        dataIndex: 'journeys',
        key: 'journeys',
        width: 150,
        render: (x) => {
          const pid = getProductId(location?.pathname);
          return (
            x &&
            x
              .map((id) => (
                <a
                  key={`${pid}-${id}-link`}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/product/${pid}/journey/${id}/overview`}
                >
                  {id}
                </a>
              ))
              .reduce((acc, x) => (acc === null ? [x] : [acc, ', ', x]))
          );
        },
      },
      {
        title: 'created date',
        dataIndex: 'createdDate',
        key: 'createdDate',
        width: 150,
        render: (x) => renderLowerFont(readableDateTime(x)),
      },
      {
        title: 'action',
        key: 'action',
        fixed: 'left',
        width: '5%',
        render: (x) => {
          if (x.type === 'DYNAMIC') {
            return defaultActions(
              x,
              {
                link: 'update/' + x.id,
                title: 'Edit',
                enabled: true,
              },
              {
                action: (id) =>
                  this.props.productSegmentCopyFetch(
                    id,
                    this.props.pageRequest
                  ),
                title: 'Duplicate',
                enabled: true,
              },
              {
                action: (id) =>
                  this.props.productSegmentDeleteFetch(
                    id,
                    this.props.pageRequest
                  ),
                title: 'Delete',
                enabled: true,
              },
              {
                action: (id) => this.props.productSegmentArchive(id),
                title: 'Archive',
                enabled: true,
              },
              featureAccessCheckCSVExport()
                ? {
                    component: (
                      <CsvButton
                        label="Export Users List"
                        display="menu"
                        callApi={() =>
                          this.props.callExportSegmentFile({
                            id: x.id,
                            body: {
                              headers: [],
                            },
                          })
                        }
                        type="segment"
                      ></CsvButton>
                    ),
                    title: 'Export Users List',
                    enabled: true,
                  }
                : null
            );
          } else {
            return defaultActions(
              x,
              {
                action: (id) =>
                  this.props.productSegmentDeleteFetch(
                    id,
                    this.props.pageRequest
                  ),
                title: 'Delete',
                enabled: true,
              },
              {
                action: (id) => this.props.productSegmentArchive(id),
                title: 'Archive',
                enabled: true,
              }
            );
          }
        },
      },
    ];

    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    return (
      <div>
        <Card>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              borderBottom: 'solid 2px #f4f9ff',
            }}
          >
            <Input
              loading={true}
              allowClear={this.props.loadingSegmentSearchList ? false : true}
              placeholder={'Search by segment name'}
              onPressEnter={() =>
                this.props.productSearchSegmentListFetch({
                  ...this.props.pageRequest,
                  page: 0,
                  name: this.state.name,
                })
              }
              onChange={(e) => this.handleChangeSearch(e.target.value)}
              value={this.state.name}
              style={{ width: 300, marginRight: 30, borderRadius: 5 }}
              suffix={
                isEmpty(this.state.name) ? (
                  <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                ) : (
                  this.props.loadingSegmentSearchList && (
                    <div style={styles.inputLoadingSpinStyle}>
                      <Spin indicator={inputLoadingSpin} />
                    </div>
                  )
                )
              }
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {
                <img
                  src={liveSegmentBlack}
                  style={{ width: 16, height: 'auto' }}
                  alt=""
                />
              }
            </div>
          </div>
          {this.props.loadingSegmentList ? (
            <div style={styles.loadingSpinStyle}>
              <Spin indicator={loadingSpin} />
            </div>
          ) : (
            <ListBase
              onChange={this.props.productSegmentListFetch}
              page={this.props.page}
              pageRequest={this.props.pageRequest}
              columns={columns}
              createLink={
                hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_SEGMENT_CREATE)
                  ? 'create'
                  : null
              }
            />
          )}
        </Card>
        {this.renderStaticSegmentSelector()}
        {this.renderCsvFileSegmentSelector()}
      </div>
    );
  }
}

export default ProductSegmentListComponent;
