import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { viewDateTime } from '../../../../utils/DateUtils';
import {
  AuthorityProvider,
  hasAnyAuthority,
} from '../../../../utils/AuthorityProvider';
import Card from '@Utils/Card';

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  title: {
    display: 'flex',
    color: '#9a9ca3',
    fontSize: 14,
    margin: '15px 30px',
  },
  description: {
    borderRadius: 10,
    backgroundColor: '#efefef',
    padding: '10px 40px',
    color: '#9a9ca3',
    marginBottom: 10,
  },
};
class RoleViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rouleId:
        this.props.pathname.includes('view') &&
        this.props.pathname.split('view/').pop(),
    };
  }
  componentDidMount() {
    this.props.roleViewFetch(this.state.rouleId);
  }

  componentWillUnmount() {
    this.props.roleChangeCurrent({});
  }

  render() {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 40 }} spin />;
    return (
      <div>
        <Card
          styles={{
            padding: 30,
          }}
        >
          {this.props.loadingViewRole ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '100px',
              }}
            >
              <Spin indicator={loadingSpin} />{' '}
            </div>
          ) : (
            <React.Fragment>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div style={{ ...styles.title, width: 150 }}>
                    CREATED DATE
                  </div>
                  <div style={{ ...styles.description, width: 300 }}>
                    {viewDateTime(
                      this.props.currentRole.createdDate
                        ? viewDateTime(this.props.currentRole.createdDate)
                        : 'No data'
                    )}
                  </div>
                  <div style={{ ...styles.title, width: 150 }}>CREATED BY</div>
                  <div style={{ ...styles.description, width: 300 }}>
                    {this.props.currentRole.createdBy
                      ? this.props.currentRole.createdBy.presentation
                      : 'No data'}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ ...styles.title, width: 150 }}>
                    MODIFIED DATE
                  </div>
                  <div style={{ ...styles.description, width: 300 }}>
                    {viewDateTime(
                      this.props.currentRole.modifiedDate
                        ? viewDateTime(this.props.currentRole.modifiedDate)
                        : 'No data'
                    )}
                  </div>
                  <div style={{ ...styles.title, width: 150 }}>MODIFIED BY</div>
                  <div style={{ ...styles.description, width: 300 }}>
                    {this.props.currentRole.modifiedBy
                      ? this.props.currentRole.modifiedBy.presentation
                      : 'No data'}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ ...styles.title, width: 150 }}>NAME</div>
                  <div style={{ ...styles.description, width: 300 }}>
                    {this.props.currentRole.name
                      ? this.props.currentRole.name
                      : 'No data'}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ ...styles.title, width: 150, marginTop: 50 }}>
                    PERMISSIONS
                  </div>
                  <div
                    style={{
                      maxHeight: 400,
                      overflow: 'scroll',
                      marginTop: 50,
                      borderRadius: 10,
                      backgroundColor: '#efefef',
                      padding: '10px 40px',
                      color: '#9a9ca3',
                      width: 300,
                    }}
                  >
                    {this.props.currentRole.authorities
                      ? this.props.currentRole.authorities.map((item) => (
                          <div key={item.message}>{item.message}</div>
                        ))
                      : ''}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Link to="../list">
                  <Button
                    type="primary"
                    shape="round"
                    size={'large'}
                    loading={this.props.loading}
                    className="w-200"
                  >
                    {'Back'}
                  </Button>
                </Link>
                {hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_ROLE_UPDATE) && (
                  <Link to={`../update/${this.state.rouleId}`}>
                    <Button
                      type="primary"
                      shape="round"
                      size={'large'}
                      loading={this.props.loading}
                      className="w-200"
                    >
                      {'Edit'}
                    </Button>
                  </Link>
                )}
              </div>
            </React.Fragment>
          )}
        </Card>
      </div>
    );
  }
}

export default RoleViewComponent;
