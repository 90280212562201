import React from 'react';
import { Empty } from 'antd';
import { filter as lodashFilter, findIndex } from 'lodash';
import {
  AndroidOutlined,
  AppleOutlined,
  ChromeOutlined,
} from '@ant-design/icons';
import UserDeviceDetailsComponent from './UserDeviceDetailsComponent';
import { HashBase64 } from '@Component/utils/HashBase64';
import vars from '@Theme/styles/vars.js';
import Card from '@Utils/Card';
class UserDevicesComponent extends React.Component {
  state = {
    openedIndex: -1,
    selectedSdkType: 'ANDROID',
  };

  componentDidMount() {
    const first = this.props.pathname.split('view/')[1];
    const encodedUserId = first.split('/devices')[0];
    const userId = HashBase64().decode(encodedUserId);
    this.props.insightProductUserDeviceFetch(userId);
    this.props.insightProductUserViewFetch(userId);
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.deviceListLoading &&
      prevProps.deviceListLoading &&
      this.props.deviceList &&
      Array.isArray(this.props.deviceList) &&
      this.props.deviceList.length > 0
    ) {
      this.setState({
        selectedSdkType: this.props.deviceList[0].sdkType,
        openedIndex: 0,
      });
    }
  }

  renderWeb = (item, index) => {
    return (
      <div style={{ marginTop: 5 }}>
        <div
          style={{
            padding: 12,
            backgroundColor: '#efefef',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ color: '#000000', fontSize: 14 }}>
            {'Device' + (index + 1)}
          </div>
          {this.state.openedIndex !== index && (
            <div
              onClick={() => this.setState({ openedIndex: index })}
              style={{
                cursor: 'pointer',
                color: vars.primaryColor,
                fontSize: 12,
              }}
            >
              Show details
            </div>
          )}
          {this.state.openedIndex === index && (
            <div
              onClick={() => this.setState({ openedIndex: -1 })}
              style={{
                cursor: 'pointer',
                color: vars.primaryColor,
                fontSize: 12,
              }}
            >
              Hide details
            </div>
          )}
        </div>
        {this.state.openedIndex === index && (
          <UserDeviceDetailsComponent device={item} />
        )}
      </div>
    );
  };

  renderMobile = (item, index) => {
    return (
      <div>
        <div
          style={{
            padding: 12,
            backgroundColor: '#efefef',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ color: '#000000', fontSize: 14 }}>
            {'Device ' + (index + 1)}
          </div>
          {this.state.openedIndex !== index && (
            <div
              onClick={() => this.setState({ openedIndex: index })}
              style={{
                cursor: 'pointer',
                color: vars.primaryColor,
                fontSize: 12,
              }}
            >
              Show details
            </div>
          )}
          {this.state.openedIndex === index && (
            <div
              onClick={() => this.setState({ openedIndex: -1 })}
              style={{
                cursor: 'pointer',
                color: vars.primaryColor,
                fontSize: 12,
              }}
            >
              Hide details
            </div>
          )}
        </div>
        {this.state.openedIndex === index && (
          <UserDeviceDetailsComponent device={item} />
        )}
      </div>
    );
  };

  renderRow = (item, index) => {
    if (item && item.sdkType) {
      if (item.sdkType === 'ANDROID' || item.sdkType === 'IOS') {
        return this.renderMobile(item, index);
      } else {
        return this.renderWeb(item, index);
      }
    }
  };

  renderList = () => {
    let result = [];
    if (this.props.deviceList && Array.isArray(this.props.deviceList)) {
      const items = lodashFilter(
        this.props.deviceList,
        (o) => o.sdkType === this.state.selectedSdkType
      );
      if (items.length > 0) {
        items.forEach((item, index) => {
          result.push(this.renderRow(item, index));
        });
        if (result.length === 1 && typeof result[0] === 'undefined') {
          result = [];
          result.push(<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />);
        }
      } else {
        result.push(<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />);
      }
    }
    return result;
  };

  handleClickSDK = (selectedSdkType) => {
    if (this.props.deviceList && Array.isArray(this.props.deviceList)) {
      const foundIndex = findIndex(
        this.props.deviceList,
        (o) => o.sdkType === selectedSdkType
      );
      if (foundIndex > -1) {
        this.setState({ selectedSdkType, openedIndex: 0 });
      } else {
        this.setState({ selectedSdkType, openedIndex: -1 });
      }
    }
  };

  renderSKDSelector = () => {
    return (
      <div
        style={{
          color: vars.primaryColor,
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <ChromeOutlined
          onClick={() =>
            this.setState({ selectedSdkType: 'WEB', openedIndex: -1 })
          }
          style={{
            color: this.state.selectedSdkType === 'WEB' ? '#000000' : '#B2B2B2',
            fontSize: 16,
            cursor: 'pointer',
          }}
        />
        <AndroidOutlined
          onClick={() =>
            this.setState({ selectedSdkType: 'ANDROID', openedIndex: -1 })
          }
          style={{
            color:
              this.state.selectedSdkType === 'ANDROID' ? '#000000' : '#B2B2B2',
            fontSize: 16,
            cursor: 'pointer',
            marginLeft: 10,
            marginRight: 10,
          }}
        />
        <AppleOutlined
          onClick={() =>
            this.setState({ selectedSdkType: 'IOS', openedIndex: -1 })
          }
          style={{
            color: this.state.selectedSdkType === 'IOS' ? '#000000' : '#B2B2B2',
            fontSize: 16,
            cursor: 'pointer',
          }}
        />
      </div>
    );
  };

  renderDevices = () => {
    return (
      <Card loading={this.props.userLoading}>
        <div>
          <div
            style={{
              padding: 16,
              borderBottom: 'solid 2px #f4f9ff',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{ fontSize: 16, color: 'black', fontWeight: '600' }}
            ></div>
            {this.renderSKDSelector()}
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', padding: 16 }}
          >
            <div>{this.renderList()} </div>
          </div>
        </div>
      </Card>
    );
  };

  render() {
    return <div>{this.renderDevices()}</div>;
  }
}
export default UserDevicesComponent;
