import * as React from 'react';

function SvgError() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18.222"
      viewBox="0 0 16 14.222"
    >
      <g>
        <path
          fill="#f61d1d"
          d="M15.82 12.222L9.155.667a1.334 1.334 0 0 0-2.31 0L.18 12.222a1.334 1.334 0 0 0 1.155 2h13.33a1.334 1.334 0 0 0 1.155-2zM8 12.444a.889.889 0 1 1 .889-.889.889.889 0 0 1-.889.889zm1.062-6.622l-.355 3.555a.444.444 0 0 1-.442.4h-.528a.444.444 0 0 1-.442-.4l-.356-3.555a.444.444 0 0 1 .441-.489h1.24a.444.444 0 0 1 .442.489z"
          transform="translate(-73 -2564.891) translate(73 2564.891)"
        />
        <path
          fill="#fff"
          d="M8.62 5.333H7.38a.444.444 0 0 0-.442.489l.356 3.556a.444.444 0 0 0 .442.4h.528a.444.444 0 0 0 .442-.4l.356-3.556a.444.444 0 0 0-.441-.489zM8 10.667a.889.889 0 1 0 .889.889.889.889 0 0 0-.889-.889z"
          transform="translate(-73 -2564.891) translate(73 2564.891)"
        />
      </g>
    </svg>
  );
}

export default SvgError;
