import React from 'react';
import { Input, Form } from 'antd';
import useTimer from '../../../../hook/useTimer';
import './VerificationModal.less';
import { useSelector, useDispatch } from 'react-redux';
import { emailVerificationOTPCodeFetch } from '../ProfileActions';

const EmailVerificationModalContent = ({
  otpCode,
  setOtpCode,
  isTimerEnabled,
}) => {
  const dispatch = useDispatch();
  const initialSeconds = 120;
  const initialTimer = '02:00';

  const { emailVerification, currentProfile } = useSelector(
    (state) => state.account.profile
  );
  const { currentUser } = useSelector((state) => state.account.auth);

  const handleResendOtpBtn = () => {
    dispatch(
      emailVerificationOTPCodeFetch({
        body: {
          userId: currentUser.userId,
          userEmail: currentProfile.email,
        },
      })
    );
  };

  const { timer, showTimer, handleResendButton } = useTimer({
    initialSeconds,
    initialTimer,
    handleResendOtpBtn,
    isTimerEnabled,
  });

  return (
    <div id="verification">
      <div>
        We&apos;ve just sent a verification code to your inbox. Please check
        your email and enter the code in the field below to complete the
        verification process.
      </div>
      <div className="row">
        <div className="inputLabel">VERIFICATION CODE</div>
        <Form.Item
          validateStatus={
            emailVerification?.emailVerifiedStatus === false ? 'error' : ''
          }
          help={
            emailVerification?.emailVerifiedStatus === false
              ? 'Invalid code. Please try again'
              : ''
          }
        >
          <Input.Password
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
            style={{ width: 250 }}
          />
        </Form.Item>
      </div>
      <div className="timerWrapper">
        <span>Didn&apos;t get the code?</span>
        <span
          className={showTimer ? 'disabledResendBtn' : 'enabledResendBtn'}
          onClick={showTimer ? () => {} : handleResendButton}
        >
          Resend
        </span>
        {showTimer && <span className="timer">{`(${timer})`}</span>}
      </div>
    </div>
  );
};

export default EmailVerificationModalContent;
