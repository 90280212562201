import React from 'react';
import {
  CheckCircleFilled,
  LoadingOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import { validateEmail } from '../../../../utils/EmailValidation';
import { Button, Modal, Input, Spin } from 'antd';
import vars from '@Theme/styles/vars.js';
import Card from '@Utils/Card';
const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    direction: 'ltr',
    fontWeight: '700',
    color: '#3d404e',
    fontSize: 17,
    textAlign: 'left',
    padding: 16,
  },
  addButton: {
    fontSize: 20,
    color: 'rgb(29, 136, 246)',
    cursor: 'pointer',
    marginLeft: 16,
  },
  tabItem: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: '500',
    color: '#3d404e',
    borderBottom: '1px solid #eee',
    fontSize: 14,
    paddingLeft: 30,
    paddingTop: 30,
  },
  buttons: {
    direction: 'ltr',
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: '#ffffff',
    background: vars.primaryColor,
    cursor: 'pointer',
    position: 'relative',
    fontSize: 13,
    fontWeight: '600',
    padding: '0 35px',
    margin: '0px 5px',
  },
};
class MembersViewComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActiveTeamMember: true,
      isActiveInvitedMember: false,
      currentActiveMembers: [],
      currentInvitedMembers: [],
      permissionsModalOpen: false,
      presentation: null,
    };
  }
  componentDidMount() {
    this.props.teamMembersFetchList({
      page: 0,
      presentation: this.state.presentation,
      order: 'id',
      status: 'ENABLED',
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.teamMembersLoading && !this.props.teamMembersLoading) {
      if (this.props.teamMembers) {
        let activeUsers = [];
        let invitedUsers = [];
        this.props.teamMembers.content.forEach((i) => {
          let rolesArray = [];
          let roleString = '';
          i.roles.forEach((role) => {
            rolesArray.push(role.name);
            if (isEmpty(roleString)) {
              roleString = roleString + role.name;
            } else {
              roleString = roleString + ', ' + role.name;
            }
          });
          if (i.user.status === 'ENABLED') {
            activeUsers.push({
              id: i.id,
              name: i.user.presentation,
              email: i.user.email,
              status: i.user.status,
              roles: rolesArray,
              role: roleString,
            });
          }
          if (i.user.status === 'PENDING_VERIFICATION') {
            invitedUsers.push({
              id: i.id,
              name: i.user.presentation,
              email: i.user.email,
              status: i.user.status,
              roles: rolesArray,
              role: roleString,
            });
          }
        });
        this.setState({
          currentActiveMembers: {
            ...this.props.teamMembers,
            content: activeUsers,
          },
        });
        this.setState({
          currentInvitedMembers: {
            ...this.props.teamMembers,
            content: invitedUsers,
          },
        });
      }
    }
  }
  handleChangeNameSearch = (presentation) => {
    this.setState({ presentation });
    if (presentation === '' || presentation == null) {
      this.props.teamMembersFetchList({
        ...this.props.teamMembersPageRequest,
        presentation: null,
        email: null,
        page: 0,
        order: 'id',
      });
    }
  };
  renderListRoles = (index, roles) => {
    if (roles && Array.isArray(roles) && roles.length > 0) {
      let result = [];
      roles.forEach((role) => {
        result.push(<div>{role}</div>);
      });
      return result;
    }
  };
  renderViewPermissionsContent() {
    let array = [];
    if (this.props.permissions) {
      this.props.permissions.roles.forEach((role) => {
        array.push(
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {role.name}
            <CheckCircleFilled style={{ color: 'green', marginLeft: 10 }} />
          </div>
        );
      });
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>{array}</div>
      );
    }
  }
  render() {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 15 }} spin />;
    const columnList = [
      {
        sorter: false,
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        sorter: false,
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
      },
      {
        sorter: false,
        title: 'Roles',
        dataIndex: 'role',
        key: 'role',
        width: 400,
        // render: (x, object, index) => {
        //   this.renderListRoles(index, x);
        // },
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '-action-',
        width: '5%',

        render: (x) =>
          defaultActions(
            x,
            {
              // action: (id) =>
              //   this.props.newTeaMemberChangeCurrent({
              //     firstName: id,
              //     email: id,
              //     userProducts: id,
              //   }),
              link: 'members/update/' + x.id,
              title: 'Edit',
              enabled: true,
            },
            {
              action: (id) =>
                this.props.teamMemberDeleteFetch(
                  id,
                  this.props.teamMembersPageRequest
                ),
              title: 'Delete',
              enabled: true,
            },
            {
              action: (id) => this.props.resendInvitation(id),
              title: 'Send Invitation Again',
              enabled: this.state.isActiveInvitedMember,
              disablePopConfirm: true,
            }
            // {
            //   title: 'View Permissions',
            //   enabled: true,
            //   disablePopConfirm: true,
            //   action: (id) => {
            //     this.props.teamMembersViewFetch(id);
            //     this.setState({ permissionsModalOpen: true });
            //   },
            // }
          ),
      },
    ];
    return (
      <Card>
        <div style={styles.header}>
          <Input
            loading={true}
            allowClear={this.props.teamMembersLoading ? false : true}
            placeholder={'Search by name or email'}
            onPressEnter={(e) => {
              let emailFormat = validateEmail(e.target.value);
              this.props.teamMembersFetchList({
                ...this.props.teamMembersPageRequest,
                page: 0,
                presentation: emailFormat ? null : this.state.presentation,
                email: emailFormat ? e.target.value : null,
                order: 'id',
              });
            }}
            onChange={(e) => this.handleChangeNameSearch(e.target.value)}
            value={this.state.presentation}
            style={{ width: 300, marginRight: 30, borderRadius: 5 }}
            suffix={
              isEmpty(this.state.presentation) ? (
                <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              ) : (
                this.props.teamMembersLoading && (
                  <div style={styles.inputLoadingSpinStyle}>
                    <Spin indicator={inputLoadingSpin} />
                  </div>
                )
              )
            }
          />
        </div>
        <div style={styles.tabItem}>
          {' '}
          <div
            style={{
              letterSpacing: 1,
              marginRight: 50,
              paddingBottom: 15,
              cursor: 'pointer',
              fontSize: 13,
              fontWeight: this.state.isActiveTeamMember ? 'bold' : 'normal',
              borderBottom: this.state.isActiveTeamMember
                ? '3px solid rgb(29, 136, 246)'
                : null,
            }}
            onClick={() => {
              this.props.teamMembersFetchList({
                page: 0,
                presentation: this.state.presentation,
                order: 'id',
                status: 'ENABLED',
              });
              this.setState({
                isActiveTeamMember: true,
                isActiveInvitedMember: false,
              });
            }}
          >
            ACTIVE
          </div>
          <div
            style={{
              letterSpacing: 1,
              marginRight: 30,
              paddingBottom: 15,
              cursor: 'pointer',
              fontSize: 13,
              fontWeight: this.state.isActiveInvitedMember ? 'bold' : 'normal',
              borderBottom: this.state.isActiveInvitedMember
                ? '3px solid rgb(29, 136, 246)'
                : null,
            }}
            onClick={() => {
              this.props.teamMembersFetchList({
                page: 0,
                presentation: this.state.presentation,
                order: 'id',
                status: 'PENDING_VERIFICATION',
              });
              this.setState({
                isActiveInvitedMember: true,
                isActiveTeamMember: false,
              });
            }}
          >
            INVITED
          </div>
        </div>
        {this.props.teamMembersLoading ? (
          <div
            style={{
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              paddingTop: 50,
              paddingBottom: 50,
            }}
          >
            {' '}
            <Spin indicator={loadingSpin} />
          </div>
        ) : (
          <React.Fragment>
            {' '}
            <div>
              {' '}
              <ListBase
                onChange={this.props.teamMembersFetchList}
                page={
                  this.state.isActiveTeamMember
                    ? this.state.currentActiveMembers
                    : this.state.currentInvitedMembers
                }
                pageRequest={this.props.teamMembersPageRequest}
                columns={columnList}
                // onClick={(row) => this.viewItem(row.id)}
              />
            </div>
            {this.state.permissionsModalOpen ? (
              <Modal
                width={'65%'}
                title={
                  this.props.permissions && (
                    <div
                      style={{
                        display: 'flex',
                        color: '#3d404e',
                        fontSize: 17,
                      }}
                    >
                      View Permissions -
                      <span>
                        &nbsp;{this.props.permissions.user.presentation}
                      </span>
                      <span>
                        {'('}
                        {this.props.permissions.user.email}
                        {')'}
                      </span>
                    </div>
                  )
                }
                centered
                visible={this.state.permissionsModalOpen}
                onCancel={() => {
                  this.setState({ permissionsModalOpen: false });
                }}
                footer={
                  <Button
                    type="primary"
                    shape="round"
                    size={'large'}
                    loading={this.props.loading}
                    style={styles.buttons}
                    onClick={() =>
                      this.setState({ permissionsModalOpen: false })
                    }
                  >
                    {'Close'}
                  </Button>
                }
              >
                <div>{this.renderViewPermissionsContent()}</div>
              </Modal>
            ) : null}
          </React.Fragment>
        )}
      </Card>
    );
  }
}
export default MembersViewComponent;
