import WhatsAppTemplateModalContent from './WhatsAppTemplateModalContent';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { checkTemplatePermissions } from '../model/WhatsAppConst';
import React, { useEffect } from 'react';
import { Button, Modal } from 'antd';
import { isNil } from '@antv/util';
import {
  whatsAppChannelData,
  fetchWhatsAppChannelTemplate,
  setCurrentWhatsAppChannelTemplate,
  createWhatsAppChannelTemplate,
  updateWhatsAppChannelTemplate,
  setTemplateWhatsAppChannelList,
} from '@Redux/slices/channel/whatsAppChannelSlice';

const WhatsAppTemplateModal = ({ isOpen, onClose, templateId = null }) => {
  const dispatch = useDispatch();
  const templatePermissions = checkTemplatePermissions();

  const { whatsAppTemplateActionLoading, currentWhatsAppTemplate } =
    useSelector(whatsAppChannelData, shallowEqual);

  useEffect(() => {
    if (!isNil(templateId)) {
      dispatch(
        fetchWhatsAppChannelTemplate(
          templateId,
          templatePermissions.createOrUpdate
        )
      );
    }

    return () => {
      dispatch(setCurrentWhatsAppChannelTemplate({}));
      dispatch(setTemplateWhatsAppChannelList([]));
    };
  }, []);

  const handleCreateWhatsAppTemplate = () => {
    dispatch(createWhatsAppChannelTemplate(currentWhatsAppTemplate, onClose));
  };

  const handleEditWhatsAppTemplate = () => {
    dispatch(
      updateWhatsAppChannelTemplate(
        templateId,
        currentWhatsAppTemplate,
        onClose
      )
    );
  };

  return (
    <Modal
      width={650}
      title={templateId ? 'View WhatsApp Template' : 'Add WhatsApp Template'}
      centered
      visible={isOpen}
      onOk={onClose}
      onCancel={onClose}
      footer={
        !templateId
          ? [
              <Button key="cancel" shape="round" onClick={onClose}>
                CANCEL
              </Button>,
              <Button
                loading={whatsAppTemplateActionLoading}
                key="submit"
                shape="round"
                type="primary"
                disabled={!templatePermissions.createOrUpdate}
                onClick={
                  templateId
                    ? handleEditWhatsAppTemplate
                    : handleCreateWhatsAppTemplate
                }
              >
                Save
              </Button>,
            ]
          : []
      }
    >
      <WhatsAppTemplateModalContent isEditMode={!isNil(templateId)} />
    </Modal>
  );
};

export default WhatsAppTemplateModal;
