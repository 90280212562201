import UserChangePasswordComponent from '../component/UserChangePasswordComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  userChangeCurrent,
  userChangePasswordFetch,
  userChangePasswordSubmitFetch,
} from '../UserActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentUser: state.admin.user.currentUser,
  errors: state.admin.user.errors,
  loadingChangePassSubmit: state.admin.user.loadingChangePassSubmit,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      userChangeCurrent,
      userChangePasswordFetch,
      userChangePasswordSubmitFetch,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserChangePasswordComponent);

UserChangePasswordComponent.propTypes = {
  currentUser: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  userChangeCurrent: PropTypes.func.isRequired,
  userChangePasswordFetch: PropTypes.func.isRequired,
  userChangePasswordSubmitFetch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
