import { history } from '../../../redux/store';
import { filter } from 'lodash';
import { showMessageError } from '../../../utils/RenderUtils';
import toastr from 'toastr';
import {
  COMMUNICATION_CHANGE_CURRENT,
  COMMUNICATION_PAGE_CHANGE_CURRENT,
  COMMUNICATION_LIST_FETCH,
  COMMUNICATION_LIST_FETCH_FAIL,
  COMMUNICATION_LIST_FETCH_SUCCESS,
  COMMUNICATION_CREATE_CLEAR,
  COMMUNICATION_CREATE_FETCH,
  COMMUNICATION_CREATE_FETCH_FAIL,
  COMMUNICATION_CREATE_FETCH_SUCCESS,
  COMMUNICATION_AUDIENCE_CREATE_FETCH,
  COMMUNICATION_AUDIENCE_CREATE_FETCH_SUCCESS,
  COMMUNICATION_AUDIENCE_CREATE_FETCH_FAIL,
  COMMUNICATION_COPY_FETCH,
  COMMUNICATION_COPY_FETCH_FAIL,
  COMMUNICATION_COPY_FETCH_SUCCESS,
  COMMUNICATION_DELETE_FETCH,
  COMMUNICATION_DELETE_FETCH_FAIL,
  COMMUNICATION_DELETE_FETCH_SUCCESS,
  COMMUNICATION_LAUNCH_FETCH,
  COMMUNICATION_LAUNCH_FETCH_FAIL,
  COMMUNICATION_LAUNCH_FETCH_SUCCESS,
  COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH_SUCCESS,
  COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH_FAIL,
  COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH,
  COMMUNICATION_LIST_EVENTS_FETCH_SUCCESS,
  COMMUNICATION_LIST_EVENTS_FETCH_FAIL,
  COMMUNICATION_LIST_EVENTS_FETCH,
  COMMUNICATION_LIST_PUSH_CHANNELS_FETCH_SUCCESS,
  COMMUNICATION_LIST_PUSH_CHANNELS_FETCH_FAIL,
  COMMUNICATION_LIST_PUSH_CHANNELS_FETCH,
  COMMUNICATION_LIST_SEGMENTS_FETCH_SUCCESS,
  COMMUNICATION_LIST_SEGMENTS_FETCH_FAIL,
  COMMUNICATION_LIST_SEGMENTS_FETCH,
  COMMUNICATION_LIST_SMS_CHANNELS_FETCH_SUCCESS,
  COMMUNICATION_LIST_SMS_CHANNELS_FETCH_FAIL,
  COMMUNICATION_LIST_SMS_CHANNELS_FETCH,
  COMMUNICATION_LIST_TAG_FETCH,
  COMMUNICATION_LIST_TAG_FETCH_FAIL,
  COMMUNICATION_LIST_TAG_FETCH_SUCCESS,
  COMMUNICATION_OVERVIEW_FETCH,
  COMMUNICATION_OVERVIEW_FETCH_FAIL,
  COMMUNICATION_OVERVIEW_FETCH_SUCCESS,
  COMMUNICATION_SPLIT_OVERVIEW_FETCH,
  COMMUNICATION_SPLIT_OVERVIEW_FETCH_FAIL,
  COMMUNICATION_SPLIT_OVERVIEW_FETCH_SUCCESS,
  COMMUNICATION_SPLIT_OVERVIEW_PAGE_CHANGE_CURRENT,
  COMMUNICATION_PAUSE_FETCH,
  COMMUNICATION_PAUSE_FETCH_FAIL,
  COMMUNICATION_PAUSE_FETCH_SUCCESS,
  COMMUNICATION_STOP_FETCH,
  COMMUNICATION_STOP_FETCH_FAIL,
  COMMUNICATION_STOP_FETCH_SUCCESS,
  COMMUNICATION_TEST_FETCH,
  COMMUNICATION_TEST_FETCH_FAIL,
  COMMUNICATION_TEST_FETCH_SUCCESS,
  COMMUNICATION_AUDIENCE_RESET,
  COMMUNICATION_UPDATE_AUDIENCE_FETCH,
  COMMUNICATION_UPDATE_AUDIENCE_FETCH_FAIL,
  COMMUNICATION_UPDATE_AUDIENCE_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH,
  COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH_FAIL,
  COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_CONVERSION_FETCH,
  COMMUNICATION_UPDATE_CONVERSION_FETCH_FAIL,
  COMMUNICATION_UPDATE_CONVERSION_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH,
  COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH_FAIL,
  COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_MESSAGE_FETCH,
  COMMUNICATION_UPDATE_MESSAGE_FETCH_FAIL,
  COMMUNICATION_UPDATE_MESSAGE_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH,
  COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH_FAIL,
  COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_WHEN_FETCH,
  COMMUNICATION_UPDATE_WHEN_FETCH_FAIL,
  COMMUNICATION_UPDATE_WHEN_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH,
  COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH_FAIL,
  COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH_SUCCESS,
  COMMUNICATION_VIEW_FETCH,
  COMMUNICATION_VIEW_FETCH_FAIL,
  COMMUNICATION_VIEW_FETCH_SUCCESS,
  COMMUNICATION_COMMUNICATION_REPORT_FETCH,
  COMMUNICATION_COMMUNICATION_REPORT_FETCH_FAIL,
  COMMUNICATION_COMMUNICATION_REPORT_FETCH_SUCCESS,
  COMMUNICATION_VARIATION_REPORT_FETCH,
  COMMUNICATION_VARIATION_REPORT_FETCH_FAIL,
  COMMUNICATION_VARIATION_REPORT_FETCH_SUCCESS,
  COMMUNICATION_UPLOAD_FILE_FETCH,
  COMMUNICATION_UPLOAD_FILE_FETCH_FAIL,
  COMMUNICATION_UPLOAD_FILE_FETCH_SUCCESS,
  COMMUNICATION_UPLOAD_FILE_RESET,
  COMMUNICATION_ENGAGED_USER_FETCH,
  COMMUNICATION_ENGAGED_USER_FETCH_FAIL,
  COMMUNICATION_ENGAGED_USER_FETCH_SUCCESS,
  COMMUNICATION_ENGAGED_USER_PAGE_CHANGE_CURRENT,
  COMMUNICATION_DRAFT_SAVE,
  COMMUNICATION_DRAFT_SAVE_DISCARD,
  COMMUNICATION_CHANGE_CURRENT_AUDIENCE,
  COMMUNICATION_SEARCH_LIST_FETCH,
  COMMUNICATION_SEARCH_LIST_FETCH_SUCCESS,
  COMMUNICATION_SEARCH_LIST_FETCH_FAIL,
  COMMUNICATION_SEARCH_ENGAGED_USER_FETCH,
  COMMUNICATION_SEARCH_ENGAGED_USER_FETCH_SUCCESS,
  COMMUNICATION_SEARCH_ENGAGED_USER_FETCH_FAIL,
  COMMUNICATION_WHEN_CLEAR_ERROR,
  COMMUNICATION_AUDIENCE_CLEAR_CREATE_ERROR,
  COMMUNICATION_AUDIENCE_CLEAR_UPDATE_ERROR,
  COMMUNICATION_TRACKING_CLEAR_ERROR,
  COMMUNICATION_MESSAGE_CLEAR_ERROR,
  COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH,
  COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH_SUCCESS,
  COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH_FAIL,
  COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH,
  COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH_SUCCESS,
  COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH_FAIL,
  SEGMENT_REPORT_CHANGE_CURRENT,
  COMMUNICATION_ARCHIVE_FETCH_FAIL,
  COMMUNICATION_ARCHIVE_FETCH_SUCCESS,
  COMMUNICATION_ARCHIVE_FETCH,
  COMMUNICATION_ACTIVE_FETCH,
  COMMUNICATION_ACTIVE_FETCH_SUCCESS,
  COMMUNICATION_ACTIVE_FETCH_FAIL,
  COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH_SUCCESS,
  COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH_FAIL,
  COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH,
  COMMUNICATION_REPORT_CSV_FETCH,
  COMMUNICATION_REPORT_CSV_SUCCESS,
  COMMUNICATION_REPORT_CSV_FAIL,
  WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH,
  WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH_SUCCESS,
  WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH_FAIL,
  WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH,
  WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH_SUCCESS,
  WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH_FAIL,
  WHATSAPP_CHANNEL_TEMPLAE_VIEW_CHANGE,
  COMMUNICATION_FAILURES_REPORT_FETCH,
  COMMUNICATION_FAILURES_REPORT_SUCCESS,
  COMMUNICATION_FAILURES_REPORT_FAIL,
  COMMUNICATION_DELIVERY_CHECK,
  COMMUNICATION_DELIVERY_CHECK_SUCCESS,
  COMMUNICATION_DELIVERY_CHECK_FAIL,
} from './CommunicationConstants';
import CommunicationApi from './CommunicationApi';

export function communicationFailuresReportFetch(body) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_FAILURES_REPORT_FETCH,
      payload: body,
    });
    CommunicationApi.communicationFailuresReport(body)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_FAILURES_REPORT_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_FAILURES_REPORT_FAIL,
          payload: response,
        });
      });
  };
}
export function communicationCommunicationReportFetch(id, body) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_COMMUNICATION_REPORT_FETCH,
      payload: body,
    });
    CommunicationApi.communicationReport(id, body)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_COMMUNICATION_REPORT_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_COMMUNICATION_REPORT_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function communicationUploadFileFetch(file) {
  return (dispatch) => {
    dispatch({ type: COMMUNICATION_UPLOAD_FILE_FETCH });
    CommunicationApi.uploadFile(file)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_UPLOAD_FILE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPLOAD_FILE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function communicationUploadFileReset() {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPLOAD_FILE_RESET,
    });
  };
}

export function communicationVariationReportFetch(body) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_VARIATION_REPORT_FETCH,
      payload: body,
    });
    CommunicationApi.variationReport(body)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_VARIATION_REPORT_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_VARIATION_REPORT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function clearModalData() {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_CREATE_CLEAR,
      payload: {},
    });
  };
}
export function clearCommunicationWhenError(errors, errorKey = null) {
  return (dispatch) => {
    if (errorKey === null) {
      dispatch({
        type: COMMUNICATION_WHEN_CLEAR_ERROR,
        payload: [],
      });
    } else {
      const filteredErrors = filter(errors, (o) => o.key !== errorKey);
      dispatch({
        type: COMMUNICATION_WHEN_CLEAR_ERROR,
        payload: filteredErrors,
      });
    }
  };
}
export function clearCommunicationMessageError(errors, errorKey = null) {
  return (dispatch) => {
    if (errorKey === null) {
      dispatch({
        type: COMMUNICATION_MESSAGE_CLEAR_ERROR,
        payload: [],
      });
    } else {
      const filteredErrors = filter(errors, (o) => o.key !== errorKey);
      dispatch({
        type: COMMUNICATION_MESSAGE_CLEAR_ERROR,
        payload: filteredErrors,
      });
    }
  };
}

export function CommunicationAudienceClearCreateError() {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_AUDIENCE_CLEAR_CREATE_ERROR,
      payload: {},
    });
  };
}
export function CommunicationAudienceClearUpdateError() {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_AUDIENCE_CLEAR_UPDATE_ERROR,
      payload: {},
    });
  };
}
export function communicationAudienceReset() {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_AUDIENCE_RESET,
      payload: {},
    });
  };
}

export function communicationDraftSave(currentAudience) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_DRAFT_SAVE,
      payload: currentAudience,
    });
  };
}

export function communicationDraftSaveDiscard() {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_DRAFT_SAVE_DISCARD,
    });
  };
}

export function communicationCreateFetch(communication, copyId, isJourney) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_CREATE_FETCH,
      payload: communication,
    });
    CommunicationApi.create(communication)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_CREATE_FETCH_SUCCESS,
          payload: response,
          success: true,
        });
        if (response && response.id && !isJourney) {
          history.push(response.id + '/when');
        }
        // if (copyId) {
        //     history.push('../list');
        // } else {
        //     history.push('list');
        // }
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_CREATE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationAudienceCreateFetch(communication) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_AUDIENCE_CREATE_FETCH,
      payload: communication,
    });
    CommunicationApi.create(communication)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_AUDIENCE_CREATE_FETCH_SUCCESS,
          payload: response,
        });
        if (response && response.id) {
          if (communication.type === 'TRANSACTIONAL') {
            history.push(response.id + '/message');
          } else {
            history.push(response.id + '/when');
          }
        }
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_AUDIENCE_CREATE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function communicationCopyFetch(id, pageRequest, fromDetails = false) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_COPY_FETCH,
    });
    CommunicationApi.copy(id)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_COPY_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been duplicated successfully');
        if (fromDetails) {
          history.push('../../campaign-list/all');
        } else {
          loadCommunicationList(dispatch, pageRequest);
        }
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_COPY_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationDeleteFetch(id, communicationPageRequest) {
  return (dispatch) => {
    dispatch({ type: COMMUNICATION_DELETE_FETCH });
    CommunicationApi.delete(id)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_DELETE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been deleted successfully');
        loadCommunicationList(dispatch, communicationPageRequest);
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_DELETE_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function communicationLaunchFetch(id) {
  return (dispatch) => {
    dispatch({ type: COMMUNICATION_LAUNCH_FETCH });
    CommunicationApi.launch(id)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_LAUNCH_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been launched successfully');
        history.push('../../campaign-list/all');
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_LAUNCH_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

const loadCommunicationList = (dispatch, communicationPageRequest) => {
  dispatch({
    type: COMMUNICATION_LIST_FETCH,
    payload: communicationPageRequest,
  });
  CommunicationApi.list(communicationPageRequest)
    .then((response) => {
      dispatch({
        type: COMMUNICATION_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: COMMUNICATION_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};
const loadCommunicationSearchList = (dispatch, communicationPageRequest) => {
  dispatch({
    type: COMMUNICATION_SEARCH_LIST_FETCH,
    payload: communicationPageRequest,
  });
  CommunicationApi.list(communicationPageRequest)
    .then((response) => {
      dispatch({
        type: COMMUNICATION_SEARCH_LIST_FETCH_SUCCESS,
        payload: response,
      });
    })
    .catch((response) => {
      dispatch({
        type: COMMUNICATION_SEARCH_LIST_FETCH_FAIL,
        payload: response,
      });
    });
};

export function communicationEngagedUserReportFetch(engagedUserPageRequest) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_ENGAGED_USER_FETCH,
      payload: engagedUserPageRequest,
    });
    CommunicationApi.engagedUsers(engagedUserPageRequest)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_ENGAGED_USER_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_ENGAGED_USER_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function communicationSearchEngagedUserReportFetch(
  engagedUserPageRequest
) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_SEARCH_ENGAGED_USER_FETCH,
      payload: engagedUserPageRequest,
    });
    CommunicationApi.engagedUsers(engagedUserPageRequest)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_SEARCH_ENGAGED_USER_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_SEARCH_ENGAGED_USER_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function communicationEngagedUserPageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_ENGAGED_USER_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function communicationChangeCurrentAudience(currentAudience) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_CHANGE_CURRENT_AUDIENCE,
      payload: currentAudience,
    });
  };
}

export function communicationSearchListFetch(communicationPageRequest) {
  return (dispatch) =>
    loadCommunicationSearchList(dispatch, communicationPageRequest);
}
export function communicationListFetch(communicationPageRequest) {
  return (dispatch) =>
    loadCommunicationList(dispatch, communicationPageRequest);
}

export function communicationListEmailChannelsFetch() {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH,
    });
    CommunicationApi.listEmailChannels()
      .then((response) => {
        dispatch({
          type: COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_LIST_EMAIL_CHANNELS_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function whatsappChannelTemplateListFetch(whatsappPageRequest) {
  return (dispatch) => {
    dispatch({
      type: WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH,
      payload: whatsappPageRequest,
    });
    CommunicationApi.whatsappTemplateList(whatsappPageRequest)
      .then((response) => {
        dispatch({
          type: WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: WHATSAPP_CHANNEL_TEMPLAE_LIST_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function whatsappChannelTemplateViewFetch(id, index) {
  return (dispatch) => {
    dispatch({ type: WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH });
    CommunicationApi.whatsappTemplateView(id)
      .then((response) => {
        dispatch({
          type: WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH_SUCCESS,
          payload: { response, index },
        });
      })
      .catch((response) => {
        dispatch({
          type: WHATSAPP_CHANNEL_TEMPLAE_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function whatsappChannelTemplateViewChange(index, value) {
  return (dispatch) => {
    dispatch({
      type: WHATSAPP_CHANNEL_TEMPLAE_VIEW_CHANGE,
      payload: { index, value },
    });
  };
}

export function communicationListEventsFetch() {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_LIST_EVENTS_FETCH,
    });
    CommunicationApi.listEvents()
      .then((response) => {
        dispatch({
          type: COMMUNICATION_LIST_EVENTS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_LIST_EVENTS_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationListPushChannelsFetch() {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_LIST_PUSH_CHANNELS_FETCH,
    });
    CommunicationApi.listPushChannels()
      .then((response) => {
        dispatch({
          type: COMMUNICATION_LIST_PUSH_CHANNELS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_LIST_PUSH_CHANNELS_FETCH_FAIL,
          payload: response,
        });
      });
  };
}

export function communicationListSegmentsFetch() {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_LIST_SEGMENTS_FETCH,
    });
    CommunicationApi.listSegments()
      .then((response) => {
        dispatch({
          type: COMMUNICATION_LIST_SEGMENTS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_LIST_SEGMENTS_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationListSmsChannelsFetch() {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_LIST_SMS_CHANNELS_FETCH,
    });
    CommunicationApi.listSmsChannels()
      .then((response) => {
        dispatch({
          type: COMMUNICATION_LIST_SMS_CHANNELS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_LIST_SMS_CHANNELS_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationListCustomChannelsFetch() {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH,
    });
    CommunicationApi.listCustomChannels()
      .then((response) => {
        dispatch({
          type: COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_LIST_CUSTOM_CHANNELS_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationListTagsFetch() {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_LIST_TAG_FETCH,
    });
    CommunicationApi.listTags()
      .then((response) => {
        dispatch({
          type: COMMUNICATION_LIST_TAG_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_LIST_TAG_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationOverviewFetch(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_OVERVIEW_FETCH,
      payload: pageRequest,
    });
    CommunicationApi.overview(pageRequest)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_OVERVIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_OVERVIEW_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationSplitOverviewFetch(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_SPLIT_OVERVIEW_FETCH,
      payload: pageRequest,
    });
    CommunicationApi.splitOverview(pageRequest)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_SPLIT_OVERVIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_SPLIT_OVERVIEW_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationSplitOverviewPageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_SPLIT_OVERVIEW_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function communicationPauseFetch(id) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_PAUSE_FETCH,
    });
    CommunicationApi.pause(id)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_PAUSE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been paused successfully');
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_PAUSE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationStopFetch(id, pageRequest = null) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_STOP_FETCH,
    });
    CommunicationApi.stop(id)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_STOP_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been stopped successfully');
        if (pageRequest) {
          loadCommunicationList(dispatch, pageRequest);
        }
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_STOP_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationTestFetch(id, body) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_TEST_FETCH,
    });
    CommunicationApi.test(id, body)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_TEST_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been sent successfully');
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_TEST_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationUpdateAudienceFetch(id) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_AUDIENCE_FETCH,
    });
    CommunicationApi.updateAudience(id)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_AUDIENCE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_AUDIENCE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationUpdateSubmitAudienceFetch(
  id,
  communicationAudience,
  isJourney,
  communicationHasJourney = false
) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH,
      payload: communicationAudience,
    });
    CommunicationApi.updateAudienceSubmit(id, communicationAudience)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        if (communicationAudience.type === 'TRANSACTIONAL') {
          history.push('message');
        } else if (communicationHasJourney) {
          history.push('message');
        } else if (!isJourney) {
          history.push('when');
        }
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationUpdateTagsSubmitFetch(id, communicationAudiences) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH,
      payload: communicationAudiences,
    });
    CommunicationApi.updateAudienceSubmit(id, communicationAudiences)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_AUDIENCE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationUpdateConversionFetch(id) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_CONVERSION_FETCH,
    });
    CommunicationApi.updateConversion(id)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_CONVERSION_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_CONVERSION_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationUpdateSubmitConversionFetch(
  id,
  communicationConversion,
  isJourney,
  cb
) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH,
      payload: communicationConversion,
    });
    CommunicationApi.updateConversionSubmit(id, communicationConversion)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        if (!isJourney) {
          history.push('test');
        } else {
          cb && cb();
        }
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_CONVERSION_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationUpdateMessageFetch(id) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_MESSAGE_FETCH,
    });
    CommunicationApi.updateMessage(id)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_MESSAGE_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_MESSAGE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationUpdateSubmitMessageFetch(
  id,
  communicationMessage,
  isJourney
) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH,
      payload: communicationMessage,
    });
    CommunicationApi.updateMessageSubmit(id, communicationMessage)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        if (!isJourney) {
          history.push('conversion');
        }
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_MESSAGE_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationUpdateWhenFetch(id) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_WHEN_FETCH,
    });
    CommunicationApi.updateWhen(id)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_WHEN_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_WHEN_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationUpdateWhenThrottlingFetch() {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH,
    });
    CommunicationApi.updateWhenThrottling()
      .then((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_WHEN_THROTTLING_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function communicationUpdateSubmitWhenFetch(
  id,
  communicationWhen,
  isJourney
) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH,
      payload: communicationWhen,
    });
    CommunicationApi.updateWhenSubmit(id, communicationWhen)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH_SUCCESS,
          payload: response,
        });
        if (!isJourney) {
          history.push('message');
        }
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_UPDATE_WHEN_SUBMIT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationChangeCurrent(communication) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_CHANGE_CURRENT,
      payload: communication,
    });
  };
}

export function communicationPageChangeCurrent(pageRequest) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_PAGE_CHANGE_CURRENT,
      payload: pageRequest,
    });
  };
}

export function communicationViewFetch(id) {
  return (dispatch) => {
    dispatch({ type: COMMUNICATION_VIEW_FETCH });
    CommunicationApi.view(id)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_VIEW_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_VIEW_FETCH_FAIL,
          payload: response,
        });
      });
  };
}
export function communicationAudienceSegmentReportFetch(body) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH,
    });
    CommunicationApi.segmentReport(body)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH_SUCCESS,
          payload: response,
        });
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_AUDIENCE_SEGMENT_REPORT_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}
export function clearCommunicationTrackingError(errors, errorKey = null) {
  return (dispatch) => {
    if (errorKey === null) {
      dispatch({
        type: COMMUNICATION_TRACKING_CLEAR_ERROR,
        payload: [],
      });
    } else {
      const filteredErrors = filter(errors, (o) => o.key !== errorKey);
      dispatch({
        type: COMMUNICATION_TRACKING_CLEAR_ERROR,
        payload: filteredErrors,
      });
    }
  };
}

export function segmentReportCurrent(user) {
  return (dispatch) => {
    dispatch({
      type: SEGMENT_REPORT_CHANGE_CURRENT,
      payload: user,
    });
  };
}

export function communicationArchive(id) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_ARCHIVE_FETCH,
    });
    CommunicationApi.archive(id)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_ARCHIVE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been archived successfully');
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_ARCHIVE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationActivate(id) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_ACTIVE_FETCH,
    });
    CommunicationApi.active(id)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_ACTIVE_FETCH_SUCCESS,
          payload: response,
        });
        toastr.success('Has been activated successfully');
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_ACTIVE_FETCH_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationReportCsvFetch(body) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_REPORT_CSV_FETCH,
    });
    CommunicationApi.reportCsv(body)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_REPORT_CSV_SUCCESS,
          payload: response,
        });
        toastr.success('Has been saved successfully');
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_REPORT_CSV_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function communicationDeliveryCheck(id) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_DELIVERY_CHECK,
    });
    CommunicationApi.deliveryCheck(id)
      .then((response) => {
        dispatch({
          type: COMMUNICATION_DELIVERY_CHECK_SUCCESS,
          payload: response,
        });
        toastr.success('Delivery check was requested successfully');
      })
      .catch((response) => {
        dispatch({
          type: COMMUNICATION_DELIVERY_CHECK_FAIL,
          payload: response,
        });
        showMessageError(response);
      });
  };
}

export function resetCurrentMessage(payload) {
  return (dispatch) => {
    dispatch({
      type: COMMUNICATION_UPDATE_MESSAGE_FETCH_SUCCESS,
      payload,
    });
  };
}
