import Card from '@Utils/Card';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  backOfficeInvoiceListFetch,
  backOfficeContractProductDetailFetch,
  setContractDetail,
} from '@Redux/slices/account/backOfficeInvoiceSlice';
import { isEmpty } from 'lodash';
// import { defaultActions } from '@Component/form/ListBase';
import ListBase from '@Component/form/ListBase';
import { PAYMENT_STATUS } from './BackOfficeConstant';
import { readableDate } from '@Utils/DateUtils';
import { Alert, Button } from 'antd';
import { ArrowRightOutlined, EyeOutlined } from '@ant-design/icons';
import BackOfficeConfirmationReceiptModal from './BackOfficeConfirmationReceiptModal';

function BackOfficeInvoiceList(props) {
  const dispatch = useDispatch();
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const { backOfficeInvoiceList: invoiceList } = useSelector(
    (state) => state.account.backOffice
  );

  const loadingList = useSelector(
    (state) => state.account.backOffice.loadingList
  );
  const pageRequest = useSelector(
    (state) => state.account.backOffice.pageRequest
  );
  const contractDetail = useSelector(
    (state) => state.account.backOffice.contractDetail
  );

  const contractId = props.match.params.contractId;

  useEffect(() => {
    if (contractId) {
      dispatch(
        backOfficeInvoiceListFetch(contractId, {
          ...pageRequest,
          statuses: [
            'CREATED',
            'ISSUED',
            'PAYMENT_PENDING',
            'PAYMENT_FAILED',
            'PAID',
          ],
        })
      );

      dispatch(backOfficeContractProductDetailFetch(contractId));
    }

    return () => {
      dispatch(setContractDetail({}));
    };
  }, []);

  const columnList = [
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date) => readableDate(date),
    },
    {
      title: 'Invoice Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) =>
        text ? (
          <span
            className="Status"
            style={{
              fontSize: 11,
              fontWeight: '600',
              padding: '5px 10px',
              textTransform: 'uppercase',
              borderRadius: '50px',
              backgroundColor: PAYMENT_STATUS[text]?.bgColor || '#e7e8e9',
              color: PAYMENT_STATUS[text]?.color || '#94969e',
            }}
          >
            {text}
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Subscription Fee',
      dataIndex: 'basePrice',
      key: 'basePrice',
    },
    {
      title: 'Total Events Fee',
      dataIndex: 'totalEventsPrice',
      key: 'totalEventsPrice',
    },
    {
      title: '',
      dataIndex: '',
      key: '-action-',
      width: '5%',
      render: (x) => {
        return (
          <Button
            type="link"
            shape="rounded"
            onClick={() => {
              const url = `product/${contractDetail?.product?.id}/invoice-preview/${x.id}`;
              window.open(url, '_blank');
            }}
          >
            <div>
              <EyeOutlined style={{ fontSize: '18px' }} />
            </div>
          </Button>
        );
      },
    },
    // {
    //   title: 'ACTION',
    //   dataIndex: '',
    //   key: '-action-',
    //   width: '5%',
    //   render: (x) =>
    //     defaultActions(
    //       x,
    //       // {
    //       //   link: 'update/' + x.id,
    //       //   title: 'Update',
    //       //   enabled: true,
    //       // }
    //       // {
    //       //   link: 'view/' + x.id,
    //       //   title: 'View',
    //       //   enabled: true,
    //       // },
    //       // {
    //       //   link: 'pay/' + x.id,
    //       //   title: 'Pay',
    //       //   enabled: true,
    //       // }
    //ToDo: It is pend of backend to implement view endpoint of transaction
    //       {
    //         action: () => handleOpeningModal(x.id),
    //         title: 'Receipt',
    //         enabled: true,
    //         disablePopConfirm: true,
    //       }
    //     ),
    // },
  ];

  const renderTitle = () => {
    return (
      <span>
        Invoice Overview {<ArrowRightOutlined />}{' '}
        {contractDetail?.product?.name ?? '-'}
      </span>
    );
  };

  // const handleOpeningModal = (id) => {
  //   setIsReceiptModalOpen(true);
  //   setSelectedInvoiceId(id);
  // };

  const handleClosingModal = () => {
    setIsReceiptModalOpen(false);
    setSelectedInvoiceId(null);
  };

  return (
    <>
      <Card title={renderTitle()} loading={loadingList}>
        {!isEmpty(contractId) ? (
          <ListBase
            onChange={(p) =>
              dispatch(
                backOfficeInvoiceListFetch(contractId, {
                  ...pageRequest,
                  page: p.page,
                })
              )
            }
            page={invoiceList}
            pageRequest={pageRequest}
            columns={columnList}
            createLink={null}
          />
        ) : (
          <Alert
            style={{ margin: 20 }}
            message="There is no active contract for this product."
            type="warning"
          />
        )}
      </Card>
      {isReceiptModalOpen ? (
        <BackOfficeConfirmationReceiptModal
          isOpen={isReceiptModalOpen}
          onClose={handleClosingModal}
          selectedInvoiceId={selectedInvoiceId}
        />
      ) : null}
    </>
  );
}

export default BackOfficeInvoiceList;
