import SmsChannelComponent from '../component/SmsChannelComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  smsChanneSearchlListFetch,
  smsChannelListFetch,
  smsChannelPageChangeCurrent,
  smsChannelCreateFetch,
  smsChannelUpdateFetch,
  smsChannelUpdateSubmitFetch,
  smsChannelDeleteFetch,
  smsChannelRefreshTokenFetch,
  smsChannelChangeCurrent,
} from '../SmsChannelActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  loadingList: state.channel.smsChannel.loadingList,
  loadingSearchList: state.channel.smsChannel.loadingSearchList,
  page: state.channel.smsChannel.page,
  pageRequest: state.channel.smsChannel.pageRequest,
  loadingUpdateSubmit: state.channel.smsChannel.loadingUpdateSubmit,
  loadingUpdate: state.channel.smsChannel.loadingUpdate,
  currentSmsChannel: state.channel.smsChannel.currentSmsChannel,
  loadingDelete: state.channel.smsChannel.loadingDelete,
  loadingCreate: state.channel.smsChannel.loadingCreate,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      smsChannelListFetch,
      smsChanneSearchlListFetch,
      smsChannelPageChangeCurrent,
      smsChannelCreateFetch,
      smsChannelUpdateFetch,
      smsChannelUpdateSubmitFetch,
      smsChannelDeleteFetch,
      smsChannelRefreshTokenFetch,
      smsChannelChangeCurrent,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SmsChannelComponent);

SmsChannelComponent.propTypes = {
  page: PropTypes.object.isRequired,
  pageRequest: PropTypes.object.isRequired,
  customAttributeListFetch: PropTypes.func.isRequired,
  customAttributeDeleteFetch: PropTypes.func.isRequired,
  customAttributePageChangeCurrent: PropTypes.func.isRequired,
};
