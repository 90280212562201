export const PRODUCT_EVENT_LIST_FETCH = 'PRODUCT_EVENT_LIST_FETCH';
export const PRODUCT_EVENT_LIST_FETCH_SUCCESS =
  'PRODUCT_EVENT_LIST_FETCH_SUCCESS';
export const PRODUCT_EVENT_LIST_FETCH_FAIL = 'PRODUCT_EVENT_LIST_FETCH_FAIL';

export const PRODUCT_EVENT_ANALYTICS_FETCH = 'PRODUCT_EVENT_ANALYTICS_FETCH';
export const PRODUCT_EVENT_ANALYTICS_FETCH_SUCCESS =
  'PRODUCT_EVENT_ANALYTICS_FETCH_SUCCESS';
export const PRODUCT_EVENT_ANALYTICS_FETCH_FAIL =
  'PRODUCT_EVENT_ANALYTICS_FETCH_FAIL';

export const EVENT_CHANGE_CURRENT = 'EVENT_CHANGE_CURRENT';
