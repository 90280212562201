import React from 'react';
import { InfoCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Select, Spin } from 'antd';
import { cloneDeep, find as lodashFind, findIndex } from 'lodash';
import RevenueMappingFooterComponent from './RevenueMappingFooterComponent';
import texts from '@Utils/texts';

import { LoadingOutlined } from '@ant-design/icons';
import Card from '@Utils/Card';

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: '600',
    flex: 1,
    textAlign: 'right',
  },
  editField: {
    marginLeft: 20,
    width: 350,
  },
  addNewSlotButton: {
    marginTop: 20,
  },
};

class RevenueMappingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.revenueMappingListCurrenciesFetch();
    this.props.revenueMappingUpdateFetch();
    this.props.productSegmentListEventsFetch();
  }

  addRevenueItem = () => {
    const currentRevenueMapping =
      this.props.currentRevenueMapping &&
      this.props.currentRevenueMapping.revenueAttributes &&
      Array.isArray(this.props.currentRevenueMapping.revenueAttributes)
        ? cloneDeep(this.props.currentRevenueMapping.revenueAttributes)
        : [];
    this.props.revenueMappingChangeCurrent({
      ...this.props.currentRevenueMapping,
      revenueAttributes: currentRevenueMapping.concat({
        productEvent: null,
        productEventAttribute: null,
      }),
    });
  };

  handleClickSave = () => {
    this.props.revenueMappingUpdateSubmitFetch(
      this.props.currentRevenueMapping
    );
  };

  handleChangeValue = (key, value, index) => {
    const revenueAttributes = cloneDeep(
      this.props.currentRevenueMapping.revenueAttributes
    );
    revenueAttributes[index][key] = value;
    if (key === 'productEvent') {
      revenueAttributes[index]['productEventAttribute'] = null;
    }
    this.props.revenueMappingChangeCurrent({
      ...this.props.currentRevenueMapping,
      revenueAttributes,
    });
  };

  confirm = (e, index) => {
    const revenueAttributes =
      this.props.currentRevenueMapping &&
      this.props.currentRevenueMapping.revenueAttributes &&
      Array.isArray(this.props.currentRevenueMapping.revenueAttributes)
        ? cloneDeep(this.props.currentRevenueMapping.revenueAttributes)
        : [];
    revenueAttributes.splice(index, 1);
    this.props.revenueMappingChangeCurrent({
      ...this.props.currentRevenueMapping,
      revenueAttributes,
    });
  };

  cancel = (e) => {
    e.stopPropagation();
  };

  renderEventAttribute = (index) => {
    const selectedEventId =
      this.props.currentRevenueMapping &&
      this.props.currentRevenueMapping.revenueAttributes &&
      Array.isArray(this.props.currentRevenueMapping.revenueAttributes)
        ? this.props.currentRevenueMapping.revenueAttributes[index]
        : null;
    let attributes = null;
    if (selectedEventId && selectedEventId.productEvent) {
      const foundEvent = lodashFind(
        this.props.events,
        (o) => o.id === selectedEventId.productEvent
      );
      if (
        foundEvent &&
        foundEvent.attributes &&
        Array.isArray(foundEvent.attributes)
      ) {
        attributes = cloneDeep(foundEvent.attributes);
      }
    }
    return (
      <React.Fragment>
        {attributes &&
          Array.isArray(attributes) &&
          attributes.map((item) => {
            if (item.type === 'NUMERIC') {
              return (
                <Select.Option value={item.id} key={item.id}>
                  {item.title}
                </Select.Option>
              );
            }
            return null;
          })}
      </React.Fragment>
    );
  };

  renderEvent = () => {
    const events = [];
    this.props.events.forEach((item) => {
      let hasNumeric = -1;
      if (item.attributes && Array.isArray(item.attributes)) {
        hasNumeric = findIndex(item.attributes, (o) => o.type === 'NUMERIC');
      }
      if (hasNumeric > -1) {
        events.push(item);
      }
    });
    return (
      <React.Fragment>
        {events &&
          events.map((item) => (
            <Select.Option value={item.id} key={item.id}>
              {item.title}
            </Select.Option>
          ))}
      </React.Fragment>
    );
  };

  render() {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
    return (
      <>
        <Card
          styles={{
            marginBottom: 60,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              paddingBottom: 0,
              paddingLeft: '20px',
            }}
          >
            <div
              style={{
                color: '#000000',
                fontSize: 16,
                fontWeight: '600',
                paddingBottom: 30,
              }}
            >
              Revenue Mapping
              <span style={{ paddingLeft: 10 }}>
                {false && (
                  <InfoCircleOutlined
                    style={{ color: '#8C8C8C', fontSize: 12 }}
                  />
                )}
              </span>
            </div>
          </div>
          <div style={{ padding: 20 }}>
            <div>
              <span>
                Select the currency in which you report your revenues, and map
                all purchase events you’re sending to {texts.brandName} as a
                revenue event. For example, if you’re sending multiple purchase
                events to Usermost such as
                <i> Hotel Booked</i>,<i> Flight Booked</i> etc., you can map
                each of these events as a revenue event. This will indicate that
                a purchase has happened that will increase the revenue of your
                business. You will also have to map the corresponding
                <i> Number</i> attribute of the purchase event to denote the
                revenue amount. For example, in case of
                <i> Flight Booked</i>, the relevant attribute could be
                <i> Ticket Price</i>. Once your mapping is done, you will start
                seeing the impact of campaigns on your revenues (provided you’ve
                specified your conversion event to be one of the revenue events
                below).
                <br />
                <br />
                Please note that you can change this mapping at any time.
                However, if you change this mapping, it will not change any past
                revenue data that has already been recorded in {texts.brandName}
                , and will be applicable only for the future.
              </span>
            </div>
            {!this.props.loadingList ? (
              <div>
                <div style={{ marginTop: 20 }}>
                  <div
                    style={{
                      fontWeight: '600',
                      marginBottom: 15,
                      fontSize: 12,
                      letterSpacing: '.5px',
                      color: 'rgba(61,64,78,.55)',
                    }}
                  >
                    REVENUE CURRENCY
                  </div>
                  <Select
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    onChange={(revenueCurrency) =>
                      this.props.revenueMappingChangeCurrent({
                        ...this.props.currentRevenueMapping,
                        revenueCurrency,
                      })
                    }
                    value={
                      this.props.currentRevenueMapping &&
                      this.props.currentRevenueMapping.revenueCurrency
                    }
                    style={{ width: 325, position: 'relative', top: -5 }}
                    className={'chart-selector'}
                    loading={this.props.loadingCurrencies}
                    bordered={false}
                  >
                    {this.props.currencies &&
                      Array.isArray(this.props.currencies) &&
                      this.props.currencies.map((item) => (
                        <Select.Option value={item.code} key={item.code}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                {this.props.currentRevenueMapping &&
                  this.props.currentRevenueMapping.revenueAttributes &&
                  Array.isArray(
                    this.props.currentRevenueMapping.revenueAttributes
                  ) &&
                  this.props.currentRevenueMapping.revenueAttributes.map(
                    (item, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 20,
                          width: 800,
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontWeight: '600',
                              marginBottom: 15,
                              fontSize: 12,
                              letterSpacing: '.5px',
                              color: 'rgba(61,64,78,.55)',
                            }}
                          >
                            REVENUE EVENT
                          </div>
                          <Select
                            filterOption={(input, option) =>
                              option.props.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            onChange={(productEvent) =>
                              this.handleChangeValue(
                                'productEvent',
                                productEvent,
                                index
                              )
                            }
                            value={item.productEvent}
                            style={{
                              width: 325,
                              position: 'relative',
                              top: -5,
                            }}
                            className={'chart-selector'}
                            loading={this.props.loadingEvents}
                            bordered={false}
                          >
                            {this.renderEvent()}
                          </Select>
                        </div>
                        <div>
                          <div
                            style={{
                              fontWeight: '600',
                              marginBottom: 15,
                              fontSize: 12,
                              letterSpacing: '.5px',
                              color: 'rgba(61,64,78,.55)',
                            }}
                          >
                            REVENUE ATTRIBUTE (AMOUNT)
                          </div>
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <Select
                              filterOption={(input, option) =>
                                option.props.children
                                  .toString()
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              showSearch
                              onChange={(productEventAttribute) =>
                                this.handleChangeValue(
                                  'productEventAttribute',
                                  productEventAttribute,
                                  index
                                )
                              }
                              value={item.productEventAttribute}
                              style={{
                                width: 325,
                                position: 'relative',
                                top: -5,
                              }}
                              className={'chart-selector'}
                              loading={this.props.loadingEvents}
                              bordered={false}
                            >
                              {this.renderEventAttribute(index)}
                            </Select>
                            <div style={{ paddingLeft: 20 }}>
                              <Popconfirm
                                title="Are you sure delete this slot?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={(e) => this.confirm(e, index)}
                                onCancel={(e) => this.cancel(e, index)}
                              >
                                <MinusCircleOutlined
                                  style={{ color: '#bbbcc1', fontSize: 20 }}
                                />
                              </Popconfirm>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                <Button
                  type="primary"
                  shape="round"
                  size={'large'}
                  style={styles.addNewSlotButton}
                  ghost
                  onClick={() => this.addRevenueItem()}
                >
                  Add Event
                </Button>
              </div>
            ) : (
              <div style={styles.loadingSpinStyle}>
                <Spin indicator={loadingSpin} />
              </div>
            )}
          </div>
        </Card>

        <RevenueMappingFooterComponent
          loading={this.props.loadingUpdateSubmit}
          handleClickSave={() => this.handleClickSave()}
        />
      </>
    );
  }
}

export default RevenueMappingComponent;
