import React from 'react';
import { isNil } from 'lodash';
import { Button } from 'antd';

const styles = {
  footer: {
    position: 'fixed',
    bottom: 0,
    height: 60,
    backgroundColor: '#ffffff',
    boxShadow:
      '0 -2px 3px 0 rgba(61,64,78,.12), inset 0 -1px 0 0 rgba(61,64,78,.1)',
    padding: '10px 30px',
    transition: 'all .3s ease-in-out',
    left: 222,
    width: 'calc(100% - 222px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: '5',
  },
};

class RevenueMappingFooterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  handleClickSave = () => {
    if (!isNil(this.props.handleClickSave)) {
      this.props.handleClickSave();
    }
  };

  renderFooter = () => {
    return (
      <div style={styles.footer}>
        <div />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            type="primary"
            shape="round"
            size={'large'}
            loading={this.props.loading}
            style={{ margin: '0px 5px' }}
            className="w-200"
            onClick={() => this.handleClickSave()}
          >
            {'SAVE'}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    return <div>{this.renderFooter()}</div>;
  }
}

export default RevenueMappingFooterComponent;
