import React from 'react';
import './style.less';

const StatusContainer = ({
  children,
  bgColor = '#e7e8e9',
  color = '#94969e',
}) => {
  return (
    <span
      className="statusContainer"
      style={{
        backgroundColor: bgColor,
        color: color,
      }}
    >
      {children}
    </span>
  );
};

export default StatusContainer;
