import WhatsAppTemplateButtonConfig from './WhatsAppTemplateButtonConfig';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { checkTemplatePermissions } from '../model/WhatsAppConst';
import { Input, Select, Spin, Switch } from 'antd';
import lodashCloneDeep from 'lodash/cloneDeep';
import React from 'react';
import {
  whatsAppChannelData,
  setCurrentWhatsAppChannelTemplate,
  fetchTemplateWhatsAppChannelList,
  setTemplateWhatsAppChannelList,
} from '@Redux/slices/channel/whatsAppChannelSlice';
import {
  WhatsAppTemplateTypes,
  WhatsAppTemplateLanguages,
  actionButtonProperties,
} from '../model/WhatsAppConst';
import DebounceSelect from './DebounceSelect';

const WhatsAppTemplateModalContent = ({ isEditMode }) => {
  //if it was necessary to update later -> it is not possible to edit whatsApp channel//
  // const isFormDisabled = !templatePermissions?.createOrUpdate;
  const templatePermissions = checkTemplatePermissions();
  const isFormDisabled = isEditMode || !templatePermissions?.createOrUpdate;

  const dispatch = useDispatch();

  const {
    currentWhatsAppTemplate,
    currentWhatsAppTemplateLoading,
    templateWhatsAppChannelList,
  } = useSelector(whatsAppChannelData, shallowEqual);

  const handleInputChange = (e) => {
    dispatch(
      setCurrentWhatsAppChannelTemplate({
        ...currentWhatsAppTemplate,
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleSelectChange = (value, name) => {
    if (name === 'whatsAppChannel') {
      const temp = {
        name: value.value,
        id: value.key,
      };
      dispatch(setTemplateWhatsAppChannelList([]));
      dispatch(
        setCurrentWhatsAppChannelTemplate({
          ...currentWhatsAppTemplate,
          [name]: temp,
        })
      );
    } else {
      dispatch(
        setCurrentWhatsAppChannelTemplate({
          ...currentWhatsAppTemplate,
          [name]: value,
        })
      );
    }
  };

  const handleSwitchButtonChange = (value) => {
    const temp = lodashCloneDeep(currentWhatsAppTemplate);
    if (value) {
      temp.templateButtons = {
        buttons: [actionButtonProperties.QUICK_REPLY],
      };
    } else {
      delete temp.templateButtons;
    }

    dispatch(setCurrentWhatsAppChannelTemplate(temp));
  };

  const fetchChannelList = (searchInput, cb) => {
    dispatch(
      fetchTemplateWhatsAppChannelList({ page: 0, name: searchInput }, cb)
    );
  };

  return currentWhatsAppTemplateLoading ? (
    <div style={styles.spinnerContainer}>
      <Spin style={styles.spinner} />
    </div>
  ) : (
    <>
      <div style={styles.formContainer}>
        {/* WhatsApp Channel */}
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>
            <span style={styles.requiredMark}>*</span>WHATSAPP CHANNEL
          </div>
          <div style={styles.editFieldContainer}>
            <DebounceSelect
              value={currentWhatsAppTemplate?.whatsAppChannel?.name ?? null}
              placeholder="Select WhatsApp Channel"
              fetchOptions={fetchChannelList}
              onChange={(whatsAppChannel) =>
                handleSelectChange(whatsAppChannel, 'whatsAppChannel')
              }
              style={styles.editField}
              options={templateWhatsAppChannelList}
              disabled={isFormDisabled}
            />
          </div>
        </div>
        {/* Template Type */}
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>
            <span style={styles.requiredMark}>*</span>TEMPLATE TYPE
          </div>
          <div style={styles.editFieldContainer}>
            <Select
              value={currentWhatsAppTemplate?.headerType ?? null}
              style={styles.editField}
              size="large"
              disabled={isFormDisabled}
              onChange={(headerType) =>
                handleSelectChange(headerType, 'headerType')
              }
            >
              {Object.keys(WhatsAppTemplateTypes)?.map((item) => (
                <Select.Option value={item} key={item}>
                  {WhatsAppTemplateTypes[item]}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        {/* Template Name */}
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>
            <span style={styles.requiredMark}>*</span>TEMPLATE NAME
          </div>
          <div style={styles.editFieldContainer}>
            <Input
              name="name"
              disabled={isFormDisabled}
              value={currentWhatsAppTemplate?.name ?? ''}
              onChange={handleInputChange}
              size="large"
              style={styles.editField}
            />
          </div>
        </div>
        {/* Header */}
        {/*ToDo: We need header just for Text Type and we should not have this field for other types later */}
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>
            <span style={styles.requiredMark}>*</span>
            HEADER
          </div>
          <div style={styles.editFieldContainer}>
            <Input
              name="headerText"
              disabled={isFormDisabled}
              value={currentWhatsAppTemplate?.headerText ?? ''}
              onChange={handleInputChange}
              size="large"
              style={styles.editField}
            />
          </div>
        </div>
        {/* Template Text */}
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>
            <span style={styles.requiredMark}>*</span>TEMPLATE TEXT
          </div>
          <div style={styles.editFieldContainer}>
            <div style={styles.editField}>
              <Input.TextArea
                value={currentWhatsAppTemplate?.bodyText ?? ''}
                onChange={handleInputChange}
                autoSize={{ minRows: 5, maxRows: 6 }}
                name="bodyText"
                disabled={isFormDisabled}
                style={styles.textAreaStyle}
              />

              <span style={styles.currentCountText}>
                {'Current count:' +
                  (currentWhatsAppTemplate?.bodyText
                    ? currentWhatsAppTemplate?.bodyText.replace(
                        /{{[^}]*}}+/g,
                        ''
                      ).length
                    : 0)}
              </span>
            </div>
          </div>
        </div>
        {/* Template NameSpace */}
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>TEMPLATE NAMESPACE</div>
          <div style={styles.editFieldContainer}>
            <Input
              name="nameSpace"
              disabled={isFormDisabled}
              value={currentWhatsAppTemplate?.nameSpace ?? ''}
              onChange={handleInputChange}
              size="large"
              style={styles.editField}
            />
          </div>
        </div>
        {/* Template Language */}
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>
            <span style={styles.requiredMark}>*</span>TEMPLATE LANGUAGE
          </div>
          <div style={styles.editFieldContainer}>
            <Select
              value={currentWhatsAppTemplate?.language ?? null}
              style={styles.editField}
              size="large"
              disabled={isFormDisabled}
              onChange={(language) => handleSelectChange(language, 'language')}
            >
              {Object.keys(WhatsAppTemplateLanguages)?.map((item) => (
                <Select.Option
                  value={WhatsAppTemplateLanguages[item].key}
                  key={item}
                >
                  {WhatsAppTemplateLanguages[item].label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        {/* Button */}
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>BUTTONS</div>
          <div style={styles.editFieldContainer}>
            <div style={styles.editField}>
              <Switch
                checked={currentWhatsAppTemplate.hasOwnProperty(
                  'templateButtons'
                )}
                disabled={isFormDisabled}
                onChange={(value) => handleSwitchButtonChange(value)}
              />
            </div>
          </div>
        </div>
        {/* Button Config */}
        {currentWhatsAppTemplate.hasOwnProperty('templateButtons') ? (
          <WhatsAppTemplateButtonConfig isEditMode={isEditMode} />
        ) : null}
        {/* Footer */}
        <div style={styles.editContainer}>
          <div style={styles.editLabel}>
            <span style={styles.requiredMark}>*</span>FOOTER
          </div>
          <div style={styles.editFieldContainer}>
            <Input
              name="footer"
              disabled={isFormDisabled}
              onChange={handleInputChange}
              value={currentWhatsAppTemplate?.footer ?? ''}
              size="large"
              style={styles.editField}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatsAppTemplateModalContent;

const styles = {
  spinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    display: 'flex',
    alignSelf: 'center',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  editContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'ltr',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
  },
  editLabel: {
    color: 'rgba(61,64,78,.55)',
    marginRight: '5px',
    fontSize: 12,
    fontWeight: '600',
    flex: 1,
  },
  editFieldContainer: {
    flex: 1,
  },
  editField: {
    marginLeft: 20,
    width: 350,
  },
  currentCountText: {
    color: '#94969e',
    fontSize: '10px',
  },
  requiredMark: {
    color: '#ff4d4f',
    fontSize: '14px',
    marginRight: '4px',
  },
  textAreaStyle: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
};
