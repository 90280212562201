import ProductSegmentOverviewComponent from '../component/ProductSegmentOverviewComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  productSegmentViewFetch,
  productSegmentChangeCurrent,
  productSegmentReportViewFetch,
  productSegmentSizeReport,
} from '../ProductSegmentActions';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => ({
  currentProductSegment: state.insight.productSegment.currentProductSegment,
  currentReport: state.insight.productSegment.currentReport,
  loadingReport: state.insight.productSegment.loadingReport,
  loadingSegmentSizeReport:
    state.insight.productSegment.loadingSegmentSizeReport,
  segmentSizeReport: state.insight.productSegment.segmentSizeReport,
  pathname: state.router.location.pathname,
  loadingOverviewList: state.insight.productSegment.loadingOverviewList,
  loadingReportView: state.insight.productSegment.loadingReportView,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      productSegmentViewFetch,
      productSegmentChangeCurrent,
      productSegmentReportViewFetch,
      productSegmentSizeReport,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSegmentOverviewComponent);

ProductSegmentOverviewComponent.propTypes = {
  currentProductSegment: PropTypes.object.isRequired,
  productSegmentViewFetch: PropTypes.func.isRequired,
  productSegmentChangeCurrent: PropTypes.func.isRequired,
  productSegmentReportViewFetch: PropTypes.func.isRequired,
  productSegmentSizeReport: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};
