import React from 'react';
import { Link } from 'react-router-dom';
import ListBase from '../../../../component/form/ListBase';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { readableDateTime } from '../../../../utils/DateUtils';
import { numberWithCommas } from '../../../../utils/RenderUtils';
import { history } from '../../../../redux/store';
import LatestMessageStatus from '../model/LatestMessageStatus';
import {
  AuthorityProvider,
  hasAnyAuthority,
  getCommunicationId,
} from '../../../../utils/AuthorityProvider';
import { Input, Tooltip, Spin, Select } from 'antd';
import { isEmpty, isNil } from 'lodash';
import { HashBase64 } from '@Component/utils/HashBase64';
import vars from '@Theme/styles/vars';
import CsvButton from '@Component/utils/CsvExport/CsvButton';
import { featureAccessCheckCSVExport } from '@Utils/AccessManagement';
import Card from '@Utils/Card';

class CommunicationEngagedUserComponent extends React.Component {
  state = {
    name: '',
    selector: null,
    csvModalVisible: false,
  };

  componentDidMount() {
    this.communicationId = getCommunicationId(this.props.pathname);
    this.props.communicationEngagedUserReportFetch({
      ...this.props.communicationEngagedUserPageRequest,
      page: 0,
      communicationId: this.communicationId,
      query: '',
      eventFilter: this.state.selector,
    });
  }

  viewItem(id) {
    if (hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_ATTRIBUTE_VIEW)) {
      history.push(`view/${id}`);
    }
  }

  getItemLink = (row) => {
    const hashedUserId = HashBase64().encode(row.id);
    return `../../../users/view/${hashedUserId}/basic`;
  };

  handleChangeSearch = (name) => {
    this.setState({ name });
    if (name === '' || name == null) {
      this.props.communicationEngagedUserReportFetch({
        ...this.props.communicationEngagedUserPageRequest,
        query: null,
        page: 0,
        eventFilter: this.state.selector,
      });
    }
  };
  renderMessageStatus = (object) => {
    let result = [];
    if (
      isNil(object.latestVariationId) ||
      !object.hasOwnProperty('latestVariationId')
    ) {
      result.push(<span>Control Group</span>);
    } else if (
      object.failed &&
      (isEmpty(object.description) || isNil(object.description))
    ) {
      result.push(<span>Failed</span>);
    } else if (
      object.failed &&
      !(isEmpty(object.description) || isNil(object.description))
    ) {
      result.push(
        <Tooltip placement="top" title={object.description}>
          <span>Failed</span>
          <i
            className="fl-info"
            style={{
              color: '#bbbcc1',
              fontSize: 24,
              fontWeight: 400,
              position: 'relative',
              top: 6,
            }}
          ></i>
        </Tooltip>
      );
    } else if (object.queued) {
      result.push(<span>Queued</span>);
    } else {
      const latestMessageStatusMap = Object.assign({}, LatestMessageStatus);
      result.push(
        <span>{latestMessageStatusMap[object.latestMessageStatus]}</span>
      );
    }
    return result;
  };

  renderDatesTotal = (type, object) => {
    if (type === 'click') {
      return (
        <React.Fragment>
          <div>{'Total Numbers: ' + numberWithCommas(object.totalClicks)}</div>
          <div>
            {!isNil(object.latestClickDate)
              ? readableDateTime(object.latestClickDate)
              : ''}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div>
            {'Total Numbers: ' + numberWithCommas(object.totalConversions)}
          </div>
          <div>
            {!isNil(object.latestConversionDate)
              ? readableDateTime(object.latestConversionDate)
              : ''}
          </div>
        </React.Fragment>
      );
    }
  };

  renderUserId = (object) => {
    if (!isNil(object.userId)) {
      return object.userId;
    }
    return (
      <Tooltip placement={'top'} title={'Anonymous'}>
        {object.anonymousId}
        <i
          className="fl-info"
          style={{
            color: '#bbbcc1',
            fontSize: 24,
            fontWeight: 400,
            position: 'relative',
            top: 6,
          }}
        ></i>
      </Tooltip>
    );
  };

  render() {
    let columns;
    columns = [
      {
        title: 'ID',
        dataIndex: 'userId',
        key: 'userId',
        fixed: 'left',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 8 };
          }
        },
        render: (x, object) => (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Link
              to={this.getItemLink(object)}
              style={{ width: object.deleted ? 1000 : null }}
            >
              <span
                style={{
                  color: vars.primaryColor,
                  fontSize: 14,
                  cursor: 'pointer',
                }}
              >
                {this.renderUserId(object)}
              </span>
            </Link>
            {object.deleted && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'left',
                  fontSize: 15,
                }}
              >
                Data of this record is outdated
              </div>
            )}
          </div>
        ),
      },
      {
        title: 'latest status',
        dataIndex: 'type',
        key: 'type',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 0 };
          }
        },
        render: (x, object) => this.renderMessageStatus(object),
      },
      {
        title: 'execution date',
        dataIndex: 'executionDate',
        key: 'executionDate',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 0 };
          }
        },
        render: (x) => readableDateTime(x),
      },
      {
        title: 'sent date',
        dataIndex: 'sentDate',
        key: 'sentDate',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 0 };
          }
        },
        render: (x, object) => {
          if (
            isNil(object.latestVariationId) ||
            !object.hasOwnProperty('latestVariationId')
          ) {
            return;
          }
          return readableDateTime(x);
        },
      },
      {
        title: 'delivery date',
        dataIndex: 'deliveryDate',
        key: 'deliveryDate',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 0 };
          }
        },
        render: (x, object) => {
          if (
            isNil(object.latestVariationId) ||
            !object.hasOwnProperty('latestVariationId')
          ) {
            return;
          }
          return readableDateTime(x);
        },
      },
      {
        title: 'click details',
        dataIndex: 'totalClicks',
        key: 'totalClicks',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 0 };
          }
        },
        render: (x, object) => {
          if (
            isNil(object.latestVariationId) ||
            !object.hasOwnProperty('latestVariationId')
          ) {
            return;
          }
          return this.renderDatesTotal('click', object);
        },
      },
      {
        title: 'conversion details',
        dataIndex: 'totalConversions',
        key: 'totalConversions',
        onCell: (_) => {
          if (_.deleted) {
            return { colSpan: 0 };
          }
        },
        render: (x, object) => this.renderDatesTotal('conversion', object),
      },
      {
        title: 'revenue',
        dataIndex: 'revenue',
        key: 'revenue',
        onCell: (_) => {
          if (_.deleted === true) {
            return { colSpan: 0 };
          }
        },
        render: (x, object) => {
          if (
            isNil(object.latestVariationId) ||
            !object.hasOwnProperty('latestVariationId')
          ) {
            return;
          }
          return numberWithCommas(x);
        },
      },
    ];
    const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;
    return (
      <div style={{ padding: 30, position: 'relative' }}>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: 5,
            boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              borderBottom: 'solid 2px #f4f9ff',
            }}
          >
            {/* {this.state.csvModalVisible && (
              <ExportCsvModal
                currentUserEmail={
                  this.props.currentUser && this.props.currentUser.email
                }
                setCsvModalVisibilityFalse={() =>
                  this.setState({ csvModalVisible: false })
                }
                csvModalVisible={this.state.csvModalVisible}
              ></ExportCsvModal>
            )} */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Input
                loading={true}
                allowClear={
                  this.props.loadingSearchCommunicationEngagedUser
                    ? false
                    : true
                }
                placeholder={'Search Users by ID, Phone or Email'}
                onPressEnter={() =>
                  this.props.communicationSearchEngagedUserReportFetch({
                    ...this.props.communicationEngagedUserPageRequest,
                    page: 0,
                    query: this.state.name,
                    eventFilter: this.state.selector,
                  })
                }
                onChange={(e) => this.handleChangeSearch(e.target.value)}
                value={this.state.name}
                style={{ width: 300, borderRadius: 5 }}
                suffix={
                  isEmpty(this.state.name) ? (
                    <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  ) : (
                    this.props.loadingSearchCommunicationEngagedUser && (
                      <div>
                        <Spin indicator={inputLoadingSpin} />
                      </div>
                    )
                  )
                }
              />
              {featureAccessCheckCSVExport() && (
                <CsvButton
                  label="List Of Users"
                  callApi={() =>
                    this.props.callExportCommunicationFile(this.communicationId)
                  }
                  type="communication"
                ></CsvButton>
              )}
            </div>
            <Select
              onChange={(selector) => {
                this.setState({ selector }, () => {
                  this.props.communicationEngagedUserReportFetch({
                    ...this.props.communicationEngagedUserPageRequest,
                    query: this.state.name,
                    page: 0,
                    eventFilter: this.state.selector,
                  });
                });
              }}
              value={this.state.selector}
              style={{ width: 150, marginRight: 40 }}
              className={'chart-selector'}
              bordered={false}
            >
              <Select.Option value={null}>All Users</Select.Option>
              <Select.Option value="FAILED">Failed</Select.Option>
              <Select.Option value="CLICKED">Clicked</Select.Option>
              <Select.Option value="CONVERTED">Converted</Select.Option>
            </Select>
          </div>
          <Card loading={this.props.loadingCommunicationEngagedUser}>
            <ListBase
              className={'custom-table'}
              onChange={this.props.communicationEngagedUserReportFetch}
              page={this.props.communicationEngagedUser}
              pageRequest={this.props.communicationEngagedUserPageRequest}
              columns={columns}
              createLink={
                hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_USER_CREATE)
                  ? 'create'
                  : null
              }
            />
          </Card>
        </div>
      </div>
    );
  }
}

export default CommunicationEngagedUserComponent;
