import React, { useCallback, useContext, useState } from 'react';
import {
  getBezierPath,
  getMarkerEnd,
  getEdgeCenter,
} from 'react-flow-renderer';
import { AppContext } from '../../app';
import { NodesInbox } from '../../utils/static';
import { isEmpty } from 'lodash';
// const foreignObjectSize = 80;

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
  ...els
}) {
  const appContext = useContext(AppContext);
  const sourceNode = appContext?.journey?.steps.find(
    (m) => m.id + '' === els?.source + ''
  );
  const countSameEdge = data?.countSameEdge;
  // TODO if source and target have connections change targetXY
  let r = 0;
  if (countSameEdge) {
    // order X edges like this: 5 3 0 2 4
    if (countSameEdge % 2 === 0) {
      r = (countSameEdge / 2) * 30;
    } else {
      r = (countSameEdge / 2) * -30;
    }
  }

  let r2 = countSameEdge ? countSameEdge * 10 : 0;

  if (countSameEdge) {
    if (Math.abs(sourceY - targetY) < 80) {
      if (countSameEdge % 2 === 0) {
        r2 = (countSameEdge / 2) * 20;
      } else {
        r2 = (countSameEdge / 2) * -20;
      }
    }
  }

  const [isActive, setIsActive] = useState(false);

  let edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX: targetX + r,
    targetY: targetY + r2,
    targetPosition,
  });

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
    // sourcePosition,
    // targetPosition
  });

  try {
    edgePath = edgePath.split(' ');
    if (edgePath.length >= 3) {
      // edgePath = edgePath[0] + " " + edgePath[2] +" " + edgePath[3]
      edgePath = edgePath[0] + ' ' + edgePath[edgePath.length - 1];
    }
  } catch (e) {
    console.log('error-create-path', e);
  }

  let X = edgeCenterX + r;
  let Y = edgeCenterY + r2;

  // console.log('data-----', {
  //   id,
  //   sourceX,
  //   sourceY,
  //   targetX,
  //   targetY,
  //   sourcePosition,
  //   targetPosition,
  //   data,
  //   arrowHeadType,
  //   markerEndId,
  //   ...els
  // });

  // Maybe edge`s label be differ
  const getEdgeLabel = useCallback(() => {
    if (sourceNode?.type === 'TRAFFIC_SPLITTER') {
      const { on } = sourceNode;
      const list = [];
      on?.forEach((x) => {
        list.push(
          appContext?.journey?.steps.find((step) => step?.id + '' === x + '')
        );
      });
      const traffic = sourceNode?.ui?.traffic;
      const nodeTarget = list.find((m) => m?.id + '' === els?.target + '');
      if (!isEmpty(traffic)) {
        if (traffic[els?.target]) {
          return `${traffic[els?.target].label}-(${
            traffic[els?.target].onWeight
          }%)`;
        } else {
          return `${nodeTarget?.ui.label}-(0%)`;
        }
      } else {
        return `${nodeTarget?.ui.label}-(0%)`;
      }
    }
    let labelEdge_ = 'On ' + data.text.replace('on', '');
    if (data.text === 'onTimeOut') labelEdge_ = `On‌ Timeout`; // const labelEdge = data.text

    try {
      const sourceNode = appContext?.journey?.steps.find(
        (m) => m.id + '' === els?.source + ''
      );
      const nodeInfo = NodesInbox().find(
        (node) => node.data.key === sourceNode.type
      );
      const portInfo = nodeInfo.data.events.find((ev) => ev.name === data.text);

      if (portInfo.title) {
        return portInfo.title;
      }
    } catch (e) {
      return;
    }
    return labelEdge_;
  }, [id, sourceNode]);

  const labelEdge = getEdgeLabel();
  return (
    <>
      <defs>
        <filter x="0" y="0" width="1" height="1" id="solid">
          <feFlood floodColor="white" />
          <feComposite in="SourceGraphic" operator="xor" />
        </filter>
        <marker
          id="markerArrow"
          markerWidth="130"
          markerHeight="130"
          refX="2"
          refY="6"
          orient="auto"
        >
          <path d="M2,2 L2,11 L10,6 L2,2" style={{ fill: '#000' }} />
        </marker>
      </defs>
      <path
        id={id}
        // style={style}
        onMouseOver={() => {
          // var elm = document.getElementsByClassName('edge-button-foreignobject-'+id)[0]
          // elm.style.visibility = "visible"
          setIsActive(true);
        }}
        onMouseLeave={() => {
          // var elm = document.getElementsByClassName('edge-button-foreignobject-'+id)[0]
          // elm.style.visibility = "hidden"
          setIsActive(false);
        }}
        className={`react-flow__edge-path ${
          isActive ? 'edge-path-active' : ''
        }`}
        d={edgePath}
        markerEnd={markerEnd}
        // markerMid={"url(#markerArrow1)"}
        style={{
          ...style,
          markerMid: 'url(#markerArrow)',
          stroke: '#000 !important',
        }}
      />
      <text>
        <textPath
          href={`#${id}`}
          className={'edge-custom-arrow'}
          startOffset="60%"
        >
          ➤
        </textPath>
      </text>
      {isActive && (
        <text
          fill="red"
          onMouseOver={() => {
            // var elm = document.getElementsByClassName('edge-button-foreignobject-'+id)[0]
            // elm.style.visibility = "visible"
            setIsActive(true);
          }}
          onMouseLeave={() => {
            // var elm = document.getElementsByClassName('edge-button-foreignobject-'+id)[0]
            // elm.style.visibility = "hidden"
            setIsActive(false);
          }}
        >
          <textPath
            href={`#${id}`}
            className={'edge-custom-delete'}
            startOffset="40%"
            textAnchor="middle"
          >
            &nbsp;⤫
          </textPath>
        </text>
      )}

      <text
        x={X}
        y={Y}
        // filter="url(#solid)"
        className={isActive ? 'edge-title-hover' : 'edge-title'}
        onMouseOver={() => {
          // var elm = document.getElementsByClassName('edge-button-foreignobject-'+id)[0]
          // elm.style.visibility = "visible"
          setIsActive(true);
        }}
        onMouseLeave={() => {
          // var elm = document.getElementsByClassName('edge-button-foreignobject-'+id)[0]
          // elm.style.visibility = "hidden"
          setIsActive(false);
        }}
      >
        {/* <textPath 
          // id={'b_'+id}
          // href={`#${id}`}
          className={'edge-custom-label'} 
          startOffset={!countSameEdge ? "50%" : (((countSameEdge) * 10 + 35) + "%")}>
            &nbsp;{labelEdge}</textPath> */}
        {labelEdge}
      </text>
    </>
  );
}
