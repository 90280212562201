export const CUSTOM_CHANNEL_DELETE_FETCH = 'CUSTOM_CHANNEL_DELETE_FETCH';
export const CUSTOM_CHANNEL_DELETE_FETCH_FAIL =
  'CUSTOM_CHANNEL_DELETE_FETCH_FAIL';
export const CUSTOM_CHANNEL_DELETE_FETCH_SUCCESS =
  'CUSTOM_CHANNEL_DELETE_FETCH_SUCCESS';

export const CUSTOM_CHANNEL_CREATE_FETCH = 'CUSTOM_CHANNEL_CREATE_FETCH';
export const CUSTOM_CHANNEL_CREATE_FETCH_FAIL =
  'CUSTOM_CHANNEL_CREATE_FETCH_FAIL';
export const CUSTOM_CHANNEL_CREATE_FETCH_SUCCESS =
  'CUSTOM_CHANNEL_CREATE_FETCH_SUCCESS';

export const CUSTOM_CHANNEL_LIST_FETCH = 'CUSTOM_CHANNEL_LIST_FETCH';
export const CUSTOM_CHANNEL_LIST_FETCH_FAIL = 'CUSTOM_CHANNEL_LIST_FETCH_FAIL';
export const CUSTOM_CHANNEL_LIST_FETCH_SUCCESS =
  'CUSTOM_CHANNEL_LIST_FETCH_SUCCESS';

export const CUSTOM_CHANNEL_SEARCH_LIST_FETCH =
  'CUSTOM_CHANNEL_SEARCH_LIST_FETCH';
export const CUSTOM_CHANNEL_SEARCH_LIST_FETCH_FAIL =
  'CUSTOM_CHANNEL_SEARCH_LIST_FETCH_FAIL';
export const CUSTOM_CHANNEL_SEARCH_LIST_FETCH_SUCCESS =
  'CUSTOM_CHANNEL_SEARCH_LIST_FETCH_SUCCESS';

export const CUSTOM_CHANNEL_UPDATE_FETCH = 'CUSTOM_CHANNEL_UPDATE_FETCH';
export const CUSTOM_CHANNEL_UPDATE_FETCH_FAIL =
  'CUSTOM_CHANNEL_UPDATE_FETCH_FAIL';
export const CUSTOM_CHANNEL_UPDATE_FETCH_SUCCESS =
  'CUSTOM_CHANNEL_UPDATE_FETCH_SUCCESS';

export const CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH =
  'CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH';
export const CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL =
  'CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL';
export const CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS =
  'CUSTOM_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS';

export const CUSTOM_CHANNEL_CHANGE_CURRENT = 'CUSTOM_CHANNEL_CHANGE_CURRENT';
export const CUSTOM_CHANNEL_PAGE_CHANGE_CURRENT =
  'CUSTOM_CHANNEL_PAGE_CHANGE_CURRENT';

export const CUSTOM_CHANNEL_TOGGLE_ADD_MODAL =
  'CUSTOM_CHANNEL_TOGGLE_ADD_MODAL';
