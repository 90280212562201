import * as React from 'react';

function SvgUnread(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#fff" transform="translate(-6 -5)" fillRule="evenodd">
        <path
          d="M21.487 10.027c-.104-.023-.233.007-.372.095l-5.48 3.488c-.345.22-.92.223-1.27 0l-5.48-3.488c-.345-.22-.635-.064-.635.355v.2c0 .428.284.94.635 1.163l5.48 3.488c.345.22.92.223 1.27 0l5.48-3.488c.345-.22.635-.743.635-1.162v-.201a.79.79 0 00-.013-.148 2.642 2.642 0 002.263.794v9.156c0 .95-.772 1.721-1.725 1.721H7.725A1.723 1.723 0 016 20.28l.009-10.562c0-.95.763-1.718 1.716-1.718h13.321a2.664 2.664 0 00.441 2.027z"
          fillRule="nonzero"
        />
        <circle cx={23.95} cy={8.15} r={2.25} />
      </g>
    </svg>
  );
}

export default SvgUnread;
