import { createSlice } from '@reduxjs/toolkit';
import { showMessageError } from '@Utils/RenderUtils';
//import { history } from '@Redux/store';
import toastr from 'toastr';
//import { appLoading } from './dataSlice';
import moment from 'moment';
import CustomEventApi from './customEventApi';
const lastWeek = moment().subtract(7, 'days');
const initialState = {
  page: {},
  loadingList: false,
  loadingSearchList: false,
  pageRequest: {},
  errors: [],

  currentCustomEvent: {},

  errorsUpdate: [],
  loadingUpdate: false,

  errorsUpdateSubmit: [],
  loadingUpdateSubmit: false,
  loadingDelete: false,
  loadingMissMatch: false,
  customEventMissMatchFilter: {
    fromDate: lastWeek,
    eventAttributeId: '',
    sdk: '',
  },
  customEventMissMatch: {},
};

export const customEventSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    loadCustomEventListStart(state, { payload }) {
      state.pageRequest = payload;
      state.loadingList = true;
    },
    loadCustomEventListSuccess(state, { payload }) {
      state.page = payload;
      state.loadingList = false;
    },
    loadCustomEventListFail(state, { payload }) {
      state.errors = payload.errors;
      state.loadingList = false;
    },
    customEventChangeCurrent(state, { payload }) {
      state.currentCustomEvent = payload;
    },
    customEventPageChangeCurrent(state, { payload }) {
      state.pageRequest = payload;
    },
    customEventUpdateFetchStart(state) {
      state.loadingUpdate = true;
    },
    customEventUpdateFetchSuccess(state, { payload }) {
      state.loadingUpdate = false;
      state.currentCustomEvent = payload;
    },
    customEventUpdateFetchFail(state, { payload }) {
      state.errors = payload.errors;
      state.loadingUpdate = false;
    },
    customEventUpdateSubmitFetchStart(state) {
      state.loadingUpdateSubmit = true;
    },
    customEventUpdateSubmitFetchSuccess(state) {
      state.loadingUpdateSubmit = false;
    },
    customEventUpdateSubmitFetchFail(state, { payload }) {
      state.loadingUpdateSubmit = false;
      state.errors = payload.errors;
    },
    customEventDeleteFetchStart(state) {
      state.loadingDelete = true;
    },
    customEventDeleteFetchSuccess(state) {
      state.loadingDelete = false;
    },
    customEventDeleteFetchFail(state, { payload }) {
      state.loadingDelete = false;
      state.errors = payload.errors;
    },
    costumEventMissMatchReportStart(state) {
      state.loadingMissMatch = true;
    },
    costumEventMissMatchReportSuccess(state, { payload }) {
      state.loadingMissMatch = false;
      state.customEventMissMatch = payload;
    },
    costumEventMissMatchReportFail(state, { payload }) {
      state.loadingMissMatch = true;
      state.errors = payload.errors;
    },
    customEventMisMatchChangeCurrent(state, { payload }) {
      state.customEventMissMatchFilter = payload;
    },
    loadCustomEventSearchListStart(state, { payload }) {
      state.pageRequest = payload;
      state.loadingSearchList = true;
    },
    loadCustomEventSearchListSuccess(state, { payload }) {
      state.page = payload;
      state.loadingSearchList = false;
    },
    loadCustomEventSearchListFail(state, { payload }) {
      state.errors = payload.errors;
      state.loadingSearchList = false;
    },
  },
});

export default customEventSlice.reducer;

export const {
  loadCustomEventListStart,
  loadCustomEventListSuccess,
  loadCustomEventListFail,
  customEventChangeCurrent,
  customEventPageChangeCurrent,
  customEventUpdateFetchStart,
  customEventUpdateFetchSuccess,
  customEventUpdateFetchFail,
  customEventUpdateSubmitFetchStart,
  customEventUpdateSubmitFetchSuccess,
  customEventUpdateSubmitFetchFail,
  customEventDeleteFetchStart,
  customEventDeleteFetchSuccess,
  customEventDeleteFetchFail,
  costumEventMissMatchReportStart,
  costumEventMissMatchReportSuccess,
  costumEventMissMatchReportFail,
  customEventMisMatchChangeCurrent,
  loadCustomEventSearchListStart,
  loadCustomEventSearchListSuccess,
  loadCustomEventSearchListFail,
} = customEventSlice.actions;

const loadCustomEventList = (customEventPageRequest) => async (dispatch) => {
  dispatch(loadCustomEventListStart(customEventPageRequest));
  try {
    const response = await CustomEventApi.list(customEventPageRequest);
    dispatch(loadCustomEventListSuccess(response));
  } catch (error) {
    dispatch(loadCustomEventListFail(error));
  }
};
const loadCustomEventSearchList =
  (customEventPageRequest) => async (dispatch) => {
    dispatch(loadCustomEventSearchListStart(customEventPageRequest));
    try {
      const response = await CustomEventApi.list(customEventPageRequest);
      dispatch(loadCustomEventSearchListSuccess(response));
    } catch (error) {
      dispatch(loadCustomEventSearchListFail(error));
    }
  };

export const customEventListFetch = (customEventPageRequest) => (dispatch) => {
  try {
    dispatch(loadCustomEventList(customEventPageRequest));
  } catch (error) {
    showMessageError(error);
  }
};
export const customEventSearchListFetch =
  (customEventPageRequest) => (dispatch) => {
    try {
      dispatch(loadCustomEventSearchList(customEventPageRequest));
    } catch (error) {
      showMessageError(error);
    }
  };

export const customEventUpdateFetch = (id) => async (dispatch) => {
  dispatch(customEventUpdateFetchStart());

  try {
    const response = await CustomEventApi.update(id);
    dispatch(customEventUpdateFetchSuccess(response));
  } catch (error) {
    dispatch(customEventUpdateFetchFail(error));
  }
};

export const customEventUpdateSubmitFetch =
  (id, customEvent, pageRequest) => async (dispatch) => {
    dispatch(customEventUpdateSubmitFetchStart());
    try {
      const response = await CustomEventApi.updateSubmit(id, customEvent);
      dispatch(customEventUpdateSubmitFetchSuccess(response));
      toastr.success('Has been updated successfully');
      dispatch(loadCustomEventList(pageRequest));
    } catch (error) {
      dispatch(customEventUpdateSubmitFetchFail(error));
      showMessageError(error);
    }
  };

export const customEventDeleteFetch = (id) => async (dispatch) => {
  dispatch(customEventDeleteFetchStart());
  try {
    const response = await CustomEventApi.delete(id);
    dispatch(customEventDeleteFetchSuccess(response));
  } catch (error) {
    dispatch(customEventDeleteFetchFail(error));
  }
};

export const costumEventMissMatchReport = (body) => async (dispatch) => {
  dispatch(costumEventMissMatchReportStart());
  try {
    const response = await CustomEventApi.customEventMissMatch(body);
    dispatch(costumEventMissMatchReportSuccess(response));
  } catch (error) {
    dispatch(costumEventMissMatchReportFail(error));
  }
};
