import React from 'react';
import { Link } from 'react-router-dom';
import ListBase, { defaultActions } from '../../../../component/form/ListBase';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import {
  numberWithCommas,
  renderCampaignStatus,
  renderLowerFont,
} from '../../../../utils/RenderUtils';
import { history } from '../../../../redux/store';
import {
  AuthorityProvider,
  hasAnyAuthority,
  getChannel,
  renderCampaignType,
} from '../../../../utils/AuthorityProvider';
import { readableDateTime } from '../../../../utils/DateUtils';
import { Badge, Button, Dropdown, Input, Modal, Select, Spin } from 'antd';
import { isEmpty, isNil } from 'lodash';
import TypeSelector from '../../../../component/form/TypeSelector';
import TagSelector from '../../../../component/form/TagSelector';
import { renderTag } from '../../../../utils/RenderUtils';
import TagInput from '../../../../component/form/TagInput';
import toastr from 'toastr';
import { LoadingOutlined } from '@ant-design/icons';
// import { HashBase64 } from '../../../../component/utils/HashBase64';
import vars from '@Theme/styles/vars';
import Card from '@Utils/Card';
const loadingSpin = <LoadingOutlined style={{ fontSize: 35 }} spin />;
const inputLoadingSpin = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const styles = {
  loadingSpinStyle: {
    height: 180,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
  },
  inputLoadingSpinStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
};

const allStatus = [
  'DRAFT',
  'UPCOMING',
  'RUNNING',
  'ENDED',
  'PAUSED',
  'STOPPED',
  'W_UPCOMING',
  'W_RUNNING',
];

const defaultTypes = ['ONE_TIME', 'TRIGGERED', 'RECURRING', 'TRANSACTIONAL'];

class CommunicationListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    name: this.getUrlParamValue('name') ?? '',
    selectedTagsRowIndex: -1,
  };

  getUrlParamValue(x) {
    // const deCodeParam = HashBase64().decode(this.props?.location?.search);
    // const params = new URLSearchParams(deCodeParam);
    // return +params.get(x);
    return new URLSearchParams(this.props.location?.search).get(x);
  }

  deleteUrlParam(x) {
    const params = new URLSearchParams(this.props.location?.search);
    if (params.has(x)) {
      params.delete(x);
      return '?' + params.toString();
    }
    return this.props.location?.search;
  }

  deleteUrlParams(keysToDelete) {
    const params = new URLSearchParams(this.props.location?.search);

    keysToDelete.forEach((key) => {
      if (params.has(key)) {
        params.delete(key);
      }
    });

    return params.toString() ? '?' + params.toString() : '';
  }

  updateQueryStringParameter = (uri, key, value) => {
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    var separator = uri?.indexOf('?') !== -1 ? '&' : '?';
    if (uri?.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      return uri + separator + key + '=' + value;
    }
  };

  componentDidMount() {
    const pageValue = this.getUrlParamValue('page') - 1;
    const nameValue = this.getUrlParamValue('name');
    const statusValue = this.getUrlParamValue('status');
    const typesValue =
      this.getUrlParamValue('types') === null
        ? null
        : this.getUrlParamValue('types').split(',');
    const tagsValue =
      this.getUrlParamValue('tags') === null
        ? null
        : this.getUrlParamValue('tags').split(',');

    const channel = getChannel(this.props.pathname);
    if (this.props.isArchived) {
      this.props.communicationListFetch({
        ...this.props.pageRequest,
        tags: tagsValue,
        types: typesValue ?? defaultTypes,
        archived: true,
        page: `${pageValue > 0 ? pageValue : 0}`,
        name: nameValue,
        direction: 'DESC',
        order: 'modifiedDate',
        channel,
        status:
          statusValue === 'ALL'
            ? allStatus
            : statusValue === null
            ? null
            : [statusValue],
      });
    } else {
      this.props.communicationListFetch({
        ...this.props.pageRequest,
        tags: tagsValue,
        types: typesValue ?? defaultTypes,
        status:
          statusValue === 'ALL'
            ? allStatus
            : statusValue === null
            ? null
            : [statusValue],
        page: `${pageValue > 0 ? pageValue : 0}`,
        name: nameValue,
        archived: false,
        direction: 'DESC',
        order: 'modifiedDate',
        channel,
      });
    }

    this.props.communicationListTagsFetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.pathname !== prevProps.pathname) {
      const channel = getChannel(this.props.pathname);
      this.setState({
        name: '',
      });
      const Archived = this.props.pathname.includes('archive');
      if (Archived) {
        this.props.communicationListFetch({
          ...this.props.pageRequest,
          channel,
          tags: null,
          types: defaultTypes,
          archived: Archived,
          page: 0,
          name: null,
          direction: 'DESC',
          order: 'modifiedDate',
          status: allStatus,
        });
      } else {
        this.props.communicationListFetch({
          ...this.props.pageRequest,
          channel,
          tags: null,
          types: defaultTypes,
          archived: false,
          status: allStatus,
          page: 0,
          name: null,
          direction: 'DESC',
          order: 'modifiedDate',
        });
      }
    }
    if (
      this.props.loadingUpdateAudienceSubmit === false &&
      prevProps.loadingUpdateAudienceSubmit === true &&
      isEmpty(this.props.errorUpdateAudienceSubmit)
    ) {
      this.setState({ selectedTagsRowIndex: -1 }, () => {
        this.props.communicationListFetch(this.props.pageRequest);
        toastr.success('Has been updated successfully');
      });
    }
    if (
      this.props.archiveSuccess !== prevProps.archiveSuccess ||
      this.props.activeSuccess !== prevProps.activeSuccess
    ) {
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    this.props.communicationPageChangeCurrent({});
  }

  viewItem(id) {
    if (hasAnyAuthority(AuthorityProvider.ROLE_PRODUCT_CUSTOM_ATTRIBUTE_VIEW)) {
      history.push(`view/${id}`);
    }
  }

  getNavigation() {
    return [{ name: 'Custom Attribute List', path: '' }];
  }

  getItemLink = (row) => {
    if (row.status === 'DRAFT' || row.status === 'UPCOMING') {
      return `../campaigns/${row.id}/audience`;
    } else {
      return `../campaigns/${row.id}/overview`;
    }
  };

  handleChangeSearch = (name) => {
    this.setState({ name });
    if (name === '' || name === null) {
      this.props.communicationListFetch({
        ...this.props.pageRequest,
        name: null,
        page: 0,
      });

      const UrlPrams = this.deleteUrlParams(['name', 'page']);

      this.props.history.push({
        pathname: this.props.history.location.pathname,
        search: UrlPrams,
      });
    }
  };

  handleSearchName = () => {
    this.props.communicationSearchListFetch({
      ...this.props.pageRequest,
      page: 0,
      name: this.state.name,
    });

    const newUrlPrams = this.deleteUrlParam('page');

    const params = this.updateQueryStringParameter(
      newUrlPrams,
      'name',
      this.state.name
    );

    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: params,
    });
  };

  handleChangeStatus = (searchStatus) => {
    {
      let params;
      const newUrlPrams = this.deleteUrlParam('page');

      if (searchStatus === 'ALL') {
        this.props.communicationListFetch({
          ...this.props.pageRequest,
          page: 0,
          status: allStatus,
        });
        params = this.updateQueryStringParameter(newUrlPrams, 'status', 'ALL');
      } else {
        this.props.communicationListFetch({
          ...this.props.pageRequest,
          page: 0,
          status: [`${searchStatus}`],
        });
        params = this.updateQueryStringParameter(newUrlPrams, 'status', [
          `${searchStatus}`,
        ]);
      }

      this.props.history.push({
        pathname: this.props.history.location.pathname,
        search: params,
      });
    }
  };

  handleApplyTags = (tags) => {
    let params;
    const newUrlPrams = this.deleteUrlParam('page');

    document.getElementById('tag-selector').click();
    if ((Array.isArray(tags) && tags.length === 0) || isNil(tags)) {
      this.props.communicationListFetch({
        ...this.props.pageRequest,
        tags: null,
        page: 0,
      });
      params = this.deleteUrlParam('tags');
    } else {
      this.props.communicationListFetch({
        ...this.props.pageRequest,
        tags,
        page: 0,
      });
      params = this.updateQueryStringParameter(newUrlPrams, 'tags', tags);
    }

    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: params,
    });
  };

  handleApplyTypes = (types) => {
    let params;
    const newUrlPrams = this.deleteUrlParam('page');

    document.getElementById('type-selector').click();
    if ((Array.isArray(types) && types.length === 0) || isNil(types)) {
      this.props.communicationListFetch({
        ...this.props.pageRequest,
        types: null,
        page: 0,
      });
      params = this.deleteUrlParam('types');
    } else {
      this.props.communicationListFetch({
        ...this.props.pageRequest,
        types,
        page: 0,
      });
      params = this.updateQueryStringParameter(newUrlPrams, 'types', types);
    }

    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: params,
    });
  };

  handleClearDraftCommunication = () => {
    this.props.communicationDraftSaveDiscard();
  };

  renderSelectedCount = (selector) => {
    if (
      this.props.pageRequest &&
      this.props.pageRequest[selector] &&
      Array.isArray(this.props.pageRequest[selector]) &&
      this.props.pageRequest[selector].length > 0
    ) {
      return this.props.pageRequest[selector].length;
    }
    return null;
  };

  renderTagSelector = () => {
    return (
      <Dropdown
        overlay={
          <TagSelector
            listTags={this.props.listTags}
            selectedTags={
              this.props.pageRequest &&
              this.props.pageRequest.tags &&
              Array.isArray(this.props.pageRequest.tags)
                ? this.props.pageRequest.tags
                : null
            }
            onApply={(tags) => this.handleApplyTags(tags)}
          />
        }
        trigger={['click']}
      >
        <div
          id="tag-selector"
          ref={this.myRef}
          style={{
            width: 80,
            marginRight: 20,
            display: 'flex',
            flexDirection: 'row',
            color: '#1C1C1C',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F3F3F3',
            cursor: 'pointer',
          }}
        >
          <Badge
            offset={[10, 0]}
            count={this.renderSelectedCount('tags')}
            size="small"
            showZero={false}
          >
            Tags
          </Badge>{' '}
          <DownOutlined
            style={{ marginLeft: 20, color: '#b6b6b6', fontSize: 12 }}
          />
        </div>
      </Dropdown>
    );
  };

  renderTypeSelector = () => {
    const listTypes = [
      { title: 'Journey', name: 'JOURNEY' },
      { title: 'Relays', name: 'RELAY' },
      { title: 'One-Time', name: 'ONE_TIME' },
      { title: 'Triggered', name: 'TRIGGERED' },
      { title: 'Recurring', name: 'RECURRING' },
      { title: 'Transactional', name: 'TRANSACTIONAL' },
    ];
    return (
      <Dropdown
        overlay={
          <TypeSelector
            listTypes={listTypes}
            selectedTypes={
              this.props.pageRequest &&
              this.props.pageRequest.types &&
              Array.isArray(this.props.pageRequest.types)
                ? this.props.pageRequest.types
                : null
            }
            onApply={(types) => this.handleApplyTypes(types)}
          />
        }
        trigger={['click']}
      >
        <div
          id="type-selector"
          style={{
            width: 90,
            marginRight: 20,
            display: 'flex',
            flexDirection: 'row',
            color: '#1C1C1C',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#F3F3F3',
            cursor: 'pointer',
          }}
        >
          <Badge
            offset={[10, 0]}
            count={this.renderSelectedCount('types')}
            size="small"
            showZero={false}
          >
            Types
          </Badge>{' '}
          <DownOutlined
            style={{ marginLeft: 20, color: '#b6b6b6', fontSize: 12 }}
          />
        </div>
      </Dropdown>
    );
  };

  openTagList = (index) => {
    if (this.props.page.content[index].id) {
      this.setState({ selectedTagsRowIndex: index }, () => {
        this.props.communicationUpdateAudienceFetch(
          this.props.page.content[this.state.selectedTagsRowIndex].id
        );
      });
    }
  };

  onOkModal = () => {
    this.props.communicationUpdateTagsSubmitFetch(
      this.props.page.content[this.state.selectedTagsRowIndex].id,
      this.props.currentAudience
    );
  };

  renderListTags = (index, tags) => {
    if (tags && Array.isArray(tags) && tags.length > 0) {
      let result = [];
      result.push(
        <span
          style={{
            marginRight: 10,
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {renderTag(tags[0], this.props.listTags)}
        </span>
      );
      if (tags.length > 1) {
        result.push(
          <span
            onClick={() => this.openTagList(index)}
            style={{
              fontSize: 12,
              cursor: 'pointer',
              color: vars.primaryColor,
              textDecoration: 'underline',
            }}
          >
            {'+' + (tags.length - 1) + ' more'}
          </span>
        );
      }
      return result;
    }
    return '-';
  };

  renderAllTags = () => {
    return (
      <TagInput
        containerStyle={{
          display: 'flex',
          flexDirection: 'column',
          flex: 6,
          fontSize: 13,
          lineHeight: 1.43,
          color: '#3d404e',
        }}
        disabled={false}
        style={{ width: 500 }}
        tags={this.props.currentAudience.tags}
        onChange={(value) =>
          this.props.communicationChangeCurrentAudience({
            ...this.props.currentAudience,
            tags: value,
          })
        }
        listTags={this.props.listTags}
        hideDescription
      />
    );
  };

  renderTagsModal = () => {
    const loadingSpin = <LoadingOutlined style={{ fontSize: 30 }} spin />;
    return (
      <Modal
        bodyStyle={{ overflow: 'scroll' }}
        width={600}
        title={
          this.state.selectedTagsRowIndex > -1 &&
          this.props.page.content[this.state.selectedTagsRowIndex].name
        }
        centered
        visible={this.state.selectedTagsRowIndex !== -1}
        onCancel={() => this.setState({ selectedTagsRowIndex: -1 })}
        footer={[
          <Button
            key="cancel"
            shape="round"
            onClick={() => this.setState({ selectedTagsRowIndex: -1 })}
          >
            CANCEL
          </Button>,
          <Button
            disabled={
              !['DRAFT', 'RUNNING', 'PAUSED', 'STOPPED', 'UPCOMING'].includes(
                this.props.page.content[this.state.selectedTagsRowIndex].status
              )
            }
            loading={this.props.loadingUpdateAudienceSubmit}
            key="submit"
            type="primary"
            shape="round"
            onClick={() => this.onOkModal()}
          >
            Update
          </Button>,
        ]}
      >
        {this.props.loadingUpdateAudience ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin indicator={loadingSpin} />{' '}
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <span style={{ paddingRight: 20, paddingTop: 10 }}>Tags </span>
            {this.renderAllTags()}
          </div>
        )}
      </Modal>
    );
  };
  onChangeHandler = (pg) => {
    this.props.communicationListFetch(pg);
    // const params = HashBase64().encode(`?page=${+pg.page + 1}`);

    const params = this.updateQueryStringParameter(
      this.props.location?.search,
      'page',
      +pg.page + 1
    );

    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: params,
    });
  };

  render() {
    let columns;
    columns = [
      {
        title: 'campaign name',
        width: 200,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        render: (x, object) => (
          <Link
            id={'int-list-name-' + object.id}
            to={this.getItemLink(object)}
            onClick={() => this.handleClearDraftCommunication()}
          >
            <span
              style={{
                color: vars.primaryColor,
                fontSize: 14,
                cursor: 'pointer',
              }}
            >
              {x}
            </span>
          </Link>
        ),
      },
      {
        title: 'type',
        width: 80,
        dataIndex: 'type',
        key: 'type',
        render: (x) => renderLowerFont(renderCampaignType(x)),
      },
      {
        title: 'status',
        dataIndex: 'status',
        key: 'status',
        width: 130,
        render: (x) => renderCampaignStatus(x),
      },
      {
        title: 'tags',
        dataIndex: 'tags',
        key: 'status',
        width: 180,
        render: (x, object, index) => this.renderListTags(index, x),
      },
      {
        title: 'start date',
        dataIndex: 'startDate',
        key: 'startDate',
        width: 150,
        render: (x) => renderLowerFont(readableDateTime(x)),
      },
      {
        title: 'delivered',
        dataIndex: 'impressions',
        key: 'impressions',
        width: 30,
        render: (x) => renderLowerFont(numberWithCommas(x)),
      },
      {
        title: (
          <React.Fragment>
            unique
            <br />
            clicks
          </React.Fragment>
        ),
        dataIndex: 'clicks',
        key: 'clicks',
        width: 30,
        render: (x) => renderLowerFont(numberWithCommas(x)),
      },
      {
        title: (
          <React.Fragment>
            unique
            <br />
            conversions
          </React.Fragment>
        ),
        dataIndex: 'conversions',
        key: 'conversions',
        width: 30,
        render: (x) => renderLowerFont(numberWithCommas(x)),
      },
      {
        title: 'revenue',
        dataIndex: 'revenue',
        key: 'revenue',
        width: 70,
        render: (x) => renderLowerFont(numberWithCommas(x)),
      },
      {
        ...(this.props.isArchived
          ? {
              title: 'action',
              key: 'action',
              fixed: 'left',
              width: '5%',
              render: (x, object, index) =>
                defaultActions(
                  x,
                  {
                    link: `../campaigns/${x.id}/audience`,
                    title: 'Edit',
                    enabled: hasAnyAuthority(
                      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_UPDATE
                    ),
                    onClick: () => this.handleClearDraftCommunication(),
                  },
                  {
                    action: () => this.openTagList(index),
                    disablePopConfirm: true,
                    title: 'Tags',
                    enabled: true,
                  },
                  {
                    action: (id) => this.props.communicationActivate(id),
                    title: 'Active',
                    enabled:
                      (this.props.isArchived && x.type !== 'JOURNEY') ||
                      x.type !== 'RELAY',
                  }
                ),
            }
          : {
              title: 'action',
              key: 'action',
              fixed: 'left',
              width: '5%',
              render: (x, object, index) =>
                defaultActions(
                  x,
                  {
                    link: `../campaigns/${x.id}/audience`,
                    title: 'Edit',
                    enabled: hasAnyAuthority(
                      AuthorityProvider.ROLE_PRODUCT_ENGAGEMENT_UPDATE
                    ),
                    onClick: () => this.handleClearDraftCommunication(),
                  },
                  {
                    action: (id) =>
                      this.props.communicationCopyFetch(
                        id,
                        this.props.pageRequest
                      ),
                    title: 'Duplicate',
                    enabled: true,
                  },
                  {
                    action: (id) =>
                      this.props.communicationStopFetch(
                        id,
                        this.props.pageRequest
                      ),
                    title: 'Stop',
                    enabled:
                      (['RUNNING', 'PAUSED', 'UPCOMING', 'ENDED'].includes(
                        x.status
                      ) &&
                        x.type !== 'JOURNEY') ||
                      x.type !== 'RELAY',
                  },
                  {
                    action: (id) =>
                      this.props.communicationDeleteFetch(
                        id,
                        this.props.pageRequest
                      ),
                    title: 'Delete',
                    enabled:
                      (['DRAFT'].includes(x.status) && x.type !== 'JOURNEY') ||
                      x.type !== 'RELAY',
                  },
                  {
                    action: () => this.openTagList(index),
                    disablePopConfirm: true,
                    title: 'Tags',
                    enabled: true,
                  },
                  {
                    action: (id) => this.props.communicationArchive(id),
                    title: 'Archive',
                    enabled:
                      (['DRAFT', 'STOPPED'].includes(x.status) &&
                        x.type !== 'JOURNEY') ||
                      x.type !== 'RELAY',
                  }
                ),
            }),
      },
    ];

    return (
      <div>
        <Card>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '12px',
              borderBottom: 'solid 2px #f4f9ff',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Input
                loading={true}
                allowClear={this.props.loadingSearchList ? false : true}
                placeholder={'Search by communication name'}
                onPressEnter={this.handleSearchName}
                onChange={(e) => this.handleChangeSearch(e.target.value)}
                value={this.state.name}
                style={{ width: 300, borderRadius: 5 }}
                suffix={
                  isEmpty(this.state.name) ? (
                    <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  ) : (
                    this.props.loadingSearchList && (
                      <div style={styles.inputLoadingSpinStyle}>
                        <Spin indicator={inputLoadingSpin} />
                      </div>
                    )
                  )
                }
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.renderTagSelector()}
              {this.renderTypeSelector()}
              {!this.props.isArchived && (
                <Select
                  onChange={(searchStatus) =>
                    this.handleChangeStatus(searchStatus)
                  }
                  value={
                    this.props?.pageRequest?.status?.length >= 6 ||
                    this.props?.pageRequest?.status === null
                      ? 'ALL'
                      : this.props?.pageRequest?.status
                  }
                  style={{ width: 120 }}
                  className={'chart-selector'}
                  bordered={false}
                >
                  <Select.Option value="ALL">All Statuses</Select.Option>
                  <Select.Option value="RUNNING">Running</Select.Option>
                  <Select.Option value="PAUSED">Paused</Select.Option>
                  <Select.Option value="STOPPED">Stopped</Select.Option>
                  <Select.Option value="ENDED">Ended</Select.Option>
                  <Select.Option value="UPCOMING">Upcoming</Select.Option>
                  <Select.Option value="DRAFT">Draft</Select.Option>
                  <Select.Option value="W_UPCOMING">W-Upcoming</Select.Option>
                  <Select.Option value="W_RUNNING">W-Running</Select.Option>
                </Select>
              )}
            </div>
          </div>
          {this.props.loadingList ? (
            <div style={styles.loadingSpinStyle}>
              <Spin indicator={loadingSpin} />
            </div>
          ) : (
            <ListBase
              onChange={this.onChangeHandler}
              page={this.props.page}
              pageRequest={this.props.pageRequest}
              columns={columns}
              createLink={
                hasAnyAuthority(AuthorityProvider.ROLE_ADMIN_USER_CREATE)
                  ? 'create'
                  : null
              }
            />
          )}
        </Card>
        {this.state.selectedTagsRowIndex > -1 && this.renderTagsModal()}
      </div>
    );
  }
}

export default CommunicationListComponent;
