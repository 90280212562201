const CommunicationChannels = {
  PUSH: 'Push',
  SMS: 'SMS',
  EMAIL: 'Email',
  WEB_PUSH: 'Web Push',
  CUSTOM: 'Custom Channel',
  ON_SITE: 'On Siteß',
  IN_APP: 'In App',
};

export default CommunicationChannels;
