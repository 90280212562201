/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable react/no-string-refs */

import React, { useState, useEffect } from 'react';
import {
  Editor,
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
  CompositeDecorator,
  convertFromHTML,
  ContentState,
  SelectionState,
  Modifier,
} from 'draft-js';
import { Modal, Input } from 'antd';
import {
  OrderedListOutlined,
  UnorderedListOutlined,
  CodeOutlined,
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  LinkOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { stateToHTML } from 'draft-js-export-html';
import { Picker } from 'emoji-mart';
import 'draft-js/dist/Draft.css';
import './RichEditor.less';

import { isEmpty, debounce, throttle } from 'lodash';
import FunctionsPopulates from './FunctionsPopulates';

export default class RichEditor extends React.Component {
  constructor(props) {
    super(props);

    const blocksFromHTML = convertFromHTML(this.props.value || '<p><br /><p>');
    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    this.onChangeAttr = (userAttr) => this.setState({ userAttr });
    this.onChangekeycommand = (keycommand) => this.setState({ keycommand });

    this.focus = () => this.refs.editor.focus();
    this.onChange = (editorState) => {
      this.setState({ editorState });
    };
    this.removeAttributes = this._removeAttributes.bind(this);
    this.insertAttributes = this._insertAttributes.bind(this);
    this.populatefFunctionDetected = this._populatefFunctionDetected.bind(this);

    this.decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
      {
        strategy: handleStrategy,
        component: withProps({
          WrappedComponent: HandlePopulate,
          remove: this.removeAttributes,
          populatefFunctionDetected: this.populatefFunctionDetected,
        }),
      },
    ]);

    const editorState = EditorState.createWithContent(content, this.decorator);

    this.state = {
      editorState,
      showEditor: true,
      userAttr: '',
      onChange: (editorState) => this.setState({ editorState }),
      keycommand: '',
      populatedFunctions: {},
    };

    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    this.handleChange = this._handleChange.bind(this);
    this.insertInline = this._insertInline.bind(this);
    this.insertLink = this._insertLink.bind(this);
    this.removeLink = this._removeLink.bind(this);
    this.toggleEditor = this._toggleEditor.bind(this);

    this.funcQueue = [];
  }

  _populatefFunctionDetected = (details, adding) => {
    this.funcQueue.push({
      details,
      adding,
    });
    this._setPopulatedFunctions();
  };

  _setPopulatedFunctions = debounce(() => {
    const populatedFunctions = Object.assign({}, this.state.populatedFunctions);
    while (this.funcQueue.length) {
      const item = this.funcQueue.shift();
      if (item) {
        if (item.adding) {
          populatedFunctions[item.details.offsetKey] = item.details;
        } else {
          delete populatedFunctions[item.details.offsetKey];
        }
      }
    }
    this.setState({ populatedFunctions });
  }, 500);

  _handleChange = (editorState) => {
    if (!this.clicked) {
      this.setState({ editorState });
    }
    const valueHTML = stateToHTML(editorState.getCurrentContent());
    const value = editorState.getCurrentContent().getPlainText();

    if (this.props.type !== 'rich') {
      if (!value) {
        this.props.onChange('  ');
      } else {
        this.props.onChange(value);
      }
    } else if (!valueHTML.replace(/<[^>]*>?/gm, ' ')?.trim()) {
      this.props.onChange('  ');
    } else {
      this.props.onChange(valueHTML);
    }
  };

  _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _mapKeyToEditorCommand(e) {
    if (e.keyCode === 9) {
      const newEditorState = RichUtils.onTab(e, this.state.editorState, 4);
      if (newEditorState !== this.state.editorState) {
        this.onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  }

  _insertInline(value) {
    const currentContent = this.state.editorState.getCurrentContent(),
      currentSelection = this.state.editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      value
    );

    const newEditorState = EditorState.push(
      this.state.editorState,
      newContent,
      'insert-characters'
    );

    this.setState({ editorState: newEditorState }, () => {
      setTimeout(() => {
        this.refs.editor.focus();
      }, 10);
    });
  }

  _insertAttributes(blockKey, start, end, value) {
    /***************replace**************/

    const contentBlock = this.state.editorState
      .getCurrentContent()
      .getBlockForKey(blockKey);

    const contentState = this.state.editorState.getCurrentContent();

    const entitySelection = new SelectionState({
      anchorKey: contentBlock.getKey(),
      focusKey: contentBlock.getKey(),
      anchorOffset: start,
      focusOffset: end,
    });
    const newContent = Modifier.replaceText(
      contentState,
      entitySelection,
      value
    );
    const newEditorState = EditorState.push(
      this.state.editorState,
      newContent,
      'insert-characters'
    );

    this.clicked = true;
    this.setState({ editorState: newEditorState }, () => {
      this.clicked = false;
      setTimeout(() => {
        this.refs.editor.focus();
      }, 10);
    });
  }

  _removeAttributes(props, clicked) {
    const { blockKey, decoratedText, offsetKey } = props;

    /***************Remove**************/
    const textIndexStart = this.state.editorState
      .getCurrentContent()
      .getPlainText()
      .indexOf(decoratedText);
    const textIndexEnd = textIndexStart + decoratedText.length;
    const contentBlock = this.state.editorState
      .getCurrentContent()
      .getBlockForKey(blockKey);
    const contentState = this.state.editorState.getCurrentContent();
    const entitySelection = new SelectionState({
      anchorKey: contentBlock.getKey(),
      focusKey: contentBlock.getKey(),
      anchorOffset: props.start,
      focusOffset: props.end,
    });
    const newContent = Modifier.removeRange(
      contentState,
      entitySelection,
      'forward'
    );
    const newEditorState = EditorState.push(
      this.state.editorState,
      newContent,
      'remove-range'
    );

    this.clicked = clicked;

    this.setState({ editorState: newEditorState }, () => {
      this.clicked = false;
      setTimeout(() => {
        this.refs.editor.focus();
      }, 10);
    });
    /**********New*********/
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return entityKey !== null;
      },
      (start, end) => {
        const entitySelection = new SelectionState({
          anchorKey: contentBlock.getKey(),
          focusKey: contentBlock.getKey(),
          anchorOffset: start,
          focusOffset: end,
        });
        const newContent = Modifier.removeRange(
          contentState,
          entitySelection,
          'backward'
        );

        const newEditorState = EditorState.push(
          this.state.editorState,
          newContent,
          'remove-block'
        );
      }
    );
  }

  _insertLink(urlValue, start = -1, end = -1) {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: urlValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const contentBlock = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey());
    const selection =
      start != -1 && end != -1
        ? new SelectionState({
            anchorKey: contentBlock.getKey(),
            focusKey: contentBlock.getKey(),
            anchorOffset: start,
            focusOffset: end,
          })
        : newEditorState.getSelection();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    this.setState(
      {
        editorState: RichUtils.toggleLink(newEditorState, selection, entityKey),
      },
      () => {
        setTimeout(() => this.refs.editor.focus(), 0);
      }
    );
  }

  _removeLink = () => {
    const { editorState } = this.state;

    const selection = editorState.getSelection();
    const contentBlock = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey());
    const contentState = editorState.getCurrentContent();
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        const en = entityKey !== null && contentState.getEntity(entityKey);
        return entityKey !== null && en.getType() === 'LINK';
      },
      (start, end) => {
        const entitySelection = new SelectionState({
          anchorKey: contentBlock.getKey(),
          focusKey: contentBlock.getKey(),
          anchorOffset: start,
          focusOffset: end,
        });
        this.setState({
          editorState: RichUtils.toggleLink(editorState, entitySelection, null),
        });
      }
    );
  };

  _toggleEditor = () => {
    if (!this.state.showEditor) {
      const blocksFromHTML = convertFromHTML(this.props.value);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      const editorState = EditorState.createWithContent(
        content,
        this.decorator
      );
      this.setState(
        {
          editorState: EditorState.moveFocusToEnd(editorState),
        },
        () => {
          setTimeout(() => {
            this.refs.editor.focus();
          }, 10);
        }
      );
    }
    this.setState({
      showEditor: !this.state.showEditor,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props?.onChangeButton && prevProps.value !== this.props.value) {
      const blocksFromHTML = convertFromHTML(this.props.value);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const editorState = EditorState.createWithContent(
        content,
        this.decorator
      );
      this.setState(
        {
          editorState: EditorState.moveFocusToEnd(editorState),
        },
        () => {
          setTimeout(() => {
            this.refs.editor.focus();
          }, 10);
        }
      );
    }
    if (this.props.index !== prevProps.index && !isEmpty(this.props.value)) {
      const htmlValue = this.props.value.replace(/(?:\r\n|\r|\n)/g, '<br>');

      const blocksFromHTML = convertFromHTML(htmlValue);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const editorState = EditorState.createWithContent(
        content,
        this.decorator
      );
      this.setState(
        {
          editorState: EditorState.moveFocusToEnd(editorState),
        },
        () => {
          setTimeout(() => {
            this.refs.editor.focus();
          }, 10);
        }
      );
    }
    if (
      this.state.showEditor &&
      isEmpty(prevProps.value) &&
      !isEmpty(this.props.value)
    ) {
      const blocksFromHTML = convertFromHTML(this.props.value);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const editorState = EditorState.createWithContent(
        content,
        this.decorator
      );
      this.setState(
        {
          editorState: EditorState.moveFocusToEnd(editorState),
        },
        () => {
          setTimeout(() => {
            this.refs.editor.focus();
          }, 10);
        }
      );
    }
    if (
      (this.props.type === 'richInline' ||
        this.props.type === 'richTextArea') &&
      !isEmpty(prevProps.value) &&
      isEmpty(this.props.value)
    ) {
      const blocksFromHTML = convertFromHTML(' ');
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const editorState = EditorState.createWithContent(
        content,
        this.decorator
      );
      this.setState(
        {
          editorState: EditorState.moveFocusToEnd(editorState),
        },
        () => {
          setTimeout(() => {
            this.refs.editor.focus();
          }, 10);
        }
      );
    }
    if (
      this.props.type === 'richTextArea' &&
      isEmpty(prevProps.value) &&
      !isEmpty(this.props.value)
    ) {
      const htmlValue = this.props.value.replace(/(?:\r\n|\r|\n)/g, '<br>');
      const blocksFromHTML = convertFromHTML(htmlValue);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const editorState = EditorState.createWithContent(
        content,
        this.decorator
      );
      this.setState(
        {
          editorState: EditorState.moveFocusToEnd(editorState),
        },
        () => {
          setTimeout(() => {
            this.refs.editor.focus();
          }, 10);
        }
      );
    }
  }

  render() {
    const { editorState } = this.state;

    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    return (
      <div style={{ width: '100%' }}>
        <div
          style={this.props.style}
          className={`RichEditor-root ${
            this.props.type === 'richTextArea'
              ? 'RichEditor-textArea'
              : this.props.type === 'richInline'
              ? 'RichEditor-inline'
              : this.props.type === 'rich'
              ? 'RichEditor-rich'
              : ''
          } ${this.props.className ?? ''}`}
        >
          <div className={className} onClick={this.focus}>
            <div
              style={{
                display: this.state.showEditor ? 'block' : 'none',
              }}
            >
              <Editor
                blockStyleFn={getBlockStyle}
                customStyleMap={styleMap}
                editorState={editorState}
                handleKeyCommand={this.handleKeyCommand}
                keyBindingFn={this.mapKeyToEditorCommand}
                onChange={this.handleChange}
                placeholder={this.props.placeholder}
                ref="editor"
                spellCheck={false}
                readOnly={this.props.readOnly}
              />
              {this.props.readOnly ? (
                <div className="RichEditor-disableWrapper" />
              ) : null}
            </div>
            {!this.state.showEditor ? (
              <div>
                <Input.TextArea
                  rows={4}
                  value={this.props.value}
                  onChange={(e) => this.props.onChange(e.target.value)}
                />
              </div>
            ) : null}
          </div>

          {this.state.showEditor ? (
            <div>
              {this.props.type === 'rich' && (
                <>
                  <BlockStyleControls
                    editorState={editorState}
                    onToggle={this.toggleBlockType}
                  />
                  <InlineStyleControls
                    editorState={editorState}
                    onToggle={this.toggleInlineStyle}
                  />
                </>
              )}

              <CustomControls
                insert={this.insertInline}
                renderAttributesSelector={this.props.renderAttributesSelector}
                insertLink={this.insertLink}
                removeLink={this.removeLink}
                editorState={this.state.editorState}
                toggleSource={this.toggleEditor}
                addAudio={this.addAudio}
                confirmMedia={this.confirmMedia}
                onChangeAttr={this.onChangeAttr}
                onChangekeycommand={this.onChangekeycommand}
                onChange={this.onChange}
                type={this.props.type}
                hideEmojiPicker={this.props.hideEmojiPicker}
              />
            </div>
          ) : (
            <div>
              <span
                className="RichEditor-styleButton"
                onMouseDown={this.toggleEditor}
              >
                Editor
              </span>
            </div>
          )}
        </div>
        {Object.keys(this.state?.populatedFunctions).length > 0 &&
          Object.keys(this.state?.populatedFunctions).map((key, index) => {
            const item = this.state?.populatedFunctions[key];
            if (!item.decoratedText.includes('{{now')) {
              return (
                <div key={key}>
                  <FunctionsPopulates
                    functionName={item.decoratedText}
                    itemBlock={item}
                    onChange={(vall) => {
                      this.setState({
                        arguValue: vall,
                      });
                    }}
                    insertAttributes={this.insertAttributes}
                  />
                </div>
              );
            }
          })}
      </div>
    );
  }
}

/***********************************decorators********************************************/
function withProps({ WrappedComponent, ...parentProps }) {
  // eslint-disable-next-line react/display-name
  return class extends React.Component {
    render() {
      return <WrappedComponent {...parentProps} {...this.props} />;
    }
  };
}

function findWithRegex2(regex1, regex2, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while (
    (matchArr = regex1.exec(text)) !== null ||
    (matchArr = regex2.exec(text)) !== null
  ) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

function handleStrategy(contentBlock, callback) {
  //const HANDLE_REGEX = /{{[^}]*}}+/g;
  const HANDLE_REGEX_FUNC = /\{\{[a-zA-Z]+\([^)]*\)\}\}+/g;
  const HANDLE_REGEX_ATTR = /{{[a-zA-Z]+\[[^}}]*]}}+/g;
  findWithRegex2(HANDLE_REGEX_FUNC, HANDLE_REGEX_ATTR, contentBlock, callback);
}

function HandlePopulate(props) {
  const { blockKey, decoratedText, data } = props;
  const isFunction =
    decoratedText.includes('{{urlEncode') ||
    decoratedText.includes('{{ceil') ||
    decoratedText.includes('{{floor') ||
    decoratedText.includes('{{jalaliDate') ||
    decoratedText.includes('{{jalaliDateTime') ||
    decoratedText.includes('{{gregorianDate') ||
    decoratedText.includes('{{gregorianDateTime') ||
    decoratedText.includes('{{orElse') ||
    decoratedText.includes('{{addMinutes') ||
    decoratedText.includes('{{now');

  const functionLabel = decoratedText.includes('urlEncode')
    ? 'urlEncode'
    : decoratedText.includes('ceil')
    ? 'ceil'
    : decoratedText.includes('floor')
    ? 'floor'
    : decoratedText.includes('jalaliDate')
    ? 'jalaliDate'
    : decoratedText.includes('jalaliDateTime')
    ? 'jalaliDateTime'
    : decoratedText.includes('gregorianDate')
    ? 'gregorianDate'
    : decoratedText.includes('gregorianDateTime')
    ? 'gregorianDateTime'
    : decoratedText.includes('orElse')
    ? 'orElse'
    : decoratedText.includes('addMinutes')
    ? 'addMinutes'
    : 'now';

  useEffect(() => {
    if (isFunction) {
      props.populatefFunctionDetected(props, true);
    }
    return () => {
      if (isFunction) {
        props.populatefFunctionDetected(props, false);
      }
    };
  }, []);

  return (
    <span
      style={{
        background: '#F4F6F8',
        padding: '3px 24px 3px 6px',
        margin: '2px 4px',
        borderRadius: '4px',
        display: 'inline-block',
        position: 'relative',
      }}
      data-offset-key={props.offsetKey}
      id="funcDecorate"
    >
      <CloseCircleOutlined
        style={{
          position: 'absolute',
          right: '4px',
          top: '8px',
          color: '#EE4157D9',
        }}
        onClick={() => {
          props.remove(props, true);
        }}
      />
      {props.children}
    </span>
  );
}

/***************************************************************************************** */

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={{ color: '#3b5998', textDecoration: 'underline' }}>
      {props.children}
    </a>
  );
};

const UrlInput = ({ editorState, haveLink, visible, confirm }) => {
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [range, setRange] = useState([0, 0]);

  useEffect(() => {
    const selection = editorState.getSelection();
    if (haveLink) {
      const contentBlock = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey());
      const contentState = editorState.getCurrentContent();
      contentBlock.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === 'LINK'
          );
        },
        (start, end) => {
          const entitySelection = new SelectionState({
            anchorKey: contentBlock.getKey(),
            focusKey: contentBlock.getKey(),
            anchorOffset: start,
            focusOffset: end,
          });

          setRange([start, end]);

          var currentContent = editorState.getCurrentContent();
          var currentContentBlock = currentContent.getBlockForKey(
            contentBlock.getKey()
          );
          var selectedText = currentContentBlock.getText().slice(start, end);

          setTitle(selectedText);

          const startKey = entitySelection.getStartKey();
          const startOffset = entitySelection.getStartOffset();
          const blockWithLinkAtBeginning =
            contentState.getBlockForKey(startKey);
          const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

          if (linkKey) {
            const linkInstance = contentState.getEntity(linkKey);
            setUrl(linkInstance.getData().url);
          } else {
            setUrl('');
          }
        }
      );
    } else {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        setUrl(linkInstance.getData().url);
      } else {
        setUrl('');
      }

      var selectionState = editorState.getSelection();
      var anchorKey = selectionState.getAnchorKey();
      var currentContent = editorState.getCurrentContent();
      var currentContentBlock = currentContent.getBlockForKey(anchorKey);
      var start = selectionState.getStartOffset();
      var end = selectionState.getEndOffset();
      var selectedText = currentContentBlock.getText().slice(start, end);

      setTitle(selectedText);
      setRange([start, end]);
    }
  }, [visible]);

  const clear = () => {
    setUrl('');
    setTitle('');
    setRange([0, 0]);
  };

  return (
    <Modal
      title="insert url"
      visible={visible}
      onOk={() => {
        confirm(url, range[0], range[1]);
        clear();
      }}
      onCancel={() => {
        confirm(false);
        clear();
      }}
    >
      <div className="RichEditor-urlFormItem">
        <lable htmlFor="urlText">Text: </lable>
        <Input
          id="urlText"
          disabled={true}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          type="text"
          value={title}
        />
      </div>
      <div className="RichEditor-urlFormItem">
        <lable htmlFor="urlText">URL: </lable>
        <Input
          onChange={(e) => {
            setUrl(e.target.value);
          }}
          required
          type="text"
          value={url}
          onKeyDown={(e) => {
            if (e.which === 13) {
              confirm(url, range[0], range[1]);
              clear();
            }
          }}
        />
      </div>
    </Modal>
  );
};

const CustomControls = (props) => {
  const [showEmoji, setShowEmoji] = useState(false);
  const [showAttributes, setShowAttributes] = useState(false);
  const [showURLInput, setShowURLInput] = useState(false);
  const [haveLink, setHaveLink] = useState(false);

  const { editorState } = props;
  const selection = editorState.getSelection();
  const selectionStartOffset = selection.getStartOffset();

  useEffect(() => {
    setHaveLink(false);
    const contentBlock = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey());
    const contentState = editorState.getCurrentContent();

    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'LINK'
        );
      },
      (start, end) => {
        setHaveLink(
          selectionStartOffset >= start && selectionStartOffset <= end
        );
      }
    );
  }, [selectionStartOffset]);
  const attrCallBack = (value) => {
    setShowAttributes(false);
    props.insert(value);
    props.onChangeAttr(value);
    //props.confirmMedia(value);
  };

  return (
    <div
      className={
        props.type === 'richTextArea'
          ? 'RichEditor-textArea-control'
          : props.type === 'richInline'
          ? 'RichEditor-inline-control'
          : ''
      }
      style={{ clear: 'both', position: 'relative' }}
    >
      {props.type === 'rich' && (
        <>
          <span
            className={`RichEditor-styleButton ${
              selection.isCollapsed() && !haveLink
                ? ' RichEditor-disableButton'
                : ''
            }`}
            onMouseDown={() => {
              if (!(selection.isCollapsed() && !haveLink)) {
                setShowURLInput(true);
              }
            }}
          >
            <LinkOutlined />
          </span>
          <span
            className={`RichEditor-styleButton ${
              haveLink ? '' : ' RichEditor-disableButton'
            }`}
            onClick={() => {
              if (haveLink) {
                props.removeLink();
              }
            }}
          >
            Remove Link
          </span>
          <span
            className="RichEditor-styleButton"
            onMouseDown={props.toggleSource}
          >
            Source
          </span>
        </>
      )}
      {!props.hideEmojiPicker && (
        <i
          onClick={() => {
            setShowEmoji(!showEmoji);
          }}
          className="fl-emoji fl-dark input-suffix-icon"
        ></i>
      )}

      {props.renderAttributesSelector ? (
        <i
          onClick={() => {
            setShowAttributes(!showAttributes);
            props.onChangekeycommand('');
          }}
          className="fl-dark fl-user input-suffix-icon"
        ></i>
      ) : null}
      <UrlInput
        editorState={editorState}
        visible={showURLInput}
        haveLink={haveLink}
        confirm={(link, start, end) => {
          setShowURLInput(false);
          if (link) {
            props.insertLink(link, start, end);
          }
        }}
      />
      {showAttributes ? (
        <>
          <div
            className="RichEditor-control-overlay"
            onClick={() => {
              if (showAttributes) {
                setShowAttributes(false);
              }
            }}
          />
          {props.renderAttributesSelector({}, attrCallBack)}
        </>
      ) : null}
      {showEmoji ? (
        <>
          <div
            className="RichEditor-control-overlay"
            onClick={() => {
              if (setShowEmoji) {
                setShowEmoji(false);
              }
            }}
          />
          <Picker
            set="apple"
            style={{
              position: 'absolute',
              zIndex: 1,
              border: '1px solid #FFFFFF',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            }}
            color="#fe5196"
            showPreview={false}
            showSkinTones={false}
            onSelect={(emoji) => {
              props.insert(emoji.native);
              setShowEmoji(false);
            }}
          />
        </>
      ) : null}
    </div>
  );
};

// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: <CodeOutlined />, style: 'code-block' },
  { label: <UnorderedListOutlined />, style: 'unordered-list-item' },
  { label: <OrderedListOutlined />, style: 'ordered-list-item' },
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type, idx) => (
        <StyleButton
          key={idx}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

var INLINE_STYLES = [
  { label: <BoldOutlined />, style: 'BOLD' },
  { label: <ItalicOutlined />, style: 'ITALIC' },
  { label: <UnderlineOutlined />, style: 'UNDERLINE' },
  //   { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type, idx) => (
        <StyleButton
          key={idx}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
