export const PRODUCT_SEGMENT_CHANGE_CURRENT = 'PRODUCT_SEGMENT_CHANGE_CURRENT';
export const PRODUCT_SEGMENT_PAGE_CHANGE_CURRENT =
  'PRODUCT_SEGMENT_PAGE_CHANGE_CURRENT';

export const PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH_SUCCESS';
export const PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH_FAIL =
  'PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH_FAIL';
export const PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH =
  'PRODUCT_SEGMENT_LIST_ATTRIBUTES_FETCH';

export const PRODUCT_SEGMENT_LIST_EVENTS_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_LIST_EVENTS_FETCH_SUCCESS';
export const PRODUCT_SEGMENT_LIST_EVENTS_FETCH_FAIL =
  'PRODUCT_SEGMENT_LIST_EVENTS_FETCH_FAIL';
export const PRODUCT_SEGMENT_LIST_EVENTS_FETCH =
  'PRODUCT_SEGMENT_LIST_EVENTS_FETCH';

export const PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH_SUCCESS';
export const PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH_FAIL =
  'PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH_FAIL';
export const PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH =
  'PRODUCT_SEGMENT_LIST_DEVICE_ATTRIBUTES_FETCH';

export const PRODUCT_SEGMENT_LIST_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_LIST_FETCH_SUCCESS';
export const PRODUCT_SEGMENT_LIST_FETCH_FAIL =
  'PRODUCT_SEGMENT_LIST_FETCH_FAIL';
export const PRODUCT_SEGMENT_LIST_FETCH = 'PRODUCT_SEGMENT_LIST_FETCH';

export const PRODUCT_SEGMENT_SEARCH_LIST_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_SEARCH_LIST_FETCH_SUCCESS';
export const PRODUCT_SEGMENT_SEARCH_LIST_FETCH_FAIL =
  'PRODUCT_SEGMENT_SEARCH_LIST_FETCH_FAIL';
export const PRODUCT_SEGMENT_SEARCH_LIST_FETCH =
  'PRODUCT_SEGMENT_SEARCH_LIST_FETCH';

export const PRODUCT_SEGMENT_LIST_RESET = 'PRODUCT_SEGMENT_LIST_RESET';

export const PRODUCT_SEGMENT_CREATE_FETCH = 'PRODUCT_SEGMENT_CREATE_FETCH';
export const PRODUCT_SEGMENT_CREATE_FETCH_FAIL =
  'PRODUCT_SEGMENT_CREATE_FETCH_FAIL';
export const PRODUCT_SEGMENT_CREATE_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_CREATE_FETCH_SUCCESS';

export const PRODUCT_SEGMENT_COPY_FETCH = 'PRODUCT_SEGMENT_COPY_FETCH';
export const PRODUCT_SEGMENT_COPY_FETCH_FAIL =
  'PRODUCT_SEGMENT_COPY_FETCH_FAIL';
export const PRODUCT_SEGMENT_COPY_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_COPY_FETCH_SUCCESS';

export const PRODUCT_SEGMENT_REPORT_FETCH = 'PRODUCT_SEGMENT_REPORT_FETCH';
export const PRODUCT_SEGMENT_REPORT_FETCH_FAIL =
  'PRODUCT_SEGMENT_REPORT_FETCH_FAIL';
export const PRODUCT_SEGMENT_REPORT_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_REPORT_FETCH_SUCCESS';

export const PRODUCT_SEGMENT_REPORT_VIEW_FETCH =
  'PRODUCT_SEGMENT_REPORT_VIEW_FETCH';
export const PRODUCT_SEGMENT_REPORT_VIEW_FETCH_FAIL =
  'PRODUCT_SEGMENT_REPORT_VIEW_FETCH_FAIL';
export const PRODUCT_SEGMENT_REPORT_VIEW_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_REPORT_VIEW_FETCH_SUCCESS';

export const PRODUCT_SEGMENT_UPDATE_FETCH = 'PRODUCT_SEGMENT_UPDATE_FETCH';
export const PRODUCT_SEGMENT_UPDATE_FETCH_FAIL =
  'PRODUCT_SEGMENT_UPDATE_FETCH_FAIL';
export const PRODUCT_SEGMENT_UPDATE_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_UPDATE_FETCH_SUCCESS';

export const PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH =
  'PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH';
export const PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH_FAIL =
  'PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH_FAIL';
export const PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_UPDATE_SUBMIT_FETCH_SUCCESS';

export const PRODUCT_SEGMENT_VIEW_FETCH = 'PRODUCT_SEGMENT_VIEW_FETCH';
export const PRODUCT_SEGMENT_VIEW_FETCH_FAIL =
  'PRODUCT_SEGMENT_VIEW_FETCH_FAIL';
export const PRODUCT_SEGMENT_VIEW_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_VIEW_FETCH_SUCCESS';

export const PRODUCT_SEGMENT_DELETE_FETCH = 'PRODUCT_SEGMENT_DELETE_FETCH';
export const PRODUCT_SEGMENT_DELETE_FETCH_FAIL =
  'PRODUCT_SEGMENT_DELETE_FETCH_FAIL';
export const PRODUCT_SEGMENT_DELETE_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_DELETE_FETCH_SUCCESS';

export const PRODUCT_SEGMENT_SIZE_REPORT_FETCH =
  'PRODUCT_SEGMENT_SIZE_REPORT_FETCH';
export const PRODUCT_SEGMENT_SIZE_REPORT_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_SIZE_REPORT_FETCH_SUCCESS';
export const PRODUCT_SEGMENT_SIZE_REPORT_FETCH_FAIL =
  'PRODUCT_SEGMENT_SIZE_REPORT_FETCH_FAIL';

export const PRODUCT_SEGMENT_ARCHIVE_FETCH = 'PRODUCT_SEGMENT_ARCHIVE_FETCH';
export const PRODUCT_SEGMENT_ARCHIVE_FETCH_FAIL =
  'PRODUCT_SEGMENT_ARCHIVE_FETCH_FAIL';
export const PRODUCT_SEGMENT_ARCHIVE_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_ARCHIVE_FETCH_SUCCESS';

export const PRODUCT_SEGMENT_ACTIVATE_FETCH = 'PRODUCT_SEGMENT_ACTIVATE_FETCH';
export const PRODUCT_SEGMENT_ACTIVATE_FETCH_FAIL =
  'PRODUCT_SEGMENT_ACTIVATE_FETCH_FAIL';
export const PRODUCT_SEGMENT_ACTIVATE_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_ACTIVATE_FETCH_SUCCESS';

export const PRODUCT_SEGMENT_SAVE_CLICKED = 'PRODUCT_SEGMENT_SAVE_CLICKED';
export const PRODUCT_SEGMENT_RESET_CLICKED = 'PRODUCT_SEGMENT_RESET_CLICKED';
export const PRODUCT_SEGMENT_RESET_COMPLETED =
  'PRODUCT_SEGMENT_RESET_COMPLETED';

export const PRODUCT_SEGMENT_STATIC_CREATE_TOGGLE =
  'PRODUCT_SEGMENT_STATIC_CREATE_TOGGLE';

export const PRODUCT_SEGMENT_FILE_UPLOAD_FETCH =
  'PRODUCT_SEGMENT_FILE_UPLOAD_FETCH';
export const PRODUCT_SEGMENT_FILE_UPLOAD_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_FILE_UPLOAD_FETCH_SUCCESS';
export const PRODUCT_SEGMENT_FILE_UPLOAD_FETCH_FAIL =
  'PRODUCT_SEGMENT_FILE_UPLOAD_FETCH_FAIL';
export const PRODUCT_SEGMENT_FILE_UPLOAD_RESET =
  'PRODUCT_SEGMENT_FILE_UPLOAD_RESET';

export const CLEAR_SEGMENT_ERROR = 'CLEAR_SEGMENT_ERROR';

export const PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH =
  'PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH';
export const PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH_FAIL =
  'PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH_FAIL';
export const PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_RECOMMEND_USER_ATTRIBUTES_FETCH_SUCCESS';

export const PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH =
  'PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH';
export const PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH_FAIL =
  'PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH_FAIL';
export const PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_RECOMMEND_DEVICE_ATTRIBUTES_FETCH_SUCCESS';

export const PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH =
  'PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH';
export const PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH_FAIL =
  'PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH_FAIL';
export const PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_RECOMMEND_EVENT_ATTRIBUTES_FETCH_SUCCESS';

export const PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH_SUCCESS =
  'PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH_SUCCESS';
export const PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH_FAIL =
  'PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH_FAIL';
export const PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH =
  'PRODUCT_SEGMENT_LIST_SOURCE_PRODUCTS_FETCH';
