import styles from './ReportChartSection.module.less';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import React from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Menu,
  Select,
  message,
} from 'antd';

const ReportChartsSectionHeader = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  productList,
  productName,
  setProductName,
  cardsList,
  selectedCards,
  setSelectedCards,
  splitBy,
  setSplitBy,
}) => {
  const splitOptions = [
    {
      value: 'CHANNEL',
      label: 'Channel',
    },
    {
      value: 'TYPE',
      label: 'Type',
    },
  ];

  const handleDatePickerChange = (val) => {
    setStartDate(val[0]);
    setEndDate(val[1]);
  };

  const handleSelectChange = (val) => {
    setProductName(val);
  };

  const isCardChecked = (cardName) => {
    let tempSelectedCard = [];
    selectedCards.forEach((selectedCard) => {
      tempSelectedCard.push(selectedCard);
    });
    return tempSelectedCard.includes(cardName);
  };

  const handleChangeCards = (e, cardName) => {
    if (e.target.checked) {
      setSelectedCards((prevState) => [...prevState, cardName]);
    } else {
      if (selectedCards.length > 1) {
        const tempSelectedCard = selectedCards.filter(
          (selectedCard) => selectedCard !== cardName
        );
        setSelectedCards(tempSelectedCard);
      } else {
        message.warning('You should have at least 1 item selected');
      }
    }
  };

  const menu = (
    <Menu>
      <div className={styles.checkListMenuWrapper}>
        <Menu selectable={false} className={styles.checkListMenu}>
          {cardsList.map((cardName, index) => (
            <Menu.Item key={index}>
              <Checkbox
                style={{ width: 200 }}
                onChange={(e) => handleChangeCards(e, cardName)}
                checked={isCardChecked(cardName)}
              >
                {cardName.charAt(0).toUpperCase() +
                  cardName.replace('_', ' ').toLowerCase().slice(1)}
              </Checkbox>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </Menu>
  );

  return (
    <div className={styles.headerWrapper}>
      <div>
        <DatePicker.RangePicker
          className={styles.headerDatePicker}
          allowClear={false}
          disabled={[false, false]}
          value={startDate && endDate ? [startDate, endDate] : [null, null]}
          onChange={(val) => handleDatePickerChange(val)}
          disabledDate={
            (date) => date && date > moment().startOf('day')
            // (date > moment().startOf('day') ||
            //   date < moment().subtract(90, 'day'))
          }
        />
        {productList?.length ? (
          <Select
            className={styles.headerProductSelect}
            placeholder="Product Name"
            value={productName}
            onChange={(e) => handleSelectChange(e)}
            options={productList}
          />
        ) : null}
        &nbsp; SPLIT BY: &nbsp;
        <Select
          className={styles.headerProductSelect}
          placeholder="Split by"
          value={splitBy}
          onChange={(e) => setSplitBy(e)}
          options={splitOptions}
        />
      </div>
      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
        <Button>
          <PlusOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default ReportChartsSectionHeader;
