export const SMS_CHANNEL_DELETE_FETCH = 'SMS_CHANNEL_DELETE_FETCH';
export const SMS_CHANNEL_DELETE_FETCH_FAIL = 'SMS_CHANNEL_DELETE_FETCH_FAIL';
export const SMS_CHANNEL_DELETE_FETCH_SUCCESS =
  'SMS_CHANNEL_DELETE_FETCH_SUCCESS';

export const SMS_CHANNEL_REFRESH_TOKEN_FETCH =
  'SMS_CHANNEL_REFRESH_TOKEN_FETCH';
export const SMS_CHANNEL_REFRESH_TOKEN_FETCH_FAIL =
  'SMS_CHANNEL_REFRESH_TOKEN_FETCH_FAIL';
export const SMS_CHANNEL_REFRESH_TOKEN_FETCH_SUCCESS =
  'SMS_CHANNEL_REFRESH_TOKEN_FETCH_SUCCESS';

export const SMS_CHANNEL_CREATE_FETCH = 'SMS_CHANNEL_CREATE_FETCH';
export const SMS_CHANNEL_CREATE_FETCH_FAIL = 'SMS_CHANNEL_CREATE_FETCH_FAIL';
export const SMS_CHANNEL_CREATE_FETCH_SUCCESS =
  'SMS_CHANNEL_CREATE_FETCH_SUCCESS';

export const SMS_CHANNEL_LIST_FETCH = 'SMS_CHANNEL_LIST_FETCH';
export const SMS_CHANNEL_LIST_FETCH_FAIL = 'SMS_CHANNEL_LIST_FETCH_FAIL';
export const SMS_CHANNEL_LIST_FETCH_SUCCESS = 'SMS_CHANNEL_LIST_FETCH_SUCCESS';

export const SMS_CHANNEL_SEARCH_LIST_FETCH = 'SMS_CHANNEL_SEARCH_LIST_FETCH';
export const SMS_CHANNEL_SEARCH_LIST_FETCH_FAIL =
  'SMS_CHANNEL_SEARCH_LIST_FETCH_FAIL';
export const SMS_CHANNEL_SEARCH_LIST_FETCH_SUCCESS =
  'SMS_CHANNEL_SEARCH_LIST_FETCH_SUCCESS';

export const SMS_CHANNEL_UPDATE_FETCH = 'SMS_CHANNEL_UPDATE_FETCH';
export const SMS_CHANNEL_UPDATE_FETCH_FAIL = 'SMS_CHANNEL_UPDATE_FETCH_FAIL';
export const SMS_CHANNEL_UPDATE_FETCH_SUCCESS =
  'SMS_CHANNEL_UPDATE_FETCH_SUCCESS';

export const SMS_CHANNEL_UPDATE_SUBMIT_FETCH =
  'SMS_CHANNEL_UPDATE_SUBMIT_FETCH';
export const SMS_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL =
  'SMS_CHANNEL_UPDATE_SUBMIT_FETCH_FAIL';
export const SMS_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS =
  'SMS_CHANNEL_UPDATE_SUBMIT_FETCH_SUCCESS';

export const SMS_CHANNEL_CHANGE_CURRENT = 'SMS_CHANNEL_CHANGE_CURRENT';
export const SMS_CHANNEL_PAGE_CHANGE_CURRENT =
  'SMS_CHANNEL_PAGE_CHANGE_CURRENT';

export const INFO_ENUM_LIST_FETCH = 'INFO_ENUM_LIST_FETCH';
export const INFO_ENUM_LIST_FETCH_FAIL = 'INFO_ENUM_LIST_FETCH_FAIL';
export const INFO_ENUM_LIST_FETCH_SUCCESS = 'INFO_ENUM_LIST_FETCH_SUCCESS';
